import myaxios from '../../axios';

export const VARIANCEAGGRIDHEADER_REQUEST = 'VARIANCEAGGRIDHEADER_REQUEST';
export const VARIANCEAGGRIDHEADER_SUCCESS = 'VARIANCEAGGRIDHEADER_SUCCESS';
export const VARIANCEAGGRIDHEADER_FAILURE ='VARIANCEAGGRIDHEADER_FAILURE';


export const payroll_variance_get_aggridheader = (id)=>{
    return dispatch=>{
        dispatch({
            type:'VARIANCEAGGRIDHEADER_REQUEST'
        });

        myaxios.get(`payroll/payroll/${id}/ReportGridHeader/`)
        .then((response)=>{
            dispatch({
                type:'VARIANCEAGGRIDHEADER_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'VARIANCEAGGRIDHEADER_FAILURE',
                payload:error
            })
        })
    }
}
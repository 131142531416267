import myaxios from '../axios';

export const OTPCHECK_REQUEST = 'OTPCHECK_REQUEST';
export const OTPCHECK_SUCCESS = 'OTPCHECK_SUCCESS';
export const OTPCHECK_FAILURE = 'OTPCHECK_FAILURE';


export const otp_check = (username,password)=>{
    return dispatch=>{
        dispatch({
            type:'OTPCHECK_REQUEST'
        });

        myaxios.post('otp/check/',{
            username:username,
            password:password
        })
        .then((response)=>{
            dispatch({
                type:'OTPCHECK_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'OTPCHECK_FAILURE',
                payload:error
            })
        })
    }
}
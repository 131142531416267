import * as createemppassport from '../../action/employee/create_employee_passport';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const create_employee_passport = (state=initialState,action)=>{
    switch(action.type){
        case createemppassport.NEW_CREATEEMPPASSPORT_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case createemppassport.NEW_CREATEEMPPASSPORT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case createemppassport.NEW_CREATEEMPPASSPORT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case createemppassport.NEW_CREATEEMPPASSPORT_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default create_employee_passport;
import myaxios from '../../axios';


export const USERCLAIMDETAILGETCLAIMDETAIL_REQUEST = 'USERCLAIMDETAILGETCLAIMDETAIL_REQUEST';
export const USERCLAIMDETAILGETCLAIMDETAIL_SUCCESS = 'USERCLAIMDETAILGETCLAIMDETAIL_SUCCESS';
export const USERCLAIMDETAILGETCLAIMDETAIL_FAILURE = 'USERCLAIMDETAILGETCLAIMDETAIL_FAILURE';
export const USERCLAIMDETAILGETCLAIMDETAIL_RESET = 'USERCLAIMDETAILGETCLAIMDETAIL_RESET';

export const claim_detail_get_claim_detail = (id) =>{
    return dispatch=>{
        dispatch({
            type:'USERCLAIMDETAILGETCLAIMDETAIL_REQUEST'
        });

        myaxios.get(`claim/self/apply/${id}/`)
        .then((response)=>{
            dispatch({
                type:'USERCLAIMDETAILGETCLAIMDETAIL_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'USERCLAIMDETAILGETCLAIMDETAIL_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_claim_detail_get_claim_detail = () => dispatch =>{
    dispatch({
        type:'USERCLAIMDETAILGETCLAIMDETAIL_RESET'
    });
}
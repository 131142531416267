import * as delete_aggrid from '../../action/report/delete_general_aggrid_template';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}


const delete_general_aggrid_template = (state=initialState,action)=>{
    switch(action.type){
        case delete_aggrid.DELGENERALAGGRID_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case delete_aggrid.DELGENERALAGGRID_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case delete_aggrid.DELGENERALAGGRID_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state
    }
}
export default delete_general_aggrid_template;
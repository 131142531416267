import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';

export const WIZARDCREATECOSTCENTER_REQUEST = 'WIZARDCREATECOSTCENTER_REQUEST';
export const WIZARDCREATECOSTCENTER_SUCCESS = 'WIZARDCREATECOSTCENTER_SUCCESS';
export const WIZARDCREATECOSTCENTER_FAILURE = 'WIZARDCREATECOSTCENTER_FAILURE';


export const wizard_create_costcenter = (data)=>{
    return dispatch=>{
        dispatch({
            type:'WIZARDCREATECOSTCENTER_REQUEST'
        });

        myaxios.post('company/costcenter/array_save/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'WIZARDCREATECOSTCENTER_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'WIZARDCREATECOSTCENTER_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}
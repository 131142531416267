import myaxios from '../../axios';

export const GETEMPEDUCATIONPIC_REQUEST = 'GETEMPEDUCATIONPIC_REQUEST';
export const GETEMPEDUCATIONPIC_SUCCESS = 'GETEMPEDUCATIONPIC_SUCCESS';
export const GETEMPEDUCATIONPIC_FAILURE = 'GETEMPEDUCATIONPIC_FAILURE';
export const GETEMPEDUCATIONPIC_RESET = 'GETEMPEDUCATIONPIC_RESET';

export const get_employee_education_picture = (id)=>{
    return dispatch=>{
        dispatch({
            type:'GETEMPEDUCATIONPIC_REQUEST'
        });

        myaxios.get(`ob/edu/${id}/`,{responseType:'arraybuffer'})
        .then((response)=>{
            dispatch({
                type:'GETEMPEDUCATIONPIC_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETEMPEDUCATIONPIC_FAILURE',
                payload:error
            })
        })
    }   
}

export const reset_get_employee_education_picture = () => dispatch =>{
    dispatch({
        type:'GETEMPEDUCATIONPIC_RESET'
    });
}
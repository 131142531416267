import * as payslippicture from '../../action/payroll/payslip_get_emp_picture';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const payslip_get_emp_picture = (state=initialState,action)=>{
    switch(action.type){
        case payslippicture.PAYSLIPEMPPICTURE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case payslippicture.PAYSLIPEMPPICTURE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case payslippicture.PAYSLIPEMPPICTURE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default payslip_get_emp_picture;
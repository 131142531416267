import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';

const mapStateToProps=(state)=>({
    claimlist:state.get_claim_list.data,
    isLoading:state.get_claim_list.isLoading,
    isLoading1:state.get_general_dashboard.isLoading,
})

function ClaimTable(props){

    return(
        <Row>
            <Col>
                <Card style={{height:'270px'}}>
                    <CardHeader className="border-0 p-2 bg-white">
                        <h3 className='title-color1 pl-2 pt-2'>
                            {IMLocalized('total_claims_for_the_month')}
                        </h3>
                    </CardHeader>
                    {props.isLoading || props.isLoading1 ? <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody> : null}
                    {!props.isLoading && !props.isLoading1 && props.list.length === 0 &&
                    <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                    {!props.isLoading && !props.isLoading1 && props.list.length !== 0 && 
                    <Table size="sm" className='align-items-center' responsive>
                        <thead className="thead-light">
                            <tr>
                                <th>{IMLocalized('claim_type')}</th>
                                <th className="text-center">{IMLocalized('remaining')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.list.map((item,index)=>{
                                const claim_detail = props.claimlist.find(item1=>item1.id === item.claim_type);
                                const current_month = new Date().getMonth();

                                return(
                                    <tr key={index}>
                                        <td>{claim_detail?.name ?? '-'}</td>
                                        <td className='text-center'>$ {item.is_monthly ? item.remaining_monthly[current_month] : item.remaining }</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>}
                </Card>
            </Col>
        </Row>
    )
}
export default connect(mapStateToProps,null)(ClaimTable);
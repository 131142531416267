import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';

export const WIZARDVALIDATEDEPARTMENT_REQUEST ='WIZARDVALIDATEDEPARTMENT_REQUEST';
export const WIZARDVALIDATEDEPARTMENT_SUCCESS ='WIZARDVALIDATEDEPARTMENT_SUCCESS';
export const WIZARDVALIDATEDEPARTMENT_FAILURE ='WIZARDVALIDATEDEPARTMENT_FAILURE';

export const wizard_validate_department = (data)=>{
    return dispatch=>{
        dispatch({
            type:'WIZARDVALIDATEDEPARTMENT_REQUEST'
        });

        myaxios.post('company/department/array_validate/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'WIZARDVALIDATEDEPARTMENT_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'WIZARDVALIDATEDEPARTMENT_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}
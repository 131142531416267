import * as updaterates from '../../action/payroll/payroll_update_rates';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}


const payroll_update_rates = (state = initialState,action)=>{
    switch(action.type){
        case updaterates.PAYROLLUPDATERATES_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case updaterates.PAYROLLUPDATERATES_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updaterates.PAYROLLUPDATERATES_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default payroll_update_rates;
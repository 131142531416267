import * as wizardcreatecompany from '../../action/wizard/wizard_create_company';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const wizard_create_company = (state=initialState,action)=>{
    switch(action.type){
        case wizardcreatecompany.WIZARDCREATECOMPANY_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case wizardcreatecompany.WIZARDCREATECOMPANY_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case wizardcreatecompany.WIZARDCREATECOMPANY_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default wizard_create_company;
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IMLocalized } from '../../../language/IMLocalized';
import ModalUpdateResign from './modal/ModalUpdateResign';
import ModalEditProfile from './modal/ModalEditProfile';
import ProfileTable from './component/ProfileTable';

export default function EmployeeDetail(){

    const isLoading = useSelector(state=>state.get_employee_detail.isLoading);
    const emp_detail = useSelector(state=>state.get_employee_detail.data);
    const [is_resign,setIsResign] = useState(false);
    const [resign_toggle,setResignToggle] = useState(false);
    const [update_toggle,setUpdateToggle] = useState(false);

    useEffect(()=>{
        if(emp_detail !== null){
            const resign = emp_detail.resign;
            if(resign !== null){
                setIsResign(true);
            }
            else{
                setIsResign(false);
            }
        }
    },[emp_detail])


    return(
        <>
            <div className='d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title'>
                <div className="d-flex align-items-center">
                    <i className="far fa-id-card text-primary"/>
                    <h4 className="text-primary m-0 pl-2">{IMLocalized('employee_profile')}</h4>
                </div>
                <div className="d-flex">
                    {is_resign &&
                    <div className="text-danger pt-2 cursor-pointer mr-2" onClick={()=>setResignToggle(true)}>
                        <i className="fas fa-pen mr-1 function_icon"/>
                        <small>{IMLocalized('update_resign_detail')}</small>
                    </div>}
                    <div className="text-primary pt-2 cursor-pointer pr-2" onClick={()=>setUpdateToggle(true)} >
                        <i className="fas fa-pen mr-1 function_icon"/>
                        <small>{IMLocalized('update_profile')}</small>
                    </div>
                </div>
            </div>
            {isLoading && 
            <div className='p-2'>
                <h4>{IMLocalized('loading')}..</h4>
            </div>}
            {!isLoading && emp_detail !== null && <ProfileTable/>}
            {is_resign && <ModalUpdateResign toggle={resign_toggle} closeModal={()=>setResignToggle(false)} />}
            {emp_detail && <ModalEditProfile toggle={update_toggle} detail={emp_detail} closeModal={()=>setUpdateToggle(false)}  />}
            
        </>
    )
}

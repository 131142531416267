import { useSelector } from "react-redux";
import { IMLocalized } from "../language/IMLocalized";


export const ClaimStatusRenderer = (params)=>{

    const colorlist = useSelector(state=>state.get_general_color.data);

    const status = params.value;
    let selected_color = '#fff';
    if(colorlist.length !== 0){
        const { data } = colorlist;
        if(status === 'PENDING'){
            selected_color = data.PENDING;
        }
        else if(status === 'APPROVED'){
            selected_color = data.APPROVED;
        }
        else if(status === 'REJECTED'){
            selected_color = data.REJECTED;
        }
        else if(status === 'CANCELLED'){
            selected_color = data.CANCELLED;
        }
        else if(status === 'PAID'){
            selected_color = data.PAID;
        }
        else if(status === 'PAYROLL'){
            selected_color = data.PAID;
        }
    }
    return <div><span className="mr-2" style={{color:selected_color}}>●</span> {IMLocalized(status)}</div>
}
import React from 'react';
import { Card, CardHeader, Table } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';

function SelfHelpTable(props){

    return(
        <Card>
            <CardHeader className="p-2 text-white border-0 bg-white">
                <h4 className='text-primary pl-2 pt-2'>{IMLocalized('payslip_statutory')}</h4>
            </CardHeader>
            <Table size="sm" className='align-items-center' responsive>
                <tbody>
                    {props.sgcpf !== null && props.sgcpf.length !== 0 &&
                    <tr>
                        <th>CPF</th>
                        <td>$ {(props.sgcpf.cpf_employee).toFixed(2)}</td>
                    </tr>}
                    {props.sgshg !== null && props.sgshg.length !== 0 &&
                    props.sgshg.map((item,index)=>{
                        return(
                            <tr key={index}>
                                <th>{item.shg}</th>
                                <td>$ {item.amount.toFixed(2)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </Card>
    )
}
export default SelfHelpTable;
import { AgGridReact } from "ag-grid-react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import { get_approvals_leave_list } from "../../../action/approvals/get_approvals_leave_list";
import { get_general_dashboard } from "../../../action/dashboard/get_general_dashboard";
import { get_apply_leave_list } from "../../../action/get_apply_leave_list";
import { hr_calendar_array_approve_leave_manager, reset_array_approve_leave } from "../../../action/hr_calendar/hr_calendar_array_approve_leave_manager";
import { manager_leave_list } from "../../../action/leave/manager_leave_list";
import { getDateFormat } from "../../../func/getDateFormat";
import { LeaveStatusRenderer } from "../../../func/LeaveStatusRenderer";
import { popError } from "../../../func/popError";
import { IMLocalized } from "../../../language/IMLocalized";
import { dateRenderer } from "../../ag-grid_component/dateRenderer";
import { isFirstColumn } from "../../ag-grid_component/isFirstColumn";

const gridOptions={
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
    suppressRowClickSelection:true,
    defaultColDef:{
        resizable:true,
        sortable:true,
        filter:true,
    },
}

export default function LeaveCalendarPendingList({ setViewDetail }){
    const applylist = useSelector(state=>state.manager_leave_list.data);
    const success = useSelector(state=>state.hr_calendar_array_approve_leave_manager.data);
    // const success = useSelector(state=>console.log(state.array_approve_leave.data));
    const dispatch = useDispatch();

    useEffect(()=>{
        if(success != null){
            dispatch(manager_leave_list());
            dispatch(get_apply_leave_list());
            dispatch(get_approvals_leave_list());
            dispatch(get_general_dashboard());
            dispatch(reset_array_approve_leave());
        }
    },[success,dispatch])


    const onCellFocused = (params) =>{
        if(params?.column?.colDef?.field != null && params.column.colDef.field !== 'select'){
            const focusedCell = gridOptions.api.getFocusedCell();
            const cellValue = gridOptions.api.getDisplayedRowAtIndex(focusedCell.rowIndex)
            const id = cellValue.data.from_date;
            
            setViewDetail(id);
            
        }
    }

    const pendingReject = (boolean) =>{
        const data = gridOptions.api.getSelectedRows();
        if(data.length !== 0 ){
            const arr = data.map((lid)=>applylist.approval_leaves.filter((item)=>item.leave_id === lid.id).map((x)=>x.id).join(''))

            const title1 = boolean ? IMLocalized('approving_leave') : IMLocalized('rejecting_leave');
            Swal.fire({
                title:title1,
                input:'textarea',
                text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
                showCancelButton:true,
                cancelButtonText:IMLocalized('no!'),
                confirmButtonText:IMLocalized('yes!')
            })
            .then((result)=>{
                if(result.hasOwnProperty('value')){
                    const comment = result.value;
                    dispatch(hr_calendar_array_approve_leave_manager(arr,boolean,comment));
                }
            })
        }
        else{
            popError(boolean ? IMLocalized('select_leave_to_approve') : IMLocalized('select_leave_to_reject'));
        }
    }
    const rowData = applylist.leaves && applylist.leaves.filter(item=>item.status === 'PENDING')
    .sort((a,b)=> new Date(b.created_date).getTime() - new Date(a.created_date).getTime())
    .map((item)=>{
        const leave_date = item.from_date === item.to_date ? getDateFormat(item.from_date) : `${getDateFormat(item.from_date)} - ${getDateFormat(item.to_date)}`;
        return {...item, leave_date}
    })

        return(
            <Row className="mt-2 d-flex justify-content-center">
                <Col lg="11">
                    <Row>
                        <Col>
                            <h3>{IMLocalized('pending_leave_list')}</h3>
                        </Col>
                        <Col className="col-auto">
                            <button className="btn btn-primary btn-sm" onClick={()=>pendingReject(true)}>{IMLocalized('approve')}</button>
                            <button className="btn btn-danger btn-sm" onClick={()=>pendingReject(false)}>{IMLocalized('reject')}</button>
                        </Col>
                    </Row>
                    <div className="ag-theme-balham" style={{height:'400px', width:'100%' }}>
                    
                        <AgGridReact
                            enableRangeSelection={true}
                            rowData={rowData}
                            gridOptions={gridOptions}
                            suppressDragLeaveHidesColumns={true}
                            suppressSizeToFit={true}
                            suppressColumnMoveAnimation={false}
                            overlayNoRowsTemplate = {IMLocalized('no_data')}
                            onCellFocused={onCellFocused}
                            columnDefs={[
                                {
                                    headerName:IMLocalized('select'),
                                    pinned:'left',
                                    field:'select',
                                    width:150,
                                    headerCheckboxSelection: isFirstColumn,
                                    checkboxSelection: isFirstColumn,
                                    headerCheckboxSelectionFilteredOnly:true,
                                    lockPosition: true
                                },
                                {
                                    headerName:IMLocalized('leave_date'),
                                    field:'leave_date',
                                    filter:false,
                                    width:250,
                                    comparator:(valueA,valueB,nodeA,nodeB)=>{
                                        const date1 = new Date(nodeA.data.from_date);
                                        const date2 = new Date(nodeB.data.from_date);
                                        return date2 - date1
                                    }
                    
                                },
                                {
                                    headerName:IMLocalized('employee_number'),
                                    field:'employee.employee_number'
                                },
                                {
                                    headerName:IMLocalized('employee_name'),
                                    field:'employee.name'
                                },
                                {
                                    headerName:IMLocalized('leave_type'),
                                    field:'leave_setting.name'
                                },
                                {
                                    headerName:IMLocalized('status'),
                                    field:'status',
                                    cellRendererFramework:LeaveStatusRenderer
                                },
                                {
                                    headerName:IMLocalized('leave_amount'),
                                    field:'amount'
                                },
                                {
                                    headerName:IMLocalized('created_date'),
                                    field:'created_date',
                                    cellRendererFramework:dateRenderer,
                                }
                            ]}
                        />
                    </div>
                </Col>
            </Row>
        )
}
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATECONTRACTMASS_SUCCESS = 'UPDATECONTRACTMASS_SUCCESS';
export const UPDATECONTRACTMASS_REQUEST = 'UPDATECONTRACTMASS_REQUEST';
export const UPDATECONTRACTMASS_FAILURE = 'UPDATECONTRACTMASS_FAILURE';
export const UPDATECONTRACTMASS_RESET = 'UPDATECONTRACTMASS_RESET';
export const UNIQUECHECK_FAILURE = 'UNIQUECHECK_FAILURE';


export const update_employee_contract_mass = (data)=>{
   return dispatch=>{
        dispatch({
            type:'UPDATECONTRACTMASS_REQUEST'
        });
            data.map((item)=>
            
            myaxios.patch(`contract/contracts/${(item.id)}/`,{
                id:item.id,
                employee:item.employee,
                start_date:item.start_date,
                contract_type:item.contract_type,
                contract_rate:item.contract_rate,
                pay:item.pay,
                is_workman:item.is_workman,
                wschedule:item.wschedule,
                hourly_rate:item.hourly_rate,
                hours_day:item.hours_day,
                daily_rate:item.daily_rate,
                department:item.department,
                section:item.section,
                cost_center:item.cost_center,
                job_title:item.job_title,
                job_level:item.job_level,
                }
            )

            .then((response)=>{
                dispatch({
                    type:'UPDATECONTRACTMASS_SUCCESS',
                    payload:response
                });

                Toast.fire({
                    type: 'success',
                    title: IMLocalized('update_success')
                });
            })
            .catch((error)=>{
                dispatch({
                    type:'UPDATECONTRACTMASS_FAILURE',
                    payload:error
                });
                ErrToast.fire({
                    type:'error',
                    title:IMLocalized('update_failure')
                });
            })
        .catch((error)=>{
            dispatch({
                type:'UNIQUECHECK_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })


        )
    }
}

export const reset_update_employee_contract_mass = () => dispatch =>{
    dispatch({
        type:'UPDATECONTRACTMASS_RESET'
    });
}
import myaxios from '../../axios';

export const GETEMPPICTURELIST_REQUEST = 'GETEMPPICTURELIST_REQUEST';
export const GETEMPPICTURELIST_SUCCESS = 'GETEMPPICTURELIST_SUCCESS';
export const GETEMPPICTURELIST_FAILURE = 'GETEMPPICTURELIST_FAILURE';

export const get_employee_picture_list = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETEMPPICTURELIST_REQUEST'
        });
        myaxios.get('ob/employee/')
        .then((response)=>{
            dispatch({
                type:'GETEMPPICTURELIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETEMPPICTURELIST_FAILURE',
                payload:error
            })
        })
    }
}
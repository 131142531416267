import React,{Component} from 'react';
import {connect} from 'react-redux';
import { Container, Row, Col, Modal, ModalHeader, ModalBody, Table, ModalFooter } from 'reactstrap';
import XLSX from 'xlsx';
import ExcelJs from 'exceljs';
import FileSaver from 'file-saver';
import moment from 'moment';
import {create_contract_increment_pay} from '../../action/payroll/create_contract_increment_pay';
import {create_contract_increment_pay_validate} from '../../action/payroll/create_contract_increment_pay_validate';
import { get_employee_list } from '../../action/employee/get_employee_list';
import Swal from 'sweetalert2';
import {IMLocalized} from '../../language/IMLocalized';
import {percentagelist} from '../../data/increment_data';
import 'ag-grid-enterprise';
import {AgGridReact} from 'ag-grid-react';
import OverlayLoading from '../loading_component/overlay_loading';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';


const gridOptions={
    singleClickEdit:true,
    defaultColDef:{
        sortable:true,
        filter:true,
        suppressMenu:true,
        resizable:true
    },
    sideBar:{
        toolPanels:[
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                
            }
        ],
        defaultToolPanel:''
    },
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
}

class payroll_increment extends Component{

    constructor(props){
        super(props);
        this.state={
            rowData:[],
            columnsDefs:[],
            noRowsTemplate:IMLocalized('no_record'),
            rowSelection:'single',
            lodingtemplate:IMLocalized('loading'),
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            apply_file:[],
            update_btn:'',
            current_step:1,
            validatelist:[],
            error_msg:null,
            language:localStorage.getItem('language'),
            action_toggle:false,
            step:1,
        }
    }

    componentDidMount(){
        const {emplist} = this.props;
        if(emplist.length !== 0){
            this.getDetail();
        }
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.emplist !== this.props.emplist || prevState.step !== this.state.step){
            Promise.resolve(this.getDetail())
        }
        if(prevProps.validate_data !== this.props.validate_data){
            this.setState({error_msg:null});
            Promise.resolve(this.setColumns()).then(this.setRowData()).then(this.setState({current_step:3}))
        }
        if(prevProps.create_success !== this.props.create_success){
            Promise.resolve(this.getDetail())
            .then(this.props.get_employee_list())
        }
        if(prevProps.validate_errors !== this.props.validate_errors){
            const errors = this.props.validate_errors;
            const {validatelist,rowData} = this.state;
            if(errors !== undefined){
                console.log(errors)
                if(errors.length !== 0){
                    let arr = [];
                    for(let i =0;i<errors.length;i++){
                        let arr1 = [];
                        const name = validatelist[i].name;
                        const number = validatelist[i].employee_number;
                        const object1 = errors[i];

                        if(object1 !== true)
                        {
                        if('non_field_errors' in object1){
                           
                            const word = object1['non_field_errors'].toString();
                            arr1.push(<div>{word}</div>);
                            const new_rowData = rowData.map((item)=>{
                                const employee_number = item.employee_number;
                                if(number === employee_number){
                                    item.err_non = true;
                                }
                                return item
                            })
                            Promise.resolve(this.setState({rowData:new_rowData}))
                            .then(gridOptions.api.setRowData(new_rowData))
                            .then(()=>setTimeout(()=>{
                                this.selectCell()
                            }));
                        }
                        if('use_amount' in object1){
                            
                            const word = IMLocalized('type') + ' : ' +object1['use_amount'].toString();
                            arr1.push(<div>{word}</div>);
                            const new_rowData = rowData.map((item)=>{
                                const employee_number = item.employee_number;
                                if(number === employee_number){
                                    item.err_amount = true;
                                }
                                return item
                            })
                            Promise.resolve(this.setState({rowData:new_rowData}))
                            .then(gridOptions.api.setRowData(new_rowData))
                            .then(()=>setTimeout(()=>{
                                this.selectCell()
                            }));
                        }
                        if('effective_date' in object1){
                            const word = IMLocalized('effective_date')+ ' : ' +IMLocalized('invalid_date_format');
                            arr1.push(<div>{word}</div>);
                            const new_rowData = rowData.map((item)=>{
                                const employee_number = item.employee_number;
                                if(number === employee_number){
                                    item.err_date = true;
                                }
                                return item
                            })
                            Promise.resolve(this.setState({rowData:new_rowData}))
                            .then(gridOptions.api.setRowData(new_rowData))
                            .then(()=>setTimeout(()=>{
                                this.selectCell()
                            }));
                        }
                        if('hourly_rate' in object1){
                            const word = IMLocalized('hourly_rate')+ ' : ' +IMLocalized('hourly_rate_is_required');
                            arr1.push(<div>{word}</div>);
                            const new_rowData = rowData.map((item)=>{
                                const employee_number = item.employee_number;
                                if(number === employee_number){
                                    item.err_hourly = true;
                                }
                                return item
                            })
                            Promise.resolve(this.setState({rowData:new_rowData}))
                            .then(gridOptions.api.setRowData(new_rowData))
                            .then(()=>setTimeout(()=>{
                                this.selectCell()
                            }));
                        }
                        if('daily_rate' in object1){
                            const word = IMLocalized('daily_rate')+ ' : ' +IMLocalized('daily_rate_is_required');
                            arr1.push(<div>{word}</div>);
                            const new_rowData = rowData.map((item)=>{
                                const employee_number = item.employee_number;
                                if(number === employee_number){
                                    item.err_daily = true;
                                }
                                return item
                            })
                            Promise.resolve(this.setState({rowData:new_rowData}))
                            .then(gridOptions.api.setRowData(new_rowData))
                            .then(()=>setTimeout(()=>{
                                this.selectCell()
                            }));
                        }
                        if('increment' in object1){
                            
                            const word = 'Increment : ' +object1['increment'].toString();
                            arr1.push(<div>{word}</div>);
                            const new_rowData = rowData.map((item)=>{
                                const employee_number = item.employee_number;
                                if(number === employee_number){
                                    item.err_increment = true;
                                }
                                return item
                            })
                            Promise.resolve(this.setState({rowData:new_rowData}))
                            .then(gridOptions.api.setRowData(new_rowData))
                            .then(()=>setTimeout(()=>{
                                this.selectCell()
                            }));
                        }
                        arr.push(<tr><td>{number}</td><td>{name}</td><td>{arr1}</td></tr>)
                        }

                    }
                    this.setState({error_msg:arr});
                    this.setState({error_toggle:true});
                }
            }
        }
        if(this.context.payroll_increment_reset === true){
            this.context.setPayrollIncrementReset(false);
            this.setState({
                rowData:[],
                apply_file:[],
                update_btn:'',
                current_step:1,
                validatelist:[],
                error_msg:null,
            })
        }
    }

    getDetail=()=>{
        let arr = [];
        const {language} = this.state;
        const {emplist} = this.props;
        if(emplist.length !== 0){
            for(let i =0;i<emplist.length ;i++){
                const item = emplist[i];
                const name = item.name;
                const employee_number = item.employee_number;
                const contract_cache = item.contract_cache;
                const hr_cache = item.hr_cache;
                if(Object.keys(hr_cache).length !== 0){
                    const daily_rate = 0;
                    const hourly_rate = 0;
                    let job_title = null;
                    let job_level = null;
                    let department = null;
                    let section = null;
                    let contract_id = null;
                    let pay = null;
                    const use_amount = null;
                    const pa = null;
                    const effective_date = null;
                    const err_increment = false;
                    const err_date = false;
                    const err_non = false;
                    const err_amount = false;
                    const err_hourly = false;
                    const err_daily = false;
                    const id = item.id;
                    const work_days_in_week = hr_cache.work_days_in_week;
                    const contract_rate = hr_cache.contract_rate;
                    const is_workman = hr_cache.is_workman;
                    if(contract_cache !== null){
                        job_title = contract_cache.job_title;
                        job_level = contract_cache.job_level;
                        department = contract_cache.department;
                        section = contract_cache.section;
                        contract_id = contract_cache.id;
                        pay = hr_cache.pay;
                    }
                    if(contract_rate === 'MONTHLY'){
                        arr.push({is_workman,work_days_in_week,err_daily,err_hourly,daily_rate,hourly_rate,err_amount,pay,err_increment,err_non,err_date,contract_id,employee_number,name,job_level,job_title,department,section,use_amount,pa,effective_date,id});
                    }
                }
            }
        }
        this.setState({rowData:arr});
        this.setState({current_step:1});
        this.setState({validatelist:[]});
        this.setState({error_msg:null});

        let option = [''];
        if(language === 'zh'){
            percentagelist.forEach((item)=>{
                const data = item.display_zh;
                option.push(data)
            })
        }
        else{
            percentagelist.forEach((item)=>{
                const data = item.display;
                option.push(data)
            })
        }

        const columnsDefs=[
            {
                headerName:IMLocalized('agrid_select'),
                field:'',
                editable:false,
                width:120,
                lockPosition:true,
                pinned:'left',
                filter:false,
                headerCheckboxSelection: this.isFirstColumn,
                checkboxSelection: this.isFirstColumn,
                headerCheckboxSelectionFilteredOnly:true,
            },
            {
                headerName:IMLocalized('agrid_emp_id'),
                field:'employee_number',
                editable:false,
                width:120,
                filter: 'agTextColumnFilter',
                floatingFilter: true,
                pinned:'left'
            },
            {
                headerName:IMLocalized('agrid_emp_name'),
                field:'name',
                editable:false,
                width:200,
                filter: 'agTextColumnFilter',
                floatingFilter: true,
                pinned:'left'
            },
            {
                headerName:IMLocalized('agrid_department'),
                field:'department',
                editable:false,
                width:150,
                filter: 'agTextColumnFilter',
                floatingFilter: true,
                pinned:'left'
            },
            {
                headerName:IMLocalized('agrid_section'),
                field:'section',
                editable:false,
                width:120,
                filter: 'agTextColumnFilter',
                floatingFilter: true,
                pinned:'left'
            },
            {
                headerName:IMLocalized('agrid_job_title'),
                field:'job_title',
                editable:false,
                width:150,
                filter: 'agTextColumnFilter',
                floatingFilter: true,
                pinned:'left'
            },
            {
                headerName:IMLocalized('agrid_job_level'),
                field:'job_level',
                editable:false,
                width:120,
                filter: 'agTextColumnFilter',
                floatingFilter: true,
                pinned:'left'
            },
            {
                headerName:IMLocalized('agrid_current_pay'),
                field:'pay',
                editable:false,
                width:150,
                pinned:'left'
            },
            {
                headerName:IMLocalized('agrid_type'),
                field:'use_amount',
                editable:true,
                width:120,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: option
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_amount}
                },
            },
            {
                headerName:IMLocalized('agrid_increment'),
                field:'pa',
                editable:true,
                width:150,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_increment}
                },
            },
            {
                headerName:IMLocalized('agrid_effective_date'),
                field:'effective_date',
                editable:true,
                width:200,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_date}
                }
            },
            {
                headerName:IMLocalized('agrid_daily_rate'),
                field:'daily_rate',
                editable:true,
                width:200,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_daily}
                }
            },
            {
                headerName:IMLocalized('agrid_hourly_rate'),
                field:'hourly_rate',
                editable:true,
                width:200,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_hourly}
                }
            },
        ]
        this.setState({columnsDefs});
        this.setState({current_step:1});
    }

    isFirstColumn(params) {
        if(params !== null){
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }   
    }

    generateExcel=()=>{
        const wb = new ExcelJs.Workbook();
        const rowData = gridOptions.api.getSelectedRows();
        if(rowData.length !== 0){
            const ws = wb.addWorksheet('payroll_increment');
            const ws2 = wb.addWorksheet('setting');
            const ws3 = wb.addWorksheet('id');
            const {language} = this.state;
            ws.columns = [
                {
                    header:IMLocalized('agrid_emp_id'),
                    key:'employee_number',
                    width:15
                },
                {
                    header:IMLocalized('agrid_emp_name'),
                    key:'name',
                    width:15
                },
                {
                    header:IMLocalized('agrid_department'),
                    key:'department',
                    width:15
                },
                {
                    header:IMLocalized('agrid_section'),
                    key:'section',
                    width:15
                },
                {
                    header:IMLocalized('agrid_job_title'),
                    key:'job_title',
                    width:15
                },
                {
                    header:IMLocalized('agrid_job_level'),
                    key:'job_level',
                    width:15
                },
                {
                    header:IMLocalized('agrid_current_pay'),
                    key:'pay',
                    width:15
                },
                {
                    header:IMLocalized('agrid_type'),
                    key:'use_amount',
                    width:15
                },
                {
                    header:IMLocalized('agrid_increment'),
                    key:'pa',
                    width:15
                },
                {
                    header:IMLocalized('agrid_effective_date'),
                    key:'effective_date',
                    width:15
                },
            ];

            const columns = [
                {
                    header:IMLocalized('agrid_emp_id'),
                    key:'employee_number',
                },
                {
                    header:IMLocalized('agrid_emp_name'),
                    key:'name',
                },
                {
                    header:IMLocalized('agrid_department'),
                    key:'department',
                },
                {
                    header:IMLocalized('agrid_section'),
                    key:'section',
                },
                {
                    header:IMLocalized('agrid_job_title'),
                    key:'job_title',
                },
                {
                    header:IMLocalized('agrid_job_level'),
                    key:'job_level',
                },
                {
                    header:IMLocalized('agrid_current_pay'),
                    key:'pay'
                },
                {
                    header:IMLocalized('agrid_type'),
                    key:'use_amount',
                },
                {
                    header:IMLocalized('agrid_increment'),
                    key:'pa',
                },
                {
                    header:IMLocalized('agrid_effective_date'),
                    key:'effective_date',
                },
            ];
            
            ws3.getCell('A1').value = 'PayrollIncrement';
            ws3.getCell('A2').value = language;
            if(language === 'zh'){
                
                for(let i=0;i<percentagelist.length;i++){
                    let y = i+1;
                    ws2.getCell('A'+y).value = percentagelist[i].display_zh;
                }
            }
            else{
                for(let i=0;i<percentagelist.length;i++){
                    let y = i+1;
                    ws2.getCell('A'+y).value = percentagelist[i].display;
                }
            }
            
    
            for(let i=0;i<rowData.length;i++){
                let y = i+1;
                ws2.getCell('B'+y).value = rowData[i].contract_id;
            }
            if(rowData.length !==0){
                for(let i=0;i<columns.length;i++){
                    const field=columns[i].key;
                    for(let x=0;x<rowData.length;x++){
                        const step = 2+x;
                        const data = this.getValue(rowData[x],field);
                        ws.getCell(this.getColumnName(i)+step).value =data;
                        if(field === 'use_amount'){
                            if(percentagelist.length !== 0){
                                ws.getCell(this.getColumnName(i)+step).dataValidation={
                                    type:'list',
                                    allowBlank:false,
                                    formulae:['=setting!$A$1:$A$'+percentagelist.length]
                                }
                            }
                            
                        }
                        if(field === 'effective_date'){
                            ws.getCell(this.getColumnName(i)+step).numFmt = 'd/M/yy';
                        }
                    }
                }
            }
            wb.xlsx.writeBuffer().then((buf)=>{
               
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
               FileSaver.saveAs(file, 'Payroll_Increment.xlsx')
            });
        }
        else{
            Swal.fire({
                title:IMLocalized('no_employee_selected'),
                type:'error',
                confirmButtonColor:'#d33',
                confirmButtonText:IMLocalized('ok!'),
            })
        }
    }

    getValue(object, path) {
        const parts = path.split(".");
        
        return parts.reduce((value, key) => value ? value[key] : value, object)
    }

    getColumnName=(n)=>{
        let ordA = 'A'.charCodeAt(0);
        let ordZ = 'Z'.charCodeAt(0);
        let len = ordZ - ordA +1;

        let s = '';
        while(n >=0){
            s = String.fromCharCode(n % len + ordA )+s;
            n = Math.floor(n/len) -1;
        }
        return s;
    }

    uploadExcel=(e)=>{
        const file = e.target.files[0];
        const reader = new FileReader();
        const {language} = this.state;
        const data1 = this.state.rowData;
        reader.onload=(e)=>{
            const bstr = e.target.result;
            const wb = XLSX.read(bstr,{type:'binary'});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const wsname3 = wb.SheetNames[2];
            const ws3 = wb.Sheets[wsname3];

            const columns = {
                'A':'employee_number',
                'B':'name',
                'C':'department',
                'D':'section',
                'E':'job_title',
                'F':'job_level',
                'G':'pay',
                'H':'use_amount',
                'I':'pa',
                'J':'effective_date'

            }
            let rowData1=[];
            let rowIndex =2;
            let idd ='';
            let lang = '';
            if(ws3 !== undefined){
                if(ws3['A1'] !== undefined){
                    idd = ws3['A1'].w;
                    lang = ws3['A2'].w;
                }
            }
            if(idd === 'PayrollIncrement'){
                if(lang === language){
                    while(ws['A'+rowIndex]){
                        let row ={};
                        for(let i =0;i<Object.keys(columns).length;i++){
                            const column = Object.keys(columns)[i];
                            const index = column + rowIndex;
                            if(ws[index] !== undefined){
                                row[columns[column]] = ws[index].w;
                            }
                            else{
                                row[columns[column]] = null;
                            }
                        }
                        rowData1.push(row);
                        rowIndex++;
                    }
                    
                    let arr = [];
                    for(let i=0;i<rowData1.length;i++){
                        const item = rowData1[i];
                        const number = item.employee_number;
                        const name = item.name;
                        const use_amount = item.use_amount;
                        const pa = item.pa;
                        const effective_date = item.effective_date;
                        let id = '';
                        let department = '';
                        let section = '';
                        let job_level = '';
                        let job_title = '';
                        let err_non = false;
                        let err_increment = false;
                        let err_date = false;
                        let err_amount = false;
                        let pay = '';
                        let err_daily = false;
                        let err_hourly = false;
                        data1.forEach((item1)=>{
                            if(number === item1.employee_number){
                                id = item1.id;
                                department = item1.department;
                                section = item1.section;
                                job_level = item1.job_level;
                                job_title = item1.job_title;
                                pay = item1.pay;
                                const work_days_in_week = item1.work_days_in_week;
                                const is_workman = item1.is_workman;
                                const daily_rate = this.calculateDaily(pay,use_amount,pa,work_days_in_week);
                                const hourly_rate = this.calculateHourly(pay,use_amount,pa,is_workman);
                                arr.push({daily_rate,hourly_rate,work_days_in_week,is_workman, employee_number:number,pay,name,use_amount,pa,effective_date,department,section,job_level,job_title,err_non,err_increment,err_date,err_hourly,err_daily,err_amount,id});
                            }
                        })
                    }
                //    ()
                    Promise.resolve(this.setState({rowData:arr}))
                    .then(gridOptions.api.setRowData(arr))
                    .then(()=>setTimeout(()=>{
                        this.selectCell()
                    }))
                    .then(this.setState({current_step:2}));
                   
                }
                else{
                    Swal.fire({
                        title:IMLocalized('wrong_file_selected'),
                        text:IMLocalized('please_choose_correct_language'),
                        type:'error',
                        confirmButtonColor:'#d33',
                        confirmButtonText:IMLocalized('ok!'),
                    })
                }
                
            }
            else{
                Swal.fire({
                    title:IMLocalized('wrong_file_selected'),
                    type:'error',
                    confirmButtonColor:'#d33',
                    confirmButtonText:IMLocalized('ok!'),
                })
            }
            
        }
        reader.readAsBinaryString(file)
    }

    selectCell=()=>{
        gridOptions.api.forEachNode((rowNode)=>{
            rowNode.setSelected(true);
        })
    }

    onAssign=()=>{
        const {current_step} = this.state;
        const rowData = gridOptions.api.getSelectedRows();
        if(rowData.length !== 0){
            if(current_step === 2){
                this.onAssign2(rowData);
            }
            else{
                Promise.resolve(this.setState({rowData}))
                .then(gridOptions.api.setRowData(rowData))
                .then(this.setState({current_step:2}))
                .then(this.onAssign2(rowData))
            }
        }
        else{
            Swal.fire({
                title:IMLocalized('no_employee_selected'),
                type:'error',
                confirmButtonColor:'#d33',
                confirmButtonText:IMLocalized('ok!'),
            })
        }
        
    }

    onAssign2=(rowData)=>{
        const {language} = this.state;
        let arr = [];
        for(let i =0;i<rowData.length;i++){
        if(rowData[i].pa !== null){
            const employee = rowData[i].id;
            const name = rowData[i].name;
            const increment = rowData[i].pa;
            const use = rowData[i].use_amount;
            let use_amount ='';
            if(language === 'zh'){
                if(use === '金额'){
                    use_amount = true;
                }
                else if(use === '百分比'){
                    use_amount = false;
                }
                else{
                    use_amount = null;
                }
            }
            else{
                if(use === 'Amount'){
                    use_amount = true;
                }
                else if(use === 'Percentage'){
                    use_amount = false;
                }
                else{
                    use_amount = null;
                }
            }

            const date = rowData[i].effective_date;
            const hourly_rate = rowData[i].hourly_rate;
            const daily_rate = rowData[i].daily_rate;
            let effective_date = null;
            if(date !== null && date !== ''){
                const date1 = moment(date,'DD/MM/YYYY');
                effective_date = date1.format('YYYY-MM-DD');
            }
            else{
                const current = new Date();
                effective_date = moment(current).format('YYYY-MM-DD');
            }
            arr.push({employee,name,increment,effective_date,use_amount,hourly_rate,daily_rate});
        }
        }
        this.setState({validatelist:rowData});
        this.props.create_contract_increment_pay_validate(arr);
    }

    setColumns=()=>{
        const {language} = this.state;
        let list =[];
        if(language === 'zh'){
            list = percentagelist.map((item)=>{
                return item.display_zh
            })
        }
        else{
            list = percentagelist.map((item)=>{
                return item.display
            })
        }
        let columnsDefs=[
            {
                headerName:IMLocalized('agrid_emp_id'),
                field:'employee_number',
                editable:false,
                width:100
            },
            {
                headerName:IMLocalized('agrid_emp_name'),
                field:'name',
                editable:false,
                width:200
            },
            {
                headerName:IMLocalized('agrid_department'),
                field:'department',
                editable:false,
                width:150
            },
            {
                headerName:IMLocalized('agrid_section'),
                field:'section',
                editable:false,
                width:150
            },
            {
                headerName:IMLocalized('agrid_job_title'),
                field:'job_title',
                editable:false,
                width:150
            },
            {
                headerName:IMLocalized('agrid_job_level'),
                field:'job_level',
                editable:false,
                width:150
            },
            {
                headerName:IMLocalized('agrid_type'),
                field:'use_amount',
                editable:true,
                width:120,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: list
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_amount}
                }
            },
            {
                headerName:IMLocalized('agrid_increment'),
                field:'pa',
                editable:true,
                width:150,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_increment}
                }
            },
            {
                headerName:IMLocalized('agrid_effective_date'),
                field:'effective_date',
                editable:true,
                width:200,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_date}
                }
            },
            {
                headerName:IMLocalized('agrid_daily_rate'),
                field:'daily_rate',
                width:150,
                editable:false
            },
            {
                headerName:IMLocalized('agrid_hourly_rate'),
                field:'hourly_rate',
                width:150,
                editable:false
            },
            {
                headerName:IMLocalized('agrid_current_pay'),
                field:'old_amount',
                width:150,
                editable:false
            },
            {
                headerName:IMLocalized('agrid_amount'),
                field:'amount',
                width:150,
                editable:false
            },
            {
                headerName:IMLocalized('agrid_forecast'),
                field:'forecast',
                width:150,
                editable:false
            },
            
        ];
        this.setState({columnsDefs});
    }

    setRowData=()=>{
        const {validate_data,emplist} = this.props;
        const {language} = this.state;
        const data = validate_data.data;
        let arr = [];
        if(data.length !== 0){
            for(let i=0;i<data.length;i++){
                
                const date = data[i].effective_date;
                let effective_date;
                if(date !== null){
                    const date1 = moment(date,'YYYY-MM-DD');
                    effective_date = date1.format('DD/MM/YYYY');
                }
                
                const employee = data[i].employee;
                const forecast = data[i].new_amount;
                const old_amount = data[i].old_amount;
                const pa = data[i].increment;
                const amount = data[i].amount;
                const toggle = data[i].use_amount;
                const daily_rate = data[i].daily_rate;
                const hourly_rate = data[i].hourly_rate;
                let use_amount;
                if(language === 'zh'){
                    if(toggle === true){
                        use_amount = '金额';
                    }
                    else{
                        use_amount = '百分比';
                    }
                }
                else{
                    if(toggle === true){
                        use_amount = 'Amount';
                    }
                    else{
                        use_amount = 'Percentage';
                    }
                }
                

                let job_title= null;
                let job_level = null;
                let department = null;
                let section = null;
                let employee_number = null;
                let name = null;
                let pay = null;
                const emp_detail = emplist.filter(function(item){
                    return item.id === employee
                })
                if(emp_detail.length !== 0){
                    const contract_cache = emp_detail[0].contract_cache;
                    employee_number = emp_detail[0].employee_number;
                    name = emp_detail[0].name;
                    const hr_cache = emp_detail[0].hr_cache;
                    if(contract_cache !== null){
                        job_level = contract_cache.job_level;
                        job_title = contract_cache.job_title;
                        department = contract_cache.department;
                        section = contract_cache.section;
                        
                    }
                    if(hr_cache !== null){
                        pay = hr_cache.pay;
                    }
                }
                arr.push({hourly_rate,daily_rate,old_amount,pay,employee,amount,name,employee_number,department,section,job_level,job_title,use_amount,pa,effective_date,forecast});
            }
        }
        this.setState({rowData:arr});
    }

    onGridReady = params =>{
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    errorToggle=()=>{
        const toggle = !this.state.error_toggle;
        this.setState({error_toggle:toggle});
    }

    create_increment=()=>{
        const {rowData,language} = this.state;
        let arr = [];
        if(rowData.length !== 0){
            for(let i=0;i<rowData.length;i++){
                const employee = rowData[i].employee;
                const use = rowData[i].use_amount;
                let use_amount;
                if(language === 'zh'){
                    if(use === '金额'){
                        use_amount = true;
                    }
                    else{
                        use_amount = false;
                    }
                }
                else{
                    if(use === 'Amount'){
                        use_amount = true;
                    }
                    else{
                        use_amount = false;
                    }
                }
                
                const date = rowData[i].effective_date;
                let effective_date = null;
                if(date !== null && date !== ''){
                    const date1 = moment(date,'DD/MM/YYYY');
                    effective_date = date1.format('YYYY-MM-DD');
                }
                else{
                    const current = new Date();
                    effective_date = moment(current).format('YYYY-MM-DD');
                }

                const increment = rowData[i].pa;
                const daily_rate = (rowData[i].daily_rate).toFixed(4);
                const hourly_rate = (rowData[i].hourly_rate).toFixed(8);
                arr.push({employee,use_amount,effective_date,increment,daily_rate,hourly_rate});
            }
        }
        this.props.create_contract_increment_pay(arr)
    }

    getErrorModal=()=>{
        const {error_msg} = this.state;
        const table =(
            <Modal size="lg" isOpen={this.state.error_toggle}>
                <ModalHeader toggle={this.errorToggle}>{IMLocalized('error_msg')}</ModalHeader>
                <ModalBody>
                    <Table size="sm" className="table-bordered" responsive>
                        <thead className="p-2 thead-light">
                            <tr>
                                <th>
                                    {IMLocalized('employee_number')}
                                </th>
                                <th>
                                    {IMLocalized('name')}
                                </th>
                                <th>
                                    {IMLocalized('error')}
                                </th>
                            </tr>
                        </thead>
                        <tbody className="p-2">
                            {error_msg}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-danger btn-sm" onClick={this.errorToggle}>{IMLocalized('close')}</button>
                </ModalFooter>
            </Modal>
        )
        return table;
    }

    onCellFocused=(params)=>{
        if(params !== null){
            if(params.column !== null){
                if(params.column.colDef !== null){
                    if(params.column.colDef.field ==='pa' || params.column.colDef.field ==='use_amount' || params.column.colDef.field ==='effective_date' || params.column.colDef.field ==='daily_rate' || params.column.colDef.field ==='hourly_rate'){
                        
                        params.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = true;
                    }
                    else{
                        params.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = false;
                    }
                }
            }   
        }
    }

    onCellValueChanged=(params)=>{
        if(params.colDef.field === 'use_amount'){
            const focusedCell = gridOptions.api.getFocusedCell();
            gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                if(index === focusedCell.rowIndex){
                    rowNode.setSelected(true);
                }
            })
            params.data.err_amount = false;
          
        }
        else if (params.colDef.field === 'pa'){
            const focusedCell = gridOptions.api.getFocusedCell();
            gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                if(index === focusedCell.rowIndex){
                    rowNode.setSelected(true);
                }
            })
            params.data.err_increment = false;
            
        }
        else if (params.colDef.field === 'effective_date'){
            const focusedCell = gridOptions.api.getFocusedCell();
            gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                if(index === focusedCell.rowIndex){
                    rowNode.setSelected(true);
                }
            })
            params.data.err_date = false;
        }
        else if(params.colDef.field === 'daily_rate'){
            const focusedCell = gridOptions.api.getFocusedCell();
            gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                if(index === focusedCell.rowIndex){
                    rowNode.setSelected(true);
                }
            })
            params.data.err_daily = false;
          
        }
        else if(params.colDef.field === 'hourly_rate'){
            const focusedCell = gridOptions.api.getFocusedCell();
            gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                if(index === focusedCell.rowIndex){
                    rowNode.setSelected(true);
                }
            })
            params.data.err_hourly = false;
          
        }

        if(params.colDef.field === 'pa' || params.colDef.field === 'use_amount'){
            const pay = params.data.pay;
            const type = params.data.use_amount;
            const pa = params.data.pa;
            const work_days_in_week = params.data.work_days_in_week;
            const is_workman = params.data.is_workman;
            params.data.daily_rate =this.calculateDaily(pay,type,pa,work_days_in_week);
            params.data.hourly_rate = this.calculateHourly(pay,type,pa,is_workman);
        }
        params.api.refreshCells();
    }

    calculateDaily=(pay,type,pa,work_days_in_week)=>{
        let total = 0;
        let basic = parseFloat(pay);
        if(type !== null && pa !== null && basic !== 0 ){
            if(  type ===  '金额' || type === 'Amount'){
                basic = basic + parseFloat(pa);     
            }
            else{
                basic = basic + basic * parseFloat(pa) /100;
            }

            total = (basic * 12) /(52* work_days_in_week);

        }
        
        return total.toFixed(4);
    }

    calculateHourly=(pay,type,pa,is_workman)=>{
        let total = 0;
        let basic = parseFloat(pay);
        if(type !== null && pa !== null){
            if(  type ===  '金额' || type === 'Amount'){
                basic = basic + parseFloat(pa);     
            }
            else{
                basic = basic + basic * parseFloat(pa) /100;
            }

            total = (basic* 12)/(52*44);

            if(is_workman === true || is_workman === 'True'){
                if(basic > 4500){
                    total = (4500*12)/(52*44);
                }
            }
            else{
                if(basic >= 2600){
                    total = 13.60
                }
            }
        }
        
        return total.toFixed(8)
    }

    clearData=()=>{
        const {step} = this.state;
        const step1 = step +1;
        Promise.resolve(this.setState({rowData:[]}))
        .then(this.setState({step:step1}));
    }

    handleFile = event => {
        const { target = {} } = event || {};
        target.value = "";
    };


    render(){

        const {current_step,error_msg} = this.state;
        let btn_field = '';
        let error_btn ='';
        let btn_assign = '';
        let import_btn = '';
        if(current_step === 1 || current_step === 2){
            btn_field = <button onClick={this.generateExcel} className="btn btn-primary btn-sm">{IMLocalized('get_template')}</button>
            btn_assign = <button className='btn btn-primary btn-sm' onClick={this.onAssign}>{IMLocalized('assign')}</button>
            import_btn =(
                <div className="ml-2 mr-2">
                    <label className="btn btn-primary btn-sm" htmlFor="increment_file">{IMLocalized('import_from_file')}</label>
                </div>
            )
        }
        else{
            btn_assign = <button onClick={this.create_increment} className="btn btn-primary btn-sm">{IMLocalized('update')}</button>
        }
        if(error_msg !== null){
            error_btn = <button onClick={this.errorToggle} className="btn btn-danger btn-sm">{IMLocalized('error_msg')}</button>
        }

        const {action_toggle} = this.context;
        let currentHeight ='calc(100vh - 260px)';
        if(action_toggle === false){
            currentHeight = 'calc(100vh - 224px)';
        }


        return(
            <div>
                {this.props.isLoading || this.props.isLoading1 || this.props.isLoading2 ?<OverlayLoading/>:
                <div>
                    <Container fluid>
                        <Row className="border-bottom bg-white">
                            <Col className="p-2">
                                <BreadCrumbList list={[
                                    { active: false, title:IMLocalized('home'), onClick:()=>this.context.addItem('1','dashboard')},
                                    { active: true, title:IMLocalized('payroll_increment')}
                                ]}/>
                            </Col>
                        </Row>
                        <Row className="pt-2">
                            <Col className="d-flex">
                                <div >
                                    {btn_field}
                                </div>
                                {import_btn}
                                <div>
                                    <button onClick={this.clearData} className="btn btn-primary btn-sm">{IMLocalized('clear')}</button>
                                    {btn_assign}
                                   
                                    {error_btn}
                                </div>
                                
                            </Col>
                        </Row>
                        <Row className="pt-2">
                            <Col>
                                <div className="d-flex justify-content-center">
                                    <div className="ag-theme-balham" style={{height:currentHeight, width:'100%' }}>
                                        <AgGridReact
                                        columnDefs={this.state.columnsDefs}
                                        rowData={this.state.rowData}
                                        suppressDragLeaveHidesColumns={true}
                                        suppressSizeToFit={true}
                                        suppressColumnMoveAnimation={false}
                                        singleClickEdit={true}
                                        rowClassRules={{
                                            'sick-days-warning': function (params) {
                                                var numSickDays = params.data.err_non;
                                                return numSickDays ===true
                                            },
                                        }}
                                        gridOptions={gridOptions}
                                        onGridReady={this.onGridReady}
                                        overlayNoRowsTemplate={this.state.noRowsTemplate}
                                        onCellValueChanged={this.onCellValueChanged}
                                        onCellFocused={this.onCellFocused}
                                        stopEditingWhenCellsLoseFocus={true}
                                        >
                                        </AgGridReact>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <input type="file" style={{display:'none'}} id="increment_file" className="custom-file-input form-control-sm" accept=".xlsx"  onChange={this.uploadExcel} onClick={this.handleFile} />
                        {this.getErrorModal.call(this)}
                    </Container>
                </div>}
            </div>
        )
    }
}
function mapStateToProps(state,ownProps){
    return{
        emplist:state.get_employee_list.data,
        validate_data:state.create_contract_increment_pay_validate.data,
        create_success:state.create_contract_increment_pay.data,
        validate_errors:state.create_contract_increment_pay_validate.errors,
        isLoading:state.get_employee_list.isLoading,
        isLoading1:state.create_contract_increment_pay_validate.isLoading,
        isLoading2:state.create_contract_increment_pay.isLoading
    }
}

const mapDispatchToProps =(dispatch)=>{
    return{
        create_contract_increment_pay:(data)=>{
            dispatch(create_contract_increment_pay(data))
        },
        create_contract_increment_pay_validate:(data)=>{
            dispatch(create_contract_increment_pay_validate(data))
        },
        get_employee_list:()=>{
            dispatch(get_employee_list())
        }
    }
}
payroll_increment.contextType = NewMainContext;
export default connect(mapStateToProps,mapDispatchToProps)(payroll_increment);

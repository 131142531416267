import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, Col, Container, Row, CardBody, ListGroup, ListGroupItem, } from 'reactstrap';
import Swal from 'sweetalert2';
import { IMLocalized } from '../../language/IMLocalized';
import { delete_general_aggrid_template } from '../../action/report/delete_general_aggrid_template';
import { get_general_aggrid_template } from '../../action/report/get_general_aggrid_template';
import { usePrevious } from '../../hook/usePrevious';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';

const mapStateToProps=(state)=>({
    list:state.get_general_aggrid_template.data,
    isLoading:state.get_general_aggrid_template.isLoading,
    success:state.delete_general_aggrid_template.data,
})

const mapDispatchToProps=(dispatch)=>({
    delete_general_aggrid_template:(id)=>{
        dispatch(delete_general_aggrid_template(id))
    },
    get_general_aggrid_template:()=>{
        dispatch(get_general_aggrid_template())
    }
})


function CustomReportTemplate(props){

    const {success,get_general_aggrid_template} = props;
    const prevSuccess = usePrevious(success);
    const { addItem } = useContext(NewMainContext);

    useEffect(()=>{
        if(prevSuccess !== undefined && prevSuccess !== success && success != null){
            get_general_aggrid_template();
        }
    },[prevSuccess,success,get_general_aggrid_template])

    return(
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title: IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title: IMLocalized('custom_report_template')}
                    ]}/>
                </Col>
            </Row>
        </Container>
        <Container className='mt-2'>
            <Card>
                <CardHeader>
                    <h4 className='mb-0'>{IMLocalized('custom_report_template')}</h4>
                </CardHeader>
                {props.isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>}
                {!props.isLoading && props.list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                {!props.isLoading && props.list.length !== 0 && 
                <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 250px)'}}>
                    {props.list.map((item,index)=>{

                        return(
                            <ListGroupItem className='py-2 d-flex justify-content-between align-items-center' key={index}>
                                <span>{index+1}. {item.name}</span>
                                <div className='mr-2'>
                                    <button className='btn btn-danger btn-sm' 
                                    onClick={()=>{
                                        Swal.fire({
                                            type:"warning",
                                            title:`${IMLocalized('delete_custom_report_template_confirmation')} (${item.name})?`,
                                            showCancelButton:true,
                                            confirmButtonColor:'red',
                                            confirmButtonText:IMLocalized('yes!'),
                                            cancelButtonText:IMLocalized('no!')
                                        })
                                        .then((result)=>{
                                            if(result.value){
                                                props.delete_general_aggrid_template(item.id)
                                            }
                                        })
                                    }}>{IMLocalized('delete')}</button>
                                </div>
                               
                            </ListGroupItem>
                        )
                    })}
                </ListGroup>}
            </Card>
        </Container>
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(CustomReportTemplate)
import * as wizarvalidatejobtitle from '../../action/wizard/wizard_validate_jobtitle';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const wizard_validate_jobtitle = (state=initialState,action)=>{
    switch(action.type){
        case wizarvalidatejobtitle.WIZARDVALIDATEJOBTITLE_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case wizarvalidatejobtitle.WIZARDVALIDATEJOBTITLE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case wizarvalidatejobtitle.WIZARDVALIDATEJOBTITLE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default wizard_validate_jobtitle;
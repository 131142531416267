import { AgGridReact } from "ag-grid-react";
import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { IMLocalized } from "../../../language/IMLocalized";
import { dateRenderer } from "../../ag-grid_component/dateRenderer";
import { getDateFormat } from "../../../func/getDateFormat";
import { isFirstColumn } from "../../ag-grid_component/isFirstColumn";
import { dateRangeOverlap } from "../../../func/dateRangeOverlap";
import { Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import { leave_table_hr_approve } from "../../../action/leave/leave_table_hr_approve";
import { usePrevious } from "../../../hook/usePrevious";
import { NewMainContext } from "../../../context/NewMainContext";
import { LeaveStatusRenderer } from "../../../func/LeaveStatusRenderer";

const mapStateToProps=(state)=>({
    colorlist:state.get_general_color.data,
    applylist:state.get_apply_leave_list.data,
})

const mapDispatchToProps=(dispatch)=>({
    leave_table_hr_approve:(id,approve,remarks)=>{
        dispatch(leave_table_hr_approve(id,approve,remarks))
    },
})

const gridOptions={
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
    suppressRowClickSelection:true,
    sideBar:{
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                toolPanelParams: {
                },
            },
        ],
        defaultToolPanel: '',
    },
    defaultColDef:{
        resizable:true,
        sortable:true,
        filter:true,
        enableValue:true,
        enableRowGroup:true,
    },
}

const initialState={
    color_approve:'#ffffff',
    color_pending:'#ffffff',
    color_reject:'#ffffff',
    color_cancel:'#ffffff',
    color_consumed:'#ffffff',
    rowData:[],
    bottomData:[],
}

function LeaveTableAggrid(props){
    const {reset_grid} = props;
    const [state,setState] = useState(initialState);
    const {action_toggle} = useContext(NewMainContext);
    const prevReset = usePrevious(reset_grid);

    useEffect(()=>{
        if(prevReset !== undefined && reset_grid !== prevReset){
            gridOptions.api.clearFocusedCell();
        }
    },[reset_grid,prevReset])

    useEffect(()=>{
        if(props.colorlist.length !== 0){
            const {data} = props.colorlist;
            setState(prevState=>({
                ...prevState,
                color_pending:data.PENDING,
                color_approve:data.APPROVED,
                color_reject:data.REJECTED,
                color_cancel:data.CANCELLED,
                color_consumed:data.CONSUMED,
            }))
        }
    },[props.colorlist])

    useEffect(()=>{
        let arr = props.applylist;
       
        arr = dateRangeOverlap(props.applylist,props.start_date,props.end_date);

        let type = null;
        if(props.selected === '0'){
            type = 'PENDING';
        }
        else if(props.selected === '1'){
            type = 'APPROVED';
        }
        else if(props.selected === '2'){
            type = 'REJECTED';
        }
        else if(props.selected === '3'){
            type = 'CANCELLED';
        }
        else if(props.selected === '4'){
            type = 'CONSUMED';
        }
        if(type !== null){
            arr = arr.filter(item=>item.status === type )
        }
        

        const datalist = arr.map((item)=>{
            const leave_date = item.from_date === item.to_date ? getDateFormat(item.from_date) : getDateFormat(item.from_date) +' - ' + getDateFormat(item.to_date);
            return {...item,leave_date}
        }).sort((a,b)=> new Date(b.created_date).getTime() - new Date(a.created_date).getTime())
        

        const amount = datalist.reduce((prev,current)=>prev+current.amount,0);
        const bottom = [{amount,leave_date:IMLocalized('total'),created_date:null,id:null}];

        setState(prevState=>({...prevState,rowData:datalist,bottomData:bottom}))
    },[props.applylist,props.start_date,props.end_date,props.selected])

    const pendingReject=(boolean)=>{
        const data = gridOptions.api.getSelectedRows();
        if(data.length !== 0){

            const arr = data.map(item=>item.id);
            Swal.fire({
                title:boolean ? IMLocalized('approving_leave') : IMLocalized('rejecting_leave'),
                input:'textarea',
                text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
                showCancelButton:true,
                cancelButtonText:IMLocalized('no!'),
                confirmButtonText:IMLocalized('yes!')
            })
            .then(result=>{
                if('value' in result){
                    let remark = result.value;
                    props.leave_table_hr_approve(arr,boolean,remark);
                }
            })
        }
        else{
            Swal.fire({
                title:boolean ? IMLocalized('select_leave_to_approve') : IMLocalized('select_leave_to_reject'),
                type:'warning',
                showConfirmButton:true,
                confirmButtonText:IMLocalized('ok!')
            })
        }
    }

    const onCellFocused=(params)=>{
        if(params?.column?.colDef?.field != null && params.column.colDef.field !== 'select'){
            const focusedCell = gridOptions.api.getFocusedCell();
            const cellValue = gridOptions.api.getDisplayedRowAtIndex(focusedCell.rowIndex)
            const id = cellValue.data.id;
            const rowPinned = focusedCell.rowPinned;
            if(rowPinned !== 'bottom'){
                props.toDetail(id)
            } 
        }
    }


    let columnDefs =[];
    
    if(props.selected === '0' || props.selected === '1'){
        columnDefs=[
            {
                headerName:IMLocalized('select'),
                pinned:'left',
                field:'select',
                width:150,
                headerCheckboxSelection: isFirstColumn,
                checkboxSelection: isFirstColumn,
                headerCheckboxSelectionFilteredOnly:true,
                lockPosition: true
            },
            {
                headerName:IMLocalized('leave_date'),
                field:'leave_date',
                width:250,
                filter:false,
                comparator:(valueA,ValueB,nodeA,nodeB)=>{
                    const date1 = new Date(nodeA.data.from_date) 
                    const date2 = new Date(nodeB.data.from_date);
                    return date2 -date1
                }
            },
            {
                headerName:IMLocalized('employee_number'),
                field:'employee.employee_number',
                width:250
            },
            {
                headerName:IMLocalized('employee_name'),
                field:'employee.name',
                width:250
            },
            {
                headerName:IMLocalized('leave_type'),
                field:'leave_setting.name',
                width:250
            },
            {   
                headerName:IMLocalized('leave_amount'),
                field:'amount',
                width:250
            },
            {
                headerName:IMLocalized('status'),
                field:'status',
                width:250,
                cellRendererFramework:LeaveStatusRenderer
            },
            {
                headerName:IMLocalized('created_date'),
                field:'created_date',
                width:250,
                cellRendererFramework:dateRenderer
            }
        ];
    }
    else{
        columnDefs = [
            {
                headerName:IMLocalized('leave_date'),
                field:'leave_date',
                width:250,
                filter:false,
                comparator:(valueA,ValueB,nodeA,nodeB)=>{
                    const date1 = new Date(nodeA.data.from_date) 
                    const date2 = new Date(nodeB.data.from_date);
                    return date2 -date1
                }
            },
            {
                headerName:IMLocalized('employee_number'),
                field:'employee.employee_number',
                width:250
            },
            {
                headerName:IMLocalized('employee_name'),
                field:'employee.name',
                width:250
            },
            {
                headerName:IMLocalized('leave_type'),
                field:'leave_setting.name',
                width:250
            },
            {   
                headerName:IMLocalized('leave_amount'),
                field:'amount',
                width:250
            },
            {
                headerName:IMLocalized('status'),
                field:'status',
                width:250,
                cellRendererFramework:LeaveStatusRenderer
            },
            {
                headerName:IMLocalized('created_date'),
                field:'created_date',
                width:250,
                cellRendererFramework:dateRenderer,
            }
        ]
    }
    return(
        <>
        <Row className="mt-2">
            <Col>
                <h3 className="mb-0">
                    {props.selected === '5' && IMLocalized('all_leave_list')}
                    {props.selected === '0' && IMLocalized('pending_leave_list')}
                    {props.selected === '1' && IMLocalized('approved_leave_list')}
                    {props.selected === '2' && IMLocalized('rejected_leave_list')}
                    {props.selected === '3' && IMLocalized('cancelled_leave_list')}
                    {props.selected === '4' && IMLocalized('consumed_leave_list')}
                </h3>
            </Col>
                <Col className="col-auto">
                <button className="btn btn-success btn-sm" onClick={props.openReport} >{IMLocalized('export')} </button>
                {props.selected === '0' &&
                <>
                    <button className="btn btn-primary btn-sm" onClick={()=>pendingReject(true)}>{IMLocalized('approve')}</button>
                    <button className="btn btn-danger btn-sm" onClick={()=>pendingReject(false)}>{IMLocalized('reject')}</button>
                </>}
                {props.selected === '1' &&
                <>
                    <button className="btn btn-danger btn-sm" onClick={()=>pendingReject(false)}>{IMLocalized('reject')}</button>
                </>}
            </Col>
        </Row>
        <div className="ag-theme-balham mt-2" style={{height:action_toggle ? 'calc(100vh - 300px)' : 'calc(100vh - 264px)', width:'100%' }}>
            <AgGridReact
            enableRangeSelection={true}
            gridOptions={gridOptions}
            columnDefs={columnDefs}
            rowData={state.rowData}
            suppressDragLeaveHidesColumns={true}
            suppressSizeToFit={true}
            suppressColumnMoveAnimation={false}
            overlayNoRowsTemplate = {IMLocalized('no_data')}
            onCellFocused={(e)=>onCellFocused(e)}
            pinnedBottomRowData={state.bottomData}
            />
        </div>
        </>
        
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(LeaveTableAggrid);
import myaxios from '../../axios';

export const GETSGCHILD_REQUEST = 'GETSGCHILD_REQUEST';
export const GETSGCHILD_SUCCESS = 'GETSGCHILD_SUCCESS';
export const GETSGCHILD_FAILURE = 'GETSGCHILD_FAILURE';

export const get_employee_sg_child = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETSGCHILD_REQUEST'
        });

        myaxios.get('leave/sg/child/')
        .then((response)=>{
            dispatch({
                type:'GETSGCHILD_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETSGCHILD_FAILURE',
                payload:error
            })
        })
    }
}
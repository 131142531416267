import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Col, Container, Input, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledTooltip } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import blank_picture from '../../../images/blank_picture.png';
import { getDateFormat } from '../../../func/getDateFormat';
import classnames from 'classnames';
import Profile from './UserEmployeeProfile';
import Contract from './UserEmployeeContract';
import Family from './UserEmployeeFamily';
import Bank from './UserEmployeeBank';
import Leave from './UserEmployeeLeave';
import Claim from './UserEmployeeClaim';
import Payroll from './UserEmployeePayroll';
import Permit from './UserEmployeePermit';
import Education from './UserEmployeeEducation';
import Certification from './UserEmployeeCertification';
import Token from './UserEmployeeToken';
import Delegation from './UserEmployeeDelegation';
import { Buffer } from 'buffer';
import { get_user_picture } from '../../../action/user/get_user_picture';
import { update_employee_profile_picture2 } from '../../../action/employee/update_employee_profile_picture2';
import { update_employee_profile_picture } from '../../../action/employee/update_employee_profile_picture';
import { usePrevious } from '../../../hook/usePrevious';

const mapStateToProps=(state)=>({
    data:state.user_self.data,
    picture:state.get_user_picture.data,
    upload_success:state.update_employee_profile_picture.data,
    upload_success1:state.update_employee_profile_picture2.data,
})

const mapDispatchToProps=(dispatch)=>({
    get_user_picture:()=>{
        dispatch(get_user_picture())
    },
    update_employee_profile_picture2:(id,data)=>{
        dispatch(update_employee_profile_picture2(id,data))
    },
    update_employee_profile_picture:(id,data)=>{
        dispatch(update_employee_profile_picture(id,data))
    }
})


function EmployeeProfileDetail(props){
    const {get_user_picture,upload_success,upload_success1} = props;
    const [picture,setPicture] = useState(null);
    const [state,setState] = useState(null);
    const prevUpload = usePrevious(upload_success);
    const prevUpload1 = usePrevious(upload_success1);

    useEffect(()=>{
        let refresh = false;
        if(prevUpload !== undefined && prevUpload !== upload_success && upload_success != null){
            refresh = true;
        }

        if(prevUpload1 !== undefined && prevUpload1 !== upload_success1 && upload_success1 != null){
            refresh = true;
        }

        if(refresh){
            refresh = false;
            get_user_picture();
        }

        return()=>{
            refresh =false;
        }

    },[prevUpload,prevUpload1,upload_success,upload_success1,get_user_picture])

    useEffect(()=>{
        get_user_picture();
    },[get_user_picture])

    useEffect(()=>{
        if(props.data.length !== 0 ){
            setState(props.data);
        }
    },[props.data])

    useEffect(()=>{
        if(props.picture != null && props.picture.length !== 0){
            const img = new Buffer.from(props.picture,'binary').toString('base64');
            const src = `data:image/png;base64,${img}`;
            setPicture(src);
        }
    },[props.picture])
    

    useEffect(()=>{

    },[])


    const toggle = (index) =>{
        props.setActiveTab(index);
        localStorage.setItem('user_step',index);
    }

    const onUpdatePicture2=(e)=>{
        const file = e.target.files[0];
        props.update_employee_profile_picture2(state.id,file);
    }

    const onUpdatePicture=(e)=>{
        const file = e.target.files[0];
        props.update_employee_profile_picture(state.id,file);
    }


    return(
        <>
            <Container fluid>
                <Row className='border-bottom bg-white'>
                    <Col className='p-2'>
                        <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                            <BreadcrumbItem><a href="/#" onClick={props.toHome}>{IMLocalized('home')}</a></BreadcrumbItem>
                            <BreadcrumbItem active >{IMLocalized('profile')}</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </Row>
            </Container>
            <Container className="bg-white" style={{minHeight:'calc(100vh - 150px)'}}>
                <Row >
                    <Col xs="12" sm="4" md="3" lg="2" className="pt-2 bg_title border-left" >
                        <label htmlFor={picture === null ? 'newimg' : 'imgupload'}><img src={picture ??blank_picture}  alt={picture ? 'user_picture' : 'blank'}  className="img-thumbnail" style={{height:'150px' ,width:'150px'}} /></label>
                    </Col>
                    <Col xs="12" sm="8" md="9" lg="10">
                        <h1 className='title_name pt-3 pb-2'>{state?.name ?? '-'}</h1>
                        <label>{state?.employee_number ?? '-' }</label>
                    </Col>
                </Row>
                <Row className='information_table'>
                    <Col lg="2" className='pt-2 bg_title border-left d-none d-lg-block'>
                        <div className='information' id="tooltipempdepartment">
                            <UncontrolledTooltip target="tooltipempdepartment">
                                {IMLocalized('department')}
                            </UncontrolledTooltip>
                            <i className='fas fa-building fa-black pr-2' />
                            <small>{state?.contract?.department ?? '-'}</small>
                        </div>
                        <div className='information' id="tooltipempjobtitle">
                            <UncontrolledTooltip target="tooltipempjobtitle">
                                {IMLocalized('job_title')}
                            </UncontrolledTooltip>
                            <i className='fas fa-address-card fa-black pr-2' />
                            <small>{state?.contract?.job_title ?? '-'}</small>
                        </div>
                        <div className="information" id="tooltipempjoblevel">
                            <UncontrolledTooltip target="tooltipempjoblevel" delay={0} placement="bottom">
                                {IMLocalized('job_level')}
                            </UncontrolledTooltip>
                            <i className="fas fa-address-book fa-black pr-2"></i>
                            <small>{state?.contract?.job_level}</small>
                        </div>
                        <hr className='m-2' />
                        <div className="information" id="tooltipempcontactnumber">
                            <UncontrolledTooltip target="tooltipempcontactnumber" delay={0} placement="bottom">
                            {IMLocalized('contact_number')}
                            </UncontrolledTooltip>
                            <i className="fas fa-mobile-alt fa-black pr-2"></i>
                            <small> {state?.contact_number}</small>
                        </div>
                        <hr className="m-2"/>
                        <div className="information">
                            <h5 className="m-0">{IMLocalized('join_date')}</h5>
                            <small className="pl-2">{state?.join_date ? getDateFormat(state.join_date) : '-'}</small>
                        </div>
                        <hr className='m-2' />
                    </Col>
                    <Col lg="10" className="border-right border-left">
                        <Nav tabs>
                            <NavItem className='cursor-pointer'>
                                <NavLink className={classnames({active:props.activeTab === '1'})} onClick={()=>toggle('1')}>
                                    {IMLocalized('profile')}
                                </NavLink>
                            </NavItem>
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({ active:props.activeTab === '2' })} onClick={()=>toggle('2')}>
                                    {IMLocalized('contract')}
                                </NavLink>
                            </NavItem>
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({ active:props.activeTab === '3' })} 
                                onClick={()=>toggle('3')}>
                                    {IMLocalized('family_contact')}
                                </NavLink>
                            </NavItem>
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({ active:props.activeTab === '4' })} 
                                onClick={()=>toggle('4')}>
                                    {IMLocalized('bank')}
                                </NavLink>
                            </NavItem>
                            {process.env.REACT_APP_LEAVE === 'true' &&
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({active:props.activeTab === '5'})}
                                onClick={()=>toggle('5')}>
                                    {IMLocalized('leave')}
                                </NavLink>
                            </NavItem>}
                            {process.env.REACT_APP_CLAIM === 'true' &&
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({active:props.activeTab === '6'})}
                                onClick={()=>toggle('6')}>
                                    {IMLocalized('claim')}
                                </NavLink>
                            </NavItem>}
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({ active:props.activeTab === '7' })} 
                                onClick={()=>toggle('7')}>
                                    {IMLocalized('payroll')}
                                </NavLink>
                            </NavItem>
                            {state?.nationality !=='SG' && state?.is_pr === false &&
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({ active: props.activeTab === '8' })} 
                                onClick={()=>toggle('8')}>
                                    {IMLocalized('workpermit')}
                                </NavLink>
                            </NavItem>}
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({ active: props.activeTab === '9' })} 
                                onClick={()=>toggle('9')}>
                                    {IMLocalized('education')}
                                </NavLink>
                            </NavItem>
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({ active: props.activeTab === '10' })} 
                                onClick={()=>toggle('10')}>
                                    {IMLocalized('certification')}
                                </NavLink>
                            </NavItem>
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({ active: props.activeTab === '11' })} 
                                onClick={()=>toggle('11')}>
                                    {IMLocalized('otp')}
                                </NavLink>
                            </NavItem>
                            {state?.is_manager === true &&
                            <NavItem className="cursor-pointer">
                                <NavLink
                                className={classnames({ active: props.activeTab === '12' })} 
                                onClick={()=>toggle('12')}>
                                    {IMLocalized('delegation')}
                                </NavLink>
                            </NavItem>}
                        </Nav>
                        <TabContent activeTab={props.activeTab}>
                            <TabPane tabId="1">
                                <Profile/>
                            </TabPane>
                            <TabPane tabId="2">
                                <Contract id={state?.id} />
                            </TabPane>
                            <TabPane tabId="3">
                                <Family/>
                            </TabPane>
                            <TabPane tabId="4">
                                <Bank  />
                            </TabPane>
                            {process.env.REACT_APP_LEAVE && 
                            <TabPane tabId="5">
                                <Leave toLeaveDetail={props.toLeaveDetail} />
                            </TabPane>}
                            {process.env.REACT_APP_CLAIM &&
                            <TabPane tabId="6">
                                <Claim toClaimDetail={props.toClaimDetail} id={state?.id} />
                            </TabPane>}
                            <TabPane tabId="7">
                                <Payroll  toPayrollDetail={props.toPayrollDetail} />
                            </TabPane>
                            {state?.nationality !== 'SG' && state?.is_pr === false &&
                            <TabPane tabId="8">
                                <Permit />
                            </TabPane>}
                            <TabPane tabId="9">
                                <Education/>
                            </TabPane>
                            <TabPane tabId="10">
                                <Certification/>
                            </TabPane>
                            <TabPane tabId="11">
                                <Token/>
                            </TabPane>
                            {state?.is_manager === true &&
                            <TabPane tabId="12">
                                <Delegation/>
                            </TabPane>}
                        </TabContent>
                    </Col>
                </Row>
            </Container>
            <Input className="d-none" type='file' id="imgupload" accept="image/*" onChange={(e)=>onUpdatePicture2(e)} ></Input>
            <Input className='d-none' type='file' id="newimg" accept="image/*" onChange={(e)=>onUpdatePicture(e)} ></Input>
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(EmployeeProfileDetail);
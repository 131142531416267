import myaxios from '../axios';

export const GETLEAVEOPTION_REQUEST = 'GETLEAVEOPTION_REQUEST';
export const GETLEAVEOPTION_SUCCESS = 'GETLEAVEOPTION_SUCCESS';
export const GETLEAVEOPTION_FAILURE = 'GETLEAVEOPTION_FAILURE';

export const get_leave_option = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETLEAVEOPTION_REQUEST'
        });

        myaxios.get('leave/basesetting/')
        .then((response)=>{
            dispatch({
                type:'GETLEAVEOPTION_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETLEAVEOPTION_FAILURE',
                payload:error
            })
        })
    }
}
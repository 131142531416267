import React from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import '../../../css/table.css';


export default function ModalViewAppraisalDetail({toggle,closeModal,detail}){

    return(
        <Modal isOpen={toggle} size="lg">
            {detail &&
            <>
            <ModalHeader toggle={closeModal}>{IMLocalized('view')} {detail.name} - {IMLocalized('question')} </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        {detail.questions.length === 0 ? <div className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></div>:
                        <Table size="sm" className='align-items-center table-bordered'>
                            <thead className='thead-light'>
                                <tr>
                                    <th>{IMLocalized('question')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {detail.questions.map((item,index)=>{
                                    return(
                                        <tr key={index}>
                                            <td className='comment-td'>
                                                <div className='comment-td-detail'>{index +1}. {item.title}</div> 
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        }
                    </Col>
                </Row>
            </ModalBody>
            </>}
            <ModalFooter>
                <button className='btn btn-danger btn-sm' onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const DELJOBTITLE_REQUEST = 'DELJOBTITLE_REQUEST';
export const DELJOBTITLE_FAILURE = 'DELJOBTITLE_FAILURE';
export const DELJOBTITLE_SUCCESS = 'DELJOBTITLE_SUCCESS';
export const DELJOBTITLE_RESET = 'DELJOBTITLE_RESET';

export const delJobtitle = (id)=>{
    return dispatch =>{
        dispatch({
            type: 'DELJOBTITLE_REQUEST'
        });
        
        myaxios.delete(`company/jobtitle/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELJOBTITLE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error) =>{
            dispatch({
                type: 'DELJOBTITLE_FAILURE',
                payload: error,
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_jobtitle = () => dispatch =>{
    dispatch({
        type:'DELJOBTITLE_RESET'
    });
}




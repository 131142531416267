import * as companyvalidate from '../../action/wizard/company_validate';

const initialState={
    isLoading:false,
    errors:null,
    data:null,
}

const company_validate = ( state= initialState,action)=>{
    switch(action.type){
        case companyvalidate.COMPANYVALIDATE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case companyvalidate.COMPANYVALIDATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case companyvalidate.COMPANYVALIDATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        default :
        return state;
    }
}
export default company_validate;
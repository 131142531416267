import React, { useContext, useEffect, useState } from 'react';
import {connect} from 'react-redux';
import {get_payroll_list} from '../../action/payroll/get_payroll_list';
import { Container, Row, Col, CardBody, ListGroupItem, ListGroup, Card, CardHeader, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {get_payroll_available_date} from '../../action/payroll/get_payroll_available_date';
import moment from 'moment';
import { IMLocalized } from '../../language/IMLocalized';
import OverlayLoading from '../loading_component/overlay_loading';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-theme-balham.css'; 
import '../../css/aggrid.css';
import { submit_payroll_resign } from '../../action/payroll/submit_payroll_resign';
import { get_employee_resign_today } from '../../action/employee/get_employee_resign_today';
import ModalRate from './modal/ModalRate';
import ModalRateCustom from './modal/ModalRateCustom';
import { getDateFormat } from '../../func/getDateFormat';
import { usePrevious } from '../../hook/usePrevious';
import { popError } from '../../func/popError';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';
import PayrollListAggrid from './PayrollListAggrid';

const mapStateToProps=(state)=>({
    payroll_list:state.get_payroll_list.data,
    isLoading:state.get_payroll_list.isLoading,
    after:state.get_payroll_available_date.after,
    resignlist:state.get_employee_resign_today.data,
    emplist:state.get_employee_list.data,
    isLoading2:state.get_employee_resign_today.isLoading,
    isLoading1:state.submit_payroll_resign.isLoading,
    submit_errors:state.submit_payroll_resign.errors,
    submit_success:state.submit_payroll_resign.data,
    create_success:state.create_new_payroll.data,
})

const mapDispatchToProps=(dispatch)=>({
    get_payroll_list:()=>{
        dispatch(get_payroll_list())
    },
    get_payroll_available_date:()=>{
        dispatch(get_payroll_available_date())
    },
    submit_payroll_resign:(payroll_date,employees)=>{
        dispatch(submit_payroll_resign(payroll_date,employees))
    },
    get_employee_resign_today:()=>{
        dispatch(get_employee_resign_today())
    }
})

const gridOptions={
    defaultColDef:{
        resizable:true,
        sortable:true
    }
}

const initialState={
    additional_toggle:false

}

function PayrollList(props){
    const [state,setState] = useState(initialState);
    const {submit_errors,submit_success,get_employee_resign_today,create_success,get_payroll_list,get_payroll_available_date} = props;
    const [new_item,setNewItem] = useState(null);
    const [payroll_type, setNewPayrollType] = useState(null)
    const [create_toggle,setCreateToggle] = useState(false);
    const [rowData,setRowData] = useState([]);
    const [idlist,setIDList] = useState([]);
    const { addItem } = useContext(NewMainContext);
    const [createCustom_toggle,setCreateCustomToggle] = useState(false);
    const {payroll_id } = useContext(NewMainContext);

    const prevSubmitSuccess = usePrevious(submit_success);
    const prevSubmitErrors = usePrevious(submit_errors);
    const prevCreateSuccess = usePrevious(create_success);

    useEffect(()=>{
        if(prevSubmitErrors !== undefined && submit_errors !== prevSubmitErrors && submit_errors !== null){
            if(submit_errors.hasOwnProperty('payroll')){
                popError(IMLocalized('payroll_created'))
            }
            else if(submit_errors.hasOwnProperty('non_field_errors')){
                popError(submit_errors['non_field_errors'].toString());
            }
            else if(submit_errors.hasOwnProperty('employees')){
                popError(IMLocalized(submit_errors['employees'].toString()));
            }
        }
    },[prevSubmitErrors,submit_errors])

    useEffect(()=>{
        if(prevSubmitSuccess !== undefined && prevSubmitSuccess !== submit_success && submit_success !== null){
            get_employee_resign_today();
        }
    },[prevSubmitSuccess,submit_success,get_employee_resign_today])

    useEffect(()=>{
        if(prevCreateSuccess !== undefined && prevCreateSuccess !== create_success && create_success !== null){
            setCreateToggle(false);
            setCreateCustomToggle(false);
            get_payroll_list();
            get_payroll_available_date();
        }
    },[prevCreateSuccess,create_success,get_payroll_list,get_payroll_available_date])

    const monthNames=[IMLocalized('january'), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
    IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")]


    useEffect(()=>{
        if(props.resignlist.length !== 0 && props.emplist.length !== 0){
            const arr = props.resignlist.map((item,index)=>{
                const emp = item[0];
                const date = item[1];
                const resign_date = getDateFormat(date);
                const emp_detail = props.emplist.find((item)=>item.id === emp);
                return({name:emp_detail.name,number:emp_detail.employee_number,resign_date});
            })

            const idlist = props.resignlist.map((item)=>{
                return item[0];
            })

            setIDList(idlist);
            setRowData(arr);
        }
        else{
            setRowData([]);
            setIDList([]);
        }
    },[props.resignlist,props.emplist,props.list])
    
        const sysCurrent = new Date();
        const sysDate = `${sysCurrent.getFullYear()}-0${sysCurrent.getMonth()+1}-01`
    
        let dateArr = [props.payroll_list.map((x=>x.payroll_date))]
        let exists = false
        if(dateArr[0].includes(sysDate)){
            exists = true
            
        }
        else {
            exists= false
        }

    return(
       <>
       {/* {props.isLoading || props.isLoading2 ? <OverlayLoading/> : */}
       <>
        <Container fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title:IMLocalized('payroll_list')}
                    ]}/>
                </Col>
            </Row>
        </Container>
        
        <Container>

        {props.isLoading2 ? <OverlayLoading/> :

            // {rowData.length !== 0 &&
            <>
            <div className='d-flex align-items-center justify-content-between mt-2'>
                <h3 className='mb-0'>{IMLocalized('resign_employee')}</h3>
                {props.isLoading1 &&  <button className="btn btn-danger btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>}
                {!props.isLoading1 && <button className='btn btn-danger btn-sm'
                onClick={()=>{
                    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
                    props.submit_payroll_resign(startOfMonth,idlist);
                }}
                >{IMLocalized('process_resign')}</button>}
            </div>
            <div className='ag-theme-balham mt-2' style={{height:'300px', width:'100%' }}>
            <AgGridReact
                columnDefs={[
                    {
                        headerName:IMLocalized('employee_number'),
                        field:'number'
                    },
                    {
                        headerName:IMLocalized('name'),
                        field:'name'
                    },
                    {
                        headerName:IMLocalized('resign_date'),
                        field:'resign_date'
                    }
                ]}
                gridOptions={gridOptions}
                rowData={rowData}
                suppressDragLeaveHidesColumns={true}
                suppressSizeToFit={true}
                suppressColumnMoveAnimation={false}
                overlayNoRowsTemplate = {IMLocalized('no_data')}
                />
            </div>
            </>
            // }
            }

        
        {props.isLoading ? <OverlayLoading/> :
            <Card className='mt-2'>

                <CardHeader className='d-flex align-items-center justify-content-between'>
                    <h4 className="mb-0">{IMLocalized('payroll_list')}</h4>
                    {props.after !== 0 &&
                    <div className='d-flex'>
                        {props.after.map((item,index)=>{
                            const month = new Date(item).getMonth();
                            const after_month = monthNames[month];
                            const after_year = new Date(item).getFullYear();
                            const today = moment().startOf('month').format('YYYY-MM-DD');
                            if(index === 0 && today === item){
                                if(index <2){
                                    return(
                                        <button key={index} onClick={()=>{
                                            setNewItem(item);
                                            setNewPayrollType('MONTH')
                                            setCreateToggle(true);
                                        }} className="btn btn-primary btn-sm">{after_month} {after_year}</button>
                                    );
                                }
                            }
                            else{
                                if(index<1){
                                    return(<button key={index} onClick={()=>{
                                        setNewItem(item);
                                        setNewPayrollType('MONTH')
                                        setCreateToggle(true);
                                    }} className="btn btn-primary btn-sm">{after_month} {after_year}</button>);
                                }
                            }
                            return false;
                        })}

                    {(exists===false)?
                    <Dropdown size="sm" direction='left' toggle={()=>setState(prevState=>({...prevState,additional_toggle:!state.additional_toggle}))} isOpen={state.additional_toggle}>
                        <DropdownToggle>
                            {IMLocalized('more_action')} <i className="fas fa-ellipsis-v"/>
                        </DropdownToggle>
                        <DropdownMenu>
                        <DropdownItem id="option" onClick={()=>{
                        setNewItem(sysDate);
                        setNewPayrollType('BONUS')
                        setCreateToggle(true);
                        }} 
                        className="d-flex justify-content-center"
                        >{IMLocalized('Bonus ')}{sysDate}
                        </DropdownItem>
                        <DropdownItem divider/>
                        <DropdownItem onClick={()=>{
                        setCreateCustomToggle(true)}} >{IMLocalized('create_custom_payroll')}
                        </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>:
                    <Dropdown size="sm" direction='bottom' toggle={()=>setState(prevState=>({...prevState,additional_toggle:!state.additional_toggle}))} isOpen={state.additional_toggle}>
                        <DropdownToggle>
                            {IMLocalized('more_action')} <i className="fas fa-ellipsis-v"/>
                        </DropdownToggle>
                        <DropdownMenu>
                        <DropdownItem>
                        <div className="text-danger text-sm d-flex justify-content-center">*{IMLocalized('payroll_exists')}</div>
                        </DropdownItem>
                        <DropdownItem divider/>
                        <DropdownItem onClick={()=>{
                        setCreateCustomToggle(true)}} >{IMLocalized('create_custom_payroll')}
                        </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>         
                    }
                    </div>
                    }
                </CardHeader>
                {props.isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>}
                {!props.isLoading && props.payroll_list.length === 0 &&  <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody> }
                {!props.isLoading && props.payroll_list.length !== 0 && 
                <ListGroup className="list scrollbarstyle1" flush  style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 280px)'}}>
                    {/* {props.payroll_list.map((item,index)=>{

                        const month1 = new Date(item.payroll_date).getMonth();
                        const month = monthNames[month1];
                        const year = new Date(item.payroll_date).getFullYear();
                        const duration = `(${getDateFormat(item.start_date)} -  ${getDateFormat(item.end_date)})`;
                        return(
                            <ListGroupItem className='py-2 d-flex justify-content-between align-items-center' key={index}>
                                <div className='d-flex'>
                                    {item.type==="BONUS"?
                                    <span className='d-flex'><h4 className='mb-0 mr-2'>Bonus {month} {year}</h4> ({getDateFormat(item.created_date)})</span>:
                                    <span className='d-flex'><h4 className='mb-0 mr-2'>{month} {year}</h4> {duration} </span>}
                                </div>
                                <button className='btn btn-info btn-sm' 
                                onClick={()=>{
                                    if(item.status === 'PAID'){
                                        props.setDetail({step:'2',id:item.id,status:item.status});
                                    }
                                    else{
                                        props.setDetail({step:'1',id:item.id,status:item.status});
                                    }
                                    localStorage.setItem('payroll_status',item.status);

                                }}>{IMLocalized('info')}</button>
                            </ListGroupItem>
                        )
                    })} */}
                    <ListGroupItem>
                        <PayrollListAggrid 
                            setDetail={props.setDetail} 
                            is_edit={state.is_edit} 
                            id={payroll_id} 
                            setEdit={(value)=>setState(prevState=>({...prevState,is_edit:value}))} 
                            toPayrollPayslip={props.toPayrollPayslip} 
                            payroll_list={props.payroll_list}
                        />
                    </ListGroupItem>
                </ListGroup>
                }
            </Card>
}
        </Container>
        <ModalRate toggle={create_toggle} item={new_item} type={payroll_type}
        closeModal={()=>{
            setNewItem(null);
            setNewPayrollType(null);
            setCreateToggle(false);
        }} />
        <ModalRateCustom toggle={createCustom_toggle} type={payroll_type}
        closeModal={()=>{
            setNewItem(null);
            setNewPayrollType(null);
            setCreateCustomToggle(false);
        }} />
        </>
       </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(PayrollList);
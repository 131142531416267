import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const ASSIGNCLAIMAPPROVAL_REQUEST = 'ASSIGNCLAIMAPPROVAL_REQUEST';
export const ASSIGNCLAIMAPPROVAL_SUCCESS = 'ASSIGNCLAIMAPPROVAL_SUCCESS';
export const ASSIGNCLAIMAPPROVAL_FAILURE = 'ASSIGNCLAIMAPPROVAL_FAILURE';
export const ASSIGNCLAIMAPPROVAL_RESET = 'ASSIGNCLAIMAPPROVAL_RESET';

export const assign_claim_approval = (employee,start_date,approval_group,approval_master)=>{
    return dispatch=>{
        dispatch({
            type:'ASSIGNCLAIMAPPROVAL_REQUEST'
        });

        myaxios.post('claim/employeeapproval/',{
            start_date:start_date,
            employee:employee,
            approval_group:approval_group,
            approval_master:approval_master
        })
        .then((response)=>{
            dispatch({
                type:'ASSIGNCLAIMAPPROVAL_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('assign_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'ASSIGNCLAIMAPPROVAL_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('assign_failure')
            });
        })
    }
}

export const reset_assign_claim_approval = () => dispatch =>{
    dispatch({
        type:'ASSIGNCLAIMAPPROVAL_RESET'
    })
}
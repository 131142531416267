import * as pay_period from '../action/update_pay_period';

const initialState={
    isLoading:false,
    errors:[],
    data:[]
}

const update_pay_period = (state=initialState,action)=>{
    switch(action.type){
        case pay_period.UPDATEPAYPERIOD_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case pay_period.UPDATEPAYPERIOD_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
            }
        case pay_period.UPDATEPAYPERIOD_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default update_pay_period;
import * as assignscheme from '../../action/leave/leave_assign_scheme';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const leave_assign_scheme =(state=initialState,action)=>{
    switch(action.type){
        case assignscheme.LEAVEASSIGNSCHEME_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case assignscheme.LEAVEASSIGNSCHEME_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case assignscheme.LEAVEASSIGNSCHEME_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default leave_assign_scheme;
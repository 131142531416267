import React from 'react';
import { Modal ,ModalBody,ModalFooter,ModalHeader,Row,Col,Table } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { useSelector } from 'react-redux';
import { getDateFormat } from '../../../../func/getDateFormat';

export default function ModalViewContract({ detail, toggle, closeModal }){

    const contract_typelist = useSelector(state=>state.get_contract_template_dropdown.contract_type);
    const contract_ratelist = useSelector(state=>state.get_contract_template_dropdown.contract_rate);
    const schedulelist = useSelector(state=>state.schedule_list.data);


    let type = '---';
    let rate = '---';
    let schedule = '---';
    if(detail){
        const item1 = contract_typelist.find((item)=>item.value === detail.contract_type);
        if(item1){
            type = item1.display;
        }

        const item2 = contract_ratelist.find((item)=>item.value === detail.contract_rate);
        if(item2){
            rate = item2.display;
        }

        const item3 = schedulelist.find((item)=>item.id === detail.wschedule);
        if(item3){
            schedule = item3.name;
        }
    }

    return(
        <Modal size="lg" isOpen={toggle} className="modal-dialog-centered">
            <ModalHeader toggle={closeModal}>{IMLocalized('view_contract')}</ModalHeader>
            <ModalBody>
                {detail &&
                <Row>
                    <Col lg="12">
                        <div>
                            <h4>{IMLocalized('contract_detail')}</h4>
                        </div>
                        <Table size="sm" className="table-bordered">
                            <tbody>
                                <tr>
                                    <td className="w-25">{IMLocalized('start_date')}</td>
                                    <td>{getDateFormat(detail.start_date)}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('contract_type')}</td>
                                    <td>{type}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('contract_rate')}</td>
                                    <td>{rate}</td>
                                </tr>
                                {detail.contract_rate === 'MONTHLY' && 
                                <tr>
                                    <td className="w-25">{IMLocalized('basic_pay')}</td>
                                    <td>$ {detail.pay}</td>
                                </tr>
                                }
                                <tr>
                                    <td className="w-25">{IMLocalized('is_workman')}</td>
                                    <td>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" id={'hrempisworkman'} className="custom-control-input" name="is_workman" checked={detail.is_workman} readOnly/>
                                            <label className="custom-control-label" htmlFor={'hrempisworkman'}></label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('schedule')}</td>
                                    <td>{schedule}</td>
                                </tr>
                                {detail.contract_rate === 'HOURLY' &&
                                <>
                                <tr>
                                    <td className="w-25">{IMLocalized('hourly_rate')}</td>
                                    <td>{detail.hourly_rate}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('hours_per_day')}</td>
                                    <td>{detail.hours_day}</td>
                                </tr>
                                </>
                                }
                                {detail.contract_rate === 'DAILY' &&
                                <tr>
                                    <td className="w-25">{IMLocalized('daily_rate')}</td>
                                    <td>{detail.daily_rate}</td>
                                </tr>}
                                {detail.contract_rate === 'MONTHLY' &&
                                <>
                                    <tr>
                                        <td className="w-25">{IMLocalized('hourly_rate')}</td>
                                        <td>{detail.hourly_rate}</td>
                                    </tr>
                                    <tr>
                                        <td className="w-25">{IMLocalized('daily_rate')}</td>
                                        <td>{detail.daily_rate}</td>
                                    </tr>
                                </>}
                                <tr>
                                    <td className="w-25">{IMLocalized('foreign_currency')}</td>
                                    <td>{detail?.currency?.currency_name ?? '---'}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('department')}</td>
                                    <td>{detail?.department ?? '---'}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('section')}</td>
                                    <td>{detail?.section ?? '---'}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('cost_center')}</td>
                                    <td>{detail?.cost_center ?? '---'}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('job_title')}</td>
                                    <td>{detail?.job_title ?? '---'}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('job_level')}</td>
                                    <td>{detail?.job_level ?? '---'}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('allowance')}</td>
                                    <td>
                                        {detail.allowances.length > 0 ?
                                        detail.allowances.map((item)=>{
                                            return <div><span className='mr-2'>●</span>{item}</div>
                                        }): '---'}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('recurring_allowance')}</td>
                                    <td>
                                        {detail.recurring_allowances.length > 0 ?
                                        detail.recurring_allowances.map((item)=>{
                                            return <div><span className='mr-2'>●</span>{item}</div>
                                        }): '---'}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('note')}</td>
                                    <td>{detail.notes.length !== 0 ? detail.notes : '---'} </td>
                                </tr>
                            </tbody>
                        </Table>
                        {detail.payinc.length > 0 &&
                        <>
                        <div><h4>{IMLocalized('increment')}</h4></div>
                        <Table size="sm" className="table-bordered">
                            <thead>
                                <tr>
                                    <td>{IMLocalized('effective_date')}</td>
                                    <td>{IMLocalized('old_amount')}</td>
                                    <td>{IMLocalized('new_amount')}</td>
                                    <td>{IMLocalized('amount')}</td>
                                    <td>{IMLocalized('percentage')} (%)</td>
                                </tr>
                            </thead>
                            <tbody>
                                {detail.payinc.map((item)=>{
                                    return(
                                        <tr>
                                            <td>
                                                {getDateFormat(item.effective_date)}
                                            </td>
                                            <td>{item.old_amount}</td>
                                            <td>{item.new_amount}</td>
                                            <td>{item.amount}</td>
                                            <td>{item.percent}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        </>    
                        }
                    </Col>
                </Row>
                }
            </ModalBody>
            <ModalFooter>
                <button onClick={closeModal}  className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const DELRECURRING_REQUEST = 'DELRECURRING_REQUEST';
export const DELRECURRING_FAILURE = 'DELRECURRING_FAILURE';
export const DELRECURRING_SUCCESS = 'DELRECURRING_SUCCESS';
export const DELRECURRING_RESET = 'DELRECURRING_RESET';

export const delRecurring = (id)=>{
    return dispatch =>{
        dispatch({
            type: 'DELRECURRING_REQUEST'
        });
        
        myaxios.delete(`company/recurring_allowance/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELRECURRING_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error) =>{
            dispatch({
                type: 'DELRECURRING_FAILURE',
                payload: error,
                error:true
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_recurring = () => dispatch=>{
    dispatch({
        type:'DELRECURRING_RESET'
    });
}
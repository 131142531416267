import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const REQUEST_DEFAULTBANK = 'REQUEST_DEFAULTBANK';
export const GET_DEFAULTBANK = 'GET_DEFAULTBANK';
export const FAILURE_DEFAULTBANK = 'FAILURE_DEFAULTBANK';
export const RESET_DEFAULTBANK = 'RESET_DEFAULTBANK';

export const setdefault_employee_bank = (id)=>{
    return dispatch =>{
        dispatch({
            type: 'REQUEST_DEFAULTBANK'
        });
        myaxios.get(`employee/bank/${id}/setdefault/`)
        .then((response)=>{
            dispatch({
                type: 'GET_DEFAULTBANK',
                payload:response 
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error) =>{
            dispatch({
                type: 'FAILURE_DEFAULTBANK',
                payload: error,
                error:true
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}

export const reset_setdefault_employee_bank = () => dispatch =>{
    dispatch({
        type:'RESET_DEFAULTBANK'
    });
}
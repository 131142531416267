import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const MASSASSIGNLEAVEAPPROVAL_REQUEST = 'MASSASSIGNLEAVEAPPROVAL_REQUEST';
export const MASSASSIGNLEAVEAPPROVAL_SUCCESS = 'MASSASSIGNLEAVEAPPROVAL_SUCCESS';
export const MASSASSIGNLEAVEAPPROVAL_FAILURE = 'MASSASSIGNLEAVEAPPROVAL_FAILURE';


export const mass_assign_leave_approval = (data)=>{
    return dispatch=>{
        dispatch({
            type:'MASSASSIGNLEAVEAPPROVAL_REQUEST'
        });

        myaxios.post('leave/employeeapproval/array_create/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'MASSASSIGNLEAVEAPPROVAL_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('assign_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'MASSASSIGNLEAVEAPPROVAL_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('assign_failure')
            });
        })
    }
}
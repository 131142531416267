import * as leave_hr_approve from '../../action/hr_calendar/array_approve_leave';

const initialState={
    errors:null,
    isLoading:false,
    data:null
}

const array_approve_leave = (state=initialState,action)=>{
    switch(action.type){
        case leave_hr_approve.LEAVEHRAPPROVE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case leave_hr_approve.LEAVEHRAPPROVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case leave_hr_approve.LEAVEHRAPPROVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case leave_hr_approve.LEAVEHRAPPROVE_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default array_approve_leave;
import * as login from '../../action/iras/get_iras_login';


const initialState={
    data:null,
    errors:null,
    isLoading:false
}


const get_iras_login = (state=initialState,action)=>{
    switch(action.type){
        case login.GETIRASLOGIN_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case login.GETIRASLOGIN_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case login.GETIRASLOGIN_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case login.GETIRASLOGIN_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state
    }
}
export default get_iras_login;
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Col, FormFeedback, FormGroup, Modal, Input, UncontrolledTooltip, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import { create_claim } from '../../../action/claim/create_claim';
import Swal from 'sweetalert2';


const mapStateToProps = (state)=>({
    isLoading:state.create_claim.isLoading,
    success:state.create_claim.data,
    errors:state.create_claim.errors,
})

const mapDispatchToProps = (dispatch)=>({
    create_claim:(code,name,medical,minimum_images,maximum_images)=>{
        dispatch(create_claim(code,name,medical,minimum_images,maximum_images))
    },
})

function usePrevious(value) {
   
    const ref = useRef();
    
    useEffect(() => {
      ref.current = value;
    }, [value]); 
    return ref.current;
}

function popError(msg){
    Swal.fire({
        title:msg,
        type:'warning',
        confirmButtonColor:'#d33',
        confirmButtonText:IMLocalized('ok!')
    })
}

function ModalCreateClaim(props){
    const {success,errors,onSuccess} = props;
    const [code,setCode] = useState('');
    const [err_code,setErrCode] = useState('');
    const [name,setName] = useState('');
    const [err_name,setErrName] = useState('');
    const [photo,setPhoto] = useState(false);
    const [medical,setMedical] = useState(false);
    const [min_image,setMin] = useState(0);
    const [max_image,setMax] = useState(0);
    const [err_min_image,setErrMin] = useState('');
    const [err_max_image,setErrMax] = useState('');
    const prevSuccess = usePrevious(success);
    const prevErrors =  usePrevious(errors);

    useEffect(()=>{
        if(props.toggle){
            setCode('');
            setErrCode('');
            setName('');
            setErrName('');
            setPhoto(false);
            setMedical(false);
            setMin(0);
            setMax(0);
            setErrMin('');
            setErrMax('');
        }
    },[props.toggle])

    useEffect(()=>{
        if(props.toggle && prevSuccess !== undefined && prevSuccess !== success && success !== null){
            onSuccess();
        }
    },[prevSuccess,success,props.toggle,onSuccess])

    useEffect(()=>{
        if(props.toggle && prevErrors !== errors && errors !== null && prevErrors !== undefined){
            if('code' in errors){
                const word = errors['code'].toString();
                setErrCode(word);
            }
            if('name' in errors){
                const word = errors['name'].toString();
                setErrName(word);
            }
            if('maximum_images' in errors){
                const word = errors['maximum_images'].toString();
                setErrMax(word);
            }
            if('minimum_images' in errors){
                const word = errors['minimum_images'].toString();
                setErrMin(word);
            }
            if('non_field_errors' in errors){
                const word = errors['non_field_errors'].toString();
                popError(word);
            }
        }
    },[prevErrors,errors,props.toggle])

    const onCreate=()=>{
        let invalid = false;
        let min =0;
        let max = 0;
        if(name !== '' && code !== ''){
            if(photo === true){
                if(min_image === '' || max_image === ''){
                    invalid = true;
                    if(min_image === ''){
                        setErrMin(IMLocalized('minimum_image_is_required'));
                    }

                    if(max_image === ''){
                        setErrMax(IMLocalized('maximum_image_is_required'));
                    }
                }
                else if(parseInt(min_image) > parseInt(max_image)){
                    invalid = true;
                    setErrMax(IMLocalized('max_cannot_less_min'));
                }
                else{
                    min = min_image;
                    max = max_image;
                }
            }
            else{
                min = 0;
                max = 0;
            }

            if(!invalid){
                props.create_claim(code,name,medical,min,max)
            }
        }
        else{
            if(name === ''){
                setErrName(IMLocalized('name_is_required'));
            }
            if(code === ''){
                setErrCode(IMLocalized('code_is_required'));
            }
        }
    }
    return(
        <Modal isOpen={props.toggle} size="lg">
            <ModalHeader toggle={props.closeModal}>{IMLocalized('new_claim_type')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='code'>{IMLocalized('code')}</label>
                            <Input size="sm" type="text" name="code" id="code" value={code} onChange={(e)=>{setCode(e.target.value); setErrCode('')}} invalid={err_code.length >0} />
                            <FormFeedback>{err_code}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>{IMLocalized('name')}</label>
                            <Input size="sm" type="text" name="name" value={name} onChange={(e)=>{setName(e.target.value); setErrName('')}} invalid={err_name.length >0}  />
                            <FormFeedback>{err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <div className='custom-control custom-checkbox'>
                            <input type="checkbox" id="create_claim_require_photo" name="photo" className='custom-control-input' checked={photo} onChange={(e)=>setPhoto(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="create_claim_require_photo">{IMLocalized('require_to_upload_photo')}</label>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className='custom-control custom-checkbox'>
                            <input type="checkbox" id="create_claim_is_medical" name="medical" className='custom-control-input' checked={medical} onChange={(e)=>setMedical(e.target.checked)} />
                            <label className="custom-control-label" id="tooltip_is_medical" htmlFor="create_claim_is_medical">{IMLocalized('is_medical_claim')}
                                <UncontrolledTooltip delay={0} placement="top" target="tooltip_is_medical">
                                    {IMLocalized('is_medical_claim_tooltip1')}
                                </UncontrolledTooltip>
                            </label>
                        </div>
                    </Col>
                    {photo && 
                    <>
                    <Col lg="6" className="pt-2">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='min_image'>{IMLocalized('minimum_image')}</label>
                            <Input type="number" id="min_image" min="0" max="10" size="sm" value={min_image} onChange={(e)=>{setMin(e.target.value); setErrMin('')}} invalid={err_min_image.length >0} />
                            <FormFeedback>{err_min_image}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6" className="pt-2">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='max_image'>{IMLocalized('maximum_image')}</label>
                            <Input type="number" id="max_image" min="0" max="10" size="sm" value={max_image} onChange={(e)=>{setMax(e.target.value); setErrMax('')}} invalid={err_max_image.length >0} />
                            <FormFeedback>{err_max_image}</FormFeedback>
                        </FormGroup>
                    </Col>
                    </>
                    }
                </Row>
            </ModalBody>
            <ModalFooter>
                {props.isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                <button onClick={onCreate} className="btn btn-primary btn-sm">{IMLocalized('create')}</button>
                <button className='btn btn-danger btn-sm' onClick={props.closeModal}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalCreateClaim);
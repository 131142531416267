import * as applyleaveself from '../../action/user/user_apply_leave_self';

const initialsState={
    data:null,
    isLoading:false,
    errors:null
}

const user_apply_leave_self = (state=initialsState,action)=>{
    switch(action.type){
        case applyleaveself.SELFAPPLYLEAVE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case applyleaveself.SELFAPPLYLEAVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case applyleaveself.SELFAPPLYLEAVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case applyleaveself.SELFAPPLYLEAVE_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default user_apply_leave_self;
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';


const mapStateToProps=(state)=>({
    list:state.get_payroll_instance.data,
    paymentlist:state.dropdown_list.payment,
})

const mapDispatchToProps=(dispatch)=>({

})

function ModalPayoutDetail(props){
    const [list,setList] = useState([]);

    useEffect(()=>{
        if(props.toggle && props.id !== null && props.list){
            const {payout} = props.list[0];
            const detail = payout.find((item)=>item.id === props.id);
            setList(detail ? detail.payouts : []);
        }
        else{
            setList([]);
        }
    },[props.toggle,props.id,props.list])

    return(
        <Modal size="lg" isOpen={props.toggle}>
            <ModalHeader toggle={props.closeModal}>{IMLocalized('payout_detail')}</ModalHeader>
            <ModalBody>
                {list.length === 0 && <span>{IMLocalized('no_data')}</span>}
                {list.length !== 0 &&
                <Table className='align-items-center table-bordered' size="sm" responsive>
                    <thead className='thead-light'>
                        <tr>
                            <th>{IMLocalized('name')}</th>
                            <th>{IMLocalized('payment_type')}</th>
                            <th>{IMLocalized('amount')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((item,index)=>{

                            const payment_detail = props.paymentlist.find((item1)=>item1.value === item.payment);

                            return(
                                <tr key={index}>
                                    <td>{item.employee_name}</td>
                                    <td>{payment_detail ? payment_detail.display : '-'}</td>
                                    <td>${item.amount}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                }
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-danger btn-sm" onClick={props.closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalPayoutDetail);
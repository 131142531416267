import * as add from '../action/additional_setting';

const initialState ={
    data:[],
    error:{},
    allowance:[],
    overtime:[],
    section:[],
    department:[],
    costcenter:[],
    joblevel:[],
    jobtitle:[],
    recurringallowance:[],
    flatallowance:[],
    isLoading:false
}


const additional_setting = (state=initialState,action) =>{
    switch(action.type) {
        case add.ADDITIONALLIST_REQUEST:
            return{
                ...state,
                isLoading: true,
                error:{}
            }
        case add.ADDITIONALLIST_SUCCESS:
            return{
                ...state,
            data: action.payload.data,
            allowance:action.payload.data.allowance,
            section:action.payload.data.section,
            department:action.payload.data.department,
            costcenter:action.payload.data.costcenter,
            overtime:action.payload.data.overtime,
            joblevel:action.payload.data.joblevel,
            jobtitle:action.payload.data.jobtitle,
            recurringallowance:action.payload.data.recurring_allowance,
            flatallowance:action.payload.data.flat_allowance,
            error:{},
            isLoading:false
        }
        case add.ADDITIONALLIST_FAILURE:
            return{
                ...state,
                error:{},
                isLoading:false
            }
            default:
                    return state;
    }
}

export default additional_setting;
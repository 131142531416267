import React, { useContext, useEffect, useState } from 'react';
import { Row,Col,Container,CardBody,Card,Breadcrumb,BreadcrumbItem, CardHeader, Input, FormFeedback, FormGroup} from 'reactstrap';
import { useDispatch, useSelector} from 'react-redux';
import { IMLocalized} from '../../language/IMLocalized';
import { create_appraisal_list, create_appraisal_list_reset} from '../../action/appraisal/create_appraisal_list';
import ModalAddAppraisalQuestion from './modal/ModalAddAppraisalQuestion';
import ModalEditAppraisalQuestion from './modal/ModalEditAppraisalQuestion';
import { popError } from '../../func/popError';
import { get_appraisal_form_list } from '../../action/appraisal/get_appraisal_form_list';
import { NewMainContext } from '../../context/NewMainContext';

function AppraisalCreate(){

    const { addItem, setAppraisalListStep } = useContext(NewMainContext);
    const [name, setName] = useState('');
    const [err_name, setErrName] = useState('');
    const [question, setQuestion] = useState([]);
    const [add_toggle, setAddToggle] = useState(false);
    const [count, setCount]= useState(0);
    const [edit_toggle, setEditToggle] = useState(false);
    const [edit_detail, setEditDetail ] = useState(null);

    const dispatch = useDispatch();
    const create_success = useSelector(state=>state.create_appraisal_list.data);
    const create_errors = useSelector(state=>state.create_appraisal_list.errors);

    useEffect(()=>{
        if( create_success != null){
            setAppraisalListStep(0);
            dispatch(get_appraisal_form_list());
            dispatch(create_appraisal_list_reset());
        }
    },[create_success,dispatch,setAppraisalListStep])


    useEffect(()=>{
        if( create_errors != null){
            if(create_errors.hasOwnProperty('name')){
                const word = create_errors['name'].toString();
                setErrName(word);
            }
            if(create_errors.hasOwnProperty('questions')){
                popError(IMLocalized('question_is_required'));
            }
            dispatch(create_appraisal_list_reset());
        }
    },[create_errors,dispatch])


    const addQuestion=(data)=>{
        const name = `question${count}`;
        const item = {...data,name};
        setQuestion(prevState=>[...prevState,item]);
        setAddToggle(false);
        setCount(count+1);
    }

    const updateQuestion=(data)=>{
        let ques = [...question];
        ques[data.index] = data;
        setQuestion(ques);
        setEditDetail(null);
        setEditToggle(false);
    }

    const addAppraisal=()=>{
        if(name !== '' && question.length !== 0){
            dispatch(create_appraisal_list(name,'APPRAISAL',question));
        }
        else{
            if(name === '') setErrName(IMLocalized('name_is_required'));
            if(question.length === 0) popError(IMLocalized('question_is_required'))
        }
    }

    return(
        <>
        <Container fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                        <BreadcrumbItem><a href="/#" onClick={()=>addItem('1','dashboard')}>{IMLocalized('home')}</a></BreadcrumbItem>
                        <BreadcrumbItem><a href="/#" onClick={()=>setAppraisalListStep(0)}>{IMLocalized('appraisal_form_list')}</a></BreadcrumbItem>
                        <BreadcrumbItem active>{IMLocalized('create')}</BreadcrumbItem>
                    </Breadcrumb>
                    <div className='ml-2'>
                        <button className="btn btn-primary btn-sm" onClick={()=>setAppraisalListStep(0)}> <i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                        <button className="btn btn-primary btn-sm" onClick={()=>addAppraisal()}>{IMLocalized('create')}</button>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className='mt-2'>
                <Col>
                    <Card>
                        <CardBody>
                            <FormGroup>
                                <label className='form-control-label' htmlFor='name'>{IMLocalized('appraisal_name')}</label>
                                <Input size="sm" id="name" value={name} invalid={err_name.length >0} 
                                onChange={(e)=>{
                                    const value = e.target.value;
                                    setName(value);
                                    setErrName('');
                                }} />
                                <FormFeedback>{err_name}</FormFeedback>
                            </FormGroup>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader className='d-flex justify-content-between align-items-center'>
                            <h4 className='mb-0'>{IMLocalized('question')}</h4>
                            <button className='btn btn-primary btn-sm' onClick={()=>setAddToggle(true)}>{IMLocalized('add')}</button>
                        </CardHeader>
                        {question.length === 0 &&  <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                        {question.length !== 0 && 
                        <CardBody>
                            {question.map((item,index)=>{
                                return(
                                    <>
                                    <div key={index}>
                                        <div>
                                            <span>{index +1}. {item.title}</span>
                                        </div>
                                        <div className='d-flex justify-content-end'>
                                            <button className='btn btn-info btn-sm' onClick={()=>{setEditDetail({...item,index});setEditToggle(true)}}>{IMLocalized('edit')}</button>
                                            <button className='btn btn-danger btn-sm'
                                            onClick={()=>{
                                                const data = [...question];
                                                data.splice(index,1);
                                                setQuestion(data);
                                            }}>{IMLocalized('delete')}</button>
                                        </div>
                                    </div>
                                    <hr className='my-1 mx-0 '/>
                                    </>
                                )
                            })}
                        </CardBody>}
                    </Card>
                </Col>
            </Row>
        </Container>
        <ModalAddAppraisalQuestion toggle={add_toggle} closeModal={()=>setAddToggle(false)} addQuestion={(data)=>addQuestion(data)} />
        <ModalEditAppraisalQuestion toggle={edit_toggle} closeModal={()=>{setEditToggle(false); setEditDetail(null) }} detail={edit_detail} updateQuestion={updateQuestion} />
        </>
    )
}
export default AppraisalCreate;
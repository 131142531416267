import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const EMPRELATIONARRAYSAVE_REQUEST = 'EMPRELATIONARRAYSAVE_REQUEST';
export const EMPRELATIONARRAYSAVE_SUCCESS = 'EMPRELATIONARRAYSAVE_SUCCESS';
export const EMPRELATIONARRAYSAVE_FAILURE = 'EMPRELATIONARRAYSAVE_FAILURE';


export const employee_relationship_array_save =(data)=>{
    return dispatch=>{
        dispatch({
            type:'EMPRELATIONARRAYSAVE_REQUEST'
        });

        myaxios.post('employee/relationship/array_save/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'EMPRELATIONARRAYSAVE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'EMPRELATIONARRAYSAVE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}
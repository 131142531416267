import * as auth12 from '../action/auth1';

const initialState={
    isLoading:false,
    data:'',
    errors:[],
    fail:''
}

const auth1 = (state=initialState,action)=>{
    switch(action.type){
        case auth12.TOKEN_REQUEST1:
            return{
                ...state,
                fail:''
            }
        case auth12.TOKEN_FAILURE1:
            return{
                ...state,
                fail:'401',
            }
        default:
            return state;
    }
}
export default auth1;
import React from "react";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import { IMLocalized } from "../../../../language/IMLocalized";



export default function FamilyTable({ list, setViewToggle, setEditContactToggle, deleteFamily}){

    const relationshiplist = useSelector(state=>state.dropdown_list.user_profile_relationship);

    return(
        <Table size="sm" className="mt-2 align-items-center table-bordered" responsive>
            <thead className="thead-light text-center">
                <tr>
                    <th className="w-50">{IMLocalized('name')}</th>
                    <th className="w-25">{IMLocalized('relation')}</th>
                    <th>{IMLocalized('view')} / {IMLocalized('edit')} / {IMLocalized('delete')}</th>
                </tr>
            </thead>
            <tbody className="text-center">
                {list.map((item,index)=>{
                    const detail = relationshiplist.find(element => element.value === item.relation);
                    return(
                        <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{detail?.display ?? '-'}</td>
                            <td align='center' valign='center'>
                                <div className='d-flex justify-content-center'>
                                    <div className="primary_btn d-flex align-items-center justify-content-center">
                                        <i className="far fa-eye text-primary cursor-pointer" onClick={()=>setViewToggle(index)}  />
                                    </div>
                                    <div className="primary_btn d-flex align-items-center justify-content-center">
                                        <i className="fas fa-pen text-primary cursor-pointer" onClick={()=>setEditContactToggle(index)}  />
                                    </div>
                                    <div className="delete_btn d-flex align-items-center justify-content-center">
                                        <i className="fas fa-trash text-red cursor-pointer" onClick={()=>deleteFamily(item.id)} />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}
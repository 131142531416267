import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const UPDATELEAVE_REQUEST = 'UPDATELEAVE_REQUEST';
export const UPDATELEAVE_SUCCESS = 'UPDATELEAVE_SUCCESS';
export const UPDATELEAVE_FAILURE = 'UPDATELEAVE_FAILURE';
export const UPDATELEAVE_RESET = 'UPDATELEAVE_RESET';

export const update_leave =(id,code,name,leave_type,can_user_apply,deductable,earned_can_negative,minimum_images,maximum_images,year_initialization,is_npl,is_proration)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATELEAVE_REQUEST'
        });
        
        myaxios.patch(`leave/setting/${id}/`,{
            code:code,
            name:name,
            leave_type:leave_type,
            can_user_apply:can_user_apply,
            deductable:deductable,
            earned_can_negative:earned_can_negative,
            minimum_images:minimum_images,
            maximum_images:maximum_images,
            year_initialization:year_initialization,
            is_npl:is_npl,
            proration:is_proration
        })
        .then((response)=>{
            dispatch({
                type:'UPDATELEAVE_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type:'success',
                title:IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATELEAVE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}

export const reset_update_leave = () => dispatch =>{
    dispatch({
        type:'UPDATELEAVE_RESET'
    });
}
import * as empcontract from '../../action/employee/create_employee_contract';


const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const create_employee_contract = (state=initialState,action)=>{
    switch(action.type){
        case empcontract.NEW_CREATECONTRACT_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case empcontract.NEW_CREATECONTRACT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case empcontract.NEW_CREATECONTRACT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default create_employee_contract;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { reset_setdefault_employee_bank} from '../../../action/employee/setdefault_employee_bank';
import { delete_employee_bank, reset_delete_employee_bank} from '../../../action/employee/delete_employee_bank';
import { get_employee_bank} from '../../../action/employee/get_employee_bank';
import { IMLocalized} from '../../../language/IMLocalized';
import ModalCreateBank from './modal/ModalCreateBank';
import ModalEditBank from './modal/ModalEditBank';
import { emp_get_employee_bank } from '../../../reducers/employee/get_employee_bank';
import LoadingTable from './component/LoadingTable';
import BankTable from './component/BankTable';
import NoDataTable from './component/NoDataTable';

export default function EmployeeBank({ id }){
    
    const list = useSelector(state=>emp_get_employee_bank(state,parseInt(id)));
    const isLoading = useSelector(state=>state.get_employee_bank.isLoading);
    
    const delete_success = useSelector(state=>state.delete_employee_bank.data);
    const default_success = useSelector(state=>state.setdefault_employee_bank.data);
    const dispatch = useDispatch();
    const [add_toggle,setAddToggle] = useState(false);
    const [edit_toggle,setEditToggle] = useState(false);
    const [edit_detail,setEditDetail] = useState(null);

    useEffect(()=>{
        if(default_success != null){
            dispatch(get_employee_bank());
            dispatch(reset_setdefault_employee_bank());
        }
    },[default_success,dispatch])


    useEffect(()=>{
        if(delete_success != null){
            dispatch(get_employee_bank());
            dispatch(reset_delete_employee_bank());
        }
    },[delete_success,dispatch])

    const editBank =(id)=>{
        const toggle = !edit_toggle;
        if(toggle){
            const detail = list.find((item)=>item.id === id);
            setEditDetail(detail);
        }
        else{
            setEditDetail(null);
        }

        setEditToggle(toggle);
    }

    const removeBank = (id)=>{
        Swal.fire({
            title:IMLocalized('delete_bank_confirmation'),
            text:IMLocalized('revert_msg'),
            type:'warning',
            showCancelButton:true,
            cancelButtonColor:'#3085d6',
            confirmButtonColor:'#d33',
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!'),
        })
        .then((result) =>{
            if(result.value){
                dispatch(delete_employee_bank(id));
            }
        })
    }

    return(
        <>
            <div className="d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title" >
                <div className="d-flex align-items-center">
                    <i className="fas fa-university text-primary" />
                    <h4 className="text-primary m-0 pl-2">{IMLocalized('employee_bank')}</h4>
                </div>
                <div className="text-primary pt-2 pr-2 cursor-pointer" onClick={()=>setAddToggle(true)}>
                    <i className="fas fa-plus-circle mr-1 function_icon"/>
                    <small>{IMLocalized('add_bank')}</small>
                </div>
            </div>
            {isLoading && <LoadingTable/>}
            {!isLoading && list.length === 0 && <NoDataTable/>}
            {!isLoading && list.length !== 0 && <BankTable list={list} editBank={editBank} removeBank={removeBank} />}
            <ModalCreateBank toggle={add_toggle} id={id} closeModal={()=>setAddToggle(false)} onSuccess={()=>{ setAddToggle(false);get_employee_bank()}} />
            <ModalEditBank toggle={edit_toggle} detail={edit_detail} closeModal={()=>{setEditToggle(false);setEditDetail(null) }} onSuccess={()=>{setEditToggle(false);get_employee_bank()}} />
        </>
    )
}
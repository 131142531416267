import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import Swal from "sweetalert2";
import { delete_leave, reset_delete_leave } from "../../action/delete_leave";
import { get_leave_list } from "../../action/get_leave_list";
import { NewMainContext } from "../../context/NewMainContext";
import { IMLocalized } from "../../language/IMLocalized";
import BreadCrumbList from "../breadcrumb/BreadCrumbList";
import ModalCreateLeaveType from "./Modal/ModalCreateLeaveType";
import ModalEditLeaveType from "./Modal/ModalEditLeaveType";
import ModalViewLeaveType from "./Modal/ModalViewLeaveType";



export default function LeaveLeaveList(){

    const { addItem } = useContext(NewMainContext);
    const list = useSelector(state=>state.get_leave_list.data);
    const delete_success = useSelector(state=>state.delete_leave.data);
    const dispatch = useDispatch();
    const [ view_id , setViewId] = useState(null);
    const [ view_toggle, setViewToggle] = useState(false);
    const [ create_toggle, setCreateToggle] = useState(false);
    const [ edit_toggle, setEditToggle] = useState(false);
    const [ edit_id, setEditId ] = useState(null);

    useEffect(()=>{
        if(delete_success != null){
            dispatch(reset_delete_leave());
            dispatch(get_leave_list());
        }
    },[delete_success,dispatch])

    const viewLeaveType=(id)=>{
        setViewId(id);
        setViewToggle(true);
    }

    const editLeave=(id)=>{
        setEditId(id);
        setEditToggle(true);
    }

    const deleteLeave =(id,name)=>{
        Swal.fire({
            type:"warning",
            title:`${IMLocalized('delete_leave_confirmation')} (` + name +') !',
            showCancelButton:true,
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!')
        })
        .then((result)=>{
            if(result.value){
                dispatch(delete_leave(id));
            }
        })
    }

    const statutorylist = list.filter(item=>item.fixed === true).sort((a,b)=> a.id - b.id);
    const userlist = list.filter(item=>item.fixed === false).sort((a,b)=> a.id - b.id);
    
    return(
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title:IMLocalized('leave_list')}
                    ]}
                    />
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className="mt-2">
                <Col>
                    <Card>
                        <CardHeader>
                            <h4 className="mb-0">{IMLocalized('statutory_leave')}</h4>
                        </CardHeader>
                        {statutorylist.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                        {statutorylist.length !== 0 && 
                        <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 250px)'}}>
                            {statutorylist.map((item,index)=>{
                                const number = index +1;
                                return(
                                    <ListGroupItem className="py-2" key={item.id}>
                                        <Row className="align-items-center">
                                            <Col>
                                                <span>{number}. <span className="cursor-pointer text-primary" onClick={()=>viewLeaveType(item.id)}>{item.name}</span></span>
                                            </Col>
                                            <Col className="col-auto mr-1">
                                                <button className="btn btn-danger btn-sm" onClick={()=>deleteLeave(item.id,item.name)}>{IMLocalized('delete')}</button>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>}
                    </Card>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col className="d-flex align-items-center">
                                    <h4 className="mb-0">{IMLocalized('user_define_leave')}</h4>
                                </Col>
                                <Col className="col-auto">
                                    <button className="btn btn-primary btn-sm" onClick={()=>setCreateToggle(true)}>{IMLocalized('create')}</button>
                                </Col>
                            </Row>
                        </CardHeader>
                        {userlist.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                        {userlist.length !== 0 && 
                        <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 250px)'}}>
                            {userlist.map((item,index)=>{
                                const number = index +1;
                                return(
                                    <ListGroupItem className="py-2" key={item.id}>
                                        <Row className="align-items-center">
                                            <Col>
                                                <span>{number}. <span className="cursor-pointer text-primary" onClick={()=>viewLeaveType(item.id)}>{item.name}</span></span>
                                            </Col>
                                            <Col className="col-auto mr-1">
                                                <button className="btn btn-info btn-sm" onClick={()=>editLeave(item.id) } >{IMLocalized('edit')}</button>
                                                <button className="btn btn-danger btn-sm" onClick={()=>deleteLeave(item.id,item.name)}>{IMLocalized('delete')}</button>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>}
                    </Card>
                </Col>
            </Row>
        </Container>
        <ModalViewLeaveType toggle={view_toggle} id={view_id} closeModal={()=>{ setViewId(null); setViewToggle(false) }} />
        <ModalCreateLeaveType toggle={create_toggle} closeModal={()=>setCreateToggle(false)} />
        <ModalEditLeaveType toggle={edit_toggle} closeModal={()=>{ setEditId(null); setEditToggle(false) }} edit_id={edit_id} />
        </>
    )
}
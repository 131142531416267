import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const ADDEMPROLE_REQUEST = 'ADDEMPROLE_REQUEST'; 
export const ADDEMPROLE_SUCCESS = 'ADDEMPROLE_SUCCESS';
export const ADDEMPROLE_FAILURE = 'ADDEMPROLE_FAILURE';
export const ADDEMPROLE_RESET = 'ADDEMPROLE_RESET';

export const add_employee_role = (id,password,role_hr,role_leave_officer,role_claim_officer,role_manager)=>{
    return dispatch=>{
        dispatch({
            type:'ADDEMPROLE_REQUEST'
        });

        myaxios.post(`employee/users/${id}/add/`,{
            password:password,
            role_hr:role_hr,
            role_claim_officer:role_claim_officer,
            role_leave_officer:role_leave_officer,
            role_manager:role_manager
        })
        .then((response)=>{
            dispatch({
                type:'ADDEMPROLE_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('add_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'ADDEMPROLE_FAILURE',
                payload:error
            });
            ErrToast.fire({
                type:'error',
                title:IMLocalized('add_failure')
            });
        })
    }
}

export const reset_add_employee_role = () => dispatch =>{
    dispatch({
        type:'ADDEMPROLE_RESET'
    })
}
import myaxios from '../../axios';

export const USERHOLIDAYLIST_REQUEST = 'USERHOLIDAYLIST_REQUEST';
export const USERHOLIDAYLIST_SUCCESS = 'USERHOLIDAYLIST_SUCCESS';
export const USERHOLIDAYLIST_FAILURE = 'USERHOLIDAYLIST_FAILURE';

export const user_holiday_list =()=>{
    return dispatch =>{
        dispatch({
            type: 'USERHOLIDAYLIST_REQUEST'
        });
        myaxios.get('leave/holiday/')
        .then((response)=>{
            dispatch({
                type:'USERHOLIDAYLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'USERHOLIDAYLIST_FAILURE',
                payload:error,
                error:true
            })
        })
    }
}
import React from "react";
import { Table } from "reactstrap";
import { getDateFormat } from "../../../../func/getDateFormat";
import { IMLocalized } from "../../../../language/IMLocalized";


export default function MaternityTable({ list, createChildrenToggle }){

    return(
        <Table size="sm" className="mt-2 table-bordered align-items-center" responsive>
            <thead className="thead-light text-center">
                <tr>
                    <th>{IMLocalized('estimated_delivery_date')}</th>
                    <th>{IMLocalized('birth_times')}</th>
                    <th>{IMLocalized('flexible_leave')}</th>
                    <th>{IMLocalized('weeks_awarded')}</th>
                    <th>{IMLocalized('weeks_to_paternal')}</th>
                    <th>{IMLocalized('add_children')}</th>
                </tr>
            </thead>
            <tbody className="text-center">
                {list.map((item)=>{
                    return(
                        <tr key={item.id}>
                            <td>{getDateFormat(item.estimated_delivery_date)}</td>
                            <td>{item.birth_times}</td>
                            <td className="p-2">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" id={'flexible'+item.id} className="custom-control-input" name="flexible_leave" checked={item.flexible_leave} readOnly/>
                                    <label className="custom-control-label" htmlFor={'flexible'+item.id}></label>
                                </div>
                            </td>
                            <td>{item.weeks_awarded}</td>
                            <td>{item.weeks_to_paternal}</td>
                            <td align="center" valign="center">
                                <div className="primary_btn d-flex align-items-center justify-content-center">
                                    <i className="fas fa-plus-circle text-primary cursor-pointer"  onClick={()=>createChildrenToggle(item.id)}  />
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import { create_new_iras } from '../../../action/iras/create_new_iras';
import moment from 'moment';

const mapStateToProps=(state)=>({
    companylist:state.company_list.data,
    isLoading:state.create_new_iras.isLoading,
})

const mapDispatchToProps=(dispatch)=>({
    create_new_iras:(newIrasCompany,employerName1,year,authPerson,authPersonDesignation,telephone,authPersonEmail,batch)=>{
        dispatch(create_new_iras(newIrasCompany,employerName1,year,authPerson,authPersonDesignation,telephone,authPersonEmail,batch))
    },
})


function PopupIras(props){
    const {EmployerName,company,BasisYear,AuthorisedPersonName,AuthorisedPersonDesignation,Telephone,AuthorisedPersonEmail,BatchDate,jobtitlelist} = props
    const [newIrasCompany, setNewIrasCompany] = useState('')
    const [year, setYear] = useState('')
    const [authPerson, setAuthPerson] = useState('')
    const [authPersonDesignation, setAuthPersonDesignation] = useState('')
    const [telephone, setTelephone] = useState('')
    const [authPersonEmail, setAuthPersonEmail] = useState('')
    const [batch, setBatch] = useState(moment(new Date()).format('YYYY-MM-DD'));
    
    // useEffect(()=>{
    //     if(props.toggle && props.currencylist.length !== 0){
    //         const arr = props.currencylist.map((item,index)=>{
    //             const name = item.currency_name;
    //             const amount = 0;
    //             return {name,amount};
    //         })

    //         setList(arr);
    //     }
    //     else{
    //         setList([]);
    //     }
    // },[props.currencylist,props.toggle])

    // const validateInput=(e,i)=>{
    //     const target = e.target;
    //     const value = target.value;
    //     if(value === ''){
    //         const list1 = [...list];
    //         list1[i].amount = 0;
    //         setList(list1);
    //     }
    // }

    const onCreate=()=>{
        // let rates = null;
        // if(list.length !== 0){
        //     let arr = {};
        //     list.forEach((item)=>{
        //         const name = item.name;
        //         const amount = item.amount;
        //         arr[name] = amount;
        //     })

        //     rates = arr;
        // }
        const [employerName] = props.companylist.filter(e=>e.id === Number(newIrasCompany)).map(f=>f.name)
        // console.log(newIrasCompany,year,authPerson,authPersonDesignation,employerName,telephone,authPersonEmail,batch)

        props.create_new_iras(newIrasCompany,year,authPerson,authPersonDesignation,employerName,telephone,authPersonEmail,batch);

    }



    const currentYear = new Date().getFullYear();
    const previousYear1 =  currentYear-1;
    const previousYear2 =  currentYear-2;
    const previousYear3 =  currentYear-3;

    const years = [currentYear, previousYear1, previousYear2, previousYear3]

    return(
        <Modal isOpen={props.toggle} size="lg">
            <ModalHeader toggle={props.isLoading ? null:props.closeModal}>{IMLocalized('create_new_iras')}</ModalHeader>
            <ModalBody>
                <div>{IMLocalized('company')}</div>
                <Input size="sm" type="select" id="leave_option_first_month_proration_option" value={company} 
                onChange={(e)=>{
                    setNewIrasCompany(e.target.value);
                }}>
                <option></option>
                {props.companylist.map(item=>{
                    return <option value={item.id} key={item.id}>{item.name}</option>
                })}
                </Input>
                <div className="d-flex justify-content-center pt-3">
                </div>

                <div>{IMLocalized('year')}</div>
                <Input size="sm" type="select"
                onChange={(e)=>{
                    const selectedYear = Number(e.target.value);
                    setYear(selectedYear);
                }}>
                <option></option>
                {years.map(item=>{
                    return <option value={item} key={item}>{item}</option>
                })}
                </Input>
                <div className="d-flex justify-content-center pt-3">
                </div>

                {/* NEW */}
                <div>{IMLocalized('auth_person_name')}</div>
                <Input size="sm" id="" value={AuthorisedPersonName} 
                onChange={(e)=>{
                    setAuthPerson(e.target.value);
                }}>
                </Input>
                <div className="d-flex justify-content-center pt-3">
                </div>

                <div>{IMLocalized('auth_person_designation')}</div>
                <Input size="sm" 
                onChange={(e)=>{
                    setAuthPersonDesignation(e.target.value);
                }}>
                <option></option>
                {jobtitlelist.map((item,index)=>{
                    return <option value={item.name} key={index}>{item.name}</option>
                })}
                </Input>
                <div className="d-flex justify-content-center pt-3">
                </div>

                <div>{IMLocalized('telephone')}</div>
                <Input size="sm"
                onChange={(e)=>{
                    setTelephone(e.target.value);
                }}>
                </Input>
                <div className="d-flex justify-content-center pt-3">
                </div>

                <div>{IMLocalized('auth_person_email')}</div>
                <Input size="sm"
                onChange={(e)=>{
                    setAuthPersonEmail(e.target.value);
                }}>
                </Input>
                <div className="d-flex justify-content-center pt-3">
                </div>
                
                <div>{IMLocalized('file_creation_date')}</div>
                <Input size="sm" name="file_creation_date" id="file_creation_date" type="date" onChange={(e)=>{setBatch(e.target.value)}} defaultValue={moment(new Date()).format('YYYY-MM-DD')} max={moment(new Date()).format('YYYY-MM-DD')}/>


            </ModalBody>
            <ModalFooter>
                {props.isLoading ?
                <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button onClick={onCreate} className="btn btn-primary btn-sm">{IMLocalized('create')}</button>
                    <button onClick={props.closeModal} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(PopupIras)
import React,{useContext } from 'react';
import '../../../css/employee_profile.css';
import PayrollDetail from './UserPayrollDetail';
import LeaveDetail from './UserLeaveDetail';
import ClaimDetail from './UserClaimDetail';
import ProfileDetail from './EmployeeProfileDetail';
import { NewMainContext } from '../../../context/NewMainContext';

function EmployeeProfile(props){

    const { addItem, user_step, user_payroll_id, user_leave_id, user_claim_id, user_employee_step, setUserStep, setUserPayrollId, setUserLeaveId, setUserClaimId, setUserEmployeeStep} = useContext(NewMainContext);

    const toHome=()=>{
        addItem('1','dashboard');
    }

    const toPayrollDetail=(id)=>{
        setUserEmployeeStep('1');
        setUserPayrollId(id);
        localStorage.setItem('user_emp_step','1');
        localStorage.setItem('user_payroll_id',id);
    }

    const toProfile=()=>{
        setUserEmployeeStep('0');
        localStorage.setItem('user_emp_step','0');
    }

    const toLeaveDetail=(id)=>{
        setUserEmployeeStep('2');
        setUserLeaveId(id);
        localStorage.setItem('user_emp_step','2');
        localStorage.setItem('user_leave_id',id);
    }

    const toClaimDetail=(id)=>{
        setUserEmployeeStep('3');
        setUserClaimId(id);
        localStorage.setItem('user_emp_step','2');
        localStorage.setItem('user_claim_id',id);
    }

    return(
        <>
        {user_employee_step === '0' && <ProfileDetail toHome={toHome} toPayrollDetail={toPayrollDetail} toLeaveDetail={toLeaveDetail} toClaimDetail={toClaimDetail} activeTab={user_step} setActiveTab={(id)=>setUserStep(id)} />}
        {user_employee_step === '1' && <PayrollDetail toPayrollDetail={toPayrollDetail} toHome={toHome} toProfile={toProfile} payroll_id={user_payroll_id} /> }
        {user_employee_step === '2' && <LeaveDetail toHome={toHome} leave_id={user_leave_id} toProfile={toProfile}   />}
        {user_employee_step === '3' && <ClaimDetail toHome={toHome} claim_id={user_claim_id} toProfile={toProfile}  />}
        </>
    )
}
export default EmployeeProfile;
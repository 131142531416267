import * as update_option from '../../action/claim/update_claim_option';


const initialState={
    data:null,
    isLoading:false,
    errors:null
}

const update_claim_option = (state=initialState,action)=>{
    switch(action.type){
        case update_option.UPDATECLAIMOPTION_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case update_option.UPDATECLAIMOPTION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case update_option.UPDATECLAIMOPTION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case update_option.UPDATECLAIMOPTION_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default update_claim_option;
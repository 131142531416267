import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { IMLocalized } from '../../language/IMLocalized';
import { Button } from 'reactstrap';
import ModalEmpApplyClaim from '../employee/hr_employee/modal/ModalEmpApplyClaim';
import { NewMainContext } from '../../context/NewMainContext';
import { reset_payroll_instance } from '../../action/payroll/get_payroll_instance';

const mapStateToProps=(state)=>({
    payroll_list:state.get_payroll_list.data,
})

const mapDispatchToProps=(dispatch)=>({
    reset_payroll_instance:()=>{
        dispatch(reset_payroll_instance())
    }
})

function FunctionalBtn(props){

    const [claim_toggle,setClaimToggle] = useState(false);
    const { addItem,setPayrollStep,setPayrollID } = useContext(NewMainContext);

    return(
        <div className='d-sm-none d-md-block d-none mt-2 '>
            <Button color="default" outline size="sm" type="button" 
            onClick={()=>{
                addItem('7','employee_list')
            }}>{IMLocalized('employee_list')}</Button>
            {/* <Button color="default" outline size="sm" type="button" onClick={()=>props.toLeaveCalendar('24','leave_calendar1')} >{IMLocalized('apply_employee_leave')}</Button> */}
            <Button color="default" outline size="sm" type="button" onClick={()=>addItem('24','leave_calendar1')} >{IMLocalized('apply_employee_leave')}</Button>
            <Button color="default" outline size="sm" type="button" onClick={()=>setClaimToggle(true)} >{IMLocalized('apply_employee_claim')}</Button>
            <Button color="default" outline size="sm" type="button" 
            onClick={()=>{
                if(props.payroll_list.length !== 0){
                    reset_payroll_instance();
                    const id = props.payroll_list[0].id;
                    const status = props.payroll_list[0].status;
                    const type = props.payroll_list[0].type;
                    setPayrollStep(status === 'PAID' || status === 'LOCK' ? '2' : '1');
                    setPayrollID(id);
                    localStorage.setItem('payroll_status',status);
                    localStorage.setItem('payroll_type',type);
                    addItem('15','payroll_list');
                }
            }} 
            >{IMLocalized('current_payroll')}</Button>
            <ModalEmpApplyClaim toggle={claim_toggle} closeModal={()=>setClaimToggle(false)} has_emp={false} has_claim={false} />
        </div>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(FunctionalBtn);
import myaxios from '../../axios';

export const COMPUTATIONPAYROLLINSTANCE_REQUEST = 'COMPUTATIONPAYROLLINSTANCE_REQUEST';
export const COMPUTATIONPAYROLLINSTANCE_SUCCESS = 'COMPUTATIONPAYROLLINSTANCE_SUCCESS';
export const COMPUTATIONPAYROLLINSTANCE_FAILURE = 'COMPUTATIONPAYROLLINSTANCE_FAILURE';

export const payroll_computation_payroll_instance = (id) =>{
    return dispatch=>{
        dispatch({
            type: 'COMPUTATIONPAYROLLINSTANCE_REQUEST'
        });

        myaxios.get(`payroll/payroll/${id}/`)
        .then((response)=>{
            dispatch({
                type:'COMPUTATIONPAYROLLINSTANCE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'COMPUTATIONPAYROLLINSTANCE_FAILURE',
                payload:error.response
            })
        })
    }
}
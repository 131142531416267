import * as managerlist from '../../action/leave/manager_leave_list';


const initialState={
    isLoading:false,
    data:[],
    errors:[],
    data1:[]
}

const manager_leave_list = (state=initialState,action)=>{
    switch(action.type){
        case managerlist.MANAGERLEAVELIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:[],
                errors:[]
            }
        case managerlist.MANAGERLEAVELIST_SUCCESS:
            return{
                ...state,
                data:action.payload.data,
                // data:action.approvals,
                // data1:action.payload,
                isLoading:false,
            }
        case managerlist.MANAGERLEAVELIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default manager_leave_list;
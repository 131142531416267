import * as payrollpayout from '../../action/payroll/payroll_basic_payout';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const payroll_basic_payout = (state=initialState,action)=>{
    switch(action.type){
        case payrollpayout.PAYROLLBASICPAYOUT_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case payrollpayout.PAYROLLBASICPAYOUT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case payrollpayout.PAYROLLBASICPAYOUT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        default:
            return state;
    }
}
export default payroll_basic_payout;
import React, { useContext, useEffect, useState } from "react";
import { NewMainContext } from "../../context/NewMainContext";
import ClaimCreateSchemeList from "./ClaimCreateSchemeList";
import ClaimSchemeList from "./ClaimSchemeList";


export default function ClaimScheme(){

    const [ step, setStep ] = useState(0);
    const { claim_scheme_reset, setClaimSchemeReset } = useContext(NewMainContext);

    useEffect(()=>{
        if(claim_scheme_reset === true){
            setClaimSchemeReset(false);
            setStep(0);
        }
    },[claim_scheme_reset,setClaimSchemeReset])

    return(
        <>
        {step === 0 && <ClaimSchemeList setStep={setStep} />}
        {step === 1 && <ClaimCreateSchemeList setStep={setStep} />}
        </>
    )
}
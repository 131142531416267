import * as updateschedule from '../action/update_schedule';


const initialState={
    isLoading:false,
    errors:null,
    data:null
}

const update_schedule = (state=initialState,action)=>{
    switch(action.type){
        case updateschedule.UPDATESCHEDULE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null
            }
        case updateschedule.UPDATESCHEDULE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case updateschedule.UPDATESCHEDULE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default update_schedule;
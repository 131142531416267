import * as holiday_list from '../action/get_holiday_list';

const initialState={
    data:[],
    error:{},
    isLoading:false
}

const holidaylist = (state=initialState,action)=>{
    switch(action.type){
        case holiday_list.HOLIDAYLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                error:{}
            }
        case holiday_list.HOLIDAYLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                error:{},
                data:action.payload.data
            }
        case holiday_list.HOLIDAYLIST_FAILURE:
            return{
                ...state,
                error:{},
                isLoading:false
            }
        default:
            return state;
    }
}
export default holidaylist;
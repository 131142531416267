import myaxios from '../../axios';

export const CALENDARAPPROVALSGETLEAVEPICTURE1_REQUEST = 'CALENDARAPPROVALSGETLEAVEPICTURE1_REQUEST';
export const CALENDARAPPROVALSGETLEAVEPICTURE1_SUCCESS = 'CALENDARAPPROVALSGETLEAVEPICTURE1_SUCCESS';
export const CALENDARAPPROVALSGETLEAVEPICTURE1_FAILURE = 'CALENDARAPPROVALSGETLEAVEPICTURE1_FAILURE';

export const calendar_approvals_get_leave_picture1 =(id)=>{
    return dispatch=>{
        dispatch({
            type:'CALENDARAPPROVALSGETLEAVEPICTURE1_REQUEST'
        });

        myaxios.get(`ob/leave/${id}/`,{responseType:'arraybuffer'})
        .then((response)=>{
            dispatch({
                type:'CALENDARAPPROVALSGETLEAVEPICTURE1_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'CALENDARAPPROVALSGETLEAVEPICTURE1_FAILURE',
                payload:error
            })
        })
    }
}
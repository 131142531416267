import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Table } from "reactstrap";
import { ClaimOfficerEmployeeContext } from "../../../context/ClaimOfficerEmployeeContext";
import { IMLocalized } from "../../../language/IMLocalized";
import { emp_get_employee_available_claim } from "../../../reducers/employee/get_all_employee_available_claim";
import ModalEmpApplyClaim from "../hr_employee/modal/ModalEmpApplyClaim";


export default function EmpClaim(){

    const {toViewAll, toViewDetail, employee_profile_id :id } = useContext(ClaimOfficerEmployeeContext);

    const availablelist = useSelector(state=>emp_get_employee_available_claim(state,id));
    const claimlist = useSelector(state=>state.get_claim_list.data);

    const [ apply_toggle, setApplyToggle] = useState(false);
    const [ apply_id, setApplyID] = useState(null);
    const [ apply_name, setApplyName] = useState('');

    const applyLeave = (claim,name) =>{
        setApplyToggle(true);
        setApplyID(claim);
        setApplyName(name);
    }

    return(
        <>
        <Row className="mt-2">
            <Col>
                <div className="d-flex align-items-center pl-2 emp-tabcontent-title">
                    <i className="fas fa-money-check text-primary" />
                    <h4 className="text-primary pl-2 m-0">{IMLocalized('employee_claim')}</h4>
                </div>
            </Col>
        </Row>
        {availablelist.length === 0 && 
        <div className='p-2'>
            <h4>{IMLocalized('no_claim_scheme_found')}</h4>
            <span>{IMLocalized('please_assign_claim_scheme_for_employee')}</span>
        </div>}
        {availablelist.length !== 0 &&
        <>
        <div className="mt-1 d-flex justify-content-end align-items-center">
            <div className="text-primary cursor-pointer" onClick={()=>toViewAll(id)}>
                <i className="far fa-eye mr-1 function_icon" />
                <small>{IMLocalized('view_all_claim')}</small>
            </div>
        </div>
        <Table className="mt-1 align-items-center table-bordered" size="sm" responsive>
            <thead className="thead-light text-center">
                <tr>
                    <th>{IMLocalized('claim_type')}</th>
                    <th>{IMLocalized('current_month_balance')}</th>
                    <th>{IMLocalized('apply')}</th>
                </tr>
            </thead>
            <tbody className="text-center">
                {availablelist.map((item)=>{
                    const current = new Date().getMonth();
                    const claim_detail = claimlist.find(element => element.id === item.claim_type);

                    return(
                        <tr key={item.id}>
                            <td>
                                <span className="text-primary cursor-pointer" onClick={()=>toViewDetail(id,item.claim_type)}>{claim_detail?.name ?? ''}</span>
                            </td>
                            <td>
                                $ {item.is_monthly ? item.remaining_monthly[current] : item.remaining}
                            </td>
                            <td><button className="btn btn-primary btn-sm" onClick={()=>applyLeave(item.claim_type,claim_detail?.name)}>{IMLocalized('apply')}</button></td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
        <ModalEmpApplyClaim toggle={apply_toggle} has_emp={true} has_claim={true} id={id} claim_type={apply_id} name={apply_name} closeModal={()=>{setApplyToggle(false); setApplyID(null); setApplyName('')}}  />
        </>
        }
        </>
    )
}
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CREATE_IRAS_REQUEST = 'CREATE_IRAS_REQUEST';
export const CREATE_IRAS_SUCCESS = 'CREATE_IRAS_SUCCESS';
export const CREATE_IRAS_FAILURE = 'CREATE_IRAS_FAILURE';


// export const create_new_iras = (newIrasCompany, previousYear)=>{
//     return dispatch=>{
//         dispatch({
//             type:'CREATE_IRAS_REQUEST'
//         });

//         myaxios.post(`payroll/iras/`,{
//         year:previousYear,
//         company:newIrasCompany
//         })
//         .then((response)=>{
//             dispatch({
//                 type:'CREATE_IRAS_SUCCESS',
//                 payload:response
//             });
//             Toast.fire({
//                 type: 'success',
//                 title: IMLocalized('create_success')
//             });

//         })
//         .catch((error)=>{
//             dispatch({
//                 type:'CREATE_IRAS_FAILURE',
//                 payload:error
//             });
//             ErrToast.fire({
//                 type:'error',
//                 title:IMLocalized('create_failure')
//             });
//         })
//     }
// }

export const create_new_iras = (newIrasCompany,year,authPerson,authPersonDesignation,employerName,telephone,authPersonEmail,batch)=>{
    return dispatch=>{
        dispatch({
            type:'CREATE_IRAS_REQUEST'
        });

        myaxios.post(`sg/iras/`,{
        BasisYear:year,
        OrganizationID: "7",
        OrganizationIDNo: "s192837",
        AuthorisedPersonName: authPerson,
        AuthorisedPersonDesignation: authPersonDesignation,
        EmployerName: employerName,
        Telephone: telephone,
        AuthorisedPersonEmail: authPersonEmail,
        BatchDate: batch,
        company: newIrasCompany
        })
        .then((response)=>{
            dispatch({
                type:'CREATE_IRAS_SUCCESS',
                payload:response
            });
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });

        })
        .catch((error)=>{
            dispatch({
                type:'CREATE_IRAS_FAILURE',
                payload:error
            });
            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}
import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const UPDATEGENSETTING_REQUEST = 'UPDATEGENSETTING_REQUEST';
export const UPDATEGENSETTING_SUCCESS = 'UPDATEGENSETTING_SUCCESS';
export const UPDATEGENSETTING_FAILURE = 'UPDATEGENSETTING_FAILURE';
export const UPDATEGENSETTING_RESET = 'UPDATEGENSETTING_RESET';

export const update_general_setting = (default_probation_choice,default_probation_amount,base_currency_name,base_currency_symbol)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEGENSETTING_REQUEST'

        });
        
        myaxios.post('settings/',{
            default_probation_choice:default_probation_choice,
            default_probation_amount:default_probation_amount,
            base_currency_name:base_currency_name,
            base_currency_symbol:base_currency_symbol
        })
        .then((response)=>{
            dispatch({
                type:'UPDATEGENSETTING_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATEGENSETTING_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}

export const reset_update_general_setting = () => dispatch =>{
    dispatch({
        type:'UPDATEGENSETTING_RESET'
    });
}
import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const UPDATE_PAYROLL_ADDITIONAL_SETTING_REQUEST = 'UPDATE_PAYROLL_ADDITIONAL_SETTING_REQUEST';
export const UPDATE_PAYROLL_ADDITIONAL_SETTING_SUCCESS = 'UPDATE_PAYROLL_ADDITIONAL_SETTING_SUCCESS';
export const UPDATE_PAYROLL_ADDITIONAL_SETTING_FAILURE = 'UPDATE_PAYROLL_ADDITIONAL_SETTING_FAILURE';
export const UPDATE_PAYROLL_ADDITIONAL_SETTING_RESET = 'UPDATE_PAYROLL_ADDITIONAL_SETTING_RESET';



export const update_payroll_additional_setting = (id,code,name,payType,wage,fromDate,toDate)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATE_PAYROLL_ADDITIONAL_SETTING_REQUEST'
        })

        myaxios.patch(`payroll/field/${id}/`,{
            code:code,
            name:name,
            pay_type:payType,
            wage:wage,            
            from_date:fromDate,
            to_date:toDate,
        })
        .then((response)=>{
            dispatch({
                type:'UPDATE_PAYROLL_ADDITIONAL_SETTING_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATE_PAYROLL_ADDITIONAL_SETTING_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            })
        })
    }
}

export const reset_pas = () => dispatch =>{
    dispatch({
        type:'UPDATE_PAYROLL_ADDITIONAL_SETTING_RESET'
    });
}
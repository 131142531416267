import * as createschedule from '../../action/wizard/wizard_create_schedule';

const initialState ={
    isLoading:false,
    data:null,
    errors:null
}

const wizard_create_schedule = (state=initialState,action)=>{
    switch(action.type){
        case createschedule.CREATESCHEDULE_REQUEST1:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case createschedule.CREATESCHEDULE_SUCCESS1:
            return{
                ...state,
                isLoading:false,
                errors:{},
                data:action.payload
            }
        case createschedule.CREATESCHEDULE_FAILURE1:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default wizard_create_schedule;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from "reactstrap";
import { get_leave_list } from "../../../action/get_leave_list";
import { reset_update_leave, update_leave } from "../../../action/update_leave";
import { get_leave_list_detail } from "../../../reducers/get_leave_list";
import { IMLocalized } from "../../../language/IMLocalized";




export default function ModalEditLeaveType({ toggle, closeModal, edit_id }){

    const list = useSelector(state=>get_leave_list_detail(state,edit_id));
    const leavetypelist = useSelector(state=>state.dropdown_list.leave_type);
    const initializationlist = useSelector(state=>state.dropdown_list.leave_setting_year_initialization);
    const errors = useSelector(state=>state.update_leave.errors);
    const update_success  = useSelector(state=>state.update_leave.data);
    const isLoading = useSelector(state=>state.update_leave.isLoading);
    const dispatch = useDispatch();

    const [code,setCode] = useState('');
    const [id,setId] = useState('');
    const [err_code,setErrCode] = useState('');
    const [name,setName] = useState('');
    const [err_name,setErrName] = useState('');
    const [fixed,setFixed] = useState(false);
    const [deductable,setDeductable] = useState('');
    const [leave_type,setLeaveType] = useState('');
    const [err_leave_type,setErrLeaveType] = useState('');
    const [year_initialization,setYear] = useState('');
    const [err_year_initialization,setErrYear] = useState('');
    const [photo,setPhoto] = useState(false);
    const [min,setMin] = useState(0);
    const [max,setMax] = useState(0);
    const [err_min,setErrMin] = useState('');
    const [err_max,setErrMax] = useState('');
    const [can_user_apply,setCanUserApply]  = useState(false);
    const [earned_can_negative,setEarned] = useState(false);
    const [is_npl,setNpl] = useState(false);
    const [is_proration,setProration] = useState(false);

    useEffect(()=>{
        if( toggle && list != null ){
            setCode(list.code);
            setErrCode('');
            setName(list.name);
            setErrName('');
            setLeaveType(list.leave_type);
            setErrLeaveType('');
            setCanUserApply(list.can_user_apply);
            setDeductable(list.deductable);
            setEarned(list.earned_can_negative);
            setMin(list.minimum_images);
            setMax(list.maximum_images);
            setYear(list.year_initialization);
            setErrMin('');
            setErrMax('');
            setErrYear('');
            if( list.maximum_images > 0){
                setPhoto(true);
            }
            else{
                setPhoto(false);
            }
            setFixed(list.fixed);
            setNpl(list.is_npl);
            setId(list.id);
            setProration(list.is_proration);
        }
    },[list,toggle])

    useEffect(()=>{
        if(update_success !== null && toggle){
            closeModal();
            dispatch(get_leave_list());
            dispatch(reset_update_leave());
        }
    },[update_success,closeModal,dispatch, toggle])

    useEffect(()=>{
        if( errors !== null && toggle){
            if( errors.hasOwnProperty('code')){
                const word = errors['code'].toString();
                setErrCode(word);
            }

            if(errors.hasOwnProperty('name')){
                const word = errors['name'].toString();
                setErrName(word);
            }

            if(errors.hasOwnProperty('minimum_images')){
                const word = errors['minimum_images'].toString();
                setErrMin(word);
            }
            dispatch(reset_update_leave());
        }
    },[errors,dispatch,toggle])

    const updateLeave=()=>{
        let min1 = min,max1 = max;
        let year_initialization1 = year_initialization;
        let leave_type1 = leave_type;
        if(code !== '' && name !== ''){
            let invalid = false;
            if(photo === true){
                if(min === '' || max === ''){
                    if(min === ''){
                        setErrMin(IMLocalized('minimum_image_is_required'));
                    }
                    if(max === ''){
                        setErrMax(IMLocalized('maximum_image_is_required'));
                    }
                    invalid = true;
                }
                else if (min > max){
                    setErrMax(IMLocalized('max_cannot_less_min'));
                    invalid = true;
                }
            }
            else{
                min1 = 0;
                max1 = 0;
            }

            if(deductable === 'true' || deductable === true){
                if(leave_type === '' || year_initialization === ''){
                    if(leave_type === ''){
                        setErrLeaveType(IMLocalized('leave_type_required'));
                        invalid = true;
                    }

                    if(year_initialization === ''){
                        setErrYear(IMLocalized('year_initialization_is_required'));
                        invalid = true;
                    }
                }
            }
            else{
                year_initialization1 = 'NA';
                leave_type1 = 'F';
            }

            if(!invalid){
                dispatch(update_leave(id,code,name,leave_type1,can_user_apply,deductable,earned_can_negative,min1,max1,year_initialization1,is_npl,is_proration));
            }
        }
        else{
            if(code === ''){
                setErrCode(IMLocalized('leave_code_is_required'));
            }

            if(name === ''){
                setErrName(IMLocalized('leave_name_is_required'));
            }
        }
    }

    return(
        <Modal isOpen={toggle} size="lg">
            {list && 
            <>
            <ModalHeader toggle={closeModal}>{IMLocalized('edit')} - {list.name}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='code'>{IMLocalized('leave_code')}</label>
                            <Input size="sm" type="text" id="code" name="code" value={code} onChange={(e)=>{setCode(e.target.value); setErrCode('')}} invalid={err_code.length>0} readOnly={fixed} />
                            <FormFeedback>{err_code}</FormFeedback>
                            <div>
                                <span className="text-red">*</span><small> {IMLocalized('leave_code_tooltip1')}</small>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='leave_name'>{IMLocalized('leave_name')}</label>
                            <Input size="sm" type="text" id="leave_name" name="name" value={name} onChange={(e)=>{setName(e.target.value); setErrName('')}} invalid={err_name.length>0} readOnly={fixed} />
                            <FormFeedback>{err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='deductible'>
                                {IMLocalized('deductible')}
                            </label>
                            <Input size="sm" name="deductable" id='deductible' type="select" value={deductable} onChange={(e)=>{setDeductable(e.target.value);}}>
                                <option value={false}>{IMLocalized('no')}</option>
                                <option value={true}>{IMLocalized('yes')}</option>
                            </Input>
                            <div>
                                <span className="text-red">*</span><small> {IMLocalized('deductible_tooltip2')}</small>
                            </div>
                            <div>
                                <span className="text-red">*</span><small> {IMLocalized('deductible_tooltip3')}</small>
                            </div>
                        </FormGroup>
                    </Col>
                    {(deductable === true || deductable === 'true') &&
                    <>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='leave_type'>{IMLocalized('leave_type')}</label>
                            <Input disabled={fixed} size="sm" id="leave_type" type="select" name="leave_type" value={leave_type} onChange={(e)=>{setLeaveType(e.target.value); setErrLeaveType('')}} invalid={err_leave_type.length>0} readOnly={fixed} >
                                <option></option>
                                {leavetypelist.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_leave_type}</FormFeedback>
                            <div>
                                <span className="text-red">*</span><small> {IMLocalized('leave_type_tooltip1')}</small>
                            </div>
                            <div>
                                <span className="text-red">*</span><small> {IMLocalized('leave_type_tooltip2')}</small>
                            </div>
                            <div>
                                <span className="text-red">*</span><small> {IMLocalized('leave_type_tooltip3')}</small>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='year_initialization'>{IMLocalized('year_initialization')}</label>
                            <Input disabled={fixed} size="sm" type="select" name="year_initialization" value={year_initialization} onChange={(e)=>{setYear(e.target.value); setErrYear('')}} invalid={err_year_initialization.length>0} readOnly={fixed} >
                                <option></option>
                                {initializationlist.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_year_initialization}</FormFeedback>
                        </FormGroup>
                    </Col>
                    </>}
                </Row>
                <Row>
                    <Col lg="12" className="d-flex align-items-center">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="leave_edit_photo" name='photo' className="custom-control-input" checked={photo} disabled={fixed} onChange={()=>setPhoto(state => !state)}  />
                            <label className="custom-control-label" htmlFor="leave_edit_photo">{IMLocalized('require_to_upload_photo')}</label>
                        </div>
                    </Col>
                    {photo &&
                    <>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='min'>{IMLocalized('minimum_image')}</label>
                            <Input size="sm" type="number" min="0" id="min" name="minimum_images" value={min} onChange={(e)=>{setMin(e.target.value); setErrMin('')}} invalid={err_min.length>0} readOnly={fixed}></Input>
                            <FormFeedback>{err_min}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='max'>{IMLocalized('maximum_image')}</label>
                            <Input size="sm" type="number" min="0" id="max" name="maximum_images" value={max} onChange={(e)=>{setMax(e.target.value); setErrMax('')}} invalid={err_max.length>0} readOnly={fixed} ></Input>
                            <FormFeedback>{err_max}</FormFeedback>
                        </FormGroup>
                    </Col>
                    </>}
                    <Col lg="12">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="leave_edit_can_user_apply" name='can_user_apply' className="custom-control-input" checked={can_user_apply} disabled={fixed} onChange={()=>{setCanUserApply(state => !state )}}  />
                            <label className="custom-control-label" id="tooltip_leave_edit_apply" htmlFor="leave_edit_can_user_apply">
                                {IMLocalized('can_user_apply')}
                                <UncontrolledTooltip delay={0} placement="top" target="tooltip_leave_edit_apply">
                                    {IMLocalized('can_user_apply_tooltip1')}
                                </UncontrolledTooltip>
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="leave_edit_earned_can_negative" name='earned_can_negative' className="custom-control-input" disabled={fixed} checked={earned_can_negative} onChange={()=>setEarned(state=>!state)}/>
                            <label className="custom-control-label" id="tooltip_leave_edit_earned" htmlFor="leave_edit_earned_can_negative">
                                {IMLocalized('earned_can_negative')}
                                <UncontrolledTooltip delay={0} placement="top" target="tooltip_leave_edit_earned" > 
                                    {IMLocalized('earned_can_negative_tooltip1')}
                                </UncontrolledTooltip>
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="leave_edit_is_npl" name='is_npl' className="custom-control-input" checked={is_npl} disabled={fixed} onChange={()=>setNpl(state=>!state)}  />
                            <label className="custom-control-label" htmlFor="leave_edit_is_npl">{IMLocalized('no_pay')}</label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="leave_edit_is_proration" name='is_proration' className="custom-control-input" checked={is_proration} disabled={fixed} onChange={()=>setProration(state=>!state)}  />
                            <label className="custom-control-label" htmlFor="leave_edit_is_proration">{IMLocalized('proration')}?</label>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            </>}
            <ModalFooter>
                {isLoading?
                    <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>
                : !fixed? <div><button onClick={updateLeave} className="btn btn-primary btn-sm">{IMLocalized('update')}</button><button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button></div> :
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button> }
            </ModalFooter>
        </Modal>
    )
}
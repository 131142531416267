import * as wizardcreaterecurring from '../../action/wizard/wizard_create_recurring';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const wizard_create_recurring = (state=initialState,action)=>{
    switch(action.type){
        case wizardcreaterecurring.WIZARDCREATERECURRING_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case wizardcreaterecurring.WIZARDCREATERECURRING_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case wizardcreaterecurring.WIZARDCREATERECURRING_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default wizard_create_recurring;
import React,{Component} from 'react';
import {Col} from 'reactstrap';

class NotFound extends Component {

    componentDidMount(){
        document.title = process.env.REACT_APP_TITLE;
    }

    toMainPage=()=>{
        window.location.href="/#/"
    }
    

    render(){
        const frameStyle = {
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            padding: '40px',
            border: '1px solid #ccc',
            borderRadius: '10px',
            margin: '20px',
          };

        return(
            <div className="page-wrap d-flex flex-row align-items-center login2_main" style={{height:'100vh'}}>
                <div className="container">
                    <div style={frameStyle}>
                        <div className="row justify-content-center">
                            <div className="col-md-12 text-center">
                                <span className="display-1 d-block">Oops... The application is currently under maintenance.</span>
                                <div className="mb-4 lead">Please try again in a while.</div>
                                {/* <button className='btn btn-danger btn-sm'  onClick={this.toMainPage}>Refresh</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default NotFound;
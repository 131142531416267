import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const UPDATECONTRACTTEMPLATE_REQUEST = 'UPDATECONTRACTTEMPLATE_REQUEST';
export const UPDATECONTRACTTEMPLATE_SUCCESS = 'UPDATECONTRACTTEMPLATE_SUCCESS';
export const UPDATECONTRACTTEMPLATE_FAILURE = 'UPDATECONTRACTTEMPLATE_FAILURE';
export const UPDATECONTRACTTEMPLATE_RESET = 'UPDATECONTRACTTEMPLATE_RESET';

export const update_contract_template = (id,name,hours_day,lower_pay_range,upper_pay_range,is_workman,department,section,job_title,job_level,allowances,recurring_allowances,cost_center,contract_type,contract_rate,wschedule)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATECONTRACTTEMPLATE_REQUEST'
        });

        myaxios.patch(`contract/template/contracts/${id}/`,{
            name:name,
            hours_day:hours_day,
            lower_pay_range:lower_pay_range,
            upper_pay_range:upper_pay_range,
            department:department,
            section:section,
            job_title:job_title,
            cost_center:cost_center,
            allowances:allowances,
            job_level:job_level,
            wschedule:wschedule,
            is_workman:is_workman,
            contract_rate:contract_rate,
            contract_type:contract_type,
            recurring_allowances:recurring_allowances
        })
        .then((response)=>{
            dispatch({
                type:'UPDATECONTRACTTEMPLATE_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATECONTRACTTEMPLATE_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('update_failure')
            });
        })
    }
}

export const reset_update_contract_template = () => dispatch =>{
    dispatch({
        type:'UPDATECONTRACTTEMPLATE_RESET'
    });
}
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATECOM_SUCCESS = 'UPDATECOM_SUCCESS';
export const UPDATECOM_REQUEST = 'UPDATECOM_REQUEST';
export const UPDATECOM_FAILURE = 'UPDATECOM_FAILURE';
export const UPDATECOM_RESET = 'UPDATECOM_RESET';

export const update_company = (id,name,contact_number,uen,cpf_payment_type,cpf_serial_number,unit_number,sg_postal_code,address)=>{
    return dispatch =>{
        dispatch({
            type: 'UPDATECOM_REQUEST'
        });
        
        myaxios.patch(`company/company/${id}/`,
        {
            name:name,
            address:address,
            unit_number:unit_number,
            sg_postal_code:sg_postal_code,
            UEN:uen,
            CPF_payment_type:cpf_payment_type,
            CPF_serial_number:cpf_serial_number,
            contact_number:contact_number,
        })
        .then((response)=>{
            dispatch({
                type:'UPDATECOM_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title:IMLocalized('update_success')
            });
        })
        .catch((error) =>{
            dispatch({
                type: 'UPDATECOM_FAILURE',
                payload: error,
                error:true
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure'),
            });
        })
    }
}

export const reset_update_company = () => dispatch =>{
    dispatch({
        type:'UPDATECOM_RESET'
    })
}
import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const DELSCHEDULE_REQUEST ='DELSCHEDULE_REQUEST';
export const DELSCHEDULE_FAILURE ='DELSCHEDULE_FAILURE';
export const DELSCHEDULE_SUCCESS ='DELSCHEDULE_SUCCESS';

export const delete_schedule = (id)=>{
    return dispatch =>{
        dispatch({
            type:'DELSCHEDULE_REQUEST'
        });

        myaxios.delete(`contract/schedules/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELSCHEDULE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELSCHEDULE_FAILURE',
                payload:error,
                error:true
            });

            ErrToast.fire({
                type: 'error',
                title:IMLocalized('delete_failure')
            });
        })
    }
}
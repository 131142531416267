import myaxios from '../../axios';

export const LEAVEPENDINGCOLOR_REQUEST = 'LEAVEPENDINGCOLOR_REQUEST';
export const LEAVEPENDINGCOLOR_SUCCESS = 'LEAVEPENDINGCOLOR_SUCCESS';
export const LEAVEPENDINGCOLOR_FAILURE = 'LEAVEPENDINGCOLOR_FAILURE';

export const get_leave_pending_color = ()=>{
    return dispatch=>{
        dispatch({
            type:'LEAVEPENDINGCOLOR_REQUEST'
        });
        
        
        myaxios.get('leave/pending/color/')
        .then((response)=>{
            dispatch({
                type:'LEAVEPENDINGCOLOR_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'LEAVEPENDINGCOLOR_FAILURE',
                payload:error
            })
        })
    }
}
import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const APPEMPPROBATION_REQUEST = 'APPEMPPROBATION_REQUEST';
export const APPEMPPROBATION_SUCCESS = 'APPEMPPROBATION_SUCCESS';
export const APPEMPPROBATION_FAILURE = 'APPEMPPROBATION_FAILURE';
export const APPEMPPROBATION_RESET = 'APPEMPPROBATION_RESET';


export const approve_employee_probation = (data)=>{
    return dispatch=>{
        dispatch({
            type:'APPEMPPROBATION_REQUEST'
        });

        myaxios.post('employee/probation/array_approve/',{
            ids:data
        })
        .then((response)=>{
            dispatch({
                type:'APPEMPPROBATION_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('approve_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'APPEMPPROBATION_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('approve_failure')
            });
        })
    }
}

export const reset_approve_employee_probation = () => dispatch =>{
    dispatch({
        type:'APPEMPPROBATION_RESET'
    });
}
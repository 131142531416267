import myaxios from '../axios';
export const GETPAYROLLADDITIONALSETTING_SUCCESS = 'GETPAYROLLADDITIONALSETTING_SUCCESS';
export const GETPAYROLLADDITIONALSETTING_REQUEST = 'GETPAYROLLADDITIONALSETTING_REQUEST';
export const GETPAYROLLADDITIONALSETTING_FAILURE = 'GETPAYROLLADDITIONALSETTING_FAILURE';

export const get_payroll_additional_setting = ()=>{
    return dispatch =>{
        dispatch({
            type: 'GETPAYROLLADDITIONALSETTING_REQUEST'
        });
        myaxios.get(`payroll/field/`)
        .then((response)=>{
            dispatch({
                type:'GETPAYROLLADDITIONALSETTING_SUCCESS',
                payload:response
                
            });
        })
        .catch((error) =>{
            dispatch({
                type: 'GETPAYROLLADDITIONALSETTING_FAILURE',
                payload: error,
                error:true
            });
        })
    }
}
import React, { useContext, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {DateRangePicker} from 'react-dates';
import { employee_get_available_leave_options } from '../../../../action/employee/employee_get_available_leave_options';
import { usePrevious } from '../../../../hook/usePrevious';
import { popError } from '../../../../func/popError';
import { getDateFormat } from '../../../../func/getDateFormat';
import { employee_user_apply_leave } from '../../../../action/employee/employee_user_apply_leave';
import { get_all_employee_available_leave } from '../../../../action/employee/get_all_employee_available_leave';
import { get_apply_leave_list } from '../../../../action/get_apply_leave_list';
import { get_general_dashboard } from '../../../../action/dashboard/get_general_dashboard';
import { get_approvals_leave_list } from '../../../../action/approvals/get_approvals_leave_list';
import { manager_leave_list } from '../../../../action/leave/manager_leave_list';
import { NewMainContext } from '../../../../context/NewMainContext';

const mapStateToProps=(state)=>({
    emplist:state.get_employee_list.data,
    availablelist:state.get_all_employee_available_leave.data,
    leavelist:state.get_leave_list.data,
    options:state.employee_get_available_leave_options.data,
    options_errors:state.employee_get_available_leave_options.errors,
    apply_success:state.employee_user_apply_leave.data,
    apply_errors:state.employee_user_apply_leave.errors,
    isLoading:state.employee_get_available_leave_options.isLoading,
    isLoading1:state.employee_user_apply_leave.isLoading,
})

const mapDispatchToProps=(dispatch)=>({
    employee_get_available_leave_options:(employee,leave_setting,from_date,to_date)=>{
        dispatch(employee_get_available_leave_options(employee,leave_setting,from_date,to_date))
    },
    employee_user_apply_leave:(leave_options,require,file,leave_entitlement,remarks)=>{
        dispatch(employee_user_apply_leave(leave_options,require,file,leave_entitlement,remarks))
    },
    manager_leave_list:()=>{
        dispatch(manager_leave_list())
    },
    get_general_dashboard:()=>{
        dispatch(get_general_dashboard())
    },
    get_apply_leave_list:()=>{
        dispatch(get_apply_leave_list())
    },
    get_all_employee_available_leave:()=>{
        dispatch(get_all_employee_available_leave())
    },
    get_approvals_leave_list:()=>{
        dispatch(get_approvals_leave_list())
    },
})

const dateFormat = 'DD/MM/YYYY';

function ModalEmpApplyLeave(props){
    const {options,options_errors,apply_success,apply_errors,closeModal,get_all_employee_available_leave,get_apply_leave_list,get_general_dashboard,get_approvals_leave_list,manager_leave_list} = props;
    const [startDate,setStartDate] = useState(null);
    const [endDate,setEndDate] = useState(null);
    const [start_date,setStart_Date] = useState(null);
    const [end_date,setEnd_Date] = useState(null);
    const [err_date,setErrDate] = useState('');
    const [focusedInput,setFocus] = useState(null);
    const [employee,setEmployee] = useState('');
    const [err_employee,setErrEmployee] = useState('');
    const [leave_setting,setLeaveSetting] = useState('');
    const [err_leave_setting,setErrLeaveSetting] = useState('');
    const [comment,setComment] = useState('');
    const [approval,setApproval] = useState(true);
    const [min,setMin] = useState(0);
    const [max,setMax] = useState(0);
    const [err_images,setErrImages] = useState('');
    const [file,setFile] = useState([]);
    const [step,setStep] = useState(0);
    const [leave_options,setLeaveOptions] = useState([]);
    const [leave_entitlement,setLeaveEntitlement] = useState(null);
    const [available,setAvailable] = useState([]);
    const [approval_group,setApprovalGroup] = useState([]);
    const [leave_exceptions,setLeaveExceptions] = useState([]);
    const [leave_approvers,setLeaveApprovers] = useState([]);

    const { current_user } = useContext(NewMainContext);

    const prevOptions = usePrevious(options);
    const prevOptionsError = usePrevious(options_errors);
    const prevApplySuccess = usePrevious(apply_success);
    const prevApplyErrors = usePrevious(apply_errors);

    useEffect(()=>{
        if(props.toggle){
            setStartDate(props.startDate ?? null);
            setStart_Date(props.start_date ?? null);
            setEndDate(null);
            setEnd_Date(null);
            setErrDate('');
            setFocus(null);
            setComment('');
            setApproval(true);
            setMax(0);
            setMin(0);
            setErrImages('');
            setFile([]);
            setStep(0);
            setLeaveOptions([]);
            setLeaveEntitlement(null);
            if(props.has_leave){
                setLeaveSetting(props.leave_setting)
            }
            else{
                if(props.leave_setting != null && props.leave_setting !== ''){
                    setLeaveSetting(props.leave_setting);
                }
                
            }
            setErrLeaveSetting('');
            if(props.has_emp){
                setEmployee(props.id);
            }
            else{
                if(props.id != null && props.id !== ''){
                    setEmployee(props.id);
                }
            }
            setErrEmployee('');
            setAvailable([]);
        }
    },[props.toggle,props.leave_setting,props.id,props.has_emp,props.has_leave,props.startDate,props.start_date])

    useEffect(()=>{
        if(leave_setting !== '' && props.leavelist.length !== 0){
            const data = props.leavelist.find((item)=>item.id === parseInt(leave_setting));
            setMin(data.minimum_images);
            setMax(data.maximum_images);
        }
        setFile([]);
        setErrImages('');
        setErrDate('');
    },[leave_setting,props.leavelist])

    useEffect(()=>{
        if(prevOptions !== undefined && prevOptions !== options && options !== null && props.toggle){
            setLeaveOptions(options.leave_options);
            setLeaveEntitlement(options.leave_entitlement);
        }
    },[prevOptions,options,props.toggle])

    useEffect(()=>{
        if(prevOptionsError !== undefined && prevOptionsError !== options_errors && options_errors !== null && props.toggle){
            if('non_field_errors' in options_errors){
                const word = options_errors['non_field_errors'].toString();
                popError(word);
              
            }
            else if('to_date' in options_errors){
                const word = options_errors['to_date'].toString();
                popError(word);
            }
            else if('leave_setting' in options_errors){
                const word = options_errors['leave_setting'].toString();
                popError(word);
                
            }
            setStep(0);
        }
    },[prevOptionsError,options_errors,props.toggle])

    useEffect(()=>{
        if(prevApplyErrors !== undefined && prevApplyErrors !== apply_errors && apply_errors !== null && props.toggle){
            if('non_field_errors' in apply_errors){
                const word = apply_errors['non_field_errors'].toString();
                popError(word);
            }
            if('leave_entitlement' in apply_errors){
                const word= apply_errors['leave_entitlement'];
                const remaining = word['remaining'];
                const amount = word['amount']
                const word1 = IMLocalized('not_enough_leave') +'\n' +IMLocalized('leave_entitlement_error2',{amount,remaining})
                popError(word1)
            }
            if('upload_images' in apply_errors){
                const word = apply_errors['upload_images'].toString();
                popError(word);
            }
        }
    },[prevApplyErrors,apply_errors,props.toggle])

    useEffect(()=>{
        if(prevApplySuccess !== undefined && prevApplySuccess !== apply_success && apply_success !== null && props.toggle){
            closeModal();
            setEmployee('');
            get_all_employee_available_leave();
            get_apply_leave_list();
            get_general_dashboard();
           
            if(current_user === 'hr'){
                get_approvals_leave_list();
                manager_leave_list();
            }
           
        }
    },[prevApplySuccess,apply_success,props.toggle,closeModal,manager_leave_list,get_approvals_leave_list,get_general_dashboard,get_apply_leave_list,get_all_employee_available_leave,current_user])

    useEffect(()=>{
        if(employee !== '' && props.availablelist.length !== 0 && props.toggle){
            const arr = props.availablelist.filter(function(item){
                return item.employee === parseInt(employee)
            })
            setAvailable(arr);
            
            const data = arr.map((item)=>{
                return {...item,group:0}
            })
            setApprovalGroup(data);
        }
        else{
            setAvailable([]);
            setApprovalGroup([]);
        }
    },[employee,props.availablelist,props.toggle])

    useEffect(()=>{
        let arr =[];
        let arr1 = approval_group;
        if(props.emplist.length !== 0 && employee !== '' && approval_group.length !== 0){
            const emp_data = props.emplist.find((item)=>item.id === parseInt(employee));
            const {metadata} = emp_data;
            if('lag' in metadata){
                const {lag} = metadata;
                if(lag !== null){
                    arr.push(lag.approvers);
                    const {exceptions} = lag;
                    for(let i=0;i<exceptions.length;i++){
                        const number = i+1;
                        const data = exceptions[i];
                        arr.push(data.approvers);
                        const exceptions1 = data.exceptions;
                        for(let j=0;j<exceptions1.length;j++){
                            arr1 = arr1.map(item=>
                                item.leave_setting === exceptions1[j].id
                                ?{...item,group:number}
                                :item
                            )
                        }
                    }
                }
            }
        }
        setLeaveApprovers(arr);
        setLeaveExceptions(arr1);
    },[props.emplist,approval_group,employee])

    const handleRadio=(e,index)=>{
        let data = leave_options;
        const target = e.target;
        const value = target.value;
        data[index].default = value;
        setLeaveOptions([...data]);
    }

    const onDatesChange=({startDate:startDate1,endDate:endDate1})=>{
        setStartDate(startDate1);
        setEndDate(endDate1);
        let date1 = null;
        let date2 = null;
        if(startDate1 !== null){
            const startdate = startDate1._d;
            let startmonth = (startdate.getMonth()+1);
            if(startmonth <10){
                startmonth = '0'+startmonth;
            }
            let startday = startdate.getDate();
            if(startday<10){
                startday = '0'+startday;
            }
            date1 = startdate.getFullYear()+'-' + startmonth+'-' +startday;
        }

        if(endDate1 !== null){
            const enddate = endDate1._d;
            let endmonth =(enddate.getMonth()+1);
            if(endmonth <10){
                endmonth = '0'+endmonth;
            }
            let endday = enddate.getDate();
            if(endday<10){
                endday = '0'+endday;
            }
            date2 = enddate.getFullYear()+'-'+endmonth+'-'+endday;
        }
        setEnd_Date(date2);
        setStart_Date(date1);
        setErrDate('');
        setStep(0);
        setLeaveOptions([]);
    }
    
    const getLoaday=()=>{
        let end = end_date;
        if(start_date !== null){
            if(end_date === null){
                end = start_date;
            }
            setStep(1);
            props.employee_get_available_leave_options(employee,leave_setting,start_date,end);
        }
        else{
            if(start_date === null){
                setErrDate(IMLocalized('please_select_date'));
            }
        }
    }

    const applyLeave=()=>{

        const days = leave_options.map((item)=>{
            return {date:item.date,day:item.default}
        })

        if(file.length <= max && file.length >= min){
            props.employee_user_apply_leave(days,!approval,file,leave_entitlement,comment);
        }
        else{
            popError(`${IMLocalized('must_upload_number')} ${min} ${IMLocalized('to')} ${max} ${IMLocalized('please_upload_number')}`);
            setErrImages(`* ${IMLocalized('must_upload_number')} ${min} ${IMLocalized('to')} ${max} ${IMLocalized('please_upload_number')}`);
        }
    }

    const approval_table=()=>{
        if(leave_approvers.length !== 0 && leave_exceptions.length !== 0 && leave_setting !== '' && props.toggle){
            
            const detail = leave_exceptions.filter(function(item){
                return item.leave_setting === parseInt(leave_setting)
            })
            if(detail.length !== 0){
                const group = detail[0].group;
                const approver = leave_approvers[group];

                const arr_approvers = approver.map((item,index)=>{
                    const data = item.map((item1,index2)=>{
                        return <div key={'approvers'+index2}><span>{item1.name} -  ({IMLocalized('level')} : {index+1})</span></div>
                    })
                    return data
                })

                return(
                    <Row className='mt-2'>
                        <Col sm="3">
                            <label htmlFor='leave_approval'>{IMLocalized('leave_approval')}</label>
                        </Col>
                        <Col sm="9">
                            <Table size="sm" className='table-bordered' responsive>
                                <thead className='thead-light'>
                                    <tr>
                                        <th>{IMLocalized('approval_level')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {arr_approvers}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                )
            }
        }

        return null;
    }

    return(
        <Modal size="lg" isOpen={props.toggle}>
            <ModalHeader toggle={()=>{props.closeModal();setEmployee('')}}>{IMLocalized('apply_leave')} {props.name ? ' - ' +props.name :''}</ModalHeader>
            <ModalBody>
                {!props.has_emp && 
                <Row>
                    <Col sm="3">
                        <label htmlFor='employee'>{IMLocalized('employee_name')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <Input size="sm" type="select" name="employee" id="employee" value={employee} onChange={(e)=>{setEmployee(e.target.value);setErrEmployee('');setStep(0);setLeaveSetting(''); setStartDate(props.startDate ?? null); setStart_Date(props.start_date?? null); setEndDate(null) ; setEnd_Date(null); setLeaveOptions([]) ;setComment(''); setApproval(true) ;setErrDate('') }} invalid={err_employee.length >0}>
                                <option></option>
                                {/* {props.emplist.map((item,index)=>{
                                    return <option value={item.id} key={index}>{item.name}</option>
                                })} */}
                                {props.emplist.map((item,index)=>{
                                    if(item.active===true){
                                    return <option value={item.id} key={index}>{item.name}</option>
                                    }
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row> }
                {employee !== ''  && available.length === 0 &&
                <small className="text-danger">{IMLocalized('please_assign_leave_scheme_for_employee')}</small>
                }
                {!props.has_leave && available.length !== 0 &&
                <>
                <Row>
                    <Col lg="3">
                        <label htmlFor='leave_setting'>{IMLocalized('leave_type')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <Input size="sm" type="select" name="leave_setting" id="leave_setting" value={leave_setting} onChange={(e)=>{setLeaveSetting(e.target.value); setErrLeaveSetting(''); setStep(0); setLeaveOptions([])}}>
                                <option></option>
                                {available.map((item,index)=>{
                                    const detail = props.leavelist.find((data)=>data.id === item.leave_setting);
                                    return <option value={item.leave_setting} key={index}>{detail.name}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_leave_setting}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                </>
                }
                {employee !== '' && available.length !== 0 &&
                <>
                <Row>
                    <Col sm="3">
                        <label htmlFor='leave_date'>{IMLocalized('leave_date')}</label>
                    </Col>
                    <Col sm="9">
                        <FormGroup>
                            <DateRangePicker 
                                startDate={startDate}
                                isOutsideRange={()=>false}
                                small = {true}
                                startDateId="unique_start_date_id"
                                endDate={endDate}
                                endDateId="unique_end_date_id"
                                onDatesChange={onDatesChange}
                                focusedInput={(focusedInput)}
                                displayFormat={dateFormat}
                                minimumNights={0}
                                onFocusChange={(v)=>setFocus(v)}
                            />
                            <div>
                                <small className='text-red'>{err_date}</small>
                            </div>
                        </FormGroup>
                        
                    </Col>
                </Row>
                <Row>
                    <Col sm="3">
                        <label htmlFor='comment'>{IMLocalized('comment')}</label>
                    </Col>
                    <Col sm="9">
                        <FormGroup>
                            <Input id="comment" size="sm" type="textarea" value={comment} onChange={(e)=>{setComment(e.target.value)}} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="3">
                        <label htmlFor='immediate_approval'>{IMLocalized('immediate_approval')}</label>
                    </Col>
                    <Col sm="9">
                        <div className="custom-control custom-checkbox mt-1">
                            <input type="checkbox" id="emp_approval" name="approval" className="custom-control-input" checked={approval} onChange={(e)=>setApproval(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="emp_approval"></label>
                        </div>
                    </Col>
                </Row>
                {min >0 || max >0 ?
                <Row className="mt-3">
                    <Col lg="3">
                        <label htmlFor='image'>{IMLocalized('upload_image')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <label className="form-control-label">
                                <input type="file" id="image" multiple onChange={(e)=>{setFile(e.target.files); setErrImages('')}} accept='image/*' ></input>
                                <div>
                                    <small className='text-red'>{err_images}</small>
                                </div>
                                
                            </label>
                        </FormGroup>
                    </Col>
                </Row>:null}
                </>
                }
                {approval_table()}
                {step ===1 &&leave_options.length !== 0 &&
                <Table size="sm" className='align-items-center table-bordered' responsive>
                    <thead className='thead-light'>
                        <tr>
                            <th>{IMLocalized('leave_date')}</th>
                            <th>{IMLocalized('option')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {leave_options.map((item,index)=>{
                            const tbutton = item.options.map((item1,index1)=>{
                                if(item1.value === item.default){
                                    return(
                                        <div className="custom-control custom-radio mr-3 d-flex align-items-center" key={'radio'+index+'radio'+index1}>
                                            <input className="custom-control-input" id={'custom'+index+'Radio'+index1} type="radio" value={item1.value} checked={true} onChange={(e)=>handleRadio(e,index)} />
                                            <label className="custom-control-label" htmlFor={'custom'+index+'Radio'+index1}>{IMLocalized(item1.value)}</label>
                                        </div>
                                    )
                                }
                                else{
                                    return(
                                        <div className="custom-control custom-radio mr-3 d-flex align-items-center" key={'radio'+index+'radio'+index1}>
                                            <input className="custom-control-input" id={'custom'+index+'Radio'+index1} type="radio" value={item1.value} checked={false} onChange={(e)=>handleRadio(e,index)}  />
                                            <label className="custom-control-label" htmlFor={'custom'+index+'Radio'+index1}>{IMLocalized(item1.value)}</label>
                                        </div>
                                    )
                                }
                            })
                            return(
                                <tr key={index}>
                                    <td>{getDateFormat(item.date)}</td>
                                    <td>
                                        <div className='d-flex'>
                                            {tbutton}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>}
            </ModalBody>
            <ModalFooter>
                {props.isLoading || props.isLoading1  ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button> : null}
                {!props.isLoading && !props.isLoading1 && employee !== '' && leave_setting !== '' && step === 0 && <button className="btn btn-primary btn-sm" onClick={()=>getLoaday()} >{IMLocalized('load_day')}</button>}
                {!props.isLoading && !props.isLoading1 && employee !== '' && leave_setting !== '' && step === 1 && <button className='btn btn-primary btn-sm' onClick={()=>applyLeave()}>{IMLocalized('apply_leave')}</button>}
                {!props.isLoading && !props.isLoading1 && <button className='btn btn-danger btn-sm' onClick={()=>{props.closeModal();setEmployee('')}}>{IMLocalized('close')}</button>}
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalEmpApplyLeave);
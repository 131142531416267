import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { popError } from '../../../../func/popError';
import { emp_all_get_report_day, reset_emp_all_get_report_day } from '../../../../action/employee/emp_all_get_report_day';
import FileSaver from 'file-saver';
import ExcelJs from 'exceljs';
import { getDateFormat } from '../../../../func/getDateFormat';
import moment from 'moment';
import { getColumnName } from '../../../../func/getColumnName';
import { getValue } from '../../../../func/getValue';

const dateFormat = 'DD/MM/YYYY';

export default function ModalReportLeave(props){

    const {closeModal} = props;
    const reportlist = useSelector(state=>state.emp_all_get_report_day.data);
    const isLoading = useSelector(state=>state.emp_all_get_report_day.isLoading);
    const optionlist = useSelector(state=>state.dropdown_list.schedule_all_options);
    const [use_day,setUseDay] = useState(false);
    const [sunday,setSunday] = useState(false); 
    const [monday,setMonday] = useState(false);
    const [tuesday,setTuesday] = useState(false); 
    const [wednesday,setWednesday] = useState(false);
    const [thursday,setThursday] = useState(false); 
    const [friday,setFriday] = useState(false);
    const [saturday,setSaturday] = useState(false); 
    const [focusedInput,setFocus] = useState(null);
    const [startDate,setStartDate] = useState(null);
    const [endDate,setEndDate] = useState(null);
    const [start_date,setStart_Date] = useState(null);
    const [end_date,setEnd_Date] = useState(null);
    const dispatch = useDispatch();


    useEffect(()=>{
        if(props.toggle){
            setStartDate(props.startDate);
            setEndDate(props.endDate);
            setStart_Date(props.start_date);
            setEnd_Date(props.end_date);
            setUseDay(false);
            setMonday(false);
            setTuesday(false);
            setWednesday(false);
            setThursday(false);
            setFriday(false);
            setSaturday(false);
            setSunday(false);
        }
    },[props.toggle,props.startDate,props.endDate,props.end_date,props.start_date])

    useEffect(()=>{
        if( reportlist != null && props.toggle){
            const data = reportlist;
            let list;
            if(props.id != null){
                list = data.filter(function(item){
                    return item.employee.id === parseInt(props.id)
                })
            }
            else{
                list = data;
            }
          
            if(list.length !== 0){
                
                const wb = new ExcelJs.Workbook();
                const ws = wb.addWorksheet('employee_leave_list');
                const today = moment(new Date()).format('DD/MM/YYYY');
                const time = moment().format('HH:mm:ss');
                const day =moment(new Date()).format("DD");
                const month =moment(new Date()).format("MM");
                let string = getDateFormat(start_date);
                if(start_date !== end_date){
                    string = `From ${getDateFormat(start_date)} ${end_date? 'to '+ getDateFormat(end_date):''}`;
                }
                ws.getCell('A1').value = 'Employee Leave Report - ' + string;
                ws.getCell('A2').value = 'Printed On : '+today+' '+time;
                ws.getCell('A3').value =  props.emp_number ? `Emplyee ID : ${props.emp_number}` : '';
                ws.getCell('A4').value =  props.emp_name ? `Employee Name : ${props.emp_name}` :'';
                const title_name = `EmployeeLeaveReport_${props.emp_name??''}_${day}-${month}`;
                const column = [
                    {header:'Date',key:'date'},
                    {header:'Emp ID',key:'employee.employee_number'},
                    {header:'Name',key:'employee.name'},
                    {header:'Leave Code',key:'metadata.leave_code'},
                    {header:'Leave Type',key:'metadata.leave_name'},
                    {header:'Option',key:'day'}
                ] 
                ws.columns =[
                    {width:20},
                    {width:20},
                    {width:20},
                    {width:20},
                    {width:20},
                    {width:20}
                ]

                for(let i=0;i<column.length;i++){
                    const headerName = column[i].header;
                    const field = (column[i].key);
                    const length = list.length;
                    ws.getCell(getColumnName(i)+'6').value = headerName;
                    for(let x=0;x < length;x++){
                        const step = 7+x;
                        const data  = getValue(list[x],field);
                        if(field === 'date'){
                            ws.getCell(getColumnName(i)+step).value = getDateFormat(data);
                        }
                        else if(field === 'day'){
                            const detail = optionlist.find((item)=>item.value === data);
                            ws.getCell(getColumnName(i)+step).value = detail ? detail.display : '-';
                        }
                        else{
                            ws.getCell(getColumnName(i)+step).value = data;
                        }
                    }
                }
                const filename = title_name+ '.xlsx';
                wb.xlsx.writeBuffer().then((buf)=>{
                    const file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                    FileSaver.saveAs(file, filename)
                });
                closeModal();
            }
            else{
                popError(IMLocalized('no_data'));
            }

            dispatch(reset_emp_all_get_report_day());
        }
    },[reportlist,props.toggle,props.id,end_date,start_date,props.emp_name,props.emp_number,optionlist,closeModal,dispatch])

    const onDatesChange=({startDate:startDate1,endDate:endDate1})=>{
        setStartDate(startDate1);
        setEndDate(endDate1);

        let start = null;
        if(startDate1 !== null){
            let startdate =startDate1._d;
            let startmonth = (startdate.getMonth()+1);
            if(startmonth <10){
                startmonth = '0'+startmonth;
            }
            let startday = startdate.getDate();
            if(startday<10){
                startday = '0'+startday;
            }
            start = startdate.getFullYear()+'-' + startmonth+'-' +startday
        }
        
        let end = null;
        if(endDate1 !== null){
            let enddate = endDate1._d;
            let endmonth =(enddate.getMonth()+1);
            if(endmonth <10){
                endmonth = '0'+endmonth;
            }
            let endday = enddate.getDate();
            if(endday<10){
                endday = '0'+endday;
            }
            end = enddate.getFullYear()+'-'+endmonth+'-'+endday
        }
        setEnd_Date(end);
        setStart_Date(start);
    }

    const handleExport =()=>{
        let end = end_date;
        if(start_date !== null ){
            if(end === null){
                end = start_date;
            }
            dispatch(emp_all_get_report_day(start_date,end,monday,tuesday,wednesday,thursday,friday,saturday,sunday,use_day));
        }
        else{
            popError(IMLocalized('date_is_required'));
        }
    }

    return(
        <Modal size="lg" isOpen={props.toggle}>
            <ModalHeader toggle={props.closeModal}>{IMLocalized('select_to_export')}</ModalHeader>
            <ModalBody>
                <DateRangePicker
                    startDate={startDate}
                    isOutsideRange={()=>false}
                    small={true}
                    minimumNights={0}
                    startDateId="unique_start_date_id"
                    endDate={endDate}
                    endDateId="unique_end_date_id"
                    onDatesChange={(e)=>onDatesChange(e)}
                    focusedInput={focusedInput}
                    displayFormat={dateFormat}
                    onFocusChange={(v)=>setFocus(v)} 
                />
                <div className='mt-2 d-flex'>
                    <div className='custom-control custom-radio mr-3'>
                        <input
                            className='custom-control-input'
                            id="modal_emp_export_leave1"
                            name="use_day"
                            type="radio"
                            value="false"
                            checked={use_day === false}
                            onChange={()=>{setUseDay(false)}}
                        />
                        <label className='custom-control-label' htmlFor='modal_emp_export_leave1'>{IMLocalized('default')}</label>
                    </div>
                    <div className='custom-control custom-radio'>
                        <input
                            className='custom-control-input'
                            id="modal_emp_export_leave2"
                            name="use_day"
                            type="radio"
                            value="true"
                            checked={use_day === true}
                            onChange={()=>{setUseDay(true)}}
                        />
                        <label className='custom-control-label' htmlFor='modal_emp_export_leave2'>{IMLocalized('CUSTOM')}</label>
                    </div>
                </div>
                {use_day &&
                <>
                <div className='mt-2 custom-control custom-checkbox'>
                    <input
                        className='custom-control-input'
                        id="report_emp_all_sunday"
                        type="checkbox"
                        checked={sunday}
                        onChange={(e)=>setSunday(e.target.checked)}
                        name="sunday"
                    />
                    <label className='custom-control-label' htmlFor='report_emp_all_sunday'>{IMLocalized('SUNDAY')}</label>
                </div>
                <div className='custom-control custom-checkbox'>
                    <input
                        className='custom-control-input'
                        id="report_emp_all_monday"
                        type="checkbox"
                        checked={monday}
                        onChange={(e)=>setMonday(e.target.checked)}
                        name="monday"
                    />
                    <label className='custom-control-label' htmlFor='report_emp_all_monday'>{IMLocalized('MONDAY')}</label>
                </div>
                <div className='custom-control custom-checkbox'>
                    <input
                        className='custom-control-input'
                        id="report_emp_all_tuesday"
                        type="checkbox"
                        checked={tuesday}
                        onChange={(e)=>setTuesday(e.target.checked)}
                        name="tuesday"
                    />
                    <label className='custom-control-label' htmlFor='report_emp_all_tuesday'>{IMLocalized('TUESDAY')}</label>
                </div>
                <div className='custom-control custom-checkbox'>
                    <input
                        className='custom-control-input'
                        id="report_emp_all_wednesday"
                        type="checkbox"
                        checked={wednesday}
                        onChange={(e)=>setWednesday(e.target.checked)}
                        name="wednesday"
                    />
                    <label className='custom-control-label' htmlFor='report_emp_all_wednesday'>{IMLocalized('WEDNESDAY')}</label>
                </div>
                <div className='custom-control custom-checkbox'>
                    <input
                        className='custom-control-input'
                        id="report_emp_all_thursday"
                        type="checkbox"
                        checked={thursday}
                        onChange={(e)=>setThursday(e.target.checked)}
                        name="thursday"
                    />
                    <label className='custom-control-label' htmlFor='report_emp_all_thursday'>{IMLocalized('THURSDAY')}</label>
                </div>
                <div className='custom-control custom-checkbox'>
                    <input
                        className='custom-control-input'
                        id="report_emp_all_friday"
                        type="checkbox"
                        checked={friday}
                        onChange={(e)=>setFriday(e.target.checked)}
                        name="friday"
                    />
                    <label className='custom-control-label' htmlFor='report_emp_all_friday'>{IMLocalized('FRIDAY')}</label>
                </div>
                <div className='custom-control custom-checkbox'>
                    <input
                        className='custom-control-input'
                        id="report_emp_all_saturday"
                        type="checkbox"
                        checked={saturday}
                        onChange={(e)=>setSaturday(e.target.checked)}
                        name="saturday"
                    />
                    <label className='custom-control-label' htmlFor='report_emp_all_saturday'>{IMLocalized('SATURDAY')}</label>
                </div>
                </>
                }
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-success btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button className='btn btn-success btn-sm' onClick={()=>handleExport()}>{IMLocalized('export')}</button>
                    <button className='btn btn-danger btn-sm' onClick={closeModal}>{IMLocalized('close')}</button>
                </>}
                
            </ModalFooter>
        </Modal>
    )
}
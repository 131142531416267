import myaxios from '../../axios';

export const GETEMPROLELIST_REQUEST = 'GETEMPROLELIST_REQUEST';
export const GETEMPROLELIST_SUCCESS = 'GETEMPROLELIST_SUCCESS';
export const GETEMPROLELIST_FAILURE = 'GETEMPROLELIST_FAILURE';

export const get_employee_role_list =()=>{
    return dispatch=>{
        dispatch({
            type:'GETEMPROLELIST_REQUEST'
        });
        
        myaxios.get('employee/users/')
        .then((response)=>{
            dispatch({
                type:'GETEMPROLELIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETEMPROLELIST_FAILURE',
                payload:error
            })
        })
    }
}
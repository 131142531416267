import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { IMLocalized } from "../../language/IMLocalized";
import { dateRenderer } from "../ag-grid_component/dateRenderer";

const mapStateToProps=(state)=>({
    historylist:state.get_payroll_history_list.data,
})

const mapDispatchToProps=(dispatch)=>({

})

function PayrollDetailHistory(props){

    const [rowData,setRowData] = useState([]);

    useEffect(()=>{
        if(props.historylist.length !== 0){
            const detail = props.historylist.filter(function(item){
                return item.payroll === props.id
            })
            const sort = detail.sort((a,b)=> new Date(b.created_at) - new Date(a.created_at));
            setRowData(sort);
        }
        else{
            setRowData([]);
        }
    },[props.historylist,props.id])

    const date2Renderer= useCallback(function(params){

        const monthNames=[IMLocalized("january"), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
        IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")
        ];
        const date1 = params.data.payroll_date;
        const month1 = new Date(date1).getMonth();
        const month2 = monthNames[month1]
        const year1 = new Date(date1).getFullYear();
        const payroll_date = month2 + ' ' + year1;

        return payroll_date

    },[])

    return(
        <div className="mt-3 mb-3">
            <h3 className="mb-0">{IMLocalized('payroll_history')}</h3>
            <div className="ag-theme-balham" style={{height:'500px', width:'100%' }}>
                <AgGridReact
                enableRangeSelection={true}
                suppressDragLeaveHidesColumns={true}
                suppressSizeToFit={true}
                suppressColumnMoveAnimation={false}
                overlayNoRowsTemplate={IMLocalized('no_data')}
                rowData={rowData}
                columnDefs={[
                    {
                        headerName:IMLocalized('history_time'),
                        field:'created_at',
                        cellRendererFramework:dateRenderer,
                        width:200
                    },
                    {
                        headerName:IMLocalized('hr_employee'),
                        field:'employee_name',
                        width:200
                    },
                    {
                        headerName:IMLocalized('payroll'),
                        field:'payroll_date',
                        cellRendererFramework:date2Renderer,
                        width:200
                    },
                    {
                        headerName:IMLocalized('payslip_employee_name'),
                        field:'payslip_employee_name',
                        cellRenderer:params=>{
                            if(params.value){
                                return params.value
                            };
                            return '-'
                        },
                        width:200
                    },
                    {
                        headerName:IMLocalized('field_name'),
                        field:'name',
                        cellRenderer:params=>{
                            if(params.value){
                                return params.value
                            };
                            return '-'
                        },
                        width:200
                    },
                    {
                        headerName:IMLocalized('old_value'),
                        field:'old_value',
                        cellRenderer:params=>{
                            if(params.value){
                                return params.value
                            };
                            return '-'
                        },
                        width:150
                    },
                    {
                        headerName:IMLocalized('new_value'),
                        field:'new_value',
                        cellRenderer:params=>{
                            if(params.value){
                                return params.value
                            };
                            return '-'
                        },
                        width:150
                    },
                    {
                        headerName:IMLocalized('old_count'),
                        field:'count_old_value',
                        cellRenderer:params=>{
                            if(params.value){
                                return params.value
                            };
                            return '-'
                        },
                        width:150
                    },
                    {
                        headerName:IMLocalized('new_count'),
                        field:'count_new_value',
                        cellRenderer:params=>{
                            if(params.value){
                                return params.value
                            };
                            return '-'
                        },
                        width:150
                    },
                    {
                        headerName:IMLocalized('history_action'),
                        field:'action',
                        cellRenderer:params=>{
                            if(params.value){
                                return IMLocalized(params.value)
                            };
                            return '-'
                        },
                        width:150
                    },
                ]}
                />
            </div>
        </div>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(PayrollDetailHistory);
import myaxios from '../../axios';


export const EMPLISTWITHOUTCONTRACT_REQUEST = 'EMPLISTWITHOUTCONTRACT_REQUEST';
export const EMPLISTWITHOUTCONTRACT_SUCCESS = 'EMPLISTWITHOUTCONTRACT_SUCCESS';
export const EMPLISTWITHOUTCONTRACT_FAILURE = 'EMPLISTWITHOUTCONTRACT_FAILURE';


export const get_employee_list_withoutcontract =()=>{
    return dispatch=>{
        dispatch({
            type:'EMPLISTWITHOUTCONTRACT_REQUEST'
        });
        
        myaxios.get('employee/employees/WithoutContract/')
        .then((response)=>{
            dispatch({
                type:'EMPLISTWITHOUTCONTRACT_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'EMPLISTWITHOUTCONTRACT_FAILURE',
                payload:error
            })
        })
    }
}
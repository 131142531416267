import React, { useCallback, useEffect, useState } from 'react';
import { Stepper } from 'react-form-stepper';
import IdleTimer from 'react-idle-timer';
import {Container, Row, Col} from 'reactstrap';
import CompanyDetail from './CompanyDetail';
import { IMLocalized, init } from '../../language/IMLocalized';
import { get_general_logo } from '../../action/get_general_logo';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import a_line from '../../images/a_line.png';
import PeriodDetail from './PeriodDetail';
import OvertimeDetail from './OvertimeDetail';
import AllowanceDetail from './AllowanceDetail';
import RecurringDetail from './RecurringDetail';
import FixedDetail from './FixedDetail';
import HolidayDetail from './HolidayDetail';
import ScheduleDetail from './ScheduleDetail';
import AdditionalDetail from './AdditionalDetail';

export default function Wizard(){
    const dispatch = useDispatch();
    const language = localStorage.getItem('language');
    const auth = useSelector(state=>state.auth1.fail);
    const logo = useSelector(state=>state.get_general_logo.data);
    const [ currentStep, setCurrentStep ] = useState(0);
    const [ company_data, setCompanyData ] = useState(null);
    const [ period_data, setPeriodData ] = useState(null);
    const [ overtime_data, setOvertimeData ] = useState([]);
    const [ allowance_data, setAllowanceData ] = useState([]);
    const [ recurring_data, setRecurringData ] = useState([]);
    const [ fixed_data, setFixedData ] = useState([]);
    const [ holiday_data, setHolidayData ] = useState([]);
    const [ schedule_data, setScheduleData ] = useState([]);

    const handleUnload= useCallback((e)=>{
        const message = "";
        (e || window.event).returnValue = message;
        return message;
    },[])

    const onIdle = useCallback((e)=>{
        localStorage.clear();
        window.removeEventListener('beforeunload',handleUnload);
        Swal.fire({
            title:IMLocalized('session_expired'),
            type:'warning',
            confirmButtonColor:'#d33',
            confirmButtonText:IMLocalized('ok!'),
            allowEnterKey:true,
            allowOutsideClick:false
        })
        .then((result)=>{
            if(result.value){
                window.location.reload();
            }
        })
    },[handleUnload])

    useEffect(()=>{
        if(auth !== ''){
            onIdle();
        }
    },[auth,onIdle])

    useEffect(()=>{
        if(logo?.data?.byteLength != null){
            if(logo.data.byteLength === 0){
                document.getElementById('favicon').href = a_line;
            }
            else{
                const url = process.env.REACT_APP_API_URL;
                document.getElementById("favicon").href = `https://${url}/api/general/logo/`;
            }
        }
    },[logo])

    useEffect(()=>{
        document.title = process.env.REACT_APP_TITLE;
        dispatch(get_general_logo());
        window.addEventListener('beforeunload',handleUnload);
        window.onunload = function(){
            localStorage.clear();
        }

        return()=>{
            window.removeEventListener('beforeunload',handleUnload)
        }
    },[dispatch,handleUnload])

    const timer = 1000 * parseInt(process.env.REACT_APP_SESSION_TIMEOUT) * 60;
    const steps=[
        { label: IMLocalized('company_detail')}, 
        { label: IMLocalized('period_setting')},
        { label: IMLocalized('overtime')}, 
        { label: IMLocalized('allowance')},
        { label: IMLocalized('recurring_allowance')},
        { label: IMLocalized('fixed_rate')},
        { label: IMLocalized('holiday'),},
        { label: IMLocalized('schedule')},
        { label: IMLocalized('additional_setting')}
    ];
    
    if(language){
        init(language);
    }
    else{
        const current  = window.navigator.language;
        const word = current.substring(0,2);
        if(word === 'zh'){
            init(word)
        }
        else{
            init('en')
        }
    }
    
    return(
        <IdleTimer
        debounce={250}
        timeout={timer}
        onIdle={onIdle}
        >
            <Container className='bg-white border-bottom' fluid>
                <Row>
                    <Col>
                        <Stepper steps={steps} activeStep={currentStep} />
                    </Col>
                </Row>
                
            </Container>
            {currentStep === 0 && <CompanyDetail setData={(item)=>{ setCompanyData(item); setCurrentStep(currentStep+1)}} />}
            {currentStep === 1 && <PeriodDetail language={language} setData={(item)=>{setPeriodData(item); setCurrentStep(currentStep+1) }} />}
            {currentStep === 2 && <OvertimeDetail setData={(item)=>{ setOvertimeData(item); setCurrentStep(currentStep+1) }} />}
            {currentStep === 3 && <AllowanceDetail setData={(item)=>{ setAllowanceData(item); setCurrentStep(currentStep+1) }} language={language} />}
            {currentStep === 4 && <RecurringDetail setData={(item)=>{ setRecurringData(item); setCurrentStep(currentStep+1)}} language={language} />}
            {currentStep === 5 && <FixedDetail setData={(item)=>{setFixedData(item); setCurrentStep(currentStep +1) }} />}
            {currentStep === 6 && <HolidayDetail setData={(item)=>{setHolidayData(item); setCurrentStep(currentStep+1) }} />}
            {currentStep === 7 && <ScheduleDetail setData={(item)=>{setScheduleData(item); setCurrentStep(currentStep+1) }} holiday_list={holiday_data} />}
            {currentStep === 8 && <AdditionalDetail company_data={company_data} period_data={period_data} overtime_data={overtime_data} allowance_data={allowance_data} recurring_data={recurring_data} fixed_data={fixed_data} holiday_data={holiday_data} schedule_data={schedule_data} removeUnload={()=>window.removeEventListener('beforeunload',handleUnload)} />}
        </IdleTimer>
    )
}
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table} from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import ModalViewCertification from '../hr_employee/modal/ModalViewCertification';


const mapStateToProps=(state)=>({
    data:state.user_self.data,
    isLoading:state.user_self.isLoading,
})

function UserEmployeeCertification(props){

    const [list,setList] = useState([]);
    const [toggle,setToggle] = useState(false);
    const [detail,setDetail]  = useState(null);

    useEffect(()=>{
        if(props.data.length !== 0){
            const {certification} = props.data;
            setList(certification);
        }
    },[props.data])

    const toView=(id)=>{
        const data = list.find(item=>item.id === id);
        setDetail(data);
        setToggle(true);
    }

    return(
        <>
        <div className='d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title'>
            <div className='d-flex align-items-center'>
                <i className='fas fa-graduation-cap text-primary' />
                <h4 className='text-primary pl-2 m-0'>{IMLocalized('employee_certification')}</h4>
            </div>
        </div>
        {props.isLoading && <div className='p-2'><h4>{IMLocalized('loading')}..</h4></div>}
        {!props.isLoading && list.length === 0 && <div className='p-2'><h4>{IMLocalized('no_data')}</h4></div>}
        {!props.isLoading && list.length !== 0 && 
        <Table size="sm" className='mt-2 align-items-center table-bordered' responsive>
            <thead className='thead-light'>
                <tr>
                    <th>{IMLocalized('name')}</th>
                </tr>
            </thead>
            <tbody>
                {list.map((item,index)=>{
                    return(
                        <tr key={index}>
                            <td><span className='text-primary cursor-pointer' onClick={()=>toView(item.id)}>{item.name}</span></td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>}
        <ModalViewCertification  toggle={toggle} detail={detail} closeModal={()=>{setDetail(null); setToggle(false)}} />
        </>
    )
}
export default connect(mapStateToProps,null)(UserEmployeeCertification);
import * as delrecurring from '../action/delete_recurring';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const delete_recurring = (state=initialState,action)=>{
    switch(action.type){
        case delrecurring.DELRECURRING_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case delrecurring.DELRECURRING_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case delrecurring.DELRECURRING_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case delrecurring.DELRECURRING_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default delete_recurring;
import * as updatepaymentamount from '../../action/payroll/payslip_update_payroll_amount';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const payslip_update_payroll_amount = (state=initialState,action)=>{
    switch(action.type){
        case updatepaymentamount.DETAILUPDATEPAYMENTAMOUNT_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case updatepaymentamount.DETAILUPDATEPAYMENTAMOUNT_SUCCESS:
            return{
                ...state,
                data:action.payload,
                isLoading:false
            }
        case updatepaymentamount.DETAILUPDATEPAYMENTAMOUNT_FAILURE:
            return{
                ...state,
                errors:action.payload?.response?.data,
                isLoading:false
            }
        default:
            return state;
    }
}
export default payslip_update_payroll_amount ;
import * as addotp from '../../action/user/add_otp_list';

const initialState={
    isLoading:false,
    errors:null,
    data:null
}


const add_otp_list = (state=initialState,action)=>{
    switch(action.type){
        case addotp.ADDOTPLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case addotp.ADDOTPLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case addotp.ADDOTPLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case addotp.ADDOTPLIST_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state
    }
}
export default add_otp_list;
import * as wizardcreateflat from '../../action/wizard/wizard_create_flat';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const wizard_create_flat = (state=initialState,action)=>{
    switch(action.type){
        case wizardcreateflat.WIZARDCREATEFLAT_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case wizardcreateflat.WIZARDCREATEFLAT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case wizardcreateflat.WIZARDCREATEFLAT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default wizard_create_flat;
import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const DELGENERALCURRENCY_REQUEST = 'DELGENERALCURRENCY_REQUEST';
export const DELGENERALCURRENCY_SUCCESS = 'DELGENERALCURRENCY_SUCCESS';
export const DELGENERALCURRENCY_FAILURE = 'DELGENERALCURRENCY_FAILURE';
export const DELGENERALCURRENCY_RESET = 'DELGENERALCURRENCY_RESET';

export const delete_general_currency = (id)=>{
    return dispatch=>{
        dispatch({
            type:'DELGENERALCURRENCY_REQUEST'
        });

        myaxios.delete(`general/currency/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELGENERALCURRENCY_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title:IMLocalized('delete_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELGENERALCURRENCY_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_general_currency = () => dispatch =>{
    dispatch({
        type:'DELGENERALCURRENCY_RESET'
    })
}
import { AgGridReact } from "ag-grid-react";
import React, { useMemo, useContext, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { NewMainContext } from "../../../context/NewMainContext";
import { IMLocalized } from "../../../language/IMLocalized";
import { checkboxCellRenderer } from '../../../func/checkboxCellRenderer'
import OverlayLoading from "../../loading_component/OverlayLoading";
import 'ag-grid-community/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import '../../../css/aggrid.css';
import BreadCrumbList from "../../breadcrumb/BreadCrumbList";

import { get_employee_list } from '../../../action/employee/get_employee_list';
import { update_employee_profile_mass, reset_update_employee_profile_mass } from "../../../action/employee/update_employee_profile_mass";
import { update_employee_contract_mass, reset_update_employee_contract_mass } from "../../../action/employee/update_employee_contract_mass";

// import { employee_contract_additional_cost } from '../../../action/employee/employee_contract_additional_cost';
// import { employee_contract_department } from '../../../action/employee/employee_contract_department';
// import { employee_contract_section } from '../../../action/employee/employee_contract_section';
// import { employee_contract_costcenter } from '../../../action/employee/employee_contract_costcenter';
// import { employee_contract_joblevel } from '../../../action/employee/employee_contract_joblevel';
// import { employee_contract_jobtitle } from '../../../action/employee/employee_contract_jobtitle';
// import { update_employee_contract } from '../../../action/employee/update_employee_contract';
// import { get_additional_setting } from '../../../action/additional_setting';

import { get_employee_contract_list } from '../../../action/employee/get_employee_contract_list';
import { getScheduleList } from "../../../action/schedule_list";


const mapStateToProps=(state)=>({
    templatelist:state.get_contract_template_list.data,
    // schedulelist:state.schedule_list.data,
    // currencylist:state.general_currency.data,
    // departmentlist:state.add.department,
    // sectionlist:state.add.section,
    // jobtitlelist:state.add.jobtitle,
    // joblevellist:state.add.joblevel,
    // costcenterlist:state.add.costcenter,
    // allowancelist:state.add.allowance,
    // recurring_list:state.add.recurringallowance,
    // additionalcost:state.employee_contract_additional_cost.data,
    // department_success:state.employee_contract_department.data,
    section_success:state.employee_contract_section.data,
    joblevel_success:state.employee_contract_joblevel.data,
    jobtitle_success:state.employee_contract_jobtitle.data,
    costcenter_success:state.employee_contract_costcenter.data,
    update_errors:state.update_employee_contract.errors,
    isLoading:state.update_employee_contract.isLoading,
    list2:state.get_employee_contract_list.data,
    isLoading2:state.get_employee_contract_list.isLoading,
    isLoading3:state.schedule_list.isLoading,
    schedulelist:state.schedule_list.data,
})

const mapDispatchToProps=(dispatch)=>({

    reset_update_employee_contract_mass:()=>{
        dispatch(reset_update_employee_contract_mass());
    },
    get_employee_list:()=>{
        dispatch(get_employee_list());
    },
    // employee_contract_department:()=>{
    //     dispatch(employee_contract_department());
    // }
    get_employee_contract_list:()=>{
        dispatch(get_employee_contract_list());
    },
    get_schedule_list:()=>{
        dispatch(getScheduleList())
    }

})

function EmployeeMassUpdateContract(props){

    const { contractlist, get_schedule_list, schedulelist } = props;
    const { addItem, action_toggle } = useContext(NewMainContext);
    // const list = useSelector(state=>state.get_employee_list.data);
    // const isLoading = useSelector(state=>state.get_employee_list.isLoading);
    const list = useSelector(state=>state.get_employee_contract_list.data)
    const isLoading = useSelector(state=>state.get_employee_contract_list.isLoading);
    const dispatch = useDispatch();
    // const success = useSelector(state=>state.update_employee_profile_mass.data);
    const dropdownlist = useSelector(state=>state.dropdown_list.data);
    // const companylist = useSelector(state=>state.company_list.data);
    const isLoading1 = useSelector(state=>state.company_list.isLoading);
    
    const [ validateList, setValidateList] = useState([]);
    const [ rowData, setRowData] = useState([]);
    const [ step, setStep] = useState(0);

    const [ dummy, setDummy] = useState(1);

    useEffect(()=>{
            dispatch(reset_update_employee_contract_mass());
            // dispatch(get_employee_list());
            dispatch(get_employee_contract_list());
            get_schedule_list();

    },[dispatch,dummy])

    useEffect(()=>{
        const arr = list.map((item)=>{
            const { contract } = item;
            const start_date = contract?.start_date;
            const contract_type = contract?.contract_type;
            const contract_rate = contract?.contract_rate;
            const pay = contract?.pay;
            const is_workman = contract?.is_workman === true ? 'true' : 'false';
            const wschedule_detail = contract?.wschedule_detail;
            const wschedule = contract?.wschedule;
            const hourly_rate = contract?.hourly_rate;
            const hours_day = contract?.hours_day;
            const daily_rate = contract?.daily_rate;
            const department = contract?.department;
            const section = contract?.section;
            const cost_center = contract?.cost_center;
            const job_title = contract?.job_title;
            const job_level = contract?.job_level;

            const contract_id = contract?.id;

            const empid = item.id
            const empnumber = item.employee_number;
            const empname = item.name;
            // employee_number:empid, name:empname,

            // return {...item, edit_is_workman: false, id :item.id,
            return {edit_is_workman: false, id :contract_id, employee_number:empnumber, name:empname,employee:empid,
                // contract:{ start_date,contract_type,contract_rate,pay,is_workman,wschedule_detail,hourly_rate,hours_day,
                //     daily_rate,department,section,cost_center,job_title,job_level } }
                    start_date,contract_type,contract_rate,pay,is_workman,wschedule,hourly_rate,hours_day,wschedule_detail,
                        daily_rate,department,section,cost_center,job_title,job_level }
        });

        setRowData(arr);
        setValidateList([]);

    },[list, step, dummy])

    const onCellValueChanged = (params) =>{
        if(params.oldValue !== params.newValue){
            const {data} = params;
            const {id} = params.data;
            const { field } = params.colDef;
            var row = params.rowIndex;
            var rowNode = params.api.getDisplayedRowAtIndex(row);
            var column = params.column;

            const colDef = params.column.getColDef();

            // if(field === 'nationality') params.data.edit_nationality = true;
            // if(field === 'email') {
            //     params.data.edit_email = true;
            //     if((params.newValue.includes('@') && params.newValue.includes('.com')) || params.newValue===''){
            //      }else {                    
            //         alert('Incorrect email format')
            //         params.api.undoCellEditing({rowNodes:[rowNode],columns:[column]})}
            // }
            if(field === 'is_workman') params.data.edit_is_workman = true;

            //IS WORKMAN?
            if(colDef.field === 'is_workman'){
                const pay = data.pay
                let rate = (parseFloat(pay)*12)/(52*44);

                if(data.is_workman === 'true'){
                    if(pay > 4500){
                        rate = (4500*12)/(52*44);
                    }
                }else{
                    if(pay >= 2600){
                        rate = 13.60
                    }
                }
                params.data.hourly_rate = rate.toFixed(8);
                params.api.refreshCells({ columns: ['hourly_rate'], rowNodes: [params.node], force: true });
            }
            //DAILY RATE
            if(data.contract_rate === 'DAILY'){

                //CALCULTE HOURLY RATE
                if(colDef.field === 'daily_rate') {
                    const dailyRate = data.daily_rate || 0;
                    const hours_day = data.hours_day || 0;
                    const newHourlyRate = (dailyRate/hours_day).toFixed(8);  
                    params.data.hourly_rate = newHourlyRate;
                    params.api.refreshCells({ columns: ['hourly_rate'], rowNodes: [params.node], force: true });
                }

                if(colDef.field === 'hours_day'){
                    const dailyRate = data.daily_rate || 0;
                    const hours_day = data.hours_day || 0;
                    const newHourlyRate = (dailyRate/hours_day).toFixed(8);  
                    params.data.hourly_rate = newHourlyRate;
                    params.api.refreshCells({ columns: ['hourly_rate'], rowNodes: [params.node], force: true });
                }
            }

            //MONTHLY RATE
            if(data.contract_rate === 'MONTHLY'){

                //CALCULTE HOURLY RATE
                if(colDef.field === 'pay') {
                    // const dailyRate = data.contract.daily_rate || 0;
                    // const hours_day = data.contract.hours_day || 0;

                    let pay = params.newValue
                    let rate = (pay*12)/(52*44).toFixed(8)
                    
                    if(data.is_workman === 'true'){
                        if(pay > 4500){
                            rate = (4500*12)/(52*44).toFixed(8)
                        }
                    }else{
                        if(pay >= 2600){
                            rate = 13.60.toFixed(8)
                        }
                    }
                    params.data.hourly_rate = rate;
                    params.api.refreshCells({ columns: ['hourly_rate'], rowNodes: [params.node], force: true });

                }

            }

            //CONTRACT RATE
            if(colDef.field === 'contract_rate'){
                // if(params.newValue === 'HOURLY'){
                //     params.data.pay = params.data.hourly_rate;
                //     params.api.refreshCells({ columns: ['pay'], rowNodes: [params.node], force: true });
                // }
                // else if(params.newValue === 'DAILY'){
                //     params.data.pay = params.data.daily_rate;;
                //     params.api.refreshCells({ columns: ['pay'], rowNodes: [params.node], force: true });
                // }
                if(params.newValue === 'HOURLY' || params.newValue === 'DAILY'){
                    params.data.pay = 0;
                    params.api.refreshCells({ columns: ['pay'], rowNodes: [params.node], force: true });
                }
            }

            const detail = validateList.findIndex(item=>item.id === id);
            if(detail === -1){
                setValidateList(prevState=>[...prevState,params.data]);
            }
            else{
                setValidateList(prevState=>{
                    const arr = [...prevState];
                    arr[detail] = params.data;
                    return arr;
                })
            }
            params.api.flashCells({rowNodes:[rowNode],columns:[column]})
            params.api.refreshCells();
            
        }
    }

    const updateSelf = ()=>{
        const data = validateList.map(item=>item)
        dispatch(update_employee_contract_mass(data));
        setDummy((v)=>v + dummy)
    }

    const gridOptions={
        defaultColDef:{
            flex:1,
            minWidth:100,
            sortable:true,
            filter:true,
            resizable:true,
        },
    }

    const sideBar = useMemo(() => {
        return {
          toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
              },
            },
          ],
          defaultToolPanel: 'columns',
        };
      }, []);

      function contractRateFormatter(params) {
        const idd = dropdownlist?.rate?.filter((item)=> item.value === params.value).map((x)=>x.display)
        return idd
      }

      function contractTypeFormatter(params) {
        const idd = dropdownlist?.employee_type?.filter((item)=> item.value === params.value).map((x)=>x.display)
        return idd
      }

      function workingScheduleFormatter(params) {
        const idd = schedulelist?.filter((item)=> item.id === params.value).map((x)=>x.name)
        return idd
      }

    return(
        <>
        {isLoading || isLoading1 || props.isLoading3 ? <OverlayLoading/> :
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title:IMLocalized('employee_mass_update_contract')}
                    ]}/>
                </Col>
            </Row>
            <Row className="mt-2 justify-content-center">
                <Col>
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-primary btn-sm" onClick={()=>setStep(step+1)}>{IMLocalized('clear')}</button>
                        <button className="btn btn-primary btn-sm" onClick={updateSelf}>{IMLocalized('update')}</button>
                    </div>
                    <div className="ag-theme-balham mt-2" style={{height:action_toggle ? 'calc(100vh - 250px)' :'calc(100vh - 214px)' , width:'100%' }}>
                        <AgGridReact
                            enableRangeSelection={true}
                            sideBar={sideBar}
                            gridOptions={gridOptions}
                            rowData={rowData}
                            onCellValueChanged={onCellValueChanged}
                            cellFlashDelay={999999}
                            // cellFadeDelay={500}
                            undoRedoCellEditing={true}
                            columnDefs={[
                                { 
                                    headerName:IMLocalized('employee_number'),
                                    field:'employee_number',
                                    sortable:true,
                                    editable:false,
                                    lockVisible: true,
                                },
                                { 
                                    headerName:IMLocalized('name'),
                                    field:'name',
                                    sortable:true,
                                    editable:false,
                                    enableCellChangeFlash:true,
                                    undoRedoCellEditing: true,
                                },
                                { 
                                    headerName:IMLocalized('start_date'),
                                    field:'start_date',
                                    sortable:true,
                                    editable:true,
                                    enableCellChangeFlash:true,
                                },
                                { 
                                    headerName:IMLocalized('type'),
                                    field:'contract_type',
                                    sortable:true,
                                    editable:true,
                                    enableCellChangeFlash:true,
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {
                                        values:dropdownlist?.employee_type?.map((item)=>(item.value)),
                                      },
                                    valueFormatter:contractTypeFormatter,
                                },
                                { 
                                    headerName:IMLocalized('contract_rate'),
                                    field:'contract_rate',
                                    sortable:true,
                                    editable:true,
                                    enableCellChangeFlash:true,
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {
                                        values:dropdownlist?.rate?.map((item)=>(item.value)),
                                      },
                                    valueFormatter:contractRateFormatter,
                                },
                                { 
                                    headerName:IMLocalized('is_workman'),
                                    field:'is_workman',
                                    sortable:true,
                                    editable:false,
                                    cellRenderer:checkboxCellRenderer,
                                    width:150,
                                    cellClassRules:{
                                        'green-bg':(params)=>{return params.data.edit_is_workman},
                                    },
                                },
                                { 
                                    headerName:IMLocalized('working_schedule'),
                                    field:'wschedule',
                                    // field:'schedulelist.name',
                                    sortable:true,
                                    editable:true,
                                    enableCellChangeFlash:true,
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {
                                        values:schedulelist?.map((item)=>(item.id)),
                                      },
                                    valueFormatter:workingScheduleFormatter,
                                },
                                { 
                                    headerName:IMLocalized('basic_pay'),
                                    field:'pay',
                                    sortable:true,
                                    editable: (params) => {
                                        return params.data.contract_rate === 'MONTHLY';
                                    },
                                    enableCellChangeFlash:true,
                                },
                                { 
                                    headerName:IMLocalized('hours_per_day'),
                                    field:'hours_day',
                                    sortable:true,
                                    editable: (params) => {
                                        return params.data.contract_rate === 'DAILY';
                                    },
                                    enableCellChangeFlash:true,
                                },
                                { 
                                    headerName:IMLocalized('daily_rate'),
                                    field:'daily_rate',
                                    sortable:true,
                                    editable:true,
                                },
                                { 
                                    headerName:IMLocalized('hourly_rate'),
                                    field:'hourly_rate',
                                    sortable:true,
                                    editable:true,
                                    enableCellChangeFlash:true,
                                    valueSetter: (params) => {
                                        const data = params.data;
                                        if (data.daily_rate !== 0 && data.wschedule_detail.work_days_in_week !== 0) {
                                            const calculatedValue = (data.daily_rate * 12) / (data.wschedule_detail.work_days_in_week * 52);
                                            data.hourly_rate = calculatedValue;
                                            return true;
                                        }
                                        return false;
                                    }    
                                },                                
                                { 
                                    headerName:IMLocalized('department'),
                                    field:'department',
                                    sortable:true,
                                    editable:true,
                                    enableCellChangeFlash:true,
                                },
                                { 
                                    headerName:IMLocalized('section'),
                                    field:'section',
                                    sortable:true,
                                    editable:true,
                                    enableCellChangeFlash:true,
                                },
                                { 
                                    headerName:IMLocalized('cost_center'),
                                    field:'cost_center',
                                    sortable:true,
                                    editable:true,
                                    enableCellChangeFlash:true,
                                },
                                { 
                                    headerName:IMLocalized('job_title'),
                                    field:'job_title',
                                    sortable:true,
                                    editable:true,
                                    enableCellChangeFlash:true,
                                },
                                { 
                                    headerName:IMLocalized('job_level'),
                                    field:'job_level',
                                    sortable:true,
                                    editable:true,
                                    enableCellChangeFlash:true,
                                },


                            ]}
                        >
                        </AgGridReact>
                    </div>
                </Col>
            </Row>
        </Container>}
        </>
    )
}

export default connect(mapStateToProps,mapDispatchToProps)(EmployeeMassUpdateContract);
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const WIZARDCREATEOVERTIME_REQUEST = 'WIZARDCREATEOVERTIME_REQUEST';
export const WIZARDCREATEOVERTIME_SUCCESS = 'WIZARDCREATEOVERTIME_SUCCESS';
export const WIZARDCREATEOVERTIME_FAILURE = 'WIZARDCREATEOVERTIME_FAILURE';

export const wizard_create_overtime = (data)=>{
    return dispatch=>{
        dispatch({
            type:'WIZARDCREATEOVERTIME_REQUEST'

        });

        myaxios.post('company/overtime/array_save/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'WIZARDCREATEOVERTIME_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'WIZARDCREATEOVERTIME_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}
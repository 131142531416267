import React, { useContext, useEffect, useRef, useState } from 'react';
import {connect} from 'react-redux';
import {AgGridReact} from 'ag-grid-react';
import moment from 'moment';
import {saveAs} from 'file-saver';
import {IMLocalized} from '../../language/IMLocalized';
import 'ag-grid-enterprise';
import { Container, Row, Col, Input } from 'reactstrap';
import { employee_payslip_date_filter, reset_payroll_date } from '../../action/report/employee_payslip_date_filter';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import JSZip from 'jszip';
import { Buffer } from 'buffer';
import { isFirstColumn } from '../ag-grid_component/isFirstColumn';
import { popError } from '../../func/popError';
import { getDateFormat } from '../../func/getDateFormat';
import OverlayLoading from '../loading_component/overlay_loading';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';
import { get_payroll_instance_array, reset_payroll_instance_array } from '../../action/payroll/get_payroll_instance_array';
import ProgressBar from '../loading_component/progress_loading';


const mapStateToProps=(state)=>({
    payroll_list:state.get_payroll_list.data,
    emplist:state.get_employee_list.data,
    list:state.employee_payslip_date_filter.data,
    letterhead:state.get_general_letter.data,
    pay_component_type:state.dropdown_list.pay_component_type,
    paymentlist:state.dropdown_list.payment,
    wagelist:state.dropdown_list.wage,
    isLoading:state.employee_payslip_date_filter.isLoading,
    isLoading1:state.get_employee_list.isLoading,
    isLoading2:state.get_payroll_list.isLoading,
    instance:state.get_payroll_instance_array.data
})

const mapDispatchToProps=(dispatch)=>({
    employee_payslip_date_filter:(from,to)=>{
        dispatch(employee_payslip_date_filter(from,to))
    },
    get_payroll_instance_array:(id)=>{
        dispatch(get_payroll_instance_array(id))
    },
    reset_payroll_date:()=>{
        dispatch(reset_payroll_date())
    },
    reset_payroll_instance_array:()=>{
        dispatch(reset_payroll_instance_array())
    }
})

const gridOptions={
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
    sideBar:{
        toolPanels: [
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                toolPanelParams: {},
            },
        ],
        defaultToolPanel: 'filters',
    },
    defaultColDef:{
        editable:false
    }
}

const initialState={
    from_payroll:'',
    to_payroll:'',
    active_employee:true,
    resignee:false,
    rowData:[],
    is_pending:false,
    selected_option:'',
    selected_emp:[],
    head:null,
    from_payroll_index:'',
    to_payroll_index:''
}

function PayrollPayslipReport(props){
    const {get_payroll_instance_array, reset_payroll_date} = props
    const { action_toggle, addItem, payroll_payslip_employee_reset, setPayrollPayslipEmployeeReset } = useContext(NewMainContext);
    const [state,setState]= useState(initialState);
    const [monthName] = useState([IMLocalized('january'), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
    IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")
    ]);
    const [monthName1] = useState(['January','February','March','April','May','June','July','August','September','October','November','December']);
    const gridApi = useRef();
    const gridColumnApi = useRef();
    const [filteredArr, setFilteredArr] = useState([])
    const [exportLoading, setExportLoading] = useState(false)

    useEffect(()=>{
        if(props.list.length !== 0 && state.is_pending){
            let data  = [];
            for(const item of props.list){
                data.push(item);
                setFilteredArr(data);
            }
        }
    },[props.list])

    useEffect(()=>{
        if(props.list != null && state.is_pending){

            let idarr = []
            for(let x=0;x<props.list.length;x++){
                let idd = props.list[x]
                idarr.push(idd)          
            
            
            }
            get_payroll_instance_array(idarr);
            setState(prevState=>({...prevState,is_pending:true}));
        }
    },[props.list,props.get_payroll_instance_array])

    useEffect(()=>{
        if(props.instance.length !== 0){

            if(props.instance[0]?.loop < (props.list.length-1) || props.instance[0]?.loop === null || props.instance[0]?.loop===undefined){
                setExportLoading(true)
            }else{
                setFilteredArr([])
                setExportLoading(false)
                reset_payroll_instance_array();
                reset_payroll_date();
            }
        }
        },[setExportLoading,props.instance ])

    useEffect(()=>{
        if(payroll_payslip_employee_reset === true){
            setPayrollPayslipEmployeeReset(false);
            setState(initialState);
        }
    },[payroll_payslip_employee_reset,setPayrollPayslipEmployeeReset])

    useEffect(()=>{
        const arr = props.emplist.filter(item=>(item.active === true && item.resignation_date === null && state.active_employee === true) || (state.resignee === true && item.resignation_date !== null)).filter(item=>item.contract_cache !== null);
        setState(prevState=>({...prevState,rowData:arr}));
    },[props.emplist,state.resignee,state.active_employee])

    useEffect(()=>{
        if(props.letterhead != null && props.letterhead.length !== 0){
            const picture = props.letterhead.data;
            const byte = picture.byteLength;
            if(byte === 0){
                setState(prevState=>({...prevState,head:null}));
            }
            else{
                const img = new Buffer.from(picture,'binary').toString('base64');
                const src = `data:image/png;base64,${img}`;
                setState(prevState=>({...prevState,head:src}));
            }
        }
    },[props.letterhead])

    // useEffect(()=>{
    //     if(props.instance.length !== 0){
    //         let idd = props.instance?.map((j)=>j)
    //         setState(prevState=>({...prevState,is_pending:true}));
    //         setFilteredArr(prevState=>[...prevState,idd])
    //     }
        
    // },[get_payroll_instance_array,props.instance])

    useEffect(()=>{
        if(props.instance.length !== 0){
            const zip = new JSZip();
            for(const data of props.instance){
                const month1 = new Date(data.payroll_date).getMonth();
                const year = new Date(data.payroll_date).getFullYear();
                const report_date = `${monthName1[month1]}-${year}`;
                const today = moment().format('DD/MM/YYYY');
                const {payslips} = data;
                let index = 0;
                for(const emp_data of state.selected_emp){
                    const detail = payslips.filter(item=>item.employee.id === emp_data.id);
                    const doc = new jsPDF();
                    const payee = emp_data.name;
                    for(const item of detail){
                        const head1 = [[{content:'Date of Payment'}]];
                        const head2 = [[{content:'Mode of Payment'}]];
                        const head3 = [[{content: 'Salary (A)', styles: {halign: 'left'}}, {content: 'Amount', styles: {halign: 'right'}}]];
                        const head4 = [[{content: 'Total Allowances (B)', styles: {halign: 'left'}}, {content: 'Amount', styles: {halign: 'right'}}]];
                        const head5 = [[{content: 'Total Deductions (C)', styles: {halign: 'left'}}, {content: 'Amount', styles: {halign: 'right'}}]];
                        const head6 = [[{content: 'Overtime (D)'},{content:'Period',styles:{halign:'center'}},{content:'Hrs Work',styles:{halign:'center'}},{content:'Wage',styles:{halign:'center'}},{content: 'Amount', styles: {halign: 'right'} }]];
                        const head7 = [[{content: 'Other Pay (E)', styles: {halign: 'left'}}, {content: 'Amount', styles: {halign: 'right'}}]];
                        let cell1 =[];
                        let cell2 =[];
                        let cell3 = [];
                        let cell4 = [];
                        let cell5 = [];
                        let cell6 = [];
                        let cell7 = [];
                        let cell8 = [];
                        const {payment,metadata,} = item.employee;
                        const {sgcpf,sgshg,payslipcontract,pay,payable_wage} = item;
                        const payment_detail = props.paymentlist.find(item=>item.value === payment);
                        const payment_name = payment_detail?.display ? `${payment_detail.display} Deposit`  :'';
                        if(payment != null && payment !== ''){
                            cell2.push([payment_name]);
                        }
                        
                        const payer = metadata.company;
                    
                        let annual_amount = null;
                        if(metadata.hasOwnProperty('annual_amt')){
                            const annual_amt = metadata['annual_amt'];
                            const check = Array.isArray(annual_amt);
                            if(!check){
                                annual_amount = annual_amt;
                            }
                            else{
                                const month = new Date().getMonth();
                                annual_amount = annual_amt[month];
                            }
                        }

                        const string = `Employer : ${payer}`;
                        const string1 = `Employee : ${payee}`;
                        const string2 = `Printed Date : ${today}`;
                        const string3 = `Payslip for ${getDateFormat(data.start_date)} to ${getDateFormat(data.end_date)}`;
                        
                        if(data.paid_date !== null){
                            cell1.push([getDateFormat(data.paid_date)]);
                        }

                        const claimlist = pay.filter(el=>el.pay_type === 'CLAIM');
                        const overtimelist = pay.filter(el=>el.pay_type === 'OT');
                        const allowancelist = pay.filter(el=>el.pay_type === 'ALLOWANCE');
                        const recurringlist = pay.filter(el=>el.pay_type === 'RA');
                        const flatlist = pay.filter(el=>el.pay_type === 'FX');
                        const backpaylist = pay.filter(el=>el.pay_type === 'BACKPAY' || el.pay_type === 'COMM' || el.pay_type === 'GRATUITY' || el.pay_type === 'INSURANCE' || el.pay_type === 'SHARES' || el.pay_type === 'DIRECTORS' || el.pay_type === 'PENSION' || el.pay_type === 'RETIREMENT' || el.pay_type === 'OTHERS' || el.pay_type === 'DEDUCT');
                        const absentlist = pay.filter(el=>el.pay_type === 'ABS');
                        const bonuslist = pay.filter(el=>el.pay_type === 'BONUS');
                        const deductablelist = pay.filter(el=>el.pay_type === 'DEDUCT');
                        const nationlist = pay.filter(el=>el.pay_type === 'NSMUP');
                        const nopaylist = pay.filter(el=>el.pay_type === 'NPL');

                        for(const contract_data of payslipcontract){
                            cell3.push([`Basic Pay (${getDateFormat(contract_data.from_date)} to ${getDateFormat(contract_data.to_date)})`,`$${(contract_data.gross_month).toFixed(2)}`])
                        }
                    
                        for(const overtime of overtimelist){
                            const {name,is_gross,amount,wage,count,payslip_contract} = overtime;
                            if(!is_gross && amount > 0){
                                const wage_detail = props.wagelist.find(el=>el.value === wage);
                                const wage_name = wage_detail?.display ?? '-';
                                const detail = payslipcontract.find(el=>el.id === payslip_contract);
                                const date = detail ? `${getDateFormat(detail.from_date)} to ${getDateFormat(detail.to_date)}` : '';
                                cell6.push([name,date,count,wage_name,`$${(amount).toFixed(2)}`]);
                            }
                        }

                        let allowance_length = 0
                        for(const allowance of allowancelist){
                            const {amount,name,is_gross} = allowance;
                            if(!is_gross && amount >0){
                                cell4.push([name,`$${(amount).toFixed(2)}`]);
                                allowance_length++;
                            }
                        }

                        for(const recurring of recurringlist){
                            const {amount,name,is_gross} = recurring;
                            if(!is_gross && amount >0){
                                cell4.push([name,`$${(amount).toFixed(2)}`]);
                            }
                        }

                        for(const flat of flatlist){
                            const {amount,code,is_gross} = flat;
                            if(!is_gross && amount >0){
                                cell7.push([code,`$${(amount).toFixed(2)}`]);
                            }
                        }

                        for(const backpay of backpaylist){
                            const {amount,pay_type,deductable,is_gross,name} = backpay;
                            if(!is_gross && !deductable && amount >0 ){
                                const pay_detail = props.pay_component_type.find(el=>el.value === pay_type);
                                const pay_name = pay_detail?.display ?? '-';
                                cell7.push([`${pay_name} (${name})`,`$${amount.toFixed(2)}`]);
                            }
                        }

                        for(const bonus of bonuslist){
                            const {amount,pay_type,is_gross} = bonus;
                            if(!is_gross && amount >0){
                                const pay_detail = props.pay_component_type.find(el=>el.value === pay_type);
                                const pay_name = pay_detail?.display ?? '-';
                                cell7.push([pay_name,`$${(amount).toFixed(2)}`]);
                            }
                        }

                        for(const claim of claimlist){
                            cell7.push(['Total Claim (E)',`$${(claim.amount).toFixed(2)}`]);
                        }

                        if(sgcpf !== null){
                            cell5.push(['CPF Employee', `$${(sgcpf.cpf_employee).toFixed(2)}`]);
                        }

                        if(sgshg !== null){
                            for(const shg_item of sgshg){
                                cell5.push([shg_item.shg,`$${(shg_item.amount).toFixed(2)}`]);
                            }
                        }

                        for(const nopay of nopaylist){
                            const {code,amount} = nopay;
                            cell5.push([code,`$${amount.toFixed(2)}`]);
                        }

                        for(const nation of nationlist){
                            const {code,amount} = nation;
                            cell5.push([code,`$${amount.toFixed(2)}`]);
                        }

                        for(const absent of absentlist){
                            const {amount,pay_type} = absent;
                            const detail = props.pay_component_type.find(el=>el.value === pay_type);
                            const name = detail?.display ?? '';
                            cell5.push([name,`$${amount.toFixed(2)}`]);
                        }

                        for(const deduct of deductablelist){
                            const {pay_type,amount,name} = deduct;
                            const detail = props.pay_component_type.find(el=>el.value === pay_type);
                            const name1 = detail?.display ?? '';
                            cell5.push([`${name1} (${name})`,`$${amount.toFixed(2)}` ]);
                        }

                        cell8.push(['Net Pay (A+B-C+D+E)',`$${payable_wage.toFixed(2)}`]);
                        if(sgcpf !== null){
                            const cpf_employer = sgcpf.cpf_employer;
                            cell8.push(["Employer's CPF Contributions" , `$${(cpf_employer).toFixed(2)}`]);
                        }

                        if(annual_amount !== null){
                            cell8.push(['Annual Leave Balance',annual_amount])
                        }

                        if(state.head !== null){
                            doc.addImage(state.head,'JPEG',20,10,180,40);
                        }

                        doc.setFontSize(10);
                        doc.text(string,15,60,'left');
                        doc.text(string1,107,60,'left');
                        doc.text(string2,15,70,'left');
                        doc.text(string3,107,70,'left');
                        doc.autoTable({
                            head:head1,
                            body:cell1,
                            startY:80,
                            tableWidth:90,
                            columnStyles:{ 0: {halign:'left'}}
                        })
                        doc.autoTable({
                            head:head2,
                            body:cell2,
                            startY:80,
                            margin: {left: 107} ,
                            columnStyles:{ 0: {halign:'left'}}
                        })

                        doc.autoTable({
                            head:head3,
                            body:cell3,
                            tableWidth:90,
                            startY:100,
                            columnStyles:{ 0: {halign:'left'},1: {halign:'right'}}
                        })

                        doc.autoTable({
                            head:head4,
                            body:cell4,
                            margin: {left: 107} ,
                            startY:100,
                            columnStyles:{ 0: {halign:'left'},1: {halign:'right'}}
                        })

                        let abc = doc.autoTable.previous.finalY + 5
                        if(allowance_length === 0){
                            const length = payslipcontract.length;
                            const number = length * 5 + 10
                            abc = doc.autoTable.previous.finalY + number
                        }
                        
                        doc.autoTable({
                            head:head5,
                            body:cell5,
                            startY: typeof doc.autoTable.previous.finalY === 'undefined' ? 50 : abc,
                            columnStyles:{ 0: {halign:'left'},1: {halign:'right'}}
                        })

                        doc.autoTable({
                            head:head6,
                            body:cell6,
                            startY: typeof doc.autoTable.previous.finalY === 'undefined' ? 50 : doc.autoTable.previous.finalY + 5,
                            columnStyles:{ 0: {halign:'left'},1:{halign:'center'},2:{halign:'center'},3:{halign:'center'},4: {halign:'right'}}
                        })

                        doc.autoTable({
                            head:head7,
                            body:cell7,
                            startY: typeof doc.autoTable.previous.finalY === 'undefined' ? 50 : doc.autoTable.previous.finalY + 5,
                            columnStyles:{ 0: {halign:'left'},1: {halign:'right'}},
                        })
                        doc.autoTable({
                            head:[],
                            body:cell8,
                            startY: typeof doc.autoTable.previous.finalY === 'undefined' ? 50 : doc.autoTable.previous.finalY + 5,
                            columnStyles:{ 0: {halign:'left'},1: {halign:'right'}},
                        })
                    }
                    zip.file(`${payee}.pdf`,doc.output('blob'));
                    if(index === state.selected_emp.length-1){
                        zip.generateAsync({type:'blob'}).then(function(content) {
                            saveAs(content, 'payslip-'+report_date+'.zip');
                        });
                        setState(prevState=>({...prevState,is_pending:false}));
                    }
                    index++ ;
                }
            }
        }
    },[props.instance,props.pay_component_type,props.wagelist,props.paymentlist,monthName1,state.head,state.selected_emp])

    let from_payroll_int = parseInt(state.from_payroll_index);
    const from_index = props.payroll_list.findIndex((item,index)=> index === from_payroll_int);

    const arr2  = from_index === -1 ? [] :  props.payroll_list.slice(from_index,props.payroll_list.length);

    let to_payroll_int = parseInt(state.to_payroll_index);
    const to_index = arr2.findIndex((item,index)=> index === to_payroll_int);

    useEffect(()=>{
        if(state.from_payroll_index !== null){
            setState(prevState=>({...prevState,from_payroll:props.payroll_list[from_index]?.payroll_date,to_payroll:''}))
        }

        if(state.to_payroll_index !== null){
            setState(prevState=>({...prevState,to_payroll:arr2[to_index]?.payroll_date}))
        }
        
    },[state.from_payroll_index,state.to_payroll_index])

    return(
        <>
        {/* {props.isLoading1 || props.isLoading2 ? <OverlayLoading /> : */}

        {exportLoading ?
        <>
        <OverlayLoading />
        <ProgressBar value={props.instance[0]?.loop} maxValue={props.list.length-1}  />
        </>
        :
        <>
        <Container fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    <BreadCrumbList list={[
                        { active: false, title: IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title: IMLocalized('employee_payslip')}
                    ]}/>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col className='d-flex align-items-center'>
                    <Input size="sm" type="select" value={state.from_payroll_index} style={{width:'150px'}}
                    onChange={(e)=>{
                        const value = e.target.value;
                        setState(prevState=>({...prevState,from_payroll_index:value,to_payroll:''}))
                    }}>
                        <option value="">{IMLocalized('select_payroll')}</option>
                        {props.payroll_list.map((item,index)=>{
                            const month = new Date(item.payroll_date).getMonth();
                            const year = new Date(item.payroll_date).getFullYear();
                            // return <option value={item.payroll_date} key={index}>{item.type==="BONUS"?"Bonus ":item.type==="HMTH1"?"Half of ":""}{monthName[month]} {year}</option>
                            return <option value={index} key={index}>{item.type==="BONUS"?"Bonus ":item.type==="HMTH1"?"Half of ":""}{monthName[month]} {year}</option>
                        })}
                    </Input>
                    <span className='ml-2 mr-2'>{IMLocalized('to')}</span>
                    <Input size="sm" type="select" value={state.to_payroll_index} style={{width:'150px'}}
                    onChange={(e)=>{
                        const value = e.target.value;
                        setState(prevState=>({...prevState,to_payroll_index:value}))
                    }}>
                        <option value="">{IMLocalized('select_payroll')}</option>
                        {arr2.map((item,index)=>{
                            const month = new Date(item.payroll_date).getMonth();
                            const year = new Date(item.payroll_date).getFullYear();
                            // return <option value={item.payroll_date} key={index}>{monthName[month]} {year}</option>
                            return <option value={index} key={index}>{item.type==="BONUS"?"Bonus ":item.type==="HMTH1"?"Half of ":""}{monthName[month]} {year}</option>
                        })}
                    </Input>
                </Col>
            </Row>
            <div className='mt-2 d-flex'>
                <div className='custom-control custom-checkbox'>
                    <input type="checkbox" id='emp_payslip_report_active' name='active_employee' className='custom-control-input' checked={state.active_employee} 
                    onChange={(e)=>{
                        const checked = e.target.checked;
                        setState(prevState=>({...prevState,active_employee:checked}))
                    }} 
                    />
                    <label className='custom-control-label' htmlFor='emp_payslip_report_active'>{IMLocalized('active_user')}</label>
                </div>
                <div className='custom-control custom-checkbox ml-2 mr-2'>
                    <input type="checkbox" id='emp_payslip_report_resignee' name='resignee' className='custom-control-input' checked={state.resignee} 
                    onChange={(e)=>{
                        const checked = e.target.checked;
                        setState(prevState=>({...prevState,resignee:checked}))
                    }} 
                    />
                    <label className='custom-control-label' htmlFor='emp_payslip_report_resignee'>{IMLocalized('resignee')}</label>
                </div>
                {props.isLoading ? <button className="btn btn-success btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button> : 
                <button className="btn btn-success btn-sm" 
                onClick={()=>{
                    if(state.from_payroll !== ''){
                        const to_payroll = state.to_payroll !== '' ? state.to_payroll : state.from_payroll;
                        const data =gridApi.current.getSelectedRows();

                        if(data.length !== 0 ){
                            props.employee_payslip_date_filter(to_payroll,state.from_payroll);
                            setState(prevState=>({...prevState,is_pending:true,selected_emp:data,selected_option:state.select_option}))
                        }
                        else{
                            popError(IMLocalized('select_employee_to_export'));
                        }
                    }
                    else{
                        popError(IMLocalized('pls_select_payroll_month'));
                    }
                }}>{IMLocalized('export')}</button>}
            </div>
            <div className='mt-2 ag-theme-balham' style={{height:action_toggle ? 'calc(100vh - 288px)' : 'calc(100vh - 252px)' , width:'100%' }} >
                <AgGridReact
                    gridOptions={gridOptions}
                    rowData={state.rowData}
                    suppressDragLeaveHidesColumns={true}
                    suppressSizeToFit={true}
                    suppressColumnMoveAnimation={false}
                    overlayNoRowsTemplate = {IMLocalized('no_data')}
                    onGridReady={(params)=>{
                        gridApi.current = params.api;
                        gridColumnApi.current = params.columnApi;
                    }}
                    columnDefs={[
                        {
                            headerName:IMLocalized('agrid_select'),
                            field:'is_select',
                            headerCheckboxSelection: isFirstColumn,
                            checkboxSelection: isFirstColumn,
                            headerCheckboxSelectionFilteredOnly:true,
                            editable:false,
                            width:150,
                            filter:false,
                            pinned:'left',
                            lockPosition:true
                        },
                        {
                            headerName:IMLocalized('agrid_emp_id'),
                            field:'employee_number',
                            editable:false,
                            width:150,
                            filter:false,
                        },
                        {
                            headerName:IMLocalized('agrid_emp_name'),
                            field:'name',
                            editable:false,
                            width:150,
                            filter:false,
                        },
                        {
                            headerName:IMLocalized('agrid_cost_center'),
                            field:'contract_cache.cost_center',
                            editable:false,
                            width:150,
                            filter:true
                        },
                        {
                            headerName:IMLocalized('agrid_department'),
                            field:'contract_cache.department',
                            editable:false,
                            width:150,
                            filter:true
                        },
                        {
                            headerName:IMLocalized('agrid_section'),
                            field:'contract_cache.section',
                            editable:false,
                            width:150,
                            filter:true
                        },
                        {
                            headerName:IMLocalized('agrid_job_title'),
                            field:'contract_cache.job_title',
                            editable:false,
                            width:150,
                            filter:true
                        },
                        {
                            headerName:IMLocalized('agrid_job_level'),
                            field:'contract_cache.job_level',
                            editable:false,
                            width:150,
                            filter:true
                        }
                    ]}
                />
            </div>
        </Container>
        </>}
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(PayrollPayslipReport);
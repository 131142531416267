import myaxios from '../../axios';

export const EMPALLGETREPORTDAY_REQUEST = 'EMPALLGETREPORTDAY_REQUEST';
export const EMPALLGETREPORTDAY_SUCCESS = 'EMPALLGETREPORTDAY_SUCCESS';
export const EMPALLGETREPORTDAY_FAILURE = 'EMPALLGETREPORTDAY_FAILURE';
export const EMPALLGETREPORTDAY_RESET = 'EMPALLGETREPORTDAY_RESET';

export const emp_all_get_report_day =(from_date,to_date,monday,tuesday,wednesday,thursday,friday,saturday,sunday,use_day)=>{
    return dispatch=>{
        dispatch({
            type:'EMPALLGETREPORTDAY_REQUEST'
        })

        myaxios.post('/leave/report/day/',{
            from_date:from_date,
            to_date:to_date,
            monday:monday,
            tuesday: tuesday,
            wednesday: wednesday,
            thursday: thursday,
            friday: friday,
            saturday: saturday,
            sunday: sunday,
            use_day: use_day
        })
        .then((response)=>{
            dispatch({
                type:'EMPALLGETREPORTDAY_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'EMPALLGETREPORTDAY_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_emp_all_get_report_day = () => dispatch =>{
    dispatch({
        type:'EMPALLGETREPORTDAY_RESET'
    });
}
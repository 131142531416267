import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Table, Row, Col, Input, FormFeedback, Container, Card, CardHeader, CardBody } from 'reactstrap';
import { wizard_validate_flat } from "../../action/wizard/wizard_validate_flat";
import { IMLocalized } from "../../language/IMLocalized";
import ModalErrOvertime from "./modal/ModalErrOvertime";



export default function FixedDetail({setData}){
    const dispatch = useDispatch();
    const success = useSelector(state=>state.wizard_validate_flat.data);
    const errors = useSelector(state=>state.wizard_validate_flat.errors);
    const [ code, setCode ] = useState('');
    const [ err_code, setErrCode ] = useState('');
    const [ name, setName ] = useState('');
    const [ err_name, setErrName ] = useState('');
    const [ amount, setAmount ] = useState('');
    const [ err_amount, setErrAmount ] = useState('');
    const [ list, setList] = useState([]);
    const [ is_pending, setIsPending] = useState(false);
    const [ err_msg, setErrMsg] = useState([]);
    const [ err_toggle, setErrToggle] = useState(false);

    useEffect(()=>{
        if(errors != null && is_pending === true){
            const arr = [];
            for(let i=0; i<errors.length; i++ ){
                const obj = errors[i];
                if(obj !== true){
                    if(obj.hasOwnProperty('name')){
                        const msg = obj['name'].toString();
                        const name = list[i].name;
                        arr.push({ name, msg });
                    }
                    if(obj.hasOwnProperty('code')){
                        const msg = obj['code'].toString();
                        const name = list[i].code;
                        arr.push({ name, msg });
                    }
                    if(obj.hasOwnProperty('non_field_errors')){
                        const msg = obj['non_field_errors'].toString();
                        const name = list[i].code;
                        arr.push({ name, msg });
                    }
                }
            }
            setErrMsg(arr);
            setErrToggle(true);
            setIsPending(false);
        }
    },[errors,is_pending,list])

    useEffect(()=>{
        if(success != null){
            setData(list);
        }
    },[success,setData,list])

    const onSubmit=()=>{
        if(list.length !== 0 ){
            dispatch(wizard_validate_flat(list));
            setIsPending(true);
        }
        else{
            setData([]);
        }
    }

    const addFlat=()=>{
        if(name !== '' && code !== '' && amount !== ''){
            let count = 0;
            if(list.some(el => el.name === name)){
                setErrCode(IMLocalized('duplicate_name'));
                count++;
            }
            if(list.some(el => el.code === code)){
                setErrCode(IMLocalized('duplicate_code'));
                count++;
            }

            if(count === 0){
                setList(prevState=>([...prevState,{ code, name, amount}]));
                setCode('');
                setName('');
                setAmount('');
            }
        }
        else{
            if(name === ''){
                setErrName(IMLocalized('name_is_required'));
            }
            if(code === ''){
                setErrCode(IMLocalized('code_is_required'));
            }
            if(amount === ''){
                setErrAmount(IMLocalized('amount_is_required'));
            }
        }
    }

    const removeList=(index)=>{
        const data = [...list];
        data.splice(index,1);
        setList(data);
    }

    return(
        <>
        <Container>
            <Row className="mt-2">
                <Col>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h4 className="mb-0">{IMLocalized('add_fixed_rate')}</h4>
                                </Col>
                                <Col className="col-auto">
                                    <button className="btn btn-primary btn-sm" onClick={addFlat}>{IMLocalized('add')}</button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="code">{IMLocalized('code')}</label>
                                        <Input size="sm" type="text" id="code" value={code} onChange={(e)=>{ setCode(e.target.value); setErrCode('')}} invalid={err_code.length >0} />
                                        <FormFeedback>{err_code}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="name">{IMLocalized('name')}</label>
                                        <Input size="sm" type="text" id="name" value={name} invalid={err_name.length >0 } onChange={(e)=>{ setName(e.target.value); setErrName('')}} />
                                        <FormFeedback>{err_name}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="amount">{IMLocalized('amount')}</label>
                                        <Input size="sm" type="number" id="amount" value={amount} invalid={err_amount.length >0} onChange={(e)=>{ setAmount(e.target.value) ; setErrAmount('') }} />
                                        <FormFeedback>{err_amount}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <Card>
                        <CardHeader>
                            <h4 className="mb-0">{IMLocalized('fixed_rate')}</h4>
                        </CardHeader>
                        {list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                        {list.length !== 0 && 
                        <Table size="sm" className="align-items-center" responsive>
                            <thead className="text-center">
                                <tr>
                                    <th>{IMLocalized('code')}</th>
                                    <th>{IMLocalized('name')}</th>
                                    <th>{IMLocalized('amount')}</th>
                                    <th>{IMLocalized('delete')}</th>
                                </tr>
                            </thead>
                            <tbody className="text-center">
                                {list.map((item,index)=>{
                                    return(
                                        <tr key={index}>
                                            <td>{item.code}</td>
                                            <td>{item.name}</td>
                                            <td>$ {item.amount}</td>
                                            <td align="center" valign="center">
                                                <div className="delete_btn d-flex align-items-center justify-content-center">
                                                    <i className="fas fa-trash text-red cursor-pointer" onClick={()=>removeList(index)}  />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>}
                    </Card>
                </Col>
            </Row>
            <div className='create_btn_parent'>
                <i className='fas fa-arrow-circle-right text-success cursor-pointer create_btn' onClick={()=>onSubmit()}  />
            </div>
        </Container>
        <ModalErrOvertime toggle={err_toggle} detail={err_msg} closeModal={()=>{setErrToggle(false); setErrMsg([]) }} />
        </>
    )
}
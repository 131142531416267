import * as otpinitial from '../../action/user/get_otp_initial';

const initialState={
    data:null,
    errors:null,
    isLoading:false,
}


const get_otp_initial = (state=initialState,action)=>{
    switch(action.type){
        case otpinitial.GETOTPINITIAL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case otpinitial.GETOTPINITIAL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case otpinitial.GETOTPINITIAL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_otp_initial;
import myaxios from '../../axios';

export const LATESTEMPLOYEE_REQUEST = 'LATESTEMPLOYEE_REQUEST';
export const LATESTEMPLOYEE_SUCCESS = 'LATESTEMPLOYEE_SUCCESS';
export const LATESTEMPLOYEE_FAILURE = 'LATESTEMPLOYEE_FAILURE';


export const get_latest_employee =()=>{
    return dispatch=>{
        dispatch({
            type:'LATESTEMPLOYEE_REQUEST'
        });

        myaxios.get('employee/latest/')
        .then((response)=>{
            dispatch({
                type:'LATESTEMPLOYEE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'LATESTEMPLOYEE_FAILURE',
                payload:error
            })
        })

    }
}
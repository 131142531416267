import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const CREATEGENERALCURRENCY_REQUEST = 'CREATEGENERALCURRENCY_REQUEST';
export const CREATEGENERALCURRENCY_SUCCESS = 'CREATEGENERALCURRENCY_SUCCESS';
export const CREATEGENERALCURRENCY_FAILURE = 'CREATEGENERALCURRENCY_FAILURE';
export const CREATEGENERALCURRENCY_RESET = 'CREATEGENERALCURRENCY_RESET';


export const create_general_currency = (currency_name,currency_symbol)=>{
    return dispatch=>{
        dispatch({
            type:'CREATEGENERALCURRENCY_REQUEST'
        });
        
        myaxios.post('general/currency/',{
            currency_name:currency_name,
            currency_symbol:currency_symbol
        })
        .then((response)=>{
            dispatch({
                type:'CREATEGENERALCURRENCY_SUCCESS',
                payload:response
            });

            Toast.fire({
                type:'success',
                title:IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CREATEGENERALCURRENCY_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}

export const reset_create_general_currency = () => dispatch =>{
    dispatch({
        type:'CREATEGENERALCURRENCY_RESET'
    });
}
import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const EXTENDEMPPROBATION_REQUEST = 'EXTENDEMPPROBATION_REQUEST';
export const EXTENDEMPPROBATION_SUCCESS = 'EXTENDEMPPROBATION_SUCCESS';
export const EXTENDEMPPROBATION_FAILURE = 'EXTENDEMPPROBATION_FAILURE';
export const EXTENDEMPPROBATION_RESET = 'EXTENDEMPPROBATION_RESET'

export const extend_employee_probation =(id,date)=>{
    return dispatch=>{
        dispatch({
            type:'EXTENDEMPPROBATION_REQUEST'
        });

        myaxios.post(`employee/probation/${id}/extend/`,{
            probation_end_date:date
        })
        .then((response)=>{
            dispatch({
                type:'EXTENDEMPPROBATION_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('extend_success')
              })
        })
        .catch((error)=>{
            dispatch({
                type:'EXTENDEMPPROBATION_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('extend_failure')
            });
        })
    }
}


export const reset_extend_employee_probation = () => dispatch =>{
    dispatch({
        type:'EXTENDEMPPROBATION_RESET'
    });
}
import * as delrelationship from '../../action/employee/delete_employee_relationship';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const delete_employee_relationship = (state=initialState,action)=>{
    switch(action.type){
        case delrelationship.NEW_DELEMPRELATION_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case delrelationship.NEW_DELEMPRELATION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case delrelationship.NEW_DELEMPRELATION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case delrelationship.NEW_DELEMPRELATION_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default delete_employee_relationship;
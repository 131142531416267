import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const EMPARRCREATE_REQUEST = 'EMPARRCREATE_REQUEST';
export const EMPARRCREATE_SUCCESS = 'EMPARRCREATE_SUCCESS';
export const EMPARRCREATE_FAILURE = 'EMPARRCREATE_FAILURE';


export const create_employee_array_create = (data)=>{
    return dispatch=>{
        dispatch({
            type:'EMPARRCREATE_REQUEST'
        });

        myaxios.post('employee/employees/array_create/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'EMPARRCREATE_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'EMPARRCREATE_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}
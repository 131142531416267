import myaxios from '../axios';
export const ADDITIONALLIST_REQUEST = 'ADDITIONALLIST_REQUEST';
export const ADDITIONALLIST_SUCCESS = 'ADDITIONALLIST_SUCCESS';
export const ADDITIONALLIST_FAILURE = 'ADDITIONALLIST_FAILURE';


export const get_additional_setting = ()=>{
    return dispatch =>{
        dispatch({
            type: 'ADDITIONALLIST_REQUEST'
        });
        myaxios.get('company/cas/')
        .then((response)=>{
            dispatch({
                type: 'ADDITIONALLIST_SUCCESS',
                payload:response 
            })
        })
        .catch((error) =>
        dispatch({
            type: 'ADDITIONALLIST_FAILURE',
            payload: error,
            error:true
        })
        )
    }
}

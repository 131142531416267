import myaxios from '../../axios';
import Swal from 'sweetalert2';


export const SUBMITCPFTEST_REQUEST = 'SUBMITCPFTEST_REQUEST';
export const SUBMITCPFTEST_SUCCESS = 'SUBMITCPFTEST_SUCCESS';
export const SUBMITCPFTEST_FAILURE = 'SUBMITCPFTEST_FAILURE';

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    type:'success',
    showConfirmButton: false,
    timer: 3000,
})

const ErrToast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    type:'error',
    showConfirmButton: false,
    timer: 3000,
})

export const submit_cpf_test = (employee_birth_date,payroll_date,ordinary_wage,additional_wage,ow_paid,aw_paid,pr,pr_cpf_contribution_type)=>{
    return dispatch=>{
        dispatch({
            type:'SUBMITCPFTEST_REQUEST'
        });

        return myaxios.post('payroll/cpf/test/',{
            employee_birth_date:employee_birth_date,
            payroll_date:payroll_date,
            ordinary_wage:ordinary_wage,
            additional_wage:additional_wage,
            ow_paid:ow_paid,
            aw_paid:aw_paid,
            pr:pr,
            pr_cpf_contribution_type:pr_cpf_contribution_type
        })
        .then((response)=>{
            dispatch({
                type:'SUBMITCPFTEST_SUCCESS',
                payload:response
            }
            ,Toast.fire({
                type: 'success',
                title: 'Submit success'
            }))
        })
        .catch((error)=>{
            dispatch({
                type:'SUBMITCPFTEST_FAILURE',
                payload:error
            }
            ,ErrToast.fire({
                type:'error',
                title:'Failed to submit'
            }))
        })
    }
}
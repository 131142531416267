import * as delflat from '../action/delete_flat_allowance';

const initialState={
    isLoading:false,
    errors:null,
    data:null
}

const delete_flat_allowance = (state=initialState,action)=>{
    switch(action.type){
        case delflat.DELETEFLATALLOWANCE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case delflat.DELETEFLATALLOWANCE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case delflat.DELETEFLATALLOWANCE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case delflat.DELETEFLATALLOWANCE_RESET:
            return{
                ...state,
                errors:null,
                data:null
            }
        default:
            return state;
    }
}
export default delete_flat_allowance;
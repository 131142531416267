import React, { useContext } from "react";
import { TabContent, TabPane } from "reactstrap";
import { NewMainContext } from "../../../context/NewMainContext";
import LeaveOfficerDashboard from "../../dashboard/LeaveOfficerDashboard";
import LeaveOfficerEmployeeList from '../../employee/leave_employee/EmployeeMain';
import LeaveTable from '../../leave/LeaveTable';
import LeaveOfficerCalendar from '../../leave/LeaveCalendar';

export default function LeaveOfficerTabContent(){

    const { activeTab, action_toggle } = useContext(NewMainContext);

    return(
        <TabContent activeTab={activeTab} className={action_toggle === true ? 'body-tabpane scrollbarstyle1' : 'body-tabpane1 scrollbarstyle1'}>
            <TabPane tabId="1">
                <LeaveOfficerDashboard/>
            </TabPane>
            <TabPane tabId="2">
                <LeaveOfficerEmployeeList/>
            </TabPane>
            <TabPane tabId="3">
                <LeaveTable/>
            </TabPane>
            <TabPane tabId="24">
                <LeaveOfficerCalendar/>
            </TabPane>
        </TabContent>
    )
}
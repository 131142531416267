import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';

export const EMPCHILDARRAYVALIDATE_REQUEST = 'EMPCHILDARRAYVALIDATE_REQUEST';
export const EMPCHILDARRAYVALIDATE_SUCCESS = 'EMPCHILDARRAYVALIDATE_SUCCESS';
export const EMPCHILDARRAYVALIDATE_FAILURE = 'EMPCHILDARRAYVALIDATE_FAILURE';
export const EMPCHILDARRAYVALIDATE_RESET = 'EMPCHILDARRAYVALIDATE_RESET';


export const employee_child_array_validate = (data)=>{
    return dispatch=>{
        dispatch({
            type:'EMPCHILDARRAYVALIDATE_REQUEST'
        });

        myaxios.post('leave/sg/child/array_validate/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'EMPCHILDARRAYVALIDATE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'EMPCHILDARRAYVALIDATE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}

export const reset_employee_child_array_validate = () => dispatch =>{
    dispatch({
        type:'EMPCHILDARRAYVALIDATE_RESET'
    });
}
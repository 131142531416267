import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, FormFeedback, FormGroup, Modal, ModalBody, ModalHeader, Row, Input, Table, ModalFooter } from "reactstrap";
import { IMLocalized } from "../../../../language/IMLocalized";
import { reset_validate_user_apply_claim, validate_user_apply_claim } from "../../../../action/user/validate_user_apply_claim";
import { popError } from '../../../../func/popError';
import Swal from "sweetalert2";
import { reset_user_apply_claim, user_apply_claim } from "../../../../action/user/user_apply_claim";
import { get_medical_claim_autocomplete } from "../../../../action/claim/get_medical_claim_autocomplete";
import { user_get_available_claim } from "../../../../action/user/user_get_available_claim";
import { user_apply_claim_list } from "../../../../action/user/user_apply_claim_list";

const mapStateToProps=(state)=>({
    doctorlist:state.get_medical_claim_autocomplete.doctor,
    locationlist:state.get_medical_claim_autocomplete.location,
    diagnosislist:state.get_medical_claim_autocomplete.diagnosis,
    approval_group:state.user_claim_approval_group.data,
    availablelist:state.user_get_available_claim.data,
    claimlist:state.get_claim_list.data,
    success:state.validate_user_apply_claim.data,
    errors:state.validate_user_apply_claim.errors,
    apply_success:state.user_apply_claim.data,
    isLoading:state.validate_user_apply_claim.isLoading,
    isLoading1:state.user_apply_claim.isLoading,
})

const mapDispatchToProps=(dispatch)=>({
    validate_user_apply_claim:(claim_type,original_amount,claim_date,dependant,set_pending,notes,file,doctor,diagnosis,location)=>{
        dispatch(validate_user_apply_claim(claim_type,original_amount,claim_date,dependant,set_pending,notes,file,doctor,diagnosis,location))
    },
    user_apply_claim:(claim_type,original_amount,claim_date,dependant,set_pending,notes,file,doctor,diagnosis,location)=>{
        dispatch(user_apply_claim(claim_type,original_amount,claim_date,dependant,set_pending,notes,file,doctor,diagnosis,location))
    },
    user_apply_claim_list:()=>{
        dispatch(user_apply_claim_list())
    },
    get_medical_claim_autocomplete:()=>{
        dispatch(get_medical_claim_autocomplete())
    },
    user_get_available_claim:()=>{
        dispatch(user_get_available_claim())
    },
    reset_validate_user_apply_claim:()=>{
        dispatch(reset_validate_user_apply_claim())
    },
    reset_user_apply_claim:()=>{
        dispatch(reset_user_apply_claim())
    }
})

const initialState={
    claim_date:'',
    err_claim_date:'',
    claim_amount:'',
    err_claim_amount:'',
    comment:'',
    dependant:false,
    file:[],
    err_images:'',
    claim_type:'',
    err_claim_type:'',
    min:0,
    max:0,
    is_medical:false,
    doctor:null,
    diagnosis:null,
    location:null,
    err_doctor:'',
    err_diagnosis:'',
    err_location:'',
}

function ModalUserApplyClaim(props){
    const {success,errors,reset_user_apply_claim,reset_validate_user_apply_claim,apply_success,closeModal,user_apply_claim,get_medical_claim_autocomplete,user_get_available_claim,user_apply_claim_list} = props;
    const [state,setState]= useState(initialState);
    const [arr_approvers,setArrApprovers] = useState([]);
    const [arr_availablelist,setArrAvailableList] = useState([]);


    useEffect(()=>{
        if(props.toggle && apply_success != null){
            closeModal();
            get_medical_claim_autocomplete();
            user_apply_claim_list();
            user_get_available_claim();
            reset_user_apply_claim();
        }
    },[props.toggle,apply_success,reset_user_apply_claim,closeModal,user_apply_claim_list,user_get_available_claim,get_medical_claim_autocomplete])

    useEffect(()=>{
        if(props.toggle && errors != null){
            if(errors.hasOwnProperty('dependant')){
                const word = errors['dependant'].toString();
                popError(word);
            }
            else if(errors.hasOwnProperty('claim_date')){
                const word = errors['claim_date'].toString();
                popError(word);
            }
            else if(errors.hasOwnProperty('original_amount')){
                const word = errors['original_amount'].toString();
                popError(word);
            }
            else if(errors.hasOwnProperty('non_field_errors')){
                const word = errors['non_field_errors'].toString();
                popError(word);
            }
            reset_validate_user_apply_claim()
        }
    },[props.toggle,errors,reset_validate_user_apply_claim])

    useEffect(()=>{

        function submitApply1(){
            user_apply_claim(state.claim_type,state.claim_amount,state.claim_date,state.dependant,true,state.comment,state.file,state.doctor,state.diagnosis,state.location);
        }

        if(props.toggle && success != null){
            const {error_message} = success;
            if(error_message != null){
                Swal.fire({
                    title:error_message,
                    type:'warning',
                    showCancelButton:true,
                    showConfirmButton:true,
                    cancelButtonColor:'red',
                    cancelButtonText:IMLocalized('no!'),
                    confirmButtonText:IMLocalized('yes!')
                })
                .then((res)=>{
                    if(res.value){
                        submitApply1();
                    }
                })
            }
            else{
                submitApply1();
            }
            reset_validate_user_apply_claim();
        }
    },[props.toggle,reset_validate_user_apply_claim,success,user_apply_claim,state.claim_amount,state.claim_date,state.claim_type,state.comment,state.dependant,state.diagnosis,state.doctor,state.file,state.location])

    useEffect(()=>{
        if(props.toggle){
            setState({
                ...initialState,
                claim_type:props.claim_id ?? ''
            })
        }
    },[props.toggle,props.claim_id])

    useEffect(()=>{
        let arr = [];
        if(props.approval_group != null && state.claim_type !== '' ){
            const {exceptions,approvers} = props.approval_group;
            const approval_detail = exceptions.find(item=>item.claim_type === parseInt(state.claim_type));
            const group = approval_detail?.group ?? null;
            if(group !== null){
                const approver = approvers[group];
                for(let j=0;j<approver.length;j++){
                    const data = approver[j];
                    for(const emp_data of data){
                        const employee = emp_data.name;
                        const ordering = j+1;
                        arr.push({employee,ordering});
                    }
                }
            }
        }
        setArrApprovers(arr);
    },[state.claim_type,props.approval_group])

    useEffect(()=>{
        let arr = [];
        if(props.availablelist.length !== 0 && props.claimlist.length !== 0){
            arr = props.availablelist.map((item)=>{
                const detail = props.claimlist.find(el=>el.id === item.claim_type);
                return{...item,name:detail?.name ?? ''}
            })
        }
        setArrAvailableList(arr);
    },[props.availablelist,props.claimlist])

    useEffect(()=>{
        if(props.claimlist.length !== 0 && state.claim_type !== ''){
            const detail = props.claimlist.find(item=>item.id === parseInt(state.claim_type));
            setState(prevState=>({
                ...prevState,
                min:detail?.minimum_images ?? 0,
                max:detail?.maximum_images ?? 0,
                is_medical:detail?.medical ?? false
            }))
        }
    },[props.claimlist,state.claim_type])


    const medicalApply=()=>{
        if(state.doctor !== null && state.doctor !== '' && state.diagnosis !== null && state.diagnosis !== '' && state.location !== null && state.location !== ''){
            submitApply();
        }
        else{
            setState(prevState=>({
                ...prevState,
                err_doctor:state.doctor === null || state.doctor === '' ? IMLocalized('doctor_required') : '',
                err_diagnosis:state.diagnosis === null || state.diagnosis === '' ? IMLocalized('diagnosis_required') : '',
                err_location:state.location === null || state.location === '' ? IMLocalized('location_required') : '',
            }))
        }
    }

    const submitApply=()=>{
        props.validate_user_apply_claim(state.claim_type,state.claim_amount,state.claim_date,state.dependant,true,state.comment,state.file,state.doctor,state.diagnosis,state.location);
    }

    const applyClaim=()=>{
        if(state.claim_amount !== '' && state.claim_date !== '' && state.claim_type !== '' && state.file.length <= state.max && state.file.length >= state.min){
            if(state.is_medical){
                medicalApply();
            }
            else{
                submitApply();
            }
        }
        else{
            setState(prevState=>({
                ...prevState,
                err_images:state.file.length > state.max || state.file.length < state.min ? `* ${IMLocalized('must_upload_image_number',{min:state.min,max:state.max})}`:'',
                err_claim_amount:state.claim_amount === '' ? IMLocalized('claim_amount_required') : '',
                err_claim_date:state.claim_date === '' ? IMLocalized('claim_date_required') : '',
                err_claim_type:state.claim_type === '' ? IMLocalized('claim_type_required') : ''
            }))
        }
    }

    return(
        <Modal size="lg" isOpen={props.toggle}>
            <ModalHeader toggle={props.closeModal}>{IMLocalized('apply_claim')} {props.claim_name != null ? `- ${props.claim_name}` : null}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="3">
                        <label htmlFor="claim_date">{IMLocalized('claim_date')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <Input size="sm" type="date" id="claim_date"  value={state.claim_date} invalid={state.err_claim_date.length > 0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({
                                    ...prevState,
                                    claim_date:value,
                                    err_claim_date:'',
                                }))
                            }} />
                            <FormFeedback>{state.err_claim_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                {props.has_claim == null  &&
                <Row>
                    <Col lg="3">
                        <label htmlFor="claim_type">{IMLocalized('claim_type')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <Input type="select" id="claim_type" size="sm" value={state.claim_type}
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,claim_type:value,err_claim_type:''}))
                            }}
                            >
                                <option></option>
                                {arr_availablelist.map((item,index)=>{
                                    return(
                                        <option value={item.claim_type} key={index}>{item.name}</option>
                                    )
                                })}
                            </Input>
                            <FormFeedback>{state.err_claim_type}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>}
                <Row>
                    <Col lg="3">
                        <label htmlFor="claim_amount">{IMLocalized('claim_amount')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <Input size="sm" min="0" type="number" id="claim_amount"  value={state.claim_amount} invalid={state.err_claim_amount.length > 0}
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({
                                    ...prevState,
                                    claim_amount:value,
                                    err_claim_amount:'',
                                }))
                            }}/>
                            <FormFeedback>{state.err_claim_amount}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                {state.is_medical &&
                <>
                <Row>
                    <Col lg="3">
                        <label htmlFor="doctor">{IMLocalized('doctor')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <Input size="sm" list="doctorlist" id="doctor" value={state.doctor} invalid={state.err_doctor.length >0}
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({
                                    ...prevState,
                                    doctor:value,
                                    err_doctor:'',
                                }))
                            }}/>
                            <datalist id="doctorlist">
                                {props.doctorlist.map((item,index)=>{
                                    return <option value={item} key={index}/>
                                })}
                            </datalist>
                            <FormFeedback>{state.err_doctor}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="3">
                        <label htmlFor="location">{IMLocalized('location')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <Input size="sm" list="locationlist" id="location" value={state.location} invalid={state.err_location.length >0}
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({
                                    ...prevState,
                                    location:value,
                                    err_location:''
                                }))
                            }}/>
                            <datalist id="locationlist">
                                {props.locationlist.map((item,index)=>{
                                    return <option value={item} key={index}/>
                                })}
                            </datalist>
                            <FormFeedback>{state.err_location}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="3">
                        <label htmlFor="diagnosis">{IMLocalized('diagnosis')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <Input size="sm" list="diagnosislist" id="diagnosis" value={state.diagnosis} invalid={state.err_diagnosis.length >0}
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({
                                    ...prevState,
                                    diagnosis:value,
                                    err_diagnosis:''
                                }))
                            }}/>
                            <datalist id="diagnosislist">
                                {props.diagnosislist.map((item,index)=>{
                                    return <option value={item} key={index}/>
                                })}
                            </datalist>
                            <FormFeedback>{state.err_diagnosis}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                </>}
                <Row>
                    <Col lg="3">
                        <label htmlFor="comment">{IMLocalized('comment')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <Input size="sm" type="textarea" value={state.comment} id="comment" 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({
                                    ...prevState,
                                    comment:value,
                                }))
                            }}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="3">
                        <label htmlFor="dependantuser">{IMLocalized('dependant')}</label>
                    </Col>
                    <Col lg="9">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="dependantuser" name='dependant123' className="custom-control-input" defaultChecked={state.dependant}
                            onChange={(e)=>{
                                const checked = e.target.checked;
                                setState(prevState=>({
                                    ...prevState,
                                    dependant:checked
                                }));
                            }} />
                            <label className="custom-control-label" htmlFor="dependantuser"></label>
                        </div>
                    </Col>
                </Row>
                {state.max !== 0 &&
                <Row className="mt-3">
                    <Col lg="3">
                        <label htmlFor="upload_image">{IMLocalized('image')}</label>
                    </Col>
                    <Col lg="9">
                        <FormGroup>
                            <input type="file" multiple accept="image/*" id="upload_image"
                            onChange={(e)=>{
                                const file = e.target.files;
                                setState(prevState=>({
                                    ...prevState,
                                    file,
                                    err_images:'',
                                }))
                            }}
                            />
                            <div>
                                <small className="text-danger">{state.err_images}</small>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>}
                {arr_approvers.length !== 0 &&
                <Row className="mt-2">
                    <Col lg="3">
                        <label>{IMLocalized('claim_approval')}</label>
                    </Col>
                    <Col lg="9">
                        <Table size="sm" className="table-bordered" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th>{IMLocalized('approval_level')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {arr_approvers.map((item,index)=>{
                                            return(
                                                <div key={index}>
                                                    <span>{item.employee} - ({IMLocalized('level')} : {item.ordering}) </span>
                                                </div>
                                            )
                                        })}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>}
            </ModalBody>
            <ModalFooter>
                {props.isLoading || props.isLoading1 ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                <button onClick={applyClaim} className="btn btn-primary btn-sm">{IMLocalized('apply')}</button>
                <button onClick={props.closeModal} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalUserApplyClaim);
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {DropdownItem} from 'reactstrap';
import { getDateFormat } from '../../../func/getDateFormat';
import { IMLocalized } from '../../../language/IMLocalized';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import JSZip from 'jszip';
import { Buffer } from 'buffer';
import {saveAs} from 'file-saver';

const mapStateToProps=(state)=>({
    letterhead:state.get_general_letter.data,
    list:state.get_payroll_instance.data,
    paymentlist:state.dropdown_list.payment,
    wagelist:state.dropdown_list.wage,
    pay_component_type:state.dropdown_list.pay_component_type,
})

const mapDispatchToProps=(dispatch)=>({

})

function DownloadPayslipButton(props){

    const [head,setHead] = useState(null);

    useEffect(()=>{
        if(props.letterhead.length !== 0){
            const pic = props.letterhead.data;
            const byte = pic.byteLength;
            if(byte === 0){
                setHead(null);
            }
            else{
                const img = new Buffer.from(pic,'binary').toString('base64');
                const src = 'data:image/png;base64,'+img;
                setHead(src);
            }
        }
    },[props.letterhead])

    const downloadPayslip=useCallback(()=>{

        const monthNames= [IMLocalized("january"), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
        IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")];


        if(props.list.length !== 0){
            const data = props.list[0];
            const date = data.payroll_date;
            const month1 = new Date(date).getMonth();
            const month = monthNames[month1];
            const year = new Date(date).getFullYear();
            
            const report_date = month + '-' + year ;
            const {paid_date,start_date,end_date,payslips} = data;
            
            var zip = new JSZip();
            const today = moment(new Date()).format('DD/MM/YYYY');
            if(payslips.length !== 0){
                
                for(let i =0;i<payslips.length ;i++){
                    const head1 = [[{content:'Date of Payment'}]];
                    const head2 = [[{content:'Mode of Payment'}]];
                    const head3 = [[{content: 'Salary (A)', styles: {halign: 'left'}}, {content: 'Amount', styles: {halign: 'right'}}]];
                    const head4 = [[{content: 'Total Allowances (B)', styles: {halign: 'left'}}, {content: 'Amount', styles: {halign: 'right'}}]];
                    const head5 = [[{content: 'Total Deductions (C)', styles: {halign: 'left'}}, {content: 'Amount', styles: {halign: 'right'}}]];
                    const head6 = [[{content: 'Overtime (D)'},{content:'Period',styles:{halign:'center'}},{content:'Hrs Work',styles:{halign:'center'}},{content:'Wage',styles:{halign:'center'}},{content: 'Amount', styles: {halign: 'right'} }]];
                    const head7 = [[{content: 'Other Pay (E)', styles: {halign: 'left'}}, {content: 'Amount', styles: {halign: 'right'}}]];

                    let cell1 =[];
                    let cell2 =[];
                    let cell3 = [];
                    let cell4 = [];
                    let cell5 = [];
                    let cell6 = [];
                    let cell7 = [];
                    let cell8 = [];

                    const item = payslips[i];
                    const employee = item.employee;
                    const payment = item.employee.payment;
                    const metadata = item.employee.metadata;

                    let annual_amount = null;
                    if('annual_amt' in metadata){
                        const annual_amt = metadata['annual_amt'];
                        const check = Array.isArray(annual_amt);
                        if(check === false){
                            annual_amount = annual_amt;
                        }
                        else{
                            const data1 = new Date();
                            const month = data1.getMonth();
                            const amount = annual_amt[month];
                            annual_amount = amount;
                        }
                    }
                    else{
                        annual_amount = null;
                    }
                    const payment_detail = props.paymentlist.find((item1)=>item1.value === payment);
                    const payment_name = payment_detail !== undefined ? payment_detail.display : '';
                    
                    if(payment !== '' && payment != null){
                        cell2.push([payment_name+ ' Deposit']);
                    }
                    const payer = employee.metadata.company;
                    let payee =employee.name;
                    const payeeId = employee.employee_number;
                    const sgcpf = item.sgcpf;
                    const sgshg = item.sgshg;
                    const payable_wage = item.payable_wage;
                    const string = `Employer : ${payer}`;
                    const string1 = `Employee Name : ${payee}`;
                    const string2 = `Printed Date : ${today}`;
                    const string3 = `Payslip for ${getDateFormat(start_date)} to ${getDateFormat(end_date)}`;
                    const string4 = `Employee ID : ${payeeId}`;

                    if(paid_date !== null){
                        cell1.push([getDateFormat(paid_date)])
                    }

                    const payslipcontract = item.payslipcontract;
                    const pay = item.pay;

                    const contract_type = employee.hr_cache.contract_rate                    ;

                    const claimlist = pay.filter(function(item){
                        return item.pay_type === 'CLAIM'
                    })
                    const overtimelist = pay.filter(function(item){
                        return item.pay_type === 'OT'
                    })

                    const allowancelist = pay.filter(function(item){
                        return item.pay_type === 'ALLOWANCE'
                    })

                    const recurringlist = pay.filter(function(item){
                        return item.pay_type === 'RA'
                    })
                    
                    const flatlist = pay.filter(function(item){
                        return item.pay_type === 'FX'
                    })

                    const backpaylist = pay.filter(function(item){
                        return item.pay_type === 'BACKPAY' || item.pay_type === 'COMM' || item.pay_type === 'GRATUITY' || item.pay_type === 'INSURANCE' || item.pay_type === 'SHARES' || item.pay_type === 'DIRECTORS' || item.pay_type === 'PENSION' || item.pay_type === 'RETIREMENT' || item.pay_type === 'OTHERS' || item.pay_type === 'DEDUCT'
                    })

                    const absentlist = pay.filter(function(item){
                        return item.pay_type === 'ABS'
                    })

                    const bonuslist = pay.filter(function(item){
                        return item.pay_type === 'BONUS'
                    })
    
                    const deductablelist = pay.filter(function(item){
                        return item.pay_type === 'DEDUCT'
                    })
    
                    const nationlist = pay.filter(function(item){
                        return item.pay_type === 'NSMUP'
                    });
    
                    const nopaylist = pay.filter(function(item){
                        return item.pay_type === 'NPL'
                    })

                    const basiclist = pay.filter(function(item){
                        return item.name === 'Daily Rate'
                    })

                    payslipcontract.forEach((element)=>{
                        if(contract_type==='DAILY'){
                            // cell3.push([`Daily Pay (${(element.from_date)} X ${getDateFormat(element.to_date)})`,`$${(element.gross_month).toFixed(2)}`]);
                            cell3.push([`Daily Pay - $${basiclist[0].rate} × ${basiclist[0].count} Day(s)`,`$${(basiclist[0].amount)}`])
                        }else{
                            cell3.push([`Basic Pay (${getDateFormat(element.from_date)} to ${getDateFormat(element.to_date)})`,`$${(element.gross_month).toFixed(2)}`]);
                        }

                    })

                    overtimelist.forEach((element)=>{
                        const {payslip_contract} = element;
                        const payslip_detail = payslipcontract.find((item1)=>item1.id === payslip_contract);
                        const wage_detail = props.wagelist.find((item1)=>item1.value === element.wage);
                        const wage_name = wage_detail ? wage_detail.display : '-';
                        const date = payslip_detail ? `${getDateFormat(payslip_detail.from_date)} to ${getDateFormat(payslip_detail.to_date)}` : '-'
                        if(element.is_gross === false && element.amount > 0){
                            cell6.push([element.name,date,element.count,wage_name,`$${element.amount.toFixed(2)}`]);
                        }
                    })

                    let allowance_length = 0 ;
                    allowancelist.forEach((element)=>{
                        if(element.is_gross === false && element.amount > 0){
                            cell4.push([element.name,`$${(element.amount).toFixed(2)}`]);
                            allowance_length += 1;
                        }
                    })

                    recurringlist.forEach((element)=>{
                        if(element.is_gross === false && element.amount > 0){
                            cell4.push([element.name,`$${(element.amount).toFixed(2)}`]);
                            allowance_length += 1;
                        }
                    })

                    flatlist.forEach((element)=>{
                        if(element.is_gross === false && element.amount > 0){
                            cell7.push([element.code,`$${(element.amount).toFixed(2)}`]);
                        }
                    })

                    backpaylist.forEach((element)=>{
                        if(element.is_gross === false && element.deductable === false && element.amount >0){
                            const pay_detail = props.pay_component_type.find((item1)=>item1.value === element.pay_type);
                            const pay_name = pay_detail ? pay_detail.display : '-';
                            cell7.push([`${pay_name} (${element.name})`,`$${(element.amount).toFixed(2)}`]);
                        }
                    })

                    bonuslist.forEach((element)=>{
                        if(element.is_gross === false && element.amount > 0){
                            const pay_detail = props.pay_component_type.find((item1)=>item1.value === element.pay_type);
                            const pay_name = pay_detail ? pay_detail.display : '-';
                            cell7.push([pay_name,`$${(element.amount).toFixed(2)}`]);
                        }
                    })

                    claimlist.forEach((element)=>{
                        cell7.push(['Total Claim (E)',`$${(element.amount).toFixed(2)}`]);

                    })

                    if(sgcpf !== null){
                        cell5.push(['CPF Employee',`$${(sgcpf.cpf_employee).toFixed(2)}`]);
                    }

                    if(sgshg !== null){
                        sgshg.forEach((element)=>{
                            cell5.push([element.shg,`$${(element.amount).toFixed(2)}`]);
                        })
                    }

                    nopaylist.forEach((element)=>{
                        cell5.push([element.code,`$${(element.amount).toFixed(2)}`]);
                    })

                    nationlist.forEach((element)=>{
                        cell5.push([element.code,`$${(element.amount).toFixed(2)}`]);
                    })

                    absentlist.forEach((element)=>{
                        const pay_detail = props.pay_component_type.find((item1)=>item1.value === element.pay_type);
                        const name = pay_detail ? `${pay_detail.display} (AWOL)` : '-' ;
                        cell5.push([name,`$${(element.amount).toFixed(2)}`]);
                    })

                    deductablelist.forEach((element)=>{
                        const pay_detail = props.pay_component_type.find((item1)=>item1.value === element.pay_type);
                        const name = pay_detail ? pay_detail.display : '-';
                        cell5.push([`${name} (${element.name})`,`$${(element.amount).toFixed(2)}`]);
                    })
                    cell8.push(['Net Pay (A+B-C+D+E)','$'+(payable_wage.toFixed(2))]);
                    if(sgcpf !== null){
                        const cpf_employer = sgcpf.cpf_employer;
                        cell8.push(["Employer's CPF Contributions" , '$'+(cpf_employer).toFixed(2)]);
                    }
                    if(annual_amount !== null){
                        cell8.push(['Annual Leave Balance',annual_amount])
                    }

                    var doc = new jsPDF();
                    if(head !== null){
                        doc.addImage(head, 'JPEG', 20, 10, 180, 40);
                    }
                    doc.setFontSize(10);
                    // doc.text(string,15,60,'left');
                    // doc.text(string1,107,60,'left');
                    // doc.text(string2,15,70,'left');
                    // doc.text(string3,107,70,'left');

                    doc.text(string,15,60,'left');
                    doc.text(string4,107,60,'left');
                    doc.text(string2,15,70,'left');
                    doc.text(string1,107,70,'left');
                    doc.text(string3,15,80,'left');

                    doc.autoTable({
                        head:head1,
                        body:cell1,
                        startY:90,
                        tableWidth:90,
                        columnStyles:{ 0: {halign:'left'}}
                    })
                    doc.autoTable({
                        head:head2,
                        body:cell2,
                        startY:90,
                        margin: {left: 107} ,
                        columnStyles:{ 0: {halign:'left'}}
                    })

                    doc.autoTable({
                        head:head3,
                        body:cell3,
                        tableWidth:90,
                        startY:110,
                        columnStyles:{ 0: {halign:'left'},1: {halign:'right'}}
                    })

                    doc.autoTable({
                        head:head4,
                        body:cell4,
                        margin: {left: 107} ,
                        startY:110,
                        columnStyles:{ 0: {halign:'left'},1: {halign:'right'}}
                    })

                    let abc = doc.autoTable.previous.finalY + 5
                    if(allowance_length === 0){
                        const length = payslipcontract.length;
                        const number = length *5 +10
                        abc = doc.autoTable.previous.finalY + number
                    }
        

                    doc.autoTable({
                        head:head5,
                        body:cell5,
                        startY: typeof doc.autoTable.previous.finalY === 'undefined' ? 50 : abc,
                        columnStyles:{ 0: {halign:'left'},1: {halign:'right'}}
                    })

                    doc.autoTable({
                        head:head6,
                        body:cell6,
                        startY: typeof doc.autoTable.previous.finalY === 'undefined' ? 50 : doc.autoTable.previous.finalY + 5,
                        columnStyles:{ 0: {halign:'left'},1:{halign:'center'},2:{halign:'center'},3:{halign:'center'},4: {halign:'right'}}
                    })

                    doc.autoTable({
                        head:head7,
                        body:cell7,
                        startY: typeof doc.autoTable.previous.finalY === 'undefined' ? 50 : doc.autoTable.previous.finalY + 5,
                        columnStyles:{ 0: {halign:'left'},1: {halign:'right'}},
                    })
                    doc.autoTable({
                        head:[],
                        body:cell8,
                        startY: typeof doc.autoTable.previous.finalY === 'undefined' ? 50 : doc.autoTable.previous.finalY + 5,
                        columnStyles:{ 0: {halign:'left'},1: {halign:'right'}},
                    })
                

                    payee = payee.replace(/[^\w\s]/g, '');
                    Promise.resolve(zip.file(payee+'.pdf',doc.output('blob')))
                    .then(()=>{
                        if(i === payslips.length-1){
                            zip.generateAsync({type:'blob'}).then(function(content) {
                                saveAs(content, 'payslip-'+report_date+'.zip');
                            });
                        }
                    });
                }
            }
        }
    },[head,props.list,props.pay_component_type,props.paymentlist,props.wagelist])

    return(
        <DropdownItem onClick={downloadPayslip}>{IMLocalized('download_payslip')}</DropdownItem>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(DownloadPayslipButton);
import Swal from "sweetalert2"
import { IMLocalized } from "../language/IMLocalized"

export function popError(msg,msg2=''){
    Swal.fire({
        title:msg,
        text:msg2,
        type:'warning',
        confirmButtonColor:'#d33',
        confirmButtonText:IMLocalized('ok!')
    })
}
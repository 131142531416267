import React,{Component} from 'react';
import {connect} from 'react-redux';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import XLSX from 'xlsx';
import ExcelJs from 'exceljs';
import FileSaver from 'file-saver';
import { Container,Row,Col,Modal,ModalBody,ModalFooter,Table,ModalHeader } from 'reactstrap';
import Swal from 'sweetalert2';
import {compose} from 'redux';
import moment from 'moment';
import {employee_permit_array_save} from '../../action/wizard_employee/employee_permit_array_save';
import { IMLocalized } from '../../language/IMLocalized';
import OverlayLoading from '../loading_component/overlay_loading';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

const gridOptions={
    singleClickEdit:true,
    defaultColDef:{
        sortable:true,
        filter:true,
        suppressMenu:true,
        resizable:true
    },
    sideBar:{
        toolPanels:[
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                
            }
        ],
        defaultToolPanel:''
    },
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
}

class wizard_employee_permit extends Component{

    constructor(props){
        super(props);
        this.state={
            list:[],
            rowData:[],
            rowData2:[],
            noRowTemplate:IMLocalized('no_data'),
            rowSelection:'single',
            lodingtemplate:IMLocalized('loading'),
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            columnsDefs:[],
            select_all:false,
            errmsg:[],
            err_toggle:false,
            language:localStorage.getItem('language'),
            defaultColDef:{
                suppressMenu:true,
                resizable:true
            },
            import_lang:'',
            step:0
        }
    }

    componentDidMount(){
        const {list} = this.props;
        const arr = list.filter(function(item){
            return item.employee.nationality !== 'SG' && item.employee.is_pr !== true
        });
        if(arr.length !== 0){
            this.setState({list:arr});
        }
        else{
            this.props.onNext();
        }
        
       
    }


    componentDidUpdate(prevProps,prevState){
        if(prevState.list !== this.state.list || prevState.step !== this.state.step){
            this.getDetail();
        }
        if(prevProps.success !== this.props.success){
            this.props.onNext();
        }
    }

    getDetail=()=>{
        const {list} = this.state;
        const {permitlist} = this.props;
        let permit_option = [''];
        if(permitlist.length !== 0){
            for(let i=0;i<permitlist.length;i++){
                const name = permitlist[i].display;
                permit_option.push(name);
            }
        }
        if(list.length !== 0){
            const detail = list.map((item)=>{
                item.employee.permit_type = null;
                item.employee.permit = null;
                item.employee.permit_man_year_entitlement = 'false';
                item.employee.permit_expiry_date = 'd/M/yy';
                item.employee.permit_issued_date = 'd/M/yy';
                item.employee.permit_application_date = 'd/M/yy';
                item.err_permit_type = false;
                item.err_permit = false;
                item.err_expiry = false;
                item.err_issued = false;
                item.err_application = false;
                return item;
            })
            this.setState({rowData:detail});
        }

        const columnsDefs=[
            {
                headerName:IMLocalized('agrid_select'),
                field:'',
                editable:false,
                width:120,
                lockPosition:true,
                pinned:'left',
                filter:false,
                headerCheckboxSelection: this.isFirstColumn,
                checkboxSelection: this.isFirstColumn,
                headerCheckboxSelectionFilteredOnly:true,
            },
            {
                headerName:IMLocalized('agrid_emp_id'),
                field:'employee.employee_number',
                editable:false,
                width:120,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_emp_name'),
                field:'employee.name',
                editable:false,
                width:200,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_permit_type'),
                field:'employee.permit_type',
                editable:true,
                width:150,
                cellEditor:'agSelectCellEditor',
                cellEditorParams:{
                    values:permit_option,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_permit_type}
                },
            },
            {
                headerName:IMLocalized('agrid_permit_number'),
                field:'employee.permit',
                editable:true,
                width:150,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_permit}
                },
            },
            {
                headerName:IMLocalized('agrid_application_date'),
                field:'employee.permit_application_date',
                editable:true,
                width:150,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_application}
                },
            },
            {
                headerName:IMLocalized('agrid_expiry_date'),
                field:'employee.permit_expiry_date',
                editable:true,
                width:150,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_expiry}
                },
            },
            {
                headerName:IMLocalized('agrid_issued_date'),
                field:'employee.permit_issued_date',
                editable:true,
                width:150,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_issued}
                },

            },
            {
                headerName:IMLocalized('agrid_man_year_entitlement'),
                field:'employee.permit_man_year_entitlement',
                editable:false,
                cellRenderer:this.checkboxCellRenderer,
                width:200
            }
        ]

        this.setState({columnsDefs});
    }

    isFirstColumn(params) {
        if(params !== null){
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }   
    }

    checkboxCellRenderer(params){
        if(params.value !== 'true' && params.value !== 'false'){
            params.setValue(params.value === true || params.value === 'true' ? 'true' : 'false');
        }
        else{
            var input = document.createElement("input");
            
            input.type = "checkbox";
            input.value = params.value === true || params.value === 'true' ? 'true' : 'false';
            input.checked = params.value === true || params.value === 'true' ? true : false;
            
            input.onclick = function(){
                params.setValue(this.checked === true ? 'true' : 'false');
            }
           
            return input;
        }
    }

    goBack=()=>{
        this.props.goBack();
    }

    skipStep=()=>{
        Swal.fire({
            title:IMLocalized('skip_emp_workpermit_creation'),
            type:'warning',
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!')
            
        })
        .then((result)=>{
            if(result.value){
                this.props.onNext();
            }
        })
    }

    handleFile = event => {
        const { target = {} } = event || {};
        target.value = "";
    };

    getTemplate=()=>{
        const {language} = this.state;
        const {permitlist} = this.props;
        const rowData = gridOptions.api.getSelectedRows();
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('employee_permit');
        const ws2 = wb.addWorksheet('setting');
        const ws3 = wb.addWorksheet('id');
        ws.columns=[
            {header:IMLocalized('agrid_emp_id'),key:'employee.employee_number'},
            {header:IMLocalized('agrid_emp_name'),key:'employee.name'},
            {header:IMLocalized('agrid_permit_type'),key:'employee.permit_type'},
            {header:IMLocalized('agrid_permit_number'),key:'employee.permit_number'},
            {header:IMLocalized('agrid_application_date'),key:'employee.permit_application_date'},
            {header:IMLocalized('agrid_expiry_date'),key:'employee.permit_expiry_date'},
            {header:IMLocalized('agrid_issued_date'),key:'employee.permit_issued_date'},
            {header:IMLocalized('agrid_man_year_entitlement'),key:'employee.permit_man_year_entitlement'}
        ];

        const columns=[
            {header:IMLocalized('agrid_emp_id'),key:'employee.employee_number'},
            {header:IMLocalized('agrid_emp_name'),key:'employee.name'},
            {header:IMLocalized('agrid_permit_type'),key:'employee.permit_type'},
            {header:IMLocalized('agrid_permit_number'),key:'employee.permit_number'},
            {header:IMLocalized('agrid_application_date'),key:'employee.permit_application_date'},
            {header:IMLocalized('agrid_expiry_date'),key:'employee.permit_expiry_date'},
            {header:IMLocalized('agrid_issued_date'),key:'employee.permit_issued_date'},
            {header:IMLocalized('agrid_man_year_entitlement'),key:'employee.permit_man_year_entitlement'}
        ];

        ws3.getCell('A1').value = 'WizardEmployeePermit';
        ws3.getCell('A2').value = language;
        for(let i=0;i<permitlist.length;i++){
            const y =i+1;
            ws2.getCell('A'+y).value = permitlist[i].display;

        }
        const datalist = [{data:IMLocalized('excel_true')},{data:IMLocalized('excel_false')}];
        for(let i=0;i<datalist.length;i++){
            const y=i+1;
            ws2.getCell('B'+y).value = datalist[i].data;
        }

        if(rowData.length !== 0){

            for(let i=0;i<columns.length;i++){
                const field = columns[i].key;
                for(let x=0;x<rowData.length;x++){
                    const step = 2+x;
                    const data = this.getValue(rowData[x],field);
                    ws.getCell(this.getColumnName(i)+step).value = data;
                    if(field === 'employee.permit_application_date' || field === 'employee.permit_expiry_date' || field === 'employee.permit_issued_date'){
                        ws.getCell(this.getColumnName(i)+step).numFmt = 'd/M/yy';
                        ws.getCell(this.getColumnName(i)+step).value = null;
                    }
                    else if (field ==='employee.permit_type'){
                        if(permitlist.length !== 0){
                            ws.getCell(this.getColumnName(i)+step).dataValidation={
                                type:'list',
                                allowBlank:true,
                                formulae:['=setting!$A$1:$A$'+permitlist.length],
                                error:'Value must be in the list',
                                errorStyle:'error',
                                errorTitle:'Error',
                                showErrorMessage:true,
                            };
                        }
                        ws.getCell(this.getColumnName(i)+step).value = null;
                    }
                    else if(field === 'employee.permit_man_year_entitlement'){
                        if(datalist.length !== 0){
                            ws.getCell(this.getColumnName(i)+step).dataValidation={
                                type:'list',
                                allowBlank:true,
                                formulae:['=setting!$B$1:$B$'+datalist.length],
                                error:'Value must be in the list',
                                errorStyle:'error',
                                errorTitle:'Error',
                                showErrorMessage:true,
                            };
                        }
                        ws.getCell(this.getColumnName(i)+step).value = null;
                    }
                    else if(field ==='employee.permit'){
                        ws.getCell(this.getColumnName(i)+step).value = null;
                    }
                    else{
                        ws.getCell(this.getColumnName(i)+step).value = data;
                    }
                }
            }

            ws.columns.forEach(column =>{
                column.width = column.header.length < 15 ? 15 : column.header.length
            });
    
            wb.xlsx.writeBuffer().then((buf)=>{
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
               FileSaver.saveAs(file, 'Wizard_Employee_Permit_Template.xlsx')
            });

        }
        else{
            Swal.fire({
                title:IMLocalized('no_employee_selected'),
                type:'warning',
                confirmButtonText:IMLocalized('ok!')
            })
        }
    }

    getValue(object, path) {
        const parts = path.split(".");
        return parts.reduce((value, key) => value ? value[key] : value, object)
    }

    getColumnName=(n)=>{
        let ordA = 'A'.charCodeAt(0);
        let ordZ = 'Z'.charCodeAt(0);
        let len = ordZ - ordA +1;

        let s = '';
        while(n >=0){
            s = String.fromCharCode(n % len + ordA )+s;
            n = Math.floor(n/len) -1;
        }
        return s;
    }

    uploadExcel=(e)=>{
        const file = e.target.files[0];
        const reader = new FileReader();
        const {language} = this.state;
        reader.onload=(e)=>{
            const bstr = e.target.result;
            const wb = XLSX.read(bstr,{type:'binary'});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const wsname3 = wb.SheetNames[2];
            const ws3 = wb.Sheets[wsname3];

            const columns = {
                'A':'employee_number',
                'B':'name',
                'C':'permit_type',
                'D':'permit',
                'E':'permit_application_date',
                'F':'permit_expiry_date',
                'G':'permit_issued_date',
                'H':'permit_man_year_entitlement'
            }

            let rowData1 = [];
            let rowIndex =2;
            let idd = '';
            let lang = '';
            if(ws3 !== undefined){
                if(ws3['A1'] !== undefined){
                    idd = ws3['A1'].w;
                    lang = ws3['A2'].w;
                }
            }

            if(idd === 'WizardEmployeePermit'){
                if(lang === language){
                    while(ws['A'+rowIndex]){
                        let row ={};
                        for(let i =0;i<Object.keys(columns).length;i++){
                            const column = Object.keys(columns)[i];
                            const index = column + rowIndex;
                            if(ws[index] !== undefined){
                                row[columns[column]] = ws[index].w;
                            }
                            else{
                                row[columns[column]] = null;
                            }
                        }
                        rowData1.push(row);
                        rowIndex++;
                    }
                    Promise.resolve(this.getClearAggrid())
                    .then(()=> this.getImportData(rowData1,lang))
                    this.getImportData(rowData1,lang)
                }
                else{
                    Swal.fire({
                        title:IMLocalized('wrong_file_selected'),
                        text:IMLocalized('please_choose_correct_language'),
                        type:'error',
                        confirmButtonColor:'#d33',
                        confirmButtonText:IMLocalized('ok!'),
                    })
                }
                
            }
            else{
                Swal.fire({
                    title:IMLocalized('wrong_file_selected'),
                    type:'error',
                    confirmButtonColor:'#d33',
                    confirmButtonText:IMLocalized('ok!'),
                })
            }
        }
        reader.readAsBinaryString(file);
    }

    getClearAggrid=()=>{
        gridOptions.api.forEachNode((rowNode,index)=>{
            rowNode.setSelected(false);
        })
    }

    getImportData=(item1,lang)=>{
        for(let i =0;i<item1.length;i++){
            const item = item1[i];
            const employee_number = item.employee_number;
            const permit_type = item.permit_type;
            const permit = item.permit;
            const permit_application_date = item.permit_application_date;
            const permit_issued_date = item.permit_issued_date;
            const permit_expiry_date = item.permit_expiry_date;
            const permit_man_year_entitlement = item.permit_man_year_entitlement;
            let toggle = 'false';
            if(lang === 'zh'){
                if(permit_man_year_entitlement === '是'){
                    toggle = 'true';
                }
            } 
            else{
                if(permit_man_year_entitlement === 'Yes'){
                    toggle = 'true';
                }
            }

            gridOptions.api.forEachNode((rowNode,index)=>{
                const employee_number1 = rowNode.data.employee.employee_number;
                if(employee_number1 === employee_number){
                    rowNode.data.employee.permit_type = permit_type;
                    rowNode.data.employee.permit = permit;
                    rowNode.data.employee.permit_application_date = permit_application_date;
                    rowNode.data.employee.permit_expiry_date = permit_expiry_date;
                    rowNode.data.employee.permit_issued_date = permit_issued_date;
                    rowNode.data.employee.permit_man_year_entitlement = toggle;
                    rowNode.data.err_permit= false;
                    rowNode.data.err_permit_type = false;
                    rowNode.data.err_application = false;
                    rowNode.data.err_issued =false;
                    rowNode.data.err_expiry = false;
                    rowNode.setSelected(true);
                    gridOptions.api.refreshCells(rowNode)
                }
            })
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        
    };

    clearData=()=>{
        const {step} = this.state;
        this.setState({err_msg:[]});
        Promise.resolve(this.setState({rowData:[]}))
        .then(()=>this.setState({step:step+1}));
    }

    onCreate=()=>{
        this.setState({err_msg:[]});
        const data = gridOptions.api.getSelectedRows();
        const {permitlist} = this.props;
        let arr = [];
        let err = [];
        let validate = [];
        if(data.length !== 0){
            for(let i=0;i<data.length;i++){
                let err1 = [];
                const date1 = data[i].employee.permit_application_date;
                const date2 = data[i].employee.permit_expiry_date;
                const employee = data[i].employee.id;
                const name = data[i].employee.name;
                const type = data[i].employee.permit_type;
                const permit = data[i].employee.permit;
                const number = data[i].employee.employee_number;
                const date3 = data[i].employee.permit_issued_date;
                const man = data[i].employee.permit_man_year_entitlement;
                let application_date =null;
                let expiry_date = null;
                let issued_date =null;
                let permit_type = null;
                let err_permit = false;
                let err_permit_type = false;
                let err_expiry = false;
                let err_issued = false;
                let err_application = false;
                
                let man_year_entitlement = false;
                if(man === 'true'){
                    man_year_entitlement = true;
                }
                if(permit === '' || permit === null ){
                    err1.push(<div><span>● {IMLocalized('permit_number_is_required')}</span></div>);
                    gridOptions.api.forEachNode((rowNode,index)=>{
                        if(rowNode.data.employee.employee_number === number){
                            rowNode.data.err_permit = true;
                            gridOptions.api.refreshCells(rowNode)
                        }
                    })
                    
                }
                
                if(type !== '' && type !== null ){
                    
                    const detail = permitlist.filter(function(item){
                        return item.display === type;
                    })
                    permit_type = detail[0].value;
                }
                else{
                    err1.push(<div><span>● {IMLocalized('permit_type_is_required')}</span></div>);
                    gridOptions.api.forEachNode((rowNode,index)=>{
                        if(rowNode.data.employee.employee_number === number){
                            rowNode.data.err_permit_type = true;
                            gridOptions.api.refreshCells(rowNode)
                        }
                    })
                }
                if(date1 !== null && date1 !== '' && date1 !== 'd/M/yy'){
                    application_date = this.convertDate(date1);
                    if(application_date === 'Invalid date'){
                        err1.push(<div><span>● {IMLocalized('application_date_is_invalid')}</span></div>);
                        gridOptions.api.forEachNode((rowNode,index)=>{
                            if(rowNode.data.employee.employee_number === number){
                                rowNode.data.err_application = true;
                                gridOptions.api.refreshCells(rowNode)
                            }
                        })
                    }
                }
                else{
                    const msg = IMLocalized('application_date_is_required');
                    err1.push(<div><span>● {msg}</span></div>);
                    gridOptions.api.forEachNode((rowNode,index)=>{
                        if(rowNode.data.employee.employee_number === number){
                            rowNode.data.err_application = true;
                            gridOptions.api.refreshCells(rowNode)
                        }
                    })
                }

                if(date2 !== null && date2 !== '' && date2 !== 'd/M/yy'){
                    expiry_date = this.convertDate(date2);
                    if(expiry_date === 'Invalid date'){
                        err1.push(<div><span>● {IMLocalized('expiry_date_is_invalid')}</span></div>);
                        gridOptions.api.forEachNode((rowNode,index)=>{
                            if(rowNode.data.employee.employee_number === number){
                                rowNode.data.err_expiry = true;
                                gridOptions.api.refreshCells(rowNode)
                            }
                        })
                    }
                }
                else{
                    const msg = IMLocalized('expiry_date_is_required');
                    err1.push(<div><span>● {msg}</span></div>);
                    gridOptions.api.forEachNode((rowNode,index)=>{
                        if(rowNode.data.employee.employee_number === number){
                            rowNode.data.err_expiry = true;
                            gridOptions.api.refreshCells(rowNode)
                        }
                    })
                }

                if(date3 !== null && date3 !== '' && date3 !== 'd/M/yy'){
                    issued_date = this.convertDate(date3);
                    if(issued_date === 'Invalid date'){
                        err1.push(<div><span>● {IMLocalized('issued_date_is_invalid')}</span></div>);
                        gridOptions.api.forEachNode((rowNode,index)=>{
                            if(rowNode.data.employee.employee_number === number){
                                rowNode.data.err_issued = true;
                                gridOptions.api.refreshCells(rowNode)
                            }
                        })
                    }
                }
                else{
                    const msg = IMLocalized('issued_date_is_required');
                    err1.push(<div><span>● {msg}</span></div>);
                    gridOptions.api.forEachNode((rowNode,index)=>{
                        if(rowNode.data.employee.employee_number === number){
                            rowNode.data.err_issued = true;
                            gridOptions.api.refreshCells(rowNode)
                        }
                    })
                }
                if(err1.length !== 0){
                    err.push({name,number,msg:err1})
                    validate.push({number,err_application,err_issued,err_expiry,err_permit,err_permit_type})
                }
                arr.push({employee,application_date,expiry_date,issued_date,permit,permit_type,man_year_entitlement});
            }
        }

        if(err.length !== 0){
            this.handleError(err,validate);
        }
        else{
            if(arr.length !== 0){
                this.props.employee_permit_array_save(arr);
            }
            else{
                this.popError(IMLocalized('no_employee_selected'));
            }
        }
        
    }

    handleError=(data,err)=>{
        let arr = [];
        for(let i=0;i<data.length ;i++){
            const msg = data[i].msg;
            const name = data[i].name;
            const number = data[i].number;
            arr.push(
                <tr>
                    <td>{number}</td>
                    <td>{name}</td>
                    <td>{msg}</td>
                </tr>
            )
        
        }
        this.setState({err_msg:arr});
        this.setState({err_toggle:true});
    }

    closeError=()=>{
        const toggle = !this.state.err_toggle;
        this.setState({err_toggle:toggle});
    }

    popError=(msg)=>{
        Swal.fire({
            title:msg,
            type:'warning',
            confirmButtonColor:'#d33',
            confirmButtonText:IMLocalized('ok!')
        })
    }

    convertDate=(date)=>{
        const date1 = moment(date,'dd/M/YY');
        const date2 = date1.format('YYYY-MM-DD');
        return date2;
    }

    onCellValueChanged=(params)=>{
        if(params.oldValue !== params.newValue){
            const column = params.column.colDef.field;
            if(column === 'employee.permit_man_year_entitlement'){
                const focusedCell = gridOptions.api.getFocusedCell();
                if(focusedCell !== undefined){
                    gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                        if(index === focusedCell.rowIndex){
                            rowNode.setSelected(true);
                        }
                    })
                }
            }
            if(column === 'employee.permit'){
                params.data.err_permit = false;
                const focusedCell = gridOptions.api.getFocusedCell();
                if(focusedCell !== undefined){
                    gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                        if(index === focusedCell.rowIndex){
                            rowNode.setSelected(true);
                        }
                    })
                }
            }
            else if(column === 'employee.permit_application_date'){
                params.data.err_application = false;
                const focusedCell = gridOptions.api.getFocusedCell();
                if(focusedCell !== undefined){
                    gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                        if(index === focusedCell.rowIndex){
                            rowNode.setSelected(true);
                        }
                    })
                }
            }
            else if(column === 'employee.permit_expiry_date'){
                params.data.err_expiry = false;
                const focusedCell = gridOptions.api.getFocusedCell();
                if(focusedCell !== undefined){
                    gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                        if(index === focusedCell.rowIndex){
                            rowNode.setSelected(true);
                        }
                    })
                }
            }
            else if(column === 'employee.permit_issued_date'){
                params.data.err_issued = false;
                const focusedCell = gridOptions.api.getFocusedCell();
                if(focusedCell !== undefined){
                    gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                        if(index === focusedCell.rowIndex){
                            rowNode.setSelected(true);
                        }
                    })
                }
            }
            else if(column === 'employee.permit_type'){
                params.data.err_permit_type = false;
                const focusedCell = gridOptions.api.getFocusedCell();
                if(focusedCell !== undefined){
                    gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                        if(index === focusedCell.rowIndex){
                            rowNode.setSelected(true);
                        }
                    })
                }
            }
            params.api.refreshCells();
        }
    }

    onCellFocused=(params)=>{
        if(params !== null){
            if(params.column.colDef !== null){
                if(params.column.colDef.field ==='employee.permit' || params.column.colDef.field ==='employee.permit_application_date' || params.column.colDef.field ==='employee.permit_expiry_date' || params.column.colDef.field ==='employee.permit_issued_date' || params.column.colDef.field ==='employee.permit_type' || params.column.colDef.field ==='employee.permit_man_year_entitlement'){
                    
                    params.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = true;
                }
                else{
                    params.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = false;
                }
            }   
        }
    }
    

    render(){

        const {rowData} = this.state;
        let btn ='';
        if(rowData.length !== 0){
            btn =  <button onClick={this.getTemplate} className="btn btn-primary btn-sm">{IMLocalized('get_emp_permit_template')}</button>
        }
        return(
            <div>
                {this.props.isLoading || this.props.isLoading1 ? <OverlayLoading/>:
                <div>
                <Container fluid>
                        <Row className="pt-2">
                            <Col>
                                <div className="d-flex">
                                    <div className="mr-2">
                                        <button className="btn btn-primary btn-sm" onClick={this.goBack}> <i className="fas fa-chevron-left"></i> {IMLocalized('back_to_main_page')}</button>
                                        <button onClick={this.skipStep} className="btn btn-primary btn-sm">{IMLocalized('skip')}</button>
                                        {btn}
                                    </div>
                                    <div className="ml-2 mr-2">
                                        <input type="file" style={{display:'none'}} id="ImportPermitcustomFile" className="custom-file-input form-control-sm" onChange={this.uploadExcel.bind(this)} onClick={this.handleFile} accept=".xlsx" />
                                        <label className="btn btn-primary btn-sm" for="ImportPermitcustomFile">{IMLocalized('import_from_file')}</label>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary btn-sm" onClick={this.clearData}>{IMLocalized('clear')}</button>
                                        <button className="btn btn-primary btn-sm" onClick={this.onCreate}>{IMLocalized('create')}</button>
                                        {/* {func_btn} */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="pt-2">
                            <Col>
                                <div className="d-flex justify-content-center">
                                    <div className="ag-theme-balham" style={{height:'calc(100vh - 200px)', width:'100%' }}>
                                        <AgGridReact
                                        columnDefs={this.state.columnsDefs}
                                        rowData={this.state.rowData}
                                        suppressDragLeaveHidesColumns={true}
                                        suppressSizeToFit={true}
                                        suppressColumnMoveAnimation={false}
                                        singleClickEdit={true}
                                        onCellValueChanged={this.onCellValueChanged}
                                        onCellFocused={this.onCellFocused}
                                        onGridReady={this.onGridReady}
                                        gridOptions={gridOptions}
                                        overlayNoRowsTemplate = {this.state.noRowTemplate}
                                        stopEditingWhenCellsLoseFocus={true}
                                        >
                                        </AgGridReact>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Modal isOpen={this.state.err_toggle} size="lg">
                        <ModalHeader toggle={this.closeError}>{IMLocalized('error_msg')}</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col>
                                    <Table size="sm" className="table-bordered align-items-center">
                                        <thead>
                                            <tr>
                                                <th>{IMLocalized('employee_number')}</th>
                                                <th>{IMLocalized('name')}</th>
                                                <th>{IMLocalized('error')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.err_msg}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn btn-danger btn-sm" onClick={this.closeError}>{IMLocalized('close')}</button>
                        </ModalFooter>
                    </Modal>
                </div>}
            </div>
        )
    }
}
function mapStateToProps(state,ownProps){
    return{
        permitlist:state.dropdown_list.work_permit,
        success:state.employee_permit_array_save.data,
        errors:state.employee_permit_array_save.errors,
        isLoading:state.dropdown_list.isLoading,
        isLoading1:state.employee_permit_array_save.isLoading,
    }
}
  
const mapDispatchToProps =(dispatch)=>{
    return{
        employee_permit_array_save:(data)=>{
            dispatch(employee_permit_array_save(data))
        }
    }
}
  
export default compose(connect(mapStateToProps,mapDispatchToProps))(wizard_employee_permit);
import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const CREATEALLOWANCE_SUCCESS = 'CREATEALLOWANCE_SUCCESS';
export const CREATEALLOWANCE_REQUEST = 'CREATEALLOWANCE_REQUEST';
export const CREATEALLOWANCE_FAILURE = 'CREATEALLOWANCE_FAILURE';
export const CREATEALLOWANCE_RESET = 'CREATEALLOWANCE_RESET';

export const create_allowance = (allowancecode,allowancename,amount,allowancegross,allowance_type)=>{
    return dispatch =>{
        dispatch({
            type: 'CREATEALLOWANCE_REQUEST'
        });
        
        myaxios.post(`company/allowance/`,
        {
            name:allowancename,
            code:allowancecode,
            amount:amount,
            is_included_in_gross:allowancegross,
            allowance_type:allowance_type,
        })
        .then((response)=>{
            dispatch({
                type:'CREATEALLOWANCE_SUCCESS',
                payload:response  
            });
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error) =>{
            dispatch({
                type: 'CREATEALLOWANCE_FAILURE',
                payload: error
            });
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_failure')
            });
        })
    }
}

export const reset_create_allowance = () => dispatch =>{
    dispatch({
        type:'CREATEALLOWANCE_RESET'
    });
}
import React,{Component} from 'react';
import { Row, Col,DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {connect} from 'react-redux';
import ExcelJs from 'exceljs';
import moment from 'moment';
import {DateRangePicker} from 'react-dates';
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import FileSaver from 'file-saver';
import { get_apply_leave_list2 } from '../../action/leave/get_apply_leave_list2';
import {IMLocalized} from '../../language/IMLocalized';
class summary_report_leave extends Component{


    constructor(props){
        super(props);
        this.state={
            list:[],
            startDate:null,
            endDate:null,
            start_date:null,
            end_date:null,
            dateFormat:'DD/MM/YYYY',
            focusedInput:null,
            employee_data:[],
            bottom_employee_data:[],
            bottom_section_data:[],
            section_data:[],
            department_data:[],
            bottom_department_data:[],
            company_data:[],
            bottom_company_data:[],
            summaryleave_toggle:false,
            isLoading1:false,
            isLoading2:false,
            isLoading3:false,
            isLoading4:false
        }
    }

    componentDidMount(){
        
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.period !== this.props.period){
            const {period} = this.props;
            const end_day = period.end_day;
            const pay_period = period.pay_period;
            const today = new Date();
            let start_date = null;
            let startDate = null;
            let end_date = null;
            let endDate = null;
            if(pay_period === "CUSTOM"){
                const item_year = today.getFullYear();
                const item_month = today.getMonth()+1;
                let end_item ='';
                if(end_day >=10){
                    end_item = end_day;
                }
                else{
                    end_item = '0'+end_day;
                }
                const item = item_year+'-'+item_month+'-'+end_item;
                const item_date = new Date(item);
                if(today > item_date){
                    startDate = moment(item_date, "DD-MM-YYYY").add(1, 'days');
                    start_date = startDate.format('YYYY-MM-DD');
                    endDate = moment(item_date).add(1, 'M');
                    end_date = endDate.format('YYYY-MM-DD');
                }
                else{
                    endDate = moment(item_date);
                    end_date = endDate.format('YYYY-MM-DD');
                    const date2 = moment(endDate).subtract(1,'month');
                    startDate = moment(date2,"DD-MM-YYYY").add(1,'days');
                    start_date = startDate.format('YYYY-MM-DD');
                }
            }
            else{
                start_date = moment().startOf('month').format('YYYY-MM-DD');
                end_date   = moment().endOf('month').format('YYYY-MM-DD');
                startDate = moment(start_date);
                endDate = moment(end_date);
              
            }
            
            this.setState({startDate});
            this.setState({start_date});
            this.setState({endDate});
            this.setState({end_date});
        }
        if(prevProps.applylist !== this.props.applylist || prevState.start_date !== this.state.start_date || prevState.end_date !== this.state.end_date || prevProps.leavelist !== this.props.leavelist){
            this.getDetail();
        }
    }

    getDetail=()=>{
        const {applylist} = this.props;
        const {start_date,end_date} = this.state;
        let detail = [];
        if(applylist.length !== 0 ){
            detail = applylist.filter(function(item){
                return item.status === 'CONSUMED'
            })
            if(start_date !== null){
                detail = detail.filter(function(item){
                    return new Date(item.date) >= new Date(start_date)
                })
            }
           
            if(end_date !== null){
                detail = detail.filter(function(item){
                    return new Date(item.date) <= new Date(end_date)
                })
            }
        }
        
        this.getData(detail);
        this.getSectionData(detail);
        this.getDepartmentData(detail);
        this.getCompanyData(detail);
    }

    getCompanyData=(list)=>{
        const {leavelist} = this.props;
        this.setState({isLoading1:true});
        let arr=[];
        let lookup ={};
        let arr1=[];
        if(leavelist.length !==0){
            let object={};
            for(let k=0;k<leavelist.length;k++){
                const name = leavelist[k].name;
                object[name]= {amount:0};
            }
            arr1.push({company:'Grands Totals :',leave_arr:object});
        }

        for(let i=0;i<list.length;i++){
            let object ={};
            if(leavelist.length !== 0){
                for( let m=0;m<leavelist.length;m++){
                    const name = leavelist[m].name;
                    object[name] = {amount:0};
                }
            }
            const company = list[i].employee.company;
            if(!(company in lookup)){
                lookup[company] = 1;
                arr.push({leave_arr:object,company});
            }
        }
        if(arr.length !== 0){
            for(let j=0;j<arr.length;j++){
                const company = arr[j].company;
                const detail = list.filter(function(item){
                    return item.employee.company === company
                })

                if(detail.length !== 0){
                    for(let x=0;x<detail.length ;x++){
                        const name = detail[x].leave_setting.name;
                        const amount = detail[x].amount;
                        const amount1 = arr[j].leave_arr[name].amount;
                        const total = amount + amount1;
                        arr[j].leave_arr[name].amount = total;
                        const amount2 = arr1[0].leave_arr[name].amount;
                        const total_amount = amount2 + amount;
                        arr1[0].leave_arr[name].amount = total_amount;
                    }
                }
            }
        }
        this.setState({bottom_company_data:arr1});
        this.setState({company_data:arr});
        this.setState({isLoading1:false});
    }

    getDepartmentData=(list)=>{
        const {leavelist} = this.props;
        this.setState({isLoading2:true});
        let arr=[];
        let lookup ={};
        let arr1=[];
        if(leavelist.length !==0){
            let object={};
            for(let k=0;k<leavelist.length;k++){
                const name = leavelist[k].name;
                object[name]= {amount:0};
            }
            arr1.push({department:'Grands Totals :',leave_arr:object});
        }

        for(let i=0;i<list.length;i++){
            let object ={};
            if(leavelist.length !== 0){
                for( let m=0;m<leavelist.length;m++){
                    const name = leavelist[m].name;
                    object[name] = {amount:0};
                }
            }
            const department = list[i].employee.contract.department;
            if(!(department in lookup)){
                lookup[department] = 1;
                arr.push({leave_arr:object,department});
            }
        }
        if(arr.length !== 0){
            for(let j=0;j<arr.length;j++){
                const department = arr[j].department;
                const detail = list.filter(function(item){
                    return item.employee.contract.department === department
                })

                if(detail.length !== 0){
                    for(let x=0;x<detail.length ;x++){
                        const name = detail[x].leave_setting.name;
                        const amount = detail[x].amount;
                        const amount1 = arr[j].leave_arr[name].amount;
                        const total = amount + amount1;
                        arr[j].leave_arr[name].amount = total;
                        const amount2 = arr1[0].leave_arr[name].amount;
                        const total_amount = amount2 + amount;
                        arr1[0].leave_arr[name].amount = total_amount;
                    }
                }
            }
        }
        this.setState({bottom_department_data:arr1});
        this.setState({department_data:arr});
        this.setState({isLoading2:false});
    }

    getSectionData=(list)=>{
        const {leavelist} = this.props;
        this.setState({isLoading3:true});
        let arr=[];
        let lookup ={};
        let arr1=[];
        if(leavelist.length !==0){
            let object={};
            for(let k=0;k<leavelist.length;k++){
                const name = leavelist[k].name;
                object[name]= {amount:0};
            }
            arr1.push({section:'Grands Totals :',leave_arr:object});
        }

        for(let i=0;i<list.length;i++){
            let object ={};
            if(leavelist.length !== 0){
                for( let m=0;m<leavelist.length;m++){
                    const name = leavelist[m].name;
                    object[name] = {amount:0};
                }
            }
            const section = list[i].employee.contract.section;
            if(!(section in lookup)){
                lookup[section] = 1;
                arr.push({leave_arr:object,section});
            }
        }
        if(arr.length !== 0){
            for(let j=0;j<arr.length;j++){
                const section = arr[j].section;
                const detail = list.filter(function(item){
                    return item.employee.contract.section === section
                })

                if(detail.length !== 0){
                    for(let x=0;x<detail.length ;x++){
                        const name = detail[x].leave_setting.name;
                        const amount = detail[x].amount;
                        const amount1 = arr[j].leave_arr[name].amount;
                        const total = amount + amount1;
                        arr[j].leave_arr[name].amount = total;
                        const amount2 = arr1[0].leave_arr[name].amount;
                        const total_amount = amount2 + amount;
                        arr1[0].leave_arr[name].amount = total_amount;
                    }
                }
            }
        }
        this.setState({bottom_section_data:arr1});
        this.setState({section_data:arr});
        this.setState({isLoading3:false})
    }

    getData=(list)=>{
        const {leavelist} = this.props;
        this.setState({isLoading4:true});
        let arr =[];
        let lookup = {};
        let arr1 = [];
        if(leavelist.length !== 0){
            let object ={};
            for(let k=0;k<leavelist.length;k++){
                const name = leavelist[k].name;
                object[name] = {amount:0};
            }
            arr1.push({employee:{employee_number:'Grands Totals :',name:''},leave_arr:object});
        }
        
        for(let i=0;i<list.length;i++){
            let object ={};
            if(leavelist.length !== 0){
                for(let m =0;m<leavelist.length;m++){
                    const name = leavelist[m].name;
                    object[name] = {amount:0};
                }
            }
            const name = list[i].employee.name;
            const id = list[i].employee.id;
            const employee = list[i].employee;
            if(!(name in lookup)){
                lookup[name] = 1;
                arr.push({id,leave_arr:object,employee});
            }
        }
        
        if(arr.length !== 0){
            for(let j=0;j<arr.length;j++){
                const id = arr[j].id;
                const detail = list.filter(function(item){
                    return item.employee.id === id
                })
                if(detail.length !== 0){
                    for(let x=0;x<detail.length;x++){
                        const name = detail[x].leave_setting.name;
                        const amount = detail[x].amount;
                        const amount1 = arr[j].leave_arr[name].amount;
                        const total = amount + amount1;
                        arr[j].leave_arr[name].amount = total;
                        const amount2 = arr1[0].leave_arr[name].amount;
                        const total_amount = amount2 + amount;
                        arr1[0].leave_arr[name].amount = total_amount;
                    }
                }
            }
        }
        this.setState({bottom_employee_data:arr1});
        this.setState({employee_data:arr});
        this.setState({isLoading4:false});
    }

    generateExcel=()=>{
        const {leavelist} = this.props;
        const {employee_data,bottom_employee_data,section_data,bottom_section_data,department_data,bottom_department_data,company_data,bottom_company_data} = this.state;
        const {start_date,end_date} = this.state;
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('By Employee');
        const today = moment(new Date()).format('DD/MM/YYYY');
        const time = moment().format('HH:mm:ss');
        const day =moment(new Date()).format("DD");
        const month =moment(new Date()).format("MM");
        const title_name = 'LeaveSummary_'+day+'-'+month;
        ws.getCell('A1').value = 'Leave Summary Report - Employee';
        ws.getCell('A2').value = 'Start Date : '+this.getDateFormat(start_date)+'          End Date : '+this.getDateFormat(end_date)+'     Printed On : '+today+' '+time;
        let size = [
            {width: 15},
            {width: 20}, 
            
           ];
        const column = [
            {header:'Emp ID',key:'employee.employee_number'},
            {header:'Name',key:'employee.name'},
        ]
        for(let i=0;i<leavelist.length;i++){
            const header = leavelist[i].name;
            const length = header.length;
            const key = `leave_arr.${header}.amount`;
            column.push({header,key});
            if(length <15){
                size.push({width:15});
            }
            else{
                size.push({width:length})
            }
        }

        ws.columns = size;
        
        for(let j =0;j<column.length;j++){
            const headerName = column[j].header;
            const field = (column[j].key);
            const length = employee_data.length +1;
            ws.getCell(this.getColumnName(j)+'5').value = headerName;
            for(let x=0;x<length;x++){
                const step = 6+x;
                if(x<length -1){
                    const item = this.getValue(employee_data[x],field);
                    if(item === 0 || item === null || item=== undefined){
                        ws.getCell(this.getColumnName(j)+step).value = '-';
                    }
                    else{
                        ws.getCell(this.getColumnName(j)+step).value = item;
                    }
                }
                else if(x === length -1){
                    const item = this.getValue(bottom_employee_data[0],field);
                    if(item === 0 || item === null || item === undefined){
                        ws.getCell(this.getColumnName(j)+step).value = '-';
                    }
                    else{
                        ws.getCell(this.getColumnName(j)+step).value = item;
                    }
                    
                }
            }
        }

        const ws2 = wb.addWorksheet('By Section');
        ws2.getCell('A1').value = 'Leave Summary Report - Section';
        ws2.getCell('A2').value = 'Start Date : '+this.getDateFormat(start_date)+'          End Date : '+this.getDateFormat(end_date)+'     Printed On : '+today+' '+time;
        const section_column = [
            {header:'Section',key:'section'}
        ]
        for(let i=0;i<leavelist.length;i++){
            const header = leavelist[i].name;
            const key = `leave_arr.${header}.amount`;
            section_column.push({header,key});
        }

        let section_size =[];
        for(let j =0;j<section_column.length;j++){
            const header = section_column[j].header;
            const length = header.length;
            if(length <15){
                section_size.push({width:15});
            }
            else{
                section_size.push({width:length})
            }
        }

        ws2.columns = section_size;

        for(let j =0;j<section_column.length;j++){
            const headerName = section_column[j].header;
            const field = (section_column[j].key);
            const length = section_data.length +1;
            ws2.getCell(this.getColumnName(j)+'5').value = headerName;
            for(let x=0;x<length;x++){
                const step = 6+x;
                if(x<length -1){
                    const item = this.getValue(section_data[x],field);
                    if(item === 0 || item === null || item=== undefined){
                        ws2.getCell(this.getColumnName(j)+step).value = '-';
                    }
                    else{
                        ws2.getCell(this.getColumnName(j)+step).value = item;
                    }
                }
                else if(x === length -1){
                    const item = this.getValue(bottom_section_data[0],field);
                    if(item === 0 || item === null || item === undefined){
                        ws2.getCell(this.getColumnName(j)+step).value = '-';
                    }
                    else{
                        ws2.getCell(this.getColumnName(j)+step).value = item;
                    }
                    
                }
            }
        }

        const ws3 = wb.addWorksheet('By Department');
        ws3.getCell('A1').value = 'Leave Summary Report - Department';
        ws3.getCell('A2').value = 'Start Date : '+this.getDateFormat(start_date)+'          End Date : '+this.getDateFormat(end_date)+'     Printed On : '+today+' '+time;
        const department_column = [
            {header:'Department',key:'department'}
        ]
        for(let i=0;i<leavelist.length;i++){
            const header = leavelist[i].name;
            const key = `leave_arr.${header}.amount`;
            department_column.push({header,key});
        }

        let department_size =[];
        for(let j =0;j<department_column.length;j++){
            const header = department_column[j].header;
            const length = header.length;
            if(length <15){
                department_size.push({width:15});
            }
            else{
                department_size.push({width:length})
            }
        }

        ws3.columns = department_size;

        for(let j =0;j<department_column.length;j++){
            const headerName = department_column[j].header;
            const field = (department_column[j].key);
            const length = department_data.length +1;
            ws3.getCell(this.getColumnName(j)+'5').value = headerName;
            for(let x=0;x<length;x++){
                const step = 6+x;
                if(x<length -1){
                    const item = this.getValue(department_data[x],field);
                    if(item === 0 || item === null || item=== undefined){
                        ws3.getCell(this.getColumnName(j)+step).value = '-';
                    }
                    else{
                        ws3.getCell(this.getColumnName(j)+step).value = item;
                    }
                }
                else if(x === length -1){
                    const item = this.getValue(bottom_department_data[0],field);
                    if(item === 0 || item === null || item === undefined){
                        ws3.getCell(this.getColumnName(j)+step).value = '-';
                    }
                    else{
                        ws3.getCell(this.getColumnName(j)+step).value = item;
                    }
                    
                }
            }
        }

        const ws4 = wb.addWorksheet('By Company');
        ws4.getCell('A1').value = 'Leave Summary Report - Company';
        ws4.getCell('A2').value = 'Start Date : '+this.getDateFormat(start_date)+'          End Date : '+this.getDateFormat(end_date)+'     Printed On : '+today+' '+time;
        const company_column = [
            {header:'Company',key:'company'}
        ]
        for(let i=0;i<leavelist.length;i++){
            const header = leavelist[i].name;
            const key = `leave_arr.${header}.amount`;
            company_column.push({header,key});
        }

        let company_size =[];
        for(let j =0;j<company_column.length;j++){
            const header = company_column[j].header;
            const length = header.length;
            if(length <15){
                company_size.push({width:15});
            }
            else{
                company_size.push({width:length})
            }
        }

        ws4.columns = company_size;

        for(let j =0;j<company_column.length;j++){
            const headerName = company_column[j].header;
            const field = (company_column[j].key);
            const length = company_data.length +1;
            ws4.getCell(this.getColumnName(j)+'5').value = headerName;
            for(let x=0;x<length;x++){
                const step = 6+x;
                if(x<length -1){
                    const item = this.getValue(company_data[x],field);
                    if(item === 0 || item === null || item=== undefined){
                        ws4.getCell(this.getColumnName(j)+step).value = '-';
                    }
                    else{
                        ws4.getCell(this.getColumnName(j)+step).value = item;
                    }
                }
                else if(x === length -1){
                    const item = this.getValue(bottom_company_data[0],field);
                    if(item === 0 || item === null || item === undefined){
                        ws4.getCell(this.getColumnName(j)+step).value = '-';
                    }
                    else{
                        ws4.getCell(this.getColumnName(j)+step).value = item;
                    }
                    
                }
            }
        }


        const file_name = title_name +'.xlsx';
        wb.xlsx.writeBuffer().then((buf)=>{
            var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
            FileSaver.saveAs(file, file_name)
        });

        const toggle = !this.state.summaryleave_toggle;
        this.setState({summaryleave_toggle:toggle});
    }

    getDateFormat=(date)=>{
        const date1 = moment(date,'YYYY-MM-DD');
        const new_date = date1.format('DD/MM/YYYY');
        return new_date;
    }

    getColumnName=(n)=>{
        let ordA = 'A'.charCodeAt(0);
        let ordZ = 'Z'.charCodeAt(0);
        let len = ordZ - ordA +1;

        let s = '';
        while(n >=0){
            s = String.fromCharCode(n % len + ordA )+s;
            n = Math.floor(n/len) -1;
        }
        return s;
    }
    getValue(object, path) {
        const parts = path.split(".");
      
        return parts.reduce((value, key) => value ? value[key] : value, object)
    }


    onDatesChange({startDate,endDate}){
        this.setState({startDate,endDate})
        let start_date = null;
        if(startDate !== null){
            let startdate =startDate._d;
            let startmonth = (startdate.getMonth()+1);
            if(startmonth <10){
                startmonth = '0'+startmonth;
            }
            let startday = startdate.getDate();
            if(startday<10){
                startday = '0'+startday
            }
            start_date = startdate.getFullYear()+'-' + startmonth+'-' +startday
         
        }
        
        let end_date = null;
        if(endDate !== null){
            let enddate = endDate._d;
            let endmonth =(enddate.getMonth()+1);
            if(endmonth <10){
                endmonth = '0'+endmonth;
            }
            let endday = enddate.getDate();
            if(endday<10){
                endday = '0'+endday
            }
            end_date = enddate.getFullYear()+'-'+endmonth+'-'+endday
        }
        this.setState({end_date});
        this.setState({start_date});
    }

    onFocusChange=(focusedInput)=>{
        this.setState({focusedInput})
    }

    modal=()=>{
        const toggle = !this.state.summaryleave_toggle;
        if(toggle === false){
            const {period} = this.props;
            const end_day = period.end_day;
            const pay_period = period.pay_period;
            const today = new Date();
            let start_date = null;
            let startDate = null;
            let end_date = null;
            let endDate = null;
            if(pay_period === "CUSTOM"){
                const item_year = today.getFullYear();
                const item_month = today.getMonth()+1;
                let end_item ='';
                if(end_day >=10){
                    end_item = end_day;
                }
                else{
                    end_item = '0'+end_day;
                }
                const item = item_year+'-'+item_month+'-'+end_item;
                const item_date = new Date(item);
                if(today > item_date){
                    startDate = moment(item_date, "DD-MM-YYYY").add(1, 'days');
                    start_date = startDate.format('YYYY-MM-DD');
                    endDate = moment(item_date).add(1, 'M');
                    end_date = endDate.format('YYYY-MM-DD');
                }
                else{
                    endDate = moment(item_date);
                    end_date = endDate.format('YYYY-MM-DD');
                    const date2 = moment(endDate).subtract(1,'month');
                    startDate = moment(date2,"DD-MM-YYYY").add(1,'days');
                    start_date = startDate.format('YYYY-MM-DD');
                }
            }
            else{
                start_date = moment().startOf('month').format('YYYY-MM-DD');
                end_date   = moment().endOf('month').format('YYYY-MM-DD');
                startDate = moment(start_date);
                endDate = moment(end_date);
              
            }
            
            this.setState({startDate});
            this.setState({start_date});
            this.setState({endDate});
            this.setState({end_date});
        }
        else{
            // this.props.get_apply_leave_list2();
        }
        this.setState({summaryleave_toggle:toggle});
    }

    render(){
        return(
            <div>
                <DropdownItem onClick={this.modal} className="cursor-pointer">{IMLocalized('summary_report_leave')}</DropdownItem>
                <Modal size="lg" isOpen={this.state.summaryleave_toggle}>
                    <ModalHeader toggle={this.modal}>{IMLocalized('summary_report_leave')}</ModalHeader>
                    <ModalBody>
                        <Row className="pt-2">
                            <Col>
                                <div className="mr-2">
                                <DateRangePicker 
                                    startDate={this.state.startDate}
                                    isOutsideRange={()=>false}
                                    small = {true}
                                    startDateId="unique_start_date_id"
                                    endDate={this.state.endDate}
                                    endDateId="unique_end_date_id"
                                    onDatesChange={this.onDatesChange.bind(this)}
                                    focusedInput={this.state.focusedInput}
                                    displayFormat={this.state.dateFormat}
                                    onFocusChange={this.onFocusChange} 
                                />
                                </div>
                                
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {this.props.isLoading || this.props.isLoading1 || this.props.isLoading2 || this.state.isLoading1 || this.state.isLoading2 || this.state.isLoading3 || this.state.isLoading4 ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button> :
                            <button onClick={this.generateExcel} className="btn btn-primary btn-sm">{IMLocalized('export_summary_report_leave')}</button>
                        }
                        <button className="btn btn-danger btn-sm" onClick={this.modal}>{IMLocalized('close')}</button>
                    </ModalFooter>
                </Modal>
            </div>
            
        )
    }
}
function mapStateToProps(state,ownProps){
    return{
        period:state.get_payroll_period.data,
        applylist:state.get_apply_leave_list2.data,
        leavelist:state.get_leave_list.data,
        isLoading:state.get_payroll_period.isLoading,
        isLoading1:state.get_apply_leave_list2.isLoading,
        isLoading2:state.get_leave_list.isLoading,
    }
}

const mapDispatchToProps =(dispatch)=>{
    return{
        get_apply_leave_list2:()=>{
            dispatch(get_apply_leave_list2())
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(summary_report_leave);
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Table, Container, Row, Col, Tooltip } from 'reactstrap';
import {get_iras_detail, reset_get_iras_detail} from '../../action/iras/get_iras_detail';
import {get_iras_login} from '../../action/iras/get_iras_login';
import OverlayLoading from '../loading_component/overlay_loading';
import {IMLocalized} from '../../language/IMLocalized';
import '../../css/iras_text.css';
import { AgGridReact } from 'ag-grid-react';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getDateFormat } from '../../func/getDateFormat';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';
import { popError } from '../../func/popError';

const gridOption={
    defaultColDef:{
        enableValue:true,
        enableRowGroup:true,
        enablePivot:true,
        sortable:true,
        filter:true,
        resizable:true,
        editable:true
    },
    sideBar:true
}

export default function IrasDetail(props){

    const detail = useSelector(state=>state.get_iras_detail.data);
    const nationalitylist = useSelector(state=>state.get_iras_nationality.data);
    const post_success = useSelector(state=>state.get_iras_login.data);
    const emplist = useSelector(state=>state.get_employee_list.data);
    const isLoading3 = useSelector(state=>state.get_employee_list.isLoading);
    const isLoading2 = useSelector(state=>state.get_iras_login.isLoading);
    const isLoading1 = useSelector(state=>state.get_iras_nationality.isLoading);
    const isLoading = useSelector(state=>state.get_iras_detail.isLoading);
    const dispatch  = useDispatch();
    const { addItem, setIsRedirect, is_redirect, setIrasStep, iras_id } = useContext(NewMainContext);
    const [ has_posted, setHasPosted] = useState(false);
    const [ company, setCompany] = useState('');
    const [ year, setYear] = useState('');
    const [ data, setData] = useState(null);
    const [ rowData, setRowData] = useState([]);

    const [tooltip_toggle,setTooltipToggle] = useState(false);
    const [is_edit,setEdit] = useState(false);

    const [ validateList, setValidateList] = useState([]);
    const [ dummy, setDummy ] = useState([]);

    const irasRef = useRef();

    useEffect(()=>{
        if(iras_id !== ''){
            dispatch(get_iras_detail(iras_id));
        }
    },[iras_id,dispatch])

    useEffect(()=>{
        if(detail != null && nationalitylist.length !== 0){
            const {employees,BasisYear,EmployerName,has_posted} = detail;
            setCompany(EmployerName);
            setYear(BasisYear);
            setHasPosted(has_posted);
            
            const arr = employees.map((item)=>{
                let dd ={};
                let err={};

                // const cellClassRules={
                //     'green-bg':(params)=>{return params.data.dd[name.replace('.','-')]},
                //     'orange-bg':(params)=>{return params.data.err[name.replace('.','-')]}
                // }

                const {Nationality,employee} = item;
                const nation = nationalitylist.find(el=>el.value === Nationality);
                const nationality_name = nation?.nationality ?? '';
                const from_date1 = getDateFormat(item.PaymentPeriodFromDate);
                const to_date1 = getDateFormat(item.PaymentPeriodToDate);

                const empid = emplist.filter(e=>e.id === employee).map(f=>f.employee_number)
                const contract_cache = emplist.filter(e=>e.id === employee).map(f=>f.contract_cache)
                const firstContract = contract_cache.length > 0 ? contract_cache[0] : {};
    
                const cost_center = firstContract ? firstContract.cost_center : '';
                const department = firstContract ? firstContract.department : '';
                const job_level = firstContract ? firstContract.job_level : '';
                const job_title = firstContract ? firstContract.job_title : '';
                const section = firstContract ? firstContract.section : '';
            
                return {...item,from_date1,to_date1,nationality_name,empid,cost_center,department,job_level,job_title,section,dd,err}
            });
            setData(detail);
            setRowData(arr);
        }
    },[detail,nationalitylist,emplist])

    useEffect(()=>{
        if(!is_redirect && post_success != null){
            const {url} = post_success;
            localStorage.setItem('reoutside','1');
            localStorage.setItem('reside','1');
            window.location.href=url;
        }
    },[post_success,is_redirect])

    // const toDetail=useCallback((id,emp)=>{
    //     if(is_edit === true){
    //         Swal.fire({
    //             title:IMLocalized('changes_may_not_be_saved'),
    //             type:'warning',
    //             showCancelButton:true,
    //             cancelButtonColor:'#d33',
    //             confirmButtonColor:'#3085d6',
    //             confirmButtonText:IMLocalized('yes!'),
    //             cancelButtonText:IMLocalized('no!'),
    //         })
    //         .then((result)=>{
    //             if('value' in result){
    //                 setEdit(false);
    //                 toPayrollPayslip({step:'4',id,emp});
    //             }
    //         })
    //     }
    //     else{
    //         toPayrollPayslip({step:'4',id,emp});
    //     }
    // },[toPayrollPayslip,setEdit,is_edit])

    // const myCellRenderer = useCallback((params)=>{
        
    //     return <a href="/#" onClick={()=>toDetail(props.id,params.data.employee.id)} >{params.value}</a>
    // },[props.id,toDetail])

    const onCellValueChanged=(params)=>{
        if(params.oldValue !== params.newValue){
            const {id} = params.data;
            const { field } = params.colDef;
            var row = params.rowIndex;
            var rowNode = params.api.getDisplayedRowAtIndex(row);
            var column = params.column;

            if(field === 'Amount') {
                params.data.edit_Amount = true;
            }
            if(field === 'Salary') {
                params.data.edit_Salary = true;
            }
            if(field === 'Others') {
                params.data.edit_Others = true;
            }
            if(field === 'CPF') {
                params.data.edit_CPF = true;
            }
            if(field === 'GrossCommissionAmount') {
                params.data.edit_GrossCommissionAmount = true;
            }
            if(field === 'Pension') {
                params.data.edit_Pension = true;
            }
            if(field === 'TransportAllowance') {
                params.data.edit_TransportAllowance = true;
            }
            if(field === 'EntertainmentAllowance') {
                params.data.edit_EntertainmentAllowance = true;
            }
            if(field === 'OtherAllowance') {
                params.data.edit_OtherAllowance = true;
            }
            if(field === 'Gratuity') {
                params.data.edit_Gratuity = true;
            }
            if(field === 'ExcessEmployerContributionToCPF') {
                params.data.edit_ExcessEmployerContributionToCPF = true;
            }
            if(field === 'MBF') {
                params.data.edit_MBF = true;
            }
            if(field === 'Donation') {
                params.data.edit_Donation = true;
            }
            if(field === 'Insurance') {
                params.data.edit_Insurance = true;
            }
            if(field === 'Bonus') {
                params.data.edit_Bonus = true;
            }
            if(field === 'DirectorsFees') {
                params.data.edit_DirectorsFees = true;
            }
            if(field === 'IDNo') {
                params.data.edit_IDNo = true;
            }
            if(field === 'from_date1') {
                params.data.edit_from_date1 = true;
            }
            if(field === 'to_date1') {
                params.data.edit_to_date1 = true;
            }
            if(field === 'Bonus') {
                params.data.edit_Bonus = true;
            }

            const detail = validateList.findIndex(item=>item.id === id);
            if(detail === -1){
                setValidateList(prevState=>[...prevState,params.data]);
            }
            else{
                setValidateList(prevState=>{
                    const arr = [...prevState];
                    arr[detail] = params.data;
                    return arr;
                })
            }
            params.api.flashCells({rowNodes:[rowNode],columns:[column]})
            params.api.refreshCells();
            
        }
    }

    const onGridReady = (params) =>{
        irasRef.current =  params.api;
    }

    const exportExcel = ()=>{
        const today = moment(new Date()).format('DD/MM/YYYY');
        const time = moment().format('HH:mm:ss');
        const getRows = ()=>[
            [
                {
                    data:{
                        value: 'IRAS -' +company+' (' + year + ') - Printed On :' +today + ' ' + time,
                        type: 'String',
                    }
                },
            ],
            []
        ];

        const getParams=()=>({
            prependContent: getRows(),
            fileName:'IRAS_'+company+'_'+year+'.xlsx',
            sheetName:'iras',
            columnWidth: 120
        });

        irasRef.current.exportDataAsExcel(getParams());
    }

    const updateSelf = ()=>{
        const data = validateList.map(item=>item)
        // dispatch(update_employee_profile_mass(data));
        setDummy((v)=>v + dummy)
    }

    const submitIras=()=>{
        dispatch(get_iras_login(iras_id));
        setIsRedirect(true);
    }

    const backMain = () =>{
        setIrasStep('0'); 
        dispatch(reset_get_iras_detail());
    }

    return(
        <>
        {isLoading || isLoading1 || isLoading3  ? <OverlayLoading/>:
        <>
        <Container fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: false, title:IMLocalized('iras'), onClick:()=>backMain()},
                        { active: true, title:data? `${data.EmployerName} (${data.BasisYear})` : '-' }
                    ]}/>
                    <Row>
                        <Col>
                            <button className="btn btn-primary btn-sm ml-2" onClick={()=>{ setIrasStep('0'); dispatch(reset_get_iras_detail()) }}> <i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                        </Col>
                        <Col className='col-auto'>
                            {isLoading2 && <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>}
                            {!isLoading2 && !has_posted && <button className="btn btn-primary btn-sm" onClick={submitIras} >{IMLocalized('submit_to_iras')}</button>}
                            <button className="btn btn-success btn-sm" onClick={exportExcel}>{IMLocalized('export')}</button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {data !== null &&
            <Row className='mt-2'>
                <Col>
                    <Table className="table-sm table-white table-bordered" responsive>
                        <tbody>
                            <tr>
                                <td>
                                    <span className="iras_title iras_font_size">Company Name : </span> <span className="iras_font_size">{data.EmployerName}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Year: </span> <span className="iras_font_size">{data.BasisYear}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Created Date : </span> <span className="iras_font_size">{moment(new Date(data.created)).format('DD/MM/YYYY HH:mm:ss')}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Auth Person Number : </span> <span className="iras_font_size">{data.Telephone}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="iras_title iras_font_size">Auth Person Name : </span> <span className="iras_font_size">{data.AuthorisedPersonName}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Auth Person Email : </span> <span className="iras_font_size">{data.AuthorisedPersonEmail}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Amount : </span> <span className="iras_font_size">{data.TotalPayment}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Salary : </span> <span className="iras_font_size">{data.TotalSalary}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="iras_title iras_font_size">CPF : </span> <span className="iras_font_size">{data.TotalCPF}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Bonus : </span> <span className="iras_font_size">{data.TotalBonus}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Director Fee : </span> <span className="iras_font_size">{data.TotalDirectorsFees}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Donation : </span> <span className="iras_font_size">{data.TotalDonation}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="iras_title iras_font_size">Exempt Income : </span> <span className="iras_font_size">{data.TotalExemptIncome}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Insurance : </span> <span className="iras_font_size">{data.TotalInsurance}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">MBMF : </span> <span className="iras_font_size">{data.TotalMBF}</span>
                                </td>
                                <td>
                                    <span className="iras_title iras_font_size">Others : </span> <span className="iras_font_size">{data.TotalOthers}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="iras_title iras_font_size">Records: </span> <span className="iras_font_size">{data.NoOfRecords}</span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>}
            {/* <Col className='col-auto'>
                <button id="payrollUpdateTooltip" className="btn btn-primary btn-sm">{IMLocalized('update_changes')}</button>
            </Col> */}
            <Row className='mt-2 mb-2'>
                <Col>

                {/* {is_edit && <Tooltip placement="down" isOpen={tooltip_toggle} target="payrollUpdateTooltip" toggle={()=>setTooltipToggle(false)} >{IMLocalized('please_click_here_to_update_changes')}</Tooltip>} */}

                    <div className='ag-theme-balham' style={{height:'calc(100vh - 280px)', width:'100%' }}>
                        <AgGridReact
                        columnDefs={[
                            {
                                headerName:'EMP ID',
                                field:'empid',
                                width:100,
                                editable:false
                            },
                            {
                                headerName:'EMP NAME',
                                field:'NameLine1',
                                width:200,
                                editable:false
                            },
                            {
                                headerName:'TOTAL AMOUNT',
                                field:'Amount',
                                width:150,
                                sortable:true,
                                editable:true,
                                enableCellChangeFlash:true,
                                undoRedoCellEditing: true,
                            },
                            {
                                headerName:'SALARY',
                                field:'Salary',
                                width:150,
                            },
                            {
                                headerName:'OTHERS',
                                field:'Others',
                                width:150,
                            },
                            {
                                headerName:'CPF',
                                field:'CPF',
                                width:150,
                            },
                            {
                                headerName:'GROSS COMMISSION',
                                field:'GrossCommissionAmount',
                                width:150,
                            },
                            {
                                headerName:'PENSION',
                                field:'Pension',
                                width:150,
                            },
                            {
                                headerName:'TRANSPORT ALLOWANCE',
                                field:'TransportAllowance',
                                width:100,
                            },
                            {
                                headerName:'ENTERTAINMENT ALLOWANCE',
                                field:'EntertainmentAllowance',
                                width:150,
                            },
                            {
                                headerName:'OTHER ALLOWANCE',
                                field:'OtherAllowance',
                                width:150,
                            },
                            {
                                headerName:'GRATUITY',
                                field:'gratuity',
                                width:150,
                            },
                            {
                                headerName:'EMPLOYER CPF EXCESS',
                                field:'ExcessEmployerContributionToCPF',
                                width:150,
                            },
                            {
                                headerName:'MBMF',
                                field:'MBF',
                                width:150,
                            },
                            {
                                headerName:'DONATION',
                                field:'Donation',
                                width:150,
                            },
                            {
                                headerName:'INSURANCE',
                                field:'Insurance',
                                width:150,
                            },
                            {
                                headerName:'BONUS',
                                field:'Bonus',
                                width:150,
                            },
                            {
                                headerName:'DIRECTOR FEES',
                                field:'DirectorsFees',
                                width:150,
                            },
                            {
                                headerName:'IDENTIFICATION NUMBER',
                                field:'IDNo',
                                width:150,
                            },
                            {
                                headerName:'FROM DATE',
                                field:'from_date1',
                                width:150,
                            },
                            {
                                headerName:'TO DATE',
                                field:'to_date1',
                                width:150,
                            },
                            {
                                headerName:'NATIONALITY',
                                field:'nationality_name',
                                width:200,
                                editable:false
                            },
                            {
                                headerName:'DEPARTMENT',
                                field:'department',
                                width:200,
                                editable:false
                            },
                            {
                                headerName:'SECTION',
                                field:'section',
                                width:200,
                                editable:false
                            },
                            {
                                headerName:'JOB TITLE',
                                field:'job_title',
                                width:200,
                                editable:false
                            },
                            {
                                headerName:'JOB LEVEL',
                                field:'job_level',
                                width:200,
                                editable:false
                            },
                            {
                                headerName:'COST CENTER',
                                field:'cost_center',
                                width:200,
                                editable:false
                            },
                        ]}
                        rowData={rowData}
                        gridOptions={gridOption}
                        suppressDragLeaveHidesColumns={true}
                        onGridReady={onGridReady}
                        onCellValueChanged={onCellValueChanged}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
        </>}
        </>
    )
}
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormFeedback, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row ,Input} from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { assign_claim_approval, reset_assign_claim_approval } from '../../../../action/approvals/assign_claim_approval';
import { popError } from '../../../../func/popError';


export default function ModalAssignClaimApproval({toggle, onSuccess, closeModal, type, join_date, id}){

    const claimapproval_list = useSelector(state=>state.get_claim_approval.data);
    const isLoading = useSelector(state=>state.assign_claim_approval.isLoading);
    const success = useSelector(state=>state.assign_claim_approval.data);
    const errors = useSelector(state=>state.assign_claim_approval.errors);
    const dispatch = useDispatch();
    const [start_date,setStartDate] = useState('');
    const [err_start_date,setErrStartDate] = useState('');
    const [group,setGroup] = useState('');
    const [err_group,setErrGroup] = useState('');

    useEffect(()=>{
        if(toggle){
            if(type === 'create_employee' && join_date){
                setStartDate(join_date);
            }
            else{
                setStartDate('');
                
            }
            setErrStartDate('');
            setErrGroup('');
            setGroup('');
        }
    },[toggle,type,join_date])

    useEffect(()=>{
        if( success !== null && toggle ){
            dispatch(reset_assign_claim_approval());
            onSuccess();
        }
    },[success,onSuccess,toggle,dispatch])

    useEffect(()=>{
        if( errors !== null && toggle){
            if(errors.hasOwnProperty('non_field_errors')){
                const word = errors['non_field_errors'].toString();
                popError(word);
            }
            dispatch(reset_assign_claim_approval());
        }
    },[errors,toggle,dispatch])

    const assignApproval = ()=>{
        if(start_date !== '' && group !== ''){
            const detail = claimapproval_list.find((item)=>item.id === group);
            let approval_master = null
            if(detail){
                approval_master = detail.approval_master;
            }
            dispatch(assign_claim_approval(id,start_date,group,approval_master));
        }
        else{
            if(start_date === ''){
                setErrStartDate('')
            }
            if(group === ''){   
                setErrGroup('');
            }
        }
    }

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('assign_claim_approval')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='start_date'>{IMLocalized('start_date')}</label>
                            <Input type="date" id="start_date" size="sm" value={start_date} onChange={(e)=>{setStartDate(e.target.value); setErrStartDate('')}} invalid={err_start_date.length >0} />
                            <FormFeedback>{err_start_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='group'>{IMLocalized('approval_group')}</label>
                            <Input type="select" size="sm" name="group" value={group} id="group" onChange={(e)=>{setGroup(e.target.value); setErrGroup('')}} invalid={err_group.length > 0}>
                                <option></option>
                                {claimapproval_list.map((item,index)=>{
                                    return <option value={item.id} key={index}>{item.name}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_group}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading?
                <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button onClick={assignApproval} className="btn btn-primary btn-sm">{IMLocalized('assign')}</button>
                    <button onClick={closeModal} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                </>
                }
            </ModalFooter>
        </Modal>
    )
}
import * as contractjoblevel1 from '../../action/employee/employee_contract_joblevel1';

const initialState={
    data:[],
    isLoading:false,
    errors:[]
}


const employee_contract_joblevel1 = (state=initialState,action)=>{
    switch(action.type){
        case contractjoblevel1.EMPCONTRACTJOBLEVEL1_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case contractjoblevel1.EMPCONTRACTJOBLEVEL1_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case contractjoblevel1.EMPCONTRACTJOBLEVEL1_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default employee_contract_joblevel1;
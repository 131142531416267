import React, { useContext } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { NewMainContext } from '../../../context/NewMainContext';
import ClaimOfficerDashboard from '../../dashboard/claim_officer_dashboard';
import ClaimAllList from '../../employee/claim_employee/ClaimAllList';
import EmployeeMain from '../../employee/claim_employee/EmployeeMain';



export default function ClaimOfficerTabContent(){

    const { activeTab, action_toggle } = useContext(NewMainContext);

    return(
        <TabContent activeTab={activeTab} className={action_toggle === true ? 'body-tabpane scrollbarstyle1' : 'body-tabpane1 scrollbarstyle1'}>
            <TabPane tabId="1">
                <ClaimOfficerDashboard/>
            </TabPane>
            <TabPane tabId="2">
                <EmployeeMain/>
            </TabPane>
            <TabPane tabId="3">
                <ClaimAllList/>
            </TabPane>
        </TabContent>
    )
}
import React from 'react';
import { Card, CardHeader, Table } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';


export default function NoPayTable(props){

    return(
        <Card>
            <CardHeader className="p-2 border-0 bg-white">
                <h4 className='text-primary pl-2 pt-2'>
                    {props.type === 'nopay' && IMLocalized('payslip_no_pay_leave') }
                    {props.type === 'absent' && IMLocalized('payslip_absent')}
                    {props.type === 'nation' && IMLocalized('national_service')}
                </h4>
            </CardHeader>
            <Table size="sm" className="align-items-center" responsive>
                <thead className="text-center thead-light p-2">
                    <tr>
                        <th className="p-2">{IMLocalized('job_title')}</th>
                        <th className="p-2">{IMLocalized('days')}</th>
                        <th className="p-2">{IMLocalized('payslip_hours')}</th>
                        <th className="p-2">{IMLocalized('amount')}</th>
                    </tr>
                </thead>
                <tbody className="text-center p-2">
                    {props.list.map((item,index)=>{
                        const {amount,timedata} = item;
                        const detail = props.payslipcontract.find(el=>el.id === item.payslip_contract);
                        const job_title = detail?.contract?.job_title  ?? '-';
                        return(
                            <tr key={index}>
                                <td className="p-2">{job_title}</td>
                                <td className="p-2">{timedata.days}</td>
                                <td className="p-2">{timedata.hours}</td>
                                <td className="p-2">$ {amount.toFixed(2)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </Card>
    )
}

import * as noscheme from '../../action/leave/get_leave_employee_noscheme';

const initialState={
    data:[],
    errors:[],
    isLoading:false,
}


const get_leave_employee_noscheme = (state=initialState,action)=>{
    switch(action.type){
        case noscheme.LEAVEEMPNOSCHEME_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case noscheme.LEAVEEMPNOSCHEME_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case noscheme.LEAVEEMPNOSCHEME_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_leave_employee_noscheme;
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import Swal from "sweetalert2";
import { delete_leave_scheme, reset_delete_leave_scheme } from "../../action/delete_leave_scheme";
import { get_leave_scheme_list } from "../../action/get_leave_scheme_list";
import { NewMainContext } from "../../context/NewMainContext";
import { IMLocalized } from "../../language/IMLocalized";
import BreadCrumbList from "../breadcrumb/BreadCrumbList";
import ModalViewLeaveScheme from "./Modal/ModalViewLeaveScheme";


const LeaveSchemeList = ({setStep})=>{
  
    const { addItem } = useContext(NewMainContext);
    const dispatch = useDispatch();
    const list = useSelector(state=>state.get_leave_scheme_list.data);
    const isLoading = useSelector(state=>state.get_leave_scheme_list.isLoading);
    const delete_success = useSelector(state=>state.delete_leave_scheme.data);

    const [ view_toggle, setViewToggle] = useState(false);
    const [ view_id, setViewId] = useState(null);

    useEffect(()=>{
        if(delete_success != null){
            dispatch(get_leave_scheme_list());
            dispatch(reset_delete_leave_scheme());
        }
    },[delete_success,dispatch])

    const deleteScheme = (id,name)=>{
        Swal.fire({
            type:"warning",
            title:`${IMLocalized('delete_leave_scheme_confirmation')} (` + name +') !',
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!')
        })
        .then((result)=>{
            if(result.value){
                dispatch(delete_leave_scheme(id));
            }
        })
    }

    const viewScheme = (id)=>{
        setViewId(id);
        setViewToggle(true);
    }

    return(
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title:IMLocalized('leave_scheme_list')}
                    ]}/>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className="mt-2">
                <Col>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col className="d-flex align-items-center">
                                    <h4 className="mb-0">{IMLocalized('leave_scheme_list')}</h4>
                                </Col>
                                <Col className="col-auto">
                                    <button className="btn btn-primary btn-sm" onClick={()=>setStep(1)}>{IMLocalized('create')}</button>
                                </Col>
                            </Row>
                        </CardHeader>
                        { isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>}
                        { !isLoading && list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                        { !isLoading && list.length !== 0 &&
                        <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 250px)'}}>
                            {list.map((item,index)=>{
                                const number = index +1;
                                return(
                                    <ListGroupItem className="py-2" key={item.id}>
                                        <Row>
                                            <Col className="d-flex align-items-center">
                                                <span>{number}. {item.name}</span>
                                            </Col>
                                            <Col className="col-auto mr-1">
                                                <button onClick={()=>viewScheme(item.id)} className="btn btn-info btn-sm">{IMLocalized('view')}</button>
                                                <button onClick={()=>deleteScheme(item.id,item.name)} className="btn btn-danger btn-sm">{IMLocalized('delete')}</button>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>}
                    </Card>
                </Col>
            </Row>
        </Container>
        <ModalViewLeaveScheme toggle={view_toggle} closeModal={()=>{ setViewId(null); setViewToggle(false)}} id={view_id} />
        </>
    )
}
export default LeaveSchemeList;
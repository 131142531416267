import * as flush from '../action/flush_otp_blacklist';

const initialState={
    data:null,
    isLoading:false,
    errors:null,
}

const flush_otp_blacklist = (state=initialState,action)=>{
    switch(action.type){
        case flush.FLUSHOTPBLACKLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case flush.FLUSHOTPBLACKLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case flush.FLUSHOTPBLACKLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case flush.FLUSHOTPBLACKLIST_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default flush_otp_blacklist;
import * as resignemp from '../../action/employee/resign_employee';

const initialState={
    data:null,
    isLoading:false,
    errors:null
}

const resign_employee= (state=initialState,action)=>{
    switch(action.type){
        case resignemp.RESIGNEMPLOYEE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case resignemp.RESIGNEMPLOYEE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case resignemp.RESIGNEMPLOYEE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case resignemp.RESIGNEMPLOYEE_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default resign_employee;

import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const RESIGNEMPLOYEE_REQUEST = 'RESIGNEMPLOYEE_REQUEST';
export const RESIGNEMPLOYEE_SUCCESS = 'RESIGNEMPLOYEE_SUCCESS';
export const RESIGNEMPLOYEE_FAILURE = 'RESIGNEMPLOYEE_FAILURE';
export const RESIGNEMPLOYEE_RESET = 'RESIGNEMPLOYEE_RESET';

export const resign_employee = (id,resign_date,severance_month,severance_amount,notes,blacklist)=>{
    return dispatch=>{
        dispatch({
            type:'RESIGNEMPLOYEE_REQUEST'
        });
        
        myaxios.post(`employee/employees/${id}/resign/`,{
            resignation_date:resign_date,
            severance_amount:severance_amount,
            severance_month:severance_month,
            blacklist:blacklist,
            notes:notes
        })
        .then((response)=>{
            dispatch({
                type:'RESIGNEMPLOYEE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('resign_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'RESIGNEMPLOYEE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('resign_failure')
            });
        })
    }
}

export const reset_resign_employee = () => dispatch =>{
    dispatch({
        type:'RESIGNEMPLOYEE_RESET'
    });
}
// Monthly Report
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import ExcelJs from 'exceljs';
import moment from 'moment';
import FileSaver from 'file-saver';
import { Col, Container, Input, Row } from 'reactstrap';
import { IMLocalized } from '../../language/IMLocalized';
import { isFirstColumn } from '../ag-grid_component/isFirstColumn';
import { popError } from '../../func/popError';
import { monthly_report_date_filter2, reset_payroll_date } from '../../action/report/monthly_report_date_filter2';
import { getColumnName } from '../../func/getColumnName';
import { getValue } from '../../func/getValue';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';
import { PayrollEmployeeCheck } from '../../func/PayrollEmployeeCheck';
import { get_payroll_instance_array, reset_payroll_instance_array } from '../../action/payroll/get_payroll_instance_array';
import ProgressBar from '../loading_component/progress_loading';
import OverlayLoading from "../loading_component/overlay_loading";


const mapStateToProps=(state)=>({
    payroll_list:state.get_payroll_list.data,
    emplist:state.get_employee_list.data,
    isLoading:state.monthly_report_date_filter2.isLoading,
    list:state.monthly_report_date_filter2.data,
    pay_type:state.dropdown_list.pay_component_type,
    selflist:state.dropdown_list.self_help_group,
    isLoading1:state.get_payroll_instance.isLoading,
    instance:state.get_payroll_instance_array.data
})

const mapDispatchToProps=(dispatch)=>({
    monthly_report_date_filter2:(from,to)=>{
        dispatch(monthly_report_date_filter2(from,to))
    },
    get_payroll_instance_array:(id)=>{
        dispatch(get_payroll_instance_array(id))
    },
    reset_payroll_date:()=>{
        dispatch(reset_payroll_date())
    },
    reset_payroll_instance_array:()=>{
        dispatch(reset_payroll_instance_array())
    }
})

const gridOptions={
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
    sideBar:{
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
            },
          },
        ],
        defaultToolPanel: 'filters',
    },
    defaultColDef:{
        editable:false
    },
}

// const initialState={
//     from_payroll:'',
//     to_payroll:'',
//     select_option:'employee',
//     active_employee:true,
//     resignee:false,
//     rowData:[],
//     is_pending:false,
//     selected_emp:[],
//     select_type:'code',
//     selected_option:'employee',
// }

const initialState={
    from_payroll:'',
    to_payroll:'',
    active_employee:true,
    resignee:true,
    select_option:'employee',
    rowData:[],
    is_pending:false,
    selected_emp:[],
    select_type:'code',
    selected_option:'employee',
    join_date:'',
    resignation_date:''
}

function MonthlyReport(props){
    const {get_payroll_instance_array,reset_payroll_date,reset_payroll_instance_array} = props;
    const {action_toggle, addItem, monthly_report_reset2, setMonthlyReportReset2} = useContext(NewMainContext);
    const [state,setState]= useState(initialState);
    const [monthNames] = useState([IMLocalized('january'), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
    IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")]);
    const [monthNames1] = useState(['January','February','March','April','May','June','July','August','September','October','November','December'])
    const gridApi = useRef();
    const gridColumnApi = useRef();
    const [fromDate, setFromDate]= useState('');
    const [toDate, setToDate]= useState('');
    const [prArr, setPrArr] = useState([])
    const [exportLoading, setExportLoading] = useState(false)

    useEffect(()=>{
        if(monthly_report_reset2 === true){
            setState(initialState);
            setMonthlyReportReset2(false);
        }
    },[monthly_report_reset2,setMonthlyReportReset2])

    // useEffect(()=>{
    //     const arr = props.emplist.filter(item=>(item.active === true && item.resignation_date === null && state.active_employee === true) || (state.resignee === true && item.resignation_date !== null)).filter(item=>item.contract_cache !== null);
    //     setState(prevState=>({...prevState,rowData:arr}));
    // },[props.emplist,state.active_employee,state.resignee])


    
    useEffect(()=>{
        // const arr = props.emplist.filter
        // (item=>(item.join_date <= fromDate && item.resignation_date === null && item.active === true ) || 
        // (item.join_date <= fromDate && item.resignation_date <= fromDate && item.resignation_date >= toDate) || 
        // (item.join_date <= fromDate && item.resignation_date >= fromDate && item.resignation_date >= toDate))
        // setState(prevState=>({...prevState,rowData:arr}));

        const arr = PayrollEmployeeCheck(fromDate, toDate, props)

        setState(prevState=>({...prevState,rowData:arr}));

    },[props.emplist, toDate])

    const generateSectionExcel= useCallback((option,option_data,bottom_data,header)=>{
        let title_name;
        if(state.selected_option === 'cost_center'){
            title_name = 'Cost-Center';
        }
        else if(state.selected_option === 'department'){
            title_name = 'Department';
        }
        else if(state.selected_option === 'section'){
            title_name = 'Section';
        }
        else if(state.selected_option === 'job_level'){
            title_name = 'Job-Level';
        }
        
        const today = moment().format('DD/MM/YYYY');
        const time = moment().format('HH:mm:ss');

        const columns = [
            {header:'EMP ID',key:'employee.employee_number'},
            {header:'EMP NAME',key:'employee.name'},
            {header:'COST CENTER',key:'employee.contract_cache.cost_center'},
            {header:'DEPARTMENT',key:'employee.contract_cache.department'},
            {header:'SECTION',key:'employee.contract_cache.section'},
            {header:'JOB TITLE',key:'employee.contract_cache.job_title'},
            {header:'JOB LEVEL',key:'employee.contract_cache.job_level'},
            {header:'COMPANY',key:'employee.metadata.company'},
            {header:'GROSS SALARY',key:'basic_pay'},
            {header:'NETT SALARY',key:'payable_wage'},
            {header:'TOTAL OVERTIME',key:'total_overtime'},
            {header:'TOTAL ALLOWANCE',key:'total_allowance'},
            {header:'TOTAL FIXED RATE', key:'total_fixed'}
        ];

        for(const head of header){
            const {name} = head;
            const headerName = name.replace('-','.');
            const key = `aggrid.${name.replace('.','-')}.amount`;
            columns.push({header:headerName,key});
        }

        columns.push({header:'CPF EMPLOYEE',key:'cpf_employee'});
        for(const shg_item of props.selflist){
            const headerName = shg_item.value;
            const key = `shg.${headerName}.amount`;
            columns.push({header:headerName,key});
        }
        columns.push({header:'CPF EMPLOYER',key:'cpf_employer'});
        columns.push({header:'SDL',key:'sdl'});
        columns.push({header:'TOTAL EMPLOYER',key:'total_employer'});
        const size = [];
        for(const column of columns){
            const length = column.header.length;
            size.push({ width:length <15 ? 15 : length });
        }

        for( let i=0;i<option.length;i++){
            const item = option[i];
            const option_value = option_data[i];
            const bottom_value = bottom_data[i];
            let payroll_name = '';
            const wb = new ExcelJs.Workbook();
            for(let j=0;j<item.length;j++){
                const option_name = item[j].name;
                const payroll_date = item[j].payroll_date;
                const ws = wb.addWorksheet(option_name);
                const month = new Date(payroll_date).getMonth();
                const month1 = monthNames1[month];
                const year = new Date(payroll_date).getFullYear();
                ws.getCell('A1').value = `Payroll Monthly Report - ${month1} ${year} by ${title_name}`;
                ws.getCell('A2').value = 'Print On : '+today+' ' + time;
                payroll_name = `MonthlyReport_${month1}_${year}_by_${title_name}`;
                for(let x=0;x<columns.length;x++){
                    const headerName = columns[x].header;
                    const field = columns[x].key;
                    ws.getCell(getColumnName(x)+'4').value = headerName;
                    const length = option_value[option_name].length +1;
                    for(let k=0;k<length;k++){
                        const step = 5+k;
                        if(k <length -1){
                            const data3 = getValue(option_value[option_name][k],field);
                            if(field === 'employee.employee_number' || field === 'employee.name' || field === 'employee.contract_cache.cost_center' || field === 'employee.contract_cache.department' || field === 'employee.contract_cache.section' || field === 'employee.contract_cache.job_title' || field === 'employee.contract_cache.job_level' || field === 'employee.metadata.company' ){
                                ws.getCell(getColumnName(x)+step).value = data3 != null ? data3 : '-';
                            }
                            else{
                                ws.getCell(getColumnName(x)+step).value = data3 !== null && data3 !== undefined ? `$${data3.toFixed(2)}` : '-';
                            }
                        }
                        else if (k === length -1){
                            const data3 = getValue(bottom_value[option_name],field);
                            if(field === 'employee.employee_number' || field === 'employee.name' || field === 'employee.contract_cache.cost_center' || field === 'employee.contract_cache.department' || field === 'employee.contract_cache.section' || field === 'employee.contract_cache.job_title' || field === 'employee.contract.job_level' || field === 'employee.metadata.company' ){
                                ws.getCell(getColumnName(x)+step).value = data3 ?? '-' ;
                            }
                            else{
                                ws.getCell(getColumnName(x)+step).value = data3 == null ? '-' : `$${data3.toFixed(2)}`;
                            }
                        }
                    }
                }
                ws.columns = size;   
            }
            wb.xlsx.writeBuffer().then((buf)=>{
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                FileSaver.saveAs(file, `${payroll_name}.xlsx`);
            });
            setState(prevState=>({...prevState,is_pending:false}));
        }

    },[props.selflist,state.selected_option,monthNames1])

    const generateSection = useCallback((data,header)=>{
        const option = [];
        for(let i = 0 ;i < data.length; i++){
            const detail = data[i];
            const option1 = [];
            for(let j=0; j<detail.length;j++){
                const item = detail[j];
                if(state.selected_option === 'section'){
                    const section = item.employee.contract_cache?.section;
                    const option_detail =  option1.find((el)=>el.name === section);
                    if(!option_detail){
                        option1.push({name:section,payroll_date:item.payroll_date});
                    }
                }
                else if(state.selected_option === 'department'){
                    const department = item.employee.contract_cache?.department;
                    const option_detail =  option1.find((el)=>el.name === department);
                    if(!option_detail){
                        option1.push({name:department,payroll_date:item.payroll_date});
                    }
                }
                else if(state.selected_option === 'cost_center'){
                    const costcenter = item.employee?.contract_cache?.cost_center;
                    const option_detail =  option1.find((el)=>el.name === costcenter);
                    if(!option_detail){
                        option1.push({name:costcenter,payroll_date:item.payroll_date});
                    }
                }
                else if(state.selected_option === 'job_level'){
                    const joblevel = item.employee.contract_cache?.job_level;
                    const option_detail =  option1.find((el)=>el.name === joblevel);
                    if(!option_detail){
                        option1.push({name:joblevel,payroll_date:item.payroll_date});
                    }
                }
            }
            option.push(option1);
        }
        
        if(option.length !== 0){
            const bottom_data = [];
            const option_data = [];

            for(let i =0;i<option.length;i++){
                let option1_data = {};
                let bottom1_data = {};

                const data1 = data[i];
                const item = option[i];

                for(let j=0;j<item.length;j++){
                    const name = item[j].name;
                    const payroll_date = item[j].payroll_date;
                    const detail = data1.filter(el=>el.employee?.contract_cache?.[state.selected_option] === name);
                    let aggrid = {};
                    let shg ={};

                    for(const head of header){
                        aggrid[head.name] = { amount : 0};
                    }

                    for(const shg_item of props.selflist){
                        shg[shg_item.value] = {amount :0};
                    }
                    
                    const deductables = detail.reduce((prev,current)=>prev+current.deductables,0);
                    const total_wage = detail.reduce((prev,current)=>prev+current.total_wage,0);
                    const payable_wage = detail.reduce((prev,current)=>prev+current.payable_wage,0);
                    const basic_pay = detail.reduce((prev,current)=>prev+current.basic_pay,0);
                    const cpf_employee = detail.reduce((prev,current)=>prev+current.cpf_employee,0);
                    const cpf_employer = detail.reduce((prev,current)=>prev+current.cpf_employer,0);
                    const sdl = detail.reduce((prev,current)=>prev+current.sdl,0);
                    const sg_deductables = detail.reduce((prev,current)=>prev+current.sg_deductables,0);
                    const total_allowance = detail.reduce((prev,current)=>prev+current.total_allowance,0);
                    const total_employer = detail.reduce((prev,current)=>prev+current.total_employer,0);
                    const total_fixed = detail.reduce((prev,current)=>prev+current.total_fixed,0);
                    const total_overtime = detail.reduce((prev,current)=>prev+current.total_overtime,0);

                    for(const head of header){
                        const name = head.name;
                        for(const emp_data of detail){
                            const emp_aggrid = emp_data.aggrid;
                            if(emp_aggrid.hasOwnProperty(name)){
                                const amount = aggrid[name].amount;
                                const amount1 = emp_aggrid[name].amount;
                                aggrid[name] = {amount:amount+amount1};
                            }
                        }
                    }

                    for(const shg_item of props.selflist){
                        const name = shg_item.value;
                        for(const emp_data of detail){
                            const emp_shg = emp_data.shg;
                            if(emp_shg.hasOwnProperty(name)){
                                const amount = shg[name].amount;
                                const amount1 = emp_shg[name].amount;
                                shg[name] = {amount:amount+amount1}
                            }
                        }
                    }
                    bottom1_data[name] = {payroll_date,shg,employee:{employee_number:'Total'},aggrid,deductables,total_wage,payable_wage,basic_pay,cpf_employee,cpf_employer,sdl,sg_deductables,total_allowance,total_employer,total_fixed,total_overtime};
                    option1_data[name] = detail;
                }
                bottom_data.push(bottom1_data);
                option_data.push(option1_data)
            }
            generateSectionExcel(option,option_data,bottom_data,header);
        }
        
    },[state.selected_option,props.selflist,generateSectionExcel])

    const generateEmployee = useCallback((data,header)=>{
        const bottom_data = [];
        for(const item of data){
            let shg = {};
            let aggrid = {};
            
            for(const shg_item of props.selflist){
                shg[shg_item.value] = {amount :0};
            }

            for(const head of header){
                aggrid[head.name] = { amount :0};
            }

            const deductables = item.reduce((prev,current)=>prev+current.deductables,0);
            const total_wage = item.reduce((prev,current)=>prev+current.total_wage,0);
            const payable_wage = item.reduce((prev,current)=>prev+current.payable_wage,0);
            const basic_pay = item.reduce((prev,current)=>prev+current.basic_pay,0);
            const cpf_employee = item.reduce((prev,current)=>prev+current.cpf_employee,0);
            const cpf_employer = item.reduce((prev,current)=>prev+current.cpf_employer,0);
            const sdl = item.reduce((prev,current)=>prev+current.sdl,0);
            const sg_deductables = item.reduce((prev,current)=>prev+current.sg_deductables,0);
            const total_allowance = item.reduce((prev,current)=>prev+current.total_allowance,0);
            const total_employer = item.reduce((prev,current)=>prev+current.total_employer,0);
            const total_fixed = item.reduce((prev,current)=>prev+current.total_fixed,0);
            const total_overtime = item.reduce((prev,current)=>prev+current.total_overtime,0);

            for(const head of header){
                const name = head.name;
                for(const emp_data of item){
                    const emp_aggrid = emp_data.aggrid;
                    if(emp_aggrid.hasOwnProperty(name)){
                        const amount = aggrid[name].amount;
                        const amount1 = emp_aggrid[name].amount;
                        aggrid[name] = {amount:amount+amount1};
                    }
                }
            }

            for(const shg_item of props.selflist){
                const name = shg_item.value;
                for(const emp_data of item){
                    const emp_shg = emp_data.shg;
                    if(emp_shg.hasOwnProperty(name)){
                        const amount = shg[name].amount;
                        const amount1 = emp_shg[name].amount;
                        shg[name] = {amount:amount+amount1}
                    }
                }
            }

            bottom_data.push({employee:{employee_number:'Total'},aggrid,deductables,total_wage,payable_wage,basic_pay,cpf_employee,cpf_employer,sdl,sg_deductables,total_allowance,total_employer,total_fixed,total_overtime,shg});
        }
        let z =0;
        for(const item of data){
            
            const wb = new ExcelJs.Workbook();
            let payroll_name  ='';
            let payroll_title = '';
            if(item.length !== 0){
                const month = new Date(item[0].payroll_date).getMonth();
                const year = new Date(item[0].payroll_date).getFullYear();
                payroll_name = `MonthlyReport_${monthNames1[month]}_${year}`;
                payroll_title = `${monthNames1[month]} ${year}`;
            }

            const ws = wb.addWorksheet('monthly_report');
            const today = moment().format('DD/MM/YYYY');
            const time = moment().format('HH:mm:ss');
            ws.getCell('A1').value = `Payroll Monthly Report - ${payroll_title}`;
            ws.getCell('A2').value = `Print On : ${today} ${time}`;

            let columns = [
                {header:'EMP ID',key:'employee.employee_number'},
                {header:'EMP NAME',key:'employee.name'},
                {header:'COST CENTER',key:'employee.contract_cache.cost_center'},
                {header:'DEPARTMENT',key:'employee.contract_cache.department'},
                {header:'SECTION',key:'employee.contract_cache.section'},
                {header:'JOB TITLE',key:'employee.contract_cache.job_title'},
                {header:'JOB LEVEL',key:'employee.contract_cache.job_level'},
                {header:'COMPANY',key:'employee.metadata.company'},
                {header:'GROSS SALARY',key:'basic_pay'},
                {header:'NETT SALARY',key:'payable_wage'},
                {header:'TOTAL OVERTIME',key:'total_overtime'},
                {header:'TOTAL ALLOWANCE',key:'total_allowance'},
                {header:'TOTAL FIXED RATE', key:'total_fixed'}
            ]

            for(const head of header){
                const headerName = head.name.replace('-','.');
                const key = `aggrid.${head.name.replace('.','-')}.amount`;
                columns.push({header:headerName,key})
            }
            columns.push({header:'CPF EMPLOYEE',key:'cpf_employee'});
            for(const shg_item of props.selflist){
                const key = `shg.${shg_item.value}.amount`;
                columns.push({header:shg_item.value,key})
            }
            columns.push({header:'CPF EMPLOYER',key:'cpf_employer'});
            columns.push({header:'SDL',key:'sdl'});
            columns.push({header:'TOTAL EMPLOYER',key:'total_employer'});

            let size = [];
            if(columns.length !== 0){
                for(let j=0;j<columns.length ;j++){
                    const header = columns[j].header;
                    const length = header.length;
                    if(length <15){
                        size.push({width:15});
                    }
                    else{
                        size.push({width:length});
                    }
                }
            }
            ws.columns = size;
            if(columns.length !== 0){
                for(let i=0;i<columns.length;i++){
                    const headerName = columns[i].header;
                    const field = columns[i].key;
                    const length = item.length+1;
                    ws.getCell(getColumnName(i)+'4').value = headerName;
                    for(let x=0;x<length;x++){
                        const step = 5+x;
                        if(x<length-1){
                            const item1 = getValue(item[x],field);
                            if(field === 'employee.employee_number' || field === 'employee.name' || field === 'employee.contract_cache.cost_center' || field === 'employee.contract_cache.department' || field === 'employee.contract_cache.section' || field === 'employee.contract_cache.job_title' || field === 'employee.contract_cache.job_level' || field === 'employee.metadata.company' ){
                                if(item1 === undefined || item1 === null){
                                    ws.getCell(getColumnName(i)+step).value = '-';
                                }
                                else{
                                    ws.getCell(getColumnName(i)+step).value = item1;
                                }
                            }
                            else{
                                if(item1 === undefined || item1 === null){
                                    ws.getCell(getColumnName(i)+step).value = '-';
                                }
                                else{
                                    ws.getCell(getColumnName(i)+step).value = '$'+ item1.toFixed(2) ;
                                }
                            }
                            
                        }
                        else if (x=== length -1){
                            const item1 = getValue(bottom_data[z],field);
                            if(field === 'employee.employee_number' || field === 'employee.name' || field === 'employee.contract_cache.cost_center' || field === 'employee.contract_cache.department' || field === 'employee.contract_cache.section' || field === 'employee.contract_cache.job_title' || field === 'employee.contract_cache.job_level' || field === 'employee.metadata.company' ){
                                if(item1 !== null && item1 !== undefined){
                                    ws.getCell(getColumnName(i)+step).value = item1;
                                }
                                
                            }
                            else{
                                if(item1 !== null && item1 !== undefined){
                                    ws.getCell(getColumnName(i)+step).value = '$'+item1.toFixed(2);
                                }
                                else{
                                    ws.getCell(getColumnName(i)+step).value = '-';
                                }
                            }
                        }
                    }
                }
            }
            const file_name = payroll_name + '.xlsx';
            wb.xlsx.writeBuffer().then((buf)=>{
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                FileSaver.saveAs(file, file_name)
            });
            z++;
        }
        if(prArr.length < props.list.length){
            setState(prevState=>({...prevState,is_pending:true}));
        }else{
        setState(prevState=>({...prevState,is_pending:false}));}
    },[props.selflist,monthNames1,setPrArr])

    const filterData = useCallback((rowData,emp)=>{
        const header = [];
        const arr = [];
        for(const payslip of rowData){
            let arr1 = [];
            for(const item of payslip){
                const {sgcpf,payslipcontract,pay,sgshg} = item;
                if(emp.includes(item.employee.id)){
                    const cpf_employee = sgcpf?.cpf_employee ?? 0;
                    const cpf_employer = sgcpf?.cpf_employer ?? 0;
                    const basic_pay = payslipcontract.reduce((prev,current)=>prev+current.gross_month,0);
                    let aggrid = {};
                    let shg = {};
                    for(const pay_item of pay){
                        const name = pay_item?.name ? (pay_item.name).replace('.','-') : '';
                        const amount = pay_item?.amount;
                        const id =pay_item?.id;
                        aggrid[name] = {amount,id};
                        const header_detail = header.find(item1=>item1.name ===name);
                        if(header_detail == null){
                            header.push({name});
                        }
                    }
    
                    for(const shg_item of sgshg){
                        const amount = shg_item.amount;
                        const name = shg_item.shg;
                        shg[shg_item.shg] = {name,amount};
                    }
    
                    const total_overtime = pay.filter(item=>item.pay_type === 'OT').reduce((prev,current)=>prev+current.amount,0);
                    const total_allowance = pay.filter(item=>item.pay_type === 'ALLOWANCE').reduce((prev,current)=>prev+current.amount,0);
                    const total_fixed = pay.filter(item=>item.pay_type === 'FX').reduce((prev,current)=>prev+current.amount,0);
                    arr1.push({...item,total_fixed,total_allowance,total_overtime,shg,aggrid,basic_pay,cpf_employee,cpf_employer})
                }
            }
            arr.push(arr1);
            setState(prevState=>({...prevState,is_pending:false}));
        }

        const data = [];
        for(const rowData of arr){
            const data2 = [];
            for( const item of emp){
                let shg = {};
                let aggrid = {};

                for(const shg_item of props.selflist){
                    shg[shg_item.value] = { amount:0 };
                }

                for(const head of header){
                    aggrid[head.name] = { amount: 0};
                }
                const detail = rowData.filter(el=>el.employee.id === item);
                const payroll_date = detail.length !== 0 ? detail[0].payroll_date : '';
                const deductables = detail.reduce((prev,current)=>prev+current.deductables,0);
                const total_wage = detail.reduce((prev,current)=>prev+current.total_wage,0);
                const payable_wage = detail.reduce((prev,current)=>prev+current.payable_wage,0);
                const basic_pay = detail.reduce((prev,current)=>prev+current.basic_pay,0);
                const cpf_employee = detail.reduce((prev,current)=>prev+current.cpf_employee,0);
                const cpf_employer = detail.reduce((prev,current)=>prev+current.cpf_employer,0);
                const sdl = detail.reduce((prev,current)=>prev+current.sdl,0);
                const sg_deductables = detail.reduce((prev,current)=>prev+current.sg_deductables,0);
                const total_allowance = detail.reduce((prev,current)=>prev+current.total_allowance,0);
                const total_employer = detail.reduce((prev,current)=>prev+current.total_employer,0);
                const total_fixed = detail.reduce((prev,current)=>prev+current.total_fixed,0);
                const total_overtime = detail.reduce((prev,current)=>prev+current.total_overtime,0);
                const employee = detail[0]?.employee;
                if(detail.length !== 0){
                    for(const emp_data of detail){
                        const emp_aggrid = emp_data.aggrid;
                        for(const head of header){
                            const name = head.name;
                            if(emp_aggrid.hasOwnProperty(name)){
                                const amount = aggrid[name].amount;
                                const amount1 = emp_aggrid[name].amount;
                                aggrid[name] = { amount :amount+amount1};
                            }
                        }
    
                        const emp_shg = emp_data.shg;
                        for(const shg_item of props.selflist){
                            const name = shg_item.value;
                            if(emp_shg.hasOwnProperty(name)){
                                const amount = shg[name].amount;
                                const amount1 = emp_shg[name].amount;
                                shg[name] = {amount:amount+amount1};
                            }
                        }
                    }
                    data2.push({employee,payroll_date,aggrid,deductables,total_wage,payable_wage,basic_pay,cpf_employee,cpf_employer,sdl,sg_deductables,total_allowance,total_employer,total_fixed,total_overtime,shg});
                }
            }
            data.push(data2);
        }

        if(state.select_option === 'employee' ){
            
            generateEmployee(data,header);
        }
        else{
            generateSection(data,header);
        }

    },[props.selflist,state.select_option,generateEmployee,generateSection,props.instance,setPrArr])

    useEffect(()=>{
        if(props.instance.length !== 0 && state.is_pending){
            const emp = state.selected_emp.map((item)=>item.id);
            let data  = [];
            for(const item of props.instance){
                const {payslips} = item;
                data.push(payslips);
            }
            filterData(data,emp);
        }
    },[props.list,state.selected_emp,state.is_pending,filterData,props.instance])

    useEffect(()=>{
    if(props.list.length !== 0){
        if(props.instance[0]?.loop < (props.list.length-1) || props.instance[0]?.loop === null || props.instance[0]?.loop===undefined){
            setExportLoading(true)
        }else{
            setPrArr([])
            setExportLoading(false)
            reset_payroll_instance_array();
            reset_payroll_date();
        }
    }
    },[props.list,setExportLoading,props.instance,props.instance.loop])

    useEffect(()=>{
        if(props.list != null && state.is_pending){
            let idarr = []
            for(let x=0;x<props.list.length;x++){
                let idd = props.list[x]
                idarr.push(idd)          
            }
            get_payroll_instance_array(idarr);
            setState(prevState=>({...prevState,is_pending:true}));
        }
    },[props.list,props.get_payroll_instance_array])

    useEffect(()=>{
        if(props.instance.length !== 0){
            let idd = props.instance?.map((j)=>j)
            setState(prevState=>({...prevState,is_pending:true}));
            setPrArr(prevState=>[...prevState,idd])
        }
        
    },[get_payroll_instance_array,props.instance])

    const from_index = props.payroll_list.findIndex(item=>item.end_date === state.from_payroll);
    const arr2  = from_index === -1 ? [] :  props.payroll_list.slice(from_index,props.payroll_list.length);

    return(
        <>
        {exportLoading ?
        <>
        <OverlayLoading />
        <ProgressBar value={props.instance[0]?.loop} maxValue={props.list.length-1}  />
        </>
        :
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title: IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title: IMLocalized('monthly_report')}
                    ]}/>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col className='d-flex align-items-center'>
                    <Input size="sm" type="select" value={state.from_payroll} style={{width:'150px'}} 
                    onChange={(e)=>{
                        const value = e.target.value;
                        setState(prevState=>({...prevState,from_payroll:value,to_payroll:''}))
                        setFromDate(value)
                    }}
                    >
                        <option>{IMLocalized('select_payroll')}</option>
                        {props.payroll_list.map((item,index)=>{
                            // const date = item.payroll_date;
                            const date = item.end_date;
                            const month = new Date(date).getMonth();
                            const year  = new Date(date).getFullYear();
                            // return <option value={date} key={index}>{monthNames[month]} {year}</option>
                            return <option value={date} key={index}>{item.type==="BONUS"?"Bonus ":item.type==="HMTH1"?"Half of ":""}{monthNames[month]}  {year}</option>
                        })}
                    </Input>
                    <span className='ml-2 mr-2'>{IMLocalized('to')}</span>
                    <Input size="sm" type="select" value={state.to_payroll} style={{width:'150px'}}
                    onChange={(e)=>{
                        const value = e.target.value;
                        setState(prevState=>({...prevState,to_payroll:value}))
                        setToDate(value)
                    }}
                    >
                        <option>{IMLocalized('select_payroll')}</option>
                        {arr2.map((item,index)=>{
                            const date = item.start_date;
                            const month = new Date(date).getMonth();
                            const year  = new Date(date).getFullYear();
                            // return <option value={date} key={index}>{monthNames[month]} {year}</option>
                            return <option value={date} key={index}>{item.type==="BONUS"?"Bonus ":item.type==="HMTH1"?"Half of ":""}{monthNames[month]}  {year}</option>
                        })}
                    </Input>
                </Col>
            </Row>
            <div className='mt-2 d-flex justify-content-between'>
                <Input type="select" size="sm" name="select_option" value={state.select_option} style={{width:'150px'}}
                onChange={(e)=>{
                    const value = e.target.value;
                    setState(prevState=>({...prevState,select_option:value}));
                }}>
                    <option value='employee'>{IMLocalized('employee')}</option>
                    <option value='section'>{IMLocalized('section')}</option>
                    <option value='department'>{IMLocalized('department')}</option>
                    <option value='job_level'>{IMLocalized('job_level')}</option>
                    <option value='cost_center'>{IMLocalized('cost_center')}</option>
                </Input>
                <div className='d-flex'>
                    <div>
                    {props.isLoading || props.isLoading1 || exportLoading ? 
                    <button className="btn btn-success btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}...</button> :
                    <button className="btn btn-success btn-sm"
                    onClick={()=>{
                        if(state.from_payroll !== ''){
                            const to_payroll = state.to_payroll !== '' ? state.to_payroll : state.from_payroll;
                            const data =gridApi.current.getSelectedRows();
                            if(data.length !== 0){
                                props.monthly_report_date_filter2(to_payroll,state.from_payroll);
                                setState(prevState=>({...prevState,is_pending:true,selected_emp:data,selected_option:state.select_option}))
                            }
                            else{
                                popError(IMLocalized('select_employee_to_export'));
                            }
                        }
                        else{
                            popError(IMLocalized('pls_select_payroll_month'));
                        }
                    }}>{IMLocalized('export')}</button>}
                    </div>
                </div>
            </div>
            <div className='mt-2 ag-theme-balham' style={{height:action_toggle? 'calc(100vh - 288px)' : 'calc(100vh - 252px)' , width:'100%' }} >
                <AgGridReact
                    columnDefs={
                        [
                            {
                                headerName:IMLocalized('agrid_select'),
                                field:'is_select',
                                headerCheckboxSelection: isFirstColumn,
                                checkboxSelection: isFirstColumn,
                                headerCheckboxSelectionFilteredOnly:true,
                                editable:false,
                                width:150,
                                filter:false,
                                pinned:'left',
                                lockPosition:true
                            },
                            {
                                headerName:IMLocalized('agrid_emp_id'),
                                field:'employee_number',
                                editable:false,
                                width:150,
                                filter:true,
                            },
                            {
                                headerName:IMLocalized('agrid_emp_name'),
                                field:'name',
                                editable:false,
                                width:150,
                                filter:true,
                            },
                            {
                                headerName:IMLocalized('agrid_cost_center'),
                                field:'contract_cache.cost_center',
                                editable:false,
                                width:150,
                                filter:true
                            },
                            {
                                headerName:IMLocalized('agrid_department'),
                                field:'contract_cache.department',
                                editable:false,
                                width:150,
                                filter:true
                            },
                            {
                                headerName:IMLocalized('agrid_section'),
                                field:'contract_cache.section',
                                editable:false,
                                width:150,
                                filter:true
                            },
                            {
                                headerName:IMLocalized('agrid_job_title'),
                                field:'contract_cache.job_title',
                                editable:false,
                                width:150,
                                filter:true
                            },
                            {
                                headerName:IMLocalized('agrid_job_level'),
                                field:'contract_cache.job_level',
                                editable:false,
                                width:150,
                                filter:true
                            }
                        ]
                    }
                    gridOptions={gridOptions}
                    rowData={state.rowData}
                    suppressDragLeaveHidesColumns={true}
                    suppressSizeToFit={true}
                    suppressColumnMoveAnimation={false}
                    onGridReady={(params)=>{
                        gridApi.current = params.api;
                        gridColumnApi.current = params.columnApi;
                    }}
                    overlayNoRowsTemplate = {IMLocalized('no_data')}
                    >
                </AgGridReact>
            </div>
        </Container>
        }
        </>
    )
    
}
export default connect(mapStateToProps,mapDispatchToProps)(MonthlyReport);
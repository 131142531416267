import React, { useContext } from "react";
import HolidayList from "./HolidayList";
import WizardHolidayContextProvider, { WizardHolidayContext } from "../../context/WizardHolidayContext";
import HolidayTable from "./HolidayTable";

export default function HolidayDetail(props){

    return(
        <WizardHolidayContextProvider setData={props.setData}>
            <AppInstance/>
        </WizardHolidayContextProvider>
    )
}

function AppInstance(){
    const {step} = useContext(WizardHolidayContext);
    
    return(
        <>
        {step === 0 && <HolidayList />}
        {step === 1 && <HolidayTable/>}
        </>
    )
}
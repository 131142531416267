import * as newpass from '../action/submit_user_newpass';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const submit_user_newpass = (state=initialState,action)=>{
    switch(action.type){
        case newpass.SUBMITUSERNEWPASS_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case newpass.SUBMITUSERNEWPASS_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case newpass.SUBMITUSERNEWPASS_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        default:
            return state;
    }
}
export default submit_user_newpass;
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CREATECLAIMAPPROVAL1_REQUEST = 'CREATECLAIMAPPROVAL1_REQUEST';
export const CREATECLAIMAPPROVAL1_SUCCESS = 'CREATECLAIMAPPROVAL1_SUCCESS';
export const CREATECLAIMAPPROVAL1_FAILURE = 'CREATECLAIMAPPROVAL1_FAILURE';
export const CREATECLAIMAPPROVAL1_RESET = 'CREATECLAIMAPPROVAL1_RESET';


export const create_claim_approval1 = (name,exceptions,approvers) =>{
    return dispatch=>{
        dispatch({
            type:'CREATECLAIMAPPROVAL1_REQUEST'
        });

        myaxios.post('claim/approval/',{
            name:name,
            exceptions:exceptions,
            approvers:approvers,
            approval_master:null,
            start_date:null,
        })
        .then((response)=>{
            dispatch({
                type:'CREATECLAIMAPPROVAL1_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type:'success',
                title:IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CREATECLAIMAPPROVAL1_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_failure')
            });
        })
    }
}


export const reset_create_claim_approval1 = () => dispatch =>{
    dispatch({
        type:'CREATECLAIMAPPROVAL1_RESET'
    })
}
import myaxios from '../../axios';

export const PAYROLLUPDATETIMEVALIDATE_REQUEST = 'PAYROLLUPDATETIMEVALIDATE_REQUEST';
export const PAYROLLUPDATETIMEVALIDATE_SUCCESS = 'PAYROLLUPDATETIMEVALIDATE_SUCCESS';
export const PAYROLLUPDATETIMEVALIDATE_FAILURE = 'PAYROLLUPDATETIMEVALIDATE_FAILURE';


export const update_payroll_update_time_validate = (id,data)=>{
    return dispatch=>{
        dispatch({
            type:'PAYROLLUPDATETIMEVALIDATE_REQUEST'
        });

        myaxios.post(`payroll/payroll/${id}/AddUpdateTimeValidate/`,{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'PAYROLLUPDATETIMEVALIDATE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'PAYROLLUPDATETIMEVALIDATE_FAILURE',
                payload:error
            })
        })
    }
}
import myaxios from '../../axios';

export const GETEMPCERTIFICATIONPIC_REQUEST = 'GETEMPCERTIFICATIONPIC_REQUEST';
export const GETEMPCERTIFICATIONPIC_SUCCESS = 'GETEMPCERTIFICATIONPIC_SUCCESS';
export const GETEMPCERTIFICATIONPIC_FAILURE = 'GETEMPCERTIFICATIONPIC_FAILURE';
export const GETEMPCERTIFICATIONPIC_RESET = 'GETEMPCERTIFICATIONPIC_RESET';


export const get_employee_certification_picture = (id)=>{
    return dispatch=>{
        dispatch({
            type:'GETEMPCERTIFICATIONPIC_REQUEST'
        });

        myaxios.get(`ob/cert/${id}/`,{responseType:'arraybuffer'})
        .then((response)=>{
            dispatch({
                type:'GETEMPCERTIFICATIONPIC_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETEMPCERTIFICATIONPIC_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_get_employee_certification_picture = () => dispatch =>{
    dispatch({
        type:'GETEMPCERTIFICATIONPIC_RESET'
    })
}
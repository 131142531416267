import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { add_otp_list, reset_add_otp_list } from '../../../../action/user/add_otp_list';
import { get_otp_list } from '../../../../action/user/get_otp_list';


export default function ModalCreateToken({ toggle, closeModal }){

    const initiallist = useSelector(state=>state.get_otp_initial.data);
    const isLoading = useSelector(state=>state.add_otp_list.isLoading);
    const success = useSelector(state=>state.add_otp_list.data);
    const errors = useSelector(state=>state.add_otp_list.errors);
    const isLoading1 = useSelector(state=>state.get_otp_initial.isLoading);

    const dispatch = useDispatch();
    const [secret,setSecret] = useState('');
    const [uri,setUri] = useState('');
    const [otp,setOtp] = useState('');
    const [err_otp,setErrOtp] = useState('');

    useEffect(()=>{
        if(toggle){
            setErrOtp('');
            setOtp('');
        }
    },[toggle])

    useEffect(()=>{
        if( toggle && success !== null){
            dispatch(get_otp_list());
            dispatch(reset_add_otp_list());
            closeModal();
        }
    },[success,dispatch,closeModal,toggle])

    useEffect(()=>{
        if( toggle && errors != null){
            if(errors.hasOwnProperty('non_field_errors')){
                const word = errors['non_field_errors'].toString();
                setErrOtp(word);
            }
            else if(errors.hasOwnProperty('otp')){
                const word = errors['otp'].toString();
                setErrOtp(word);
            }
            dispatch(reset_add_otp_list());
        }
    },[toggle,dispatch,errors])

    useEffect(()=>{
        if(initiallist != null ){
            const {uri,secret} = initiallist;
            setUri(uri);
            setSecret(secret);
        }
    },[initiallist])

    const addOTP=(event)=>{
        event.preventDefault();
        if(otp !== ''){
            dispatch(add_otp_list(secret,otp));
        }
        else{
            setErrOtp(IMLocalized('enter_valid_otp'));
        }
    }

    return(
        <Modal isOpen={toggle} autoFocus={false}>
            <ModalHeader toggle={closeModal}>{IMLocalized('add_otp')}</ModalHeader>
            <ModalBody >
                <>
                {isLoading1 && <span>{IMLocalized('loading')}..</span>}
                {!isLoading1 &&
                <>
                <div className='d-flex justify-content-center'>
                    <h2>{IMLocalized('scan_the_qr_code')}</h2>
                   
                </div>
                <div className='d-flex justify-content-center mt-2'>
                    <QRCode value={uri} />
                </div>
                <div className='d-flex justify-content-center mt-3'>
                    <h2>{IMLocalized('enter_the_otp')}</h2>
                </div>
                <form onSubmit={addOTP}>
                    <div className="d-flex justify-content-center mt-3">
                        <OtpInput
                            value={otp}
                            onChange={(value)=>{setOtp(value); setErrOtp('')}}
                            numInputs={6}
                            separator={<span>-</span>}
                            inputStyle={{width:'50px',height:'50px'}}
                            shouldAutoFocus={true}
                        />
                    </div>
                    <div className="d-flex justify-content-center mt-2">
                        <small className="text-red">{err_otp}</small>
                    </div>
                    <div className="d-flex justify-content-center mt-2">
                        {isLoading ? <button style={{width:'75%'}} className="btn btn-primary btn-block"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button> :
                        <button style={{width:'75%'}} type="submit" className="btn btn-primary btn-block" >{IMLocalized('confirm')}</button>}
                    </div>
                </form>
                <div className="d-flex justify-content-center mt-2">
                    <button className="btn btn-danger btn-block" style={{width:'75%'}} onClick={closeModal}>{IMLocalized('close')}</button>
                </div>
                </>}
                </>
            </ModalBody>
        </Modal>
    )
}
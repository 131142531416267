import React, { useState } from 'react';
import { Button } from 'reactstrap';
import ModalEmpApplyLeave from '../employee/hr_employee/modal/ModalEmpApplyLeave';
import { IMLocalized } from '../../language/IMLocalized';



export default function LeaveOfficerFunctionButton(props){

    const [toggle,setToggle] = useState(false);

    return(
        <div className='d-sm-none d-md-block d-none mt-2'>
            <Button color="default" outline size="sm" type="button" onClick={()=>setToggle(true)}>
                {IMLocalized('apply_employee_leave')}
            </Button>
            <ModalEmpApplyLeave toggle={toggle} closeModal={()=>setToggle(false)} />
        </div>
    )
}
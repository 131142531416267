import * as wizardcomplete from '../../action/wizard/wizard_complete';

const initialState={
    isLoading:false,
    errors:null,
    data:null
}

const wizard_complete = (state= initialState,action)=>{
    switch(action.type){
        case wizardcomplete.WIZARDCOMPLETE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case wizardcomplete.WIZARDCOMPLETE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case wizardcomplete.WIZARDCOMPLETE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        default:
            return state;
    }
}
export default wizard_complete;
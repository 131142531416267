import myaxios from '../../axios';

export const GETCALENDARAPPROVALSLEAVEDETAIL1_REQUEST = 'GETCALENDARAPPROVALSLEAVEDETAIL1_REQUEST';
export const GETCALENDARAPPROVALSLEAVEDETAIL1_SUCCESS = 'GETCALENDARAPPROVALSLEAVEDETAIL1_SUCCESS';
export const GETCALENDARAPPROVALSLEAVEDETAIL1_FAILURE = 'GETCALENDARAPPROVALSLEAVEDETAIL1_FAILURE';

export const get_calendar_approvals_leave_detail1 = (id)=>{
    return dispatch=>{
        dispatch({
            type:'GETCALENDARAPPROVALSLEAVEDETAIL1_REQUEST'
        });

        myaxios.get(`leave/manager/${id}/`)
        .then((response)=>{
            dispatch({
                type:'GETCALENDARAPPROVALSLEAVEDETAIL1_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETCALENDARAPPROVALSLEAVEDETAIL1_FAILURE',
                payload:error
            })
        })
    }
}

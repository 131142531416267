import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { getHolidayList } from "../../../action/get_holiday_list";
import { reset_update_holiday_list, update_holiday_list } from "../../../action/update_holiday_list";
import { IMLocalized } from "../../../language/IMLocalized";

const initialState={
    name:'',
    err_name:'',
}

function ModalEditHolidayList({toggle,closeModal,detail}){
    
    const isLoading = useSelector(state=>state.update_holiday_list.isLoading);
    const update_success = useSelector(state=>state.update_holiday_list.data);
    const update_errors = useSelector(state=>state.update_holiday_list.errors);
    const dispatch = useDispatch();
    const [state,setState] = useState(initialState);
    

    useEffect(()=>{
        if( update_success != null && toggle){
            closeModal();
            dispatch(getHolidayList());
            dispatch(reset_update_holiday_list());
        }
    },[update_success,toggle,closeModal,dispatch])

    useEffect(()=>{
        if( update_errors != null && toggle){
            if(update_errors.hasOwnProperty('name')){
                const word = update_errors['name'].toString();
                setState(prevState=>({...prevState,err_name:word}));
            }
            dispatch(reset_update_holiday_list());
        }
    },[update_errors,toggle,dispatch])

    useEffect(()=>{
        if(toggle && detail){
            setState(prevState=>({
                ...prevState,
                name:detail?.name ?? '',
                err_name:''
            }))
        }
    },[toggle,detail])

    const updateHoliday=()=>{
        if(state.name !== ''){
            dispatch(update_holiday_list(detail.id,state.name));
        }
        else{
            setState(prevState=>({
                ...prevState,
                err_name:IMLocalized('name_is_required')
            }))
        }
    }

    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('edit_holiday_list')} {detail ? `- ${detail?.name}` : null}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="name">{IMLocalized('name')}</label>
                            <Input size="sm" type="text" id="name" value={state.name} invalid={state.err_name.length >0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({
                                    ...prevState,
                                    name:value,
                                    err_name:'',
                                }))
                            }} />
                            <FormFeedback>{state.err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button className="btn btn-primary btn-sm" onClick={()=>updateHoliday()} >{IMLocalized('update')}</button>
                    <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
export default ModalEditHolidayList;
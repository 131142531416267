import * as min_resign from '../../action/employee/get_min_resign_date';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const get_min_resign_date = (state=initialState,action)=>{
    switch(action.type){
        case min_resign.GETMINRESIGNDATE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case min_resign.GETMINRESIGNDATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case min_resign.GETMINRESIGNDATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_min_resign_date;
import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const CREATECONTRACTTEMPLATE_REQUEST = 'CREATECONTRACTTEMPLATE_REQUEST';
export const CREATECONTRACTTEMPLATE_SUCCESS = 'CREATECONTRACTTEMPLATE_SUCCESS';
export const CREATECONTRACTTEMPLATE_FAILURE = 'CREATECONTRACTTEMPLATE_FAILURE';
export const CREATECONTRACTTEMPLATE_RESET = 'CREATECONTRACTTEMPLATE_RESET';

export const create_contract_template = (name,hours_day,lower_pay_range,upper_pay_range,is_workman,department,section,job_title,job_level,allowances,recurring_allowances,cost_center,contract_type,contract_rate,wschedule)=>{
    return dispatch=>{
        dispatch({
            type:'CREATECONTRACTTEMPLATE_REQUEST'
        })
        myaxios.post('contract/template/contracts/',{
            hours_day:hours_day,
            contract_type:contract_type,
            contract_rate:contract_rate,
            is_workman:is_workman,
            name:name,
            lower_pay_range:lower_pay_range,
            upper_pay_range:upper_pay_range,
            job_level:job_level,
            department:department,
            section:section,
            job_title:job_title,
            cost_center:cost_center,
            wschedule:wschedule,
            allowances:allowances,
            recurring_allowances:recurring_allowances 
        })
        .then((response)=>{
            dispatch({
                type:'CREATECONTRACTTEMPLATE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title:IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CREATECONTRACTTEMPLATE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_failure')
            });
        })
    }
}

export const reset_create_contract_template = () => dispatch =>{
    dispatch({
        type:'CREATECONTRACTTEMPLATE_RESET'
    })
}
import React, { useContext, useState } from 'react';
import { NewMainContext } from '../../../context/NewMainContext';
import { IMLocalized } from '../../../language/IMLocalized';

function PayslipTable(props){

    const [monthNames] = useState([IMLocalized('january'), IMLocalized('february'), IMLocalized('march'), IMLocalized('april'), IMLocalized('may'),IMLocalized('june'),
    IMLocalized('july'),IMLocalized('august'), IMLocalized('september'), IMLocalized('october'), IMLocalized('november'), IMLocalized('december')]);

    const {current_user} = useContext(NewMainContext);


    let table = null;
    if(props.list.length !== 0 ){
        const id = props.list[0].employee.id;
    
        const payroll = current_user === 'hr' ? props.list[0].payroll.id : props.list[0].id;
        const payroll_date = props.list[0].payroll_date;
        const month1 = new Date(payroll_date).getMonth();
        const month = monthNames[month1];
        const year = new Date(payroll_date).getFullYear();
        table = (
            <div className="card-single cursor-pointer bg-primary" onClick={!props.drag && (current_user === 'hr' || current_user === 'user' || current_user === 'manager' ) ?()=> props.viewPayroll(payroll,id) :null} >
                <div>
                    <h1 className="text-white">{month} {year}</h1>
                    <span className="text-white">{IMLocalized('this_month_payslip')}</span>
                </div>
                <div>
                    <i className="far fa-money-bill-alt text-white dashboard_text"></i>
                </div>
            </div>
        )
    }

    return(
        <>
        {props.list.length === 0 &&
        <div className="card-single bg-primary">
            <div>
                <h1 className="text-white">{IMLocalized('no_data')}</h1>
                <span className="text-white">{IMLocalized('this_month_payslip')}</span>
            </div>
            <div>
                <i className="far fa-money-bill-alt text-white dashboard_text"></i>
            </div>
        </div>}
        {props.list.length !== 0 && table}
        </>
    )
}
export default PayslipTable;
import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const CREATEPAYROLLADDITIONALSETTING_SUCCESS = 'CREATEPAYROLLADDITIONALSETTING_SUCCESS';
export const CREATEPAYROLLADDITIONALSETTING_REQUEST = 'CREATEPAYROLLADDITIONALSETTING_REQUEST';
export const CREATEPAYROLLADDITIONALSETTING_FAILURE = 'CREATEPAYROLLADDITIONALSETTING_FAILURE';
export const CREATEPAYROLLADDITIONALSETTING_RESET = 'CREATEPAYROLLADDITIONALSETTING_RESET';

export const create_payroll_additional_setting = (code,name,payType,wage,fromDate,toDate)=>{
    return dispatch =>{
        dispatch({
            type: 'CREATEPAYROLLADDITIONALSETTING_REQUEST'
        });
        
        myaxios.post(`payroll/field/`,{
            code:code,
            name:name,
            pay_type:payType,
            wage:wage,            
            from_date:fromDate,
            to_date:toDate,
        })
        .then((response)=>{
            dispatch({
                type:'CREATEPAYROLLADDITIONALSETTING_SUCCESS',
                payload:response
                
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            })
        })
        .catch((error) =>{
            dispatch({
                type: 'CREATEPAYROLLADDITIONALSETTING_FAILURE',
                payload: error,
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_failure')
            });
        })
    }
}

export const reset_create_payroll = () => dispatch =>{
    dispatch({
        type:'CREATEPAYROLLADDITIONALSETTING_RESET'
    })
}
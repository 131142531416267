import myaxios from '../../axios';

export const GETLEAVEGROUPTEMPLATE_REQUEST = 'GETLEAVEGROUPTEMPLATE_REQUEST';
export const GETLEAVEGROUPTEMPLATE_SUCCESS = 'GETLEAVEGROUPTEMPLATE_SUCCESS';
export const GETLEAVEGROUPTEMPLATE_FAILURE = 'GETLEAVEGROUPTEMPLATE_FAILURE';


export const get_leave_group_template = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETLEAVEGROUPTEMPLATE_REQUEST'
        });

        myaxios.get('leave/mass/template/')
        .then((response)=>{
            dispatch({
                type:'GETLEAVEGROUPTEMPLATE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETLEAVEGROUPTEMPLATE_FAILURE',
                payload:error
            })
        })
    }
}
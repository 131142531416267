import myaxios from '../../axios';

export const GETCALENDARAPPROVALSLEAVEDETAIL_REQUEST = 'GETCALENDARAPPROVALSLEAVEDETAIL_REQUEST';
export const GETCALENDARAPPROVALSLEAVEDETAIL_SUCCESS = 'GETCALENDARAPPROVALSLEAVEDETAIL_SUCCESS';
export const GETCALENDARAPPROVALSLEAVEDETAIL_FAILURE = 'GETCALENDARAPPROVALSLEAVEDETAIL_FAILURE';

export const get_calendar_approvals_leave_detail = (id)=>{
    return dispatch=>{
        dispatch({
            type:'GETCALENDARAPPROVALSLEAVEDETAIL_REQUEST'
        });

        myaxios.get(`leave/manager/${id}/`)
        .then((response)=>{
            dispatch({
                type:'GETCALENDARAPPROVALSLEAVEDETAIL_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETCALENDARAPPROVALSLEAVEDETAIL_FAILURE',
                payload:error
            })
        })
    }
}

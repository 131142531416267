import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATEPAYSLIPBONUS_REQUEST = 'UPDATEPAYSLIPBONUS_REQUEST';
export const UPDATEPAYSLIPBONUS_SUCCESS = 'UPDATEPAYSLIPBONUS_SUCCESS';
export const UPDATEPAYSLIPBONUS_FAILURE = 'UPDATEPAYSLIPBONUS_FAILURE';


export const update_payslip_bonus = (id,data)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEPAYSLIPBONUS_REQUEST'
        })

        myaxios.post(`payroll/payroll/${id}/AddUpdateBonus/`,{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'UPDATEPAYSLIPBONUS_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATEPAYSLIPBONUS_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}
import * as deladd from '../action/delete_section';

const initialState ={
    isLoading:false,
    errors:null,
    data:null
}


const delete_section = (state=initialState,action) =>{
    switch(action.type) {
        case deladd.DELSECTION_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            };
        case deladd.DELSECTION_SUCCESS:
            return{
                ...state,
            isLoading:false,
            errors:{},
            data:action.payload,
            }
        case deladd.DELSECTION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.message
            }
            default:
                    return state;
    }
}
export default delete_section;


import * as updatepaymentamount from '../../action/payroll/payroll_detail_update_payment_amount';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const payroll_detail_update_payment_amount = (state=initialState,action)=>{
    switch(action.type){
        case updatepaymentamount.DETAILUPDATEPAYMENTAMOUNT_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case updatepaymentamount.DETAILUPDATEPAYMENTAMOUNT_SUCCESS:
            return{
                ...state,
                data:action.payload,
                isLoading:false
            }
        case updatepaymentamount.DETAILUPDATEPAYMENTAMOUNT_FAILURE:
            return{
                ...state,
                errors:action.payload?.response?.data,
                isLoading:false
            }
        default:
            return state;
    }
}
export default payroll_detail_update_payment_amount;
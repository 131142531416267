import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const DELSCHEME_REQUEST = 'DELSCHEME_REQUEST';
export const DELSCHEME_SUCCESS = 'DELSCHEME_SUCCESS';
export const DELSCHEME_FAILURE = 'DELSCHEME_FAILURE';
export const DELSCHEME_RESET = 'DELSCHEME_RESET';

export const delete_leave_scheme = (id)=>{
    return dispatch=>{
        dispatch({
            type:'DELSCHEME_REQUEST'
        });

        myaxios.delete(`leave/scheme/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELSCHEME_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELSCHEME_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_leave_scheme = () => dispatch =>{
    dispatch({
        type:'DELSCHEME_RESET'
    });
}
import * as maternity_list from '../../action/employee/get_employee_maternity_list';

const initialState={
    data:[],
    isLoading:false,
    errors:null,
}

const get_employee_maternity_list = (state=initialState,action)=>{
    switch(action.type){
        case maternity_list.GETEMPMATERNITY_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:[]
            }
        case maternity_list.GETEMPMATERNITY_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case maternity_list.GETEMPMATERNITY_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_employee_maternity_list;


export const emp_get_employee_maternity_list = (state,id)=>{
    const { data } = state.get_employee_maternity_list;
    return  id != null && id !== '' ? data.filter(item=>item.employee === id).sort((a,b)=> a.birth_times - b.birth_times) : [];
}
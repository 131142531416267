import { Buffer } from "buffer";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Viewer from "react-viewer";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import Swal from "sweetalert2";
import { reset_get_user_leave_detail } from "../../../../action/user/get_user_leave_detail";
import { user_apply_leave_list } from "../../../../action/user/user_apply_leave_list";
import { user_get_all_leave_list } from "../../../../action/user/user_get_all_leave_list";
import { user_get_available_leave } from "../../../../action/user/user_get_available_leave";
import { reset_user_leave_cancel_apply_leave, user_leave_cancel_apply_leave } from "../../../../action/user/user_leave_cancel_apply_leave";
import { reset_user_leave_get_leave_picture, user_leave_get_leave_picture } from "../../../../action/user/user_leave_get_leave_picture";
import { getDateFormat } from "../../../../func/getDateFormat";
import { IMLocalized } from "../../../../language/IMLocalized";


export default function ModalViewLeaveDetail({ toggle, closeModal}){

    const detail = useSelector(state=>state.get_user_leave_detail.data);
    const isLoading = useSelector(state=>state.get_user_leave_detail.isLoading);
    const success = useSelector(state=>state.user_leave_cancel_apply_leave.data);
    const isLoading1 = useSelector(state=>state.user_leave_cancel_apply_leave.isLoading);
    const picture = useSelector(state=>state.user_leave_get_leave_picture.data);

    const dispatch = useDispatch();
    const [ activeImg, setActiveImg] = useState(0);
    const [ visible, setVisible] = useState(false);
    const [ imglist, setImageList] = useState([]);

    useEffect(()=>{
        if(toggle && detail != null){
            const { leavepic } = detail;
            if(leavepic != null){
                for(const image of leavepic){
                    dispatch(user_leave_get_leave_picture(image.id));
                }
            }
        }
    },[toggle,detail,dispatch])

    useEffect(()=>{
        if(picture != null && toggle){
            const img = new Buffer.from(picture,'binary').toString('base64');
            const src = `data:image/png;base64,${img}`;
            setImageList(prevState=>([...prevState,{src}]));
        }
    },[picture,toggle])

    useEffect(()=>{
        if(toggle &&  success != null){
            closeModal();
            dispatch(user_apply_leave_list());
            dispatch(user_get_available_leave());
            dispatch(user_get_all_leave_list());
            dispatch(reset_user_leave_cancel_apply_leave());
        }
    },[success,toggle,closeModal,dispatch])

    useEffect(()=>{
        if(!toggle){
            setImageList([]);
            dispatch(reset_user_leave_get_leave_picture());
            dispatch(reset_get_user_leave_detail());
        }
    },[toggle,dispatch])

    const openPicture=(index)=>{
        setVisible(true);
        setActiveImg(index);
    }


    const cancelLeave=()=>{
        Swal.fire({
            title:IMLocalized('cancelling_leave'),
            input:'textarea',
            text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!')
        })
        .then(result=>{
            if('value' in result){
                let memo = result.value;
                let arr = [detail?.id];
                dispatch(user_leave_cancel_apply_leave(arr,memo));
            }
        })
    }

    const approvers = detail ? detail.approvers.filter(item =>item.is_active === true) : [];

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('leave_detail')}</ModalHeader>
            <ModalBody>
                {isLoading && <span>{IMLocalized('loading')}..</span>}
                {!isLoading && detail &&
                <>
                <Row>
                    <Col className="d-flex jusitfy-content-center">
                        <Table size="sm" className="align-items-center bg-white table-bordered">
                            <thead className="thead-light">
                                <tr>
                                    <th className='td-w-30'>{IMLocalized('date')}</th>
                                    <th>{IMLocalized('option')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {detail.days.map((item,index)=>{
                                    const date = getDateFormat(item.date);
                                    const day = IMLocalized(item.day);
                                    return(
                                        <tr key={index}>
                                            <td>{date}</td>
                                            <td>{day}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Table size="sm" className="table-bordered">
                    <tbody>
                        <tr>
                            <td className='td-w-30'>{IMLocalized('employee_number')}</td>
                            <td>{detail.employee.employee_number}</td>
                        </tr>
                        <tr>
                            <td >{IMLocalized('employee_name')}</td>
                            <td>{detail.employee.name}</td>
                        </tr>
                        <tr>
                            <td>{IMLocalized('leave_type')}</td>
                            <td>{detail.leave_setting.name}</td>
                        </tr>
                        <tr>
                            <td>{IMLocalized('status')}</td>
                            <td>{IMLocalized(detail.status)}</td>
                        </tr>
                        {detail.comments.length !== 0 &&
                        <tr>
                            <td>{IMLocalized('comment')}</td>
                            <td className='comment-td'>
                                {detail.comments.map((item,index)=>{
                                const word = item.comment;
                                const employee_name = item.employee_name;
                                return <div className='comment-td-detail' key={index}><span className='font-weight-bold'>{employee_name}</span><span>: {word}</span></div>
                                })}
                            </td>
                        </tr>}
                        <tr>
                            <td>{IMLocalized('leave_amount')}</td>
                            <td>{detail.amount}</td>
                        </tr>
                        {imglist.length !== 0 &&
                        <tr>
                            <td>{IMLocalized('image')}</td>
                            <td>
                                <Row>
                                    {imglist.map((item,index)=>{
                                        return(<Col lg="4" key={index}>
                                            <div className="image-item mr-2" key={index} onClick={()=>openPicture(index)}  >
                                                <div className="image-inner">
                                                    <img src={item.src} className="img-thumbnail" alt={"This is Leave" +index}></img>
                                                </div>
                                            </div>
                                        </Col>)
                                    })}
                                </Row>
                            </td>
                        </tr>}
                        {approvers.length !== 0 &&
                        <tr>
                            <td className='td-w-30'>{IMLocalized('approval_pending')}</td>
                            <td>
                                {approvers.map((item,index)=>{
                                    if(item.delegation_application_approval === true){
                                        return <div key={index}><span>{item.employee.name} ({IMLocalized('delegation')}) </span></div>
                                    }
                                    else{
                                        return <div key={index}><span>{item.employee.name}</span></div>
                                    }
                                })}
                            </td>
                        </tr>}
                    </tbody>
                </Table>
                <Viewer activeIndex={activeImg} zIndex={1060} visible={visible} onClose={()=>setVisible(false)} images={imglist} />
                </>}
            </ModalBody>
            <ModalFooter>
                {isLoading1 && <button className="btn btn-danger btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>}
                {!isLoading1 && detail?.status === 'PENDING' &&
                <>
                    <button className="btn btn-danger btn-sm" onClick={()=>cancelLeave()}>{IMLocalized('cancel')}</button>
                    
                </>}
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
                
            </ModalFooter>
        </Modal>
    )
}
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import { get_additional_setting } from '../../../action/additional_setting';
import { create_flat_allowance, reset_create_flat_allowance } from '../../../action/create_flat_allowance';

const initialState={
    code:'',
    err_code:'',
    name:'',
    err_name:'',
    amount:'',
    err_amount:'',
}

function ModalCreateFixed({toggle,closeModal}){
   
    const isLoading = useSelector(state=>state.create_flat_allowance.isLoading);
    const success = useSelector(state=>state.create_flat_allowance.data);
    const errors = useSelector(state=>state.create_flat_allowance.errors);
    const [data,setData] = useState(initialState);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(toggle){
            setData(initialState);
        }
    },[toggle])

    useEffect(()=>{
        if( toggle && success !== null ){
            closeModal();
            dispatch(get_additional_setting());
            dispatch(reset_create_flat_allowance());
         
        }
    },[success,toggle,closeModal,dispatch])

    useEffect(()=>{
        if( toggle && errors !== null){
            setData(prevState=>({
                ...prevState,
                err_name:errors.hasOwnProperty('name') ? errors['name'].toString() : '',
                err_code:errors.hasOwnProperty('code') ? errors['code'].toString() : '',
            }));
            dispatch(reset_create_flat_allowance());
        }
    },[errors,toggle,dispatch])

    const createFixed=()=>{
        if(data.amount !== '' && data.code !== '' && data.name !== ''){
            dispatch(create_flat_allowance(data.code,data.name,data.amount));
        }
        else{
            setData(prevState=>({
                ...prevState,
                err_amount:data.amount === '' ? IMLocalized('amount_is_required') : '',
                err_name:data.name === '' ? IMLocalized('name_is_required') : '',
                err_code:data.code === '' ? IMLocalized('code_is_required') : '',
            }))
        }
    }

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={()=>closeModal()}>{IMLocalized('add_fixed_rate')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='code'>{IMLocalized('code')}</label>
                            <Input size="sm" type="text" id='code' value={data.code} invalid={data.err_code.length > 0}
                            onChange={(e)=>{
                                const value = e.target.value;
                                setData(prevState=>({
                                    ...prevState,
                                    code:value,
                                    err_code:'',
                                }))
                            }} 
                            />
                            <FormFeedback>{data.err_code}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>{IMLocalized('name')}</label>
                            <Input type="text" size="sm" id="name" value={data.name} invalid={data.err_name.length > 0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setData(prevState=>({
                                    ...prevState,
                                    name:value,
                                    err_name:''
                                }))
                            }} 
                            />
                            <FormFeedback>{data.err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='amount'>{IMLocalized('amount')}</label>
                            <Input type="number" size="sm" min="0" step="1" id="amount" value={data.amount} invalid={data.err_amount.length >0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setData(prevState=>({
                                    ...prevState,
                                    amount:value,
                                    err_amount:'',
                                }))
                            }}
                            />
                            <FormFeedback>{data.err_amount}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                <button className="btn btn-primary btn-sm" onClick={()=>createFixed()} >{IMLocalized('add')}</button>
                <button className="btn btn-danger btn-sm" onClick={()=>closeModal()}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
export default ModalCreateFixed;
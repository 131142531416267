import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { user_claim_approval_group } from '../../action/user/user_claim_approval_group';
import { user_leave_approval_group } from '../../action/user/user_leave_approval_group';
import ModalUserApplyLeave from '../employee/user_employee/modal/ModalUserApplyLeave';
import ModalUserApplyClaim from '../employee/user_employee/modal/ModalUserApplyClaim';
import { IMLocalized } from '../../language/IMLocalized';
import { NewMainContext } from '../../context/NewMainContext';

const mapStateToProps=(state)=>({
    emp_data:state.user_self.data,
    availablelist:state.user_get_available_leave.data,
    claim_availablelist:state.user_get_available_claim.data,
    dashboardlist:state.get_general_dashboard.data,
})

const mapDispatchToProps=(dispatch)=>({
    user_leave_approval_group:(data)=>{
        dispatch(user_leave_approval_group(data))
    },
    user_claim_approval_group:(data)=>{
        dispatch(user_claim_approval_group(data))
    }
})

function UserFunctionButton(props){

    const { addItem, setUserEmployeeStep, setUserPayrollId } = useContext(NewMainContext);
    const {user_leave_approval_group,user_claim_approval_group} = props;
    const [user_payslip,setUserPayslip] = useState([]);
    const [leave_toggle,setLeaveToggle] = useState(false);
    const [claim_toggle,setClaimToggle] = useState(false);

    useEffect(()=>{
        if(props.dashboardlist.length !== 0){
            const {is_admin,user} = props.dashboardlist;
            if(!is_admin){
                const {payslip} = user;
                setUserPayslip(payslip);
            }
        }
    },[props.dashboardlist])

    useEffect(()=>{
        let exceptions_group = props.availablelist.map(item=>{
            return {...item,group:0}
        })

        const approvers_group =[];
        let name = null;
        if(props.emp_data.length !== 0 && exceptions_group.length !== 0){
            const {metadata} = props.emp_data;
            if(metadata.hasOwnProperty('lag')){
                const {lag} = metadata;
                if(lag != null){
                    const {approvers,exceptions} = lag;
                    approvers_group.push(approvers);
                    for(let i=0;i<exceptions.length;i++){
                        const number = i+1;
                        approvers_group.push(exceptions[i].approvers);
                        const exceptions1 = exceptions[i].exceptions;
                        for(let j=0;j<exceptions1.length;j++){
                            const {id} = exceptions1[j];
                            exceptions_group = exceptions_group.map(item=>
                                item.leave_setting === id
                                ? { ...item, group:number}
                                :item
                            )
                        }
                    }
                    name = lag.name;
                }
            }
        }
        user_leave_approval_group({approvers:approvers_group,exceptions:exceptions_group,name});
    },[props.availablelist,props.emp_data,user_leave_approval_group])

    useEffect(()=>{
        let exceptions_group = props.claim_availablelist.map(item=>{
            return {...item,group:0}
        })

        const approvers_group =[];
        let name = null;
        if(props.emp_data.length !== 0 && exceptions_group.length !== 0){
            const {metadata} = props.emp_data;
            if(metadata.hasOwnProperty('cag')){
                const {cag} = metadata;
                if(cag != null){
                    const {approvers,exceptions} = cag;
                    approvers_group.push(approvers);
                    for(let i=0;i<exceptions.length;i++){
                        const number = i+1;
                        approvers_group.push(exceptions[i].approvers);
                        const exceptions1 = exceptions[i].exceptions;
                        for(let j=0;j<exceptions1.length;j++){
                            const {id} = exceptions1[j];
                            exceptions_group = exceptions_group.map(item=>
                                item.claim_type === id
                                ? { ...item, group:number}
                                :item
                            )
                        }
                    }
                    name = cag.name;
                }
            }
        }
        user_claim_approval_group({approvers:approvers_group,exceptions:exceptions_group,name});
    },[props.claim_availablelist,props.emp_data,user_claim_approval_group])


    const viewPayroll = ()=>{
        const {id} = user_payslip[0];
        addItem('2','profile');
        setUserPayrollId(id);
        setUserEmployeeStep('1')
    }

    return(
        <div className='pt-2'>
            <Button color="default" outline size="sm" type="button" onClick={()=>setLeaveToggle(true)} >{IMLocalized('apply_leave')}</Button>
            <Button color="default" outline size="sm" type="button" onClick={()=>setClaimToggle(true)} >{IMLocalized('apply_claim')}</Button>
            {user_payslip.length !== 0  && <Button color="default" outline size="sm" type="button" onClick={()=>viewPayroll()} >{IMLocalized('current_payroll')}</Button>}
            <ModalUserApplyLeave toggle={leave_toggle} 
            closeModal={()=>{
                setLeaveToggle(false);
            }} />
            <ModalUserApplyClaim toggle={claim_toggle} 
            closeModal={()=>{
                setClaimToggle(false);
            }}
            />
        </div>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(UserFunctionButton);
import * as emprange from '../../action/employee/employee_range.js';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const employee_range = (state=initialState,action)=>{
    switch(action.type){
        case emprange.REQUEST_EMPRANGE:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case emprange.GET_EMPRANGE:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case emprange.FAILURE_EMPRANGE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default employee_range;
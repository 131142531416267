import * as delegationlist from '../../action/employee/get_employee_delegation_list';

const initialState={
    data:[],
    isLoading:false,
    errors:[]
}

const get_employee_delegation_list = (state=initialState,action)=>{
    switch(action.type){
        case delegationlist.GETEMPDELEGATIONLIST_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case delegationlist.GETEMPDELEGATIONLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case delegationlist.GETEMPDELEGATIONLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_employee_delegation_list;
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const REFRESHEMPLOYEE_REQUEST = 'REFRESHEMPLOYEE_REQUEST';
export const REFRESHEMPLOYEE_SUCCESS = 'REFRESHEMPLOYEE_SUCCESS';
export const REFRESHEMPLOYEE_FAILURE = 'REFRESHEMPLOYEE_FAILURE';


export const refresh_employee =(id)=>{
    return dispatch=>{
        dispatch({
            type:'REFRESHEMPLOYEE_REQUEST'
        });

        myaxios.post(`payroll/payroll/${id}/RefreshEmployee/`)
        .then((response)=>{
            dispatch({
                type:'REFRESHEMPLOYEE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('refresh_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'REFRESHEMPLOYEE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('refresh_failure')
            });
        })
    }
}
import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const CREATEJOBTITLE_SUCCESS = 'CREATEJOBTITLE_SUCCESS';
export const CREATEJOBTITLE_REQUEST = 'CREATEJOBTITLE_REQUEST';
export const CREATEJOBTITLE_FAILURE = 'CREATEJOBTITLE_FAILURE';
export const CREATEJOBTITLE_RESET = 'CREATEJOBTITLE_RESET';

export const create_jobtitle = (name)=>{
    return dispatch =>{
        dispatch({
            type: 'CREATEJOBTITLE_REQUEST'
        });
        
        myaxios.post(`company/jobtitle/`,{
            name:name
        })
        .then((response)=>{
            dispatch({
                type:'CREATEJOBTITLE_SUCCESS',
                payload:response
                
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            })
            
        })
        .catch((error) =>{
            dispatch({
                type: 'CREATEJOBTITLE_FAILURE',
                payload: error,
                error:true
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_failure')
            });
        })
    }
}

export const reset_create_jobtitle = () => dispatch =>{
    dispatch({
        type:'CREATEJOBTITLE_RESET'
    });
}
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';


export const USERCLAIMDETAILCANCELCLAIM_REQUEST = 'USERCLAIMDETAILCANCELCLAIM_REQUEST';
export const USERCLAIMDETAILCANCELCLAIM_SUCCESS = 'USERCLAIMDETAILCANCELCLAIM_SUCCESS';
export const USERCLAIMDETAILCANCELCLAIM_FAILURE = 'USERCLAIMDETAILCANCELCLAIM_FAILURE';
export const USERCLAIMDETAILCANCELCLAIM_RESET = 'USERCLAIMDETAILCANCELCLAIM_RESET';

export const user_claim_detail_cancel_apply_claim =(claims,comment)=>{
    return dispatch=>{
        dispatch({
            type:'USERCLAIMDETAILCANCELCLAIM_REQUEST'
        });

        myaxios.post('claim/self/apply/array_cancel/',{
            claims:claims,
            comment:comment
        })
        .then((response)=>{
            dispatch({
                type:'USERCLAIMDETAILCANCELCLAIM_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('cancel_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'USERCLAIMDETAILCANCELCLAIM_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('cancel_failure')
            });
        })
    }
}

export const reset_user_claim_detail_cancel_apply_claim = () => dispatch =>{
    dispatch({
        type:'USERCLAIMDETAILCANCELCLAIM_RESET'
    });
}
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { claim_approvals_get_claim_picture, reset_claim_approvals_get_claim_picture } from '../../../action/approvals/claim_approvals_get_claim_picture';
import { getDateFormat } from '../../../func/getDateFormat';
import { IMLocalized } from '../../../language/IMLocalized';
import { Buffer } from 'buffer';
import { reset_claim_approvals_get_claim_detail } from '../../../action/approvals/claim_approvals_get_claim_detail';
import Viewer from 'react-viewer';


export default function ModalManagerClaimDetail({ toggle, closeModal }){

    const detail = useSelector(state=>state.claim_approvals_get_claim_detail.data);
    const picture = useSelector(state=>state.claim_approvals_get_claim_picture.data);
    const [img_toggle, setImgToggle] = useState(false);
    const [activeImg,setActiveImg] = useState(0);
    const [imglist, setImageList] = useState([]);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(!toggle){
            setImageList([]);
            dispatch(reset_claim_approvals_get_claim_detail());
            dispatch(reset_claim_approvals_get_claim_picture());
        }
    },[toggle,dispatch])

    useEffect(()=>{
        if(detail != null ){
            const { images } = detail;
            for(let i=0; i<images.length; i++){
                dispatch(claim_approvals_get_claim_picture(images[i]));
            }
        }
    },[detail,dispatch])

    useEffect(()=>{
        if(picture != null){
            const img = new Buffer.from(picture,'binary').toString('base64');
            const src = `data:image/png;base64,${img}`;
            setImageList(prevState=>([...prevState,{src}]));
        }
    },[picture])

    
    const approvers = detail?.approvers.filter(item=>item.is_active === true) ?? []; 

    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('claim_detail')}</ModalHeader>
            {detail &&
            <>
            <ModalBody>
                <Row>
                    <Col>
                        <Table className='table-bordered' size="sm">
                            <tbody>
                                <tr>
                                    <td className="td-w-30">{IMLocalized('employee_number')}</td>
                                    <td>{detail.employee.employee_number}</td>
                                </tr>
                                <tr>
                                    <td >{IMLocalized('employee_name')}</td>
                                    <td>{detail.employee.name}</td>
                                </tr>
                                <tr>
                                    <td >{IMLocalized('claim_date')}</td>
                                    <td>{getDateFormat(detail.claim_date)}</td>
                                </tr>
                                <tr>
                                    <td >{IMLocalized('claim_type')}</td>
                                    <td>
                                        {detail.claim_type.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td >{IMLocalized('claim_amount')}</td>
                                    <td>
                                        $ {detail.claim_amount}
                                    </td>
                                </tr>
                                <tr>
                                    <td >{IMLocalized('status')}</td>
                                    <td>{IMLocalized(detail.status)}</td>
                                </tr>
                                {detail.claim_type.medical && 
                                <>
                                <tr >
                                    <td>{IMLocalized('doctor')}</td>
                                    <td>{detail.medicalclaim.doctor}</td>
                                </tr>
                                <tr >
                                    <td>{IMLocalized('diagnosis')}</td>
                                    <td>{detail.medicalclaim.diagnosis}</td>
                                </tr>
                                <tr>
                                    <td>{IMLocalized('location')}</td>
                                    <td>{detail.medicalclaim.location}</td>
                                </tr>
                                </>}
                                <tr>
                                    <td >{IMLocalized('dependant')}</td>
                                    <td>
                                        <div className='custom-control custom-checkbox'>
                                            <input type="checkbox" id={'empallclaimdependant'} name="dependant" className="custom-control-input" checked={detail.dependant} readOnly={true} />
                                            <label className="custom-control-label" htmlFor={'empallclaimdependant'}></label>
                                        </div>
                                    </td>
                                </tr>
                                {detail.comments.length !== 0 &&
                                <tr>
                                    <td>{IMLocalized('comment')}</td>
                                    <td className='comment-td'>
                                        {detail.comments.map((item,index)=>{
                                            const word = item.comment;
                                            const employee_name = item.employee_name;
                                            return <div className='comment-td-detail' key={index}><span className='font-weight-bold'>{employee_name}</span><span>: {word}</span></div>
                                        })}
                                    </td>
                                </tr>}
                                {imglist.length !== 0 &&
                                <tr>
                                    <td>{IMLocalized('image')}</td>
                                    <td>
                                        <Row >
                                            {imglist.map((item,index)=>{
                                                return(
                                                    <Col lg="4" key={index} className="mt-1">
                                                        <div className='image-item mr-2' onClick={()=>{setImgToggle(true);setActiveImg(index)}}>
                                                            <div className='image-inner'>
                                                                <img src={item.src} className="img-thumbnail" alt={"This is Claim " +index} />
                                                            </div>
                                                        </div>
                                                    </Col>)
                                            })}
                                        </Row>
                                    </td>
                                </tr>
                                }
                                {approvers.length !== 0 && 
                                <tr>
                                    <td>{IMLocalized('approval_pending')}</td>
                                    <td>
                                        {approvers.map((item,index)=>{
                                            if(item.delegation_application_approval === true){
                                                return <div key={index}><span>{item.employee.name} ({IMLocalized('delegation')}) </span></div>
                                            }
                                            else{
                                                return <div key={index}><span>{item.employee.name}</span></div>
                                            }
                                        })}
                                    </td>
                                </tr>}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Viewer activeIndex={activeImg} zIndex={1060} visible={img_toggle} onClose={()=>setImgToggle(false)} images={imglist}  />
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
            </>}
        </Modal>
    )
}
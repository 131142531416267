import axios from 'axios';
import store from './store';
import { TOKEN_FAILURE1 } from './action/auth1';

const myaxios = axios.create({
    baseURL: 'https://'+process.env.REACT_APP_API_URL +'/api/',
    timeout:0,
});


myaxios.interceptors.request.use(function (config){
    const token = localStorage.getItem('tkn');
    config.headers.Authorization = token ? `Bearer ${token}` : 'token is empty';
    return config;
},error => Promise.reject(error));

myaxios.interceptors.response.use(function(response){
    return response
}, function(error){
    
    if(!error.response){
       window.location.href="/#/network/failed/";
    }
    
    if(error.response.status ===403 ){
    }

    if(error.response.status === 401){
        store.dispatch({type:TOKEN_FAILURE1});
    }
    return Promise.reject(error)
})
export default myaxios;
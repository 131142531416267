import React, { useCallback, useEffect, useState,useRef } from 'react';
import { connect } from 'react-redux';
import { IMLocalized } from '../../language/IMLocalized';
import CustomPinnedRowRenderer from '../ag-grid_component/custompinnedrow';
import { AgGridReact } from 'ag-grid-react';
import { popError } from '../../func/popError';
import {update_payroll_paymentdetail} from '../../action/payroll/payment_update_payroll_payment';


const mapStateToProps=(state)=>({
    list:state.get_payroll_instance.data,
    isLoading:state.get_payroll_instance.isLoading,
})

const mapDispatchToProps=(dispatch)=>({
    update_payroll_paymentdetail:(url,data)=>{
        dispatch(update_payroll_paymentdetail(url,data))
    },
})

function PayrollPaymentAggrid(props){
    
    const {toPayrollPayslip} = props;
    const [rowData,setRowData] = useState([]);
    const [rowData2,setRowData2] = useState([]);
    const [bottomData,setBottomData] = useState([]);
    const gridApi = useRef();

    useEffect(()=>{
        if(props.list.length !== 0){
            const item = props.list[0];
            const {payslips} = item;
            const arr = payslips.map((item1)=>{
                return {...item1,payable_wage1:`$${item1.payable_wage}`,total_wage1:`$${item1.total_wage}`};
            })
            const arr2 = payslips.map((item1)=>{
                return {reference:item1.reference}
            })
            setRowData(arr);
            setRowData2(arr2);
            const amount = payslips.reduce((prev,current)=>prev + current.payable_wage,0)
            const amount2 = payslips.reduce((prev,current)=>prev + current.total_wage,0)
            const bottomData1 = [{reference:'Total',payable_wage1:'$'+amount.toFixed(2),total_wage1:'$'+amount2.toFixed(2)}]
            setBottomData(bottomData1)
        }   
    },[props.list])


    const toDetail=useCallback((id,emp)=>{
        toPayrollPayslip({step:'4',id,emp});
    },[toPayrollPayslip])

    const myCellRenderer = useCallback((params)=>{
        
        return <a href="/#"  onClick={()=>toDetail(props.id,params.data.employee.id)} >{params.value}</a>
    },[props.id,toDetail])

    const updateReference=()=>{
        let aggrid_data = [];
        gridApi.current.forEachNode(function(rowNodex,index){
            const item = rowNodex.data;
            aggrid_data.push(item);
        })
        let new_data = [];
        for(let i=0;i<aggrid_data.length;i++){
            const item = aggrid_data[i];
            if(item.reference != null && item.reference !== rowData2[i].reference){
                new_data.push({id:item.id ,reference:item.reference})
            }
        }
        if(new_data.length !== 0){
            props.update_payroll_paymentdetail(props.id,new_data);
        }
        else{
            popError(IMLocalized('no_data_updated'));
        }
    }

    return(
        <>
        {rowData.length !== 0 &&
        <div className='d-flex justify-content-end mt-2'>
            <button className='btn btn-primary btn-sm' onClick={updateReference}>{IMLocalized('update_reference')}</button>
        </div>}
        <div className='ag-theme-balham mt-2' style={{height:'calc(100vh - 350px)', width:'100%' }}>
            <AgGridReact
            enableSorting={true}
            rowData={rowData}
            pinnedBottomRowData={bottomData}
            defaultColDef={{
                resizable:true
            }}
            onGridReady={(params)=>{
                gridApi.current = params.api;
            }}
            stopEditingWhenCellsLoseFocus={true}
            singleClickEdit={true}
            frameworkComponents={{customPinnedRowRenderer:CustomPinnedRowRenderer}}
            columnDefs={[
                {
                    headerName:IMLocalized('agrid_emp_id'),
                    field:'employee.employee_number',
                    editable:false,
                    width:100,
                    suppressMovable: true
                },
                {
                    headerName:IMLocalized('agrid_emp_name'),
                    field:'employee.name',
                    editable:false,
                    width:200,
                    suppressMovable: true,
                    cellRendererFramework: myCellRenderer
                },
                {
                    headerName:IMLocalized('agrid_job_level'),
                    field:'employee.contract_cache.job_level',
                    editable:false,
                    width:150,
                    suppressMovable: true
                },
                {
                    headerName:IMLocalized('agrid_department'),
                    field:'employee.contract_cache.department',
                    editable:false,
                    width:150,
                    suppressMovable: true
                },
                {
                    headerName:IMLocalized('agrid_job_title'),
                    field:'employee.contract_cache.job_title',
                    editable:false,
                    width:150,
                    suppressMovable: true
                },
                {
                    headerName:IMLocalized('agrid_cost_center'),
                    field:'employee.contract_cache.cost_center',
                    editable:false,
                    width:150,
                    suppressMovable: true
                },
                {
                    headerName:IMLocalized('agrid_section'),
                    field:'employee.contract_cache.section',
                    editable:false,
                    width:150,
                    suppressMovable: true
                },
                {
                    headerName:IMLocalized('agrid_cpf_employer'),
                    field:'sgcpf.cpf_employer',
                    editable:false,
                    width:150,
                    suppressMovable: true
                },
                {
                    headerName:IMLocalized('agrid_cpf_employee'),
                    field:'sgcpf.cpf_employee',
                    editable:false,
                    width:150,
                    suppressMovable: true
                },
                {
                    headerName:IMLocalized('agrid_payment_type'),
                    field:'employee.payment',
                    editable:false,
                    width:150,
                    suppressMovable: true
                },
                {
                    headerName:IMLocalized('agrid_reference_id'),
                    field:'reference',
                    editable:true,
                    width:150,
                    pinnedRowCellRenderer: 'customPinnedRowRenderer',
                    suppressMovable: true
                },
                {
                    headerName:IMLocalized('agrid_salary'),
                    field:'total_wage1',
                    editable:false,
                    width:150,
                    pinnedRowCellRenderer: 'customPinnedRowRenderer',
                    suppressMovable: true
                },
                {
                    headerName:IMLocalized('agrid_payable'),
                    field:'payable_wage1',
                    editable:false,
                    width:150,
                    pinnedRowCellRenderer: 'customPinnedRowRenderer',
                    suppressMovable: true
                },
            ]}
            />
        </div>
        </>
    )   
}
export default connect(mapStateToProps,mapDispatchToProps)(PayrollPaymentAggrid);
import myaxios from '../../axios';

export const EMPGETAVALEAVEOPTION_REQUEST= 'EMPGETAVALEAVEOPTION_REQUEST';
export const EMPGETAVALEAVEOPTION_SUCCESS= 'EMPGETAVALEAVEOPTION_SUCCESS';
export const EMPGETAVALEAVEOPTION_FAILURE= 'EMPGETAVALEAVEOPTION_FAILURE';

export const employee_get_available_leave_options = (employee,leave_setting,from_date,to_date)=>{
    return dispatch=>{
        dispatch({
            type:'EMPGETAVALEAVEOPTION_REQUEST'
        });

        myaxios.post(`/leave/employee/get_leave_options/`,{
            employee:employee,
            leave_setting:leave_setting,
            from_date:from_date,
            to_date:to_date
        })
        .then((response)=>{
            dispatch({
                type:'EMPGETAVALEAVEOPTION_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'EMPGETAVALEAVEOPTION_FAILURE',
                payload:error
            })
        })
    }
}
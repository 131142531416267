import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const DELETEFLATALLOWANCE_REQUEST = 'DELETEFLATALLOWANCE_REQUEST';
export const DELETEFLATALLOWANCE_SUCCESS = 'DELETEFLATALLOWANCE_SUCCESS';
export const DELETEFLATALLOWANCE_FAILURE = 'DELETEFLATALLOWANCE_FAILURE';
export const DELETEFLATALLOWANCE_RESET = 'DELETEFLATALLOWANCE_RESET';

export const delete_flat_allowance = (id)=>{
    return dispatch=>{
        dispatch({
            type:'DELETEFLATALLOWANCE_REQUEST'
        });

        myaxios.delete(`company/flat_allowance/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELETEFLATALLOWANCE_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELETEFLATALLOWANCE_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_flat_allowance = () => dispatch =>{
    dispatch({
        type:'DELETEFLATALLOWANCE_RESET'
    });
}
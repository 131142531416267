import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';



const mapStateToProps=(state)=>({
    data:state.user_self.data,
    list:state.get_employee_bank.data,
    bank_payee:state.dropdown_list.bank_payee,
    isLoading:state.user_self.isLoading,
    isLoading1:state.get_employee_bank.isLoading,
    isLoading2:state.dropdown_list.isLoading,
})

function UserEmployeeBank(props){

    const [list,setList] = useState([]);

    useEffect(()=>{
        if(props.data.length !== 0){
            const {banks} = props.data;
            banks.sort(function(a,b){
                if(a.default === b.default){
                    return 0;
                }
                else if(a.default === null){
                    return 1;
                }
                else if(b.default === null){
                    return -1;
                }
                else{
                    return a < b ? -1 : 1;
                }
            })
            setList(banks);
        }
    },[props.data])

    return(
        <>
        <div className='mt-2 pl-2 d-flex emp-tabcontent-title'>
            <div className="d-flex align-items-center">
                <i className="fas fa-university text-primary" />
                <h4 className="text-primary m-0 pl-2">{IMLocalized('employee_bank')}</h4>
            </div>
        </div>
        {props.isLoading || props.isLoading1 || props.isLoading2 ? 
        <div className='p-2'>
            <h4>{IMLocalized('loading')}..</h4>
        </div> :null}
        {!props.isLoading && !props.isLoading1 && !props.isLoading2 && list.length === 0 &&
        <div className='p-2'>
            <h4>{IMLocalized('no_data')}</h4>
        </div>}
        {!props.isLoading && !props.isLoading1 && !props.isLoading2 && list.length !== 0 &&
        <Table size="sm" className='mt-2 align-items-center table-bordered' responsive>
            <thead className="thead-light text-center">
                <tr>
                    <th>{IMLocalized('bank')}</th>
                    <th>{IMLocalized('account_number')}</th>
                </tr>
            </thead>
            <tbody className='text-center'>
                {list.map((item,index)=>{
                    
                    const detail = props.bank_payee.find(el=>el.value === item.bank);
                    const name = detail?.display ?? '-';
                    const default_name = item.default !== null &&   <span className='font-weight-bold'>({IMLocalized('default')})</span>;
                    return(
                        <tr key={index}>
                            <td><span className='text-primary'>{name}</span> {default_name}</td>
                            <td>{item.account_number}</td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>}
        </>
    )
}
export default connect(mapStateToProps,null)(UserEmployeeBank);
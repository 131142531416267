import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CREATEBRANCH_SUCCESS = 'CREATEBRANCH_SUCCESS';
export const CREATEBRANCH_REQUEST = 'CREATEBRANCH_REQUEST';
export const CREATEBRANCH_FAILURE = 'CREATEBRANCH_FAILURE';
export const CREATEBRANCH_RESET = 'CREATEBRANCH_RESET';

export const create_branch = (id,name,code,unit_number,sg_postal_code,address,description)=>{
    return dispatch =>{
        dispatch({
            type: 'CREATEBRANCH_REQUEST'
        });
        
        myaxios.post(`company/branch/`,
        {
            name:name,
            branch_code:code,
            address:address,
            sg_postal_code:sg_postal_code,
            unit_number:unit_number,
            description:description,
            company:id
        })
        .then((response)=>{
            dispatch({
                type:'CREATEBRANCH_SUCCESS',
                payload:response
            });
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            })
        })
        .catch((error) =>{
            dispatch({
                type: 'CREATEBRANCH_FAILURE',
                payload:error,
                error:true,
            });
            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            })
        })
    }
}

export const reset_create_branch = () => dispatch =>{
    dispatch({
        type:'CREATEBRANCH_RESET'
    })
}
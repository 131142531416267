import myaxios from '../../axios';

export const USERAPPLYCLAIMLIST_REQUEST = 'USERAPPLYCLAIMLIST_REQUEST';
export const USERAPPLYCLAIMLIST_SUCCESS = 'USERAPPLYCLAIMLIST_SUCCESS';
export const USERAPPLYCLAIMLIST_FAILURE = 'USERAPPLYCLAIMLIST_FAILURE';

export const user_apply_claim_list =()=>{
    return dispatch=>{
        dispatch({
            type:'USERAPPLYCLAIMLIST_REQUEST'
        });

        myaxios.get('claim/self/apply/')
        .then((response)=>{
            dispatch({
                type:'USERAPPLYCLAIMLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'USERAPPLYCLAIMLIST_FAILURE',
                payload:error
            })
        })
    }
}

import * as holiday_list from '../../action/user/user_holiday_list';

const initialState={
    data:[],
    errors:{},
    isLoading:false
}

const user_holiday_list = (state=initialState,action)=>{
    switch(action.type){
        case holiday_list.USERHOLIDAYLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case holiday_list.USERHOLIDAYLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case holiday_list.USERHOLIDAYLIST_FAILURE:
            return{
                ...state,
                errors:action.payload,
                isLoading:false
            }
        default:
            return state;
    }
}
export default user_holiday_list;

import * as recalculatepayroll from '../../action/payroll/recalculate_payroll';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const recalculate_payroll = (state=initialState,action)=>{
    switch(action.type){
        case recalculatepayroll.RECALCULATEPAYROLL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case recalculatepayroll.RECALCULATEPAYROLL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case recalculatepayroll.RECALCULATEPAYROLL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default recalculate_payroll;

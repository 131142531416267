import * as updateemppassport from '../../action/employee/update_employee_passport';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const update_employee_passport = (state=initialState,action)=>{
    switch(action.type){
        case updateemppassport.NEW_UPDATEEMPPASSPORT_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case updateemppassport.NEW_UPDATEEMPPASSPORT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updateemppassport.NEW_UPDATEEMPPASSPORT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case updateemppassport.NEW_UPDATEEMPPASSPORT_RESET:
            return{
                ...state,
                data:null,
                errors:null,
            }
        default:
            return state;
    }
}
export default update_employee_passport;
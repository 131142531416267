import myaxios from '../../axios';

export const DELEMPEDUCATIONPIC_REQUEST = 'DELEMPEDUCATIONPIC_REQUEST';
export const DELEMPEDUCATIONPIC_SUCCESS = 'DELEMPEDUCATIONPIC_SUCCESS';
export const DELEMPEDUCATIONPIC_FAILURE = 'DELEMPEDUCATIONPIC_FAILURE';

export const delete_employee_education_picture = (id)=>{
    return dispatch=>{
        dispatch({
            type:'DELEMPEDUCATIONPIC_REQUEST'
        });

        myaxios.delete(`ob/edu/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELEMPEDUCATIONPIC_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'DELEMPEDUCATIONPIC_FAILURE',
                payload:error
            })
        })
    }
}
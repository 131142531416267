import * as skip from '../../action/employee/update_skip_option';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const update_skip_option = (state=initialState,action)=>{
    switch(action.type){
        case skip.UPDATESKIPOPTION_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case skip.UPDATESKIPOPTION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case skip.UPDATESKIPOPTION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default update_skip_option;
import myaxios from '../../axios';
import FormData from 'form-data';

export const ADDEMPCERTIFICATIONPIC_REQUEST = 'ADDEMPCERTIFICATIONPIC_REQUEST';
export const ADDEMPCERTIFICATIONPIC_SUCCESS = 'ADDEMPCERTIFICATIONPIC_SUCCESS';
export const ADDEMPCERTIFICATIONPIC_FAILURE = 'ADDEMPCERTIFICATIONPIC_FAILURE';

export const add_employee_certification_picture = (id,file)=>{
    return dispatch=>{
        dispatch({
            type:'ADDEMPCERTIFICATIONPIC_REQUEST'
        });

        let formData = new FormData();
        if(file.length !== 0){
            for(let i=0;i<file.length;i++){
                formData.append('picture',file[i],file[i].name);
            }
        }
        
        myaxios.post(`employee/certification/${id}/AddPic/`,formData,{headers:{'Content-Type':'multipart/form-data'}})
        .then((response)=>{
            dispatch({
                type:'ADDEMPCERTIFICATIONPIC_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'ADDEMPCERTIFICATIONPIC_FAILURE',
                payload:error
            })
        })
    }
}
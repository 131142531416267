import React,{Component} from 'react';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';
import {Calendar} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interaction from '@fullcalendar/interaction';
import moment from 'moment';
import {DateRangePicker} from 'react-dates';
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {Input,FormFeedback,FormGroup,Modal,DropdownItem,ModalHeader,ModalBody,ModalFooter,Table,Container,Row,Col,Breadcrumb,BreadcrumbItem,Card,Button,CardHeader, Dropdown, DropdownToggle, DropdownMenu} from 'reactstrap';
import {calendar_leave_manager_approve} from '../../../action/approvals/calendar_leave_manager_approve';
import {get_general_dashboard} from '../../../action/dashboard/get_general_dashboard';
import {user_get_all_apply_leave_list} from '../../../action/user/user_get_all_apply_leave_list';
import Viewer from 'react-viewer';
import {calendar_approvals_get_leave_picture1} from '../../../action/approvals/calendar_approvals_get_leave_picture1';
import {leave_calendar_get_self_available_leave_options1} from '../../../action/user/leave_calendar_get_self_available_leave_options1';
import {leave_calendar_user_apply_leave_self1} from '../../../action/user/leave_calendar_user_apply_leave_self1';
import {user_get_available_leave} from '../../../action/user/user_get_available_leave';
import {user_apply_leave_list} from '../../../action/user/user_apply_leave_list';
import {get_calendar_approvals_leave_detail1} from '../../../action/approvals/get_calendar_approvals_leave_detail1';
import {manager_leave_list} from '../../../action/leave/manager_leave_list';
import {IMLocalized} from '../../../language/IMLocalized';
import OverlayLoading from '../../loading_component/overlay_loading';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css'; 
import '../../../css/aggrid.css';
import { Buffer } from 'buffer';
import { NewMainContext } from '../../../context/NewMainContext';


let calendar;

const gridOptions={
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
    suppressRowClickSelection:true,
}


class manager_calendar extends Component{

    constructor(props){
        super(props);
        this.state={
            holidaylist:[],
            holiday_arr:[],
            holoday_length:0,
            defaultlist:[],
            events:[],
            rowData:[],
            columnDefs:[],
            defaultColDef:{
                resizable:true,
                sortable:true,
            },
            noRowTemplate:IMLocalized('no_data'),
            has_action:false,
            approval_group:[],
            leave_approvers:[],
            leave_exceptions:[],
            isLoading:false,
            detail_toggle:false,
            detail_id:'',
            detail_img:[],
            detail_list:null,
            imglist:[],
            imgtoggle:false,
            activeImg:0,
            apply_toggle:false,
            startDate:null,
            start_date:null,
            endDate:null,
            end_date:null,
            err_select_date:'',
            step_apply:0,
            leave_setting:'',
            err_leave_setting:'',
            leave_entitlement:'',
            option_data:[],
            load_table:'',
            apply_file:[],
            remarks:'',
            err_remarks:'',
            approval:false,
            err_images:'',
            min_image:'',
            max_images:'',
            dateFormat:'DD/MM/YYYY',
            focusedInput:null,
            available_option:[],
            id:'',
            leave_checkbox:[],
            type_toggle:false,
            type_all:true,
            status_toggle:false,
            status_option:'all',
            apply_detail:'',
            color_approve:'#ffffff',
            color_pending:'#ffffff',
            color_reject:'#ffffff',
            color_cancel:'#ffffff',
            color_consumed:'#ffffff',
            approval_leaves:[],
            approval_id:'',
        }
        this.statusRenderer = this.statusRenderer.bind(this)
        this.dateRenderer = this.dateRenderer.bind(this)
        this.isFirstColumn = this.isFirstColumn.bind(this)
    }

    componentDidMount(){

        const columnDefs = [
            {
                headerName:IMLocalized('select'),
                pinned:'left',
                field:'select',
                width:150,
                headerCheckboxSelection: this.isFirstColumn,
                checkboxSelection: this.isFirstColumn,
                headerCheckboxSelectionFilteredOnly:true,
                lockPosition: true
            },
            {
                headerName:IMLocalized('leave_date'),
                field:'leave_date',
                filter:false,
                width:250,
                comparator:(valueA,valueB,nodeA,nodeB)=>{
                    const date1 = new Date(nodeA.data.from_date);
                    const date2 = new Date(nodeB.data.from_date);
                    return date2 - date1
                }

            },
            {
                headerName:IMLocalized('employee_number'),
                field:'leave.employee.employee_number'
            },
            {
                headerName:IMLocalized('employee_name'),
                field:'leave.employee.name'
            },
            {
                headerName:IMLocalized('leave_type'),
                field:'leave.leave_setting.name'
            },
            {
                headerName:IMLocalized('status'),
                field:'leave.status',
                cellRendererFramework:this.statusRenderer
            },
            {
                headerName:IMLocalized('leave_amount'),
                field:'leave.amount'
            },
            {
                headerName:IMLocalized('created_date'),
                field:'leave.created_date',
                cellRendererFramework:this.dateRenderer,
            }
        ]
        this.setState({columnDefs});

        Promise.resolve(this.getEvent())
        .then(this.getPendingList())
        .then(this.getColorDetail())
        .then(this.createCalendar())
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.list1 !== this.props.list1 || prevState.color_approve !==this.state.color_approve || prevState.color_pending !== this.state.color_pending || prevState.color_cancel !== this.state.color_cancel || prevState.color_consumed !== this.state.color_consumed || prevState.color_reject !== this.state.color_reject || prevState.count1 !== this.state.count1){
            Promise.resolve(this.getEvent())
            .then(this.getPendingList());
        }
        if(prevState.events !== this.state.events || this.context.leave_approvals_calendar_refresh === true){
            Promise.resolve(calendar.destroy())
            .then(this.createCalendar(this.state.holiday_arr,this.state.holiday_length))
            this.context.setLeaveApprovalsCalendarRefresh(false);
        }
        if(prevProps.approve_success !== this.props.approve_success){
            Promise.resolve(this.props.get_general_dashboard()).then(this.props.user_get_all_apply_leave_list())
            .then(this.props.user_apply_leave_list())
            .then(this.props.user_get_available_leave())
            .then(this.props.manager_leave_list())
            this.closeModal();
        }
        if(prevProps.detail !== this.props.detail){
            const {detail} = this.props;
            if(detail !== undefined && detail.length !== 0){
                const data = detail.data;
                const leavepic = data.leavepic;
                const id = data.id;
                this.setState({detail_id:id});
                this.setState({detail_list:data});
                this.setState({detail_toggle:true});
                this.setState({detail_img:leavepic});
                if(leavepic.length !== 0){
                    for(let i=0;i<leavepic.length;i++){
                        const id = leavepic[i].id;
                        this.props.calendar_approvals_get_leave_picture1(id);
                    }
                }
            }
            else{
                this.setState({detail_list:null})
                this.setState({detail_toggle:false})
            }
        }
        if(prevProps.detail_errors !== this.props.detail_errors){
            const {detail_errors} = this.props;
            if(detail_errors !== undefined && detail_errors.length !== 0){
                const data = detail_errors.response.data;
                if('non_field_errors' in data){
                    const word = data['non_field_errors'].toString();
                    this.popError(word)
                }
            }
        }
        if(prevProps.picture !== this.props.picture){
           
            let imglist = this.state.imglist;
            let data = this.props.picture;
            let img = new Buffer.from(data,'binary').toString('base64');
            let src = 'data:image/png;base64,'+img;
            imglist.push({src});
            this.setState({imglist});
        }
        if(prevProps.availablelist !== this.props.availablelist || prevProps.leavelist !== this.props.leavelist){
            Promise.resolve(this.getAvailableOption())
        }
        if(prevState.leave_setting !== this.state.leave_setting){
            const {leavelist} = this.props;
            let id = this.state.leave_setting;
            let detail = leavelist.filter(function(item){
                return item.id === parseInt(id)
            })
            let min,max;
            if(detail.length !== 0){
                min = detail[0].minimum_images;
                max = detail[0].maximum_images;
            }
            else{
                min = '';
                max = '';
            }
            this.setState({min_image:min});
            this.setState({max_images:max});
            this.setState({load_table:''});
            this.setState({step_apply:0})
            this.setState({apply_file:[]})
        }
        if(prevProps.data !== this.props.data){
            this.getId();
        }
        if(prevProps.option_data !== this.props.option_data){
            let data = this.props.option_data;
            let leave_entitlement = data.leave_entitlement;
            let leave_options = data.leave_options
            this.setState({leave_entitlement});
            this.setState({option_data:leave_options});
        }
        if(prevState.option_data !== this.state.option_data){
            this.getLeaveListOption();
        }
        if(prevProps.getoption_error !== this.props.getoption_error){
            let error = this.props.getoption_error;
            if('non_field_errors' in error){
                let arr='';
                const word = error['non_field_errors'];
                if(word.length !== 0){
                 arr = word.toString();
                }
                
                this.popError(arr);
                
            }
            if('to_date' in error){
                let arr= '';
                const word = error['to_date'];
                if(word.length !==0){
                    arr = word.toString();
                }
                this.popError(arr)
                
            }
            if('leave_setting' in error){
                const word = error['leave_setting'].toString();
                this.popError(word)
            }
            this.setState({step_apply:0});
        }
        if(prevProps.applyerr !== this.props.applyerr){
            let error = this.props.applyerr;
            if('non_field_errors' in error){
                let arr='';
                const word = error['non_field_errors'];
                if(word.length !== 0){
                 arr = word.toString();
                }
               
                this.popError(arr);
                
                this.setState({step_add:0});
            }
            if('leave_entitlement' in error){
                const word= error['leave_entitlement'].toString();
                this.popError(word)
            }
            if('upload_images' in error){
                const word = error['upload_images'].toString();
                this.popError(word)
            }
        }
        if(prevProps.apply_success !== this.props.apply_success){
            this.cancelApply();
            this.setState({allpending:false});
            this.props.user_get_available_leave();
            this.props.user_apply_leave_list();
            this.props.user_get_all_apply_leave_list();
            this.props.manager_leave_list();
        }
        if(prevState.apply_detail !== this.state.apply_detail){
            const data = this.state.apply_detail;
            if(data!==''){
                const leave_setting = data.leave_setting;
                this.setState({leave_setting});
                this.setState({apply_toggle:true});
            }
        }
        if(prevProps.leavelist !== this.props.leavelist){
            this.getLeaveCheckbox();
        }
        if(prevState.defaultlist !== this.state.defaultlist || prevState.type_all !== this.state.type_all || prevState.leave_checkbox !== this.state.leave_checkbox || prevState.status_option !== this.state.status_option){
            this.filterData()
        }
        if(prevProps.colorlist !== this.props.colorlist){
            this.getColorDetail();
        }
        if(prevState.detail_id !== this.state.detail_id || prevState.approval_leaves !== this.state.approval_leaves){
            const {detail_id,approval_leaves} = this.state; 
            if(detail_id !== '' && approval_leaves.length !== 0){
                const inside = this.existID(detail_id,approval_leaves);
                this.setState({has_action:inside});
                if(inside === true){
                    const detail = approval_leaves.filter(function(item){
                        return item.leave_id === detail_id
                    })
                    if(detail.length !== 0){
                        this.setState({approval_id:detail[0].id});
                    }
                }
            }
            else{
                this.setState({has_action:false});
                this.setState({approval_id:''});
            }
        }
        if(prevState.approval_group !== this.state.approval_group || prevProps.data !== this.props.data){
            this.getLeaveApproval();
        }
        if(this.context.leave_approvals_calendar_reset === true){
            this.setState({
                defaultlist:[],
                events:[],
                id:'',
                leave_checkbox:[],
                type_toggle:false,
                type_all:true,
                status_toggle:false,
                status_option:'all',
                approval_leaves:[],
                approval_id:'',
                has_action:false,
                rowData:[],
            })
            this.context.setLeaveApprovalsCalendarReset(false);
        }
    }

    statusRenderer=function(params){
        const {color_approve,color_cancel,color_consumed,color_pending,color_reject} = this.state;
        const status = params.value;
        let selected_color = '#fff';

        if(status === 'PENDING'){
            selected_color = color_pending;
        }
        else if(status === 'APPROVED'){
            selected_color = color_approve;
        }
        else if(status === 'REJECTED'){
            selected_color = color_reject;
        }
        else if(status === 'CANCELLED'){
            selected_color = color_cancel
        }
        else if(status === 'CONSUMED'){
            selected_color = color_consumed;
        }
        return <div><span className="mr-2" style={{color:selected_color}}>●</span> {IMLocalized(status)}</div>
    }

    dateRenderer=function(params){
        const date = params.value;
        const date2 = new Date(params.value);
        let that_date = null;
        if(date !== null){
            let month = date2.getMonth()+1;
            if(month<10){
                month = '0'+month;
            }
            let day = date2.getDate();
            if(day<10){
                day = '0'+day;
            }
            let min = date2.getMinutes();
            if(min<10){
                min = '0'+min;
            }
            let sec = date2.getSeconds();
            if(sec<10){
                sec = '0'+sec;
            }
            let hour = date2.getHours();
            if(hour <10){
                hour = '0'+hour;
            }
            that_date = day+'/'+month+'/'+date2.getFullYear() + ' ' + hour +':'+min +":"+sec;
        
           
        }
        return that_date 
    }

    isFirstColumn(params) {
        if(params !== null){
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
        
    }

    popError=(msg)=>{
        Swal.fire({
            title:msg,
            type:'warning',
            confirmButtonColor:'#d33',
            confirmButtonText:IMLocalized('ok!')
        })
    }

    getLeaveApproval=()=>{
        const {data} = this.props;
        let {approval_group} = this.state;
        let approvers_group=[];

        if(data.length !== 0 && approval_group.length !== 0){
            const metadata = data.metadata;
            if('lag' in metadata ){
                const lag = metadata.lag;
                if(lag !== null){
                    const approvers = lag.approvers;
                    approvers_group.push(approvers);
                    const exceptions = lag.exceptions;
                    for(let i =0;i<exceptions.length ;i++){
                        const number = i+1;
                        const approvers = exceptions[i].approvers;
                        approvers_group.push(approvers)
                        const exceptions1 = exceptions[i].exceptions;
                        for(let j=0;j<exceptions1.length;j++){
                            const id = exceptions1[j].id;
                            approval_group = approval_group.map(item=>
                                item.leave_setting === id
                                ?{...item,group:number}
                                :item
                            )
                        }
                    }
                }
            }
            
        }
        this.setState({leave_approvers:approvers_group});
        this.setState({leave_exceptions:approval_group});
    }

    existID(id,arr){
        return arr.some(function(el){
            return el.leave_id === id
        })
    }

    getPendingList=()=>{
        const {list} = this.props;
        const approvals = list.approvals;
        if(approvals){

            if(approvals.length !== 0){
                const detail = approvals.filter(function(item){
                    return item.leave.status === 'PENDING'
                })

                let arr = [];
                if(detail.length !== 0){
                    for(let i=0;i<detail.length;i++){
                        const data = detail[i];
                        const from_date1 = data.leave.from_date;
                        const to_date1 = data.leave.to_date;
                        const from_date = this.getDateFormat(data.leave.from_date);
                        const to_date = this.getDateFormat(data.leave.to_date);
                        let date = '';
                        if(from_date1 === to_date1){
                            date = from_date;
                        }
                        else{
                            date = from_date + ' - ' + to_date;
                        }

                        data.leave_date = date;
                        arr.push(data);
                    }
                    arr = arr.sort((a,b)=> new Date(b.created_date).getTime() - new Date(a.created_date).getTime())
                }
                this.setState({rowData:arr});
            }
        }
    }

    singleApprove=()=>{
        const {approval_id} = this.state;
        Swal.fire({
            title:IMLocalized('approving_leave'),
            input:'textarea',
            text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!'),
            showCancelButton:true,
        })
        .then(result=>{
            if('value' in result){
                let comment = result.value;
                this.props.calendar_leave_manager_approve([approval_id],true,comment);
            }
        })
    }

    singleReject=()=>{
        const {approval_id} = this.state;
        Swal.fire({
            title:IMLocalized('rejecting_leave'),
            input:'textarea',
            text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!'),
        })
        .then(result=>{
            if('value' in result){
                let comment = result.value;
                this.props.calendar_leave_manager_approve([approval_id],false,comment);
            }
        })
    }

    filterData=()=>{
        const {leave_checkbox,defaultlist,status_option } = this.state;
        let list = defaultlist;

        let leavedata = leave_checkbox.filter(function(item){
            return item.check === true
        })
        
      
        let arr = [];
        let detail = [];
        if(leavedata.length !== 0){
            for(let i=0;i<leavedata.length ;i++){
                const id = leavedata[i].id;
                const data = list.filter(function(item){
                    return item.leave_setting.id === id
                })
                arr.push(data);
            }
           
            list = arr.flat();
            if(status_option === 'reject'){
                detail = list.filter(function(item){
                    return item.status === 'REJECTED'
                })
            }
            else if(status_option === 'pending'){
                detail = list.filter(function(item){
                    return item.status === 'PENDING'
                })
            }
            else if(status_option === 'approve'){
                detail = list.filter(function(item){
                    return item.status === 'APPROVED'
                })
            }
            else if(status_option === 'cancel'){
                detail = list.filter(function(item){
                    return item.status === 'CANCELLED'
                })
            }
            else if(status_option === 'consumed'){
                detail = list.filter(function(item){
                    return item.status === 'CONSUMED'
                })
            }
            else{
                detail = list;
            }
            this.setState({events:detail});
        }
        else{
            this.setState({events:[]});
        }
    }

    getLeaveCheckbox=()=>{
        const {leavelist} = this.props;
        let arr = [];
        if(leavelist.length !== 0){
            for(let i=0;i<leavelist.length;i++){
                const name = leavelist[i].name;
                const id = leavelist[i].id;
                const check = true;
                arr.push({name,id,check});
            }
            
        }
        this.setState({leave_checkbox:arr});
    }

    typeToggle=()=>{
        const toggle = !this.state.type_toggle;
        this.setState({type_toggle:toggle});
    }

    statusToggle=()=>{
        const toggle = !this.state.status_toggle;
        this.setState({status_toggle:toggle});
    }

    radioChange=(e)=>{
        const target = e.target;
        const value = target.value;
        this.setState({
            status_option:value
        })
    }

    handle_leave_type=(e,index)=>{
        Promise.resolve(this.leavecheckbox(e,index))
    }

    leavecheckbox=(e,index)=>{
        const target = e.target;
        const checked = target.checked;
        let leave_checkbox = this.state.leave_checkbox;
        const new_leave_checkbox = leave_checkbox.map(item=>
            item.id === index
            ?{...item,check:checked}
            :item
        )
        let count = 0 ;
        for(let i=0;i<new_leave_checkbox.length ;i++){
            const check = new_leave_checkbox[i].check;
            if(check === false){
                count = count +1;
            }
        }
        if(count === 0){
            this.setState({type_all:true});
        }
        else{
            this.setState({type_all:false});
        }
        this.setState({leave_checkbox:new_leave_checkbox});
    }

    handleTypeAll=(e)=>{
        const target = e.target;
        const checked = target.checked;
        const name = target.name;
        this.setState({
            [name]:checked
        });
        if(name === 'type_all'){
            let leave_checkbox = this.state.leave_checkbox;
            for(let i=0;i<leave_checkbox.length ;i++){
                leave_checkbox[i].check = checked
            }
            this.setState({leave_checkbox});
        }
    }

    toHome=()=>{
        this.context.addItem('1','dashboard');
    }

    handleRadio=(e)=>{
        const target = e.target;
        const name = target.name;
        const value = target.value;
        let data = this.state.option_data;
        data[name].default = value;
        this.setState({option_data:data});
        this.getLeaveListOption();
    }

    getLeaveListOption=()=>{
        let leave_options = this.state.option_data;
        let ttbody=[];
        let ttable = '';
        if(leave_options.length !==0){
            for(let i=0;i<leave_options.length;i++){
                let date = this.getDateFormat(leave_options[i].date);
                let options = leave_options[i].options;
                let vvalue = leave_options[i].default;
                let tbutton=[];
                for(let x=0;x<options.length;x++){
                    let name = 'custom'+i+'Radio'+x;
                    let display = IMLocalized(options[x].value);
                    let value = options[x].value;
                    if(value === vvalue){
                        tbutton.push(
                            <div className="custom-control custom-radio mr-3" key={name}>
                                <input name={i} className="custom-control-input" id={name} type="radio" value={value} checked onClick={this.handleRadio} />
                                <label className="custom-control-label" htlmFor={name}>{display}</label>
                            </div>)
                    }
                    else{
                        tbutton.push(
                            <div className="custom-control custom-radio mr-3" key={name}>
                                <input name={i} className="custom-control-input" id={name} type="radio" value={value} onClick={this.handleRadio} />
                                <label className="custom-control-label" htmlFor={name}>{display}</label>
                            </div>)
                    }
                }
                ttbody.push(
                    <tr>
                        <td>{date}</td>
                        <td>
                            <div className="d-flex">
                                {tbutton}
                            </div>
                        </td>
                    </tr>
                )
            }
            ttable=(
                <Table size="sm" className="table-bordered" responsive>
                    <thead className="thead-light">
                        <tr>
                            <th>{IMLocalized('leave_date')}</th>
                            <th>{IMLocalized('option')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ttbody}
                    </tbody>
                </Table>
            )
        }
        this.setState({load_table:ttable});
    }

    getId(){
        let data = this.props.data;
        if(data.length !== 0){
            let id = data.id;
            this.setState({id})
        }
    }

    apply_Leave=()=>{
        const leave_entitlement = this.state.leave_entitlement;
        const remarks = this.state.remarks;
        const approval = this.state.approval;
        const file = this.state.apply_file;
        const min = this.state.min_image;
        const max = this.state.max_images;
        let require;
        let leave_options =[];
        if(approval === true){
            require = false;
        }
        else{
            require = true;
        }

        let data = this.state.option_data;
        for(let i=0;i<data.length;i++){
            let date = data[i].date;
            let day = data[i].default;
            leave_options.push({date,day});
        }
        
        if(file.length <= max && file.length>= min){
            this.props.leave_calendar_user_apply_leave_self1(leave_options,require,file,leave_entitlement,remarks);
        }
        else{
            this.popError(`${IMLocalized('must_upload_number')} ${min} ${IMLocalized('to')} ${max} ${IMLocalized('please_upload_number')}`)
            this.setState({err_images:`* ${IMLocalized('must_upload_number')} ${min} ${IMLocalized('to')} ${max} ${IMLocalized('please_upload_number')}`  })
        }
        
    }

    cancelApply=()=>{
        this.setState({apply_toggle:false});
        this.setState({start_date:null});
        this.setState({end_date:null});
        this.setState({err_select_date:''});
        this.setState({startDate:null});
        this.setState({endDate:null});
        this.setState({step_apply:0});
        this.setState({load_table:''});
        this.setState({approval:false});
        this.setState({apply_file:[]});
        this.setState({remarks:''});
        this.setState({err_remarks:''});
        this.setState({leave_setting:''});
        this.setState({err_leave_setting:''})
        this.setState({min_image:''});
        this.setState({max_images:''});
    }

    getAvailableOption=()=>{
        const {leavelist,availablelist} = this.props;
        if(availablelist.length !== 0){
            const detail = availablelist.map((item,index)=>{
                const leave_setting = item.leave_setting;
                const leave_detail = leavelist.filter(function(item){
                    return item.id === leave_setting
                })
                let name = '';
                if(leave_detail.length !== 0){
                    name = leave_detail[0].name;
                }
                return <option value={leave_setting} key={index}>{name}</option>
            })
            this.setState({available_option:detail});
        }
        const arr1= availablelist.map((item)=>{
            item.group = 0;
            return item;
        })
        this.setState({approval_group:arr1});
    }

    loadDay=()=>{
        let employee = this.state.id;
        let leave_setting = this.state.leave_setting;
        let from_date = this.state.start_date;
        let to_date = this.state.end_date;
        if(from_date !== null && leave_setting !== ''){
            if(to_date === null || to_date === ''){
                to_date = from_date;
            }
            this.setState({step_apply:1});
            this.props.leave_calendar_get_self_available_leave_options1(employee,leave_setting,from_date,to_date);
        }
        else{
            if(from_date === null){
                this.setState({err_select_date:IMLocalized('leave_date_required')});
            }
            if(leave_setting === ''){
                this.setState({err_leave_setting:IMLocalized('leave_type_required')});
            }
        }
    }

    onDatesChange({startDate,endDate}){
        this.setState({startDate,endDate})
        let start_date = null;
        if(startDate !== null){
            let startdate =startDate._d;
            let startmonth = (startdate.getMonth()+1);
            if(startmonth <10){
                startmonth = '0'+startmonth;
            }
            let startday = startdate.getDate();
            if(startday<10){
                startday = '0'+startday
            }
            start_date = startdate.getFullYear()+'-' + startmonth+'-' +startday
           
        }
        
        let end_date = null;
        if(endDate !== null){
            let enddate = endDate._d;
            let endmonth =(enddate.getMonth()+1);
            if(endmonth <10){
                endmonth = '0'+endmonth;
            }
            let endday = enddate.getDate();
            if(endday<10){
                endday = '0'+endday
            }
            end_date = enddate.getFullYear()+'-'+endmonth+'-'+endday
        }
        this.setState({end_date});
        this.setState({start_date});
        this.setState({err_select_date:''});
        this.setState({load_table:''});
        this.setState({step_apply:0});
    }

    onFocusChange=(focusedInput)=>{
        this.setState({focusedInput})
    }

    pendingReject=(boolean)=>{
        const data = gridOptions.api.getSelectedRows();
        if(data.length !== 0){
            let arr = [];
            for(let i=0;i<data.length;i++){
                const id = data[i].id;
                arr.push(id);
            }

            let title1 = '';
            if(boolean === false){
                title1 = IMLocalized('rejecting_leave')
            }
            else{
                title1 = IMLocalized('approving_leave')
            }

            Swal.fire({
                title:title1,
                input:'textarea',
                text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
                showCancelButton:true,
                cancelButtonText:IMLocalized('no!'),
                confirmButtonText:IMLocalized('yes!')
            })
            .then(result=>{
                if('value' in result){
                    let comment = result.value;
                    this.props.calendar_leave_manager_approve(arr,boolean,comment);
                }
            })
        }
        else{
            let tt = IMLocalized('select_leave_to_reject');
            if(boolean === true){
                tt = IMLocalized('select_leave_to_approve');
            }
            Swal.fire({
                title:tt,
                type:'warning',
                showConfirmButton:true,
                confirmButtonText:IMLocalized('ok!')
            })
        }
        
    }

    getEvent=()=>{
        const {list} = this.props;
        const leaves = list.leaves;
        const approval_leaves = list.approval_leaves;
        this.setState({approval_leaves});
        if(list.length !== 0){
            if(leaves.length !== 0){
                const detail = leaves.map((item)=>{
                    const id = item.id;
                    const status = item.status;
                    const leave_setting = item.leave_setting;
                    const start_date = item.from_date;
                    const employee = item.employee;
                    const employee_name = employee.name;
    
                    let backgroundColor = leave_setting.color;
                    const leave_name = leave_setting.name;
                    
                    if(status === 'PENDING'){
                        // backgroundColor = pendingcolor;
                        backgroundColor = this.state.color_pending;
                    }
                    else if (status ==='APPROVED'){
                        backgroundColor = this.state.color_approve;
                    }
                    else if(status === 'REJECTED'){
                        backgroundColor = this.state.color_reject;
                    }
                    else if(status=== 'CANCELLED'){
                        backgroundColor = this.state.color_cancel;
                    }
                    else if(status === 'CONSUMED'){
                        backgroundColor = this.state.color_consumed;
                    }
    
                    const enddate = new Date(item.to_date);
                    enddate.setDate(enddate.getDate() +1);
                    let endday = enddate.getDate();
                    let endmonth = (enddate.getMonth()+1);
                    if(endday<10){
                        endday = '0'+endday;
                    }
                    if(endmonth <10){
                        endmonth = '0'+endmonth;
                    }
                    const end_date = enddate.getFullYear()+'-'+endmonth+'-'+endday;
                    const title = employee_name + ' - ' + leave_name;
                    const hello = item.id;
                    const arr = {leave_setting,id,hello,title,backgroundColor,start:start_date,end:end_date,status};
                    return arr
                })
                this.setState({defaultlist:detail});
                
            }
            else{
                this.setState({defaultlist:[]})
            }
        }
        
    }

    getColorDetail=()=>{
        const{colorlist} = this.props;
        if(colorlist !== undefined && colorlist.length !== 0){
            const data =colorlist.data;
            const color_pending = data.PENDING;
            const color_approve = data.APPROVED;
            const color_reject = data.REJECTED;
            const color_cancel = data.CANCELLED;
            const color_consumed = data.CONSUMED;
            this.setState({color_approve});
            this.setState({color_consumed});
            this.setState({color_reject});
            this.setState({color_cancel});
            this.setState({color_pending});
        }
    }

    createCalendar=(arr,length)=>{
        calendar = new Calendar(this.refs.calendar,{
            plugins:[interaction,dayGridPlugin],
            defaultView:'dayGridMonth',
            selectable:true,
            locale:localStorage.getItem('language'),
            selectHelper:true,
            height:700,
            editable:false,
            events:this.state.events,
            holidays:this.state.holiday_arr,
            dayRender:function(date,info){
                if(length !== 0){
                    for(let i=0;i<length;i++){
                        let newdate = new Date(arr[i]);
                        if(date.date.getFullYear() === newdate.getFullYear() && date.date.getMonth() === newdate.getMonth() && date.date.getDate() === newdate.getDate()){
                            date.el.style.backgroundColor = '#30d5c8'
                        }
                    }
                }
            },
            select:info=>{
                this.setState({
                    startDate:moment(info.startStr),
                    start_date:info.startStr,
                    apply_toggle:true,
                })
            },
            eventClick:({event})=>{
              
                let id = event.extendedProps.hello;
                this.handleView(id);
            }
        })
        calendar.render();
        this.setState({currentDate:calendar.view.title});
    }

    getCalendar=()=>{
        const {leave_checkbox} = this.state;
        let button_group = [];
        let button_final = [];
        if(leave_checkbox.length !== 0){
            for(let i=0;i<leave_checkbox.length ;i++){
                const name = leave_checkbox[i].name;
                const id = leave_checkbox[i].id;
                const check = leave_checkbox[i].check;
                button_group.push(
                    <div className="custom-control custom-checkbox ml-2" key={i}>
                        <input type="checkbox" id={'calendarfiltertype3'+i} className="custom-control-input" checked={check} onChange={(e)=>this.handle_leave_type(e,id)} />
                        <label className="custom-control-label" htmlFor={'calendarfiltertype3' +i}>{name}</label>
                    </div>
                )
            }

            button_final=(
                <>
                    <div className="custom-control custom-checkbox ml-2">
                        <input type="checkbox" id='calendarfiltertypeall3' className="custom-control-input" name="type_all" checked={this.state.type_all} onChange={this.handleTypeAll}  />
                            <label className="custom-control-label" htmlFor='calendarfiltertypeall3'>{IMLocalized('select_all')}</label>
                    </div>
                    <DropdownItem divider></DropdownItem>
                    {button_group}
                </>
            )
        }
        const table= (
            <Card className="card-calendar">
                <CardHeader className="bg-info">
                    <Row>
                        <Col lg="4">
                            <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0 mr-1">
                                {this.state.currentDate}
                            </h6>
                        </Col>
                        <Col className="mt-3 mt-lg-0 text-lg-right" lg="8">
                            <Button className="fullcalendar-btn-prev btn-neutral" color="default" size="sm" onClick={()=>this.prevBtn()}>
                                <i className="fas fa-angle-left"  />
                            </Button>
                            <Button className="fullcalendar-btn-today btn-neutral" color="default" size="sm" onClick={()=>this.currentBtn()}>
                                {IMLocalized('current')}
                            </Button>
                            <Button className="fullcalendar-btn-next btn-neutral" color="default" size="sm" onClick={()=>this.nextBtn()} >
                                <i className="fas fa-angle-right" />
                            </Button>
                            <Button className="btn-neutral" color='default' data-calendar-view='month' onClick={()=> this.changeView('dayGridMonth')} size="sm">
                                {IMLocalized('MONTH')}
                            </Button>
                            <Button className="btn-neutral" color="default" data-calendar-view="basicWeek" onClick={()=> this.changeView('dayGridWeek')} size="sm">
                                {IMLocalized('WEEK')}
                            </Button>
                            <Button className="btn-neutral" color="default" data-calendar-view="basicDay" onClick={()=> this.changeView('dayGridDay')} size="sm">
                                {IMLocalized('DAY')}
                            </Button>
                            {/* <Button className="btn-neutral" color="default" onClick={this.viewHoliday} size="sm">
                                Holiday List
                            </Button> */}
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col>
                            <Dropdown size="sm" direction="down" isOpen={this.state.type_toggle} toggle={this.typeToggle}>
                                <DropdownToggle className="bg-neutral text-primary" caret aria-expanded={this.state.type_toggle}>
                                    {IMLocalized('filter_by_leave_type')}
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-scroll">
                                    {button_final}
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown size="sm" direction="down" isOpen={this.state.status_toggle} toggle={this.statusToggle}>
                                <DropdownToggle  className="bg-neutral text-primary" caret aria-expanded={this.state.status_toggle} >
                                    {IMLocalized('filter_by_status')}
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-scroll">
                                    <div className="custom-control custom-radio ml-2 mb-1">
                                        <input className="custom-control-input" id="leavecalendarstatus1Radio1" name="leavecalendarstatus1Radio" type="radio" value="all" checked = {this.state.status_option ==='all'} onChange={this.radioChange}/>
                                        <label className="custom-control-label" htmlFor="leavecalendarstatus1Radio1">{IMLocalized('ALL')}</label>
                                    </div>
                                    <div className="custom-control custom-radio ml-2 mb-1">
                                        <input className="custom-control-input" id="leavecalendarstatus1Radio2" name="leavecalendarstatus1Radio" type="radio" value="pending" checked = {this.state.status_option ==='pending'} onChange={this.radioChange}/>
                                        <label className="custom-control-label" htmlFor="leavecalendarstatus1Radio2">{IMLocalized('PENDING')}</label>
                                    </div>
                                    <div className="custom-control custom-radio ml-2 mb-1">
                                        <input className="custom-control-input" id="leavecalendarstatus1Radio3" name="leavecalendarstatus1Radio" type="radio" value="approve" checked = {this.state.status_option ==='approve'} onChange={this.radioChange}/>
                                        <label className="custom-control-label" htmlFor="leavecalendarstatus1Radio3">{IMLocalized('APPROVED')}</label>
                                    </div>
                                    <div className="custom-control custom-radio ml-2 mb-1">
                                        <input className="custom-control-input" id="leavecalendarstatus1Radio4" name="leavecalendarstatus1Radio" type="radio" value="reject" checked = {this.state.status_option ==='reject'} onChange={this.radioChange}/>
                                        <label className="custom-control-label" htmlFor="leavecalendarstatus1Radio4">{IMLocalized('REJECTED')}</label>
                                    </div>
                                    <div className="custom-control custom-radio ml-2 mb-1">
                                        <input className="custom-control-input" id="leavecalendarstatus1Radio5" name="leavecalendarstatus1Radio" type="radio" value="cancel" checked = {this.state.status_option ==='cancel'} onChange={this.radioChange}/>
                                        <label className="custom-control-label" htmlFor="leavecalendarstatus1Radio5">{IMLocalized('CANCELLED')}</label>
                                    </div>
                                    <div className="custom-control custom-radio ml-2 mb-1">
                                        <input className="custom-control-input" id="leavecalendarstatus1Radio6" name="leavecalendarstatus1Radio" type="radio" value="consumed" checked = {this.state.status_option ==='consumed'} onChange={this.radioChange}/>
                                        <label className="custom-control-label" htmlFor="leavecalendarstatus1Radio6">{IMLocalized('CONSUMED')}</label>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                    </Row>
                </CardHeader>
                <div className="calendar" ref="calendar"></div>
            </Card>
        )

        return table;
    }

    open=(index)=>{
        this.setState({imgtoggle:true});
        this.setState({activeImg:index});
    }

    close=()=>{
        this.setState({imgtoggle:false});
    }

    prevBtn = ()=>{
        calendar.prev();
        this.setState({
            currentDate:calendar.view.title
        })
    }

    handleView=(id)=>{
        this.props.get_calendar_approvals_leave_detail1(id);
    }

    changeView = newView =>{
        calendar.changeView(newView);
        this.setState({
            currentDate:calendar.view.title
        })
    }

    nextBtn = ()=>{
        calendar.next();
        this.setState({
            currentDate:calendar.view.title
        })
    }

    currentBtn=()=>{
        calendar.today();
        this.setState({
            currentDate:calendar.view.title
        })
    }

    getDateFormat=(date)=>{
        const date1 = moment(date,'YYYY-MM-DD');
        const new_date = date1.format('DD/MM/YYYY');
        return new_date;
    }

    closeModal=()=>{
        this.setState({detail_toggle:false});
        this.setState({detail_id:''});
        this.setState({detail_img:[]});
        this.setState({detail_list:null});
        this.setState({approval_id:''});
        this.setState({has_action:false});
        this.setState({imglist:[]});
    }

    modalDetail=()=>{
        const {detail_list,imglist}= this.state;
        const {leave_status} = this.props;
        let img_table ='';
        if(imglist.length !== 0){
            const img1 = imglist.map((item,index)=>(
                <Col lg="4" className="pt-1">
                <div className="image-item mr-2" key={index} onClick={this.open.bind(this,index)} >
                    <div className="image-inner">
                        <img src={item.src} className="img-thumbnail" alt={"This is Leave" +index}></img>
                    </div>
                </div>
                </Col>
            ))

            img_table = (
                <Row>
                    {img1}
                </Row>
            )
        }
        else{
            img_table = IMLocalized('no_image');
        }
        let table= '';
        let days_table = '';
        let approval_table = '';
        if(detail_list !== null){
            const days = detail_list.days;
            const employee_number = detail_list.employee.employee_number;
            const name = detail_list.employee.name;
            const leave_type = detail_list.leave_setting.name;
            const approvers = detail_list.approvers;
            const amount = detail_list.amount;
            if(approvers.length !== 0){
                let arr_approvers = [];
                for(let i=0;i<approvers.length ;i++){
                    const name = approvers[i].employee.name;
                    const is_active = approvers[i].is_active;
                    const daa = approvers[i].delegation_application_approval;
                    let string = `(${IMLocalized('delegation')})`;
                    if(is_active === true){
                        if(daa !== null){
                            arr_approvers.push(<div><span>{name} {string}</span></div>)
                        }
                        else{
                            arr_approvers.push(<div><span>{name}</span></div>)
                            
                        }
                    }
                    
                }
                if(arr_approvers.length !== 0){
                    approval_table = (
                        <tr>
                            <td className="w-25">{IMLocalized('approval_pending')}</td>
                            <td>
                                {arr_approvers}
                            </td>
                        </tr>
                    )
                }
                
            }
            const status = detail_list.status;
            let comments = detail_list.comments;
            let comment_string = [];
            if(comments.length !== 0){
                for(let i =0;i<comments.length;i++){
                    const word = comments[i].comment;
                    const employee_name = comments[i].employee_name;
                    const text1 = <div className="d-flex"><h5>{employee_name}</h5> <span className="pl-2">: {word}</span></div>;
                    
                    comment_string.push(text1)
                }
            }
            else{
                comment_string = '---'
            }

            const status_detail  = leave_status.filter(function(item){
                return item.value === status;
            })
            let status_name = '';
            if(status_detail.length !== 0){
                status_name = status_detail[0].display;
            }
            if(days.length !== 0){
                let ttbody = [];
                for(let i =0;i<days.length;i++){
                    const date = this.getDateFormat(days[i].date);
                    const day = IMLocalized(days[i].day);
                    
                    ttbody.push(<tr><td>{date}</td><td>{day}</td></tr>)
                }
    
                days_table = (
                    <Table size="sm" className="align-items-center bg-white table-bordered" responsive>
                        <thead className="thead-light">
                            <tr >
                                <th className="w-25">{IMLocalized('leave_date')}</th>
                                <th>{IMLocalized('option')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ttbody}
                        </tbody>
                    </Table>
                )
            }

            let modal_btn ='';
            if(this.state.has_action){
                modal_btn =(
                    <>
                        <button onClick={this.singleApprove} className="btn btn-primary btn-sm">{IMLocalized('approve')}</button>
                        <button onClick={this.singleReject} className="btn btn-danger btn-sm">{IMLocalized('reject')}</button>
                    </>
                )
            }

            table=(
                <Modal size="lg"  isOpen={this.state.detail_toggle}>
                    <ModalHeader toggle={this.closeModal}>{IMLocalized('leave_detail')}</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                {days_table}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table size="sm" className="table-bordered">
                                    <tbody>
                                        <tr>
                                            <td className="w-25">{IMLocalized('employee_number')}</td>
                                            <td>{employee_number}</td>
                                        </tr>
                                        <tr>
                                            <td className="w-25">{IMLocalized('employee_name')}</td>
                                            <td>{name}</td>
                                        </tr>
                                        <tr>
                                            <td className="w-25">{IMLocalized('leave_type')}</td>
                                            <td>{leave_type}</td>
                                        </tr>
                                        <tr>
                                            <td className="w-25">{IMLocalized('status')}</td>
                                            <td>{status_name}</td>
                                        </tr>
                                        <tr>
                                            <td className="w-25">{IMLocalized('comment')}</td>
                                            <td>{comment_string}</td>
                                        </tr>
                                        <tr>
                                            <td className="w-25">{IMLocalized('leave_amount')}</td>
                                            <td>{amount}</td>
                                        </tr>
                                        <tr>
                                            <td className="w-25">{IMLocalized('image')}</td>
                                            <td>
                                                {img_table}
                                            </td>
                                        </tr>
                                        {approval_table}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Viewer activeIndex={this.state.activeImg} zIndex={1060} visible={this.state.imgtoggle} onClose={this.close} images={this.state.imglist} />
                    </ModalBody>
                    <ModalFooter>
                        {modal_btn}
                        <button className="btn btn-danger btn-sm" onClick={this.closeModal}>{IMLocalized('close')}</button>
                    </ModalFooter>
                </Modal>
            )
        }

        return table;
    }

    handleInput=(e)=>{
        const target =e.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]:value
        })

        if(name === 'remarks'){
            this.setState({err_remarks:''});
        }

        if(name === 'leave_setting'){
            this.setState({err_leave_setting:''});
        }
    }

    fileInput=(e)=>{
        const file = e.target.files
      
        this.setState({apply_file:file});
        this.setState({err_images:''});
    }

    onCellFocused=(params)=>{
        if(params !== null){
            if(params.column !== null){
                if(params.column.colDef !== null){
                    if(params.column.colDef.field !== 'select'){
                        const focusedCell = gridOptions.api.getFocusedCell();
                        
                        const cellValue = gridOptions.api.getDisplayedRowAtIndex(focusedCell.rowIndex)
                        
                        const id = cellValue.data.leave.from_date;
                        calendar.gotoDate(id);
                        calendar.changeView('dayGridWeek');
                        this.setState({
                            currentDate:calendar.view.title
                        })
                        this.refs['calendarview'].scrollIntoView({block:'start',behavior:'smooth'});
                    }
                } 
            }  
        }
    }

    modalApply=()=>{

        let step_apply = this.state.step_apply;
        let modal_apply = '';
        let close_btn =<button onClick={this.cancelApply} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>;
        if(this.props.isLoading10 || this.props.isLoading11 ){
            close_btn ='';
            modal_apply = <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>
        }
        else{
            if(step_apply === 0){
                modal_apply = <button onClick={this.loadDay} className="btn btn-primary btn-sm">{IMLocalized('load_day')}</button>
            }
            else if (step_apply === 1){
                modal_apply = <button onClick={this.apply_Leave} className="btn btn-primary btn-sm">{IMLocalized('apply')}</button>
            }
    
        }

        let image_table = '';
        const {min_image,max_images} = this.state;
        if(min_image !== '' && max_images !== ''){
            if(min_image !== 0 || max_images !== 0){
                image_table=(
                    <Row className="mt-3">
                        <Col lg="3">
                            <label>{IMLocalized('upload_image')}</label>
                        </Col>
                        <Col lg="9">
                            <FormGroup>
                                <label className="form-control-label">
                                    <input type="file" multiple onChange={this.fileInput} accept='image/*' ></input>
                                    <div className="text-danger">
                                        <small>{this.state.err_images}</small>
                                    </div>
                                </label>
                            </FormGroup>
                        </Col>
                    </Row>
                )
            } 
        }

        let approval_table = '';
        const {leave_approvers,leave_exceptions,leave_setting} = this.state;
        if(leave_approvers.length !== 0 && leave_exceptions.length !== 0 && leave_setting !== ''){
            const approval_detail = leave_exceptions.filter(function(item){
                return item.leave_setting === parseInt(leave_setting)
            })
            let arr_approvers = [];
            if(approval_detail.length !== 0){
                let group = approval_detail[0].group;
                const approvers = leave_approvers[group];
                
                for(let j=0;j<approvers.length;j++){
                    const data = approvers[j];
                    for(let x=0;x<data.length;x++){
                        const employee = data[x].name;
                        const ordering = j+1;
                        arr_approvers.push(
                            <div><span>{employee} -  ({IMLocalized('level')} : {ordering})</span></div>
                        )
                    }
                } 
            }

            approval_table = (
                <Row className="pt-2">
                    <Col lg="3">
                        <label>{IMLocalized('leave_approval')}</label>
                    </Col>
                    <Col lg="9">    
                        <Table size="sm" className="table-bordered" responsive>
                            <thead >
                                <tr>
                                    <th>{IMLocalized('approval_level')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {arr_approvers}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )
        }
            
        const table=(
            <Modal size="lg" isOpen={this.state.apply_toggle}>
                <ModalHeader toggle={this.cancelApply}>{IMLocalized('apply_leave')}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg="3">
                            <label>{IMLocalized('leave_date')}</label>
                        </Col>
                        <Col lg="9">
                        <DateRangePicker 
                            startDate={this.state.startDate}
                            isOutsideRange={()=>false}
                            small = {true}
                            startDateId="unique_start_date_id"
                            endDate={this.state.endDate}
                            endDateId="unique_end_date_id"
                            onDatesChange={this.onDatesChange.bind(this)}
                            focusedInput={this.state.focusedInput}
                            displayFormat={this.state.dateFormat}
                            minimumNights={0}
                            onFocusChange={this.onFocusChange} />
                            <div style={{fontSize:'12px',color:'red'}}>{this.state.err_select_date}</div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col lg="3">
                            <label>{IMLocalized('leave_type')}</label>
                        </Col>
                        <Col lg="9">
                            <Input size="sm" type="select" name="leave_setting" value={this.state.leave_setting} onChange={this.handleInput} invalid={this.state.err_leave_setting.length>0} >
                                <option></option>
                                {this.state.available_option}
                            </Input>
                            <FormFeedback>{this.state.err_leave_setting}</FormFeedback>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col lg="3">
                            <label>{IMLocalized('comment')}</label>
                        </Col>
                        <Col lg="9">
                            <Input size="sm" type="textarea" name="remarks" value={this.state.remarks} onChange={this.handleInput} invalid={this.state.err_remarks.length>0}/>
                            <FormFeedback>{this.state.err_remarks}</FormFeedback>
                        </Col>
                    </Row>
                    {image_table}
                    {approval_table}
                    <Row className="mt-2">
                        <Col>
                            {this.state.load_table}
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {modal_apply}
                    {close_btn}
                </ModalFooter>
            </Modal>
        )

        return table;
    }

    getColor=()=>{
        const table1 = (
            <div style={{position:'sticky',top:'25px'}}>
                <Row className="pt-3 bg-white">
                    <Col>
                        <div className="bor-bottom">
                            <h5>{IMLocalized('legend')}</h5>
                        </div>
                        <div className="pt-2">
                            <div className="d-flex">
                                <i className="fas fa-square mr-2" style={{color:this.state.color_pending}} />
                                <h5>{IMLocalized('pending_leave')}</h5>
                            </div>
                            <div className="d-flex">
                                <i className="fas fa-square mr-2" style={{color:this.state.color_approve}} />
                                <h5>{IMLocalized('approved_leave')}</h5>
                            </div>
                            <div className="d-flex">
                                <i className="fas fa-square mr-2" style={{color:this.state.color_reject}} />
                                <h5>{IMLocalized('rejected_leave')}</h5>
                            </div>
                            <div className="d-flex">
                                <i className="fas fa-square mr-2" style={{color:this.state.color_consumed}} />
                                <h5>{IMLocalized('consumed_leave')}</h5>
                            </div>
                            <div className="d-flex">
                                <i className="fas fa-square mr-2" style={{color:this.state.color_cancel}} />
                                <h5>{IMLocalized('cancelled_leave')}</h5>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )

        return table1;
    }

    render(){

        let test = '';
        if(this.props.isLoading || this.props.isLoading1 || this.props.isLoading2 || this.props.isLoading3 || this.props.isLoading4 || this.props.isLoading5 || this.props.isLoading6 || this.props.isLoading7 || this.props.isLoading8 || this.props.isLoading9 || this.props.isLoading12){
            test = <OverlayLoading />
        }

        return(
            <div>
                <Container fluid>
                    <Row className="bg-white border-bottom">
                        <Col className="p-2">
                            <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                                <BreadcrumbItem><a href="/#" onClick={this.toHome}>{IMLocalized('home')}</a></BreadcrumbItem>
                                <BreadcrumbItem active>{IMLocalized('leave_calendar1')}</BreadcrumbItem>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
                {test}
                <Container fluid>
                    {this.state.rowData.length !== 0 &&
                    <Row className="pt-2 justify-content-center">
                        <Col lg="11">
                            <Row>
                                <Col>
                                    <h3>{IMLocalized('pending_leave_list')}</h3>
                                </Col>
                                <Col className="col-auto">
                                    {this.state.rowData.length !== 0 && <><button className='btn btn-primary btn-sm' onClick={()=>this.pendingReject(true)}>{IMLocalized('approve')}</button><button className='btn btn-danger btn-sm' onClick={()=>this.pendingReject(false)}>{IMLocalized('reject')}</button></>}
                                </Col>
                            </Row>
                            <div className="d-flex justify-content-center">
                                <div className="ag-theme-balham" style={{height:'400px', width:'100%' }}>
                                    <AgGridReact
                                    columnDefs={this.state.columnDefs}
                                    gridOptions={gridOptions}
                                    rowData={this.state.rowData}
                                    defaultColDef={this.state.defaultColDef}
                                    suppressDragLeaveHidesColumns={true}
                                    suppressSizeToFit={true}
                                    suppressColumnMoveAnimation={false}
                                    onGridReady={this.onGridReady}
                                    overlayNoRowsTemplate = {this.state.noRowTemplate}
                                    onCellFocused={this.onCellFocused}
                                    >
                                    </AgGridReact>
                                </div>
                            </div>
                        </Col>
                    </Row>}
                    <Row className="pt-4 justify-content-center">
                        <Col lg="11">
                            <Row>
                                <Col lg="10">
                                    <div ref="calendarview">
                                        {this.getCalendar.call(this)}
                                    </div>
                                </Col>
                                <Col lg="2">
                                    {this.getColor.call(this)}
                                </Col>
                            </Row>
                            
                        </Col>
                    </Row>
                    {this.modalDetail.call(this)}
                    {this.modalApply.call(this)}
                </Container>
            </div>
        )
    }
}
function mapStateToProps(state){
    return{
        list:state.manager_leave_list.data,
        list1:state.manager_leave_list.data1,
        emplist:state.get_employee_list.data,
        leavelist:state.get_leave_list.data,
        availablelist:state.user_get_available_leave.data,
        data:state.user_self.data,
        leave_status:state.dropdown_list.leave_status,
        colorlist:state.get_general_color.data,
        optionlist:state.dropdown_list.schedule_leave_options,
        alllist:state.dropdown_list.schedule_all_options,
        pendingcolor:state.get_leave_pending_color.data,
        dropdownlist:state.dropdown_list.data,
        holidaylist:state.user_holiday_list.data,
        picture:state.calendar_approvals_get_leave_picture1.data,
        option_data:state.leave_calendar_get_self_available_leave_options1.data,
        getoption_error:state.leave_calendar_get_self_available_leave_options1.errors,
        applyerr:state.leave_calendar_user_apply_leave_self1.errors,
        apply_success:state.leave_calendar_user_apply_leave_self1.data,
        daterange:state.general_daterange.data,
        selflist:state.user_apply_leave_list.data,
        approve_success:state.calendar_leave_manager_approve.data,
        detail:state.get_calendar_approvals_leave_detail1.data,
        detail_errors:state.get_calendar_approvals_leave_detail1.errors,
        isLoading:state.user_get_all_leave_list.isLoading,
        isLoading1:state.get_employee_list.isLoading,
        isLoading2:state.get_leave_list.isLoading,
        isLoading3:state.user_get_available_leave.isLoading,
        isLoading4:state.user_self.isLoading,
        isLoading5:state.dropdown_list.isLoading,
        isLoading6:state.user_holiday_list.isLoading,
        isLoading7:state.general_daterange.isLoading,
        isLoading8:state.user_apply_leave_list.isLoading,
        isLoading9:state.calendar_leave_manager_approve.isLoading,
        isLoading10:state.leave_calendar_get_self_available_leave_options1.isLoading,
        isLoading11:state.leave_calendar_user_apply_leave_self1.isLoading,
        isLoading12:state.get_calendar_approvals_leave_detail1.isLoading,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        calendar_leave_manager_approve:(id,approve,remarks)=>{
            dispatch(calendar_leave_manager_approve(id,approve,remarks))
        },
        get_general_dashboard:()=>{
            dispatch(get_general_dashboard())
        },
        user_get_available_leave:()=>{
            dispatch(user_get_available_leave())
        },
        user_get_all_apply_leave_list:()=>{
            dispatch(user_get_all_apply_leave_list())
        },
        calendar_approvals_get_leave_picture1:(id)=>{
             dispatch(calendar_approvals_get_leave_picture1(id))
        },
        leave_calendar_get_self_available_leave_options1:(employee,leave_setting,from_date,to_date)=>{
            dispatch(leave_calendar_get_self_available_leave_options1(employee,leave_setting,from_date,to_date))
        },
        leave_calendar_user_apply_leave_self1:(leave_options,require,file,leave_entitlement,remarks)=>{
            dispatch(leave_calendar_user_apply_leave_self1(leave_options,require,file,leave_entitlement,remarks))
        },
        user_apply_leave_list:()=>{
            dispatch(user_apply_leave_list())
        },
        get_calendar_approvals_leave_detail1:(id)=>{
            dispatch(get_calendar_approvals_leave_detail1(id))
        },
        manager_leave_list:()=>{
            dispatch(manager_leave_list())
        }
    }
}
manager_calendar.contextType = NewMainContext;
export default connect(mapStateToProps, mapDispatchToProps)(manager_calendar);







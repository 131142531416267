import * as probation from '../action/get_employee_probation_list';


const initialState={
    isLoading:false,
    errors:[],
    data:[]
}

const get_employee_probation_list = (state= initialState,action)=>{
    switch(action.type){
        case probation.GETPROBATIONLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[],
                data:[]
            }
        case probation.GETPROBATIONLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                errors:[],
                data:action.payload.data,
            }
        case probation.GETPROBATIONLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_employee_probation_list;
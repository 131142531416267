import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Container, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import { IMLocalized } from '../../language/IMLocalized';
import { pay_period_list } from '../../data/pay_period_data';
import { get_payroll_period } from '../../action/get_payroll_period';
import OverlayLoading from '../loading_component/overlay_loading';


export default function PeriodDetail({language,setData}){

    const dispatch = useDispatch();
    const list = useSelector(state=>state.get_payroll_period.data);
    const isLoading = useSelector(state=>state.get_payroll_period.isLoading);
    const [ pay_period, setPayPeriod] = useState('');
    const [ end_day, setEndDay] = useState(null);
    const [ err_pay_period, setErrPayPeriod] = useState('');
    const [ err_end_day, setErrEndDay] = useState('');

    useEffect(()=>{
        dispatch(get_payroll_period());
    },[dispatch])

    useEffect(()=>{
        if(list != null){
            setPayPeriod(list.pay_period);
            setEndDay(list.end_day);
        }
    },[list])

    const onSubmit=()=>{
        if(pay_period !== ''){
            let valid = true;
            if(pay_period === 'CUSTOM' ){
                if(end_day === '' || end_day === null){
                    setErrEndDay(IMLocalized('end_day_is_required'));
                    valid = false;
                }
            }
            if(valid){
                setData({pay_period,end_day});
            }
        }
        else{
            if(pay_period === ''){
                setPayPeriod(IMLocalized('pay_period_is_required'))
            }
        }
    }

    return(
        <>
        {isLoading ? <OverlayLoading/>:
        <Container className='mt-2' fluid>
            <Row className='justify-content-center'>
                <Col lg="8">
                    <Card>
                        <CardHeader><h4 className='mb-0'>{IMLocalized('period_setting')}</h4></CardHeader>
                        <CardBody>
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='pay_period'>{IMLocalized('pay_period')}</label>
                                        <Input size="sm" id="pay_period" type="select" value={pay_period} invalid={err_pay_period.length >0} onChange={(e)=>{setPayPeriod(e.target.value); setErrPayPeriod('')}} >
                                            <option></option>
                                            {pay_period_list.map((item,index)=>{
                                                return <option value={item.value} key={index}>{language === 'zh' ? item.display_zh : item.display}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{err_pay_period}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                {pay_period === 'CUSTOM' && 
                                <Col lg="6">
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='end_day'>{IMLocalized('end_day')}</label>
                                        <Input size="sm" id="end_day" type="number" min="0" value={end_day} onChange={(e)=>{setEndDay(e.target.value); setErrEndDay('')}} invalid={err_end_day.length >0} />
                                        <FormFeedback>{err_end_day}</FormFeedback>
                                    </FormGroup>
                                </Col>}
                                {pay_period !== 'CUSTOM' &&
                                <Col lg="6" className='d-flex align-items-center'>
                                    <div>
                                        <small className="text-danger">* {IMLocalized('period_msg')}</small> 
                                    </div>
                                </Col>}
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <div className='create_btn_parent'>
                <i className='fas fa-arrow-circle-right text-success cursor-pointer create_btn' onClick={()=>onSubmit()}  />
            </div>
        </Container>}
        </>
    )
}
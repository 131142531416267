import * as massassign from '../../action/leave/mass_assign_leave_scheme';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const mass_assign_leave_scheme = (state=initialState,action) =>{
    switch(action.type){
        case massassign.MASSASSIGNLEAVESCHEME_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case massassign.MASSASSIGNLEAVESCHEME_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case massassign.MASSASSIGNLEAVESCHEME_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default mass_assign_leave_scheme;
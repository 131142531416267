import * as assignscheme1 from '../../action/leave/leave_assign_scheme1';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const leave_assign_scheme1 =(state=initialState,action)=>{
    switch(action.type){
        case assignscheme1.LEAVEASSIGNSCHEME1_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case assignscheme1.LEAVEASSIGNSCHEME1_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case assignscheme1.LEAVEASSIGNSCHEME1_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case assignscheme1.LEAVEASSIGNSCHEME1_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default leave_assign_scheme1
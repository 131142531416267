import myaxios from '../../axios';

export const VARIANCEAGGRIDHEADER2_REQUEST = 'VARIANCEAGGRIDHEADER2_REQUEST';
export const VARIANCEAGGRIDHEADER2_SUCCESS = 'VARIANCEAGGRIDHEADER2_SUCCESS';
export const VARIANCEAGGRIDHEADER2_FAILURE = 'VARIANCEAGGRIDHEADER2_FAILURE';


export const payroll_variance_get_aggridheader2 = (id)=>{
    return dispatch=>{
        dispatch({
            type:'VARIANCEAGGRIDHEADER2_REQUEST'
        });

        myaxios.get(`payroll/payroll/${id}/ReportGridHeader/`)
        .then((response)=>{
            dispatch({
                type:'VARIANCEAGGRIDHEADER2_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'VARIANCEAGGRIDHEADER2_FAILURE',
                payload:error
            })
        })
    }
}
import * as detail from '../../action/employee/get_hr_employee_all_leave_detail';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const get_hr_employee_all_leave_detail = (state=initialState,action)=>{
    switch(action.type){
        case detail.GETHREMPALLLEAVEDETAIL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case detail.GETHREMPALLLEAVEDETAIL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case detail.GETHREMPALLLEAVEDETAIL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case detail.GETHREMPALLLEAVEDETAIL_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
} 
export default get_hr_employee_all_leave_detail;
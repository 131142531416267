import myaxios from '../../axios';

export const GETCLAIMSCHEMELIST_REQUEST = 'GETCLAIMSCHEMELIST_REQUEST';
export const GETCLAIMSCHEMELIST_SUCCESS = 'GETCLAIMSCHEMELIST_SUCCESS';
export const GETCLAIMSCHEMELIST_FAILURE = 'GETCLAIMSCHEMELIST_FAILURE';

export const get_claim_scheme_list = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETCLAIMSCHEMELIST_REQUEST'
        });

        myaxios.get('claim/scheme/')
        .then((response)=>{
            dispatch({
                type:'GETCLAIMSCHEMELIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETCLAIMSCHEMELIST_FAILURE',
                payload:error
            })
        })
    }
}
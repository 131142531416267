import myaxios from '../../axios';
export const REQUEST_EMPADDRESS = 'REQUEST_EMPADDRESS';
export const GET_EMPADDRESS = 'GET_EMPADDRESS';
export const FAILURE_EMPADDRESS = 'FAILURE_EMPADDRESS';


export const get_employee_address = ()=>{
    return dispatch =>{
        dispatch({
            type: 'REQUEST_EMPADDRESS'
        });
        myaxios.get('employee/address/')
        .then((response)=>{
            dispatch({
                type: 'GET_EMPADDRESS',
                payload:response
            })
        })
        .catch((error) =>{
            dispatch({
                type: 'FAILURE_ADDRESS',
                payload: error,
                error:true
            })
        })
    }
}

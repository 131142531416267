import React, { useContext, useEffect, useState } from "react";
import EmployeeList from "../hr_employee/EmployeeList";
import EmployeeProfile from "./EmployeeProfile";
import EmployeeLeaveDetailView from "../hr_employee/EmployeeLeaveDetailView";
import EmployeeAllLeaveView from "../hr_employee/EmployeeAllLeaveView";
import EmployeeAwardList from "../hr_employee/EmployeeAwardList";
import { NewMainContext } from "../../../context/NewMainContext";


export default function EmployeeMain(){

    const [ step, setStep ] = useState(localStorage.getItem('leave_emp_step'));
    const [ employee_profile_id, setEmployeeProfileId ] = useState(localStorage.getItem('leave_emp_profile_id'));
    const [ employee_leave_detail_id, setEmployeeLeaveDetailId ] = useState(localStorage.getItem('leave_leave_detail_view_id'));
    const [ employee_leave_detail_leave, setEmployeeLeaveDetailLeave ] = useState(localStorage.getItem('leave_leave_detail_view_setting'));
    const [ employee_allleave_view, setEmployeeAllLeaveView ] = useState(localStorage.getItem('leave_allleave_view_id'));
    const [ employee_awardlist_id, setEmployeeAwardListId ] = useState(localStorage.getItem('leave_awardlist_id'));
    const { emplist_reset, setEmpListReset } = useContext(NewMainContext); 

    useEffect(()=>{
        if(emplist_reset === true){
            setStep('0');
            localStorage.setItem('leave_emp_step','0');
            setEmpListReset(false);
        }
    },[setEmpListReset, emplist_reset])

    const goProfile=(id)=>{
        setStep('1');
        localStorage.setItem('leave_emp_step','1');
        localStorage.setItem('leave_emp_profile_id',id);
        setEmployeeProfileId(id);
    }

    const toEmployeeList = (id) =>{
        setStep('0');
        localStorage.setItem('leave_emp_step',id);
    }

    const toViewAll = (id) =>{
        setStep('3');
        localStorage.setItem('leave_emp_step','3');
        localStorage.setItem('leave_allleave_view_id',id);
        setEmployeeAllLeaveView(id);
    }

    const toViewDetail=(id,leave_id)=>{
        setStep('2');
        setEmployeeLeaveDetailId(id);
        setEmployeeLeaveDetailLeave(leave_id);
        localStorage.setItem('leave_emp_step','2');
        localStorage.setItem('leave_leave_detail_view_id',id);
        localStorage.setItem('leave_leave_detail_view_setting',leave_id);
    }

    const toEmployeeProfile = (id) =>{
        setStep(id);
        localStorage.setItem('leave_emp_step',id);
    }

    const toAwardList = (id) =>{
        setStep('4');
        localStorage.setItem('leave_emp_step','4');
        localStorage.setItem('leave_awardlist_id',id);
        setEmployeeAwardListId(id);
    }


    return(
        <>
        {step === '0' && <EmployeeList goProfile={goProfile} />}
        {step === '1' && <EmployeeProfile id={employee_profile_id} toAwardList={toAwardList} toEmployeeList={toEmployeeList} toViewAll={toViewAll} toViewDetail={toViewDetail} setEmployeeProfileId={setEmployeeProfileId}  />}
        {step === '2' && <EmployeeLeaveDetailView id={employee_leave_detail_id} leave_setting={employee_leave_detail_leave} toEmployeeList={toEmployeeList} toEmployeeProfile={toEmployeeProfile}  />}
        {step === '3' && <EmployeeAllLeaveView id={employee_allleave_view} toEmployeeList={toEmployeeList} toEmployeeProfile={toEmployeeProfile} />}
        {step === '4' && <EmployeeAwardList id={employee_awardlist_id} toEmployeeList={toEmployeeList} toEmployeeProfile={toEmployeeProfile} />}
        </>
    )
}
import React, { useContext } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css'; 
import AppraisalGroupTable from './AppraisalGroupTable';
import AppraisalGroupCreate from './AppraisalGroupCreate';
import { NewMainContext } from '../../context/NewMainContext';


function AppraisalGroupList(){

    const { appraisal_group_list_step, addItem, setAppraisalGroupListStep } = useContext(NewMainContext);


    const toHome = ()=>{
        addItem('1','dashboard');
    }

    const setStep =(index)=>{
        setAppraisalGroupListStep(index);
    }

    return(
        <>
        {appraisal_group_list_step === 0 && <AppraisalGroupTable toHome={toHome} setStep={()=>setStep(1)} />}
        {appraisal_group_list_step === 1 && <AppraisalGroupCreate toHome={toHome} setStep={()=>setStep(0)} />}
        </>
    )
}
export default AppraisalGroupList;
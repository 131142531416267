import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const CREATESCHEME_REQUEST = 'CREATESCHEME_REQUEST';
export const CREATESCHEME_SUCCESS = 'CREATESCHEME_SUCCESS';
export const CREATESCHEME_FAILURE = 'CREATESCHEME_FAILURE';
export const CREATESCHEME_RESET = 'CREATESCHEME_RESET';

export const create_leave_scheme =(name,entitlement)=>{
    return dispatch=>{
        dispatch({
            type:'CREATESCHEME_REQUEST'
        });

        myaxios.post('leave/scheme/',{
            name:name,
            entitlement:entitlement
        })
        .then((response)=>{
            dispatch({
                type:'CREATESCHEME_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CREATESCHEME_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_failure')
            });
        })
    }
}

export const reset_create_leave_scheme = () => dispatch =>{
    dispatch({
        type:'CREATESCHEME_RESET'
    });
}


export const backpaylist1 =[
    {
        "value": "COMM",
        "display": "Commission",
        "display_zh": "Commission"
    },
    {
        "value": "GRATUITY",
        "display": "Gratuity",
        "display_zh": "Gratuity"
    },
    {
        "value": "INSURANCE",
        "display": "Insurance Premiums",
        "display_zh": "Insurance Premiums"
    },
    {
        "value": "SHARES",
        "display": "Share Profits",
        "display_zh": "Share Profits"
    },
    {
        "value": "DIRECTORS",
        "display": "Directors Fee",
        "display_zh": "Directors Fee"
    },
    {
        "value": "PENSION",
        "display": "Pension",
        "display_zh": "Pension"
    },
    {
        "value": "RETIREMENT",
        "display": "Retirement Benefits",
        "display_zh": "Retirement Benefits"
    },
    {
        "value": "BACKPAY",
        "display": "Backpay",
        "display_zh": "Backpay"
    },
    {
        "value": "OTHERS",
        "display": "Others",
        "display_zh": "其他"
    },
    {
        "value": "DEDUCT",
        "display": "Deductables",
        "display_zh": "Deductables"
    },
]


export const wagelist1 =[
    {
        "value": "ORDINARY",
        "display": "Ordinary",
        "display_zh":"普通薪资"
    },
    {
        "value": "ADDITIONAL",
        "display": "Additional",
        "display_zh":"额外薪资"
    }
]
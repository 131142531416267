import myaxios from "../../axios";
import { IMLocalized } from "../../language/IMLocalized";
import { ErrToast, Toast } from "../../swal/SwalToast";

export const UPDATEEMPSELFHELP_REQUEST = 'UPDATEEMPSELFHELP_REQUEST';
export const UPDATEEMPSELFHELP_SUCCESS = 'UPDATEEMPSELFHELP_SUCCESS';
export const UPDATEEMPSELFHELP_FAILURE = 'UPDATEEMPSELFHELP_FAILURE';
export const UPDATEEMPSELFHELP_RESET = 'UPDATEEMPSELFHELP_RESET';



export const update_employee_self_help = (data)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEMEPSELFHELP_REQUEST'
        });

        myaxios.post('employee/shg/',{
            data
        })
        .then((response)=>{
            dispatch({
                type:'UPDATEEMPSELFHELP_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATEEMPSELFHELP_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            })
        })
    }
}

export const reset_update_employee_self_help = () => dispatch =>{
    dispatch({
        type:'UPDATEEMPSELFHELP_RESET'
    });
}
import { AgGridReact } from 'ag-grid-react';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Input, Row, Table } from 'reactstrap';
import { isFirstColumn } from '../../ag-grid_component/isFirstColumn';
import { getDateFormat } from '../../../func/getDateFormat';
import { IMLocalized } from '../../../language/IMLocalized';
import ModalUserApplyLeave from './modal/ModalUserApplyLeave';
import { get_user_leave_detail } from '../../../action/user/get_user_leave_detail';
import ModalViewLeaveDetail from './modal/ModalViewLeaveDetail';
import Swal from 'sweetalert2';
import { user_apply_leave_list } from '../../../action/user/user_apply_leave_list';
import { user_get_available_leave } from '../../../action/user/user_get_available_leave';
import { user_get_all_leave_list } from '../../../action/user/user_get_all_leave_list';
import { user_leave_cancel_apply_leave } from '../../../action/user/user_leave_cancel_apply_leave';
import OverlayLoading from '../../loading_component/overlay_loading';
import { popError } from '../../../func/popError';
import { NewMainContext } from '../../../context/NewMainContext';
import { user_get_all_apply_leave_list } from '../../../action/user/user_get_all_apply_leave_list';
import { LeaveStatusRenderer } from '../../../func/LeaveStatusRenderer';
import { get_leave_list_detail } from '../../../reducers/get_leave_list';
import BreadCrumbList from '../../breadcrumb/BreadCrumbList';

const initialState={
    thislist:null,
    selected:'5',
    datalist:[],
    detail_id:null,
    detail_toggle:false,
}

const gridOptions={
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
    suppressRowClickSelection:true,
    defaultColDef:{
        resizable:true,
        sortable:true,
    },
}

export default function UserLeaveDetail({leave_id, toHome, toProfile}){

    const leavedetail = useSelector(state=>get_leave_list_detail(state,parseInt(leave_id)));
    const availablelist = useSelector(state=>state.user_get_available_leave.data);
    const applylist = useSelector(state=>state.user_apply_leave_list.data);
    const isLoading = useSelector(state=>state.user_apply_leave_list.isLoading);
    const isLoading1 = useSelector(state=>state.user_get_available_leave.isLoading);
    const cancel_success = useSelector(state=>state.user_leave_cancel_apply_leave.data);

    const dispatch = useDispatch();
    const [state,setState] = useState(initialState);
    const { action_toggle } = useContext(NewMainContext);
    const [apply_toggle,setApplyToggle] = useState(false);
    const [is_pending,setIsPending] = useState(false);
    const [monthNames] = useState([IMLocalized("JAN"), IMLocalized("FEB"), IMLocalized("MAR"), IMLocalized("APR"), IMLocalized("MAY"), IMLocalized("JUN"),
    IMLocalized("JUL"), IMLocalized("AUG"), IMLocalized("SEP"), IMLocalized("OCT"), IMLocalized("NOV"), IMLocalized("DEC")]);


    useEffect(()=>{
        if(is_pending && cancel_success != null){
            dispatch(user_apply_leave_list());
            dispatch(user_get_available_leave());
            dispatch(user_get_all_leave_list());
            dispatch(user_get_all_apply_leave_list());
            setIsPending(false);
        }
    },[cancel_success,is_pending,dispatch])


    useEffect(()=>{
        if(availablelist.length !== 0 && leave_id != null && leave_id !== ''){
            const detail = availablelist.find(item=>item.leave_setting === parseInt(leave_id));
            setState(prevState=>({...prevState,thislist:detail ?? null }));
        }
    },[availablelist,leave_id])

    useEffect(()=>{

        const sort = applylist
        .filter(item=>item.leave_setting.id === parseInt(leave_id))
        .map((item)=>{
            const leave_date = item.from_date === item.to_date ? getDateFormat(item.from_date) : `${getDateFormat(item.from_date)} - ${getDateFormat(item.to_date)}`;
            return {...item,leave_date}
        });
        setState(prevState=>({...prevState,datalist:sort}))

    },[applylist,leave_id])

    const cancelPending=()=>{
        const data = gridOptions.api.getSelectedRows();
        if(data.length !== 0){
            Swal.fire({
                title:IMLocalized('cancelling_leave'),
                input:'textarea',
                text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
                showCancelButton:true,
            })
            .then(result=>{
                if('value' in result){
                    let memo = result.value;
                    const arr = data.map(item=>item.id);
                    setIsPending(true);
                    dispatch(user_leave_cancel_apply_leave(arr,memo));
                }
            })
        }
        else{
            popError(IMLocalized('select_leave_to_cancel'));
        }
    }

    const onCellFocused=(params)=>{
        if(params?.column?.colDef?.field != null && params.column.colDef.field !== 'select'){
            const focusedCell = gridOptions.api.getFocusedCell();
            const cellValue = gridOptions.api.getDisplayedRowAtIndex(focusedCell.rowIndex)
            const id = cellValue.data.id;
            const rowPinned = focusedCell.rowPinned;
            if(rowPinned !== 'bottom'){
                setState(prevState=>({
                    ...prevState,
                    detail_toggle:true
                }))
                dispatch(get_user_leave_detail(id));
            }                         
        }
    }


    let columnDefs = [];
    if(state.selected === '0' || state.selected === '1' ){
        columnDefs = [
            {
                headerName:IMLocalized('select'),
                pinned:'left',
                field:'select',
                width:150,
                headerCheckboxSelection: isFirstColumn,
                checkboxSelection: isFirstColumn,
                headerCheckboxSelectionFilteredOnly:true,
            },
            {
                headerName:IMLocalized('leave_date'),
                field:'leave_date',
                width:250,
                filter:false,
                comparator:(valueA,ValueB,nodeA,nodeB)=>{
                    const date1 = new Date(nodeA.data.from_date) 
                    const date2 = new Date(nodeB.data.from_date);
                    return date2 -date1
                }
            },
            {   
                headerName:IMLocalized('leave_amount'),
                field:'amount',
                width:250
            },
            {
                headerName:IMLocalized('status'),
                field:'status',
                width:250,
                cellRendererFramework:LeaveStatusRenderer
            }
        ]
    }
    else{
        columnDefs=[
            {
                headerName:IMLocalized('leave_date'),
                field:'leave_date',
                width:250,
                filter:false,
                comparator:(valueA,ValueB,nodeA,nodeB)=>{
                    const date1 = new Date(nodeA.data.from_date) 
                    const date2 = new Date(nodeB.data.from_date);
                    return date2 -date1
                }
            },
            {   
                headerName:IMLocalized('leave_amount'),
                field:'amount',
                width:250
            },
            {
                headerName:IMLocalized('status'),
                field:'status',
                width:250,
                cellRendererFramework:LeaveStatusRenderer
            }
        ]
    }

    const row1 = state.datalist.filter(item=>{
        if(state.selected === '0'){
            return item.status === 'PENDING'
        }
        else if(state.selected === '1'){
            return item.status === 'APPROVED'
        }
        else if(state.selected === '2'){
            return item.status === 'REJECTED'
        }
        else if(state.selected === '3'){
            return item.status === 'CANCELLED'
        }
        else if(state.selected === '4'){
            return item.status === 'CONSUMED'
        }
        return item;
    }).sort((a,b)=> new Date(b.created_date).getTime() - new Date(a.created_date).getTime() )

    const pinnedData = [];
    if(row1.length !== 0){
        const amount = row1.reduce((sum,item)=>{
            return sum+item.amount;
        },0);

        pinnedData.push({amount,leave_date:IMLocalized('total')})
    }

    return(
        <>
        {isLoading || isLoading1 ? <OverlayLoading/>:
        <>
        <Container fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>toHome()},
                        { active: false, title:IMLocalized('profile'), onClick:()=>toProfile()},
                        { active: true, title:leavedetail?.name ?? ''}
                    ]}/>
                    <div className='ml-2'>
                        <button className='btn btn-primary btn-sm' onClick={()=>toProfile()}><i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                        <button className='btn btn-primary btn-sm' onClick={()=>setApplyToggle(true)}>{IMLocalized('apply_leave')}</button>
                    </div>
                </Col>
            </Row>
            <Row className='mt-2 justify-content-center'>
                <Col lg="10">
                    {state.thislist != null && state.thislist.leave_type === 'E' &&
                    <>
                        <h3>{state.leave_name}</h3>
                        <Table size="sm" className='bg-white align-items-center table-bordered' responsive>
                            <thead className='thead-light text-center'>
                                <tr>
                                    <th>{IMLocalized('MONTH')}</th>
                                    {state.thislist.remaining_monthly.map((item,index)=>{
                                        const month_name = monthNames[index];
                                        const year = state.thislist.year;
                                        return <th key={index}>{month_name} {year}</th>
                                    })}
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                <tr>
                                    <td>{IMLocalized('balance')}</td>
                                    {state.thislist.remaining_monthly.map((item,index)=>{
                                        return <td key={index}>{item.toFixed(2)}</td>
                                    })}
                                </tr>
                                <tr>
                                    <td>{IMLocalized('total')}</td>
                                    {state.thislist.remaining_monthly.map((item,index)=>{
                                        const {month_total} = state.thislist.employeeearntable;
                                        return <td key={index}>{month_total[index]}</td>
                                    })}
                                </tr>
                            </tbody>
                        </Table>
                    </>}
                    {state.thislist != null && state.thislist.leave_type !== 'E' && 
                    <>
                        <h3>{state.leave_name}</h3>
                        <Table size="sm" style={{width:'25%'}} className="table-bordered bg-white align-items-center" responsive>
                            <tbody className='text-center'>
                                <tr>
                                    <td>{IMLocalized('balance')}</td>
                                    <td>{state.thislist.remaining}</td>
                                </tr>
                                <tr>
                                    <td>{IMLocalized('total')}</td>
                                    <td>{state.thislist.total}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </>}
                    <div className='mt-2 d-flex justify-content-between'>
                        <h3 className='mb-0'>
                            {state.selected === '5' && IMLocalized('all_leave_list')}
                            {state.selected === '0' && IMLocalized('pending_leave_list')}
                            {state.selected === '1' && IMLocalized('approved_leave_list')}
                            {state.selected === '2' && IMLocalized('rejected_leave_list')}
                            {state.selected === '3' && IMLocalized('cancelled_leave_list')}
                            {state.selected === '4' && IMLocalized('consumed_leave_list')}
                        </h3>
                        <div className='d-flex'>
                            {state.selected === '0' || state.selected === '1' ? <div className='mr-2'><button className='btn btn-danger btn-sm' onClick={()=>cancelPending()}>{IMLocalized('cancel')}</button></div> : null}
                            <Input type="select" size="sm" value={state.selected}
                            onChange={(e)=>{
                                const value = e.target.value;
                                setState(prevState=>({...prevState,selected:value}));
                            }}>
                                <option value="5">{IMLocalized('ALL')}</option>
                                <option value='0'>{IMLocalized('PENDING')}</option>
                                <option value="1">{IMLocalized('APPROVED')}</option>
                                <option value="2">{IMLocalized('REJECTED')}</option>
                                <option value="3">{IMLocalized('CANCELLED')}</option>
                                <option value="4">{IMLocalized('CONSUMED')}</option>
                            </Input>
                        </div>
                    </div>
                    <div className='mt-2 ag-theme-balham' style={{height:action_toggle ? 'calc(100vh - 450px)' : 'calc(100vh - 414px)',width:'100%'}}>
                        <AgGridReact 
                        gridOptions={gridOptions}
                        columnDefs={columnDefs}
                        rowData={row1}
                        suppressDragLeaveHidesColumns={true}
                        suppressSizeToFit={true}
                        suppressColumnMoveAnimation={false}
                        overlayNoRowsTemplate={IMLocalized('no_data')}
                        pinnedBottomRowData={pinnedData}
                        onCellFocused={onCellFocused}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
        <ModalViewLeaveDetail toggle={state.detail_toggle} 
        closeModal={()=>{
            setState(prevState=>({
                ...prevState,
                detail_toggle:false,
            })) 
            gridOptions.api.clearFocusedCell();
        }} />
        <ModalUserApplyLeave toggle={apply_toggle} has_leave={true} leave_id={leave_id} closeModal={()=>setApplyToggle(false)} leave_name={state.leave_name}  />
        </>}
        </>
    )
}
import * as contractcostcenter from '../../action/employee/employee_contract_costcenter';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const employee_contract_costcenter = (state= initialState,action)=>{
    switch(action.type){
        case contractcostcenter.EMPCONTRACTCOSTCENTER_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case contractcostcenter.EMPCONTRACTCOSTCENTER_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case contractcostcenter.EMPCONTRACTCOSTCENTER_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default employee_contract_costcenter;
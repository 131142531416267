import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader ,Row , Col , Table , Card, Input, FormGroup, FormFeedback, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { popError } from '../../../../func/popError';
import Swal from 'sweetalert2';
import { IMLocalized } from '../../../../language/IMLocalized';

import { create_employee_contract } from '../../../../action/employee/create_employee_contract';
import { get_additional_setting } from '../../../../action/additional_setting';
import { usePrevious } from '../../../../hook/usePrevious';
import { get_skip_option } from '../../../../action/employee/get_skip_option';

const mapStateToProps = (state)=>({
    templatelist:state.get_contract_template_list.data,
    contract_typelist:state.get_contract_template_dropdown.contract_type,
    contract_ratelist:state.get_contract_template_dropdown.contract_rate,
    create_errors:state.create_employee_contract.errors,
    create_success:state.create_employee_contract.data,

    isLoading:state.get_skip_option.isLoading,
    checklist:state.get_skip_option.data,
    update_success:state.update_skip_option.data,
})

const mapDispatchToProps = (dispatch)=>({
    create_employee_contract:(data)=>{
        dispatch(create_employee_contract(data))
    },
    get_skip_option:(data)=>{
        dispatch(get_skip_option(data))
    }
})



function ModalSkip(props){
    const {get_skip_option,update_skip_option,nationality} = props;
    useEffect(()=>{
        if(props?.checklist?.layout?.hasOwnProperty('skip')){
        if(props.checklist && props.isLoading===false && props?.checklist?.layout.skip.skipData?.skip_remember===true){
            if(props?.checklist?.layout.skip && props?.checklist?.layout.skip.skipData?.skip_remember===true){
            props.setSkipData(prevState=>({
                ...prevState,
                skip_remember:props?.checklist?.layout.skip?.skipData?.skip_remember ?? '',
                skip_permit:props?.checklist?.layout.skip?.skipData?.skip_permit ?? '',
                skip_passport:props?.checklist?.layout.skip?.skipData?.skip_passport ?? '',
                skip_bank:props?.checklist?.layout.skip?.skipData?.skip_bank ?? '',
                skip_leave_scheme:props?.checklist?.layout.skip?.skipData?.skip_leave_scheme ?? '',
                skip_leave_approval:props?.checklist?.layout.skip?.skipData?.skip_leave_approval ?? '',
                skip_claim_scheme:props?.checklist?.layout.skip?.skipData?.skip_claim_scheme ?? '',
                skip_claim_approval:props?.checklist?.layout.skip?.skipData?.skip_claim_approval ?? '',
                skip_family:props?.checklist?.layout.skip?.skipData?.skip_family ?? '',
                skip_child:props?.checklist?.layout.skip?.skipData?.skip_child ?? '',
            }))
        }else{
            props.setSkipData(prevState=>({
                ...prevState,
                skip_remember:false,
                skip_permit:false,
                skip_passport:false,
                skip_bank:false,
                skip_leave_scheme:false,
                skip_leave_approval:false,
                skip_claim_scheme:false,
                skip_claim_approval:false,
                skip_family:false,
                skip_child:false
            }))
        }
        if(props?.checklist?.layout?.skip?.skipData?.skip_remember===true){
            props.setSkipRemember(true)
        }else{props.setSkipRemember(false)}
        if(props?.checklist?.layout?.skip?.skipData?.skip_permit===true){
            props.setSkipPermit(true)
        }else{props.setSkipPermit(false)}
        if(props?.checklist?.layout?.skip?.skipData?.skip_passport===true){
            props.setSkipPassport(true)
        }else{props.setSkipPassport(false)}
        if(props?.checklist?.layout?.skip?.skipData?.skip_bank===true){
            props.setSkipBank(true)
        }else{props.setSkipBank(false)}
        if(props?.checklist?.layout?.skip?.skipData?.skip_leave_scheme===true){
            props.setSkipLeaveScheme(true)
        }else{props.setSkipLeaveScheme(false)}
        if(props?.checklist?.layout?.skip?.skipData?.skip_leave_approval===true){
            props.setSkipLeaveApproval(true)
        }else{props.setSkipLeaveApproval(false)}
        if(props?.checklist?.layout?.skip?.skipData?.skip_claim_scheme===true){
            props.setSkipClaimScheme(true)
        }else{props.setSkipClaimScheme(false)}
        if(props?.checklist?.layout?.skip?.skipData?.skip_claim_approval===true){
            props.setSkipClaimApproval(true)
        }else{props.setSkipClaimApproval(false)}
        if(props?.checklist?.layout?.skip?.skipData?.skip_family===true){
            props.setSkipFamily(true)
        }else{props.setSkipFamily(false)}
        if(props?.checklist?.layout?.skip?.skipData?.skip_child===true){
            props.setSkipChild(true)
        }else{props.setSkipChild(false)}
        }
    }else if(props?.checklist.layout){
        if(props.checklist && props.isLoading===false && props?.checklist?.layout.skip_remember===true){
            if(props?.checklist?.layout && props?.checklist?.layout.skip_remember===true){
            props.setSkipData(prevState=>({
                ...prevState,
                skip_remember:props?.checklist?.layout?.skip_remember ?? '',
                skip_permit:props?.checklist?.layout?.skip_permit ?? '',
                skip_passport:props?.checklist?.layout?.skip_passport ?? '',
                skip_bank:props?.checklist?.layout?.skip_bank ?? '',
                skip_leave_scheme:props?.checklist?.layout?.skip_leave_scheme ?? '',
                skip_leave_approval:props?.checklist?.layout?.skip_leave_approval ?? '',
                skip_claim_scheme:props?.checklist?.layout?.skip_claim_scheme ?? '',
                skip_claim_approval:props?.checklist?.layout?.skip_claim_approval ?? '',
                skip_family:props?.checklist?.layout?.skip_family ?? '',
                skip_child:props?.checklist?.layout?.skip_child ?? '',
            }))
        }else{
            props.setSkipData(prevState=>({
                ...prevState,
                skip_remember:false,
                skip_permit:false,
                skip_passport:false,
                skip_bank:false,
                skip_leave_scheme:false,
                skip_leave_approval:false,
                skip_claim_scheme:false,
                skip_claim_approval:false,
                skip_family:false,
                skip_child:false
            }))
        }
    }}
    },[props.checklist])



    // const [defaultlist] = useState({
    //     permitChecked:false,
    //     passportChecked:false,
    //     bankChecked:false,
    //     leaveSchemeChecked:false,
    //     leaveApprovalChecked:false,
    //     claimSchemeChecked:false,
    //     claimApprovalChecked:false,
    //     familyChecked:false,
    //     childChecked:false
    // })

    useEffect(()=>{
        get_skip_option()
    },[get_skip_option])


    useEffect(()=>{
    if(props.isLoading===false && props.skip_remember===false){
        if(document.getElementById("modalskip_skippermit").checked===true){
            props.setSkipPermit(true)
        }else{
            props.setSkipPermit(false)
        }
        if(document.getElementById("modalskip_skippassport").checked===true){
            props.setSkipPassport(true)
        }else{
            props.setSkipPassport(false)
        }
        if(document.getElementById("modalskip_skipbank").checked===true){
            props.setSkipBank(true)
        }else{
            props.setSkipBank(false)
        }
        if(document.getElementById("modalskip_skipleavescheme").checked===true){
            props.setSkipLeaveScheme(true)
        }else{
            props.setSkipLeaveScheme(false)
        }
        if(document.getElementById("modalskip_skipleaveapproval").checked===true){
            props.setSkipLeaveApproval(true)
        }else{
            props.setSkipLeaveApproval(false)
        }
        if(document.getElementById("modalskip_skipclaimscheme").checked===true){
            props.setSkipClaimScheme(true)
        }else{
            props.setSkipClaimScheme(false)
        }
        if(document.getElementById("modalskip_skipclaimapproval").checked===true){
            props.setSkipClaimApproval(true)
        }else{
            props.setSkipClaimApproval(false)
        }
        if(document.getElementById("modalskip_skipfamily").checked===true){
            props.setSkipFamily(true)
        }else{
            props.setSkipFamily(false)
        }
        if(document.getElementById("modalskip_skipchild").checked===true){
            props.setSkipChild(true)
        }else{
            props.setSkipChild(false)
        }

    }
    },[props.checklist,
    props])

        // props.checklist,props.skip_permit,props.skip_passport,props.skip_bank,props.skip_leave_scheme,
        // props.skip_leave_approval,props.skip_claim_scheme,props.skip_claim_approval,
        // props.skip_family,props.skip_child

    const handleCheckbox=(e)=>{
        const target = e.target;
        const checked = target.checked;
        if(checked === true){
            props.setSkipPermit(true)
            props.setSkipPassport(true)
            props.setSkipBank(true)
            props.setSkipLeaveScheme(true)
            props.setSkipLeaveApproval(true)
            props.setSkipClaimScheme(true)
            props.setSkipClaimApproval(true)
            props.setSkipFamily(true)
            props.setSkipChild(true)
            props.setSkipData(prevState=>({
                ...prevState,
                skip_permit:true,
                skip_passport:true,
                skip_bank:true,
                skip_leave_scheme:true,
                skip_leave_approval:true,
                skip_claim_scheme:true,
                skip_claim_approval:true,
                skip_family:true,
                skip_child:true
            }))
        }else{
            props.setSkipPermit(false)
            props.setSkipPassport(false)
            props.setSkipBank(false)
            props.setSkipLeaveScheme(false)
            props.setSkipLeaveApproval(false)
            props.setSkipClaimScheme(false)
            props.setSkipClaimApproval(false)
            props.setSkipFamily(false)
            props.setSkipChild(false)
            props.setSkipData(prevState=>({
                ...prevState,
                skip_permit:false,
                skip_passport:false,
                skip_bank:false,
                skip_leave_scheme:false,
                skip_leave_approval:false,
                skip_claim_scheme:false,
                skip_claim_approval:false,
                skip_family:false,
                skip_child:false
            }))
        }
    }


    // const handleCheckbox1=(e)=>{
    //     const target = e.target;
    //     const checked = target.checked;
    //     if(checked === true){
    //         setSkipContract(true)
    //     }else{
    //         setSkipContract(false)
    //     }
    // }

    const handleCheckbox2=(e)=>{
        const target = e.target;
        const checked = target.checked;
        if(checked === true){
            props.setSkipPermit(true)
        }else{
            props.setSkipPermit(false)
            document.getElementById("modalskip_skipall").checked = false;
        }
    }

    const handleCheckbox3=(e)=>{
        const target = e.target;
        const checked = target.checked;
        if(checked === true){
            props.setSkipPassport(true)
        }else{
            props.setSkipPassport(false)
            document.getElementById("modalskip_skipall").checked = false;
        }
    }

    const handleCheckbox4=(e)=>{
        const target = e.target;
        const checked = target.checked;
        if(checked === true){
            props.setSkipBank(true)
        }else{
            props.setSkipBank(false)
            document.getElementById("modalskip_skipall").checked = false;
        }
    }

    const handleCheckbox5=(e)=>{
        const target = e.target;
        const checked = target.checked;
        if(checked === true){
            props.setSkipLeaveScheme(true)
        }else{
            props.setSkipLeaveScheme(false)
            document.getElementById("modalskip_skipall").checked = false;
        }
    }

    const handleCheckbox6=(e)=>{
        const target = e.target;
        const checked = target.checked;
        if(checked === true){
            props.setSkipLeaveApproval(true)
        }else{
            props.setSkipLeaveApproval(false)
            document.getElementById("modalskip_skipall").checked = false;
        }
    }

    const handleCheckbox7=(e)=>{
        const target = e.target;
        const checked = target.checked;
        if(checked === true){
            props.setSkipClaimScheme(true)
        }else{
            props.setSkipClaimScheme(false)
            document.getElementById("modalskip_skipall").checked = false;
        }
    }

    const handleCheckbox8=(e)=>{
        const target = e.target;
        const checked = target.checked;
        if(checked === true){
            props.setSkipClaimApproval(true)
        }else{
            props.setSkipClaimApproval(false)
            document.getElementById("modalskip_skipall").checked = false;
        }
    }

    const handleCheckbox9=(e)=>{
        const target = e.target;
        const checked = target.checked;
        if(checked === true){
            props.setSkipFamily(true)
        }else{
            props.setSkipFamily(false)
            document.getElementById("modalskip_skipall").checked = false;
        }
    }

    const handleCheckbox10=(e)=>{
        const target = e.target;
        const checked = target.checked;
        if(checked === true){
            props.setSkipChild(true)
        }else{
            props.setSkipChild(false)
            document.getElementById("modalskip_skipall").checked = false;
        }
    }


    return(
        <Modal isOpen={props.toggle} className="modal-dialog-centered" style={{maxWidth: '1600px', width: '90%'}}>
            <ModalHeader className='mt-2 text-lg' toggle={props.closeModal}><div className='text-lg'>{IMLocalized('skip_option')}</div></ModalHeader>
            <ModalBody>
                <Table size="sm" className="mt-2 align-items-center table-bordered">
                    <thead style={{backgroundColor:'#e9eff6'}}>
                    <tr>
                    <th>
                    <div className='custom-control custom-checkbox checkboxes'>
                        <input type="checkbox" id='modalskip_skipall' name='skip_all' className="custom-control-input"  onChange={(e)=>handleCheckbox(e)} />
                        <label className="custom-control-label" htmlFor="modalskip_skipall">{IMLocalized('skip_all')}</label>
                    </div>
                    </th>
                    <th>
                    </th>
                    </tr>

                    </thead>
                    <tbody>
                    <tr>
                        <td>
                        <div className='custom-control custom-checkbox checkboxes'>
                            <input type="checkbox" id='modalskip_skippermit' name='skip_permit' className="custom-control-input" checked={props.skipData.skip_permit} onChange={(e)=>{props.setSkipData(prevState=>({...prevState,skip_permit:!props.skipData.skip_permit}));handleCheckbox2(e)}} />
                            <label className="custom-control-label" htmlFor="modalskip_skippermit">{IMLocalized('skip_permit')}</label>
                        </div>
                        </td>
                        <td>
                        <div className='custom-control custom-checkbox checkboxes'>
                            <input type="checkbox" id='modalskip_skipclaimscheme' name='skip_claim_scheme' className="custom-control-input" checked={props.skipData.skip_claim_scheme} onChange={(e)=>{props.setSkipData(prevState=>({...prevState,skip_claim_scheme:!props.skipData.skip_claim_scheme}));handleCheckbox7(e)}} />
                            <label className="custom-control-label" htmlFor="modalskip_skipclaimscheme">{IMLocalized('skip_claim_scheme')}</label>
                        </div>
                        </td>
                    </tr>
                    
                    <tr>
                        <td>
                        <div className='custom-control custom-checkbox checkboxes'>
                            <input type="checkbox" id='modalskip_skippassport' name='skip_passport' className="custom-control-input" checked={props.skipData.skip_passport} onChange={(e)=>{props.setSkipData(prevState=>({...prevState,skip_passport:!props.skipData.skip_passport}));handleCheckbox3(e)}} />
                            <label className="custom-control-label" htmlFor="modalskip_skippassport">{IMLocalized('skip_passport')}</label>
                        </div>
                        </td>
                        <td>
                        <div className='custom-control custom-checkbox checkboxes'>
                            <input type="checkbox" id='modalskip_skipclaimapproval' name='skip_claim_approval' className="custom-control-input" checked={props.skipData.skip_claim_approval} onChange={(e)=>{props.setSkipData(prevState=>({...prevState,skip_claim_approval:!props.skipData.skip_claim_approval}));handleCheckbox8(e)}} />
                            <label className="custom-control-label" htmlFor="modalskip_skipclaimapproval">{IMLocalized('skip_claim_approval')}</label>
                        </div>
                        </td>
                    </tr>

                    <tr>
                    <td>
                        <div className='custom-control custom-checkbox checkboxes'>
                            <input type="checkbox" id='modalskip_skipbank' name='skip_bank' className="custom-control-input" checked={props.skipData.skip_bank} onChange={(e)=>{props.setSkipData(prevState=>({...prevState,skip_bank:!props.skipData.skip_bank}));handleCheckbox4(e)}} />
                            <label className="custom-control-label" htmlFor="modalskip_skipbank">{IMLocalized('skip_bank')}</label>
                        </div>
                    </td>
                    <td>
                        <div className='custom-control custom-checkbox checkboxes'>
                            <input type="checkbox" id='modalskip_skipfamily' name='skip_family' className="custom-control-input" checked={props.skipData.skip_family} onChange={(e)=>{props.setSkipData(prevState=>({...prevState,skip_family:!props.skipData.skip_family}));handleCheckbox9(e)}} />
                            <label className="custom-control-label" htmlFor="modalskip_skipfamily">{IMLocalized('skip_family')}</label>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td>
                        <div className='custom-control custom-checkbox checkboxes'>
                            <input type="checkbox" id='modalskip_skipleavescheme' name='skip_leave_scheme' className="custom-control-input" checked={props.skipData.skip_leave_scheme} onChange={(e)=>{props.setSkipData(prevState=>({...prevState,skip_leave_scheme:!props.skipData.skip_leave_scheme}));handleCheckbox5(e)}} />
                            <label className="custom-control-label" htmlFor="modalskip_skipleavescheme">{IMLocalized('skip_leave_scheme')}</label>
                        </div>
                    </td>
                    <td>
                        <div className='custom-control custom-checkbox checkboxes'>
                            <input type="checkbox" id='modalskip_skipchild' name='skip_child' className="custom-control-input" checked={props.skipData.skip_child} onChange={(e)=>{props.setSkipData(prevState=>({...prevState,skip_child:!props.skipData.skip_child}));handleCheckbox10(e)}} />
                            <label className="custom-control-label" htmlFor="modalskip_skipchild">{IMLocalized('skip_child')}</label>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td>
                        <div className='custom-control custom-checkbox checkboxes'>
                            <input type="checkbox" id='modalskip_skipleaveapproval' name='skip_leave_approval' className="custom-control-input" checked={props.skipData.skip_leave_approval} onChange={(e)=>{props.setSkipData(prevState=>({...prevState,skip_leave_approval:!props.skipData.skip_leave_approval}));handleCheckbox6(e)}} />
                            <label className="custom-control-label" htmlFor="modalskip_skipleaveapproval">{IMLocalized('skip_leave_approval')}</label>
                        </div>
                    </td>
                    </tr>

                    </tbody>
                </Table>

                <div className='custom-control custom-checkbox checkboxes'>
                    <input type="checkbox" id='modalskip_remember' name='skip_remember' className="custom-control-input" checked={props.skipData.skip_remember} onChange={(e)=>{props.setSkipData(prevState=>({...prevState,skip_remember:!props.skipData.skip_remember})); props.setSkipRemember(!props.skipRemember)}} />
                    <label className="custom-control-label" htmlFor="modalskip_remember">{IMLocalized('skip_remember')}</label>
                </div>
            </ModalBody>
            <ModalFooter>
            {props.isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
            <>
                <button onClick={props.closeModal} className='btn btn-primary btn-sm'>{IMLocalized('confirm')}</button>
                {/* <button onClick={props.closeModal} className='btn btn-danger btn-sm'>{IMLocalized('close')}</button> */}
            </>
            }
            </ModalFooter>
        </Modal>
    )

}
export default connect(mapStateToProps,mapDispatchToProps)(ModalSkip);
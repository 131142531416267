import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import Swal from 'sweetalert2';
import { IMLocalized } from '../../../language/IMLocalized';
import { delDepartment, reset_delete_department } from '../../../action/delete_department';
import { get_additional_setting } from '../../../action/additional_setting';
import ModalCreateDepartment from '../modal/ModalCreateDepartment';

function DepartmentContent(){
    const [create_toggle,setCreateToggle] = useState(false);
    const dispatch = useDispatch();
    const departmentlist = useSelector(state=>state.add.department);
    const isLoading = useSelector(state=>state.add.isLoading);
    const delete_success = useSelector(state=>state.delete_department.data);

    useEffect(()=>{
        if( delete_success !== null){
            dispatch(get_additional_setting());
            dispatch(reset_delete_department());
        }
    },[delete_success,dispatch])

    
    const removeDepartment=(name)=>{
        Swal.fire({
            type:"warning",
            title:`${IMLocalized('do_you_really_want_to_delete')} \n ${IMLocalized('department')} (` + name +`) !`,
            showCancelButton:true,
            confirmButtonColor:'red',
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!')
        })
        .then((result)=>{
            if(result.value){
                dispatch(delDepartment(name));
            }
        })
    }

    return(
        <>
        <Card>
            <CardHeader className='d-flex justify-content-between align-items-center '>
                <h4 className="mb-0">{IMLocalized('department_list')}</h4>
                <button className='btn btn-primary btn-sm' onClick={()=>setCreateToggle(true)}>{IMLocalized('add')}</button>
            </CardHeader>
            {isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>}
            {!isLoading && departmentlist.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
            {!isLoading && departmentlist.length !== 0 &&
            <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 340px)'}}>
                {departmentlist.map((item,index)=>{
                    return(
                        <ListGroupItem className="py-2" key={index}>
                            <Row>
                                <Col>
                                    {index+1}. {item.name}
                                </Col>
                                <Col className="col-auto">
                                    <div className="delete_btn mr-3 d-flex align-items-center">
                                        <i className="fas fa-trash text-danger cursor-pointer" onClick={()=>removeDepartment(item.name)}  />
                                    </div>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    )
                })}
            </ListGroup>}
        </Card>
        <ModalCreateDepartment toggle={create_toggle} closeModal={()=>setCreateToggle(false)} />
        </>
    )
}
export default DepartmentContent;
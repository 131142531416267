import HCaptcha from '@hcaptcha/react-hcaptcha';
import React, {useEffect, useState} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { IMLocalized } from '../../../language/IMLocalized';
import { ModalHeader, Modal, ModalFooter, Input, FormFeedback, FormGroup,ModalBody } from 'reactstrap';

export default function ModalEmail({toggle, closeModal, submitEmail}){

    const dispatch = useDispatch();

    const isLoading = useSelector(state=>state.reset_otp_password.isLoading);
    const [email,setEmail] = useState('');
    const [err_email,setErrEmail] = useState('');
    const [verify_token,setVerifyToken] = useState(null);
    const [verify,setVerify] = useState(false);

    useEffect(()=>{
        if(toggle){
            setEmail('');
            setErrEmail('');
            setVerify(false);
            setVerifyToken(null);
        }
    },[toggle])

    const handleEmail=(e)=>{
        const target = e.target;
        const value = target.value;
        setEmail(value);
        setErrEmail('');
    }

    const onReset=()=>{
        if(email !== ''){
            if(validateEmail(email)){
                dispatch(submitEmail(email,verify_token));
            }
            else{
                setErrEmail(IMLocalized('invalid_email'));
            }
        }
        else{
            setErrEmail(IMLocalized('email_required'));
        }
    }

    const validateEmail = (email)=> {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleVerificationSuccess=(token,ekey)=>{
        setVerify(true);
        setVerifyToken(token);
    }

    return(
        <Modal isOpen={toggle} className="modal-dialog-centered">
            <ModalHeader toggle={closeModal}>
                {IMLocalized('forgot_password')}
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <label className="form-control-label">{IMLocalized('email_address')}</label>
                    <Input type="email" name="email" value={email} onChange={handleEmail} id="forgotEmail" placeholder={IMLocalized('enter_email_address')} invalid={err_email.length>0}/>
                    <FormFeedback>{err_email}</FormFeedback>
                </FormGroup>
                {verify && isLoading &&  <button className="btn btn-lg btn-block btn2-login" type="submit" ><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>}
                {verify && !isLoading && <button className="btn btn-lg btn-block btn2-login" type="submit" onClick={onReset}>{IMLocalized('submit')}</button>}
                {!verify && !isLoading && <HCaptcha sitekey={'e269b0ee-849d-4b8f-a940-1f0be69a0870'} onVerify={(token,ekey) =>handleVerificationSuccess(token, ekey)} />}
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
import * as claim_picture from '../../action/user/user_claim_detail_get_claim_picture';

const initialState={
    data:null,
    errors:null,
    isLoading:false
}

const user_claim_detail_get_claim_picture = (state=initialState,action)=>{
    switch(action.type){
        case claim_picture.USERCLAIMDETAILGETCLAIMPICTURE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null
            }
        case claim_picture.USERCLAIMDETAILGETCLAIMPICTURE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case claim_picture.USERCLAIMDETAILGETCLAIMPICTURE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case claim_picture.USERCLAIMDETAILGETCLAIMPICTURE_RESET:
            return{
                ...state,
                errors:null,
                data:null
            }
        default:
            return state;
    }
}
export default user_claim_detail_get_claim_picture;

import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {AgGridReact} from 'ag-grid-react';
import moment from 'moment';
import 'ag-grid-enterprise';
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Input, Row } from "reactstrap";
import { NewMainContext } from "../../context/NewMainContext";
import { IMLocalized } from "../../language/IMLocalized";
import { payroll_variance_employee_payroll, reset_payroll_variance_employee_payroll } from "../../action/report/payroll_variance_employee_payroll";
import { payroll_variance_get_aggridheader } from "../../action/report/payroll_variance_get_aggridheader";
import { payroll_variance_get_aggridheader2 } from "../../action/report/payroll_variance_get_aggridheader2";
import OverlayLoading from '../loading_component/overlay_loading';
import BreadCrumbList from "../breadcrumb/BreadCrumbList";

const gridOptions={
    defaultColDef:{
        flex:1,
        minWidth:100,
        enableValue:true,
        enableRowGroup:true,
        sortable:true,
        filter:true,
        resizable:true,
        editable:false,
    },
    sideBar:{
        toolPanels: [
              {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                toolPanelParams: {
                },
              },
            ],
        defaultToolPanel:'filters'
    }
}

export default function PayrollVarianceEmployee(){

    const isLoading = useSelector(state=>state.get_employee_list.isLoading);
    const isLoading1 = useSelector(state=>state.payroll_variance_get_aggridheader.isLoading);
    const isLoading2 = useSelector(state=>state.payroll_variance_get_aggridheader2.isLoading);
    const isLoading3 = useSelector(state=>state.payroll_variance_employee_payroll.isLoading);
    const emplist = useSelector(state=>state.get_employee_list.data);
    const payrolllist = useSelector(state=>state.payroll_variance_employee_payroll.data);
    const agheader = useSelector(state=>state.payroll_variance_get_aggridheader.data);
    const agheader2 = useSelector(state=>state.payroll_variance_get_aggridheader2.data);
    const selflist = useSelector(state=>state.dropdown_list.self_help_group);
    const dispatch = useDispatch();
    const { addItem, action_toggle, payroll_variance_reset, setPayrollVarianceReset } = useContext(NewMainContext);

    const agRef = useRef();
    const [ select_emp, setSelectEmp] = useState('');
    const [ to_payroll, setToPayroll] = useState('');
    const [ from_payroll, setFromPayroll] = useState('');
    // const [ rowData, setRowData] = useState([]);
    const [ current_step, setCurrentStep] = useState(0);
    const [ total_agheader, setTotalAgheader] = useState([]);

    const monthNames =["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const monthNames1 = [IMLocalized("january"), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
    IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")
    ]

    useEffect(()=>{

        function arrayUnique(array) {
            var a = array.concat();
            for(var i=0; i<a.length; ++i) {
                for(var j=i+1; j<a.length; ++j) {
                    if(a[i] === a[j])
                        a.splice(j--, 1);
                }
            }
        
            return a;
        }
        if(agheader?.ag_grid != null && agheader2?.ag_grid != null ){
            const grid1 = agheader.ag_grid;
            const grid2 = agheader2.ag_grid;
            const arr = arrayUnique(grid1.concat(grid2));
            setTotalAgheader(arr);
        }
    },[agheader,agheader2])

    useEffect(()=>{
        if(payroll_variance_reset === true){
            setSelectEmp('');
            setFromPayroll('');
            setCurrentStep(0);
            setPayrollVarianceReset(false);
            setTotalAgheader([]);
        }
    },[payroll_variance_reset,setPayrollVarianceReset])

    useEffect(()=>{
        if(select_emp !== ''){
            dispatch(payroll_variance_employee_payroll(select_emp));
        }
        else{
            dispatch(reset_payroll_variance_employee_payroll());
        }
        setToPayroll('');
        setFromPayroll('');
        setTotalAgheader([]);
    },[select_emp, dispatch])

    const handlePayroll = (e) =>{
        const { value } = e.target;
        setFromPayroll(value);
        if(value === ''){
            setToPayroll('');
        }
        else if (parseInt(value) < payrolllist.length -1){
            const data1 = payrolllist[parseInt(value)];
            const id = data1?.payroll?.id;

            const to_payroll = parseInt(value)+1;
            const data2  = payrolllist[to_payroll];
            const id2 = data2?.payroll?.id;
            setCurrentStep(1);
            setToPayroll(to_payroll);
            dispatch(payroll_variance_get_aggridheader(id));
            dispatch(payroll_variance_get_aggridheader2(id2));
        }
        else{
            setToPayroll('');
            setCurrentStep(2);
            setTotalAgheader([]);
        }
    }

    const rowData = useMemo(()=>{

        function combineData(data){
            const { pay, payslipcontract, sgcpf, sgshg } = data;
            
            const cpf_employee_amount = sgcpf?.cpf_employee ?? null;
            const cpf_employer_amount = sgcpf?.cpf_employer ?? null;
            
            const basic_amount = payslipcontract.reduce((total,item)=>total + item.gross_month,0);
            let aggrid = {};
            for( const pay_item of pay){
                const { code, amount, id } = pay_item;
                const name = code.replace('.','-');
                aggrid[name] = { amount, id};
            }

            let shg1 = {};
            for( const shg_item of sgshg){
               const { shg, amount, id } = shg_item;
               shg1[shg] = { amount, id };
            }

            const total_overtime = pay.filter(item=>item.pay_type === 'OT').reduce((total,item)=>total + item.amount, 0);
            const total_allowance = pay.filter(item=>item.pay_type === 'ALLOWANCE').reduce((total,item)=>total + item.amount, 0);
            const total_fixed = pay.filter(item=>item.pay_type === 'FX').reduce((total,item)=> total + item.amount, 0);

            return { ...data, total_fixed, total_allowance, total_overtime, shg:shg1, aggrid, basic_pay:basic_amount, cpf_employee:cpf_employee_amount, cpf_employer: cpf_employer_amount, }
        }


        if(payrolllist.length !== 0 && from_payroll !== '' && to_payroll !== ''){
            const arr = [];
            const data1 = combineData(payrolllist[from_payroll]);
            const data2 = combineData(payrolllist[to_payroll]);
            console.log(data1)

            const dif_gross_salary = data1.basic_pay - data2.basic_pay;
            const per_gross_salary = data1.basic_pay !== 0 && data2.basic_pay !== 0 ? parseFloat((dif_gross_salary / data1.basic_pay * 100 ).toFixed(2)) : 0;

            arr.push({ description :'GROSS SALARY', from_payroll:(parseFloat(data2.basic_pay)).toFixed(2), to_payroll:parseFloat(data1.basic_pay).toFixed(2), difference:parseFloat(dif_gross_salary.toFixed(2)), percentage: parseFloat(per_gross_salary.toFixed(2))});

            const dif_nett_salary = data1.payable_wage - data2.payable_wage;
            const per_nett_salary = data1.payable_wage !== 0 && data2.payable_wage !== 0 && dif_nett_salary !== 0 ? parseFloat((dif_nett_salary / data1.payable_wage * 100).toFixed(2)) : 0;
            arr.push({ description:'NETT SALARY',  from_payroll: parseFloat(data2.payable_wage.toFixed(2)), to_payroll: parseFloat(data1.payable_wage.toFixed(2)), difference: parseFloat(dif_nett_salary.toFixed(2)), percentage:parseFloat(per_nett_salary.toFixed(2))});

            const dif_total_overtime = data1.total_overtime - data2.total_overtime;
            const per_total_overtime = data1.total_overtime !== 0 && data2.total_overtime !== 0 && dif_total_overtime !== 0 ? parseFloat((dif_total_overtime / data1.total_allowance * 100).toFixed(2)) : '-' ;
            
            arr.push({ description:'TOTAL OVERTIME', from_payroll: parseFloat(data2.total_overtime.toFixed(2)), to_payroll: parseFloat(data1.total_overtime.toFixed(2)), difference:parseFloat(dif_total_overtime.toFixed(2)), percentage:per_total_overtime });

            const dif_total_allowance = data1.total_allowance - data2.total_allowance;
            const per_total_allowance = data1.total_allowance !== 0 && data2.total_allowance && dif_total_allowance !== 0 ?  parseFloat((dif_total_allowance / data1.total_allowance * 100).toFixed(2)) : '-' ;

            arr.push({ description:'TOTAL ALLOWANCE', from_payroll: parseFloat(data2.total_allowance.toFixed(2)), to_payroll: parseFloat(data1.total_allowance.toFixed(2)), difference: parseFloat(dif_total_allowance.toFixed(2)), percentage: per_total_allowance})

            const dif_total_fixed = data1.total_fixed - data2.total_fixed; 
            const per_total_fixed = data1.total_fixed !== 0 && data2.total_fixed !== 0 && dif_total_fixed !== 0 ? parseFloat((dif_total_fixed / data1.total_fixed * 100 ).toFixed(2)) : '-';
             
            arr.push( { description: 'TOTAL FIXED RATE', from_payroll: parseFloat(data2.total_fixed.toFixed(2)), to_payroll: parseFloat(data1.total_fixed.toFixed(2)), difference: parseFloat(dif_total_fixed.toFixed(2)), percentage: per_total_fixed });

            for(const total_item of total_agheader){
                const field = total_item.replace('.','-');
                const aggrid = data1.aggrid;
                const aggrid1 = data2.aggrid;

                const amount1 = aggrid[field]?.amount ?? '-';
                const amount2 = aggrid1[field]?.amount ?? '-';

                const dif_aggrid = amount1 !== '-' && amount1 !== 0 && amount2 !== '-' && amount2 !== 0 ? parseFloat((amount1 - amount2).toFixed(2)) : '-';
                const per_aggrid = amount1 !== '-' && amount1 !== 0 && amount2 !== '-' && amount2 !== 0 ? parseFloat((dif_aggrid / amount1 * 100).toFixed(2))  : '-';
                
                arr.push({ description:total_item, from_payroll:amount2, to_payroll:amount1, difference:dif_aggrid, percentage: per_aggrid});
            
            }

            const dif_cpf_employee = data1.cpf_employee != null && data2.cpf_employee != null ? parseFloat((data1.cpf_employee - data2.cpf_employee).toFixed(2)) : '-';
            const per_cpf_employee = data1.cpf_employee != null && data2.cpf_employee != null ? parseFloat((dif_cpf_employee / data1.cpf_employee * 100 ).toFixed(2)) : '-';

            arr.push({ description:'CPF EMPLOYEE', from_payroll: data2?.cpf_employee ?? '-' , to_payroll: data1?.cpf_employee ?? '-', difference:dif_cpf_employee, percentage:per_cpf_employee });

            for(const self of selflist){
                const {value} = self;
                const shg1 = data1.shg;
                const shg2 = data2.shg;

                const amount1 = shg1[value]?.amount ?? '-';
                const amount2 = shg2[value]?.amount ?? '-';

                const dif_shg = amount1 !== '-' && amount1 !== 0 && amount2 !== '-' && amount2 !== 0 ? parseFloat((amount1 - amount2).toFixed(2)) : '-';
                const per_shg = amount1 !== '-' && amount1 !== 0 && amount2 !== '-' && amount2 !== 0 ? parseFloat((dif_shg / amount1 * 100).toFixed(2)) : '-';
                
                arr.push( { description:value, from_payroll:amount2, to_payroll: amount1, difference:dif_shg, percentage:per_shg});
            }

            const dif_cpf_employer = data1.cpf_employer != null && data2.cpf_employer != null ? parseFloat((data1.cpf_employer - data2.cpf_employer).toFixed(2)) : '-';
            const per_cpf_employer = data1.cpf_employer != null && data2.cpf_employer != null ? parseFloat((dif_cpf_employer / data1.cpf_employer * 100 ).toFixed(2)) : '-';

            arr.push({ description:'CPF EMPLOYER', from_payroll: data2?.cpf_employer ?? '-' , to_payroll: data1?.cpf_employer ?? '-', difference:dif_cpf_employer, percentage:per_cpf_employer });

            const sdl1_amount = data1?.sdl ?? '-';
            const sdl2_amount = data2?.sdl ?? '-';

            const dif_sdl = sdl1_amount !== '-' && sdl1_amount !== 0 && sdl2_amount !== '-' && sdl2_amount !== 0 ? parseFloat((sdl1_amount - sdl2_amount).toFixed(2)) : '-';
            const per_sdl = sdl1_amount !== '-' && sdl1_amount !== 0 && sdl2_amount !== '-' && sdl2_amount !== 0 ? parseFloat((dif_sdl / sdl1_amount * 100 ).toFixed(2)) : '-';

            arr.push({ description:'SDL', from_payroll: sdl2_amount, to_payroll: sdl1_amount, difference:dif_sdl, percentage:per_sdl});

            const total_employer1 = data1?.total_employer ?? '-';
            const total_employer2 = data2?.total_employer ?? '-';

            const dif_total_employer = total_employer1 !== '-' && total_employer1 !== 0 && total_employer2 !== '-' && total_employer2 !== 0 ? parseFloat(( total_employer1 - total_employer2).toFixed(2)) : '-';
            const per_total_employer = total_employer1 !== '-' && total_employer1 !== 0 && total_employer2 !== '-' && total_employer2 !== 0 ? parseFloat(( dif_total_employer / total_employer1 * 100 ).toFixed(2)) : '-';
            
            arr.push({ description:'TOTAL EMPLOYER', from_payroll:total_employer2, to_payroll:total_employer1, difference:dif_total_employer, percentage:per_total_employer});
            
            return arr ;
        }
        return [];
    },[selflist,total_agheader,payrolllist,from_payroll,to_payroll])

    const generateExcel = ()=>{
        const data = payrolllist[from_payroll];
        const payroll_month1 = new Date(data.payroll_date).getMonth();
        const from_month = monthNames[payroll_month1];
        const from_year = new Date(data.payroll_date).getFullYear();
        const from_date = `${from_month} ${from_year}`;
        const today = moment().format('DD/MM/YYYY HH:mm:ss');

        const emp_detail = emplist.find(item=>item.id === parseInt(select_emp));

        const emp_name = emp_detail?.name ?? '';
        const emp_number = emp_detail?.employee_number;

        const getRows = () => [
            [
                {
                    data :{
                        value: `Payroll Variance Report - By Employee  For The Month of ${from_date}`,
                        type:'String',
                    }
                }
            ],
            [
                {
                    data:{
                        value: `Printed On : ${today}`,
                        type:'String'
                    }
                }
            ],
            [],
            [
                {
                    data:{
                        value:`Employee Number : ${emp_number}`,
                        type:'String'
                    }
                }
            ],
            [
                {
                    data:{
                        value:`Employee Name : ${emp_name}`,
                        type:'String'
                    }
                }
            ],
            []
        ];

        const getParams = () =>({
            prependContent: getRows(),
            fileName:`PayrollVarianceReportByEmployee_${emp_name}.xlsx`,
            sheetName:'payroll_variance_report',
            columnWidth: 120
        });
        agRef.current.exportDataAsExcel(getParams());
    }

    const shortmonthNames = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const columnDefs = [];

    if(from_payroll !== '' && to_payroll !== ''){
        const data = payrolllist[from_payroll];
        const month1 = new Date(data.payroll_date).getMonth();
        const month = shortmonthNames[month1];
        const year1 = new Date(data.payroll_date).getFullYear();
        const from_date = `${month}-${year1}`;

        const data2 = payrolllist[to_payroll];
        const month2 = new Date(data2.payroll_date).getMonth();
        const month3 = shortmonthNames[month2];
        const year2 = new Date(data2.payroll_date).getFullYear();
        const to_date = `${month3}-${year2}`;

        columnDefs.push(
            {
                headerName:'DESCRIPTION',
                field:'description',
                hide:false,
                editable:false,
            },
            {
                headerName:to_date,
                field:'from_payroll',
                hide:false,
                editable:false,
            },
            {
                headerName:from_date,
                field:'to_payroll',
                hide:false,
                editable:false,
            },
            {
                headerName:'DIFF',
                field:'difference',
                hide:false,
                editable:false,
            },
            {
                headerName:'DIFF %',
                field:'percentage',
                hide:false,
                editable:false
            }
        )
    }

    return(
        <>
        {isLoading || isLoading1 || isLoading2 || isLoading3 ? <OverlayLoading/>:
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title: IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title: IMLocalized('payroll_variance_by_employee')}
                    ]}/>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <div>
                        <Input size="sm" className="width-200px" type="select" value={select_emp} onChange={(e)=> setSelectEmp(e.target.value)} >
                            <option value="">{IMLocalized('select_employee')}</option>
                            {emplist.map((item)=>{
                                return <option value={item.id} key={item.id}>{item.name}</option>
                            })}
                        </Input>
                    </div>
                    {select_emp !== '' && payrolllist.length === 0 && <small className="text-danger">{IMLocalized('employee_no_payroll_list')}</small>}
                    {select_emp !== '' && payrolllist.length !== 0 &&
                    <div className="d-flex align-items-center mt-2">
                        <Input size="sm" className="width-150px" type="select" value={from_payroll} 
                        onChange={handlePayroll}>
                            <option value="">{IMLocalized('select_payroll')}</option>
                            {payrolllist.map((item,index)=>{
                                const payroll_month1 = new Date(item.payroll_date).getMonth();
                                const payroll_month = monthNames1[payroll_month1];
                                const payroll_year = new Date(item.payroll_date).getFullYear();
                                return(
                                    <option value={index} key={item.payroll_date}>{payroll_month} {payroll_year}</option>
                                )
                            })}
                        </Input>
                        <div className="d-flex align-items-center">
                            <small className="ml-2 mr-2 d-flex align-items-center ">{IMLocalized("compare_with")}</small>
                        </div>
                        <Input size="sm" className="width-150px" type="select" value={to_payroll} readOnly={true}>
                            <option value=""></option>
                            {payrolllist.map((item,index)=>{
                                const payroll_month1 = new Date(item.payroll_date).getMonth();
                                const payroll_month = monthNames1[payroll_month1];
                                const payroll_year = new Date(item.payroll_date).getFullYear();
                                return(
                                    <option value={index} key={item.payroll_date}>{payroll_month} {payroll_year}</option>
                                )
                            })}
                        </Input>
                    </div>}
                    {current_step === 1 && to_payroll !== '' && from_payroll !== '' &&
                    <button onClick={generateExcel} className="btn btn-success btn-sm mt-2">{IMLocalized('export')}</button>}
                    {from_payroll !== '' && current_step === 2 && <small className="text-danger">{IMLocalized('payroll_cant_compare_payroll')}</small>}
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <div className="ag-theme-balham" style={{height:action_toggle ? 'calc(100vh - 350px)' : 'calc(100vh - 314px)' , width:'100%'}}>
                        <AgGridReact
                            columnDefs={columnDefs}
                            rowData={rowData}
                            suppressDragLeaveHidesColumns={true}
                            gridOptions={gridOptions}
                            overlayNoRowsTemplate={IMLocalized('no_data')}
                            onGridReady={(params)=>{
                                agRef.current = params.api;
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
        </>}
        </>
    )
}
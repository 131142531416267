

export const relationlist=[
    {
        "value": "SPOUSE",
        "display": "Spouse",
        "display_zh":"伴侣"
    },
    {
        "value": "PARENT",
        "display": "Parent",
        "display_zh":"父母"
    },
    {
        "value": "CHILD",
        "display": "Child",
        "display_zh":"孩子"
    },
    {
        "value": "FRIEND",
        "display": "Friend",
        "display_zh":"朋友"
    },
    {
        "value": "RELATIVE",
        "display": "Relative",
        "display_zh":"亲戚"
    }
]
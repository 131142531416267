import * as contractarrayvalidate from '../../action/contract/contract_contract_array_validate';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const contract_contract_array_validate = (state=initialState,action)=>{
    switch(action.type){
        case contractarrayvalidate.CONTRACTARRAYVALIDATE_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case contractarrayvalidate.CONTRACTARRAYVALIDATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case contractarrayvalidate.CONTRACTARRAYVALIDATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default contract_contract_array_validate;
import React, { useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import { NewMainContext } from "../../../context/NewMainContext";
import { IMLocalized } from "../../../language/IMLocalized";
import BreadCrumbList from "../../breadcrumb/BreadCrumbList";
import UserCalendarTable from "./component/UserCalendarTable";


export default function UserLeaveCalendar(){

    const { addItem } = useContext(NewMainContext);

    

    return(
        <>
        <Container fluid>
            <Row className="bg-white border-bottom">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title:IMLocalized('self_leave')}
                    ]} />
                </Col>
            </Row>
            <UserCalendarTable/>
        </Container>
        </>
    )
}
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATECLAIMAPPROVAL_REQUEST = 'UPDATECLAIMAPPROVAL_REQUEST';
export const UPDATECLAIMAPPROVAL_SUCCESS = 'UPDATECLAIMAPPROVAL_SUCCESS';
export const UPDATECLAIMAPPROVAL_FAILURE = 'UPDATECLAIMAPPROVAL_FAILURE';
export const UPDATECLAIMAPPROVAL_RESET = 'UPDATECLAIMAPPROVAL_RESET';

export const update_claim_approval = (name,start_date,master,approvers,exceptions)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATECLAIMAPPROVAL_REQUEST'
        });

        myaxios.post('claim/approval/',{
            name:name,
            start_date:start_date,
            approval_master:master,
            approvers:approvers,
            exceptions:exceptions
        })
        .then((response)=>{
            dispatch({
                type:'UPDATECLAIMAPPROVAL_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type:'success',
                title:IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATECLAIMAPPROVAL_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('update_failure')
            });
        })
    }
}

export const reset_update_claim_approval = () => dispatch =>{
    dispatch({
        type:'UPDATECLAIMAPPROVAL_RESET'
    });
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row,Col, FormFeedback, Input, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { create_employee_bank, reset_create_employee_bank } from '../../../../action/employee/create_employee_bank';

export default function ModalCreateBank({ toggle, closeModal, id, onSuccess}){

    const dispatch = useDispatch();
    const bank_payee = useSelector(state=>state.dropdown_list.bank_payee);
    const isLoading = useSelector(state=>state.create_employee_bank.isLoading);
    const create_success = useSelector(state=>state.create_employee_bank.data);

    const [bank,setBank] = useState('');
    const [err_bank,setErrBank] = useState('');
    const [account,setAccount] = useState('');
    const [err_account_number,setErrAccount] = useState('');

    useEffect(()=>{
        if(toggle){
            setBank('');
            setErrBank('');
            setErrAccount('');
            setAccount('');
        }
    },[toggle])

    useEffect(()=>{
        if( create_success != null && toggle){
           onSuccess();
           dispatch(reset_create_employee_bank());
        }
    },[create_success, toggle, onSuccess, dispatch])

    const addBank = ()=>{
        if(bank !== '' && account !== ''){
            dispatch(create_employee_bank([{bank,employee:id,account_number:account}]));
        }
        else{
            if(bank === ''){
                setErrBank(IMLocalized('bank_is_required'));
            }
            if(account === ''){
                setErrAccount(IMLocalized('account_number_is_required'));
            }
        }
    }

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('add_bank')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <label className='form-control-label' htmlFor='bank'>{IMLocalized('bank')}</label>
                        <Input size="sm" type="select" name="bank" value={bank} id="bank" onChange={(e)=>{setBank(e.target.value); setErrBank('')}} invalid={err_bank.length >0}>
                            <option></option>
                            {bank_payee.map((item,index)=>{
                                return <option value={item.value} key={index}>{item.display}</option>
                            })}
                        </Input>
                        <FormFeedback>{err_bank}</FormFeedback>
                    </Col>
                    <Col lg="6">
                        <label className='form-control-label' htmlFor='account'>{IMLocalized('account_number')}</label>
                        <Input size="sm" id="account" type="number" name="account" value={account} onChange={(e)=>{setAccount(e.target.value); setErrAccount('') }} invalid={err_account_number.length >0} />
                        <FormFeedback>{err_account_number}</FormFeedback>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading?
                <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button onClick={addBank} className="btn btn-primary btn-sm">{IMLocalized('add')}</button>
                    <button onClick={closeModal} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                </>
                }
            </ModalFooter>
        </Modal>
    )
}
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATELEAVEAPPROVAL_REQUEST = 'UPDATELEAVEAPPROVAL_REQUEST';
export const UPDATELEAVEAPPROVAL_SUCCESS = 'UPDATELEAVEAPPROVAL_SUCCESS';
export const UPDATELEAVEAPPROVAL_FAILURE = 'UPDATELEAVEAPPROVAL_FAILURE';
export const UPDATELEAVEAPPROVAL_RESET = 'UPDATELEAVEAPPROVAL_RESET';


export const update_leave_approval = (name,start_date,master,approvers,exceptions)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATELEAVEAPPROVAL_REQUEST'
        });

        myaxios.post('leave/approval/',{
            name:name,
            start_date:start_date,
            approval_master:master,
            approvers:approvers,
            exceptions:exceptions
        })
        .then((response)=>{
            dispatch({
                type:'UPDATELEAVEAPPROVAL_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type:'success',
                title:IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATELEAVEAPPROVAL_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('update_failure')
            });
        })
    }
}

export const reset_update_leave_approval = () => dispatch =>{
    dispatch({
        type:'UPDATELEAVEAPPROVAL_RESET'
    });
}
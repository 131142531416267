import * as emp from '../../action/employee/get_employee_list';

const initialState ={
    data:[],
    errors:{},
    isLoading:false,
    data1:[]
}

const get_employee_list =(state=initialState,action)=>{
    switch(action.type){
        case emp.GETEMPLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[]
            }
        case emp.GETEMPLIST_SUCCESS:
            return{
                ...state,
                data:action.payload,
                data1:action.payload,
                isLoading:false,
            }
        case emp.GETEMPLIST_FAILURE:
            return{
                ...state,
                errors:action.payload,
                isLoading:false,
            }
        default:
            return state;
    }
}
export default get_employee_list;


export const get_employee_list_detail  = (state,id)=>{
    const { data } = state.get_employee_list;
    return data.length !== 0 && id !== null ? data.find(item=>item.id === id) : null
}
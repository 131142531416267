import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { usePrevious } from '../../../hook/usePrevious';
import { IMLocalized } from '../../../language/IMLocalized';


const mapStateToProps=(state)=>({
    errors:state.create_general_aggrid_template.errors,
})

const mapDispatchToProps=(dispatch)=>({

})

function ModalSaveCustomTempalte(props){
    const {errors} = props;
    const [name,setName] = useState('');
    const [err_name,setErrName] = useState('');
    const prevErrors = usePrevious(errors);

    useEffect(()=>{
        if(prevErrors !== undefined && prevErrors !== errors && errors != null){
            if(errors.hasOwnProperty('non_field_errors')){
                const word = errors['non_field_errors'].toString();
                setErrName(word);
            }
        }
    },[prevErrors,errors])

    useEffect(()=>{
        if(props.toggle){
            setErrName('');
            setName('');
        }
    },[props.toggle])

    const saveProfile =()=>{
        if(name !== ''){
            props.onSave(name);
        }   
        else{
            setErrName(IMLocalized('name_is_required'));
        }
    }

    return(
        <Modal isOpen={props.toggle} size="modal-dialog-centered">
            <ModalHeader toggle={props.closeModal}>{IMLocalized('save_as_report_profile')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>{IMLocalized('name')}</label>
                            <Input size="sm" type="text" value={name} id="name" invalid={err_name.length > 0}
                            onChange={(e)=>{
                                const value = e.target.value;
                                setName(value);
                                setErrName('');
                            }} />
                            <FormFeedback>{err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button className='btn btn-primary btn-sm' onClick={saveProfile}>{IMLocalized('save')}</button>
                <button className="btn btn-danger btn-sm" onClick={props.closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalSaveCustomTempalte);
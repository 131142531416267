import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const PAYROLLCONFIRMATION_REQUEST = 'PAYROLLCONFIRMATION_REQUEST';
export const PAYROLLCONFIRMATION_SUCCESS = 'PAYROLLCONFIRMATION_SUCCESS';
export const PAYROLLCONFIRMATION_FAILURE = 'PAYROLLCONFIRMATION_FAILURE';


export const confirm_payroll =(id)=>{
    return dispatch=>{
        dispatch({
            type:'PAYROLLCONFIRMATION_REQUEST'
        });

        myaxios.post(`payroll/payroll/${id}/ConfirmPayroll/`)
        .then((response)=>{
            dispatch({
                type:'PAYROLLCONFIRMATION_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('finalize_success')
            })
        })
        .catch((error)=>{
            dispatch({
                type:'PAYROLLCONFIRMATION_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('finalize_failure')
            });
        })
    }
}
import * as wizardcreatesection from '../../action/wizard/wizard_create_section';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const wizard_create_section =(state=initialState,action)=>{
    switch(action.type){
        case wizardcreatesection.WIZARDCREATESECTION_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case wizardcreatesection.WIZARDCREATESECTION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case wizardcreatesection.WIZARDCREATESECTION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default wizard_create_section;
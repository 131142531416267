import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import '../../../css/scroll.css';
import EmployeeProfile from './EmployeeEmployeeProfile';
import AwardList from './EmployeeAwardList';
import ViewAll from './EmployeeAllLeaveView';
import ViewDetail from './EmployeeLeaveDetailView';
import ViewClaimDetail from './EmployeeClaimDetailView';
import ViewClaimAll from './EmployeeAllClaimView';
import { NewMainContext } from '../../../context/NewMainContext';
import EmployeeList from './EmployeeList';
import { reset_get_employee_detail } from '../../../action/employee/get_employee_detail';


export default function EmployeeMain(){
    const dispatch = useDispatch();
    const { emplist_reset, setEmpListReset, employeelist_step, setEmployeeListStep, employee_profile_id, setEmpProfileId, emp_step,setEmpStep } = useContext(NewMainContext);

    const [emp_award_id,setEmpAwardID] = useState('');
    const [emp_allleave_view,setEmpAllLeaveView] = useState('');
    const [emp_leave_detail_id,setEmpLeaveDetailID] = useState('');
    const [emp_leave_detail_leave,setEmpLeaveDetailLeave] = useState('');
    const [emp_allclaim_view,setEmpAllClaimView] = useState('');
    const [emp_claim_detail_id,setEmpClaimDetailID] = useState('');
    const [emp_claim_detail_claim,setEmpClaimDetailClaim] = useState('');

    useEffect(()=>{
        if(emplist_reset === true){
            dispatch(reset_get_employee_detail());
            setEmpListReset();
        }
    },[emplist_reset,setEmpListReset,dispatch])


    useEffect(()=>{

        function getAwardID(){
            const id = localStorage.getItem('emp_awardlist_id');
            setEmpAwardID(id);
        }

        function getAllLeaveView(){
            const id = localStorage.getItem('emp_allleave_view_id');
            setEmpAllLeaveView(id);
        }

        function getLeaveDetail(){
            const id = localStorage.getItem('emp_leave_detail_view_id');
            const leavesetting = localStorage.getItem('emp_leave_detail_view_setting');
            setEmpLeaveDetailID(id);
            setEmpLeaveDetailLeave(leavesetting);
        }

        function getAllClaimView(){
            const id = localStorage.getItem('emp_claim_all_view_id');
            setEmpAllClaimView(id);
        }

        function getClaimDetail(){
            const id = localStorage.getItem('emp_claim_detail_view_id');
            const setting = localStorage.getItem('emp_claim_detail_view_setting');
            setEmpClaimDetailID(id);
            setEmpClaimDetailClaim(setting);
        }

        if(employeelist_step === '2'){
            getAwardID();
        }
        if(employeelist_step === '3'){
            getAllLeaveView();

        }
        if(employeelist_step === '4'){
            getLeaveDetail();
        }
        if(employeelist_step === '5'){
            getClaimDetail();
        }
        if(employeelist_step === '6'){
            getAllClaimView();
        }
    },[employeelist_step])

    const toEmployeeList=(id)=>{
        setEmployeeListStep(id); 
        localStorage.setItem('employeelist_step',id); 
        setEmpStep('1'); 
        localStorage.setItem('emp_step','1'); 
        setEmpProfileId(null)
        dispatch(reset_get_employee_detail());
    }

    const toViewDetail=(id,leave)=>{
        setEmployeeListStep('4'); 
        localStorage.setItem('employeelist_step','4'); 
        localStorage.setItem('emp_leave_detail_view_id',id); 
        localStorage.setItem('emp_leave_detail_view_setting',leave); 
    }

    const toViewClaimDetail=(id,claim)=>{
        setEmployeeListStep('5'); 
        localStorage.setItem('employeelist_step','5'); 
        localStorage.setItem('emp_claim_detail_view_id',id); 
        localStorage.setItem('emp_claim_detail_view_setting',claim);
    }

    const toViewClaimAll=(id)=>{
        setEmployeeListStep('6'); 
        localStorage.setItem('employeelist_step','6'); 
        localStorage.setItem('emp_claim_all_view_id',id);
    }

    const toAwardList = (id) =>{
        setEmployeeListStep('2'); 
        localStorage.setItem('employeelist_step','2'); 
        localStorage.setItem('emp_awardlist_id',id);
    }
    
    const toViewAll = (id) =>{
        setEmployeeListStep('3'); 
        localStorage.setItem('employeelist_step','3'); 
        localStorage.setItem('emp_allleave_view_id',id); 
    }

    const toEmployeeProfile =(id)=>{
        setEmployeeListStep(id); 
        localStorage.setItem('employeelist_step',id);
    }

    return(
        <>
            {employeelist_step === '0' && <EmployeeList />}
            {employeelist_step === '1' && 
            <EmployeeProfile 
                step={emp_step} 
                id={employee_profile_id} 
                getEmployeeInfo={(id)=>setEmpProfileId(id)} 
                toEmployeeList={toEmployeeList}
                toViewDetail={toViewDetail} 
                toViewClaimDetail={toViewClaimDetail} 
                toViewClaimAll={toViewClaimAll} 
                toAwardList={toAwardList} 
                toViewAll={toViewAll} 
            />}
            {employeelist_step === '2' && 
            <AwardList id={emp_award_id} 
                toEmployeeList={toEmployeeList} 
                toEmployeeProfile={toEmployeeProfile} 
            />}
            {employeelist_step === '3' && 
            <ViewAll id={emp_allleave_view}  
                toEmployeeList={toEmployeeList} 
                toEmployeeProfile={toEmployeeProfile} 
            />}
            {employeelist_step === '4' && 
            <ViewDetail 
                id={emp_leave_detail_id} 
                leave_setting={emp_leave_detail_leave}  
                toEmployeeList={toEmployeeList} 
                toEmployeeProfile={toEmployeeProfile}    
            />}
            {employeelist_step === '5' && 
            <ViewClaimDetail 
                id={emp_claim_detail_id} 
                claim_type={emp_claim_detail_claim}  
                toEmployeeList={toEmployeeList}
                toEmployeeProfile={toEmployeeProfile}    
            />}
            {employeelist_step === '6' && 
            <ViewClaimAll 
                id={emp_allclaim_view} 
                toEmployeeList={toEmployeeList}
                toEmployeeProfile={toEmployeeProfile}    
           />}
        </>
    )
}
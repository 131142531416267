import * as sg_address from '../../action/wizard/wizard_get_sg_postal';

const initialState={
    data:[],
    errors:[],
    isLoading:false,
    address:null
}

const wizard_get_sg_postal = (state=initialState,action)=>{
    switch(action.type){
        case sg_address.WIZARDSGPOSTAL_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[],
                data:null,
                address:null,
            }
        case sg_address.WIZARDSGPOSTAL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data,
                address:action.payload?.data?.results,
                errors:[]
            }
        case sg_address.WIZARDSGPOSTAL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload,
                address:'',
                data:[]
            }
        default:
            return state;
    }
}
export default wizard_get_sg_postal;

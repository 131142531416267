import * as company_bank from '../../action/company/create_company_bank';

const initialState={
    isLoading:false,
    errors:null,
    data:null
}

const create_company_bank = (state=initialState,action)=>{
    switch(action.type){
        case company_bank.CREATECOMBANK_REQUEST:
            return{
                isLoading:true,
                errors:null,
                data:null
            }
        case company_bank.CREATECOMBANK_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case company_bank.CREATECOMBANK_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case company_bank.CREATECOMBANK_RESET:
            return{
                ...state,
                data:null,
                errros:null
            }
        default:
            return state;
    }
}
export default create_company_bank;
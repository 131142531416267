import * as createtask from '../../action/task/create_task_list';


const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const create_task_list =(state=initialState,action)=>{
    switch(action.type){
        case createtask.CREATETASKLIST_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case createtask.CREATETASKLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case createtask.CREATETASKLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default create_task_list;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Container, FormFeedback, FormGroup, Row, Input } from 'reactstrap';
import { getCompanyDropdown } from '../../action/company_dropdown';
import { dropdown_list } from '../../action/dropdown_list';
import { company_validate } from '../../action/wizard/company_validate';
import { wizard_get_sg_postal } from '../../action/wizard/wizard_get_sg_postal';
import { usePrevious } from '../../hook/usePrevious';
import { popError } from '../../func/popError';
import { IMLocalized } from '../../language/IMLocalized';
import OverlayLoading from '../loading_component/overlay_loading';

export default function CompanyDetail(props){
    const {setData} = props;
    const dispatch = useDispatch();
    const isLoading = useSelector(state=>state.company_dropdown.isLoading);
    const isLoading1 = useSelector(state=>state.dropdown_list.isLoading);
    const dropdown = useSelector(state=>state.company_dropdown.cpf);
    const code = useSelector(state=>state.wizard_get_sg_postal.address);
    const validate = useSelector(state=>state.company_validate.data);
    const errors = useSelector(state=>state.company_validate.errors);

    const [ name, setName] = useState('');
    const [ contact_number, setContactNumber] = useState('');
    const [ cpf_payment_type, setCpfPaymentType] = useState('');
    const [ uen, setUen] = useState('');
    const [ cpf_serial_number, setCpfSerialNumber] = useState('');
    const [ unit_number, setUnitNumber] = useState('');
    const [ sg_postal_code, setSgPostalCode] = useState('');
    const [ address, setAddress] = useState('');

    const [ err_name, setErrName] = useState('');
    const [ err_contact_number, setErrContactNumber] = useState('');
    const [ err_cpf_payment_type ,setErrCpfPaymentType] = useState('');
    const [ err_uen, setErrUen] = useState('');
    const [ err_cpf_serial_number, setErrCpfSerialNumber] = useState('');
    const [ err_unit_number, setErrUnitNumber] = useState('');
    const [ err_sg_postal_code, setErrSgPostalCode] = useState('');
    const [ err_address, setErrAddress] = useState('');
    const prevValidate = usePrevious(validate);

    useEffect(()=>{
        if(errors != null){
            if(errors.hasOwnProperty('name')){
                const word = errors['name'].toString();
                setErrName(word);
            }
            if(errors.hasOwnProperty('contact_number')){
                const word = errors['contact_number'].toString();
                setErrContactNumber(word);
            }
            if(errors.hasOwnProperty('UEN')){
                const word = errors['UEN'].toString();
                setErrUen(word);
            }
            if(errors.hasOwnProperty('CPF_payment_type')){
                const word = errors['CPF_payment_type'].toString();
                setErrCpfPaymentType(word);
            }
            if(errors.hasOwnProperty('CPF_serial_number')){
                const word = errors['CPF_serial_number'].toString();
                setErrCpfSerialNumber(word);
            }
            if(errors.hasOwnProperty('unit_number')){
                const word = errors['unit_number'].toString();
                setErrUnitNumber(word);
            }
            if(errors.hasOwnProperty('sg_postal_code')){
                const word = errors['sg_postal_code'].toString();
                setErrSgPostalCode(word);
            }
            if(errors.hasOwnProperty('address')){
                const word = errors['address'].toString();
                setErrAddress(word);
            }
        }
    },[errors])

    useEffect(()=>{
        if(prevValidate !== undefined && prevValidate !== validate && validate != null){
            setData({name,contact_number,UEN:uen,CPF_payment_type:cpf_payment_type,CPF_serial_number:cpf_serial_number,unit_number,sg_postal_code,address})
        }
    },[prevValidate,validate,setData,name,contact_number,uen,cpf_payment_type,cpf_serial_number,sg_postal_code,unit_number,address])

    useEffect(()=>{
        dispatch(getCompanyDropdown());
        dispatch(dropdown_list());
    },[dispatch])

    useEffect(()=>{
        if(code != null){
            if(code.length !== 0){
                const {ADDRESS} = code[0];
                setAddress(ADDRESS);
                setErrAddress('');
            }
            else{
                popError(IMLocalized('address_not_found'));
            }
        }
    },[code])

    const onSearch=()=>{
        const reg = /^[0-9]{6}$/;
        if(!reg.test(sg_postal_code)){
            setErrSgPostalCode(IMLocalized('invalid_sg_postal_code'));
        }
        else{
            dispatch(wizard_get_sg_postal(sg_postal_code));
        }
    }

    const onSubmit=()=>{
        const reg1 = /^[0-9]{6}$/;
        const reg2 = /^[0-9]{2}$/;

        if(name !== '' && contact_number !== '' && cpf_payment_type !== '' && cpf_serial_number !== '' && sg_postal_code !== '' && address !== '' && uen !=='' ){
            dispatch(company_validate(name,contact_number,uen,cpf_payment_type,cpf_serial_number,unit_number,sg_postal_code,address))
        }
        else{
            if(name === ''){
                setErrName(IMLocalized('name_is_required'))
            }
            if(contact_number === ''){
                setErrContactNumber(IMLocalized('contact_number_is_required'))
            }
            if(cpf_payment_type === ''){
                setErrCpfPaymentType(IMLocalized('cpf_payment_type_is_required'));
            }
            if(cpf_serial_number === ''){
                setErrCpfSerialNumber(IMLocalized('cpf_serial_number_is_required'));
            }
            else if(!reg2.test(cpf_serial_number)){
                setErrCpfSerialNumber(IMLocalized('invalid_cpf_serial_number'));
            }
            if(unit_number === ''){
                setErrUnitNumber(IMLocalized('unit_number_is_required'));
            }
            if(address === ''){
                setErrAddress(IMLocalized('address_is_required'));
            }
            if(sg_postal_code === ''){
                setErrSgPostalCode(IMLocalized('sg_postal_code_is_required'));
            }
            else if(!reg1.test(sg_postal_code)){
                setErrSgPostalCode(IMLocalized('invalid_sg_postal_code'));
            }
            if(uen === ''){
                setErrUen(IMLocalized('uen_is_required'));
            }
        }
    }

    return(
        <>
        {isLoading || isLoading1 ? <OverlayLoading/>:
        <Container className='mt-2' fluid>
            <Row className='justify-content-center'>
                <Col lg="8">
                    <Card>
                        <CardHeader>
                            <h4 className='mb-0'>{IMLocalized('company_detail')}</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='name'>{IMLocalized('name')}</label>
                                        <Input id="name" type="text" size="sm" value={name} onChange={(e)=>{setName(e.target.value); setErrName('') }} invalid={err_name.length >0}  />
                                        <FormFeedback>{err_name}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='contact_number'>{IMLocalized('contact_number')}</label>
                                        <Input id="contact_number" type="number" size="sm" value={contact_number} onChange={(e)=>{setContactNumber(e.target.value); setErrContactNumber('')}} invalid={err_contact_number.length >0} />
                                        <FormFeedback>{err_contact_number}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='uen'>UEN</label>
                                        <Input id="uen" type="text" size="sm" value={uen} invalid={err_uen.length > 0 } onChange={(e)=>{setUen(e.target.value); setErrUen('')}} />
                                        <FormFeedback>{err_uen}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='cpf_payment_type'>CPF Payment Type</label>
                                        <Input id="cpf_payment_type" type="select" size="sm" value={cpf_payment_type} invalid={err_cpf_payment_type.length >0} onChange={(e)=>{setCpfPaymentType(e.target.value); setErrCpfPaymentType('') }} >
                                            <option></option>
                                            {dropdown.map((item,index)=>{
                                                return <option value={item.value} key={index}>{item.display}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{err_cpf_payment_type}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='cpf_serial_number'>CPF Serial Number</label>
                                        <Input size="sm" id="cpf_serial_number" min="0" type="number" value={cpf_serial_number} invalid={err_cpf_serial_number.length >0 } onChange={(e)=>{setCpfSerialNumber(e.target.value); setErrCpfSerialNumber('')}} />
                                        <FormFeedback>{err_cpf_serial_number}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='sg_postal_code'>{IMLocalized('sg_postal_code')}</label>
                                        <div className='input-group'>
                                            <Input id="sg_postal_code" type="number" size="sm" value={sg_postal_code} invalid={err_sg_postal_code.length > 0 } onChange={(e)=>{setSgPostalCode(e.target.value); setErrSgPostalCode('') }} />
                                            <div className='input-group-append'>
                                                <button className='btn btn-primary btn-sm' onClick={onSearch}>{IMLocalized('autofill')}</button>
                                            </div>
                                            <FormFeedback>{err_sg_postal_code}</FormFeedback>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='address'>{IMLocalized('address')}</label>
                                        <Input id="address" type="textarea" size="sm" value={address} invalid={err_address.length >0} onChange={(e)=>{setAddress(e.target.value); setErrAddress('')}} />
                                        <FormFeedback>{err_address}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='unit_number'>{IMLocalized('unit_number')}</label>
                                        <Input id="unit_number" type="text" size="sm" value={unit_number} invalid={err_unit_number.length >0} onChange={(e)=>{setUnitNumber(e.target.value); setErrUnitNumber('')}} />
                                        <FormFeedback>{err_unit_number}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <div className='create_btn_parent'>
                <i className='fas fa-arrow-circle-right text-success cursor-pointer create_btn' onClick={()=>onSubmit()}  />
            </div>
        </Container>}
        </>
    )
}
import React, { useEffect, useState } from 'react';
import '../../css/progress.css';
import { IMLocalized } from '../../language/IMLocalized';

const ProgressBar = ({ value, maxValue }) => {

  const [loadingNumber, setLoadingNumber]= useState(0);

  const calculateProgress = () => {
    let progress = (value / maxValue) * 100;
    
    if(value === NaN|| maxValue === NaN){
        return progress = 1;
    }else{
        // const progress = (value / maxValue) * 100;
        return progress.toFixed(2);
    }
  };

  useEffect(()=>{
    setLoadingNumber((value / maxValue) * 100)
    if(loadingNumber===NaN || value === undefined){
      setLoadingNumber(1)
    }else{
      setLoadingNumber(calculateProgress)
    }
    },[value,maxValue,calculateProgress,loadingNumber])

  return (
    <div className="progress-bar__container">
    <div className="progress-bar">
      <div
        className="progress-bar__fill"
        style={{ width: `${calculateProgress()}%` }}
        // style={{ width: calculateProgress() }}
      />
    </div>
    <div style={{color:"#0253cc"}}>{IMLocalized('exporting')}...</div>
    </div>
  );
};

// progress_bar.defaultProps = {
//     value: 10,
//     maxValue: 100,
//   };

export default ProgressBar;

import myaxios from '../../axios';

export const GETUSERMANAGERLIST_REQUEST = 'GETUSERMANAGERLIST_REQUEST';
export const GETUSERMANAGERLIST_SUCCESS = 'GETUSERMANAGERLIST_SUCCESS';
export const GETUSERMANAGERLIST_FAILURE = 'GETUSERMANAGERLIST_FAILURE';

export const get_user_manager_list = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETUSERMANAGERLIST_REQUEST'
        });

        myaxios.get('employee/users/ManagerList/')
        .then((response)=>{
            dispatch({
                type:'GETUSERMANAGERLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETUSERMANAGERLIST_FAILURE',
                payload:error
            })
        })
    }
}
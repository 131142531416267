import myaxios from '../axios';



export const GETOTPBLACKLIST_REQUEST = 'GETOTPBLACKLIST_REQUEST';
export const GETOTPBLACKLIST_SUCCESS = 'GETOTPBLACKLIST_SUCCESS';
export const GETOTPBLACKLIST_FAILURE = 'GETOTPBLACKLIST_FAILURE';


export const get_otp_blacklist = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETOTPBLACKLIST_REQUEST'
        });

        myaxios.get('otp/blacklist/')
        .then((response)=>{
            dispatch({
                type:'GETOTPBLACKLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETOTPBLACKLIST_FAILURE',
                payload:error
            })
        })
    }
}
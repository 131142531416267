import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledTooltip } from "reactstrap";
import { get_nextprev_employee } from "../../../action/employee/get_nextprev_employee";
import { NewMainContext } from "../../../context/NewMainContext";
import { IMLocalized } from "../../../language/IMLocalized";
import { get_employee_list_detail } from "../../../reducers/employee/get_employee_list";
import Select from 'react-select';
import OverlayLoading from "../../loading_component/OverlayLoading";
import blank_picture from '../../../images/blank_picture.png';
import { getDateFormat } from "../../../func/getDateFormat";
import classnames from 'classnames';
import EmpClaim from "./EmpClaim";
import { ClaimOfficerEmployeeContext } from "../../../context/ClaimOfficerEmployeeContext";
import { emp_get_employee_picture_list } from "../../../reducers/employee/get_employee_picture_list";
import { get_employee_profile_picture, reset_get_employee_profile_picture } from "../../../action/employee/get_employee_profile_picture";
import { Buffer } from "buffer";
import BreadCrumbList from "../../breadcrumb/BreadCrumbList";

export default function EmployeeProfile(){

    const {toEmployeeList, employee_profile_id: id, toViewAll, toViewDetail, setEmployeeProfileId } = useContext(ClaimOfficerEmployeeContext);
    const { addItem } = useContext(NewMainContext);
    const dispatch = useDispatch();
    const prevname = useSelector(state=>state.get_nextprev_employee.prevname);
    const prevurl = useSelector(state=>state.get_nextprev_employee.previd);
    const nextname = useSelector(state=>state.get_nextprev_employee.nextname);
    const nexturl = useSelector(state=>state.get_nextprev_employee.nextid);
    const searchlist = useSelector(state=>state.get_nextprev_employee.searchlist);
    const emp_data = useSelector(state=>get_employee_list_detail(state,parseInt(id)));
    const isLoading = useSelector(state=>state.get_nextprev_employee.isLoading);
    const picData = useSelector(state=>emp_get_employee_picture_list(state,parseInt(id)));
    const picture = useSelector(state=>state.get_employee_profile_picture.data);

    const [profile_picture, setProfilePicture] = useState(null);
 
    useEffect(()=>{
        if(picture.length !== 0){
            const img = new Buffer.from(picture,'binary').toString('base64');
            const src = `data:image/png;base64,${img}`;
            setProfilePicture(src);
        }
        else{
            setProfilePicture(null);
        }
    },[picture])

    useEffect(()=>{
        if(picData){
            dispatch(get_employee_profile_picture(id))
        }
        else{
            dispatch(reset_get_employee_profile_picture());
        }
    },[picData,dispatch,id])

    useEffect(()=>{
        if(id != null){
            dispatch(get_nextprev_employee(id));
        }
    },[id,dispatch])

    const onPrev = () =>{
        const id = localStorage.getItem('prevurl');
        localStorage.setItem('claim_emp_profile_id',prevurl);
        setEmployeeProfileId(id);
    }

    const onNext = () =>{
        const id = localStorage.getItem('nexturl');
        localStorage.setItem('claim_emp_profile_id',nexturl);
        setEmployeeProfileId(id);
    }

    const onSearch = (value) =>{
        setEmployeeProfileId(value);
        localStorage.setItem('claim_emp_profile_id',value);
    }


    const searchbar = searchlist.sort((a,b)=> a.number - b.number).map(item=>{
        return { value:item.id, label: item.name}
    })

    return(
        <>
        {isLoading ? <OverlayLoading/>:
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: false, title:IMLocalized('employee'), onClick:toEmployeeList},
                        { active: true, title:emp_data?.name ?? '-'}
                    ]}
                    />
                    <Row>
                        <Col lg="2">
                            <div className="ml-2">
                                <button onClick={toEmployeeList} className="btn btn-primary btn-sm"> <i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                            </div>
                        </Col>
                        <Col>
                            <div className="pt-2">
                                <div className="d-flex justify-content-center">
                                    <button className="btn btn-primary nextprev_btn mr-0" id="profile_prevname" onClick={(e)=>{onPrev(e)}}>{prevname}
                                        <UncontrolledTooltip delay={0} placement="bottom" target="profile_prevname">
                                            {prevname}
                                        </UncontrolledTooltip>
                                    </button>
                                    <Select styles={{control:(base,_state) =>({...base,minHeight:'43px',width:'200px',height:'43px' })}} options={searchbar} value={null} onChange={value=> onSearch(value.value)}></Select>
                                    <button className="btn btn-primary nextprev_btn" id="profile_nextname" onClick={(e)=>onNext(e)}>{nextname}
                                        <UncontrolledTooltip delay={0} placement="bottom" target="profile_nextname">
                                            {nextname}
                                        </UncontrolledTooltip>
                                    </button>
                                </div>
                            </div>
                        </Col>
                        <Col lg="2"></Col>
                    </Row>
                    
                </Col>
            </Row>
        </Container>
        <Container className="bg-white"  style={{minHeight:'calc(100vh - 193px)'}}>
            <Row>
                <Col xs="12" sm="4" md="3" lg="2" className="pt-2 bg_title border-left" >
                    <img src={profile_picture ?? blank_picture } alt={profile_picture ? "user-profile" : "blank" } className="img-thumbnail" style={{height:'150px' ,width:'150px'}} />
                </Col>
                <Col xs="12" sm="8" md="9" lg="10" className=" border-right profile_border_left">
                    <Row>
                        <Col sm="12" className="pt-3">
                            <div className="title_name">
                                <h1>{emp_data?.name ?? '-'}</h1>
                            </div>
                        </Col>
                        <Col sm="12" className="pt-2">
                            <label>{emp_data?.employee_number ?? '-'}</label>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="information_table">
                <Col lg="2" className="pt-2 bg_title border-left" >
                    <div className="information_group">
                        <div className="information" id="tooltipdepartment">
                            <UncontrolledTooltip target="tooltipdepartment" delay={0} placement="bottom" >
                                {IMLocalized('department')}
                            </UncontrolledTooltip>
                            <i className="fas fa-building fa-black pr-2"></i>
                            <small>{emp_data?.contract_cache?.department ?? '-'}</small>
                        </div>
                        <div className="information" id="tooltipjobtitle">
                            <UncontrolledTooltip target="tooltipjobtitle" delay={0} placement="bottom">
                                {IMLocalized('job_title')}
                            </UncontrolledTooltip>
                            <i className="fas fa-address-card fa-black pr-2"></i>
                            <small>{emp_data?.contract_cache?.job_title ?? '-'}</small>
                        </div>
                        <div className="information" id="tooltipjoblevel">
                            <UncontrolledTooltip target="tooltipjoblevel" delay={0} placement="bottom">
                                {IMLocalized('job_level')}
                            </UncontrolledTooltip>
                            <i className="fas fa-address-book fa-black pr-2"></i>
                            <small>{emp_data?.contract_cache?.job_level ?? '-'}</small>
                        </div>
                        <hr className="m-2" />
                        <div className="information" id="tooltipcontactnumber">
                            <UncontrolledTooltip target="tooltipcontactnumber" delay={0} placement="bottom">
                                {IMLocalized('contact_number')}
                            </UncontrolledTooltip>
                            <i className="fas fa-mobile-alt fa-black pr-2"></i>
                            <small>{emp_data?.contact_number != null && emp_data?.contact_number.length !== 0 ? emp_data.contact_number : '-'}</small>
                        </div>
                        <hr className="m-2"/>
                        <div className="information">
                            <h5>{IMLocalized('join_date')}</h5>
                            <small>{emp_data?.join_date ? getDateFormat(emp_data?.join_date) : '-' }</small>
                        </div>
                    </div>
                </Col>
                <Col lg="10" className="border-right border-left">
                    <Nav tabs>
                        <NavItem className="cursor-pointer">
                            <NavLink className={classnames({ active: true })}>
                                {IMLocalized('claim')}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab='1'>
                        <TabPane tabId='1'>
                            <EmpClaim id={id} toViewAll={toViewAll} toViewDetail={toViewDetail}  />
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </Container>
        </>}
        </>
    )
}
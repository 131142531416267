import * as updateleave from '../action/update_leave';

const initialState={
    isLoading:false,
    errors:null,
    data:null
}

const update_leave = (state=initialState,action)=>{
    switch(action.type){
        case updateleave.UPDATELEAVE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case updateleave.UPDATELEAVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updateleave.UPDATELEAVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case updateleave.UPDATELEAVE_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default update_leave;
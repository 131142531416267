import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {AgGridReact} from 'ag-grid-react';
import ExcelJs from 'exceljs';
import FileSaver from 'file-saver';
import { Container, Row, Col,Input } from 'reactstrap';
import {headcount_get_aggridheader} from '../../action/report/headcount_get_aggridheader';
import {headcount_payroll_instance} from '../../action/report/headcount_payroll_instance';
import moment from 'moment';
import { IMLocalized } from '../../language/IMLocalized';
import OverlayLoading from '../loading_component/overlay_loading';
import { getColumnName } from '../../func/getColumnName';
import { getValue } from '../../func/getValue';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';

const mapStateToProps=(state)=>({
    payroll_list:state.get_payroll_list.data,
    agheader:state.headcount_get_aggridheader.data,
    data:state.headcount_payroll_instance.data,
    selflist:state.dropdown_list.self_help_group,
    isLoading:state.headcount_get_aggridheader.isLoading,
    isLoading1:state.headcount_payroll_instance.isLoading,
})

const mapDispatchToProps=(dispatch)=>({
    headcount_get_aggridheader:(id)=>{
        dispatch(headcount_get_aggridheader(id))
    },
    headcount_payroll_instance:(id)=>{
        dispatch(headcount_payroll_instance(id))
    }
})

const gridOptions={
    defaultColDef:{
        suppressMenu:true,
        resizable:true,
        filter:true,
        editable:false
    },
    sideBar:{
        toolPanels:[
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivots: true,
                    suppressPivotMode: true,
                    suppressColumnFilter: false,
                    suppressColumnSelectAll: false,
                    suppressColumnExpandAll: false,
                },
            },
        ],
        defaultToolPanel:'columns'
    },
}

const initialState={
    select_month:'',
    rowData:[],
    columnDefs:[],

}

function PayrollComputationHeadcount(props){
    const {headcount_get_aggridheader,headcount_payroll_instance} = props;
    const [state,setState] = useState(initialState);
    const {action_toggle, addItem, payroll_computation_headcount_reset, setPayrollComputationHeadcountReset } = useContext(NewMainContext);
    const [monthNames] = useState(["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
    ]);
    const [monthNames1] = useState([IMLocalized('january'), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
    IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")
    ]);
    
    const gridApi = useRef();
    const gridColumnApi = useRef();

    useEffect(()=>{
        if(payroll_computation_headcount_reset){
            setState(initialState);
            setPayrollComputationHeadcountReset(false);
        }
    },[payroll_computation_headcount_reset,setPayrollComputationHeadcountReset])

    useEffect(()=>{
        if(state.select_month !==''){
            headcount_get_aggridheader(state.select_month);
            headcount_payroll_instance(state.select_month);
        }
        else{
            setState(prevState=>({...prevState,columnDefs:[],rowData:[]}))
        }
    },[state.select_month,headcount_get_aggridheader,headcount_payroll_instance])

    useEffect(()=>{
        let newlist = [];
        if(props.agheader != null && state.select_month !== ''){
            const {ag_grid} = props.agheader;
            newlist=[
                {
                    headerName:'EMP ID',
                    field:'employee.employee_number',
                    hide:false,
                    editable:false,
                    width:100
                },
                {
                    headerName:'EMP NAME',
                    field:'employee.name',
                    width:150,
                    hide:false
                },
                {
                    headerName:'COST CENTER',
                    field:'employee.contract_cache.cost_center',
                    width:150,
                    hide:false,
                },
                {
                    headerName:'DEPARTMENT',
                    field:'employee.contract_cache.department',
                    width:150,
                    hide:false,
                },
                {
                    headerName:'SECTION',
                    hide:false,
                    width:150,
                    field:'employee.contract_cache.section',
                },
                {
                    headerName:'JOB TITLE',
                    hide:false,
                    width:150,
                    field:'employee.contract_cache.job_title'
                },
                {
                    headerName:'JOB LEVEL',
                    hide:false,
                    width:150,
                    field:'employee.contract_cache.job_level'
                },
                {
                    headerName:'COMPANY',
                    hide:false,
                    width:150,
                    field:'employee.metadata.company'
                },
                {
                    headerName:'GROSS SALARY',
                    field:'basic_pay',
                    width:150,
                    editable:false,
                    hide:false
                },
                {
                    headerName:'NETT SALARY',
                    field:'payable_wage',
                    filter:'agNumberColumnFilter',
                    width:150,
                    editable:false,
                    hide:false,
                },
                {
                    headerName:'TOTAL OVERTIME',
                    field:'total_overtime',
                    width:150,
                    editable:false,
                    hide:false,
                },
                {
                    headerName:"TOTAL ALLOWANCE",
                    field:'total_allowance',
                    width:150,
                    editable:false,
                    hide:false,
                },
                {
                    headerName:"TOTAL FIXED RATE",
                    field:'total_fixed',
                    width:150,
                    editable:false,
                    hide:false,
                }
            ]

            for(const ag_item of ag_grid){
                const headerName = ag_item.replace('-','.');
                const field = `aggrid.${ag_item.replace('.','-')}.amount`;
                const width = 150;
                const hide = false;
                newlist.push({headerName,field,width,hide});
            }
            newlist.push({headerName:'CPF EMPLOYEE',field:'cpf_employee',width:100,hide:false});
            for(const shg_item of props.selflist){
                const headerName = shg_item.value;
                const field = `shg.${shg_item.value}.amount`;
                const width = 150;
                const hide = false;
                newlist.push({headerName,field,width,hide});
            }
            newlist.push({headerName:'CPF EMPLOYER',field:'cpf_employer',width:150,hide:false}); 
            newlist.push({headerName:'SDL',field:'sdl',width:150,hide:false});
            newlist.push({headerName:'TOTAL EMPLOYER',field:'total_employer',width:150,hide:false});    
        }
        
        setState(prevState=>({...prevState,columnDefs:newlist}));
    },[props.agheader,props.selflist,state.select_month])

    useEffect(()=>{
        if(props.data.length !== 0 && state.select_month !== ''){
            const {payslips} = props.data[0];
            const arr = payslips.map(item=>{
                const {sgcpf,payslipcontract,pay,sgshg} = item;
                const cpf_employee = sgcpf?.cpf_employee ?? 0;
                const cpf_employer = sgcpf?.cpf_employer ?? 0;
                const basic_pay = payslipcontract.reduce((prev,current)=>prev+current.gross_month,0);
                const aggrid = {};
                const shg = {};
                for(const pay_item of pay){
                    const {id,amount,code} = pay_item;
                    const name = code.replace('.','-');
                    aggrid[name] = {amount,id};
                }

                for(const shg_item of sgshg){
                    const {amount,id} = shg_item;
                    const name = shg_item.name;
                    shg[name] = {amount,id};
                }

                const total_overtime = pay.filter(el=>el.pay_type === 'OT').reduce((prev,current)=>prev+current.amount,0);
                const total_allowance = pay.filter(el=>el.pay_type === 'ALLOWANCE').reduce((prev,current)=>prev+current.amount,0);
                const total_fixed = pay.filter(el=>el.pay_type === 'FX').reduce((prev,current)=>prev+current.amount,0);
                return {...item,total_fixed,total_allowance,total_overtime,shg,basic_pay,cpf_employee,cpf_employer,aggrid};
            })

            setState(prevState=>({...prevState,rowData:arr}));
        }
        else{
            setState(prevState=>({...prevState,rowData:[]}));
        }
    },[props.data,state.select_month])

    const generateExcel=useCallback(()=>{
        const wb = new ExcelJs.Workbook();
        const detail = props.payroll_list.find(item=>item.id === state.select_month);
        let payroll_name ='';
        if(detail){
            const month1 = new Date(detail.payroll_date).getMonth();
            const year = new Date(detail.payroll_date).getFullYear();
            payroll_name = `${monthNames[month1]} ${year}`
        }
        const today = moment().format('DD/MM/YYYY');
        const time = moment().format('HH:mm:ss');
        const ws = wb.addWorksheet('Payroll Computation Summary Report');
        ws.getCell('A1').value = 'Payroll Computation Summary -  All Payments';
        ws.getCell('A2').value = `Printed On : ${today} ${time}`;
        ws.getCell('D1').value = `For ${payroll_name}`;
        const columnState = gridColumnApi.current.getColumnState();
        const column = [];

        for(const column_item of state.columnDefs){
            const {field} = column_item;
            const columnDetail = columnState.find(el=>el.colId === field);
            const hide = columnDetail?.hide ?? false;
            if(!hide){
                column.push(column_item);
            }
        }
        const size = [];
        for(const item of column){
            const {length} = item.headerName;
            const width = length < 15 ? 15 : length;
            size.push({width});
        }
        ws.columns = size;

        for(let i=0;i<column.length;i++){
            const {headerName,field,hide} = column[i];
            if(!hide){
                ws.getCell(getColumnName(i)+'5').value = headerName;
                for(let x=0;x<state.rowData.length;x++){
                    const step = 6 + x;
                    const data = getValue(state.rowData[x],field);
                    if(data != null){
                        if(field === 'employee.employee_number' || field === 'employee.name' || field === 'employee.contract_cache.cost_center' || field === 'employee.contract_cache.department' || field === 'employee.contract_cache.section' || field === 'employee.contract_cache.job_title' || field === 'employee.contract_cache.job_level' || field === 'employee.metadata.company' || field === 'employee.join_date' ){
                            ws.getCell(getColumnName(i)+step).value = data;
                        }
                        else{
                            ws.getCell(getColumnName(i)+step).value = '$'+data.toFixed(2);
                        }
                    }
                    else{
                        ws.getCell(getColumnName(i)+step).value ='-';
                    }
                }
            }
        }

        const filename = `PayrollComputationSummaryReportHeadcount_${payroll_name}.xlsx`;
        wb.xlsx.writeBuffer().then((buf)=>{
           
            var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
            FileSaver.saveAs(file, filename)
        });
    },[monthNames,props.payroll_list,state.columnDefs,state.rowData,state.select_month])

    return(
        <>
        {props.isLoading || props.isLoading1 ? <OverlayLoading/>:
        <>
        <Container fluid> 
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    <BreadCrumbList list={[
                        { active: false, title: IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title: IMLocalized('payroll_computation_headcount')}
                    ]}/>
                </Col>
            </Row>
            <div className='mt-2 d-flex'>
                <Input size="sm" type="select" name="select_month" value={state.select_month} style={{width:'150px'}}
                onChange={(e)=>{
                    const value = e.target.value;
                    setState(prevState=>({...prevState,select_month:value}));
                }}>
                    <option value=''>{IMLocalized('select_payroll')}</option>
                    {props.payroll_list.map((item,index)=>{
                        const month = new Date(item.payroll_date).getMonth();
                        const year = new Date(item.payroll_date).getFullYear();
                        return <option value={item.id} key={index}>{monthNames1[month]} {year}</option>
                    })}
                </Input>
                {state.rowData.length !== 0 && 
                <div className='ml-2'>
                    <button className='btn btn-success btn-sm' onClick={()=>generateExcel()}>{IMLocalized('export')}</button>
                </div>}
            </div>
            <div className='mt-2 d-flex justify-content-center'>
                <div className='ag-theme-balham' style={{height:action_toggle ? 'calc(100vh - 260px)' : 'calc(100vh - 224px)', width:'100%' }}>
                    <AgGridReact
                    columnDefs={state.columnDefs}
                    rowData={state.rowData}
                    suppressDragLeaveHidesColumns={true}
                    gridOptions={gridOptions}
                    onGridReady={(params)=>{
                        gridApi.current = params.api;
                        gridColumnApi.current = params.columnApi;
                    }}
                    />
                </div>
            </div>
        </Container>
        </>}
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(PayrollComputationHeadcount);
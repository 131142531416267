import * as updateemployee from '../../action/employee/update_employee';


const initialState ={
    isLoading:false,
    errors:null,
    data:null
}


const update_employee = (state=initialState,action) =>{

    switch(action.type) {
        case updateemployee.UPDATEEMPLOYEE_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            };
        case update_employee.UPDATEEMPLOYEE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updateemployee.UPDATEEMPLOYEE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case updateemployee.UPDATEEMPLOYEE_RESET:
            return{
                ...state,
                data:null,
                errors:null,
            }
        default:
            return state;
    }
}
export default update_employee;

import React,{Component} from 'react';
import {connect} from 'react-redux';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import XLSX from 'xlsx';
import ExcelJs from 'exceljs';
import FileSaver from 'file-saver';
import { Container, Row, Col, Modal, ModalHeader, ModalBody, Table, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import { IMLocalized } from '../../../language/IMLocalized';
import 'ag-grid-enterprise';
import OverlayLoading from '../../loading_component/overlay_loading';
import { get_employee_bank } from '../../../action/employee/get_employee_bank';
import { employee_bank_array_save } from '../../../action/wizard_employee/employee_bank_array_save';
import { employee_bank_array_validate } from '../../../action/wizard_employee/employee_bank_array_validate';
import { NewMainContext } from '../../../context/NewMainContext';
import BreadCrumbList from '../../breadcrumb/BreadCrumbList';


const gridOptions={
    singleClickEdit:true,
    defaultColDef:{
        sortable:true,
        filter:true,
        suppressMenu:true,
        resizable:true
    },
    sideBar:{
        toolPanels:[
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                
            }
        ],
        defaultToolPanel:''
    },
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
}

class import_create_employee_bank extends Component{

    constructor(props){
        super(props);
        this.state={
            list:[],
            language:localStorage.getItem('language'),
            error_msg:[],
            rowData:[],
            rowData2:[],
            import_lang:'',
            errorlist:[],
            columnsDefs:[],
            action_toggle:false,
            err_toggle:false,
            noRowTemplate:IMLocalized('no_data'),
        }
    }

    componentDidMount(){
        const {emplist} = this.props;
        if(emplist.length !== 0){
            this.getDetail();
        }
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.emplist !== this.props.emplist || prevProps.banklist2 !== this.props.banklist2){
            Promise.resolve(this.getDetail())
        }
        if(prevProps.validate_errors !== this.props.validate_errors){
            const {rowData,validatelist} = this.state;
            const {validate_errors} = this.props;
            if(validate_errors !== undefined){
                const errors = validate_errors.response.data;
                if(errors !== undefined){
                    if(errors.length !== 0){
                        let arr = [];
                        for(let i=0;i<errors.length;i++){
                            const object1 = errors[i];
                            const name = validatelist[i].name;
                            const number = validatelist[i].employee_number;
                            let arr1 = [];
                            if(object1 !== true){
                                if('non_field_errors' in object1){
                                    
                                    const word = object1['non_field_errors'].toString();
                                    arr1.push(<div><span>● {word}</span></div>)

                                    const new_rowData = rowData.map((item)=>{
                                        const employee_number = item.employee_number;
                                        if(number === employee_number){
                                            item.err_non = true;
                                        }
                                        return item
                                    })
                                    Promise.resolve(this.setState({rowData:new_rowData}))
                                    .then(gridOptions.api.setRowData(new_rowData))
                                    .then(()=>setTimeout(()=>{
                                        this.selectCell2(validatelist)
                                    }));
                                }
                                if('bank' in object1){
                                
                                    const word = `${IMLocalized('bank')} : `+ object1['bank'].toString();
                                    arr1.push(<div><span>● {word}</span></div>)

                                    const new_rowData = rowData.map((item)=>{
                                        const employee_number = item.employee_number;
                                        if(number === employee_number){
                                            item.err_bank = true;
                                        }
                                        return item
                                    })
                                    Promise.resolve(this.setState({rowData:new_rowData}))
                                    .then(gridOptions.api.setRowData(new_rowData))
                                    .then(()=>setTimeout(()=>{
                                        this.selectCell2(validatelist)
                                    }));
                                }
                                if('account_number' in object1){
                

                                    const word = `${IMLocalized('account_number')} : ` + object1['account_number'].toString();
                                    arr1.push(<div><span>● {word}</span></div>)

                                    const new_rowData = rowData.map((item)=>{
                                        const employee_number = item.employee_number;
                                        if(number === employee_number){
                                            item.err_account = true;
                                        }
                                        return item
                                    })
                                    Promise.resolve(this.setState({rowData:new_rowData}))
                                    .then(gridOptions.api.setRowData(new_rowData))
                                    .then(()=>setTimeout(()=>{
                                        this.selectCell2(validatelist)
                                    }));
                                }
                                if('employee' in object1){
                                    const word = `${IMLocalized('employee')} : ` + object1['employee'].toString();
                                    arr1.push(<div><span>● {word}</span></div>)

                                    const new_rowData = rowData.map((item)=>{
                                        const employee_number = item.employee_number;
                                        if(number === employee_number){
                                            item.err_non = true;
                                        }
                                        return item
                                    })
                                    Promise.resolve(this.setState({rowData:new_rowData}))
                                    .then(gridOptions.api.setRowData(new_rowData))
                                    .then(()=>setTimeout(()=>{
                                        this.selectCell2(validatelist)
                                    }));
                                }
                                if(arr1.length !== 0){
                                    arr.push(<tr><td>{number}</td><td>{name}</td><td>{arr1}</td></tr>)
                                }
                            }
                        }
                        this.setState({error_msg:arr});
                        this.setState({err_toggle:true})
                    }
                }
                
            }
        }
        if(prevProps.validate_success !== this.props.validate_success){
            const {validatelist} = this.state;
            this.props.employee_bank_array_save(validatelist);
        }
        if(prevProps.create_success !== this.props.create_success){
            this.props.get_employee_bank();
            
            this.clearData();
        }
        if(this.context.import_bank_reset === true){
            this.clearData();
            this.context.setImportBankReset(false);
        }
    }

    getDetail=()=>{
        let arr = [];

        const {emplist} = this.props;
        if(emplist.length !== 0){
            for(let i =0;i<emplist.length ;i++){
                const item = emplist[i];
                const payment = item.payment;
                const employee = item.id;
                const name = item.name;
                const employee_number = item.employee_number;
                const is_active = item.active;
                if(is_active === true){
                    if(payment === 'BANK'){
                        arr.push({name,employee,bank:null,account_number:null,employee_number,err_bank:false,err_account:false,err_non:false});
                    }
                }
            }
        }

        this.setState({rowData:arr});

        const {bank_payee} = this.props;
        let bank_option = [''];
        if(bank_payee.length !== 0){
            for(let i=0;i<bank_payee.length;i++){
                const name = bank_payee[i].display;
                bank_option.push(name);
            }
        }

        const columnsDefs=[
            {
                headerName:IMLocalized('agrid_select'),
                field:'',
                editable:false,
                width:120,
                lockPosition:true,
                pinned:'left',
                filter:false,
                headerCheckboxSelection: this.isFirstColumn,
                checkboxSelection: this.isFirstColumn,
                headerCheckboxSelectionFilteredOnly:true,
            },
            {
                headerName:IMLocalized('agrid_emp_id'),
                field:'employee_number',
                editable:false,
                width:200
            },
            {
                headerName:IMLocalized('agrid_emp_name'),
                field:'name',
                editable:false,
                width:200
            },
            {
                headerName:IMLocalized('agrid_bank'),
                field:'bank',
                editable:true,
                width:200,
                cellEditor:'agSelectCellEditor',
                cellEditorParams:{
                    values:bank_option,
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_bank}
                },
            },
            {
                headerName:IMLocalized('agrid_account_number'),
                field:'account_number',
                editable:true,
                width:200,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_account}
                },
            }
        ]

        this.setState({columnsDefs});
        
    }

    onCellValueChanged=(params)=>{
        if(params.oldValue !== params.newValue){
            const column = params.column.colDef.field;
            if(column === 'bank'){
                const focusedCell = gridOptions.api.getFocusedCell();
                gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                    if(index === focusedCell.rowIndex){
                        rowNode.setSelected(true);
                    }
                })

                params.data.err_bank = false;
                
            }
            else if(column === 'account_number'){
                const focusedCell = gridOptions.api.getFocusedCell();
                gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                    if(index === focusedCell.rowIndex){
                        rowNode.setSelected(true);
                    }
                })

                params.data.err_account = false;
            }

            params.data.err_non = false;
        }
        params.api.refreshCells();
    }

    onCellFocused=(params)=>{
        if(params !== null){
            if(params.column.colDef !== null){
                if(params.column.colDef.field ==='bank' || params.column.colDef.field ==='account_number'){
                    
                    params.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = true;
                }
                else{
                    params.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = false;
                }
            }
        }
    }

    getTemplate=()=>{
        const {language} = this.state;
        const rowData = gridOptions.api.getSelectedRows();
        const {bank_payee} = this.props;
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('employee_bank');
        const ws2 = wb.addWorksheet('setting');
        const ws3 = wb.addWorksheet('id');

        ws.columns =[
            {header:IMLocalized('agrid_emp_id'),key:'employee_number'},
            {header:IMLocalized('agrid_emp_name'),key:'name'},
            {header:IMLocalized('agrid_bank'),key:'bank'},
            {header:IMLocalized('agrid_account_number'),key:'account_number'}
        ];

        ws3.getCell('A1').value = 'ImportEmployeeBank';
        ws3.getCell('A2').value = language;
        for(let i=0;i<bank_payee.length;i++){
            const y =i+1;
            ws2.getCell('A'+y).value = bank_payee[i].display;
        }

        if(bank_payee.length !== 0){
            for(let i=2;i<2001;i++){
                ws.getCell('C'+i).dataValidation={
                    type:'list',
                    allowBlank:false,
                    error:IMLocalized('excel_valid_in_list'),
                    errorStyle:'error',
                    errorTitle:'Error',
                    showErrorMessage:true,
                    formulae:['=setting!$A$1:$A$'+bank_payee.length]
                }
            }
        }

        if(rowData.length !== 0){
            for(let i=0;i<ws.columns.length;i++){
                const field = ws.columns[i].key;
                for(let x=0;x<rowData.length;x++){
                    const step = 2+x;
                    const data = this.getValue(rowData[x],field);
                    ws.getCell(this.getColumnName(i)+step).value = data;
                    if(field === 'account_number'){
                        ws.getCell(this.getColumnName(i)+step).numFmt = '@';
                    }
                }
            }

            ws.columns.forEach(column =>{
                column.width = column.header.length < 15 ? 15 : column.header.length
            })
    
            wb.xlsx.writeBuffer().then((buf)=>{
                
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                FileSaver.saveAs(file, 'Import_Employee_Bank_Template.xlsx')
            });
        }
        else{
            Swal.fire({
                title:IMLocalized('no_employee_selected'),
                type:'error',
                confirmButtonColor:'#d33',
                confirmButtonText:IMLocalized('ok!'),
            })
        }
    }

    getValue(object, path) {
        const parts = path.split(".");
       
        return parts.reduce((value, key) => value ? value[key] : value, object)
    }

    getColumnName=(n)=>{
        let ordA = 'A'.charCodeAt(0);
        let ordZ = 'Z'.charCodeAt(0);
        let len = ordZ - ordA +1;

        let s = '';
        while(n >=0){
            s = String.fromCharCode(n % len + ordA )+s;
            n = Math.floor(n/len) -1;
        }
        return s;
    }

    uploadExcel=(e)=>{
        const file = e.target.files[0];
        const data1 = this.state.rowData;
        const reader = new FileReader();
        reader.onload=(e)=>{
            const bstr = e.target.result;
            const wb = XLSX.read(bstr,{type:'binary'});
            const wsname = wb.SheetNames[0];
            const wsname3 = wb.SheetNames[2];
            const ws3 = wb.Sheets[wsname3];
            const ws = wb.Sheets[wsname];
            const columns = {
                'A':'employee_number',
                'B':'name',
                'C':'bank',
                'D':'account_number'
            }
            let rowData1 = [];
            let rowIndex = 2 ;
            let idd = '';
            if(ws3 !== undefined){
                if(ws3['A1'] !== undefined){
                    idd = ws3['A1'].w;
                }
            }

            if(idd === 'ImportEmployeeBank'){
                while(ws['A'+rowIndex]){
                    let row = {};
                    for(let i =0;i<Object.keys(columns).length;i++){
                        const column = Object.keys(columns)[i];
                        const index = column + rowIndex;
                        if(ws[index] !== undefined){
                            row[columns[column]] = ws[index].w;
                        }
                        else{
                            row[columns[column]] = null;
                        }
                    }
                    rowData1.push(row);
                    rowIndex++;
                }


                let arr = [];
                for(let i=0;i<rowData1.length;i++){
                    const item = rowData1[i];
                    const number = item.employee_number;
                    const name = item.name;
                    const bank = item.bank;
                    const account_number = item.account_number;
                    const err_account = false;
                    const err_bank = false;
                    const err_non = false;
                    let employee = '';
                    data1.forEach((item1)=>{
                        if(number === item1.employee_number){
                            employee = item1.employee;
                            arr.push({name,bank,employee_number:number,err_account,err_bank,err_non,account_number,employee});
                        }
                    })
                }

                Promise.resolve(this.setState({rowData:arr}))
                .then(gridOptions.api.setRowData(arr))
                .then(()=>setTimeout(()=>{
                    this.selectCell()
                }))

            }
            else{
                Swal.fire({
                    title:IMLocalized('wrong_file_selected'),
                    type:'error',
                    confirmButtonColor:'#d33',
                    confirmButtonText:IMLocalized('ok!')
                })
            }
        }
        reader.readAsBinaryString(file)
    }

    handleFile = event => {
        const { target = {} } = event || {};
        target.value = "";
    };

    selectCell=()=>{
        gridOptions.api.forEachNode((rowNode)=>{
            rowNode.setSelected(true);
        })
    }

    selectCell2=(items)=>{
        for(let i =0;i<items.length;i++){
            const employee = items[i].employee;
            gridOptions.api.forEachNode((rowNode,index)=>{
                if(rowNode.data.employee === employee){
                    rowNode.setSelected(true);
                }
            })
        }
    }

    clearData=()=>{
        this.setState({validatelist:[]});
        this.setState({error_msg:[]});
        Promise.resolve( this.setState({rowData:[]}))
        .then(()=>this.getDetail());
    }

    toHome=()=>{
        this.context.addItem('1','dashboard');
    }

    isFirstColumn(params) {
        if(params !== null){
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }   
    }

    onCreate=()=>{
        const rowData = gridOptions.api.getSelectedRows();
        const {bank_payee,banklist} = this.props;
        let arr = [];
        let err = [];
        for(let i=0;i<rowData.length;i++){
            const item = rowData[i];
            const bank1 = item.bank;
            let account_number = item.account_number;
            const employee = item.employee;
            const name = item.name;
            const employee_number =item.employee_number;
            let step =0;
            let bank = null;
            if(bank1 !== null && bank1 !== ''){
                step = step +1;
                const detail = bank_payee.filter(function(item){
                    return item.display === bank1
                })
                if(detail.length !== 0){
                    bank = detail[0].value
                }
            }
            const bankdetail = banklist.filter((function(item){
                return item.employee === employee;
            }))
            if(bankdetail.length !== 0){
                console.log(bankdetail)
                console.log(account_number)
                let is_duplicate = false;
                for(let j =0 ;j<bankdetail.length;j++){
                    const account_number1 = bankdetail[j].account_number;
                    if(account_number !== null && account_number !== ''){
                        if(account_number1 === account_number.toString()){
                            is_duplicate = true;
                        }
                    }
                    
                }
                if(is_duplicate === true){
                    err.push({bank,account_number,employee,employee_number,name,err_msg:IMLocalized('bank_account_duplicate')});
                }
                else{
                    arr.push({bank,account_number,employee,employee_number,name})
                }
            }
            else{
                arr.push({bank,account_number,employee,employee_number,name})
            }
           
        }
        if(err.length !== 0){
            const data = this.state.rowData;
            let error_msg = [];

            for(let z=0;z<err.length;z++){
                const number = err[z].employee_number;
                const name = err[z].name;
                const err_msg = err[z].err_msg;
                const new_data = data.map((item)=>{
                    const employee_number = item.employee_number;
                    if(number === employee_number){
                        item.err_account = true;
                    }
                    return item;
                })
                error_msg.push(<tr><td>{number}</td><td>{name}</td><td>{err_msg}</td></tr>)
                Promise.resolve(this.setState({rowData:new_data}))
                .then(gridOptions.api.setRowData(new_data))
                .then(()=>setTimeout(()=>{
                    this.selectCell2(rowData)
                }))
            }
            this.setState({error_msg});
            this.setState({err_toggle:true});
        }
        else{
            if(arr.length !== 0){
                this.props.employee_bank_array_validate(arr);
                this.setState({validatelist:arr});
            }
            else{
                Swal.fire({
                    type:'warning',
                    title:IMLocalized('no_data'),
                    confirmButtonText:IMLocalized('ok!'),
                    confirmButtonColor:'#d33',
                })
            }
        }
    }

    setErrToggle=()=>{
        const toggle = !this.state.err_toggle;
        this.setState({err_toggle:toggle});
    }

    getError=()=>{
        const table=(
            <Modal isOpen={this.state.err_toggle} size="lg" >
                <ModalHeader toggle={this.setErrToggle}>{IMLocalized('error_msg')}</ModalHeader>
                <ModalBody>
                    <Table size="sm" className="table-bordered align-items-center" responsive>
                        <thead className="thead-light p-2">
                            <tr>
                                <th>{IMLocalized('employee_number')}</th>
                                <th>{IMLocalized('name')}</th>
                                <th>{IMLocalized('error')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.error_msg}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-danger btn-sm" onClick={this.setErrToggle}>{IMLocalized('close')}</button>
                </ModalFooter>
            </Modal>
        )

        return table;
    }

    render(){

        const {rowData,error_msg} = this.state;
        let btn  ='';
        if(rowData.length !== 0){
            btn= <button className="btn btn-primary btn-sm" onClick={this.getTemplate}>{IMLocalized('get_employee_bank_template')}</button>
        }
        let error_btn = '';
        if(error_msg.length !== 0){
            error_btn =<button className="btn btn-danger btn-sm" onClick={this.setErrToggle}>{IMLocalized('error_msg')}</button>
        }
        const {action_toggle} = this.context;
        let currentHeight = 'calc(100vh - 260px)';
        if(action_toggle === false){
            currentHeight = 'calc(100vh - 224px)';
        }

        return(
            <div>
                {this.props.isLoading || this.props.isLoading1 || this.props.isLoading2 ? <OverlayLoading />:
                <div>
                    <Container fluid>
                        <Row className="border-bottom bg-white">
                            <Col className="p-2">
                                <BreadCrumbList list={[
                                    { active: false, title:IMLocalized('home'), onClick:()=>this.context.addItem('1','dashboard')},
                                    { active: true, title:IMLocalized('employee_bank_import')}
                                ]}/>
                            </Col>
                        </Row>
                        <Row className="pt-2">
                            <Col>
                                <div className="d-flex">
                                    <div>
                                        {btn}
                                    </div>
                                    <div className="ml-2 mr-2">
                                        <input type="file" style={{display:'none'}} id="ImportBankcustomFile" className="custom-file-input form-control-sm" onChange={this.uploadExcel.bind(this)} onClick={this.handleFile} accept=".xlsx" />
                                        <label className="btn btn-primary btn-sm" htmlFor="ImportBankcustomFile">{IMLocalized('import_from_file')}</label>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary btn-sm" onClick={this.clearData}>{IMLocalized('clear')}</button>
                                        <button className="btn btn-primary btn-sm" onClick={this.onCreate}>{IMLocalized('create')}</button>
                                        {error_btn}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="pt-2">
                            <Col>
                                <div className="d-flex justify-content-center">
                                    <div className="ag-theme-balham" style={{height:currentHeight, width:'100%' }}>
                                        <AgGridReact
                                        columnDefs={this.state.columnsDefs}
                                        rowData={this.state.rowData}
                                        suppressDragLeaveHidesColumns={true}
                                        suppressSizeToFit={true}
                                        suppressColumnMoveAnimation={false}
                                        singleClickEdit={true}
                                        onCellValueChanged={this.onCellValueChanged}
                                        onCellFocused={this.onCellFocused}
                                        rowClassRules={{
                                            'sick-days-warning': function (params) {
                                                var numSickDays = params.data.err_non;
                                                return numSickDays ===true
                                                },
                                        
                                        }}
                                        defaultColDef={this.state.defaultColDef}
                                        onGridReady={this.onGridReady}
                                        gridOptions={gridOptions}
                                        overlayNoRowsTemplate = {this.state.noRowTemplate}
                                        stopEditingWhenCellsLoseFocus={true}
                                        >
                                        </AgGridReact>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    {this.getError.call(this)}
                </div>
                }
            </div>
        )
    }
}
function mapStateToProps(state){
    return{
        emplist:state.get_employee_list.data,
        banklist:state.get_employee_bank.data,
        banklist2:state.get_employee_bank.data2,
        bank_payee:state.dropdown_list.bank_payee,
        validate_success:state.employee_bank_array_validate.data,
        validate_errors:state.employee_bank_array_validate.errors,
        create_success:state.employee_bank_array_save.data,
        create_errors:state.employee_bank_array_save.errors,
        isLoading3:state.employee_bank_array_validate.isLoading,
        isLoading1:state.get_employee_bank.isLoading,
        isLoading:state.get_employee_list.isLoading,
        isLoading2:state.dropdown_list.isLoading,
        isLoading4:state.employee_bank_array_save.isLoading,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        employee_bank_array_validate:(data)=>{
            dispatch(employee_bank_array_validate(data))
        },
        employee_bank_array_save:(data)=>{
            dispatch(employee_bank_array_save(data))
        },
        get_employee_bank:()=>{
            dispatch(get_employee_bank())
        }
    }  
}
import_create_employee_bank.contextType = NewMainContext;
export default connect(mapStateToProps, mapDispatchToProps)(import_create_employee_bank);
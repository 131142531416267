import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import { NewMainContext } from "../../../context/NewMainContext";
import { IMLocalized } from "../../../language/IMLocalized";
import OverlayLoading from "../../loading_component/OverlayLoading";
import classnames from 'classnames';
import EmployeeListNavbar from "./component/EmployeeListNavbar";
import EmployeeListCard from "./component/EmployeeListCard";
import { get_employee_picture } from "../../../action/employee/get_employee_picture";
import { usePrevious } from "../../../hook/usePrevious";
import { Buffer } from "buffer";
import BreadCrumbList from "../../breadcrumb/BreadCrumbList";

export default function EmployeeList({ goProfile}){
   
    const { addItem, action_toggle, current_user } = useContext(NewMainContext);

    const emplist = useSelector(state=>state.get_employee_list.data);
    const departmentlist = useSelector(state=>state.add.department);
    const sectionlist = useSelector(state=>state.add.section);
    const joblevellist = useSelector(state=>state.add.joblevel);
    const jobtitlelist = useSelector(state=>state.add.jobtitle);
    const costcenterlist = useSelector(state=>state.add.costcenter);
    const isLoading = useSelector(state=>state.get_employee_list.isLoading);
    const isLoading1 = useSelector(state=>state.add.isLoading);
    const picturelist = useSelector(state=>state.get_employee_picture_list.data);
    const picture = useSelector(state=>state.get_employee_picture.data);
    const dispatch = useDispatch();

    const [ deparmentCheckbox, setDepartmentCheckbox] = useState([]);
    const [ sectionCheckbox, setSectionCheckbox] = useState([]);
    const [ costcenterCheckbox, setCostcenterCheckbox] = useState([]);
    const [ jobtitleCheckbox, setJobTitleCheckbox] = useState([]);
    const [ joblevelCheckbox, setJobLevelCheckbox] = useState([]);
    const [ is_active, setIsActive] = useState(true);
    const [ search, setSearch] = useState('');
    const [ imglist,setImgList] = useState([]);

    const [ departmentToggle, setDepartmentToggle] = useState(false);
    const [ sectionToggle, setSectionToggle] = useState(false);
    const [ jobtitleToggle, setJobtitleToggle] = useState(false);
    const [ joblevelToggle, setJoblevelToggle] = useState(false);
    const [ costcenterToggle, setCostcenterToggle] = useState(false);

    const prevPicture = usePrevious(picture);

    useEffect(()=>{
        if(prevPicture !== undefined && prevPicture !== picture && picture != null ){
            const { data } = picture;
            const { url } = picture.config;
            const img = new Buffer.from(data, 'binary').toString('base64');
            const src = `data:image/png;base64,${img}`;
            const new_imagelist = imglist.map((item)=>{
                const imgurl = 'ob/employee/'+ item.id+'/thumbnail/';
                if(url === imgurl ){
                    return {...item,img:src}
                }
                else{
                    return item;
                }
            })

            setImgList(new_imagelist);
        }
    },[prevPicture,picture,imglist])

    useEffect(()=>{
        if(picturelist.length !== 0 ){
            const data = picturelist.map((item)=>{
                const { employee } = item;
                dispatch(get_employee_picture(employee));

                return { id: employee, img:null }
            })
            setImgList(data);
        }
    },[picturelist,dispatch])

    useEffect(()=>{
        let lookup = {} ;
        const arr = departmentlist.map((item)=>{
            if(!(item.name in lookup)){
                lookup[item.name] = 1;
                return { label: item.name, checked: false}
            }
            return false;
        })
        setDepartmentCheckbox(arr);
    },[departmentlist])

    useEffect(()=>{
        let lookup = {} ;
        const arr = costcenterlist.map((item)=>{
            if(!(item.name in lookup)){
                lookup[item.name] = 1;
                return { label: item.name, checked: false}
            }
            return false;
        })
        setCostcenterCheckbox(arr);
    },[costcenterlist])

    useEffect(()=>{
        let lookup = {} ;
        const arr = joblevellist.map((item)=>{
            if(!(item.name in lookup)){
                lookup[item.name] = 1;
                return { label: item.name, checked: false}
            }
            return false;
        })
        setJobLevelCheckbox(arr);
    },[joblevellist])

    useEffect(()=>{
        let lookup = {} ;
        const arr = jobtitlelist.map((item)=>{
            if(!(item.name in lookup)){
                lookup[item.name] = 1;
                return { label: item.name, checked: false}
            }
            return false;
        })
        setJobTitleCheckbox(arr);
    },[jobtitlelist])

    useEffect(()=>{
        let lookup = {} ;
        const arr = sectionlist.map((item)=>{
            if(!(item.name in lookup)){
                lookup[item.name] = 1;
                return { label: item.name, checked: false}
            }
            return false;
        })
        setSectionCheckbox(arr);
    },[sectionlist])

    const setToggle = (name) =>{
        setCostcenterToggle(name === 'cost_center' ? prevState=>!prevState : false);
        setDepartmentToggle(name === 'department' ? prevState=>!prevState : false);
        setSectionToggle(name === 'section' ? prevState=>!prevState : false);
        setJoblevelToggle(name === 'job_level' ? prevState=>!prevState : false);
        setJobtitleToggle(name === 'job_title' ? prevState=>!prevState : false);
    }

    const rowData = useMemo(()=>{
        let arr = emplist.filter(item=>is_active === true ? item.active === true : item.active === false && item.resignation_date !== null )
        .filter(item=>{
            if(search !== ''){
                return (item.employee_number).includes(search)||item.name.toLocaleLowerCase().indexOf(search) !== -1 || item.name.toLocaleUpperCase().indexOf(search) !== -1 || (item.name).includes(search);
            }
            return item;
        });

        const dep_every = deparmentCheckbox.every(item => item.checked === false);
        const sec_every = sectionCheckbox.every(item=>item.checked === false );
        const title_every = jobtitleCheckbox.every(item=>item.checked === false );
        const level_every = joblevelCheckbox.every(item=>item.checked === false );
        const cost_every = costcenterCheckbox.every(item=>item.checked === false );
        if(!dep_every || !sec_every || !title_every || !level_every || !cost_every){
            const dep_data = deparmentCheckbox.filter(item=>item.checked === true);
            const deparr = [];
            if(dep_data.length !== 0){
                for(const value of dep_data){
                    const data = arr.filter(item=>item.contract_cache != null ? item.contract_cache.department === value.label : false );
                    deparr.push(data);
                }
                arr = deparr.flat();
            }
           

            const sec_data = sectionCheckbox.filter(item=>item.checked === true);
            const secarr = [];
            if(sec_data.length !== 0){
                for(const value of sec_data){
                    const data = arr.filter(item=>item.contract_cache != null ? item.contract_cache.section === value.label : false );
                    secarr.push(data);
                }
                console.log(secarr);
                arr = secarr.flat();
            }
           

            const title_data = jobtitleCheckbox.filter(item=>item.checked === true);
            const titlearr = [];
            if(title_data.length !== 0){
                for(const value of title_data){
                    const data = arr.filter(item=>item.contract_cache != null ? item.contract_cache.job_title === value.label : false );
                    titlearr.push(data);
                }
                arr = titlearr.flat();
            }
          

            const level_data = joblevelCheckbox.filter(item=>item.checked === true);
            const levelarr = [];
            if(level_data.length !== 0){
                for(const value of level_data){
                    const data = arr.filter(item=>item.contract_cache != null ? item.contract_cache.job_level === value.label : false );
                    levelarr.push(data);
                }
                arr = levelarr.flat();
            }
           

            const cost_data = costcenterCheckbox.filter(item=>item.checked === true);
            const costarr = [];
            if(cost_data.length !== 0){
                for(const value of cost_data){
                    const data = arr.filter(item=>item.contract_cache != null ? item.contract_cache.cost_center === value.label : false );
                    costarr.push(data);
                }
                arr = costarr.flat();
            }
            console.log(arr);
        }
        return arr;
    },[emplist,is_active,deparmentCheckbox,sectionCheckbox,joblevelCheckbox,jobtitleCheckbox,costcenterCheckbox, search])

    const emp_head = emplist.filter(item=>is_active === true ? item.active === true : item.active === false && item.resignation_date !== null )
    return(
        <>
        {isLoading || isLoading1 ? <OverlayLoading/>:
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title:IMLocalized('employee_list')}
                    ]}/>
                </Col>
                <Col className="p-2 col-auto">
                    <ButtonGroup size="sm" className="btn-group-toggle" data-toggle="buttons">
                        <Button className={classnames({active:is_active===true})} color="secondary" onClick={()=>setIsActive(true)}>
                            <input autoComplete="off" name="options" type="radio" value={is_active === true} />
                            {IMLocalized('active_user')}
                        </Button>
                        <Button className={classnames({active:is_active===false})} color="secondary" onClick={()=>setIsActive(false)}>
                            <input autoComplete="off" name="options" type="radio" value={is_active === false} />
                            {IMLocalized('resignee')}
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col xl="2" className="border-right bg-white d-none d-xl-block">
                    <div className="pt-3" style={{minHeight:action_toggle ? 'calc(100vh - 195px)' : 'calc(100vh - 160px)',position:'sticky',top:10}}>
                        <EmployeeListNavbar name="department" list={deparmentCheckbox} icon='ni ni-briefcase-24 text-blue' setList={(list)=>setDepartmentCheckbox(list)} toggle={departmentToggle} setToggle={()=>setToggle('department')} />
                        <EmployeeListNavbar name="section" list={sectionCheckbox} icon="ni ni-ungroup text-orange" setList={(list)=>setSectionCheckbox(list)} toggle={sectionToggle} setToggle={()=>setToggle('section')}/>
                        <EmployeeListNavbar name="job_title" list={jobtitleCheckbox} icon="ni ni-ui-04 text-info" setList={(list)=>setJobTitleCheckbox(list)}  toggle={jobtitleToggle} setToggle={()=>setToggle('job_title')} />
                        <EmployeeListNavbar name="job_level" list={joblevelCheckbox} icon="ni ni-single-copy-04 text-pink" setList={(list)=>setJobLevelCheckbox(list)}  toggle={joblevelToggle} setToggle={()=>setToggle('job_level')} />
                        <EmployeeListNavbar name="cost_center" list={costcenterCheckbox} icon="ni ni-align-left-2 text-default" setList={(list)=>setCostcenterCheckbox(list)} toggle={costcenterToggle} setToggle={()=>setToggle('cost_center')} />
                    </div>
                </Col>
                <Col xl="10" lg="12" className="mt-2">
                    <div className='d-flex justify-content-between'>
                        <div className="d-flex align-items-center">
                            <h2 className="mb-0">{IMLocalized('employee_list')}</h2><span className="pl-2 d-none d-lg-block">- {is_active ? IMLocalized('active_user') : IMLocalized('resignee')} ( {IMLocalized('head_count')} - {emp_head.length})</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <InputGroup size="sm" className="mr-2">
                                <Input type="text" value={search} 
                                onChange={(event)=>{
                                    const {value} = event.target;
                                    setSearch(value);
                                }} />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText><i className="fas fa-search "></i></InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                            {current_user === 'hr' && <button className="btn btn-success btn-sm" onClick={()=>addItem('9','create_employee') }>{IMLocalized('create')}</button>}
                        </div>
                    </div>
                    <Row className="mt-2">
                        {rowData.length === 0 && 
                        <Col>
                            <span>{IMLocalized('no_data')}</span>
                        </Col>}
                        {rowData.length !== 0 &&
                        rowData.map((item)=>{
                            const pic = imglist.find(element=>element.id === item.id);
                            return(
                                <EmployeeListCard {...item} pic={pic} goProfile={goProfile} key={item.id} />
                            )
                        })
                        }
                    </Row>
                </Col>
            </Row>
        </Container>}
        </>
    )
}
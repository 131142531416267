import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import { getDateFormat } from "../../../func/getDateFormat";
import {IMLocalized} from '../../../language/IMLocalized';
import ModalViewChild from "../hr_employee/modal/ModalViewChild";
import ModalViewFamily from "../hr_employee/modal/ModalViewFamily";


const mapStateToProps=(state)=>({
    data:state.user_self.data,
    isLoading:state.user_self.isLoading,
})

function UserEmployeeFamily(props){

    const [familylist,setFamilyList] = useState([]);
    const [childlist,setChildList] = useState([]);
    const [maternitylist,setMaternityList] = useState([]);
    const [family_toggle,setFamilyToggle] = useState(false);
    const [family_detail,setFamilyDetail] = useState(null);
    const [child_detail,setChildDetail] = useState(null);
    const [child_toggle,setChildToggle] = useState(false);


    useEffect(()=>{
        if(props.data.length !== 0){
            const {contacts,sgchild,sgbirth} = props.data;
            setFamilyList(contacts);
            setChildList(sgchild);
            setMaternityList(sgbirth);
        }
    },[props.data])


    return(
        <>
        <div className='d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title'>
            <div className='d-flex align-items-center'>
                <i className="far fa-address-book text-primary" />
                <h4 className="text-primary pl-2 m-0">{IMLocalized('employee_family_other_contact')}</h4>
            </div>
        </div>
        {props.isLoading && <div className='p-2'><h4>{IMLocalized('loading')}..</h4></div>}
        {!props.isLoading && familylist.length === 0 && <div className='p-2'><h4>{IMLocalized('no_data')}</h4></div>}
        {!props.isLoading && familylist.length !== 0 && 
        <Table size="sm" className="align-items-center table-bordered mt-2" responsive>
            <thead className="thead-light text-center">
                <tr>
                    <th className="w-50">{IMLocalized('name')}</th>
                    <th className="w-50">{IMLocalized('relation')}</th>
                </tr>
            </thead>
            <tbody className="text-center">
                {familylist.map((item,index)=>{
                    return(
                        <tr key={index}>
                            <td><span className="text-primary cursor-pointer" 
                            onClick={()=>{
                                const data = familylist.find((el)=>el.id === item.id);
                                setFamilyDetail(data);
                                setFamilyToggle(true);
                            }}>{item.name}</span></td>
                            <td>{IMLocalized(item.relation)}</td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>}
        <div className='d-flex justify-content-between mt-3 pl-2 emp-tabcontent-title'>
            <div className='d-flex align-items-center'>
                <i className="fas fa-child text-primary" />
                <h4 className="text-primary pl-2 m-0">{IMLocalized('employee_children')}</h4>
            </div>
        </div>
        {props.isLoading && <div className='p-2'><h4>{IMLocalized('loading')}..</h4></div>}
        {!props.isLoading && childlist.length === 0 && <div className='p-2'><h4>{IMLocalized('no_data')}</h4></div>}
        {!props.isLoading && childlist.length !== 0 && 
        <Table size="sm" className="mt-2 table-bordered align-items-center" responsive>
            <thead className="thead-light text-center">
                <tr>
                    <th className="w-50">{IMLocalized('name')}</th>
                    <th className="w-50">{IMLocalized('date_of_birth')}</th>
                </tr>
            </thead>
            <tbody className="text-center">
                {childlist.map((item,index)=>{
                    return(
                        <tr key={index }>
                            <td><span className="text-primary cursor-pointer" 
                            onClick={()=>{
                                const data = childlist.find(el=>el.id === item.id);
                                setChildDetail(data);
                                setChildToggle(true);
                            }}
                            >{item.name}</span></td>
                            <td>{getDateFormat(item.date_of_birth)}</td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>} 
        {!props.isLoading && maternitylist.length !== 0 &&
        <>
        <div className='d-flex justify-content-between mt-3 pl-2 emp-tabcontent-title'>
            <div className='d-flex align-items-center'>
                <i className="far fa-address-book text-primary" />
                <h4 className="text-primary pl-2 m-0">{IMLocalized('employee_maternity_leave')}</h4>
            </div>
        </div>
        <Table size="sm" className=" table-bordered align-items-center mt-2" responsive>
            <thead className="thead-light text-center">
                <tr>
                    <th>{IMLocalized('estimated_delivery_date')}</th>
                    <th>{IMLocalized('birth_times')}</th>
                    <th>{IMLocalized('flexible_leave')}</th>
                    <th>{IMLocalized('weeks_awarded')}</th>
                    <th>{IMLocalized('weeks_to_paternal')}</th>
                </tr>
            </thead>
            <tbody className="text-center">
                {maternitylist.map((item,index)=>{

                    return(
                        <tr key={index}>
                            <td>{getDateFormat(item.estimated_delivery_date)}</td>
                            <td>{item.birth_times}</td>
                            <td className="p-2">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" id={'flexible'+item.id} className="custom-control-input" name="flexible_leave" checked={item.flexible_leave} readOnly/>
                                    <label className="custom-control-label" htmlFor={'flexible'+item.id}></label>
                                </div>
                            </td>
                            <td>{item.weeks_awarded}</td>
                            <td>{item.weeks_to_paternal}</td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
        </>}
        <ModalViewFamily toggle={family_toggle} closeModal={()=>{setFamilyDetail(null); setFamilyToggle(false)}} detail={family_detail} />
        <ModalViewChild toggle={child_toggle} closeModal={()=>{setChildDetail(null); setChildToggle(false)}} detail={child_detail} />
        </>
    )
}
export default connect(mapStateToProps,null)(UserEmployeeFamily);
import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import '../../../css/dashboard.css';
import { IMLocalized } from "../../../language/IMLocalized";
import { PieChart } from 'bizcharts';
import { connect } from "react-redux";

const mapStateToProps=(state)=>({
    isLoading:state.get_general_dashboard.isLoading,
})

function CompanyStrength(props){
    return(
        <Row>
            <Col>           
                <Card  style={{height:'270px'}}>
                    <CardHeader className="border-0 p-2 bg-white">
                        <h3 className="title-color1 pl-2 pt-2">
                            {IMLocalized('company_strength')}
                        </h3>
                    </CardHeader>
                    {props.isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>}
                    {!props.isLoading && props.attendance_present === '' && props.attendance_absentees === '' &&
                    <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                    {!props.isLoading && props.attendance_present !== '' && props.attendance_absentees !== '' &&
                    <CardBody>
                        <PieChart
                            data={props.series}
                            radius={0.8}
                            angleField='value'
                            colorField='type'
                            label={{
                                visible: true,
                                type: 'outer',
                                offset: 20,
                            }}
                        />
                    </CardBody>}
                </Card>
            </Col>
        </Row>
    )
}
export default connect(mapStateToProps,null)(CompanyStrength);

import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Table } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import { popError } from '../../../func/popError';
import { payslip_update_payroll_amount } from '../../../action/payroll/payslip_update_payroll_amount';
import { connect } from 'react-redux';

const mapDispatchToProps=(dispatch)=>({
    payslip_update_payroll_amount:(id,data)=>{
        dispatch(payslip_update_payroll_amount(id,data))
    }
})

function RecurringTable(props){
    const [list,setList] = useState([]);

    useEffect(()=>{
        setList(props.list);
    },[props.list])

    return(
        <Card>
            <CardHeader className="p-2 border-0 bg-white">
                <h4 className='text-primary pl-2 pt-2'>{IMLocalized('recurring_allowance')}</h4>
            </CardHeader>
            <Table size="sm" className='align-items-center' responsive>
                <thead className="text-center p-1 thead-light">
                    <tr>
                        <th className="p-2">{IMLocalized('name')}</th>
                        <th className="p-2">{IMLocalized('code')}</th>
                        <th className="p-2">{IMLocalized('amount')}</th>
                        <th className="p-2">{IMLocalized('update')}</th>
                    </tr>
                </thead>
                <tbody className='text-center p-1'>
                    {list.map((item,index)=>{                    
                        return(
                            <tr key={index}>
                                <td className="p-2">{item.name}</td>
                                <td className="p-2">{item.code}</td>
                                <td className="p-2">
                                    $ <input type="number" className="text-center" name="amount" min="0" value={item.amount} style={{width:'70px'}} 
                                    onChange={(e)=>{
                                        const value = e.target.value;
                                        setList(prevState=>{
                                            const newState= prevState.map((obj,i)=>{
                                                if(index === i){
                                                    return {...obj,amount:value}
                                                }
                                                else{
                                                    return obj;
                                                }
                                            })
                                            return newState;
                                        })
                                    }}
                                    />
                                </td>
                                <td align="center" valign="center" className="p-2">
                                    <div className="primary_btn d-flex align-items-center justify-content-center">
                                        <i className="fas fa-check text-primary cursor-pointer"
                                        onClick={()=>{
                                            const detail = list[index];
                                            const data = [{paycomponent:detail.id,amount:detail.amount}];

                                            if(detail.amount === ''){
                                                popError(IMLocalized('value_is_required'),IMLocalized('value_cant_empty'));
                                            }
                                            else{
                                                props.payslip_update_payroll_amount(detail.payroll,data);
                                                // props.payslip_update_payroll_amount(detail.payslip,detail.amount);
                                            }
                                        }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
                {/* <tbody className='text-center p-1'>
                    {props.list.map((item,index)=>{
                        return(
                            <tr key={index}>
                                <td className="p-2">{item.name}</td>
                                <td className="p-2">{item.code}</td>
                                <td className="p-2">${item.amount.toFixed(2)}</td>
                            </tr>
                        )
                    })}
                </tbody> */}
            </Table>
        </Card>
    )

}
export default connect(null,mapDispatchToProps)(RecurringTable);
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import {Route, Switch} from 'react-router-dom';
//Redux: store
import ReduxStore from './store';
import Login from './components/login/Login';
import AuthChecker from './layouts/AuthChecker';
//import "react-perfect-scrollbar/dist/css/styles.css";
// core styles
import "./assets/scss/argon-dashboard-pro-react.scss?v1.0.1";
import "@geedmo/yamm";
import history from './history';
import {HashRouter} from 'react-router-dom';
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "./assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import WizardEmployee from './components/wizard_employee/wizard';
import NetworkFail from './components/error/NetworkFail';
import Wizard from './components/wizard/Wizard1';
import Newuser from './components/new_user/UserForm'
import NewPass from './components/new_user/PasswordForm';
import "ag-grid-enterprise";
import {LicenseManager} from 'ag-grid-enterprise';
import IrasPage from './components/iras/IrasPage';
import MainNav from './components/navbar/NewMainNav';
import MaintenancePage from './components/error/MaintenancePage';

LicenseManager.setLicenseKey("CompanyName=Circle Enterprise Pte Ltd,LicensedApplication=Aline HRMS,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-031873,SupportServicesEnd=8_September_2023_[v2]_MTY5NDEyNzYwMDAwMA==c35d5ca3cef8261098e8ba122de0eb1b")

ReactDOM.render((
  <Provider store={ReduxStore}>
    <HashRouter history={history}>
      <Switch>
            <Route exact path="/login/" component={Login} />
            <Route path="/new_user/:data/" component={Newuser} />
            <Route path="/email/reset/:data/" component={NewPass} />
            <Route path="/network/failed/" component={NetworkFail} />
            <AuthChecker path="/wizard/employee/" component={WizardEmployee} />
            <AuthChecker path="/wizard/first/time/" component={Wizard} />
            <Route path="/iras_upload/" component={IrasPage} />
            <AuthChecker path="/" component={MainNav}/>
            <Route path="/503/" component={MaintenancePage} />
      </Switch>
    </HashRouter>
  </Provider>
), document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
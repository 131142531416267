import axios from 'axios';


export const WIZARDSGPOSTAL_REQUEST = 'WIZARDSGPOSTAL_REQUEST';
export const WIZARDSGPOSTAL_SUCCESS = 'WIZARDSGPOSTAL_SUCCESS';
export const WIZARDSGPOSTAL_FAILURE = 'WIZARDSGPOSTAL_FAILURE';

export const wizard_get_sg_postal = (pos) =>{
    return dispatch=>{
        dispatch({
            type : 'WIZARDSGPOSTAL_REQUEST'
        });
        axios.get(`https://developers.onemap.sg/commonapi/search?searchVal=${pos}&returnGeom=Y&getAddrDetails=Y&pageNum=1/`)
        .then((response)=>{
            dispatch({
                type:'WIZARDSGPOSTAL_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'WIZARDSGPOSTAL_FAILURE',
                payload:error
            })
        })
    }
}
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {connect} from 'react-redux';
import { Container, Row, Col,Input, InputGroup, InputGroupAddon } from 'reactstrap';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {IMLocalized} from '../../language/IMLocalized';
import moment from 'moment';
import { get_general_aggrid_template } from '../../action/report/get_general_aggrid_template';
import { create_general_aggrid_template } from '../../action/report/create_general_aggrid_template';
import { filterCountryDropdown, filterDropdown } from '../../func/filterDropdown';
import { convertDataDate } from '../../func/convertDataDate';
import ModalSaveCustomTemplate from './modal/ModalSaveCustomTemplate';
import { usePrevious } from '../../hook/usePrevious';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';

const mapStateToProps=(state)=>({
    claim_available:state.get_all_employee_available_claim.data,
    leave_available:state.get_all_employee_available_leave.data,
    claimlist:state.get_claim_list.data,
    allowancelist:state.add.allowance,
    recurringlist:state.add.recurringallowance,
    templatelist:state.get_general_aggrid_template.data,
    emplist:state.get_employee_contract_list.data,
    addresslist:state.get_employee_address.data,
    salutation:state.dropdown_list.salutation,
    gender:state.dropdown_list.gender,
    race:state.dropdown_list.race,
    religion:state.dropdown_list.religion,
    relationship:state.dropdown_list.relationship,
    nationalitylist:state.dropdown_list.countries,
    payment:state.dropdown_list.payment,
    sg_identification_type:state.dropdown_list.s_g_identification_type,
    contract_ratelist:state.get_contract_template_dropdown.contract_rate,
    contract_typelist:state.get_contract_template_dropdown.contract_type,
    leavelist:state.get_leave_list.data,
    success:state.create_general_aggrid_template.data,
})

const mapDispatchToProps=(dispatch)=>({
    create_general_aggrid_template:(name,type,model,arr)=>{
        dispatch(create_general_aggrid_template(name,type,model,arr))
    },
    get_general_aggrid_template:()=>{
        dispatch(get_general_aggrid_template())
    }
})

const gridOptions={
    defaultColDef:{
        flex:1,
        minWidth:100,
        enableValue:true,
        enableRowGroup:true,
        enablePivot:true,
        sortable:true,
        filter:true,
        resizable:true
    },
    sideBar:true,
}

const initialState={
    select_temp:'',
    columnDefs:[],
    rowData:[],
    count:0,
    toggle:false,
}

function EmployeeListingReport(props){
    const {success,get_general_aggrid_template} = props;
    const {action_toggle, addItem} = useContext(NewMainContext);
    const [state,setState] = useState(initialState);
    const gridApi = useRef();
    const gridColumnApi = useRef();
    const prevSuccess = usePrevious(success);

    useEffect(()=>{
        if(prevSuccess !== undefined && prevSuccess !== success && success!= null){
            setState(prevState=>({...prevState,toggle:false}));
            get_general_aggrid_template();
        }
    },[prevSuccess,success,get_general_aggrid_template])

    useEffect(()=>{

        const addresslist = props.addresslist.map((item)=>{
            return {
                ...item,
                country_name:filterCountryDropdown(props.nationalitylist,item.country_of_residence)
            }
        })

        const empdata = props.emplist.map((item,index)=>{
            const address =  addresslist.find((el)=>el.employee === item.id);
            let contract;

            if(item.contract !== null){
                let allowance_detail = {};
                let recurring_detail = {};
                
                for(const value of props.allowancelist){
                    allowance_detail[value.name] = '-';
                }

                for(const value of item.contract.allowances){
                    allowance_detail[value] = 'Yes';
                }

                for(const value of props.recurringlist){
                    recurring_detail[value.name] = '-';
                }

                for(const value of item.contract.recurring_allowances){
                    recurring_detail[value] = 'Yes';
                }

                contract = {...item.contract,allowance_detail,recurring_detail};
                contract.start_date = convertDataDate(item.contract.start_date);
                contract.contract_rate = filterDropdown(props.contract_ratelist,item.contract.contract_rate);
                contract.contract_type = filterDropdown(props.contract_typelist,item.contract.contract_type);
                contract.is_workman = item.contract.is_workman ? 'Yes' : 'No';
            }
            else{
                contract = null;
            }

            const empleave = props.leave_available.filter(el=>el.employee === item.id);
            let leave_detail = {};
            for(const value of props.leavelist){
                leave_detail[value.code] = { amount :'-'}
            }

            for(const value of empleave){
                const setting = props.leavelist.find(el=>el.id === value.leave_setting);
                const code = setting?.code ?? null;
                if(value.leave_type === 'E'){
                    const month = new Date().getMonth();
                    const remaining = value.remaining_monthly;
                    const amount = remaining[month];
                    leave_detail[code] = {amount};
                }
                else{
                    const amount = value.remaining;
                    leave_detail[code] = {amount};
                }
            }

            const empclaim = props.claim_available.filter(el=>el.employee === item.id);
            let claim_detail = {};
            for(const value of props.claimlist){
                claim_detail[value.code] = { amount :'-'}
            }

            for(const value of empclaim){
                const setting = props.claimlist.find(el=>el.id === value.claim_type);
                const code = setting?.code ?? null;
                if(value.is_monthly === true){
                    const month = new Date().getMonth();
                    const amount = value.remaining_monthly[month];
                    claim_detail[code] = {amount}
                }
                else{
                    const amount = value.remaining;
                    claim_detail[code] = {amount};
                }
            }
            
            return{
                ...item,
                address,
                contract,
                salutation:filterDropdown(props.salutation,item.salutation),
                join_date:convertDataDate(item.join_date),
                pr_start_date:convertDataDate(item.pr_start_date),
                date_of_birth:convertDataDate(item.date_of_birth),
                gender:filterDropdown(props.gender,item.gender),
                race:filterDropdown(props.race,item.race),
                religion:filterDropdown(props.religion,item.religion),
                relationship:filterDropdown(props.relationship,item.relationship),
                payment:filterDropdown(props.payment,item.payment),
                nationality:filterCountryDropdown(props.nationalitylist,item.nationality),
                probation_end_date:convertDataDate(item.probation_end_date),
                is_pr:item.is_pr ? 'Yes' : 'No',
                leave_detail,
                claim_detail,
            }
        })

        const contract_head =[
            {
                headerName:'Start Date',
                field:'contract.start_date',
                cellRenderer:params=>{
                    return params.value ?? '-'
                },
            },
            {
                headerName:'Contract Type',
                field:'contract.contract_type',
                cellRenderer:params=>{
                    return params.value ?? '-'
                },
            },
            {
                headerName:'Contract Rate',
                field:'contract.contract_rate',
                cellRenderer:params=>{
                    return params.value ?? '-'
                },
            },
            {
                headerName:'Is Workman',
                field:'contract.is_workman',
                cellRenderer:params=>{
                    return params.value ?? '-'
                },
            },
            {
                headerName:'Schedule',
                field:'contract.wschedule_detail.name',
                cellRenderer:params=>{
                    return params.value ?? '-'
                },
            },
            {
                headerName:'Basic Pay',
                field:'contract.pay',
                cellRenderer:params=>{
                    return params.value ?? '-'
                },
            },
            {
                headerName:'Hourly Rate',
                field:'contract.hourly_rate',
                cellRenderer:params=>{
                    return params.value ?? '-'
                },
            },
            {
                headerName:'Daily Rate',
                field:'contract.daily_rate',
                cellRenderer:params=>{
                    return params.value ?? '-'
                },
            },
            {
                headerName:'Hourly Rate',
                field:'contract.hourly_rate',
                cellRenderer:params=>{
                    return params.value ?? '-'
                },
            },
            {
                headerName:'Hours per Day(Daily rated staff only) ',
                field:'contract.hours_day',
                cellRenderer:params=>{
                    return params.value ?? '-'
                },
            },
            {
                headerName:'Currency',
                field:'contract.currency.currency_name',
                cellRenderer:params=>{
                    return params.value ?? '-'
                },
            },
            {
                headerName:'Department',
                field:'contract.department',
                cellRenderer:params=>{
                    return params.value ?? '-'
                },
            },
            {
                headerName:'Section',
                field:'contract.section',
                cellRenderer:params=>{
                    return params.value ?? '-'
                },
            },
            {
                headerName:'Job Level',
                field:'contract.job_level',
                cellRenderer:params=>{
                    return params.value ?? '-'
                },
            },
            {
                headerName:'Cost Center',
                field:'contract.cost_center',
                cellRenderer:params=>{
                    return params.value ?? '-'
                },
            },
        ];

        for(const value of props.allowancelist){
            contract_head.push({headerName:`${value.code} - ${value.name}`,field:`contract.allowance_detail.${value.name}`})
        }

        for(const value of props.recurringlist){
            contract_head.push({headerName:`${value.code} - ${value.name}`,field:`contract.recurring_detail.${value.name}`})
        }

        const leave_head = [
            {
                headerName:'Leave Scheme',
                field:'metadata.leave_scheme.name',
                cellRenderer:params=>{
                    return params.value ?? '-'
                },
            }
        ]

        for(const value of props.leavelist){
            leave_head.push({headerName:`${value.code} - ${value.name}`,field:`leave_detail.${value.code}.amount`});
        }

        const claim_head = [
            {
                headerName:'Claim Scheme',
                field:'metadata.claim_scheme.name',
                cellRenderer:params=>{
                    return params.value ?? '-'
                },
            }
        ]

        for(const value of props.claimlist){
            claim_head.push({headerName:`${value.code} - ${value.name}`,field:`claim_detail.${value.code}.amount`});
        }

        const column= [
            {
                headerName:'Profile',
                children:[
                    {
                        headerName:'Salutation',
                        field:'salutation',
                        width:20,
                        hide:false,
                        filter:true,
                    },
                    {
                        headerName:'Name',
                        field:'name',
                        width:20
                    },
                    {
                        headerName:'Employee Number',
                        field:'employee_number',
                        width:20,
                    },
                    {
                        headerName:'Badge Number',
                        field:'badge_number',
                        width:20,
                    },
                    {
                        headerName:'Email Address',
                        field:'email',
                        width:20,
                        cellRenderer:params=>{
                            return params.value == null || params.value === '' ? '-' : params.value 
                        },
                    },
                    {
                        headerName:'Citizenship',
                        field:'nationality',
                        width:20,
                        filter:true
                    },
                    {
                        headerName:'Identification Type',
                        field:'id_type',
                        width:20,
                    },
                    {
                        headerName:'Identification Number',
                        field:'identification_number',
                        width:20,
                    },
                    {
                        headerName:'Contact Number',
                        field:'contact_number',
                        width:20,
                    },
                    {
                        headerName:'Gender',
                        field:'gender',
                        width:20,
                    },
                    {
                        headerName:'Race',
                        field:'race',
                        width:20,
                    },
                    {
                        headerName:'Religion',
                        field:'religion',
                        width:20
                    },
                    {
                        headerName:'Relationship',
                        field:'relationship',
                        width:20
                    },
                    {
                        headerName:'Date of Birth',
                        field:'date_of_birth',
                        width:20
                    },
                    {
                        headerName:'Company',
                        field:'metadata.company',
                        width:20
                    },
                    {
                        headerName:'Branch',
                        field:'metadata.branch',
                        width:20,
                        cellRenderer:params=>{
                            return params.value ?? '-'
                        },
                    },
                    {
                        headerName:'Payment',
                        field:'payment',
                        width:20
                    },
                    {
                        headerName:'Is PR',
                        field:'is_pr',
                        width:20
                    },
                    {
                        headerName:'PR Start Date',
                        field:'pr_start_date',
                        width:20
                    },
                    {
                        headerName:'Join Date',
                        field:'join_date',
                        width:20
                    },
                    {
                        headerName:'Probation End Date',
                        field:'probation_end_date',
                        width:20
                    },
                ]
                
            },
            {
                headerName:'Address',
                children:[
                    {
                        headerName:'Country of Residence',
                        field:'address.country_name',
                        cellRenderer:params=>{
                            return params.value ?? '-'
                        },
                    },
                    {
                        headerName:'SG Postal Code',
                        field:'address.sg_postal_code',
                        cellRenderer:params=>{
                            return params.value ?? '-'
                        },
                    },
                    {
                        headerName:'Block Number',
                        field:'address.block_number',
                        cellRenderer:params=>{
                            return params.value ?? '-'
                        },
                    },
                    {
                        headerName:'Street Name',
                        field:'address.street_name',
                        cellRenderer:params=>{
                            return params.value ?? '-'
                        },
                    },
                    {
                        headerName:'Level Number',
                        field:'address.level_number',
                        cellRenderer:params=>{
                            return params.value ?? '-'
                        },
                    },
                    {
                        headerName:'Unit Number',
                        field:'address.unit_number',
                        cellRenderer:params=>{
                            return params.value ?? '-'
                        },
                    },
                    {
                        headerName:'Postal Code (Oversea)',
                        field:'address.postal_code',
                        cellRenderer:params=>{
                            return params.value ?? '-'
                        },
                    },
                    {
                        headerName:'Address (Oversea)',
                        field:'address.address',
                        cellRenderer:params=>{
                            return params.value ?? '-'
                        },
                    }
                ]
            },
            {
                headerName:'Contract',
                children:contract_head
            },
            {
                headerName:'Leave',
                children:leave_head
            },
            {
                headerName:'Claim',
                children:claim_head
            }
        ]

        setState(prevState=>({...prevState,rowData:empdata,columnDefs:column}))
    },[props.emplist,props.addresslist,props.nationalitylist,props.allowancelist,props.claim_available,props.claimlist,props.contract_ratelist,props.contract_typelist,props.gender,props.leave_available,props.leavelist,props.payment,props.race,props.recurringlist,
    props.relationship,props.religion,props.salutation,state.count])

    const onSelectTemplate=()=>{

        if(state.select_temp !== ''){
            const data = props.templatelist.find(item=>item.id === state.select_temp);
            const {model,columnDefs} = data;
            gridOptions.api.setFilterModel(model);
            gridOptions.api.setColumnDefs(columnDefs);
        }
        else{
            gridOptions.api.setFilterModel(null);
            setState(prevState=>({...prevState,count:prevState.count+1,rowData:[],columnDefs:[]}))
        }
    }


    const option1 = props.templatelist.filter(item=>item.type === 'emplistingreport')
    return(
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className='p-2'>
                    <BreadCrumbList list={[
                        { active: false, title: IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title: IMLocalized('report_generator')},
                        { active: true, title: IMLocalized('custom_report')}
                    ]}/>
                </Col>
            </Row>
            <div className='mt-2 d-flex justify-content-between'>
                <div>
                    <button className="btn btn-success btn-sm"
                    onClick={()=>{
                        const today = moment(new Date()).format('DD/MM/YYYY');
                        const time = moment().format('HH:mm:ss');
                        const getRows = () => [
                            [
                            {
                                data: {
                                    value: `Employee Listing Report - Printed On : ${today} ${time}`,
                                    type: 'String',
                                },
                            },
                            ],
                            []
                        ];

                        const getParams = () => ({
                            prependContent: getRows(),
                            fileName:'EmployeeListingReport.xlsx',
                            sheetName:'employee_listing',
                            columnWidth: 120
                        });
                        gridApi.current.exportDataAsExcel(getParams());
                    }}>{IMLocalized('export')}</button>
                    <button className="btn btn-primary btn-sm" onClick={()=>setState(prevState=>({...prevState,toggle:true}))}>{IMLocalized('save_as_report_profile')}</button>   
                </div>
                <div>
                    <InputGroup size='sm'>
                        <Input type="select" style={{minWidth:'150px'}} value={state.select_temp} onChange={(e)=>{const value = e.target.value; setState(prevState=>({...prevState,select_temp:value}))}}>
                            <option value="">{IMLocalized('default')}</option>
                            {option1.map((item,index)=>{
                                return <option value={item.id} key={index}>{item.name}</option>
                            })}
                        </Input>
                        <InputGroupAddon addonType='append'>
                            <button className='btn btn-warning btn-sm'
                            onClick={onSelectTemplate}>{IMLocalized('confirm')}</button>
                        </InputGroupAddon>
                    </InputGroup>
                </div>
            </div>
            <div className='ag-theme-balham mt-2' style={{height:action_toggle ? 'calc(100vh - 260px)' : 'calc(100vh - 224px)' , width:'100%' }}>
                <AgGridReact
                columnDefs={state.columnDefs}
                gridOptions={gridOptions}
                rowData={state.rowData}
                onGridReady={(params)=>{
                    gridApi.current = params.api;
                    gridColumnApi.current = params.columnApi;
                }}
                />
            </div>
        </Container>
        <ModalSaveCustomTemplate toggle={state.toggle} closeModal={()=>setState(prevState=>({...prevState,toggle:false}))} 
        onSave={(name)=>{
            const model = gridOptions.api.getFilterModel();
            const columnstate = gridColumnApi.current.getColumnState();
            let arr  =[];
            for(let i=0;i<state.columnDefs.length;i++){
                const item = state.columnDefs[i];
                const children = item.children;
                for(let j=0;j<children.length;j++){
                    const field = children[j].field;
                    const detail = columnstate.filter(function (item1){
                        return item1.colId === field;
                    })
                    let hide = false;
                    let rowGroup= true;
                    let rowGroupIndex= 0;
                    if(detail.length !== 0){
                        hide = detail[0].hide;
                        rowGroup = detail[0].rowGroup;
                        rowGroupIndex = detail[0].rowGroupIndex;
                    }
                    children[j].hide = hide;
                    children[j].rowGroup = rowGroup;
                    children[j].rowGroupIndex = rowGroupIndex;
                }
                arr.push(item);
            }
            props.create_general_aggrid_template(name,'emplistingreport',model,arr);
        }} />
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(EmployeeListingReport);
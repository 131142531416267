import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';

export const WIZARDVALIDATEFLAT_REQUEST ='WIZARDVALIDATEFLAT_REQUEST';
export const WIZARDVALIDATEFLAT_SUCCESS ='WIZARDVALIDATEFLAT_SUCCESS';
export const WIZARDVALIDATEFLAT_FAILURE ='WIZARDVALIDATEFLAT_FAILURE';

export const wizard_validate_flat = (data)=>{
    return dispatch=>{
        dispatch({
            type:'WIZARDVALIDATEFLAT_REQUEST'
        });

        myaxios.post('company/flat_allowance/array_validate/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'WIZARDVALIDATEFLAT_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'WIZARDVALIDATEFLAT_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('validate_failure')
            });
        })
    }
}
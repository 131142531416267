import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { getDateFormat } from '../../../func/getDateFormat';
import { IMLocalized } from '../../../language/IMLocalized';


const mapStateToProps=(state)=>({
    data:state.user_self.data,
    isLoading:state.user_self.isLoading,
    salutationlist:state.dropdown_list.salutation,
    nationalitylist:state.dropdown_list.countries,
    genderlist:state.dropdown_list.gender,
    racelist:state.dropdown_list.race,
    religionlist:state.dropdown_list.religion,
    relationshiplist:state.dropdown_list.relationship,
    paymentlist:state.dropdown_list.payment,
    companylist:state.company_list.data,
    branchlist:state.get_branch_list.data,
    sg_identification_type:state.dropdown_list.s_g_identification_type,
    sgpr_employee_cpf_payment:state.dropdown_list.sgpr_employee_cpf_payment,
})

function UserEmployeeDetail(props){


    let salutation = '';
    let nationality = '';
    let gender = '';
    let race = '';
    let religion = '';
    let relationship = '';
    let id_name = '';

    if(props.data.length !== 0){
        const salutation_detail = props.salutationlist.find(item=>item.value === props.data.salutation);
        salutation = salutation_detail?.display ?? '-';
    
        const nationality_detail = props.nationalitylist.find(item=>item.value === props.data.nationality);
        nationality = nationality_detail?.display_name ?? '-';

        const gender_detail = props.genderlist.find(item=>item.value === props.data.gender);
        gender = gender_detail?.display ?? '-';

        const race_detail = props.racelist.find(item=>item.value === props.data.race);
        race = race_detail?.display ?? '-';

        const religion_detail = props.religionlist.find(item=>item.value === props.data.religion);
        religion = religion_detail?.display ?? '-';

        const relationship_detail = props.relationshiplist.find(item=>item.value === props.data.relationship);
        relationship = relationship_detail?.display ?? '-';

        const id_detail = props.sg_identification_type.find(item=>item.value === props.data.id_type);
        id_name = id_detail?.display ?? '-';
    }

    return(
        <>
        <div className='d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title'>
            <div className='d-flex align-items-center'>
                <i className='far fa-id-card text-primary' />
                <h4 className='text-primary m-0 pl-2'>{IMLocalized('employee_profile')}</h4>
            </div>
        </div>
        {props.isLoading && <div className='p-2'><h4>{IMLocalized('loading')}..</h4></div>}
        {!props.isLoading && props.data.length === 0 && <div className='p-2'><h4>{IMLocalized('no_data')}</h4></div>}
        {!props.isLoading && props.data.length !== 0 &&
        <Row className='mt-2'>
            <Col lg="6">
                <Row className='pl-2'>
                    <Col xs="5">
                        <h5>{IMLocalized('name')}</h5>
                    </Col>
                    <Col xs="7">
                        {salutation} {props.data?.name}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className='pl-2'>
                    <Col xs="5">
                        <h5>{IMLocalized('employee_number')}</h5>
                    </Col>
                    <Col xs="7">
                        {props.data.employee_number}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('badge_number')}</h5>
                    </Col>
                    <Col xs="7">
                        {props.data.badge_number}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('email_address')}</h5>
                    </Col>
                    <Col xs="7">
                        {props.data.email ?? '-'}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('nationality')}</h5>
                    </Col>
                    <Col xs="7">
                        {nationality}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('gender')}</h5>
                    </Col>
                    <Col xs="7">
                        {gender}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('race')}</h5>
                    </Col>
                    <Col xs="7">
                        {race}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('religion')}</h5>
                    </Col>
                    <Col xs="7">
                        {religion}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('relationship')}</h5>
                    </Col>
                    <Col xs="7">
                        {relationship}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('id_type')}</h5>
                    </Col>
                    <Col xs="7">
                        {id_name}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('identification_number')}</h5>
                    </Col>
                    <Col xs="7">
                        {props.data.identification_number}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('contact_number')}</h5>
                    </Col>
                    <Col xs="7">
                        {props.data.contact_number}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('date_of_birth')}</h5>
                    </Col>
                    <Col xs="7">
                        <div>
                            {getDateFormat(props.data.date_of_birth)} 
                        </div>
                    </Col>
                </Row>
            </Col>
            {props.data.nationality !== 'SG' &&
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('is_pr')}</h5>
                    </Col>
                    <Col xs="7">
                        {props.data.is_pr ? IMLocalized('yes') : IMLocalized('no')}
                    </Col>
                </Row>
            </Col>}
            {props.data.nationality !== 'SG' && props.data.is_pr ===true &&
            <>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('pr_start_date')}</h5>
                    </Col>
                    <Col xs="7">
                        {getDateFormat(props.data.pr_start_date)}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('pr_cpf_contribution_type')}</h5>
                    </Col>
                    <Col xs="7">
                        {IMLocalized(props.data.pr_cpf_contribution_type) }
                    </Col>
                </Row>
            </Col>
            </>}
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('company')}</h5>
                    </Col>
                    <Col xs="7">
                        {props.data.metadata.company}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('branch')}</h5>
                    </Col>
                    <Col xs="7">
                        {props.data.metadata.branch ?? '-'}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('payment')}</h5>
                    </Col>
                    <Col xs="7">
                        {IMLocalized(props.data.payment)}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('join_date')}</h5>
                    </Col>
                    <Col xs="7">
                        {getDateFormat(props.data.join_date)}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('probation_end_date')}</h5>
                    </Col>
                    <Col xs="7">
                        {props.data.probation_end_date ? getDateFormat(props.data.probation_end_date) : '-'}
                    </Col>
                </Row>
            </Col>
            {props.data.resignation_date &&
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('resign_date')}</h5>
                    </Col>
                    <Col xs="7">
                        {getDateFormat(props.data.resignation_date)}
                    </Col>
                </Row>
            </Col>}
        </Row>}
        </>
    )
}
export default connect(mapStateToProps,null)(UserEmployeeDetail);
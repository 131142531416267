import * as claimapprovalgroup from '../../action/user/user_claim_approval_group';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const user_claim_approval_group=(state=initialState,action)=>{
    switch(action.type){
        case claimapprovalgroup.USERCLAIMAPPROVALGROUP_SUCCESS:
            return{
                ...state,
                data:action.payload
            }
        case claimapprovalgroup.USERCLAIMAPPROVALGROUP_FAILURE:
            return{
                ...state,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default user_claim_approval_group;

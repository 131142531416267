import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CONTRACTINPAY_REQUEST = 'CONTRACTINPAY_REQUEST';
export const CONTRACTINPAY_SUCCESS = 'CONTRACTINPAY_SUCCESS';
export const CONTRACTINPAY_FAILURE = 'CONTRACTINPAY_FAILURE';


export const create_contract_increment_pay_daily =(data)=>{
    return dispatch=>{
        dispatch({
            type:'CONTRACTINPAY_REQUEST'
        });

        myaxios.post('contract/incpay/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'CONTRACTINPAY_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CONTRACTINPAY_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}

import * as delclaimscheme from '../../action/claim/delete_claim_scheme';

const initialState={
    errors:null,
    isLoading:false,
    data:null
}

const delete_claim_scheme = (state=initialState,action)=>{
    switch(action.type){
        case delclaimscheme.DELCLAIMSCHEME_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case delclaimscheme.DELCLAIMSCHEME_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case delclaimscheme.DELCLAIMSCHEME_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case delclaimscheme.DELCLAIMSCHEME_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default delete_claim_scheme;
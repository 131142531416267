import * as create_delegation from '../../action/employee/create_employee_delegation';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const create_employee_delegation = (state=initialState,action)=>{
    switch(action.type){
        case create_delegation.CREATEEMPDELEGATION_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errros:null,
            }
        case create_delegation.CREATEEMPDELEGATION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case create_delegation.CREATEEMPDELEGATION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case create_delegation.CREATEEMPDELEGATION_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default create_employee_delegation;
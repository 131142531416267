import * as leave_option from '../action/get_leave_option';

const initialState={
    data:null,
    errors:null,
    isLoading:false
}

const get_leave_option = (state=initialState,action)=>{
    switch(action.type){
        case leave_option.GETLEAVEOPTION_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null
            }
        case leave_option.GETLEAVEOPTION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                errors:[],
                data:action.payload?.data
            }
        case leave_option.GETLEAVEOPTION_FAILURE:
            return{
                ...state,
                errors:action.payload,
                isLoading:false,
            }
        default:
            return state;
    }
}
export default get_leave_option;

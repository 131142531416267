
export const pay_period_list = [
    {
        "value": "FULLMONTH",
        "display": "Full Month",
        "display_zh": "整月"
    },
    {
        "value": "CUSTOM",
        "display": "Custom Period",
        "display_zh":"定制"
    }
]
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, Table } from 'reactstrap';
import '../../../css/card.css';
import { popError } from '../../../func/popError';
import { IMLocalized } from '../../../language/IMLocalized';
import { payslip_update_payroll_count } from '../../../action/payroll/payslip_update_payroll_count';

const mapDispatchToProps=(dispatch)=>({
    payslip_update_payroll_count:(id,data)=>{
        dispatch(payslip_update_payroll_count(id,data))
    },
})

function DailyBasicTable(props){

    const [list,setList] = useState([]);

    useEffect(()=>{
        setList(props.list);
    },[props.list])

    return(
        <Card className='payslip_card'>
            <CardHeader className='p-2 border-0 bg-white'>
                <h4 className='text-primary pl-2 pt-2'>{IMLocalized('daily_rate')}</h4>
            </CardHeader>
            <Table size="sm" className='align-items-center' responsive>
                <thead className='text-center p-1 thead-light'>
                    <tr>
                        <th className="p-2">{IMLocalized('name')}</th>
                        <th className="p-2">{IMLocalized('payslip_rate')}</th>
                        <th className="p-2">{IMLocalized('payslip_count')}</th>
                        <th className="p-2">{IMLocalized('amount')}</th>
                        <th className="p-2">{IMLocalized('update')}</th>
                    </tr>
                </thead>
                <tbody className='text-center p-1'>{console.log(list)}
                    {list.map((item,index)=>{

                        return(
                            <tr key={index}>
                                <td className="p-2">{item.name}</td>
                                <td className="p-2">{item.rate}</td>
                                <td className="p-2">
                                    <input type="number" className="text-center" name="ot_hours" min="0" value={item.count} style={{width:'70px'}} 
                                    onChange={(e)=>{
                                        const value = e.target.value;
                                        setList(prevState=>{
                                            const newState= prevState.map((obj,i)=>{
                                                if(index === i){
                                                    return {...obj,count:value}
                                                }
                                                else{
                                                    return obj;
                                                }
                                            })
                                            return newState;
                                        })
                                    }}
                                    />
                                </td>
                                <td >${(item.amount).toFixed(2)}</td>
                                <td align="center" valign="center" className="p-2">
                                    <div className="primary_btn d-flex align-items-center justify-content-center">
                                        <i className="fas fa-check text-primary cursor-pointer"
                                        onClick={()=>{
                                            const detail = list[index];
                                            const data = [{paycomponent:detail.id,count:detail.count}];
                                            if(detail.count === ''){
                                                popError(IMLocalized('value_is_required'),IMLocalized('value_cant_empty'));
                                            }
                                            else{
                                                props.payslip_update_payroll_count(props.id,data);
                                            }
                                        }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </Card>
    )
}
export default connect(null,mapDispatchToProps)(DailyBasicTable);
import myaxios from '../../axios';

export const GETAPPLYCLAIMLIST2_REQUEST = 'GETAPPLYCLAIMLIST2_REQUEST';
export const GETAPPLYCLAIMLIST2_SUCCESS = 'GETAPPLYCLAIMLIST2_SUCCESS';
export const GETAPPLYCLAIMLIST2_FAILURE = 'GETAPPLYCLAIMLIST2_FAILURE';

export const get_apply_claim_list2 =()=>{
    return dispatch=>{
        dispatch({
            type:'GETAPPLYCLAIMLIST2_REQUEST'
        });

        myaxios.get('claim/apply/details/')
        .then((response)=>{
            dispatch({
                type:'GETAPPLYCLAIMLIST2_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETAPPLYCLAIMLIST2_FAILURE',
                payload:error
            })
        })
    }
}
import * as contractjobtitle1 from '../../action/employee/employee_contract_jobtitle1';


const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const employee_contract_jobtitle1 = (state=initialState,action)=>{
    switch(action.type){
        case contractjobtitle1.EMPCONTRACTJOBTITLE1_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case contractjobtitle1.EMPCONTRACTJOBTITLE1_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case contractjobtitle1.EMPCONTRACTJOBTITLE1_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default employee_contract_jobtitle1;
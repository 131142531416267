import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATERESIGNEMPLOYEE_REQUEST = 'UPDATERESIGNEMPLOYEE_REQUEST';
export const UPDATERESIGNEMPLOYEE_SUCCESS = 'UPDATERESIGNEMPLOYEE_SUCCESS';
export const UPDATERESIGNEMPLOYEE_FAILURE = 'UPDATERESIGNEMPLOYEE_FAILURE';
export const UPDATERESIGNEMPLOYEE_RESET = 'UPDATERESIGNEMPLOYEE_RESET';

export const update_resign =(id,resignation_date,severance_month,severance_amount,notes,blacklist)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATERESIGNEMPLOYEE_REQUEST'
        });
        
        myaxios.patch(`employee/employees/${id}/resign/`,{
            resignation_date:resignation_date,
            severance_amount:severance_amount,
            severance_month:severance_month,
            blacklist:blacklist,
            notes:notes
        })
        .then((response)=>{
            dispatch({
                type:'UPDATERESIGNEMPLOYEE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATERESIGNEMPLOYEE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}

export const reset_update_resign = () => dispatch =>{
    dispatch({
        type:'UPDATERESIGNEMPLOYEE_RESET'
    });
}
import myaxios from '../../axios';

export const LEAVECALENDARGETSELFLEAVEOPTION_REQUEST = 'LEAVECALENDARGETSELFLEAVEOPTION_REQUEST';
export const LEAVECALENDARGETSELFLEAVEOPTION_SUCCESS = 'LEAVECALENDARGETSELFLEAVEOPTION_SUCCESS';
export const LEAVECALENDARGETSELFLEAVEOPTION_FAILURE = 'LEAVECALENDARGETSELFLEAVEOPTION_FAILURE';

export const leave_calendar_get_self_available_leave_options= (employee,leave_setting,from_date,to_date)=>{
    return dispatch=>{
        dispatch({
            type:'LEAVECALENDARGETSELFLEAVEOPTION_REQUEST'
        });

        myaxios.post(`leave/self/apply/get_leave_options/`,{
            employee:employee,
            leave_setting:leave_setting,
            from_date:from_date,
            to_date:to_date
        })
        .then((response)=>{
            dispatch({
                type:'LEAVECALENDARGETSELFLEAVEOPTION_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'LEAVECALENDARGETSELFLEAVEOPTION_FAILURE',
                payload:error
            })
        })
    }
}
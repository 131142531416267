import React, { useEffect, useRef, useState } from 'react';
import {Container,Row,Col,Input,FormFeedback, FormGroup} from 'reactstrap';
import {submit_user_newpass} from '../../action/submit_user_newpass';
import {useDispatch,useSelector} from 'react-redux';
import Swal from 'sweetalert2';
import {IMLocalized,init} from '../../language/IMLocalized';
import {get_general_logo} from '../../action/get_general_logo';
import a_line from '../../images/a_line.png';
import '../../css/user_form.css';
import PasswordChecklist from 'react-password-checklist';
import { usePrevious } from '../../hook/usePrevious';
import { popError } from '../../func/popError';

export default function UserForm(props){

    const dispatch = useDispatch();
    const state = useSelector(state=>state.submit_user_newpass);
    const general = useSelector(state=>state.get_general_logo);

    const [employee_number, setEmployeeNumber] = useState('');
    const [err_employee_number, setErrEmployeeNumber] = useState('');
    const [date_of_birth, setDateOfBirth] = useState('');
    const [err_date_of_birth, setErrDateOfBirth] = useState('');
    const [password, setPassword] = useState('');
    const [err_password,setErrPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [err_confirm_password, setErrConfirmPassword] = useState('');
    const [is_valid, setValid] = useState(false);
    const prevSuccess = usePrevious(state.data);
    const prevErrors = usePrevious(state.errors);
    const passwordRef = useRef();

    
    useEffect(()=>{
        document.title = process.env.REACT_APP_TITLE;
        dispatch(get_general_logo());
    },[dispatch])

    useEffect(()=>{
        if(general.data?.data != null){
            const picture = general.data.data;
            const byte = picture.byteLength;
            if(byte === 0){
                document.getElementById("favicon").href = a_line;
            }
            else{
                const url =  process.env.REACT_APP_API_URL;
                document.getElementById("favicon").href = `https://${url}/api/general/logo/`;
            }
        }
    },[general.data])

    useEffect(()=>{
        if(prevSuccess !== undefined && prevSuccess !== state.data && state.data != null){
            function getMobileOperatingSystem(){
                const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
                // Windows Phone must come first because its UA also contains "Android"
                if (/windows phone/i.test(userAgent)) {
                    return "Windows Phone";
                }
            
                if (/android/i.test(userAgent)) {
                    return "Android";
                }
            
                // iOS detection from: http://stackoverflow.com/a/9039885/177710
                if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                    return "iOS";
                }
            
                return "unknown";
            }
            
            
            Swal.fire({
                type:'success',
                title:IMLocalized('update_success'),
                confirmButtonText:IMLocalized('ok!')
            })
            .then(()=>{
                const platform = getMobileOperatingSystem();
                if(platform === 'iOS') window.location.href='https://apps.apple.com/sg/app/aline-mobile/id1604709107'
                else window.location.href ='https://play.google.com/store/apps/details?id=com.alinehrms.app.android'
            })
        }
    },[state.data,prevSuccess])

    useEffect(()=>{
        if(prevErrors !== undefined && prevErrors !== state.errors && state.errors != null){
            if(state.errors.hasOwnProperty('code')){
                const word = state.errors['code'].toString();
                popError(IMLocalized(word));
            }
            else if(state.errors.hasOwnProperty('non_field_errors')){
                const word = state.errors['non_field_errors'].toString();
                popError(IMLocalized(word));
            }
        }
    },[prevErrors,state.errors])


    const onSubmit=(event)=>{
        event.preventDefault();
        const uuid = props.match.params.data;
        if(is_valid && employee_number !== '' && date_of_birth !== ''){
            dispatch(submit_user_newpass(uuid,employee_number,date_of_birth,password));
        }
        else{
            if(date_of_birth === ''){
                setErrDateOfBirth(IMLocalized('date_of_birth_is_required'));
            }
            if(employee_number === ''){
                setErrEmployeeNumber(IMLocalized('employee_number_is_required'));
            }
            if(is_valid === false){
                passwordRef.current.scrollIntoView({behavior:'smooth',block:'start'})
                setErrConfirmPassword(IMLocalized('confirm_password_required'));
                setErrPassword(IMLocalized('login_password_required'))
            }
        }
    }

    const current  = window.navigator.language;
    const word = current.substring(0,2);
    
    if(word === 'zh') init('zh')
    else init('en')

    return(
        <Container className='login2_main' fluid>
            <Row className='d-flex justify-content-center'>
                <Col lg="6" md="8" sm="9" xs="11"  className='user_form_main'>
                    <div className='d-flex justify-content-center'>
                        <h2 className='mb-0'>{IMLocalized('welcometo')} {process.env.REACT_APP_TITLE}</h2>
                    </div>
                    <form className='mt-4' onSubmit={onSubmit}>
                        <FormGroup>
                            <label className='form-control-label' htmlFor='employee_number'>{IMLocalized('employee_number')}</label>
                            <Input id="employee_number" type="text" value={employee_number} invalid={err_employee_number.length >0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setEmployeeNumber(value);
                                setErrEmployeeNumber('');
                            }}  />
                            <FormFeedback>{err_employee_number}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <label className='form-control-label' htmlFor='date_of_birth'>{IMLocalized('date_of_birth')}</label>
                            <Input id="date_of_birth" type="date" value={date_of_birth} invalid={err_date_of_birth.length > 0}
                            onChange={(e)=>{
                                const value = e.target.value;
                                setDateOfBirth(value);
                                setErrDateOfBirth('');
                            }}/>
                            <FormFeedback>{err_date_of_birth}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <label className='form-control-label' htmlFor='password'>{IMLocalized('password')}</label>
                            <Input id="password" type="password" value={password} invalid={err_password.length >0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setPassword(value);
                                setErrPassword('');
                            }}
                            />
                            <FormFeedback>{err_password}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <label className='form-control-label' htmlFor='confirm_password'>{IMLocalized('confirm_password')}</label>
                            <Input id="confirm_password" type="password" value={confirm_password} invalid={err_confirm_password.length >0} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                setConfirmPassword(value);
                                setErrConfirmPassword('');
                            }}
                            />
                            <FormFeedback>{err_confirm_password}</FormFeedback>
                        </FormGroup>
                        <div ref={passwordRef}>
                            <PasswordChecklist 
                                rules={['minLength','number','capital','match','lowercase','notEmpty','specialChar']}
                                minLength={8}
                                value={password}
                                valueAgain={confirm_password}
                                onChange={(is_valid)=>{setValid(is_valid)}}
                                messages={{
                                    minLength: IMLocalized('password_has_8_char'),
                                    number: IMLocalized('password_has_a_number'),
                                    capital: IMLocalized('password_has_a_capital_letter'),
                                    match:IMLocalized('password_match'),
                                    notEmpty:IMLocalized('password_not_empty'),
                                    lowercase:IMLocalized('password_has_a_lowercase_letter'),
                                    specialChar:IMLocalized('password_has_special')
                                }}
                            />
                        </div>
                        <button className="mt-2 btn btn-lg btn-block btn2-login" type="submit">{IMLocalized('submit')}</button>
                    </form>
                </Col>
            </Row>
        </Container>
    )
}
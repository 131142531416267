import * as arrayrejectclaim from '../../action/employee/emp_all_array_reject_apply_claim';

const initialState={
    isLoading:false,
    errors:null,
    data:null,
}

const emp_all_array_reject_apply_claim = (state=initialState,action)=>{
    switch(action.type){
        case arrayrejectclaim.EMPALLARRAYREJECTCLAIM_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case arrayrejectclaim.EMPALLARRAYREJECTCLAIM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case arrayrejectclaim.EMPALLARRAYREJECTCLAIM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default emp_all_array_reject_apply_claim;
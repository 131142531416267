import { getDateFormat } from '../../func/getDateFormat';
import { IMLocalized } from '../../language/IMLocalized';

export function date2Renderer(params){
    const date = params.value;
    let date2 = '';
    if(date !== IMLocalized('total')){
        date2 = getDateFormat(date);
    }
    else{
        date2 = params.value;
    }

    return date2
}
import myaxios from '../../axios';

export const LEAVESGMATERNITY_REQUEST = 'LEAVESGMATERNITY_REQUEST';
export const LEAVESGMATERNITY_SUCCESS = 'LEAVESGMATERNITY_SUCCESS';
export const LEAVESGMATERNITY_FAILURE = 'LEAVESGMATERNITY_FAILURE';
export const LEAVESGMATERNITY_RESET = 'LEAVESGMATERNITY_RESET';

export const create_sg_maternity = (employee,estimated_delivery_date,block_leave_start_date,birth_times,is_sg,flexible_leave)=>{
    return dispatch=>{
        dispatch({
            type:'LEAVESGMATERNITY_REQUEST'
        });

        myaxios.post('leave/sg/maternity/validate/',{
            employee:employee,
            estimated_delivery_date:estimated_delivery_date,
            block_leave_start_date:block_leave_start_date,
            birth_times:birth_times,
            flexible_leave:flexible_leave,
            is_sg:is_sg
        })
        .then((response)=>{
            dispatch({
                type:'LEAVESGMATERNITY_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'LEAVESGMATERNITY_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_create_sg_maternity = () => dispatch =>{
    dispatch({
        type:'LEAVESGMATERNITY_RESET'
    });
}
import myaxios from '../axios';

export const GETAPPLYLEAVELIST_REQUEST = 'GETAPPLYLEAVELIST_REQUEST';
export const GETAPPLYLEAVELIST_SUCCESS = 'GETAPPLYLEAVELIST_SUCCESS';
export const GETAPPLYLEAVELIST_FAILURE = 'GETAPPLYLEAVELIST_FAILURE';

export const get_apply_leave_list =()=>{
    return dispatch=>{
        dispatch({
            type:'GETAPPLYLEAVELIST_REQUEST'
        });

        myaxios.get('leave/employee/')
        .then((response)=>{
            dispatch({
                type:'GETAPPLYLEAVELIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETAPPLYLEAVELIST_FAILURE',
                payload:error
            })
        })
    }
}
import * as claimlistcancelclaim from '../../action/user/user_claim_detail_cancel_apply_claim';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const user_claim_detail_cancel_apply_claim =(state=initialState,action)=>{
    switch(action.type){
        case claimlistcancelclaim.USERCLAIMDETAILCANCELCLAIM_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case claimlistcancelclaim.USERCLAIMDETAILCANCELCLAIM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case claimlistcancelclaim.USERCLAIMDETAILCANCELCLAIM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case claimlistcancelclaim.USERCLAIMDETAILCANCELCLAIM_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default user_claim_detail_cancel_apply_claim;
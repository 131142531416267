import * as deleteschedule from '../action/delete_schedule';

const initialState ={
    isLoading:false,
    errors:null,
    data:null
}

const delete_schedule = (state=initialState,action) =>{
    switch(action.type){
        case deleteschedule.DELSCHEDULE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case deleteschedule.DELSCHEDULE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case deleteschedule.DELSCHEDULE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default delete_schedule;
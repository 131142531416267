import myaxios from '../../axios';

export const LEAVECALENDARGETSELFLEAVEOPTION1_REQUEST = 'LEAVECALENDARGETSELFLEAVEOPTION1_REQUEST';
export const LEAVECALENDARGETSELFLEAVEOPTION1_SUCCESS = 'LEAVECALENDARGETSELFLEAVEOPTION1_SUCCESS';
export const LEAVECALENDARGETSELFLEAVEOPTION1_FAILURE = 'LEAVECALENDARGETSELFLEAVEOPTION1_FAILURE';

export const leave_calendar_get_self_available_leave_options1= (employee,leave_setting,from_date,to_date)=>{
    return dispatch=>{
        dispatch({
            type:'LEAVECALENDARGETSELFLEAVEOPTION1_REQUEST'
        });

        myaxios.post(`leave/self/apply/get_leave_options/`,{
            employee:employee,
            leave_setting:leave_setting,
            from_date:from_date,
            to_date:to_date
        })
        .then((response)=>{
            dispatch({
                type:'LEAVECALENDARGETSELFLEAVEOPTION1_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'LEAVECALENDARGETSELFLEAVEOPTION1_FAILURE',
                payload:error
            })
        })
    }
}
import * as selfpassword from '../../action/employee/update_self_password';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const update_self_password = (state=initialState,action)=>{
    switch(action.type){
        case selfpassword.UPDATESELFPASSWORD_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
            }
        case selfpassword.UPDATESELFPASSWORD_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case selfpassword.UPDATESELFPASSWORD_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default update_self_password;
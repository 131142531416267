import * as bankarraysave from '../../action/wizard_employee/employee_bank_array_save';

const initialState={
    data:[],
    isLoading:false,
    errors:[]
}

const employee_bank_array_save = (state=initialState,action)=>{
    switch(action.type){
        case bankarraysave.EMPBANKARRAYSAVE_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case bankarraysave.EMPBANKARRAYSAVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case bankarraysave.EMPBANKARRAYSAVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default employee_bank_array_save;
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import Swal from "sweetalert2";
import { delete_claim_mass_group_template, reset_delete_claim_mass_group_template } from "../../action/claim/delete_claim_mass_group_template";
import { get_claim_group_template } from "../../action/claim/get_claim_group_template";
import { NewMainContext } from "../../context/NewMainContext";
import { IMLocalized } from "../../language/IMLocalized";
import ModalViewClaimGroupDetail from "./modal/ModalViewClaimGroupDetail";




export default function ClaimMassGroupTemplate(){

    const { addItem } = useContext(NewMainContext);
    const dispatch = useDispatch();
    const list = useSelector(state=>state.get_claim_group_template.data);
    const isLoading = useSelector(state=>state.get_claim_group_template.isLoading);
    const delete_success = useSelector(state=>state.delete_claim_mass_group_template.data);
    const [ view_toggle , setViewToggle] = useState(false);
    const [ view_id, setViewID] = useState(null);

    useEffect(()=>{
        if(delete_success != null){
            dispatch(get_claim_group_template());
            dispatch(reset_delete_claim_mass_group_template());
        }
    },[delete_success,dispatch])

    


    const toView =(id)=>{
        setViewToggle(true);
        setViewID(id);
    }

    const toDelete=(id,name)=>{
        Swal.fire({
            type:"warning",
            title:`${IMLocalized('do_you_really_want_to_delete')} \n ${IMLocalized('claim_group_template')} (` + name +`) !`,
            showCancelButton:true,
            confirmButtonColor:'red'
        })
        .then((result)=>{
            if(result.value){
                dispatch(delete_claim_mass_group_template(id));
            }
        })
    }

    return(
        <>
            <Container fluid>
                <Row className="bg-white border-bottom">
                    <Col className="p-2">
                        <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                            <BreadcrumbItem><a href="/#" onClick={()=>addItem('1','dashboard')}>{IMLocalized('home')}</a></BreadcrumbItem>
                            <BreadcrumbItem active>{IMLocalized('claim_group_template')}</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="mt-2">
                    <Col>
                        <Card>
                            <CardHeader className="d-flex align-items-center">
                                <h4 className="mb-0">{IMLocalized('claim_group_template')}</h4>
                            </CardHeader>
                            {isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody> }
                            {!isLoading && list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody> }
                            {!isLoading && list.length !== 0 &&
                            <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 320px)'}}>
                                {list.map((item,index)=>{
                                    const { name, id} = item;
                                    const number = index + 1;
                                    return(
                                        <ListGroupItem className="py-2" key={item.id}>
                                            <Row className="align-items-center">
                                                <Col>
                                                    <span>{number}. {name}</span>
                                                </Col>
                                                <Col className="col-auto mr-2">
                                                    <button className="btn btn-primary btn-sm" onClick={()=>toView(id)}>{IMLocalized('view')}</button>
                                                    <button className="btn btn-danger btn-sm" onClick={()=>toDelete(id,name)}>{IMLocalized('delete')}</button>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                    )
                                })}
                            </ListGroup>}
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalViewClaimGroupDetail toggle={view_toggle} id={view_id} closeModal={()=>{ setViewToggle(false); setViewID(null); }} />
        </>
    )
}
import * as detail from '../../action/iras/get_iras_detail';


const initialState={
    data:null,
    errors:null,
    isLoading:false
}


const get_iras_detail = (state=initialState,action)=>{
    switch(action.type){
        case detail.GETIRASDETAIL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case detail.GETIRASDETAIL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case detail.GETIRASDETAIL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case detail.GETIRASDETAIL_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state
    }
}
export default get_iras_detail;
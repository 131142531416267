import myaxios from "../../axios";


export const GETCOMBANK_REQUEST = 'GETCOMBANK_REQUEST';
export const GETCOMBANK_SUCCESS = 'GETCOMBANK_SUCCESS';
export const GETCOMBANK_FAILURE = 'GETCOMBANK_FAILURE';


export const get_company_bank = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETCOMBANK_REQUEST'
        });

        myaxios.get('company/bank/')
        .then((response)=>{
            dispatch({
                type:'GETCOMBANK_SUCCESS',
                payload:response
            })
        })
        .catch((errors)=>{
            dispatch({
                type:'GETCOMBANK_FAILURE',
                payload:errors,
            })
        })
    }
}
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CREATECOMBANK_REQUEST = 'CREATECOMBANK_REQUEST';
export const CREATECOMBANK_SUCCESS = 'CREATECOMBANK_SUCCESS';
export const CREATECOMBANK_FAILURE = 'CREATECOMBANK_FAILURE';
export const CREATECOMBANK_RESET = 'CREATECOMBANK_RESET';

export const create_company_bank = (id,bank,account_number)=>{
    return dispatch=>{
        dispatch({
            type:'CREATECOMBANK_REQUEST'
        })

        myaxios.post('company/bank/',{
            parent:id,
            bank:bank,
            account_number:account_number
        })
        .then((res)=>{
            dispatch({
                type:'CREATECOMBANK_SUCCESS',
                payload:res
            });
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((errors)=>{
            dispatch({
                type:'CREATECOMBANK_FAILURE',
                payload:errors
            }); 
            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}

export const reset_create_company_bank = () => dispatch =>{
    dispatch({
        type:'CREATECOMBANK_RESET'
    })
}
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import { NewMainContext } from "../../context/NewMainContext";
import { usePrevious } from "../../hook/usePrevious";
import { IMLocalized } from "../../language/IMLocalized";
import BreadCrumbList from "../breadcrumb/BreadCrumbList";
import OverlayLoading from "../loading_component/overlay_loading";
import IrasDetail from "./IrasDetail";
import PopupIras from "./modal/PopupIras";
import { get_iras_list } from "../../action/iras/get_iras_list";
import { get_additional_setting } from '../../action/additional_setting';


const mapStateToProps=(state)=>({
    iras_list:state.get_iras_list.data,
    isLoading:state.get_iras_list.isLoading,
    isLoading1:state.add.isLoading,
    jobtitlelist:state.add.jobtitle,
    create_success:state.create_new_iras.data,
})

const mapDispatchToProps=(dispatch)=>({
    get_iras_list:()=>{
        dispatch(get_iras_list())
    },
    get_additional_setting:()=>{
        dispatch(get_additional_setting())
    }
    // employee_contract_section:(value)=>{
    //     dispatch(employee_contract_section(value))
    // },
    // employee_contract_department:(value)=>{
    //     dispatch(employee_contract_department(value))
    // },
    // employee_contract_joblevel:(value)=>{
    //     dispatch(employee_contract_joblevel(value))
    // },
    // employee_contract_jobtitle:(value)=>{
    //     dispatch(employee_contract_jobtitle(value))
    // },
    // employee_contract_costcenter:(value)=>{
    //     dispatch(employee_contract_costcenter(value))
    // },
})

function IrasList(props){
    const { iras_step} = useContext(NewMainContext);

    return(
        <>
        {/* iras_list={props.iras_list} create_success={props.create_success} */}
        {iras_step === '0' && <IrasTable props={props} />}
        {iras_step === '1' && <IrasDetail/>}
        </>
    )

}


function IrasTable({props}){
    // const isLoading = useSelector(state=>state.get_iras_list.isLoading);
    // const isLoading1 = useSelector(state=>state.add.isLoading);

    const {setIrasStep, setIrasId, addItem} = useContext(NewMainContext);
    
    const [create_toggle,setCreateToggle] = useState(false);
    const {isLoading, isLoading1,create_success, get_iras_list,get_additional_setting,jobtitlelist} = props
    const prevCreateSuccess = usePrevious(create_success);

    const toDetail=(id)=>{
        setIrasStep('1');
        setIrasId(id);
        localStorage.setItem('irasdetail_id',id);
        localStorage.setItem('iraslist_step','1');
    }

    useEffect(()=>{
        // if(prevCreateSuccess !== undefined && prevCreateSuccess !== create_success && create_success !== null){
        if(create_success !== null){
            setCreateToggle(false);
            get_iras_list();
            get_additional_setting();
        }
    },[prevCreateSuccess,create_success,get_iras_list,get_additional_setting])

    return(
        <>
        {isLoading | isLoading1 ? <OverlayLoading/>:
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title:IMLocalized('iras')}
                    ]}
                    />
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className="mt-2">
                <Col>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col className="d-flex align-items-center">
                                    <h4 className="mb-0">{IMLocalized('iras')}</h4>
                                </Col>
                                <Col>
                                </Col>
                                <button 
                                    onClick={()=>setCreateToggle(true)}
                                    className="btn btn-primary btn-sm">
                                    {IMLocalized('create_new_iras')}
                                </button>
                            </Row>
                        </CardHeader>
                        {props.iras_list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                        {props.iras_list.length !== 0 &&
                        <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 250px)'}}>
                            {props.iras_list.map((item,index)=>{
                                const y = index+1;
                                return(
                                    <ListGroupItem key={item.id} className="py-2">
                                        <Row className="align-items-center">
                                            <Col>
                                                <span>{y}. {item.EmployerName} ({item.BasisYear}) - {item.BatchIndicator}</span> 
                                            </Col>
                                            <Col className="col-auto mr-2">
                                                <button className="btn btn-info btn-sm" onClick={()=>toDetail(item.id)}>{IMLocalized('detail')}</button>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                )
                            })}

                        </ListGroup>
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
        <PopupIras toggle={create_toggle} jobtitlelist={jobtitlelist}
        closeModal={()=>{
            setCreateToggle(false);
        }} />
        </>}
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(IrasList);
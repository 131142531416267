import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATE_EMPLOYEEBANK_REQUEST = 'UPDATE_EMPLOYEEBANK_REQUEST';
export const UPDATE_EMPLOYEEBANK_SUCCESS = 'UPDATE_EMPLOYEEBANK_SUCCESS';
export const UPDATE_EMPLOYEEBANK_FAILURE = 'UPDATE_EMPLOYEEBANK_FAILURE';
export const UPDATE_EMPLOYEEBANK_RESET = 'UPDATE_EMPLOYEEBANK_RESET';


export const update_employee_bank = (bankdata)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATE_EMPLOYEEBANK_REQUEST'
        })
        if(bankdata.length !== 0){
            for(let i=0;i<bankdata.length ; i++){
                const {account_number, bank, id, bank_default} = bankdata[i];
                if(bank_default !== null ){
                    myaxios.get(`employee/bank/${id}/setdefault/`)   
                }

                myaxios.patch(`/employee/bank/${id}/`,{
                    bank:bank,
                    account_number:account_number,
                
                })
                .then((response)=>{
                    if(i === bankdata.length -1){
                        dispatch({
                            type:'UPDATE_EMPLOYEEBANK_SUCCESS',
                            payload:response
                        });

                        Toast.fire({
                            type: 'success',
                            title: IMLocalized('update_success')
                        });
                    }
                })
                .catch((error)=>{
                    dispatch({
                        type:'UPDATE_EMPLOYEEBANK_FAILURE',
                        payload:error
                    });

                    ErrToast.fire({
                        type:'error',
                        title:IMLocalized('update_failure')
                    });
                })
            }

        }
    }
}

export const reset_update_employee_bank = () => dispatch =>{
    dispatch({
        type:'UPDATE_EMPLOYEEBANK_RESET'
    });
}
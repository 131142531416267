import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMLocalized } from '../../../../language/IMLocalized';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { reset_update_employee_role, update_employee_role } from '../../../../action/employee/update_employee_role';

export default function ModalUpdateRole({toggle, closeModal, detail, id, onSuccess, name}){

    const isLoading = useSelector(state=>state.update_employee_role.isLoading);
    const success = useSelector(state=>state.update_employee_role.data);
    const dispatch = useDispatch();

    const [role_hr,setHR] = useState(false);
    const [role_claim,setClaim] = useState(false);
    const [role_leave,setLeave] = useState(false);
    const [role_manager,setManager] = useState(false);

    useEffect(()=>{
        if(toggle && detail){
            const hr = detail.roles.includes('role_hr');
            setHR(hr);

            const claim = detail.roles.includes('role_claim_officer');
            setClaim(claim);

            const leave = detail.roles.includes('role_leave_officer');
            setLeave(leave);

            const manager = detail.roles.includes('role_manager');
            setManager(manager)
        }
    },[toggle,detail])

    useEffect(()=>{
        if( toggle && success != null){
            onSuccess();
            dispatch(reset_update_employee_role());
        }
    },[success,toggle,onSuccess,dispatch])

    const updateRole=()=>{
        dispatch(update_employee_role(id,role_hr,role_leave,role_claim,role_manager))
    }

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('update_role')} - {name}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="update_role_hroh" name='update_role_hr' className="custom-control-input" checked={role_hr} onChange={(e)=>setHR(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="update_role_hroh">{IMLocalized('role_hr')}?</label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="update_role_manageroh" name='update_role_manager' className="custom-control-input" checked={role_manager} onChange={(e)=>setManager(e.target.checked)}  />
                            <label className="custom-control-label" htmlFor="update_role_manageroh">{IMLocalized('role_manager')}?</label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="update_role_leaveofficeroh" name='update_role_leave_officer' className="custom-control-input" checked={role_leave} onChange={(e)=>setLeave(e.target.checked)}  />
                            <label className="custom-control-label" htmlFor="update_role_leaveofficeroh">{IMLocalized('role_leave_officer')}?</label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="update_role_claimofficeroh" name='update_role_claim_officer' className="custom-control-input" checked={role_claim} onChange={(e)=>setClaim(e.target.checked)}  />
                            <label className="custom-control-label" htmlFor="update_role_claimofficeroh">{IMLocalized('role_claim_officer')}?</label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="update_role_useroh" name='update_role_user' className="custom-control-input" checked={true} disabled={true} />
                            <label className="custom-control-label" htmlFor="update_role_useroh">{IMLocalized('role_user')}?</label>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ?  <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                <button className="btn btn-primary btn-sm" onClick={updateRole}>{IMLocalized('update')}</button>
                <button className="btn btn-danger btn-sm" onClick={closeModal} >{IMLocalized('close')}</button>
                </>
                }
            </ModalFooter>
        </Modal>
    )
}
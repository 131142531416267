import * as latestemployee from '../../action/employee/get_latest_employee';

const initialState={
    data:null,
    isLoading:false,
    errors:null
}

const get_latest_employee = (state=initialState,action)=>{
    switch(action.type){
        case latestemployee.LATESTEMPLOYEE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case latestemployee.LATESTEMPLOYEE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
            }
        case latestemployee.LATESTEMPLOYEE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_latest_employee;
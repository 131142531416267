import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormFeedback, FormGroup, Input, Row, Table } from "reactstrap";
import { create_leave_scheme, reset_create_leave_scheme } from "../../action/create_leave_scheme";
import { get_leave_list } from "../../action/get_leave_list";
import { get_leave_scheme_list } from "../../action/get_leave_scheme_list";
import { NewMainContext } from "../../context/NewMainContext";
import { IMLocalized } from "../../language/IMLocalized";
import BreadCrumbList from "../breadcrumb/BreadCrumbList";


export default function LeaveCreateSchemeList({setStep}){

    const leavelist = useSelector(state=>state.get_leave_list.data);
    const success = useSelector(state=>state.create_leave_scheme.data);
    const errors = useSelector(state=>state.create_leave_scheme.errors);
    const dispatch = useDispatch();
    const { addItem } = useContext(NewMainContext);
    const [ setting, setSetting] = useState(false);
    const [ data, setData] = useState([]);
    const [ select_all, setSelectAll] = useState(true);
    const [ name , setName] = useState('');
    const [ err_name, setErrName] = useState('');
    const [ submit_arr ,setSubmitArr] = useState([]);

    const schemeRef = useRef([]);

    useEffect(()=>{
        if(success != null){
            dispatch(get_leave_list());
            dispatch(get_leave_scheme_list());
            dispatch(reset_create_leave_scheme());
            setName('');
        }
    },[success,dispatch])

    useEffect(()=>{
        function compareArray(submit){
            const arr1 = [...data];
            let first = false;
            for(let i=0; i<submit.length ;i++){
                const leave_id = submit[i].leave_setting;
                for(let x=0; x<arr1.length; x++){
                    const {leave_setting} = arr1[x];
                    if(leave_setting === leave_id){
                        arr1[x].err_amount = submit[i].err_amount;
                        arr1[x].err_age = submit[i].err_age;
                        if((submit[i].err_amount.length !== 0 || submit[i].err_age.length !== 0) && !first){
                            schemeRef.current[x].scrollIntoView({ block:'start', behavior:'smooth'});
                            first = true;
                        }
                    }
                }
            }
            setData(arr1);
        }

        if(errors != null){
            if(errors.hasOwnProperty('name')){
                const word = errors['name'].toString();
                setErrName(word);
            }
            
            if(errors.hasOwnProperty('entitlement')){
                const {entitlement} = errors;
                const arr = [];
                for(let i=0; i < entitlement.length ;i++ ){
                    if(Object.entries(entitlement[i].length !== 0)){
                        const age = entitlement[i].age_tier;
                        const amount = entitlement[i].amount_tier;
                        const {leave_setting} = submit_arr[i];
                        let err_age = '';
                        let err_amount = '';
                        if(age !== undefined){
                            err_age = age.toString();
                        
                        }
                        if(amount !== undefined){
                            err_amount = amount.toString();
                        }
                        arr.push({ leave_setting, err_age, err_amount });
                    }
                }
                compareArray(arr);
            }
            
            dispatch(reset_create_leave_scheme());
        }
    },[errors,dispatch,data,submit_arr])

    useEffect(()=>{
        if(leavelist.length !== 0){
            const sort_arr = leavelist
            .sort((a,b)=>a.id - b.id)
            .map((item)=>{
                const { id, name, leave_type, year_initialization } = item;
                const age_tier = [ 99, 0, 0, 0];
                const amount_tier = [ 0, 0, 0, 0];
                const max_encash_limit = 0;
                const year_carry_over_limit = 0;
                const max_carry_over_limit = 0;
                const err_age = '';
                const err_amount = '';
                const checked = true;
                return {year_initialization, leave_type,leave_setting:id,name,age_tier,amount_tier,max_carry_over_limit,year_carry_over_limit,max_encash_limit,err_age,err_amount,checked};
            })
            setSelectAll(true);
            setData(sort_arr);

        }
    },[leavelist])

    const handleCheckbox=(index)=>{
        const arr = [...data];
        arr[index].checked = !arr[index].checked;
        setData(arr);

        let checked = arr.every(element => element.checked === true);
        setSelectAll(checked);
    }

    const handleValue = (e,index) => {
        const {name,value} = e.target;
        const arr = [...data];
        arr[index][name] = value;
        setData(arr);
    }

    const handleEmpty = (e,index)=>{
        const {name, value} = e.target;
        const arr = [...data];
        if(value === ''){
            arr[index][name] = 0;
        }
        setData(arr);
    }

    const handleEmpty2 = (e,index,index2)=>{
        const {name, value} = e.target;
        const arr = [...data];
        if(value === ''){
            arr[index][name][index2] = 0;
        }
        setData(arr);
    }

    const addRow = (index)=>{
        const arr = [...data];
        arr[index].age_tier.push(0);
        arr[index].amount_tier.push(0);

        setData(arr);
    }

    const removeRow = (index)=>{
        const arr = [...data];
        if(arr[index].age_tier.length >1){
            arr[index].age_tier.pop();
            arr[index].amount_tier.pop();
    
            setData(arr);
        }
        
    }

    const handleInput = (e,index,index2)=>{
        const {value, name} = e.target;
        const arr = [...data];
        arr[index][name][index2] = value;
        setData(arr);
    }

    const handleAll = (e) =>{
        const { checked } = e.target;
        setSelectAll(checked);

        const arr = [...data];
        const new_arr = arr.map((item)=>{
            return {...item,checked:checked}
        })
        setData(new_arr);
    }

    const onSubmit=()=>{
        if(name !== ''){
            const arr = data.filter((item)=>item.checked === true);
            dispatch(create_leave_scheme(name,arr));
            setSubmitArr(arr);
            
        }
        else{
            setErrName(IMLocalized('name_is_required'));
        }
    }

    return(
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: false, title:IMLocalized('leave_scheme_list'), onClick:()=>setStep(0)},
                        { active: true, title:IMLocalized('create')}
                    ]}
                    />
                    <div className="ml-2">
                        <button onClick={()=>setStep(0)} className="btn btn-primary btn-sm"> <i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className="mt-2">
                <Col>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col className="d-flex align-items-center">
                                    <h4 className="mb-0">{IMLocalized('new_leave_scheme')}</h4>
                                </Col>
                                <Col className="col-auto">
                                    <button className="btn btn-primary btn-sm" onClick={onSubmit}>{IMLocalized('create')}</button>
                                    <Dropdown size="sm" direction="left" isOpen={setting} toggle={()=>setSetting(!setting)}>
                                        <DropdownToggle className="px-1 py-0 m-0" aria-expanded={setting}>
                                            <i className="ni ni-settings-gear-65" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-scroll p-2">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" name="select_all" id="leavecreateschemelistall" className="custom-control-input" checked={select_all} onChange={(e)=>handleAll(e)} />
                                                <label className="custom-control-label" htmlFor="leavecreateschemelistall">{IMLocalized('select_all')}</label>
                                            </div>
                                            <DropdownItem divider></DropdownItem>
                                            {data.map((item,index)=>{
                                                return(
                                                    <div className="custom-control custom-checkbox" key={item.leave_setting}>
                                                        <input type="checkbox" id={"leavecreateschemelist"+index} className="custom-control-input"  checked={item.checked} onChange={()=>handleCheckbox(index)} />
                                                        <label className="custom-control-label" htmlFor={"leavecreateschemelist"+index}>{item.name}</label>
                                                    </div>
                                                )
                                            })}
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="create_leave_scheme_name">{IMLocalized('name')}</label>
                                        <Input size="sm" type="text" id="create_leave_scheme_name" value={name} invalid={err_name.length >0}
                                        onChange={(e)=>{
                                            setName(e.target.value);
                                            setErrName('');
                                        }} 
                                        />
                                        <FormFeedback>{err_name}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    {data.length !== 0 &&
                    data.filter(item=>item.checked === true).map((item,index)=>{
                        const { leave_type, name, max_encash_limit, year_carry_over_limit, year_initialization, max_carry_over_limit, err_age, err_amount, age_tier, amount_tier } = item;
                        let is_readonly = year_initialization === 'BF' ? false : true;
                        
                        if(leave_type === 'G'){
                            return(
                                <div ref={el => (schemeRef.current[index] = el)} key={item.leave_setting}>
                                    <Card  >
                                        <CardBody>
                                            <h4 className="mb-0">{item.name}</h4>
                                        </CardBody>
                                    </Card>  
                                </div>
                                
                            )
                        }
                        else{
                            return(
                                <div ref={el => (schemeRef.current[index] = el)} key={item.leave_setting}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <h4 className="mb-0">{name}</h4>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label" htmlFor={'max_encash_limit'+index}>{IMLocalized('max_encash_limit')}</label>
                                                        <Input size="sm" type="number" name="max_encash_limit" id={'max_encash_limit'+index} min="0" value={max_encash_limit} onChange={(e)=>handleValue(e,index)} onBlur={(e)=>handleEmpty(e,index)} readOnly={is_readonly} />
                                                        <div>
                                                            <span className="text-red">*</span><small>{IMLocalized('max_encash_limit_tooltip1')}</small>
                                                        </div>
                                                        <div>
                                                            <span className="text-red">*</span><small>{IMLocalized('max_encash_limit_tooltip2')}</small>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label" htmlFor={'year_carry_over_limit'+index}>{IMLocalized('year_carry_over_limit')}</label>
                                                        <Input size="sm" id={'year_carry_over_limit'+index} type="number" min="0" name="year_carry_over_limit" value={year_carry_over_limit} onChange={(e)=>handleValue(e,index)} onBlur={(e)=>handleEmpty(e,index)} disabled={is_readonly} />
                                                        <div>
                                                            <span className="text-red">*</span><small>{IMLocalized('year_carry_over_limit_tooltip1')}</small>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label" htmlFor={'max_carry_over_limit'+index}>{IMLocalized('max_carry_over_limit')}</label>
                                                        <Input size="sm" type="number" min="0" id={'max_carry_over_limit'+index} name="max_carry_over_limit" value={max_carry_over_limit} onChange={(e)=>handleValue(e,index)} onBlur={(e)=>handleEmpty(e,index)} disabled={is_readonly} />
                                                        <div>
                                                            <span className="text-red">*</span><small>{IMLocalized('max_carry_over_limit_tooltip1')}</small>
                                                        </div>
                                                        <div>
                                                            <span className="text-red">*</span><small>{IMLocalized('max_carry_over_limit_tooltip2')}</small>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className="mb-2">
                                                <Col>
                                                    {err_age.length !== 0 &&
                                                    <div>
                                                        <small className="text-danger">{IMLocalized('leave_scheme_year')} : {err_age}</small>
                                                    </div>}
                                                    {err_amount.length !== 0 &&
                                                    <div>
                                                        <small className="text-danger">{IMLocalized('leave_scheme_amount')} : {err_amount}</small>
                                                    </div>}
                                                </Col>
                                                <Col className="col-auto">
                                                    <button onClick={()=>addRow(index)} style={{minWidth:'50px'}} className="btn btn-primary btn-sm">{IMLocalized('add')}</button>
                                                    <button onClick={()=>removeRow(index)} style={{minWidth:'50px'}} className="btn btn-danger btn-sm">{IMLocalized('delete')}</button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Table className="align-items-center table-bordered" responsive>
                                                        <thead className="thead-light text-center">
                                                            <tr>
                                                                <th>{IMLocalized('year_in_company')}</th>
                                                                <th>{IMLocalized('leave_entitlement_amount')}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-center">
                                                            {amount_tier.map((el,n)=>{
                                                                const age = age_tier[n];
                                                                return(
                                                                    <tr key={n}>
                                                                        <td>
                                                                            <div className="d-flex justify-content-center">
                                                                                <Input type="number" size="sm" min="0" style={{textAlign:'center',width:'100px'}} name="age_tier" value={age} onChange={(e)=>handleInput(e,index,n)} onBlur={(e)=>handleEmpty2(e,index,n)} />
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex justify-content-center">
                                                                                <Input type="number" size="sm" min='0' style={{textAlign:'center',width:'100px'}} name="amount_tier" value={el} onChange={(e)=>handleInput(e,index,n)} onBlur={(e)=>handleEmpty2(e,index,n)} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                            )
                        }
                    })}
                </Col>
            </Row>
        </Container>
        </>
    )
}
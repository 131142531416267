import myaxios from '../../axios';

export const EMPARRAYVALIDATE_REQUEST = 'EMPARRAYVALIDATE_REQUEST';
export const EMPARRAYVALIDATE_SUCCESS = 'EMPARRAYVALIDATE_SUCCESS';
export const EMPARRAYVALIDATE_FAILURE = 'EMPARRAYVALIDATE_FAILURE';

export const create_employee_array_validate = (data)=>{
    return dispatch=>{
        dispatch({
            type:'EMPARRAYVALIDATE_REQUEST'
        });

        myaxios.post('employee/employees/array_validate/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'EMPARRAYVALIDATE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'EMPARRAYVALIDATE_FAILURE',
                payload:error
            })
        })
    }
}
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {get_employee_payroll} from '../../../action/employee/get_employee_payroll';
import { NewMainContext } from '../../../context/NewMainContext';
import { IMLocalized } from '../../../language/IMLocalized';
import PayrollTable from './component/PayrollTable';

export default function EmployeePayroll({ id }){

    const dispatch = useDispatch();
    const {addItem, setPayrollID, setPayrollStep, setPayslipEmpID} = useContext(NewMainContext);
    const payroll_list = useSelector(state=>state.get_employee_payroll.data);
    const isLoading = useSelector(state=>state.get_employee_payroll.isLoading);
    
    useEffect(()=>{
        if(id !== '' && id != null){
            dispatch(get_employee_payroll(id));
        }
    },[id,dispatch])

    const toDetail=(inx)=>{
        addItem('15','payroll_list');
        setPayrollID(inx);
        setPayslipEmpID(id);
        setPayrollStep('4');
    }

    return(
        <>
            <div className="d-flex align-items-center mt-2 pl-2 emp-tabcontent-title" >
                <i className="far fa-money-bill-alt text-primary"></i>
                <h4 className="text-primary pl-2 m-0">{IMLocalized('employee_payroll')}</h4>
            </div>
            {isLoading && <div className="m-2"><h4>{IMLocalized('loading')}..</h4></div>}
            {!isLoading && payroll_list.length === 0 && <div className="m-2"><h4>{IMLocalized('no_data')}</h4></div>}
            {!isLoading && payroll_list.length !== 0 && <PayrollTable toDetail={toDetail} />}
        </>
    )
}

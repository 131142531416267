import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { add_claim_group_template, reset_add_claim_group_template } from "../../../action/claim/add_claim_group_template";
import { get_claim_group_template } from "../../../action/claim/get_claim_group_template";
import { IMLocalized } from "../../../language/IMLocalized";


export default function ModalSaveClaimApprovalTemplate({toggle, closeModal, claim_groups, state }){

    const dispatch = useDispatch();
    const isLoading = useSelector(state=>state.add_claim_group_template.isLoading);
    const success = useSelector(state=>state.add_claim_group_template.data);
    const errors = useSelector(state=>state.add_claim_group_template.errors);

    const [ name, setName] = useState('');
    const [ err_name, setErrName] = useState('');

    useEffect(()=>{
        if(toggle){
            setName('');
            setErrName('');
        }
    },[toggle])

    useEffect(()=>{
        if(success != null){
            dispatch(get_claim_group_template());
            dispatch(reset_add_claim_group_template());
            closeModal();
        }
    },[success, dispatch, closeModal])

    useEffect(()=>{
        if(errors != null){
            if(errors.hasOwnProperty('name')){
                const word = errors['name'].toString();
                setErrName(word);
            }
            dispatch(reset_add_claim_group_template());
        }
    },[errors,dispatch])

    const onSave = () =>{
        let group1 = claim_groups;
        if(name !== ''){
            let group2 = group1.filter(item=>item.group === 'group2');
            let group3 = group1.filter(item=>item.group === 'group3');
            let approvers = [];
            let exceptions = [];

            if(state.employee1 !== '' ){
                if(state.ordering1 !== ''){
                    approvers.push({employee: state.employee1,ordering: state.ordering1});
                }
            }

            if(state.employee2 !== ''){
                if(state.ordering2 !== ''){
                    approvers.push({employee: state.employee2,ordering: state.ordering2});
                }
            }

            if(state.employee3 !== ''){
                if(state.ordering3 !== ''){
                    approvers.push({employee: state.employee3,ordering: state.ordering3});
                }
            }

            if(group2.length !== 0 || group3.length !== 0 ){
                for(let i=0; i< 2 ; i++){
                    let exception = [];
                    let approver = [];
                    if( i === 0){
                        if(group2.length !== 0 ){
                            for(let j=0; j< group2.length; j++){
                                exception.push(group2[j].id);
                            }

                            if(state.employee4 !== '' || state.employee5 !== '' || state.employee6 !== ''){
                                if(state.employee4 !== ''){
                                    if(state.ordering4 !== ''){
                                        approver.push({employee: state.employee4, ordering: state.ordering4})
                                    }
                                }

                                if(state.employee5 !== ''){
                                    if(state.ordering5 !== ''){
                                        approver.push({employee: state.employee5, ordering: state.ordering5})
                                    }
                                }

                                if(state.employee6 !== ''){
                                    if(state.ordering6 !== ''){
                                        approver.push({employee: state.employee6, ordering: state.ordering6})
                                    }
                                }
                                exceptions.push({exceptions:exception,approvers:approver});
                            }
                        }
                    }
                    else if ( i === 0){
                        if(group3.length !== 0 ){
                            for(let j =0;j<group3.length;j++){
                                exception.push(group3[j].id);
                            }

                            if(state.employee7 !== '' || state.employee8 !== '' || state.employee9 !== ''){
                                if(state.employee7 !== ''){
                                    if(state.ordering7 !== ''){
                                        approver.push({employee: state.employee7, ordering: state.ordering7})
                                    }
                                }

                                if(state.employee8 !== ''){
                                    if(state.ordering8 !== ''){
                                        approver.push({ employee: state.employee8, ordering: state.ordering8})
                                    }
                                }

                                if(state.employee9 !== ''){
                                    if(state.ordering9 !== ''){
                                        approver.push({employee: state.employee9, ordering: state.ordering9})
                                    }
                                }
                                exceptions.push({exceptions:exception,approvers:approver});
                            }
                        }
                    }
                }
            }
            dispatch(add_claim_group_template(name, {approvers,exceptions}));
        }
        else{
            setErrName(IMLocalized('template_name_is_required'));
        }
    }


    return(
        <Modal className="modal-dialog-centered" size="md" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('save_as_template')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="save_claim_approval_name">{IMLocalized('template_name')}</label>
                            <Input size="sm" type="text" id="save_claim_approval_name" value={name} invalid={err_name.length >0} onChange={(e)=>{
                                const value = e.target.value;
                                setName(value);
                                setErrName('');
                            }} />
                            <FormFeedback>{err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                <button onClick={onSave} className="btn btn-primary btn-sm">{IMLocalized('create')}</button>
                <button onClick={closeModal} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Input, InputGroup, InputGroupAddon, InputGroupText, Tooltip } from 'reactstrap';
import Swal from 'sweetalert2';
import { popError } from '../../func/popError';
import { IMLocalized } from '../../language/IMLocalized';
import FileSaver from 'file-saver';
import ExcelJs from 'exceljs';
import { getColumnName } from '../../func/getColumnName';
import { getValue } from '../../func/getValue';
import {payroll_detail_update_payment_count} from '../../action/payroll/payroll_detail_update_payment_count';
import { usePrevious } from '../../hook/usePrevious';
// import { get_payroll_instance } from '../../action/payroll/get_payroll_instance';
import { get_payroll_history_list } from '../../action/history/get_payroll_history_list';
// import { payroll_detail_get_aggridheader } from '../../action/payroll/payroll_detail_get_aggridheader';
import {payroll_detail_update_payment_amount} from '../../action/payroll/payroll_detail_update_payment_amount';
import XLSX from 'xlsx';
import OverlayLoading from '../loading_component/overlay_loading';

const mapStateToProps=(state)=>({
    // agheader:state.payroll_detail_get_aggridheader.data,
    // list:state.get_payroll_instance.data,

    isLoading:state.payroll_detail_update_payment_count.isLoading,
    update_success:state.payroll_detail_update_payment_count.data,
    update_errors:state.payroll_detail_update_payment_count.errors,
    isLoading1:state.payroll_detail_update_payment_amount.isLoading,
    update_success1:state.payroll_detail_update_payment_amount.data,
    update_errors1:state.payroll_detail_update_payment_amount.errors,
})

const mapDispatchToProps=(dispatch)=>({
    payroll_detail_update_payment_count:(id,data)=>{
        dispatch(payroll_detail_update_payment_count(id,data))
    },
    // payroll_detail_get_aggridheader:(id)=>{
    //     dispatch(payroll_detail_get_aggridheader(id))
    // },
    // get_payroll_instance:(id)=>{
    //     dispatch(get_payroll_instance(id))
    // },
    get_payroll_history_list:()=>{
        dispatch(get_payroll_history_list())
    },
    payroll_detail_update_payment_amount:(id,data)=>{
        dispatch(payroll_detail_update_payment_amount(id,data))
    },
})

const gridOption={
    defaultColDef:{
        suppressMenu:true,
        resizable:true,
        filter:true,
        editable:true
    },
    sideBar:{
        toolPanels: [
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                toolPanelParams: {
                },
            },
        ],
        defaultToolPanel: '',
    },
    singleClickEdit:true,
    overlayLoadingTemplate:
    '<span class="ag-overlay-loading-center">Please wait while your data is loading</span>',
    overlayNoRowsTemplate:
    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">No Data</span>',
}


function PayrollDetailAggrid(props){
    // const {update_success,update_success1,get_payroll_instance,get_payroll_history_list,payroll_detail_get_aggridheader} = props;
    const {update_success,update_success1,get_payroll_instance,get_payroll_history_list} = props;
    const {setEdit,toPayrollPayslip,is_edit} = props;
    const [quickFilterText,setQuickFilterText] = useState('');
    const [columnDefs,setColumnDefs] = useState([]);
    const [rowData,setRowData] = useState([]);
    const [rowData2,setRowData2] = useState([]);
    const [tooltip_toggle,setTooltipToggle] = useState(false);

    const [countArr,setCountArr] = useState([]);
    const [amountArr,setAmountArr] = useState([]);

    const prevUpdate = usePrevious(update_success);
    const prevUpdate1 = usePrevious(update_success1);

    useEffect(()=>{
        if(prevUpdate !== undefined && prevUpdate !== update_success && update_success != null){
            get_payroll_instance(props.id);
            get_payroll_history_list();
            // payroll_detail_get_aggridheader(props.id);
        }
    // },[prevUpdate,update_success,props.id,get_payroll_instance,get_payroll_history_list,payroll_detail_get_aggridheader])
    },[prevUpdate,update_success,props.id,get_payroll_instance,get_payroll_history_list])

    useEffect(()=>{
        if(prevUpdate1 !== undefined && prevUpdate1 !== update_success1 && update_success1 != null){
            get_payroll_instance(props.id);
            get_payroll_history_list();
        }
    // },[prevUpdate1,update_success1,props.id,get_payroll_instance,get_payroll_history_list,payroll_detail_get_aggridheader])
    },[prevUpdate1,update_success1,props.id,get_payroll_instance,get_payroll_history_list])

    const toDetail=useCallback((id,emp)=>{
        if(is_edit === true){
            Swal.fire({
                title:IMLocalized('changes_may_not_be_saved'),
                type:'warning',
                showCancelButton:true,
                cancelButtonColor:'#d33',
                confirmButtonColor:'#3085d6',
                confirmButtonText:IMLocalized('yes!'),
                cancelButtonText:IMLocalized('no!'),
            })
            .then((result)=>{
                if('value' in result){
                    setEdit(false);
                    toPayrollPayslip({step:'4',id,emp});
                }
            })
        }
        else{
            toPayrollPayslip({step:'4',id,emp});
        }
    },[toPayrollPayslip,setEdit,is_edit])

    const myCellRenderer = useCallback((params)=>{
        
        return <a href="/#" onClick={()=>toDetail(props.id,params.data.employee.id)} >{params.value}</a>
    },[props.id,toDetail])

    useEffect(()=>{
        let arr =[];
        let arr2 =[];
        let arr3 =[];
        if(props.list.length !== 0){
            const data = props.list[0];
            const {payslips} = data;

            for(let i=0;i<payslips.length ;i++){
                const item = payslips[i];
                const pay = item.pay;
                let aggrid = {};
                let dd ={};
                let err={};
                let aggrid2 = {}; 
                let aggrid3 = {};
                const idd = item.id;
                for(let x=0; x<pay.length;x++){
                    const pay_type = pay[x].pay_type;
                    const name = pay[x].code;
                    const code = name.replace('.','-');
                    const count = pay[x].count;
                    const amount = pay[x].amount;
                    const id = pay[x].id;
                    if(pay_type === 'OT' || pay_type === 'FX' || pay_type === 'ALLOWANCE'){
                        aggrid[code] =  {count,id};
                        aggrid2[code] = {count};
                        aggrid3[code] = {count};
                        dd[code] = false;
                        err[code]= false;
                    }else{
                    // if(pay_type === 'BASIC' || pay_type === 'OTHERS' || pay_type === 'BONUS'){
                        aggrid[code] =  {amount,id};
                        aggrid2[code] = {amount};
                        aggrid3[code] = {amount};
                        dd[code] = false;
                        err[code]= false;
                    }     


                    // if(pay_type === 'OT' || pay_type === 'FX' || pay_type === 'ALLOWANCE'|| pay_type === 'BASIC'|| pay_type === 'OTHERS' || pay_type === 'DE_nocpf'){
                    //     aggrid[code] =  {count,id,amount};
                    //     aggrid2[code] = {count,amount};
                    //     aggrid3[code] = {amount};
                    //     dd[code] = false;
                    //     err[code]= false;
                    // }
                    // if(pay_type === 'BONUS'){
                    //     aggrid[code] =  {count,id,amount};
                    //     aggrid2[code] = {count,amount};
                    //     aggrid3[code] = {amount};
                    //     dd[code] = false;
                    //     err[code]= false;
                    // }
                }
                arr.push({...item,idd,aggrid,dd,err}); 
                arr2.push({...item,idd2:idd,aggrid:aggrid2});
                arr3.push({...item,idd3:idd,aggrid:aggrid3});
            }
            setRowData(prevState=>([...prevState,...arr]));
            setRowData2(arr2);
        }
        else{
            setRowData2([]);
            setRowData([]);
        }
       
    },[props.list])



    useEffect(()=>{
        let newlist = [
            {
                headerName:IMLocalized('agrid_emp_id'),
                field:'employee.employee_number',
                lockPosition:true,
                hide:false,
                pinned:'left',
                width:100,
                filter:false,
                editable:false
            },
            {
                headerName:IMLocalized('agrid_emp_name'),
                field:'employee.name',
                lockPosition:true,
                pinned:'left',
                width:200,
                hide:false,
                filter:false,
                editable:false,
                cellRendererFramework:myCellRenderer
            },
            {
                headerName:IMLocalized('agrid_salary'),
                field:'total_wage',
                lockPosition:true,
                pinned:'left',
                width:120,
                hide:false,
                filter:false,
                editable:false,
            },
            {
                headerName:IMLocalized('agrid_deduction'),
                field:'deductables',
                editable:false,
                lockPosition:true,
                pinned:'left',
                hide:false,
                filter:false,
                width:140,
            },
            {
                headerName:IMLocalized('agrid_payable'),
                field:'payable_wage',
                lockPosition:true,
                pinned:'left',
                hide:false,
                width:120,
                editable:false,
                filter:false,
            },
            {
                headerName:IMLocalized('agrid_company'),
                field:'employee.metadata.company',
                hide:true,
                editable:false
            },
            {
                headerName:IMLocalized('agrid_cost_center'),
                field:'employee.contract_cache.cost_center',
                hide:true,
                editable:false
            },
            {
                headerName:IMLocalized('agrid_department'),
                field:'employee.contract_cache.department',
                hide:true,
                editable:false
            },
            {
                headerName:IMLocalized('agrid_section'),
                field:'employee.contract_cache.section',
                hide:true,
                editable:false
            },
            {
                headerName:IMLocalized('agrid_job_title'),
                field:'employee.contract_cache.job_title',
                hide:true,
                editable:false
            },
            {
                headerName:IMLocalized('agrid_job_level'),
                field:'employee.contract_cache.job_level',
                hide:true,
                editable:false
            }
        ];

        // if(props.agheader){
        //     if(props.agheader[0]?.detail_page_fields === undefined)
        if(props.list){
            if(props.list[0]?.detail_page_fields === undefined)
            <>
            {console.log('Still loading...')}
            {/* <OverlayLoading /> */}
            <p>loading</p>
            </>
            else{
                {console.log('Finished loading.')}
                // setCountArr(props?.agheader[0]?.detail_page_fields.count);
                // setAmountArr(props?.agheader[0]?.detail_page_fields.amount);
                setCountArr(props?.list[0]?.detail_page_fields.count);
                setAmountArr(props?.list[0]?.detail_page_fields.amount);

                if((countArr != null || amountArr != null) && (countArr.length !== 0|| amountArr.length !== 0)){

                for(let i=0;i<countArr.length;i++){
                    const name = countArr[i];
                    const headerName = name.replace('-','.');
                    const field = `aggrid.${name.replace('.','-')}.count`;
                    const width = 100;
                    const hide =false;
                    const filter = false;
                    const cellClassRules={
                        'green-bg':(params)=>{return params.data.dd[name.replace('.','-')]},
                        'orange-bg':(params)=>{return params.data.err[name.replace('.','-')]}
                    }
                    newlist.push({headerName,filter,field,width,cellClassRules,hide});
                }
                for(let i=0;i<amountArr.length;i++){
                    const name = amountArr[i];
                    const headerName = name.replace('-','.');
                    const field = `aggrid.${name.replace('.','-')}.amount`;
                    const width = 100;
                    const hide =false;
                    const filter = false;
                    const cellClassRules={
                        'green-bg':(params)=>{return params.data.dd[name.replace('.','-')]},
                        'orange-bg':(params)=>{return params.data.err[name.replace('.','-')]}
                    }
                    newlist.push({headerName,filter,field,width,cellClassRules,hide});
                }
            }
            }
        }
        setColumnDefs(newlist);
    // },[props.agheader,myCellRenderer,countArr,amountArr])
    },[props.list,myCellRenderer,countArr,amountArr])

    const onCellValueChanged=(params)=>{
        if(params.oldValue !== params.newValue){
            const headerName = params.column.colDef.headerName;
            const name = headerName.replace('.','-');
            if(!isNaN(params.newValue)){
                params.data.dd[name] = true;
                params.data.err[name] = false;
                setEdit(true);
                setTooltipToggle(true);
            }
            else{
                params.data.err[name] = true;
                popError(IMLocalized('key_in_valid_value'));
            }
            
        }
        params.api.refreshCells();
    }

    const exportExcel=()=>{

        const monthNames = [IMLocalized("january"), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
        IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")];

        let payroll_date = '';
        if(props.list.length !== 0){
            const data = props.list[0];
            const date = data.payroll_date;
            const month1 = new Date(date).getMonth();
            const month = monthNames[month1];
            const year = new Date(date).getFullYear();
            payroll_date = month + '-' + year ;
        }

        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('Payroll');
        const ws2 = wb.addWorksheet('id');
        // const {count} = props.agheader[0].detail_page_fields;
        // const {amount} = props.agheader[0].detail_page_fields;
        const {count} = props.list[0].detail_page_fields;
        const {amount} = props.list[0].detail_page_fields;

        ws2.getCell('A1').value = props.id;
        let columns =[];
        if(columnDefs.length !== 0){
            for(let i =0;i<columnDefs.length;i++){
                columns.push({width:10})
                let headerName = columnDefs[i].headerName;

                if(count.includes(headerName)){
                    headerName = headerName +'(Count)'
                }else if(amount.includes(headerName)){
                    headerName = headerName +'(Amount)'
                }

                ws.getCell(getColumnName(i)+'1').value = headerName;
                
                const field = (columnDefs[i].field);
                for(let x=0;x<rowData2.length;x++){
                    const step = 2+x;
                    const item = getValue(rowData2[x],field);
                    ws.getCell(getColumnName(i)+step).value = item;
                }
            }
            ws.columns=columns;
            const file_name = `Payroll_${payroll_date}.xlsx`;
            wb.xlsx.writeBuffer().then((buf)=>{
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                FileSaver.saveAs(file, file_name)
            });
        }
    }
    const updatePayroll=()=>{
        let new_change1 = [];
        let new_change2 = [];
        if(rowData.length !== 0){
            for(let i=0;i<rowData.length;i++){
                const item = rowData[i];
                const {pay} = item;
                const {dd} = item;
                const {aggrid} = item;
                const number = Object.keys(aggrid).length;
                for(let x=0;x<number;x++)
                {
                    const pay_type = Object.values(pay)[x].pay_type;
                    const contentType = Object.values(pay)[x].content_type;
                    if(pay_type === "OT" || pay_type === "ALLOWANCE" || pay_type === "FX"){
                        const value = Object.values(aggrid)[x].count;
                        const bool = Object.values(dd)[x];
                        if(bool === true){
                            const paycomponent = Object.values(aggrid)[x].id;
                            new_change1.push({paycomponent,count:value})}
                    }
                    else{
                        const value = Object.values(aggrid)[x].amount;
                        const bool = Object.values(dd)[x];
                        if(bool === true){
                            const paycomponent = Object.values(aggrid)[x].id;
                            new_change2.push({paycomponent,amount:value})}
                    } 
                    // if(contentType === 245 || pay_type === "BONUS"){
                    //     const value = Object.values(aggrid)[x].amount;
                    //     const bool = Object.values(dd)[x];
                    //     if(bool === true){
                    //         const paycomponent = Object.values(aggrid)[x].id;
                    //         new_change2.push({paycomponent,amount:value})}
                    // } 
                    // if(contentType === null){
                    //     const value = Object.values(aggrid)[x].count;
                    //     const bool = Object.values(dd)[x];
                    //     if(bool === true){
                    //         const paycomponent = Object.values(aggrid)[x].id;
                    //         new_change1.push({paycomponent,count:value})}
                    // }
                }            
        }
            setEdit(false);
            if(new_change1.length !== 0 || new_change2.length !== 0)
            {if(new_change1.length !== 0){
                props.payroll_detail_update_payment_count(props.id,new_change1)
            }
            if(new_change2.length !== 0){
                props.payroll_detail_update_payment_amount(props.id,new_change2)
            }}
            else{
                popError(IMLocalized('no_data_updated'))
            }
        
    }}

    const uploadExcel=(e)=>{
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload=(e)=>{
            const bstr = e.target.result;
            const wb = XLSX.read(bstr,{type:'binary', cellDates: true });
            const wsname = wb.SheetNames[0];
            const ws =wb.Sheets[wsname];
            const wsname2 = wb.SheetNames[1];
            const ws2 = wb.Sheets[wsname2];
            let columns={
                'A':'employee_number',
                'B':'name',
                'C':'total_wage',
                'D':'deductable',
                'E':'payable_wage',
                'F':'company',
                'G':'cost_center',
                'H':'department',
                'I':'section',
                'J':'job_title',
                'K':'job_level',
            }

            const arrCustomHeader = Object.values(wb.Sheets.Payroll)
            // const {amount} = props.agheader[0].detail_page_fields;
            // const {count} = props.agheader[0].detail_page_fields;
            const {amount} = props.list[0].detail_page_fields;
            const {count} = props.list[0].detail_page_fields;
            const total = count.concat(amount)
            const customArray = []

            for(let i=0;i<total.length;i++){
                const name1 = total[i];
                const name = name1.replace('.','-');
                customArray.push(arrCustomHeader[12+i].w)

                const number = getColumnName(11+i);
                columns[number] = name;
            }

            let importData = [];
            let rowIndex = 2;
            let idd = '';
            if(ws2 !== undefined){
                if(ws['A1'] !== undefined){
                    idd = ws2['A1'].w;
                }
            }

            while(ws['A'+rowIndex]){
                let row = {};
                for(let i =0;i<Object.keys(columns).length;i++){
                    const column = Object.keys(columns)[i];
                    const index = column + rowIndex;
                    if(ws[index] !== undefined){
                        row[columns[column]] = ws[index].w;
                    }
                    else{
                        row[columns[column]] = null;
                    }
                }
                importData.push(row);
                rowIndex++;
            }

            let uploadData1 =[];
            let uploadData2 =[];
            if(idd === props.id){
                if(importData.length !== 0){
                    for(let i=0;i<importData.length;i++){
                        const employee_number = importData[i].employee_number;
                        const index = rowData2.findIndex(element => element.employee.employee_number === employee_number);
                        const item = rowData2[index];
                        const {aggrid} = item;
                        const aggrid2 = rowData[index].aggrid;
                        const length = Object.keys(aggrid).length
                        const aggridArr = []
                        aggridArr.push(aggrid)
                        const aggridHeader = Object.keys(aggridArr[0])

                        const countArray = customArray.filter((string)=>string.includes('(Count)'))
                        
                        const countArray1 = countArray.map((x)=>x.replace('(Count)','')).map((y)=>y.replace('.','-'))
                        const filtered = countArray1.map((str)=> aggridHeader.filter((str2)=>str2===str)).map(x=>x)
                        const countArray2 = filtered.filter(e => e.length).flat()

                        const amountArray = customArray.filter((string)=>string.includes('(Amount)'))
                        const amountArray1 = amountArray.map((x)=>x.replace('(Amount)','')).map((y)=>y.replace('.','-'))
                        const filteredAmount = amountArray1.map((str)=> aggridHeader.filter((str2)=>str2===str)).map(x=>x)
                        const amountArray2 = filteredAmount.filter(e => e.length).flat()  
                        let finalArr =[]
                        finalArr = [...countArray2,...amountArray2]
                        for(let l=0 ;l<countArray2.length; l++){
                            const name1 = finalArr[l];
                            const value1 = aggrid[name1].count;
                            const id = aggrid2[name1].id;
                            const value2 = importData[i][name1];
                            if(JSON.stringify(value1) !== value2){
                                if(value2 !== null){
                                    uploadData1.push({paycomponent:id,count:value2});
                                }
                            }

                        }
                        for(let m=countArray2.length; m<finalArr.length; m++){
                            const name1 =finalArr[m];
                            const value3 = aggrid[name1].amount;
                            const id = aggrid2[name1].id;
                            const value4 = importData[i][name1];
                            if(JSON.stringify(value3) !== value4){
                                if(value4 !== null){
                                    uploadData2.push({paycomponent:id,amount:value4});
                                }
                            }
                        }
                        

                    }
                    if(uploadData1.length !== 0 || uploadData2.length !== 0){
                    if(uploadData1.length !== 0){
                        props.payroll_detail_update_payment_count(props.id,uploadData1);
                    }
                    if(uploadData2.length !== 0){
                        props.payroll_detail_update_payment_amount(props.id,uploadData2);
                    }}
                    else{
                        popError(IMLocalized('no_data_updated'))
                    }
                }
                else{
                    popError(IMLocalized('no_data'));
                }
            }
            else{
                popError(IMLocalized('wrong_file_selected'));
            }
        }
        reader.readAsBinaryString(file)
    }


    return(
        <>
        <div className='mt-2 d-flex justify-content-between'>
            <div>
            <InputGroup size="sm" style={{width:'200px'}}>
                <Input type="text" value={quickFilterText} onChange={(e)=>{const value = e.target.value ; setQuickFilterText(value)}}/>
                    <InputGroupAddon addonType='append'>
                        <InputGroupText><i className='fas fa-search' /></InputGroupText>
                    </InputGroupAddon>
            </InputGroup>
            </div>
            <div className='d-flex'>
                <div>
                    <label className='btn btn-success btn-sm mb-0' htmlFor='ImportPayrollcustomFile'>{IMLocalized('import_from_file')}</label>
                    <input type="file" id="ImportPayrollcustomFile" className="custom-file-input form-control-sm d-none" accept=".xlsx"  onChange={uploadExcel} 
                    onClick={(event)=>{
                        const { target = {} } = event || {};
                        target.value = '';
                    }} 
                    />
                    <button className='btn btn-success btn-sm' onClick={exportExcel}>{IMLocalized('export_payroll_template')}</button>
                    {props.isLoading || props.isLoading1 ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button> :
                    <button id="payrollUpdateTooltip" className="btn btn-primary btn-sm" onClick={updatePayroll}>{IMLocalized('update_changes')}</button>}
                </div>
                {props.is_edit && <Tooltip placement="down" isOpen={tooltip_toggle} target="payrollUpdateTooltip" toggle={()=>setTooltipToggle(false)} >{IMLocalized('please_click_here_to_update_changes')}</Tooltip>}
            </div>
        </div>
        
        <div className='ag-theme-balham mt-2' style={{height:'calc(100vh - 350px)', width:'100%' }}>
            <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            // overlayNoRowsTemplate={IMLocalized('no_data')}
            // overlayNoRowsTemplate={true}
            enableSorting={true}
            // overlayLoadingTemplate={IMLocalized('loading')}
            // overlayLoadingTemplate={true}
            quickFilterText={quickFilterText}
            suppressDragLeaveHidesColumns={true}
            enableColResize={true}
            stopEditingWhenCellsLoseFocus={true}
            gridOptions={gridOption}
            onCellValueChanged={onCellValueChanged}
            />
        </div>
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(PayrollDetailAggrid);
import * as award_leave from '../../action/employee/employee_award_leave';

const initialState={
    errors:null,
    isLoading:false,
    data:null,
}

const employee_award_leave = (state=initialState,action)=>{
    switch(action.type){
        case award_leave.EMPAWARDLEAVE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null
            }
        case award_leave.EMPAWARDLEAVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case award_leave.EMPAWARDLEAVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case award_leave.EMPAWARDLEAVE_RESET:
            return{
                ...state,
                errors:null,
                data:null
            }
        default:
            return state;
    }
}
export default employee_award_leave;
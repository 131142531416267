import React from 'react';
import QRCode from 'react-qr-code';
import { Col, Modal, ModalBody, ModalHeader, Row,ModalFooter } from 'reactstrap';
import {IMLocalized} from '../../../../language/IMLocalized';


export default function ModalRoleQr({toggle, closeModal, code}){

    const title = process.env.REACT_APP_TITLE;
    const url = process.env.REACT_APP_API_URL;
    const key = 'aline_hr';
    const object = {title,url,key};

    return(
        <Modal isOpen={toggle} style={{maxWidth: '1600px', width: '90%'}}>
            <ModalHeader toggle={closeModal}>{IMLocalized('emp_qr_registration')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6" className="bor-right">
                        <div>
                            <div className="d-flex justify-content-center">
                                <h2>1. {IMLocalized('emp_qr_step1')}</h2>
                            </div>
                            <div className="d-flex justify-content-center pt-4">
                                <QRCode value={code}></QRCode>
                            </div>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div>
                            <div className="d-flex justify-content-center">
                                <h2>2. {IMLocalized('emp_qr_step2')}</h2>
                            </div>
                            <div className="d-flex justify-content-center pt-4">
                                <QRCode value={JSON.stringify(object)}></QRCode>
                            </div>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
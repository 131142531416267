import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { reset_update_employee_address, update_employee_address } from '../../../../action/employee/update_employee_address';
import { get_employee_address } from '../../../../action/employee/get_employee_address';
import { branch_get_sg_postal, reset_branch_get_sg_postal } from '../../../../action/company/branch_get_sg_postal';
import { popError } from '../../../../func/popError';


export default function ModalEditAddress({ toggle, closeModal, detail,   }){

    const nationalitylist = useSelector(state=>state.dropdown_list.countries);
    const isLoading = useSelector(state=>state.update_employee_address.isLoading);
    const success = useSelector(state=>state.update_employee_address.data);
    const sg_address = useSelector(state=>state.branch_get_sg_postal.address);
    const dispatch = useDispatch();
    const [country,setCountry] = useState('');
    const [err_country,setErrCountry] = useState('');
    const [sg_postal_code,setSgPostalCode] = useState(null);
    const [err_sg_postal_code,setErrSgPostalCode] = useState('');
    const [block_number,setBlockNumber] = useState(null);
    const [err_block_number,setErrBlockNumber] = useState('');
    const [street_name,setStreetName] = useState(null);
    const [err_street_name,setErrStreetName] = useState('');
    const [level_number,setLevelNumber] = useState(null);
    const [err_level_number,setErrLevelNumber] = useState('');
    const [unit_number,setUnitNumber] = useState(null);
    const [err_unit_number,setErrUnitNumber] = useState('');
    const [postal_code,setPostal] = useState(null);
    const [err_postal_code,setErrPostal] = useState('');
    const [address,setAddress] = useState(null);
    const [err_address,setErrAddress] = useState('');

    useEffect(()=>{
        if( toggle && detail !== null){
            setCountry(detail.country_of_residence);
            setErrCountry('');
            setSgPostalCode(detail.sg_postal_code);
            setErrSgPostalCode('');
            setBlockNumber(detail.block_number);
            setErrBlockNumber('');
            setStreetName(detail.street_name);
            setErrStreetName('');
            setLevelNumber(detail.level_number);
            setErrLevelNumber('');
            setUnitNumber(detail.unit_number);
            setErrUnitNumber('');
            setPostal(detail.postal_code);
            setErrPostal('');
            setAddress(detail.address);
            setErrAddress('');
        }
    },[detail,toggle])

    useEffect(()=>{
        if(toggle && success !== null){
            closeModal();
            dispatch(get_employee_address());
            dispatch(reset_update_employee_address());
        }
    },[toggle,success,closeModal,dispatch])

    useEffect(()=>{
        if(sg_address != null && toggle){
            if(sg_address.length !== 0){
                setBlockNumber(sg_address[0].BLK_NO);
                setStreetName(sg_address[0].ROAD_NAME);
                setErrBlockNumber('');
                setErrStreetName('');
            }
            else{
                popError(IMLocalized('address_not_found'));
            }
            dispatch(reset_branch_get_sg_postal());
        }
    },[sg_address,toggle,dispatch])

    const updateAddress=()=>{
        if(country !== ''){
            if(country === 'SG'){
                const regex = /^[0-9]{6}$/;
                if(regex.test(sg_postal_code) && sg_postal_code !=='' && sg_postal_code !== null && block_number !== null && block_number !== '' &&  street_name !== '' && street_name !== null && level_number !== null && level_number !== '' && unit_number !=='' && unit_number !== null){
                    dispatch(update_employee_address([{id:detail.id,postal_code:null,address:null,country_of_residence:country,sg_postal_code,level_number,unit_number,street_name,block_number}]));
                }
                else{
                    if(sg_postal_code ==='' || sg_postal_code === null){
                        setErrSgPostalCode(IMLocalized('sg_postal_code_is_required'));
                    }
                    if(!regex.test(sg_postal_code)){
                        setErrSgPostalCode(IMLocalized('invalid_sg_postal_code'));
                    }
                    if(block_number ==='' || block_number === null){
                        setErrBlockNumber(IMLocalized('block_number_is_required'));
                    }
                    if(street_name ==='' || street_name === null){
                        setErrStreetName(IMLocalized('street_name_is_required'));

                    }
                    if(level_number ==='' || level_number === null){
                        setErrLevelNumber(IMLocalized('level_number_is_required'));
                    }
                    if(unit_number ==='' || unit_number === null){
                        setErrUnitNumber(IMLocalized('unit_number_is_required'));
                    }
                }
            }
            else{
                if(address !== '' && address !== null && postal_code !== '' && postal_code !== null){
                    dispatch(update_employee_address([{id:detail.id,country_of_residence:country,postal_code,address,sg_postal_code:null,level_number:null,unit_number:null,street_name:null,block_number:null}]));
                }
                else{
                    if(address !== '' || address !== null){
                        setErrAddress(IMLocalized('address_is_required'));
                    }
                    if(postal_code !== '' || postal_code !== null){
                        setErrPostal(IMLocalized('postal_code_is_required'));
                    }
                }
            }
        }
        else{
            setErrCountry(IMLocalized('country_of_residence_is_required'))
        }
    }

    const searchPostal=()=>{
        const regex = /^[0-9]{6}$/;
        if(!regex.test(sg_postal_code)){
            setErrSgPostalCode(IMLocalized('invalid_sg_postal_code'));
        }
        else{
            dispatch(branch_get_sg_postal(sg_postal_code));
        }
    }

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('update_address')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='contry'>{IMLocalized('country_of_residence')}</label>
                            <Input size="sm" name="country" type="select" id="country" value={country} onChange={(e)=>{setCountry(e.target.value); setErrCountry('')}} invalid={err_country.length >0}>
                                <option></option>
                                {nationalitylist.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display_name}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_country}</FormFeedback>
                        </FormGroup>
                    </Col>
                    {country !== '' && country ==='SG' &&
                    <>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor="sg_postal_code">{IMLocalized('sg_postal_code')}</label>
                            <div className='input-group input-group-sm'>
                                <Input size="sm" id="sg_postal_code" type="text" value={sg_postal_code} onChange={(e)=>{setSgPostalCode(e.target.value); setErrSgPostalCode('')}} invalid={err_sg_postal_code.length !== 0} />
                                <div className='input-group-append'>
                                    <button className='btn btn-primary' onClick={()=>searchPostal()}>{IMLocalized('autofill')}</button>
                                </div>
                                <FormFeedback>{err_sg_postal_code}</FormFeedback>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='block_number'>{IMLocalized('block_number')}</label>
                            <Input size="sm" id="block_number" type="text" value={block_number} onChange={(e)=>{setBlockNumber(e.target.value); setErrBlockNumber('')}} invalid={err_block_number.length >0} />
                            <FormFeedback>{err_block_number}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='street_name'>{IMLocalized('street_name')}</label>
                            <Input size="sm" id="street_name" type="text" value={street_name} onChange={(e)=>{setStreetName(e.target.value); setErrStreetName('')}} invalid={err_street_name.length >0} />
                            <FormFeedback>{err_street_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='level_number'>{IMLocalized('level_number')}</label>
                            <Input size="sm" id="level_number" type="number" value={level_number} onChange={(e)=>{setLevelNumber(parseInt(e.target.value)); setErrLevelNumber('')}} invalid={err_level_number.length >0 }  />
                            <FormFeedback>{err_level_number}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='unit_number'>{IMLocalized('unit_number')}</label>
                            <Input size="sm" id="unit_number" type="unit_number" value={unit_number} onChange={(e)=>{setUnitNumber(e.target.value); setErrUnitNumber('')}} invalid={err_unit_number.length >0} />
                            <FormFeedback>{err_unit_number}</FormFeedback>
                        </FormGroup>
                    </Col>
                    </>
                    }
                    {country !== '' && country !== 'SG' &&
                    <>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='postal_code'>{IMLocalized('postal_code')}</label>
                            <Input size="sm" type="text" id="postal" value={postal_code} onChange={(e)=>{setPostal(e.target.value); setErrPostal('')}} invalid={err_postal_code.length >0} />
                            <FormFeedback>{err_postal_code}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='address'>{IMLocalized('address')}</label>
                            <Input size="sm" type="textarea" id="address" value={address} onChange={(e)=>{setAddress(e.target.value); setErrAddress('')}} invalid={err_address.length >0} />
                            <FormFeedback>{err_address}</FormFeedback>
                        </FormGroup>
                    </Col>
                    </>
                    }
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button onClick={()=>updateAddress()} className="btn btn-primary btn-sm">{IMLocalized('update')}</button>
                    <button onClick={()=>closeModal()} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
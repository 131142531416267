import myaxios from '../axios';

export const DROPDOWNLIST_REQUEST = 'DROPDOWNLIST_REQUEST';
export const DROPDOWNLIST_SUCCESS = 'DROPDOWNLIST_SUCCESS';
export const DROPDOWNLIST_FAILURE = 'DROPDOWNLIST_FAILURE';

export const dropdown_list = ()=>{
    return dispatch=>{
        dispatch({
            type:'DROPDOWNLIST_REQUEST'
        });
        myaxios.get('dropdown2/')
        .then((response)=>{
            dispatch({
                type:'DROPDOWNLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'DROPDOWNLIST_FAILURE',
                payload:error
            })
        })
    }
}
import * as deleteemppermit from '../../action/employee/delete_employee_workpermit';

const initialState={
    data:null,
    isLoading:false,
    errors:null
}


const delete_employee_workpermit = (state=initialState,action)=>{
    switch(action.type){
        case deleteemppermit.NEW_DELEMPPERMIT_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case deleteemppermit.NEW_DELEMPPERMIT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case deleteemppermit.NEW_DELEMPPERMIT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case deleteemppermit.NEW_DELEMPPERMIT_RESET:
            return{
                ...state,
                errors:null,
                data:null
            }
        default:
            return state;
    }
}
export default delete_employee_workpermit;
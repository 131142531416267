import React from "react";
import { Table } from "reactstrap";
import { getDateFormat } from "../../../../func/getDateFormat";
import { IMLocalized } from "../../../../language/IMLocalized";



export default function ChildTable({ list, deleteChild, setViewChildren }){

    return(
        <Table size="sm" className="mt-2 align-items-center table-bordered"  responsive>
            <thead className="thead-light text-center">
                <tr>
                    <th className="w-50">{IMLocalized('child_name')}</th>
                    <th className="w-25">{IMLocalized('date_of_birth')}</th>
                    <th>{IMLocalized('view')} / {IMLocalized('delete')}</th>
                </tr>
            </thead>
            <tbody className="text-center">
                {list.map((item,index)=>{
                    return(
                        <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{getDateFormat(item.date_of_birth)}</td>
                            <td align="center" valign="center">
                                <div className='d-flex justify-content-center'>
                                    <div className="primary_btn d-flex align-items-center justify-content-center">
                                        <i className="far fa-eye text-primary cursor-pointer" onClick={()=>setViewChildren(index)}  />
                                    </div>
                                    <div className="delete_btn d-flex align-items-center justify-content-center">
                                        <i className="fas fa-trash text-red cursor-pointer" onClick={()=>deleteChild(item.id)} />
                                    </div> 
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}
import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardHeader, Table,CardBody } from 'reactstrap';
import Swal from 'sweetalert2';
import { IMLocalized } from '../../../language/IMLocalized';
import { delete_flat_allowance, reset_delete_flat_allowance } from '../../../action/delete_flat_allowance';
import { get_additional_setting } from '../../../action/additional_setting';
import ModalCreateFixed from '../modal/ModalCreateFixed';

function FixedContent(){
    const [create_toggle,setCreateToggle] = useState(false);
    const dispatch = useDispatch();
    const flatlist = useSelector(state=>state.add.flatallowance);
    const isLoading = useSelector(state=>state.add.isLoading);
    const success = useSelector(state=>state.delete_flat_allowance.data);

    useEffect(()=>{
        if(success !== null){
            dispatch(get_additional_setting());
            dispatch(reset_delete_flat_allowance());
        }
    },[success,dispatch])

    const removeFlat=(name)=>{
        Swal.fire({
            type:'warning',
            title:`${IMLocalized('do_you_really_want_to_delete')} \n ${IMLocalized('fixed_rate')} (`+ name + `)`,
            showCancelButton:true,
            confirmButtonColor:'red'
        })
        .then((result)=>{
            if(result.value){
                dispatch(delete_flat_allowance(name));
            }
        })
    }

    return(
        <>
            <Card>
                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <h4 className="mb-0">{IMLocalized('fixed_rate')}</h4>
                    <button className="btn btn-primary btn-sm" onClick={()=>setCreateToggle(true)}>{IMLocalized('add')}</button>
                </CardHeader>
                {isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>}
                {!isLoading && flatlist.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                {!isLoading && flatlist.length !== 0 &&
                <Table size="sm" className='align-items-center' responsive>
                    <thead className="text-center">
                        <tr>
                            <th>{IMLocalized('code')}</th>
                            <th>{IMLocalized('name')}</th>
                            <th>{IMLocalized('amount')}</th>
                            <th>{IMLocalized('delete')}</th>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {flatlist.map((item,index)=>{
                            return(
                                <tr key={index}>
                                    <td>{item.code}</td>
                                    <td>{item.name}</td>
                                    <td>$ {item.amount}</td>
                                    <td align="center" valign="center">
                                        <div className="delete_btn d-flex align-items-center justify-content-center">
                                            <i onClick={()=>removeFlat(item.name)} className="fas fa-trash text-red cursor-pointer"></i>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>}
            </Card>
            <ModalCreateFixed toggle={create_toggle} closeModal={()=>setCreateToggle(false)} />
        </>
    )
}
export default FixedContent;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { get_claim_approval } from "../../../action/claim/get_claim_approval";
import { reset_update_claim_approval, update_claim_approval } from "../../../action/claim/update_claim_approval";
import { get_employee_list } from "../../../action/employee/get_employee_list";
import { popError } from "../../../func/popError";
import { IMLocalized } from "../../../language/IMLocalized";



export default function ModalUpdateClaimApprovalName({ toggle, closeModal, name, master, approvers, exceptions, onSuccess  }){

    const dispatch = useDispatch();
    const isLoading = useSelector(state=>state.update_claim_approval.isLoading);
    const success = useSelector(state=>state.update_claim_approval.data);
    const errors = useSelector(state=>state.update_claim_approval.errors);

    const [ date, setDate] = useState('');
    const [ err_date, setErrDate] = useState('');

    useEffect(()=>{
        if(success != null){
            dispatch(get_claim_approval());
            dispatch(get_employee_list());
            dispatch(reset_update_claim_approval());
            onSuccess();
        }
    },[success, dispatch, onSuccess])

    useEffect(()=>{
        if(errors != null){
            if(errors.hasOwnProperty('non_field_errors')){
                const word = errors['non_field_errors'].toString();
                popError(word);
            }
            dispatch(reset_update_claim_approval());
        }
    },[dispatch,errors])

    useEffect(()=>{
        if(toggle){
            setDate('');
            setErrDate('');
        }
    },[toggle])

    const onUpdate = () =>{
        if(date !== ''){
            dispatch(update_claim_approval(name, date, master, approvers, exceptions));
        }
        else{
            setErrDate(IMLocalized('effective_date_is_required'));
        }
    }

    return(
        <Modal className="modal-dialog-centered" size="md" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('edit')} - {name} </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="update_claim_approval_date"></label>
                            <Input size="sm" type="date" value={date} id="update_claim_approval_date" invalid={err_date.length >0} onChange={(e)=>{
                                const value = e.target.value;
                                setDate(value);
                                setErrDate('');
                            }} />
                            <FormFeedback>{err_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                <button className="btn btn-primary btn-sm" onClick={onUpdate}>{IMLocalized('update')}</button>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const DELETEHOLIDAYLIST_REQUEST = 'DELETEHOLIDAYLIST_REQUEST';
export const DELETEHOLIDAYLIST_SUCCESS = 'DELETEHOLIDAYLIST_SUCCESS';
export const DELETEHOLIDAYLIST_FAILURE = 'DELETEHOLIDAYLIST_FAILURE';
export const DELETEHOLIDAYLIST_RESET = 'DELETEHOLIDAYLIST_RESET';


export const delete_holiday_list = (id)=>{
    return dispatch=>{
        dispatch({
            type:'DELETEHOLIDAYLIST_REQUEST'
        })

        myaxios.delete(`contract/holidaylist/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELETEHOLIDAYLIST_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELETEHOLIDAYLIST_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_holiday_list = () => dispatch =>{
    dispatch({
        type:'DELETEHOLIDAYLIST_RESET'
    });
}
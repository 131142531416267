import React, { useContext } from 'react';
import classnames from 'classnames';
import { Container, Row, Col, Nav, NavItem , NavLink, TabContent, TabPane} from 'reactstrap';
import { IMLocalized } from '../../language/IMLocalized';
import OvertimeContent from './content/OvertimeContent';
import AllowanceContent from './content/AllowanceContent';
import RecurringContent from './content/RecurringContent';
import FixedContent from './content/FixedContent';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';

function OvertimeAllowance(){


    const {overtime_activeTab, addItem, setOvertimeActiveTab} = useContext(NewMainContext);

    const setActiveTab=(index)=>{
        setOvertimeActiveTab(index);
        localStorage.setItem('otal',index);
    }

    return(
        <>
            <Container fluid>
                <Row className="border-bottom bg-white">
                    <Col className="p-2">
                        <BreadCrumbList list={[
                            {active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                            {active: false, title:IMLocalized('company'), onClick:()=>addItem('2','company_list')},
                            {active: true, title:IMLocalized('overtime_allowance')}
                        ]} />
                    </Col>
                </Row>
            </Container>
            <Container className='mt-2'>
                <div className='d-flex justify-content-between'>
                    <h3 className='mb-0'>{IMLocalized('company_overtime_allowance')}</h3>
                    <button className='btn btn-info btn-sm' onClick={()=>addItem('4','additional_setting')}>{IMLocalized('additional_setting')}</button>
                </div>
                <Nav tabs>
                    <NavItem className="cursor-pointer">
                        <NavLink className={classnames({active:overtime_activeTab === '1'})}
                        onClick={()=>setActiveTab('1')}>
                            {IMLocalized('overtime')}
                        </NavLink>
                    </NavItem>
                    <NavItem className="cursor-pointer">
                        <NavLink  className={classnames({active:overtime_activeTab === '2'})}
                        onClick={()=>setActiveTab('2')}>
                            {IMLocalized('allowance')}
                        </NavLink>
                    </NavItem>
                    <NavItem className="cursor-pointer">
                        <NavLink  className={classnames({active:overtime_activeTab === '3'})}
                        onClick={()=>setActiveTab('3')}>
                            {IMLocalized('recurring_allowance')}
                        </NavLink>
                    </NavItem>
                    <NavItem className="cursor-pointer">
                        <NavLink  className={classnames({active:overtime_activeTab === '4'})}
                        onClick={()=>setActiveTab('4')}>
                            {IMLocalized('fixed_rate')}
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={overtime_activeTab}>
                    <TabPane tabId="1">
                        <OvertimeContent/>
                    </TabPane>
                    <TabPane tabId="2">
                        <AllowanceContent/>
                    </TabPane>
                    <TabPane tabId="3">
                        <RecurringContent/>
                    </TabPane>
                    <TabPane tabId="4">
                        <FixedContent/>
                    </TabPane>
                </TabContent>
            </Container>
        </>
    )
}
export default OvertimeAllowance;
import * as update_employeerelationship from '../../action/employee/update_employee_relationship';


const initialState ={
    isLoading:false,
    errors:null,
    data:null
}


const update_employee_relationship = (state=initialState,action) =>{

    switch(action.type) {
        case update_employeerelationship.UPDATEEMPLOYEERELATION_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            };
        case update_employeerelationship.UPDATEEMPLOYEERELATION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case update_employeerelationship.UPDATEEMPLOYEERELATION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case update_employeerelationship.UPDATEEMPLOYEERELATION_RESET:
            return{
                ...state,
                data:null,
                errors:null,
            }
        default:
            return state;
    }
}
export default update_employee_relationship;

import axios from 'axios';


export const BRANCHSGADDDRESS_REQUEST = 'BRANCHSGADDDRESS_REQUEST';
export const BRANCHSGADDDRESS_SUCCESS = 'BRANCHSGADDDRESS_SUCCESS';
export const BRANCHSGADDDRESS_FAILURE = 'BRANCHSGADDDRESS_FAILURE';
export const BRANCHSGADDDRESS_RESET = 'BRANCHSGADDDRESS_RESET'

export const branch_get_sg_postal = (pos) =>{
    return dispatch=>{
        dispatch({
            type : 'BRANCHSGADDDRESS_REQUEST'
        });
        axios.get(`https://developers.onemap.sg/commonapi/search?searchVal=${pos}&returnGeom=Y&getAddrDetails=Y&pageNum=1/`)
        .then((response)=>{
            dispatch({
                type:'BRANCHSGADDDRESS_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'BRANCHSGADDDRESS_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_branch_get_sg_postal = () => dispatch=>{
    dispatch({
        type:'BRANCHSGADDDRESS_RESET'
    });
}
import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const DELETE_PAYROLL_ADDITIONAL_SETTING_REQUEST = 'DELETE_PAYROLL_ADDITIONAL_SETTING_REQUEST';
export const DELETE_PAYROLL_ADDITIONAL_SETTING_FAILURE = 'DELETE_PAYROLL_ADDITIONAL_SETTING_FAILURE';
export const DELETE_PAYROLL_ADDITIONAL_SETTING_SUCCESS = 'DELETE_PAYROLL_ADDITIONAL_SETTING_SUCCESS';
export const DELETE_PAYROLL_ADDITIONAL_SETTING_RESET = 'DELETE_PAYROLL_ADDITIONAL_SETTING_RESET';

export const delete_payroll_additional_setting = (id)=>{
    return dispatch =>{
        dispatch({
            type: 'DELETE_PAYROLL_ADDITIONAL_SETTING_REQUEST'
        });
        
        myaxios.delete(`payroll/field/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELETE_PAYROLL_ADDITIONAL_SETTING_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error) =>{
            dispatch({
                type: 'DELETE_PAYROLL_ADDITIONAL_SETTING_FAILURE',
                payload: error,
                error:true
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            })
        })
    }
}

export const reset_delete_pas = () => dispatch =>{
    dispatch({
        type:'DELETE_PAYROLL_ADDITIONAL_SETTING_RESET'
    });
}




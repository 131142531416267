import myaxios from '../../axios';
import FormData from 'form-data';
import { IMLocalized } from '../../language/IMLocalized';
import { Toast } from '../../swal/SwalToast';

export const CLAIMDETAILUSERAPPLYCLAIM_REQUEST = 'CLAIMDETAILUSERAPPLYCLAIM_REQUEST';
export const CLAIMDETAILUSERAPPLYCLAIM_SUCCESS = 'CLAIMDETAILUSERAPPLYCLAIM_SUCCESS';
export const CLAIMDETAILUSERAPPLYCLAIM_FAILURE = 'CLAIMDETAILUSERAPPLYCLAIM_FAILURE';


export const claim_detail_user_apply_claim = (claim_type,original_amount,claim_date,dependant,set_pending,notes,file,doctor,diagnosis,location)=>{
    return dispatch=>{
        dispatch({
            type:'CLAIMDETAILUSERAPPLYCLAIM_REQUEST'
        });
        let formData = new FormData();
        if(file.length !==0){
            for(let i=0;i<file.length;i++){
                formData.append('upload_images',file[i],file[i].name);
            }
        }
        formData.append('claim_type',claim_type);
        formData.append('original_amount',original_amount);
        formData.append('dependant',dependant);
        formData.append('claim_date',claim_date);
        formData.append('set_pending',set_pending);
        formData.append('comment',notes);
        formData.append('medicalclaim.doctor',doctor);
        formData.append('medicalclaim.diagnosis',diagnosis);
        formData.append('medicalclaim.location',location)

        myaxios.post('claim/self/apply/',formData,{headers:{'Content-Type':'multipart/form-data'}})
        .then((response)=>{
            dispatch({
                type:'CLAIMDETAILUSERAPPLYCLAIM_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('apply_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CLAIMDETAILUSERAPPLYCLAIM_FAILURE',
                payload:error
            })
        })
    }
}
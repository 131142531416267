import * as validateuserapplyclaim from '../../action/user/validate_user_apply_claim';

const initialState={
    data:null,
    errors:null,
    isLoading:false
}

const validate_user_apply_claim = (state=initialState,action)=>{
    switch(action.type){
        case validateuserapplyclaim.VALIDATEUSERAPPLYCLAIM_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case validateuserapplyclaim.VALIDATEUSERAPPLYCLAIM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case validateuserapplyclaim.VALIDATEUSERAPPLYCLAIM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case validateuserapplyclaim.VALIDATEUSERAPPLYCLAIM_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default validate_user_apply_claim;
import myaxios from '../../axios';

export const USERAVAILABLECLAIM_REQUEST = 'USERAVAILABLECLAIM_REQUEST';
export const USERAVAILABLECLAIM_SUCCESS = 'USERAVAILABLECLAIM_SUCCESS';
export const USERAVAILABLECLAIM_FAILURE = 'USERAVAILABLECLAIM_FAILURE';

export const user_get_available_claim = ()=>{
    return dispatch=>{
        dispatch({
            type:'USERAVAILABLECLAIM_REQUEST'
        });
        
        
        myaxios.get('claim/self/available/')
        .then((response)=>{
            dispatch({
                type:'USERAVAILABLECLAIM_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'USERAVAILABLECLAIM_FAILURE',
                payload:error
            })
        })
    }
}
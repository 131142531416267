import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const DELBRANCH_REQUEST = 'DELBRANCH_REQUEST';
export const DELBRANCH_FAILURE = 'DELBRANCH_FAILURE';
export const DELBRANCH_SUCCESS = 'DELBRANCH_SUCCESS';
export const DELBRANCH_RESET = 'DELBRANCH_RESET';

export const delete_branch = (id)=>{
    return dispatch =>{
        dispatch({
            type: 'DELBRANCH_REQUEST'
        });
        
        myaxios.delete(`company/branch/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELBRANCH_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error) =>{
            dispatch({
                type: 'DELBRANCH_FAILURE',
                payload: error,
                error:true
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            });
        })
    }
}


export const reset_delete_branch = () => dispatch =>{
    dispatch({
        type:'DELBRANCH_RESET'
    });
}




import { useEffect, useState } from "react";
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { IMLocalized } from "../../../language/IMLocalized";



export default function ModalAddAdditional({type,list,toggle,closeModal,addList}){

    const [ name, setName] = useState('');
    const [ err_name, setErrName] = useState('');

    useEffect(()=>{
        if(toggle){
            setName('');
            setErrName('');
        }
    },[toggle])

    const addItem=()=>{
        if(name !== ''){
            let count = 0;
            for(const item of list){
                if(item.name === name){
                    count++;
                }
            }

            if(count !== 0){
                setErrName(IMLocalized('duplicate_name'));
            }
            else{
                addList({name});
                setName('');
            }
        }   
        else{
            setErrName(IMLocalized('name_is_required'))
        }
    }

    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>
                {type === 'department' && IMLocalized('add_department')}
                {type === 'section' && IMLocalized('add_section')}
                {type === 'jobtitle' && IMLocalized('add_jobtitle')}
                {type === 'joblevel' && IMLocalized('add_joblevel')}
                {type === 'costcenter' && IMLocalized('add_costcenter')}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="name">{IMLocalized('name')}</label>
                            <Input size="sm" type="text" id="name" value={name} invalid={err_name.length >0} onChange={(e)=>{setName(e.target.value); setErrName('')}} />
                            <FormFeedback>{err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-primary btn-sm" onClick={addItem}>{IMLocalized('add')}</button>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
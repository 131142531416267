import myaxios from '../../axios';

export const GETALLEMPLEAVE_REQUEST = 'GETALLEMPLEAVE_REQUEST';
export const GETALLEMPLEAVE_SUCCESS = 'GETALLEMPLEAVE_SUCCESS';
export const GETALLEMPLEAVE_FAILURE = 'GETALLEMPLEAVE_FAILURE';

export  const get_all_employee_available_leave =()=>{
    return dispatch=>{
        dispatch({
            type:'GETALLEMPLEAVE_REQUEST'
        });

        myaxios.get(`leave/available/`)
        .then((response)=>{
            dispatch({
                type:'GETALLEMPLEAVE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETALLEMPLEAVE_FAILURE',
                payload:error
            })
        })
    }
}
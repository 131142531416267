import * as leave_resetlist from '../../action/leave/leave_reset_list';

const initialState={
    data:[],
    isLoading:false,
    errors:[]
}

const leave_reset_list = (state=initialState,action)=>{
    switch(action.type){
        case leave_resetlist.LEAVERESETLISTHR_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[]
            }
        case leave_resetlist.LEAVERESETLISTHR_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
                errors:[]
            }
        case leave_resetlist.LEAVERESETLISTHR_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default leave_reset_list;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import Swal from "sweetalert2";
import { reset_claim_detail_get_claim_detail } from "../../../../action/user/claim_detail_get_claim_detail";
import { user_apply_claim_list } from "../../../../action/user/user_apply_claim_list";
import { reset_user_claim_detail_get_claim_picture, user_claim_detail_get_claim_picture } from "../../../../action/user/user_claim_detail_get_claim_picture";
import { user_get_available_claim } from "../../../../action/user/user_get_available_claim";
import { getDateFormat } from "../../../../func/getDateFormat";
import { IMLocalized } from "../../../../language/IMLocalized";
import { Buffer } from "buffer";
import Viewer from "react-viewer";
import '../../../../css/image.css';
import { reset_user_modal_cancel_claim, user_modal_cancel_claim } from "../../../../action/user/user_modal_cancel_claim";


export default function ModalViewClaimDetail({ toggle, closeModal}){

    const detail = useSelector(state=>state.claim_detail_get_claim_detail.data);
    const isLoading = useSelector(state=>state.claim_detail_get_claim_detail.isLoading);
    const success = useSelector(state=>state.user_modal_cancel_claim.data);
    const picture = useSelector(state=>state.user_claim_detail_get_claim_picture.data);
    const dispatch = useDispatch();
    
    const [ imglist, setImgList] = useState([]);
    const [ activeImg, setActiveImg] = useState(0);
    const [ img_toggle, setImgToggle] = useState(false);

    useEffect(()=>{
        if( toggle && detail != null ){
            const {images} = detail;
            for(const image of images){
                dispatch(user_claim_detail_get_claim_picture(image));
            }
        }
    },[detail,toggle,dispatch])

    useEffect(()=>{
        if(toggle && picture != null){
            const img = new Buffer.from(picture,'binary').toString('base64');
            const src = `data:image/png;base64,${img}`;
            setImgList(prevState=>([...prevState,{src}]));
        }
    },[picture,toggle])


    useEffect(()=>{
        if(!toggle){
            setImgList([]);
            dispatch(reset_user_claim_detail_get_claim_picture());
            dispatch(reset_claim_detail_get_claim_detail());
        }
    },[toggle,dispatch])

    useEffect(()=>{
        if(toggle && success != null){
            dispatch(user_apply_claim_list());
            dispatch(user_get_available_claim());
            dispatch(reset_user_modal_cancel_claim());
            closeModal();
        }
    },[success, toggle, dispatch, closeModal])

    const detailCancel=()=>{
        Swal.fire({
            title:IMLocalized('cancelling_claim'),
            input:'textarea',
            text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!')
        })
        .then((result)=>{
            if(result.value !== undefined){
                dispatch(user_modal_cancel_claim([detail?.id],result.value ));
            }
        })
    }


    const approvers = detail?.approvers ? detail.approvers.filter(item=>item.is_active === true) : [];

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('claim_detail')}</ModalHeader>
            <ModalBody>
                {isLoading && <span>{IMLocalized('loading')}..</span>}
                {!isLoading && detail &&
                <>
                <Row>
                    <Col>
                        <Table className='table-bordered' size="sm">
                            <tbody>
                                <tr>
                                    <td className="td-w-30">{IMLocalized('employee_number')}</td>
                                    <td>{detail.employee.employee_number}</td>
                                </tr>
                                <tr>
                                    <td >{IMLocalized('employee_name')}</td>
                                    <td>{detail.employee.name}</td>
                                </tr>
                                <tr>
                                    <td >{IMLocalized('claim_date')}</td>
                                    <td>{getDateFormat(detail.claim_date)}</td>
                                </tr>
                                <tr>
                                    <td >{IMLocalized('claim_type')}</td>
                                    <td>
                                        {detail.claim_type.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td >{IMLocalized('claim_amount')}</td>
                                    <td>
                                        $ {detail.claim_amount}
                                    </td>
                                </tr>
                                <tr>
                                    <td >{IMLocalized('status')}</td>
                                    <td>{IMLocalized(detail.status)}</td>
                                </tr>
                                {detail.claim_type.medical && 
                                <>
                                <tr >
                                    <td>{IMLocalized('doctor')}</td>
                                    <td>{detail.medicalclaim.doctor}</td>
                                </tr>
                                <tr >
                                    <td>{IMLocalized('diagnosis')}</td>
                                    <td>{detail.medicalclaim.diagnosis}</td>
                                </tr>
                                <tr>
                                    <td>{IMLocalized('location')}</td>
                                    <td>{detail.medicalclaim.location}</td>
                                </tr>
                                </>}
                                <tr>
                                    <td >{IMLocalized('dependant')}</td>
                                    <td>
                                        <div className='custom-control custom-checkbox'>
                                            <input type="checkbox" id={'empallclaimdependant'} name="dependant" className="custom-control-input" checked={detail.dependant} readOnly={true} />
                                            <label className="custom-control-label" htmlFor={'empallclaimdependant'}></label>
                                        </div>
                                    </td>
                                </tr>
                                {detail.comments.length !== 0 &&
                                <tr>
                                    <td>{IMLocalized('comment')}</td>
                                    <td className='comment-td'>
                                        {detail.comments.map((item,index)=>{
                                        const word = item.comment;
                                        const employee_name = item.employee_name;
                                        return <div className='comment-td-detail' key={index}><span className='font-weight-bold'>{employee_name}</span><span>: {word}</span></div>
                                        })}
                                    </td>
                                </tr>  
                                }
                                {imglist.length !== 0 &&
                                <tr>
                                    <td>{IMLocalized('image')}</td>
                                    <td>
                                        <Row >
                                            {imglist.map((item,index)=>{
                                                return(
                                                    <Col lg="4" key={index} className="mt-1">
                                                        <div className='image-item mr-2' onClick={()=>{ setImgToggle(true); setActiveImg(index)} } >
                                                            <div className='image-inner'>
                                                                <img src={item.src} className="img-thumbnail" alt={"This is Claim " +index} />
                                                            </div>
                                                        </div>
                                                    </Col> 
                                                )
                                            })}
                                        </Row>
                                    </td>
                                </tr>
                                }
                                {approvers.length !== 0 && 
                                <tr>
                                    <td>{IMLocalized('approval_pending')}</td>
                                    <td>
                                        {approvers.map((item,index)=>{
                                            if(item.delegation_application_approval === true){
                                                return <div key={index}><span>{item.employee.name} ({IMLocalized('delegation')}) </span></div>
                                            }
                                            else{
                                                return <div key={index}><span>{item.employee.name}</span></div>
                                            }
                                        })}
                                    </td>
                                </tr>}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                {imglist.length !== 0 && <Viewer activeIndex={activeImg} zIndex={1060} visible={img_toggle} onClose={()=>setImgToggle(false)} images={imglist} />}
                </>}
            </ModalBody>
            <ModalFooter>
                {detail?.status === 'PENDING' && <button className="btn btn-danger btn-sm" onClick={detailCancel} >{IMLocalized('cancel')}</button>}
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
import React, { useContext, useEffect, useMemo, useState } from "react";
import { DateRangePicker } from "react-dates";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Input, Row } from "reactstrap";
import { NewMainContext } from "../../../context/NewMainContext";
import { IMLocalized } from "../../../language/IMLocalized";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import { isFirstColumn } from "../../ag-grid_component/isFirstColumn";
import { date2Renderer } from "../../ag-grid_component/date2Renderer";
import { amountRenderer } from "../../ag-grid_component/amountRenderer";
import { dateRenderer } from "../../ag-grid_component/dateRenderer";
import { popError } from "../../../func/popError";
import Swal from "sweetalert2";
import { array_reject_apply_claim, reset_array_reject_apply_claim } from "../../../action/claim/array_reject_apply_claim";
import { array_approve_apply_claim, reset_array_approve_apply_claim } from "../../../action/claim/array_approve_apply_claim";
import { get_apply_claim_list } from "../../../action/claim/get_apply_claim_list";
import { claim_all_get_claim_detail } from "../../../action/claim/claim_all_get_claim_detail";
import ModalClaimDetail from "../../claim/modal/ModalClaimDetail";
import OverlayLoading from '../../loading_component/OverlayLoading';
import { ClaimStatusRenderer } from "../../../func/ClaimStatusRenderer";
import BreadCrumbList from "../../breadcrumb/BreadCrumbList";

const gridOptions={
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
    suppressRowClickSelection:true,
    defaultColDef:{
        resizable:true,
        sortable:true,
        filter:true,
        enableValue:true,
        enableRowGroup:true,
    },
    sideBar:{
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                toolPanelParams: {
                },
            },
        ],
        defaultToolPanel: '',
    }
}

export default function ClaimAllList(){

    const { addItem, action_toggle, claim_all_list_reset, setClaimAllListReset } = useContext(NewMainContext);
    const applylist = useSelector(state=>state.get_apply_claim_list.data);
    const isLoading = useSelector(state=>state.get_apply_claim_list.isLoading);
    const isLoading1 = useSelector(state=>state.get_general_color.isLoading);
    const array_reject_success = useSelector(state=>state.array_reject_apply_claim.data);
    const array_approve_success = useSelector(state=>state.array_approve_apply_claim.data);
    const dispatch = useDispatch();
    const [ selected, setSelected] = useState('4');
    const [ startDate, setStartDate] = useState(null);
    const [ endDate, setEndDate] = useState(null);
    const [ start_date, setStart_Date] = useState(null);
    const [ end_date, setEnd_Date] = useState(null);
    const [ focusedInput, setFocusedInput] = useState(null);
    const [ detail_toggle,setDetaiToggle] = useState(false);

    useEffect(()=>{
        if(claim_all_list_reset === true){
            setClaimAllListReset(false);
            const date = moment().subtract(3, 'month');
            const date1 = moment();
            setStartDate(date);
            setEndDate(date1);
            const start_date = moment().subtract(3, 'month').format('YYYY-MM-DD');
            const end_date = moment().format('YYYY-MM-DD');
            setStart_Date(start_date);
            setEnd_Date(end_date);
            setSelected('4');
        }
    },[claim_all_list_reset, setClaimAllListReset])
    
    useEffect(()=>{
        const date = moment().subtract(3, 'month');
        const date1 = moment();
        setStartDate(date);
        setEndDate(date1);
        const start_date = moment().subtract(3, 'month').format('YYYY-MM-DD');
        const end_date = moment().format('YYYY-MM-DD');
        setStart_Date(start_date);
        setEnd_Date(end_date);
    },[])

    useEffect(()=>{
        if(array_approve_success != null){
            dispatch(get_apply_claim_list());
            dispatch(reset_array_approve_apply_claim());
        }

        if(array_reject_success != null){
            dispatch(get_apply_claim_list());
            dispatch(reset_array_reject_apply_claim());
        }
    },[array_approve_success, array_reject_success, dispatch])

    const onDatesChange=({ startDate:startDate1, endDate:endDate1 })=>{
        setStartDate(startDate1);
        setEndDate(endDate1);
        const start_date = startDate1 != null ? moment(startDate1._d).format('YYYY-MM-DD') : null;
        const end_date = endDate1 != null ? moment(endDate1._d).format('YYYY-MM-DD') : null;
        setStart_Date(start_date);
        setEnd_Date(end_date);
    }

    const arrayApprove=(bool)=>{
        const data = gridOptions.api.getSelectedRows();
        if(data.length !== 0){
            Swal.fire({
                title: bool ? IMLocalized('approving_claim') : IMLocalized('rejecting_claim'),
                input:'textarea',
                text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
                showCancelButton:true,
                cancelButtonText:IMLocalized('no!'),
                confirmButtonText:IMLocalized('yes!')
            })
            .then((result)=>{
                if(result.hasOwnProperty('value')){
                    const comment = result.value;
                    const arr = data.map(item=>{
                        return { claim:item.id, comment}
                    });

                    if(!bool){
                        dispatch(array_reject_apply_claim(arr));
                    }
                    else{
                        dispatch(array_approve_apply_claim(arr));
                    }
                }
            })
        }
        else{
            popError(bool ? IMLocalized('select_claim_to_approve') : IMLocalized('select_claim_to_reject'))
        }
    }

    const onCellFocused = (params) => {
        if(params?.column?.colDef != null && params.column.colDef.field !== 'select'){
            const focusedCell = gridOptions.api.getFocusedCell();
            const cellValue = gridOptions.api.getDisplayedRowAtIndex(focusedCell.rowIndex);
            const { id } = cellValue.data;
            dispatch(claim_all_get_claim_detail(id));
            setDetaiToggle(true);
        }
    }

    const datalist = useMemo(()=>{
        let arr = [...applylist];
        if(start_date !== null){
            arr = arr.filter(item=> new Date(item.claim_date) >= new Date(start_date));
        }

        if(end_date !== null){
            arr = arr.filter(item=> new Date(item.claim_date) <= new Date(end_date))
        }

        if(selected === '0'){
            arr = arr.filter(item=>item.status === 'PENDING');
        }
        else if ( selected === '1'){
            arr = arr.filter(item=>item.status === 'APPROVED');
        }
        else if (selected === '2'){
            arr = arr.filter(item=>item.status === 'REJECTED');
        }
        else if ( selected === '3'){
            arr = arr.filter(item=>item.status === 'CANCELLED');
        }
        else if ( selected === '5'){
            arr = arr.filter(item=>item.status === 'PAID');
        }
        else if ( selected === '6'){
            arr = arr.filter(item=>item.status === 'PAYROLL');
        }

        return arr.sort((a,b)=> new Date(b.created_date) - new Date(a.created_date));
    },[applylist, start_date, end_date, selected])

    const option1 = [
        { value : '4', display: IMLocalized('ALL') },
        { value : '0', display: IMLocalized('PENDING')},
        { value : '1', display: IMLocalized('APPROVED')},
        { value : '2', display: IMLocalized('REJECTED')},
        { value : '3', display: IMLocalized('CANCELLED')},
        { value : '5', display: IMLocalized('PAID')},
        { value : '6', display: IMLocalized('PAYROLL')}
    ];

    const amount = datalist.reduce((total,item)=> total + item.claim_amount, 0);

    const pinnedBottomRowData = datalist.length !== 0 && [
        { claim_date: 'Total', claim_amount: amount, created_date: null}
    ]
 
    return(
        <>
        {isLoading || isLoading1 ? <OverlayLoading/> :
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title:IMLocalized('claim_table')}
                    ]} />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col className="d-flex align-items-center justify-content-end">
                    <Input className="mr-2 width-150px" type="select" size="sm" value={selected}
                    onChange={(e)=>{
                        const value = e.target.value;
                        setSelected(value);
                    }}>
                        {option1.map((item)=>{
                            return(
                                <option value={item.value} key={item.value}>{item.display}</option>
                            )
                        })}
                    </Input>
                    <DateRangePicker
                        isOutsideRange={()=>false}
                        small={true}
                        startDateId="unique_start_date_id"
                        endDateId="unique_end_date_id"
                        focusedInput={focusedInput}
                        displayFormat='DD/MM/YYYY'
                        onFocusChange={(e)=>setFocusedInput(e)}
                        minimumNights={0}
                        startDate={startDate}
                        endDate={endDate}
                        onDatesChange={onDatesChange}
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <h3 className="mb-0">
                        {selected === '4' && IMLocalized('all_claim_list')}
                        {selected === '0' && IMLocalized('pending_claim_list')}
                        {selected === '1' && IMLocalized('approved_claim_list')}
                        {selected === '2' && IMLocalized('rejected_claim_list')}
                        {selected === '3' && IMLocalized('cancelled_claim_list')}
                        {selected === '5' && IMLocalized('paid_claim_list')}
                        {selected === '6' && IMLocalized('payroll_paid_claim_list')}
                    </h3>
                </Col>
                {(selected === '0' || selected === '1') && 
                <Col className="col-auto">
                    {selected === '0' && 
                    <>
                    <button className="btn btn-primary btn-sm" onClick={()=>arrayApprove(true)}>{IMLocalized('approve')}</button>
                    <button className="btn btn-danger btn-sm" onClick={()=>arrayApprove(false)}>{IMLocalized('reject')}</button>
                    </>}
                    {selected === '1' && 
                    <>
                    <button className="btn btn-danger btn-sm" onClick={()=>arrayApprove(false)}>{IMLocalized('reject')}</button>
                    </>}
                </Col>}
            </Row>
            <Row className="mt-2">
                <Col>
                    <div className="ag-theme-balham" style={{ height: action_toggle ? 'calc(100vh - 300px)' : 'calc(100vh - 264px)', width:'100%' }}>
                        <AgGridReact
                        enableRangeSelection={true}
                        rowData={datalist}
                        columnDefs={ selected=== '0' || selected === '1' ? 
                        [
                            {
                                headerName:IMLocalized('select'),
                                pinned:'left',
                                field:'select',
                                width:150,
                                headerCheckboxSelection: isFirstColumn,
                                checkboxSelection: isFirstColumn,
                                headerCheckboxSelectionFilteredOnly:true,
                                filter:false,
                                lockPosition: true
                            },
                            {
                                headerName:IMLocalized('claim_date'),
                                field:'claim_date',
                                cellRendererFramework: date2Renderer,
                                filter:false,
                            },
                            {
                                headerName:IMLocalized('employee_number'),
                                field:'employee_detail.employee_number',
                            },
                            {
                                headerName:IMLocalized('employee_number'),
                                field:'employee_detail.name',
                            },
                            {
                                headerName:IMLocalized('claim_type'),
                                field:'claim_type_detail.name',
                            },
                            {
                                headerName:IMLocalized('claim_amount'),
                                field:'claim_amount',
                                cellRendererFramework: amountRenderer
                            },
                            {
                                headerName:IMLocalized('status'),
                                field:'status',
                                cellRendererFramework: ClaimStatusRenderer
                            },
                            {
                                headerName:IMLocalized('created_date'),
                                field:'created_date',
                                cellRendererFramework:dateRenderer,
                                filter:false,
                            }
                        ]:
                        [
                            {
                                headerName:IMLocalized('claim_date'),
                                field:'claim_date',
                                cellRendererFramework:date2Renderer,
                                filter:false,
                            },
                            {
                                headerName:IMLocalized('employee_number'),
                                field:'employee_detail.employee_number',
                            },
                            {
                                headerName:IMLocalized('employee_number'),
                                field:'employee_detail.name',
                            },
                            {
                                headerName:IMLocalized('claim_type'),
                                field:'claim_type_detail.name',
                            },
                            {
                                headerName:IMLocalized('claim_amount'),
                                field:'claim_amount',
                                cellRendererFramework:amountRenderer
                            },
                            {
                                headerName:IMLocalized('status'),
                                field:'status',
                                cellRendererFramework:ClaimStatusRenderer
                            },
                            {
                                headerName:IMLocalized('created_date'),
                                field:'created_date',
                                cellRendererFramework:dateRenderer,
                                filter:false,
                            }
                        ]}
                        gridOptions={gridOptions}
                        suppressDragLeaveHidesColumns={true}
                        suppressSizeToFit={true}
                        suppressColumnMoveAnimation={false}
                        overlayNoRowsTemplate = {IMLocalized('no_data')}
                        pinnedBottomRowData={pinnedBottomRowData}
                        onCellFocused={onCellFocused}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
        <ModalClaimDetail toggle={detail_toggle} closeModal={()=>{setDetaiToggle(false); gridOptions.api.clearFocusedCell() }} />
        </> }
        </>
    )
}
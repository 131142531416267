import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const DELETELEAVEGROUPTEM_REQUEST = 'DELETELEAVEGROUPTEM_REQUEST';
export const DELETELEAVEGROUPTEM_SUCCESS = 'DELETELEAVEGROUPTEM_SUCCESS';
export const DELETELEAVEGROUPTEM_FAILURE = 'DELETELEAVEGROUPTEM_FAILURE';
export const DELETELEAVEGROUPTEM_RESET = 'DELETELEAVEGROUPTEM_RESET';

export const delete_leave_mass_group_template = (id)=>{
    return dispatch=>{
        dispatch({
            type:'DELETELEAVEGROUPTEM_REQUEST'
        });

        myaxios.delete(`leave/mass/template/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELETELEAVEGROUPTEM_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELETELEAVEGROUPTEM_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_leave_mass_group_template = () => dispatch =>{
    dispatch({
        type:'DELETELEAVEGROUPTEM_RESET'
    });
}
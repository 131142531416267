import { AgGridReact } from "ag-grid-react";
import React, { useMemo, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { NewMainContext } from "../../../context/NewMainContext";
import { IMLocalized } from "../../../language/IMLocalized";
import { checkboxCellRenderer } from '../../../func/checkboxCellRenderer'
import OverlayLoading from "../../loading_component/OverlayLoading";
import 'ag-grid-community/dist/styles/ag-grid.css';
import '../../../css/aggrid.css';
import { reset_update_employee_self_help, update_employee_self_help } from "../../../action/employee/update_employee_self_help";
import { get_employee_self_help } from "../../../action/employee/get_employee_self_help";
import BreadCrumbList from "../../breadcrumb/BreadCrumbList";

export default function EmployeeMassSelfHelp(){

    const { addItem, action_toggle } = useContext(NewMainContext);
    const list = useSelector(state=>state.get_employee_self_help.data);
    const isLoading = useSelector(state=>state.get_employee_self_help.isLoading);
    const dispatch = useDispatch();
    const success = useSelector(state=>state.update_employee_self_help.data);


    const [ validateList, setValidateList] = useState([]);
    const [ rowData, setRowData] = useState([]);
    const [ step, setStep] = useState(0);

    useEffect(()=>{
        if(success != null){
            dispatch(reset_update_employee_self_help());
            dispatch(get_employee_self_help());
        }
    },[success,dispatch])

    useEffect(()=>{
        const arr = list.map((item)=>{
            const { shg } = item;
            const cdac = shg.cdac === true ? 'true' : 'false';
            const ecf = shg.ecf === true ? 'true' : 'false';
            const mbmf = shg.mbmf === true ? 'true' : 'false';
            const sinda = shg.sinda === true ? 'true' : 'false' ; 
            return {...item,edit_cdac: false, edit_ecf: false, edit_mbmf: false, edit_sinda: false , shg:{ cdac, mbmf, ecf, sinda, id :shg.id }}
        });

        setRowData(arr);
        setValidateList([]);

    },[list, step])

    const onCellValueChanged = (params) =>{
        if(params.oldValue !== params.newValue){
            const {id} = params.data;
            const { field } = params.colDef;
            if(field === 'shg.ecf') params.data.edit_ecf = true;
            else if(field === 'shg.sinda') params.data.edit_sinda = true;
            else if(field === 'shg.cdac') params.data.edit_cdac = true;
            else if(field === 'shg.mbmf') params.data.edit_mbmf = true;

            const detail = validateList.findIndex(item=>item.id === id);
            if(detail === -1){
                setValidateList(prevState=>[...prevState,params.data]);
            }
            else{
                setValidateList(prevState=>{
                    const arr = [...prevState];
                    arr[detail] = params.data;
                    return arr;
                })
            }
            params.api.refreshCells();
        }
    }

    const updateSelf = ()=>{
        const data = validateList.map(item=>item.shg)
        dispatch(update_employee_self_help(data));
    }


    // Filter Column //
            // enableValue:false,
            // enableRowGroup:false,
            // enablePivot:false,

    const gridOptions={
        defaultColDef:{
            flex:1,
            minWidth:100,
            sortable:true,
            filter:true,
            resizable:true,
        },
        // sideBar:true,
    }

    const sideBar = useMemo(() => {
        return {
          toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                // suppressColumnFilter: true,
                // suppressColumnSelectAll: true,
                // suppressColumnExpandAll: true,
              },
            },
          ],
          defaultToolPanel: 'columns',
        };
      }, []);


    return(
        <>
        {isLoading ? <OverlayLoading/> :
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title:IMLocalized('employee_mass_self_help_group')}
                    ]}/>
                </Col>
            </Row>
            <Row className="mt-2 justify-content-center">
                <Col>
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-primary btn-sm" onClick={()=>setStep(step+1)}>{IMLocalized('clear')}</button>
                        <button className="btn btn-primary btn-sm" onClick={updateSelf}>{IMLocalized('update')}</button>
                    </div>
                    <div className="ag-theme-balham mt-2" style={{height:action_toggle ? 'calc(100vh - 250px)' :'calc(100vh - 214px)' , width:'100%' }}>
                        <AgGridReact
                            enableRangeSelection={true}
                            sideBar={sideBar}
                            gridOptions={gridOptions}
                            rowData={rowData}
                            onCellValueChanged={onCellValueChanged}
                            columnDefs={[
                                { 
                                    headerName:IMLocalized('employee_number'),
                                    field:'employee_number',
                                    sortable:true,
                                },
                                { 
                                    headerName:IMLocalized('name'),
                                    field:'name',
                                    sortable:true,
                                },
                                { 
                                    headerName:IMLocalized('nationality'),
                                    field:'nationality',
                                    sortable:true,
                                },
                                { 
                                    headerName:IMLocalized('race'),
                                    field:'race',
                                    sortable:true,
                                },
                                { 
                                    headerName:IMLocalized('religion'),
                                    field:'religion',
                                    sortable:true,
                                },
                                { 
                                    headerName:IMLocalized('department'),
                                    field:'contract_cache.department',
                                    sortable:true,
                                    width:150
                                },
                                { 
                                    headerName:IMLocalized('section'),
                                    field:'contract_cache.section',
                                    sortable:true,
                                    width:150
                                },
                                { 
                                    headerName:IMLocalized('job_title'),
                                    field:'contract_cache.job_title',
                                    sortable:true,
                                    width:150
                                },
                                { 
                                    headerName:IMLocalized('job_level'),
                                    field:'contract_cache.job_level',
                                    sortable:true,
                                    width:150
                                },
                                { 
                                    headerName:IMLocalized('cost_center'),
                                    field:'contract_cache.cost_center',
                                    sortable:true,
                                    width:150
                                },
                                { 
                                    headerName:'CDAC',
                                    field:'shg.cdac',
                                    editable:false,
                                    cellRenderer:checkboxCellRenderer,
                                    width:150,
                                    cellClassRules:{
                                        'green-bg':(params)=>{return params.data.edit_cdac},
                                    },
                                    lockVisible: true,
                                },
                                { 
                                    headerName:'ECF',
                                    field:'shg.ecf',
                                    editable:false,
                                    cellRenderer:checkboxCellRenderer,
                                    width:150,
                                    cellClassRules:{
                                        'green-bg':(params)=>{return params.data.edit_ecf},
                                    },
                                    lockVisible: true,
                                },
                                { 
                                    headerName:'MBMF',
                                    field:'shg.mbmf',
                                    editable:false,
                                    cellRenderer:checkboxCellRenderer,
                                    width:150,
                                    cellClassRules:{
                                        'green-bg':(params)=>{return params.data.edit_mbmf}
                                    },
                                    lockVisible: true,
                                },
                                { 
                                    headerName:'SINDA',
                                    field:'shg.sinda',
                                    editable:false,
                                    cellRenderer:checkboxCellRenderer,
                                    width:150,
                                    cellClassRules:{
                                        'green-bg':(params)=>{return params.data.edit_sinda}
                                    },
                                    lockVisible: true,
                                }
                            ]}
                        >
                        </AgGridReact>
                    </div>
                </Col>
            </Row>
        </Container>}
        </>
    )
}
import * as monthly_instance from '../../action/report/monthly_payroll_instance';

const initialState={
    data:[],
    errors:[],
    isLoading:false,
    start_date:'',
    end_date:'',
    payslip:[],
    payslip2:[],
    total:'',
    status:'',
}

const monthly_payroll_instance = (state=initialState,action)=>{
    switch(action.type){
        case monthly_instance.MONTHLYPAYROLLINSTANCE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[],
                data:[]
            }
        case monthly_instance.MONTHLYPAYROLLINSTANCE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
                payroll_date:action.payload.data.payroll_date,
                start_date:action.payload.data.start_date,
                end_date:action.payload.data.end_date,
                payslip:action.payload.data.payslips,
                payslip2:action.payload.data.payslips,
                total:action.payload.data.total_payable,
                status:action.payload.data.status,
                errors:[]
            }
        case monthly_instance.MONTHLYPAYROLLINSTANCE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default monthly_payroll_instance;

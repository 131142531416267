import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CREATESGCHILD_REQUEST = 'CREATESGCHILD_REQUEST';
export const CREATESGCHILD_SUCCESS = 'CREATESGCHILD_SUCCESS';
export const CREATESGCHILD_FAILURE = 'CREATESGCHILD_FAILURE';
export const CREATESGCHILD_RESET = 'CREATESGCHILD_RESET';


export const create_sg_child = (employee,name,date_of_birth,leave_start_next_year,is_sg,sg_maternity,child_is_adopted,paternity)=>{
    return dispatch=>{
        dispatch({
            type:'CREATESGCHILD_REQUEST'
        });

        myaxios.post('leave/sg/child/',{
            employee:employee,
            name:name,
            date_of_birth:date_of_birth,
            leave_start_next_year:leave_start_next_year,
            is_sg:is_sg,
            sg_maternity:sg_maternity,
            is_adopted:child_is_adopted,
            paternity:paternity
        })
        .then((response)=>{
            dispatch({
                type:'CREATESGCHILD_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CREATESGCHILD_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}

export const reset_create_sg_child = () => dispatch =>{
    dispatch({
        type:'CREATESGCHILD_RESET'
    });
}
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import {payroll_update_rates} from '../../../action/payroll/payroll_update_rates';
import { usePrevious } from '../../../hook/usePrevious';
import { get_payroll_instance } from '../../../action/payroll/get_payroll_instance';
import { payroll_detail_get_aggridheader } from '../../../action/payroll/payroll_detail_get_aggridheader';
import { get_payroll_history_list } from '../../../action/history/get_payroll_history_list';

const mapStateToProps=(state)=>({
    isLoading:state.payroll_update_rates.isLoading,
    update_success:state.payroll_update_rates.data,
})

const mapDispatchToProps=(dispatch)=>({
    payroll_update_rates:(id,rates)=>{
        dispatch(payroll_update_rates(id,rates))
    },
    get_payroll_history_list:()=>{
        dispatch(get_payroll_history_list())
    },
    payroll_detail_get_aggridheader:(id)=>{
        dispatch(payroll_detail_get_aggridheader(id))
    },
    get_payroll_instance:(id)=>{
        dispatch(get_payroll_instance(id))
    },
})

function ModalUpdateRates(props){
    const {update_success,closeModal,get_payroll_instance,get_payroll_history_list,payroll_detail_get_aggridheader} = props;
    const [rates,setRates] = useState([]);

    const prevUpdate = usePrevious(update_success);

    useEffect(()=>{
        if(props.toggle && props.rates !== null){
            const length = Object.keys(props.rates).length;
            let arr = [];
            for(let i=0;i<length;i++){
                const name = Object.keys(props.rates)[i];
                const amount = Object.values(props.rates)[i];
                arr.push({name,amount});
            }
            setRates(arr);
        }
        else{
            setRates([]);
        }
    },[props.toggle,props.rates])

    useEffect(()=>{
        if(prevUpdate !== undefined && prevUpdate !== update_success && update_success !== null){
            closeModal();
            get_payroll_instance(props.id);
            get_payroll_history_list();
            payroll_detail_get_aggridheader(props.id);
        }
    },[prevUpdate,update_success,closeModal,get_payroll_history_list,get_payroll_instance,payroll_detail_get_aggridheader,props.id])

    const handleRate=(e,i)=>{
        const value = e.target.value;
        setRates(prevState=>{
            const newState = prevState.map((obj,index)=>{
                if(index === i){
                    return {...obj,amount:value}
                }
                return obj;
            })
            return newState;
        })
    }

    const updateRates=()=>{
        if(rates.length !== 0){
            let arr ={};
            for(let i=0;i<rates.length;i++){
                
                arr[rates[i].name] = rates[i].amount;
            }
            props.payroll_update_rates(props.id,arr);
        }
    }

    return(
        <Modal isOpen={props.toggle} size="lg">
            <ModalHeader toggle={()=>props.closeModal()}>{IMLocalized('rates')}</ModalHeader>
            <ModalBody>
                {rates.map((item,index)=>{

                    return(
                        <div className='d-flex'>
                            <FormGroup style={{width:'200px'}}>
                                <InputGroup size="sm">
                                    <Input defaultValue="1" type="number" readOnly={true} />
                                    <InputGroupAddon addonType='append'>
                                        <InputGroupText>SGD</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                            <label className='align-items-center ml-2 mr-2'> - </label>
                            <FormGroup style={{width:'200px'}}>
                                <InputGroup size="sm">
                                    <Input type="number" value={item.amount} min="0" step="0.1" 
                                    onChange={(e)=>{handleRate(e,index)}}
                                    onBlur={(e)=>{
                                        const value = e.target.value;
                                        if(value === ''){
                                            setRates(prevState=>{
                                                const newState = prevState.map((obj,i)=>{
                                                    if(index === i){
                                                        return {...obj,amount:0}
                                                    }
                                                    return obj;
                                                })
                                                return newState
                                            })
                                        }
                                    }}
                                    />
                                    <InputGroupAddon addonType='append'>
                                        <InputGroupText>{item.name}</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </div>
                    )
                })}
            </ModalBody>
            <ModalFooter>
                {props.isLoading && <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>}
                {!props.isLoading && rates.length !== 0 && <button className="btn btn-primary btn-sm" onClick={updateRates}> {IMLocalized('update')}</button>}
                {!props.isLoading && <button onClick={props.closeModal} className="btn btn-danger btn-sm"> {IMLocalized('close')}</button>}
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalUpdateRates);
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import { setdefault_employee_bank } from "../../../../action/employee/setdefault_employee_bank";
import { IMLocalized } from "../../../../language/IMLocalized";

export default function BankTable({ list, editBank, removeBank }){

    const bank_payee = useSelector(state=>state.dropdown_list.bank_payee);
    const isLoading = useSelector(state=>state.setdefault_employee_bank.isLoading);
    const dispatch = useDispatch();
    return(
        <Table size="sm" className="mt-2 align-items-center table-bordered" responsive>
            <thead className="thead-light text-center">
                <tr>
                    <th className="w-50">{IMLocalized('bank')}</th>
                    <th className="w-25">{IMLocalized('account_number')}</th>
                    <th>{IMLocalized('edit')} / {IMLocalized('delete')} / {IMLocalized('default')}</th>
                </tr>
            </thead>
            <tbody className="text-center">
                {list.map((item)=>{
                    const bank_detail = bank_payee.find(item1=>item1.value === item.bank);
                    return(
                        <tr key={item.id}>
                            <td>
                                {item.default !== null ? 
                                <><span className="text-primary">{bank_detail.display}</span><span className="font-weight-bold ml-1">({IMLocalized('default')})</span></>: <span className="text-primary">{bank_detail.display}</span>}
                            </td>
                            <td>{item.account_number}</td>
                            <td align="center" valign="center">
                                <div className='d-flex justify-content-center'>
                                    <div className="primary_btn d-flex align-items-center justify-content-center">
                                        <i className="fas fa-pen text-primary cursor-pointer" onClick={()=>editBank(item.id)}  />
                                    </div>
                                    <div className="delete_btn d-flex align-items-center justify-content-center">
                                        <i className="fas fa-trash text-red cursor-pointer" onClick={()=>removeBank(item.id)} />
                                    </div>
                                    {(isLoading || item.default !== null) ? 
                                    <div className='default_btn d-flex align-items-center justify-content-center '>
                                        <i className="fas fa-check text-primary d-none"></i>
                                    </div>:
                                    <div className='success_btn d-flex align-items-center justify-content-center'>
                                        <i className='fas fa-check text-success cursor-pointer' onClick={()=>dispatch(setdefault_employee_bank(item.id))} />
                                    </div>}
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}
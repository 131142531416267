export function dateRangeOverlap(arr,start_date,end_date){

    const start = new Date(start_date);
    const end = new Date(end_date);

    if(start_date !== null && end_date !== null){
        return arr.filter(item=>{
            const from = new Date(item.from_date);
            const to = new Date(item.to_date);
    
            return (start >= from && start <= to ) || (from >= start && from <= end);
        })
    }
    else if(start_date !== null && end_date === null){
        return arr.filter(item=>{
            const from = new Date(item.from_date);
            const to = new Date(item.to_date);
            return (start <= from) || (start >=from && start <= to);
        })
    }
    else if(start_date === null && end_date !== null){
        return arr.filter(item=>{
            const from = new Date(item.from_date);
            const to = new Date(item.to_date);
            return (end >= from && end <= to ) 
        })
    }

    return arr;
}
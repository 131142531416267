
export const allowance_type = [
    {
        "value": "TRANSPORT",
        "display": "Transport",
        "display_zh":"交通"
    },
    {
        "value": "ENTERTAINMENT",
        "display": "Entertainment",
        "display_zh":"娱乐"
    },
    {
        "value": "OTHERS",
        "display": "Others",
        "display_zh":"其他"
    }
]
import myaxios from '../axios';

export const HOLIDAYLIST_REQUEST = 'HOLIDAYLIST_REQUEST';
export const HOLIDAYLIST_SUCCESS = 'HOLIDAYLIST_SUCCESS';
export const HOLIDAYLIST_FAILURE = 'HOLIDAYLIST_FAILURE';

export const getHolidayList =()=>{
    return dispatch =>{
        dispatch({
            type: 'HOLIDAYLIST_REQUEST'
        });
        myaxios.get('contract/holidaylist/')
        .then((response)=>{
            dispatch({
                type:'HOLIDAYLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'HOLIDAYLIST_FAILURE',
                payload:error,
                error:true
            })
        })
    }
}
import * as claim_picture from '../../action/approvals/claim_approvals_get_claim_picture';

const initialState={
    data:null,
    errors:null,
    isLoading:false
}

const claim_approvals_get_claim_picture = (state=initialState,action)=>{
    switch(action.type){
        case claim_picture.CLAIMAPPROVALSGETCLAIMPICTURE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case claim_picture.CLAIMAPPROVALSGETCLAIMPICTURE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case claim_picture.CLAIMAPPROVALSGETCLAIMPICTURE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case claim_picture.CLAIMAPPROVALSGETCLAIMPICTURE_RESET:
            return{
                ...state,
                data:null,
                errors:null,
            }
        default:
            return state;
    }
}
export default claim_approvals_get_claim_picture;

import * as wizarvalidatejoblevel from '../../action/wizard/wizard_validate_joblevel';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const wizard_validate_joblevel = (state=initialState,action)=>{
    switch(action.type){
        case wizarvalidatejoblevel.WIZARDVALIDATEJOBLEVEL_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case wizarvalidatejoblevel.WIZARDVALIDATEJOBLEVEL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case wizarvalidatejoblevel.WIZARDVALIDATEJOBLEVEL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default wizard_validate_joblevel;
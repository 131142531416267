import myaxios from '../../axios';


export const PAYROLLINSTANCEARRAY_REQUEST = 'PAYROLLINSTANCEARRAY_REQUEST';
export const PAYROLLINSTANCEARRAY_SUCCESS = 'PAYROLLINSTANCEARRAY_SUCCESS';
export const PAYROLLINSTANCEARRAY_FAILURE = 'PAYROLLINSTANCEARRAY_FAILURE';
export const PAYROLLINSTANCEARRAY_RESET = 'PAYROLLINSTANCEARRAY_RESET';

// const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// export const get_payroll_instance_array = (idarr) =>{
//     return dispatch=>{
//         dispatch({
//             type: 'PAYROLLINSTANCEARRAY_REQUEST'
//         });

//         for(let x=0; x<idarr.length; x++){
//             console.log(idarr[x])
//             myaxios.get(`payroll/payroll/${idarr[x]}/`)
//             .then((response)=>{
//                 dispatch({
//                     type:'PAYROLLINSTANCEARRAY_SUCCESS',
//                     payload:response
//                 },1200)
//             })
//             .then(() => delay(1000))
//             .catch((error)=>{
//                 dispatch({
//                     type:'PAYROLLINSTANCEARRAY_FAILURE',
//                     payload:error.response
//                 })
//             })
//         }
//     }
// }

// export const get_payroll_instance_array = (idarr) =>{
//     return async dispatch=>{
//         dispatch({
//             type: 'PAYROLLINSTANCEARRAY_REQUEST'
//         });

//         try{
//             for(let x=0; x<idarr.length; x++){
//                 console.log(idarr[x])
//                 const response = await myaxios.get(`payroll/payroll/${idarr[x]}/`)
                
//                 dispatch({
//                         type:'PAYROLLINSTANCEARRAY_SUCCESS',
//                         payload:response
//                 })
//             }
//         }
//         catch(error){
//                 dispatch({
//                     type:'PAYROLLINSTANCEARRAY_FAILURE',
//                     payload:error.response
//                 })
//         }
        
//     }
// }

export const get_payroll_instance_array = (idarr) =>{
    return async dispatch=>{
        try{
            for(let x=0; x<idarr.length; x++){                
                dispatch({
                    type: 'PAYROLLINSTANCEARRAY_REQUEST'
                });
                const response = await myaxios.get(`payroll/payroll/${idarr[x]}/`)
                
                const updateResponse = {...response}
                updateResponse.data[0].loop = x

                dispatch({
                    type:'PAYROLLINSTANCEARRAY_SUCCESS',
                    payload:updateResponse
                })
            }
            dispatch({
                type:'PAYROLLINSTANCEARRAY_RESET'
            });

        }
        catch(error){
                dispatch({
                    type:'PAYROLLINSTANCEARRAY_FAILURE',
                    payload:error.response
                })
        }
        
    }
}

export const reset_payroll_instance_array = () => dispatch =>{
    dispatch({
        type:'PAYROLLINSTANCEARRAY_RESET'
    });
}
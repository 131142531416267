import * as templatelist from '../action/get_contract_template_list';

const initialState={
    data:[],
    errors:[],
    isLoading:false,
}

const get_contract_template_list = (state=initialState,action)=>{
    switch(action.type){
        case templatelist.TEMPLATELIST_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case templatelist.TEMPLATELIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
            }
        case templatelist.TEMPLATELIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_contract_template_list;
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IMLocalized } from '../../../language/IMLocalized';
import ModalEditAddress from './modal/ModalEditAddress';
import ModalCreateAddress from './modal/ModalCreateAddress';
import { emp_get_employee_address } from '../../../reducers/employee/get_employee_address';
import AddressTable from './component/AddressTable';
import NoDataTable from './component/NoDataTable';

export default function EmployeeAddress({id}){
    
    const list = useSelector(state=>emp_get_employee_address(state,parseInt(id)));
    const [update_toggle,setUpdateToggle] = useState(false);
    const [create_toggle,setCreateToggle] = useState(false);
  
    return(
        <>
        <div className="d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title">
            <div className="d-flex align-items-center">
                <i className="far fa-address-card text-primary"/>
                <h4 className="text-primary m-0 pl-2">{IMLocalized('employee_address')}</h4>
            </div>
            {list == null ?
            <div className="text-primary pt-2 cursor-pointer pr-2" onClick={()=>setCreateToggle(true)} >
                <i className="fas fa-plus-circle mr-1 function_icon" />
                <small>{IMLocalized('add_address')}</small>
            </div> :
            <div className="text-primary pt-2 cursor-pointer pr-2" onClick={()=>setUpdateToggle(true)}  >
                <i className="fas fa-pen mr-1 function_icon "/>
                <small>{IMLocalized('update_address')}</small>
            </div>}
        </div>
        {list == null ? <NoDataTable/> : <AddressTable list={list} />}
        <ModalEditAddress detail={list} toggle={update_toggle} closeModal={()=>setUpdateToggle(false)} />
        <ModalCreateAddress id={id} toggle={create_toggle} closeModal={()=>setCreateToggle(false)} />
        </>
    )
}
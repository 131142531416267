import React from "react";
import { Col, Row } from "reactstrap";
import { IMLocalized } from "../../../language/IMLocalized";
import NoPayTable from "./NoPayTable";
import SelfHelpTable from "./SelfHelpTable";
import BackpayTable from "./BackpayTable";

function DeducationTable(props){

    const col1 = [];
    const col2 = [];

    let col1_amount = 0;
    let col2_amount = 0;

    col1.push(<NoPayTable list={props.nopaylist} payslipcontract={props.emp_payslip?.payslipcontract ?? [] } id={props.id} emp_payslip={props.emp_payslip} key="nopay" type='nopay' />)
    col1_amount += 2;

    let count = 0;
    if(props.sgshg !== null && props.sgshg.length !==0){
        count += props.sgshg.length; 
    }
    if(props.sgcpf !== null){
        count +=1;
    }

    if(count !==0 ){
        col2_amount = count;
        col2.push(<SelfHelpTable sgshg={props.sgshg} sgcpf={props.sgcpf} key="selfhelp"/>)
    }

    if(col2_amount < col1_amount){
        col2.push(<NoPayTable list={props.absentlist} payslipcontract={props.emp_payslip?.payslipcontract ?? [] } id={props.id} emp_payslip={props.emp_payslip} key="absent" type='absent'/>)
        col2_amount += 2;
    }
    else{
        col1.push(<NoPayTable list={props.absentlist} payslipcontract={props.emp_payslip?.payslipcontract ?? [] } id={props.id} emp_payslip={props.emp_payslip} key="absent" type='absent' />)
        col1_amount += 2;
    }

    if(col2_amount < col1_amount){
        col2.push(<NoPayTable list={props.nationlist} payslipcontract={props.emp_payslip?.payslipcontract ?? [] } id={props.id} emp_payslip={props.emp_payslip} key="nation" type='national' />)
        col2_amount += 2;
    }
    else{
        col1.push(<NoPayTable list={props.nationlist} payslipcontract={props.emp_payslip?.payslipcontract ?? [] } id={props.id} emp_payslip={props.emp_payslip} key="nation" type='national' />)
        col1_amount += 2;
    }

    if(col2_amount < col1_amount){
        col2.push(<BackpayTable list={props.deductablelist} id={props.id} payslip_id={props.payslip_id} key="deductbackpaylist" payslipcontract={props.emp_payslip?.payslipcontract ?? [] } type="deduct" />)
        col2_amount += 2;
    }
    else{
        col1.push(<BackpayTable list={props.deductablelist} id={props.id} payslip_id={props.payslip_id} key="deductbackpaylist" payslipcontract={props.emp_payslip?.payslipcontract ?? [] } type="deduct" />)
        col1_amount += 2;
    }

    
    return(
        <>
        <Row>
            <Col lg="12">
                <div className="d-flex justify-content-between pl-2" style={{backgroundColor:'#dee6f1',height:'38px'}}>
                    <div className="d-flex align-items-center">
                    <h2 className="title-color1 mb-0">{IMLocalized('payslip_title_deductions')}</h2>
                    </div>
                </div>
            </Col>
        </Row>
        <Row className="mt-2">
            <Col lg="6">
                {col1}
            </Col>
            <Col lg="6">
                {col2}
            </Col>
        </Row>
        </>
    )
}
export default DeducationTable;
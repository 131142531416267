import * as uob from '../../action/payroll/get_payment_uob';

const initialState={
    isLoading:false,
    data:null,
    errors:null,
}


const get_payment_uob = (state=initialState,action)=>{
    switch(action.type){
        case uob.GETPAYMENTUOB_REQUEST:
            return{
                isLoading:true,
                errors:null,
                data:null
            }
        case uob.GETPAYMENTUOB_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case uob.GETPAYMENTUOB_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_payment_uob;
import myaxios from '../../axios';
import FormData from 'form-data';

export const ADDEMPEDUCATIONPICTURE_REQUEST = 'ADDEMPEDUCATIONPICTURE_REQUEST';
export const ADDEMPEDUCATIONPICTURE_SUCCESS = 'ADDEMPEDUCATIONPICTURE_SUCCESS';
export const ADDEMPEDUCATIONPICTURE_FAILURE = 'ADDEMPEDUCATIONPICTURE_FAILURE';

export const add_employee_education_picture = (id,file)=>{
    return dispatch=>{
        dispatch({
            type:'ADDEMPEDUCATIONPICTURE_REQUEST'
        })

        let formData= new FormData();
        if(file.length !== 0){
            for(let i=0;i<file.length;i++){
                formData.append('picture',file[i],file[i].name);
            }
        }

        myaxios.post(`employee/education/${id}/AddPic/`,formData,{headers:{'Content-Type':'multipart/form-data'}})
        .then((response)=>{
            dispatch({
                type:'ADDEMPEDUCATIONPICTURE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'ADDEMPEDUCATIONPICTURE_FAILURE',
                payload:error
            })
        })
    }
}
import myaxios from '../../axios';

export const GETUSEREMPLEAVEDETAIL2_REQUEST = 'GETUSEREMPLEAVEDETAIL2_REQUEST';
export const GETUSEREMPLEAVEDETAIL2_SUCCESS = 'GETUSEREMPLEAVEDETAIL2_SUCCESS';
export const GETUSEREMPLEAVEDETAIL2_FAILURE = 'GETUSEREMPLEAVEDETAIL2_FAILURE';
export const GETUSEREMPLEAVEDETAIL2_RESET = 'GETUSEREMPLEAVEDETAIL2_RESET';


export const get_user_leave_detail2 = (id)=>{
    return dispatch=>{
        dispatch({
            type:'GETUSEREMPLEAVEDETAIL2_REQUEST'
        });

        myaxios.get(`leave/all/apply/${id}/`)
        .then((response)=>{
            dispatch({
                type:'GETUSEREMPLEAVEDETAIL2_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETUSEREMPLEAVEDETAIL2_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_get_user_leave_detail2 = () => dispatch =>{
    dispatch({
        type:'GETUSEREMPLEAVEDETAIL2_RESET'
    })
}
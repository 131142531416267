import myaxios from "../../axios";

export const VARIANCECOMPANYPAYROLL_REQUEST = 'VARIANCECOMPANYPAYROLL_REQUEST';
export const VARIANCECOMPANYPAYROLL_SUCCESS = 'VARIANCECOMPANYPAYROLL_SUCCESS';
export const VARIANCECOMPANYPAYROLL_FAILURE = 'VARIANCECOMPANYPAYROLL_FAILURE';

export const payroll_variance_company_payroll = (from_date,to_date)=>{
    return dispatch=>{
        dispatch({
            type:'VARIANCECOMPANYPAYROLL_REQUEST'
        });

        myaxios.post('payroll/report/paywage/',{
            from_date:from_date,
            to_date:to_date
        })
        .then((res)=>res.data)
        .then((json)=>{
            dispatch({
                type:'VARIANCECOMPANYPAYROLL_SUCCESS',
                payload:json
            })
        })
        .catch((errors)=>{
            dispatch({
                type:'VARIANCECOMPANYPAYROLL_FAILURE',
                payload:errors
            })
        })
    }
}
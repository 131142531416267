import React from 'react';
import { Col, Row, Table } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import ImportAdditionalBody from './ImportAdditionalBody';


export default function ImportAdditionalTable({department,costcenter,joblevel,jobtitle,section,deleteItem}){

    return(
    
        <Row>
            <Col>
                <Table className='table-bordered bg-white' size="sm" responsive>
                    <thead className='thead-light text-center'>
                        <tr>
                            {department.length !== 0 && <th className='td-w-min200'>{IMLocalized('department')}</th>}
                            {section.length !== 0 && <th className='td-w-min200'>{IMLocalized('section')}</th>}
                            {jobtitle.length !== 0 && <th className='td-w-min200'>{IMLocalized('job_title')}</th>}
                            {joblevel.length !== 0 && <th className='td-w-min200'>{IMLocalized('job_level')}</th>}
                            {costcenter.length !== 0 && <th className='td-w-min200'>{IMLocalized('cost_center')}</th>}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {department.length !== 0 && 
                            <td className='p-0'>
                                {department.map((item,index)=>{
                                    return <ImportAdditionalBody item={item} key={index} deleteItem={()=>deleteItem('department',index)} />
                                })}
                            </td>}
                            {section.length !== 0 && 
                            <td className='p-0'>
                                {section.map((item,index)=>{
                                    return <ImportAdditionalBody item={item} key={index} deleteItem={()=>deleteItem('section',index)} />
                                })}
                            </td>}
                            {jobtitle.length !== 0 && 
                            <td className='p-0'>
                                {jobtitle.map((item,index)=>{
                                    return <ImportAdditionalBody item={item} key={index} deleteItem={()=>deleteItem('jobtitle',index)} />
                                })}
                            </td>}
                            {joblevel.length !== 0 && 
                            <td className='p-0'>
                                {joblevel.map((item,index)=>{
                                    return <ImportAdditionalBody item={item} key={index} deleteItem={()=>deleteItem('joblevel',index)} />
                                })}
                            </td>}
                            {costcenter.length !== 0 && 
                            <td className='p-0'>
                                {costcenter.map((item,index)=>{
                                    return <ImportAdditionalBody item={item} key={index} deleteItem={()=>deleteItem('costcenter',index)} />
                                })}
                            </td>}
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
    )
}
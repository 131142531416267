import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const SAVECONTACTTEMPLATE_REQUEST = 'SAVECONTACTTEMPLATE_REQUEST';
export const SAVECONTACTTEMPLATE_SUCCESS = 'SAVECONTACTTEMPLATE_SUCCESS';
export const SAVECONTACTTEMPLATE_FAILURE = 'SAVECONTACTTEMPLATE_FAILURE';
export const SAVECONTACTTEMPLATE_RESET = 'SAVECONTACTTEMPLATE_RESET';

export const save_contract_template = (name,hours_day,lower_pay_range,upper_pay_range,is_workman,department,section,job_title,job_level,allowances,recurring_allowances,cost_center,contract_type,contract_rate,wschedule)=>{
    return dispatch=>{
        dispatch({
            type:'SAVECONTACTTEMPLATE_REQUEST'
        });

        myaxios.post('contract/template/contracts/',{
            hours_day:hours_day,
            contract_type:contract_type,
            contract_rate:contract_rate,
            is_workman:is_workman,
            name:name,
            lower_pay_range:lower_pay_range,
            upper_pay_range:upper_pay_range,
            job_level:job_level,
            department:department,
            section:section,
            job_title:job_title,
            cost_center:cost_center,
            wschedule:wschedule,
            allowances:allowances,
            recurring_allowances:recurring_allowances 
        })
        .then((response)=>{
            dispatch({
                type:'SAVECONTACTTEMPLATE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('save_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'SAVECONTACTTEMPLATE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('save_failure')
            });
        })
    }
}

export const reset_save_contract_template = () => dispatch =>{
    dispatch({
        type:'SAVECONTACTTEMPLATE_RESET'
    });
}
import myaxios from '../../axios';
export const NEXTPREV_REQUEST = 'NEXTPREV_REQUEST';
export const NEXTPREV_SUCCESS = 'NEXTPREV_SUCCESS';
export const NEXTPREV_FAILURE = 'NEXTPREV_FAILURE';


export const get_nextprev_employee = (id)=>{
    return dispatch =>{
        dispatch({
            type: 'NEXTPREV_REQUEST'
        });
        myaxios.get(`employee/employees/${id}/`)
        .then((response)=>{
            dispatch({
                type: 'NEXTPREV_SUCCESS',
                payload:response
                
            },
            localStorage.setItem("nexturl", response.data.quickswitch.next.id),
            localStorage.setItem("prevurl", response.data.quickswitch.prev.id),
            )})
        .catch((error) =>{
            dispatch({
                type: 'NEXTPREV_FAILURE',
                payload: error,
                error:true
            })
        })
    }
}



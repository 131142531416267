import myaxios from '../../axios';

export const MONTHLYAGGRIDHEADER_REQUEST = 'MONTHLYAGGRIDHEADER_REQUEST';
export const MONTHLYAGGRIDHEADER_SUCCESS = 'MONTHLYAGGRIDHEADER_SUCCESS';
export const MONTHLYAGGRIDHEADER_FAILURE = 'MONTHLYAGGRIDHEADER_FAILURE';


export const monthly_report_get_aggridheader = (id)=>{
    return dispatch=>{
        dispatch({
            type:'MONTHLYAGGRIDHEADER_REQUEST'
        });

        myaxios.get(`payroll/payroll/${id}/ReportGridHeader/`)
        .then((response)=>{
            dispatch({
                type:'MONTHLYAGGRIDHEADER_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'MONTHLYAGGRIDHEADER_FAILURE',
                payload:error
            })
        })
    }
}
import * as contractdepartment1 from '../../action/employee/employee_contract_department1';

const initialstate={
    isLoading:false,
    errors:[],
    data:[]
}

const employee_contract_department1 = (state=initialstate,action)=>{
    switch(action.type){
        case contractdepartment1.EMPCONTRACTDEPARTMENT1_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case contractdepartment1.EMPCONTRACTDEPARTMENT1_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case contractdepartment1.EMPCONTRACTDEPARTMENT1_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default employee_contract_department1;
import * as create from '../../action/iras/create_new_iras';


const initialState={
    data:null,
    errors:null,
    isLoading:false
}


const create_new_iras = (state=initialState,action)=>{
    switch(action.type){
        case create.CREATE_IRAS_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case create.CREATE_IRAS_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case create.CREATE_IRAS_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state
    }
}
export default create_new_iras;
import React, { useContext, useEffect, useState } from "react";
import LeaveSchemeList from "./LeaveSchemeList";
import LeaveCreateSchemeList from "./LeaveCreateSchemeList";
import { NewMainContext } from "../../context/NewMainContext";

export default function LeaveScheme(){

    const [step, setStep] = useState(0);
    const { leave_scheme_reset, setLeaveSchemeReset } = useContext(NewMainContext);

    useEffect(()=>{
        if(leave_scheme_reset === true){
            setStep(0);
            setLeaveSchemeReset(false);
        }
    },[leave_scheme_reset, setLeaveSchemeReset])

    return(
        <>
        {step === 0 && <LeaveSchemeList setStep={setStep} />}
        {step === 1 && <LeaveCreateSchemeList setStep={setStep} />}
        </>
    )
}
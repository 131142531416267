import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const MASSASSIGNLEAVEAPPROVAL1_REQUEST = 'MASSASSIGNLEAVEAPPROVAL1_REQUEST';
export const MASSASSIGNLEAVEAPPROVAL1_SUCCESS = 'MASSASSIGNLEAVEAPPROVAL1_SUCCESS';
export const MASSASSIGNLEAVEAPPROVAL1_FAILURE = 'MASSASSIGNLEAVEAPPROVAL1_FAILURE';
export const MASSASSIGNLEAVEAPPROVAL1_RESET = 'MASSASSIGNLEAVEAPPROVAL1_RESET';

export const mass_assign_leave_approval1 = (data)=>{
    return dispatch=>{
        dispatch({
            type:'MASSASSIGNLEAVEAPPROVAL1_REQUEST'
        });

        myaxios.post('leave/employeeapproval/array_create/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'MASSASSIGNLEAVEAPPROVAL1_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('assign_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'MASSASSIGNLEAVEAPPROVAL1_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('assign_failure')
            });
        })
    }
}

export const reset_mass_assign_leave_approval1 = () => dispatch =>{
    dispatch({
        type:'MASSASSIGNLEAVEAPPROVAL1_RESET'
    });
} 
import * as get_logo from '../action/get_general_logo';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const get_general_logo = (state=initialState,action)=>{
    switch(action.type){
        case get_logo.GETGENERALLOGO_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case get_logo.GETGENERALLOGO_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case get_logo.GETGENERALLOGO_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_general_logo;
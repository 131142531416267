import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { claim_assign_scheme1, reset_claim_assign_scheme1 } from '../../../../action/claim/claim_assign_scheme1';

export default function ModalAssignClaimScheme({ toggle, closeModal, onSuccess, id }){

    const schemelist = useSelector(state=>state.get_claim_scheme_list.data);
    const success = useSelector(state=>state.claim_assign_scheme1.data);
    const errors = useSelector(state=>state.claim_assign_scheme1.errors);
    const isLoading = useSelector(state=>state.claim_assign_scheme1.isLoading);
    const daterange = useSelector(state=>state.general_daterange.data);

    const [claim_scheme,setScheme] = useState('');
    const [err_claim_scheme,setErrScheme] = useState('');
    const [select_year,setYear] = useState('');
    const [err_select_year,setErrYear] = useState('');
    const [full,setFull] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(toggle){
            if(daterange.length !== 0){
                const date_range = daterange.date_range;
                const claim = date_range.claim;
                const year = claim.year;
                setYear(year);
            }
            else{
                setYear('');
            }   
            setErrYear('');
            setErrScheme('');
            setScheme('');
        }
    },[toggle,daterange])

    useEffect(()=>{
        if( success != null && toggle){
           onSuccess();
           dispatch(reset_claim_assign_scheme1());
        }
    },[success,toggle,onSuccess,dispatch])

    useEffect(()=>{
        if( errors !== null && toggle){
            if(errors.hasOwnProperty('year')){
                const word = errors['year'].toString();
                setErrYear(word);
            }
            dispatch(reset_claim_assign_scheme1());
        }
    },[errors,toggle,dispatch])

    const assignScheme=()=>{
        if(claim_scheme !== ''){
            
            const year = select_year !== '' ? select_year : null;
            const employee = [id];
            dispatch(claim_assign_scheme1(claim_scheme,employee,full,year));
        }
        else{
            setErrScheme(IMLocalized('claim_scheme_is_required'))
        }
    }
    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('assign_claim_scheme')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='claim_scheme'>{IMLocalized('claim_scheme')}</label>
                            <Input type="select" size="sm" name="claim_scheme" id='claim_scheme' value={claim_scheme} onChange={(e)=>{setScheme(e.target.value); setErrScheme('')}} invalid={err_claim_scheme.length > 0}>
                                <option></option>
                                {schemelist.map((item,index)=>{
                                    return <option value={item.id} key={index}>{item.name}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_claim_scheme}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='select_year'>{IMLocalized('year')}</label>
                            <Input size="sm" type="text" id="select_year" name="select_year" value={select_year} onChange={(e)=>{setYear(e.target.value); setErrYear('')}} invalid={err_select_year.length > 0} />
                            <FormFeedback>{err_select_year}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id='empclaimfullproration' name='full' className="custom-control-input" checked={full} onChange={(e)=>setFull(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="empclaimfullproration">{IMLocalized('full_proration')}</label>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ?
                <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button className="btn btn-primary btn-sm" onClick={assignScheme}>{IMLocalized('assign')}</button>
                    <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}

import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';

export const UPDATEPAYSLIPBONUSVALIDATE_REQUEST = 'UPDATEPAYSLIPBONUSVALIDATE_REQUEST';
export const UPDATEPAYSLIPBONUSVALIDATE_SUCCESS = 'UPDATEPAYSLIPBONUSVALIDATE_SUCCESS';
export const UPDATEPAYSLIPBONUSVALIDATE_FAILURE = 'UPDATEPAYSLIPBONUSVALIDATE_FAILURE';

export const update_payslip_bonus_validate = (id,data)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEPAYSLIPBONUSVALIDATE_REQUEST'
        });

        myaxios.post(`payroll/payroll/${id}/AddUpdateBonusValidate/`,{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'UPDATEPAYSLIPBONUSVALIDATE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATEPAYSLIPBONUSVALIDATE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}
import myaxios from "../axios";

export const FIRSTTIMEPASS_REQUEST = 'FIRSTTIMEPASS_REQUEST';
export const FIRSTTIMEPASS_SUCCESS = 'FIRSTTIMEPASS_SUCCESS';
export const FIRSTTIMEPASS_FAILURE = 'FIRSTTIMEPASS_FAILURE';


export const first_time_password = (token,password)=>{
    return dispatch=>{

        dispatch({
            type:'FIRSTTIMEPASS_REQUEST'
        });

        myaxios.post('otp/first_time/',{
            first_time:token,
            password:password
        })
        .then((response)=>{
            dispatch({
                type:'FIRSTTIMEPASS_SUCCESS',
                payload:response
            })
        })
        .catch((errors)=>{
            dispatch({
                type:'FIRSTTIMEPASS_FAILURE',
                payload:errors
            })
        })
    }
}
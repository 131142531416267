import * as applyleavelist2 from '../../action/leave/get_apply_leave_list2';

const initialState={
    isLoading:false,
    errors:[],
    data:[]
}

const get_apply_leave_list2 = (state=initialState,action)=>{
    switch(action.type){
        case applyleavelist2.GETAPPLYLEAVELIST2_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case applyleavelist2.GETAPPLYLEAVELIST2_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case applyleavelist2.GETAPPLYLEAVELIST2_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_apply_leave_list2;
import * as com from '../action/company_list';

const initialState ={
    data:[],
    error:null,
    isLoading:false
}


const companyList = (state=initialState,action) =>{
    switch(action.type) {
        case com.COMPANYLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:[]
            }
        case com.COMPANYLIST_SUCCESS:
            return{
                ...state,
                data:action.payload.data,
                isLoading:false
        }
        case com.COMPANYLIST_FAILURE:
            return{
                ...state,
                error:action.payload,
                isLoading:false,
            }
        default:
            return state;
    }
}

export default companyList;


export const company_list_detail = (state,id)=>{
    const { data } = state.company_list;

    return data.length !== 0 && id != null ? data.find(item=>item.id === id) : null;
}
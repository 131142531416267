import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { Toast } from '../../swal/SwalToast';

export const NEW_DELEMPPERMIT_REQUEST = 'NEW_DELEMPPERMIT_REQUEST';
export const NEW_DELEMPPERMIT_SUCCESS = 'NEW_DELEMPPERMIT_SUCCESS';
export const NEW_DELEMPPERMIT_FAILURE = 'NEW_DELEMPPERMIT_FAILURE';
export const NEW_DELEMPPERMIT_RESET = 'NEW_DELEMPPERMIT_RESET';


export const delete_employee_workpermit =(data)=>{
    return dispatch=>{
    
        if(data.length!== 0){
            dispatch({
                type:'NEW_DELEMPPERMIT_REQUEST'
            });

            for(let i=0;i<data.length;i++){
                const id = data[i].id

                myaxios.delete(`employee/workpermit/${id}/`)
                .then((response)=>{
                    dispatch({
                        type:'NEW_DELEMPPERMIT_SUCCESS',
                        payload:response
                    });

                    Toast.fire({
                        type: 'success',
                        title: IMLocalized('delete_success')
                    });
                })
                .catch((error)=>{
                    dispatch({
                        type:'NEW_DELEMPPERMIT_FAILURE',
                        payload:error
                    });
                })
            }
        }
    }
}

export const reset_delete_employee_workpermit =()=>dispatch=>{
    dispatch({
        type:'NEW_DELEMPPERMIT_RESET'
    });
}
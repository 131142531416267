import * as userclaimlist from '../../action/user/user_apply_claim_list';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const user_apply_claim_list = (state=initialState,action) =>{
    switch(action.type){
        case userclaimlist.USERAPPLYCLAIMLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case userclaimlist.USERAPPLYCLAIMLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case userclaimlist.USERAPPLYCLAIMLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default user_apply_claim_list;
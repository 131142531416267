import React,{Component} from 'react';
import { connect } from 'react-redux';
import {Row,Col,Container,CardBody,Card,Table,Breadcrumb,BreadcrumbItem,CardHeader} from 'reactstrap';
import OverlayLoading from '../../loading_component/overlay_loading';
import "survey-react/survey.css";
import {IMLocalized} from '../../../language/IMLocalized';
import moment from 'moment';
import FileSaver from 'file-saver';
import ExcelJs from 'exceljs';
import * as Survey from 'survey-react';
import {manager_submit_appraisal} from '../../../action/appraisal/manager_submit_appraisal';
import {get_manager_appraisal_list} from '../../../action/appraisal/get_manager_appraisal_list';
import { NewMainContext } from '../../../context/NewMainContext';
class manager_appraisal extends Component{

    constructor(props){
        super(props);
        this.state={
            group:'',
            err_group:'',
            employeelist:[],
            employee:'',
            err_employee:'',
            is_start:false,
            model:new Survey.Model([]),
            json:{
                questions:[],
                completedHtml:"<p>Thank you for completing appraisal<p>"
            },
            step:0,
            grouplist:[],
            view_data:{},
            view_name:'',
            group_name:'',
        }
    }

    componentDidMount(){
        const {grouplist} = this.props;
        this.setState({grouplist});
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.grouplist !== this.props.grouplist){
            const {grouplist} = this.props;
            const {group} = this.state;
            if(group !== ''){
                const item = this.props.grouplist.filter(function(item){
                    return item.id === group
                });
                if(item.length !== 0){
                    const data = item[0];
                    const appraisals = data.appraisals;
                    const form = data.form;
                    this.setState({form});
                    this.setState({employeelist:appraisals});
                }
               
            }
            this.setState({grouplist});
        }
        if(prevState.group !== this.state.group){
            const {group} = this.state;
            if(group !== ''){
                const item = this.props.grouplist.filter(function(item){
                    return item.id === group
                });
                if(item.length !== 0){
                    const data = item[0];
                    const appraisals = data.appraisals;
                    const form = data.form;
                    this.setState({form});
                    this.setState({employeelist:appraisals});
                }
                
            }
        }
        if(prevState.form !== this.state.form){
            this.getDetail();
        }
        if(prevProps.submit_success !== this.props.submit_success){
            this.props.get_manager_appraisal_list();
            this.goback1();
        }
        if(this.context.manager_appraisal_reset === true){
            this.setState({step:0});
            this.context.setManagerAppraisalReset(false);
        }
    }

    getDetail=(response)=>{
        const {form,json} = this.state;
        const {formlist} = this.props;
        if(form !== ''){
            const data = formlist.filter(function(item){
                return item.id === form
            })

            if(data.length !== 0){
                const item = data[0];
                const questions = item.questions;
                json.questions = questions;
                this.setState({json});
                this.setState({model:new Survey.Model(json)})
                this.setState({view_data:response})
            }
        }
    }

    getDateFormat=(date)=>{
        const date1 = moment(date,'YYYY-MM-DD');
        const new_date = date1.format('DD/MM/YYYY');
        return new_date;
    }

    handleGroup=(id,name)=>{
        this.setState({step:1});
        this.setState({group:id});
        this.setState({group_name:name});
    }

    startAppraisal=(id)=>{
        
        Promise.resolve(this.getDetail())
        .then(this.setState({employee:id}))
        .then(this.setState({is_start:true}))
    }

    viewAppraisal=(id,response,name)=>{
        
        Promise.resolve(this.getDetail(response))
        .then(this.setState({employee:id}))
        .then(this.setState({is_view:true}))
        .then(this.setState({view_name:name}))
    }

    completedSurvey=(json)=>{
        const {employee,group} = this.state;
        this.props.manager_submit_appraisal(group,employee,json.data);
      
    }

    goback=()=>{
        
        this.setState({step:0});
        this.setState({group:''});
        this.setState({group_name:''})
    }

    goback1=()=>{
        this.setState({is_start:false});
        this.setState({model:new Survey.Model([])})
        this.setState({employee:''});
        this.setState({is_view:false})
        this.setState({view_name:''});
        
    }

    generateGroup=(data1,name)=>{
        const wb= new ExcelJs.Workbook();
        const ws = wb.addWorksheet('Appraisal Report Group');
        const today = moment(new Date()).format('DD/MM/YYYY');
        const time = moment().format('HH:mm:ss');
        ws.getCell('A1').value = 'Appraisal Report - Group';
        ws.getCell('A2').value = 'Printed On : '+today+' '+time;
        const column = [
            {headerName:'EMP ID',field:'employee.employee_number'},
            {headerName:'EMP NAME',field:'employee.name'},
            {headerName:'DEPARTMENT',field:'employee.contract_cache.department'},
            {headerName:'SECTION',field:'employee.contract_cache.section'},
            {headerName:'JOB TITLE',field:'employee.contract_cache.job_title'},
            {headerName:'JOB LEVEL',field:'employee.contract_cache.job_level'},
            {headerName:'COST CENTER',field:'employee.contract_cache.cost_center'},
            {headerName:'PERCENTAGE (%)',field:'percentage'}
        ];

        ws.columns = [
            {width:15},
            {width:15},
            {width:15},
            {width:15},
            {width:15},
            {width:15},
            {width:15},
            {width:15},
        ]

        for(let i =0;i<column.length ;i++){
            const headerName = column[i].headerName;
            const field = (column[i].field);
            const length = data1.length;
            ws.getCell(this.getColumnName(i)+'4').value = headerName;
            for(let x=0;x<length;x++){
                const step = 5+x;
                const data = this.getValue(data1[x],field);
                if(field !== 'percentage'){
                    ws.getCell(this.getColumnName(i)+step).value = data
                }
                else{
                   
                    const max = data1[x].max_score;
                    const total = data1[x].total_score;
                    const final = (total/max) * 100;
                    ws.getCell(this.getColumnName(i)+step).value = final
                }
            }
        }

        ws.columns=[
            {width:20},
            {width:20},
            {width:20},
            {width:20},
            {width:20},
            {width:20},
            {width:20},
            {width:20},
        ]

        const filename = 'Appraisal_Report_Group('+name+').xlsx';
        wb.xlsx.writeBuffer().then((buf)=>{

            var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
            FileSaver.saveAs(file, filename)
        });
    }

    generateExcel=(data1)=>{
        const {view_name} = this.state;
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('Appraisal Report-Individual');
        const today = moment(new Date()).format('DD/MM/YYYY');
        const time = moment().format('HH:mm:ss');
        // const day =moment(new Date()).format("DD");
        // const month =moment(new Date()).format("MM");
        ws.getCell('A1').value = 'Appraisal Report - Individual';
        ws.getCell('A2').value = 'Printed On : '+today+' '+time;

        const column = [
            {headerName:'EMP ID',field:'employee.employee_number'},
            {headerName:'EMP NAME',field:'employee.name'},
            {headerName:'DEPARTMENT',field:'employee.contract_cache.department'},
            {headerName:'SECTION',field:'employee.contract_cache.section'},
            {headerName:'JOB TITLE',field:'employee.contract_cache.job_title'},
            {headerName:'JOB LEVEL',field:'employee.contract_cache.job_level'},
            {headerName:'COST CENTER',field:'employee.contract_cache.cost_center'},
            {headerName:'PERCENTAGE (%)',field:'percentage'}
        ];

        for(let i =0;i<column.length ;i++){
            const headerName = column[i].headerName;
            const field = (column[i].field);
            const length = data1.length;
            ws.getCell(this.getColumnName(i)+'4').value = headerName;
            for(let x=0;x<length;x++){
                const step = 5+x;
                const data = this.getValue(data1[x],field);
                if(field !== 'percentage'){
                    ws.getCell(this.getColumnName(i)+step).value = data
                }
                else{
                  
                    const max = data1[x].max_score;
                    const total = data1[x].total_score;
                    const final = (total/max) * 100;
                    ws.getCell(this.getColumnName(i)+step).value = final
                }
            }
        }

        ws.columns=[
            {width:20},
            {width:20},
            {width:20},
            {width:20},
            {width:20},
            {width:20},
            {width:20},
            {width:20},
        ]

        const filename = 'Appraisal_Report_'+view_name+'.xlsx';
        wb.xlsx.writeBuffer().then((buf)=>{

            var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
            FileSaver.saveAs(file, filename)
        });

    }

    getColumnName=(n)=>{
        let ordA = 'A'.charCodeAt(0);
        let ordZ = 'Z'.charCodeAt(0);
        let len = ordZ - ordA +1;

        let s = '';
        while(n >=0){
            s = String.fromCharCode(n % len + ordA )+s;
            n = Math.floor(n/len) -1;
        }
        return s;
    }

    getValue(object, path) {
        const parts = path.split(".");
        return parts.reduce((value, key) => value ? value[key] : value, object)
    }

    exportGroup=(id)=>{
        const {grouplist} = this.state;
        const detail = grouplist.filter(function(item){
            return item.id === id
        })
        if(detail.length !== 0){
            const data = detail[0].appraisals;
            const name = detail[0].name;
            this.generateGroup(data,name);
        }
    }

    exportExcel=()=>{
        const {employee,group,grouplist} = this.state;
        const list = grouplist.filter(function(item){
            return item.id === group
        })

        if(list.length !== 0){
            const data = list[0];
            const appraisal = data.appraisals;
            const detail = appraisal.filter(function(item){
                return item.employee.id === employee;
            })
            if(detail.length !== 0){
                const item = detail;
                this.generateExcel(item)
            }
        }
    }

    render(){
        var myCss = {
            matrix: {root: "table table-striped matrixtable"},
        };

        let surveyRender = '';
        const {step,grouplist} = this.state;
        let group_table='';
        let emp_table ='';
        let back_button = '';
        let print_button = '';
        if(step === 0){
            if(grouplist.length !== 0){
                const option = grouplist.map((item,index)=>{
                    let print1_button = '---';
                    const complete = item.complete;
                    if(complete === true){
                        print1_button = <button className="btn btn-success btn-sm" onClick={this.exportGroup.bind(this,item.id)} >{IMLocalized('export')}</button>
                    }

                    return(
                        <tr >
                            <td className="cursor-pointer" onClick={this.handleGroup.bind(this,item.id,item.name)}>{item.name}</td>
                            <td className="cursor-pointer" onClick={this.handleGroup.bind(this,item.id,item.name)}>{item.year}</td>
                            <td className="cursor-pointer" onClick={this.handleGroup.bind(this,item.id,item.name)}>{this.getDateFormat(item.from_date)}</td>
                            <td className="cursor-pointer" onClick={this.handleGroup.bind(this,item.id,item.name)}>{this.getDateFormat(item.to_date)}</td>
                            <td>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" id={"appraisalgroup"+index} name="appraisal" className="custom-control-input" checked={item.complete} />
                                    <label className="custom-control-label" htmlFor={"appraisalgroup"+index}></label>
                                </div>
                            </td>
                            <td>{print1_button}</td>
                        </tr>
                    )
                })

                group_table = (
                    <Row className="pt-2">
                        <Col>
                            <Card>
                                <CardHeader>
                                    <h4 className="mb-0">{IMLocalized('appraisal')}</h4> 
                                </CardHeader>
                                <Table size="sm" className="table-bordered align-items-center" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th>{IMLocalized('name')}</th>
                                            <th>{IMLocalized('year')}</th>
                                            <th>{IMLocalized('from_date')}</th>
                                            <th>{IMLocalized('to_date')}</th>
                                            <th>{IMLocalized('completed')}</th>
                                            <th>{IMLocalized('export_excel')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {option}
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                    
                )
            }
            else{
                group_table = (
                    <Row className="pt-2">
                        <Col>
                            <Card>
                                <CardHeader>
                                    <h4 className="mb-0">{IMLocalized('appraisal')}</h4>
                                </CardHeader>
                                <CardBody className="d-flex justify-content-center align-items-center">
                                    <span className="h4  mb-0">{IMLocalized('no_data')}</span>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )
            }
        }
        else if(step === 1){
            if(this.state.employeelist.length !== 0){
                const option = this.state.employeelist.map((item,index)=>{
                    const submit = item.submitted;
                    const max = item.max_score;
                    const total = item.total_score;
                    let final = 0;
                    if(max !== 0 && total !== 0){
                        final = (total / max) * 100;
                    }
                    let label = IMLocalized('no');
                    if(submit === true){
                        label = IMLocalized('yes');
                    }
                    let button_submit = '';
                    if(submit === false){
                        button_submit=(
                            <button className="btn btn-primary btn-sm" onClick={this.startAppraisal.bind(this,item.employee.id)}>{IMLocalized('start')}</button>
                        )
                    }
                    else{
                        button_submit =(
                            <button className="btn btn-primary btn-sm" onClick={this.viewAppraisal.bind(this,item.employee.id,item.response,item.employee.name)}>{IMLocalized('view')}</button>
                        )
                    }
                    return(
                        <tr>
                            <td>{item.employee.employee_number}</td>
                            <td>{item.employee.name}</td>
                            <td>{item.employee.contract_cache.department}</td>
                            <td>{item.employee.contract_cache.section}</td>
                            <td>{item.employee.contract_cache.job_title}</td>
                            <td>{item.employee.contract_cache.job_level}</td>
                            <td>{item.employee.contract_cache.cost_center}</td>
                            <td>
                                {label}
                            </td>
                            <td>{final}</td>
                            <td>{button_submit}</td>
                        </tr>
                    )
                })
    
                emp_table=(
                    <Row className="pt-2">
                        <Col>
                            <Card>
                                <CardHeader>
                                    <h4>{IMLocalized('appraisal')} - {this.state.group_name}</h4> 
                                </CardHeader>
                                <Table size="sm" className="table-bordered align-items-center" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th>{IMLocalized('employee_id')}</th>
                                            <th>{IMLocalized('employee_name')}</th>
                                            <th>{IMLocalized('department')}</th>
                                            <th>{IMLocalized('section')}</th>
                                            <th>{IMLocalized('job_title')}</th>
                                            <th>{IMLocalized('job_level')}</th>
                                            <th>{IMLocalized('cost_center')}</th>
                                            <th>{IMLocalized('submitted')}</th>
                                            <th>{IMLocalized('percentage')} (%)</th>
                                            <th>{IMLocalized('action')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {option}
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                )
            }
            else{
                emp_table = (
                    <Row className="pt-2">
                        <Col>
                            <Card>
                                <CardHeader>
                                    <h4 className="mb-0">{IMLocalized('appraisal')}</h4>
                                </CardHeader>
                                <CardBody className="d-flex justify-content-center align-items-center">
                                    <span className="h4  mb-0">{IMLocalized('no_data')}</span>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )
            }
            back_button= <button className="btn btn-primary btn-sm" onClick={this.goback}><i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
        }

        if(this.state.is_start === true){
            back_button = <button className="btn btn-primary btn-sm" onClick={this.goback1}><i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
            surveyRender = <Survey.Survey cssType="bootstrap" css={myCss} model={this.state.model} onComplete={this.completedSurvey} ></Survey.Survey>
        }
        else if(this.state.is_view === true){
            print_button = <button className="btn btn-primary btn-sm" onClick={this.exportExcel}>{IMLocalized('export_excel')}</button>
            back_button = <button className="btn btn-primary btn-sm" onClick={this.goback1}><i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
            surveyRender = <Survey.Survey cssType="bootstrap" data={this.state.view_data} css={myCss} model={this.state.model} showNavigationButtons={false} mode="display"></Survey.Survey>
        }
        else{
            surveyRender = (
                <Container fluid>
                    {group_table}
                    {emp_table}
                   
                </Container>
            )
        }
        return(
            <div>
                {this.props.isLoading || this.props.isLoading1 || this.props.isLoading2 ? <OverlayLoading/>:
                <div>
                    <Container fluid>
                        <Row className="border-bottom bg-white">
                            <Col className="p-2">
                                <Row>
                                    <Col>
                                        <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                                            <BreadcrumbItem><a href="/#" onClick={()=>this.context.addItem('1','dashboard')}>{IMLocalized('home')}</a></BreadcrumbItem>
                                            <BreadcrumbItem active>{IMLocalized('appraisal')}</BreadcrumbItem>
                                        </Breadcrumb>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="pl-2">
                                            {back_button}
                                            {print_button}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                    {surveyRender}
                </div>}
            </div>
        )
    }
}
function mapStateToProps(state){
    return{
        grouplist:state.get_manager_appraisal_list.data,
        formlist:state.get_appraisal_form_list.data,
        submit_success:state.manager_submit_appraisal.data,
        isLoading:state.get_appraisal_form_list.isLoading,
        isLoading1:state.get_manager_appraisal_list.isLoading,
        isLoading2:state.manager_submit_appraisal.isLoading,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        manager_submit_appraisal:(id,employee,response)=>{
            dispatch(manager_submit_appraisal(id,employee,response))
        },
        get_manager_appraisal_list:()=>{
            dispatch(get_manager_appraisal_list())
        }
    }  
}
manager_appraisal.contextType = NewMainContext;
export default connect(mapStateToProps, mapDispatchToProps)(manager_appraisal);
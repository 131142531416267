import * as delallowance from '../action/delete_allowance';

const initialState ={
   isLoading:false,
   errors:null,
   data:null
}


const delete_allowance =(state=initialState,action) =>{
    switch(action.type) {
        case delallowance.DELALLOWANCE_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            };
        case delallowance.DELALLOWANCE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case delallowance.DELALLOWANCE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case delallowance.DELALLOWANCE_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default delete_allowance;


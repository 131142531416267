import { AgGridReact } from "ag-grid-react";
import React, { useMemo, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { NewMainContext } from "../../../context/NewMainContext";
import { IMLocalized } from "../../../language/IMLocalized";
import { checkboxCellRenderer } from '../../../func/checkboxCellRenderer'
import OverlayLoading from "../../loading_component/OverlayLoading";
import 'ag-grid-community/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import '../../../css/aggrid.css';
import BreadCrumbList from "../../breadcrumb/BreadCrumbList";

import { get_employee_list } from '../../../action/employee/get_employee_list';
import { update_employee_profile_mass, reset_update_employee_profile_mass } from "../../../action/employee/update_employee_profile_mass";

import { get_company_list } from '../../../action/company_list';

export default function EmployeeMassUpdateProfile(){

    const { addItem, action_toggle } = useContext(NewMainContext);
    const list = useSelector(state=>state.get_employee_list.data);
    const isLoading = useSelector(state=>state.get_employee_list.isLoading);
    const dispatch = useDispatch();
    // const success = useSelector(state=>state.update_employee_profile_mass.data);
    const dropdownlist = useSelector(state=>state.dropdown_list.data);
    const companylist = useSelector(state=>state.company_list.data);
    const isLoading1 = useSelector(state=>state.company_list.isLoading);

    const [ validateList, setValidateList] = useState([]);
    const [ rowData, setRowData] = useState([]);
    const [ step, setStep] = useState(0);

    const [ dummy, setDummy] = useState(1);

    useEffect(()=>{
        // if(success != null){
            dispatch(reset_update_employee_profile_mass());
            dispatch(get_employee_list());
            dispatch(get_company_list());
        // }
    },[dispatch,dummy])

    useEffect(()=>{

        const arr = list.map((item)=>{
            return {...item, edit_name: false, id :item.id }
        });

        companylist.map((item)=>item.name)

        setRowData(arr);
        setValidateList([]);

    },[list, step, dummy])

    const onCellValueChanged = (params) =>{
        if(params.oldValue !== params.newValue){
            const {id} = params.data;
            const { field } = params.colDef;
            var row = params.rowIndex;
            var rowNode = params.api.getDisplayedRowAtIndex(row);
            var column = params.column;
            if(field === 'name') {
                params.data.edit_name = true;
            }
            if(field === 'nationality') params.data.edit_nationality = true;
            if(field === 'email') {
                params.data.edit_email = true;
                if(params.newValue.includes('@') && params.newValue.includes('.com') || params.newValue===''){
                 }else {                    
                    alert('Incorrect email format')
                    params.api.undoCellEditing({rowNodes:[rowNode],columns:[column]})}
            }
            if(field === 'identification_number') 
                params.data.edit_identification_number = true;
                if(params.newValue.length!==9 && params.newValue!==''){
                    alert('IC Number/FIN Number must be 9 digits')
                    params.api.undoCellEditing({rowNodes:[rowNode],columns:[column]})
                }

            const detail = validateList.findIndex(item=>item.id === id);
            if(detail === -1){
                setValidateList(prevState=>[...prevState,params.data]);
            }
            else{
                setValidateList(prevState=>{
                    const arr = [...prevState];
                    arr[detail] = params.data;
                    return arr;
                })
            }
            params.api.flashCells({rowNodes:[rowNode],columns:[column]})
            params.api.refreshCells();
            
        }
    }

    const updateSelf = ()=>{
        const data = validateList.map(item=>item)
        dispatch(update_employee_profile_mass(data));
        setDummy((v)=>v + dummy)
    }

    const gridOptions={
        defaultColDef:{
            flex:1,
            minWidth:100,
            sortable:true,
            filter:true,
            resizable:true,
        },
    }

    const sideBar = useMemo(() => {
        return {
          toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
              },
            },
          ],
          defaultToolPanel: 'columns',
        };
      }, []);

      function countryFormatter(params) {
        const idd = dropdownlist?.countries?.filter((item)=> item.value === params.value).map((x)=>x.display_name)
        return idd
      }

    return(
        <>
        {isLoading || isLoading1 ? <OverlayLoading/> :
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title:IMLocalized('employee_mass_update_profile')}
                    ]}/>
                </Col>
            </Row>
            <Row className="mt-2 justify-content-center">
                <Col>
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-primary btn-sm" onClick={()=>setStep(step+1)}>{IMLocalized('clear')}</button>
                        <button className="btn btn-primary btn-sm" onClick={updateSelf}>{IMLocalized('update')}</button>
                    </div>
                    <div className="ag-theme-balham mt-2" style={{height:action_toggle ? 'calc(100vh - 250px)' :'calc(100vh - 214px)' , width:'100%' }}>
                        <AgGridReact
                            enableRangeSelection={true}
                            sideBar={sideBar}
                            gridOptions={gridOptions}
                            rowData={rowData}
                            onCellValueChanged={onCellValueChanged}
                            cellFlashDelay={999999}
                            undoRedoCellEditing={true}
                            columnDefs={[
                                { 
                                    headerName:IMLocalized('employee_number'),
                                    field:'employee_number',
                                    sortable:true,
                                    editable:false,
                                    lockVisible: true,
                                },
                                { 
                                    headerName:IMLocalized('salutation'),
                                    field:'salutation',
                                    sortable:true,
                                    editable:true,
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {
                                        values:dropdownlist?.salutation?.map((item)=>(item.value)),
                                      },
                                },
                                { 
                                    headerName:IMLocalized('name'),
                                    field:'name',
                                    sortable:true,
                                    editable:true,
                                    enableCellChangeFlash:true,
                                    undoRedoCellEditing: true,
                                },
                                { 
                                    headerName:IMLocalized('badge_number'),
                                    field:'badge_number',
                                    sortable:true,
                                    editable:true,
                                },
                                { 
                                    headerName:IMLocalized('email'),
                                    field:'email',
                                    sortable:true,
                                    editable:true,
                                    enableCellChangeFlash:true,
                                },
                                { 
                                    headerName:IMLocalized('nationality'),
                                    field:'nationality',
                                    sortable:true,
                                    editable:true,
                                    enableCellChangeFlash:true,
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {
                                        values:dropdownlist?.countries?.map((item)=>(item.value)),
                                      },
                                    valueFormatter:countryFormatter,
                                },
                                { 
                                    headerName:IMLocalized('id_type'),
                                    field:'id_type',
                                    sortable:true,
                                    editable:true,
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {
                                        values:dropdownlist?.s_g_identification_type?.map((item)=>(item.value)),
                                      },
                                },
                                { 
                                    headerName:IMLocalized('identification_number'),
                                    field:'identification_number',
                                    sortable:true,
                                    editable:true,
                                },
                                { 
                                    headerName:IMLocalized('contact_number'),
                                    field:'contact_number',
                                    sortable:true,
                                    editable:true,
                                },
                                { 
                                    headerName:IMLocalized('date_of_birth'),
                                    field:'date_of_birth',
                                    sortable:true,
                                    editable:true,
                                },
                                { 
                                    headerName:IMLocalized('gender'),
                                    field:'gender',
                                    sortable:true,
                                    editable:true,
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {
                                        values:dropdownlist?.gender?.map((item)=>(item.value)),
                                      },
                                },
                                { 
                                    headerName:IMLocalized('race'),
                                    field:'race',
                                    sortable:true,
                                    editable:true,
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {
                                        values:dropdownlist?.race?.map((item)=>(item.value)),
                                      },
                                },
                                { 
                                    headerName:IMLocalized('religion'),
                                    field:'religion',
                                    sortable:true,
                                    editable:true,
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {
                                        values:dropdownlist?.religion?.map((item)=>(item.value)),
                                      },
                                },
                                { 
                                    headerName:IMLocalized('relationship'),
                                    field:'relationship',
                                    sortable:true,
                                    editable:true,
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {
                                        values:dropdownlist?.relationship?.map((item)=>(item.value)),
                                      },
                                },
                            ]}
                        >
                        </AgGridReact>
                    </div>
                </Col>
            </Row>
        </Container>}
        </>
    )
}
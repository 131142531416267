import * as update_employee from '../../action/employee/update_employee_profile';


const initialState ={
    isLoading:false,
    errors:null,
    data:null
}


const update_employee_profile = (state=initialState,action) =>{
    switch(action.type) {
        case update_employee.UPDATEEMPLOYEE_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            };
        case update_employee.UPDATEEMPLOYEE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                errors:{},
                data:action.payload
            }
        case update_employee.UPDATEEMPLOYEE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data,
            }
        case update_employee.UNIQUECHECK_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default update_employee_profile;

import myaxios from '../../axios';


export const PAYROLLINSTANCEARRAY2_REQUEST = 'PAYROLLINSTANCEARRAY2_REQUEST';
export const PAYROLLINSTANCEARRAY2_SUCCESS = 'PAYROLLINSTANCEARRAY2_SUCCESS';
export const PAYROLLINSTANCEARRAY2_FAILURE = 'PAYROLLINSTANCEARRAY2_FAILURE';
export const PAYROLLINSTANCEARRAY2_RESET = 'PAYROLLINSTANCEARRAY2_RESET';

export const get_payroll_instance_array2 = (idarr) =>{
    return async dispatch=>{
        try{
            for(let x=0; x<idarr.length; x++){                
                dispatch({
                    type: 'PAYROLLINSTANCEARRAY2_REQUEST'
                });
                const response = await myaxios.get(`payroll/payroll/${idarr[x]}/`)
                
                const updateResponse = {...response}
                updateResponse.data[0].loop = x
                console.log(updateResponse)

                // if(x === idarr.length-1){
                dispatch({
                    type:'PAYROLLINSTANCEARRAY2_SUCCESS',
                    payload:updateResponse
                })
                // }
            }
        }
        catch(error){
                dispatch({
                    type:'PAYROLLINSTANCEARRAY2_FAILURE',
                    payload:error.response
                })
        }
    }
}

export const reset_payroll_instance_array2 = () => dispatch =>{
    dispatch({
        type:'PAYROLLINSTANCEARRAY2_RESET'
    });
}
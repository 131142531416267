import myaxios from '../axios';

export const GETGENERALLOGO_REQUEST = 'GETGENERALLOGO_REQUEST'; 
export const GETGENERALLOGO_SUCCESS = 'GETGENERALLOGO_SUCCESS';
export const GETGENERALLOGO_FAILURE = 'GETGENERALLOGO_FAILURE';

export const get_general_logo = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETGENERALLOGO_REQUEST'
        });

        myaxios.get('general/logo/',{responseType:'arraybuffer'})
        .then((response)=>{
            localStorage.setItem('serverStatus','')
            dispatch({
                type:'GETGENERALLOGO_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            if(error.message.includes('503')){
                localStorage.setItem('serverStatus',503)
                dispatch({
                    type:'GETGENERALLOGO_FAILURE',
                    payload:error
                })
            }else{
                localStorage.setItem('serverStatus','')
                dispatch({
                        type:'GETGENERALLOGO_FAILURE',
                        payload:error
                    })
            }
        })
    }


    // return dispatch=>{
    //     try {
    //     dispatch({
    //         type:'GETGENERALLOGO_REQUEST'
    //     });
    //     myaxios.get('general/logo/',{responseType:'arraybuffer'})
    //     .then((response)=>{
    //         dispatch({
    //             type:'GETGENERALLOGO_SUCCESS',
    //             payload:response
    //         })

    //         if (!response.ok) {
    //             if (response.status === 503) {
    //               // Redirect to the specific page for 503 error
    //               console.log('/503-error-page');
    //               return;
    //             }
    //             // Handle other error codes if needed
    //           }
    //     })
    //     }catch(error){
    //         dispatch({
    //             type:'GETGENERALLOGO_FAILURE',
    //             payload:error
    //         })
    //     }
    // }
}
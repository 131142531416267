import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';

export const WIZARDVALIDATEALLOWANCE_REQUEST ='WIZARDVALIDATEALLOWANCE_REQUEST';
export const WIZARDVALIDATEALLOWANCE_SUCCESS ='WIZARDVALIDATEALLOWANCE_SUCCESS';
export const WIZARDVALIDATEALLOWANCE_FAILURE ='WIZARDVALIDATEALLOWANCE_FAILURE';



export const wizard_validate_allowance = (data)=>{
    return dispatch=>{
        dispatch({
            type:'WIZARDVALIDATEALLOWANCE_REQUEST'
        });

        myaxios.post('company/allowance/array_validate/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'WIZARDVALIDATEALLOWANCE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'WIZARDVALIDATEALLOWANCE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('validate_failure')
            });
        })
    }
}
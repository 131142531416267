import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATESKIPOPTION_REQUEST = 'UPDATESKIPOPTION_REQUEST';
export const UPDATESKIPOPTION_SUCCESS = 'UPDATESKIPOPTION_SUCCESS';
export const UPDATESKIPOPTION_FAILURE = 'UPDATESKIPOPTION_FAILURE';


export const update_skip_option =(skip_check)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATESKIPOPTION_REQUEST'
        });

        myaxios.post('general/dashboard/',{
            data:skip_check
        })
        .then((response)=>{
            dispatch({
                type:'UPDATESKIPOPTION_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('save_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATESKIPOPTION_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('save_failure'),
            });
        })

    }
}
import * as detail from '../../action/user/claim_detail_get_claim_detail';

const initialState={
    isLoading:false,
    data:null,
    errors:null,
}

const claim_detail_get_claim_detail = (state=initialState,action)=>{
    switch(action.type){
        case detail.USERCLAIMDETAILGETCLAIMDETAIL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case detail.USERCLAIMDETAILGETCLAIMDETAIL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data,
            }
        case detail.USERCLAIMDETAILGETCLAIMDETAIL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case detail.USERCLAIMDETAILGETCLAIMDETAIL_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default claim_detail_get_claim_detail;
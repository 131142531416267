import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const VALIDATEAPPLYCLAIM_REQUEST = 'VALIDATEAPPLYCLAIM_REQUEST';
export const VALIDATEAPPLYCLAIM_SUCCESS = 'VALIDATEAPPLYCLAIM_SUCCESS';
export const VALIDATEAPPLYCLAIM_FAILURE = 'VALIDATEAPPLYCLAIM_FAILURE';
export const VALIDATEAPPLYCLAIM_RESET = 'VALIDATEAPPLYCLAIM_RESET'


export const validate_approve_apply_claim = (id,comment)=>{
    return dispatch=>{
        dispatch({
            type:'VALIDATEAPPLYCLAIM_REQUEST'
        });

        myaxios.post(`claim/apply/${id}/validate_approve/`,{
            comment:comment
        })
        .then((response)=>{
            myaxios.post(`claim/apply/${id}/approve/`,{
                comment:comment
            })
            .then((response)=>{
                dispatch({
                    type:'VALIDATEAPPLYCLAIM_SUCCESS',
                    payload:response
                });

                Toast.fire({
                    type: 'success',
                    title: IMLocalized('approve_success')
                });
            })
            .catch((error)=>{
                dispatch({
                    type:'APPROVEAPPLYCLAIM_FAILURE',
                    payload:error
                });
                
                ErrToast.fire({
                    type:'error',
                    title:IMLocalized('approve_failure')
                });
            })
        })
        .catch((error)=>{
            dispatch({
                type:'APPROVEAPPLYCLAIM_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('approve_failure')
            });
        })
    }
}

export const reset_validate_approve_apply_claim = () => dispatch =>{
    dispatch({
        type:'VALIDATEAPPLYCLAIM_RESET'
    });
}
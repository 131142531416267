import myaxios from '../axios';

export const GENERALDATERANGE_REQUEST = 'GENERALDATERANGE_REQUEST';
export const GENERALDATERANGE_SUCCESS = 'GENERALDATERANGE_SUCCESS';
export const GENERALDATERANGE_FAILURE = 'GENERALDATERANGE_FAILURE';

export const general_daterange=()=>{
    return dispatch=>{
        dispatch({
            type:'GENERALDATERANGE_REQUEST'
        });
        
        
        myaxios.post('general/daterange/')
        .then((response)=>{
            dispatch({
                type:'GENERALDATERANGE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GENERALDATERANGE_FAILURE',
                payload:error
            })
        })
    }
}
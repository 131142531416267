import * as deljobtitle from '../action/delete_jobtitle';

const initialState ={
    isLoading:false,
   errors:null,
   data:null
}


const delete_jobtitle = (state=initialState,action) =>{
    switch(action.type) {
        case deljobtitle.DELJOBTITLE_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            };
        case deljobtitle.DELJOBTITLE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case deljobtitle.DELJOBTITLE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case deljobtitle.DELJOBTITLE_RESET:
            return{
                ...state,
                errors:null,
                data:null
            }
        default:
            return state;
    }
}
export default delete_jobtitle;



import React from 'react';
import { connect } from 'react-redux';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';


const mapStateToProps=(state)=>({
    relationshiplist:state.dropdown_list.user_profile_relationship,
})

function ModalViewFamily(props){

    let relation_name = '';
    if(props.detail){
        const data = props.relationshiplist.find((item)=>item.value === props.detail.relation);
        if(data){
            relation_name = data.display;
        }
    }

    return(
        <Modal size="lg" isOpen={props.toggle}>
            <ModalHeader toggle={props.closeModal}>{IMLocalized('employee_family_other_contact')}</ModalHeader>
            <ModalBody>
                {props.detail &&
                <Row>
                    <Col>
                        <Table size="sm" className="table-bordered">
                            <tbody>
                                <tr>
                                    <td className="w-25">{IMLocalized('name')}</td>
                                    <td>{props.detail.name}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('relation')}</td>
                                    <td>{relation_name}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('contact_detail')}</td>
                                    <td>{props.detail.contact_detail ? props.detail.contact_detail : '---' }</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                }
            </ModalBody>
            <ModalFooter>
                <button className='btn btn-danger btn-sm' onClick={props.closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,null)(ModalViewFamily);
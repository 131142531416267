import * as managerlist from '../../action/approvals/get_user_manager_list';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const get_user_manager_list = (state=initialState,action)=>{
    switch(action.type){
        case managerlist.GETUSERMANAGERLIST_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case managerlist.GETUSERMANAGERLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case managerlist.GETUSERMANAGERLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_user_manager_list;
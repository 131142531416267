import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const ASSIGNLEAVEAPPROVAL_REQUEST = 'ASSIGNLEAVEAPPROVAL_REQUEST';
export const ASSIGNLEAVEAPPROVAL_SUCCESS = 'ASSIGNLEAVEAPPROVAL_SUCCESS';
export const ASSIGNLEAVEAPPROVAL_FAILURE = 'ASSIGNLEAVEAPPROVAL_FAILURE';
export const ASSIGNLEAVEAPPROVAL_RESET = 'ASSIGNLEAVEAPPROVAL_RESET';

export const assign_leave_approval = (employee,start_date,approval_group,approval_master)=>{
    return dispatch=>{
        dispatch({
            type:'ASSIGNLEAVEAPPROVAL_REQUEST'
        });

        myaxios.post('leave/employeeapproval/',{
            start_date:start_date,
            employee:employee,
            approval_group:approval_group,
            approval_master:approval_master
        })
        .then((response)=>{
            dispatch({
                type:'ASSIGNLEAVEAPPROVAL_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('assign_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'ASSIGNLEAVEAPPROVAL_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('assign_failure')
            });
        })
    }
}

export const reset_assign_leave_approval = () => dispatch =>{
    dispatch({
        type:'ASSIGNLEAVEAPPROVAL_RESET'
    });
}
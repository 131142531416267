import * as arrayrejectclaim from '../../action/claim/array_reject_apply_claim';

const initialState={
    isLoading:false,
    errors:[]
}

const array_reject_apply_claim = (state=initialState,action)=>{
    switch(action.type){
        case arrayrejectclaim.ARRAYREJECTCLAIM_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[]
            }
        case arrayrejectclaim.ARRAYREJECTCLAIM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case arrayrejectclaim.ARRAYREJECTCLAIM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default array_reject_apply_claim;
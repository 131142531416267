import myaxios from '../../axios';

export const LEAVEEMPNOSCHEME_REQUEST = 'LEAVEEMPNOSCHEME_REQUEST';
export const LEAVEEMPNOSCHEME_SUCCESS = 'LEAVEEMPNOSCHEME_SUCCESS';
export const LEAVEEMPNOSCHEME_FAILURE = 'LEAVEEMPNOSCHEME_FAILURE';

export const get_leave_employee_noscheme = () =>{
    return dispatch=>{
        dispatch({
            type:'LEAVEEMPNOSCHEME_REQUEST'
        });

        myaxios.get('leave/noscheme/')
        .then((response)=>{
            dispatch({
                type:'LEAVEEMPNOSCHEME_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'LEAVEENPNOSCHEME_FAILURE',
                payload:error
            })
        })
    }
}

import * as creategeneral from '../../action/report/create_general_aggrid_template';

const initialState={
    data:null,
    isLoading:false,
    errors:null
}

const create_general_aggrid_template = (state=initialState,action)=>{
    switch(action.type){
        case creategeneral.CREATEGENERALAGGRIDTEM_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case creategeneral.CREATEGENERALAGGRIDTEM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case creategeneral.CREATEGENERALAGGRIDTEM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        default:
            return state
    }
}
export default create_general_aggrid_template;
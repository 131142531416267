import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import blank_picture from '../../../images/blank_picture.png';
import { IMLocalized } from '../../../language/IMLocalized';


function InformationTable(props){

    return(
        <Row>
            <Col xl="6" lg="12" md="12 ">
                <Card>
                    <div className='d-flex flex-row cursor-pointer'>
                        <div className='p-0 w-30'>
                            <img alt="blank blank employeepayslip" src={props.emp_picture ?? blank_picture} className="img-thumbnail" style={{height:'100px',width:'100px'}}/>
                        </div>
                        <div className='p-2 w-70 border-left' style={{flexGrow:100}}>
                            <h4 className='mb-0 text-primary'>{IMLocalized('employee_number')} : {props.emp_payslip ? props.emp_payslip.employee.employee_number : ''} </h4>
                            <h3 >{props.emp_payslip ? props.emp_payslip.employee.name : ''}</h3>
                            <div className='d-flex justify-content-end align-items-end mr-2'>
                                <button className='btn btn-primary btn-sm' onClick={()=>props.regeneratePayslip()}>Regenerate</button>
                                <button className='btn btn-primary btn-sm' onClick={()=>props.getPrintBtn()}>Print</button>
                            </div>
                        </div>
                    </div>
                </Card>
            </Col>
        </Row>
    )
}
export default InformationTable;
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const EMPLOYEEPERMITARRAYSAVE_REQUEST = 'EMPLOYEEPERMITARRAYSAVE_REQUEST';
export const EMPLOYEEPERMITARRAYSAVE_SUCCESS = 'EMPLOYEEPERMITARRAYSAVE_SUCCESS';
export const EMPLOYEEPERMITARRAYSAVE_FAILURE = 'EMPLOYEEPERMITARRAYSAVE_FAILURE';


export const employee_permit_array_save = (data)=>{
    return dispatch=>{
        dispatch({
            type:'EMPLOYEEPERMITARRAYSAVE_REQUEST'
        });

        myaxios.post('employee/workpermit/array_create/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'EMPLOYEEPERMITARRAYSAVE_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'EMPLOYEEPERMITARRAYSAVE_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}
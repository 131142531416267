import * as letter from '../action/update_general_letter';

const initialState={
    data:null,
    isLoading:false,
    errors:null
}

const update_general_letter = (state=initialState,action)=>{
    switch(action.type){
        case letter.UPDATEGENLETTER_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case letter.UPDATEGENLETTER_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case letter.UPDATEGENLETTER_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case letter.UPDATEGENLETTER_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default update_general_letter;
import * as payroll_instance_array from '../../action/payroll/get_payroll_instance_array';

const initialState={
    data:[],
    errors:[],
    isLoading:false,
    start_date:'',
    end_date:'',
    payslip:[],
    payslip2:[],
    total:'',
    status:'',
}

const get_payroll_instance_array = (state=initialState,action)=>{
    switch(action.type){
        case payroll_instance_array.PAYROLLINSTANCEARRAY_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[],
                data:[]
            }
        case payroll_instance_array.PAYROLLINSTANCEARRAY_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
                payroll_date:action.payload.data.payroll_date,
                start_date:action.payload.data.start_date,
                end_date:action.payload.data.end_date,
                payslip:action.payload.data.payslips,
                payslip2:action.payload.data.payslips,
                total:action.payload.data.total_payable,
                status:action.payload.data.status,
                errors:[],
                loop:action.payload.data.loop
            }
        case payroll_instance_array.PAYROLLINSTANCEARRAY_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case payroll_instance_array.PAYROLLINSTANCEARRAY_RESET:
            return{
                ...state,
                errors:null,
                data:[],
            }
        default:
            return state;
    }
}
export default get_payroll_instance_array;

import React from 'react';
import { Modal, ModalHeader, Table ,Row,Col,ModalBody, ModalFooter} from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';


function ModalViewCompanyBank(props){

    return(
        <Modal isOpen={props.toggle} size="lg">
            {props.data &&
            <>
            <ModalHeader toggle={props.closeModal}>{IMLocalized('bank_detail')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <Table  size="sm" className="align-items-center table-bordered">
                            <tbody>
                                <tr>
                                    <td className='td-w-30'>{IMLocalized('bank')}</td>
                                    <td>{props.data.bank}</td>
                                </tr>
                                <tr>
                                    <td className='td-w-30'>{IMLocalized('account_number')}</td>
                                    <td>{props.data.account_number}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button className='btn btn-danger btn-sm' onClick={props.closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
            </>}
        </Modal>
    )
}
export default  ModalViewCompanyBank;
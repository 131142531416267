export const USERLEAVEAPPROVALGROUP_REQUEST = 'USERLEAVEAPPROVALGROUP_REQUEST';
export const USERLEAVEAPPROVALGROUP_SUCCESS = 'USERLEAVEAPPROVALGROUP_SUCCESS';
export const USERLEAVEAPPROVALGROUP_FAILURE = 'USERLEAVEAPPROVALGROUP_FAILURE';


export const user_leave_approval_group = (data)=>{
    return dispatch=>{
        try{
            dispatch({
                type:'USERLEAVEAPPROVALGROUP_SUCCESS',
                payload:data
            });
        }
        catch(error){
            dispatch({
                type:'USERLEAVEAPPROVALGROUP_FAILURE',
                payload:error
            })
        }
        
    }
}
import myaxios from '../axios';

export const GENERALGROUP_REQUEST = 'GENERALGROUP_FAILURE';
export const GENERALGROUP_SUCCESS = 'GENERALGROUP_SUCCESS';
export const GENERALGROUP_FAILURE = 'GENERALGROUP_FAILURE';

export const general_group=()=>{
    return dispatch=>{
        dispatch({
            type:'GENERALGROUP_REQUEST'
        });

        myaxios.get('general/group/')
        .then((response)=>{
            dispatch({
                type:'GENERALGROUP_SUCCESS',
                payload:response
            },localStorage.setItem('roles',JSON.stringify(response.data)))
        })
        .catch((error)=>{
            dispatch({
                type:'GENERALGROUP_FAILURE',
                payload:error
            })
        })
    }
}
import * as withoutcontract from '../../action/employee/get_employee_list_withoutcontract';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const get_employee_list_withoutcontract = (state=initialState,action)=>{
    switch(action.type){
        case withoutcontract.EMPLISTWITHOUTCONTRACT_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case withoutcontract.EMPLISTWITHOUTCONTRACT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case withoutcontract.EMPLISTWITHOUTCONTRACT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_employee_list_withoutcontract;
import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";



export default function BreadCrumbList({list}){




    return(
        <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
            {list.map((item,index)=>{
                if(item.active){
                    return <BreadcrumbItem active key={index}>{item.title}</BreadcrumbItem>
                }
                else{
                    return <BreadcrumbItem key={index}><a href="/#" onClick={item.onClick}>{item.title}</a></BreadcrumbItem>
                }
            })}
        </Breadcrumb>
    )
}
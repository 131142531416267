import * as self_payroll from '../../action/user/get_self_payroll';

const initialState={
    data:[],
    errors:[],
    isLoading:false
}

const get_self_payroll = (state=initialState,action)=>{
    switch(action.type){
        case self_payroll.GETSELFPAYROLL_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case self_payroll.GETSELFPAYROLL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case self_payroll.GETSELFPAYROLL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_self_payroll;
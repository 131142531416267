import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const NEW_DELEMPRELATION_REQUEST = 'NEW_DELEMPRELATION_REQUEST';
export const NEW_DELEMPRELATION_SUCCESS = 'NEW_DELEMPRELATION_SUCCESS';
export const NEW_DELEMPRELATION_FAILURE = 'NEW_DELEMPRELATION_FAILURE';
export const NEW_DELEMPRELATION_RESET = 'NEW_DELEMPRELATION_RESET';

export const delete_employee_relationship = (data)=>{
    return dispatch=>{
        
        if(data.length !==0){
            dispatch({
                type:'NEW_DELEMPRELATION_REQUEST'
            });

            for(let i =0; i<data.length ; i++){
                const { id }  = data[i];

                return myaxios.delete(`employee/relationship/${id}/`)
                .then((response)=>{
                    dispatch({
                        type:'NEW_DELEMPRELATION_SUCCESS',
                        payload:response
                    });

                    Toast.fire({
                        type: 'success',
                        title: IMLocalized('delete_success')
                    });
                })
                .catch((error)=>{
                    dispatch({
                        type:'NEW_DELEMPRELATION_FAILURE',
                        payload:error
                    });

                    ErrToast.fire({
                        type:'error',
                        title:IMLocalized('delete_failure')
                    });
                })
            }
        }
    }
}

export const reset_delete_employee_relationship= ()=> dispatch=>{
    dispatch({
        type:'NEW_DELEMPRELATION_RESET'
    })
}
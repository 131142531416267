import React from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { IMLocalized } from "../../../language/IMLocalized";


export default function ModalViewLeaveScheme({ toggle, closeModal, id}){

    const list = useSelector(state=>state.get_leave_scheme_list.data);


    const detail = id !== null ? list.find(el => el.id === id) : null;
    
    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('view_leave_scheme')}</ModalHeader>
            <ModalBody>
                {detail && 
                detail.entitlement.map((item)=>{
                    const { age_tier, amount_tier} = item;
                    return(
                        <>
                        <div className="mt-2">
                            <h4>{item.leave_setting_name}</h4>
                            <Table size="sm" className="align-items-center table-bordered" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th style={{width:'50%'}}>{IMLocalized('name')}</th>
                                        <th style={{width:'50%'}}>{IMLocalized('leave_scheme_amount')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{IMLocalized('max_encash_limit')}</td>
                                        <td>{item.max_encash_limit}</td>
                                    </tr>
                                    <tr>
                                        <td>{IMLocalized('year_carry_over_limit')}</td>
                                        <td>{item.year_carry_over_limit}</td>
                                    </tr>
                                    <tr>
                                        <td>{IMLocalized('max_carry_over_limit')}</td>
                                        <td>{item.max_carry_over_limit}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Table size="sm" className="align-items-center table-bordered" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th style={{width:'50%'}}>{IMLocalized('year_in_company')}</th>
                                        <th style={{width:'50%'}}>{IMLocalized('leave_entitlement_amount')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {age_tier.map((el,i)=>{   
                                        return(
                                            <tr key={i}>
                                                <td>{el}</td>
                                                <td>{amount_tier[i]}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                        <hr className="m-1" />
                        </>
                    )
                })}
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
import React from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row, Table,ModalFooter } from "reactstrap";
import { IMLocalized } from "../../../language/IMLocalized";


function ModalCalculate(props){
     
    return(
        <Modal isOpen={props.toggle} size="lg" >
            <ModalHeader toggle={props.closeModal}>{IMLocalized('payslip_gross_month_info')}</ModalHeader>
            {props.detail &&
            <ModalBody>
                <Row>
                    <Col>
                        <Table size="sm" className="align-items-center table-bordered">
                            <tbody>
                                <tr>
                                    <td>{IMLocalized('payslip_gross_month')}</td>
                                    <td><span>{IMLocalized('total')}  <i className="fas fa-times"/>  {IMLocalized('payslip_actual_work_days')} <i className="fas fa-divide " style={{fontSize:'12px'}}></i>  {IMLocalized('payslip_month_total_work_days')}</span></td>
                                </tr>
                                <tr>
                                    <td>{IMLocalized('payslip_gross_month')}</td>
                                    <td>{props.detail.gross_month} <i className="fas fa-times"/> {props.detail.actual_work_days} <i className="fas fa-divide" style={{fontSize:'12px'}}></i> {props.detail.month_total_work_days}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </ModalBody>}
            <ModalFooter>
                <button className="btn btn-danger btn-sm" onClick={props.closeModal} >{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
export default ModalCalculate;
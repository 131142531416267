import * as events from '../../action/dashboard/get_key_events';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}



const get_key_events = (state=initialState,action)=>{
    switch(action.type){
        case events.GETKEYEVENTS_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case events.GETKEYEVENTS_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,

            }
        case events.GETKEYEVENTS_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_key_events;
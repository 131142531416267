import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const NEW_UPDATEEMPPERMIT_REQUEST = 'NEW_UPDATEEMPPERMIT_REQUEST';
export const NEW_UPDATEEMPPERMIT_SUCCESS = 'NEW_UPDATEEMPPERMIT_SUCCESS';
export const NEW_UPDATEEMPPERMIT_FAILURE = 'NEW_UPDATEEMPPERMIT_FAILURE';
export const NEW_UPDATEEMPPERMIT_RESET = 'NEW_UPDATEEMPPERMIT_RESET';

export const update_employee_workpermit = (data,employee,identification)=>{
    return dispatch=>{
        dispatch({
            type:'NEW_UPDATEEMPPERMIT_REQUEST'
        });

        myaxios.patch(`employee/employees/${employee}/`,{
            identification_number:identification
        })
        .then(()=>{

            const {permit_type, permit, man_year_entitlement,expiry_date,issued_date,application_date,id} = data[0];
            
            myaxios.patch(`employee/workpermit/${id}/`,{
                permit:permit,
                permit_type:permit_type,
                man_year_entitlement:man_year_entitlement,
                expiry_date:expiry_date,
                application_date:application_date,
                issued_date:issued_date
            })
            .then((response)=>{
                dispatch({
                    type:'NEW_UPDATEEMPPERMIT_SUCCESS',
                    payload:response
                });

                Toast.fire({
                    type: 'success',
                    title: IMLocalized('update_success')
                });
            })
            .catch((error)=>{
                dispatch({
                    type:'NEW_UPDATEEMPPERMIT_FAILURE',
                    payload:error
                });

                ErrToast.fire({
                    type:'error',
                    title:IMLocalized('update_failure')
                });
            })
        })
        .catch((error)=>{
            dispatch({
                type:'NEW_UPDATEEMPPERMIT_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}

export const reset_update_employee_workpermit = () => dispatch =>{
    dispatch({
        type:'NEW_UPDATEEMPPERMIT_RESET'
    });
}

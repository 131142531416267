import * as validate_approve from '../../action/claim/validate_approve_apply_claim';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const validate_approve_apply_claim = (state=initialState,action )=>{
    switch(action.type){
        case validate_approve.VALIDATEAPPLYCLAIM_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case validate_approve.VALIDATEAPPLYCLAIM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case validate_approve.VALIDATEAPPLYCLAIM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case validate_approve.VALIDATEAPPLYCLAIM_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default validate_approve_apply_claim;
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { isFirstColumn } from '../../ag-grid_component/isFirstColumn';
import { probationDateRenderer } from '../../ag-grid_component/probationDateRenderer';
import { IMLocalized } from '../../../language/IMLocalized';
import ModalExtendProbation from './modal/ModalExtendProbation';
import OverlayLoading from '../../loading_component/overlay_loading';
import { popError } from '../../../func/popError';
import Swal from 'sweetalert2';
import {approve_employee_probation, reset_approve_employee_probation} from '../../../action/approve_employee_probation';
import { get_employee_list } from '../../../action/employee/get_employee_list';
import { get_employee_probation_list} from '../../../action/get_employee_probation_list';
import ButtonProbationReport from './button/ButtonProbationReport';
import { NewMainContext } from '../../../context/NewMainContext';
import BreadCrumbList from '../../breadcrumb/BreadCrumbList';

const gridOptions={
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
    suppressRowClickSelection:true,
    defaultColDef:{
        resizable:true,
        sortable:true,
        filter:true
    }
}


function ProbationList(){
    
    const probationlist = useSelector(state=>state.get_employee_probation_list.data);
    const isLoading = useSelector(state=>state.get_employee_probation_list.isLoading);
    const approve_success = useSelector(state=>state.approve_employee_probation.data);
    const isLoading1 = useSelector(state=>state.approve_employee_probation.isLoading);
   
    const [extend_id,setExtendID] = useState(null);
    const [extend_toggle,setExtendToggle] = useState(false);
    const { action_toggle, addItem } = useContext(NewMainContext);
    const dispatch = useDispatch();

    useEffect(()=>{
        if( approve_success !== null){
            dispatch(reset_approve_employee_probation());
            dispatch(get_employee_list());
            dispatch(get_employee_probation_list());
        }
    },[approve_success,dispatch])

    const extendRenderer = useCallback(function(params){
        const id = params.data.id;
        return <span className='text-primary cursor-pointer'  onClick={()=>{setExtendID(id);setExtendToggle(true)}}>{IMLocalized('extend')}</span>
    },[])

    const columnDefs =[
        {
            headerName:IMLocalized('select'),
            field:'',
            width:150,
            pinned:'left',
            headerCheckboxSelection: isFirstColumn,
            checkboxSelection: isFirstColumn,
            headerCheckboxSelectionFilteredOnly:true,
            lockPosition: true
        },
        {
            headerName:IMLocalized('employee_id'),
            field:'employee_number',
            filter:true,
        },
        {
            headerName:IMLocalized('employee_name'),
            field:'name',
            filter:true,
        },
        {
            headerName:IMLocalized('probation_end_date'),
            field:'probation_end_date',
            cellRenderer:probationDateRenderer,

        },
        {
            headerName:IMLocalized('extend'),
            field:'',
            cellRendererFramework:extendRenderer
        }
    ]

    const toApprove=()=>{
        const data = gridOptions.api.getSelectedRows();
        if(data.length !== 0){
            Swal.fire({
                title:IMLocalized('approve_probation_confirmation'),
                type:'warning',
                showCancelButton:true,
                showConfirmButton:true,
                confirmButtonText:IMLocalized('yes!'),
                cancelButtonText:IMLocalized('no!')
            })
            .then((result=>{
                if(result.value){
                    let arr = [];
                    for(let i=0;i<data.length;i++){
                        const id = data[i].id;
                        arr.push(id)
                    }
                    dispatch(approve_employee_probation(arr));
                }
            }))
        }
        else{
            popError(IMLocalized('select_employee_to_approve'))
        }
    }

    return (
        <>
        {isLoading || isLoading1 ? <OverlayLoading/> :
        <Container fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'> 
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: false, title:IMLocalized('employee'), onClick:()=>addItem('7','employee_list')},
                        { active: true, title:IMLocalized('employee_probation_list')}
                    ]}/>
                </Col>
            </Row>
            <Row className='mt-2 d-flex justify-content-center'>
                <Col lg="8">
                    <div className='d-flex justify-content-between'>
                        <h3 className='mb-0'>{IMLocalized('employee_probation_list')}</h3>
                        <div>
                        {probationlist.length !== 0 &&
                        <>
                            <button onClick={()=>toApprove()} className='btn btn-primary btn-sm'>{IMLocalized('approve')}</button>
                            <ButtonProbationReport rowData={probationlist} />
                        </>}
                        </div>
                    </div>
                    <div className='ag-theme-balham mt-2' style={{height:action_toggle ? 'calc(100vh - 250px)' : 'calc(100vh - 214px)' ,width:'100%' }}>
                        <AgGridReact
                            columnDefs={columnDefs}
                            rowData={probationlist}
                            gridOptions={gridOptions}
                            suppressDragLeaveHidesColumns={true}
                            suppressSizeToFit={true}
                            suppressColumnMoveAnimation={false}
                            overlayNoRowsTemplate={IMLocalized('no_data')}
                        />
                    </div>
                </Col>
            </Row>
            <ModalExtendProbation toggle={extend_toggle} id={extend_id} closeModal={()=>setExtendToggle(false)}  />
        </Container>}
        </>
    )
}
export default ProbationList;
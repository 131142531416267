import * as update_currency from '../action/update_general_currency';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const update_general_currency = (state = initialState,action)=>{
    switch(action.type){
        case update_currency.UPDATEGENERALCURRENCY_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null
            }
        case update_currency.UPDATEGENERALCURRENCY_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case update_currency.UPDATEGENERALCURRENCY_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case update_currency.UPDATEGENERALCURRENCY_RESET:
            return{
                ...state,
                errors:null,
                data:null
            }
        default:
            return state;
    }
}
export default update_general_currency;
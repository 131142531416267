import * as applyclaimlist from '../../action/claim/get_apply_claim_list';

const initialState={
    isLoading:false,
    errors:null,
    data:[]
}

const get_apply_claim_list =(state=initialState,action)=>{
    switch(action.type){
        case applyclaimlist.GETAPPLYCLAIMLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null
            }
        case applyclaimlist.GETAPPLYCLAIMLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case applyclaimlist.GETAPPLYCLAIMLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_apply_claim_list;


export const emp_get_apply_claim_list_detail = (state,emp,claim_type)=>{
    const { data } = state.get_apply_claim_list;
    return data.filter(item=>item.employee === emp).filter(item=>item.claim_type === claim_type).sort((a,b)=> new Date(b.created_date) - new Date(a.created_date));
}

export const emp_get_apply_claim_list = (state,emp)=>{
    const { data } = state.get_apply_claim_list;
    return data.filter(item=>item.employee === emp).sort((a,b)=> new Date(b.created_date) - new Date(a.created_date));
}
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const LEAVEASSIGNSCHEME2_REQUEST = 'LEAVEASSIGNSCHEME2_REQUEST';
export const LEAVEASSIGNSCHEME2_SUCCESS = 'LEAVEASSIGNSCHEME2_SUCCESS';
export const LEAVEASSIGNSCHEME2_FAILURE = 'LEAVEASSIGNSCHEME2_FAILURE';


export const leave_assign_scheme2 = (scheme,employees,full_proration,year)=>{
    return dispatch=>{
        dispatch({
            type:'LEAVEASSIGNSCHEME2_REQUEST'
        });

        myaxios.post('leave/assign/scheme/',{
            scheme:scheme,
            employees:employees,
            full_proration:full_proration,
            year:year
        })
        .then((response)=>{
            dispatch({
                type:'LEAVEASSIGNSCHEME2_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type:'success',
                title:IMLocalized('assign_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'LEAVEASSIGNSCHEME2_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('assign_failure')
            });
        })
    }
}
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormFeedback, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row , Input } from "reactstrap";
import { IMLocalized } from "../../../../language/IMLocalized";
import PasswordChecklist from 'react-password-checklist';
import { add_employee_role,reset_add_employee_role } from "../../../../action/employee/add_employee_role";

function ModalAssignRole({ toggle,onSuccess, closeModal, id, name }){

    const dispatch = useDispatch();
    const success = useSelector(state=>state.add_employee_role.data);
    const errors = useSelector(state=>state.add_employee_role.errors);
    const isLoading = useSelector(state=>state.add_employee_role.isLoading);

    const [new_pass,setNewPass] = useState('');
    const [err_new_pass,setErrNewPass] = useState('');
    const [confirm_pass,setConfirmPass] = useState('');
    const [err_confirm_pass,setErrConfirmPass] = useState('');
    const [is_valid,setValid] = useState(false);
    const [role_hr,setHr] = useState(false);
    const [role_manager,setManager] = useState(false);
    const [role_leave,setLeave] = useState(false);
    const [role_claim,setClaim] = useState(false);

    useEffect(()=>{
        if(toggle){
            setNewPass('');
            setErrNewPass('');
            setErrConfirmPass('');
            setConfirmPass('');
            setHr(false);
            setValid(false);
            setManager(false);
            setLeave(false);
            setClaim(false);
        }
    },[toggle])

    useEffect(()=>{
        if(toggle && success !== null ){
            onSuccess();
            dispatch(reset_add_employee_role());
        }
    },[success,onSuccess,toggle,dispatch])

    useEffect(()=>{
        if(toggle && errors != null){
            if(errors.hasOwnProperty('password')){
                const word = errors['password'].toString();
                setErrNewPass(word);
            }
            dispatch(reset_add_employee_role());
        }
    },[errors,toggle,dispatch])


    const addRole = () =>{
        if(new_pass !== '' && confirm_pass !== ''){
            if(is_valid === true){
                dispatch(add_employee_role(id,new_pass,role_hr,role_leave,role_claim,role_manager));
            }
        }
        else{
            if(new_pass === ''){
                setErrNewPass(IMLocalized('new_password_required'));
            }
            if(confirm_pass === ''){
                setErrConfirmPass(IMLocalized('confirm_password_required'));
            }
        }
    }

    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('add_user')} - {name} </ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="new_pass">{IMLocalized('new_password')}</label>
                            <Input id="new_pass" size="sm" type="password" name="new_pass" value={new_pass} onChange={(e)=>{setNewPass(e.target.value); setErrNewPass('')}} invalid={err_new_pass.length >0} />
                            <FormFeedback>{err_new_pass}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="confirm_pass">{IMLocalized('confirm_password')}</label>
                            <Input id="confirm_pass" size="sm" type="password" name="confirm_pass" value={confirm_pass} onChange={(e)=>{setConfirmPass(e.target.value); setErrConfirmPass('')}} invalid={err_confirm_pass.length > 0} />
                            <FormFeedback>{err_confirm_pass}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <PasswordChecklist
                    rules={['minLength','number','capital','match','lowercase','notEmpty','specialChar']}
                    minLength={8}
                    value={new_pass}
                    valueAgain={confirm_pass}
                    onChange={(isValid)=>setValid(isValid)}
                    messages={{
                        minLength: IMLocalized('password_has_8_char'),
                        number: IMLocalized('password_has_a_number'),
                        capital: IMLocalized('password_has_a_capital_letter'),
                        match:IMLocalized('password_match'),
                        notEmpty:IMLocalized('password_not_empty'),
                        lowercase:IMLocalized('password_has_a_lowercase_letter'),
                        specialChar:IMLocalized('password_has_special')
                    }}
                />
                <Row className="pt-2">
                    <Col lg="6">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="assign_role_hrmeh" name='role_hr' className="custom-control-input" checked={role_hr} onChange={(e)=>setHr(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="assign_role_hrmeh">{IMLocalized('role_hr')}?</label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="assign_role_managermeh" name='role_manager' className="custom-control-input" checked={role_manager} onChange={(e)=>setManager(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="assign_role_managermeh">{IMLocalized('role_manager')}?</label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="assign_role_leaveofficermeh" name='role_leave_officer' className="custom-control-input" checked={role_leave} onChange={(e)=>setLeave(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="assign_role_leaveofficermeh">{IMLocalized('role_leave_officer')}?</label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="assign_role_claimofficermeh" name='role_claim_officer' className="custom-control-input" checked={role_claim} onChange={(e)=>setClaim(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="assign_role_claimofficermeh">{IMLocalized('role_claim_officer')}?</label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="assign_role_usermeh" name='role_user' className="custom-control-input" checked={true} disabled={true} />
                            <label className="custom-control-label" htmlFor="assign_role_usermeh">{IMLocalized('role_user')}?</label>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ?<button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                <button className="btn btn-primary btn-sm" onClick={addRole}>{IMLocalized('add')}</button>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
export default ModalAssignRole;
import React, { useEffect, useState } from "react";
import a_line from '../../images/a_line.png';
import Swal from 'sweetalert2';
import {get_general_logo} from '../../action/get_general_logo';
import {get_iras_detail} from '../../action/iras/get_iras_detail';
import { submit_iras } from '../../action/iras/submit_iras';
import {Col,Row,Container} from 'reactstrap';
import {IMLocalized,init} from '../../language/IMLocalized';
import OverlayLoading from '../loading_component/overlay_loading';
import { useDispatch, useSelector } from "react-redux";
import {get_employee_contract_status} from '../../action/employee/get_employee_contract_status';


export default function IrasPage(){

    const logo = useSelector(state=>state.get_general_logo.data);
    const item = useSelector(state=>state.get_iras_detail.data);
    const success = useSelector(state=>state.submit_iras.data);
    const isLoading1 = useSelector(state=>state.submit_iras.isLoading);
    const isLoading = useSelector(state=>state.get_iras_detail.isLoading);
    const dispatch = useDispatch();
    const [id, setId] = useState('');


    useEffect(()=>{
       
        if(logo.length !== 0){
            const { data } = logo;
            const { byteLength } = data;
            if(byteLength === 0){
                document.getElementById("favicon").href = a_line;
            }
            else{
                const url =  process.env.REACT_APP_API_URL;
                document.getElementById("favicon").href = `https://${url}/api/general/logo/`;
            }
        }
        
    },[logo])

    useEffect(()=>{
        document.title = process.env.REACT_APP_TITLE;
        dispatch(get_general_logo());
        const outside = localStorage.getItem('reoutside');
        const side = localStorage.getItem('reside');

        if(outside === null || side === null){
            window.location.href='/#/'
        }
        else{
            const id = localStorage.getItem('irasdetail_id');
            setId(id);
            dispatch(get_iras_detail(id));
        }

    },[dispatch])

    useEffect(()=>{
        if(success != null){
            Swal.fire({
                type:'success',
                title:IMLocalized('submitted_iras'),
                confirmButtonText:IMLocalized('ok!')
            })
            .then(()=>{
                localStorage.removeItem('reoutside');
            localStorage.removeItem('reside');
            window.location.href="/#/";
            })
        }
    },[success])

    const goBack = ()=>{
        localStorage.removeItem('reoutside');
        localStorage.removeItem('reside');
        window.location.href="/#/";
    }

    const submitIras=()=>{
        dispatch(submit_iras(id));
    }

    init(localStorage.getItem('language'));
    return(
        <>
        {isLoading ? <OverlayLoading/> :
        <div className="login2-block">
            <Container>
                <Row className="mt-3 d-flex justify-content-center">
                    <Col lg="8" className="container2 login2-sec" style={{height:'500px'}}>
                        <div className="d-flex justify-content-center">
                            <h2 >{IMLocalized('proceed_iras')}</h2>
                        </div>

                        <div className="p-4" style={{borderStyle:'solid'}}>
                            {item != null &&
                            <>
                            <div className="d-flex">
                                <h3 style={{width:'300px'}}>{IMLocalized('company')} </h3><span>{item.EmployerName}</span>
                            </div>
                            <div className="d-flex" >
                                <h3 style={{width:'300px'}}>{IMLocalized('year')}</h3><span>{item.BasisYear}</span>
                            </div>
                            <div className="d-flex">
                                <h3 style={{width:'300px'}}>{IMLocalized('number_of_employees')}</h3><span>{item.NoOfRecords}</span>
                            </div>
                            </>}
                            <div className="pt-2 d-flex align-items-end">
                                {isLoading1 ? <button className="btn btn-primary"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button> :
                                <button className="btn btn-primary" onClick={submitIras}>{IMLocalized('submit')}</button>}
                                <button className="btn btn-danger" onClick={goBack} >{IMLocalized('cancel_transaction')}</button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>}
        </>
    )
}
import myaxios from '../axios';

export const GENERALCURRENCY_REQUEST = 'GENERALCURRENCY_REQUEST';
export const GENERALCURRENCY_SUCCESS = 'GENERALCURRENCY_SUCCESS';
export const GENERALCURRENCY_FAILURE = 'GENERALCURRENCY_FAILURE';

export const general_currency = ()=>{
    return dispatch=>{
        dispatch({
            type:'GENERALCURRENCY_REQUEST'
        });
        
        
        myaxios.get('general/currency/')
        .then((response)=>{
            dispatch({
                type:'GENERALCURRENCY_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GENERALCURRENCY_FAILURE',
                payload:error
            })
        })
    }
}
import * as updateempaddress from '../../action/employee/update_employee_address';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const update_employee_address = (state=initialState,action)=>{
    switch(action.type){
        case updateempaddress.NEW_UPDATEEMPADDRESS_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case updateempaddress.NEW_UPDATEEMPADDRESS_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updateempaddress.NEW_UPDATEEMPADDRESS_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case updateempaddress.NEW_UPDATEEMPADDRESS_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default update_employee_address;
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const REGENERATEPAYROLL_REQUEST = 'REGENERATEPAYROLL_REQUEST';
export const REGENERATEPAYROLL_SUCCESS = 'REGENERATEPAYROLL_SUCCESS';
export const REGENERATEPAYROLL_FAILURE = 'REGENERATEPAYROLL_FAILURE';


export const regenerate_payroll =(id)=>{
    return dispatch=>{
        dispatch({
            type:'REGENERATEPAYROLL_REQUEST'
        });

        myaxios.post(`payroll/payroll/${id}/Regenerate/`)
        .then((response)=>{
            dispatch({
                type:'REGENERATEPAYROLL_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('regenerate_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'REGENERATEPAYROLL_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('regenerate_failure')
            });
        })
    }
}
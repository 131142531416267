import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import { create_joblevel, reset_create_joblevel } from '../../../action/create_joblevel';
import { get_additional_setting } from '../../../action/additional_setting';

function ModalCreateJoblevel({toggle,closeModal}){

    const isLoading = useSelector(state=>state.create_joblevel.isLoading);
    const success = useSelector(state=>state.create_joblevel.data);
    const errors = useSelector(state=>state.create_joblevel.errors);
    const dispatch = useDispatch();
    const [name,setName] = useState('');
    const [err_name,setErrName] = useState('');

    useEffect(()=>{
        if(toggle){
            setErrName('');
            setName('');
        }
    },[toggle])

    useEffect(()=>{
        if( toggle && success !== null ){
            dispatch(get_additional_setting());
            dispatch(reset_create_joblevel());
            closeModal();
        }
    },[success,closeModal,toggle,dispatch])

    useEffect(()=>{
        if( toggle && errors !== null ){
            if(errors.hasOwnProperty('name')){
                setErrName(errors['name'].toString());
            }
            dispatch(reset_create_joblevel());
        }
    },[errors,toggle,dispatch])

    const addJoblevel=()=>{
        if(name !== ''){
            dispatch(create_joblevel(name));
        }
        else{
            setErrName(IMLocalized('joblevel_is_required'))
        }
    }
    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={()=>closeModal()}>{IMLocalized('add_joblevel')}</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <label className='form-control-label' htmlFor='name'>{IMLocalized('name')}</label>
                    <Input type="text" id="name" size="sm" value={name} onChange={(e)=>{setName(e.target.value); setErrName('')}} invalid={err_name.length > 0}  />
                    <FormFeedback>{err_name}</FormFeedback>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                <button className='btn btn-primary btn-sm' onClick={()=>addJoblevel()}>{IMLocalized('add')}</button>
                <button className='btn btn-danger btn-sm' onClick={()=>closeModal()}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
export default ModalCreateJoblevel;
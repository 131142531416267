import * as appprobation from '../action/approve_employee_probation';

const initialState={
    isLoading:false,
    errors:null,
    data:null,
}

const approve_employee_probation = (state=initialState,action)=>{
    switch(action.type){
        case appprobation.APPEMPPROBATION_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null
            }
        case appprobation.APPEMPPROBATION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case appprobation.APPEMPPROBATION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case appprobation.APPEMPPROBATION_RESET:
            return{
                ...state,
                data:null,
                errors:null,
            }
        default:
            return state;
    }
}
export default approve_employee_probation;
import * as otpcheck from '../action/otp_check';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const otp_check = (state=initialState,action)=>{
    switch(action.type){
        case otpcheck.OTPCHECK_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case otpcheck.OTPCHECK_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
            }
        case otpcheck.OTPCHECK_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default otp_check;
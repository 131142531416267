import * as year_balance from '../../action/report/get_year_leave_balance';

const initialState={
    data:[],
    errors:[],
    isLoading:false,
    data1:[]
}

const get_year_leave_balance = (state=initialState,action)=>{
    switch(action.type){
        case year_balance.GETYEARLEAVEBALANCE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:[]
            }
        case year_balance.GETYEARLEAVEBALANCE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
                data1:action.payload,
            }
        case year_balance.GETYEARLEAVEBALANCE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_year_leave_balance;
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const SUBMITAPPRAISAL_REQUEST = 'SUBMITAPPRAISAL_REQUEST';
export const SUBMITAPPRAISAL_SUCCESS = 'SUBMITAPPRAISAL_SUCCESS';
export const SUBMITAPPRAISAL_FAILURE = 'SUBMITAPPRAISAL_FAILURE';
export const SUBMITAPPRAISAL_RESET = 'SUBMITAPPRAISAL_RESET';


export const submit_appraisal =(id,employee,response)=>{
    return dispatch=>{
        dispatch({
            type:'SUBMITAPPRAISAL_REQUEST'
        })

        myaxios.post(`forms/groups/${id}/submit/`,{
            employee:employee,
            response:response
        })
        .then((response)=>{
            dispatch({
                type:'SUBMITAPPRAISAL_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('submit_appraisal_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'SUBMITAPPRAISAL_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('submit_appraisal_failure')
            });
        })
    }
}

export const remove_submit_appraisal = () => dispatch =>{
    dispatch({
        type:'SUBMITAPPRAISAL_RESET'
    });
}
import * as apply from '../../action/employee/employee_user_apply_leave';

const initialState={
    data:[],
    isLoading:false,
    errors:[]
}

const employee_user_apply_leave = (state=initialState,action)=>{
    switch(action.type){
        case apply.EMPUSERAPPLYLEAVE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[]
            }
        case apply.EMPUSERAPPLYLEAVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                errors:[],
                data:action.payload
            }
        case apply.EMPUSERAPPLYLEAVE_FAILURE:
            if(Object.keys(action.payload.response.data===['upload_images'].includes(0))){
                let err = action.payload.response.data.upload_images[0]
            return{
                ...state,
                isLoading:false,
                errors:{upload_images:err}
            }
            }else{
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }}
        default:
            return state;

    }
}
export default employee_user_apply_leave;
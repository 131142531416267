import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const MASSASSIGNCLAIMAPPROVAL_REQUEST = 'MASSASSIGNCLAIMAPPROVAL_REQUEST';
export const MASSASSIGNCLAIMAPPROVAL_SUCCESS = 'MASSASSIGNCLAIMAPPROVAL_SUCCESS';
export const MASSASSIGNCLAIMAPPROVAL_FAILURE = 'MASSASSIGNCLAIMAPPROVAL_FAILURE';


export const mass_assign_claim_approval = (data)=>{
    return dispatch=>{
        dispatch({
            type:'MASSASSIGNCLAIMAPPROVAL_REQUEST'
        });

        return myaxios.post('claim/employeeapproval/array_create/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'MASSASSIGNCLAIMAPPROVAL_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('assign_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'MASSASSIGNCLAIMAPPROVAL_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('assign_failure')
            });
        })
    }
}
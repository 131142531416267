import * as applyleaveself from '../../action/user/leave_calendar_user_apply_leave_self';

const initialsState={
    data:[],
    isLoading:false,
    errors:[]
}

const leave_calendar_user_apply_leave_self=  (state=initialsState,action)=>{
    switch(action.type){
        case applyleaveself.LEAVECALENDARSELFAPPLYLEAVE_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case applyleaveself.LEAVECALENDARSELFAPPLYLEAVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case applyleaveself.LEAVECALENDARSELFAPPLYLEAVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default leave_calendar_user_apply_leave_self;
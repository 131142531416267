import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col ,DropdownMenu,DropdownToggle, Input,  DropdownItem, Dropdown } from 'reactstrap';
import {get_payroll_instance} from '../../action/payroll/get_payroll_instance';
import {payroll_detail_get_aggridheader} from '../../action/payroll/payroll_detail_get_aggridheader';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css'; 
import {regenerate_payroll} from '../../action/payroll/regenerate_payroll';
import '../../css/aggrid.css';
import {get_payroll_history_list} from '../../action/history/get_payroll_history_list';
import Swal from 'sweetalert2';
import {get_payslip_employee_contract} from '../../action/payroll/get_payslip_employee_contract';
import {payroll_basic_payout} from '../../action/payroll/payroll_basic_payout';
import {IMLocalized} from '../../language/IMLocalized';
import '../../css/loading1.css';
import OverlayLoading from '../loading_component/overlay_loading';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import ModalUpdateRates from './modal/ModalUpdateRates';
import DownloadPayslipConsolidatedButton from './button/DownloadPayslipConsolidatedButton';
import DownloadPayslipButton from './button/DownloadPayslipButton';
import ModalViewPayout from './modal/ModalViewPayout';
import ModalPayoutDetail from './modal/ModalPayoutDetail';
import { usePrevious } from '../../hook/usePrevious';
import {popError} from '../../func/popError';
import ModalPayoutError from './modal/ModalPayoutError';
import ModalUploadBonus from './modal/ModalUploadBonus';
import ModalUploadAbsent from './modal/ModalUploadAbsent';
import ModalUploadBackPay from './modal/ModalUploadBackPay';
import PayrollDetailAggrid from './PayrollDetailAggrid';
import PayrollDetailHistory from './PayrollDetailHistory';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';
import {refresh_employee} from '../../action/payroll/refresh_employee';
import { reset_payroll_instance } from '../../action/payroll/get_payroll_instance';
import {recalculate_payroll} from '../../action/payroll/recalculate_payroll';
import ModalDownloadPayslips from './modal/ModalDownloadPayslips';

const mapStateToProps=(state)=>({
    letterhead:state.get_general_letter.data,
    list:state.get_payroll_instance.data,
    payrolllist:state.get_payroll_list.data,
    basic_success:state.payroll_basic_payout.data,
    basic_errors:state.payroll_basic_payout.errors,
    regenerate_success:state.regenerate_payroll.data,
    refresh_success:state.refresh_employee.data,
    recalculate_success:state.recalculate_payroll.data,

    isLoading:state.get_payroll_instance.isLoading,
    isLoading1:state.payroll_basic_payout.isLoading,
    isLoading2:state.regenerate_payroll.isLoading,
    isLoading3:state.get_payslip_employee_contract.isLoading,
    isLoading4:state.get_general_letter.isLoading,
    isLoading5:state.recalculate_payroll.isLoading,
})

const mapDispatchToProps=(dispatch)=>({
    get_payslip_employee_contract:(id)=>{
        dispatch(get_payslip_employee_contract(id))
    },
    payroll_detail_get_aggridheader:(id)=>{
        dispatch(payroll_detail_get_aggridheader(id))
    },
    get_payroll_instance:(id)=>{
        dispatch(get_payroll_instance(id))
    },
    payroll_basic_payout:(id,first)=>{
        dispatch(payroll_basic_payout(id,first))
    },
    get_payroll_history_list:()=>{
        dispatch(get_payroll_history_list())
    },
    regenerate_payroll:(id)=>{
        dispatch(regenerate_payroll(id))
    },
    refresh_employee:(id)=>{
        dispatch(refresh_employee(id))
    },
    reset_payroll_instance:()=>{
        dispatch(reset_payroll_instance())
    },
    recalculate_payroll:(id)=>{
        dispatch(recalculate_payroll(id))
    },
})

const initialState={
    is_edit:false,
    status_display:'',
    payroll_date:'',
    select_payroll:'',
    additional_toggle:false,
    payout_toggle:false,
    download_toggle:false,
    update_toggle:false,
    rates:null,
    rates_toggle:false,
    view_payout_toggle:false,
    payout_detail_toggle:false,
    payout_detail_id:null,
    payout_errors_msg:[],
    payout_errors_toggle:false,
    bonus_toggle:false,
    absent_toggle:false,
    backpay_toggle:false,
    payroll_type:'',

}


function PayrollDetail(props){
    const {reset_payroll_instance,get_payslip_employee_contract,get_payroll_instance,regenerate_success,refresh_success,basic_errors,basic_success,handlePayroll,payroll_detail_get_aggridheader,get_payroll_history_list} = props;
    const [state,setState] = useState(initialState);
    const [monthNames] = useState([IMLocalized("january"), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
    IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")]);
    const { addItem } = useContext(NewMainContext);
    const prevBasicError = usePrevious(basic_errors);
    const prevBasicSuccess = usePrevious(basic_success);
    const prevRegenerate = usePrevious(regenerate_success);
    const prevRefresh = usePrevious(refresh_success);
    const {setPayrollID,payroll_id } = useContext(NewMainContext);

    useEffect(()=>{ 
        if(props.list.length !== 0){
            // reset_payroll_instance();
            const item = props.list[0];
            const {status,id,payroll_date,rates,type} = item;
            const month1 = new Date(payroll_date).getMonth();
            const month = monthNames[month1];
            const year = new Date(payroll_date).getFullYear();
            const date1 = `${month} ${year}`;

            // get_payslip_employee_contract(id)

            localStorage.setItem('payroll_status', props.list[0]?.status)
            localStorage.setItem('payroll_type', props.list[0]?.type)

            if(localStorage.getItem('payroll_status') === 'INCOMPLETE'){
                let idd = id
                setPayrollID(idd)
                handlePayroll({step:'1',id:idd,type:type,status:'INCOMPLETE'});
            }
            if(localStorage.getItem('payroll_status') === 'PAID'){
                let idd = id
                setPayrollID(idd)
                handlePayroll({step:'2',id:idd,type:type,status:'PAID'});
            }
            setState(prevState=>({...prevState,status_display:status,payroll_date:date1,rates:rates,payroll_type:type}));
        }
    },[props.list,handlePayroll,monthNames])

    useEffect(()=>{
        if(prevBasicError !== undefined && prevBasicError !== basic_errors && basic_errors != null){
            if(basic_errors.hasOwnProperty('non_field_errors')){
                popError(basic_errors['non_field_errors'].toString());
            }
            else if(basic_errors.hasOwnProperty('negative')){
                const word = basic_errors['negative'];
                setState(prevState=>({...prevState,payout_errors_msg:word,payout_errors_toggle:true}));
            }
            else if(basic_errors.hasOwnProperty('bank')){
                const word = basic_errors['bank'];
                setState(prevState=>({...prevState,payout_errors_msg:word,payout_errors_toggle:true}));
            }
        }
    },[prevBasicError,basic_errors])

    useEffect(()=>{
        if(prevBasicSuccess !== undefined && prevBasicSuccess !== basic_success && basic_success != null){
            get_payroll_instance(props.id);
            get_payslip_employee_contract(props.id);
            payroll_detail_get_aggridheader(props.id);
            get_payroll_history_list();
        }
    },[props.id,prevBasicSuccess,basic_success,get_payroll_instance,payroll_detail_get_aggridheader,get_payroll_history_list])

    useEffect(()=>{
        if(prevRegenerate !== undefined && prevRegenerate !== regenerate_success && regenerate_success != null){
            get_payroll_instance(props.id);
            get_payslip_employee_contract(props.id);
            payroll_detail_get_aggridheader(props.id);
            get_payroll_history_list();
        }
    },[prevRegenerate,regenerate_success,props.id,get_payroll_instance,payroll_detail_get_aggridheader,get_payroll_history_list])

    useEffect(()=>{
        if(prevRefresh !== undefined && prevRefresh !== refresh_success && refresh_success != null){
            get_payroll_instance(props.id);
            get_payslip_employee_contract(props.id);
            payroll_detail_get_aggridheader(props.id);
            get_payroll_history_list();
        }
    },[prevRefresh,refresh_success,props.id,get_payroll_instance,payroll_detail_get_aggridheader,get_payroll_history_list])

    const toPayrollList=()=>{
        if(state.is_edit){
            Swal.fire({
                title:IMLocalized('changes_may_not_be_saved'),
                type:'warning',
                showCancelButton:true,
                cancelButtonColor:'#d33',
                confirmButtonColor:'#3085d6',
                confirmButtonText:IMLocalized('yes!'),
                cancelButtonText:IMLocalized('no!'),
            })
            .then((result)=>{
                if(result.value){
                    setState(prevState=>({...prevState,is_edit:false}))
                    // props.handlePayroll({step:'0',id:props.id,type:null});
                    props.handlePayroll({step:'0',id:null,type:null});
                    localStorage.setItem('payroll_id',null);
                    setPayrollID(null)
                    reset_payroll_instance();
                }
            })
        }
        else{
            // props.handlePayroll({step:'0',id:props.id,type:null});
            props.handlePayroll({step:'0',id:null,type:null});
            localStorage.setItem('payroll_id',null);
            setPayrollID(null)
                reset_payroll_instance();
        }
    }

    const submitPayout=(boolean)=>{
        Swal.fire({
            title:boolean?IMLocalized('confirm_basic_payout'): IMLocalized('complete_payout'),
            text: !boolean ? IMLocalized('no_more_changes_can_be_made') :'',
            type:'warning',
            showCancelButton:true,
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!'),
        })
        .then((result)=>{
            if(result.value){
                props.payroll_basic_payout(props.id,boolean);
                localStorage.setItem('payroll_status','PAID')
            }
        })
    }

    let prev_btn = '';
    if(props.prev_item.payroll !== '' && props.prev_item.payroll_name !== ''){
        const month1 = new Date(props.prev_item.payroll_name).getMonth();
        const month = monthNames[month1];
        const year = new Date(props.prev_item.payroll_name).getFullYear();
        const type = props.prev_item.payroll_type;
        prev_btn = <button className="btn btn-primary btn-sm" onClick={()=>{reset_payroll_instance();props.payrollInfo(props.prev_item.payroll)}}>{type} {month} {year}</button>;
    }
    let next_btn = '';
    if(props.next_item.payroll !== '' && props.next_item.payroll_name !== ''){
        const month1 = new Date(props.next_item.payroll_name).getMonth();
        const month = monthNames[month1];
        const year = new Date(props.next_item.payroll_name).getFullYear();
        const type = props.next_item.payroll_type;
        next_btn = <button className="btn btn-primary btn-sm" onClick={()=>{reset_payroll_instance();props.payrollInfo(props.next_item.payroll)}}>{type} {month} {year}</button>;
    }

    return(
        <>
        {/* isLoading3 slowest */}
        {props.isLoading || props.isLoading1 || props.isLoading2 || props.isLoading3 || props.isLoading4 || props.isLoading5 ? 
        <OverlayLoading/> :
        <Container fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    {state.payroll_type==='BONUS'?(
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>{addItem('1','dashboard');reset_payroll_instance()}},
                        { active: false, title:IMLocalized('payroll'), onClick:()=>{handlePayroll({step:'0'});reset_payroll_instance()}},
                        { active: true, title:"Bonus "+state.payroll_date}
                    ]}/>)
                    :state.payroll_type==='HMTH1'?(
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>{addItem('1','dashboard');reset_payroll_instance()}},
                        { active: false, title:IMLocalized('payroll'), onClick:()=>{handlePayroll({step:'0'});reset_payroll_instance()}},
                        { active: true, title:"Half of "+state.payroll_date}
                    ]}/>)
                    :(
                        <BreadCrumbList list={[
                            { active: false, title:IMLocalized('home'), onClick:()=>{addItem('1','dashboard');reset_payroll_instance()}},
                            { active: false, title:IMLocalized('payroll'), onClick:()=>{handlePayroll({step:'0'});reset_payroll_instance()}},
                            { active: true, title:state.payroll_date}
                        ]}/>)
                    }
                
                    <div className='d-flex justify-content-between'>
                        <Input className='ml-2' size="sm" style={{width:'150px'}} type="select" value={state.select_payroll} onChange={(e)=>{
                            reset_payroll_instance();
                            const value = e.target.value;
                            props.payrollInfo(value);
                        }} >
                            <option>{IMLocalized('select_month')}</option>
                            {props?.payrolllist?.map((item,index)=>{
                                const date = item.payroll_date;
                                const month1 = new Date(date).getMonth();
                                const month = monthNames[month1];
                                const year = new Date(date).getFullYear();
                                return <option value={item.id} key={index}>{item.type==="BONUS"?"Bonus ":item.type==="HMTH1"?"Half of ":""}{month} {year}</option>
                            })}
                        </Input>
                        {state.status_display !== '' && 
                        <small className='mr-2'>{IMLocalized('status')} : {IMLocalized(state.status_display)}</small>}
                    </div>
                    <div className='d-flex justify-content-between'>

                    <div className='mt-1 d-flex justify-content-start'>
                        <div className='ml-2'>
                            <button className="btn btn-primary btn-sm" onClick={()=>toPayrollList()}> <i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                            {props.prev_item.payroll_name !== '' && props.prev_item.payroll !== '' && prev_btn }
                            {props.next_item.payroll_name !== '' && props.next_item.payroll !== '' && next_btn }
                        </div>
                    </div>
                    <div className='mt-1 d-flex justify-content-end'>

                        {/* PAYOUT */}
                        <Dropdown size="sm" direction='left' toggle={()=>setState(prevState=>({...prevState,payout_toggle:!state.payout_toggle}))} isOpen={state.payout_toggle}>
                            <DropdownToggle>
                    
                                {IMLocalized('payout_action')} <i className="fas fa-ellipsis-v"/>
                            </DropdownToggle>
                            {state.payroll_type==="MONTH"?
                            <DropdownMenu>
                            <DropdownItem onClick={()=>submitPayout(true)}>{IMLocalized('basic_payout')}</DropdownItem>
                            <DropdownItem onClick={()=>submitPayout(false)}>{IMLocalized('final_payout')}</DropdownItem>
                            <DropdownItem onClick={()=>setState(prevState=>({...prevState,view_payout_toggle:true}))}>{IMLocalized('view_payout')}</DropdownItem>
                            </DropdownMenu>:''
                            }
                            
                            {state.payroll_type==="BONUS"||state.payroll_type==="HMTH1"||state.payroll_type==="SPECL"?
                            <DropdownMenu>
                            <DropdownItem onClick={()=>submitPayout(false)}>{IMLocalized('final_payout')}</DropdownItem>
                            <DropdownItem onClick={()=>setState(prevState=>({...prevState,view_payout_toggle:true}))}>{IMLocalized('view_payout')}</DropdownItem>
                            </DropdownMenu>:''
                        }
                        </Dropdown>


                        {/* DOWNLOAD */}
                        <Dropdown size="sm" direction='left' onClick={()=>setState(prevState=>({...prevState,download_toggle:true}))}>
                            <DropdownToggle >
                                {IMLocalized('download_action')} <i className="fas fa-ellipsis-v"/>
                            </DropdownToggle>
                            <DropdownMenu>
                            <DropdownItem />
                            <DropdownItem />
                            <DropdownItem />
                            </DropdownMenu>
                            {/* {state.payroll_type==="MONTH"||state.payroll_type==="SPECL"?
                            <DropdownMenu>
                            <DownloadPayslipButton/>
                            <DownloadPayslipConsolidatedButton />
                            </DropdownMenu>:''
                            }
                            
                            {state.payroll_type==="BONUS"||state.payroll_type==="HMTH1"?
                            <DropdownMenu>
                            <DownloadPayslipButton/>
                            <DownloadPayslipConsolidatedButton />
                            </DropdownMenu>:''
                            } */}
                            
                        </Dropdown>

                        {/* UPDATE */}
                        <Dropdown size="sm" direction='left' toggle={()=>setState(prevState=>({...prevState,update_toggle:!state.update_toggle}))} isOpen={state.update_toggle}>
                            <DropdownToggle>
                                {IMLocalized('update_action')} <i className="fas fa-ellipsis-v"/>
                            </DropdownToggle>
                            {state.payroll_type==="MONTH"||state.payroll_type==="SPECL"?
                            <DropdownMenu>
                            <DropdownItem onClick={()=>setState(prevState=>({...prevState,rates_toggle:true}))}>{IMLocalized('update_rates')}</DropdownItem>
                            <DropdownItem onClick={()=>setState(prevState=>({...prevState,bonus_toggle:true}))}>{IMLocalized('upload_bonus')}</DropdownItem>                                        
                            <DropdownItem onClick={()=>setState(prevState=>({...prevState,absent_toggle:true}))}>{IMLocalized('upload_absent')}</DropdownItem>
                            <DropdownItem onClick={()=>setState(prevState=>({...prevState,backpay_toggle:true}))}>{IMLocalized('upload_other_pay')}</DropdownItem>                      
                            </DropdownMenu>:''
                            }
                            
                            {state.payroll_type==="BONUS"||state.payroll_type==="HMTH1"?
                            <DropdownMenu>
                            <DropdownItem onClick={()=>setState(prevState=>({...prevState,rates_toggle:true}))}>{IMLocalized('update_rates')}</DropdownItem>
                            <DropdownItem onClick={()=>setState(prevState=>({...prevState,bonus_toggle:true}))}>{IMLocalized('upload_bonus')}</DropdownItem>                                        
                            <DropdownItem onClick={()=>setState(prevState=>({...prevState,absent_toggle:true}))}>{IMLocalized('upload_absent')}</DropdownItem>
                            <DropdownItem onClick={()=>setState(prevState=>({...prevState,backpay_toggle:true}))}>{IMLocalized('upload_other_pay')}</DropdownItem>
                            </DropdownMenu>:''
                            }
                        </Dropdown>

                        <Dropdown size="sm" direction='left' toggle={()=>setState(prevState=>({...prevState,additional_toggle:!state.additional_toggle}))} isOpen={state.additional_toggle}>
                            <DropdownToggle>
                                {IMLocalized('more_action')} <i className="fas fa-ellipsis-v"/>
                            </DropdownToggle>
                            {state.payroll_type==="MONTH"?
                            <DropdownMenu>
                            <DropdownItem 
                            onClick={()=>{
                                Swal.fire({
                                    title:IMLocalized('confirm_regenerate_payroll'),
                                    text:IMLocalized('regenerate_data_gone'),
                                    type:'warning',
                                    confirmButtonColor:'#d33',
                                    confirmButtonText:IMLocalized('yes!'),
                                    showCancelButton:true,
                                    cancelButtonText:IMLocalized('no!')
                                })
                                .then((result)=>{
                                    if(result.value){
                                        props.regenerate_payroll(props.id);
                                    }
                                })
                            }}
                            >{IMLocalized('regenerate_payroll')}</DropdownItem>
                            {/* <DropdownItem divider/> */}
                            <DropdownItem 
                            onClick={()=>{
                                if(state.is_edit === true){
                                    Swal.fire({
                                        title:IMLocalized('changes_may_not_be_saved'),
                                        type:'warning',
                                        showCancelButton:true,
                                        cancelButtonColor:'#d33',
                                        confirmButtonColor:'#3085d6',
                                        confirmButtonText:IMLocalized('yes!'),
                                        cancelButtonText:IMLocalized('no!'),
                                    })
                                    .then((result)=>{
                                        if('value' in result){
                                            setState(prevState=>({...prevState,is_edit:false}));
                                            props.handlePayroll({step:'3',id:props.id});
                                            localStorage.setItem('payroll_report_id',props.id);
                                        }
                                    })
                                }
                                else{
                                    props.handlePayroll({step:'3',id:props.id});
                                    localStorage.setItem('payroll_report_id',props.id);
                                }
                            }}>{IMLocalized('report')}</DropdownItem>
                            <DropdownItem 
                            onClick={()=>{
                                if(state.is_edit === true){
                                    Swal.fire({
                                        title:IMLocalized('changes_may_not_be_saved'),
                                        type:'warning',
                                        showCancelButton:true,
                                        cancelButtonColor:'#d33',
                                        confirmButtonColor:'#3085d6',
                                        confirmButtonText:IMLocalized('yes!'),
                                        cancelButtonText:IMLocalized('no!'),
                                    })
                                    .then((result)=>{
                                        if('value' in result){
                                            setState(prevState=>({...prevState,is_edit:false}));
                                            props.handlePayroll({step:'2',id:props.id});
                                        }
                                    })
                                }
                                else{
                                    props.handlePayroll({step:'2',id:props.id});
                                }
                            }}>{IMLocalized('summary')}</DropdownItem>
                            <DropdownItem
                            onClick={()=>{
                                Swal.fire({
                                    title:IMLocalized('confirm_refresh_employee'),
                                    text:IMLocalized('refresh_data_keep'),
                                    type:'warning',
                                    confirmButtonColor:'#d33',
                                    confirmButtonText:IMLocalized('yes!'),
                                    showCancelButton:true,
                                    cancelButtonText:IMLocalized('no!')
                                })
                                .then((result)=>{
                                    if(result.value){
                                        props.refresh_employee(props.id);
                                    }
                                })
                            }}
                            >{IMLocalized('refresh_employee_list')}</DropdownItem>
                            <DropdownItem
                            onClick={()=>{
                                Swal.fire({
                                    title:IMLocalized('confirm_recalculate_payroll'),
                                    text:IMLocalized('refresh_data_keep'),
                                    type:'warning',
                                    confirmButtonColor:'#d33',
                                    confirmButtonText:IMLocalized('yes!'),
                                    showCancelButton:true,
                                    cancelButtonText:IMLocalized('no!')
                                })
                                .then((result)=>{
                                    if(result.value){
                                        props.recalculate_payroll(props.id);
                                    }
                                })
                            }}
                            >{IMLocalized('recalculate_payroll')}</DropdownItem>                            
                            </DropdownMenu>:''
                            }
                            
                            {state.payroll_type==="BONUS"||state.payroll_type==="HMTH1"?
                            <DropdownMenu>
                            <DropdownItem 
                            onClick={()=>{
                                Swal.fire({
                                    title:IMLocalized('confirm_regenerate_payroll'),
                                    text:IMLocalized('regenerate_data_gone'),
                                    type:'warning',
                                    confirmButtonColor:'#d33',
                                    confirmButtonText:IMLocalized('yes!'),
                                    showCancelButton:true,
                                    cancelButtonText:IMLocalized('no!')
                                })
                                .then((result)=>{
                                    if(result.value){
                                        props.regenerate_payroll(props.id);
                                    }
                                })
                            }}
                            >{IMLocalized('regenerate_payroll')}</DropdownItem>
                            {/* <DropdownItem divider/> */}
                            <DropdownItem 
                            onClick={()=>{
                                if(state.is_edit === true){
                                    Swal.fire({
                                        title:IMLocalized('changes_may_not_be_saved'),
                                        type:'warning',
                                        showCancelButton:true,
                                        cancelButtonColor:'#d33',
                                        confirmButtonColor:'#3085d6',
                                        confirmButtonText:IMLocalized('yes!'),
                                        cancelButtonText:IMLocalized('no!'),
                                    })
                                    .then((result)=>{
                                        if('value' in result){
                                            setState(prevState=>({...prevState,is_edit:false}));
                                            props.handlePayroll({step:'3',id:props.id});
                                            localStorage.setItem('payroll_report_id',props.id);
                                        }
                                    })
                                }
                                else{
                                    props.handlePayroll({step:'3',id:props.id});
                                    localStorage.setItem('payroll_report_id',props.id);
                                }
                            }}>{IMLocalized('report')}</DropdownItem>
                            <DropdownItem 
                            onClick={()=>{
                                if(state.is_edit === true){
                                    Swal.fire({
                                        title:IMLocalized('changes_may_not_be_saved'),
                                        type:'warning',                                         
                                        showCancelButton:true,
                                        cancelButtonColor:'#d33',
                                        confirmButtonColor:'#3085d6',
                                        confirmButtonText:IMLocalized('yes!'),
                                        cancelButtonText:IMLocalized('no!'),
                                    })
                                    .then((result)=>{
                                        if('value' in result){
                                            setState(prevState=>({...prevState,is_edit:false}));
                                            props.handlePayroll({step:'2',id:props.id});
                                        }
                                    })
                                }
                                else{
                                    props.handlePayroll({step:'2',id:props.id});
                                }
                            }}>{IMLocalized('summary')}</DropdownItem>
                            {/* <DropdownItem divider/> */}
                            <DropdownItem
                            onClick={()=>{
                                Swal.fire({
                                    title:IMLocalized('confirm_refresh_employee'),
                                    text:IMLocalized('refresh_data_keep'),
                                    type:'warning',
                                    confirmButtonColor:'#d33',
                                    confirmButtonText:IMLocalized('yes!'),
                                    showCancelButton:true,
                                    cancelButtonText:IMLocalized('no!')
                                })
                                .then((result)=>{
                                    if(result.value){
                                        props.refresh_employee(props.id);
                                    }
                                })
                            }}
                            >{IMLocalized('refresh_employee_list')}</DropdownItem>
                            <DropdownItem
                            onClick={()=>{
                                Swal.fire({
                                    title:IMLocalized('confirm_recalculate_payroll'),
                                    text:IMLocalized('refresh_data_keep'),
                                    type:'warning',
                                    confirmButtonColor:'#d33',
                                    confirmButtonText:IMLocalized('yes!'),
                                    showCancelButton:true,
                                    cancelButtonText:IMLocalized('no!')
                                })
                                .then((result)=>{
                                    if(result.value){
                                        props.recalculate_payroll(props.id);
                                    }
                                })
                            }}
                            >{IMLocalized('recalculate_payroll')}</DropdownItem>
                            </DropdownMenu>:''
                            }

                            {state.payroll_type==="SPECL"?
                            <DropdownMenu>
                            <DropdownItem 
                            onClick={()=>{
                                if(state.is_edit === true){
                                    Swal.fire({
                                        title:IMLocalized('changes_may_not_be_saved'),
                                        type:'warning',
                                        showCancelButton:true,
                                        cancelButtonColor:'#d33',
                                        confirmButtonColor:'#3085d6',
                                        confirmButtonText:IMLocalized('yes!'),
                                        cancelButtonText:IMLocalized('no!'),
                                    })
                                    .then((result)=>{
                                        if('value' in result){
                                            setState(prevState=>({...prevState,is_edit:false}));
                                            props.handlePayroll({step:'3',id:props.id});
                                            localStorage.setItem('payroll_report_id',props.id);
                                        }
                                    })
                                }
                                else{
                                    props.handlePayroll({step:'3',id:props.id});
                                    localStorage.setItem('payroll_report_id',props.id);
                                }
                            }}>{IMLocalized('report')}</DropdownItem>
                            <DropdownItem 
                            onClick={()=>{
                                if(state.is_edit === true){
                                    Swal.fire({
                                        title:IMLocalized('changes_may_not_be_saved'),
                                        type:'warning',                                         
                                        showCancelButton:true,
                                        cancelButtonColor:'#d33',
                                        confirmButtonColor:'#3085d6',
                                        confirmButtonText:IMLocalized('yes!'),
                                        cancelButtonText:IMLocalized('no!'),
                                    })
                                    .then((result)=>{
                                        if('value' in result){
                                            setState(prevState=>({...prevState,is_edit:false}));
                                            props.handlePayroll({step:'2',id:props.id});
                                        }
                                    })
                                }
                                else{
                                    props.handlePayroll({step:'2',id:props.id});
                                }
                            }}>{IMLocalized('summary')}</DropdownItem>
                            </DropdownMenu>:''
                            }
                            
                        </Dropdown>
                    </div>
                    </div>
                </Col>
            </Row>
            <PayrollDetailAggrid get_payroll_instance={props.get_payroll_instance} list={props.list} is_edit={state.is_edit} id={props.id} setEdit={(value)=>setState(prevState=>({...prevState,is_edit:value}))} toPayrollPayslip={props.toPayrollPayslip} />
            <PayrollDetailHistory id={props.id} />
        </Container>}
        <ModalUpdateRates toggle={state.rates_toggle} closeModal={()=>setState(prevState=>({...prevState,rates_toggle:false}))} rates={state.rates} id={props.id}  />
        <ModalViewPayout toggle={state.view_payout_toggle} closeModal={()=>setState(prevState=>({...prevState,view_payout_toggle:false}))} 
        toViewDetail={(id)=>{setState(prevState=>({...prevState,payout_detail_toggle:true,payout_detail_id:id})); }} />
        <ModalPayoutDetail toggle={state.payout_detail_toggle} id={state.payout_detail_id} closeModal={()=>setState(prevState=>({...prevState,payout_detail_id:null,payout_detail_toggle:false}))} />
        <ModalPayoutError toggle={state.payout_errors_toggle} detail={state.payout_errors_msg} closeModal={()=>setState(prevState=>({...prevState,payout_errors_msg:[],payout_errors_toggle:false}))} />
        <ModalUploadBonus toggle={state.bonus_toggle} closeModal={()=>setState(prevState=>({...prevState,bonus_toggle:false}))} id={props.id} />
        <ModalUploadAbsent toggle={state.absent_toggle} closeModal={()=>{setState(prevState=>({...prevState,absent_toggle:false}))}} id={props.id} />
        <ModalUploadBackPay toggle={state.backpay_toggle} closeModal={()=>{setState(prevState=>({...prevState,backpay_toggle:false}))}} id={props.id} />
        <ModalDownloadPayslips 
            toggle={state.download_toggle} 
            closeModal={()=>{setState(prevState=>({...prevState,download_toggle:false}))}} 
            // list={props.list}
            id={props.id} />
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(PayrollDetail);
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CREATEEMPLOYEE_REQUEST = 'CREATEEMPLOYEE_REQUEST';
export const CREATEEMPLOYEE_SUCCESS = 'CREATEEMPLOYEE_SUCCESS';
export const CREATEEMPLOYEE_FAILURE = 'CREATEEMPLOYEE_FAILURE';
export const VALIDATE_FAILURE = 'VALIDATE_FAILURE';


export const create_employee = (salutation,name,employee_number,badge_number,email,nationality,id_type,identification_number,contact_number,gender,race,religion,relationship,company,branch,payment,is_pr,pr_startdate,date_of_birth,join_date,probation_end_date,country_of_residence,sg_postal_code,block_number,street_name,level_number,unit_number,postal_code,address,pr_cpf_contribution_type)=>{
    return dispatch=>{
        dispatch({
            type: 'CREATEEMPLOYEE_REQUEST'
        });

        myaxios.post('employee/address/validate/',{
            country_of_residence:country_of_residence,
            block_number:block_number,
            street_name:street_name,
            level_number:level_number,
            unit_number:unit_number,
            sg_postal_code:sg_postal_code,
            address:address,
            postal_code:postal_code
        })
        .then(()=>{
            myaxios.post('employee/employees/',{
                employee_number:employee_number,
                salutation:salutation,
                name:name,
                badge_number:badge_number,
                email:email,
                nationality:nationality,
                identification_number:identification_number,
                race:race,
                religion:religion,
                gender:gender,
                relationship:relationship,
                contact_number:contact_number,
                date_of_birth:date_of_birth,
                join_date:join_date,
                probation_end_date:probation_end_date,
                company:company,
                branch:branch,
                payment:payment,
                is_pr:is_pr,
                pr_start_date:pr_startdate,
                id_type:id_type,
                pr_cpf_contribution_type:pr_cpf_contribution_type

            })

            .then((response)=>{
                dispatch({
                    type:'CREATEEMPLOYEE_SUCCESS',
                    payload:response
                })
                myaxios.post('employee/address/',{
                    country_of_residence:country_of_residence,
                    block_number:block_number,
                    street_name:street_name,
                    level_number:level_number,
                    unit_number:unit_number,
                    sg_postal_code:sg_postal_code,
                    address:address,
                    employee:response.data.id
                })
                .then(()=>{
                    Toast.fire({
                        type: 'success',
                        title: IMLocalized('create_success')
                    })
                })                
            })
            .catch((error)=>{
                dispatch({
                    type:'CREATEEMPLOYEE_FAILURE',
                    payload:error.response.data
                });

                ErrToast.fire({
                    type:'error',
                    title:IMLocalized('create_failure')
                });
            })
        })
        .catch((error)=>{
            dispatch({
                type: 'VALIDATE_FAILURE',
                payload:error.response.data
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}
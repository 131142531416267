import myaxios from '../../axios';
import FormData from 'form-data';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';


export const ADDEMPEDUCATION_REQUEST = 'ADDEMPEDUCATION_REQUEST';
export const ADDEMPEDUCATION_SUCCESS = 'ADDEMPEDUCATION_SUCCESS';
export const ADDEMPEDUCATION_FAILURE = 'ADDEMPEDUCATION_FAILURE';
export const ADDEMPEDUCATION_RESET = 'ADDEMPEDUCATION_RESET';

export const add_employee_education = (id,level,institute,school,country,certification,gpa,highiest_education,graduation_date,enrollment_date,notes,upload_images)=>{
    return dispatch=>{
        dispatch({
            type:'ADDEMPEDUCATION_REQUEST'
        });

        let formData = new FormData();
        if(upload_images.length !== 0){
            for(let i=0;i<upload_images.length;i++){
                formData.append('upload_images',upload_images[i],upload_images[i].name);
            }
        }

        formData.append('employee',id);
        formData.append('level',level);
        formData.append('institute',institute);
        formData.append('school',school);
        formData.append('country',country);
        formData.append('certification',certification);
        formData.append('gpa',gpa);
        formData.append('highest_education',highiest_education);
        formData.append('graduation_date',graduation_date);
        formData.append('enrollment_date',enrollment_date);
        formData.append('notes',notes);
        
        myaxios.post('employee/education/',formData,{headers:{'Content-Type':'multipart/form-data'}})
        .then((response)=>{
            dispatch({
                type:'ADDEMPEDUCATION_SUCCESS',
                payload:response
            });
            
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'ADDEMPEDUCATION_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}

export const reset_add_employee_education = () => dispatch =>{
    dispatch({
        type:'ADDEMPEDUCATION_RESET'
    });
}
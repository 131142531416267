import React from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { IMLocalized } from "../../../language/IMLocalized";


function ModalViewClaimSchemeDetail({ toggle, closeModal, id}){

    const list = useSelector(state=>state.get_claim_scheme_list.data);
    const claimlist = useSelector(state=>state.get_claim_list.data);
    const detail = id !== null ? list.find(el => el.id === id) : null;
    
    return(
        <Modal size="lg" isOpen={toggle}>
            {detail &&
            <>
            <ModalHeader toggle={closeModal}>{IMLocalized('view_claim_scheme')} - {detail.name}</ModalHeader>
            <ModalBody>
                {detail.claim_entitlement.length !== 0 && detail.claim_entitlement.map((item,index)=>{
                    const claim_data = claimlist.find(el=>el.id === item.claim_type);
                    return(
                        <React.Fragment key={index}>
                            <div className="p-2 mb-2">
                                <h3 className="mb-0">{claim_data?.name ?? ''}</h3>
                                <Table size="sm" className="align-items-center table-bordered" responsive>
                                    <thead className="thead-light text-center">
                                        <tr>
                                            <th style={{width:'50%'}}>{IMLocalized('name')}</th>
                                            <th style={{width:'50%'}}>{IMLocalized('claim_scheme_amount')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        <tr>
                                            <td>{IMLocalized('employee_percent')}</td>
                                            <td>{item.employee_percent}%</td>
                                        </tr>
                                        <tr>
                                            <td>{IMLocalized('dependent_percent')}</td>
                                            <td>{item.dependent_percent}%</td>
                                        </tr>
                                        <tr>
                                            <td>{IMLocalized('is_monthly')}</td>
                                            <td>
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" id={'monthly'+index} name='is_monthly' className="custom-control-input" defaultChecked={item.is_monthly} />
                                                    <label className="custom-control-label" htmlFor={'monthly'+index}></label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <Table size="sm" className="align-items-center table-bordered" responsive>
                                    <thead className="thead-light text-center">
                                        <tr>
                                            <th style={{width:'50%'}}>
                                                {IMLocalized('year_in_company')}
                                            </th>
                                            <th>
                                                {IMLocalized('claim_entitlement_amount')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        {item.age_tier.map((item1,index1)=>{
                                            return(
                                                <tr key={index1}>
                                                    <td>{item1}</td>
                                                    <td>{item.amount_tier[index1]}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </React.Fragment>
                    )
                })
                }
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
            </>}
        </Modal>
    )
}
export default ModalViewClaimSchemeDetail;
import React, { useContext, useState } from 'react';
import { Container, Row, Col, Nav, NavItem , NavLink, TabContent, TabPane, Card, CardHeader, Table} from 'reactstrap';
import { IMLocalized } from '../../language/IMLocalized';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';
import ModalCreatePayrollAdditionalSetting from './modal/ModalCreatePayrollAdditionalSetting';
import { useSelector, useDispatch } from 'react-redux';
import OverlayLoading from '../loading_component/OverlayLoading';
import Swal from 'sweetalert2';
import { delete_payroll_additional_setting, reset_delete_pas } from '../../action/delete_payroll_additional_setting';
import { useEffect } from 'react';
import { get_payroll_additional_setting } from '../../action/get_payroll_additional_setting';
import ModalEditPayrollAdditionalSetting from './modal/ModalEditPayrollAdditionalSetting';


function PayrollAdditionalSetting(){

    const [create_toggle,setCreateToggle] = useState(false);
    const dispatch = useDispatch();
    const {addItem} = useContext(NewMainContext);
    const pasList = useSelector(state=>state.get_payroll_additional_setting.data);
    const isLoading = useSelector(state=>state.get_payroll_additional_setting.isLoading);
    const success = useSelector(state=>state.delete_payroll_additional_setting.data);
    const [edit_toggle,setEditToggle] = useState(false);
    const [edit_id,setEditID] = useState(null);

    useEffect(()=>{
        if( success !== null){
            dispatch(get_payroll_additional_setting());
            dispatch(reset_delete_pas());
        }
    },[success,dispatch])

    const removePAS = (id, code) => {
        Swal.fire({
            type:'warning',
            title:`${IMLocalized('do_you_really_want_to_delete')} \n ${IMLocalized('payroll_additional_setting')} (`+ code +`)`,
            showCancelButton:true,
            confirmButtonColor:'red'
        })
        .then((result)=>{
            if(result.value){
                dispatch(delete_payroll_additional_setting(id));
            }
        })

    }

    return(
        <>
        {isLoading ? <OverlayLoading/>:
        <>
            <Container fluid>
                <Row className="border-bottom bg-white">
                    <Col className="p-2">
                        <BreadCrumbList list={[
                            {active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                            {active: false, title:IMLocalized('company'), onClick:()=>addItem('2','company_list')},
                            {active: true, title:IMLocalized('Payroll Additional Setting')}
                        ]} />
                    </Col>
                </Row>
            </Container>
            <Container className='mt-2'>

            <Card>
            <CardHeader className='d-flex justify-content-between align-items-center'>
                <h4 className="mb-0">{IMLocalized('Payroll Additional Setting')}</h4>
                <div>
                {/* <button className="btn btn-primary btn-sm" onClick={()=>addItem('92','import_payroll_additional_setting')}>{IMLocalized('import')}</button> */}
                <button className="btn btn-primary btn-sm" onClick={()=>setCreateToggle(true)}>{IMLocalized('add')}</button>
                </div>
            </CardHeader>

            <Table size="sm" className='align-items-center' responsive>
                <thead className='text-center'>
                    <tr>
                        <th>{IMLocalized('code')}</th>
                        <th>{IMLocalized('name')}</th>
                        <th>{IMLocalized('pay_type')}</th>
                        <th>{IMLocalized('wage')}</th>
                        <th>{IMLocalized('from_date')}</th>
                        <th>{IMLocalized('to_date')}</th>
                        <th>{IMLocalized('edit')}</th>
                        <th>{IMLocalized('delete')}</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {pasList?.map((item,index)=>{
                        return(
                            <tr key={index}>
                                <td>{item.code}</td>
                                <td>{item.name}</td>
                                <td>{item.pay_type}</td>
                                <td>{item.wage}</td>
                                <td>{item.from_date}</td>
                                <td>{item.to_date}</td>
                                <td align="center" valign="center">
                                    <div className="edit_btn d-flex align-items-center justify-content-center">
                                        <i onClick={()=>{setEditToggle(true); setEditID(item)}} className="fas fa-pen text-yellow cursor-pointer"/>
                                    </div>
                                </td>
                                <td align="center" valign="center">
                                    <div className="delete_btn d-flex align-items-center justify-content-center">
                                        <i onClick={()=>removePAS(item.id,item.code)} className="fas fa-trash text-red cursor-pointer"/>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </Card>
        </Container>
        </>}
        <ModalCreatePayrollAdditionalSetting toggle={create_toggle} closeModal={()=>setCreateToggle(false)} />
        <ModalEditPayrollAdditionalSetting toggle={edit_toggle} closeModal={()=>{setEditToggle(false);setEditID(null)}} detail={edit_id} />
        </>
    )
}
export default PayrollAdditionalSetting;
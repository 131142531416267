import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const EMPALLARRAYAPPROVECLAIM_REQUEST = 'EMPALLARRAYAPPROVECLAIM_REQUEST';
export const EMPALLARRAYAPPROVECLAIM_SUCCESS = 'EMPALLARRAYAPPROVECLAIM_SUCCESS';
export const EMPALLARRAYAPPROVECLAIM_FAILURE = 'EMPALLARRAYAPPROVECLAIM_FAILURE';
export const EMPALLARRAYAPPROVECLAIM_RESET = 'EMPALLARRAYAPPROVECLAIM_RESET';

export const emp_all_array_approve_apply_claim = (data)=>{
    return dispatch=>{
        dispatch({
            type:'EMPALLARRAYAPPROVECLAIM_REQUEST'
        });

        myaxios.post(`claim/apply/array_approve/`,{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'EMPALLARRAYAPPROVECLAIM_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('approve_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'EMPALLARRAYAPPROVECLAIM_FAILURE',
                payload:error
            });
            ErrToast.fire({
                type:'error',
                title:IMLocalized('approve_failure')
            })
        })
    }
}

export const reset_emp_all_array_approve_apply_claim = ()=> dispatch =>{
    dispatch({
        type:'EMPALLARRAYAPPROVECLAIM_RESET'
    });
}

import * as validate_apply_leave from '../../action/leave/validate_apply_leave_reset';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const validate_apply_leave_reset = (state=initialState,action)=>{
    switch(action.type){
        case validate_apply_leave.VALIDATEAPPLYLEAVERESET_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case validate_apply_leave.VALIDATEAPPLYLEAVERESET_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case validate_apply_leave.VALIDATEAPPLYLEAVERESET_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case validate_apply_leave.VALIDATEAPPLYLEAVERESET_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default validate_apply_leave_reset;
import myaxios from "../../axios";

export const GETPAYROLLINCREMENTDETAIL_REQUEST = 'GETPAYROLLINCREMENTDETAIL_REQUEST';
export const GETPAYROLLINCREMENTDETAIL_SUCCESS = 'GETPAYROLLINCREMENTDETAIL_SUCCESS';
export const GETPAYROLLINCREMENTDETAIL_FAILURE = 'GETPAYROLLINCREMENTDETAIL_FAILURE';


export const get_payroll_increment_detail = (id)=>{
    return dispatch=>{
        dispatch({
            type:'GETPAYROLLINCREMENTDETAIL_FAILURE'
        })

        return myaxios.get(`contract/incview/${id}/`)
        .then((response)=>{
            dispatch({
                type:'GETPAYROLLINCREMENTDETAIL_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETPAYROLLINCREMENTDETAIL_FAILURE',
                payload:error
            })
        })
    }
}
import React from 'react';
import { connect } from 'react-redux';
import { Modal,ModalBody,ModalHeader,ModalFooter,Row,Col,Table } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { getDateFormat } from '../../../../func/getDateFormat';

const mapStateToProps=(state)=>({
    permitlist:state.dropdown_list.work_permit,
})

function ModalViewPermit(props){
    
    let type = '';
    if(props.detail){
        const detail = props.permitlist.find((item)=>item.value === props.detail.permit_type);
        if(detail){
            type = detail.display;
        }
    }

    return(
        <Modal isOpen={props.toggle} size="lg">
            <ModalHeader toggle={props.closeModal}>{IMLocalized('view_employee_permit')}</ModalHeader>
            {props.detail &&
            <ModalBody>
                <Row>
                    <Col>
                        <Table size="sm" className="align-items-center table-bordered">
                            <tbody>
                                <tr>
                                    <td>{IMLocalized('permit_type')}</td>
                                    <td>{type}</td>
                                </tr>
                                <tr>
                                    <td>{IMLocalized('permit_number')}</td>
                                    <td>{props.detail.permit}</td>
                                </tr>
                                <tr>
                                    <td>{IMLocalized('application_date')}</td>
                                    <td>{getDateFormat(props.detail.application_date)}</td>
                                </tr>
                                <tr>
                                    <td>{IMLocalized('expiry_date')}</td>
                                    <td>{getDateFormat(props.detail.expiry_date)}</td>
                                </tr>
                                <tr>
                                    <td>{IMLocalized('issued_date')}</td>
                                    <td>{getDateFormat(props.detail.issued_date)}</td>
                                </tr>
                                <tr>
                                    <td>{IMLocalized('man_year_entitlement')}</td>
                                    <td>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" id="hrviewmanyear" name='new_man_year_entitlement' className="custom-control-input" checked={props.detail.man_year_entitlement} readOnly />
                                            <label className="custom-control-label" htmlFor="hrviewmanyear"></label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </ModalBody>
            }
            <ModalFooter>
                <button className="btn btn-danger btn-sm" onClick={props.closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,null)(ModalViewPermit);
import * as payslipbackpay from '../../action/payroll/create_payslip_backpay';

const initialState={
    data:null,
    errors:null,
    isLoading:false
}

const create_payslip_backpay = (state=initialState,action)=>{
    switch(action.type){
        case payslipbackpay.PAYSLIPBACKPAY_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case payslipbackpay.PAYSLIPBACKPAY_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case payslipbackpay.PAYSLIPBACKPAY_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        default:
            return state;
    }
}
export default create_payslip_backpay;
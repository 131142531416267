import myaxios from "../axios";


export const USERRESETPASSWORD_REQUEST = 'USERRESETPASSWORD_REQUEST';
export const USERRESETPASSWORD_SUCCESS = 'USERRESETPASSWORD_SUCCESS';
export const USERRESETPASSWORD_FAILURE = 'USERRESETPASSWORD_FAILURE';


export const user_reset_password = (code,password)=>{
    return dispatch=>{
        dispatch({
            type:'USERRESETPASSWORD_REQUEST'
        });

        myaxios.post('otp/email/reset/',{
            code:code,
            password:password
        })
        .then((response)=>{
            dispatch({
                type:'USERRESETPASSWORD_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'USERRESETPASSWORD_FAILURE',
                payload:error
            })
        })
    }
}
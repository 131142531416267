import * as  empwork from '../../action/employee/create_employee_permit';

const initialState={
    isLoading:false,
    errors:null,
    data:null,
}

const create_employee_permit =(state=initialState,action)=>{
    switch(action.type){
        case empwork.NEW_CREATEEMPPERMIT_REQUEST:
            return {
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case empwork.NEW_CREATEEMPPERMIT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case empwork.NEW_CREATEEMPPERMIT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case empwork.NEW_CREATEEMPPERMIT_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default create_employee_permit;
import myaxios from '../../axios';

export const DOWNLOADBANKPAYMENT_REQUEST = 'DOWNLOADBANKPAYMENT_REQUEST';
export const DOWNLOADBANKPAYMENT_SUCCESS = 'DOWNLOADBANKPAYMENT_SUCCESS';
export const DOWNLOADBANKPAYMENT_FAILURE = 'DOWNLOADBANKPAYMENT_FAILURE';

export const download_bankpayment = (id,msg)=>{
    return dispatch=>{
        dispatch({
            type:'DOWNLOADBANKPAYMENT_REQUEST'
        });

        myaxios({
            url:`payroll/payroll/${id}/DownloadBankPayments/`,
            method:'GET',
            responseType:'blob'
        })
        .then((response)=>{
            const hello = `${msg}.zip`;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',hello);
            document.body.appendChild(link);
            link.click();
            dispatch({
                type:'DOWNLOADBANKPAYMENT_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'DOWNLOADBANKPAYMENT_FAILURE',
                payload:error
            })
        })
    }
}
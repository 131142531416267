import * as skip from '../../action/employee/get_skip_option'

const initialState={
    isLoading:true,
    data:[],
    errors:[]
}



const get_skip_option = (state=initialState,action)=>{
    switch(action.type){
        case skip.GETSKIPOPTION_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case skip.GETSKIPOPTION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,

            }
        case skip.GETSKIPOPTION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_skip_option;
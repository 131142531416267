import myaxios from '../../axios';

export const HISTORYLIST_REQUEST = 'HISTORYLIST_REQUEST';
export const HISTORYLIST_SUCCESS = 'HISTORYLIST_SUCCESS';
export const HISTORYLIST_FAILURE = 'HISTORYLIST_FAILURE';

export const get_history_list = (page)=>{
    return dispatch=>{
        dispatch({
            type:'HISTORYLIST_REQUEST'
        });
        
        myaxios.get(`history/action/?page=${page}`)
        .then((response)=>{
            dispatch({
                type:'HISTORYLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'HOLIDAYLIST_FAILURE',
                payload:error
            })
        })
    }
}
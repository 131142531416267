import myaxios from '../../axios';

export const REQUEST_BRANCHLIST = 'REQUEST_BRANCHLIST';
export const GET_BRANCHLIST = 'GET_BRANCHLIST';
export const FAILURE_BRANCHLIST = 'FAILURE_BRANCHLIST';


export const get_branch_list = ()=>{
    return dispatch =>{
        dispatch({
            type: 'REQUEST_BRANCHLIST'
        });

        myaxios.get('company/branch/')
        .then((response)=>{
            dispatch({
                type: 'GET_BRANCHLIST',
                payload:response 
            }) 
        })
        .catch((error) =>{
            dispatch({
                type: 'FAILURE_BRANCHLIST',
                payload: error,
                
            })
        })
    }
}

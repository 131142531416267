import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { usePrevious } from '../../hook/usePrevious';
import PayrollDetail from './PayrollDetail';
import PayrollList from './PayrollList';
import PayrollPayment from './PayrollPayment';
import PayrollReport from './PayrollReport';
import PayrollPayslip from './PayrollPayslip';
import { get_payroll_instance } from '../../action/payroll/get_payroll_instance';
import { payroll_detail_get_aggridheader } from '../../action/payroll/payroll_detail_get_aggridheader';
import { get_payslip_employee_contract } from '../../action/payroll/get_payslip_employee_contract';
import { NewMainContext } from '../../context/NewMainContext';

const mapStateToProps=(state)=>({
    list:state.get_payroll_list.data,

})

const mapDispatchToProps=(dispatch)=>({
    get_payroll_instance:(id)=>{
        dispatch(get_payroll_instance(id))
    },
    get_payslip_employee_contract:(id)=>{
        dispatch(get_payslip_employee_contract(id))
    },
    payroll_detail_get_aggridheader:(id)=>{
        dispatch(payroll_detail_get_aggridheader(id))
    },
})


function Payroll(props){
    const {payrolllist_count,current_payroll,emppayroll,get_payroll_instance,get_payslip_employee_contract,payroll_detail_get_aggridheader } =props;
    const [next_item,setNextItem] = useState({payroll:'',payroll_name:'',payroll_type:''});
    const [prev_item,setPrevItem] = useState({payroll:'',payroll_name:'',payroll_type:''});
    const {payroll_id, payroll_step, payslip_emp_id ,setPayrollID, setPayrollStep, setPayslipEmpID, setPayrollType } = useContext(NewMainContext);
    const prevEmpPayroll = usePrevious(emppayroll);
    const prevPayrollListCount = usePrevious(payrolllist_count);
    const prevCurrentPayroll = usePrevious(current_payroll);

    useEffect(()=>{
        if(payroll_id !== null && payroll_id !== undefined){
            let idd = localStorage.getItem('payroll_id')
            if(idd == null){
                get_payslip_employee_contract(payroll_id);
            }else{
                get_payslip_employee_contract(idd);
            }
            get_payroll_instance(payroll_id);
            
            // payroll_detail_get_aggridheader(payroll_id);
        }
        else{
            setPayrollID(localStorage.getItem(payroll_id))
        }
    },[payroll_id,get_payroll_instance,get_payslip_employee_contract])

    useEffect(()=>{
        if(payroll_id !=='' && props.list.length !== 0 && payroll_id !== null && payroll_id !== undefined){
            localStorage.setItem('payroll_id',payroll_id)
            const index= props.list.findIndex(item=>item.id === payroll_id);
            let next = {payroll:'',payroll_name:'',payroll_type:''};
            let prev = {payroll:'',payroll_name:'',payroll_type:''};
            if(index !== 0){
                const next_data = props.list[index-1];
                next.payroll = next_data.id;
                next.payroll_name = next_data.payroll_date;
                if(next_data.type==='BONUS'){
                next.payroll_type = 'Bonus'
                }else if(next_data.type==='HMTH1'){
                next.payroll_type = 'Half of'
                }
                else{next.payroll_type=''}
                // next.payroll_type = next_data.type;
            }
            if(index !== props.list.length -1){
                const prev_data = props.list[index+1];
                prev.payroll = prev_data.id;
                prev.payroll_name = prev_data.payroll_date;
                if(prev_data.type==='BONUS'){
                prev.payroll_type = 'Bonus'
                }else if(prev_data.type==='HMTH1'){
                prev.payroll_type = 'Half of'
                }
                else{prev.payroll_type=''}
            }
            setPrevItem(prev);
            setNextItem(next);
        }
    },[payroll_id,props.list])
    // },[payroll_id, props.list])

    useEffect(()=>{
        if(prevEmpPayroll !== undefined && prevEmpPayroll !== emppayroll && emppayroll !== ''){
            const {payroll,id} = emppayroll;
            setPayrollStep('4');
            setPayrollID(payroll);
            setPayslipEmpID(id);
            localStorage.setItem('payroll_step','4');
            localStorage.setItem('payroll_payslip_id',payroll);
            localStorage.setItem('payslip_emp_id',id);
        }
    },[emppayroll,prevEmpPayroll,setPayrollID,setPayrollStep,setPayslipEmpID,setPayrollType])

    useEffect(()=>{
        if(prevPayrollListCount !== undefined && prevPayrollListCount !== payrolllist_count && payrolllist_count !== ''){
            setPayrollStep(payrolllist_count.step);
        }
    },[payrolllist_count,prevPayrollListCount,setPayrollStep])

    useEffect(()=>{
        if(prevCurrentPayroll !== undefined && prevCurrentPayroll !== current_payroll && current_payroll !== ''){
            const {step:step1,payroll} = current_payroll;
            setPayrollStep(step1);
            localStorage.setItem('payroll_step',step1);
            setPayrollID(payroll);
            localStorage.setItem('payroll_id',payroll);
        }
    },[current_payroll,prevCurrentPayroll,setPayrollStep,setPayrollID])

    const handlePayroll=({step,id,type,status})=>{
        localStorage.setItem('payroll_step',step);
        setPayrollStep(step);
        localStorage.setItem('payroll_type',type);
        setPayrollType(type);
        localStorage.setItem('payroll_status',status);

        localStorage.setItem('payroll_id',id);
        setPayrollID(id);
    }

    const payrollInfo=(id)=>{
        const detail = props.list.find((item)=>item.id === id);
        if(detail){
            const status = detail.status;
            const type = detail.type
            handlePayroll({step:status === 'PAID' || status === 'LOCK' ? '2' : '1',id,type});
        }
    }

    const toPayrollPayslip=({step:step1,id,emp})=>{
        localStorage.setItem('payroll_step',step1);
        setPayrollStep(step1);
        localStorage.setItem('payslip_emp_id',emp);
        setPayslipEmpID(emp);
        localStorage.setItem('payroll_payslip_id',id);
        setPayrollID(id);
    }

    return(
        <>  
            {payroll_step === '0' &&
            <PayrollList setDetail={handlePayroll}  />}
            {/* {payroll_step === '0' &&
            <PayrollListAggrid setDetail={handlePayroll}  />} */}
            {payroll_step === '1' &&
            <PayrollDetail
            id={payroll_id}
            prev_item={prev_item} 
            next_item={next_item} 
            payrollInfo={payrollInfo}
            handlePayroll={handlePayroll} // toReportPage //toSummaryPage // toPayrollList
            toPayrollPayslip={toPayrollPayslip}
            />}
            {payroll_step === '2' &&
            <PayrollPayment
            id={payroll_id}
            payrollInfo={payrollInfo}
            prev_item={prev_item} 
            next_item={next_item}
            handlePayroll={handlePayroll}
            toPayrollPayslip={toPayrollPayslip}
            />}
            {payroll_step === '3' &&
            <PayrollReport
            handlePayroll={handlePayroll} 
            />}
            {payroll_step === '4' &&
            <PayrollPayslip
            id={payroll_id}
            emp={payslip_emp_id}
            goEmpOtherPayslip={(payroll)=>{
                setPayrollID(payroll);
                localStorage.setItem('payroll_id',payroll);
            }}
            handlePayroll={handlePayroll}
            setEmp={(id)=>{
                setPayslipEmpID(id);
                localStorage.setItem('payslip_emp_id',id)
            }}
            />}
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(Payroll);
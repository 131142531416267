import * as applyleavereset from '../../action/leave/apply_leave_reset';


const initialState={
    isLoading:false,
    data:null,
    errors:null,

}

const apply_leave_reset = (state=initialState,action)=>{
    switch(action.type){
        case applyleavereset.APPLYLEAVERESET_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case applyleavereset.APPLYLEAVERESET_SUCCESS:
            return{
                ...state,
                data:action.payload,
                isLoading:false,
            }
        case applyleavereset.APPLYLEAVERESET_FAILURE:
            return{
                ...state,
                errors:action.payload?.response?.data,
                isLoading:false
            }
        case applyleavereset.APPLYLEAVERESET_RESET:
            return{
                ...state,
                data:null,
                errors:null,
            }
        default:
            return state;
    }
}
export default apply_leave_reset;
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';

export const CONTRACTINPAYVALIDATEDAILY_REQUEST = 'CONTRACTINPAYVALIDATEDAILY_REQUEST';
export const CONTRACTINPAYVALIDATEDAILY_SUCCESS = 'CONTRACTINPAYVALIDATEDAILY_SUCCESS';
export const CONTRACTINPAYVALIDATEDAILY_FAILURE = 'CONTRACTINPAYVALIDATEDAILY_FAILURE';


export const create_contract_increment_pay_validate_daily =(data)=>{
    return dispatch=>{
        dispatch({
            type:'CONTRACTINPAYVALIDATEDAILY_REQUEST'
        });

        myaxios.post('contract/incpay/validate/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'CONTRACTINPAYVALIDATEDAILY_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'CONTRACTINPAYVALIDATEDAILY_FAILURE',
                payload:error
            });
            ErrToast.fire({
                type:'error',
                title:IMLocalized('validate_failure')
            });
        })
    }
}

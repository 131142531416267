import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import { getDateFormat } from "../../../func/getDateFormat";
import { IMLocalized } from "../../../language/IMLocalized";


const mapStateToProps=(state)=>({
    passportlist:state.get_employee_passport.data,
    emplist:state.get_employee_list.data,
    isLoading1:state.get_employee_list.isLoading,
    isLoading:state.get_employee_passport.isLoading,
})

const mapDispatchToProps=(dispatch)=>({

})

function ExpiryPassportTable(props){
    
    const [list,setList] = useState([]);

    useEffect(()=>{
        const arr = [];
        if(props.passportlist.length !== 0 && props.emplist.length !== 0){
            const new_date = moment().format('YYYY-MM-DD');
            for (const item of props.passportlist){
                const new_date2 = moment(new_date,'YYYY-MM-DD').add(6,'M').endOf('month');
                const date2 = new_date2.format('YYYY-MM-DD');

                const emp_detail = props.emplist.find(element=>element.id === item.employee);
                if(new Date(item.expiry_date) <= new Date(date2)){
                    arr.push({...item,name:emp_detail?.name ?? '-',employee_number:emp_detail?.employee_number??'-'});

                }
            }
        }
        setList(arr);
    },[props.passportlist,props.emplist])


    return(
        <Row>
            <Col>
                <Card style={{height:'270px'}}>
                    <CardHeader className="border-0 pp-2 bg-white">
                        <h3 className="title-color1">
                            {IMLocalized('passport_expire_soon')}
                        </h3>
                    </CardHeader>
                    {props.isLoading || props.isLoading1  ? <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>:null}
                    {!props.isLoading && !props.isLoading1 && list.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                    {!props.isLoading && !props.isLoading1 && list.length !== 0 && 
                    <Table className="align-items-center" size="sm" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th>{IMLocalized('emp_id')}</th>
                                <th>{IMLocalized('name')}</th>
                                <th>{IMLocalized('expiry_date')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((item,index)=>{
                                return(
                                    <tr key={index}>
                                        <td>{item.employee_number}</td>
                                        <td>{item.name}</td>
                                        <td>{getDateFormat(item.expiry_date)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>}
                </Card>
            </Col>
        </Row>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ExpiryPassportTable);
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Modal , ModalBody, ModalFooter, ModalHeader , Row , Col, CardBody, FormFeedback , Card , FormGroup , Input , Table } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { employee_contract_additional_cost } from '../../../../action/employee/employee_contract_additional_cost';
import { employee_contract_department } from '../../../../action/employee/employee_contract_department';
import { employee_contract_section } from '../../../../action/employee/employee_contract_section';
import { employee_contract_costcenter } from '../../../../action/employee/employee_contract_costcenter';
import { employee_contract_joblevel } from '../../../../action/employee/employee_contract_joblevel';
import { employee_contract_jobtitle } from '../../../../action/employee/employee_contract_jobtitle';
import { update_employee_contract } from '../../../../action/employee/update_employee_contract';
import { get_additional_setting } from '../../../../action/additional_setting';
import { popError } from '../../../../func/popError';
import { usePrevious } from '../../../../hook/usePrevious';


const mapStateToProps=(state)=>({
    templatelist:state.get_contract_template_list.data,
    contract_typelist:state.get_contract_template_dropdown.contract_type,
    contract_ratelist:state.get_contract_template_dropdown.contract_rate,
    schedulelist:state.schedule_list.data,
    currencylist:state.general_currency.data,
    departmentlist:state.add.department,
    sectionlist:state.add.section,
    jobtitlelist:state.add.jobtitle,
    joblevellist:state.add.joblevel,
    costcenterlist:state.add.costcenter,
    allowancelist:state.add.allowance,
    recurring_list:state.add.recurringallowance,
    additionalcost:state.employee_contract_additional_cost.data,
    department_success:state.employee_contract_department.data,
    section_success:state.employee_contract_section.data,
    joblevel_success:state.employee_contract_joblevel.data,
    jobtitle_success:state.employee_contract_jobtitle.data,
    costcenter_success:state.employee_contract_costcenter.data,
    update_errors:state.update_employee_contract.errors,
    isLoading:state.update_employee_contract.isLoading,
})

const mapDispatchToProps=(dispatch)=>({
    employee_contract_additional_cost:(id,pay)=>{
        dispatch(employee_contract_additional_cost(id,pay))
    },
    employee_contract_section:(value)=>{
        dispatch(employee_contract_section(value))
    },
    employee_contract_department:(value)=>{
        dispatch(employee_contract_department(value))
    },
    employee_contract_joblevel:(value)=>{
        dispatch(employee_contract_joblevel(value))
    },
    employee_contract_jobtitle:(value)=>{
        dispatch(employee_contract_jobtitle(value))
    },
    employee_contract_costcenter:(value)=>{
        dispatch(employee_contract_costcenter(value))
    },
    update_employee_contract:(data)=>{
        dispatch(update_employee_contract(data))
    },
    get_additional_setting:()=>{
        dispatch(get_additional_setting())
    }
})

function ModalEditContract(props){
    const {update_errors,employee_contract_additional_cost,additionalcost,department_success,section_success,joblevel_success,jobtitle_success,costcenter_success,update_employee_contract,employee_contract_costcenter,employee_contract_department,employee_contract_joblevel,employee_contract_jobtitle,employee_contract_section,get_additional_setting} = props;
    const [contract_rate,setRate] = useState('');
    const [contract_type,setType] = useState('');
    const [hourly_rate,setHourlyRate] = useState(0);
    const [daily_rate,setDailyRate] = useState(0);
    const [pay,setPay] = useState('');
    const [is_workman,setWorkman] = useState(false);
    const [schedule,setSchedule] = useState(null);
    const [currency,setCurrency] = useState(null);
    const [currency_toggle,setCurrencyToggle] = useState(false);
    const [job_level,setJobLevel] = useState(null);
    const [job_title,setJobTitle] = useState(null);
    const [cost_center,setCostCenter] = useState(null);
    const [department,setDepartment] = useState(null);
    const [section,setSection] = useState(null);
    const [allowance,setAllowance] = useState([]);
    const [recurring_allowance,setRecurring] = useState([]);
    const [start_date,setStartDate] = useState('');
    const [err_start_date,setErrStartDate] = useState('');
    const [err_contract_type,setErrType] = useState('');
    const [notes,setNotes] = useState('');
    const [err_notes,setErrNotes] =  useState('');
    const [err_contract_rate,setErrRate] = useState('');
    const [err_pay,setErrPay] = useState('');
    const [err_hourly_rate,setErrHourlyRate] = useState('');
    const [err_schedule,setErrSchedule] = useState('');
    const [err_daily_rate,setErrDailyRate] = useState('');
    const [err_hours_day,setErrHoursDay] = useState('');
    const [hours_day,setHoursDay] = useState('');
    const [err_currency,setErrCurrency] = useState('');
    const [err_department,setErrDepartment] = useState('');
    const [err_section,setErrSection] = useState('');
    const [err_cost_center,setErrCostCenter] = useState('');
    const [err_job_level,setErrJobLevel] = useState('');
    const [err_job_title,setErrJobTitle] = useState('');
    const [total_pay,setTotal] = useState('');
    const [additional,setAdditional] = useState(null);
    const prevAdditionalCost = usePrevious(additionalcost);
    const prevDepartment = usePrevious(department_success);
    const prevSection = usePrevious(section_success);
    const prevJobTitle = usePrevious(jobtitle_success);
    const prevJobLevel = usePrevious(joblevel_success);
    const prevCostCenter = usePrevious(costcenter_success);
    const prevErrors = usePrevious(update_errors)

    const onCalculate= useCallback(()=>{
        if(pay !== ''){
            let recurring_amount = 0;
            if(recurring_allowance.length !== 0){
                recurring_allowance.forEach(function(item){
                    const value = item.value;
                    const detail = props.recurring_list.find((item1)=>item1.name === value);
                    if(detail){
                        recurring_amount += parseFloat(detail.amount);
                    } 
                })
            }
            const total = recurring_amount + parseFloat(pay)
            setTotal(total);
            employee_contract_additional_cost(props.id,total);
        }
        else{
            setTotal(0);
        }
    },[recurring_allowance,pay,employee_contract_additional_cost,props.id,props.recurring_list])

    const updateContract = useCallback(()=>{
        let department1 = null;
        if(department !== null){
            department1 = department.value;
        }

        let section1 = null;
        if(section !== null){
            section1 = section.value;
        }

        let joblevel1 = null;
        if(job_level !== null){
            joblevel1 = job_level.value;
        }

        let jobtitle1 = null;
        if(job_title !== null){
            jobtitle1 = job_title.value;
        }

        let costcenter1 = null;
        if(cost_center !== null){
            costcenter1 = cost_center.value;
        }

        const recurring_allowances = recurring_allowance.map((item)=>{
            return item.value
        })

        const allowances = allowance.map((item)=>{
            return item.value
        })
        const data = [{daily_rate,currency,hours_day,contract_type,contract_rate,job_level:joblevel1,job_title:jobtitle1,department:department1,section:section1,cost_center:costcenter1,wschedule:schedule,id:props.detail.id,notes,is_workman,allowances,recurring_allowances,start_date,pay,hourly_rate,employee:props.id}]
        update_employee_contract(data);
    },[update_employee_contract,daily_rate,currency,start_date,pay,hourly_rate,schedule,job_level,job_title,department,section,cost_center,allowance,contract_rate,contract_type,notes,props.id,recurring_allowance,props.detail.id,hours_day,is_workman])

    const checkCostCenter =useCallback(()=>{
        if(cost_center !== null){
            const found = props.costcenterlist.some((item1)=>item1.name === cost_center.value);
            if(!found){
                employee_contract_costcenter(cost_center.value);
            }
            else{
                updateContract();
            }
        }
        else{
            updateContract();
        }
    },[employee_contract_costcenter,updateContract,props.costcenterlist,cost_center])

    const checkJobTitle = useCallback(()=>{
        if(job_title !== null){
            const found = props.jobtitlelist.some((item1)=>item1.name === job_title.value);
            if(!found){
                employee_contract_jobtitle(job_title.value);
            }
            else{
                checkCostCenter();
            }
        }
        else{
            checkCostCenter();
        }
    },[employee_contract_jobtitle,checkCostCenter,props.jobtitlelist,job_title])

    const checkJobLevel =useCallback(()=>{
        if(job_level !== null){
            const found = props.joblevellist.some((item1)=>item1.name === job_level.value);
            if(!found){
                employee_contract_joblevel(job_level.value);
            }
            else{
                checkJobTitle();
            }
        }
        else{
            checkJobTitle();
        }
    },[employee_contract_joblevel,checkJobTitle,props.joblevellist,job_level])

    const checkSection=useCallback(()=>{
        if(section !== null){
            const found = props.sectionlist.some((item1)=>item1.name === section.value);
            if(!found){
                employee_contract_section(section.value);
            }
            else{
                checkJobLevel();
            }
        }
        else{
            checkJobLevel();
        }
    },[employee_contract_section,checkJobLevel,props.sectionlist,section])

    const checkDepartment=useCallback(()=>{
        if(department !== null){
            const found = props.departmentlist.some((item1)=>item1.name === department.value);
            if(!found){
                employee_contract_department(department.value);
            }
            else{
                checkSection();
            }
        }
        else{
            checkSection();
        }
    },[employee_contract_department,checkSection,props.departmentlist,department])

    useEffect(()=>{
        if(props.toggle && props.detail){
            setNotes(props.detail.notes);
            setErrNotes('');
            setStartDate(props.detail.start_date);
            setErrStartDate('');
            setType(props.detail.contract_type);
            setErrType('');
            setRate(props.detail.contract_rate);
            setErrRate('');
            setPay(props.detail.pay);
            setErrPay('');
            setWorkman(props.detail.is_workman);
            setSchedule(props.detail.wschedule);
            setErrSchedule('');
            setHourlyRate(props.detail.hourly_rate);
            setErrHourlyRate('');
            setDailyRate(props.detail.daily_rate);
            setErrDailyRate('');
            setHoursDay(props.detail.hours_day);
            setErrHoursDay('');
            if(props.detail.currency){
                setCurrency(props.detail.currency.id);
                setCurrencyToggle(true);
            }
            else{
                setCurrency(null);
                setCurrencyToggle(false)
            }
            setErrCurrency('');
            if(props.detail.department){
                setDepartment({value:props.detail.department,label:props.detail.department});
            }
            else{
                setDepartment(null);
            }
            setErrDepartment('');

            if(props.detail.section){
                setSection({value:props.detail.section,label:props.detail.section});
            }
            else{
                setSection(null);
            }
            setErrSection('');


            if(props.detail.cost_center){
                setCostCenter({value:props.detail.cost_center,label:props.detail.cost_center});
            }
            else{
                setCostCenter(null);
            }
            setErrCostCenter('');

            if(props.detail.job_level){
                setJobLevel({value:props.detail.job_level,label:props.detail.job_level});
            }
            else{
                setJobLevel(null);
            }
            setErrJobLevel('');

            if(props.detail.job_title){
                setJobTitle({value:props.detail.job_title,label:props.detail.job_title});
            }
            else{
                setJobTitle(null);
            }
            setErrJobTitle('');

            if(props.detail.allowances.length >0){
                const allowance_data = props.detail.allowances.map((item)=>{
                    return {label:item,value:item}
                })

                setAllowance(allowance_data)
            }
            else{
                setAllowance([]);
            }

            if(props.detail.recurring_allowances.length >0){
                const recurring_data = props.detail.recurring_allowances.map((item)=>{
                    return {label:item,value:item}
                })

                setRecurring(recurring_data)
            }
            else{
                setRecurring([]);
            }
        }
    },[props.toggle,props.detail])

    useEffect(()=>{
        if(prevErrors !== undefined && update_errors !== prevErrors && update_errors !== undefined && update_errors !== null){
            // const errors = update_errors.response.data;
            const errors = update_errors.response;
            if('non_field_errors' in errors){
                const word = errors['non_field_errors'].toString();
                popError(word)
            }
            if('daily_rate' in errors){
                const word = errors['daily_rate'].toString();
                setErrDailyRate(word);
            }
            if('hourly_rate' in errors){
                const word = errors['hourly_rate'].toString();
                setErrHourlyRate(word);
            }
            if('contract' in errors){
                const word = errors['contract'].toString();
                popError(word);
            }
            get_additional_setting();
        }
        
    },[update_errors,prevErrors,get_additional_setting])

    useEffect(()=>{
        if(props.toggle && props.additionalcost && prevAdditionalCost !== undefined){
            setAdditional(props.additionalcost);
        }
        else{
            setAdditional(null);
        }
    },[props.additionalcost,props.toggle,prevAdditionalCost])

    useEffect(()=>{
        onCalculate();
    },[pay,recurring_allowance,onCalculate])

    useEffect(()=>{
        if(prevDepartment !== undefined && department_success !== prevDepartment && props.toggle){
            checkSection();
        }
    },[prevDepartment,department_success,props.toggle,checkSection])

    useEffect(()=>{
        if(prevSection !== undefined && section_success !== prevSection && props.toggle){
            checkJobLevel();
        }
    },[prevSection,section_success,props.toggle,checkJobLevel])

    useEffect(()=>{
        if(prevJobLevel !== undefined && joblevel_success !== prevJobLevel && props.toggle){
            checkJobTitle();
        }
    },[prevJobLevel,joblevel_success,props.toggle,checkJobTitle])

    useEffect(()=>{
        if(prevJobTitle !== undefined && jobtitle_success !== prevJobTitle && props.toggle){
            checkCostCenter();
        }
    },[prevJobTitle,jobtitle_success,props.toggle,checkCostCenter])

    useEffect(()=>{
        if(prevCostCenter !== undefined && costcenter_success !== prevCostCenter && props.toggle){
            updateContract();
        }
    },[prevCostCenter,costcenter_success,props.toggle,updateContract])

    const handleRate = (rate)=>{
        if(rate !== ''){
            if(rate === 'MONTHLY'){
                setDailyRate('');
                setHoursDay(0);
                setPay('');
                setHourlyRate('');
                setErrDailyRate('');
                setErrHoursDay('');
                setErrPay('');
                setErrHourlyRate('');
            }
            else if (rate === 'HOURLY'){
                setDailyRate(0);
                setHoursDay(0);
                setPay(0);
                setHourlyRate('');
                setErrDailyRate('');
                setErrHoursDay('');
                setErrPay('');
                setErrHourlyRate('');
            }
            else if ( rate === 'DAILY'){
                setHourlyRate(0);
                setDailyRate('');
                setHoursDay('');
                setPay(0);
                setErrHourlyRate('');
                setErrDailyRate('');
                setErrHoursDay('');
                setErrPay('');
            }
        }
    }

    const calculateDaily=()=>{
        if(pay !== '' && schedule !== ''){
            let work_days_in_week = 0;
            const detail = props.schedulelist.find((item1)=>item1.id === parseInt(schedule));
            if(detail){
                work_days_in_week = detail.work_days_in_week;
            }
            if(pay !== 0 && work_days_in_week !== 0){
                const total = (pay*12)/(52*work_days_in_week);
                const num = parseFloat(total.toFixed(4));
                setDailyRate(num);
                setErrDailyRate('');
            }
        }
        else{
            setDailyRate(0.0000);
        }
    }

    const handleBlur=()=>{
        calculateDaily();
        onCalculate();
    }

    const handlePay=(e)=>{
        const target = e.target;
        const value = target.value;
        let hourly_rate1  = (value*12)/(52*44);
        if(is_workman){
            if(value >4500){
                hourly_rate1 = (4500*12)/(52*44);
            }
        }
        else{
            if(value >=2600){
                hourly_rate1 = 13.60;
            }
        }
        setHourlyRate(hourly_rate1.toFixed(8));
        setPay(value);
        setErrPay('');
        setErrHourlyRate('');
    }

    //Daily Rate
    const handlePay1=(e)=>{
        const target = e.target;
        const value = target.value;
        let hourly_rate1  = value/hours_day;
        setHourlyRate(hourly_rate1.toFixed(8));
        setPay(0);
        setErrPay('');
        setErrHourlyRate('');
    }

    //Hours per day
    const handlePay2=(e)=>{
        const target = e.target;
        const value = target.value;
        let hourly_rate1  = daily_rate/value;
        setHoursDay(value);
        setHourlyRate(hourly_rate1.toFixed(8));
        setPay(0);
        setErrPay('');
        setErrHourlyRate('');
    }



    const handleCheckbox=(e)=>{
        const target = e.target;
        const checked = target.checked;
        if(pay !== ''){
            let rate = (parseFloat(pay)*12)/(52*44);
            if(checked === true){
                if(pay > 4500){

                    rate = (4500*12)/(52*44);
                }
            }
            else{
                if(pay >=2600){
                    rate = 13.60;
                }
            }
            setHourlyRate(rate.toFixed(8));
            setErrHourlyRate('');
        }
        setWorkman(checked);
    }

    const handleCurrency = (e)=>{
        const target = e.target;
        const checked = target.checked;
        if(!checked){
            setCurrency(null);
            setErrCurrency('');
        }
        setCurrencyToggle(checked);
    }

    const onUpdate=()=>{
        if(contract_rate !== ''){
            if(contract_rate === 'MONTHLY'){
                if(pay !== '' && start_date !== '' && hourly_rate !== '' && schedule !== '' && contract_type !== '' && department !== null ){
                   Promise.resolve(()=>setHoursDay(0))
                   .then(()=>checkDepartment())
                }
                else{
                    if(pay === ''){
                        setErrPay(IMLocalized('pay_is_required'))
                    }
                    if(start_date === ''){
                        setErrStartDate(IMLocalized('start_date_is_required'));
                    }
                    if(schedule === ''){
                        setErrSchedule(IMLocalized('scheudule_is_required'));
                    }
                    if(contract_type === ''){
                        setErrRate(IMLocalized('contract_type_is_required'));
                    }
                    if(hourly_rate === ''){
                        setErrHourlyRate(IMLocalized('hourly_rate_is_required'));
                    }
                    if(daily_rate === ''){
                        setErrDailyRate(IMLocalized('daily_rate_is_required'));
                    }
                    if(department === null){
                        setErrDepartment(IMLocalized('department_is_required'));
                    }
                }
            }
            else if (contract_rate === 'HOURLY'){
                if(start_date !== '' && contract_type !== '' && schedule !== '' && hourly_rate !=='' && department !== null ){
                    Promise.resolve(()=>{
                        setPay(0);
                        setDailyRate(0);
                        setHoursDay(0);
                    })
                    .then(()=>checkDepartment())
                }
                else{
                    if(start_date === ''){
                        setErrStartDate(IMLocalized('start_date_is_required'));
                    }
                    if(schedule === ''){
                        setErrSchedule(IMLocalized('scheudule_is_required'));
                    }
                    if(contract_type === ''){
                        setErrRate(IMLocalized('contract_type_is_required'));
                    }
                    if(hourly_rate === ''){
                        setErrHourlyRate(IMLocalized('hourly_rate_is_required'));
                    }
                    if(department === null){
                        setErrDepartment(IMLocalized('department_is_required'));
                    }
                }
            }
            else if(contract_rate === 'DAILY'){
                if(hours_day !== '' && start_date !== '' && contract_type !== '' && schedule !== '' && daily_rate !== '' && department !== null){
                    Promise.resolve(()=>{
                        setPay(0);
                        setHourlyRate(0);
                    })
                    .then(()=>checkDepartment())
                }
                else{
                    if(hours_day === ''){
                        setErrHoursDay(IMLocalized('hours_per_day_required'));
                    }
                    if(start_date === ''){
                        setErrStartDate(IMLocalized('start_date_is_required'));
                    }
                    if(schedule === ''){
                        setErrSchedule(IMLocalized('scheudule_is_required'));
                    }
                    if(contract_type === ''){
                        setErrRate(IMLocalized('contract_type_is_required'));
                    }
                    if(daily_rate === ''){
                        setErrDailyRate(IMLocalized('daily_rate_is_required'));
                    }
                    if(department === null){
                        setErrDepartment(IMLocalized('department_is_required'));
                    }
                }
            }
        }
        else{
            setErrRate(IMLocalized('contract_rate_is_required'));
        }
    }

    return(
        <Modal isOpen={props.toggle} className="modal-dialog-centered" style={{maxWidth: '1600px', width: '90%'}}>
            <ModalHeader toggle={props.closeModal}>{IMLocalized('edit_contract')} - {props.emp_number} - {props.name} </ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="9">
                        <Card>
                            <CardBody style={{backgroundColor:'#e9eff6'}}>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='notes'>{IMLocalized('note')}</label>
                                            <Input size="sm" type="textarea" name="notes" id="notes" value={notes} onChange={(e)=>{setNotes(e.target.value); setErrNotes('')}} invalid={err_notes.length > 0 } />
                                            <FormFeedback>{err_notes}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='start_date'>{IMLocalized('start_date')}</label>
                                            <Input size="sm" type="date" name="start_date" id="start_date" value={start_date} onChange={(e)=>{setStartDate(e.target.value); setErrStartDate('')}}  invalid={err_start_date.length >0} disabled={props.is_first} />
                                            <FormFeedback>{err_start_date}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='contract_type'>{IMLocalized('contract_type')}</label>
                                            <Input size="sm" type="select" name="contract_type" id="contract_type" value={contract_type} onChange={(e)=>{setType(e.target.value); setErrType('')}}  invalid={err_contract_type.length >0}>
                                                <option></option>
                                                {props.contract_typelist.map((item,index)=>{
                                                    return <option value={item.value} key={index}>{item.display}</option>
                                                })}
                                            </Input>
                                            <FormFeedback>{err_contract_type}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='contract_rate'>{IMLocalized('contract_rate')}</label>
                                            <Input size="sm" type="select" name="contract_rate" id="contract_rate" value={contract_rate} onChange={(e)=>{setRate(e.target.value); setErrRate(''); handleRate(e.target.value)}}  invalid={err_contract_rate.length >0}>
                                                <option></option>
                                                {props.contract_ratelist.map((item,index)=>{
                                                    return <option value={item.value} key={index}>{item.display}</option>
                                                })}
                                            </Input>
                                            <FormFeedback>{err_contract_rate}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    {contract_rate === 'MONTHLY' &&
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='basic_pay'>{IMLocalized('basic_pay')}</label>
                                            <Input size="sm" type="number" value={pay} onChange={(e)=>handlePay(e)} name="basic_pay" id="basic_pay" invalid={err_pay.length >0} onBlur={handleBlur} />
                                            <FormFeedback>{err_pay}</FormFeedback>
                                        </FormGroup>
                                    </Col>}
                                    <Col lg="6" className="d-flex align-items-center">
                                        <div className='custom-control custom-checkbox checkboxes'>
                                            <input type="checkbox" id='editcontract_isworkman' name='is_workman' className="custom-control-input" checked={is_workman} onChange={(e)=>handleCheckbox(e)} />
                                            <label className="custom-control-label" htmlFor="editcontract_isworkman">{IMLocalized('is_workman')}</label>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor='schedule'>{IMLocalized('schedule')}</label>
                                            <Input type="select" size="sm"  name="schedule" id="schedule" value={schedule} onChange={(e)=>{setSchedule(e.target.value); setErrSchedule('')}} invalid={err_schedule.length>0} onBlur={calculateDaily} >
                                                <option></option>
                                                {props.schedulelist.map((item,index)=>{
                                                    return <option value={item.id} key={index}>{item.name}</option>
                                                })}
                                            </Input>
                                            <FormFeedback>{err_schedule}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    {contract_rate === 'MONTHLY' &&
                                    <>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='hourly_rate'>{IMLocalized('hourly_rate')}</label>
                                            <Input size="sm" name="hourly_rate" id="hourly_rate" value={hourly_rate} onChange={(e)=>{setHourlyRate(e.target.value); setErrHourlyRate('')}} invalid={err_hourly_rate.length >0} />
                                            <FormFeedback>{err_hourly_rate}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='daily_rate'>{IMLocalized('daily_rate')}</label>
                                            <Input size="sm" name="daily_rate" id="daily_rate" value={daily_rate} onChange={(e)=>{setDailyRate(e.target.value); setErrDailyRate('')}} invalid={err_daily_rate.length >0} />
                                            <FormFeedback>{err_daily_rate}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    </>}
                                    {contract_rate === 'DAILY' &&
                                    <>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor='hours_per_day'>{IMLocalized('hours_per_day')}</label>
                                            <Input size="sm" type="number" name="hours_day" id="hours_per_day" value={hours_day} onChange={(e)=>{setHoursDay(e.target.value); setErrHoursDay(''); handlePay2(e)}} invalid={err_hours_day.length>0} />
                                            <FormFeedback>{err_hours_day}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='daily_rate'>{IMLocalized('daily_rate')}</label>
                                            <Input size="sm" name="daily_rate" id="daily_rate" value={daily_rate} onChange={(e)=>{setDailyRate(e.target.value); setErrDailyRate(''); handlePay1(e)}} invalid={err_daily_rate.length >0} />
                                            <FormFeedback>{err_daily_rate}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='hourly_rate'>{IMLocalized('hourly_rate')}</label>
                                            <Input size="sm" name="hourly_rate" id="hourly_rate" value={hourly_rate} onChange={(e)=>{setHourlyRate(e.target.value); setErrHourlyRate('')}} invalid={err_hourly_rate.length >0} />
                                            <FormFeedback>{err_hourly_rate}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    </>}
                                    {contract_rate === 'HOURLY' &&
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='hourly_rate'>{IMLocalized('hourly_rate')}</label>
                                            <Input size="sm" name="hourly_rate" id="hourly_rate" value={hourly_rate} onChange={(e)=>{setHourlyRate(e.target.value); setErrHourlyRate('')}} invalid={err_hourly_rate.length >0} />
                                            <FormFeedback>{err_hourly_rate}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    }
                                    <Col lg="6" className="d-flex align-items-center">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" id='editcurrency_toggle' name='curreny_toggle' className="custom-control-input" checked={currency_toggle} onChange={(e)=>{handleCurrency(e)}} />
                                            <label className="custom-control-label" htmlFor="editcurrency_toggle">{IMLocalized('foreign_currency')}?</label>
                                        </div>
                                    </Col>
                                    {currency_toggle &&
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='currency'>{IMLocalized('currency')}</label>
                                            <Input type="select" size="sm"  name="currency" value={currency} onChange={(e)=>{setCurrency(e.target.value); setErrCurrency('')}} invalid={err_currency.length>0} >
                                                <option></option>
                                                {props.currencylist.map((item,index)=>{
                                                    return (
                                                        <option value={item.id} key={index}>{item.currency_name}</option>
                                                    )
                                                })}
                                            </Input>
                                            <FormFeedback>{err_currency}</FormFeedback>
                                        </FormGroup>
                                    </Col>}
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor='department'>{IMLocalized('department')}</label>
                                            <CreatableSelect id="department" styles={{
                                            control: (provided, state) => (err_department.length >0 ? {
                                            ...provided, borderColor: '#dc3545', 
                                            } : provided)
                                            }} isClearable placeholder={IMLocalized('select1')} 
                                            options={props.departmentlist.map(item=>{return {value:item.name,label:item.name}})} 
                                            value={department} 
                                            onChange={(value)=>{setDepartment(value); setErrDepartment('')}} />
                                            <div style={{color:'#dc3545',fontSize:'12px'}}>{err_department}</div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor='section'>{IMLocalized('section')}</label>
                                            <CreatableSelect id="section" styles={{
                                            control: (provided, state) => (err_section.length >0 ? {
                                            ...provided, borderColor: '#dc3545', 
                                            } : provided)
                                            }} isClearable placeholder={IMLocalized('select1')} 
                                            options={props.sectionlist.map(item=>{return {value:item.name,label:item.name}})} 
                                            value={section} 
                                            onChange={(value)=>{setSection(value);setErrSection('')}} />
                                            <div style={{color:'#dc3545',fontSize:'12px'}}>{err_section}</div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor='cost_center'>{IMLocalized('cost_center')}</label>
                                            <CreatableSelect id="cost_center" styles={{
                                            control: (provided, state) => (err_cost_center.length >0 ? {
                                            ...provided, borderColor: '#dc3545', 
                                            } : provided)
                                            }} isClearable placeholder={IMLocalized('select1')} 
                                            options={props.costcenterlist.map(item=>{return {value:item.name,label:item.name}})} 
                                            value={cost_center} 
                                            onChange={(value)=>{setCostCenter(value);setErrCostCenter('')}} />
                                            <div style={{color:'#dc3545',fontSize:'12px'}}>{err_cost_center}</div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor='job_title'>{IMLocalized('job_title')}</label>
                                            <CreatableSelect id="job_title" styles={{
                                            control: (provided, state) => (err_job_title.length >0 ? {
                                            ...provided, borderColor: '#dc3545', 
                                            } : provided)
                                            }} isClearable placeholder={IMLocalized('select1')} 
                                            options={props.jobtitlelist.map(item=>{return {value:item.name,label:item.name}})} 
                                            value={job_title} 
                                            onChange={(value)=>{setJobTitle(value);setErrJobTitle('')}} />
                                            <div style={{color:'#dc3545',fontSize:'12px'}}>{err_job_title}</div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor='job_level'>{IMLocalized('job_level')}</label>
                                            <CreatableSelect id="cost_center" styles={{
                                            control: (provided, state) => (err_job_level.length >0 ? {
                                            ...provided, borderColor: '#dc3545', 
                                            } : provided)
                                            }} isClearable placeholder={IMLocalized('select1')} 
                                            options={props.joblevellist.map(item=>{return {value:item.name,label:item.name}})} 
                                            value={job_level} 
                                            onChange={(value)=>{setJobLevel(value);setErrJobLevel('')}} />
                                            <div style={{color:'#dc3545',fontSize:'12px'}}>{err_job_level}</div>
                                        </FormGroup>
                                    </Col>
                                    <>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='allowance'>{IMLocalized('allowance')}</label>
                                            <Select id="allowance"  isMulti placeholder={IMLocalized('select1')}  
                                            options={props.allowancelist.map(item=>{
                                                return {value:item.name,label:item.name}
                                            })} className="basic-multi-select" classNamePrefix="select"  onChange={(value)=>{setAllowance(value)}} value={allowance} />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='recurring_allowance'>{IMLocalized('recurring_allowance')}</label>
                                            <Select id="recurring_allowance"  isMulti placeholder={IMLocalized('select1')}  
                                            options={props.recurring_list.map(item=>{
                                                return {value:item.name,label:item.name}
                                            })} className="basic-multi-select" classNamePrefix="select"  onChange={(value)=>{setRecurring(value)}} value={recurring_allowance} />
                                        </FormGroup>
                                    </Col>
                                    </>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="3">
                        <Card>
                            <CardBody style={{backgroundColor:'#e9eff6'}}>
                                <div><h4>{IMLocalized('summary')}</h4></div>
                                <Table  size="sm">
                                    <tbody>
                                        <tr>
                                            <td>{IMLocalized('cost_center')}</td>
                                            <td>{cost_center ? cost_center.value : '---'}</td>
                                        </tr>
                                        <tr>
                                        <td>{IMLocalized('gross_salary')}</td>
                                            {contract_rate === 'MONTHLY' ?
                                            <>
                                            <td>{total_pay !== '' ? '$ ' + total_pay : '---'}</td>
                                            </>
                                            :
                                            <>
                                            <td>---</td>
                                            </>
                                            }
                                        </tr>
                                        {additional && 'total' in additional && pay!== '' && 
                                        <tr>
                                            <td>{IMLocalized('annual_salary')}</td>
                                            {contract_rate === 'MONTHLY' ?
                                            <td>$ { (parseFloat(additional.total)*12).toFixed(2)}</td>
                                            :
                                            <td>---</td>
                                            }
                                        </tr>
                                        }
                                        {additional && 'cpf_employer' in additional &&  pay!== '' && 
                                        <tr>
                                            <td>Employer CPF</td>
                                            <td>$ { (parseFloat(additional.cpf_employer)*12).toFixed(2)}</td>
                                        </tr>
                                        }
                                        {additional && 'sdl' in additional && pay!== '' && 
                                        <tr>
                                            <td>SDL</td>
                                            <td>$ { (parseFloat(additional.sdl)*12).toFixed(2)}</td>
                                        </tr>
                                        }
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
            {props.isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
            <>
                <button className='btn btn-primary btn-sm' onClick={()=>onUpdate()}>{IMLocalized('update')}</button>
                <button className='btn btn-danger btn-sm' onClick={props.closeModal}>{IMLocalized('close')}</button>
            </>
            }
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalEditContract);
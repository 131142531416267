import React, { useEffect, useState } from 'react';
import { Input , Col, FormFeedback, FormGroup, Modal, ModalBody, ModalHeader, Row, ModalFooter } from 'reactstrap';
import { create_sg_child, reset_create_sg_child } from '../../../../action/employee/create_sg_child';
import { IMLocalized } from '../../../../language/IMLocalized';
import { popError } from '../../../../func/popError';
import { useDispatch, useSelector } from 'react-redux';


export default function ModalCreateChildren({ toggle, clear_step, children_id, closeModal, onSuccess, id, gender, setChildClearStep}){
    
    const dispatch = useDispatch();
    const success = useSelector(state=>state.create_sg_child.data);
    const isLoading = useSelector(state=>state.create_sg_child.isLoading);
    const errors = useSelector(state=>state.create_sg_child.errors);

    const [name,setName] = useState('');
    const [err_name,setErrName] = useState('');
    const [date_of_birth,setDate] = useState('');
    const [err_date_of_birth,setErrDate] = useState('');
    const [leave_start_next_year,setLeaveStart] = useState(false);
    const [is_sg,setSg] = useState(false);
    const [is_adopted,setAdopted] = useState(false);
    const [paternity,setPaternity] = useState(false);

    const applyChild=()=>{
        if(name !== '' && date_of_birth !== ''){
            const sg_maternity = children_id !== '' && children_id !== 'no' ? children_id : null;
            dispatch(create_sg_child(id,name,date_of_birth,leave_start_next_year,is_sg,sg_maternity,is_adopted,paternity))
        }
        else{
            if(name === ''){
                setErrName(IMLocalized('name_is_required'));
            }

            if(date_of_birth === ''){
                setErrDate(IMLocalized('date_of_birth_is_required'));
            }
        }
    }

    useEffect(()=>{
        if(toggle &&  clear_step){
            setName('');
            setErrName('');
            setDate('');
            setErrDate('');
            setLeaveStart(false);
            setSg(false);
            setAdopted(false);
            setPaternity(false);
            setChildClearStep(false);
        }
    },[toggle,clear_step, setChildClearStep])


    useEffect(()=>{
        if(  success !== null && toggle){
            onSuccess();
            dispatch(reset_create_sg_child());
        }
    },[success,toggle,onSuccess, dispatch])

    useEffect(()=>{
        if( errors!== null && toggle){

            if(errors.hasOwnProperty('non_field_errors')){
                const word = errors['non_field_errors'].toString();
                popError(word);
            }
            else if(errors.hasOwnProperty('paternity')){
                const word = errors['paternity'].toString();
                const string = IMLocalized(word)
                popError(string);
            }
            else if(errors.hasOwnProperty('date_of_birth')){
                const word = errors['date_of_birth'].toString();
                setErrDate(word);
            }
            dispatch(reset_create_sg_child());
        }
    },[errors,toggle, dispatch])
    
    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('add_children')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>{IMLocalized('child_name')}</label>
                            <Input id="name" size="sm" name="name" type="text" value={name} onChange={(e)=>{setName(e.target.value); setErrName('')}} invalid={err_name.length >0} />
                            <FormFeedback>{err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='date_of_birth'>{IMLocalized('date_of_birth')}</label>
                            <Input id="date_of_birth" size="sm" type="date" name="date_of_birth" value={date_of_birth} onChange={(e)=>{setDate(e.target.value); setErrDate('')}} invalid={err_date_of_birth.length >0} />
                            <FormFeedback>{err_date_of_birth}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <div className="custom-control custom-checkbox mt-1">
                            <input type="checkbox" id="emp_child_leave_start" name='leave_start_next_year' className="custom-control-input" checked={leave_start_next_year} onChange={(e)=>setLeaveStart(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="emp_child_leave_start">{IMLocalized('leave_start_next_year')}?</label>
                        </div>
                        <div className="custom-control custom-checkbox mt-1">
                            <input type="checkbox" id="emp_child_sg" name='is_sg' className="custom-control-input" checked={is_sg} onChange={(e)=>setSg(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="emp_child_sg"> {IMLocalized('is_sg')}</label>
                        </div>
                        <div className="custom-control custom-checkbox mt-1">
                            <input type="checkbox" id="emp_child_adopted" name='is_adopted' className="custom-control-input" checked={is_adopted} onChange={(e)=>setAdopted(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="emp_child_adopted">{IMLocalized('is_adopted')}?</label>
                        </div> 
                        <div className="custom-control custom-checkbox mt-1">
                            <input type="checkbox" id="emp_paternity" name='paternity' className="custom-control-input" checked={paternity} onChange={(e)=>setPaternity(e.target.checked)} disabled={gender === 'MALE' ? false : true} />
                            <label className="custom-control-label" htmlFor="emp_paternity">{IMLocalized('paternity')}</label>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? 
                <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button onClick={applyChild} className="btn btn-primary btn-sm">{IMLocalized('add')}</button>
                    <button onClick={closeModal} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                </>
                }
            </ModalFooter>
        </Modal>
    )
}
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Modal,ModalBody,ModalFooter,ModalHeader,Row,Col,FormFeedback,FormGroup,Input } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import moment from 'moment';
import { update_payslip_bonus_validate } from '../../../action/payroll/update_payslip_bonus_validate';
import { update_payslip_bonus } from '../../../action/payroll/update_payslip_bonus';
import { usePrevious } from '../../../hook/usePrevious';


const mapStateToProps = (state)=>({
    bonus_validate_success:state.update_payslip_bonus_validate.data,
    payroll_bonus_code:state.dropdown_list.payroll_bonus_code,
    isLoading:state.update_payslip_bonus_validate.isLoading,
    contractlist:state.get_payslip_employee_contract.data,
    bonus_success:state.update_payslip_bonus.data,
})

const mapDispatchToProps = (dispatch)=>({
    update_payslip_bonus_validate:(id,data)=>{
        dispatch(update_payslip_bonus_validate(id,data))
    },
    update_payslip_bonus:(id,data)=>{
        dispatch(update_payslip_bonus(id,data))
    }
})

function ModalBonus(props){
    const {bonus_validate_success,closeModal,bonus_success,update_payslip_bonus} = props;
    const [code,setCode] = useState('');
    const [emp_contract,setEmpContract] = useState([]);
    const [err_code,setErrCode] = useState('');
    const [amount,setAmount] = useState('');
    const [err_amount,setErrAmount] = useState('');
    const [count,setCount] = useState(''); 
    const [err_count,setErrCount] = useState('');
    const [contract,setContract] = useState('');
    const [bonusData,setBonusData] = useState([]);

    const prevSuccess = usePrevious(bonus_success);
    const prevValidateSuccess = usePrevious(bonus_validate_success);

    useEffect(()=>{
        if(prevValidateSuccess !== undefined && prevValidateSuccess !== bonus_validate_success){
            update_payslip_bonus(props.id,bonusData);
        }
    },[prevValidateSuccess, bonus_validate_success,props.id,update_payslip_bonus,bonusData])

    useEffect(()=>{
        if(prevSuccess !== undefined && prevSuccess !== bonus_success && bonus_success != null ){
            closeModal()
        }
    },[prevSuccess,bonus_success,closeModal])

    useEffect(()=>{
        if(props.contractlist.length !== 0 && props.toggle){
            const detail = props.contractlist.find((item)=>item.employee.id === parseInt(props.emp));
            setEmpContract(detail ? detail.employee.contracts : []);
            setContract(detail ? detail.employee.contracts[0].id:'');
            
        }
    },[props.emp,props.contractlist,props.toggle])

    useEffect(()=>{
        if(props.toggle){
            setCode('');
            setErrCode('');
            setCount(0);
            setErrCount('');
            setAmount(0);
            setErrAmount('');
            setBonusData([]);
        }
    },[props.toggle,props.emp_contract])

    const getDateFormat=(date)=>{
        const date1 = moment(date,'YYYY-MM-DD');
        const new_date = date1.format('DD/MM/YYYY');
        return new_date;
    }

    const addBonus = ()=>{
        let bonus_amount = amount;
        let bonus_count = count;
        if(code !== ''){
            let step = 0;
            if(code === 'CA' || code === 'C'){
                if(count !== ''){
                    bonus_amount = 1;
                }
                else{
                    step = step +1;
                    setErrCount(IMLocalized('count_is_required'));
                }
            }
            else if (code === 'A'){
                if(amount !== ''){
                    bonus_count = 1;
                }
                else{
                    step = step + 1;
                    setErrAmount(IMLocalized('amount_is_required'));
                }
            }

            if(step === 0){
                const data =[{payslip:props.payslip_id,bonus_code:code,amount:bonus_amount,count:bonus_count,contract:contract}];
                props.update_payslip_bonus_validate(props.id,data);
                setBonusData(data);
            }
        }
        else{
            setErrCode(IMLocalized('code_is_required'))
        }
    }

    let input_table = <div></div>;
    if(code === 'C' || code === 'CA'){
        input_table = (
            <Col lg="6">
                <label className='form-control-label'>{IMLocalized('payslip_count')}</label>
                <Input size="sm" type="number" min="0" step="1" value={count} onChange={(e)=>{setCount(e.target.value) ; setErrCount('')}} invalid={err_count.length >0} />
                <FormFeedback>{err_count}</FormFeedback>
            </Col>
        )
    }
    else if (code === 'A'){
        input_table=(
            <Col lg="6">
                <label className="form-control-label">{IMLocalized('amount')}</label>
                <Input size="sm" type="number" min="0" step="1" value={amount} onChange={(e)=>{setAmount(e.target.value); setErrAmount('')}} invalid={err_amount.length >0} />
                <FormFeedback>{err_amount}</FormFeedback>
            </Col>
        )
    }
    return(
        <Modal size="lg" isOpen={props.toggle}>
            <ModalHeader toggle={props.closeModal}>{IMLocalized('update_bonus')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label'>{IMLocalized('bonus_code')}</label>
                            <Input size="sm" type="select" value={code} onChange={(e)=>{setCode(e.target.value) ; setErrCode('')}} invalid={err_code.length > 0}>
                                <option value=""></option>
                                {props.payroll_bonus_code.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_code}</FormFeedback>
                        </FormGroup>
                    </Col>
                    {input_table}
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label'>{IMLocalized('contract')}</label>
                            <Input type="select" size="sm" value={contract} onChange={(e)=>setContract(e.target.value)} >
                                <option value=""></option>
                                {emp_contract.map((item,index)=>{
                                    const start_date = item.start_date;
                                    let x = '';
                                    if(code === 'CA'){
                                        x = item.pay_with_recurring;
                                    }
                                    else{
                                        x = item.pay;
                                    }

                                    if(item.start_date !== ''){
                                        return <option value={item.id} key={index}>{getDateFormat(start_date)} - {x} </option>
                                    }
                                    else{
                                        return false;
                                    }
                                    
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
            {props.isLoading?
                <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                <button className='btn btn-primary btn-sm' onClick={addBonus}>{IMLocalized('update')}</button>
                <button className='btn btn-danger btn-sm' onClick={props.closeModal}>{IMLocalized('close')}</button>
                </>
            }
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalBonus);
import * as delcombank from '../../action/company/delete_company_bank';


const initialState ={
    errors:null,
    data:null,
    isLoading:false
}

const delete_company_bank = (state=initialState,action)=>{
    switch(action.type){
        case delcombank.DELETECOMBANK_REQUEST:
            return{
                isLoading:true,
                data:null,
                errors:null
            }
        case delcombank.DELETECOMBANK_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case delcombank.DELETECOMBANK_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case delcombank.DELETECOMBANK_RESET:
            return{
                ...state,
                data:null,
                errors:null,
            }
        default:
            return state;
    }
}
export default delete_company_bank;
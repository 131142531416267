import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader,Row,Col,FormFeedback,Input,ModalFooter,FormGroup } from "reactstrap";
import { IMLocalized} from '../../../language/IMLocalized';
import { branch_get_sg_postal, reset_branch_get_sg_postal} from '../../../action/company/branch_get_sg_postal';
import { connect } from 'react-redux';
import { update_branch, reset_update_branch } from '../../../action/company/update_branch';
import { popError } from '../../../func/popError';


const mapStateToProps = (state)=>({
    sg_address:state.branch_get_sg_postal.address,
    errors:state.update_branch.errors,
    isLoading:state.update_branch.isLoading,
    success:state.update_branch.data,
})

const mapDispatchToProps = (dispatch)=>({
    branch_get_sg_postal:(code)=>{
        dispatch(branch_get_sg_postal(code))
    },
    update_branch:(id,name,branch_code,unit_number,sg_postal_code,address,description)=>{
        dispatch(update_branch(id,name,branch_code,unit_number,sg_postal_code,address,description))
    },
    reset_branch_get_sg_postal:()=>{
        dispatch(reset_branch_get_sg_postal())
    },
    reset_update_branch:()=>{
        dispatch(reset_update_branch())
    }
})

function ModalEditBranch(props){
    const {sg_address,errors,success,onSuccess,reset_branch_get_sg_postal,reset_update_branch} = props;
    const [name,setName] = useState('');
    const [err_name,setErrName] = useState('');
    const [branch_code,setBranchCode] = useState('');
    const [err_branch_code,setErrBranchCode] = useState('');
    const [unit_number,setUnitNumber] = useState('');
    const [err_unit_number,setErrUnitNumber] = useState('');
    const [sg_postal_code,setPostalCode] = useState('');
    const [err_sg_postal_code,setErrPostalCode] = useState('');
    const [address,setAddress] = useState('');
    const [err_address,setErrAddress] = useState('');
    const [description,setDescription] = useState('');
    const [err_description,setErrDescription] = useState('');

    useEffect(()=>{
        if(props.detail !== null){
            setName(props.detail.name);
            setBranchCode(props.detail.branch_code);
            setUnitNumber(props.detail.unit_number);
            setPostalCode(props.detail.sg_postal_code);
            setAddress(props.detail.address);
            setDescription(props.detail.description);
            setErrAddress('');
            setErrName('');
            setErrBranchCode('');
            setErrUnitNumber('');
            setErrPostalCode('');
            setErrDescription('');
        }
        else{
        }
    },[props.detail,props.toggle])

    useEffect(()=>{
        if(props.toggle && sg_address != null){
            if(sg_address.length !== 0){
                const address = sg_address[0].ADDRESS;
                setAddress(address);
                setErrAddress('');
            }
            else{
                const word = IMLocalized('address_not_found');
                popError(word);
                
            }
            reset_branch_get_sg_postal();
        }
    },[props.toggle,sg_address,reset_branch_get_sg_postal])

    useEffect(()=>{
        if( props.toggle && errors != null){
            if( errors.hasOwnProperty('name')){
                const word = errors['name'].toString();
                setErrName(word);
            }
            if( errors.hasOwnProperty('branch_code')){
                const word = errors['branch_code'].toString();
                setErrBranchCode(word);
            }
            if( errors.hasOwnProperty('unit_number')){
                const word = errors['unit_number'].toString();
                setErrUnitNumber(word);
            }
            if( errors.hasOwnProperty('sg_postal_code')){
                const word = errors['sg_postal_code'].toString();
                setErrPostalCode(word);
            }
            if( errors.hasOwnProperty('address')){
                const word = errors['address'].toString();
                setErrAddress(word);
            }
            if( errors.hasOwnProperty('description')){
                const word = errors['description'].toString();
                setErrDescription(word);
            }       
            reset_update_branch();        
        }
    },[props.toggle,errors,reset_update_branch])

    useEffect(()=>{
        if( props.toggle && success !== null){
            onSuccess();
            reset_update_branch();
        }
    },[success,props.toggle,onSuccess,reset_update_branch])

    const onSearch=()=>{
        var reg = /^[0-9]{6}$/;
        if(!reg.test(sg_postal_code)){
            setErrPostalCode(IMLocalized('invalid_sg_postal_code'))
        }
        else{
            props.branch_get_sg_postal(sg_postal_code)
        }
    }

    const updateBranch=()=>{
        if(branch_code !== '' && name !== '' && address !== '' && description !== '' && sg_postal_code !== '' && unit_number !==''){
            var reg = /^[0-9]{6}$/;
            if(!reg.test(sg_postal_code)){
                setErrPostalCode(IMLocalized('invalid_sg_postal_code'));
            }
            else{
                props.update_branch(props.detail.id,name,branch_code,unit_number,sg_postal_code,address,description);
            }
            
        }
        else{
            if(name === ''){
                setErrName(IMLocalized('name_is_required'));
            }
            if(branch_code === ''){
                setErrBranchCode(IMLocalized('branch_code_is_required'));
            }
            if(address === ''){
                setErrAddress(IMLocalized('address_is_required'));
            }
            if(description === ''){
                setErrDescription(IMLocalized('description_is_required'));
            }
            if(sg_postal_code === ''){
                setErrPostalCode(IMLocalized('sg_postal_code_is_required'));
            }
            if(unit_number === ''){
                setErrUnitNumber(IMLocalized('unit_number_is_required'))
            }
        }
    }

    return(
            <Modal isOpen={props.toggle} size="lg">
                {props.detail &&
                <>
                <ModalHeader toggle={props.closeModal}>{IMLocalized('edit')} - {props.detail.name} </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor='name'>{IMLocalized('name')}</label>
                                <Input size="sm" name="name" id="name" type="text" value={name} onChange={(e)=>{setName(e.target.value) ; setErrName('')}} invalid={err_name.length > 0} />
                                <FormFeedback>{err_name}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor='branch_code'>{IMLocalized('branch_code')}</label>
                                <Input size="sm" name="branch_code" id="branch_code" type="text" value={branch_code} onChange={(e)=>{setBranchCode(e.target.value) ; setErrBranchCode('')}} invalid={err_branch_code.length > 0}  />
                                <FormFeedback>{err_branch_code}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor='unit_number'>{IMLocalized('unit_number')}</label>
                                <Input size="sm" name="unit_number" id="unit_number" type="text" value={unit_number} onChange={(e)=>{setUnitNumber(e.target.value) ; setErrUnitNumber('')}} invalid={err_unit_number.length >0} />
                                <FormFeedback>{err_unit_number}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor='sg_postal_code'>{IMLocalized('sg_postal_code')}</label>
                                <div className="input-group">
                                <Input size="sm" name="sg_postal_code" id="sg_postal_code" type="number"  value={sg_postal_code} onChange={(e)=>{setPostalCode(e.target.value) ; setErrPostalCode('')}} invalid={err_sg_postal_code.length >0} />
                                <div className="input-group-append">
                                    <button className="btn btn-primary btn-sm" onClick={onSearch}>{IMLocalized('autofill')}</button>
                                </div>
                                <FormFeedback>{err_sg_postal_code}</FormFeedback>
                                </div>
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor='address'>{IMLocalized('address')}</label>
                                <Input size="sm" name="address" id="address" type="textarea" value={address} onChange={(e)=>{setAddress(e.target.value) ; setErrAddress('')}} invalid={err_address.length > 0} />
                                <FormFeedback>{err_address}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor='description'>{IMLocalized('description')}</label>
                                <Input size="sm" name="description" id="description" type="textarea" value={description} onChange={(e)=>{setDescription(e.target.value) ; setErrDescription('')}} invalid={err_description.length > 0} />
                                <FormFeedback>{err_description}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {props.isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                    <div>
                        <button onClick={updateBranch} className="btn btn-primary btn-sm">{IMLocalized('update')}</button>
                        <button onClick={props.closeModal} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                    </div>
                    }
                </ModalFooter>
                </>
                }
            </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalEditBranch);
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormFeedback, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row , Input } from 'reactstrap';
import { assign_leave_approval, reset_assign_leave_approval } from '../../../../action/approvals/assign_leave_approval';
import { IMLocalized } from '../../../../language/IMLocalized';
import { popError } from '../../../../func/popError';

export default function ModalAssignLeaveApproval({ toggle, closeModal, onSuccess, type, join_date, id  }){
    
    const approval_list = useSelector(state=>state.get_leave_approval.data);
    const isLoading = useSelector(state=>state.assign_leave_approval.isLoading);
    const success = useSelector(state=>state.assign_leave_approval.data);
    const errors = useSelector(state=>state.assign_leave_approval.errors);
    const dispatch = useDispatch();
    const [start_date,setStartDate] = useState('');
    const [err_start_date,setErrStartDate] = useState('');
    const [group,setGroup] = useState('');
    const [err_group,setErrGroup] = useState('');
   

    useEffect(()=>{
        if(toggle){
            if(type === 'create_employee' && join_date){
                setStartDate(join_date);
            }
            else{
                setStartDate('');
                
            }
            setErrStartDate('');
            setErrGroup('');
            setGroup('');
        }
    },[toggle,type,join_date])

    useEffect(()=>{
        if( success !== null && toggle ){
            onSuccess();
            dispatch(reset_assign_leave_approval());
        }
    },[success,onSuccess,toggle, dispatch])

    useEffect(()=>{
        if(toggle && errors !== null){
            if(errors.hasOwnProperty('non_field_errors')){
                const word = errors['non_field_errors'].toString();
                popError(word);
            }
            dispatch(reset_assign_leave_approval())
        }
    },[errors, toggle, dispatch])

    const assignApproval=()=>{
        if(start_date !== '' && group !== ''){
            const detail = approval_list.find((item)=>item.id === group);
            const approval_master = detail?.approval_master ?? null;

            dispatch(assign_leave_approval(id,start_date,group,approval_master));
        }
        else{
            if(start_date === ''){
                setErrStartDate(IMLocalized('start_date_is_required'))
            }
            if(group === ''){
                setErrGroup(IMLocalized('approval_group_is_required'))
            }
        }
    }

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('assign_leave_approval')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='start_date'>{IMLocalized('start_date')}</label>
                            <Input id="start_date" type="date" size="sm" name="start_date" value={start_date} onChange={(e)=>{setStartDate(e.target.value); setErrStartDate('')}} invalid={err_start_date.length >0} />
                            <FormFeedback>{err_start_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='approval_group'>{IMLocalized('approval_group')}</label>
                            <Input id="approval_group" type="select" size="sm" name="approval_group" value={group} onChange={(e)=>{setGroup(e.target.value);setErrGroup('')}} invalid={err_group.length >0}>
                                <option></option>
                                {approval_list.map((item,index)=>{
                                    return <option value={item.id} key={index}>{item.name}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_group}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading?
                <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button onClick={assignApproval} className="btn btn-primary btn-sm">{IMLocalized('assign')}</button>
                    <button onClick={closeModal} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
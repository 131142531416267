import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const DELGENERALAGGRID_REQUEST = 'DELGENERALAGGRID_REQUEST';
export const DELGENERALAGGRID_SUCCESS = 'DELGENERALAGGRID_SUCCESS';
export const DELGENERALAGGRID_FAILURE = 'DELGENERALAGGRID_FAILURE';

export const delete_general_aggrid_template = (id)=>{
    return dispatch=>{
        dispatch({
            type:'DELGENERALAGGRID_REQUEST'
        });

        myaxios.delete(`general/ag_grid_profile/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELGENERALAGGRID_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELGENERALAGGRID_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            });
        })
    }
}
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Table, Input } from 'reactstrap';
import {get_pay_summary_list} from '../../action/claim/get_pay_summary_list';
import {IMLocalized} from '../../language/IMLocalized';
import {DateRangePicker} from 'react-dates';
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { getDateFormat } from "../../func/getDateFormat";
import moment from "moment";
import { NewMainContext } from "../../context/NewMainContext";

const initialState={
    startDate:null,
    endDate:null,
    focusedInput:null,
    dateFormat:'DD/MM/YYYY',
    selected:'',
}

function ClaimPaySummaryList(){

    const dispatch = useDispatch();
    const data = useSelector(state=>state.get_pay_summary_list.data);
    const isLoading = useSelector(state=>state.get_pay_summary_list.isLoading);
  

    const [state,setState] = useState(initialState);
    const [today,setToday] = useState(new Date());
    const [list,setList] = useState([]);
    const { addItem, pay_summary_list_reset, setPaySummaryListReset } = useContext(NewMainContext);
    
    const summaryRef = useRef();

    useEffect(()=>{
        if(pay_summary_list_reset === true){
            setState(initialState);
            setPaySummaryListReset(false);
        }
    },[pay_summary_list_reset,setPaySummaryListReset])

    useEffect(()=>{
        setToday(new Date());
        const arr = [];
        for(const item of data){
            const {claims,send_to_payroll,pay_date} = item;
            for(const data of claims){  
                arr.push({...data,send_to_payroll,pay_date});
            }
        }
        setList(arr);
    },[data])



    const rowData = list.filter(item=>{
        if(state.selected === 'true'){
            return item.send_to_payroll === true
        }
        else if(state.selected === 'false'){
            return item.send_to_payroll === false
        }
        return item;
    }).filter(item=>{
        if(state.startDate !== null || state.endDate !== null){
            return (state.startDate !== null && new Date(item.pay_date) >= state.startDate) && (state.endDate !== null && new Date(item.pay_date) <= state.endDate)
        }
        return item
    })

    const date1 = moment(today).format('DD/MM/YYYY HH:mm:ss');

    return(
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                        <BreadcrumbItem><a href="/#" onClick={()=>addItem('1','dashboard')}>{IMLocalized('home')}</a></BreadcrumbItem>
                        <BreadcrumbItem active>{IMLocalized('pay_summary_list')}</BreadcrumbItem>
                    </Breadcrumb>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className="mt-2">
                <Col>
                    {rowData.length !== 0 &&   
                    <ReactToPrint 
                    trigger={()=><button className="btn btn-primary btn-sm">{IMLocalized('print_summary')}</button>}
                    content={()=>summaryRef.current}
                    />}
                    <button className="btn btn-primary btn-sm" onClick={()=>dispatch(get_pay_summary_list())}>{IMLocalized('refresh')}</button>
                </Col>
                <Col className="col-auto d-flex align-items-center">
                    <DateRangePicker
                    startDate={state.startDate}
                    isOutsideRange={()=>false}
                    small = {true}
                    startDateId="unique_start_date_id"
                    endDate={state.endDate}
                    endDateId="unique_end_date_id"
                    onDatesChange={({startDate,endDate})=>setState(prevState=>({...prevState,startDate,endDate}))}
                    minimumNights={0}
                    focusedInput={state.focusedInput}
                    displayFormat={state.dateFormat}
                    onFocusChange={(value)=>setState(prevState=>({...prevState,focusedInput:value}))}  />
                    <Input size="sm" className="ml-2" type="select" name="selected" value={state.selected} style={{width:'120px'}}
                    onChange={(e)=>{
                        const value = e.target.value;
                        setState(prevState=>({...prevState,selected:value}));
                    }}
                    >
                        <option value=''>{IMLocalized('ALL')}</option>
                        <option value={true}>{IMLocalized('pay_payroll')}</option>
                        <option value={false}>{IMLocalized('pay_petty')}</option>
                    </Input>
                </Col>
            </Row>
            <Row>
                <Col>
                    {isLoading && <span>{IMLocalized('loading')}..</span>}
                    {!isLoading && rowData.length === 0 && <span>{IMLocalized('no_data')}</span>}
                    {!isLoading && rowData.length !== 0 &&
                    <div className="pt-2 px-2" ref={summaryRef}>
                        <h3 className="ml-2">{IMLocalized('pay_summary')} - {IMLocalized('printed_on')} : {date1}</h3>
                        <Table size="sm" className="bg-white table-bordered align-items-center">
                            <thead className="thead-light">
                                <tr>
                                    <th>{IMLocalized('employee_id')}</th>
                                    <th>{IMLocalized('employee_name')}</th>
                                    <th>{IMLocalized('claim_type')}</th>
                                    <th>{IMLocalized('paid_date')}</th>
                                    <th>{IMLocalized('paid_type')}</th>
                                    <th>{IMLocalized('claim_amount1')}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {rowData.map((item,index)=>{

                                    return(
                                        <tr key={index}>
                                            <td>{item.employee.employee_number}</td>
                                            <td>{item.employee.name}</td>
                                            <td>{item.claim_type.name}</td>
                                            <td>{getDateFormat(item.pay_date)}</td>
                                            <td>{item.send_to_payroll === true ? IMLocalized('pay_payroll') : IMLocalized('pay_petty') }</td>
                                            <td>$ {item.claim_amount}</td>
                                            <td></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                    }
                </Col>
            </Row>
        </Container>
        </>
    )
}
export default ClaimPaySummaryList;
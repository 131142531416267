import * as regeneratepayslip from '../../action/payroll/regenerate_payslip';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}


const regenerate_payslip = (state=initialState,action)=>{
    switch(action.type){
        case regeneratepayslip.REGENERATEPAYSLIP_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case regeneratepayslip.REGENERATEPAYSLIP_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case regeneratepayslip.REGENERATEPAYSLIP_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default regenerate_payslip;
import * as createallowance from '../action/create_allowance';


const initialState ={
    isLoading:false,
    errors:null,
    data:null
}


const create_allowance = (state=initialState,action) =>{

    switch(action.type) {
        case createallowance.CREATEALLOWANCE_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null
            };
        case createallowance.CREATEALLOWANCE_SUCCESS:
            return{
                ...state,
            isLoading:false,
            errors:{},
            data:action.payload,
            }
        case createallowance.CREATEALLOWANCE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case createallowance.CREATEALLOWANCE_RESET:
            return{
                ...state,
                errors:null,
                data:null
            }
        default:
            return state;
    }
}
export default create_allowance;
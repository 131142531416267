import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { Table } from 'reactstrap';
import {delete_employee_workpermit, reset_delete_employee_workpermit} from '../../../action/employee/delete_employee_workpermit';
import {get_employee_permit} from '../../../action/employee/get_employee_permit';
import {get_employee_list} from '../../../action/employee/get_employee_list';
import {IMLocalized} from '../../../language/IMLocalized';
import ModalCreatePermit from './modal/ModalCreatePermit';
import ModalViewPermit from './modal/ModalViewPermit';
import ModalEditPermit from './modal/ModalEditPermit';
import { get_employee_detail } from '../../../action/employee/get_employee_detail';

const mapStateToProps=(state)=>({
    isLoading:state.get_employee_permit.isLoading,
    list:state.get_employee_permit.data,
    permitlist:state.dropdown_list.work_permit,
    emp_detail:state.get_employee_detail.data,
    isLoading1:state.get_employee_detail.isLoading,
    delete_success:state.delete_employee_workpermit.data,
})

const mapDispatchToProps=(dispatch)=>({
    delete_employee_workpermit:(data)=>{
        dispatch(delete_employee_workpermit(data));
    },
    get_employee_permit:()=>{
        dispatch(get_employee_permit())
    },
    get_employee_list:(id)=>{
        dispatch(get_employee_list(id))
    },
    get_employee_detail:(id)=>{
        dispatch(get_employee_detail(id))
    },
    reset_delete_employee_workpermit:()=>{
        dispatch(reset_delete_employee_workpermit())
    }
})

function EmployeePermit(props){
    const {delete_success,get_employee_permit,reset_delete_employee_workpermit} = props;
    const [list,setList] = useState([]);
    const [add_toggle,setAddToggle] = useState(false);
    const [view_toggle,setViewToggle] = useState(false);
    const [view_detail,setViewDetail] = useState(null);
    const [edit_toggle,setEditToggle] = useState(false);
    const [edit_detail,setEditDetail] = useState(null);
    const [identification_number,setIDNumber] = useState(null);

    useEffect(()=>{
        if(props.id !== ''){
            const data = props.list.filter(function(item){
                return item.employee === parseInt(props.id);
            })
            setList(data);
        }
    },[props.id,props.list])

    useEffect(()=>{
        if(props.emp_detail != null){
            const data = props.emp_detail;
            setIDNumber(data.identification_number);
        }
        else{
            setIDNumber(null);
        }
    },[props.emp_detail])

    useEffect(()=>{
        if(delete_success !== null){
            get_employee_permit();
            reset_delete_employee_workpermit();
        }
    },[delete_success,get_employee_permit,reset_delete_employee_workpermit])


    const toView=(index)=>{
        const toggle = !view_toggle;
        if(toggle === true){
            setViewDetail(list[index]);
        }
        else{
            setViewDetail(null);
        }
        setViewToggle(toggle);
    }

    const toEdit=(index)=>{
        const toggle = !edit_toggle;
        if(toggle === true){
            setEditDetail(list[index]);
        }
        else{
            setEditDetail(null);
        }
        setEditToggle(toggle);
    }

    const toRemove=(index)=>{
        Swal.fire({
            title:IMLocalized('delete_permit_confirmation'),
            text:IMLocalized('revert_msg'),
            type:'warning',
            showCancelButton:true,
            cancelButtonColor:'#3085d6',
            confirmButtonColor:'#d33',
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!'),
        })
        .then((result)=>{
            if(result.value){
                const id = list[index].id;
                props.delete_employee_workpermit([{id}]);
            }
        })
    }

    return(
        <>
            <div className='d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title'>
                <div className='d-flex align-items-center'>
                    <i className="far fa-id-card text-primary"></i>
                    <h4 className="text-primary pl-2 m-0">{IMLocalized('employee_permit')}</h4>
                </div>
                <div className='text-primary pt-2 cursor-pointer pr-2' onClick={()=>setAddToggle(true)}>
                    <i className='fas fa-plus-circle mr-1 function_icon' />
                    <small>{IMLocalized('add_permit')}</small>
                </div>
            </div> 
            {props.isLoading && <div className='p-2'><h4>{IMLocalized('loading')}..</h4></div>}
            {!props.isLoading && list.length === 0 && <div className='p-2'><h4>{IMLocalized('no_data')}</h4></div>}
            {!props.isLoading && list.length !== 0 && 
            <Table size="sm" className='align-items-center table-bordered mt-2' responsive>
                <thead className="thead-light text-center">
                    <tr>
                        <th className="w-25">{IMLocalized('permit_type')}</th>
                        <th className="w-25">{IMLocalized('permit_number')}</th>
                        <th>{IMLocalized('view')} / {IMLocalized('edit')} / {IMLocalized('delete')}</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {list.map((item,index)=>{
                        const permit_data = props.permitlist.find((item1)=>item1.value === item.permit_type);
                        return(
                            <tr key={index}>
                                <td>{permit_data? permit_data.display : '-'}</td>
                                <td>{item.permit}</td>
                                <td align="center" valign="center">
                                    <div className='d-flex justify-content-center'>
                                        <div className="primary_btn d-flex align-items-center justify-content-center">
                                            <i className="far fa-eye text-primary cursor-pointer" onClick={()=>toView(index)} />
                                        </div>
                                        <div className="primary_btn d-flex align-items-center justify-content-center">
                                            <i className="fas fa-pen text-primary cursor-pointer" onClick={()=>toEdit(index)} />
                                        </div>
                                        <div className="delete_btn d-flex align-items-center justify-content-center">
                                            <i className="fas fa-trash text-red cursor-pointer" onClick={()=>toRemove(index)} />
                                        </div>
                                    </div>
                                </td>   
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            }
            <ModalCreatePermit toggle={add_toggle} closeModal={()=>setAddToggle(false)} id={props.id} onSuccess={()=>{setAddToggle(false); props.get_employee_list(); props.get_employee_permit(); props.get_employee_detail(props.id) }} fin_number={identification_number} />
            <ModalViewPermit toggle={view_toggle} closeModal={()=>setViewToggle(false)} detail={view_detail} />
            <ModalEditPermit toggle={edit_toggle} closeModal={()=>{setEditToggle(false) ; setEditDetail(null)}}  id={props.id} detail={edit_detail} fin_number={identification_number} onSuccess={()=>{props.get_employee_permit(); props.get_employee_list();props.get_employee_detail(props.id) }} />
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(EmployeePermit);
import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const CREATELEAVE_REQUEST = 'CREATELEAVE_REQUEST';
export const CREATELEAVE_SUCCESS = 'CREATELEAVE_SUCCESS';
export const CREATELEAVE_FAILURE = 'CREATELEAVE_FAILURE';
export const CREATELEAVE_RESET = 'CREATELEAVE_RESET';


export const create_leave = (code,name,leave_type,can_user_apply,deductable,earned_can_negative,minimum_images,maximum_images,year_initialization,is_npl,is_proration)=>{
    return dispatch=>{
        dispatch({
            type:'CREATELEAVE_REQUEST'
        });

        myaxios.post('leave/setting/',{
            code:code,
            name:name,
            leave_type:leave_type,
            can_user_apply:can_user_apply,
            deductable:deductable,
            earned_can_negative:earned_can_negative,
            minimum_images:minimum_images,
            maximum_images:maximum_images,
            year_initialization:year_initialization,
            is_npl:is_npl,
            proration:is_proration
        })
        .then((response)=>{
            dispatch({
                type:'CREATELEAVE_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type:'success',
                title:IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CREATELEAVE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}

export const reset_create_leave = () => dispatch =>{
    dispatch({
        type:'CREATELEAVE_RESET'
    });
}
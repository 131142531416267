import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const WIZARDCREATEFLAT_REQUEST = 'WIZARDCREATEFLAT_REQUEST';
export const WIZARDCREATEFLAT_SUCCESS = 'WIZARDCREATEFLAT_SUCCESS';
export const WIZARDCREATEFLAT_FAILURE = 'WIZARDCREATEFLAT_FAILURE';

export const wizard_create_flat = (data)=>{
    return dispatch=>{
        dispatch({
            type:'WIZARDCREATEFLAT_REQUEST'

        });

        myaxios.post('company/flat_allowance/array_save/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'WIZARDCREATEFLAT_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title:IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'WIZARDCREATEFLAT_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}
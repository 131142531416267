import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const CREATESCHEDULE_REQUEST = 'CREATESCHEDULE_REQUEST';
export const CREATESCHEDULE_SUCCESS = 'CREATESCHEDULE_SUCCESS';
export const CREATESCHEDULE_FAILURE = 'CREATESCHEDULE_FAILURE';


export const create_schedule = (name,start_year,working_hours,hours_in_year,array,holiday_list) =>{
    return dispatch =>{
        dispatch({
            type: 'CREATESCHEDULE_REQUEST'
        });

        return myaxios.post(`contract/schedules/`,
        {
            name:name,
            start_year:start_year,
            working_hours:working_hours,
            hours_in_year:hours_in_year,
            calendar_array:array,
            holiday_list:holiday_list
        })
        .then((response)=>{
            dispatch({
                type: 'CREATESCHEDULE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error) =>{
            dispatch({
                type:'CREATESCHEDULE_FAILURE',
                payload: error
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_failure')
            });
        })
    }
}
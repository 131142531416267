import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CREATECLAIM_REQUEST = 'CREATECLAIM_REQUEST';
export const CREATECLAIM_SUCCESS = 'CREATECLAIM_SUCCESS';
export const CREATECLAIM_FAILURE = 'CREATECLAIM_FAILURE';

export const create_claim = (code,name,medical,minimum_images,maximum_images)=>{
    return dispatch=>{
        dispatch({
            type:'CREATECLAIM_REQUEST'
        });

        myaxios.post('claim/type/',{
            code:code,
            name:name,
            medical:medical,
            minimum_images:minimum_images,
            maximum_images:maximum_images
        })
        .then((response)=>{
            dispatch({
                type:'CREATECLAIM_SUCCESS',
                payload:response
            });

            Toast.fire({
                type:'success',
                title:IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CREATECLAIM_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}
import myaxios from '../../axios';
import FormData from 'form-data';
import { IMLocalized } from '../../language/IMLocalized';
import { Toast } from '../../swal/SwalToast';

export const EMPAPPLYCLAIM_REQUEST = 'EMPAPPLYCLAIM_REQUEST';
export const EMPAPPLYCLAIM_SUCCESS = 'EMPAPPLYCLAIM_SUCCESS';
export const EMPAPPLYCLAIM_FAILURE = 'EMPAPPLYCLAIM_FAILURE';

export const employee_apply_claim = (employee,claim_type,original_amount,claim_date,dependant,set_pending,notes,file,doctor,diagnosis,location)=>{
    return dispatch=>{
        dispatch({
            type:'EMPAPPLYCLAIM_REQUEST'
        });

        let formData = new FormData();
        if(file.length !==0){
            for(let i=0;i<file.length;i++){
                formData.append('upload_images',file[i],file[i].name);
            }
        }
        formData.append('employee',employee);
        formData.append('claim_type',claim_type);
        formData.append('original_amount',original_amount);
        formData.append('dependant',dependant);
        formData.append('claim_date',claim_date);
        formData.append('set_pending',set_pending);
        formData.append('comment',notes);
        formData.append('medicalclaim.doctor',doctor);
        formData.append('medicalclaim.diagnosis',diagnosis);
        formData.append('medicalclaim.location',location)
       

        myaxios.post('claim/apply/',formData,{headers:{'Content-Type':'multipart/form-data'}})
        .then((response)=>{
            dispatch({
                type:'EMPAPPLYCLAIM_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('apply_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'EMPAPPLYCLAIM_FAILURE',
                payload:error
            });
        })
    }
}
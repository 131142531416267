import * as leave_list from '../action/get_leave_list';

const initialState={
    data:[],
    errors:[],
    isLoading:false
}

const get_leave_list = (state=initialState,action)=>{
    switch(action.type){
        case leave_list.GETLEAVELIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[]
            }
        case leave_list.GETLEAVELIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
                errors:[]
            }
        case leave_list.GETLEAVELIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_leave_list;


export const get_leave_list_detail = (state,id) =>{
    const {data} = state.get_leave_list;
    return id !== null ? data.find(item=>item.id === id) : null;
}
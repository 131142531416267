import React,{Component} from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {connect} from 'react-redux';
import { Container, Row, Col,FormGroup,FormFeedback,Input,Modal,ModalBody,ModalFooter,ModalHeader} from 'reactstrap';
import Swal from 'sweetalert2';
import {general_daterange} from '../../action/general_daterange';
import {get_claim_scheme_list} from '../../action/claim/get_claim_scheme_list';
import {get_employee_list} from '../../action/employee/get_employee_list';
import {claim_assign_scheme} from '../../action/claim/claim_assign_scheme';
import {IMLocalized} from '../../language/IMLocalized';
import OverlayLoading from '../loading_component/overlay_loading';

const gridOptions={
    defaultColDef:{
        sortable:true,
        filter:true,
        suppressMenu:true,
        resizable:true
    },
    sideBar:{
        toolPanels:[
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                
            }
        ],
        defaultToolPanel:'filters'
    },
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
}

class wizard_employee_claim_scheme extends Component{

    constructor(props){
        super(props);
        this.state={
            list:[],
            full_proration:false,
            select_year:'',
            rowData:[],
            noRowTemplate:IMLocalized('no_data'),
            rowSelection:'single',
            lodingtemplate:IMLocalized('loading'),
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            columnsDefs:[],
            err_select_year:'',
            claim_scheme:'',
            err_claim_scheme:'',
            count:0,
        }
    }

    componentDidMount(){
        const {list} = this.props;
        this.setState({list});
        this.props.general_daterange();
        this.props.get_claim_scheme_list();
        this.props.get_employee_list();
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.daterange !== this.props.daterange){
            const {daterange} = this.props;
            if(daterange.length !== 0){
                const date_range = daterange.date_range;
                const claim = date_range.claim;
                const year = claim.year;
                this.setState({select_year:year});
            }
        }
        if(prevState.list !== this.state.list || prevProps.emplist1 !== this.props.emplist1 || prevState.count !== this.state.count){
            const {isLoading} = this.props;
            if(!isLoading){
                this.getDetail();
            }
        }
        if(prevProps.success !== this.props.success){
            this.setSchemeToggle();
            const {rowData} = this.state;
            const data = gridOptions.api.getSelectedRows();
            const idlist = data.map((item)=>{
                return item.employee.id
            }) 
            let arr = rowData.filter(function(item){
                return idlist.includes(item.employee.id) === false
            })
            this.setState({rowData:arr});
            if(arr.length !== 0){
                Swal.fire({
                    type:'warning',
                    title:IMLocalized('do_you_continue_assign_scheme'),
                    text:IMLocalized('skip_move_to_next_step'),
                    showCancelButton:true,
                    cancelButtonText:IMLocalized('skip'),
                    confirmButtonText:IMLocalized('continue')
                })
                .then((result)=>{
                    if('value' in result){
                        
                        
                    }
                    else{
                        // 0 not show this msg
                        if(arr.length !== 0){
                            Swal.fire({
                                type:'warning',
                                title:`${arr.length} ${IMLocalized('ppl_not_assign_scheme')}`,
                                showCancelButton:true,
                                cancelButtonText:IMLocalized('cancel'),
                                confirmButtonText:IMLocalized('ok!')
                            })
                            .then((result1)=>{
                                if('value' in result1){
                                    this.props.onNext();
                                }
                            })
                        }
                        else{
                            this.props.onNext();
                        }
                    }
                })
            }
            else{
                this.props.onNext();
            }
            this.clearContent();
        }
    }

    clearContent=()=>{
        this.setState({claim_scheme:''});
        this.setState({err_claim_scheme:''});
        const {count} = this.state;
        const count1 = count +1;
        const {daterange} = this.props;
        if(daterange.length !== 0){
            const date_range = daterange.date_range;
            const claim = date_range.claim;
            const year = claim.year;
            this.setState({select_year:year});
        }
        this.setState({err_select_year:''});
        this.setState({full_proration:false});
        Promise.resolve(this.setState({rowData:[]}))
        .then(()=>this.setState({count:count1}))

    }

    handleInput=(e)=>{
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]:value
        })
        if(name === 'claim_scheme'){
            this.setState({err_claim_scheme:''});
        }

        if(name === 'select_year'){
            this.setState({err_select_year:''})
        }
    }

    getDetail=()=>{
        const {list} = this.state;
        if(list.length !== 0){
            const {emplist} = this.props;
            const detail = list.map((item)=>{
                const id = item.employee.id;
                const detail = emplist.filter(function(item){
                    return item.id === id
                })
                let contract = null;
                if(detail.length !== 0){
                    const empcontract = detail[0].contract_cache;
                    if(empcontract !== null){
                        contract = empcontract;
                    }
                }
    
                item.employee.contract = contract;
                return item;
            })
            this.setState({rowData:detail});
            this.setState({rowData2:detail});
        }

        const columnsDefs=[
            {
                headerName:IMLocalized('agrid_select'),
                field:'',
                editable:false,
                width:200,
                lockPosition:true,
                pinned:'left',
                filter:false,
                headerCheckboxSelection: this.isFirstColumn,
                checkboxSelection: this.isFirstColumn,
                headerCheckboxSelectionFilteredOnly:true,
            },
            {
                headerName:IMLocalized('agrid_emp_id'),
                field:'employee.employee_number',
                editable:false,
                width:200,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_emp_name'),
                field:'employee.name',
                editable:false,
                width:200,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_department'),
                field:'employee.contract.department',
                editable:false,
                width:200,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_section'),
                field:'employee.contract.section',
                editable:false,
                width:200,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_job_title'),
                field:'employee.contract.job_title',
                editable:false,
                width:200,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_job_level'),
                field:'employee.contract.job_level',
                editable:false,
                width:200,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_cost_center'),
                field:'employee.contract.cost_center',
                editable:false,
                width:200,
                sortable:true
            }
        ]

        this.setState({columnsDefs});

    }

    isFirstColumn(params) {
        if(params !== null){
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }   
    }

    handleProration=(e)=>{
        const target = e.target;
        const checked = target.checked;
        const name = target.name;
        this.setState({
            [name]:checked
        })
    }

    onAssign=()=>{
        const {select_year,claim_scheme,full_proration} = this.state;
        
        if(claim_scheme !== ''){
            let year = null;
            if(select_year !== ''){
                year = select_year;
            }

            const rowData = gridOptions.api.getSelectedRows();
            let arr = [];
            for(let i=0;i<rowData.length;i++){
                const id = rowData[i].employee.id;
                arr.push(id);
            }

            if(arr.length !== 0){
                this.props.claim_assign_scheme(claim_scheme,arr,full_proration,year);
            }
            else{
                Swal.fire({
                    title:IMLocalized('no_employee_selected'),
                    type:'warning',
                    confirmButtonColor:'#d33',
                    confirmButtonText:IMLocalized('ok!'),
                })
            }
            
        }
        else{
            this.setState({err_claim_scheme:IMLocalized('claim_scheme_is_required')});
        }
    }

    skipAssign=()=>{
        Swal.fire({
            title:IMLocalized('skip_emp_claim_scheme'),
            type:'warning',
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!')
           
        })
        .then((result)=>{
            if(result.value){
                this.props.onNext();
            }
        })
    }

    goBack=()=>{
        Swal.fire({
            type:'warning',
            title:IMLocalized('skip_emp_wizard_confirmation'),
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!'),
        })
        .then((result)=>{
            if('value' in result){
                this.props.goBack()
            }
        })
        
    }

    setSchemeToggle=()=>{
        const rowData = gridOptions.api.getSelectedRows();
        const toggle = !this.state.scheme_toggle;
        if(toggle === true){
            if(rowData.length !== 0){
                this.setState({scheme_toggle:toggle});
            }
            else{
                Swal.fire({
                    title:IMLocalized('no_employee_selected'),
                    type:'error',
                    confirmButtonColor:'#d33',
                    confirmButtonText:IMLocalized('ok!'),
                })
            }
        }
        else{
            this.setState({scheme_toggle:toggle});
        }
        
        
        this.setState({err_claim_scheme:''});
        this.setState({claim_scheme:''});
        const {daterange} = this.props;
        if(daterange.length !== 0){
            const date_range = daterange.date_range;
            const claim = date_range.claim;
            const year = claim.year;
            this.setState({select_year:year});
            
        }
        this.setState({full_proration:false});
    }

    getModal=()=>{

        const {schemelist} = this.props;
        const scheme_option = schemelist.map((item,index)=>{
            return <option value={item.id} key={index}>{item.name}</option>
        })

        let btn1=(
            <div>
                <button className="btn btn-primary btn-sm" onClick={this.onAssign}>{IMLocalized('assign')}</button>
                <button className="btn btn-danger btn-sm" onClick={this.setSchemeToggle}>{IMLocalized('close')}</button>
            </div>
        )

        if(this.props.isLoading2){
            btn1 =<button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>;
        }


        const table=(
            <Modal isOpen={this.state.scheme_toggle} size="md">
                <ModalHeader toggle={this.setSchemeToggle}>{IMLocalized('select_claim_scheme')}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <label className="form-control-label">{IMLocalized('claim_scheme')}</label>
                                <Input type="select" size="sm" value={this.state.claim_scheme} name="claim_scheme" onChange={this.handleInput} invalid={this.state.err_claim_scheme.length >0}>
                                    <option></option>
                                    {scheme_option}
                                </Input>
                                <FormFeedback>{this.state.err_claim_scheme}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <label className="form-control-label">{IMLocalized('year')}</label>
                                <Input type="text" size="sm" value={this.state.select_year} name="select_year" onChange={this.handleInput} invalid={this.state.err_select_year.length >0}/>
                                <FormFeedback>{this.state.err_select_year}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" id={'emp6proration'} name="full_proration" className="custom-control-input" checked={this.state.full_proration} onChange={this.handleProration} />
                                    <label className="custom-control-label" htmlFor={'emp6proration'}>{IMLocalized('full_proration')}</label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {btn1}
                </ModalFooter>
            </Modal>
        )

        return table;
    }

    render(){
        return(
            <div>
                {this.props.isLoading || this.props.isLoading1  ? <OverlayLoading/>:
                <Container fluid>
                    <Row className="pt-2">
                        <Col>
                            <div>
                                <button className="btn btn-primary btn-sm" onClick={this.goBack}> <i className="fas fa-chevron-left"></i> {IMLocalized('back_to_main_page')}</button>
                                <button className="btn btn-primary btn-sm" onClick={this.skipAssign} >{IMLocalized('skip')}</button>
                                <button className="btn btn-primary btn-sm" onClick={this.clearContent}>{IMLocalized('clear')}</button>
                                <button className="btn btn-primary btn-sm" onClick={this.setSchemeToggle}>{IMLocalized('assign')}</button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col>
                            <div className="d-flex justify-content-center">
                                <div className="ag-theme-balham" style={{height:'calc(100vh - 200px)', width:'100%' }}>
                                    <AgGridReact
                                    columnDefs={this.state.columnsDefs}
                                    rowData={this.state.rowData}
                                    suppressDragLeaveHidesColumns={true}
                                    suppressSizeToFit={true}
                                    suppressColumnMoveAnimation={false}
                                    singleClickEdit={true}
                                    onGridReady={this.onGridReady}
                                    gridOptions={gridOptions}
                                    overlayNoRowsTemplate = {this.state.noRowTemplate}
                                    >
                                    </AgGridReact>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {this.getModal.call(this)}
                </Container>}
            </div>
        )
    }
}
function mapStateToProps(state){
    return{
        daterange:state.general_daterange.data,
        schemelist:state.get_claim_scheme_list.data,
        emplist:state.get_employee_list.data,
        emplist1:state.get_employee_list.data1,
        success:state.claim_assign_scheme.data,
        errors:state.claim_assign_scheme.errors,
        isLoading:state.general_daterange.isLoading,
        isLoading1:state.get_claim_scheme_list.isLoading,
        isLoading2:state.claim_assign_scheme.isLoading,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        general_daterange:()=>{
            dispatch(general_daterange())
        },
        get_claim_scheme_list:()=>{
            dispatch(get_claim_scheme_list())
        },
        get_employee_list:()=>{
            dispatch(get_employee_list())
        },
        claim_assign_scheme:(scheme,employees,full_proration,year)=>{
            dispatch(claim_assign_scheme(scheme,employees,full_proration,year))
        }
    }  
}


export default connect(mapStateToProps, mapDispatchToProps)(wizard_employee_claim_scheme);

import * as quick from '../../action/employee/get_employee_quick_view';

const initialState={
    isLoading:false,
    data:[],
    errors:[],

}

const get_employee_quick_view = (state=initialState,action)=>{
    switch(action.type){
        case quick.GETEMPQUICKVIEW_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case quick.GETEMPQUICKVIEW_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case quick.GETEMPQUICKVIEW_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload,
            }
        default:
            return state
    }
}
export default get_employee_quick_view;
import Swal from "sweetalert2";

export const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    type:'success',
    showConfirmButton: false,
    timer: 3000,
})

export const ErrToast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    type:'error',
    showConfirmButton: false,
    timer: 3000,
})
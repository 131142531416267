import * as createempaddress from '../../action/employee/create_employee_address';


const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const create_employee_address = (state=initialState,action)=>{
    switch(action.type){
        case createempaddress.NEW_CREATEEMPADDRESS_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case createempaddress.NEW_CREATEEMPADDRESS_SUCCESS:
            return{
                ...state,
                data:action.payload
            }
        case createempaddress.NEW_CREATEEMPADDRESS_FAILURE:
            return{
                ...state,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default create_employee_address;
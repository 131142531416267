import myaxios from '../axios';
import FormData from 'form-data';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const UPDATEGENLETTER_REQUEST = 'UPDATEGENLETTER_REQUEST';
export const UPDATEGENLETTER_SUCCESS = 'UPDATEGENLETTER_SUCCESS';
export const UPDATEGENLETTER_FAILURE = 'UPDATEGENLETTER_FAILURE';
export const UPDATEGENLETTER_RESET = 'UPDATEGENLETTER_RESET';

export const update_general_letter = (file)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEGENLETTER_REQUEST'
        });

        const formData = new FormData();
        formData.append('letterhead',file,file.filename);

        myaxios.post('settings/',formData,{headers: {'Content-Type': 'multipart/form-data'}})
        .then((response)=>{
            dispatch({
                type:'UPDATEGENLETTER_SUCCESS',
                payload:response
            });
        
            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATEGENLETTER_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}

export const reset_update_general_letter = () => dispatch =>{
    dispatch({
        type:'UPDATEGENLETTER_RESET'
    });
}

import * as refreshemployee from '../../action/payroll/refresh_employee';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const refresh_employee = (state=initialState,action)=>{
    switch(action.type){
        case refreshemployee.REFRESHEMPLOYEE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case refreshemployee.REFRESHEMPLOYEE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case refreshemployee.REFRESHEMPLOYEE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default refresh_employee;

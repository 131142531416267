import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';


export const CLAIMMANAGERAPPROVE_REQUEST = 'CLAIMMANAGERAPPROVE_REQUEST';
export const CLAIMMANAGERAPPROVE_SUCCESS = 'CLAIMMANAGERAPPROVE_SUCCESS';
export const CLAIMMANAGERAPPROVE_FAILURE = 'CLAIMMANAGERAPPROVE_FAILURE';
export const CLAIMMANAGERAPPROVE_RESET = 'CLAIMMANAGERAPPROVE_RESET'

export const claim_manager_approve = (id,approve,remarks)=>{
    return dispatch=>{
        dispatch({
            type:'CLAIMMANAGERAPPROVE_REQUEST'
        });

        const swal = approve ?  IMLocalized('approve_success') : IMLocalized('reject_success');
        const err_swal = approve ? IMLocalized('approve_failure') : IMLocalized('reject_failure');

        myaxios.post('claim/manager/approve/',{
            approvals:id,
            approve:approve,
            comment:remarks
        })
        .then((response)=>{
            dispatch({
                type:'CLAIMMANAGERAPPROVE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: swal
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CLAIMMANAGERAPPROVE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:err_swal,
            })
        })
    }
}

export const reset_claim_manager_approve = () => dispatch =>{
    dispatch({
        type:'CLAIMMANAGERAPPROVE_RESET'
    });
}
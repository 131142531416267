import myaxios from '../axios';
export const CREATECONTRACTJOBLEVEL_SUCCESS = 'CREATECONTRACTJOBLEVEL_SUCCESS';
export const CREATECONTRACTJOBLEVEL_REQUEST = 'CREATECONTRACTJOBLEVEL_REQUEST';
export const CREATECONTRACTJOBLEVEL_FAILURE = 'CREATECONTRACTJOBLEVEL_FAILURE';
export const CREATECONTRACTJOBLEVEL_RESET = 'CREATECONTRACTJOBLEVEL_RESET';

export const create_contract_job_level = (name)=>{
    return dispatch =>{
        dispatch({
            type: 'CREATECONTRACTJOBLEVEL_REQUEST'
        });
        
        myaxios.post(`company/joblevel/`,{
            name:name 
        })
        .then((response)=>{
            dispatch({
                type:'CREATECONTRACTJOBLEVEL_SUCCESS',
                payload:response
            });
        })
        .catch((error) =>
            dispatch({
                type: 'CREATECONTRACTJOBLEVEL_FAILURE',
                payload: error,
            })
        )
    }
}

export const reset_create_contract_job_level = () => dispatch =>{
    dispatch({
        type:'CREATECONTRACTJOBLEVEL_RESET'
    });
}
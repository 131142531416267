import React from "react";
import { IMLocalized } from "../../../../language/IMLocalized";



export default function LoadingTable(){

    return(
        <div className="m-2">
            <h4>{IMLocalized('loading')}..</h4>
        </div>
    )
}
import * as contractlist from '../../action/employee/get_employee_contract_list';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const get_employee_contract_list = (state=initialState,action)=>{
    switch(action.type){
        case contractlist.GETEMPCONTRACTLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case contractlist.GETEMPCONTRACTLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
            }
        case contractlist.GETEMPCONTRACTLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_employee_contract_list;
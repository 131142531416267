import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const EMPBANKARRAYSAVE_REQUEST = 'EMPBANKARRAYSAVE_REQUEST';
export const EMPBANKARRAYSAVE_SUCCESS = 'EMPBANKARRAYSAVE_SUCCESS';
export const EMPBANKARRAYSAVE_FAILURE = 'EMPBANKARRAYSAVE_FAILURE';

export const employee_bank_array_save =(data)=>{
    return dispatch=>{
        dispatch({
            type:'EMPBANKARRAYSAVE_REQUEST'
        });

        myaxios.post('employee/bank/array_save/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'EMPBANKARRAYSAVE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'EMPBANKARRAYSAVE_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}
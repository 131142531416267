import * as claimgroup from '../../action/claim/add_claim_group_template';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const add_claim_group_template= (state=initialState,action)=>{
    switch(action.type){
        case claimgroup.ADDCLAIMGROUPTEMPLATE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case claimgroup.ADDCLAIMGROUPTEMPLATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case claimgroup.ADDCLAIMGROUPTEMPLATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case claimgroup.ADDCLAIMGROUPTEMPLATE_RESET:
            return{
                ...state,
                data:null,
                errors:null,
            }
        default:
            return state;
    }
}
export default add_claim_group_template;
import * as updatePAS from '../action/update_payroll_additional_setting';

const initialState={
    isLoading:false,
    errors:null,
    data:null
}

const update_payroll_additional_setting = (state=initialState,action)=>{
    switch(action.type){
        case updatePAS.UPDATE_PAYROLL_ADDITIONAL_SETTING_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case updatePAS.UPDATE_PAYROLL_ADDITIONAL_SETTING_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updatePAS.UPDATE_PAYROLL_ADDITIONAL_SETTING_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case updatePAS.UPDATE_PAYROLL_ADDITIONAL_SETTING_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default update_payroll_additional_setting;
import * as deleteapproval from '../../action/leave/delete_leave_approval';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}


const delete_leave_approval = (state=initialState,action)=>{
    switch(action.type){
        case deleteapproval.DELETELEAVEAPPROVAL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case deleteapproval.DELETELEAVEAPPROVAL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case deleteapproval.DELETELEAVEAPPROVAL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case deleteapproval.DELETELEAVEAPPROVAL_RESET:
            return{
                ...state,
                data:null,
                errors:null,
            }
        default:
            return state;
    }
}
export default delete_leave_approval;
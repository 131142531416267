import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const CREATEFLATALLOWANCE_REQUEST = 'CREATEFLATALLOWANCE_REQUEST';
export const CREATEFLATALLOWANCE_SUCCESS = 'CREATEFLATALLOWANCE_SUCCESS';
export const CREATEFLATALLOWANCE_FAILURE = 'CREATEFLATALLOWANCE_FAILURE';
export const CREATEFLATALLOWANCE_RESET = 'CREATEFLATALLOWANCE_RESET';

export const create_flat_allowance = (code,name,amount)=>{
    return dispatch=>{
        dispatch({
            type:'CREATEFLATALLOWANCE_REQUEST'
        });

        myaxios.post('company/flat_allowance/',{
            name:name,
            code:code,
            amount:amount
        })
        .then((response)=>{
            dispatch({
                type:'CREATEFLATALLOWANCE_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CREATEFLATALLOWANCE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_failure')
            })
        })
    }
}

export const reset_create_flat_allowance = () => dispatch =>{
    dispatch({
        type:'CREATEFLATALLOWANCE_RESET'
    });
}
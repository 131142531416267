import * as createschedule from '../action/create_schedule';

const initialState ={
    isLoading:false,
    errors:null,
    data:null
}

const create_schedule = (state=initialState,action)=>{
    switch(action.type){
        case createschedule.CREATESCHEDULE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case createschedule.CREATESCHEDULE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case createschedule.CREATESCHEDULE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default create_schedule;
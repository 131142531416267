import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const PAYSLIPBACKPAY_REQUEST = 'PAYSLIPBACKPAY_REQUEST';
export const PAYSLIPBACKPAY_SUCCESS = 'PAYSLIPBACKPAY_SUCCESS';
export const PAYSLIPBACKPAY_FAILURE = 'PAYSLIPBACKPAY_FAILURE';


export const create_payslip_backpay =(id,data)=>{
    return dispatch=>{
        dispatch({
            type:'PAYSLIPBACKPAY_REQUEST'
        });

        myaxios.post(`payroll/payroll/${id}/AddUpdateUserAmount/`,{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'PAYSLIPBACKPAY_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('add_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'PAYSLIPBACKPAY_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('add_failure')
            });
        })
    }
}
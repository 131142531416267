import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormFeedback, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row, Input} from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import PasswordChecklist from 'react-password-checklist';
import { change_emp_password, reset_change_emp_password } from '../../../../action/employee/change_emp_password';

export default function ModalUpdatePassword({toggle, closeModal,onSuccess, name, id}){

    const isLoading = useSelector(state=>state.change_emp_password.isLoading);
    const success = useSelector(state=>state.change_emp_password.data);
    const errors = useSelector(state=>state.change_emp_password.errors);
    const dispatch = useDispatch();

    const [new_pass,setNewPass] = useState('');
    const [err_new_pass,setErrNewPass] = useState('');
    const [confirm_pass,setConfirmPass] = useState('');
    const [err_confirm_pass,setErrConfirmPass] = useState('');
    const [is_valid,setValid] = useState(false);


    useEffect(()=>{
        if(toggle){
            setConfirmPass('');
            setErrConfirmPass('');
            setNewPass('');
            setErrNewPass('');
            setValid(false);
        }
    },[toggle])

    useEffect(()=>{
        if(toggle && success != null){
            onSuccess();
            dispatch(reset_change_emp_password());
        }
    },[toggle, success, onSuccess, dispatch])

    useEffect(()=>{
        if(toggle &&  errors != null){
            if(errors.hasOwnProperty('password')){
                const word = errors['password'].toString();
                setErrNewPass(IMLocalized(word));
            }
            dispatch(reset_change_emp_password());
        }
    },[toggle,dispatch,errors])

    const updatePassword=()=>{
        if(new_pass !== '' && confirm_pass !== ''){
            if(is_valid === true){
                dispatch(change_emp_password(id,new_pass));
            }
        }
        else{
            if(new_pass === ''){
                setErrNewPass(IMLocalized('confirm_password_required'));
            }
            if(confirm_pass === ''){
                setErrConfirmPass(IMLocalized('new_password_required'));
            }
        }
    }

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('change_password')} - {name}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='new_pass'>{IMLocalized('new_password')}</label>
                            <Input size="sm" type="password" id="new_pass" name="new_pass" value={new_pass} onChange={(e)=>{setNewPass(e.target.value); setErrNewPass('')}} invalid={err_new_pass.length >0} />
                            <FormFeedback>{err_new_pass}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='confirm_pass'>{IMLocalized('confirm_password')}</label>
                            <Input size="sm" type="password" id="confirm_pass" name="confirm_pass" value={confirm_pass} onChange={(e)=>{setConfirmPass(e.target.value); setErrConfirmPass('')}} invalid={err_confirm_pass.length >0} />
                            <FormFeedback>{err_confirm_pass}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <PasswordChecklist 
                    rules={['minLength','number','capital','match','lowercase','notEmpty','specialChar']}
                    minLength={8}
                    value={new_pass}
                    valueAgain={confirm_pass}
                    onChange={(is_valid)=>{setValid(is_valid)}}
                    messages={{
                        minLength: IMLocalized('password_has_8_char'),
                        number: IMLocalized('password_has_a_number'),
                        capital: IMLocalized('password_has_a_capital_letter'),
                        match:IMLocalized('password_match'),
                        notEmpty:IMLocalized('password_not_empty'),
                        lowercase:IMLocalized('password_has_a_lowercase_letter'),
                        specialChar:IMLocalized('password_has_special')
                    }}
                />
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button className="btn btn-primary btn-sm" onClick={updatePassword}>{IMLocalized('confirm')}</button>
                    <button className="btn btn-danger btn-sm" onClick={closeModal} >{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
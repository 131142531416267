import * as gpas from '../action/get_payroll_additional_setting';


const initialState ={
    isLoading:false,
    errors:{},
    data:[],
}


const get_payroll_additional_setting = (state=initialState,action) =>{
    switch(action.type) {
        case gpas.GETPAYROLLADDITIONALSETTING_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:{},
                data:null,
            };
        case gpas.GETPAYROLLADDITIONALSETTING_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case gpas.GETPAYROLLADDITIONALSETTING_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
            
        default:
            return state;
    }
}
export default get_payroll_additional_setting;
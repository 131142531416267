import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal , ModalBody, ModalFooter, ModalHeader ,Row ,Col, FormGroup, Input, FormFeedback} from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import { update_payroll_update_time_validate } from '../../../action/payroll/update_payroll_update_time_validate';
import { update_payroll_update_time } from '../../../action/payroll/update_payroll_update_time';
import { usePrevious } from '../../../hook/usePrevious';
import { get_payroll_instance } from '../../../action/payroll/get_payroll_instance';

const mapStateToProps=(state)=>({
    validate_success:state.update_payroll_update_time_validate.data,
    validate_errors:state.update_payroll_update_time_validate.errors,
    success:state.update_payroll_update_time.data,
    errors:state.update_payroll_update_time.errors,
    isLoading:state.update_payroll_update_time.isLoading,
    isLoading1:state.update_payroll_update_time_validate.isLoading,
    contractlist:state.get_payslip_employee_contract.data,
})

const mapDispatchToProps=(dispatch)=>({
    update_payroll_update_time_validate:(id,data)=>{
        dispatch(update_payroll_update_time_validate(id,data))
    },
    update_payroll_update_time:(id,data)=>{
        dispatch(update_payroll_update_time(id,data))
    },
    get_payroll_instance:(id)=>{
        dispatch(get_payroll_instance(id))
    }
})

function ModalNopay(props){
    const {get_payroll_instance,validate_success,update_payroll_update_time,success,closeModal} = props;
    const [contract,setContract] = useState('');
    const [err_contract,setErrContract] = useState('');
    const [days,setDays] = useState(0);
    const [err_days,setErrDays] = useState('');
    const [hours,setHours] = useState(0);
    const [err_hours,setErrHours] = useState('');
    const [validatedata,setValidate] = useState([]);
    const [payslip_id,setPayslipID] = useState(null);
    const prevValidateSuccess = usePrevious(validate_success);
    const prevSuccess = usePrevious(success);

    useEffect(()=>{
        if(prevValidateSuccess !== undefined && prevValidateSuccess !== validate_success){
            update_payroll_update_time(props.id,validatedata);
        }
    },[prevValidateSuccess,validate_success,update_payroll_update_time,props.id,validatedata])

    useEffect(()=>{
        if(prevSuccess !== undefined && prevSuccess !== success){
            closeModal();
            get_payroll_instance(props.id);
        }
    },[prevSuccess,success,closeModal,props.id,get_payroll_instance])

    useEffect(()=>{
        if(props.emp_payslip != null){
            setPayslipID(props.emp_payslip.id);
        }
    },[props.emp_payslip])


    useEffect(()=>{
        if(props.toggle){
            setErrContract('');
            setErrDays('');
            setErrHours('');
            setDays(0);
            setHours(0);
            setValidate([]);
            if(props.payslipcontract.length !== 0){
                const id = props.payslipcontract[0].id;
                setContract(id);
            }
        }
    },[props.toggle,props.payslipcontract])

    const onSubmit=()=>{
        if(hours !== '' && days !== '' && contract !== ''){
            if(props.type === 'nopay'){
                submitNoPay();
            }
            else if(props.type === 'absent'){
                submitAbsent();
            }
            else if(props.type === 'national'){
                submitNational();
            } 
        }
        else{
            if(hours === ''){
                setErrHours(IMLocalized('hours_is_required'));
            }
            if(days === ''){
                setErrDays(IMLocalized('days_is_required'));
            }
            if(contract === ''){
                setErrContract(IMLocalized('payslip_contract_is_required'));
            }
        }
    }

    const submitNoPay=()=>{
        
        const NPL = {hours:hours,days:days};
        const NSMUP = null;
        const ABS = null;
        const data = [{payslip_contract:contract,NPL,NSMUP,ABS,payslip:payslip_id}];
        props.update_payroll_update_time_validate(props.id,data);
        setValidate(data);

    }

    const submitAbsent=()=>{
        const ABS = {hours:hours,days:days};
        const NSMUP = null;
        const NPL = null;
        const data = [{payslip_contract:contract,NPL,NSMUP,ABS,payslip:payslip_id}];
        props.update_payroll_update_time_validate(props.id,data);
        setValidate(data);
    }

    const submitNational=()=>{
        const ABS = null;
        const NSMUP = {hours:hours,days:days};
        const NPL = null;
        const data = [{payslip_contract:contract,NPL,NSMUP,ABS,payslip:payslip_id}];
        props.update_payroll_update_time_validate(props.id,data);
        setValidate(data);
    }

    let string = '';
    if(props.type === 'nopay'){
        string = IMLocalized('add_no_pay_leave')
    }
    else if (props.type === 'national'){
        string = IMLocalized('add_national_service');
    }
    else if (props.type === 'absent'){
        string = IMLocalized('add_absent');
    }

    return(
        <Modal isOpen={props.toggle} size="lg">
            <ModalHeader toggle={props.closeModal}>{string}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label'>{IMLocalized('payslip_contract')}</label>
                            <Input size="sm" type="select" value={contract} onChange={(e)=>{setContract(e.target.value); setErrContract('')}} invalid={err_contract.length > 0}>
                                <option value=""></option>
                                {props.payslipcontract.map((item,index)=>{
                                    const name = item.contract?.job_title ? `${item.contract.job_title} - ${item.gross_month}` :  item.gross_month
                                    return <option value={item.id} key={index}>{name}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_contract}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label'>{IMLocalized('payslip_days')}</label>
                            <Input size="sm" type="number" min="0" step="1" value={days} onChange={(e)=>{setDays(e.target.value) ; setErrDays('')}} invalid={err_days.length >0}  />
                            <FormFeedback>{err_days}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label'>{IMLocalized('payslip_hours')}</label>
                            <Input size="sm" type="number" min='0' step="1" value={hours} onChange={(e)=>{setHours(e.target.value) ; setErrHours('')}} invalid={err_hours.length > 0} />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
            {props.isLoading || props.isLoading1 ?
                <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                <button className='btn btn-primary btn-sm' onClick={onSubmit}>{IMLocalized('add')}</button>
                <button className='btn btn-danger btn-sm' onClick={props.closeModal}>{IMLocalized('close')}</button>
                </>
            }
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalNopay);
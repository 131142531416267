import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATEEMPLOYEE_REQUEST = 'UPDATEEMPLOYEE_REQUEST';
export const UPDATEEMPLOYEE_SUCCESS = 'UPDATEEMPLOYEE_SUCCESS';
export const UPDATEEMPLOYEE_FAILURE = 'UPDATEEMPLOYEE_FAILURE';
export const UPDATEEMPLOYEE_RESET = 'UPDATEEMPLOYEE_RESET';

export const update_employee = (arr)=>{
    return dispatch =>{
        dispatch({
            type: 'UPDATEEMPLOYEE_REQUEST'
        });
        if(arr.length !== 0){
            for (let i =0;i<arr.length; i++){
                const { salutation, name,employee_number, badge_number, email, id_type, identification_number, contact_number,
                gender, race, religion, relationship, date_of_birth, company, branch, payment,is_pr,
                pr_start_date,
                pr_cpf_contribution_type,
                join_date,
                probation_end_date,
                country_of_residence,
                sg_postal_code,
                block_number,
                street_name,
                level_number,
                unit_number,
                postal_code,
                address,
                empid
                } = arr[i];
                myaxios.patch(`employee/employees/${empid}/`,{
                    salutation:salutation,
                    name:name,
                    employee_number:employee_number,
                    badge_number:badge_number,
                    email:email,
                    id_type:id_type,
                    identification_number:identification_number,
                    contact_number:contact_number,
                    gender:gender,
                    race:race,
                    religion:religion,
                    relationship:relationship,
                    date_of_birth:date_of_birth,
                    company:company,
                    branch:branch,
                    payment:payment,
                    is_pr:is_pr,
                    pr_start_date:pr_start_date,
                    pr_cpf_contribution_type:pr_cpf_contribution_type,
                    join_date:join_date,
                    probation_end_date:probation_end_date,
                    country_of_residence:country_of_residence,
                    sg_postal_code:sg_postal_code,
                    block_number:block_number,
                    street_name:street_name,
                    level_number:level_number,
                    unit_number:unit_number,
                    postal_code:postal_code,
                    address:address
                })
                .then((response)=>{
                    if(i === arr.length -1){
                        dispatch({
                            type:'UPDATEEMPLOYEE_SUCCESS',
                            payload:response
                        });

                        Toast.fire({
                            type: 'success',
                            title: IMLocalized('update_success')
                        });
                    }
                })
                .catch((error)=>{
                    dispatch({
                        type: 'UPDATEEMPLOYEE_FAILURE',
                        payload: error,
                        error:true
                    });

                    ErrToast.fire({
                        type:'error',
                        title:IMLocalized('update_failure')
                    });
                })
            }
        }
    }
}

export const reset_update_employee = () => dispatch =>{
    dispatch({
        type:'UPDATEEMPLOYEE_RESET'
    });
}
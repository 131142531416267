import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const REGENERATEPAYSLIP_REQUEST = 'REGENERATEPAYSLIP_REQUEST';
export const REGENERATEPAYSLIP_SUCCESS = 'REGENERATEPAYSLIP_SUCCESS';
export const REGENERATEPAYSLIP_FAILURE = 'REGENERATEPAYSLIP_FAILURE';

export const regenerate_payslip = (id,data)=>{
    return dispatch=>{
        dispatch({
            type:'REGENERATEPAYSLIP_REQUEST'
        });

        myaxios.post(`payroll/payroll/${id}/RegeneratePayslips/`,{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'REGENERATEPAYSLIP_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('regenerate_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'REGENERATEPAYSLIP_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('regenerate_failure')
            });
        })
    }
}
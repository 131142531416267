import * as massassigngroup from '../../action/leave/mass_assign_leave_group_validate';


const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const mass_assign_leave_group_validate = (state=initialState,action)=>{
    switch(action.type){
        case massassigngroup.MASSASSIGNLEAVEGROUPVALIDATE_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case massassigngroup.MASSASSIGNLEAVEGROUPVALIDATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case massassigngroup.MASSASSIGNLEAVEGROUPVALIDATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default mass_assign_leave_group_validate;
import * as undopayroll from '../../action/payroll/undo_payroll';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const undo_payroll = (state=initialState,action)=>{
    switch(action.type){
        case undopayroll.UNDOPAYROLL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case undopayroll.UNDOPAYROLL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case undopayroll.UNDOPAYROLL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default undo_payroll;

import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const DETAILUPDATEPAYMENTAMOUNT_REQUEST = 'DETAILUPDATEPAYMENTAMOUNT_REQUEST';
export const DETAILUPDATEPAYMENTAMOUNT_SUCCESS = 'DETAILUPDATEPAYMENTAMOUNT_SUCCESS';
export const DETAILUPDATEPAYMENTAMOUNT_FAILURE = 'DETAILUPDATEPAYMENTAMOUNT_FAILURE';


export const payroll_detail_update_payment_amount = (id,data)=>{
    return dispatch=>{
        dispatch({
            type:'DETAILUPDATEPAYMENTAMOUNT_REQUEST'
        });
        
        myaxios.post(`payroll/payroll/${id}/UpdatePaymentAmount/`,{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'DETAILUPDATEPAYMENTAMOUNT_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DETAILUPDATEPAYMENTAMOUNT_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}
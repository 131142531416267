import * as rejectclaim from '../../action/employee/emp_all_reject_apply_claim';

const initialState={
    data:null,
    isLoading:false,
    errors:null
}

const emp_all_reject_apply_claim = (state=initialState,action) =>{
    switch(action.type){
        case rejectclaim.EMPALLREJECTAPPLYCLAIM_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case rejectclaim.EMPALLREJECTAPPLYCLAIM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case rejectclaim.EMPALLREJECTAPPLYCLAIM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default emp_all_reject_apply_claim;
import myaxios from '../../axios';
export const EMPPAYSLIPDATEFILTER_REQUEST = 'EMPPAYSLIPDATEFILTER_REQUEST';
export const EMPPAYSLIPDATEFILTER_SUCCESS = 'EMPPAYSLIPDATEFILTER_SUCCESS';
export const EMPPAYSLIPDATEFILTER_FAILURE = 'EMPPAYSLIPDATEFILTER_FAILURE';
export const EMPPAYSLIPDATEFILTER_RESET = 'EMPPAYSLIPDATEFILTER_RESET';

export const employee_payslip_date_filter=(from_date,to_date)=>{
    return dispatch=>{
        dispatch({
            type:'EMPPAYSLIPDATEFILTER_REQUEST'
        });

        myaxios.post('payroll/payroll/ReportDateFiler/',{
            from_date:from_date,
            to_date:to_date
        })
        .then((response)=>{
            dispatch({
                type:'EMPPAYSLIPDATEFILTER_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'EMPPAYSLIPDATEFILTER_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_payroll_date = () => dispatch =>{
    dispatch({
        type:'EMPPAYSLIPDATEFILTER_RESET'
    });
}
import * as assignscheme from '../../action/claim/claim_assign_scheme';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const claim_assign_scheme =(state=initialState,action)=>{
    switch(action.type){
        case assignscheme.CLAIMASSIGNSCHEME_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case assignscheme.CLAIMASSIGNSCHEME_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case assignscheme.CLAIMASSIGNSCHEME_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default claim_assign_scheme;
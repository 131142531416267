import * as grouptemp from '../../action/leave/get_leave_group_template';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const get_leave_group_template = (state=initialState,action)=>{
    switch(action.type){
        case grouptemp.GETLEAVEGROUPTEMPLATE_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case grouptemp.GETLEAVEGROUPTEMPLATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case grouptemp.GETLEAVEGROUPTEMPLATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_leave_group_template;
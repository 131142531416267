import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const CREATEJOBLEVEL_SUCCESS = 'CREATEJOBLEVEL_SUCCESS';
export const CREATEJOBLEVEL_REQUEST = 'CREATEJOBLEVEL_REQUEST';
export const CREATEJOBLEVEL_FAILURE = 'CREATEJOBLEVEL_FAILURE';
export const CREATEJOBLEVEL_RESET = 'CREATEJOBLEVEL_RESET';

export const create_joblevel = (name)=>{
    return dispatch =>{
        dispatch({
            type: 'CREATEJOBLEVEL_REQUEST'
        });
        
        myaxios.post(`company/joblevel/`,{
            name:name
        })
        .then((response)=>{
            dispatch({
                type:'CREATEJOBLEVEL_SUCCESS',
                payload:response
                
            });
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error) =>{
            dispatch({
                type: 'CREATEJOBLEVEL_FAILURE',
                payload: error,
                error:true
            });
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_failure')
            })
        })
    }
}

export const reset_create_joblevel = () => dispatch =>{
    dispatch({
        type:'CREATEJOBLEVEL_RESET'
    });
}
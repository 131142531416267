import * as medical_autocomplete from '../../action/claim/get_medical_claim_autocomplete';

const initialState={
    data:[],
    errors:[],
    isLoading:false,
    location:[],
    doctor:[],
    diagnosis:[]
}

const get_medical_claim_autocomplete = (state=initialState,action)=>{
    switch(action.type){
        case medical_autocomplete.GETMEDICALAUTOCOMPLETE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[]
            }
        case medical_autocomplete.GETMEDICALAUTOCOMPLETE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
                location:action.payload.data.location,
                diagnosis:action.payload.data.diagnosis,
                doctor:action.payload.data.doctor,
            }
        case medical_autocomplete.GETMEDICALAUTOCOMPLETE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload,

            }
        default:
            return state;
    }
}
export default get_medical_claim_autocomplete;
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Card,Col,Row} from 'reactstrap';
import blank_picture from '../../../../images/blank_picture.png';
import { IMLocalized } from '../../../../language/IMLocalized';
import { get_employee_profile_picture } from '../../../../action/employee/get_employee_profile_picture';
import { connect } from 'react-redux';
import { Buffer } from 'buffer';
import moment from 'moment';
import { getDateFormat } from '../../../../func/getDateFormat';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserContext } from '../UserPayrollDetail';

const mapStateToProps=(state)=>({
    user_picture:state.get_user_picture.data,
    pay_component_type:state.dropdown_list.pay_component_type,
    wagelist:state.dropdown_list.wage,
    paymentlist:state.dropdown_list.payment,
    letterhead:state.get_general_letter.data,
    isLoading:state.get_general_letter.isLoading,
})

const mapDispatchToProps=(dispatch)=>({
    get_employee_profile_picture:(id)=>{
        dispatch(get_employee_profile_picture(id))
    }
})

function InformationTable(props){
    
    const [emp_pic,setEmpPic] = useState(null);
    const [letter,setLetter] = useState(null);
    const [monthNames] = useState(['January','February','March','April','May','June','July','August','September','October','November','December']);

    const user = useContext(UserContext);

    useEffect(()=>{
        if(props.user_picture.length !== 0 ){
            const img = new Buffer.from(props.user_picture,'binary').toString('base64');
            const src = `data:image/png;base64,${img}`;
            setEmpPic(src);
        }
        else{
            setEmpPic(null);
        }
    },[props.user_picture])

    useEffect(()=>{
        if(props.letterhead.length !== 0){
            const pic = props.letterhead.data;
            const byte = pic.byteLength;
            if(byte === 0){
                setLetter(null);
            }
            else{
                const img = new Buffer.from(pic,'binary').toString('base64');
                const src = `data:image/png;base64,${img}`;
                setLetter(src);
            }
        }
    },[props.letterhead])

    const getPrint=useCallback(()=>{

        const today = moment().format('DD/MM/YYYY');
        const head5 = [[{content:'Mode of Payment'}]];
        const head4 = [[{content:'Date of Payment'}]];
        const head3 = [[{content: 'Other Pay (E)', styles: {halign: 'left'}}, {content: 'Amount', styles: {halign: 'right'}}]];
        const head1 = [[{content: 'Salary (A)', styles: {halign: 'left'}}, {content: 'Amount', styles: {halign: 'right'}}]];
        const head7 = [[{content: 'Total Deductions (C)', styles: {halign: 'left'}}, {content: 'Amount', styles: {halign: 'right'}}]];
        const head6 = [[{content: 'Total Allowances (B)', styles: {halign: 'left'}}, {content: 'Amount', styles: {halign: 'right'}}]];
        const head2 = [[{content: 'Overtime (D)'},{content:'Period',styles:{halign:'center'}},{content:'Hrs Work',styles:{halign:'center'}},{content:'Wage',styles:{halign:'center'}},{content: 'Amount', styles: {halign: 'right'} }]];
        let cell1 =[];
        let cell2 =[];
        let cell3 = [];
        let cell4 = [];
        let cell5 = [];
        let cell6 = [];
        let cell7 = [];
        let cell8 =[];

        const {employee,pay,paid_date,payroll,payroll_date,payable_wage,payslipcontract,sgshg,sgcpf} = user.emp_payslip;
        const {payment,metadata} = employee;
        const payment_detail = props.paymentlist.find(item=>item.value === payment);
        const payment_name = `${payment_detail?.display} Deposit` ?? '';    
        cell5.push([payment_name]);
        const {start_date,end_date} = payroll;
        const payer = metadata.company;
        const payee = employee.name;

        const month1 = new Date(payroll_date).getMonth();
        const year = new Date(payroll_date).getFullYear();
        const payroll_date2 = `${monthNames[month1]}-${year}`;

        for(const payslip_item of payslipcontract){
            cell1.push([`Basic Pay (${getDateFormat(payslip_item.from_date)} to ${getDateFormat(payslip_item.to_date)})` ,`$${(payslip_item.gross_month).toFixed(2)}` ])
        }

        const overtimelist = pay.filter(item=>item.pay_type === 'OT');
        const allowancelist = pay.filter(item=>item.pay_type === 'ALLOWANCE');
        const reccuringlist = pay.filter(item=>item.pay_type === 'RA');
        const flatlist = pay.filter(item=>item.pay_type === 'FX');
        const backpaylist = pay.filter(item=>item.pay_type === 'BACKPAY' || item.pay_type === 'COMM' || item.pay_type === 'GRATUITY' || item.pay_type === 'INSURANCE' || item.pay_type === 'SHARES' || item.pay_type === 'DIRECTORS' || item.pay_type === 'PENSION' || item.pay_type === 'RETIREMENT' || item.pay_type === 'OTHERS' || item.pay_type === 'DEDUCT');
        const bonuslist = pay.filter(item=>item.pay_type === 'BONUS');
        const nopaylist = pay.filter(item=>item.pay_type === 'NPL');
        const absentlist = pay.filter(item=>item.pay_type === 'ABS');
        const nationlist = pay.filter(item=>item.pay_type === 'NSMUP');
        const deductablelist = pay.filter(item=>item.pay_type === 'DEDUCT');
        const claimlist = pay.filter(item=>item.pay_type === 'CLAIM')
        for(const overtime of overtimelist){
            const {name,is_gross,amount,payslip_contract,wage,count} = overtime;

            const wage_detail = props.wagelist.find(el=>el.value === wage);
            const detail  = payslipcontract.find(el=>el.id === payslip_contract);
            const date = detail ? `${getDateFormat(detail.from_date)} to ${getDateFormat(detail.to_date)}` :'';
            const wage_name = wage_detail?.display ?? '';
            if(!is_gross && amount >0 ){
                cell2.push([
                    name,
                    date,
                    count,
                    wage_name,
                    `$${amount.toFixed(2)}`
                ])
            }
        }
        let allowance_length = 0;
        for(const allowance of allowancelist){
            const {amount,is_gross,name} = allowance;
            if(!is_gross && amount >0){
                cell6.push([name,`$${amount.toFixed(2)}`])
                allowance_length++;
            }
        }

        for(const recurring of reccuringlist){
            const {amount,name,is_gross} = recurring;
            if(!is_gross && amount >0){
                cell6.push([name,`$${amount.toFixed(2)}`]);
            }
        }
        for(const flat of flatlist){
            const {amount,code,is_gross} = flat;
            if(!is_gross && amount >0){
                cell3.push([code,`$${amount.toFixed(2)}`]);
            }
        }

        for(const backpay of backpaylist){
            const {amount,pay_type,deductable,is_gross} = backpay;
            if(!is_gross && !deductable && amount >0){
                const pay_detail = props.pay_component_type.find(item=>item.value === pay_type);
                const name = pay_detail?.display ?? '';
                cell3.push([`${name} (${backpay.name})`,`$${amount.toFixed(2)}`]);
            }
        }

        for(const bonus of bonuslist){
            const {is_gross,amount,pay_type} = bonus;
            if(!is_gross && amount >0){
                const pay_detail = props.pay_component_type.find(item=>item.value === pay_type);
                const name = pay_detail?.display ?? '';
                cell3.push([name,`$${amount.toFixed(2)}`]);
            }
        }

        if(sgcpf !== null){
            const amount = sgcpf.cpf_employee;
            cell7.push(['CPF Employee','$'+(amount).toFixed(2)]);
        }

        if(sgshg.length !== 0  && sgshg != null){
            for(const shg_item of sgshg){
                cell7.push([shg_item.shg,`$${shg_item.amount.toFixed(2)}`]);
            }
        }

        for(const nopay of nopaylist){
            const {amount,code} = nopay;
            cell7.push([code,`$${amount.toFixed(2)}`]);
        }

        for(const nation of nationlist){
            const {amount,code} = nation;
            cell7.push([code,`$${amount.toFixed(2)}`]);
        }

        for(const absent of absentlist){
            const {amount,pay_type} = absent;
            const detail = props.pay_component_type.find(el=>el.value === pay_type);
            const name = detail?.display ?? '';
            cell7.push([name,`$${amount.toFixed(2)}`]);
        }

        for(const deduct of deductablelist){
            const {pay_type,amount} = deduct;
            const detail = props.pay_component_type.find(el=>el.value === pay_type);
            const name = detail?.display ?? '-';
            cell7.push([name,`$${amount.toFixed(2)}`]);
        }

        if(paid_date !== null){
            cell4.push([getDateFormat(paid_date)]);
        }

        for(const claim of claimlist){
            const {amount} = claim;
            cell3.push(['Total Claim (E)',`$${amount.toFixed(2)}`]);
        }
        cell8.push(['Net Pay (A+B-C+D+E)','$'+(payable_wage.toFixed(2))]);

        if(sgcpf !== null){
            const {cpf_employer} = sgcpf;
            cell8.push(["Employer's CPF Contributions" , '$'+(cpf_employer).toFixed(2)]);
        }

        let annual_amount = null;
        if(metadata.hasOwnProperty('annual_amt')){
            const annual_amt = metadata['annual_amt'];
            const check = Array.isArray(annual_amt);
            if(check === false){
                annual_amount = annual_amt;
            }
            else{
                const month = new Date().getMonth();
                const amount = annual_amt[month];
                annual_amount = amount;
            }
        }

        if(annual_amount !== null){
            cell8.push(['Annual Leave Balance',annual_amount]);
        }

        const string = `Employer : ${payer}`;
        const string1 = `Employee : ${payee}`;
        const string2 = `Printed Date : ${today}`;
        const string3 = `Payslip for ${getDateFormat(start_date)} to ${getDateFormat(end_date)}`;
        const pdf_name = payee+ '-'+payroll_date2+'.pdf';
        var doc = new jsPDF();
        if(letter !== null){
            doc.addImage(letter, 'JPEG', 20, 10, 180, 40);
        }
        doc.setFontSize(10);
        doc.text(string,15,60,'left');
        doc.text(string1,107,60,'left');
        doc.text(string2,15,70,'left');
        doc.text(string3,107,70,'left');
        doc.autoTable({
            head:head4,
            body:cell4,
            startY:80,
            tableWidth:90,
            
            columnStyles:{ 0: {halign:'left'}},
            
        })
        doc.autoTable({
            head:head5,
            body:cell5,
            startY:80,
            startX:110,
            margin: {left: 107} ,
            columnStyles:{ 0: {halign:'left'}}
        })

        doc.autoTable({
            head:head1,
            body:cell1,
            tableWidth:90,
            startY:100,
            columnStyles:{ 0: {halign:'left'},1: {halign:'right'}}
        })

        doc.autoTable({
            head:head6,
            body:cell6,
            margin: {left: 107} ,
            startY:100,
            columnStyles:{ 0: {halign:'left'},1: {halign:'right'}}
        })
        let abc = doc.autoTable.previous.finalY + 5
        if(allowance_length === 0){
            const length = payslipcontract.length;
            const number = length * 5 +10
            abc = doc.autoTable.previous.finalY + number;
        }

        doc.autoTable({
            head:head7,
            body:cell7,
            startY: typeof doc.autoTable.previous.finalY === 'undefined' ? 50 : abc,
            columnStyles:{ 0: {halign:'left'},1: {halign:'right'}}
        })
        
        doc.autoTable({
            head:head2,
            body:cell2,
            startY: typeof doc.autoTable.previous.finalY === 'undefined' ? 50 : doc.autoTable.previous.finalY + 5,
            columnStyles:{ 0: {halign:'left'},1:{halign:'center'},2:{halign:'center'},3:{halign:'center'},4: {halign:'right'}}
        })
        doc.autoTable({
            head:head3,
            body:cell3,
            startY: typeof doc.autoTable.previous.finalY === 'undefined' ? 50 : doc.autoTable.previous.finalY + 5,
            columnStyles:{ 0: {halign:'left'},1: {halign:'right'}},
        })

        doc.autoTable({
            head:[],
            body:cell8,
            startY: typeof doc.autoTable.previous.finalY === 'undefined' ? 50 : doc.autoTable.previous.finalY + 5,
            columnStyles:{ 0: {halign:'left'},1: {halign:'right'}},
        })

        doc.save(pdf_name);
    },[props.pay_component_type,props.wagelist,props.paymentlist,user.emp_payslip,letter,monthNames])

    return(
        <Row>
            <Col xl="6" lg="12" md="12">
                <Card>
                    <div className='d-flex flex-row cursor-pointer'>
                        <div className='p-0 w-30'>
                            <img alt="employeepayslip" src={emp_pic ?? blank_picture} className="img-thumbnail" style={{height:'100px',width:'100px'}}/>
                        </div>
                        <div className='p-2 w-70 border-left' style={{flexGrow:100}}>
                            <h4 className='mb-0 text-primary'>{IMLocalized('employee_number')} : {user.emp_payslip ? user.emp_payslip.employee.employee_number : ''} </h4>
                            <h3 >{user.emp_payslip ? user.emp_payslip.employee.name : ''}</h3>
                            <div className='d-flex justify-content-end align-items-end mr-2'>
                                {props.isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button> :
                                <button className='btn btn-primary btn-sm' onClick={()=>getPrint()}>Print</button>}
                            </div>
                        </div>
                    </div>
                </Card>
            </Col>
        </Row>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(InformationTable);

import * as currency from '../action/general_currency';

const initialState={
    data:[],
    isLoading:false,
    errors:[],
}

const general_currency = (state=initialState,action)=>{
    switch(action.type){
        case currency.GENERALCURRENCY_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[]
            }
        case currency.GENERALCURRENCY_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case currency.GENERALCURRENCY_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default general_currency;
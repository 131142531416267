import myaxios from '../../axios';


export const GETAPPRAISALFORMLIST_REQUEST = 'GETAPPRAISALFORMLIST_REQUEST';
export const GETAPPRAISALFORMLIST_SUCCESS = 'GETAPPRAISALFORMLIST_SUCCESS';
export const GETAPPRAISALFORMLIST_FAILURE = 'GETAPPRAISALFORMLIST_FAILURE';



export const get_appraisal_form_list =()=>{
    return dispatch=>{
        dispatch({
            type:'GETAPPRAISALFORMLIST_REQUEST'
        })

        myaxios.get('forms/forms/')
        .then((response)=>{
            dispatch({
                type:'GETAPPRAISALFORMLIST_SUCCESS',
                payload:response
            });
        })
        .catch((error)=>{
            dispatch({
                type:'GETAPPRAISALFORMLIST_FAILURE',
                payload:error
            })
        })
    }

}
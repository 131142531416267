import React from "react";
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import { IMLocalized } from "../../language/IMLocalized";
import { getDateFormat } from '../../func/getDateFormat';
import { getColumnName } from '../../func/getColumnName';
import { getValue } from '../../func/getValue';
import moment from 'moment';
import FileSaver from 'file-saver';
import ExcelJs from 'exceljs';


export default function AppraisalActionGroup({grouplist,handleGroup}){


    const exportGroup=(id)=>{
        const detail = grouplist.find(item=>item.id === id);
        if(detail) generateGroup(detail?.appraisals,detail?.name);
    }

    const generateGroup=(item,name)=>{
        const wb= new ExcelJs.Workbook();
        const ws = wb.addWorksheet('Appraisal Report Group');
        ws.getCell('A1').value = 'Appraisal Report - Group';
        ws.getCell('A2').value =`Printed On : ${moment(new Date()).format('DD/MM/YYYY HH:mm:ss')}`;
        
        const column = [
            {headerName:'EMP ID',field:'employee.employee_number'},
            {headerName:'EMP NAME',field:'employee.name'},
            {headerName:'DEPARTMENT',field:'employee.contract_cache.department'},
            {headerName:'SECTION',field:'employee.contract_cache.section'},
            {headerName:'JOB TITLE',field:'employee.contract_cache.job_title'},
            {headerName:'JOB LEVEL',field:'employee.contract_cache.job_level'},
            {headerName:'COST CENTER',field:'employee.contract_cache.cost_center'},
            {headerName:'PERCENTAGE (%)',field:'percentage'}
        ];

        ws.columns = [
            {width:15},
            {width:15},
            {width:15},
            {width:15},
            {width:15},
            {width:15},
            {width:15},
            {width:15},
        ]
    
        for(let i =0;i<column.length ;i++){
            const headerName = column[i].headerName;
            const field = (column[i].field);
            const length = item.length;
            ws.getCell(getColumnName(i)+'4').value = headerName;
            for(let x=0;x<length;x++){
                const step = 5+x;
                const data = getValue(item[x],field);
                if(field !== 'percentage'){
                    ws.getCell(getColumnName(i)+step).value = data;
                }
                else{
                   
                    const max = item[x].max_score;
                    const total = item[x].total_score;
                    const final = (total/max) * 100;
                    ws.getCell(getColumnName(i)+step).value = final;
                }
            }
        }

        const filename = `Appraisal_Report_Group(${name}).xlsx`;
        wb.xlsx.writeBuffer().then((buf)=>{

            var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
            FileSaver.saveAs(file, filename)
        });
    }

    return(
        <Row className='mt-2'>
            <Col>
                <Card>
                    <CardHeader>
                        <h4 className='mb-0' >{IMLocalized('appraisal')}</h4>
                    </CardHeader>
                    {grouplist.length === 0  && 
                    <CardBody className="d-flex justify-content-center align-items-center">
                        <span className="h4  mb-0">{IMLocalized('no_data')}</span>
                    </CardBody>}
                    {grouplist.length !== 0 && 
                    <Table size="sm" className="table-bordered align-items-center" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th>{IMLocalized('name')}</th>
                                <th>{IMLocalized('year')}</th>
                                <th>{IMLocalized('from_date')}</th>
                                <th>{IMLocalized('to_date')}</th>
                                <th>{IMLocalized('completed')}</th>
                                <th>{IMLocalized('export_excel')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {grouplist.map((item,index)=>{
                                return(
                                    <tr key={index}>
                                        <td className="cursor-pointer" onClick={()=>handleGroup(item)}>{item.name}</td>
                                        <td className="cursor-pointer" onClick={()=>handleGroup(item)}>{item.year}</td>
                                        <td className="cursor-pointer" onClick={()=>handleGroup(item)}>{getDateFormat(item.from_date)}</td>
                                        <td className="cursor-pointer" onClick={()=>handleGroup(item)}>{getDateFormat(item.to_date)}</td>
                                        <td>
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" id={"appraisalgroup"+index} name="appraisal" className="custom-control-input" checked={item.complete} />
                                                <label className="custom-control-label" htmlFor={"appraisalgroup"+index}></label>
                                            </div>
                                        </td>
                                        <td>{item.complete === true ? <button className="btn btn-success btn-sm" onClick={()=>exportGroup(item.id)}>{IMLocalized('export')}</button> :'-'}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>}
                </Card>
                
            </Col>
        </Row>
    )
}
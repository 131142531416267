import myaxios from '../../axios';

export const GETMEDICALAUTOCOMPLETE_REQUEST = 'GETMEDICALAUTOCOMPLETE_REQUEST';
export const GETMEDICALAUTOCOMPLETE_SUCCESS = 'GETMEDICALAUTOCOMPLETE_SUCCESS';
export const GETMEDICALAUTOCOMPLETE_FAILURE = 'GETMEDICALAUTOCOMPLETE_FAILURE';

export const get_medical_claim_autocomplete =()=>{
    return dispatch=>{
        dispatch({
            type:'GETMEDICALAUTOCOMPLETE_REQUEST'
        });

        myaxios.get('claim/medical/')
        .then((response)=>{
            dispatch({
                type:'GETMEDICALAUTOCOMPLETE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETMEDICALAUTOCOMPLETE_FAILURE',
                payload:error
            })
        })
    }
}
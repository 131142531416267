import myaxios from '../../axios';

export const PAYROLLAGGRIDHEADER_REQUEST = 'PAYROLLAGGRIDHEADER_REQUEST';
export const PAYROLLAGGRIDHEADER_SUCCESS = 'PAYROLLAGGRIDHEADER_SUCCESS';
export const PAYROLLAGGRIDHEADER_FAILURE = 'PAYROLLAGGRIDHEADER_FAILURE';


export const payroll_detail_get_aggridheader = (id)=>{
    return dispatch=>{
        dispatch({
            type:'PAYROLLAGGRIDHEADER_REQUEST'
        });

        return myaxios.get(`payroll/payroll/${id}/`)
        .then((response)=>{
            dispatch({
                type:'PAYROLLAGGRIDHEADER_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'PAYROLLAGGRIDHEADER_FAILURE',
                payload:error
            })
        })
    }
}

// export const payroll_detail_get_aggridheader = (id)=>{
//     return dispatch=>{
//         dispatch({
//             type:'PAYROLLAGGRIDHEADER_REQUEST'
//         });

//         return myaxios.get(`payroll/payroll/${id}/AgGridHeader/`)
//         .then((response)=>{
//             dispatch({
//                 type:'PAYROLLAGGRIDHEADER_SUCCESS',
//                 payload:response
//             })
//         })
//         .catch((error)=>{
//             dispatch({
//                 type:'PAYROLLAGGRIDHEADER_FAILURE',
//                 payload:error
//             })
//         })
//     }
// }
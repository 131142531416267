import * as claim_option from '../../action/claim/get_claim_option';

const initialState={
    data:null,
    errors:null,
    isLoading:false
}

const get_claim_option = (state=initialState,action)=>{
    switch(action.type){
        case claim_option.GETCLAIMOPTION_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null
            }
        case claim_option.GETCLAIMOPTION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case claim_option.GETCLAIMOPTION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        default:
            return state;
    }
}
export default get_claim_option;
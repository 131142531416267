import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import { create_payroll_additional_setting, reset_create_payroll } from '../../../action/create_payroll_additional_setting';
import moment from 'moment';
import { get_payroll_additional_setting } from '../../../action/get_payroll_additional_setting';

const initialState={
    code:'',
    err_code:'',
    name:'',
    err_name:'',
    name:'',
    err_name:'',
    payType:'',
    err_payType:'',
    fromDate:'',
    err_wage:'',    
    wage:'',
    err_fromDate:'',
    toDate:'',
    err_toDate:'',
    dateFormat:'DD/MM/YYYY',
}

function ModalCreatePayrollAdditionalSetting({toggle,closeModal}){
    
    const dispatch = useDispatch();
    const isLoading = useSelector(state=>state.create_payroll_additional_setting.isLoading);
    const success = useSelector(state=>state.create_payroll_additional_setting.data);
    const errors = useSelector(state=>state.create_payroll_additional_setting.errors);
    const [data,setData] = useState(initialState);
    const dropdownlist = useSelector(state=>state.dropdown_list.data);

    useEffect(()=>{
        if(toggle){
            setData(initialState);
        }
    },[toggle])

    useEffect(()=>{
        if( toggle && success !== null){
            dispatch(get_payroll_additional_setting());
            dispatch(reset_create_payroll());
            closeModal();
        }
    },[success,toggle,closeModal,dispatch])

    useEffect(()=>{
        if( toggle && errors !== null){
            setData(prevState=>({
                ...prevState,
                err_code:errors.hasOwnProperty('code') ? errors['code'].toString() :'',
                err_name:errors.hasOwnProperty('name') ? errors['name'].toString() :'',
                
            }));
            dispatch(reset_create_payroll());
        }
    },[errors,toggle,dispatch])

    const createPayrollAdditionalSetting=()=>{
        if(data.code !== '' && data.name !== ''){
            if(data.toDate === ''){
                data.toDate = null
            }
            dispatch(create_payroll_additional_setting(data.code,data.name,data.payType,data.wage,data.fromDate,data.toDate))
        }
        else{
            setData(prevState=>({
                ...prevState,
                err_code:data.code === '' ? IMLocalized('code_is_required') : '',
                err_name:data.name === '' ? IMLocalized('name_is_required') : '',
            }))
        }
    }

    const handleChecked = () => {

    }

    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('add_payroll_additional_setting')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='code'>{IMLocalized('code')}</label>
                            <Input size="sm" type="text" id="code" value={data.code} onChange={(e)=>{ const value = e.target.value; setData(prevState=>({...prevState,code:value,err_code:''}))}} invalid={data.err_code.length > 0} />
                            <FormFeedback>{data.err_code}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>{IMLocalized('name')}</label>
                            <Input size="sm" type="text" id="name" value={data.name} onChange={(e)=>{ const value = e.target.value; setData(prevState=>({...prevState,name:value,err_name:''}))}} invalid={data.err_name.length > 0} />
                            <FormFeedback>{data.err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>{IMLocalized('pay_type')}</label>
                            <Input size="sm" type="select" id="payType" value={data.payType}  
                            
                            onChange={(e)=>{const value = e.target.value; setData(prevState=>({...prevState,payType:value}))}}

                            invalid={data.err_payType.length > 0}>
                                {dropdownlist?.pay_component_type?.map((item)=>{
                                    return <option value={item.value} key={item.value}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{data.err_payType}</FormFeedback>
                        </FormGroup>
                    </Col>



                    <Col lg="6">
                        <FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="radio1" value="ORDINARY" onClick={(e)=>{const value = e.target.value; setData(prevState=>({...prevState,wage:value}))}} />
                                    Ordinary
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="radio1" value="ADDITIONAL" onClick={(e)=>{const value = e.target.value; setData(prevState=>({...prevState,wage:value}))}} />
                                    Additional
                                </Label>
                            </FormGroup>
                            <FormFeedback>{data.err_wage}</FormFeedback>
                        </FormGroup>
                    </Col>

                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='fromDate'>{IMLocalized('fromDate')}</label>
                            <Input size="sm" type="date" id="fromDate" value={data.fromDate} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,fromDate:value,err_fromDate:''})) }} invalid={data.err_fromDate.length >0} max={moment(new Date()).format('DD/MM/YYYY')} />
                            <FormFeedback>{data.err_fromDate}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='toDate'>{IMLocalized('toDate')}</label>
                            <Input size="sm" type="date" id="toDate" value={data.toDate} onChange={(e)=>{const value = e.target.value ; setData(prevState=>({...prevState,toDate:value,err_toDate:''})) }} invalid={data.err_toDate.length >0} max={moment(new Date()).format('DD/MM/YYYY')} />
                            <FormFeedback>{data.err_toDate}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button className="btn btn-primary btn-sm" onClick={()=>createPayrollAdditionalSetting()}>{IMLocalized('add')}</button> 
                    <button className="btn btn-danger btn-sm" onClick={()=>closeModal()}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
export default ModalCreatePayrollAdditionalSetting;
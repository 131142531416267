import * as delPAS from '../action/delete_payroll_additional_setting';

const initialState ={
    isLoading:false,
    errors:null,
    data:null
}


const delete_payroll_additional_setting = (state=initialState,action) =>{
    switch(action.type) {
        case delPAS.DELETE_PAYROLL_ADDITIONAL_SETTING_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            };
        case delPAS.DELETE_PAYROLL_ADDITIONAL_SETTING_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case delPAS.DELETE_PAYROLL_ADDITIONAL_SETTING_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default delete_payroll_additional_setting;



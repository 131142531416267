import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const RECALCULATEPAYROLL_REQUEST = 'RECALCULATEPAYROLL_REQUEST';
export const RECALCULATEPAYROLL_SUCCESS = 'RECALCULATEPAYROLL_SUCCESS';
export const RECALCULATEPAYROLL_FAILURE = 'RECALCULATEPAYROLL_FAILURE';


export const recalculate_payroll=(id)=>{
    return dispatch=>{
        dispatch({
            type:'RECALCULATEPAYROLL_REQUEST'
        });

        myaxios.post(`payroll/payroll/${id}/RecalculateAll/`)
        .then((response)=>{
            dispatch({
                type:'RECALCULATEPAYROLL_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('recalculate_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'RECALCULATEPAYROLL_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('recalculate_failure')
            });
        })
    }
}
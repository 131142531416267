import React from "react";
import { useSelector } from "react-redux";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { IMLocalized } from "../../../language/IMLocalized";

export default function ModalViewClaimGroupDetail({ toggle, closeModal, id }){

    const list = useSelector(state=>state.get_claim_group_template.data);
    const claimlist = useSelector(state=>state.get_claim_list.data);
    const managerlist = useSelector(state=>state.get_user_manager_list.data);
    const detail = list.find(item=>item.id === id);

    if(detail){

        let claim_arr = claimlist.map((item)=>{
            const name = item.name;
            const id = item.id;
            const code = item.code;
            const group = 0;
            return {name,id,code,group}
        })

        const { name, groups } = detail;
        const { approvers, exceptions } = groups;
        const approvers_data = [];
        for(let i=0;i<approvers.length ;i++){
            approvers_data.push({employee:approvers[i].employee, ordering:approvers[i].ordering})
        }
        const emp_arr = [approvers_data];
        for(let i= 0; i<exceptions.length ;i++){
            emp_arr.push(exceptions[i].approvers);
            const ex = exceptions[i].exceptions;
            for(let x = 0; x < ex.length ; x++){
                const id = ex[x];
                claim_arr = claim_arr.map((element)=>{
                    if(element.id === id){
                        return {...element, group: i+1 }
                    }
                    return {...element}
                });
            }
        }
       
        const claim1 = claim_arr.filter(item=>item.group === 0);
        const claim2 = claim_arr.filter(item=>item.group === 1);
        const claim3 = claim_arr.filter(item=>item.group === 2);

        const emp1 = emp_arr[0];
        const emp2 = emp_arr[1];
        const emp3 = emp_arr[2];

        return(
            <Modal size="lg" isOpen={toggle} className="modal-dialog-centered" style={{maxWidth: '1600px', width: '80%'}} >
                <ModalHeader toggle={closeModal}>
                    {IMLocalized('claim_group_template')} - {name}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <h4>{IMLocalized('group')} 1</h4>
                            <Table className="table-bordered">
                                <thead className="thead-light">
                                    <tr>
                                        <th width="50%">{IMLocalized('claim_type')}</th>
                                        <th>{IMLocalized('employee')}</th>
                                    </tr>
                                    
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {claim1.map((item)=>{
                                                return(
                                                    <div key={item.id}>
                                                        <span className="mr-2" >●</span>
                                                        <span>{item.name}</span>
                                                    </div>
                                                )
                                            })}
                                        </td>
                                        <td>
                                            {emp1 != null && emp1.length !== 0 && emp1.map((item)=>{
                                                const emp_data = managerlist.find(el=>el.id === parseInt(item.employee));
                                                const name = emp_data?.name ?? '';
                                                
                                                const ordering = parseInt(item.ordering) +1; 
                                                return(
                                                    <div key={item.employee}>
                                                        <span className="mr-2" >●</span>
                                                        <span>{name} - {IMLocalized('level')} ({ordering})</span>
                                                    </div>
                                                )
                                            })}
                                            {emp1 != null && emp1.length === 0 && 
                                                <div>
                                                    <span>{IMLocalized('no_employee')}</span>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    {claim2.length !== 0 &&
                    <Row>
                        <Col>
                            <h4>{IMLocalized('group')} 2</h4>
                            <Table className="table-bordered">
                                <thead className="thead-light">
                                    <tr>
                                        <th width="50%">{IMLocalized('claim_type')}</th>
                                        <th>{IMLocalized('employee')}</th>
                                    </tr>
                                    
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {claim2.map((item)=>{
                                                return(
                                                    <div key={item.id}>
                                                        <span className="mr-2" >●</span>
                                                        <span>{item.name}</span>
                                                    </div>
                                                )
                                            })}
                                        </td>
                                        <td>
                                            {emp2 != null && emp2.map((item)=>{
                                                const emp_data = managerlist.find(el=>el.id === parseInt(item.employee));
                                                const name = emp_data?.name ?? '';
                                                
                                                const ordering = parseInt(item.ordering) +1; 
                                                return(
                                                    <div key={item.employee}>
                                                        <span className="mr-2" >●</span>
                                                        <span>{name} - {IMLocalized('level')} ({ordering})</span>
                                                    </div>
                                                )
                                            })}
                                            {emp2 != null && emp2.length === 0 && 
                                                <div>
                                                    <span>{IMLocalized('no_employee')}</span>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>}
                    {claim3.length !== 0 && 
                    <Row>
                        <Col>
                            <h4>{IMLocalized('group')} 3</h4>
                            <Table className="table-bordered">
                                <thead className="thead-light">
                                    <tr>
                                        <th width="50%">{IMLocalized('claim_type')}</th>
                                        <th>{IMLocalized('employee')}</th>
                                    </tr>
                                    
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {claim3.map((item)=>{
                                                return(
                                                    <div key={item.id}>
                                                        <span className="mr-2" >●</span>
                                                        <span>{item.name}</span>
                                                    </div>
                                                )
                                            })}
                                        </td>
                                        <td>
                                            {emp3 != null && emp3.map((item)=>{
                                                const emp_data = managerlist.find(el=>el.id === parseInt(item.employee));
                                                const name = emp_data?.name ?? '';
                                                
                                                const ordering = parseInt(item.ordering) +1; 
                                                return(
                                                    <div key={item.employee}>
                                                        <span className="mr-2" >●</span>
                                                        <span>{name} - {IMLocalized('level')} ({ordering})</span>
                                                    </div>
                                                )
                                            })}
                                            {emp3 != null && emp3.length === 0 && 
                                                <div>
                                                    <span>{IMLocalized('no_employee')}</span>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>}
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
                </ModalFooter>
            </Modal>
        )
    }
    else{
        return null;
    }
}
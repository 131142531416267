import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { create_claim_approval1, reset_create_claim_approval1 } from "../../../action/claim/create_claim_approval1";
import { IMLocalized } from "../../../language/IMLocalized";
import { popError } from "../../../func/popError";


export default function ModalClaimApprovalName({ toggle, closeModal, v_exceptions, v_approvers }){

    const dispatch = useDispatch();
    const errors = useSelector(state=>state.create_claim_approval1.errors);
    const isLoading = useSelector(state=>state.create_claim_approval1.isLoading);
    const [ name, setName ] = useState('');
    const [ err_name, setErrName] = useState('');

    useEffect(()=>{
        if(errors != null){
            if(errors.hasOwnProperty('non_field_errors')){
                const word = errors['non_field_errors'].toString();
                popError(word);
            }
            dispatch(reset_create_claim_approval1());
        }
    },[errors,dispatch])

    useEffect(()=>{
        if(toggle === true){
            setName('');
            setErrName('');
        }
    },[toggle])

    const onSubmit = () =>{
        if(name !== ''){
            dispatch(create_claim_approval1(name,v_exceptions,v_approvers,null));
        }
        else{
            setErrName(IMLocalized('claim_approval_name_is_required'));
        }
    }

    return(
        <Modal className="modal-dialog-centered" size="md" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('create')} {IMLocalized('claim_approval_list2')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="claim_approval_scheme_create_name">{IMLocalized('claim_approval_name')}</label>
                            <Input size="sm" type="text" value={name} id="claim_approval_scheme_create_name" invalid={err_name} onChange={(e)=>{
                                const value = e.target.value;
                                setName(value);
                                setErrName('');
                            }} />
                            <FormFeedback>{err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button className="btn btn-primary btn-sm" onClick={onSubmit}>{IMLocalized('create')}</button>
                    <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
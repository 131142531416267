import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';

export const SUBMITIRAS_REQUEST = 'SUBMITIRAS_REQUEST';
export const SUBMITIRAS_SUCCESS = 'SUBMITIRAS_SUCCESS';
export const SUBMITIRAS_FAILURE = 'SUBMITIRAS_FAILURE';


export const submit_iras = (id)=>{
    return dispatch=>{
        dispatch({
            type:'SUBMITIRAS_REQUEST'
        });

        myaxios.post(`sg/iras/${id}/send/`)
        .then((response)=>{
            dispatch({
                type:'SUBMITIRAS_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'SUBMITIRAS_FAILURE',
                payload:error
            })
            ErrToast.fire({
                type:'error',
                title:IMLocalized('retry')
            });
        })
    }
}
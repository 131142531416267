import * as resetpass from '../action/user_reset_password';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}


const user_reset_password = (state=initialState,action)=>{
    switch(action.type){
        case resetpass.USERRESETPASSWORD_REQUEST:{
            return{
                isLoading:true,
                data:null,
                errors:null,
            }
        }
        case resetpass.USERRESETPASSWORD_SUCCESS:{
            return{
                isLoading:false,
                data:action.payload
            }
        }
        case resetpass.USERRESETPASSWORD_FAILURE:{
            return{
                isLoading:false,
                errors:action.payload?.response?.data
            }
        }
        default:
            return state;
    }
}
export default user_reset_password;
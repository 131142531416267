import React,{useEffect, useState} from 'react';
import { DropdownItem } from 'reactstrap';
import { useDispatch, useSelector} from 'react-redux';
import FileSaver from 'file-saver';
import ExcelJs from 'exceljs';
import moment from 'moment';
import { IMLocalized } from '../../language/IMLocalized';
import { get_employee_permit} from '../../action/employee/get_employee_permit';
import { getColumnName } from '../../func/getColumnName';
import { getValue } from '../../func/getValue';

import { get_employee_list} from '../../action/employee/get_employee_list';

function ForeignWorkerHeadCountReport(){
    const dispatch = useDispatch();
    const list = useSelector(state=>state.get_employee_permit.data);
    const list1 = useSelector(state=>state.get_employee_list.data);
    const isLoading = useSelector(state=>state.get_employee_permit.isLoading);
    const [count,setCount] = useState(0);
    const [wpCount,setWpCount] = useState(0);


    useEffect(()=>{
        if(!isLoading && count !== 0){
            const arr = list.map((item)=>{
                // const role_hr = item.roles.includes('role_hr');
                // const role_claim_officer = item.roles.includes('role_claim_officer');
                // const role_leave_officer = item.roles.includes('role_leave_officer');

                let work_pass=''
                // let wpcount=0
                // let spcount=0
                // let epcount=0

                if(item.permit_type === 'WORKPERMIT'){
                    work_pass = 'WORK PERMIT';
                    setWpCount(wpCount + 1)
                }else if (item.permit_type === 'SPASS'){
                    work_pass = 'S PASS';
                    setWpCount(wpCount + 1)
                }else if (item.permit_type === 'EPASS'){
                    work_pass = 'E PASS';
                    setWpCount(wpCount + 1)
                }else{
                    work_pass = 'Other'
                }

                const empnamerr = list1.filter(x=>x.id === item.employee).map(y=>y.name)
                const empname = empnamerr[0]
                const empiddrr = list1.filter(x=>x.id === item.employee).map(y=>y.employee_number)
                const empidd = empiddrr[0]
                // const work_pass = item.permit_type.includes('WORKPERMIT');
                // const work_pass = item.permit_type.includes('SPASS');
                // const work_pass = item.permit_type.includes('EPASS');

                return {
                    employee_number:empidd,
                    empname,
                    permit_id:item.permit,
                    work_pass,
                    wpCount
                }
            })

            if(arr.length !== 0 ){
                const wb = new ExcelJs.Workbook();
                const ws = wb.addWorksheet('Foreign Worker Headcount Report');
                const today = moment().format('DD/MM/YYYY');
                const time = moment().format('HH:mm:ss');
                const day =moment().format("DD");
                const month =moment().format("MM");

                const title_name = `ForeignWorkerHeadCountReport_${day}-${month}`;
                ws.getCell('A1').value = 'Foreign Worker Headcount Report';
                ws.getCell('A2').value = `Printed On : ${today} ${time}`;

                ws.getCell('A4').value = `Work Permit HC`;
                ws.getCell('B4').value = `S Pass HC`;
                ws.getCell('C4').value = `E Pass HC`;

                // ws.getCell('A5').value = `${wpCount}`;
                // ws.getCell('B5').value = `${wpCount}`;
                // ws.getCell('C5').value = `${wpCount}`;

                const column = [
                    {header:'Emp ID', key:'employee_number'},
                    {header:'Emp Name', key:'empname'},
                    {header:'Work Pass ID', key:'permit_id'},
                    {header:'Work Pass Type',key:'work_pass'}
                ]

                ws.columns=[
                    {width:15},
                    {width:20},
                    {width:20},
                    {width:20}
                ]

                for(let j=0;j<column.length;j++){
                    const headerName = column[j].header;
                    const field = (column[j].key);
                    ws.getCell(getColumnName(j)+'7').value = headerName;
                    let wpc = 0;
                    let spc = 0;
                    let epc = 0;
                    for(let x=0;x<arr.length;x++){
                        const step = 8+x;
                        const item = getValue(arr[x],field);
                        
                        if (arr[x].work_pass.includes("WORK PERMIT")===true)
                        {wpc = wpc + 1}
                        if (arr[x].work_pass.includes("S PASS")===true)
                        {spc = spc + 1}
                        if (arr[x].work_pass.includes("E PASS")===true)
                        {epc = epc + 1}
                        if(item !== null && item !== '' && item !== undefined){
                            
                            ws.getCell(getColumnName(0)+'5').value = wpc;
                            ws.getCell(getColumnName(1)+'5').value = spc;
                            ws.getCell(getColumnName(2)+'5').value = epc;

                            if(item === true){
                                ws.getCell(getColumnName(j)+step).value = 'Yes';
                            }
                            else if (item === false){
                                ws.getCell(getColumnName(j)+step).value = '-';
                            }
                            else{
                                ws.getCell(getColumnName(j)+step).value = item;
                            }
                        }
                        else{
                            ws.getCell(getColumnName(j)+step).value ='-';
                        }
                    }
                }
                
                const file_name = `${title_name}.xlsx`;
                wb.xlsx.writeBuffer().then((buf)=>{
                    var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                    FileSaver.saveAs(file, file_name)
                });
                setCount(0);
            }
        }
    },[list,isLoading,count])

    return(
        <DropdownItem className="cursor-pointer" 
        onClick={isLoading || count !== 0 ? null : ()=>{
            dispatch(get_employee_permit());
            setCount(count+1);
        }}
        >
            {isLoading || count !== 0 ? `${IMLocalized('loading')}..` : IMLocalized('foreign_worker_headcount_report')}
        </DropdownItem>
    )
}
export default ForeignWorkerHeadCountReport;
import * as scheduledropdown from '../action/schedule_dropdown';

const initialState={
    data:[],
    error:{},
    calendar_choices:[],
    calendar_key:[],
    isLoading:false
}


const schedule_dropdown = (state=initialState,action) =>{
    switch(action.type){
        case scheduledropdown.REQUEST_SCHEDULEDROPDOWN:
            return{
                ...state,
                isLoading:true,
                error:{}
            }
        case scheduledropdown.GET_SCHEDULEDROPDOWN:
            return{
                ...state,
                calendar_choices:action.payload.data.calendar_choices,
                calendar_key:action.payload.data.calendar_key,
                error:{},
                isLoading:false
            } 
        case scheduledropdown.FAILURE_SCHEDULEDROPDOWN:
            return{
                ...state,
                error:{},
                isLoading:false
            }
        default:
            return state;
                
    }
}
export default schedule_dropdown;
import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader, Table, Row, Col, ModalFooter, } from 'reactstrap';
import { get_leave_list_detail } from '../../../reducers/get_leave_list';
import { IMLocalized } from '../../../language/IMLocalized';


export default function ModalViewLeaveType({ toggle, closeModal, id}){

    const leavetypelist = useSelector(state=>state.dropdown_list.leave_type);
    const initializationlist = useSelector(state=>state.dropdown_list.leave_setting_year_initialization);
    const list = useSelector(state=>get_leave_list_detail(state,id))

   
    const data = list && initializationlist.find((item)=>item.value === list.year_initialization);
    const initial = data?.display ?? '';

    const data1 = list && leavetypelist.find((item)=>item.value === list.leave_type);
    const type = data1?.display ?? '';
        
    
    return(
        <Modal isOpen={toggle} size="lg">
            {list &&
            <>
            <ModalHeader toggle={closeModal}>{IMLocalized('view')} {list.name}</ModalHeader> 
            <ModalBody>
                <Row>
                    <Col>
                        <Table size="sm" className="table-bordered" >
                            <tbody>
                                <tr>
                                    <td className='w-25'>{IMLocalized('leave_code')}</td>
                                    <td>{list.code}</td>
                                </tr>
                                <tr>
                                    <td className='w-25'>{IMLocalized('leave_name')}</td>
                                    <td>{list.name}</td>
                                </tr>
                                <tr>
                                    <td className='w-25'>{IMLocalized('deductible')}</td>
                                    <td>
                                        <div className='custom-control custom-checkbox'>
                                            <input type="checkbox" id="view_leave_type_deductible" name='deductible' className="custom-control-input" checked={list.deductable} readOnly />
                                            <label className="custom-control-label" htmlFor="view_leave_type_deductible"></label>
                                        </div>
                                    </td>
                                </tr>
                                {list.deductable &&
                                <>
                                <tr>
                                    <td className='w-25'>{IMLocalized('leave_type')}</td>
                                    <td>{type}</td>
                                </tr>
                                <tr>
                                    <td className='w-25'>{IMLocalized('year_initialization')}</td>
                                    <td>{initial}</td>
                                </tr>
                                </>}
                                <tr>
                                    <td className='w-25'>{IMLocalized('minimum_image')}</td>
                                    <td>{list.minimum_images}</td>
                                </tr>
                                <tr>
                                    <td className='w-25'>{IMLocalized('maximum_image')}</td>
                                    <td>{list.maximum_images}</td>
                                </tr>
                                <tr>
                                    <td className='w-25'>{IMLocalized('can_user_apply')}</td>
                                    <td>
                                        <div className='custom-control custom-checkbox'>
                                            <input type="checkbox" id="view_leave_type_can_user_apply" name='can_user_apply' className="custom-control-input" checked={list.can_user_apply} readOnly />
                                            <label className="custom-control-label" htmlFor="view_leave_type_can_user_apply"></label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='w-25'>{IMLocalized('earned_can_negative')}</td>
                                    <td>
                                        <div className='custom-control custom-checkbox'>
                                            <input type="checkbox" id="view_leave_type_earned_can_negative" name='earned_can_negative' className="custom-control-input" checked={list.earned_can_negative} readOnly />
                                            <label className="custom-control-label" htmlFor="view_leave_type_earned_can_negative"></label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='w-25'>{IMLocalized('no_pay')}</td>
                                    <td>
                                        <div className='custom-control custom-checkbox'>
                                            <input type="checkbox" id="view_leave_type_is_npl" name='is_npl' className="custom-control-input" checked={list.is_npl} readOnly />
                                            <label className="custom-control-label" htmlFor="view_leave_type_is_npl"></label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </ModalBody> 
            </>}
            <ModalFooter>
                <button className='btn btn-danger btn-sm' onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}

import React, { useContext, useEffect, useState } from "react";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import XLSX from 'xlsx';
import ExcelJs from 'exceljs';
import FileSaver from 'file-saver';
import moment from 'moment';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "reactstrap";
import { NewMainContext } from "../../context/NewMainContext";
import { IMLocalized } from "../../language/IMLocalized";
import { useDispatch, useSelector } from "react-redux";
import { isFirstColumn } from "../ag-grid_component/isFirstColumn";
import { getValue } from "../../func/getValue";
import { getColumnName } from "../../func/getColumnName";
import { popError } from "../../func/popError";
import OverlayLoading from '../loading_component/overlay_loading';
import { mass_assign_claim_approval1, reset_mass_assign_claim_approval1 } from "../../action/approvals/mass_assign_claim_approval1";
import ModalClaimMassApprovalError from "./modal/ModalClaimMassApprovalError";
import { get_employee_list } from "../../action/employee/get_employee_list";
import { get_all_employee_available_claim } from "../../action/employee/get_all_employee_available_claim";

const gridOptions={
    singleClickEdit:true,
    defaultColDef:{
        sortable:true,
        filter:true,
        suppressMenu:true,
        resizable:true
    },
    sideBar:{
        toolPanels:[
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                
            }
        ],
        defaultToolPanel:''
    },
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
}

export default function ClaimMassApproval(){

    const dispatch = useDispatch();
    const { addItem, action_toggle } = useContext(NewMainContext);
    const approvallist = useSelector(state=>state.get_claim_approval.data);
    const emplist = useSelector(state=>state.get_employee_list.data);
    const isLoading = useSelector(state=>state.mass_assign_claim_approval1.isLoading);
    const isLoading1 = useSelector(state=>state.get_claim_approval.isLoading);
    const errors = useSelector(state=>state.mass_assign_claim_approval1.errors);
    const success  = useSelector(state=>state.mass_assign_claim_approval1.data);

    
    const [ rowData, setRowData] = useState([]);
    const [ err_msg, setErrorMsg] = useState([]);
    const [ err_toggle, setErrorToggle] = useState(false);
    const [ step, setStep] = useState(0);

    useEffect(()=>{
        if(success != null){
            setRowData([]);
            setStep(prevState=>prevState +1);
            dispatch(get_employee_list());
            dispatch(get_all_employee_available_claim());
            dispatch(reset_mass_assign_claim_approval1());
        }
    },[success,dispatch])

    useEffect(()=>{
        if(errors != null){
            if(errors.hasOwnProperty('non_field_errors')){
                const word = errors['non_field_errors'].toString();
                popError(word);
            }
            else if( errors.hasOwnProperty('approval_group')){
                const word =  `${IMLocalized('approval_group')} :` + errors['approval_group'].toString();
                popError(word);
            }
            dispatch(reset_mass_assign_claim_approval1());
        }
    },[errors,dispatch])

    useEffect(()=>{
        const arr = emplist.filter(item=>item.active === true).map((item)=>{
            return {...item,start_date:'d/M/yy',group:null,err_group:false,err_start_date:false}
        })
        setRowData(arr);
    },[emplist,step])

    const onCellFocused=(params)=>{
        if(params?.column?.colDef != null ){
            const {field} = params.column.colDef;
            params.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = field === 'group' || field === 'start_date' ? true: false;
        }
    }

    const onCellValueChanged=(params)=>{
        const { field } = params.colDef; 
        if( field === 'start_date' || field === 'group'){
            const focusedCell = gridOptions.api.getFocusedCell();
            gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                if(index === focusedCell.rowIndex){
                    rowNode.setSelected(true);
                }
            })

            if(field === 'start_date') params.data.err_start_date = false;
            else if (field === 'group') params.data.err_group = false;
            
        }
        params.api.refreshCells();
    }

    const uploadExcel=(e)=>{
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload=(e)=>{
            const bstr = e.target.result;
            const wb = XLSX.read(bstr,{ type : 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const wsname3 = wb.SheetNames[2];
            const ws3 = wb.Sheets[wsname3];

            const columns = {
                'A':'employee_number',
                'B':'name',
                'C':'start_date',
                'D':'group',
            }

            const rowData1 = [];
            let rowIndex = 2;
            const idd = ws3 !== undefined && ws3['A1'] !== undefined ? ws3['A1'].w : '';
            
            if(idd === 'ClaimApprovalGroup'){
                while(ws['A'+rowIndex]){
                    let row = {};
                    for(let i =0;i<Object.keys(columns).length;i++){
                        const column = Object.keys(columns)[i];
                        const index = column + rowIndex;
                        row[columns[column]] = ws[index] !== undefined ? ws[index].w : null;
                    }
                    rowData1.push(row);
                    rowIndex++;
                }
                gridOptions.api.deselectAll();
                getImportData(rowData1);
            }
            else{
                popError(IMLocalized('wrong_file_selected'));
            }
        }
        reader.readAsBinaryString(file);
    }

    const getImportData = (data) =>{
        for(let i=0; i < data.length; i++){
            const { employee_number, group, start_date} = data[i];
            gridOptions.api.forEachNode((rowNode)=>{
                if(employee_number === rowNode.data.employee_number){
                    rowNode.data.group = group;
                    rowNode.data.start_date = start_date;
                    rowNode.data.err_group = false;
                    rowNode.data.err_start_date = false;
                    rowNode.setSelected(true);
                    gridOptions.api.refreshCells(rowNode)
                }
            })
        }
    }

    const generateExcel = () =>{
        const items = gridOptions.api.getSelectedRows();
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('Claim Approval Group');
        const ws2 = wb.addWorksheet('setting');
        const ws3 = wb.addWorksheet('id');
        ws3.getCell('A1').value = "ClaimApprovalGroup";

        const columns = [
            { header:IMLocalized('agrid_emp_id'), key:'employee_number'},
            { header:IMLocalized('agrid_emp_name'), key:'name'},
            { header:IMLocalized('agrid_start_date'), key:'start_date'},
            { header:IMLocalized('agrid_approval_group'), key:'group'}
        ];

        ws.columns = columns;

        for(let i=2; i <= 2001; i++){
            ws.getCell('C'+i).numFmt = 'd/M/yy';
        }

        for(let i=0; i<approvallist.length ; i++){
            const x= i+1;
            ws2.getCell('A'+x).value = approvallist[i].name;
        }

        if(items.length !==  0){
            for(let i = 0; i < columns.length ; i++){
                const field = columns[i].key;
                for( let x=0; x< items.length; x++){
                    const step = 2 + x;
                    const data = getValue(items[x],field);
                    ws.getCell(getColumnName(i)+step).value = data;
                    if(field === 'start_date'){
                        ws.getCell(getColumnName(i)+step).numFmt = 'd/M/yy';
                        ws.getCell(getColumnName(i)+step).value = null;
                    }
                    else if( field === 'group'){
                        if(approvallist.length !== 0){
                            ws.getCell(getColumnName(i)+step).dataValidation={
                                type:'list',
                                allowBlank:true,
                                formulae:['=setting!$A$1:$A$'+approvallist.length],
                                error:IMLocalized('excel_valid_in_list'),
                                errorStyle:'error',
                                errorTitle:'Error',
                                showErrorMessage:true,
                            };
                        }
                        ws.getCell(getColumnName(i)+step).value = null;
                    }
                    else{
                        ws.getCell(getColumnName(i)+step).value = data;
                    }
                }
            }

            ws.columns.forEach(column=>{
                column.width = column.header.length < 15 ? 15 : column.header.length
            });

            wb.xlsx.writeBuffer().then((buf)=>{
                const file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                FileSaver.saveAs(file, 'Claim_Approval_Template.xlsx')
            });
        }
        else{
            popError(IMLocalized('no_employee_selected'));
        }
    }

    const onAssign = () =>{
        setErrorMsg([]);
        const data = gridOptions.api.getSelectedRows();
        const arr = [];
        const arr2 = [];
        if(data.length !== 0){
            for(let i=0; i< data.length; i++){
                const {group, name, employee_number, id, start_date} = data[i];
                const detail =  approvallist.find(item=> item.name === group);
                const approval_master = detail?.approval_master ?? null;
                const approval_group = detail?.id ?? null;
                let date = null;
                if(start_date != null && start_date !== '' && start_date !== 'd/M/yy'){
                    date = moment(start_date,'dd/M/YY').format('YYYY-MM-DD');
                    if(date === ' Invalid date'){
                        arr2.push({ employee:id ,start_date:date , approval_group , name, number:employee_number, approval_master });
                    }
                }
                else{
                    arr2.push({ employee:id ,start_date: date , approval_group , name, number:employee_number, approval_master });
                }
                arr.push({ employee:id ,start_date: date , approval_group , name, number:employee_number, approval_master });
            }

            if(arr2.length !== 0 ){
                handleError(arr2);
            }
            else{
                dispatch(mass_assign_claim_approval1(arr));
            }
        }
        else{
            popError(IMLocalized('no_employee_selected'));
        }
    }

    const handleError =(data)=>{
        const arr = [];
        for(const item of data){
            const { start_date, approval_group, name, number, employee} = item;
            const arr1 = [];
            if(start_date === 'Invalid date'){
                
                arr1.push({ msg :`${IMLocalized('start_date')} : ${IMLocalized('invalid_date')}` });
                
                gridOptions.api.forEachNode((rowNode)=>{
                    if(rowNode.data.id === employee){
                        rowNode.data.err_start_date = true;
                        gridOptions.api.refreshCells(rowNode)
                    }
                })
            }
            else if( start_date === null){
                
                arr1.push({ msg :`${IMLocalized('start_date_is_required')}`});

                gridOptions.api.forEachNode((rowNode)=>{
                    if(rowNode.data.id === employee){
                        rowNode.data.err_start_date = true;
                        gridOptions.api.refreshCells(rowNode)
                    }
                })
            }

            if(approval_group === null){
               
                arr1.push({ msg: `${IMLocalized('approval_group_is_required')}`})

                gridOptions.api.forEachNode((rowNode)=>{
                    if(rowNode.data.id === employee){
                        rowNode.data.err_group = true;
                        gridOptions.api.refreshCells(rowNode)
                    }
                })
            }

            if(arr1.length !== 0){
                arr.push({ number, name, arr1});
            }
        }
        setErrorMsg(arr);
        setErrorToggle(true);
    }


    const approval_detail = [''];
    for(const item of approvallist){
        approval_detail.push(item.name);
    }

    return(
        <>
        {isLoading1 ? <OverlayLoading/> :
        <>
        <Container fluid>
            <Row className="bg-white border-bottom">
                <Col className="p-2">
                    <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                        <BreadcrumbItem><a href="/#" onClick={()=>addItem('1','dashboard')}>{IMLocalized('home')}</a></BreadcrumbItem>
                        <BreadcrumbItem active>{IMLocalized('mass_assign_claim_approval_group')}</BreadcrumbItem>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <div className="d-flex">
                        <div>
                            <button className="btn btn-primary btn-sm" onClick={generateExcel}>{IMLocalized('get_template')}</button>
                        </div>
                        <div className="ml-2">
                            <label className="btn btn-primary btn-sm" htmlFor="massclaimscheme_file1">{IMLocalized('import_from_file')}</label>
                            <input type="file" id="massclaimscheme_file1" className="custom-file-input form-control-sm d-none" accept=".xlsx" onChange={(e)=>uploadExcel(e)}
                            onClick={(e)=>{
                                const { target = {} } = e || {};
                                target.value = '';
                            }}  />
                        </div>
                        <div>
                            <button className="btn btn-primary btn-sm" onClick={()=> { setRowData([]); setStep(prevState=>prevState +1); setErrorMsg([])}}>{IMLocalized('clear')}</button>
                            {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button> :
                            <button className="btn btn-primary btn-sm" onClick={onAssign}>{IMLocalized('assign')}</button>}
                            {err_msg.length !== 0 && <button className="btn btn-danger btn-sm" onClick={()=>setErrorToggle(true)}>{IMLocalized('error')}</button>}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <div className="ag-theme-balham" style={{height:action_toggle ? 'calc(100vh - 260px)' : 'calc(100vh - 224px)', width:'100%' }}>
                        <AgGridReact
                            rowData={rowData}
                            gridOptions={gridOptions}
                            overlayNoRowsTemplate={IMLocalized('no_data')}
                            suppressDragLeaveHidesColumns={true}
                            stopEditingWhenCellsLoseFocus={true}
                            onCellFocused={onCellFocused}
                            onCellValueChanged={onCellValueChanged}
                            columnDefs={[
                                {
                                    headerName:IMLocalized('agrid_select'),
                                    field:'',
                                    editable:false,
                                    width:120,
                                    lockPosition:true,
                                    pinned:'left',
                                    filter:false,
                                    headerCheckboxSelection: isFirstColumn,
                                    checkboxSelection: isFirstColumn,
                                    headerCheckboxSelectionFilteredOnly:true,
                                },
                                {
                                    headerName:IMLocalized('agrid_emp_id'),
                                    field:'employee_number',
                                    lockPosition:true,
                                    pinned:'left',
                                    width:100,
                                    editable:false,
                                   
                                    filterParams:{
                                        buttons:['clear']
                                    },
                                },
                                {
                                    headerName:IMLocalized('agrid_emp_name'),
                                    field:'name',
                                    filterParams:{
                                        buttons:['clear']
                                    },
                                    
                                    lockPosition:true,
                                    pinned:'left',
                                    width:150,
                                    editable:false,
                                },
                                {
                                    headerName:IMLocalized('agrid_company'),
                                    field:'metadata.company',
                                    filterParams:{
                                        buttons:['clear']
                                    },
                                   
                                    lockPosition:true,
                                    pinned:'left',
                                    width:120,
                                    editable:false,
                                },
                                {
                                    headerName:IMLocalized('agrid_department'),
                                    field:'contract_cache.department',
                                    filterParams:{
                                        buttons:['clear']
                                    },
                                   
                                    lockPosition:true,
                                    pinned:'left',
                                    width:120,
                                    editable:false,
                                },
                                {
                                    headerName:IMLocalized('agrid_section'),
                                    field:'contract_cache.section',
                                    filterParams:{
                                        buttons:['clear']
                                    },
                                   
                                    lockPosition:true,
                                    pinned:'left',
                                    width:120,
                                    editable:false,
                                },
                                {
                                    headerName:IMLocalized('agrid_job_title'),
                                    field:'contract_cache.job_title',
                                    filterParams:{
                                        buttons:['clear']
                                    },
                                   
                                    lockPosition:true,
                                    pinned:'left',
                                    width:120,
                                    editable:false,
                                },
                                {
                                    headerName:IMLocalized('agrid_job_level'),
                                    field:'contract_cache.job_level',
                                    filterParams:{
                                        buttons:['clear']
                                    },
                                   
                                    lockPosition:true,
                                    pinned:'left',
                                    width:120,
                                    editable:false,
                                },
                                {
                                    headerName:IMLocalized('agrid_cost_center'),
                                    field:'contract_cache.cost_center',
                                    filterParams:{
                                        buttons:['clear']
                                    },
                                    
                                    lockPosition:true,
                                    pinned:'left',
                                    width:120,
                                    editable:false,
                                },
                                {
                                    headerName:IMLocalized('agrid_start_date'),
                                    field:'start_date',
                                    editable:true,
                                    width:120,
                                    filter:false,
                                    cellClassRules:{
                                        'orange-bg':(params)=>{return params.data.err_start_date}
                                    },
                                },
                                {
                                    headerName:IMLocalized('agrid_approval_group'),
                                    field:'group',
                                    width:180,
                                    editable:true,
                                    cellEditor:'agSelectCellEditor',
                                    cellEditorParams:{
                                        values:approval_detail
                                    },
                                    filter:false,
                                    cellClassRules:{
                                        'orange-bg':(params)=>{return params.data.err_group}
                                    },
                                }
                            ]}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
        <ModalClaimMassApprovalError toggle={err_toggle } closeModal={()=>setErrorToggle(false)} detail={err_msg} />
        </>}
        </>
    )
}
import * as createnewreset from '../../action/leave/create_new_leave_reset';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const create_new_leave_reset = (state=initialState,action)=>{
    switch(action.type){
        case createnewreset.CREATENEWRESET_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case createnewreset.CREATENEWRESET_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case createnewreset.CREATENEWRESET_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case createnewreset.CREATENEWRESET_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default create_new_leave_reset;
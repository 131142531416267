import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CREATEGENERALAGGRIDTEM_REQUEST = 'CREATEGENERALAGGRIDTEM_REQUEST';
export const CREATEGENERALAGGRIDTEM_SUCCESS = 'CREATEGENERALAGGRIDTEM_SUCCESS';
export const CREATEGENERALAGGRIDTEM_FAILURE = 'CREATEGENERALAGGRIDTEM_FAILURE';

export const create_general_aggrid_template = (name,type,model,columnDefs)=>{
    return dispatch=>{
        dispatch({
            type:'CREATEGENERALAGGRIDTEM_REQUEST'
        });

        myaxios.post('general/ag_grid_profile/',{
            name:name,
            type:type,
            model:model,
            columnDefs:columnDefs
        })
        .then((response)=>{
            dispatch({
                type:'CREATEGENERALAGGRIDTEM_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CREATEGENERALAGGRIDTEM_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}
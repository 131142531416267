import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CONTRACTARRAYSAVE_REQUEST = 'CONTRACTARRAYSAVE_REQUEST';
export const CONTRACTARRAYSAVE_SUCCESS = 'CONTRACTARRAYSAVE_SUCCESS';
export const CONTRACTARRAYSAVE_FAILURE = 'CONTRACTARRAYSAVE_FAILURE';

export const contract_contract_array_save = (data)=>{
    return dispatch=>{
        dispatch({
            type:'CONTRACTARRAYSAVE_REQUEST'
        });

        myaxios.post('contract/contracts/array_save/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'CONTRACTARRAYSAVE_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CONTRACTARRAYSAVE_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}
import * as rejectclaim from '../../action/claim/reject_apply_claim';

const initialState={
    data:null,
    isLoading:false,
    errors:null
}

const reject_apply_claim = (state=initialState,action) =>{
    switch(action.type){
        case rejectclaim.REJECTAPPLYCLAIM_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case rejectclaim.REJECTAPPLYCLAIM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case rejectclaim.REJECTAPPLYCLAIM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case rejectclaim.REJECTAPPLYCLAIM_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default reject_apply_claim;
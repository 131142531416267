import * as payslipempcontract from '../../action/payroll/get_payslip_employee_contract';

const initialState={
    data:[],
    errors:[],
    isLoading:false,
}


const get_payslip_employee_contract = (state=initialState,action)=>{
    switch(action.type){
        case payslipempcontract.GETPAYSLIPEMPCONTRACT_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:[],
                errors:[]
            }
        case payslipempcontract.GETPAYSLIPEMPCONTRACT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case payslipempcontract.GETPAYSLIPEMPCONTRACT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_payslip_employee_contract;
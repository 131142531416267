import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Row,Col,Container,Table,Breadcrumb,BreadcrumbItem} from 'reactstrap';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import {get_pay_summary_detail} from '../../action/claim/get_pay_summary_detail';
import {IMLocalized} from '../../language/IMLocalized';
import { getDateFormat } from "../../func/getDateFormat";
import { NewMainContext } from "../../context/NewMainContext";

export default function ClaimPaySummaryDetail(){

    const { claim_all_list_pay_id, addItem, setClaimAllListStep} = useContext(NewMainContext);
    const dispatch = useDispatch();
    const data = useSelector(state=>state.get_pay_summary_detail.data);
    const isLoading = useSelector(state=>state.get_pay_summary_detail.isLoading);

    const [list,setList] = useState([]);
    const summaryRef = useRef();

    useEffect(()=>{
        dispatch(get_pay_summary_detail(claim_all_list_pay_id));
    },[claim_all_list_pay_id,dispatch])

    useEffect(()=>{
        const arr = [];
        if(data != null){
            const {claims,pay_date,send_to_payroll} = data;
            for(const item of claims){
                arr.push({...item,pay_date,send_to_payroll});
            }
        }
        setList(arr);
    },[data])

    const date1 = moment().format('DD/MM/YYYY HH:mm:ss');

    return(
        <>
        <Container fluid>
            <Row className="bg-white border-bottom">
                <Col className="p-2">
                    <Breadcrumb  listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                        <BreadcrumbItem><a href="/#" onClick={()=>addItem('1','dashboard')}>{IMLocalized('home')}</a></BreadcrumbItem>
                        <BreadcrumbItem><a href="/#" onClick={()=>{ setClaimAllListStep('0'); localStorage.setItem('claim_alllist_step','0');}}>{IMLocalized('claim_table')}</a></BreadcrumbItem>
                        <BreadcrumbItem active>{IMLocalized('pay_summary_detail')}</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="d-flex ml-2">
                        <button onClick={()=>{ setClaimAllListStep('0'); localStorage.setItem('claim_alllist_step','0');}} className="btn btn-primary btn-sm"> <i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                        {list.length !== 0 &&   
                        <ReactToPrint 
                        trigger={()=><button className="btn btn-primary btn-sm">{IMLocalized('print_summary')}</button>}
                        content={()=>summaryRef.current}
                        />}
                    </div>
                </Col>
            </Row>
            <Row className="mt-2 justify-content-center">
                <Col lg="10">
                    {isLoading && <span className="px-2">{IMLocalized('loading')}..</span>}
                    {!isLoading && list.length !== 0 &&
                    <div className="pt-2 px-2" ref={summaryRef}>
                        <h3 className="ml-2">{IMLocalized('pay_summary')} - {IMLocalized('printed_on')} : {date1}</h3>
                        <Table size="sm" className="bg-white table-bordered align-items-center">
                            <thead className="thead-light">
                                <tr>
                                    <th>{IMLocalized('employee_id')}</th>
                                    <th>{IMLocalized('employee_name')}</th>
                                    <th>{IMLocalized('claim_type')}</th>
                                    <th>{IMLocalized('paid_date')}</th>
                                    <th>{IMLocalized('paid_type')}</th>
                                    <th>{IMLocalized('claim_amount1')}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map((item,index)=>{
                                    return(
                                        <tr key={index}>
                                            <td>{item.employee.employee_number}</td>
                                            <td>{item.employee.name}</td>
                                            <td>{item.claim_type.name}</td>
                                            <td>{getDateFormat(item.pay_date)}</td>
                                            <td>{item.send_to_payroll === true ? IMLocalized('pay_payroll') : IMLocalized('pay_petty') }</td>
                                            <td>$ {item.claim_amount}</td>
                                            <td></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>}
                </Col>
            </Row>
        </Container>
        </>
    )
}

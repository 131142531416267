import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';

export const CONTRACTARRAYVALIDATE_REQUEST = 'CONTRACTARRAYVALIDATE_REQUEST';
export const CONTRACTARRAYVALIDATE_SUCCESS = 'CONTRACTARRAYVALIDATE_SUCCESS';
export const CONTRACTARRAYVALIDATE_FAILURE = 'CONTRACTARRAYVALIDATE_FAILURE';


export const contract_contract_array_validate =(data)=>{
    return dispatch=>{
        dispatch({
            type:'CONTRACTARRAYVALIDATE_REQUEST'
        });

        myaxios.post('contract/contracts/array_validate/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'CONTRACTARRAYVALIDATE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'CONTRACTARRAYVALIDATE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}
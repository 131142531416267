import React,{Component} from 'react';
//import Stepper from 'react-stepper-horizontal';
import {Container,Row,Col} from 'reactstrap';
import EmpDetail from './wizard_employee_detail';
import EmpContract from './wizard_employee_contract';
import EmpBank from './wizard_employee_bank';
import EmpRelationship from './wizard_employee_relationship';
import EmpChild from './WizardEmployeeChild';
import LeaveScheme from './wizard_employee_leave_scheme';
import LeaveApproval from './wizard_employee_leave_approval';
import EmpPassport from './wizard_employee_passport';
import ClaimScheme from './wizard_employee_claim_scheme';
import ClaimApproval from './wizard_employee_claim_approval';
import Permit from './wizard_employee_permit';
import {IMLocalized, init} from '../../language/IMLocalized';
import i18n from 'i18n-js';
import {connect} from 'react-redux';
import {get_general_logo} from '../../action/get_general_logo';
import a_line from '../../images/a_line.png';
import { Stepper } from 'react-form-stepper';
import Swal from 'sweetalert2';
import IdleTimer from 'react-idle-timer';
import {Buffer} from 'buffer';
i18n.fallbacks = true;
class wizard extends Component{

    constructor(){
        super();
        this.state={
            currentStep:0,
            list:[],
            language:localStorage.getItem('language'),
            timeout:1000 * parseInt(process.env.REACT_APP_SESSION_TIMEOUT) * 60,
            isTimedOut:false,
        }
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
    }

    componentDidMount(){
        const title = process.env.REACT_APP_TITLE;
        document.title = title;

        this.props.get_general_logo();

        window.addEventListener('beforeunload', this.handleUnload);
        window.onunload = function () {
            localStorage.clear();
        }
    }

    componentWillUnmount(){
        window.removeEventListener('beforeunload',this.handleUnload);
    }
    
    componentDidUpdate(prevProps,prevState){
        if(prevProps.logo !== this.props.logo){
            const picture = this.props.logo.data;
            const byte = picture.byteLength;
            if(byte === 0){
                this.setState({logo:null});
                document.getElementById("favicon").href = a_line;
            }
            else{
                const img = new Buffer.from(picture,'binary').toString('base64');
                const src = 'data:image/png;base64,'+img;
                this.setState({logo:src});
                const url =  process.env.REACT_APP_API_URL
                document.getElementById("favicon").href = `https://${url}/api/general/logo/`;
            }
        }
        if(prevProps.auth1 !== this.props.auth1){
            const {auth1} = this.props;
            if(auth1 !== ''){
                Promise.resolve(localStorage.clear())
                .then(()=>{
                    this.onIdle()
                });
            } 
        }
    }

    handleUnload=(e)=>{
        var message = "";
        (e || window.event).returnValue = message;
        return message;
    }

    _onAction(e) {
        
        this.setState({isTimedOut: false})
    }
     
    _onActive(e) {
    
        this.setState({isTimedOut: false})
    }
     
    _onIdle(e) {
        
        Promise.resolve(window.removeEventListener('beforeunload',this.handleUnload))
        .then(this.reloadWeb());
    }

    reloadWeb=()=>{
        Swal.fire({
            title:IMLocalized('session_expired'),
            type:'warning',
            confirmButtonColor:'#d33',
            confirmButtonText:IMLocalized('ok!'),
            allowEnterKey:true,
            allowOutsideClick:false
        })
        .then((result)=>{
            if(result.value){
                Promise.resolve(window.removeEventListener('beforeunload',this.handleUnload))
                .then(localStorage.clear())
                .then(window.location.href="/#/")
            }
        })
    }

    getList=(list)=>{
        this.setState({list});
        this.next();
    }

    next=()=>{
        const {currentStep} = this.state;
        const final = currentStep + 1;
        this.setState({currentStep:final});
    }

    showStep=()=>{
        const {currentStep} = this.state;
        if(currentStep === 0){
            return <EmpDetail goBack={this.goBack} onNext={this.getList}/>
        }
        else if(currentStep === 1){
            return <EmpContract goBack={this.goBack} onNext={this.next} list={this.state.list} />
        }
        else if (currentStep === 2){
            return <LeaveScheme goBack={this.goBack} onNext={this.next} list={this.state.list}   />
        }
        else if (currentStep === 3){
            return <LeaveApproval goBack={this.goBack} onNext={this.next} list={this.state.list}   />
        }
        else if (currentStep === 4){
            return <ClaimScheme goBack={this.goBack} onNext={this.next} list={this.state.list}   />
        }
        else if (currentStep === 5){
            return <ClaimApproval goBack={this.goBack} onNext={this.next} list={this.state.list}   />
        }
        else if (currentStep === 6){
            return <Permit goBack={this.goBack} onNext={this.next} list={this.state.list}   />
        }
        else if (currentStep === 7){
            return <EmpPassport goBack={this.goBack} onNext={this.next} list={this.state.list}   />
        }
        else if(currentStep === 8){
            return  <EmpBank goBack={this.goBack} onNext={this.next} list={this.state.list} />
        }
        else if(currentStep === 9){
            return <EmpRelationship goBack={this.goBack} onNext={this.next} list={this.state.list} language={this.state.language} />
        }
        else if(currentStep === 10){
            return <EmpChild goBack={this.goBack} onNext={this.next} list={this.state.list} language={this.state.language} />
        }
    }

    goBack=()=>{
        window.location.href="/#/";
    }

    render(){
        init(this.state.language);
        i18n.fallbacks = true
        const {currentStep} = this.state;
        const steps=[
            {
                label:IMLocalized('employee_detail'),
            },
            {
                label:IMLocalized('contract'),
            },
            {
                label:IMLocalized('leave_scheme')
            },
            {
                label:IMLocalized('leave_approval')
            },
            {
                label:IMLocalized('claim_scheme')
            },
            {
                label:IMLocalized('claim_approval'),
            },
            {
                label:IMLocalized('workpermit'),
            },
            {
                label:IMLocalized('passport')
            },
            {
                label:IMLocalized('bank')
            },
            {
                label:IMLocalized('emergency_contact'),
            },
            {
                label:IMLocalized('CHILD'),
            }
        ]
        return(
            <div>
                <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                // onActive={this._onActive}
                onIdle={this.onIdle}
                // onAction={this._onAction}
                debounce={250}
                timeout={this.state.timeout} />
                <Container fluid >
                    <Row className="bg-white border-bottom">
                        <Col>
                            <div className="justify-content-center">
                                <Stepper
                                connectorStateColors='true'
                                connectorStyleConfig={{activeColor:'#FF0000'}}
                                steps={steps}
                                activeStep={currentStep}/>
                
                            </div>
                        </Col>
                    </Row>
                    {this.showStep.call(this)}
                        
                </Container>
                
            </div>
        )
    }
}
function mapStateToProps(state,ownProps){
    return{
        logo:state.get_general_logo.data,
        auth1:state.auth1.fail,
    }
}

const mapDispatchToProps =(dispatch)=>{
    return{
        get_general_logo:()=>{
            dispatch(get_general_logo())
        }
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(wizard);
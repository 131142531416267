import * as childarraysave from '../../action/wizard_employee/employee_child_array_save';

const initialState={
    data:null,
    isLoading:false,
    errors:null
}

const employee_child_array_save = (state=initialState,action)=>{
    switch(action.type){
        case childarraysave.EMPCHILDARRAYSAVE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case childarraysave.EMPCHILDARRAYSAVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case childarraysave.EMPCHILDARRAYSAVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case childarraysave.EMPCHILDARRAYSAVE_RESET:
            return{
                ...state,
                data:null,
                errors:null,
            }
        default:
            return state;
    }
}
export default employee_child_array_save;


import myaxios from '../../axios';

export const GETGENERALDASHBOARD_REQUEST = 'GETGENERALDASHBOARD_REQUEST';
export const GETGENERALDASHBOARD_SUCCESS = 'GETGENERALDASHBOARD_SUCCESS';
export const GETGENERALDASHBOARD_FAILURE = 'GETGENERALDASHBOARD_FAILURE';

export const get_general_dashboard = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETGENERALDASHBOARD_REQUEST'
        });

        myaxios.get('general/dashboard/')
        .then((response)=>{
            dispatch({
                type:'GETGENERALDASHBOARD_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETGENERALDASHBOARD_FAILURE',
                payload:error
            })
        })
    }
}
import * as leave_picture from '../../action/approvals/calendar_approvals_get_leave_picture';

const initialState={
    data:[],
    errors:[],
    isLoading:false
}

const calendar_approvals_get_leave_picture = (state=initialState,action)=>{
    switch(action.type){
        case leave_picture.CALENDARAPPROVALSGETLEAVEPICTURE_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case leave_picture.CALENDARAPPROVALSGETLEAVEPICTURE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case leave_picture.CALENDARAPPROVALSGETLEAVEPICTURE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default calendar_approvals_get_leave_picture;
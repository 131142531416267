import * as picture from '../../action/user/get_user_picture';

const initialState={
    data:[],
    isLoading:true,
    errors:[]
}

const get_user_picture = (state=initialState,action)=>{
    switch(action.type){
        case picture.USERPICTURE_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case picture.USERPICTURE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case picture.USERPICTURE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_user_picture;
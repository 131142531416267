import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import { get_leave_option } from "../../action/get_leave_option";
import { reset_update_leave_option, update_leave_option } from "../../action/update_leave_option";
import { get_leave_pending_color } from "../../action/user/get_leave_pending_color";
import { NewMainContext } from "../../context/NewMainContext";
import { popError } from "../../func/popError";
import { IMLocalized } from "../../language/IMLocalized";
import BreadCrumbList from "../breadcrumb/BreadCrumbList";
import OverlayLoading from '../loading_component/overlay_loading';


const LeaveBaseSetting = () =>{

    const { addItem } = useContext(NewMainContext);
    const dispatch = useDispatch();
    const calendar_view_dropdown = useSelector(state=>state.dropdown_list.calendar_view_list);
    const leavelist = useSelector(state=>state.get_leave_list.data);
    const proration_dropdown = useSelector(state=>state.dropdown_list.proration_option);
    const list = useSelector(state=>state.get_leave_option.data);
    const isLoading = useSelector(state=>state.get_leave_option.isLoading);
    const success = useSelector(state=>state.update_leave_option.data);
    const errors = useSelector(state=>state.update_leave_option.errors);

    const [ calendar_view_list, setCalendarViewList] = useState('');
    const [ err_calendar_view_list, setErrCalendarViewList] = useState('');
    const [ maximum_months_application, setMaximumMonthApplication] = useState('');
    const [ err_maximum_months_application, setErrMaximumMonthApplication ] = useState('');
    const [ annual_before_marked_as_urgent, setAnnualBeforeMarkedAsUrgent] = useState('');
    const [ err_annual_before_marked_as_urgent, setErrAnnualBeforeMarkedAsUrgent] = useState('');
    const [ annual_leave, setAnnualLeave ] = useState('');
    const [ err_annual_leave, setErrAnnualLeave] = useState('');
    const [ medical_leave, setMedicalLeave ] = useState('');
    const [ err_medical_leave, setErrMedicalLeave] = useState('');
    const [ absent_leave, setAbsentLeave ] = useState('');
    const [ err_absent_leave, setErrAbsentLeave] = useState('');
    const [ nsmup_leave, setNsmupLeave ] = useState('');
    const [ err_nsmup_leave, setErrNsmupLeave] = useState('');
    const [ no_pay_leave, setNoPayLeave] = useState('');
    const [ err_no_pay_leave, setErrNoPayLeave] = useState('');
    const [ probation_leave, setProbaitonLeave] = useState(false);
    const [ allow_earned_leave_negative, setAllowEarnedLeaveNegative] = useState(false);
    const [ leave_without_approval_pending, setLeaveWithoutApprovalPending] = useState(false);
    const [ proration_option, setProrationOption] = useState('');
    const [ err_proration_option, setErrProrationOption] = useState('');
    const [ cutoff_day, setCutoffDay] = useState('');
    const [ err_cutoff_day, setErrCutoffDay] = useState('');
    const [ after_cutoff_leave, setAfterCufoffLeave] = useState('');
    const [ err_after_cutoff_leave, setErrAfterCutoffLeave] = useState('');


    useEffect(()=>{
        if(list != null){
            const { proration_option, 
                cutoff_day, 
                after_cutoff_leave, 
                probation_leave, 
                calendar_view_list, 
                allow_earned_leave_negative,  
                maximum_months_application, 
                annual_before_marked_as_urgent,
                no_pay_leave,
                annual_leave,
                leave_without_approval_pending,
                medical_leave,
                absent_leave,
                nsmup_leave
            } = list;

            setProrationOption(proration_option);
            setCutoffDay(cutoff_day);
            setAfterCufoffLeave(after_cutoff_leave);
            setProbaitonLeave(probation_leave);
            setCalendarViewList(calendar_view_list);
            setAllowEarnedLeaveNegative(allow_earned_leave_negative);
            setMaximumMonthApplication(maximum_months_application);
            setAnnualBeforeMarkedAsUrgent(annual_before_marked_as_urgent);
            setNoPayLeave(no_pay_leave);
            setAnnualLeave(annual_leave);
            setLeaveWithoutApprovalPending(leave_without_approval_pending);
            setMedicalLeave(medical_leave);
            setAbsentLeave(absent_leave);
            setNsmupLeave(nsmup_leave);
        }
    },[list])

    useEffect(()=>{
        if(errors != null){
            if(errors.hasOwnProperty('maximum_months_application')){
                const word = errors['maximum_months_application'].toString();
                setErrMaximumMonthApplication(word);
            }
            if(errors.hasOwnProperty('non_field_errors')){
                const word = errors['non_field_errors'].toString();
                popError(word);
            }

            dispatch(reset_update_leave_option());
        }
    },[errors,dispatch])

    useEffect(()=>{
        if(success != null){
            dispatch(get_leave_option());
            dispatch(get_leave_pending_color());
            dispatch(reset_update_leave_option());
        }
    },[dispatch,success])

    const onUpdate = () =>{
        let nsmup = null;
        let absent = null;
        let nopay = null;
        let medical = null;
        let annual = null;
        if(annual_leave !== ''){
            annual = annual_leave;
        }
        if(medical_leave !== ''){
            medical = medical_leave;
        }
        if(nsmup_leave !== ''){
            nsmup = nsmup_leave;
        }
        if(absent_leave !== ''){
            absent = absent_leave;
        }
        if(no_pay_leave !== ''){
            nopay = no_pay_leave;
        }

        if(proration_option !== '' && cutoff_day !== '' && after_cutoff_leave !== '' &&  calendar_view_list !=='' && maximum_months_application !== '' && annual !== null &&annual_before_marked_as_urgent !== '' && medical !== null && nopay !== null){
            dispatch(update_leave_option(proration_option,cutoff_day,after_cutoff_leave,probation_leave,calendar_view_list,allow_earned_leave_negative,maximum_months_application,annual_before_marked_as_urgent,annual,leave_without_approval_pending,medical,absent,nsmup,nopay))
        }
        else{
            if(annual_before_marked_as_urgent === ''){
                setErrAnnualBeforeMarkedAsUrgent(IMLocalized('period_is_required'));
            }
            if( annual === null){
                setErrAnnualLeave(IMLocalized('annual_leave_is_required'));
            }
            if(proration_option===''){
                setErrProrationOption(IMLocalized('proration_option_is_required'));
            }
            if(cutoff_day ===''){
                setErrCutoffDay(IMLocalized('cutoff_day_is_required'));
            }
            if(after_cutoff_leave ===''){
                setErrAfterCutoffLeave(IMLocalized('after_cutoff_leave_is_required'));
            }
            if(calendar_view_list ===''){
                setErrCalendarViewList(IMLocalized('calendar_view_list_is_required'));
            }
            if(maximum_months_application ===''){
                setErrMaximumMonthApplication(IMLocalized('max_month_application_is_required'));
            }
            if(no_pay_leave === null){
                setErrNoPayLeave(IMLocalized('no_pay_leave_is_required'));
            }
        }
    }

    return(
        <>
        {isLoading ? <OverlayLoading/> :
        <>
        <Container fluid>
            <Row className="border-bottom bg-white">
                <Col className="p-2">
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title:IMLocalized('leave_option')}
                    ]}
                    />
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className="mt-2">
                <Col>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col className="d-flex align-items-center">
                                    <h4 className="mb-0">{IMLocalized('general')}</h4>
                                </Col>
                                <Col className="col-auto">
                                    <button className="btn btn-primary btn-sm" onClick={onUpdate}>{IMLocalized('update')}</button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="calendar_view_list">{IMLocalized('individual_calendar_view_list')}</label>
                                        <Input size="sm" type="select" id="calendar_view_list" value={calendar_view_list} invalid={err_calendar_view_list.length > 0}
                                        onChange={(e)=>{
                                            setCalendarViewList(e.target.value);
                                            setErrCalendarViewList(''); 
                                        }}
                                        >
                                            <option></option>
                                            {calendar_view_dropdown.map((item)=>{
                                                return <option value={item.value} key={item.value}>{item.display}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{err_calendar_view_list}</FormFeedback>
                                        <div className="d-flex align-items-center">
                                            <span className="text-red">*</span><small className="ml-1">{IMLocalized('individual_calendar_tooltip')}</small>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="maximum_months_application" >{IMLocalized('application_leave_period_limit')}</label>
                                        <InputGroup size="sm">
                                            <Input size="sm" type="number" id="maximum_months_application" value={maximum_months_application} invalid={err_maximum_months_application.length >0}
                                            onChange={(e)=>{
                                                setMaximumMonthApplication(e.target.value);
                                                setErrMaximumMonthApplication('');
                                            }} 
                                            />
                                            <InputGroupAddon size="sm" addonType="append">
                                                <InputGroupText>{IMLocalized('MONTH')}</InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                        <FormFeedback>{err_maximum_months_application}</FormFeedback>
                                        <div className="d-flex align-items-center">
                                            <span className="text-red">*</span><small className="ml-1">{IMLocalized('application_leave_tooltip')}</small>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label"  htmlFor="urgent_leave_indicator">{IMLocalized('urgent_leave_indicator')}</label>
                                        <InputGroup size="sm">
                                            <Input size="sm" type="number" id="urgent_leave_indicator" value={annual_before_marked_as_urgent} invalid={err_annual_before_marked_as_urgent.length > 0} 
                                            onChange={(e)=>{
                                                setAnnualBeforeMarkedAsUrgent(e.target.value);
                                                setErrAnnualBeforeMarkedAsUrgent('');
                                            }}
                                            />
                                            <InputGroupAddon size="sm" addonType="append">
                                                <InputGroupText>{IMLocalized('payslip_days')}</InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                        <FormFeedback>{err_annual_before_marked_as_urgent}</FormFeedback>
                                        <div className="d-flex align-items-center">
                                            <span className="text-red">*</span><small className="ml-1">{IMLocalized('urgent_leave_tooltip')} </small>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="leave_option_annual_leave">{IMLocalized('annual_leave')}</label>
                                        <Input size="sm" type="select" id="leave_option_annual_leave" value={annual_leave} invalid={err_annual_leave.length >0}
                                        onChange={(e)=>{
                                            setAnnualLeave(e.target.value);
                                            setErrAnnualLeave('');
                                        }}
                                        >
                                            <option></option>
                                            {leavelist.map((item)=>{
                                                return <option value={item.id} key={item.id}>{item.name}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{err_annual_leave}</FormFeedback>
                                        <div className="d-flex align-items-center">
                                            <span className="text-red">*</span><small className="ml-1">{IMLocalized('select_annual_leave')}</small>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="leave_option_medical_leave">{IMLocalized('medical_leave')}</label>
                                        <Input size="sm" type="select" id="leave_option_medical_leave" value={medical_leave} invalid={err_medical_leave.length > 0}
                                        onChange={(e)=>{
                                            setMedicalLeave(e.target.value);
                                            setErrMedicalLeave('');
                                        }}>
                                            <option></option>
                                            {leavelist.map((item)=>{
                                                return <option value={item.id} key={item.id}>{item.name}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{err_medical_leave}</FormFeedback>
                                        <div className="d-flex align-items-center">
                                            <span className="text-red">*</span><small className="ml-1">{IMLocalized('select_medical_leave')}</small>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="leave_option_absent_leave">{IMLocalized('absent_leave')}</label>
                                        <Input size="sm" type="select" id="leave_option_absent_leave" value={absent_leave} invalid={err_absent_leave.length >0}
                                        onChange={(e)=>{
                                            setAbsentLeave(e.target.value);
                                            setErrAbsentLeave('');
                                        }}>
                                            <option></option>
                                            {leavelist.map((item)=>{
                                                return <option value={item.id} key={item.id}>{item.name}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{err_absent_leave}</FormFeedback>
                                        <div className="d-flex align-items-center">
                                            <span className="text-red">*</span><small className="pl-1">{IMLocalized('select_absent_leave')}</small>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="leave_option_nsmup_leave">{IMLocalized('nsmup_leave')}</label>
                                        <Input size="sm" type="select" id="leave_option_nsmup_leave" value={nsmup_leave} invalid={err_nsmup_leave.length >0} 
                                        onChange={(e)=>{
                                            setNsmupLeave(e.target.value);
                                            setErrNsmupLeave('');
                                        }}>
                                            <option></option>
                                            {leavelist.map((item)=>{
                                                return <option value={item.id} key={item.id}>{item.name}</option>
                                            })}
                                        </Input>
                                        <div className="d-flex align-items-center">
                                            <span className="text-red">*</span><small className="ml-1">{IMLocalized('select_nsmup_leave')}</small>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="leave_option_nopay_leave">{IMLocalized('payslip_no_pay_leave')}</label>
                                        <Input size="sm" type="select" id="leave_option_nopay_leave" value={no_pay_leave} invalid={err_no_pay_leave.length >0} 
                                        onChange={(e)=>{
                                            setNoPayLeave(e.target.value);
                                            setErrNoPayLeave('');
                                        }}>
                                            <option></option>
                                            {leavelist.map((item)=>{
                                                return <option value={item.id} key={item.id}>{item.name}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{err_no_pay_leave}</FormFeedback>
                                        <div className="d-flex align-items-center">
                                            <span className="text-red">*</span><small className="ml-1">{IMLocalized('select_no_pay_leave')}</small>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg="12">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="leave_option_probation_leave" name='probation_leave' className="custom-control-input" checked={probation_leave} onChange={(e)=>setProbaitonLeave(e.target.checked)} />
                                        <label className="custom-control-label" htmlFor="leave_option_probation_leave">{IMLocalized('probation_leave')}</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <span className="text-red">*</span><small className="ml-1">{IMLocalized('probation_leave_tooltip')}</small>
                                    </div>

                                    <div className="custom-control custom-checkbox mt-2">
                                        <input type="checkbox" id="leave_option_earned_leave_negative" name='allow_earned_leave_negative' className="custom-control-input" checked={allow_earned_leave_negative} onChange={(e)=>setAllowEarnedLeaveNegative(e.target.checked)} />
                                        <label className="custom-control-label" htmlFor="leave_option_earned_leave_negative">{IMLocalized('allow_earned_leave_negative')}</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <span className="text-red">*</span><small className="ml-1">{IMLocalized('allow_earned_leave_tooltip')}</small>
                                    </div>

                                    <div className="custom-control custom-checkbox pt-2">
                                        <input type="checkbox" id="leave_without_approval_pending" name='leave_without_approval_pending' className="custom-control-input" checked={leave_without_approval_pending} onChange={(e)=>setLeaveWithoutApprovalPending(e.target.checked)} />
                                        <label className="custom-control-label" htmlFor="leave_without_approval_pending">{IMLocalized('leave_without_approval_pending')}</label>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader className="d-flex align-items-center">
                            <h4 className="mb-0">{IMLocalized('first_month_proration')}</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col lg="4">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="leave_option_first_month_proration_option">{IMLocalized('first_month_proration_option')}</label>
                                        <Input size="sm" type="select" id="leave_option_first_month_proration_option" value={proration_option} invalid={err_proration_option.length >0}
                                        onChange={(e)=>{
                                            setProrationOption(e.target.value);
                                            setErrProrationOption('');
                                        }}>
                                            <option></option>
                                            {proration_dropdown.map(item=>{
                                                return <option value={item.value} key={item.value}>{item.display}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{err_proration_option}</FormFeedback>
                                        <div>
                                            <span className="text-red">*</span><small className="ml-1">{IMLocalized('first_month_proration_tooltip1')}</small>
                                        </div>
                                        <div>
                                            <span className="text-red">*</span><small className="ml-1">{IMLocalized('first_month_proration_tooltip2')}</small>
                                        </div>
                                        <div>
                                            <span className="text-red">*</span><small className="ml-1">{IMLocalized('first_month_proration_tooltip3')}</small>
                                        </div>
                                    </FormGroup>
                                </Col>
                                {proration_option === 'DATE' &&
                                <>
                                <Col lg="4">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="leave_option_cutoff_day">{IMLocalized('cutoff_day')}</label>
                                        <Input size="sm" id="leave_option_cutoff_day" min="0" type="number" value={cutoff_day} invalid={err_cutoff_day.length > 0} 
                                        onChange={(e)=>{
                                            setCutoffDay(e.target.value);
                                            setErrCutoffDay('');
                                        }}
                                        />
                                        <FormFeedback>{err_cutoff_day}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg="4">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="first_month_cutoff_entitlement">{IMLocalized('first_month_cutoff_entitlement')}</label>
                                        <Input size="sm" type="number" id="first_month_cutoff_entitlement" value={after_cutoff_leave} invalid={err_after_cutoff_leave.length >0}
                                        onChange={(e)=>{
                                            setAfterCufoffLeave(e.target.value);
                                            setErrAfterCutoffLeave('');
                                        }}/>
                                        <FormFeedback>{err_after_cutoff_leave}</FormFeedback>
                                        <div>
                                            <span className="text-red">*</span><small> {IMLocalized('first_month_cutoff_tooltip1')}</small>
                                        </div>
                                        <div>
                                            <span className="text-red">*</span><small> {IMLocalized('first_month_cutoff_tooltip2')}</small>
                                        </div>
                                        <div>
                                            <span className="text-red">*</span><small> {IMLocalized('first_month_cutoff_tooltip3')}</small>
                                        </div>
                                    </FormGroup>
                                </Col>
                                </>
                                }
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        </>}
        </>
    )
}
export default LeaveBaseSetting;
import * as remove from '../action/remove_otp_blacklist';

const initialState={
    data:null,
    isLoading:false,
    errors:null,
}

const remove_otp_blacklist = (state=initialState,action)=>{
    switch(action.type){
        case remove.REMOVEOTPBLACKLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null
            }
        case remove.REMOVEOTPBLACKLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case remove.REMOVEOTPBLACKLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case remove.REMOVEOTPBLACKLIST_RESET:
            return{
                ...state,
                errors:null,
                data:null
            }
        default:
            return state;
    }
}
export default remove_otp_blacklist;
import * as createjoblevel from '../action/create_joblevel';

const initialState ={
    isLoading:false,
    errors:null,
    data:null,
}

const create_joblevel = (state=initialState,action) =>{

    switch(action.type){
        case createjoblevel.CREATEJOBLEVEL_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            };
        case createjoblevel.CREATEJOBLEVEL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case createjoblevel.CREATEJOBLEVEL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case createjoblevel.CREATEJOBLEVEL_RESET:
            return{
                ...state,
                errors:null,
                data:null
            }
        default:
            return state;
        }
}
export default create_joblevel;
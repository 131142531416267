import myaxios from '../../axios';

export const MANAGERLEAVELIST_REQUEST = 'MANAGERLEAVELIST_REQUEST';
export const MANAGERLEAVELIST_SUCCESS = 'MANAGERLEAVELIST_SUCCESS';
export const MANAGERLEAVELIST_FAILURE = 'MANAGERLEAVELIST_FAILURE';

export const manager_leave_list = ()=>{
    return dispatch=>{
        console.log('im in managerLeaveRequest')
        dispatch({
            type:'MANAGERLEAVELIST_REQUEST'
        });

        myaxios.get('leave/manager/')
        .then((response)=>{
            dispatch({
                type:'MANAGERLEAVELIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'MANAGERLEAVELIST_FAILURE',
                payload:error
            })
        })
    }
}
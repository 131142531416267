import React,{useContext, useEffect, useState} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import Swal from 'sweetalert2';
import { Container, Row, Col, BreadcrumbItem, Breadcrumb, CardBody, ListGroupItem, Card, CardHeader, ListGroup} from 'reactstrap';
import { get_claim_list} from '../../action/claim/get_claim_list';
import { delete_claim, reset_delete_claim} from '../../action/claim/delete_claim';
import { IMLocalized} from '../../language/IMLocalized';
import ModalCreateClaim from './modal/ModalCreateClaim';
import ModalEditClaim from './modal/ModalEditClaim';
import { NewMainContext } from '../../context/NewMainContext';

function ClaimClaimList(){
    const dispatch = useDispatch();
    const list = useSelector(state=>state.get_claim_list.data);
    const delete_success = useSelector(state=>state.delete_claim.data);
    const isLoading = useSelector(state=>state.get_claim_list.isLoading);
    const isLoading2 = useSelector(state=>state.delete_claim.isLoading);
    const { addItem } = useContext(NewMainContext);
    const [edit_toggle,setEditToggle] = useState(false);
    const [create_toggle,setCreateToggle] = useState(false);
    const [edit_detail,setEditDetail] = useState(null);

    useEffect(()=>{
        if(delete_success !== null){
            dispatch(get_claim_list());
            dispatch(reset_delete_claim());
        }
    },[delete_success,dispatch])
    

    const updateSuccess=()=>{
        editClaim();
        dispatch(get_claim_list());
    }

    const createSuccess=()=>{
        dispatch(get_claim_list());
        toCreate();
    }

    const toCreate=()=>{
        setCreateToggle(prevState=>!prevState);
    }

    const editClaim=(id)=>{
        const toggle = !edit_toggle;
        if(toggle === true){
            const detail = list.find((item)=>item.id === id);
            if(detail){
                setEditDetail(detail);
            }
        }
        else{
            setEditDetail(null);
        }
        setEditToggle(toggle);
    }

    const deleteClaim =(id,name)=>{
        Swal.fire({
            type:"warning",
            title:`${IMLocalized('delete_claim_type_confirmation')} (${name})!`,
            showCancelButton:true,
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!')
        })
        .then((result)=>{
            if(result.value){
                dispatch(delete_claim(id));
            }
        })
    }

    const arr = list.slice().sort((a,b)=>(a.id) - (b.id));
    return(
        <>
        <Container fluid>
            <Row className="bg-white border-bottom">
                <Col className="p-2">
                    <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                        <BreadcrumbItem><a href="/#" onClick={()=>addItem('1','dashboard')}>{IMLocalized('home')}</a></BreadcrumbItem>
                        <BreadcrumbItem active>{IMLocalized('claim_list')}</BreadcrumbItem>
                    </Breadcrumb>
                </Col>
            </Row>
        </Container>
        <Container className="pt-2">
            <Row>
                <Col>
                    <Card>
                        <CardHeader className='d-flex align-items-center justify-content-between'>
                            <h4 className='mb-0'>{IMLocalized('claim_list')}</h4>
                            <button onClick={()=>toCreate()} className="btn btn-primary btn-sm">{IMLocalized('create')}</button>
                        </CardHeader>
                        {isLoading || isLoading2 ? <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>:null}
                        {!isLoading && !isLoading2 && arr.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
                        {!isLoading && !isLoading2 && arr.length !== 0 && 
                        <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 300px)'}}>
                            {arr.map((item,index)=>{
                                return(
                                    <ListGroupItem className="py-2 d-flex justify-content-between align-items-center" key={item.id}>
                                        <span>{index+ 1}.  {item.name}</span>        
                                        <div className='mr-1'>
                                            <button onClick={()=>editClaim(item.id)}   className="btn btn-info btn-sm">{IMLocalized('edit')}</button>
                                            <button onClick={()=>deleteClaim(item.id,item.name)} className="btn btn-danger btn-sm">{IMLocalized('delete')}</button>
                                        </div>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>
                        }
                    </Card>
                </Col>
            </Row>
            <ModalEditClaim toggle={edit_toggle} detail={edit_detail} closeModal={()=>editClaim()} onSuccess={()=>updateSuccess()}  />
            <ModalCreateClaim toggle={create_toggle} closeModal={()=>toCreate()} onSuccess={()=>createSuccess()} />
        </Container>
        </>
        
    )
}
export default ClaimClaimList;
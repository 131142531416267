import * as nationality from '../action/get_iras_nationality';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const get_iras_nationality = (state=initialState,action)=>{
    switch(action.type){
        case nationality.GETIRASNATIONALITY_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[],
                data:[]
            }
        case nationality.GETIRASNATIONALITY_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data,
            }
        case nationality.GETIRASNATIONALITY_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state
    }
}
export default get_iras_nationality;
import * as selfavailableleave from '../../action/user/leave_calendar_get_self_available_leave_options';

const initialState={
    isLoading:false,
    errors:[],
    data:[],
}

const leave_calendar_get_self_available_leave_options = (state=initialState,action)=>{
    switch(action.type){
        case selfavailableleave.LEAVECALENDARGETSELFLEAVEOPTION_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case selfavailableleave.LEAVECALENDARGETSELFLEAVEOPTION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
            }
        case selfavailableleave.LEAVECALENDARGETSELFLEAVEOPTION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload.response.data
            }
        default:
            return state;
    }
}
export default leave_calendar_get_self_available_leave_options;
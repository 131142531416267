import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormFeedback, FormGroup, Modal ,ModalBody, ModalFooter, ModalHeader, Row ,Input} from 'reactstrap';
import { add_employee_education, reset_add_employee_education } from '../../../../action/employee/add_employee_education';
import { IMLocalized } from '../../../../language/IMLocalized';

function ModalCreateEducation({ toggle, closeModal,onSuccess,id}){

    const dispatch = useDispatch();
    const education_level = useSelector(state=>state.dropdown_list.education_level);
    const nationalitylist = useSelector(state=>state.dropdown_list.countries);
    const isLoading = useSelector(state=>state.add_employee_education.isLoading);
    const success = useSelector(state=>state.add_employee_education.data);

    const [level,setLevel] = useState('');
    const [err_level,setErrLevel] = useState('');
    const [institute,setInstitute] = useState('');
    const [err_institute,setErrInstitute] = useState('');
    const [school,setSchool] = useState('');
    const [err_school,setErrSchool] = useState('');
    const [country,setCountry] = useState('');
    const [err_country,setErrCountry] = useState('');
    const [certification,setCertification] = useState('');
    const [err_certification,setErrCertification] = useState('');
    const [gpa,setGpa] = useState(0);
    const [err_gpa,setErrGpa] = useState('');
    const [highest_education,setHighest] = useState(false);
    const [graduation_date,setGraduation] = useState('');
    const [err_graduation_date,setErrGraduation] = useState('');
    const [enrollment_date,setEnrollment] = useState('');
    const [err_enrollment_date,setErrEnrollment] = useState('');
    const [notes,setNotes] = useState('');
    const [images,setImages] = useState([]);
    const [err_images,setErrImages] = useState('');

    useEffect(()=>{
        if(toggle){
            setLevel('');
            setErrLevel('');
            setInstitute('');
            setErrInstitute('');
            setSchool('');
            setErrSchool('');
            setCountry('');
            setErrCountry('');
            setCertification('');
            setErrCertification('');
            setGpa(0);
            setErrGpa('');
            setHighest(false);
            setGraduation('');
            setErrGraduation('');
            setEnrollment('');
            setErrEnrollment('');
            setNotes('');
            setImages([]);
            setErrImages('');
        }
    },[toggle])

    useEffect(()=>{
        if( success !== null && toggle){
            onSuccess();
            dispatch(reset_add_employee_education());
        }
    },[toggle,success,onSuccess,dispatch])

    const createEducation=()=>{
        if(level !== '' && institute !== '' && school !== '' && country !== '' && certification !== '' && gpa !== '' && graduation_date !== '' && enrollment_date !== ''){
            if(images.length >=1 && images.length <= 30){
                dispatch(add_employee_education(id,level,institute,school,country,certification,gpa,highest_education,graduation_date,enrollment_date,notes,images));
            }
            else{
                setErrImages(IMLocalized('must_upload_image_30'))
            }
        }
        else{
            if(level === ''){
                setErrLevel(IMLocalized('education_level_is_required'));
            }
            if(institute === ''){
                setErrInstitute(IMLocalized('institute_is_required'));
            }
            if(school === ''){
                setErrSchool(IMLocalized('school_is_required'));
            }
            if(country === ''){
                setErrCountry(IMLocalized('country_is_required'));
            }
            if(certification === ''){
                setErrCertification(IMLocalized('certification_is_required'));
            }
            if(gpa === ''){
                setErrGpa(IMLocalized('gpa_is_required'));
            }
            if(graduation_date === ''){
                setErrGraduation(IMLocalized('graduation_date_is_required'))
            }
            if(enrollment_date === ''){
                setErrEnrollment(IMLocalized('enrollment_date_is_required'))
            }
        }
    }


    return(
        <Modal size="lg" isOpen={toggle}>
            <ModalHeader toggle={closeModal}>{IMLocalized('add_education')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='education_level'>{IMLocalized('education_level')}</label>
                            <Input id="education_level" size="sm" type="select" name="level" value={level} onChange={(e)=>{setLevel(e.target.value); setErrLevel('') }} invalid={err_level.length >0}>
                                <option></option>
                                {education_level.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_level}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='institute'>{IMLocalized('institute')}</label>
                            <Input size="sm" id="institute" type="textarea" name="institute" value={institute} onChange={(e)=>{setInstitute(e.target.value); setErrInstitute('')}} invalid={err_institute.length >0} />
                            <FormFeedback>{err_institute}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='school'>{IMLocalized('school')}</label>
                            <Input size="sm" id="school" type="text" name="school" value={school} onChange={(e)=>{setSchool(e.target.value); setErrSchool('')}} invalid={err_school.length >0} />
                            <FormFeedback>{err_school}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='country'>{IMLocalized('country')}</label>
                            <Input size="sm" type="select" name="country" id="country" value={country} onChange={(e)=>{setCountry(e.target.value); setErrCountry('')}} invalid={err_country.length >0}>
                                <option></option>
                                {nationalitylist.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display_name}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_country}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='certification'>{IMLocalized('certification')}</label>
                            <Input size="sm" id="certification" type="certification" value={certification} onChange={(e)=>{setCertification(e.target.value);setErrCertification('')}} invalid={err_certification.length >0} />
                            <FormFeedback>{err_certification}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='gpa'>{IMLocalized('gpa')}</label>
                            <Input size="sm" id="gpa" min="0" step="0.1" type="number" name="gpa" value={gpa} onChange={(e)=>{setGpa(e.target.value); setErrGpa('')}} invalid={err_gpa.length >0} />
                            <FormFeedback>{err_gpa}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='graduation_date'>{IMLocalized('graduation_date')}</label>
                            <Input id="graduation_date" size="sm" type="date" name="graduation_date" value={graduation_date} onChange={(e)=>{setGraduation(e.target.value); setErrGraduation('')}} invalid={err_graduation_date.length >0} />
                            <FormFeedback>{err_graduation_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='enrollment_date'>{IMLocalized('enrollment_date')}</label>
                            <Input id="enrollment_date" size="sm" type="date" name="enrollment_date" value={enrollment_date} onChange={(e)=>{setEnrollment(e.target.value); setErrEnrollment('')}} invalid={err_enrollment_date.length >0}  />
                            <FormFeedback>{err_enrollment_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='note'>{IMLocalized('note')}</label>
                            <Input size="sm" id="note" type="textarea" name="notes" value={notes} onChange={(e)=>setNotes(e.target.value)} />
                        </FormGroup>
                    </Col>
                    <Col lg="12" className="d-flex align-items-center">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="education_highest" name='highest_education' className="custom-control-input" checked={highest_education} onChange={(e)=>setHighest(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="education_highest">{IMLocalized('highest_education')}</label>
                        </div>
                    </Col>
                    <Col lg="12" className="pt-4">
                        <FormGroup>
                            <input type="file" multiple onChange={(e)=>{setImages(e.target.files); setErrImages('') }} accept="image/*" />
                            <div>
                                <small className='text-red'>{err_images}</small>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading?
                <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button className="btn btn-primary btn-sm" onClick={createEducation}>{IMLocalized('create')}</button>
                    <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
                </>
                }
            </ModalFooter>
        </Modal>
    )
}
export default ModalCreateEducation;
import * as deletepaycomponent from '../../action/payroll/delete_pay_component';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}


const delete_pay_component = (state =initialState,action)=>{
    switch(action.type){
        case deletepaycomponent.DELETEPAYCOMPONENT_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case deletepaycomponent.DELETEPAYCOMPONENT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case deletepaycomponent.DELETEPAYCOMPONENT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        default:
            return state;
    }
}
export default delete_pay_component;
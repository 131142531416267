import * as updateapproval from '../../action/claim/update_claim_approval';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const update_claim_approval = (state=initialState,action)=>{
    switch(action.type){
        case updateapproval.UPDATECLAIMAPPROVAL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case updateapproval.UPDATECLAIMAPPROVAL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updateapproval.UPDATECLAIMAPPROVAL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case updateapproval.UPDATECLAIMAPPROVAL_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default update_claim_approval;
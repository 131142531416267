import * as blacklist from '../action/get_otp_blacklist';

const initialState={
    data:null,
    errors:null,
    isLoading:false
}

const get_otp_blacklist = (state=initialState,action)=>{
    switch(action.type){
        case blacklist.GETOTPBLACKLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case blacklist.GETOTPBLACKLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data,
                
            }
        case blacklist.GETOTPBLACKLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state
    }
}
export default get_otp_blacklist;
import myaxios from '../../axios';

export const DOWNLOADCPF_REQUEST = 'DOWNLOADCPF_REQUEST';
export const DOWNLOADCPF_SUCCESS = 'DOWNLOADCPF_SUCCESS';
export const DOWNLOADCPF_FAILURE = 'DOWNLOADCPF_FAILURE';

export const download_cpf=(id,name)=>{
    return dispatch=>{
        dispatch({
            type:'DOWNLOADCPF_REQUEST'
        })

        myaxios({
            url:`payroll/payroll/${id}/DownloadCPFSubmission/`,
            method:'GET',
            responseType:"blob"
        })
        .then((response)=>{
            const hello = `${name}.zip`
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',hello);
            document.body.appendChild(link);
            link.click();
            dispatch({
                type:'DOWNLOADCPF_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'DOWNLOADCPF_FAILURE',
                payload:error
            })
        })
    }
}
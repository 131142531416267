import myaxios from '../../axios';

export const GETLEAVEAPPROVAL_REQUEST = 'GETLEAVEAPPROVAL_REQUEST';
export const GETLEAVEAPPROVAL_SUCCESS = 'GETLEAVEAPPROVAL_SUCCESS';
export const GETLEAVEAPPROVAL_FAILURE = 'GETLEAVEAPPROVAL_FAILURE';


export const get_leave_approval = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETLEAVEAPPROVAL_REQUEST'
        })

        myaxios.get('leave/approval/')
        .then((response)=>{
            dispatch({
                type:'GETLEAVEAPPROVAL_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETLEAVEAPPROVAL_FAILURE',
                payload:error
            })
        })
    }
}
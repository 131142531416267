import * as cancelclaim from '../../action/user/user_modal_cancel_claim';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}


const user_modal_cancel_claim = (state=initialState,action)=>{
    switch(action.type){
        case cancelclaim.USERMODALCANCELCLAIM_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case cancelclaim.USERMODALCANCELCLAIM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case cancelclaim.USERMODALCANCELCLAIM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case cancelclaim.USERMODALCANCELCLAIM_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state
    }
}
export default user_modal_cancel_claim;
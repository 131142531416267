import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';

export const VALIDATEAPPLYLEAVERESET_REQUEST = 'VALIDATEAPPLYLEAVERESET_REQUEST';
export const VALIDATEAPPLYLEAVERESET_SUCCESS = 'VALIDATEAPPLYLEAVERESET_SUCCESS';
export const VALIDATEAPPLYLEAVERESET_FAILURE = 'VALIDATEAPPLYLEAVERESET_FAILURE';
export const VALIDATEAPPLYLEAVERESET_RESET = 'VALIDATEAPPLYLEAVERESET_RESET';

export const validate_apply_leave_reset = (idd,data)=>{
    return dispatch=>{
        dispatch({
            type:'VALIDATEAPPLYLEAVERESET_REQUEST'
        });

        myaxios.post(`leave/reset/${idd}/array_validate/`,{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'VALIDATEAPPLYLEAVERESET_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'VALIDATEAPPLYLEAVERESET_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('reset_failure')
            });
        })
    }
}

export const reset_validate_apply_leave_reset = () => dispatch =>{
    dispatch({
        type:'VALIDATEAPPLYLEAVERESET_RESET'
    });
}
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const LEAVEASSIGNSCHEME3_REQUEST = 'LEAVEASSIGNSCHEME3_REQUEST';
export const LEAVEASSIGNSCHEME3_SUCCESS = 'LEAVEASSIGNSCHEME3_SUCCESS';
export const LEAVEASSIGNSCHEME3_FAILURE = 'LEAVEASSIGNSCHEME3_FAILURE';
export const LEAVEASSIGNSCHEME3_RESET = 'LEAVEASSIGNSCHEME3_RESET';


export const leave_assign_scheme3 = (scheme,employees,full_proration,year)=>{
    return dispatch=>{
        dispatch({
            type:'LEAVEASSIGNSCHEME3_REQUEST'
        });

        myaxios.post('leave/assign/scheme/',{
            scheme:scheme,
            employees:employees,
            full_proration:full_proration,
            year:year
        })
        .then((response)=>{
            dispatch({
                type:'LEAVEASSIGNSCHEME3_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type:'success',
                title:IMLocalized('assign_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'LEAVEASSIGNSCHEME3_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('assign_failure')
            });
        })
    }
}

export const reset_leave_assign_scheme3 = () => dispatch =>{
    dispatch({
        type:'LEAVEASSIGNSCHEME3_RESET'
    });
}
import * as deljoblevel from '../action/delete_joblevel';

const initialState ={
    isLoading:false,
    errors:null,
    data:null,
}


const delete_joblevel = (state=initialState,action) =>{
    switch(action.type) {
        case deljoblevel.DELJOBLEVEL_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            };
        case deljoblevel.DELJOBLEVEL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case deljoblevel.DELJOBLEVEL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case deljoblevel.DELJOBLEVEL_RESET:
            return{
                ...state,
                errors:null,
                data:null
            }
        default:
            return state;
    }
}
export default delete_joblevel;



import myaxios from '../axios';

export const RESETOTPPASS_REQUEST = 'RESETOTPPASS_REQUEST';
export const RESETOTPPASS_SUCCESS = 'RESETOTPPASS_SUCCESS';
export const RESETOTPPASS_FAILURE = 'RESETOTPPASS_FAILURE';


export const reset_otp_password = (email,captcha_response)=>{
    return dispatch=>{
        dispatch({
            type:'RESETOTPPASS_REQUEST'
        })

        myaxios.post('otp/password/forgot/',{
            email:email,
            captcha_response:captcha_response
        })
        .then((response)=>{
            dispatch({
                type:'RESETOTPPASS_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'RESETOTPPASS_FAILURE',
                payload:error
            })
        })
    }
}
import React,{Component} from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {connect} from 'react-redux';
import { Container, Row, Col, Modal, ModalHeader, ModalBody, Table, ModalFooter} from 'reactstrap';
import Swal from 'sweetalert2';
import XLSX from 'xlsx';
import ExcelJs from 'exceljs';
import FileSaver from 'file-saver';
import moment from 'moment';
import {array_create_employee_passport} from '../../action/employee/array_create_employee_passport';
import {IMLocalized} from '../../language/IMLocalized';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

const gridOptions={
    singleClickEdit:true,
    defaultColDef:{
        sortable:true,
        filter:true,
        suppressMenu:true,
        resizable:true
    },
    sideBar:{
        toolPanels:[
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                
            }
        ],
        defaultToolPanel:''
    },
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
}
class wizard_employee_passport extends Component{

    constructor(props){
        super(props);
        this.state={
            list:[],
            rowData:[],
            count:0,
            noRowTemplate:IMLocalized('no_data'),
            rowSelection:'single',
            lodingtemplate:IMLocalized('loading'),
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            columnsDefs:[],
            select_all:false,
            errmsg:[],
            err_toggle:false,
            language:localStorage.getItem('language'),
            validatelist:[],
        }
    }

    componentDidMount(){
        const {list} = this.props;
        this.setState({list});
    }

    componentDidUpdate(prevProps,prevState){
        if(prevState.list !== this.state.list || prevState.count !== this.state.count){
            this.getDetail();
        }
        if(prevProps.success !== this.props.success){
            const {rowData} = this.state;
            const data = gridOptions.api.getSelectedRows();
            const idlist = data.map((item)=>{
                return item.employee.id
            }) 
            let arr = rowData.filter(function(item){
                return idlist.includes(item.employee.id) === false
            })
            this.setState({rowData:arr});
            if(arr.length !== 0){
                Swal.fire({
                    type:'warning',
                    title:IMLocalized('do_you_continue_create_passport'),
                    text:IMLocalized('skip_move_to_next_step'),
                    showCancelButton:true,
                    cancelButtonText:IMLocalized('skip'),
                    confirmButtonText:IMLocalized('continue')
                })
                .then((result)=>{
                    if('value' in result){
                        
                        
                    }
                    else{
                        // 0 not show this msg
                        if(arr.length !== 0){
                            Swal.fire({
                                type:'warning',
                                title:`${arr.length} ${IMLocalized('ppl_not_create_passport')}`,
                                showCancelButton:true,
                                cancelButtonText:IMLocalized('cancel'),
                                confirmButtonText:IMLocalized('ok!')
                            })
                            .then((result1)=>{
                                if('value' in result1){
                                    this.props.onNext();
                                }
                            })
                        }
                        else{
                            this.props.onNext();
                        }
                    }
                })
            }
            else{
                this.props.onNext();
            }
        }
        if(prevProps.errors !== this.props.errors){
            const {errors} = this.props;
            if(errors !==undefined){
                const data = errors.response.data;
                if('expiry_date' in data){
                    const word = IMLocalized('expiry_date_is_invalid');
                    this.popError(word);
                }
                
                if('issue_date' in data){
                    const word = IMLocalized('issued_date_is_invalid');
                    this.popError(word)
                }
            }
        }

    }

    popError=(msg)=>{
        Swal.fire({
            title:msg,
            type:'warning',
            confirmButtonText:IMLocalized('ok!')
        })
    }

    onCellValueChanged=(params)=>{
        if(params.oldValue !== params.newValue){
            const column = params.column.colDef.field;
            if(column === 'employee.passport_number'){
                params.data.employee.err_number = false;
                const focusedCell = gridOptions.api.getFocusedCell();
                if(focusedCell !== undefined){
                    gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                        if(index === focusedCell.rowIndex){
                            rowNode.setSelected(true);
                        }
                    })
                }
            }
            else if(column === 'employee.passport_issue'){
                params.data.employee.err_issue = false;
                const focusedCell = gridOptions.api.getFocusedCell();
                if(focusedCell !== undefined){
                    gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                        if(index === focusedCell.rowIndex){
                            rowNode.setSelected(true);
                        }
                    })
                }
            }
            else if(column === 'employee.passport_expiry'){
                params.data.employee.err_expiry = false;
                const focusedCell = gridOptions.api.getFocusedCell();
                if(focusedCell !== undefined){
                    gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                        if(index === focusedCell.rowIndex){
                            rowNode.setSelected(true);
                        }
                    })
                }
            }

            params.api.refreshCells();
        }
    }

    onCellFocused=(params)=>{
        if(params !== null){
            if(params.column.colDef !== null){
                if(params.column.colDef.field ==='employee.passport_number' || params.column.colDef.field ==='employee.passport_issue' || params.column.colDef.field ==='employee.passport_expiry'){
                    
                    params.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = true;
                }
                else{
                    params.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = false;
                }
            }   
        }
    }

    getDetail=()=>{
        const {list} = this.state;
        if(list.length !== 0){
            const detail = list.map((item)=>{
                item.employee.passport_number = null;
                item.employee.passport_issue = null;
                item.employee.passport_expiry = null;
                item.employee.err_number = false;
                item.employee.err_issue = false;
                item.employee.err_expiry = false;
                return item;
            })
            this.setState({rowData:detail});
        }

        const columnsDefs=[
            {
                headerName:IMLocalized('agrid_select'),
                field:'',
                editable:false,
                width:120,
                lockPosition:true,
                pinned:'left',
                filter:false,
                headerCheckboxSelection: this.isFirstColumn,
                checkboxSelection: this.isFirstColumn,
                headerCheckboxSelectionFilteredOnly:true,
            },
            {
                headerName:IMLocalized('agrid_emp_id'),
                field:'employee.employee_number',
                editable:false,
                width:200,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_emp_name'),
                field:'employee.name',
                editable:false,
                width:200,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_passport_number'),
                field:'employee.passport_number',
                editable:true,
                width:250,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.employee.err_number}
                },
            },
            {
                headerName:IMLocalized('agrid_issued_date'),
                field:'employee.passport_issue',
                editable:true,
                width:200,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.employee.err_issue}
                },
            },
            {
                headerName:IMLocalized('agrid_expiry_date'),
                field:'employee.passport_expiry',
                editable:true,
                width:200,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.employee.err_expiry}
                },
            }
        ]

        this.setState({columnsDefs});
    }

    isFirstColumn(params) {
        if(params !== null){
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }   
    }

    goBack=()=>{
        Swal.fire({
            type:'warning',
            title:IMLocalized('skip_emp_wizard_confirmation'),
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!')
        })
        .then((result)=>{
            if('value' in result){
                this.props.goBack()
            }
        })
    }

    generateExcel=()=>{
        const {language} = this.state;
        const rowData = gridOptions.api.getSelectedRows();
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('employee_passport');
        const ws2 = wb.addWorksheet('id');
        ws2.getCell('A1').value = 'WizardEmployeePassport';
        ws2.getCell('A2').value = language;
        ws.columns=[
            {header:IMLocalized('agrid_emp_id'),key:'employee.employee_number'},
            {header:IMLocalized('agrid_emp_name'),key:'employee.name'},
            {header:IMLocalized('agrid_passport_number'),key:'employee.passport_number'},
            {header:IMLocalized('agrid_issued_date'),key:'employee.passport_issue'},
            {header:IMLocalized('agrid_expiry_date'),key:'employee.passport_expiry'}
        ]

        const columns=[
            {header:IMLocalized('agrid_emp_id'),key:'employee.employee_number'},
            {header:IMLocalized('agrid_emp_name'),key:'employee.name'},
            {header:IMLocalized('agrid_passport_number'),key:'employee.passport_number'},
            {header:IMLocalized('agrid_issued_date'),key:'employee.passport_issue'},
            {header:IMLocalized('agrid_expiry_date'),key:'employee.passport_expiry'}
        ]

        if(rowData.length !== 0){
            for(let i=0;i<columns.length;i++){
                const field = columns[i].key;
                for(let x= 0;x<rowData.length;x++){
                    const step = 2+x;
                    const data = this.getValue(rowData[x],field);
                    ws.getCell(this.getColumnName(i)+step).value = data;
                    if(field === 'employee.passport_issue' || field === 'employee.passport_expiry'){
                        ws.getCell(this.getColumnName(i)+step).numFmt = 'd/M/yy';
                        ws.getCell(this.getColumnName(i)+step).value = null;
                    }
                    else{
                        ws.getCell(this.getColumnName(i)+step).value = data;
                    }
                }
            }

            ws.columns.forEach(column =>{
                column.width = column.header.length < 15 ? 15 : column.header.length
            });
    
    
            wb.xlsx.writeBuffer().then((buf)=>{
                
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                FileSaver.saveAs(file, 'Wizard_Employee_Passport_Template.xlsx')
            });
        }
        else{
            Swal.fire({
                type:'warning',
                title:IMLocalized('no_employee_selected'),
                confirmButtonText:IMLocalized('yes!'),
            })
        }
        
    }

    getValue(object, path) {
        const parts = path.split(".");
        
        return parts.reduce((value, key) => value ? value[key] : value, object)
    }

    getColumnName=(n)=>{
        let ordA = 'A'.charCodeAt(0);
        let ordZ = 'Z'.charCodeAt(0);
        let len = ordZ - ordA +1;

        let s = '';
        while(n >=0){
            s = String.fromCharCode(n % len + ordA )+s;
            n = Math.floor(n/len) -1;
        }
        return s;
    }

    handleFile = event => {
        const { target = {} } = event || {};
        target.value = "";
    };

    uploadExcel=(e)=>{
        const file = e.target.files[0];
        const reader = new FileReader();
        const {language} = this.state;
        reader.onload=(e)=>{
            const bstr = e.target.result;
            const wb = XLSX.read(bstr,{type:'binary'});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const wsname2 = wb.SheetNames[1];
            const ws2 = wb.Sheets[wsname2];

            const columns = {
                'A':'employee_number',
                'B':'name',
                'C':'passport_number',
                'D':'passport_issue',
                'E':'passport_expiry'
            }

            let rowData1 = [];
            let rowIndex = 2;
            let idd = '';
            let lang = '';
            if(ws2 !== undefined){
                if(ws2['A1'] !== undefined){
                    idd = ws2['A1'].w;
                    lang = ws2['A2'].w;
                }
            }

            if(idd === 'WizardEmployeePassport'){
                if(lang === language){
                    while(ws['A'+rowIndex]){
                        let row ={};
                        for(let i =0;i<Object.keys(columns).length;i++){
                            const column = Object.keys(columns)[i];
                            const index = column + rowIndex;
                            if(ws[index] !== undefined){
                                row[columns[column]] = ws[index].w;
                            }
                            else{
                                row[columns[column]] = null;
                            }
                        }
                        rowData1.push(row);
                        rowIndex++;
                    }
                    Promise.resolve(this.getClearAggrid())
                    .then(()=> this.getImportData(rowData1))
                }
                else{
                    Swal.fire({
                        title:IMLocalized('wrong_file_selected'),
                        text:IMLocalized('please_choose_correct_language'),
                        type:'error',
                        confirmButtonColor:'#d33',
                        confirmButtonText:IMLocalized('ok!'),
                    })
                }
            }
            else{
                Swal.fire({
                    title:IMLocalized('wrong_file_selected'),
                    type:'error',
                    confirmButtonColor:'#d33',
                    confirmButtonText:IMLocalized('ok!'),
                })
            }
        }
        reader.readAsBinaryString(file)
    }

    getClearAggrid=()=>{
        gridOptions.api.forEachNode((rowNode,index)=>{
            rowNode.setSelected(false);
        })
    }

    getImportData=(data)=>{
        for(let i=0;i<data.length;i++){
            const employee_number = data[i].employee_number;
            const passport_expiry = data[i].passport_expiry;
            const passport_number = data[i].passport_number;
            const passport_issue = data[i].passport_issue;
            gridOptions.api.forEachNode((rowNode,index)=>{
                const employee_number1 = rowNode.data.employee.employee_number;
                if(employee_number1 === employee_number){
                    rowNode.data.employee.passport_expiry = passport_expiry;
                    rowNode.data.employee.passport_issue = passport_issue;
                    rowNode.data.employee.passport_number = passport_number;
                    rowNode.setSelected(true);
                    gridOptions.api.refreshCells(rowNode)
                }
            })
        }
    }

    getDateFormat=(date)=>{
        const date1 = moment(date,'DD/MM/YYYY');
        const new_date = date1.format('YYYY-MM-DD');
        return new_date;
    }

    onCreate=()=>{
        this.setState({err_msg:[]});
        const data = gridOptions.api.getSelectedRows();
        let arr = [];
        let err = [];
        if(data.length !== 0){
            for(let i=0;i<data.length;i++){
                let err1 = [];

                const date1 = data[i].employee.passport_issue;
                const date2 = data[i].employee.passport_expiry;
                const employee = data[i].employee.id;
                const name = data[i].employee.name;
                const number = data[i].employee.employee_number;
                const passport = data[i].employee.passport_number;

                if(date1 === null || date1 === '' ){
                    const msg = IMLocalized('issued_date_is_required');
                    err1.push(msg);
                    gridOptions.api.forEachNode((rowNode,index)=>{
                        if(rowNode.data.employee.employee_number === number){
                            rowNode.data.employee.err_issue = true;
                            gridOptions.api.refreshCells(rowNode)
                        }
                    })
                    
                }
                else if (date2 === null || date2 === ''){
                    const msg = IMLocalized('expiry_date_is_required');
                    err1.push(msg);
                    gridOptions.api.forEachNode((rowNode,index)=>{
                        if(rowNode.data.employee.employee_number === number){
                            rowNode.data.employee.err_expiry = true;
                            gridOptions.api.refreshCells(rowNode)
                        }
                    })
                }
                else{
                    const d1 = this.getDateFormat(date1);
                    const d2 = this.getDateFormat(date2);
                    if(d1 === 'Invalid date'){
                        const msg = IMLocalized('issued_date_is_invalid');
                        err1.push(msg)
                        gridOptions.api.forEachNode((rowNode,index)=>{
                            if(rowNode.data.employee.employee_number === number){
                                rowNode.data.employee.err_issue = true;
                                gridOptions.api.refreshCells(rowNode)
                            }
                        })
                    }
                    else if (d2 === 'Invalid date'){
                        const msg = IMLocalized('expiry_date_is_invalid');
                        err1.push(msg)
                        gridOptions.api.forEachNode((rowNode,index)=>{
                            if(rowNode.data.employee.employee_number === number){
                                rowNode.data.employee.err_expiry = true;
                                gridOptions.api.refreshCells(rowNode)
                            }
                        })
                    }
                    if(d1 > d2){
                        const msg = IMLocalized('issued_cant_great_expiry_date')
                        err1.push(msg)
                        gridOptions.api.forEachNode((rowNode,index)=>{
                            if(rowNode.data.employee.employee_number === number){
                                rowNode.data.employee.err_issue = true;
                                gridOptions.api.refreshCells(rowNode)
                            }
                        })
                    }
                    
                }
                
                if(passport === '' || passport === null){
                    const msg = IMLocalized('passport_number_is_required')
                    err1.push(msg)
                    gridOptions.api.forEachNode((rowNode,index)=>{
                        if(rowNode.data.employee.employee_number === number){
                            rowNode.data.employee.err_number = true;
                            gridOptions.api.refreshCells(rowNode)
                        }
                    })
                }
                if(err1.length !== 0){
                    err.push({name,number,msg:err1});
                }


                const expiry_date = this.getDateFormat(date2);
                const issued_date = this.getDateFormat(date1);
                
                
                arr.push({employee,expiry_date,issued_date,passport});
            }

            if(err.length !== 0){
                this.setState({errmsg:err});
                this.setState({err_toggle:true})
                
            }
            else{
                this.setState({validatelist:arr});
                this.props.array_create_employee_passport(arr);
            }
        }
        else{
            Swal.fire({
                title:IMLocalized('no_employee_selected'),
                type:'warning',
                confirmButtonText:IMLocalized('ok!')
            })
        }
        

        
    }

    errorToggle=()=>{
        const toggle = !this.state.err_toggle;
        this.setState({err_toggle:toggle});
    }

    modalError=()=>{

        const {errmsg} = this.state;
        const body = errmsg.map((item)=>{
            let arr = [];
            const msg = item.msg;
            for(let i =0;i<msg.length;i++){
                const msg1 = msg[i];
                arr.push(<div><span>● {msg1}</span></div>)
            }
            return(
                <tr>
                    <td>{item.number}</td>
                    <td>{item.name}</td>
                    <td>{arr}</td>
                </tr>
            )
        })
        const table=(
            <Modal size="lg" isOpen={this.state.err_toggle}>
                <ModalHeader toggle={this.errorToggle}>{IMLocalized('error_msg')}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Table size="sm" className="align-items-center table-bordered" responsive>
                                <thead>
                                    <tr>
                                        <th>{IMLocalized('employee_number')}</th>
                                        <th>{IMLocalized('name')}</th>
                                        <th>{IMLocalized('error')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {body}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-danger btn-sm" onClick={this.errorToggle}>{IMLocalized('close')}</button>
                </ModalFooter>
            </Modal>
        )

        return table;
    }

    skipAssign=()=>{
        Swal.fire({
            title:IMLocalized('skip_emp_passport_creation'),
            type:'warning',
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!')
        })
        .then((result)=>{
            if(result.value){
                this.props.onNext();
            }
        })
    }

    clearData=()=>{
        const {count} = this.state;
        const final = count + 1;
        this.setState({rowData:[]});
        this.setState({count:final});
        this.setState({error_msg:[]});
    }

    render(){
        return(
            <div>
                {/* {this.props.isLoading ?<OverlayLoading/>: */}
                <Container fluid>
                    <Row className="pt-2">
                        <Col >
                            <div className="d-flex">
                                <div>
                                    <button className="btn btn-primary btn-sm" onClick={this.goBack}> <i className="fas fa-chevron-left"></i> {IMLocalized('back_to_main_page')}</button>
                                    <button className="btn btn-primary btn-sm" onClick={this.skipAssign}>{IMLocalized('skip')}</button>
                                    <button className="btn btn-primary btn-sm" onClick={this.generateExcel}>{IMLocalized('get_employee_passport_template')}</button>
                                </div>
                                <div className="ml-2 mr-2">
                                    <label className="btn btn-primary btn-sm" for="masspassport_file">{IMLocalized('import_from_file')}</label>
                                </div>
                                <div>
                                    <button className="btn btn-primary btn-sm" onClick={this.clearData}>{IMLocalized('clear')}</button>
                                    <button className="btn btn-primary btn-sm" onClick={this.onCreate}>{IMLocalized('create')}</button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="pt-2">
                        <Col>
                            <div className="d-flex justify-content-center">
                                <div className="ag-theme-balham" style={{height:'calc(100vh - 200px)', width:'100%' }}>
                                    <AgGridReact
                                    columnDefs={this.state.columnsDefs}
                                    rowData={this.state.rowData}
                                    suppressDragLeaveHidesColumns={true}
                                    suppressSizeToFit={true}
                                    suppressColumnMoveAnimation={false}
                                    singleClickEdit={true}
                                    onGridReady={this.onGridReady}
                                    gridOptions={gridOptions}
                                    defaultColDef={this.state.defaultColDef}
                                    onCellValueChanged={this.onCellValueChanged}
                                    onCellFocused={this.onCellFocused}
                                    overlayNoRowsTemplate = {this.state.noRowTemplate}
                                    stopEditingWhenCellsLoseFocus={true}
                                    >
                                    </AgGridReact>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <input type="file" style={{display:'none'}} id="masspassport_file" className="custom-file-input form-control-sm" accept=".xlsx"  onChange={this.uploadExcel} onClick={this.handleFile} />
                    {this.modalError.call(this)}
                </Container>
            </div>
        )
    }
}
function mapStateToProps(state){
    return{
        success:state.array_create_employee_passport.data,
        errors:state.array_create_employee_passport.errors,
        //isLoading:state.array_create_employee_passport.isLoading,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        array_create_employee_passport:(data)=>{
            dispatch(array_create_employee_passport(data))
        }
    }  
}


export default connect(mapStateToProps, mapDispatchToProps)(wizard_employee_passport);

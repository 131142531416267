import * as claimapproval from '../../action/claim/get_claim_approval';

const initialState={
    isLoading:false,
    data:[],
    errors:[],
}

const get_claim_approval = (state=initialState,action)=>{
    switch(action.type){
        case claimapproval.GETCLAIMAPPROVAL_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case claimapproval.GETCLAIMAPPROVAL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case claimapproval.GETCLAIMAPPROVAL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_claim_approval;
import React from 'react';
import '../../../css/employee_profile.css';
import Passport from './EmployeePassport';
import Shg from './EmployeeShg';
import EmployeeAddress from './EmployeeAddress';
import EmployeeDetail from './EmployeeDetail';



export default function EmployeeProfile({ id }){

    return(
        <>
            <EmployeeDetail  />
            <EmployeeAddress id={id} />
            <Passport id={id} />
            <Shg id={id} />
        </>
    )
}
import * as datefilter from '../../action/report/employee_payslip_date_filter';


const initialState={
    isLoading:false,
    data:[],
    errors:[],
    hello:[]
}

const employee_payslip_date_filter = (state=initialState,action)=>{
    switch(action.type){
        case datefilter.EMPPAYSLIPDATEFILTER_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:[]
            }
        case datefilter.EMPPAYSLIPDATEFILTER_SUCCESS:
            return{
                ...state,
                data:action.payload.data,
                isLoading:false,
                hello:action.payload
            }
        case datefilter.EMPPAYSLIPDATEFILTER_FAILURE:
            return{
                ...state,
                errors:action.payload,
                isLoading:false
            }
        case datefilter.EMPPAYSLIPDATEFILTER_RESET:
            return{
                ...state,
                errors:null,
                data:[],
            }
        default:
            return state;
    }
}
export default employee_payslip_date_filter;
import myaxios from '../../axios';

export const MASSASSIGNLEAVESCHEME_REQUEST = 'MASSASSIGNLEAVESCHEME_REQUEST';
export const MASSASSIGNLEAVESCHEME_SUCCESS = 'MASSASSIGNLEAVESCHEME_SUCCESS';
export const MASSASSIGNLEAVESCHEME_FAILURE = 'MASSASSIGNLEAVESCHEME_FAILURE';


export const mass_assign_leave_scheme = (employees,leave_scheme,year)=>{
    return dispatch=>{
        dispatch({
            type:'MASSASSIGNLEAVESCHEME_REQUEST'
        })

        myaxios.post('leave/mass/assign/',{
            employees:employees,
            leave_scheme:leave_scheme,
            year:year
        })
        .then((response)=>{
            dispatch({
                type:'MASSASSIGNLEAVESCHEME_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'MASSASSIGNLEAVESCHEME_FAILURE',
                payload:error
            })
        })
    }
}
import * as createdepartment from '../action/create_department';

const initialState ={
    isLoading:false,
    errors:null,
    data:null
}


const create_department = (state=initialState,action) =>{
    switch(action.type) {
        case createdepartment.CREATEDEPARTMENT_REQUEST:
            return {
                ...state,
                isLoading:true,
                data:null,
                errors:null
            };
        case createdepartment.CREATEDEPARTMENT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
            }
        case createdepartment.CREATEDEPARTMENT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case createdepartment.CREATEDEPARTMENT_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default create_department;
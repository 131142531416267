import * as delcurrency from '../action/delete_general_currency';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const delete_general_currency = (state=initialState,action)=>{
    switch(action.type){
        case delcurrency.DELGENERALCURRENCY_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case delcurrency.DELGENERALCURRENCY_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case delcurrency.DELGENERALCURRENCY_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case delcurrency.DELGENERALCURRENCY_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default delete_general_currency;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { Row,Col } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import ModalEmpUpdateShg from './modal/ModalEmpUpdateShg';
import { get_employee_shg } from '../../../action/employee/get_employee_shg';

export default function EmpShg({ id }){

    const list = useSelector(state=>state.get_employee_shg.data);
    const dispatch = useDispatch();
    const [cdac,setCDAC] = useState(false);
    const [ecf,setECF] = useState(false);
    const [mbmf,setMBMF] = useState(false);
    const [sinda,setSINDA] = useState(false);
    const [has_data,setData] = useState(false);
    const [toggle,setToggle] = useState(false);

    useEffect(()=>{
        if(id !== ''){
            dispatch(get_employee_shg(id));
        }
    },[id,dispatch])

    useEffect(()=>{
        let mounted = true;
        if(mounted){
           
            setData(list ? true : false);
            setCDAC(list?.cdac ?? false);
            setECF(list?.ecf ?? false);
            setMBMF(list?.mbmf ?? false);
            setSINDA(list?.sinda ?? false);
        }
        return()=>{
            mounted = false;
        }
    },[list])

    if(has_data){
        return(
            <>
                <div className="d-flex justify-content-between mt-2 mb-2 pl-2 emp-tabcontent-title">
                    <div className="d-flex align-items-center">
                        <i className="far fa-id-card text-primary"/>
                        <h4 className="text-primary m-0 pl-2">{IMLocalized('emp_shg')}</h4>
                    </div>
                    <div className='d-flex'>
                        <div className="text-primary pt-2 cursor-pointer pr-2" onClick={()=>setToggle(true)}>
                            <i className="fas fa-pen mr-1 function_icon"/>
                            <small>{IMLocalized('edit')}</small>
                        </div>
                    </div>
                </div>
                <Row className="pt-2">
                    <Col lg="6">
                        <Row className="pl-2">
                            <Col xs="5">
                                <h5>CDAC</h5>
                            </Col>
                            <Col xs="7">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" id="profile_cdac1" name='profile_cdac' className="custom-control-input" checked={cdac} disabled={true} />
                                    <label className="custom-control-label" htmlFor="profile_cdac1"></label>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="6">
                        <Row className="pl-2">
                            <Col xs="5">
                                <h5>ECF</h5>
                            </Col>
                            <Col xs="7">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" id="profile_ecf1" name='profile_ecf' className="custom-control-input" checked={ecf} disabled={true} />
                                    <label className="custom-control-label" htmlFor="profile_ecf1"></label>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="6">
                        <Row className="pl-2">
                            <Col xs="5">
                                <h5>MBMF</h5>
                            </Col>
                            <Col xs="7">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" id="profile_mbmf1" name='profile_mbmf' className="custom-control-input" checked={mbmf} disabled={true} />
                                    <label className="custom-control-label" htmlFor="profile_mbmf"></label>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="6">
                        <Row className="pl-2">
                            <Col xs="5">
                                <h5>SINDA</h5>
                            </Col>
                            <Col xs="7">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" id="profile_sinda1" name='profile_sinda' className="custom-control-input" checked={sinda} disabled={true} />
                                    <label className="custom-control-label" htmlFor="profile_sinda1"></label>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <ModalEmpUpdateShg toggle={toggle} closeModal={()=>setToggle(false)} list={list} id={id} />
            </>
        )
    }
    return null;
}
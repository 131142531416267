import * as aggridheader2 from '../../action/report/payroll_variance_get_aggridheader2';


const initialState={
    data:null,
    isLoading:false,
    errors:null
}

const payroll_variance_get_aggridheader2 = (state=initialState,action)=>{
    switch(action.type){
        case aggridheader2.VARIANCEAGGRIDHEADER2_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null
            }
        case aggridheader2.VARIANCEAGGRIDHEADER2_SUCCESS:
            return{
                ...state,
                data:action.payload?.data,
                isLoading:false,
            }
        case aggridheader2.VARIANCEAGGRIDHEADER2_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default payroll_variance_get_aggridheader2;

import React from 'react';
import { Card, CardHeader, Col, Row, Table } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';

function ReferenceTable(props){

    return(
        <Row>
            <Col>
                <Card style={{height:'270px'}}>
                    <CardHeader className="border-0 p-2 bg-white">
                        <h3 className='title-color1 pl-2 pt-2'>
                            {IMLocalized('reference')}
                        </h3>
                    </CardHeader>
                    <Table size="sm" className="align-items-center">
                        <tbody>
                            <tr>
                                <td>{IMLocalized('cpf_rate')} 2022</td>
                                <td align="right" className="py-2"><a href="https://www.cpf.gov.sg/content/dam/web/employer/employer-obligations/documents/CPFContributionRatesTable_1Jan2022.pdf" target="_blank" rel="noreferrer">Open</a></td>
                            </tr>
                        </tbody>
                    </Table>
                </Card>
            </Col>
        </Row>
    )
}
export default ReferenceTable;
import * as updatecontracttemplate from '../action/update_contract_template';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const update_contract_template =  (state= initialState,action)=>{
    switch(action.type){
        case updatecontracttemplate.UPDATECONTRACTTEMPLATE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case updatecontracttemplate.UPDATECONTRACTTEMPLATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updatecontracttemplate.UPDATECONTRACTTEMPLATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case updatecontracttemplate.UPDATECONTRACTTEMPLATE_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default update_contract_template;
import * as employeeimage from '../../action/employee/get_employee_profile_picture';

const initialState={
    isLoading:false,
    data:[],
    errors:[],
    hello:'',
}

const get_employee_profile_picture = (state=initialState,action)=>{
    switch(action.type){
        case employeeimage.GETEMPLOYEEPROFILEPICTURE_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case employeeimage.GETEMPLOYEEPROFILEPICTURE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                hello:action.payload,
                data:action.payload.data
            }
        case employeeimage.GETEMPLOYEEPROFILEPICTURE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case employeeimage.GETEMPLOYEEPROFILEPICTURE_RESET:
            return{
                ...state,
                data:[],
                
            }
        default:
            return state;
    }
}
export default get_employee_profile_picture;
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const DELETEPAYCOMPONENT_REQUEST = 'DELETEPAYCOMPONENT_REQUEST';
export const DELETEPAYCOMPONENT_SUCCESS = 'DELETEPAYCOMPONENT_SUCCESS';
export const DELETEPAYCOMPONENT_FAILURE = 'DELETEPAYCOMPONENT_FAILURE';


export const delete_pay_component =(id,data)=>{
    return dispatch=>{
        dispatch({
            type:'DELETEPAYCOMPONENT_REQUEST'
        });

        myaxios.post(`payroll/payroll/${id}/DeletePayComponent/`,{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'DELETEPAYCOMPONENT_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELETEPAYCOMPONENT_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('delete_failure')
            });
        })
    }
}
import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const CREATERECURRING_REQUEST = 'CREATERECURRING_REQUEST';
export const CREATERECURRING_SUCCESS = 'CREATERECURRING_SUCCESS';
export const CREATERECURRING_FAILURE = 'CREATERECURRING_FAILURE';
export const CREATERECURRING_RESET = 'CREATERECURRING_RESET';

export const create_recurring = (code,name,amount,gross,allowance_type)=>{
    return dispatch=>{
        dispatch({
            type:'CREATERECURRING_REQUEST'
        });

        myaxios.post('company/recurring_allowance/',{
            name:name,
            code:code,
            amount:amount,
            is_included_in_gross:gross,
            allowance_type:allowance_type
        })
        .then((response)=>{
            dispatch({
                type:'CREATERECURRING_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            })
        })
        .catch((error)=>{
            dispatch({
                type:'CREATERECURRING_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_failure')
            });
        })
    }
}

export const reset_create_recurring = () => dispatch =>{
    dispatch({
        type:'CREATERECURRING_RESET'
    });
}
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { checkboxCellRenderer } from "../../func/checkboxCellRenderer";
import { IMLocalized } from "../../language/IMLocalized";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import XLSX from 'xlsx';
import ExcelJs from 'exceljs';
import FileSaver from 'file-saver';
import Swal from 'sweetalert2';
import moment from 'moment';
import { getValue } from '../../func/getValue';
import { getColumnName } from '../../func/getColumnName';
import { popError } from "../../func/popError";
import { useDispatch, useSelector } from "react-redux";
import { employee_child_array_validate, reset_employee_child_array_validate } from "../../action/wizard_employee/employee_child_array_validate";
import { employee_child_array_save, reset_employee_child_array_save } from "../../action/wizard_employee/employee_child_array_save";
import ModalErrorEmployeeChild from "./modal/ModalErrorEmployeeChild";


const gridOptions={
    defaultColDef:{
        suppressMenu:true,
        resizable:true
    }
}

export default function WizardEmployeeChild({ list, language, goBack }){


    const validate_errors = useSelector(state=>state.employee_child_array_validate.errors);
    const validate_success = useSelector(state=>state.employee_child_array_validate.data);
    const create_success = useSelector(state=>state.employee_child_array_save.data);

    const [ rowData, setRowData] = useState([]);
    const [ step, setStep] = useState(0);
    const [ validatelist, setValidateList] = useState([]);
    const [ err_msg, setErrMsg] = useState([]);
    const [ err_toggle, setErrToggle] = useState(false);

    const childRef = useRef();
    const dispatch = useDispatch();

    const onComplete = useCallback(() =>{
        Swal.fire({
            title:IMLocalized('emp_wizard_is_completed'),
            type:'success',
            confirmButtonColor:'green',
            confirmButtonText:IMLocalized('ok!')
        })
        .then((result)=>{
            if(result.hasOwnProperty('value')){
                window.location.href="/#/"
            }
        })
    },[])

    useEffect(()=>{

        if(create_success != null){
            dispatch(reset_employee_child_array_save());
            onComplete();
        }
    },[create_success,dispatch,onComplete])

    useEffect(()=>{
        if(validate_success != null){
            dispatch(employee_child_array_save(validatelist));
            dispatch(reset_employee_child_array_validate());
        }
    },[validate_success,dispatch, validatelist])

    useEffect(()=>{
        if(validate_errors != null){
            const arr = [];
            for( let i =0 ; i< validate_errors.length; i++){
                const arr1 = [];
                const name = rowData[i].name;
                const number = rowData[i].employee_number;
                const item = validate_errors[i];
                if(item !== true){
                    if(item.hasOwnProperty('non_field_errors')){
                        const msg = item['non_field_errors'].toString();
                        arr1.push({ msg});
                        const newRowData = rowData.map((element)=>{
                            const emp_number = element.employee_number;
                            if(number === emp_number){
                                return {...element , err_non:true}
                            }
                            return element;
                        })
                        setRowData(newRowData);
                        gridOptions.api.setRowData(newRowData);
                    }
                    if(item.hasOwnProperty('name')){
                        const msg = `${IMLocalized('name')} : ${item['name'].toString()}`;
                        arr1.push({ msg });
                        const newRowData = rowData.map((element)=>{
                            const emp_number = element.employee_number;
                            if(number === emp_number){
                                return {...element , err_name:true}
                            }
                            return element;
                        })
                        setRowData(newRowData);
                        gridOptions.api.setRowData(newRowData);
                    }
                    if(item.hasOwnProperty('date_of_birth')){
                        const msg = `${IMLocalized('date_of_birth')} :  ${item['date_of_birth'].toString()}`;
                        arr1.push({ msg });
                        const newRowData = rowData.map((element)=>{
                            const emp_number = element.employee_number;
                            if(number === emp_number){
                                return {...element , err_dob:true}
                            }
                            return element;
                        })
                        setRowData(newRowData);
                        gridOptions.api.setRowData(newRowData);
                    }
                    if(item.hasOwnProperty('paternity')){
                        const msg = `${IMLocalized('paternity')} : ${item['paternity'].toString()}`;
                        arr1.push({ msg });
                        const newRowData = rowData.map((element)=>{
                            const emp_number = element.employee_number;
                            if(number === emp_number){
                                return {...element , err_pat:true}
                            }
                            return element;
                        })
                        setRowData(newRowData);
                        gridOptions.api.setRowData(newRowData);
                    }
                    if(item.hasOwnProperty('employee')){
                        const msg = `${IMLocalized('employee')} : ${item['employee'].toString()}`;
                        arr1.push({ msg });
                        const newRowData = rowData.map((element)=>{
                            const emp_number = element.employee_number;
                            if(number === emp_number){
                                return {...element , err_non:true}
                            }
                            return element;
                        })
                        setRowData(newRowData);
                        gridOptions.api.setRowData(newRowData);
                    }
                    if(arr1.length !== 0){
                        arr.push({ number, name, msg:arr1 });
                    }
                }
            }
            setErrToggle(true);
            setErrMsg(arr);
            dispatch(reset_employee_child_array_validate());
        }
    },[validate_errors,dispatch,rowData])

    useEffect(()=>{
        const arr = list.map((item)=>{
            const emp_name = item.employee.name;
            const employee_number = item.employee.employee_number;
            const name = '';
            const date_of_birth = null;
            const leave_start_next_year = 'false';
            const is_sg = 'false';
            const is_adopted = 'false';
            const paternity = 'false';
            const err_pat = false;
            const err_non = false;
            const err_name = false;
            const err_dob =false;
            return {emp_name,employee_number,name,date_of_birth,leave_start_next_year,is_sg,is_adopted,paternity,err_pat,err_non,err_name,err_dob}
        })
        setValidateList([]);
        setRowData(arr);
        setErrMsg([]);
    },[list,step])

    const onCellValueChanged = (params)=>{
        if(params.oldValue !== params.newValue){
            const column = params.column.colDef.field;
            if(column === 'name'){
                params.data.err_name = false;

            }
            else if(column === 'date_of_birth'){
                params.data.err_dob = false;

            }
            else if(column === 'paternity'){
                params.data.err_pat = false;
            }

            params.data.err_non = false;
            params.api.redrawRows({
                force:true,
                columns:[column],
                rowNodes:[params.node]
            })
        }
    }

    const handleFile = (e) =>{
        const { target = {} } = e || {};
        target.value = '';
    } 

    const uploadExcel=(e)=>{
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload=(e)=>{
            const bstr = e.target.result;
            const wb = XLSX.read(bstr,{ type:'binary'});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const wsname3 = wb.SheetNames[2];
            const ws3 = wb.Sheets[wsname3];

            const columns = {
                'A':'employee_number',
                'B':'emp_name',
                'C':'name',
                'D':'date_of_birth',
                'E':'leave_start_next_year',
                'F':'is_sg',
                'G':'is_adopted',
                'H':'paternity'
            }
            let rowData1=[];
            let rowIndex =2;

            const idd = ws3?.['A1']?.w ?? '';
            const lang = ws3?.['A2']?.w ?? '';
           
            if(idd === 'WizardEmployeeChild' && lang === language){
                while(ws['A'+rowIndex]){
                    let row = {};
                    for(let i =0;i<Object.keys(columns).length;i++){
                        const column = Object.keys(columns)[i];
                        if(ws[column+rowIndex] !== undefined){
                            row[columns[column]] = ws[column+rowIndex].w;
                        }
                        else{
                            row[columns[column]] = null;
                        }
                    }
                    rowData1.push(row);
                    rowIndex++;
                }
                if(rowData1.length !== 0){
                    const detail = rowData1.map((item)=>{
                        
                        let leave_start_next_year,is_sg,is_adopted,paternity;
                        if(language === 'zh'){
                            leave_start_next_year = item.leave_start_next_year === '是' ? 'true' : 'false';
                            is_sg = item.is_sg === '是' ? 'true' : 'false';
                            is_adopted = item.is_adopted === '是' ? 'true' : 'false';
                            paternity = item.paternity === '是' ? 'true' : 'false';
                        } 
                        else{
                            leave_start_next_year = item.leave_start_next_year === 'Yes' ? 'true' : 'false';
                            is_sg = item.is_sg === 'Yes' ? 'true' : 'false';
                            is_adopted = item.is_adopted === 'Yes' ? 'true' : 'false';
                            paternity = item.paternity === 'Yes' ? 'true' : 'false';
                        }
                        
                        return {...item,paternity,leave_start_next_year,is_sg,is_adopted}
                    })
                    setRowData(detail);
                }
                else{
                    popError(IMLocalized('no_data'))
                }
            }
            else{
                popError(IMLocalized('wrong_file_selected'), lang !== language ? IMLocalized('please_choose_correct_language') : null);
            }
        }
        reader.readAsBinaryString(file);
    }

    const getTemplate = () =>{
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('employee_child');
        const ws2 = wb.addWorksheet('setting');
        const ws3 = wb.addWorksheet('id');
        ws3.getCell('A1').value = 'WizardEmployeeChild';
        ws3.getCell('A2').value = language;
        ws.columns =[
            {header:IMLocalized('agrid_emp_id'),key:'employee_number'},
            {header:IMLocalized('agrid_emp_name'),key:'emp_name'},
            {header:IMLocalized('agrid_child_name'),key:'name'},
            {header:IMLocalized('agrid_date_of_birth'),key:'date_of_birth',style: { numFmt: 'dd/mm/yyyy' }},
            {header:IMLocalized('agrid_leave_start_next_year'),key:'leave_start_next_year'},
            {header:IMLocalized('agrid_is_sg'),key:'is_sg'},
            {header:IMLocalized('agrid_is_adopted'),key:'is_adopted'},
            {header:IMLocalized('agrid_paternity'),key:'paternity'}
        ]

        for (let i=2;i<=199;i++){
            ws.getCell('D'+i).numFmt = 'd/M/yy';
        }

        const selection = [{data:IMLocalized('excel_true')},{data:IMLocalized('excel_false')}];
        for(let i=0;i<selection.length;i++){
            let y = i+1;
            ws2.getCell('A'+y).value = selection[i].data;
        }

        for(let i=2;i<2001;i++){
            ws.getCell('E'+i).dataValidation={
                type:'list',
                allowBlank:false,
                error:IMLocalized('excel_valid_in_list'),
                errorStyle:'error',
                errorTitle:'Error',
                showErrorMessage:true,
                formulae:['=setting!$A$1:$A$'+selection.length]
            }

            ws.getCell('F'+i).dataValidation={
                type:'list',
                allowBlank:false,
                error:IMLocalized('excel_valid_in_list'),
                errorStyle:'error',
                errorTitle:'Error',
                showErrorMessage:true,
                formulae:['=setting!$A$1:$A$'+selection.length]
            }

            ws.getCell('G'+i).dataValidation={
                type:'list',
                allowBlank:false,
                error:IMLocalized('excel_valid_in_list'),
                errorStyle:'error',
                errorTitle:'Error',
                showErrorMessage:true,
                formulae:['=setting!$A$1:$A$'+selection.length]
            }

            ws.getCell('H'+i).dataValidation={
                type:'list',
                allowBlank:false,
                error:IMLocalized('excel_valid_in_list'),
                errorStyle:'error',
                errorTitle:'Error',
                showErrorMessage:true,
                formulae:['=setting!$A$1:$A$'+selection.length]
            }
        }

        if(rowData.length !== 0 ){
            for(let i=0; i< ws.columns.length; i++){
                const field = ws.columns[i].key;
                for( let x =0; x<rowData.length ; x++){
                    const step = 2 + x ;
                    const data = getValue(rowData[x],field);
                    if(field === 'is_sg' || field === 'is_adopted' || field === 'leave_start_next_year' || field === 'paternity'){
                        ws.getCell(getColumnName(i)+step).value = null;
                    }
                    else{
                        ws.getCell(getColumnName(i)+step).value = data;
                    }
                }
            }
            ws.columns.forEach(column =>{
                column.width = column.header.length < 15 ? 15 : column.header.length
            })

            wb.xlsx.writeBuffer().then((buf)=>{
                const file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                FileSaver.saveAs(file, 'Employee_Child_List.xlsx')
            });
        }
    }

    const validateChild=()=>{
        const arr = [];
        childRef.current.forEachNode(function(rowNode,index){
            const {data} = rowNode;
            const {employee_number,name, emp_name,leave_start_next_year,is_adopted,is_sg,paternity} = data;
            const emp_detail = list.find(item=>item.employee.employee_number === employee_number);
            const employee = emp_detail?.employee?.id;

            const date = data.date_of_birth;
            const date_of_birth = date != null && date !== '' ? moment(date,'DD/MM/YYYY').format('YYYY-MM-DD') : null;

            const sg_maternity = null;
            
            if(name !== '' && name != null){
                arr.push({paternity,employee_number,emp_name,name,employee,date_of_birth,leave_start_next_year,is_sg,is_adopted,sg_maternity});
            }
        })
        if(arr.length !== 0){
            dispatch(employee_child_array_validate(arr));
            setValidateList(arr);
        }
        else{
            popError(IMLocalized('no_data_updated'));
        }
    }

    

    const skipStep=()=>{
        Swal.fire({
            title:IMLocalized('skip_emp_child'),
            type:'warning',
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!')
        })
        .then((result)=>{
            if(result.value){
                onComplete();
            }
        })
    }

    return(
        <>
        <Container fluid>
            <Row className="mt-2">
                <Col>
                    <div className="d-flex">
                        <div className="mr-2">
                            <button className="btn btn-primary btn-sm" onClick={goBack}> <i className="fas fa-chevron-left"></i> {IMLocalized('back_to_main_page')}</button>
                            <button className="btn btn-primary btn-sm" onClick={skipStep}> {IMLocalized('skip')}</button>
                            {rowData.length !== 0 && 
                            <button className="btn btn-primary btn-sm" onClick={getTemplate}>{IMLocalized('get_emp_child_template')}</button>}
                        </div>
                        <div className="ml-2 mr-2">
                            <input type="file" id="ImportChildcustomFile" className="custom-file-input form-control-sm d-none" accept=".xlsx" onClick={handleFile} onChange={uploadExcel}  />
                            <label className="btn btn-primary btn-sm" htmlFor="ImportChildcustomFile">{IMLocalized('import_from_file')}</label>
                        </div>
                        <div>
                            <button className="btn btn-primary btn-sm" onClick={()=>setStep(step+1) }>{IMLocalized('clear')}</button>
                            {rowData.length !== 0 &&
                            <button onClick={validateChild} className="btn btn-primary btn-sm">{IMLocalized('create')}</button>}
                            {err_msg.length !== 0 && 
                            <button className="btn btn-danger btn-sm" onClick={()=>setErrToggle(true)}>{IMLocalized('error')}</button>}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <div className="ag-theme-balham" style={{height:'calc(100vh - 200px)', width:'100%' }}>
                        <AgGridReact
                        gridOptions={gridOptions}
                        rowData={rowData}
                        suppressDragLeaveHidesColumns={true}
                        suppressSizeToFit={true}
                        suppressColumnMoveAnimation={false}
                        singleClickEdit={true}
                        stopEditingWhenCellsLoseFocus={true}
                        rowClassRules={{
                            'sick-days-warning': function (params) {
                                var numSickDays = params.data.err_non;
                                return numSickDays ===true
                                },
                        
                        }}
                        onCellValueChanged={onCellValueChanged}
                        onGridReady={(params)=>{
                            childRef.current = params.api; 
                        }}
                        overlayNoRowsTemplate={IMLocalized('no_data')}
                        columnDefs={[
                            {
                                headerName:IMLocalized('agrid_emp_id'),
                                field:'employee_number',
                                editable:false,
                                filter:false,
                                width:150
                            },
                            {
                                headerName:IMLocalized('agrid_emp_name'),
                                field:'emp_name',
                                editable:false,
                                filter:false,
                                width:150
                            },
                            {
                                headerName:IMLocalized('agrid_child_name'),
                                field:'name',
                                editable:true,
                                filter:false,
                                width:150,
                                cellClassRules:{
                                    'orange-bg':(params)=>{return params.data.err_name}
                                },
                            },
                            {
                                headerName:IMLocalized('agrid_date_of_birth'),
                                field:'date_of_birth',
                                editable:true,
                                filter:false,
                                width:150,
                                cellClassRules:{
                                    'orange-bg':(params)=>{return params.data.err_dob}
                                },
                            },
                            {
                                headerName:IMLocalized('agrid_leave_start_next_year'),
                                field:'leave_start_next_year',
                                editable:false,
                                width:150,
                                filter:false,
                                cellRenderer:checkboxCellRenderer,
                            },
                            {
                                headerName:IMLocalized('agrid_is_sg'),
                                field:'is_sg',
                                editable:false,
                                width:150,
                                filter:false,
                                cellRenderer:checkboxCellRenderer,
                            },
                            {
                                headerName:IMLocalized('agrid_is_adopted'),
                                field:'is_adopted',
                                editable:false,
                                width:150,
                                filter:false,
                                cellRenderer:checkboxCellRenderer,
                            },
                            {
                                headerName:IMLocalized('agrid_paternity'),
                                field:'paternity',
                                editable:false,
                                width:150,
                                filter:false,
                                cellRenderer:checkboxCellRenderer,
                                cellClassRules:{
                                    'orange-bg':(params)=>{return params.data.err_pat}
                                },
                            }
                        ]}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
        <ModalErrorEmployeeChild toggle={err_toggle} list={err_msg} closeModal={()=>setErrToggle(false)} />
        </>
    )
}
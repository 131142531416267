import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';

export const WIZARDVALIDATEJOBTITLE_REQUEST ='WIZARDVALIDATEJOBTITLE_REQUEST';
export const WIZARDVALIDATEJOBTITLE_SUCCESS ='WIZARDVALIDATEJOBTITLE_SUCCESS';
export const WIZARDVALIDATEJOBTITLE_FAILURE ='WIZARDVALIDATEJOBTITLE_FAILURE';

export const wizard_validate_jobtitle = (data)=>{
    return dispatch=>{
        dispatch({
            type:'WIZARDVALIDATEJOBTITLE_REQUEST'
        });

        myaxios.post('company/jobtitle/array_validate/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'WIZARDVALIDATEJOBTITLE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'WIZARDVALIDATEJOBTITLE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}
import myaxios from '../../axios';

export const GETSELFLEAVEOPTION_REQUEST = 'GETSELFLEAVEOPTION_REQUEST';
export const GETSELFLEAVEOPTION_SUCCESS = 'GETSELFLEAVEOPTION_SUCCESS';
export const GETSELFLEAVEOPTION_FAILURE = 'GETSELFLEAVEOPTION_FAILURE';
export const GETSELFLEAVEOPTION_RESET = 'GETSELFLEAVEOPTION_RESET';

export const get_self_available_leave_options= (employee,leave_setting,from_date,to_date)=>{
    return dispatch=>{
        dispatch({
            type:'GETSELFLEAVEOPTION_REQUEST'
        });

        myaxios.post(`leave/self/apply/get_leave_options/`,{
            employee:employee,
            leave_setting:leave_setting,
            from_date:from_date,
            to_date:to_date
        })
        .then((response)=>{
            dispatch({
                type:'GETSELFLEAVEOPTION_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETSELFLEAVEOPTION_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_get_self_available_leave_options = () => dispatch =>{
    dispatch({
        type:'GETSELFLEAVEOPTION_RESET'
    });
}

import * as wizardcreatedepartment from '../../action/wizard/wizard_create_department';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const wizard_create_department = (state=initialState,action)=>{
    switch(action.type){
        case wizardcreatedepartment.WIZARDCREATEDEPARTMENT_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case wizardcreatedepartment.WIZARDCREATEDEPARTMENT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case wizardcreatedepartment.WIZARDCREATEDEPARTMENT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default wizard_create_department;
import React from "react";



export default function LeaveCalendarTypeCheckbox({ id, index, name, check, changeTypeCheckbox}){


    return(
        <div className="custom-control custom-checkbox ml-2" key={id}>
            <input type="checkbox" id={'calendarfiltertype'+index} className="custom-control-input" checked={check} onChange={()=>changeTypeCheckbox(index)} />
            <label className="custom-control-label" htmlFor={'calendarfiltertype' +index}>{name}</label>
        </div>
    )
}
import myaxios from '../../axios';

export const USERSELF_REQUEST='USERSELF_REQUEST';
export const USERSELF_SUCCESS='USERSELF_SUCCESS';
export const USERSELF_FAILURE='USERSELF_FAILURE';

export const user_self = () =>{
    return dispatch=>{
        dispatch({
            type:'USERSELF_REQUEST'
        });

        myaxios.get('employee/self/')
        .then((response)=>{
            dispatch({
                type:'USERSELF_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'USERSELF_FAILURE',
                payload:error
            })
        })
    }
}
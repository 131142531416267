import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import { create_new_payroll } from '../../../action/payroll/create_new_payroll';

const mapStateToProps=(state)=>({
    currencylist:state.general_currency.data,
    isLoading:state.create_new_payroll.isLoading,
})

const mapDispatchToProps=(dispatch)=>({
    create_new_payroll:(date,rates,type)=>{
        dispatch(create_new_payroll(date,rates,type))
    },
})



function ModalRate(props){
    const [list,setList] = useState([]);

    useEffect(()=>{
        if(props.toggle && props.currencylist.length !== 0){
            const arr = props.currencylist.map((item,index)=>{
                const name = item.currency_name;
                const amount = 0;
                return {name,amount};
            })

            setList(arr);
        }
        else{
            setList([]);
        }
    },[props.currencylist,props.toggle])

    const validateInput=(e,i)=>{
        const target = e.target;
        const value = target.value;
        if(value === ''){
            const list1 = [...list];
            list1[i].amount = 0;
            setList(list1);
        }
    }

    const handleInput=(e,index)=>{
        const target = e.target;
        const value = target.value;
        const list1 = [...list];
        list1[index].amount = value;
        setList(list1);
    }
    const onCreate=()=>{
        let rates = null;
        if(list.length !== 0){
            let arr = {};
            list.forEach((item)=>{
                const name = item.name;
                const amount = item.amount;
                arr[name] = amount;
            })

            rates = arr;
        }
        props.create_new_payroll(props.item,rates,props.type);
    }

    return(
        <Modal isOpen={props.toggle} size="lg">
            <ModalHeader toggle={props.isLoading ? null:props.closeModal}>{IMLocalized('rates')}</ModalHeader>
            <ModalBody>
                {list.map((item,index)=>{
                    const name = item.name;
                    const amount = item.amount;
                    return(
                        <Row className="pt-2" key={index}>
                            <Col className="d-flex">
                                <FormGroup className="mb-0" style={{width:'180px'}}>
                                    <InputGroup size="sm">
                                        <Input defaultValue='1' type="number" readOnly={true} />
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText>SGD</InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                                <div className='d-flex align-items-center ml-2 mr-2'>
                                    <small>{IMLocalized('to')}</small>
                                </div>
                                <FormGroup className="mb-0" style={{width:'180px'}}>
                                    <InputGroup size="sm">
                                        <Input value={amount} type="number" min="0" onChange={(e)=>handleInput(e,index)} onBlur={(e)=>validateInput(e,index)} />
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText>{name}</InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                    )
                })}
            </ModalBody>
            <ModalFooter>
                {props.isLoading ?
                <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button onClick={onCreate} className="btn btn-primary btn-sm">{IMLocalized('create')}</button>
                    <button onClick={props.closeModal} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalRate)
import React,{Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {Calendar} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interaction from '@fullcalendar/interaction';
import Swal from 'sweetalert2';
import moment from 'moment';
import {manager_leave_list} from '../../action/leave/manager_leave_list';
import {get_approvals_leave_list} from '../../action/approvals/get_approvals_leave_list';
import { Container, Row,DropdownItem,Dropdown,DropdownToggle,DropdownMenu, Col,Button,Card,CardHeader,Modal,ModalHeader,ModalBody,ModalFooter,Table } from 'reactstrap';
import {calendar_leave_manager_approve} from '../../action/approvals/calendar_leave_manager_approve';
import {get_general_dashboard} from '../../action/dashboard/get_general_dashboard';
import {get_apply_leave_list} from '../../action/get_apply_leave_list';
import {calendar_approvals_get_leave_picture} from '../../action/approvals/calendar_approvals_get_leave_picture';
import {get_all_employee_available_leave} from '../../action/employee/get_all_employee_available_leave';
import Viewer from 'react-viewer';
import {get_calendar_approvals_leave_detail} from '../../action/approvals/get_calendar_approvals_leave_detail';
import {IMLocalized} from '../../language/IMLocalized';
import OverlayLoading from '../loading_component/overlay_loading';
import {AgGridReact} from 'ag-grid-react';
import { Buffer } from 'buffer';
import 'ag-grid-enterprise';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css'; 
import '../../css/aggrid.css';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';
let calendar;

const gridOptions={
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
    suppressRowClickSelection:true,
}

class leave_approval_list extends Component{

    constructor(props){
        super(props);
        this.state={
            events:[],
            holidaylist:[],
            holiday_arr:[],
            holiday_length:0,
            isLoading:false,
            detail_toggle:false,
            detail_id:'',
            detail_list:null,
            detail_img:[],
            imglist:[],
            imgtoggle:false,
            activeImg:0,
            leave_checkbox:[],
            type_toggle:false,
            type_all:true,
            status_toggle:false,
            status_option:'all',
            defaultlist:[],
            color_approve:'#ffffff',
            color_pending:'#ffffff',
            color_reject:'#ffffff',
            color_cancel:'#ffffff',
            color_consumed:'#ffffff',
            approval_leaves:[],
            approval_id:'',
            has_action:false,
            count1:0,
            rowData:[],
            columnDefs:[],
            defaultColDef:{
                resizable:true,
                sortable:true,
                filter:true,
            },
            noRowTemplate:IMLocalized('no_data')
        }
        this.statusRenderer = this.statusRenderer.bind(this)
        this.dateRenderer = this.dateRenderer.bind(this)
        this.isFirstColumn = this.isFirstColumn.bind(this)
    }

    componentDidMount(){

        
        Promise.resolve(this.getEvent())
        .then(this.getPendingList())
        .then(this.getColorDetail())
        .then(this.createCalendar())
        .then(this.setColumnDef());
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.list1 !== this.props.list1 || prevState.color_approve !==this.state.color_approve || prevState.color_pending !== this.state.color_pending || prevState.color_cancel !== this.state.color_cancel || prevState.color_consumed !== this.state.color_consumed || prevState.color_reject !== this.state.color_reject || prevState.count1 !== this.state.count1){
            Promise.resolve(this.getEvent())
            .then(this.getPendingList())
            .then(this.setColumnDef());
        }
        if(prevProps.holidaylist !== this.props.holidaylist){
            let arr = [];
            let data = this.props.holidaylist;
            let sort = data.sort((a,b)=> new Date(a.date) - new Date(b.date));
            if(data.length !== 0){
                for(let i = 0;i<data.length;i++){
                    const date =data[i].date;
                    arr.push(date);
                }
                this.setState({holiday_length:data.length});
                this.setState({holiday_arr:arr});
            }
            this.setState({holidaylist:sort});
        }
        if(prevState.events !== this.state.events || this.context.leave_approvals_calendar_refresh === true ){
            Promise.resolve(calendar.destroy())
            .then(this.createCalendar(this.state.holiday_arr,this.state.holiday_length));
            this.context.setLeaveApprovalsCalendarRefresh(false);

        }
        if(prevProps.approve_success !== this.props.approve_success){
            Promise.resolve(this.props.get_general_dashboard()).then(this.props.manager_leave_list()).then(this.props.get_approvals_leave_list()).then(this.props.get_apply_leave_list()).then(this.props.get_all_employee_available_leave())
            this.closeModal();
        }
        if(prevProps.detail !== this.props.detail){
            const {detail} = this.props;
            if(detail !== undefined && detail.length !== 0){
                const data = detail.data;
                const leavepic = data.leavepic;
                const id = data.id;
                this.setState({detail_id:id});
                this.setState({detail_list:data});
                this.setState({detail_toggle:true});
                this.setState({detail_img:leavepic});
                if(leavepic.length !== 0){
                    for(let i=0;i<leavepic.length;i++){
                        const id = leavepic[i].id;
                        this.props.calendar_approvals_get_leave_picture(id);
                    }
                }
            }
            else{
                this.setState({detail_list:null})
                this.setState({detail_toggle:false})
            }
        }
        if(prevProps.detail_errors !== this.props.detail_errors){
            const {detail_errors} = this.props;
            if(detail_errors !== undefined && detail_errors.length !== 0){
                const data = detail_errors.response.data;
                if('non_field_errors' in data){
                    const word = data['non_field_errors'].toString();
                    this.popError(word)
                }
            }
        }
        if(prevProps.picture !== this.props.picture){
            let imglist = this.state.imglist;
            let data = this.props.picture;
            let img = new Buffer.from(data,'binary').toString('base64');
            let src = 'data:image/png;base64,'+img;
            imglist.push({src});
            this.setState({imglist});
        }
        if(prevProps.leavelist !== this.props.leavelist){
            this.getLeaveCheckbox();
        }
        if(prevState.defaultlist !== this.state.defaultlist || prevState.type_all !== this.state.type_all || prevState.leave_checkbox !== this.state.leave_checkbox || prevState.status_option !== this.state.status_option){
            this.filterData()
        }
        if(prevProps.colorlist !== this.props.colorlist){
            this.getColorDetail();
        }
        if(prevState.detail_id !== this.state.detail_id || prevState.approval_leaves !== this.state.approval_leaves){
            const {detail_id,approval_leaves} = this.state; 
            if(detail_id !== '' && approval_leaves.length !== 0){
                const inside = this.existID(detail_id,approval_leaves);
                this.setState({has_action:inside});
                if(inside === true){
                    const detail = approval_leaves.filter(function(item){
                        return item.leave_id === detail_id
                    })
                    if(detail.length !== 0){
                        this.setState({approval_id:detail[0].id});
                    }
                }
            }
            else{
                this.setState({has_action:false});
                this.setState({approval_id:''});
            }
        }
        if(this.context.leave_approvals_calendar_reset === true){
            this.setState({
                events:[],
                holidaylist:[],
                holiday_arr:[],
                holoday_length:0,
                isLoading:false,
                type_toggle:false,
                type_all:true,
                status_toggle:false,
                status_option:'all',
                defaultlist:[],
                approval_leaves:[],
                approval_id:'',
                rowData:[],
                columnDefs:[],
                defaultColDef:{
                    resizable:true,
                    sortable:true,
                    filter:true,
                },
                noRowTemplate:IMLocalized('no_data')
            });
            this.context.setLeaveApprovalsCalendarReset(false);
        }
    }

    setColumnDef=()=>{
        const columnDefs = [
            {
                headerName:IMLocalized('select'),
                pinned:'left',
                field:'select',
                width:150,
                headerCheckboxSelection: this.isFirstColumn,
                checkboxSelection: this.isFirstColumn,
                headerCheckboxSelectionFilteredOnly:true,
                lockPosition: true
            },
            {
                headerName:IMLocalized('leave_date'),
                field:'leave_date',
                filter:false,
                width:250,
                comparator:(valueA,valueB,nodeA,nodeB)=>{
                    const date1 = new Date(nodeA.data.from_date);
                    const date2 = new Date(nodeB.data.from_date);
                    return date2 - date1
                }

            },
            {
                headerName:IMLocalized('employee_number'),
                field:'leave.employee.employee_number'
            },
            {
                headerName:IMLocalized('employee_name'),
                field:'leave.employee.name'
            },
            {
                headerName:IMLocalized('leave_type'),
                field:'leave.leave_setting.name'
            },
            {
                headerName:IMLocalized('status'),
                field:'leave.status',
                cellRendererFramework:this.statusRenderer
            },
            {
                headerName:IMLocalized('leave_amount'),
                field:'leave.amount'
            },
            {
                headerName:IMLocalized('created_date'),
                field:'leave.created_date',
                cellRendererFramework:this.dateRenderer,
            }
        ]
        this.setState({columnDefs});
    }

    onCellFocused=(params)=>{
        if(params !== null){
            if(params.column !== null){
                if(params.column.colDef !== null){
                    if(params.column.colDef.field !== 'select'){
                        const focusedCell = gridOptions.api.getFocusedCell();
                        
                        const cellValue = gridOptions.api.getDisplayedRowAtIndex(focusedCell.rowIndex)
                        
                        const id = cellValue.data.leave.from_date;
                        // console.log(cellValue)
                        calendar.gotoDate(id);
                        calendar.changeView('dayGridWeek');
                        this.setState({
                            currentDate:calendar.view.title
                        })
                        this.refs['calendarview'].scrollIntoView({block:'start',behavior:'smooth'});
                    }
                }   
            }
        }
    }

    getColorDetail=()=>{
        const{colorlist} = this.props;
            if(colorlist !== undefined && colorlist.length !== 0){
                const data =colorlist.data;
                const color_pending = data.PENDING;
                const color_approve = data.APPROVED;
                const color_reject = data.REJECTED;
                const color_cancel = data.CANCELLED;
                const color_consumed = data.CONSUMED;
                this.setState({color_approve});
                this.setState({color_consumed});
                this.setState({color_reject});
                this.setState({color_cancel});
                this.setState({color_pending});
            }
    }

    statusRenderer=function(params){
        const {color_approve,color_cancel,color_consumed,color_pending,color_reject} = this.state;
        const status = params.value;
        let selected_color = '#fff';

        if(status === 'PENDING'){
            selected_color = color_pending;
        }
        else if(status === 'APPROVED'){
            selected_color = color_approve;
        }
        else if(status === 'REJECTED'){
            selected_color = color_reject;
        }
        else if(status === 'CANCELLED'){
            selected_color = color_cancel
        }
        else if(status === 'CONSUMED'){
            selected_color = color_consumed;
        }
        return <div><span className="mr-2" style={{color:selected_color}}>●</span> {IMLocalized(status)}</div>
    }

    dateRenderer=function(params){
        const date = params.value;
        const date2 = new Date(params.value);
        let that_date = null;
        if(date !== null){
            let month = date2.getMonth()+1;
            if(month<10){
                month = '0'+month;
            }
            let day = date2.getDate();
            if(day<10){
                day = '0'+day;
            }
            let min = date2.getMinutes();
            if(min<10){
                min = '0'+min;
            }
            let sec = date2.getSeconds();
            if(sec<10){
                sec = '0'+sec;
            }
            let hour = date2.getHours();
            if(hour <10){
                hour = '0'+hour;
            }
            that_date = day+'/'+month+'/'+date2.getFullYear() + ' ' + hour +':'+min +":"+sec;
        
           
        }
        return that_date 
    }

    isFirstColumn(params) {
        if(params !== null){
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }
        
    }

    popError=(msg)=>{
        Swal.fire({
            title:msg,
            type:'warning',
            confirmButtonColor:'#d33',
            confirmButtonText:IMLocalized('ok!')
        })
    }

    existID(id,arr){
        return arr.some(function(el){
            return el.leave_id === id
        })
    }

    filterData=()=>{
        const {leave_checkbox,defaultlist,status_option } = this.state;
        let list = defaultlist;

        let leavedata = leave_checkbox.filter(function(item){
            return item.check === true
        })
        
       
        let arr = [];
        let detail = [];
        if(leavedata.length !== 0){
            for(let i=0;i<leavedata.length ;i++){
                const id = leavedata[i].id;
                const data = list.filter(function(item){
                    return item.leave_setting.id === id
                })
                arr.push(data);
            }
           
            list = arr.flat();
            if(status_option === 'reject'){
                detail = list.filter(function(item){
                    return item.status === 'REJECTED'
                })
            }
            else if(status_option === 'pending'){
                detail = list.filter(function(item){
                    return item.status === 'PENDING'
                })
            }
            else if(status_option === 'approve'){
                detail = list.filter(function(item){
                    return item.status === 'APPROVED'
                })
            }
            else if(status_option === 'cancel'){
                detail = list.filter(function(item){
                    return item.status === 'CANCELLED'
                })
            }
            else if(status_option === 'consumed'){
                detail = list.filter(function(item){
                    return item.status === 'CONSUMED'
                })
            }
            else{
                detail = list;
            }
            this.setState({events:detail});
        }
        else{
            this.setState({events:[]});
        }
    }

    getLeaveCheckbox=()=>{
        const {leavelist} = this.props;
        let arr = [];
        if(leavelist.length !== 0){
            for(let i=0;i<leavelist.length;i++){
                const name = leavelist[i].name;
                const id = leavelist[i].id;
                const check = true;
                arr.push({name,id,check});
            }
            
        }
        this.setState({leave_checkbox:arr});
    }

    toHome=(e)=>{
        e.preventDefault();
        this.context.addItem('1','dashboard');
    }

    open=(index)=>{
        this.setState({imgtoggle:true});
        this.setState({activeImg:index});
    }

    close=()=>{
        this.setState({imgtoggle:false});
    }

    closeModal=()=>{
        this.setState({detail_toggle:false});
        this.setState({detail_id:''});
        this.setState({detail_status:''});
        this.setState({detail_images:[]});
        this.setState({detail_leave_name:''});
        this.setState({detail_date_name:''});
        this.setState({detail_emp_name:''});
        this.setState({detail_emp_number:''});
        this.setState({detail_status_name:''});
        this.setState({detail_remarks:''});
        this.setState({imglist:[]});
        this.setState({approval_id:''});
        this.setState({has_action:false});
    }

    handleAll=(e)=>{
        const target = e.target;
        const checked = target.checked;
        const name = target.name;
        this.setState({
            [name]:checked
        });
        if(name === 'allpending'){
            let pendingcheckbox = this.state.pendingcheckbox;
            for(let i=0;i<pendingcheckbox.length ;i++){
                pendingcheckbox[i].check = checked
            }
            this.setState({pendingcheckbox});
        }
    }

    handleCheckbox=(e,id)=>{
        const target = e.target;
        const name = target.name;
        const checked = target.checked;
        if(name === 'pending'){
            let pendingcheckbox = this.state.pendingcheckbox;
            const new_pendingcheckbox = pendingcheckbox.map(item=>
                item.id === id
                ?{...item,check:checked}
                :item
            )
            this.setState({pendingcheckbox:new_pendingcheckbox});

            let count = 0;
            for(let i=0;i<new_pendingcheckbox.length ;i++){
                const check  = new_pendingcheckbox[i].check;
                if(check === false){
                    count= count +1;
                }
            }
            if(count === 0){
                this.setState({allpending:true});
            }
            else{
                this.setState({allpending:false});
            }
        }
    }

    getDateFormat=(date)=>{
        const date1 = moment(date,'YYYY-MM-DD');
        const new_date = date1.format('DD/MM/YYYY');
        return new_date;
    }

    toDisplay=(id)=>{
        
        calendar.gotoDate(id);
        calendar.changeView('dayGridWeek');
        this.setState({
            currentDate:calendar.view.title
        })
        this.refs['calendarview'].scrollIntoView({block:'start',behavior:'smooth'});
    }

    singleApprove=()=>{
        const {approval_id} = this.state;
        Swal.fire({
            title:IMLocalized('approving_leave'),
            input:'textarea',
            text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!')
        })
        .then(result=>{
            if('value' in result){
                let comment = result.value;
                this.props.calendar_leave_manager_approve([approval_id],true,comment);
            }
        })
    }

    singleReject=()=>{
        const {approval_id} = this.state;
        Swal.fire({
            title:IMLocalized('rejecting_leave'),
            input:'textarea',
            text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!')
        })
        .then(result=>{
            if('value' in result){
                let comment = result.value;
                this.props.calendar_leave_manager_approve([approval_id],false,comment);
            }
        })
    }

    pendingReject=(boolean)=>{
        const data = gridOptions.api.getSelectedRows();
        if(data.length !== 0){
            let arr = [];
            for(let i=0;i<data.length;i++){
                const id = data[i].id;
                arr.push(id);
            }

            let title1 = '';
            if(boolean === false){
                title1 = IMLocalized('rejecting_leave')
            }
            else{
                title1 = IMLocalized('approving_leave')
            }

            Swal.fire({
                title:title1,
                input:'textarea',
                text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
                showCancelButton:true,
                cancelButtonText:IMLocalized('no!'),
                confirmButtonText:IMLocalized('yes!')
            })
            .then(result=>{
                if('value' in result){
                    let comment = result.value;
                    this.props.calendar_leave_manager_approve(arr,boolean,comment);
                }
            })
        }
        else{
            let tt = IMLocalized('select_leave_to_reject');
            if(boolean === true){
                tt = IMLocalized('select_leave_to_approve');
            }
            Swal.fire({
                title:tt,
                type:'warning',
                showConfirmButton:true,
                confirmButtonText:IMLocalized('ok!')
            })
        }
        
    }


    getPendingList=()=>{
        const {list} = this.props;
        const approvals = list.approvals;
        if(approvals){

            if(approvals.length !== 0){
                const detail = approvals.filter(function(item){
                    return item.leave.status === 'PENDING'
                })

                let arr = [];
                if(detail.length !== 0){
                    for(let i=0;i<detail.length;i++){
                        const data = detail[0];
                        const from_date1 = data.leave.from_date;
                        const to_date1 = data.leave.to_date;
                        const from_date = this.getDateFormat(data.leave.from_date);
                        const to_date = this.getDateFormat(data.leave.to_date);
                        let date = '';
                        if(from_date1 === to_date1){
                            date = from_date;
                        }
                        else{
                            date = from_date + ' - ' + to_date;
                        }

                        data.leave_date = date;
                        arr.push(data);
                    }
                    arr = arr.sort((a,b)=> new Date(b.created_date).getTime() - new Date(a.created_date).getTime())
                }
                this.setState({rowData:arr});
                //this.setState({pendinglist:approvals}
            }
        }
    }

    getEvent=()=>{
        const {list} = this.props;
        const leaves = list.leaves;
        const approval_leaves = list.approval_leaves;
        this.setState({approval_leaves});
        if(list.length !== 0){
            if(leaves.length !== 0){
                const detail = leaves.map((item)=>{
                    const id = item.id;
                    const status = item.status;
                    const leave_setting = item.leave_setting;
                    const start_date = item.from_date;
                    const employee = item.employee;
                    const employee_name = employee.name;
    
                    let backgroundColor = leave_setting.color;
                    const leave_name = leave_setting.name;
                    
                    if(status === 'PENDING'){
                        // backgroundColor = pendingcolor;
                        backgroundColor = this.state.color_pending;
                    }
                    else if (status ==='APPROVED'){
                        backgroundColor = this.state.color_approve;
                    }
                    else if(status === 'REJECTED'){
                        backgroundColor = this.state.color_reject;
                    }
                    else if(status=== 'CANCELLED'){
                        backgroundColor = this.state.color_cancel;
                    }
                    else if(status === 'CONSUMED'){
                        backgroundColor = this.state.color_consumed;
                    }
    
                    const enddate = new Date(item.to_date);
                    enddate.setDate(enddate.getDate() +1);
                    let endday = enddate.getDate();
                    let endmonth = (enddate.getMonth()+1);
                    if(endday<10){
                        endday = '0'+endday;
                    }
                    if(endmonth <10){
                        endmonth = '0'+endmonth;
                    }
                    const end_date = enddate.getFullYear()+'-'+endmonth+'-'+endday;
                    const title = employee_name + ' - ' + leave_name;
                    const hello = item.id;
                    const arr = {leave_setting,id,hello,title,backgroundColor,start:start_date,end:end_date,status};
                    return arr
                })
                this.setState({defaultlist:detail});
                
            }
            else{
                this.setState({defaultlist:[]});
            }
        }
        
    }

    createCalendar=(arr,length)=>{
        calendar = new Calendar(this.refs.calendar,{
            plugins:[interaction,dayGridPlugin],
            defaultView:'dayGridMonth',
            selectable:true,
            locale:localStorage.getItem('language'),
            selectHelper:true,
            editable:false,
            height:700,
            events:this.state.events,
            holidays:this.state.holiday_arr,
            dayRender:function(date,info){
                if(length !== 0){
                    for(let i=0;i<length;i++){
                        let newdate = new Date(arr[i]);
                        if(date.date.getFullYear() === newdate.getFullYear() && date.date.getMonth() === newdate.getMonth() && date.date.getDate() === newdate.getDate()){
                            date.el.style.backgroundColor = '#30d5c8'
                        }
                    }
                }
            },
            select:info=>{
                this.setState({
                    startDate:moment(info.startStr),
                    start_date:info.startStr,
                    apply_toggle:true,
                })
            },
            eventClick:({event})=>{
               
                let id = event.extendedProps.hello;
                this.handleView(id);
            }
        })
        calendar.render();
        this.setState({currentDate:calendar.view.title});
    }

    handleView=(id)=>{
        this.props.get_calendar_approvals_leave_detail(id);
    }

    prevBtn = ()=>{
        calendar.prev();
        this.setState({
            currentDate:calendar.view.title
        })
    }

    changeView = newView =>{
        calendar.changeView(newView);
        this.setState({
            currentDate:calendar.view.title
        })
    }

    nextBtn = ()=>{
        calendar.next();
        this.setState({
            currentDate:calendar.view.title
        })
    }

    currentBtn=()=>{
        calendar.today();
        this.setState({
            currentDate:calendar.view.title
        })
    }

    handle_leave_type=(e,index)=>{
        Promise.resolve(this.leavecheckbox(e,index))
    }

    leavecheckbox=(e,index)=>{
        const target = e.target;
        const checked = target.checked;
        let leave_checkbox = this.state.leave_checkbox;
        const new_leave_checkbox = leave_checkbox.map(item=>
            item.id === index
            ?{...item,check:checked}
            :item
        )
        let count = 0 ;
        for(let i=0;i<new_leave_checkbox.length ;i++){
            const check = new_leave_checkbox[i].check;
            if(check === false){
                count = count +1;
            }
        }
        if(count === 0){
            this.setState({type_all:true});
        }
        else{
            this.setState({type_all:false});
        }
        this.setState({leave_checkbox:new_leave_checkbox});
    }

    handleTypeAll=(e)=>{
        const target = e.target;
        const checked = target.checked;
        const name = target.name;
        this.setState({
            [name]:checked
        });
        if(name === 'type_all'){
            let leave_checkbox = this.state.leave_checkbox;
            for(let i=0;i<leave_checkbox.length ;i++){
                leave_checkbox[i].check = checked
            }
            this.setState({leave_checkbox});
        }
    }

    typeToggle=()=>{
        const toggle = !this.state.type_toggle;
        this.setState({type_toggle:toggle});
    }

    statusToggle=()=>{
        const toggle = !this.state.status_toggle;
        this.setState({status_toggle:toggle});
    }

    radioChange=(e)=>{
        const target = e.target;
        const value = target.value;
        this.setState({
            status_option:value
        })
    }


    getCalendar=()=>{

        const {leave_checkbox} = this.state;
        let button_group = [];
        let button_final = [];
        if(leave_checkbox.length !== 0){
            for(let i=0;i<leave_checkbox.length ;i++){
                const name = leave_checkbox[i].name;
                const id = leave_checkbox[i].id;
                const check = leave_checkbox[i].check;
                button_group.push(
                    <div className="custom-control custom-checkbox ml-2" key={i}>
                        <input type="checkbox" id={'approvalcalendarfiltertype'+i} className="custom-control-input" checked={check} onChange={(e)=>this.handle_leave_type(e,id)} />
                        <label className="custom-control-label" htmlFor={'approvalcalendarfiltertype' +i}>{name}</label>
                    </div>
                )
            }

            button_final=(
                <>
                    <div className="custom-control custom-checkbox ml-2">
                        <input type="checkbox" id='approvalcalendarfiltertypeall' className="custom-control-input" name="type_all" checked={this.state.type_all} onChange={this.handleTypeAll}  />
                            <label className="custom-control-label" htmlFor='approvalcalendarfiltertypeall'>{IMLocalized('select_all')}</label>
                    </div>
                    <DropdownItem divider></DropdownItem>
                    {button_group}
                </>
            )
        }
        const table=(
            <Card className="card-calendar">
                <CardHeader className="bg-info">
                    <Row>
                        <Col lg="4">
                            <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0 mr-1">
                                {this.state.currentDate}
                            </h6>
                        </Col>
                        <Col className="mt-3 mt-lg-0 text-lg-right" lg="8">
                            <Button className="fullcalendar-btn-prev btn-neutral" color="default" size="sm" onClick={()=>this.prevBtn()}>
                                <i className="fas fa-angle-left"  />
                            </Button>
                            <Button className="fullcalendar-btn-today btn-neutral" color="default" size="sm" onClick={()=>this.currentBtn()}>
                               {IMLocalized('current')}
                            </Button>
                            <Button className="fullcalendar-btn-next btn-neutral" color="default" size="sm" onClick={()=>this.nextBtn()} >
                                <i className="fas fa-angle-right" />
                            </Button>
                            <Button className="btn-neutral" color='default' data-calendar-view='month' onClick={()=> this.changeView('dayGridMonth')} size="sm">
                            {IMLocalized('MONTH')}
                            </Button>
                            <Button className="btn-neutral" color="default" data-calendar-view="basicWeek" onClick={()=> this.changeView('dayGridWeek')} size="sm">
                            {IMLocalized('WEEK')}
                            </Button>
                            <Button className="btn-neutral" color="default" data-calendar-view="basicDay" onClick={()=> this.changeView('dayGridDay')} size="sm">
                            {IMLocalized('DAY')}
                            </Button>
                            {/* <Button className="btn-neutral" color="default" onClick={this.viewHoliday} size="sm">
                                Holiday List
                            </Button> */}
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col>
                            <Dropdown size="sm" direction="down" isOpen={this.state.type_toggle} toggle={this.typeToggle}>
                                <DropdownToggle className="bg-neutral text-primary" caret aria-expanded={this.state.type_toggle}>
                                    {IMLocalized('filter_by_leave_type')}
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-scroll">
                                    {button_final}
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown size="sm" direction="down" isOpen={this.state.status_toggle} toggle={this.statusToggle}>
                                <DropdownToggle  className="bg-neutral text-primary" caret aria-expanded={this.state.status_toggle} >
                                    {IMLocalized('filter_by_status')}
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-scroll">
                                    <div className="custom-control custom-radio ml-2 mb-1">
                                        <input className="custom-control-input" id="approvalleavecalendarstatusRadio1" name="approvalleavecalendarstatusRadio" type="radio" value="all" checked = {this.state.status_option ==='all'} onChange={this.radioChange}/>
                                        <label className="custom-control-label" htmlFor="approvalleavecalendarstatusRadio1">{IMLocalized('ALL')}</label>
                                    </div>
                                    <div className="custom-control custom-radio ml-2 mb-1">
                                        <input className="custom-control-input" id="approvalleavecalendarstatusRadio2" name="approvalleavecalendarstatusRadio" type="radio" value="pending" checked = {this.state.status_option ==='pending'} onChange={this.radioChange}/>
                                        <label className="custom-control-label" htmlFor="approvalleavecalendarstatusRadio2">{IMLocalized('PENDING')}</label>
                                    </div>
                                    <div className="custom-control custom-radio ml-2 mb-1">
                                        <input className="custom-control-input" id="approvalleavecalendarstatusRadio3" name="approvalleavecalendarstatusRadio" type="radio" value="approve" checked = {this.state.status_option ==='approve'} onChange={this.radioChange}/>
                                        <label className="custom-control-label" htmlFor="approvalleavecalendarstatusRadio3">{IMLocalized('APPROVED')}</label>
                                    </div>
                                    <div className="custom-control custom-radio ml-2 mb-1">
                                        <input className="custom-control-input" id="approvalleavecalendarstatusRadio4" name="approvalleavecalendarstatusRadio" type="radio" value="reject" checked = {this.state.status_option ==='reject'} onChange={this.radioChange}/>
                                        <label className="custom-control-label" htmlFor="approvalleavecalendarstatusRadio4">{IMLocalized('REJECTED')}</label>
                                    </div>
                                    <div className="custom-control custom-radio ml-2 mb-1">
                                        <input className="custom-control-input" id="approvalleavecalendarstatusRadio5" name="approvalleavecalendarstatusRadio" type="radio" value="cancel" checked = {this.state.status_option ==='cancel'} onChange={this.radioChange}/>
                                        <label className="custom-control-label" htmlFor="approvalleavecalendarstatusRadio5">{IMLocalized('CANCELLED')}</label>
                                    </div>
                                    <div className="custom-control custom-radio ml-2 mb-1">
                                        <input className="custom-control-input" id="approvalleavecalendarstatusRadio6" name="approvalleavecalendarstatusRadio" type="radio" value="consumed" checked = {this.state.status_option ==='consumed'} onChange={this.radioChange}/>
                                        <label className="custom-control-label" htmlFor="approvalleavecalendarstatusRadio6">{IMLocalized('CONSUMED')}</label>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                    </Row>
                </CardHeader>
                <div className="calendar" ref="calendar"></div>
            </Card>
        )
        return table;
    }

    viewHoliday=()=>{
        let toggle = !this.state.holiday_toggle
        this.setState({holiday_toggle:toggle});
    }

    modalHoliday=()=>{
        const {holidaylist} = this.state;
        let holiday_table =(
            <Table className="align-items-center table-bordered" responsive>
                <thead className="thead-light text-center">
                    <tr>
                        <th>{IMLocalized('date')}</th>
                        <th>{IMLocalized('name')}</th>
                    </tr>
                </thead>
                <tbody className="text-center">
                    <tr>
                        <td>{IMLocalized('no_data')}</td>
                    </tr>
                </tbody>
            </Table>
        );
        if(holidaylist.length !== 0){
            const ttbody = holidaylist.map((item,index)=>{
                const name = item.name;
                const date = this.getDateFormat(item.date);
                return(
                    <tr key={index}>
                        <td>{date}</td>
                        <td>{name}</td>
                    </tr>
                )
            })
            holiday_table=(
                <Table className="align-items-center table-bordered" responsive>
                    <thead className="thead-light text-center">
                        <tr>
                            <th>{IMLocalized('date')}</th>
                            <th>{IMLocalized('name')}</th>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {ttbody}
                    </tbody>
                </Table>
            )
        }
        const table=(
            <Modal size="lg" isOpen={this.state.holiday_toggle}>
                <ModalHeader toggle={this.viewHoliday}>{IMLocalized('holiday_list')}</ModalHeader>
                <ModalBody>
                    {holiday_table}
                </ModalBody>
                <ModalFooter>
                    <button onClick={this.viewHoliday} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                </ModalFooter>
            </Modal>
        )

        return table;
    }

    modalDetail=()=>{
        const {detail_list,imglist}= this.state;
        let img_table ='';
        if(imglist.length !== 0){
            const img1 = imglist.map((item,index)=>(
                <Col lg="4" className="pt-1">
                <div className="image-item mr-2" key={index} onClick={this.open.bind(this,index)} >
                    <div className="image-inner">
                        <img src={item.src} className="img-thumbnail" alt={"This is Leave" +index}></img>
                    </div>
                </div>
                </Col>
            ))

            img_table = (
                <Row>
                    {img1}
                </Row>
            )
        }
        else{
            img_table = IMLocalized('no_image');
        }
        let table= '';
        let days_table = '';
        let approval_table = '';
        if(detail_list !== null){
            const days = detail_list.days;
            const employee_number = detail_list.employee.employee_number;
            const name = detail_list.employee.name;
            const leave_type = detail_list.leave_setting.name;
            const status = detail_list.status;
            const approvers = detail_list.approvers;
            const amount = detail_list.amount;
            if(approvers.length !== 0){
                let arr_approvers = [];
                for(let i=0;i<approvers.length ;i++){
                    const name = approvers[i].employee.name;
                
                    const is_active = approvers[i].is_active;
                    const daa = approvers[i].delegation_application_approval;
                    let string = `(${IMLocalized('delegation')})`;
                    if(is_active === true){
                        if(daa !== null){
                            arr_approvers.push(<div><span>{name} {string}</span></div>)
                        }
                        else{
                            arr_approvers.push(<div><span>{name}</span></div>)
                            
                        }
                    }
                    
                }
                if(arr_approvers.length !== 0){
                    approval_table = (
                        <tr>
                            <td className="w-25">{IMLocalized('approval_pending')}</td>
                            <td>
                                {arr_approvers}
                            </td>
                        </tr>
                    )
                }
               
            }
            let comments = detail_list.comments;
            let comment_string = [];
            if(comments.length !== 0){
                for(let i =0;i<comments.length;i++){
                    const word = comments[i].comment;
                    const employee_name = comments[i].employee_name;
                    const text1 = <div className="d-flex"><h5>{employee_name}</h5> <span className="pl-2">: {word}</span></div>;
                    
                    comment_string.push(text1)
                }
            }
            else{
                comment_string = '---';
            }
           
            if(days.length !== 0){
                let ttbody = [];
                for(let i =0;i<days.length;i++){
                    const date = this.getDateFormat(days[i].date);
                    const day = IMLocalized(days[i].day);
                    
                    ttbody.push(<tr><td>{date}</td><td>{day}</td></tr>)
                }
    
                days_table = (
                    <Table size="sm" className="align-items-center bg-white table-bordered" responsive>
                        <thead className="thead-light">
                            <tr >
                                <th className="w-25">{IMLocalized('leave_date')}</th>
                                <th >{IMLocalized('option')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ttbody}
                        </tbody>
                    </Table>
                )
            }

            let modal_btn ='';
            if(this.state.has_action){
                modal_btn =(
                    <>
                        <button onClick={this.singleApprove} className="btn btn-primary btn-sm">{IMLocalized('approve')}</button>
                        <button onClick={this.singleReject} className="btn btn-danger btn-sm">{IMLocalized('reject')}</button>
                    </>
                )
            }

            table=(
                <Modal size="lg"  isOpen={this.state.detail_toggle}>
                    <ModalHeader toggle={this.closeModal}>{IMLocalized('leave_detail')}</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                {days_table}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table size="sm" className="table-bordered">
                                    <tbody>
                                        <tr>
                                            <td className="w-25">{IMLocalized('employee_number')}</td>
                                            <td>{employee_number}</td>
                                        </tr>
                                        <tr>
                                            <td className="w-25">{IMLocalized('employee_name')}</td>
                                            <td>{name}</td>
                                        </tr>
                                        <tr>
                                            <td className="w-25">{IMLocalized('leave_type')}</td>
                                            <td>{leave_type}</td>
                                        </tr>
                                        <tr>
                                            <td className="w-25">{IMLocalized('status')}</td>
                                            <td>{IMLocalized(status)}</td>
                                        </tr>
                                        <tr>
                                            <td className="w-25">{IMLocalized('comment')}</td>
                                            <td>{comment_string}</td>
                                        </tr>
                                        <tr>
                                            <td className="w-25">{IMLocalized('leave_amount')}</td>
                                            <td>{amount}</td>
                                        </tr>
                                        <tr>
                                            <td className="w-25">{IMLocalized('image')}</td>
                                            <td>
                                                {img_table}
                                            </td>
                                        </tr>
                                        {approval_table}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Viewer activeIndex={this.state.activeImg} zIndex={1060} visible={this.state.imgtoggle} onClose={this.close} images={this.state.imglist} />
                    </ModalBody>
                    <ModalFooter>
                        {modal_btn}
                        <button className="btn btn-danger btn-sm" onClick={this.closeModal}>{IMLocalized('close')}</button>
                    </ModalFooter>
                </Modal>
            )
        }

        return table;
    }

    getColor=()=>{

        const table1 = (
            <div style={{position:'sticky',top:'25px'}}>
                <Row className="pt-3 mr-2 bg-white">
                    <Col>
                        <div className="bor-bottom">
                            <h5>{IMLocalized('legend')}</h5>
                        </div>
                        <div className="pt-2">
                            <div className="d-flex">
                                <i className="fas fa-square mr-2" style={{color:this.state.color_pending}} />
                                <h5>{IMLocalized('pending_leave')}</h5>
                            </div>
                            <div className="d-flex">
                                <i className="fas fa-square mr-2" style={{color:this.state.color_approve}} />
                                <h5>{IMLocalized('approved_leave')}</h5>
                            </div>
                            <div className="d-flex">
                                <i className="fas fa-square mr-2" style={{color:this.state.color_reject}} />
                                <h5>{IMLocalized('rejected_leave')}</h5>
                            </div>
                            <div className="d-flex">
                                <i className="fas fa-square mr-2" style={{color:this.state.color_consumed}} />
                                <h5>{IMLocalized('consumed_leave')}</h5>
                            </div>
                            <div className="d-flex">
                                <i className="fas fa-square mr-2" style={{color:this.state.color_cancel}} />
                                <h5>{IMLocalized('cancelled_leave')}</h5>
                            </div>
                            
                        </div>
                    </Col>
                </Row>
            </div>
        )

        return table1;
    }

    render(){
        const {isLoading,isLoading1,isLoading2,isLoading3,isLoading4,isLoading5} = this.props;
        let test = '';
        if(isLoading||isLoading1||isLoading2||isLoading3||isLoading4 || isLoading5){
            test = <OverlayLoading />
        }

        const {rowData} = this.state;
        let btn = '';

        if(rowData.length !== 0){
            btn = <div><button className='btn btn-primary btn-sm' onClick={()=>this.pendingReject(true)}>{IMLocalized('approve')}</button><button className='btn btn-danger btn-sm' onClick={()=>this.pendingReject(false)}>{IMLocalized('reject')}</button></div>
        }
        return(
            <div>
                <Container fluid>
                    <Row className="bg-white border-bottom">
                        <Col className="p-2">
                            <BreadCrumbList list={[
                                { active: false, title:IMLocalized('home'), onClick:()=>this.context.addItem('1','dashboard')},
                                { active: true, title:IMLocalized('self_leave')}
                            ]}
                            />
                        </Col>
                    </Row>
                </Container>
                {test}
                {this.state.rowData.length !== 0 ? 
                <Row className="pt-2 justify-content-center">
                    <Col lg="11">
                        <Row>
                            <Col>
                                <h3>{IMLocalized('pending_leave_list')}</h3>
                            </Col>
                            <Col className="col-auto">
                                {btn}
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-center">
                            <div className="ag-theme-balham" style={{height:'400px', width:'100%' }}>
                                <AgGridReact
                                columnDefs={this.state.columnDefs}
                                gridOptions={gridOptions}
                                rowData={this.state.rowData}
                                defaultColDef={this.state.defaultColDef}
                                suppressDragLeaveHidesColumns={true}
                                suppressSizeToFit={true}
                                suppressColumnMoveAnimation={false}
                                onGridReady={this.onGridReady}
                                overlayNoRowsTemplate = {this.state.noRowTemplate}
                                onCellFocused={this.onCellFocused}
                                >
                                </AgGridReact>
                            </div>
                        </div>
                    </Col>
                </Row>
                : ''}
                <Container className="pt-2" fluid>
                    <Row className="pt-4 justify-content-center">
                        <Col lg="11">
                            <Row>
                                <Col lg="10">
                                    <div ref="calendarview">
                                        {this.getCalendar.call(this)}
                                    </div>  
                                </Col>
                                <Col lg="2">
                                    {this.getColor.call(this)}
                                </Col>
                            </Row>
                            
                        </Col>
                    </Row>
                    {this.modalHoliday.call(this)}
                    {this.modalDetail.call(this)}
                </Container>
            </div>
        )
    }
}
function mapStateToProps(state,ownProps){
    return{
        emplist:state.get_employee_list.data,
        list1:state.manager_leave_list.data1,
        list:state.manager_leave_list.data,
        alllist:state.dropdown_list.schedule_all_options,
        leave_status:state.dropdown_list.leave_status,
        colorlist:state.get_general_color.data,
        pendingcolor:state.get_leave_pending_color.data,
        holidaylist:state.get_holiday_list.data,
        approve_success:state.calendar_leave_manager_approve.data,
        picture:state.calendar_approvals_get_leave_picture.data,
        leavelist:state.get_leave_list.data,
        detail:state.get_calendar_approvals_leave_detail.data,
        detail_errors:state.get_calendar_approvals_leave_detail.errors,
        isLoading:state.get_approvals_leave_list.isLoading,
        isLoading1:state.dropdown_list.isLoading,
        isLoading2:state.get_leave_option.isLoading,
        isLoading3:state.get_holiday_list.isLoading,
        isLoading4:state.calendar_leave_manager_approve.isLoading,
        isLoading5:state.get_calendar_approvals_leave_detail.isLoading,
    }
}
const mapDispatchToProps =(dispatch)=>{
    return{
        get_approvals_leave_list:()=>{
            dispatch(get_approvals_leave_list())
        },
        calendar_leave_manager_approve:(id,approve,remarks)=>{
            dispatch(calendar_leave_manager_approve(id,approve,remarks))
        },
        get_general_dashboard:()=>{
            dispatch(get_general_dashboard())
        },
        get_apply_leave_list:()=>{
            dispatch(get_apply_leave_list())
        },
        calendar_approvals_get_leave_picture:(id)=>{
            dispatch(calendar_approvals_get_leave_picture(id))
        },
        get_all_employee_available_leave:()=>{
            dispatch(get_all_employee_available_leave())
        },
        get_calendar_approvals_leave_detail:(id)=>{
            dispatch(get_calendar_approvals_leave_detail(id))
        },
        manager_leave_list:()=>{
            dispatch(manager_leave_list())
        }
    }
}
leave_approval_list.contextType = NewMainContext;
export default compose(connect(mapStateToProps,mapDispatchToProps))(leave_approval_list);
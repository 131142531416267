import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const DELHOLIDAY_REQUEST = 'DELHOLIDAY_REQUEST';
export const DELHOLIDAY_SUCCESS = 'DELHOLIDAY_SUCCESS';
export const DELHOLIDAY_FAILURE = 'DELHOLIDAY_FAILURE';
export const DELHOLIDAY_RESET = 'DELHOLIDAY_RESET';

export const delete_holiday = (id)=>{
    return dispatch =>{
        dispatch({
            type: "DELHOLIDAY_REQUEST"
        });
        
        myaxios.delete(`contract/holiday/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELHOLIDAY_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELHOLIDAY_FAILURE',
                payload:error,
                error:true
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_holiday = () => dispatch =>{
    dispatch({
        type:'DELHOLIDAY_RESET'
    });
}
import React from 'react';
import { Modal,ModalHeader,ModalBody,ModalFooter ,Row ,Col,Table } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';


export default function ModalViewBranch(props){
  
    return(
        <div>
            
            <Modal isOpen={props.toggle} size="lg">
            {props.detail &&
                <>
                <ModalHeader toggle={props.closeModal}>{IMLocalized('branch_detail')} - {props.detail.name}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Table size="sm" className="align-items-center table-bordered">
                                <tbody>
                                    <tr>
                                        <td className='td-w-30'>{IMLocalized('name')}</td>
                                        <td>{props.detail.name}</td>
                                    </tr>
                                    <tr>
                                        <td className='td-w-30'>{IMLocalized('branch_code')}</td>
                                        <td>{props.detail.branch_code}</td>
                                    </tr>
                                    <tr>
                                        <td className='td-w-30'>{IMLocalized('unit_number')}</td>
                                        <td>{props.detail.unit_number}</td>
                                    </tr>
                                    <tr>
                                        <td className='td-w-30'>{IMLocalized('sg_postal_code')}</td>
                                        <td>{props.detail.sg_postal_code}</td>
                                    </tr>
                                    <tr>
                                        <td className='td-w-30'>{IMLocalized('address')}</td>
                                        <td>{props.detail.address}</td>
                                    </tr>
                                    <tr>
                                        <td className='td-w-30'>{IMLocalized('description')}</td>
                                        <td>{props.detail.description}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-danger btn-sm" onClick={props.closeModal}>{IMLocalized('close')}</button>
                </ModalFooter>
                </>}
            </Modal>
        </div>
    )
}
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import { IMLocalized } from "../../../../language/IMLocalized";
import ModalAwardLeave from "../modal/ModalAwardLeave";
import ModalEmpApplyLeave from "../modal/ModalEmpApplyLeave";
import LeaveDetailTable from "./LeaveDetailTable";
import LoadingTable from './LoadingTable';


export default function EmployeeLeaveDetail({list, has_award, toAwardList, toViewAll,toViewDetail,id}){

    const isLoading = useSelector(state=>state.get_all_employee_available_leave.isLoading);

    const [ award_toggle, setAwardToggle ] = useState(false);
    const [ apply_toggle, setApplyToggle ] = useState(false);
    const [ award_leave, setAwardLeave] = useState(null);
    const [ apply_name, setApplyName] = useState(null);
    const [ apply_id, setApplyID] = useState(null);

    const setAward = ( leave_setting ) =>{
        setAwardLeave(leave_setting);
        setAwardToggle(true);
    }

    const toApply = (leave_setting,name) =>{
        setApplyToggle(true);
        setApplyName(name);
        setApplyID(leave_setting);
    }

    return(
        <>
        <div className="d-flex align-items-center pl-2 emp-tabcontent-title" >
            <i className="far fa-calendar-alt text-primary"/>
            <h4 className="text-primary pl-2 m-0">{IMLocalized('employee_leave')}</h4>
        </div>
        {isLoading && <LoadingTable/>}
        {!isLoading && list.length === 0 && 
        <div className='p-2'>
            <h4>{IMLocalized('no_leave_scheme_found')}</h4>
            <span>{IMLocalized('please_assign_leave_scheme_for_employee')}</span>
        </div>}
        {!isLoading && list.length !== 0 && 
        <>
            <div className='mt-1 d-flex justify-content-end align-items-center'>
                { has_award &&
                <div className="text-primary cursor-pointer mr-2" onClick={()=>toAwardList(id)}>
                    <i className="far fa-eye mr-1 function_icon" />
                    <small>{IMLocalized('view_awarded_list')}</small>
                </div>}
                <div className="text-primary cursor-pointer" onClick={()=>toViewAll(id)}>
                    <i className="far fa-eye mr-1 function_icon" />
                    <small>{IMLocalized('all_leave')}</small>
                </div>
            </div>
            <Table className='mt-1 align-items-center table-bordered' size="sm" responsive>
                <thead className="thead-light text-center">
                    <tr>
                        <th>{IMLocalized('leave_type')}</th>
                        <th>{IMLocalized('total_entitlement')}</th>
                        <th>{IMLocalized('current_balance')}</th>
                        <th>{IMLocalized('award')}</th>
                        <th>{IMLocalized('apply')}</th>
                    </tr>
                </thead>
                <tbody className="text-center">
                    {list.map((item)=>{
                        return(
                            <LeaveDetailTable {...item} setAward={setAward} toApply={toApply} toViewDetail={toViewDetail} />
                        )
                    })}
                </tbody>
            </Table>
        </>}
        <ModalAwardLeave toggle={award_toggle} id={id} leave_setting={award_leave}  closeModal={()=>{ setAwardLeave(null); setAwardToggle(false) } } />
        <ModalEmpApplyLeave toggle={apply_toggle} name={apply_name} id={id} leave_setting={apply_id} closeModal={()=>{ setApplyToggle(false); setApplyID(null) ; setApplyName('')}} has_emp={true} has_leave={true}  />
        </>
    )
}
import { createContext, useContext, useEffect, useState } from "react";
import { NewMainContext } from "./NewMainContext";



export const ClaimOfficerEmployeeContext = createContext();



export default function ClaimOfficerEmployeeContextProvider({children}){
    const [ step, setStep ] = useState(localStorage.getItem('claim_emp_step'));
    const [ employee_profile_id, setEmployeeProfileId] = useState(localStorage.getItem('claim_emp_profile_id'));
    const [ employee_claim_detail_id, setEmployeeClaimDetailId] = useState(localStorage.getItem('claim_claim_detail_view_id'));
    const [ employee_claim_detail_claim, setEmployeeClaimDetailClaim] = useState(localStorage.getItem('claim_claim_detail_view_type'));
    const [ employee_allclaim_view, setEmployeeAllClaimView] = useState(localStorage.getItem('claim_allclaim_view_id'));
    const { emplist_reset, setEmpListReset } = useContext(NewMainContext); 

    useEffect(()=>{
        if(emplist_reset === true){
            setStep('0');
            localStorage.setItem('claim_emp_step','0');
            setEmpListReset(false);
        }
    },[setEmpListReset, emplist_reset])

    const goProfile=(id)=>{
        setStep('1');
        localStorage.setItem('claim_emp_step','1');
        localStorage.setItem('claim_emp_profile_id',id);
        setEmployeeProfileId(id);
    }

    const toEmployeeList=(id)=>{
        setStep('0');
        localStorage.setItem('claim_emp_step',id);
    }

    const toViewAll=(id)=>{
        setStep('3');
        localStorage.setItem('claim_emp_step','3');
        localStorage.setItem('claim_allclaim_view_id',id);
        setEmployeeAllClaimView(id);
    }

    const toViewDetail=(id,claim_id)=>{
        setStep('2');
        setEmployeeClaimDetailId(id);
        setEmployeeClaimDetailClaim(claim_id);
        localStorage.setItem('claim_emp_step','2');
        localStorage.setItem('claim_claim_detail_view_id',id);
        localStorage.setItem('claim_claim_detail_view_type',claim_id);
    }

    const toEmployeeProfile =(id)=>{
        setStep(id);
        localStorage.setItem('claim_emp_step',id);
    }

    return(
        <ClaimOfficerEmployeeContext.Provider value={{
            step,
            employee_profile_id,
            employee_claim_detail_id,
            employee_claim_detail_claim,
            employee_allclaim_view,
            toEmployeeProfile,
            toViewAll,
            toViewDetail,
            goProfile,
            toEmployeeList,
            setEmployeeProfileId
        }}>
            {children}
        </ClaimOfficerEmployeeContext.Provider>
    )
}
import React,{Component} from 'react';


class NotFound extends Component {

    componentDidMount(){
        document.title = process.env.REACT_APP_TITLE;
    }

    toMainPage=()=>{
        window.location.href="/#/"
    }

    render(){
        return(
            <div className="page-wrap d-flex flex-row align-items-center login2_main" style={{height:'100vh'}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <span className="display-1 d-block">The connection has failed</span>
                            <div className="mb-4 lead"></div>
                            <button className='btn btn-danger btn-sm'  onClick={this.toMainPage}>Back to Home</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default NotFound;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import Swal from 'sweetalert2';
import { IMLocalized } from '../../../language/IMLocalized';
import { delJobtitle, reset_delete_jobtitle } from '../../../action/delete_jobtitle';
import { get_additional_setting } from '../../../action/additional_setting';
import ModalCreateJobTitle from '../modal/ModalCreateJobTitle';

function JobtitleContent(){
    const [create_toggle,setCreateToggle] = useState(false);
    const dispatch = useDispatch();
    const jobtitlelist = useSelector(state=>state.add.jobtitle);
    const isLoading = useSelector(state=>state.add.isLoading);
    const delete_success = useSelector(state=>state.delete_jobtitle.data);

    useEffect(()=>{
        if(delete_success !== null){
            dispatch(reset_delete_jobtitle());
            dispatch(get_additional_setting());
        }
    },[delete_success,dispatch])

    
    const removeJobtitle=(name)=>{
        Swal.fire({
            type:"warning",
            title:`${IMLocalized('do_you_really_want_to_delete')} \n ${IMLocalized('job_title')} (` + name +`) !`,
            showCancelButton:true,
            confirmButtonColor:'red',
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!')
        })
        .then((result)=>{
            if(result.value){
                dispatch(delJobtitle(name));
            }
        })
    }

    return(
        <>
        <Card>
            <CardHeader className='d-flex justify-content-between align-items-center '>
                <h4 className="mb-0">{IMLocalized('jobtitle_list')}</h4>
                <button className='btn btn-primary btn-sm' onClick={()=>setCreateToggle(true)}>{IMLocalized('add')}</button>
            </CardHeader>
            {isLoading && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('loading')}..</span></CardBody>}
            {!isLoading && jobtitlelist.length === 0 && <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>}
            {!isLoading && jobtitlelist.length !== 0 &&
            <ListGroup className="list scrollbarstyle1" flush style={{ overflow:'auto',overflowX:'hidden',maxHeight:'calc(100vh - 340px)'}}>
                {jobtitlelist.map((item,index)=>{
                    return(
                        <ListGroupItem className="py-2" key={index}>
                            <Row>
                                <Col>
                                    {index+1}. {item.name}
                                </Col>
                                <Col className="col-auto">
                                    <div className="delete_btn mr-3 d-flex align-items-center">
                                        <i className="fas fa-trash text-danger cursor-pointer" onClick={()=>removeJobtitle(item.name)}  />
                                    </div>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    )
                })}
            </ListGroup>}
        </Card>
        <ModalCreateJobTitle toggle={create_toggle} closeModal={()=>setCreateToggle(false)} />
        </>
    )
}
export default JobtitleContent;
import myaxios from '../../axios';
export const MONTHLYREPORTDATEFILTER2_REQUEST = 'MONTHLYREPORTDATEFILTER2_REQUEST';
export const MONTHLYREPORTDATEFILTER2_SUCCESS = 'MONTHLYREPORTDATEFILTER2_SUCCESS';
export const MONTHLYREPORTDATEFILTER2_FAILURE = 'MONTHLYREPORTDATEFILTER2_FAILURE';
export const MONTHLYREPORTDATEFILTER2_RESET = 'MONTHLYREPORTDATEFILTER2_RESET';

export const monthly_report_date_filter2=(from_date,to_date)=>{
    return dispatch=>{
        dispatch({
            type:'MONTHLYREPORTDATEFILTER2_REQUEST'
        });

        myaxios.post('payroll/payroll/ReportDateFiler/',{
            from_date:from_date,
            to_date:to_date
        })
        .then((response)=>{
            dispatch({
                type:'MONTHLYREPORTDATEFILTER2_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'MONTHLYREPORTDATEFILTER2_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_payroll_date = () => dispatch =>{
    dispatch({
        type:'MONTHLYREPORTDATEFILTER2_RESET'
    });
}
import React,{Component} from 'react';
import { Container, Row, Col, Table, ModalHeader, ModalBody,Modal, ModalFooter } from 'reactstrap';
import {connect} from 'react-redux';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import XLSX from 'xlsx';
import ExcelJs from 'exceljs';
import FileSaver from 'file-saver';
import {relationlist} from '../../../data/relation_data';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import {IMLocalized} from '../../../language/IMLocalized';
import {employee_relationship_array_save} from '../../../action/wizard_employee/employee_relationship_array_save';
import { employee_relationship_array_validate } from '../../../action/wizard_employee/employee_relationship_array_validate';
import { get_employee_relationship } from '../../../action/employee/get_employee_relationship';
import { NewMainContext } from '../../../context/NewMainContext';
import BreadCrumbList from '../../breadcrumb/BreadCrumbList';

const gridOptions={

}

class import_create_employee_contact extends Component{

    constructor(props){
        super(props);
        this.state={
            language:localStorage.getItem('language'),
            columnDefs:[],
            rowData:[],
            validatelist:[],
            error_msg:[],
            err_toggle:false       
        }
    }

    componentDidMount(){

    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.array_validate_success !== this.props.array_validate_success){
            const {validatelist}= this.state;
            this.props.employee_relationship_array_save(validatelist);
        }
        if(prevProps.array_create_success !== this.props.array_create_success){
            this.props.get_employee_relationship();
            this.setState({rowData:[]});
            this.setState({columnDefs:[]});
            this.setState({validatelist:[]});
        }
        if(prevProps.array_validate_errors !== this.props.array_validate_errors){
            const {validatelist,rowData} = this.state;
            const {array_validate_errors} = this.props;
            if(array_validate_errors !== undefined){
                const errors = array_validate_errors.response.data;
                if(errors !== undefined){
                    if(errors.length !== 0){
                        let arr = [];
                        for(let i =0;i<errors.length;i++){
                            const object1 = errors[i];
                            let arr1=[];
                            const name = validatelist[i].emp_name;
                            if(object1 !== true){
                                
                                if('name' in object1){
                                    const word = `${IMLocalized('contact_name')} : ` + object1['name'].toString();
                                    arr1.push(<div><span>● {word}</span></div>)
                                    rowData[i].err_name = true;
                                    this.setState({rowData});
                                    gridOptions.api.setRowData(rowData);
                                }
                                if('non_field_errors' in object1){
                                    
                                    const word = object1['non_field_errors'].toString();
                                    arr1.push(<div><span>● {word}</span></div>)
                                    rowData[i].err_non = true;
                                    this.setState({rowData});
                                    gridOptions.api.setRowData(rowData);
                                }
                                if('contact_detail' in object1){
                                    
                                    const word = `${IMLocalized('contact_detail')} : `+ object1['contact_detail'].toString();
                                    arr1.push(<div><span>● {word}</span></div>)
                                    rowData[i].err_detail = true;
                                    this.setState({rowData});
                                    gridOptions.api.setRowData(rowData);
                                }
                                if('relation' in object1){
                                    rowData[i].err_relation = true;
                                    this.setState({rowData});
                                    gridOptions.api.setRowData(rowData);
                                    const word = `${IMLocalized('relation')} : ` + object1['relation'].toString();
                                    arr1.push(<div><span>● {word}</span></div>)
                                    
                                }
                                if('employee' in object1){
                                    rowData[i].err_non = true;
                                    this.setState({rowData});
                                    gridOptions.api.setRowData(rowData);
                                    const word = `${IMLocalized('employee')} : ` + object1['employee'].toString();
                                    arr1.push(<div><span>● {word}</span></div>)
                                }
                            }
                            if(arr1.length !== 0){
                                arr.push(<tr><td>{name}</td><td>{arr1}</td></tr>)

                            }
                        }
                        this.setState({error_msg:arr});
                        this.setState({err_toggle:true});
                    }
                }
            }
        }
        if(this.context.import_employee_contact_reset === true){
            this.getClearAggrid();
            this.context.setImportEmployeeContactReset(false);
        }
    }

    generateTemplate=()=>{
        const {language} = this.state;
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('employee_emergency_contact');
        const ws2 = wb.addWorksheet('setting');
        const ws3 = wb.addWorksheet('id');
        ws3.getCell('A1').value = 'EmpEmergencyContact';
        ws3.getCell('A2').value = language;

        ws.columns=[
            {header:IMLocalized('agrid_name'),key:'emp_name'},
            {header:IMLocalized('agrid_contact_name'),key:'name'},
            {header:IMLocalized('agrid_relation'),key:'relation'},
            {header:IMLocalized('agrid_contact_detail'),key:'contact_detail'}
        ]

        const {emplist} = this.props;
        for(let i=0;i<emplist.length;i++){
            let x=i+1;
            ws2.getCell('A'+x).value = emplist[i].employee_number + '-'+emplist[i].name;
        }

        if(emplist.length !== 0){
            for(let i=2;i<=2001;i++){
                ws.getCell('A'+i).dataValidation={
                    type:'list',
                    allowBlank:true,
                    formulae:['=setting!$A$1:$A$'+emplist.length],
                    error:IMLocalized('excel_valid_in_list'),
                    errorStyle:'error',
                    errorTitle:'Error',
                    showErrorMessage:true,
                }
            }
        }

        if(language ==='zh'){
            for(let i=0;i<relationlist.length ;i++){
                const y = i+1;
                ws2.getCell('B'+y).value = relationlist[i].display_zh;
    
            }
        }
        else{
            for(let i=0;i<relationlist.length ;i++){
                const y = i+1;
                ws2.getCell('B'+y).value = relationlist[i].display;
    
            }
        }
        
        if(relationlist.length !== 0){
            for(let i=2;i<2001;i++){
                ws.getCell('C'+i).dataValidation={
                    type:'list',
                    allowBlank:false,
                    error:IMLocalized('excel_valid_in_list'),
                    errorStyle:'error',
                    errorTitle:'Error',
                    showErrorMessage:true,
                    formulae:['=setting!$B$1:$B$'+relationlist.length]
                }
            }
        }


        ws.columns.forEach(column =>{
            column.width = column.header.length < 15 ? 15 : column.header.length
        })

        wb.xlsx.writeBuffer().then((buf)=>{
            var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
            FileSaver.saveAs(file, 'Employee_Emergency_Contact_Template.xlsx')
        });
    }

    handleFile = event => {
        const { target = {} } = event || {};
        target.value = "";
    };

    uploadExcel=(e)=>{
        let file = e.target.files[0];
        const {language} = this.state;
        const reader = new FileReader();
        reader.onload=(x)=>{
            const bstr = x.target.result;
            const wb = XLSX.read(bstr,{type:'binary'});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const wsname3 = wb.SheetNames[2];
            const ws3 = wb.Sheets[wsname3];

            const columns = {
                'A':'emp_name',
                'B':'name',
                'C':'relation',
                'D':'contact_detail'
            }

            let rowData= [];
            let rowIndex = 2;
            let idd = '';
            let lang = '';
            if(ws3 !== undefined){
                idd = ws3['A1'].w;
                lang = ws3['A2'].w;
            }

            if(idd === 'EmpEmergencyContact'){
                if(lang === language){
                    while(ws['A'+rowIndex]){
                        let row ={err_relation:false,err_name:false,err_detail:false,err_non:false};
                        for(let i =0;i<Object.keys(columns).length;i++){
                            const column = Object.keys(columns)[i];
                            if(ws[column+rowIndex] !== undefined){
                                row[columns[column]] = ws[column+rowIndex].v;
                            }
                            else{
                                row[columns[column]] = null;
                            }
                        }
                        
                        rowData.push(row);
                        rowIndex++;
                    }
                    Promise.resolve(this.getClearAggrid())
                    .then(()=>this.getImportData(rowData))
                }
            }
        }
        reader.readAsBinaryString(file);
    }

    getClearAggrid=()=>{
        this.setState({columnDefs:[]});
        this.setState({rowData:[]});
        this.setState({validatelist:[]});
    }

    getImportData=(data)=>{
        const {emplist} = this.props;
        const {language} = this.state;
        let emp_option = [''];
        if(emplist.length !==0){
            for(let i=0;i<emplist.length;i++){
                const name = emplist[i].employee_number +'-'+emplist[i].name;
                emp_option.push(name);
            }
        }

        let relation_option = [''];
        if(relationlist.length !== 0){
            if(language === 'zh'){
                for(let i=0;i<relationlist.length;i++){
                    const name = relationlist[i].display_zh;
                    relation_option.push(name)
                }
            }
            else{
                for(let i=0;i<relationlist.length;i++){
                    const name = relationlist[i].display;
                    relation_option.push(name)
                }
            }
            
        }
        const columnDefs=[
            {
                headerName:IMLocalized('agrid_emp_name'),
                field:'emp_name',
                editable:true,
                width:400,
                cellEditor:'agSelectCellEditor',
                cellEditorParams:{
                    values:emp_option
                }
            },
            {
                headerName:IMLocalized('agrid_contact_name'),
                field:'name',
                editable:true,
                width:300,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_name}
                },
            },
            {
                headerName:IMLocalized('agrid_relation'),
                field:'relation',
                editable:true,
                cellEditor:'agSelectCellEditor',
                cellEditorParams:{
                    values:relation_option
                },
                width:200,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.err_relation}
                },
            },
            {
                headerName:IMLocalized('agrid_contact_detail'),
                field:'contact_detail',
                editable:true,
                wdith:200
            }
        ]

        this.setState({columnDefs});
        this.setState({rowData:data});
    }

    setErrToggle=()=>{
        const toggle = !this.state.err_toggle;
        this.setState({err_toggle:toggle});
    }

    onCreate=()=>{
        const {emplist} = this.props;
        const {language} = this.state;

        let arr = [];

        gridOptions.api.forEachNode((rowNode,index)=>{
            const data = rowNode.data;
            const emp_name = data.emp_name;
            const detail = emplist.filter(function(item){
                return item.employee_number +'-'+item.name === emp_name;
            })
            let employee = null;
            detail.forEach(item=>{
                employee = item.id;
            })
            const contact_detail = data.contact_detail;
            const name = data.name;
            const relation_display = data.relation;
            let relation = null;
            if(language === 'zh'){
                const relation_detail = relationlist.filter(function(item){
                    return item.display_zh === relation_display;
                })

                relation_detail.forEach((item)=>{
                    relation = item.value;
                })
            }
            else{
                const relation_detail = relationlist.filter(function(item){
                    return item.display === relation_display;
                })

                relation_detail.forEach((item)=>{
                    relation = item.value;
                })
            }
            arr.push({employee,name,relation,contact_detail,emp_name});
        })

        this.props.employee_relationship_array_validate(arr);
        this.setState({validatelist:arr});
    }

    modalError=()=>{
        const table=(
            <Modal isOpen={this.state.err_toggle} size="lg" >
                <ModalHeader toggle={this.setErrToggle}>{IMLocalized('error_msg')}</ModalHeader>
                <ModalBody>
                    <Table size="sm" className="table-bordered align-items-center" responsive>
                        <thead className="thead-light p-2">
                            <tr>
                                <th>{IMLocalized('name')}</th>
                                <th>{IMLocalized('error')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.error_msg}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-danger btn-sm" onClick={this.setErrToggle}>{IMLocalized('close')}</button>
                </ModalFooter>
            </Modal>
        )

        return table;
    }

    onCellValueChanged=(params)=>{
        if(params.oldValue !== params.newValue){
            const column = params.column.colDef.field;
            if(column === 'name'){
                params.data.err_name = false;

            }
            else if(column === 'relation'){
                params.data.err_relation = false;
            }
            else if(column === 'contact_detail'){
                params.data.err_detail = false;
            }

            params.data.err_non = false;
            params.api.redrawRows({
                force:true,
                columns:[column],
                rowNodes:[params.node]
            })
        }
    }

    render(){

        const {action_toggle} = this.context;
        let currentHeight = 'calc(100vh - 260px)';
        if(action_toggle === false){
            currentHeight = 'calc(100vh - 224px)';
        }
        return(
            <div>
                <Container fluid>
                    <Row className="border-bottom bg-white">
                        <Col className="p-2">
                            <BreadCrumbList list={[
                                { active: false, title:IMLocalized('home'), onClick:()=>this.context.addItem('1','dashboard')},
                                { active: true, title:IMLocalized('employee_family_import')}
                            ]}/>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col className="d-flex">
                            <div>
                                <button className='btn btn-success btn-sm' onClick={this.generateTemplate}>{IMLocalized('get_template')}</button>
                            </div>
                            <div className='pl-2 pr-2'>
                                <input type="file" style={{display:'none'}} id="ImportEmergencycustomFile" className="custom-file-input form-control-sm" onChange={this.uploadExcel.bind(this)} onClick={this.handleFile} accept=".xlsx" />
                                <label className="btn btn-primary btn-sm" htmlFor="ImportEmergencycustomFile">{IMLocalized('import_from_file')}</label>
                            </div>
                            <div>
                                <button className='btn btn-primary btn-sm' onClick={this.getClearAggrid}>{IMLocalized('clear')}</button>
                                <button className='btn btn-primary btn-sm' onClick={this.onCreate}>{IMLocalized('create')}</button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col>
                            <div className="d-flex justify-content-center">
                                <div className="ag-theme-balham" style={{height:currentHeight, width:'100%' }}>
                                    <AgGridReact
                                    columnDefs={this.state.columnDefs}
                                    rowData={this.state.rowData}
                                    suppressDragLeaveHidesColumns={true}
                                    suppressSizeToFit={true}
                                    suppressColumnMoveAnimation={false}
                                    singleClickEdit={true}
                                    gridOptions={gridOptions}
                                    onCellValueChanged={this.onCellValueChanged}
                                    onCellFocused={this.onCellFocused}
                                    onGridReady={this.onGridReady}
                                    overlayNoRowsTemplate = {this.state.noRowTemplate}
                                    rowClassRules={{
                                        'sick-days-warning': function (params) {
                                            var numSickDays = params.data.err_non;
                                            return numSickDays ===true
                                            },
                                    
                                    }}
                                    stopEditingWhenCellsLoseFocus={true}
                                    >
                                    </AgGridReact>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {this.modalError.call(this)}
                </Container>
            </div>
        )
    }
}
function mapStateToProps(state){
    return{
        emplist:state.get_employee_list.data,
        isLoading:state.get_employee_list.isLoading,
        array_validate_success:state.employee_relationship_array_validate.data,
        array_validate_errors:state.employee_relationship_array_validate.errors,
        array_create_success:state.employee_relationship_array_save.data,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        employee_relationship_array_validate:(data)=>{
            dispatch(employee_relationship_array_validate(data))
        },
        employee_relationship_array_save:(data)=>{
            dispatch(employee_relationship_array_save(data))
        },
        get_employee_relationship:()=>{
            dispatch(get_employee_relationship())
        }
    }  
}

import_create_employee_contact.contextType = NewMainContext;
export default connect(mapStateToProps, mapDispatchToProps)(import_create_employee_contact);
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Modal, ModalBody, Row,ModalFooter,ModalHeader } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { update_employee_shg } from '../../../../action/employee/update_employee_shg';
import { usePrevious } from '../../../../hook/usePrevious';
import { get_employee_shg } from '../../../../action/employee/get_employee_shg';

const mapStateToProps = (state)=>({
    success:state.update_employee_shg.data,
    isLoading:state.update_employee_shg.isLoading,
})

const mapDispatchToProps = (dispatch)=>({
    update_employee_shg:(id,data)=>{
        dispatch(update_employee_shg(id,data))
    },
    get_employee_shg:(id)=>{
        dispatch(get_employee_shg(id))
    }
})

function ModalEmpUpdateShg(props){
    const {success,closeModal,get_employee_shg} = props;
    const [cdac,setCDAC] = useState(false);
    const [ecf,setECF] = useState(false);
    const [mbmf,setMBMF] = useState(false);
    const [sinda,setSINDA] = useState(false);
    const prevSuccess = usePrevious(success);

    useEffect(()=>{
        if(props.list !== undefined && Object.keys(props.list).length !== 0){
            if(props.list.cdac){
                setCDAC(props.list.cdac);
            }
            else{
                setCDAC(false);
            }
            if(props.list.ecf){
                setECF(props.list.ecf);
            }
            else{
                setECF(false);
            }
            if(props.list.mbmf){
                setMBMF(props.list.mbmf);
            }
            else{
                setMBMF(false);
            }
            if(props.list.sinda){
                setSINDA(props.list.sinda);
            }
            else{
                setSINDA(false);
            }
        }
        else{
            setSINDA(false);
            setMBMF(false);
            setECF(false);
            setCDAC(false);
        }
    },[props.list,props.toggle])

    useEffect(()=>{
        if(prevSuccess !== undefined && prevSuccess !== success && success !== null){
            closeModal();
            get_employee_shg(props.id);
        }
    },[prevSuccess,success,closeModal,get_employee_shg,props.id])

    const updateShg=()=>{
        props.update_employee_shg(props.id,{cdac,mbmf,ecf,sinda});
    }

    return(
        <Modal isOpen={props.toggle} size="lg">
            <ModalHeader toggle={props.closeModal}>{IMLocalized('edit')} {IMLocalized('emp_shg')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="edit_profile_cdac1" name='edit_profile_cdac' className="custom-control-input" checked={cdac} onChange={(e)=>setCDAC(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="edit_profile_cdac1">CDAC</label>
                        </div>
                        <div className="custom-control custom-checkbox pt-2">
                            <input type="checkbox" id="edit_profile_ecf1" name='edit_profile_ecf' className="custom-control-input" checked={ecf} onChange={(e)=>setECF(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="edit_profile_ecf1">ECF</label>
                        </div>
                        <div className="custom-control custom-checkbox pt-2">
                            <input type="checkbox" id="edit_profile_mbmf1" name='edit_profile_mbmf' className="custom-control-input" checked={mbmf} onChange={(e)=>setMBMF(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="edit_profile_mbmf1">MBMF</label>
                        </div>
                        <div className="custom-control custom-checkbox pt-2">
                            <input type="checkbox" id="edit_profile_sinda1" name='edit_profile_sinda' className="custom-control-input" checked={sinda} onChange={(e)=>setSINDA(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="edit_profile_sinda1">SINDA</label>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {
                    props.isLoading ?
                    <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                    <>
                        <button className='btn btn-primary btn-sm' onClick={updateShg}>{IMLocalized('update')}</button>
                        <button className='btn btn-danger btn-sm' onClick={props.closeModal}>{IMLocalized('close')}</button>
                    </>
                }
                
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalEmpUpdateShg);
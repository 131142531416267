// Monthly Report Consolidated
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Input } from 'reactstrap';
import {AgGridReact} from 'ag-grid-react';
import ExcelJs from 'exceljs';
import moment from 'moment';
import FileSaver from 'file-saver';
import {monthly_report_date_filter, reset_payroll_date} from '../../action/report/monthly_report_date_filter';
import {IMLocalized} from '../../language/IMLocalized';
import 'ag-grid-enterprise';
import OverlayLoading from '../loading_component/overlay_loading';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import { isFirstColumn } from '../ag-grid_component/isFirstColumn';
import { popError } from '../../func/popError';
import { getColumnName } from '../../func/getColumnName';
import { getValue } from '../../func/getValue';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';
import { PayrollEmployeeCheck } from '../../func/PayrollEmployeeCheck';
import { get_payroll_instance_array2, reset_payroll_instance_array2 } from '../../action/payroll/get_payroll_instance_array2';
import ProgressBar from '../loading_component/progress_loading';

const mapStateToProps=(state)=>({
    payroll_list:state.get_payroll_list.data,
    list:state.monthly_report_date_filter.data,
    isLoading:state.monthly_report_date_filter.isLoading,
    emplist:state.get_employee_list.data,
    selflist:state.dropdown_list.self_help_group,
    isLoading1:state.get_employee_list.isLoading,
    isLoading2:state.get_payroll_list.isLoading,
    instance:state.get_payroll_instance_array2.data
})

const mapDispatchToProps=(dispatch)=>({
    monthly_report_date_filter:(from,to)=>{
        dispatch(monthly_report_date_filter(from,to))
    },
    get_payroll_instance_array2:(id)=>{
        dispatch(get_payroll_instance_array2(id))
    },
    reset_payroll_date:()=>{
        dispatch(reset_payroll_date())
    },
    reset_payroll_instance_array2:()=>{
        dispatch(reset_payroll_instance_array2())
    }
})

const gridOptions={
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
    sideBar:{
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
            },
          },
        ],
        defaultToolPanel: 'filters',
    },
    defaultColDef:{
        editable:false
    },
}

// const initialState={
//     from_payroll:'',
//     to_payroll:'',
//     resignee:false,
//     active_employee:true,
//     rowData:[],
//     is_pending:false,
//     selected_emp:[],
//     selected_option:'employee',
//     select_option:'employee',
//     select_type:'code',
//     selected_from:'',
//     selected_to:'',
// }

const initialState={
    from_payroll:'',
    to_payroll:'',
    resignee:true,
    active_employee:true,
    rowData:[],
    is_pending:false,
    selected_emp:[],
    selected_option:'employee',
    select_option:'employee',
    select_type:'code',
    selected_from:'',
    selected_to:'',
    join_date:'',
    resignation_date:''
}

let payrollArray = []

function MonthReport2(props){
    const {get_payroll_instance_array2,reset_payroll_date,reset_payroll_instance_array2} = props;
    const {action_toggle, addItem, monthly_report_reset, setMonthlyReportReset} = useContext(NewMainContext);
    const [state,setState] = useState(initialState);
    const [monthNames] = useState([IMLocalized('january'), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
    IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")]);
    const [monthNames1] = useState(['January','February','March','April','May','June','July','August','September','October','November','December']);

    const gridApi = useRef();
    const gridColumnApi = useRef();

    const [fromDate, setFromDate]= useState('');
    const  [toDate, setToDate]= useState('');

    const [exportLoading, setExportLoading] = useState(false)

    useEffect(()=>{
        if(monthly_report_reset === true){
            setState(initialState);
            setMonthlyReportReset(false);
        }
    },[monthly_report_reset,setMonthlyReportReset])

    useEffect(()=>{
        // const arr = props.emplist.filter(item=>(item.active === true && item.resignation_date === null && state.active_employee === true) || (state.resignee === true && item.resignation_date !== null)).filter(item=>item.contract_cache !== null);

        const arr = PayrollEmployeeCheck(fromDate, toDate, props)
        setState(prevState=>({...prevState,rowData:arr}));
    },[props.emplist,toDate])

    const generateSectionExcel=useCallback((option,option_data,bottom_data,header)=>{
        const wb = new ExcelJs.Workbook();
        let payroll_name,payroll_title,title_name;
        if(state.selected_option === 'cost_center'){
            title_name = 'Cost-Center';
        }
        else if(state.selected_option === 'department'){
            title_name = 'Department';
        }
        else if(state.selected_option === 'section'){
            title_name = 'Section';
        }
        else{
            title_name = 'Job-Level';
        }
        
        if(state.selected_from === state.selected_to){
            const month = new Date(state.selected_from).getMonth();
            const month1 = monthNames1[month];
            const year = new Date(state.selected_from).getFullYear();
            payroll_name = `MonthlyReport_${month1}_${year}_by_${title_name}`;
            payroll_title = `${month1} ${year} by ${title_name}`;
        }
        else{
            const from_month = new Date(state.selected_from).getMonth();
            const from_month2 = monthNames1[from_month];
            const from_year = new Date(state.selected_from).getFullYear();
            const to_month = new Date(state.selected_to).getMonth();
            const to_month2 = monthNames1[to_month];
            const to_year = new Date(state.selected_to).getFullYear();
            payroll_name = `MonthlyReport_${to_month2}_${to_year}_to_${from_month2}_${from_year}_by_${title_name}`;
            payroll_title = `${to_month2} ${to_year} to ${from_month2} ${from_year} by ${title_name}`;
        }

        const today = moment().format('DD/MM/YYYY');
        const time = moment().format('H:mm:ss');
        const columns = [
            {header:'EMP ID',key:'employee.employee_number'},
            {header:'EMP NAME',key:'employee.name'},
            {header:'COST CENTER',key:'employee.contract_cache.cost_center'},
            {header:'DEPARTMENT',key:'employee.contract_cache.department'},
            {header:'SECTION',key:'employee.contract_cache.section'},
            {header:'JOB TITLE',key:'employee.contract_cache.job_title'},
            {header:'JOB LEVEL',key:'employee.contract_cache.job_level'},
            {header:'COMPANY',key:'employee.metadata.company'},
            {header:'GROSS SALARY',key:'basic_pay'},
            {header:'NETT SALARY',key:'payable_wage'},
            {header:'TOTAL OVERTIME',key:'total_overtime'},
            {header:'TOTAL ALLOWANCE',key:'total_allowance'},
            {header:'TOTAL FIXED RATE', key:'total_fixed'}
        ]

        for(const head of header){
            const name = head.name;
            const headerName = name.replace('-','.');
            const key = `aggrid.${name.replace('.','-')}.amount`;
            columns.push({header:headerName,key});
        }
        columns.push({header:'CPF EMPLOYEE',key:'cpf_employee'});
        for(const shg_item of props.selflist){
            const headerName = shg_item.value;
            const key = `shg.${headerName}.amount`;
            columns.push({header:headerName,key});
        }
        columns.push({header:'CPF EMPLOYER',key:'cpf_employer'});
        columns.push({header:'SDL',key:'sdl'});
        columns.push({header:'TOTAL EMPLOYER',key:'total_employer'});

        let size = [];
        for(const column of columns){
            const header = column.header;
            const length = header.length;
            if(length <15){
                size.push({width:15});
            }
            else{
                size.push({width:length});
            }
        }

        for(let i=0;i<option.length;i++){
            const option_name = option[i].name;
            const ws = wb.addWorksheet(option_name);
            ws.getCell('A1').value = 'Payroll Monthly Report - ' + payroll_title ;
            ws.getCell('A2').value = 'Print On : '+today+' ' + time;

            for(let j =0;j<columns.length;j++){
                const headerName = columns[j].header;
                const field = columns[j].key;
                ws.getCell(getColumnName(j)+'4').value = headerName;
                const length = option_data[option_name].length +1;
                for(let x =0;x<length;x++){
                    const step = 5+x;
                    if(x<length-1){
                        const item = getValue(option_data[option_name][x],field);
                        if(field === 'employee.employee_number' || field === 'employee.name' || field === 'employee.contract_cache.cost_center' || field === 'employee.contract_cache.department' || field === 'employee.contract_cache.section' || field === 'employee.contract_cache.job_title' || field === 'employee.contract_cache.job_level' || field === 'employee.metadata.company' ){
                            ws.getCell(getColumnName(j)+step).value = item == null ? '-' : item;
                        } 
                        else{
                            ws.getCell(getColumnName(j)+step).value = item == null ? '-' : `$${item.toFixed(2)}`;
                        }
                    }
                    else if(x === length -1){
                        const item = getValue(bottom_data[option_name],field);
                        if(field === 'employee.employee_number' || field === 'employee.name' || field === 'employee.contract_cache.cost_center' || field === 'employee.contract_cache.department' || field === 'employee.contract_cache.section' || field === 'employee.contract_cache.job_title' || field === 'employee.contract.job_level' || field === 'employee.metadata.company' ){
                            ws.getCell(getColumnName(j)+step).value = item ?? '-';
                        }
                        else{
                            if(item != null){
                                ws.getCell(getColumnName(j)+step).value = `$${item.toFixed(2)}`;
                            }
                            else{
                                ws.getCell(getColumnName(j)+step).value = '-';
                            }
                        }
                    }
                }
            }
            ws.columns = size;
        }
        const file_name = `${payroll_name}.xlsx`;
        wb.xlsx.writeBuffer().then((buf)=>{
            
            var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
            FileSaver.saveAs(file, file_name)
        });
        setState(prevState=>({...prevState,is_pending:false}))
    },[monthNames1,props.selflist,state.selected_option,state.selected_from,state.selected_to])

    const generateSection=useCallback((data,header)=>{
        const option = [];
        const option_data = {};
        const bottom_data = {};
        for(const item of data){
            const type = item.employee.contract_cache?.[state.selected_option]
            const type_detail = option.find(item=>item.name === type);
            if(!type_detail){
                option.push({name:type});
            }
        }

        for(const option_item of option){
            const name = option_item.name;
            const detail = data.filter(item=>item.employee.contract_cache[state.selected_option] === name);
            option_data[name] = detail;

            let aggrid = {};
            let shg = {};
            for(const head of header){
                const name = head.name;
                aggrid[name] = {amount:0};
            }

            for(const shg_item of props.selflist){
                const name = shg_item.value;
                shg[name] = {amount:0};
            }
            const deductables = detail.reduce((prev,current)=>prev+current.deductables,0);
            const total_wage = detail.reduce((prev,current)=>prev+current.total_wage,0);
            const payable_wage = detail.reduce((prev,current)=>prev+current.payable_wage,0);
            const basic_pay = detail.reduce((prev,current)=>prev+current.basic_pay,0);
            const cpf_employee = detail.reduce((prev,current)=>prev+current.cpf_employee,0);
            const cpf_employer = detail.reduce((prev,current)=>prev+current.cpf_employer,0);
            const sdl = detail.reduce((prev,current)=>prev+current.sdl,0);
            const sg_deductables  = detail.reduce((prev,current)=>prev+current.sg_deductables,0);
            const total_allowance = detail.reduce((prev,current)=>prev+current.total_allowance,0);
            const total_employer = detail.reduce((prev,current)=>prev+current.total_employer,0);
            const total_fixed = detail.reduce((prev,current)=>prev+current.total_fixed,0);
            const total_overtime = detail.reduce((prev,current)=>prev+current.total_overtime,0);

            for(const head of header){
                const name  = head.name;
                for(const emp_data of detail){
                    const emp_aggrid = emp_data.aggrid;
                    if(emp_aggrid.hasOwnProperty(name)){
                        const amount = aggrid[name].amount;
                        const amount1 = emp_aggrid[name].amount;
                        aggrid[name] = {amount:amount+amount1};
                    }
                }
            }

            for(const shg_item of props.selflist){
                const name = shg_item.name;
                for(const emp_data of detail){
                    const emp_shg = emp_data.shg;
                    if(emp_shg.hasOwnProperty(name)){
                        const amount = shg[name].amount;
                        const amount1 = emp_shg[name].amount;
                        shg[name] = {amount:amount+amount1};
                    }
                }
            }
            bottom_data[name] = {shg,employee:{employee_number:'Total'},aggrid,deductables,total_wage,payable_wage,basic_pay,cpf_employee,cpf_employer,sdl,sg_deductables,total_allowance,total_employer,total_fixed,total_overtime}
        }
        generateSectionExcel(option,option_data,bottom_data,header);
    },[state.selected_option,props.selflist,generateSectionExcel])

    const generateEmpExcel=useCallback((data,bottom_data,header)=>{
        const wb = new ExcelJs.Workbook();
        let payroll_name = '';
        let payroll_title = '';

        if(state.selected_from === state.selected_to){
            const month = new Date(state.selected_from).getMonth();
            const month1 = monthNames1[month];
            const year = new Date(state.selected_from).getFullYear();
            payroll_name = `MonthlyReport_${month1}_${year}`;
            payroll_title = `${month1} ${year}`;
        }
        else{
            const from_month = new Date(state.selected_from).getMonth();
            const from_month2 = monthNames1[from_month];
            const from_year = new Date(state.selected_from).getFullYear();
            const to_month = new Date(state.selected_to).getMonth();
            const to_month2 = monthNames1[to_month];
            const to_year = new Date(state.selected_to).getFullYear();
            payroll_name = `MonthlyReport_${to_month2}_${to_year}_to_${from_month2}_${from_year}`;
            payroll_title = `${to_month2} ${to_year} to ${from_month2} ${from_year}`;
        }

        const ws = wb.addWorksheet('monthly_report');
        const today = moment().format('DD/MM/YYYY');
        const time = moment().format('HH:mm:ss');
        ws.getCell('A1').value = `Payroll Monthly Report - ${payroll_title}`;
        ws.getCell('A2').value = `Print On : ${today} ${time}`;

        const columns = [
            {header:'EMP ID',key:'employee.employee_number'},
            {header:'EMP NAME',key:'employee.name'},
            {header:'COST CENTER',key:'employee.contract_cache.cost_center'},
            {header:'DEPARTMENT',key:'employee.contract_cache.department'},
            {header:'SECTION',key:'employee.contract_cache.section'},
            {header:'JOB TITLE',key:'employee.contract_cache.job_title'},
            {header:'JOB LEVEL',key:'employee.contract_cache.job_level'},
            {header:'COMPANY',key:'employee.metadata.company'},
            {header:'GROSS SALARY',key:'basic_pay'},
            {header:'NETT SALARY',key:'payable_wage'},
            {header:'TOTAL OVERTIME',key:'total_overtime'},
            {header:'TOTAL ALLOWANCE',key:'total_allowance'},
            {header:'TOTAL FIXED RATE', key:'total_fixed'}
        ]

        for(const head of header){
            const name = head.name;
            const headerName = name.replace('-','.');
            const key = `aggrid.${name.replace('.','-')}.amount`;
            columns.push({header:headerName,key});
        }

        columns.push({header:'CPF EMPLOYEE',key:'cpf_employee'});

        for(const shg_item of props.selflist){
            const key = `shg.${shg_item.value}.amount`;
            columns.push({header:shg_item.value,key});
        }

        columns.push({header:'CPF EMPLOYER',key:'cpf_employer'});
        columns.push({header:'SDL',key:'sdl'});
        columns.push({header:'TOTAL EMPLOYER',key:'total_employer'});
        let size = [];
        for(const column of columns){
            const {length} = column.header;
            if(length <15){
                size.push({width:15});
            }
            else{
                size.push({width:length});
            }
        } 
        ws.columns = size;
        for(let i=0;i<columns.length;i++){
            const headerName = columns[i].header;
            const field = columns[i].key;
            const length = data.length +1;
            ws.getCell(getColumnName(i)+'4').value = headerName;
            for(let x=0;x<length;x++){
                const step = 5+x;
                if(x<length-1){
                    const item = getValue(data[x],field);
                    if(field === 'employee.employee_number' || field === 'employee.name' || field === 'employee.contract_cache.cost_center' || field === 'employee.contract_cache.department' || field === 'employee.contract_cache.section' || field === 'employee.contract_cache.job_title' || field === 'employee.contract_cache.job_level' || field === 'employee.metadata.company' ){
                        if(item == null){
                            ws.getCell(getColumnName(i)+step).value = '-';
                        }
                        else{
                            ws.getCell(getColumnName(i)+step).value = item;
                        }
                    }
                    else{
                        if(item == null){
                            ws.getCell(getColumnName(i)+step).value = '-';
                        }
                        else{
                            ws.getCell(getColumnName(i)+step).value = `$${item.toFixed(2)}` ;
                        }
                    }
                }
                else if(x === length-1){
                    const item = getValue(bottom_data[0],field);
                    if(field === 'employee.employee_number' || field === 'employee.name' || field === 'employee.contract_cache.cost_center' || field === 'employee.contract_cache.department' || field === 'employee.contract_cache.section' || field === 'employee.contract_cache.job_title' || field === 'employee.contract_cache.job_level' || field === 'employee.metadata.company' ){
                        if(item !== null){
                            ws.getCell(getColumnName(i)+step).value = item;
                        }
                        
                    }
                    else{
                        if(item !== null ){
                            ws.getCell(getColumnName(i)+step).value = `$${item.toFixed(2)}`;
                        }
                        else{
                            ws.getCell(getColumnName(i)+step).value = '-';
                        }
                    }
                }
            }
        }
        const file_name = payroll_name + '.xlsx';
        wb.xlsx.writeBuffer().then((buf)=>{
            var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
            FileSaver.saveAs(file, file_name)
        });
        setState(prevState=>({...prevState,is_pending:false}))

    },[monthNames1,props.selflist,state.selected_from,state.selected_to])

    const generateEmployee=useCallback((data,header)=>{
        const bottom_data = [];
        const aggrid = {};
        const shg = {};

        for(const head of header){
            aggrid[head.name] = { amount :0 };
        }

        for(const shg_item of props.selflist){
            shg[shg_item.value] = { amount :0 };
        }

        const deductables = data.reduce((prev,current)=>prev+current.deductables,0);
        const total_wage = data.reduce((prev,current)=>prev+current.total_wage,0);
        const payable_wage = data.reduce((prev,current)=>prev+current.payable_wage,0);
        const basic_pay = data.reduce((prev,current)=>prev+current.basic_pay,0);
        const cpf_employee = data.reduce((prev,current)=>prev+current.cpf_employee,0);
        const cpf_employer = data.reduce((prev,current)=>prev+current.cpf_employer,0);
        const sdl = data.reduce((prev,current)=>prev+current.sdl,0);
        const sg_deductables = data.reduce((prev,current)=>prev+current.sg_deductables,0);
        const total_allowance = data.reduce((prev,current)=>prev+current.total_allowance,0);
        const total_employer = data.reduce((prev,current)=>prev+current.total_employer,0);
        const total_fixed = data.reduce((prev,current)=>prev+current.total_fixed,0);
        const total_overtime = data.reduce((prev,current)=>prev+current.total_overtime,0);

        for(const head of header){
            const name = head.name;
            for(const emp_data of data){
                const emp_aggrid = emp_data.aggrid;
                if(emp_aggrid.hasOwnProperty(name)){
                    const amount = aggrid[name].amount;
                    const amount1 = emp_aggrid[name].amount;
                    aggrid[name] = { amount : amount + amount1 };
                }
            }
        }

        for(const shg_item of props.selflist){
            const name = shg_item.value;
            for(const emp_data of data){
                const emp_shg = emp_data.shg;
                const amount = shg[name].amount;
                const amount1 = emp_shg[name].amount;
                shg[name] = { amount : amount + amount1};
            }
        }

        bottom_data.push({shg,employee:{employee_number:'Total'},aggrid,deductables,total_wage,payable_wage,basic_pay,cpf_employee,cpf_employer,sdl,sg_deductables,total_allowance,total_employer,total_fixed,total_overtime});
        generateEmpExcel(data,bottom_data,header);
    },[generateEmpExcel,props.selflist])

    const filterData = useCallback((rowData,emp)=>{
        const header = [];
        const arr = [];
        for(const payslip of rowData){
            for(const item of payslip){
                const {sgcpf,payslipcontract,pay,sgshg,employee} = item;
                if(emp.includes(employee.id)){
                    const cpf_employee = sgcpf?.cpf_employee ?? 0;
                    const cpf_employer = sgcpf?.cpf_employer ?? 0;
                    const basic_pay = payslipcontract.reduce((prev,current)=>prev+current.gross_month,0);
                    let aggrid={};
                    let shg={};
                    for(const pay_item of pay){
                        const name = pay_item?.name ? (pay_item.name).replace('.','-') : '';
                        const amount = pay_item.amount;
                        const id = pay_item.id;
                        aggrid[name] = { amount,id };
                        const header_detail = header.find(el=>el.name === name);
                        if(!header_detail){
                            header.push({name});
                        }
                    }
    
                    for(const shg_item of sgshg){
                        shg[shg_item.shg] = {amount:shg_item.amount,id:shg_item.id};
                    }
                    const total_overtime = pay.filter(item=>item.pay_type === 'OT').reduce((prev,current)=>prev+current.amount,0);
                    const total_allowance = pay.filter(item=>item.pay_type === 'ALLOWANCE').reduce((prev,current)=>prev+current.amount,0);
                    const total_fixed = pay.filter(item=>item.pay_type==='FX').reduce((prev,current)=>prev+current.amount,0);
                    arr.push({...item,total_fixed,total_allowance,total_overtime,shg,aggrid,basic_pay,cpf_employee,cpf_employer});
                }
            }
        }
        const data = [];
        for(const emp_data of emp){
            const detail = arr.filter(el => el.employee.id === emp_data);
            if(detail.length !== 0){

                let aggrid = {};
                let shg = {};
                for(const head of header){
                    aggrid[head.name] = {amount :0 };
                }
                for(const shg_item of props.selflist){
                    shg[shg_item.value] = {amount :0};
                }
                const employee = detail[0]?.employee;
                const deductables = detail.reduce((prev,current)=>prev+current.deductables,0);
                const total_wage = detail.reduce((prev,current)=>prev+current.total_wage,0);
                const payable_wage = detail.reduce((prev,current)=>prev+current.payable_wage,0);
                const basic_pay = detail.reduce((prev,current)=>prev+current.basic_pay,0);
                const cpf_employee = detail.reduce((prev,current)=>prev+current.cpf_employee,0);
                const cpf_employer = detail.reduce((prev,current)=>prev+current.cpf_employer,0);
                const sdl = detail.reduce((prev,current)=>prev+current.sdl,0);
                const sg_deductables = detail.reduce((prev,current)=>prev+current.sg_deductables,0);
                const total_allowance = detail.reduce((prev,current)=>prev+current.total_allowance,0);
                const total_employer = detail.reduce((prev,current)=>prev+current.total_employer,0);
                const total_fixed = detail.reduce((prev,current)=>prev+current.total_fixed,0);
                const total_overtime = detail.reduce((prev,current)=>prev+current.total_overtime,0);
                
                for(const head of header){
                    const name = head.name;
                    for(const emp_item of detail){
                        const emp_aggrid = emp_item.aggrid;
                        if(emp_aggrid.hasOwnProperty(name)){
                            const amount = aggrid[name].amount;
                            const amount1 = emp_aggrid[name].amount;
                            aggrid[name] = {amount:amount+amount1};
                        }
                    }
                }

                for(const shg_item of props.selflist){
                    const name = shg_item.value;
                    for(const emp_item of detail){
                        const emp_shg = emp_item.shg;
                        if(emp_shg.hasOwnProperty(name)){
                            const amount = shg[name].amount;
                            const amount1 = emp_shg[name].amount;
                            shg[name] = { amount : amount + amount1 };
                        }
                    }
                }
                data.push({employee,aggrid,deductables,total_wage,payable_wage,basic_pay,cpf_employee,cpf_employer,sdl,sg_deductables,total_allowance,total_employer,total_fixed,total_overtime,shg});
            }
        }

        if(data.length !== 0 ){
            if(state.selected_option === 'employee'){
                generateEmployee(data,header);
            }
            else{
                generateSection(data,header);
            }
        }
   
    },[props.selflist,generateEmployee,state.selected_option,generateSection])

    // useEffect(()=>{
    //     if(props.list.length !== 0 && state.is_pending){
    //         const emp = state.selected_emp.map((item)=>item.id);
    //         const data = props.list.map((item)=>item.payslips);
    //         filterData(data,emp);
    //     }
    // },[props.list,state.is_pending,filterData,state.selected_emp])




    useEffect(()=>{
        if(props.instance.length !== 0){
            // setState(prevState=>({...prevState,is_pending:true}));
            
            let idd = props.instance.map((j) => j);
            console.log('current instance iteration:')
            console.log(idd)

            payrollArray.push(...idd);

            console.log('payrollArray:')
            console.log(payrollArray)
                
        }
        
    },[get_payroll_instance_array2,props.instance])

    useEffect(()=>{
        // if(props.instance.length !== 0 && state.is_pending){
        if(props.instance[0]?.loop === (props.list.length-1) && state.is_pending){

            const emp = state.selected_emp.map((item)=>item.id);
            let data  = [];

            // for(const item of props.instance){
            //     const {payslips} = item;
            //     data.push(payslips);
            // }
            console.log('finalPayrollArray:')
            console.log(payrollArray)

            for(const item of payrollArray){
                const {payslips} = item;
                data.push(payslips);
            }
            filterData(data,emp);
        }
    },[props.list,state.selected_emp,state.is_pending,filterData,props.instance])
    
    useEffect(()=>{
        if(props.list != null && state.is_pending){
            let idarr = []
            for(let x=0;x<props.list.length;x++){
                let idd = props.list[x]
                idarr.push(idd)          
            }

            get_payroll_instance_array2(idarr);
            setState(prevState=>({...prevState,is_pending:true}));
        }
    },[props.list,get_payroll_instance_array2,state.is_pending])

    useEffect(()=>{
        if(props.instance.length !== 0){
            if(props.instance[0]?.loop < (props.list.length-1) || props.instance[0]?.loop === null || props.instance[0]?.loop===undefined){
                setExportLoading(true)
            }else{
                setExportLoading(false)
                reset_payroll_instance_array2();
                reset_payroll_date();
                payrollArray = []
                console.log('clearAll')
            }
        }
        },[props.list,setExportLoading,props.instance,props.instance.loop,reset_payroll_instance_array2,reset_payroll_date])

    const from_index = props.payroll_list.findIndex(item=>item.end_date === state.from_payroll);
    // const from_index = props.payroll_list.findIndex(item => `${item.type}-${new Date(item.end_date).getMonth()}-${new Date(item.end_date).getFullYear()}` === state.from_payroll);
    const arr2  = from_index === -1 ? [] :  props.payroll_list.slice(from_index,props.payroll_list.length);
    // const arr2 = from_index === -1 ? [] : props.payroll_list.slice(from_index);

    return(

        <>
        {exportLoading ?
        <>
        <OverlayLoading/> 
        <ProgressBar value={props.instance[0]?.loop} maxValue={props.list.length-1}  />
        </>
        :
        // {props.isLoading1 || props.isLoading2 ? <OverlayLoading/> :
        <>
        <Container fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    <BreadCrumbList list={[
                        { active: false, title: IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: true, title: IMLocalized('monthly_report_consolidated')}
                    ]}/>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col className='d-flex align-items-center'>
                    <Input size="sm" type="select" value={state.from_payroll} style={{width:'150px'}}
                    onChange={(e)=>{
                        const value = e.target.value;
                        setState(prevState=>({...prevState,from_payroll:value,to_payroll:''}))
                        setFromDate(value)
                    }}
                    >
                        <option>{IMLocalized('select_payroll')}</option>
                        {props.payroll_list.map((item,index)=>{
                            // const date = item.payroll_date;
                            const date = item.end_date;
                            const month = new Date(date).getMonth();
                            const year = new Date(date).getFullYear();
                            // return <option value={date} key={index}>{monthNames[month]} {year}</option>
                            // const optionValue = `${item.type}-${month}-${year}`
                            return <option value={date} key={index}>{item.type==="BONUS"?"Bonus ":item.type==="HMTH1"?"Half of ":""}{monthNames[month]}  {year}</option>
                        })}
                    </Input>
                    <span className='ml-2 mr-2'>{IMLocalized('to')}</span>
                    <Input size="sm" type="select" value={state.to_payroll} style={{width:'150px'}}
                    onChange={(e)=>{
                        const value = e.target.value;
                        setState(prevState=>({...prevState,to_payroll:value}))
                        setToDate(value)
                    }}
                    >
                        <option>{IMLocalized('select_payroll')}</option>
                        {arr2.map((item,index)=>{
                            // const date = item.payroll_date;
                            const date = item.start_date;
                            const month = new Date(date).getMonth();
                            const year  = new Date(date).getFullYear();
                            // return <option value={date} key={index}>{monthNames[month]} {year}</option>
                            // const optionValue = `${item.type}-${month}-${year}`
                            return <option value={date} key={index}>{item.type==="BONUS"?"Bonus ":item.type==="HMTH1"?"Half of ":""}{monthNames[month]}  {year}</option>
                        })}
                    </Input>
                </Col>
            </Row>
            <div className='mt-2 d-flex justify-content-between'>
                <Input type="select" size="sm" name="select_option" value={state.select_option} style={{width:'150px'}}
                onChange={(e)=>{
                    const value = e.target.value;
                    setState(prevState=>({...prevState,select_option:value}));
                }}
                >
                    <option value='employee'>{IMLocalized('employee')}</option>
                    <option value='section'>{IMLocalized('section')}</option>
                    <option value='department'>{IMLocalized('department')}</option>
                    <option value='job_level'>{IMLocalized('job_level')}</option>
                    <option value='cost_center'>{IMLocalized('cost_center')}</option>
                </Input>
                <div className='d-flex'>
                    {/* <div className='custom-control custom-checkbox'>
                        <input type="checkbox" id='monthlyreport_active' name="active_employee" className="custom-control-input" checked={state.active_employee} 
                        onChange={(e)=>{
                            const checked = e.target.checked;
                            setState(prevState=>({...prevState,active_employee:checked}))
                        }}/>
                        <label className="custom-control-label" htmlFor='monthlyreport_active'>{IMLocalized('active_user')}</label>
                    </div>
                    <div className='custom-control custom-checkbox ml-2 mr-2'>
                        <input type="checkbox" id="monthlyreport_resignee" name="resignee" className="custom-control-input" checked={state.resignee}
                        onChange={(e)=>{
                            const checked = e.target.checked;
                            setState(prevState=>({...prevState,resignee:checked}))
                        }}
                        />
                        <label className='custom-control-label' htmlFor='monthlyreport_resignee' >{IMLocalized('resignee')}</label>
                    </div> */}
                    <div>
                        {props.isLoading || exportLoading? <button className="btn btn-success btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button> :
                        <button className="btn btn-success btn-sm"
                        onClick={()=>{
                            if(state.from_payroll !== ''){
                                const to_payroll = state.to_payroll !== '' ? state.to_payroll : state.from_payroll;
                                const data =gridApi.current.getSelectedRows();
                                if(data.length !== 0 ){
                                    props.monthly_report_date_filter(to_payroll,state.from_payroll);
                                    setState(prevState=>({...prevState,is_pending:true,selected_emp:data,selected_option:state.select_option,selected_from:state.from_payroll,selected_to:to_payroll}))
                                    setExportLoading(true)
                                }
                                else{
                                    popError(IMLocalized('select_employee_to_export'));
                                }
                            }
                            else{
                                popError(IMLocalized('pls_select_payroll_month'));
                            }
                        }}>{IMLocalized('export')}</button>}
                    </div>
                </div>
            </div>
            <div className='mt-2 ag-theme-balham' style={{height:action_toggle? 'calc(100vh - 288px)' : 'calc(100vh - 252px)' , width:'100%' }}>
                <AgGridReact
                    columnDefs={
                        [
                            {
                                headerName:IMLocalized('agrid_select'),
                                field:'is_select',
                                headerCheckboxSelection: isFirstColumn,
                                checkboxSelection: isFirstColumn,
                                headerCheckboxSelectionFilteredOnly:true,
                                editable:false,
                                width:150,
                                filter:false,
                                pinned:'left',
                                lockPosition:true
                            },
                            {
                                headerName:IMLocalized('agrid_emp_id'),
                                field:'employee_number',
                                editable:false,
                                width:150,
                                filter:true,
                            },
                            {
                                headerName:IMLocalized('agrid_emp_name'),
                                field:'name',
                                editable:false,
                                width:150,
                                filter:true,
                            },
                            {
                                headerName:IMLocalized('agrid_cost_center'),
                                field:'contract_cache.cost_center',
                                editable:false,
                                width:150,
                                filter:true
                            },
                            {
                                headerName:IMLocalized('agrid_department'),
                                field:'contract_cache.department',
                                editable:false,
                                width:150,
                                filter:true
                            },
                            {
                                headerName:IMLocalized('agrid_section'),
                                field:'contract_cache.section',
                                editable:false,
                                width:150,
                                filter:true
                            },
                            {
                                headerName:IMLocalized('agrid_job_title'),
                                field:'contract_cache.job_title',
                                editable:false,
                                width:150,
                                filter:true
                            },
                            {
                                headerName:IMLocalized('agrid_job_level'),
                                field:'contract_cache.job_level',
                                editable:false,
                                width:150,
                                filter:true
                            }
                        ]
                    }
                    gridOptions={gridOptions}
                    rowData={state.rowData}
                    suppressDragLeaveHidesColumns={true}
                    suppressSizeToFit={true}
                    suppressColumnMoveAnimation={false}
                    onGridReady={(params)=>{
                        gridApi.current = params.api;
                        gridColumnApi.current = params.columnApi;
                    }}
                    overlayNoRowsTemplate = {IMLocalized('no_data')}
                    >
                </AgGridReact>
            </div>
        </Container>
        </>}
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(MonthReport2);
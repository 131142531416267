import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import ModalUserApplyClaim from './modal/ModalUserApplyClaim';


const mapStateToProps=(state)=>({
    availablelist:state.user_get_available_claim.data,
    claimlist:state.get_claim_list.data,
    data:state.user_self.data,
    approval_group:state.user_claim_approval_group.data,
})

const mapDispatchToProps=(dispatch)=>({

})

function UserEmployeeClaim(props){

    const [approval_name,setApprovalName] = useState('');
    const [claim_approvers,setClaimApprovers] = useState([]);
    const [claim_exceptions,setClaimExceptions] = useState([]);
    const [apply_toggle,setApplyToggle] = useState(false);
    const [claim_id,setClaimID] = useState(null);
    const [claim_name,setClaimName] = useState(null);

    const approvalRef = useRef();

    useEffect(()=>{
        if(props.approval_group != null){
            const {exceptions,approvers,name} = props.approval_group;
            setClaimApprovers(approvers);
            setClaimExceptions(exceptions);
            setApprovalName(name);
        }
    },[props.approval_group])

    return(
        <>
        <div className='d-flex justify-content-end align-items-center text-primary pt-1 pr-2'>
            <div className='cursor-pointer' onClick={()=>approvalRef.current.scrollIntoView({block:'start',behavior:'smooth'})}>
                <i className='fas fa-angle-down mr-1 function_icon' />
                <small>{IMLocalized('scroll_down')}</small>
            </div>
        </div>
        <div className='d-flex align-items-center pl-2 emp-tabcontent-title'>
            <i className='fas fa-money-check text-primary' />
            <h4 className='text-primary pl-2 m-0'>{IMLocalized('employee_claim')}</h4>
        </div>
        {props.availablelist.length === 0 && <h4 className='p-2'>{IMLocalized('no_claim_scheme_found')}</h4>}
        {props.availablelist.length !== 0 && 
        <>
        <Table className='mt-2 align-items-center table-bordered' size="sm" responsive>
            <thead className='thead-light text-center'>
                <tr>
                    <th>{IMLocalized('claim_type')}</th>
                    <th>{IMLocalized('current_month_balance')}</th>
                    <th>{IMLocalized('apply')}</th>
                </tr>
            </thead>
            <tbody className='text-center'>
                {props.availablelist.map((item,index)=>{
                    const current = new Date().getMonth();
                    const detail = props.claimlist.find((el)=>el.id === item.claim_type);
                    return(
                        <tr key={index}>
                            <td><span className='cursor-pointer text-primary' onClick={()=>props.toClaimDetail(item.claim_type)}>{detail?.name ?? '-'}</span></td>
                            <td>$ {item.is_monthly ? item.remaining_monthly[current] : item.remaining}</td>
                            <td><button className='btn btn-primary btn-sm' onClick={()=>{setApplyToggle(true);setClaimID(item.claim_type);setClaimName(detail?.name ?? null)}}>{IMLocalized('apply')}</button></td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
        </>}
        <div className='d-flex justify-content-between align-items-center mt-2 pl-2 emp-tabcontent-title'>
            <div className='d-flex align-items-center'>
                <i className='fas fa-money-check text-primary' />
                <h4 className='text-primary pl-2 m-0'>{IMLocalized('employee_claim_scheme')}</h4>
            </div>
        </div>
        <h4 className='p-2'>
        {props.data.length !== 0 && props.data?.metadata?.claim_scheme?.name ?
        props.data.metadata.claim_scheme.name: IMLocalized('no_data')}
        </h4>
        <div ref={approvalRef}>
            <div className='d-flex align-items-center pl-2 emp-tabcontent-title'>
                <i className='fas fa-money-check text-primary' />
                <h4 className='text-primary pl-2 m-0'>{IMLocalized('claim_approval')}</h4>
            </div>
        </div>
        {claim_approvers.length === 0 && <h4 className='p-2'>{IMLocalized('no_data')}</h4>}
        {claim_approvers.length !== 0 && claim_exceptions.length !== 0 &&
        <>
            <h4 className='p-2'>{approval_name}</h4>
            {claim_approvers.map((item,index)=>{
                const group = claim_exceptions.filter(el=>el.group === index);
                const arr_table = group.map((el,index1)=>{
                    const detail = props.claimlist.find((item1)=>item1.id === el.claim_type);

                    return (
                        <div key={'exception'+index1} className="pt-1"><span className="mr-2" >●</span><span>{detail?.name ?? '-'}</span></div>
                    )
                })

                const arr_approvers = item.map((data,index1)=>{
                    const data1 = data.map((item1,index2)=>{
                        return <div key={'approvers'+index2}><span>{item1.name} - ({IMLocalized('level')} : {index1+1})</span></div>
                    })

                    return data1;
                })

                if(arr_table.length !== 0 ){
                    return(
                        <div className='px-2' key={index}>
                            <h4>{IMLocalized('group')} {index+1}</h4>
                            <Table size="sm" className='table-bordered' responsive>
                                <thead className='thead-light'>
                                    <tr>
                                        <th className="w-50">{IMLocalized('claim_type')}</th>
                                        <th>{IMLocalized('approval_level')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{arr_table}</td>
                                        <td>{arr_approvers}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    )
                }
                return null;
            })}
        </>}
        <ModalUserApplyClaim toggle={apply_toggle} has_claim={true} claim_id={claim_id} claim_name={claim_name}
        closeModal={()=>{
            setApplyToggle(false);
            setClaimID(null);
            setClaimName(null);
        }} />
        </>
    )
}

export default connect(mapStateToProps,mapDispatchToProps)(UserEmployeeClaim);
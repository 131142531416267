import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {Modal,ModalBody,ModalFooter,ModalHeader,Input,FormFeedback,Row,Col} from 'reactstrap';
import { create_company_bank, reset_create_company_bank } from '../../../action/company/create_company_bank';
import { IMLocalized } from '../../../language/IMLocalized';


const mapStateToProps = (state)=>({
    banks:state.company_dropdown.bank,
    errors:state.create_company_bank.errors,
    isLoading:state.create_company_bank.isLoading,
    success:state.create_company_bank.data,
})

const mapDispatchToProps = (dispatch)=>({
    create_company_bank:(id,bank,acc_number)=>{
        dispatch(create_company_bank(id,bank,acc_number))
    },
    reset_create_company_bank:()=>{
        dispatch(reset_create_company_bank())
    }
})

function ModalCreateBank(props){
    const {success,onSuccess,reset_create_company_bank} = props;
    const [bank,setBank] = useState('');
    const [err_bank,setErrBank] = useState('');
    const [acc_number,setAccNumber] = useState('');
    const [err_acc_number,setErrAccNumber] = useState('');

    useEffect(()=>{
        if(props.toggle){
            setAccNumber('');
            setBank('');
            setErrAccNumber('');
            setErrBank('');
        }
    },[props.toggle])

    useEffect(()=>{
        if( props.toggle && success !== null ){
            onSuccess();
            reset_create_company_bank();
        }
    },[success,props.toggle,onSuccess,reset_create_company_bank])


    const createBank=()=>{
        if(acc_number !== '' && bank !== ''){
            props.create_company_bank(props.id,bank,acc_number)
        }
        else{
            if(acc_number === ''){
                setErrAccNumber(IMLocalized('account_number_is_required'));
            }
            if(bank === ''){
                setErrBank(IMLocalized('bank_is_required'))
            }
        }
    }

    return(
        <Modal isOpen={props.toggle} size="lg">
            <ModalHeader toggle={props.closeModal}>{IMLocalized('create_company_bank')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <div className='form-group'>
                            <label className='form-control-label' htmlFor='bank'>{IMLocalized('bank')}</label>
                            <Input name="bank" size="sm" id="bank" value={bank} type="select" invalid={err_bank.length >0} onChange={(e)=>{setBank(e.target.value) ; setErrBank('')}}>
                                <option></option>
                                {props.banks.map((item)=>{
                                    return <option value={item.value} key={item.value}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_bank}</FormFeedback>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className='form-group'>
                            <label className='form-control-label' htmlFor='account_number'>{IMLocalized('account_number')}</label>
                            <Input name="acc_number" size="sm" id="account_number" value={acc_number} invalid={err_acc_number.length >0} onChange={(e)=>{setAccNumber(e.target.value); setErrAccNumber('')}}/>
                            <FormFeedback>{err_acc_number}</FormFeedback>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
            {props.isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button> : 
            <>
                <button className='btn btn-primary btn-sm' onClick={createBank}>{IMLocalized('create')}</button>
                <button className='btn btn-danger btn-sm' onClick={props.closeModal}>{IMLocalized('close')}</button>
            </>
            }
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalCreateBank);
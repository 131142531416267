import * as day from '../../action/report/report_get_report_day';


export const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const report_get_report_day =(state=initialState,action)=>{
    switch(action.type){
        case day.REPORTGETREPORTDAY_REQUEST:
            return{
                isLoading:true,
                ...state,
            }
        case day.REPORTGETREPORTDAY_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case day.REPORTGETREPORTDAY_FAILURE:
            return{
                ...state,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default report_get_report_day;
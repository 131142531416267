import * as createpayroll from '../../action/payroll/create_new_payroll';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const create_new_payroll = (state=initialState,action)=>{
    switch(action.type){
        case createpayroll.CREATENEWPAYROLL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case createpayroll.CREATENEWPAYROLL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case createpayroll.CREATENEWPAYROLL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default create_new_payroll;
import React, { useContext } from 'react';
import { Row,Col } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { UserContext } from '../UserPayrollDetail';
import NoPayTable from './NoPayTable';
import SelfHelpTable from './SelfHelpTable';
import BackpayTable from './BackpayTable';




export default function DeductationTable(props){

    const user = useContext(UserContext);

    let col1_amount = 0;
    let col2_amount = 0;
    const col1 = [];
    const col2 = [];

    if(user.nopaylist.length !== 0){
        col1.push(<NoPayTable type="nopay" list={user.nopaylist} payslipcontract={user.payslipcontract} />);
        col1_amount += user.nopaylist.length +2; 
    }

    let count = 0;
    if(user.sgcpf !== null){
        count += 1;
    }
    if(user.sgshg.length !== 0){
        count += user.sgshg.length;
    }

    if(count !== 0){
        if(col2_amount < col1_amount){
            col2.push(<SelfHelpTable sgcpf={user.sgcpf} sgshg={user.sgshg} />);
            col2_amount += user.sgshg.length +2;
        }
        else{
            col1.push(<SelfHelpTable sgcpf={user.sgcpf} sgshg={user.sgshg} />);
            col1_amount += user.sgshg.length +2;
        }
    }

    if(user.absentlist.length !== 0){
        if(col2_amount < col1_amount){
            col2.push(<NoPayTable type="absent" list={user.absentlist} payslipcontract={user.payslipcontract} />);
            col2_amount += user.absentlist.length +2;
        }
        else{
            col1.push(<NoPayTable type="absent" list={user.absentlist} payslipcontract={user.payslipcontract} />);
            col1_amount += user.absentlist.length +2;
        }
    }

    if(user.nationlist.length !== 0){
        if(col2_amount < col1_amount){
            col2.push(<NoPayTable type="nation" list={user.nationlist} payslipcontract={user.payslipcontract} />);
            col2_amount += user.nationlist.length +2;
        }
        else{
            col1.push(<NoPayTable type="nation" list={user.nationlist} payslipcontract={user.payslipcontract} />);
            col1_amount += user.nationlist.length +2;
        }
    }

    if(user.deductablelist.length !== 0){
        if(col2_amount < col1_amount){
            col2.push(<BackpayTable list={user.deductablelist} />);
            col2_amount += user.deductablelist.length +2;
        }
        else{
            col1.push(<BackpayTable list={user.deductablelist} />);
            col1_amount += user.deductablelist.length +2;
        }
    }

    return(
        <>
        <Row>
            <Col lg="12">
                <div className="d-flex justify-content-between pl-2 emp-tabcontent-title">
                    <div className="d-flex align-items-center">
                    <h2 className="title-color1 mb-0">{IMLocalized('payslip_title_deductions')}</h2>
                    </div>
                </div>
            </Col>
        </Row>
        <Row className='mt-2'>
            {col1_amount === 0 && col2_amount === 0 &&
            <Col>
                <h3 className="pl-2">{IMLocalized('payslip_no_deduction')}</h3>
            </Col>}
            {col1_amount !== 0 || col2_amount !== 0 ? 
            <>
            <Col lg="6">
                {col1}
            </Col>
            <Col lg="6">
                {col2}
            </Col>
            </>
            : null}
            
        </Row>
        </>
    )
}
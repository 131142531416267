import myaxios from '../../axios';

export const USERGETALLAPPLYLEAVE_REQUEST = 'USERGETALLAPPLYLEAVE_REQUEST';
export const USERGETALLAPPLYLEAVE_SUCCESS = 'USERGETALLAPPLYLEAVE_SUCCESS';
export const USERGETALLAPPLYLEAVE_FAILURE = 'USERGETALLAPPLYLEAVE_FAILURE';

export const user_get_all_apply_leave_list=()=>{
    return dispatch=>{
        dispatch({
            type:'USERGETALLAPPLYLEAVE_REQUEST'
        });

        myaxios.get('leave/all/apply/')
        .then((response)=>{
            dispatch({
                type:'USERGETALLAPPLYLEAVE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'USERGETALLAPPLYLEAVE_FAILURE',
                payload:error
            })
        })
    }
}
import myaxios from '../../axios';

export const GETMINRESIGNDATE_REQUEST = 'GETMINRESIGNDATE_REQUEST';
export const GETMINRESIGNDATE_SUCCESS = 'GETMINRESIGNDATE_SUCCESS';
export const GETMINRESIGNDATE_FAILURE = 'GETMINRESIGNDATE_FAILURE';

export const get_min_resign_date = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETMINRESIGNDATE_REQUEST'
        });

        myaxios.get('employee/employees/resignation_date_earliest/')
        .then((response)=>{
            dispatch({
                type:'GETMINRESIGNDATE_SUCCESS',
                payload:response
            })
        })
        .catch((errors)=>{
            dispatch({
                type:'GETMINRESIGNDATE_FAILURE',
                payload:errors
            })
        })
    }
}
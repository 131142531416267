import React from 'react';
import { Card, CardHeader, Col, Row, Table } from 'reactstrap';
import { IMLocalized } from '../../language/IMLocalized';


export default function AppraisalActionEmployee({detail,viewAppraisal,startAppraisal}){

    return(
        <Row className='mt-2'>
            <Col>
                {detail &&
                <Card>
                    <CardHeader>
                        <h4 className='mb-0'>{IMLocalized('appraisal')} - {detail?.name}</h4>
                    </CardHeader>
                    <Table size="sm" className="table-bordered align-items-center" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th>{IMLocalized('employee_id')}</th>
                                <th>{IMLocalized('employee_name')}</th>
                                <th>{IMLocalized('department')}</th>
                                <th>{IMLocalized('section')}</th>
                                <th>{IMLocalized('job_title')}</th>
                                <th>{IMLocalized('job_level')}</th>
                                <th>{IMLocalized('cost_center')}</th>
                                <th>{IMLocalized('submitted')}</th>
                                <th>{IMLocalized('percentage')} (%)</th>
                                <th>{IMLocalized('action')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {detail.appraisals.map((item,index)=>{
                                return(
                                    <tr key={index}>
                                        <td>{item.employee.employee_number}</td>
                                        <td>{item.employee.name}</td>
                                        <td>{item.employee.contract_cache?.department ?? '-'}</td>
                                        <td>{item.employee.contract_cache?.section ?? '-'}</td>
                                        <td>{item.employee.contract_cache?.job_title ?? '-'}</td>
                                        <td>{item.employee.contract_cache?.job_level ?? '-'}</td>
                                        <td>{item.employee.contract_cache?.cost_center ?? '-'}</td>
                                        <td>
                                            {item.submitted === true ? IMLocalized('yes') : IMLocalized('no')}
                                        </td>
                                        <td>{ item.total_score !== 0 && item.max_score !== 0 ? (item.total_score / item.max_score)*100 : 0}</td>
                                        <td>{<button className="btn btn-primary btn-sm" onClick={item.submitted === true ? ()=>viewAppraisal(item) : ()=>startAppraisal(item)} >{item.submitted === true ? IMLocalized('view') : IMLocalized('start')}</button>}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Card>}
            </Col>
        </Row>
    )
}
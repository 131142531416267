import * as detail from '../../action/payroll/get_payroll_increment_detail';

const initialState={
    isLoading:false,
    data:[],
    errors:null
}


const get_payroll_increment_detail = (state=initialState,action)=>{
    switch(action.type){
        case detail.GETPAYROLLINCREMENTDETAIL_REQUEST:
            return{
                isLoading:true,
                data:[],
                errors:null
            }
        case detail.GETPAYROLLINCREMENTDETAIL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
            }
        case detail.GETPAYROLLINCREMENTDETAIL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state
    }
}
export default get_payroll_increment_detail;
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const LEAVEASSIGNSCHEME_REQUEST = 'LEAVEASSIGNSCHEME_REQUEST';
export const LEAVEASSIGNSCHEME_SUCCESS = 'LEAVEASSIGNSCHEME_SUCCESS';
export const LEAVEASSIGNSCHEME_FAILURE = 'LEAVEASSIGNSCHEME_FAILURE';

export const leave_assign_scheme = (scheme,employees,full_proration,year)=>{
    return dispatch=>{
        dispatch({
            type:'LEAVEASSIGNSCHEME_REQUEST'
        });

        myaxios.post('leave/assign/scheme/',{
            scheme:scheme,
            employees:employees,
            full_proration:full_proration,
            year:year
        })
        .then((response)=>{
            dispatch({
                type:'LEAVEASSIGNSCHEME_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type:'success',
                title:IMLocalized('assign_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'LEAVEASSIGNSCHEME_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type: 'error',
                title:IMLocalized('assign_failure')
            });
        })
    }
}
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { update_employee_profile } from '../../../../action/employee/update_employee_profile';
import { usePrevious } from '../../../../hook/usePrevious';
import { get_employee_list } from '../../../../action/employee/get_employee_list';
import { get_employee_detail } from '../../../../action/employee/get_employee_detail';

const mapStateToProps=(state)=>({
    success:state.update_employee_profile.data,
    errors:state.update_employee_profile.errors,
    isLoading:state.update_employee_profile.isLoading,
    salutationlist:state.dropdown_list.salutation,
    nationalitylist:state.dropdown_list.countries,
    genderlist:state.dropdown_list.gender,
    racelist:state.dropdown_list.race,
    religionlist:state.dropdown_list.religion,
    relationshiplist:state.dropdown_list.relationship,
    paymentlist:state.dropdown_list.payment,
    companylist:state.company_list.data,
    branchlist:state.get_branch_list.data,
    sg_identification_type:state.dropdown_list.s_g_identification_type,
    sgpr_employee_cpf_payment:state.dropdown_list.sgpr_employee_cpf_payment,
})

const mapDispatchToProps=(dispatch)=>({
    update_employee_profile:(id,salutation,name,employee_number,badge_number,email,nationality,gender,race,religion,relationship,identification_number,contact_number,date_of_birth,company,branch,payment,id_type,join_date,probation_end_date,is_pr,pr_start_date,pr_cpf_contribution_type)=>{
        dispatch(update_employee_profile(id,salutation,name,employee_number,badge_number,email,nationality,gender,race,religion,relationship,identification_number,contact_number,date_of_birth,company,branch,payment,id_type,join_date,probation_end_date,is_pr,pr_start_date,pr_cpf_contribution_type));
    },
    get_employee_list:()=>{
        dispatch(get_employee_list())
    },
    get_employee_detail:(id)=>{
        dispatch(get_employee_detail(id))
    }
})

function ModalEditProfile(props){
    const {success,errors,closeModal,get_employee_detail,get_employee_list} = props;
    const [salutation,setSalutation] = useState('');
    const [err_salutation,setErrSalutation] = useState('');
    const [name,setName] = useState('');
    const [err_name,setErrName] = useState('');
    const [employee_number,setEmpNumber] = useState('');
    const [err_employee_number,setErrEmpNumber] = useState('');
    const [badge_number,setBadgeNumber] = useState('');
    const [err_badge_number,setErrBadgeNumber] = useState('');
    const [email,setEmail] = useState('');
    const [err_email,setErrEmail] = useState('');
    const [nationality, setNationality] = useState('');
    const [err_nationality,setErrNationality] = useState('');
    const [id_type,setIDType] = useState('');
    const [err_id_type,setErrIDType] = useState('');
    const [id_number,setIDNumber] = useState('');
    const [err_id_number,setErrIDNumber] = useState('');
    const [validate_ic,setValidateIC] = useState(true);
    const [contact_number,setContactNumber] = useState('');
    const [err_contact_number,setErrContactNumber] = useState('');
    const [date_of_birth,setDOB] = useState('');
    const [err_date_of_birth,setErrDOB] = useState('');
    const [gender,setGender] = useState('');
    const [err_gender,setErrGender] = useState('');
    const [race,setRace] = useState('');
    const [err_race,setErrRace] = useState('');
    const [religion,setReligion] = useState('');
    const [err_religion,setErrReligion] = useState('');
    const [relationship,setRelationship] = useState('');
    const [err_relationship,setErrRelationship] = useState('');
    const [company,setCompany] = useState('');
    const [err_company,setErrCompany] = useState('');
    const [branch,setBranch] = useState('');
    const [err_branch,setErrBranch] = useState('');
    const [payment,setPayment] = useState('');
    const [err_payment,setErrPayment] = useState('');
    const [is_pr,setIsPR] = useState(false);
    const [pr_start_date,setPRStartDate] = useState('');
    const [err_pr_start_date,setErrPRStartDate] = useState('');
    const [pr_cpf_contribution_type,setCPFContribution] = useState('');
    const [join_date,setJoinDate] = useState('');
    const [err_join_date,setErrJoinDate] = useState('');
    const [probation_end_date,setProbation] = useState('');
    const [err_probation_end_date,setErrProbation] = useState('');
    const prevSuccess = usePrevious(success);
    const prevErrors = usePrevious(errors);


    useEffect(()=>{
        if(props.toggle && props.detail){
            setSalutation(props.detail.salutation);
            setName(props.detail.name);
            setEmpNumber(props.detail.employee_number);
            setEmail(props.detail.email);
            setNationality(props.detail.nationality);
            setIDType(props.detail.id_type);
            setIDNumber(props.detail.identification_number);
            setContactNumber(props.detail.contact_number);
            setDOB(props.detail.date_of_birth);
            setGender(props.detail.gender);
            setRace(props.detail.race);
            setReligion(props.detail.religion);
            setRelationship(props.detail.relationship);
            setCompany(props.detail.company);
            setBranch(props.detail.branch);
            setPayment(props.detail.payment);
            setIsPR(props.detail.is_pr);
            setPRStartDate(props.detail.pr_start_date);
            setCPFContribution(props.detail.pr_cpf_contribution_type);
            setJoinDate(props.detail.join_date);
            setProbation(props.detail.probation_end_date);
            setBadgeNumber(props.detail.badge_number);
            setErrBadgeNumber('');
            setErrProbation('');
            setErrJoinDate('');
            setErrPRStartDate('');
            setErrPayment('');
            setErrBranch('');
            setErrCompany('');
            setErrRelationship('');
            setErrReligion('');
            setErrRace('');
            setErrGender('');
            setErrDOB('');
            setErrContactNumber('');
            setValidateIC(true)
            setErrIDNumber('');
            setErrIDType('');
            setErrNationality('');
            setErrEmail('');
            setErrEmpNumber('');
            setErrName('');
            setErrSalutation('');
        }
    },[props.toggle,props.detail])

    useEffect(()=>{
        if(prevSuccess !== undefined && prevSuccess !== success && success !== null && props.toggle && props.detail !== null){
            closeModal();
            get_employee_list();
            get_employee_detail(props.detail.id);
            
           
        }
    },[prevSuccess, success ,props.toggle,closeModal,props.detail,get_employee_list,get_employee_detail])

    useEffect(()=>{
        if(prevErrors !== undefined && prevErrors !== errors && errors !== null && props.toggle){
            if('badge_number' in errors){
                const word = errors['badge_number'].toString();
                setErrBadgeNumber(word);
            }
            if('identification_number' in errors){
                const word = errors['identification_number'].toString();
                setErrIDNumber(word);
            }
            if('employee_number' in errors){
                const word = errors['employee_number'].toString();
                setErrEmpNumber(word);
            } 
            if('name' in errors){
                const word = this.props.errors['name'].toString();
                setErrName(word);
            } 
        }
    },[prevErrors,errors,props.toggle])


    const validateIdentification=()=>{
        const disable_nric = process.env.REACT_APP_DISABLE_NRIC;
        if(disable_nric === 'false'){
            if(id_number.length !== 9){
                setValidateIC(false);
            }

            const identification_number = id_number.toUpperCase();
            let i;
            let icArray =[];
            for(i=0;i<9;i++){
                icArray[i] = identification_number.charAt(i);
            }
    
            icArray[1] = parseInt(icArray[1],10)*2;
            icArray[2] = parseInt(icArray[2], 10) * 7;
            icArray[3] = parseInt(icArray[3], 10) * 6;
            icArray[4] = parseInt(icArray[4], 10) * 5;
            icArray[5] = parseInt(icArray[5], 10) * 4;
            icArray[6] = parseInt(icArray[6], 10) * 3;
            icArray[7] = parseInt(icArray[7], 10) * 2;
    
            let weight = 0;
            for(i=1;i<8;i++){
                weight += icArray[i];
            }
    
            var offset = (icArray[0] === 'T' || icArray[0] === 'G') ? 4:0;
            var temp = (offset+weight) % 11;
            var st = ["J","Z","I","H","G","F","E","D","C","B","A"];
            var fg = ["X","W","U","T","R","Q","P","N","M","L","K"];
    
            var theAlpha;
            if(icArray[0] === 'S' || icArray[0] === 'T'){
                theAlpha = st[temp];
               
                
            }
            else if (icArray[0] === 'F' || icArray[0] === 'G'){
                theAlpha = fg[temp];
              
            }
            if(icArray[8] !== theAlpha){
                setValidateIC(false);
            }
            else{
                setValidateIC(true);
            }
        }
    }

    const updateProfile=()=>{
        let invalid = false;
        let s_is_pr = is_pr;
        let s_pr_start_date = pr_start_date;
        let s_probation_end_date = probation_end_date;
        if(nationality !== 'SG'){
            if(is_pr === true){
                if(pr_start_date === null || pr_start_date === ''){
                    setErrPRStartDate(IMLocalized('pr_start_date_is_required'));
                    invalid = true;
                }
            }
        }
        else{
            s_is_pr = false;
            s_pr_start_date = null;
        }

        if(!invalid){
            if(salutation !== '' && name !== '' && employee_number !== '' && badge_number !== '' && nationality !== '' && gender !== '' && race !== '' && id_type !=='' && id_number !== '' && id_number !== null && contact_number !== '' &&date_of_birth !=='' && company !== ''  && payment !== '' && join_date !== ''){
                let count = 0;
                if(id_type === 'NRIC' || id_type === 'FIN'){
                    if(validate_ic === false){
                        setErrIDNumber(IMLocalized('invalid_identification_number'));
                        count = 1;
                    }
                }
                if(count === 0){
                    if(s_probation_end_date === '' ){
                        s_probation_end_date = null;
                    }
                    props.update_employee_profile(props.detail.id,salutation,name,employee_number,badge_number,email,nationality,gender,race,religion,relationship,id_number,contact_number,date_of_birth,company,branch,payment,id_type,join_date,s_probation_end_date,s_is_pr,s_pr_start_date,pr_cpf_contribution_type);
                }
            }
            else{
                if(id_type === ''){
                    setErrIDType(IMLocalized('id_type_is_required'));
                }
                if(salutation === ''){
                    setErrSalutation(IMLocalized('salutiation_is_required'));
                }
                if(name === ''){
                    setErrName(IMLocalized('name_is_required'));
                }
                if(employee_number === ''){
                    setErrEmpNumber(IMLocalized('employee_number_is_required'));
                }
                if(badge_number === ''){
                    setErrBadgeNumber(IMLocalized('badge_number_is_required'));
                }
                if(nationality === ''){
                    setErrNationality(IMLocalized('nationality_is_required'));
                }
                if(gender === ''){
                    setErrGender(IMLocalized('gender_is_required'));
                }
                if(race === ''){
                    setErrRace(IMLocalized('race_is_required'));
                }
                if(id_number === '' || id_number === null){
                    setErrIDNumber(IMLocalized('identification_number_is_required'));
                }
                if(contact_number === ''){
                    setErrContactNumber(IMLocalized('contact_number_is_required'));
                }
                if(date_of_birth === ''){
                    setErrDOB(IMLocalized('date_of_birth_is_required'));
                }
                if(company === ''){
                    setErrCompany(IMLocalized('company_is_required'));
                }
                if(payment === ''){
                    setErrPayment(IMLocalized('payment_is_required'));
                }
                if(is_pr === true){
                    if(pr_start_date === null || pr_start_date === ''){
                        setErrPRStartDate(IMLocalized('pr_start_date_is_required'));
                    }
                }
            }
        }
    }

    return(
        <Modal isOpen={props.toggle} size="lg" style={{maxWidth:'1200px',width:'100%'}}>
            <ModalHeader toggle={props.closeModal}>{IMLocalized('update_profile')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="4">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='salutation'>{IMLocalized('salutation')}</label>
                            <Input size="sm" id="salutation" type="select" value={salutation} onChange={(e)=>{setSalutation(e.target.value); setErrSalutation('')}} invalid={err_salutation.length >0} >
                                <option></option>
                                {props.salutationlist.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_salutation}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='name'>{IMLocalized('name')}</label>
                            <Input size="sm" name="name" id="name" type="text" value={name} onChange={(e)=>{setName(e.target.value); setErrName('')}} invalid={err_name.length>0}/>
                            <FormFeedback>{err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='employee_number'>{IMLocalized('employee_number')}</label>
                            <Input size="sm" name="employee_number" id="employee_number" type="text" value={employee_number} onChange={(e)=>{setEmpNumber(e.target.value); setErrEmpNumber('')}} invalid={err_employee_number.length>0}/>
                            <FormFeedback>{err_employee_number}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='badge_number'>{IMLocalized('badge_number')}</label>
                            <Input size="sm" name="badge_number" id="badge_number" type="text" value={badge_number} onChange={(e)=>{setBadgeNumber(e.target.value); setErrBadgeNumber('')}} invalid={err_badge_number.length>0}/>
                            <FormFeedback>{err_badge_number}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='email'>{IMLocalized('email_address')}</label>
                            <Input size="sm" name="email" type="text" id="email" value={email} onChange={(e)=>{setEmail(e.target.value); setErrEmail('')}} invalid={err_email.length>0}/>
                            <FormFeedback>{err_email}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='nationality'>{IMLocalized('nationality')}</label>
                            <Input size="sm" name="nationality" id="nationality" type="select" value={nationality} onChange={(e)=>{setNationality(e.target.value); setErrNationality('')}} invalid={err_nationality.length>0}>
                                <option></option>
                                {props.nationalitylist.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display_name}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_nationality}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='id_type'>{IMLocalized('id_type')}</label>
                            <Input size="sm" name="id_type" id="id_type" type="select" value={id_type} onChange={(e)=>{setIDType(e.target.value);setErrIDType('')}} invalid={err_id_type.length>0}>
                                <option></option>
                                {props.sg_identification_type.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_id_type}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='id_number'>{IMLocalized('identification_number')}</label>
                            <Input size="sm" name="identification_number" id="id_number" type="text" value={id_number} onChange={(e)=>{setIDNumber(e.target.value); setErrIDNumber('')}} invalid={err_id_number.length>0} onBlur={()=>validateIdentification()} />
                            <FormFeedback>{err_id_number}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='contact_number'>{IMLocalized('contact_number')}</label>
                            <Input size="sm" name="contact_number" id="contact_number" type="text" value={contact_number} onChange={(e)=>{setContactNumber(e.target.value); setErrContactNumber('')}} invalid={err_contact_number.length>0}/>
                            <FormFeedback>{err_contact_number}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='date_of_birth'>{IMLocalized('date_of_birth')}</label>
                            <Input size="sm" name="date_of_birth" id="date_of_birth" type="date" value={date_of_birth} onChange={(e)=>{setDOB(e.target.value); setErrDOB('')}} invalid={err_date_of_birth.length>0} max={moment(new Date()).format('YYYY-MM-DD')}/>
                            <FormFeedback>{err_date_of_birth}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='gender'>{IMLocalized('gender')}</label>
                            <Input size="sm" name="gender" type="select" id="gender" value={gender} onChange={(e)=>{setGender(e.target.value); setErrGender('')}} invalid={err_gender.length>0}>
                                <option></option>
                                {props.genderlist.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_gender}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='race'>{IMLocalized('race')}</label>
                            <Input size="sm" name="race" id="race" type="select" value={race} onChange={(e)=>{setRace(e.target.value); setErrRace('')}} invalid={err_race.length>0}>
                                <option></option>
                                {props.racelist.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_race}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='religion'>{IMLocalized('religion')}</label>
                            <Input size="sm" name="religion" id="religion" type="select" value={religion} onChange={(e)=>{setReligion(e.target.value); setErrReligion('')}} invalid={err_religion.length>0}>
                                <option></option>
                                {props.religionlist.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_religion}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='relationship'>{IMLocalized('relationship')}</label>
                            <Input size="sm" name="relationship" id="relationship" type="select" value={relationship} onChange={(e)=>{setRelationship(e.target.value); setErrRelationship('')}} invalid={err_relationship.length>0} >
                                <option></option>
                                {props.relationshiplist.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_relationship}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='company'>{IMLocalized('company')}</label>
                            <Input size="sm" name="company" type="select" id="company" value={company} onChange={(e)=>{setCompany(e.target.value); setErrCompany('')}} invalid={err_company.length>0}>
                                <option></option>
                                {props.companylist.map((item,index)=>{
                                    return <option value={item.id} key={index}>{item.name}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_company}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='branch'>{IMLocalized('branch')}</label>
                            <Input size="sm" name="branch" type="select" value={branch} onChange={(e)=>{setBranch(e.target.value); setErrBranch('')}} invalid={err_branch.length>0}>
                                <option></option>
                                {props.branchlist.map((item,index)=>{
                                    return <option value={item.id} key={index}>{item.name}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_branch}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='payment'>{IMLocalized('payment')}</label>
                            <Input size="sm" name="payment" id="payment" type="select" value={payment} onChange={(e)=>{setPayment(e.target.value); setErrPayment('')}} invalid={err_payment.length>0}>
                                <option></option>
                                {props.paymentlist.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_payment}</FormFeedback>
                        </FormGroup>
                    </Col>
                    {nationality !== 'SG' &&
                    <Col lg="4">
                        <div className="custom-control custom-checkbox mt-4">
                            <input type="checkbox" id="modal_update_ispr" name='is_pr' className="custom-control-input" checked={is_pr} onChange={(e)=>setIsPR(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="modal_update_ispr">{IMLocalized('is_pr')}</label>
                        </div>
                    </Col>}
                    {nationality !== 'SG' && is_pr &&
                    <>
                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='pr_start_date'>{IMLocalized('pr_start_date')}</label>
                            <Input size="sm" name="pr_start_date" type="date" id="pr_start_date" value={pr_start_date} onChange={(e)=>{setPRStartDate(e.target.value); setErrPRStartDate('')}} invalid={err_pr_start_date.length>0}/>
                            <FormFeedback>{err_pr_start_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='pr_cpf_contribution_type'>{IMLocalized('pr_cpf_contribution_type')}</label>
                            <Input size="sm" name="pr_cpf_contribution_type" id="pr_cpf_contribution_type" type="select" value={pr_cpf_contribution_type} onChange={(e)=>setCPFContribution(e.target.value)}>
                                {props.sgpr_employee_cpf_payment.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                    </>}
                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='join_date'>{IMLocalized('join_date')}</label>
                            <Input size="sm" name="join_date" type="date" id="join_date" value={join_date} onChange={(e)=>{setJoinDate(e.target.value); setErrJoinDate('')}} invalid={err_join_date.length>0}/>
                            <FormFeedback>{err_join_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='probation_end_date'>{IMLocalized('probation_end_date')}</label>
                            <Input size="sm" type="date" id="probation_end_date" name="probation_end_date" value={probation_end_date} onChange={(e)=>{setProbation(e.target.value); setErrProbation('')}} invalid={err_probation_end_date.length>0}></Input>
                            <FormFeedback>{err_probation_end_date} </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {props.isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button onClick={()=>updateProfile()} className="btn btn-primary btn-sm">{IMLocalized('update')}</button>
                    <button onClick={props.closeModal} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalEditProfile);
export const USERCLAIMAPPROVALGROUP_REQUEST = 'USERCLAIMAPPROVALGROUP_REQUEST';
export const USERCLAIMAPPROVALGROUP_SUCCESS = 'USERCLAIMAPPROVALGROUP_SUCCESS';
export const USERCLAIMAPPROVALGROUP_FAILURE = 'USERCLAIMAPPROVALGROUP_FAILURE';


export const user_claim_approval_group = (data)=>{
    return dispatch=>{
        try{
            dispatch({
                type:'USERCLAIMAPPROVALGROUP_SUCCESS',
                payload:data
            });
        }
        catch(error){
            dispatch({
                type:'USERCLAIMAPPROVALGROUP_FAILURE',
                payload:error
            })
        }
        
    }
}
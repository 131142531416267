import * as first from '../action/general_first_time';

const initialState={
    data:null,
    isLoading:false,
    errors:[]
}

const general_first_time = ( state=initialState,action)=>{
    switch(action.type){
        case first.GENERALFIRSTTIME_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[],
                data:null,
            }
        case first.GENERALFIRSTTIME_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case first.GENERALFIRSTTIME_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default general_first_time;

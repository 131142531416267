import * as createovertime from '../action/create_overtime';


const initialState ={
    isLoading:false,
    errors:null,
    data:null,
}


const create_overtime = (state=initialState,action) =>{
    switch(action.type) {
        case createovertime.CREATEOVERTIME_REQUEST:
            return {
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            };
        case createovertime.CREATEOVERTIME_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case createovertime.CREATEOVERTIME_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case createovertime.CREATEOVERTIME_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default create_overtime;
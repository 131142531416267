import * as useralllist from '../../action/user/user_get_all_leave_list';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const user_get_all_leave_list = (state=initialState,action)=>{
    switch(action.type){
        case useralllist.USERGETALLLEAVE_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case useralllist.USERGETALLLEAVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case useralllist.USERGETALLLEAVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default user_get_all_leave_list;
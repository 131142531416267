import * as employee_unique_check from '../../action/employee/create_employee_unique_check';

const initialState={

    isLoading:false,
    errors:null,
    data:null
}

const create_employee_unique_check = (state=initialState,action)=>{
    switch(action.type){
        case employee_unique_check.EMPUNICHECK_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case employee_unique_check.EMPUNICHECK_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case employee_unique_check.EMPUNICHECK_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        default:
            return state;
    }
}
export default create_employee_unique_check;
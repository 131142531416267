import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import ExcelJs from 'exceljs';
import { getColumnName } from '../../../func/getColumnName';
import { getValue } from '../../../func/getValue';
import FileSaver from 'file-saver';
import { popError } from '../../../func/popError';
import Swal from 'sweetalert2';
import { usePrevious } from '../../../hook/usePrevious';
import XLSX from 'xlsx';
import { update_payroll_update_time } from '../../../action/payroll/update_payroll_update_time';
import { update_payroll_update_time_validate } from '../../../action/payroll/update_payroll_update_time_validate';
import {get_payroll_instance} from '../../../action/payroll/get_payroll_instance';
import { payroll_detail_get_aggridheader } from '../../../action/payroll/payroll_detail_get_aggridheader';
import { get_payroll_history_list } from '../../../action/history/get_payroll_history_list';

const mapStateToProps=(state)=>({
    contractlist:state.get_payslip_employee_contract.data,
    isLoading1:state.update_payroll_update_time_validate.isLoading,
    isLoading2:state.update_payroll_update_time.isLoading,
    absent_success:state.update_payroll_update_time.data,
    validate_success:state.update_payroll_update_time_validate.data,
    validate_errors:state.update_payroll_update_time_validate.errors,
})

const mapDispatchToProps=(dispatch)=>({
    update_payroll_update_time:(id,data)=>{
        dispatch(update_payroll_update_time(id,data))
    },
    update_payroll_update_time_validate:(id,data)=>{
        dispatch(update_payroll_update_time_validate(id,data))
    },
    get_payroll_instance:(id)=>{
        dispatch(get_payroll_instance(id))
    },
    payroll_detail_get_aggridheader:(id)=>{
        dispatch(payroll_detail_get_aggridheader(id))
    },
    get_payroll_history_list:()=>{
        dispatch(get_payroll_history_list())
    },
})

const initialState={
    select_absent:'current',
}

function ModalUploadAbsent(props){
    const {validate_success,validate_errors,update_payroll_update_time,absent_success,closeModal,get_payroll_instance,payroll_detail_get_aggridheader,get_payroll_history_list} = props;
    const [state,setState] = useState(initialState);
    const [language] = useState(localStorage.getItem('language'));
    const [validatelist,setValidate] = useState([]);
    const [err_msg,setErrMsg] = useState([]);
    const prevValidate = usePrevious(validate_success);
    const prevErrors = usePrevious(validate_errors);
    const prevSuccess = usePrevious(absent_success);

    useEffect(()=>{
        if(prevSuccess !== undefined && prevSuccess !== absent_success && absent_success != null){
            closeModal();
            get_payroll_instance(props.id);
            payroll_detail_get_aggridheader(props.id);
            get_payroll_history_list();
        }
    },[props.id,prevSuccess,absent_success,closeModal,get_payroll_instance,payroll_detail_get_aggridheader,get_payroll_history_list])

    useEffect(()=>{
        if(prevValidate !== undefined && prevValidate !== validate_success && validate_success != null){
            update_payroll_update_time(props.id,validatelist);
            setErrMsg([]);
        }
    },[prevValidate,validate_success,update_payroll_update_time,props.id,validatelist])

    useEffect(()=>{
        if(prevErrors !== undefined && prevErrors !== validate_errors && validate_errors != null){
            let arr = [];
            for(let i=0;i<validate_errors.length;i++){
                const data = validate_errors[i];
                let msg = [];
                if(data !== true){
                    if(data.hasOwnProperty('non_field_errors')){
                        msg.push(data['non_field_errors'].toString());
                    }
                    if(data.hasOwnProperty('ABS')){
                        const word = data['ABS'];
                        if(word.hasOwnProperty('days')){
                            msg.push(`ABS (days) : ${word['days'].toString()}`);
                        }

                        if(word.hasOwnProperty('hours')){
                            msg.push(`ABS (hours) : ${word['hours'].toString()}`);
                        }
                    }
                    if(data.hasOwnProperty('NSMUP')){
                        const word = data['NSMUP'];
                        if(word.hasOwnProperty('days')){
                            msg.push(`NSMUP (days) : ${word['days'].toString()}`);
                        }

                        if(word.hasOwnProperty('hours')){
                            msg.push(`NSMUP (hours) : ${word['hours'].toString()}`);
                        }
                    }

                    if(data.hasOwnProperty('NPL')){
                        const word = data['NPL'];
                        if(word.hasOwnProperty('days')){
                            msg.push(`NPL (days) : ${word['days'].toString()}`);
                        }

                        if(word.hasOwnProperty('hours')){
                            msg.push(`NPL (hours) : ${word['hours'].toString()}`);
                        }
                    }
                }
                const name = validatelist[i].name;
                arr.push({name,msg});
            }
            setErrMsg(arr);
        }
    },[prevErrors,validate_errors,validatelist])

    const absentCurrent=(is_current)=>{
        let arr;
        if(is_current === true){
            arr = props.contractlist.map((item)=>{
                return {name:item.employee.name,employee_number:item.employee.employee_number,hours:'',days:''};
            })
        }
        else{
            arr = props.contractlist.filter((item)=>{
                return item.payslipcontract.length > 1
            }).map(function(item){
                return {name:item.employee.name,employee_number:item.employee.employee_number,hours:'',days:''};
            })
        }
        generateAbsent(arr);
    }

    const uploadAbsentExcel=(event)=>{
        let file = event.target.files[0];
        const reader = new FileReader();
        reader.onload=(e)=>{
            const bstr = e.target.result;
            const wb = XLSX.read(bstr,{type:'binary'});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const wsname2 = wb.SheetNames[1];
            const ws2 = wb.Sheets[wsname2];
            const columns={
                'A':'employee_number',
                'B':'name',
                'C':'np_days',
                'D':'np_hours',
                'E':'ab_days',
                'F':'ab_hours',
                'G':'ns_days',
                'H':'ns_hours',
            }

            let absentData = [];
            let rowIndex = 3;
            let idd = '';
            let lang = '';
            if(ws2 !== undefined){
                if(ws2['A1'] !== undefined){
                    idd = ws2['A1'].w;
                    lang = ws2['A2'].w;
                }
            }

            while(ws['A'+rowIndex]){
                let row = {};
                for(let i =0;i<Object.keys(columns).length;i++){
                    const column = Object.keys(columns)[i];
                    const index = column + rowIndex;
                    if(ws[index] !== undefined){
                        row[columns[column]] = ws[index].w;
                    }
                    else{
                        row[columns[column]] = null;
                    }
                }
                absentData.push(row);
                rowIndex++;
            }

            let rowData=[];
            if(idd === 'Absent0' || idd === 'Absent1'){
                if(lang === language){
                    if(absentData.length !== 0){
                        for(let i=0;i<absentData.length;i++){
                            const name = absentData[i].name;
                            const detail = props.contractlist.find(function(item){
                                return item.employee.name === name
                            })
                            if(detail){
                                let payslip_contract;
                                if(idd === 'Absent0'){
                                    const payslipcontract = detail.payslipcontract;
                                    payslip_contract = payslipcontract[0].id; 
                                }
                                else if(idd=== 'Absent1'){
                                    const payslipcontract = detail.payslipcontract;
                                    payslip_contract = payslipcontract[1].id; 
                                }
        
                                const payslip = detail.id;
                                let np_hours = absentData[i].np_hours;
                                let np_days = absentData[i].np_days;
                                let ab_hours = absentData[i].ab_hours;
                                let ab_days = absentData[i].ab_days;
                                let ns_hours = absentData[i].ns_hours;
                                let ns_days = absentData[i].ns_days;
                                if(np_hours !== '' || np_days !== '' || ab_hours !=='' || ab_days !== '' || ns_hours !== '' || ns_days !== ''){
                                    let ABS = null;
                                    if(ab_hours !== '' || ab_days !== '' ){
                                        if(ab_hours === '' || ab_hours === null  ){
                                            ab_hours = 0;
                                        }
                                        if(ab_days === '' || ab_days === null ){
                                            ab_days = 0;
                                        }
                                        ABS = {hours:ab_hours,days:ab_days};
                                    }
    
                                    let NSMUP = null; 
                                    if(ns_hours !== '' || ns_days !== ''){
                                        if(ns_hours === '' || ns_hours === null){
                                            ns_hours = 0;
                                        }
                                        if(ns_days === '' || ns_days === null ){
                                            ns_days = 0;
                                        }
                                        NSMUP = {hours:ns_hours,days:ns_days};
                                    }
                                    
                                    
                                    let NPL = null;
                                    if(np_hours !== '' || np_days !== ''){
                                        if(np_hours === '' || np_hours === null ){
                                            np_hours = 0;
                                        }
                                        if(np_days === '' || np_days === null ){
                                            np_days = 0;
                                        }
                                        NPL = {hours:np_hours,days:np_days};
                                    }
                                    rowData.push({name,payslip_contract,payslip,NPL,ABS,NSMUP});
                                }
                            }
                        }
                    }
                    if(rowData.length !== 0){
                        Swal.fire({
                            type:'warning',
                            title:IMLocalized('do_you_confirm_upload_absent'),
                            showCancelButton:true,
                            confirmButtonText:IMLocalized('yes!'),
                            cancelButtonText:IMLocalized('no!')
                        })
                        .then((result)=>{
                            if(result.value){
                                props.update_payroll_update_time_validate(props.id,rowData);
                                setValidate(rowData);
                            }
                        })
                    }
                    else{
                        popError(IMLocalized('no_data_updated'));
                    }
                }
                else{
                    popError(IMLocalized('wrong_file_selected'),IMLocalized('please_choose_correct_language'));
                }
            }
            else{
                popError(IMLocalized('wrong_file_selected'))
            }
        }
        reader.readAsBinaryString(file);
    }

    const generateAbsent=(arr)=>{
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('employee_absent');
        const ws2 = wb.addWorksheet('id');
        ws.mergeCells('C1:D1');
        ws.mergeCells('E1:F1');
        ws.mergeCells('G1:H1');
        ws.getCell('C1').value =IMLocalized('agrid_no_pay_leave');
        ws.getCell('E1').value =IMLocalized('agrid_absent');
        ws.getCell('G1').value =IMLocalized('agrid_national_service');
        ws.getCell('C1').alignment = {horizontal: 'center' };
        ws.getCell('E1').alignment = {horizontal: 'center' };
        ws.getCell('G1').alignment = {horizontal: 'center' };
    
        const columns =[
            {header:IMLocalized('agrid_emp_id'),key:'employee_number'},
            {header:IMLocalized('agrid_emp_name'),key:'name'},
            {header:IMLocalized('agrid_days'),key:'days'},
            {header:IMLocalized('agrid_hours'),key:'hours'},
            {header:IMLocalized('agrid_days'),key:'days'},
            {header:IMLocalized('agrid_hours'),key:'hours'},
            {header:IMLocalized('agrid_days'),key:'days'},
            {header:IMLocalized('agrid_hours'),key:'hours'}
        ]

        ws.columns = [
            {width:15},
            {width:20},
            {width:15},
            {width:15},
            {width:15},
            {width:15},
            {width:15},
            {width:15},
        ];

        if(arr.length !== 0){
            for(let i=0;i<columns.length;i++){
                const field = columns[i].key;
                const header = columns[i].header;
                ws.getCell(getColumnName(i)+'2').value = header;
                ws.getCell('B1').border = {
                    right: {style:'thin'}
                };
                ws.getCell('D1').border = {
                    right: {style:'thin'}
                };
                ws.getCell('F1').border = {
                    right: {style:'thin'}
                };
                ws.getCell('H1').border = {
                    right: {style:'thin'}
                };
                ws.getCell('B2').border = {
                    right: {style:'thin'}
                };
                ws.getCell('D2').border = {
                    right: {style:'thin'}
                };
                ws.getCell('F2').border = {
                    right: {style:'thin'}
                };
                ws.getCell('H2').border = {
                    right: {style:'thin'}
                };
                for(let j=0;j<arr.length;j++){
                    const step = 3+j;
                    const data = getValue(arr[j],field);
                    ws.getCell('B'+step).border = {
                        right: {style:'thin'}
                    };
                    ws.getCell('D'+step).border = {
                        right: {style:'thin'}
                    };
                    ws.getCell('F'+step).border = {
                        right: {style:'thin'}
                    };
                    ws.getCell('H'+step).border = {
                        right: {style:'thin'}
                    };
                    ws.getCell(getColumnName(i)+step).value = data;
                }
            }
            const title = state.select_absent === 'current' ? '(Current)' :'(Previous)';
            ws2.getCell('A1').value = state.select_absent === 'current' ? 'Absent0' : 'Absent1';
            ws2.getCell('A2').value = language;

            wb.xlsx.writeBuffer().then((buf)=>{
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                FileSaver.saveAs(file, `Employee_Absent_Template_${title}.xlsx`);
            });
        }
        else{
            popError(IMLocalized('no_data'))
        }
    }



    return(
        <Modal size="lg" isOpen={props.toggle}>
            <ModalHeader toggle={props.closeModal}>{IMLocalized('upload_absent')}</ModalHeader>
            <ModalBody>
                <div className="d-flex">
                    <div className="custom-control custom-radio mr-3">
                        <input className="custom-control-input" id="payrollAbsentcustomRadioPay1" name="absentcustomRadioPay" type="radio" value="current" checked = {state.select_absent ==='current'} onChange={(e)=>{const value = e.target.value; setState(prevState=>({...prevState,select_absent:value}))}}/>
                        <label className="custom-control-label" htmlFor="payrollAbsentcustomRadioPay1">{IMLocalized('current')}</label>
                    </div>
                    <div className="custom-control custom-radio">
                        <input className="custom-control-input" id="payrollAbsentcustomRadioPay2" name="absentcustomRadioPay" type="radio" value="previous" checked = {state.select_absent ==='previous'} onChange={(e)=>{const value = e.target.value; setState(prevState=>({...prevState,select_absent:value}))}} />
                        <label className="custom-control-label" htmlFor="payrollAbsentcustomRadioPay2">{IMLocalized('previous')}</label>
                    </div>
                </div>
                <div className='mt-2 d-flex'>
                    {state.select_absent === 'current' && <button className="btn btn-primary btn-sm" onClick={()=>absentCurrent(true)}>{IMLocalized('get_absent_template')}</button>}
                    {state.select_absent === 'previous' && <button className="btn btn-primary btn-sm"  onClick={()=>absentCurrent(false)}>{IMLocalized('get_absent_template')}</button>}
                    <input type="file" id="PayrollImportAbsentFile" className="custom-file-input form-control-sm d-none"
                    onChange={uploadAbsentExcel}
                    onClick={(e)=>{
                        const {target = {}} = e || {};
                        target.value = '';
                    }} accept=".xlsx" />
                    <label className="btn btn-primary btn-sm mb-0" htmlFor="PayrollImportAbsentFile">{IMLocalized('upload_file')}</label>
                </div>
                {err_msg.length !== 0 && 
                <Table className='mt-2 table-bordered' responsive>
                    <thead className='thead-light'>
                        <tr>
                            <th>{IMLocalized('name')}</th>
                            <th>{IMLocalized('error')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {err_msg.map((item,index)=>{

                            return(
                                <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>
                                        {item.msg.map((element,index1)=>{
                                            return <div key={index1}><span>{element}</span></div>
                                        })}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>}
            </ModalBody>
            <ModalFooter>
                {props.isLoading1 || props.isLoading2 ? <button className="btn btn-danger btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <button className="btn btn-danger btn-sm" onClick={props.closeModal}>{IMLocalized('close')}</button>}
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalUploadAbsent);
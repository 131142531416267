import React from "react";



export default function ImportAdditionalBody({item,deleteItem}){

    return(
        <>        
        <div className="d-flex justify-content-between align-items-center py-2 px-4">
            <span>{item}</span>
            <div className="delete_btn d-flex align-items-center justify-content-center" onClick={deleteItem}>
                <i className="fas fa-trash text-red cursor-pointer" />
            </div>
        </div>
        <hr className="m-0" />
        </>
    )
}
import myaxios from '../../axios';

export const REPORTGETREPORTDAY_REQUEST = 'REPORTGETREPORTDAY_REQUEST';
export const REPORTGETREPORTDAY_SUCCESS = 'REPORTGETREPORTDAY_SUCCESS';
export const REPORTGETREPORTDAY_FAILURE = 'REPORTGETREPORTDAY_FAILURE';


export const report_get_report_day =(from_date,to_date,monday,tuesday,wednesday,thursday,friday,saturday,sunday,use_day)=>{
    return dispatch=>{
        dispatch({
            type:'REPORTGETREPORTDAY_REQUEST'
        })

        myaxios.post('/leave/report/day/',{
            from_date:from_date,
            to_date:to_date,
            monday:monday,
            tuesday: tuesday,
            wednesday: wednesday,
            thursday: thursday,
            friday: friday,
            saturday: saturday,
            sunday: sunday,
            use_day: use_day
        })
        .then((response)=>{
            dispatch({
                type:'REPORTGETREPORTDAY_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'REPORTGETREPORTDAY_FAILURE',
                payload:error
            })
        })
    }
}
import { popError } from "../../../../func/popError";
import { IMLocalized } from "../../../../language/IMLocalized";
import ExcelJs from 'exceljs';
import FileSaver from 'file-saver';
import moment from "moment";
import { getColumnName } from "../../../../func/getColumnName";
import { getDateFormat } from "../../../../func/getDateFormat";
import { getValue } from "../../../../func/getValue";

function ButtonProbationReport(props){

    const generateExcel=()=>{
        const {rowData} = props;
        const wb= new ExcelJs.Workbook();
        const ws = wb.addWorksheet('Employee Probation List');
        const today = moment(new Date()).format('DD/MM/YYYY');
        const time = moment().format('HH:mm:ss');
        const day =moment(new Date()).format("DD");
        const month =moment(new Date()).format("MM");
        const title_name = 'EmployeeProbationList_'+day+'-'+month;
        ws.getCell('A1').value = 'Employee Probation List';
        ws.getCell('A2').value = 'Printed On : '+today+' '+time;
        const column =[
            {header:'Emp ID',key:'employee_number'},
            {header:'Name',key:'name'},
            {header:'Probation End Date',key:'probation_end_date'}
        ]

        ws.columns=[
            {width:15},
            {width:20},
            {width:25}
        ]

        for(let j=0;j<column.length;j++){
            const headerName = column[j].header;
            const field = (column[j].key);
            ws.getCell(getColumnName(j)+ '5').value = headerName;
            for(let x=0;x<rowData.length;x++){
                const step = 6+x;
                const item = getValue(rowData[x],field);
                if(field === 'probation_end_date'){
                    if(item !== null && item !== '' && item !== undefined){
                        ws.getCell(getColumnName(j)+step).value = getDateFormat(item);
                    }
                    else{
                        ws.getCell(getColumnName(j)+step).value = '-';
                    }
                }
                else{
                    if(item !== null && item !== '' && item !== undefined){
                        ws.getCell(getColumnName(j)+step).value = item;
                    }
                    else{
                        ws.getCell(getColumnName(j)+step).value = '-';
                    }
                }
            }
        }

        if(rowData.length !== 0){
            const file_name = title_name +'.xlsx';
            wb.xlsx.writeBuffer().then((buf)=>{
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                FileSaver.saveAs(file, file_name)
            });

        }
        else{
            popError(IMLocalized('no_data'))
        }
    }

    return(
        <button className="btn btn-success btn-sm" onClick={()=>generateExcel()}>{IMLocalized('export')}</button>
    )
}
export default ButtonProbationReport;
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {  Container, Row,Col, Input, UncontrolledTooltip } from 'reactstrap';
import { get_payroll_instance } from '../../action/payroll/get_payroll_instance';
import { get_payslip_employee_contract } from '../../action/payroll/get_payslip_employee_contract';
import { IMLocalized } from '../../language/IMLocalized';
import Select from 'react-select';
import '../../css/payroll.css';
import InformationTable from './payslip_component/InformationTable';
import {payslip_get_emp_picture} from '../../action/payroll/payslip_get_emp_picture';
import { usePrevious } from '../../hook/usePrevious';
import { Buffer } from 'buffer';
import Swal from 'sweetalert2';
import { regenerate_payslip } from '../../action/payroll/regenerate_payslip';
import OverlayLoading from '../loading_component/overlay_loading';
import moment from 'moment';
import { getDateFormat } from '../../func/getDateFormat';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import SummaryTable from './payslip_component/SummaryTable';
import EarningTable from './payslip_component/EarningTable';
import DeducationTable from './payslip_component/DeductionTable';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';
import { get_employee_payroll } from '../../action/employee/get_employee_payroll';

const mapStateToProps=(state)=>({
    list:state.get_payroll_instance.data,
    isLoading:state.get_payroll_instance.isLoading,
    isLoading1:state.regenerate_payslip.isLoading,
    isLoading2:state.get_payslip_employee_contract.isLoading,
    isLoading3:state.delete_pay_component.isLoading,
    isLoading4:state.payslip_update_payroll_count.isLoading,
    isLoading5:state.payslip_update_payroll_amount.isLoading,
    contractlist:state.get_payslip_employee_contract.data,
    picturelist:state.get_employee_picture_list.data,
    picture:state.payslip_get_emp_picture.data,
    regenerate_sucess:state.regenerate_payslip.data,
    regenerate_errors:state.regenerate_payslip.errors,
    pay_component_type:state.dropdown_list.pay_component_type,
    wagelist:state.dropdown_list.wage,
    paymentlist:state.dropdown_list.payment,
    letterhead:state.get_general_letter.data,
    count_success:state.payslip_update_payroll_count.data,
    amount_success:state.payslip_update_payroll_amount.data,
    delete_success:state.delete_pay_component.data,
    backpay_success:state.create_payslip_backpay.data,
    bonus_success:state.update_payslip_bonus.data,
    employeePayslipList:state.get_employee_payroll.data,
    list2:state.get_payroll_list.data,
})

const mapDispatchToProps=(dispatch)=>({
    get_payroll_instance:(id)=>{
        dispatch(get_payroll_instance(id))
    },
    get_payslip_employee_contract:(id)=>{
        dispatch(get_payslip_employee_contract(id))
    },
    payslip_get_emp_picture:(id)=>{
        dispatch(payslip_get_emp_picture(id))
    },
    regenerate_payslip:(id,data)=>{
        dispatch(regenerate_payslip(id,data))
    },
    get_employee_payroll:(id)=>{
        dispatch(get_employee_payroll(id))
    }
})

const initialState={
    id:null,
    emp_list:[],
    status:'',
    payroll_date:'',
    select_payslip:'',
    payslip_list:[],
    emp_payslip_list:[],
    // emp_payslip:[],
    emp_payslip:null,
    picture:null,
    payslip_id:null,
    absentlist:[],
    nopaylist:[],
    nationlist:[],
    deductablelist:[],
    basicpaylist:[],
    bonuslist:[],
    backpaylist:[],
    flatlist:[],
    recurringlist:[],
    overtimelist:[],
    claimlist:[],
    allowancelist:[],
    payslipcontract:[],
    sgcpf:null,
    sgshg:null,
    payment:'',
    annual_amount:null,
    paid_date:'',
    start_date:'',
    end_date:'',
    head:null,
    employeePayslipList:[],
    payroll_type:'',
    dailybasiclist:[]
}

function PayrollPayslip(props){
    const {payslip_get_emp_picture,bonus_success,backpay_success,delete_success,count_success,amount_success,regenerate_sucess,picture,get_payroll_instance,employeePayslipList} = props;
    const [state,setState] = useState(initialState);
    const [monthNames] = useState([IMLocalized("january"), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
    IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")]);
    const [head,setHead] = useState(null);
    const { addItem } = useContext(NewMainContext);
    const prevPicture = usePrevious(picture);
    const prevRegenerate = usePrevious(regenerate_sucess);
    const prevCount = usePrevious(count_success);
    const prevAmount = usePrevious(amount_success);
    const prevDelete = usePrevious(delete_success);
    const prevBackpay = usePrevious(backpay_success);
    const prevBonus = usePrevious(bonus_success);

    useEffect(()=>{
        if(props.letterhead.length !== 0){
            const pic = props.letterhead.data;
            const byte = pic.byteLength;
            if(byte === 0){
                setHead(null);
            }
            else{
                const img = new Buffer.from(pic,'binary').toString('base64');
                const src = 'data:image/png;base64,'+img;
                setHead(src)
            }
        } 
    },[props.letterhead])

    useEffect(()=>{
       
        setState({...initialState,id:props.id});
    },[props.id])

    useEffect(()=>{

        function getList(index){
            const {payslips} = props.list[0];
            return payslips[index] ? {id:payslips[index].employee.id,title:`${payslips[index].employee.employee_number} - ${payslips[index].employee.name}` , name :payslips[index].employee.name}  : {id:payslips[index].employee.id,title:`${payslips[0].employee.employee_number} - ${payslips[0].employee.name}`, name:payslips[0].employee.name}; 
        }

        if(props.list.length !== 0){
            const {payslips,status,payroll_date,paid_date,start_date,end_date,type} = props.list[0];
            const arr = payslips.map((item,index)=>{
                const current_item = getList(index);
                let prev_item,next_item;

                if(payslips.length !== 1){
                    if(index === 0){
                        prev_item = getList(payslips.length -1);
                        next_item = getList(index+1);
                    }
                    else if(index === payslips.length -1){
                        prev_item = getList(index -1);
                        next_item = getList(0);
                    }
                    else{
                        prev_item = getList(index - 1);
                        next_item = getList(index + 1);
                    }
                }
                else{
                    prev_item = getList(0);
                    next_item = getList(0);
                }

                const datalist = payslips.filter(element=>{
                    return element.employee.id !== item.employee.id
                }).map(element=>{
                    const label = `${element.employee.employee_number} - ${element.employee.name}`;
                    const value = element.employee.id;
                    return {label,value}
                })

                return {prev_item,next_item,current_item,datalist}
            })

            const month1 = new Date(payroll_date).getMonth();
            const month = monthNames[month1];
            const year = new Date(payroll_date).getFullYear();
            const date1 = `${month} ${year}`;

            setState(prevState=>({...prevState,emp_list:arr,status,payroll_date:date1,payslip_list:payslips,paid_date,start_date,end_date,payroll_type:type}));
        }
        else{
            setState(prevState=>({...prevState,emp_list:[],status:'',payroll_date:'',payslip_list:[],emp_payslip_list:[],paid_date:'',start_date:'',end_date:'',payroll_type:''}))
        }
    },[props.list,monthNames])

    useEffect(()=>{
        if(state.payslip_list.length !== 0){
            const detail = state.payslip_list.find(item=>item.employee.id === parseInt(props.emp));
            let annual_amount = null;
            if(detail){
                const {metadata} = detail.employee;
                if(metadata.hasOwnProperty('annual_amt')){
                    const annual_amt = metadata['annual_amt'];
                    const check = Array.isArray(annual_amt);
                    if(!check){
                        annual_amount = annual_amt;
                    }
                    else{
                        const month = new Date().getMonth();
                        annual_amount = annual_amt[month];
                    }
                }
                const {sgcpf,sgshg,pay,payment} = detail;
                const ot_arr = pay.filter(item=>item.pay_type === 'OT');
                const allowance_arr = pay.filter(item=>item.pay_type === 'ALLOWANCE');
                const recurring_arr = pay.filter(item=>item.pay_type === 'RA');
                const flat_arr = pay.filter(item=>item.pay_type === 'FX');
                const backpay_arr = pay.filter(item=>{
                    return item.pay_type === 'BACKPAY' || item.pay_type === 'COMM' || item.pay_type === 'GRATUITY' || item.pay_type === 'INSURANCE' || item.pay_type === 'SHARES' || item.pay_type === 'DIRECTORS' || item.pay_type === 'PENSION' || item.pay_type === 'RETIREMENT' || item.pay_type === 'OTHERS' || item.pay_type === 'DEDUCT' || item.pay_type === 'DE_NOCPF'
                });
                const claim_arr = pay.filter(item=>item.pay_type === 'CLAIM');
                const absent_arr = pay.filter(item=>item.pay_type === 'ABS');
                const bonus_arr = pay.filter(item=>item.pay_type === 'BONUS');
                const basic_arr = pay.filter(item=>item.pay_type === 'BASIC');
                const daily_basic_arr = pay.filter(item=>item.pay_type === 'BASIC').filter(item1=>item1.name === 'Daily Rate');
                const deduct_arr = pay.filter(item=>item.pay_type === 'DEDUCT' || item.pay_type === 'DE_NOCPF');
                const nation_arr = pay.filter(item=>item.pay_type === 'NSMUP');
                const nopay_arr = pay.filter(item=>item.pay_type === 'NPL');


                setState(prevState=>({
                    ...prevState,
                    emp_payslip_list:detail.payslip_quickswitch,
                    emp_payslip:detail,
                    payslip_id:detail.id,
                    annual_amount,
                    overtimelist:ot_arr,
                    dailybasiclist:daily_basic_arr,
                    allowancelist:allowance_arr,
                    recurringlist:recurring_arr,
                    flatlist:flat_arr,
                    backpaylist:backpay_arr,
                    claimlist:claim_arr,
                    absentlist:absent_arr,
                    bonuslist:bonus_arr,
                    basicpaylist:basic_arr,
                    deductablelist:deduct_arr,
                    nationlist:nation_arr,
                    nopaylist:nopay_arr,
                    sgshg,
                    sgcpf,
                    payment: payment?? '',
                    employeePayslipList:employeePayslipList,
                }))
            }
        }
    },[state.payslip_list,props.emp])

    useEffect(()=>{
        if(props.picturelist.length !== 0){
            const detail = props.picturelist.filter(function(item){
                return item.employee === parseInt(props.emp);
            })

            if(detail.length !== 0){
                payslip_get_emp_picture(props.emp);
            }

            setState(prevState=>({...prevState,picture:null}))
        }
    },[props.picturelist,props.emp,payslip_get_emp_picture])

    useEffect(()=>{
        if(prevPicture !== undefined && prevPicture !== props.picture && props.picture != null){
            const img = new Buffer.from(props.picture,'binary').toString('base64');
            const src = `data:image/png;base64,${img}`;
            setState(prevState=>({...prevState,picture:src})); 
        }
    },[props.picture,prevPicture])

    useEffect(()=>{
        if(prevRegenerate !== undefined && prevRegenerate !== regenerate_sucess && regenerate_sucess != null){
            get_payroll_instance(props.id);
        }
    },[prevRegenerate,regenerate_sucess,props.id,get_payroll_instance])

    useEffect(()=>{
        if(prevCount !== undefined && prevCount !== count_success && count_success != null){
            get_payroll_instance(props.id);
        }
    },[prevCount,count_success,get_payroll_instance,props.id])

    useEffect(()=>{
        if(prevAmount !== undefined && prevAmount !== amount_success && amount_success != null){
            get_payroll_instance(props.id);
        }
    },[prevAmount,amount_success,get_payroll_instance,props.id])

    useEffect(()=>{
        if(prevDelete !== undefined && prevDelete !== delete_success && delete_success != null){
            get_payroll_instance(props.id);
        }
    },[prevDelete,delete_success,get_payroll_instance,props.id])

    useEffect(()=>{
        if(prevBackpay !== undefined && prevBackpay !== backpay_success && backpay_success != null){
            get_payroll_instance(props.id);
        }
    },[prevBackpay,backpay_success,get_payroll_instance,props.id])

    useEffect(()=>{
        if(prevBonus !== undefined && prevBonus !== bonus_success && bonus_success != null){
            get_payroll_instance(props.id);
        }
    },[prevBonus,bonus_success,get_payroll_instance,props.id])

    const regeneratePayslip=()=>{
        Swal.fire({
            type:"warning",
            title:IMLocalized('do_you_really_regenerate_payslip'),
            showCancelButton:true,
            confirmButtonColor:'red',
            confirmButtonText:IMLocalized('yes!'),
            cancelButtonText:IMLocalized('no!')
        })
        .then((result)=>{
            if(result.value){
                props.regenerate_payslip(props.id,[{id:state.payslip_id}])
            }
        })
    }

    const getPrintBtn=()=>{
        const today = moment(new Date()).format('DD/MM/YYYY');
        const head5 = [[{content:'Mode of Payment'}]];
        const head4 = [[{content:'Date of Payment'}]];
        const head3 = [[{content: 'Other Pay (E)', styles: {halign: 'left'}}, {content: 'Amount', styles: {halign: 'right'}}]];
        const head1 = [[{content: 'Salary (A)', styles: {halign: 'left'}}, {content: 'Amount', styles: {halign: 'right'}}]];
        const head7 = [[{content: 'Total Deductions (C)', styles: {halign: 'left'}}, {content: 'Amount', styles: {halign: 'right'}}]];
        const head6 = [[{content: 'Total Allowances (B)', styles: {halign: 'left'}}, {content: 'Amount', styles: {halign: 'right'}}]];
        const head2 = [[{content: 'Overtime (D)'},{content:'Period',styles:{halign:'center'}},{content:'Hrs Work',styles:{halign:'center'}},{content:'Wage',styles:{halign:'center'}},{content: 'Amount', styles: {halign: 'right'} }]];
        let cell1 =[];
        let cell2 =[];
        let cell3 = [];
        let cell4 = [];
        let cell5 = [];
        let cell6 = [];
        let cell7 = [];
        let cell8 =[];

        if(state.emp_payslip != null){

            const payment_detail = props.paymentlist.find(item=>item.value === state.emp_payslip.employee.payment);
            const payment_name = payment_detail ? payment_detail.display : ''
            if(payment_detail){
                cell5.push([`${payment_name} Deposit`]);
            }
            const payee = state.emp_payslip.employee.name ;
            const payer = state.emp_payslip.employee.metadata.company ;
            const {payable_wage,payroll_date} = state.emp_payslip;
            const month1 = new Date(payroll_date).getMonth();
            const month = monthNames[month1];
            const year = new Date(payroll_date).getFullYear();
            const payroll_date2 = month +'-'+year;
            state.emp_payslip.payslipcontract.forEach((element)=>{
                cell1.push([`Basic Pay (${getDateFormat(element.from_date)} to ${getDateFormat(element.to_date)})`, `$${(element.gross_month).toFixed(2)}`]);
            })

            state.overtimelist.forEach((element)=>{
                if(element.is_gross === false && element.amount > 0){
                    const contract = state.emp_payslip.payslipcontract.find((item1)=>item1.id === element.payslip_contract);
                    const date = contract ? `${getDateFormat(contract.from_date)} to ${getDateFormat(contract.to_date)}` :'';
                    const wage_detail = props.wagelist.find(item1=>item1.value === element.wage);
                    const wage_name = wage_detail ? wage_detail.display  : '' ;
                    cell2.push([element.name,date,element.count,wage_name,`$${(element.amount).toFixed(2)}`])
                }
            })
            let allowance_length = 0;
            state.allowancelist.forEach((element)=>{
                if(element.is_gross === false && element.amount > 0){
                    allowance_length += 1;
                    cell6.push([element.name,`$${(element.amount).toFixed(2)}`]);
                }
            })

            state.recurringlist.forEach((element)=>{
                if(element.is_gross === false && element.amount >0){
                    cell6.push([element.name,`$${(element.amount).toFixed(2)}`]);
                }
            })

            state.flatlist.forEach((element)=>{
                if(element.is_gross === false && element.amount > 0){
                    cell3.push([element.code,`$${(element.amount).toFixed(2)}`]);
                }
            })

            state.backpaylist.forEach((element)=>{
                if(element.is_gross === false && element.deductable === false && element.amount > 0){
                    const pay_detail = props.pay_component_type.find((item1)=>item1.value === element.pay_type);
                    const pay_name = pay_detail?.display ?? '';
                    cell3.push([`${pay_name} (${element.name})`,`$${(element.amount).toFixed(2)}`]);

                }
            })

            state.bonuslist.forEach((element)=>{
                if(element.is_gross === false && element.amount >0){
                    cell3.push(['CPF Employee',`$${(element.amount).toFixed(2)}`]);
                }
            })

            if(state.sgcpf !== null){
                cell7.push(['CPF Employee',`$${(state.sgcpf.cpf_employee).toFixed(2)}`]);
            } 

            if(state.sgshg !== null && state.sgshg.length !== 0){
                state.sgshg.forEach((element)=>{
                    cell7.push([element.shg,`$${(element.amount).toFixed(2)}`]);
                })
            }

            state.nopaylist.forEach((element)=>{
                cell7.push([element.code,`$${(element.amount).toFixed(2)}`]);
            })

            state.nationlist.forEach((element)=>{
                cell7.push([element.code,`$${(element.amount).toFixed(2)}`]);
            })

            state.absentlist.forEach((element)=>{
                if(element.amount > 0){
                    const detail = props.pay_component_type.find(item=>item.value === element.pay_type);
                    const name = detail ? detail.display : '';

                    cell7.push([name,`$${(element.amount).toFixed(2)}`]);
                }
            })

            state.deductablelist.forEach((element)=>{
                const detail = props.pay_component_type.find((item)=>item.value === element.pay_type);
                const pay = detail ? detail.display : '';
                cell7.push([`${pay} (${element.name})`,`$${(element.amount).toFixed(2)}`]);
            })

            if(state.paid_date !== null){
                cell4.push([getDateFormat(state.paid_date)]);
            }

            state.claimlist.forEach((element)=>{
                cell3.push(['Total Claim (E)' ,`$${(element.amount).toFixed(2)}`]);
            })

            cell8.push(['Net Pay (A+B-C+D+E)','$'+(payable_wage.toFixed(2))]);
            if(state.sgcpf !== null){
                cell8.push(["Employer's CPF Contributions" , '$'+(state.sgcpf.cpf_employer).toFixed(2)]);
            }

            if(state.annual_amount !== null){
                cell8.push(['Annual Leave Balance',state.annual_amount]);
            }

            const string = `Employer : ${payer}`;
            const string1 = `Employee : ${payee}`;
            const string2 = `Printed Date : ${today}`;
            const string3 = `Payslip for ${getDateFormat(state.start_date)} to ${getDateFormat(state.end_date)}`;
            const pdf_name = `${payee}-${payroll_date2}.pdf`;

            var doc = new jsPDF();
            if(head !== null){
                doc.addImage(head, 'JPEG', 20, 10, 180, 40);
            }
            doc.setFontSize(10);
            doc.text(string,15,60,'left');
            doc.text(string1,107,60,'left');
            doc.text(string2,15,70,'left');
            doc.text(string3,107,70,'left');
            doc.autoTable({
                head:head4,
                body:cell4,
                startY:80,
                tableWidth:90,
                
                columnStyles:{ 0: {halign:'left'}},
                
            })
            doc.autoTable({
                head:head5,
                body:cell5,
                startY:80,
                startX:110,
                margin: {left: 107} ,
                columnStyles:{ 0: {halign:'left'}}
            })

            doc.autoTable({
                head:head1,
                body:cell1,
                tableWidth:90,
                startY:100,
                columnStyles:{ 0: {halign:'left'},1: {halign:'right'}}
            })

            doc.autoTable({
                head:head6,
                body:cell6,
                margin: {left: 107} ,
                startY:100,
                columnStyles:{ 0: {halign:'left'},1: {halign:'right'}}
            })
            let abc = doc.autoTable.previous.finalY + 5
            if(allowance_length === 0){
                const length = state.payslipcontract.length;
                const number = length *5 +10;
                abc = doc.autoTable.previous.finalY + number
            }

            doc.autoTable({
                head:head7,
                body:cell7,
                startY: typeof doc.autoTable.previous.finalY === 'undefined' ? 50 : abc,
                columnStyles:{ 0: {halign:'left'},1: {halign:'right'}}
            })
            
            doc.autoTable({
                head:head2,
                body:cell2,
                startY: typeof doc.autoTable.previous.finalY === 'undefined' ? 50 : doc.autoTable.previous.finalY + 5,
                columnStyles:{ 0: {halign:'left'},1:{halign:'center'},2:{halign:'center'},3:{halign:'center'},4: {halign:'right'}}
            })
            doc.autoTable({
                head:head3,
                body:cell3,
                startY: typeof doc.autoTable.previous.finalY === 'undefined' ? 50 : doc.autoTable.previous.finalY + 5,
                columnStyles:{ 0: {halign:'left'},1: {halign:'right'}},
            })

            doc.autoTable({
                head:[],
                body:cell8,
                startY: typeof doc.autoTable.previous.finalY === 'undefined' ? 50 : doc.autoTable.previous.finalY + 5,
                columnStyles:{ 0: {halign:'left'},1: {halign:'right'}},
            })
            doc.save(pdf_name);
        }
    }

    const toPayrollDate=()=>{
        if(state.status === 'PAID' || state.status === 'LOCK'){
            props.handlePayroll({step:'2',id:props.id,type:props.type,status:props.status})
        }
        else{
            props.handlePayroll({step:'1',id:props.id,type:props.type,status:props.status})
        }
    }
    
    
    
    let obj = state.emp_list.find((item)=>item.current_item.id === parseFloat(props.emp));
    
    return(
        <>
        {props.isLoading || props.isLoading1 || props.isLoading2 || props.isLoading3 || props.isLoading4 || props.isLoading5 ? <OverlayLoading/>:
        <>
        <Container fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    {state.payroll_type==='BONUS'?
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: false, title:IMLocalized('payroll'), onClick:()=>props.handlePayroll({step:'0'})},
                        { active: false, title:"Bonus "+state.payroll_date, onClick:()=>toPayrollDate()},
                        { active: true, title:obj?.current_item?.name ?? ''}
                    ]}/>:state.payroll_type==='HMTH1'?
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: false, title:IMLocalized('payroll'), onClick:()=>props.handlePayroll({step:'0'})},
                        { active: false, title:"Half of "+state.payroll_date, onClick:()=>toPayrollDate()},
                        { active: true, title:obj?.current_item?.name ?? ''}
                    ]}/>
                    :
                    <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: false, title:IMLocalized('payroll'), onClick:()=>props.handlePayroll({step:'0'})},
                        { active: false, title:state.payroll_date, onClick:()=>toPayrollDate()},
                        { active: true, title:obj?.current_item?.name ?? ''}
                    ]}/>
                    }
                    {/* <BreadCrumbList list={[
                        { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                        { active: false, title:IMLocalized('payroll'), onClick:()=>props.handlePayroll({step:'0'})},
                        { active: false, title: state.payroll_date, onClick:()=>toPayrollDate()},
                        { active: true, title:obj?.current_item?.name ?? ''  }
                    ]}/> */}
                    <Row>
                        <Col lg="2">
                            <Input type="select" className='ml-2' style={{width:'150px'}} size="sm" value={state.select_payslip} 
                            onChange={(e)=>{
                                const value = e.target.value;
                                if(props.list2.length !== 0){
                                    // const item = state.emp_payslip_list[value];
                                    const item = props.list2[value];
                                    // props.goEmpOtherPayslip(item.payroll,item.payslip);
                                    props.goEmpOtherPayslip(item.id);
                                }
                            }} >
                                <option>{IMLocalized('select_month')}</option>
                                {props.list2.map((item,index)=>{
                                    const date = item.payroll_date
                                    const month1 = new Date(date).getMonth();
                                    const month = monthNames[month1];
                                    const year = new Date(date).getFullYear();
                                    return <option value={item.id} key={index}>{item.type==="BONUS"?"Bonus ":item.type==="HMTH1"?"Half of ":""}{month} {year}</option>
                                })}
                                {/* {state.emp_payslip_list.map((item,index)=>{
                                    const date = item.date;
                                    const month1 = new Date(date).getMonth();
                                    const month = monthNames[month1];
                                    const year = new Date(date).getFullYear();
                                    return <option key={index} value={index}>{month} {year}</option>
                                })} */}
                            </Input>
                            <button className='btn btn-primary btn-sm mt-1 ml-2' onClick={state.status === 'PAID' || state.status === 'LOCK' ? ()=>props.handlePayroll({step:'2',id:props.id,type:props.type,status:props.status}) : ()=>props.handlePayroll({step:'1',id:props.id,type:props.type,status:props.status}) }> <i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                        </Col>
                        <Col>
                            <div className="payroll_display">
                                <button className="btn btn-primary nextprev_btn" id="payslip_prev_user" style={{marginRight:0}} onClick={obj ? ()=>props.setEmp(obj.prev_item.id): null}>{obj ? obj.prev_item.title :''}<UncontrolledTooltip delay={0} placement="top" target="payslip_prev_user">{obj ? obj.prev_item.title : ''}</UncontrolledTooltip></button>
                                <Select styles={{control:(base,_state) =>({...base,minHeight:'43px',width:'200px',height:'43px' })}} options={obj? obj.datalist : [] } value={null} onChange={value=> props.setEmp(value.value)} ></Select>
                                <button className="btn btn-primary nextprev_btn" id="payslip_next_user" onClick={obj ? ()=>props.setEmp(obj.next_item.id): null}>{obj ? obj.next_item.title :''}<UncontrolledTooltip delay={0} placement="top" target="payslip_next_user">{obj ? obj.next_item.title : ''}</UncontrolledTooltip></button>
                            </div>
                        </Col>
                        <Col lg="2"></Col>
                    </Row>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col lg="9" md="12">
                    <InformationTable emp_payslip={state.emp_payslip} emp_picture={state.picture} regeneratePayslip={regeneratePayslip} getPrintBtn={getPrintBtn} />
                    <EarningTable {...state} emp={props.emp} />
                    <DeducationTable {...state} emp={props.emp} />
                </Col>
                <Col lg="3" md="12">
                    <SummaryTable {...state} />
                </Col>
            </Row>
        </Container>
        </>}
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(PayrollPayslip);
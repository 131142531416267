import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATECLAIMOPTION_REQUEST = 'UPDATECLAIMOPTION_REQUEST';
export const UPDATECLAIMOPTION_SUCCESS = 'UPDATECLAIMOPTION_SUCCESS';
export const UPDATECLAIMOPTION_FAILURE = 'UPDATECLAIMOPTION_FAILURE';
export const UPDATECLAIMOPTION_RESET = 'UPDATECLAIMOPTION_RESET';

export const update_claim_option = (allow_dependant,claim_reset_day,proration_option,cutoff_day,after_cutoff_leave,rounding,decimal_places)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATECLAIMOPTION_REQUEST'
        });
        
        myaxios.post('claim/base_setting/',{
            allow_dependant:allow_dependant,
            claim_reset_day:claim_reset_day,
            proration_option:proration_option,
            cutoff_day:cutoff_day,
            after_cutoff_leave:after_cutoff_leave,
            rounding:rounding,
            decimal_places:decimal_places
        })
        .then((response)=>{
            dispatch({
                type:'UPDATECLAIMOPTION_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATECLAIMOPTION_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('update_failure')
            });
        })
    }
}

export const reset_update_claim_option = () => dispatch =>{
    dispatch({
        type:'UPDATECLAIMOPTION_RESET'
    });
}
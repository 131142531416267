import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const UPDATEHOLIDAY_REQUEST = 'UPDATEHOLIDAY_REQUEST';
export const UPDATEHOLIDAY_SUCCESS = 'UPDATEHOLIDAY_SUCCESS';
export const UPDATEHOLIDAY_FAILURE = 'UPDATEHOLIDAY_FAILURE';
export const UPDATEHOLIDAY_RESET = 'UPDATEHOLIDAY_RESET';

export const update_holiday = (id,name,date,day)=>{
    return dispatch =>{
        dispatch({
            type: 'UPDATEHOLIDAY_REQUEST'
        });

        myaxios.patch(`contract/holiday/${id}/`,{
            name:name,
            date:date,
            day:day
        })
        .then((response)=>{
            dispatch({
                type:'UPDATEHOLIDAY_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATEHOLIDAY_FAILURE',
                payload:error.response,
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('update_failure')
            });
        })
    }
}

export const reset_update_holiday = ()=> dispatch =>{
    dispatch({
        type:'UPDATEHOLIDAY_RESET'
    });
}
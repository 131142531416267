import myaxios from '../../axios';

export const GETLEAVEAWARDLIST_REQUEST = 'GETLEAVEAWARDLIST_REQUEST';
export const GETLEAVEAWARDLIST_SUCCESS = 'GETLEAVEAWARDLIST_SUCCESS';
export const GETLEAVEAWARDLIST_FAILURE = 'GETLEAVEAWARDLIST_FAILURE';

export const get_leave_award_list = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETLEAVEAWARDLIST_REQUEST'
        });

        myaxios.get('leave/given/')
        .then((response)=>{
            dispatch({
                type:'GETLEAVEAWARDLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETLEAVEAWARDLIST_FAILURE',
                payload:error
            })
        })
    }
}
import * as leave_table_hrapprove from '../../action/leave/leave_table_hr_approve';

const initialState={
    errors:null,
    isLoading:false,
    data:null
}

const leave_table_hr_approve = (state=initialState,action)=>{
    switch(action.type){
        case leave_table_hrapprove.LEAVETABLEHRAPPROVE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null
            }
        case leave_table_hrapprove.LEAVETABLEHRAPPROVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case leave_table_hrapprove.LEAVETABLEHRAPPROVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload,
            }
        case leave_table_hrapprove.LEAVETABLEHRAPPROVE_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default leave_table_hr_approve;
import myaxios from '../../axios';

export const USERLEAVEALLAPPLYDETAIL_REQUEST = 'USERLEAVEALLAPPLYDETAIL_REQUEST';
export const USERLEAVEALLAPPLYDETAIL_SUCCESS = 'USERLEAVEALLAPPLYDETAIL_SUCCESS';
export const USERLEAVEALLAPPLYDETAIL_FAILURE = 'USERLEAVEALLAPPLYDETAIL_FAILURE';

export const user_leave_all_apply_detail = ()=>{
    return dispatch=>{
        dispatch({
            type:'USERLEAVEALLAPPLYDETAIL_REQUEST'
        })
        myaxios.get('leave/all/apply/details/')
        .then((response)=>{
            dispatch({
                type:'USERLEAVEALLAPPLYDETAIL_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'USERLEAVEALLAPPLYDETAIL_FAILURE',
                payload:error
            })
        })
    }
}
import myaxios from '../../axios';

export const CLAIMAPPROVALSGETCLAIMDETAIL_REQUEST = 'CLAIMAPPROVALSGETCLAIMDETAIL_REQUEST';
export const CLAIMAPPROVALSGETCLAIMDETAIL_SUCCESS = 'CLAIMAPPROVALSGETCLAIMDETAIL_SUCCESS';
export const CLAIMAPPROVALSGETCLAIMDETAIL_FAILURE = 'CLAIMAPPROVALSGETCLAIMDETAIL_FAILURE';
export const CLAIMAPPROVALSGETCLAIMDETAIL_RESET = 'CLAIMAPPROVALSGETCLAIMDETAIL_RESET';

export const claim_approvals_get_claim_detail = (id)=>{
    return dispatch=>{
        dispatch({
            type:'CLAIMAPPROVALSGETCLAIMDETAIL_REQUEST'
        });

        myaxios.get(`claim/manager/${id}/`)
        .then((response)=>{
            dispatch({
                type:'CLAIMAPPROVALSGETCLAIMDETAIL_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'CLAIMAPPROVALSGETCLAIMDETAIL_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_claim_approvals_get_claim_detail = () => dispatch =>{
    dispatch({
        type:'CLAIMAPPROVALSGETCLAIMDETAIL_RESET'
    });
}
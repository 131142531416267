import { addAbortSignal } from 'form-data';
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATEEMPLOYEEMASS_SUCCESS = 'UPDATEEMPLOYEEMASS_SUCCESS';
export const UPDATEEMPLOYEEMASS_REQUEST = 'UPDATEEMPLOYEEMASS_REQUEST';
export const UPDATEEMPLOYEEMASS_FAILURE = 'UPDATEEMPLOYEEMASS_FAILURE';
export const UPDATEEMPLOYEEMASS_RESET = 'UPDATEEMPLOYEEMASS_RESET';
export const UNIQUECHECK_FAILURE = 'UNIQUECHECK_FAILURE';


export const update_employee_profile_mass = (data)=>{
   return dispatch=>{
        dispatch({
            type:'UPDATEEMPLOYEEMASS_REQUEST'
        });
            data.map((item)=>
            
            myaxios.patch(`employee/employees/${(item.id)}/`,{
                id:item.id,
                name:item.name,
                badge_number:item.badge_number,
                email:item.email,
                nationality:item.nationality,
                id_type:item.id_type,
                identification_number:item.identification_number,
                contact_number:item.contact_number,
                date_of_birth:item.date_of_birth,
                gender:item.gender,
                race:item.race,
                religion:item.religion,
                relationship:item.relationship,
            }
            
            )

            .then((response)=>{
                dispatch({
                    type:'UPDATEEMPLOYEEMASS_SUCCESS',
                    payload:response
                });

                Toast.fire({
                    type: 'success',
                    title: IMLocalized('update_success')
                });
            })
            .catch((error)=>{
                dispatch({
                    type:'UPDATEEMPLOYEEMASS_FAILURE',
                    payload:error
                });
                ErrToast.fire({
                    type:'error',
                    title:IMLocalized('update_failure')
                });
            })
        .catch((error)=>{
            dispatch({
                type:'UNIQUECHECK_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })


        )
    }
}

export const reset_update_employee_profile_mass = () => dispatch =>{
    dispatch({
        type:'UPDATEEMPLOYEEMASS_RESET'
    });
}
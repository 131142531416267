import * as available_date from '../../action/payroll/get_payroll_available_date';

const initialState={
    data:[],
    errors:[],
    isLoading:false,
    after:[],
    before:[],
}

const get_payroll_available_date = (state=initialState,action)=>{
    switch(action.type){
        case available_date.AVAILABLEDATE_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[]
            }
        case available_date.AVAILABLEDATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
                after:action.payload.data.after,
                before:action.payload.data.before,
                errors:[]
            }
        case available_date.AVAILABLEDATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_payroll_available_date;
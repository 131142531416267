import * as wizarvalidateallowance from '../../action/wizard/wizard_validate_allowance';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}


const wizard_validate_allowance =(state=initialState,action)=>{
    switch(action.type){
        case wizarvalidateallowance.WIZARDVALIDATEALLOWANCE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case wizarvalidateallowance.WIZARDVALIDATEALLOWANCE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case wizarvalidateallowance.WIZARDVALIDATEALLOWANCE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        default:
            return state;
    }
}
export default wizard_validate_allowance;
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CREATEEMPDELEGATION_REQUEST = 'CREATEEMPDELEGATION_REQUEST';
export const CREATEEMPDELEGATION_SUCCESS = 'CREATEEMPDELEGATION_SUCCESS';
export const CREATEEMPDELEGATION_FAILURE = 'CREATEEMPDELEGATION_FAILURE';
export const CREATEEMPDELEGATION_RESET = 'CREATEEMPDELEGATION_RESET';


export const create_employee_delegation = (start_date,end_date,delegation)=>{
    return dispatch=>{
        dispatch({
            type:'CREATEEMPDELEGATION_REQUEST'
        });

        myaxios.post('employee/delegation/',{
            start_date:start_date,
            end_date:end_date,
            delegation:delegation,
        })
        .then((response)=>{
            dispatch({
                type:'CREATEEMPDELEGATION_SUCCESS',
                payload:response
            });


            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CREATEEMPDELEGATION_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}

export const reset_create_employee_delegation = () => dispatch =>{
    dispatch({
        type:'CREATEEMPDELEGATION_RESET'
    });
}
import * as payrollupdatevalidate from '../../action/payroll/update_payroll_update_time_validate';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}


const update_payroll_update_time_validate = (state=initialState,action)=>{
    switch(action.type){
        case payrollupdatevalidate.PAYROLLUPDATETIMEVALIDATE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case payrollupdatevalidate.PAYROLLUPDATETIMEVALIDATE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case payrollupdatevalidate.PAYROLLUPDATETIMEVALIDATE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        default:
            return state;
    }
}
export default update_payroll_update_time_validate;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { employee_award_leave, reset_employee_award_leave } from '../../../../action/employee/employee_award_leave';
import moment from 'moment';
import { get_all_employee_available_leave } from '../../../../action/employee/get_all_employee_available_leave';

export default function ModalAwardLeave({ toggle, closeModal, id, leave_setting }){
    const daylist = useSelector(state=>state.dropdown_list.days_range_selector);
    const isLoading = useSelector(state=>state.employee_award_leave.isLoading);
    const success = useSelector(state=>state.employee_award_leave.data);
    const dispatch = useDispatch();

    const [start_date,setStartDate] = useState('');
    const [err_start_date,setErrStartDate] = useState('');
    const [default_amount,setDefaultAmount] = useState(2);
    const [err_default_amount,setErrDefaultAmount] = useState('');
    const [default_choice,setDefaultChoice] = useState('MONTH');
    const [to_date,setToDate] = useState('');
    const [err_to_date,setErrToDate] = useState('');
    const [amount,setAmount] = useState('');
    const [err_amount,setErrAmount] = useState('');
    const [notes,setNotes] = useState('');
    const [err_notes,setErrNotes] = useState('');

    useEffect(()=>{
        if(toggle){
            const date = moment().format('YYYY-MM-DD');
            setStartDate(date);
            setErrStartDate('');
            setDefaultAmount(2);
            setErrDefaultAmount('');
            setDefaultChoice('MONTH');
            setErrToDate('');
            setAmount('');
            setErrAmount('');
            setNotes('');
            setErrNotes('');

        }
    },[toggle])

    useEffect(()=>{
        if(toggle && success !== null){
            dispatch(get_all_employee_available_leave());
            closeModal();
            dispatch(reset_employee_award_leave());
        }
    },[success,toggle,closeModal,dispatch])

    useEffect(()=>{
        if(start_date !=='' && default_amount !== ''){
            if(default_choice === 'DAY'){
                const new_date = moment(new Date(start_date)).add(parseInt(default_amount),'days').format('YYYY-MM-DD');
                setToDate(new_date)
            }
            else if(default_choice === 'MONTH'){
                const new_date = moment(new Date(start_date)).add(parseInt(default_amount),'M').format('YYYY-MM-DD');
                setToDate(new_date)
                
            }
            else{
                const new_date = moment(new Date(start_date)).add(parseInt(default_amount*7),'days').format('YYYY-MM-DD');
                setToDate(new_date)
            }
        }
    },[start_date,default_amount,default_choice])

    const awardLeave=()=>{
        let note =null;
        if(notes !== ''){
            note = notes; 
        }
        if(start_date !== '' && to_date !== '' && amount !== ''){
            dispatch(employee_award_leave(id,leave_setting,start_date,to_date,amount,note));
        }
        else{
            if(start_date === ''){
                setErrStartDate(IMLocalized("from_date_is_required"));
            }
            if(to_date === ''){
                setErrToDate(IMLocalized('end_date_is_required'));
            }
            if(amount === ''){
                setErrAmount(IMLocalized('leave_amount_is_required'));
            }
        }
    }

    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('award_employee_leave')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='start_date'>{IMLocalized('from_date')}</label>
                            <Input type="date" size="sm" id="start_date" value={start_date} onChange={(e)=>{setStartDate(e.target.value); setErrStartDate('')}} invalid={err_start_date.length >0} />
                            <FormFeedback>{err_start_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='period'>{IMLocalized('period')}</label>
                            <div className='input-group input-group-sm'>
                                <Input type="number" id="period" size="sm" min="0" value={default_amount} onChange={(e)=>{setDefaultAmount(e.target.value); setErrDefaultAmount('')}} invalid={err_default_amount.length >0}  />
                                <div className='input-group-prepend'>
                                    <Input type="select" size="sm" value={default_choice} name="default_choice" id="default_choice" onChange={(e)=>setDefaultChoice(e.target.value)}>
                                        {daylist.map((item,index)=>{
                                            return <option value={item.value} key={index}>{item.display}</option>
                                        })}
                                    </Input>
                                </div>
                                <FormFeedback>{err_default_amount}</FormFeedback>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='to_date'>{IMLocalized('to_date')}</label>
                            <Input type="date" size="sm" id="to_date" value={to_date} onChange={(e)=>{setToDate(e.target.value); setErrToDate('')}} invalid={err_to_date.length >0} />
                            <FormFeedback>{err_to_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='leave_amount'>{IMLocalized('leave_amount')}</label>
                            <Input size="sm" type="number" id="leave_amount" min="0" value={amount} name="amount" onChange={(e)=>{setAmount(e.target.value); setErrAmount('')}} invalid={err_amount.length>0} />
                            <FormFeedback>{err_amount}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor='notes'>{IMLocalized('note')}</label>
                            <Input size="sm" type="textarea" id="notes" name="notes" value={notes} onChange={(e)=>{setNotes(e.target.value); setErrNotes('')}} invalid={err_notes.length>0}></Input>
                            <FormFeedback>{err_notes}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button className="btn btn-primary btn-sm" onClick={()=>awardLeave()}>{IMLocalized('award')}</button>
                    <button onClick={closeModal} className="btn btn-danger btn-sm">{IMLocalized('close')}</button>
                </>}
            </ModalFooter>
        </Modal>
    )
}

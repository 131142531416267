import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row,FormGroup,Input,FormFeedback } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import PasswordChecklist from 'react-password-checklist';
import { update_self_password } from '../../../action/employee/update_self_password';
import Swal from 'sweetalert2';
import { usePrevious } from '../../../hook/usePrevious';

const mapStateToProps=(state)=>({
    success:state.update_self_password.data,
    errors:state.update_self_password.errors,
    isLoading:state.update_self_password.isLoading,
})

const mapDispatchToProps=(dispatch)=>({
    update_self_password:(current_password,password1)=>{
        dispatch(update_self_password(current_password,password1))
    },
})

function ModalPassword(props){
    const {errors,success,onSuccess} = props;
    const [current_password,setCurrent] = useState('');
    const [err_current_password,setErrCurrent] = useState('');
    const [new_password,setNew] = useState('');
    const [err_new_password,setErrNew] = useState('');
    const [confirm_password,setConfirm] = useState('');
    const [err_confirm_password,setErrConfirm] = useState('');
    const [is_valid,setValid] = useState(false);
    const prevSuccess = usePrevious(success);
    const prevErrors = usePrevious(errors);

    useEffect(()=>{
        if(props.toggle){
           setCurrent('');
            setErrCurrent('');
            setNew('');
            setErrNew('');
            setConfirm('');
            setErrConfirm('');
            setValid(false);
        }
    },[props.toggle])

    useEffect(()=>{
        if(prevSuccess !== success && success !== null && props.toggle){
            onSuccess();
        }
    },[prevSuccess,success,onSuccess,props.toggle])

    useEffect(()=>{
        if(prevErrors !== errors && errors !== null && props.toggle){
            if('current_password' in errors){
                const word = errors['current_password'].toString();
                setErrCurrent(word);
            }
            if('new_password' in errors){
                const word = errors['new_password'].toString();
                setErrNew(word)
            }
            if('non_field_errors' in errors){
                const word = errors['non_field_errors'].toString();
                popError(word);
            }
        }
    },[prevErrors,errors,props.toggle])

    const popError=(word)=>{
        Swal.fire({
            type:'warning',
            title:IMLocalized(word),
            confirmButtonText:IMLocalized('ok!')
        })
    }

    const updatePassword=()=>{
        if(current_password !== '' && new_password !== '' && confirm_password !== ''){
            if(is_valid){
                props.update_self_password(current_password,new_password);
            }
        }
        else{
            if(current_password === ''){
                setErrCurrent(IMLocalized('current_password_required'));
            }
            if(new_password === ''){
                setErrNew(IMLocalized('new_password_required'));
            }
            if(confirm_password === ''){
                setErrConfirm(IMLocalized('confirm_password_required'));
            }
        }
    }

    return(
        <Modal size="lg" isOpen={props.toggle}>
            <ModalHeader toggle={props.closeModal}>{IMLocalized('change_password')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <label className="form-control-label" htmlFor='current_password'>{IMLocalized('current_password')}</label>
                            <Input size="sm" id="current_password" type="password" value={current_password} onChange={(e)=>{setCurrent(e.target.value); setErrCurrent('')}} name="current_password" invalid={err_current_password.length > 0} />
                            <FormFeedback>{err_current_password}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <label className="form-control-label" htmlFor='new_password'>{IMLocalized('new_password')}</label>
                            <Input size="sm" id="new_password" type="password" value={new_password} onChange={(e)=>{setNew(e.target.value); setErrNew('')}} name="new_password" invalid={err_new_password.length > 0} />
                            <FormFeedback>{err_new_password}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <label className="form-control-label" htmlFor='confirm_password'>{IMLocalized('confirm_password')}</label>
                            <Input size="sm" id="confirm_password" type="password" value={confirm_password} onChange={(e)=>{setConfirm(e.target.value); setErrConfirm('')}} name="confirm_password" invalid={err_confirm_password.length > 0} />
                            <FormFeedback>{err_confirm_password}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <PasswordChecklist
                    rules={['minLength','number','capital','match','lowercase','notEmpty','specialChar']}
                    minLength={8}
                    value={new_password}
                    valueAgain={confirm_password}
                    onChange={(isValid)=>{setValid(isValid)}}
                    messages={{
                        minLength: IMLocalized('password_has_8_char'),
                        number: IMLocalized('password_has_a_number'),
                        capital: IMLocalized('password_has_a_capital_letter'),
                        match:IMLocalized('password_match'),
                        notEmpty:IMLocalized('password_not_empty'),
                        lowercase:IMLocalized('password_has_a_lowercase_letter'),
                        specialChar:IMLocalized('password_has_special')
                    }}
                />
            </ModalBody>
            <ModalFooter>
                {props.isLoading?
                <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                <>
                    <button className="btn btn-primary btn-sm" onClick={updatePassword}>{IMLocalized('confirm')}</button>
                    <button className="btn btn-danger btn-sm" onClick={props.closeModal}>{IMLocalized('close')}</button>
                </>
                }
            </ModalFooter>
        </Modal>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalPassword);
import React from 'react';
import { connect } from 'react-redux';
import { DropdownItem } from 'reactstrap';
import FileSaver from 'file-saver';
import ExcelJs from 'exceljs';
import moment from 'moment';
import { IMLocalized } from '../../language/IMLocalized';
import { getColumnName } from '../../func/getColumnName';
import { getValue } from '../../func/getValue';

const mapStateToProps=(state)=>({
    list:state.get_claim_list.data,
    isLoading:state.get_claim_list.isLoading,
})

function ClaimListReport(props){

    const generateExcel=()=>{
        const list = props.list.sort((a,b)=>a.id - b.id);
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('Claim List Report');
        const today = moment().format('DD/MM/YYYY');
        const time = moment().format('HH:mm:ss');
        const day =moment().format("DD");
        const month =moment().format("MM");
        const title_name = `ClaimListReport_${day}-${month}`;
        ws.getCell('A1').value = 'Claim List Report';
        ws.getCell('A2').value = `Printed On : ${today} ${time}`;
        const column =[
            {header:'No.',key:'no'},
            {header:'Claim Type',key:'name'}
        ]

        ws.columns = [
            {width: 15},
            {width: 20}, 
            {width: 15}
        ];

        for(let j=0;j<column.length;j++){
            
            const headerName = column[j].header;
            const field = (column[j].key);
            ws.getCell(getColumnName(j)+'5').value = headerName;
            for(let x=0;x<list.length;x++){
                const step = 6+x;
                const number = x+1;
                const item = getValue(list[x],field);
                if(field === 'no'){
                    ws.getCell(getColumnName(j)+step).value = number.toString();
                }
                else{
                    ws.getCell(getColumnName(j)+step).value = item;
                }
            }
        }
        const file_name = title_name+'.xlsx';
        wb.xlsx.writeBuffer().then((buf)=>{
            var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
            FileSaver.saveAs(file, file_name)
        });
    }

    return(
        <DropdownItem className="cursor-pointer" onClick={!props.isLoading ? ()=>generateExcel() : null} >{props.isLoading ? IMLocalized('loading') : IMLocalized('claim_list_report')}</DropdownItem>
    )
}
export default connect(mapStateToProps,null)(ClaimListReport);


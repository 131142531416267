import myaxios from '../../axios';

export const VARIANCEEMPPAYROLL_REQUEST = 'VARIANCEEMPPAYROLL_REQUEST';
export const VARIANCEEMPPAYROLL_SUCCESS = 'VARIANCEEMPPAYROLL_SUCCESS';
export const VARIANCEEMPPAYROLL_FAILURE = 'VARIANCEEMPPAYROLL_FAILURE';
export const VARIANCEEMPPAYROLL_RESET = 'VARIANCEEMPPAYROLL_RESET';

export const payroll_variance_employee_payroll =(id)=>{
    return dispatch=>{
        dispatch({
            type:'VARIANCEEMPPAYROLL_REQUEST'
        });

        myaxios.get(`payroll/employee/${id}/`)
        .then((response)=>{
            dispatch({
                type:'VARIANCEEMPPAYROLL_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'VARIANCEEMPPAYROLL_FAILURE',
                payload:error
            })
        })
    }
}


export const reset_payroll_variance_employee_payroll = () => dispatch =>{
    dispatch({
        type:'VARIANCEEMPPAYROLL_RESET'
    });
}
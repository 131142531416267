import * as scheme_list from '../action/get_leave_scheme_list';

const initialState={
    data:[],
    errors:[],
    isLoading:false
}

const get_scheme_list = (state=initialState,action)=>{
    switch(action.type){
        case scheme_list.GETSCHEMELIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[]
            }
        case scheme_list.GETSCHEMELIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                errors:[],
                data:action.payload.data
            }
        case scheme_list.GETSCHEMELIST_FAILURE:
            return{
                ...state,
                errros:action.payload,
                isLoading:false,
                
            }
        default:
            return state;
    }
}
export default get_scheme_list;
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const LEAVETABLEHRAPPROVE_REQUEST ='LEAVETABLEHRAPPROVE_REQUEST';
export const LEAVETABLEHRAPPROVE_SUCCESS ='LEAVETABLEHRAPPROVE_SUCCESS';
export const LEAVETABLEHRAPPROVE_FAILURE ='LEAVETABLEHRAPPROVE_FAILURE';
export const LEAVETABLEHRAPPROVE_RESET = 'LEAVETABLEHRAPPROVE_RESET';


export const leave_table_hr_approve =(id,approve,remarks)=>{
    return dispatch=>{
        dispatch({
            type:'LEAVETABLEHRAPPROVE_REQUEST'
        });

        const swal = approve ?  IMLocalized('approve_success') : IMLocalized('reject_success');
        const err_swal = approve ? IMLocalized('approve_failure') : IMLocalized('reject_failure');

        myaxios.post('leave/employee/approve/',{
            leaves:id,
            approve:approve,
            comment:remarks
        })
        .then((response)=>{
            dispatch({
                type:'LEAVETABLEHRAPPROVE_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: swal
            });
        })
        .catch((error)=>{
            dispatch({
                type:'LEAVEHRTABLEAPPROVE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:err_swal,
            });
        })
    }
}

export const reset_leave_table_hr_approve = () => dispatch =>{
    dispatch({
        type:"LEAVEHRTABLEAPPROVE_RESET"
    })
}

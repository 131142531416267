import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';


export const UPDATEEMPSHG_REQUEST = 'UPDATEEMPSHG_REQUEST';
export const UPDATEEMPSHG_SUCCESS = 'UPDATEEMPSHG_SUCCESS';
export const UPDATEEMPSHG_FAILURE = 'UPDATEEMPSHG_FAILURE';


export const update_employee_shg = (id,data)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEEMPSHG_REQUEST'
        });

        myaxios.post(`employee/employees/${id}/SHG/`,data)
        .then((json)=>{
            dispatch({
                type:'UPDATEEMPSHG_SUCCESS',
                payload:json
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((errors)=>{
            dispatch({
                type:'UPDATEEMPSHG_FAILURE',
                payload:errors
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}
import myaxios from '../../axios';

export const GETMANAGERAPPRAISALLIST_REQUEST = 'GETMANAGERAPPRAISALLIST_REQUEST';
export const GETMANAGERAPPRAISALLIST_SUCCESS = 'GETMANAGERAPPRAISALLIST_SUCCESS';
export const GETMANAGERAPPRAISALLIST_FAILURE = 'GETMANAGERAPPRAISALLIST_FAILURE';


export const get_manager_appraisal_list = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETMANAGERAPPRAISALLIST_REQUEST'
        });


        myaxios.get('forms/manager/')
        .then((response)=>{
            dispatch({
                type:'GETMANAGERAPPRAISALLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETMANAGERAPPRAISALLIST_FAILURE',
                payload:error
            })
        })
    }
}
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';
import { get_payroll_increment_detail } from '../../action/payroll/get_payroll_increment_detail';
import { IMLocalized } from '../../language/IMLocalized';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import moment from 'moment';
import OverlayLoading from '../loading_component/overlay_loading';
import { NewMainContext} from '../../context/NewMainContext';

const mapStateToProps=(state)=>({
    rowData:state.get_payroll_increment_detail.data,
    isLoading:state.get_payroll_increment_detail.isLoading,
})

const mapDispatchToProps=(dispatch)=>({
    get_payroll_increment_detail:(id)=>{
        dispatch(get_payroll_increment_detail(id))
    }
})

function PayrollIncrementViewDetail(props){
    const {get_payroll_increment_detail} = props;
    const [columnDefs,setColumn] = useState([]);
    const { action_toggle } = useContext(NewMainContext);

    const dateRenderer= useCallback((params)=>{
        const date = params.value;
        const date2 = new Date(params.value);
        let that_date = null;
        if(date !== null){
            let month = date2.getMonth()+1;
            if(month<10){
                month = '0'+month;
            }
            let day = date2.getDate();
            if(day<10){
                day = '0'+day;
            }
            let min = date2.getMinutes();
            if(min<10){
                min = '0'+min;
            }
            let sec = date2.getSeconds();
            if(sec<10){
                sec = '0'+sec;
            }
            let hour = date2.getHours();
            if(hour <10){
                hour = '0'+hour;
            }
            that_date = day+'/'+month+'/'+date2.getFullYear() + ' ' + hour +':'+min +":"+sec;
        
           
        }
        return that_date 
    },[])

    const date2Renderer=useCallback((params)=>{
        return getDateFormat(params.value)
    },[]) 

    useEffect(()=>{
        if(props.id){
            get_payroll_increment_detail(props.id);
            const column = [
                {
                    headerName:IMLocalized('employee_number'),
                    field:'employee.employee_number'
                },
                {
                    headerName:IMLocalized('employee_name'),
                    field:'employee.name'
                },
                {
                    headerName:IMLocalized('effective_date'),
                    field:'effective_date',
                    cellRendererFramework:date2Renderer
                },
                {
                    headerName:IMLocalized('old_amount'),
                    field:'old_amount'
                },
                {
                    headerName:IMLocalized('new_amount'),
                    field:'new_amount'
                },
                {
                    headerName:IMLocalized('amount'),
                    field:'amount'
                },
                {
                    headerName:IMLocalized('daily_rate'),
                    field:'daily_rate'
                },
                {
                    headerName:IMLocalized('hourly_rate'),
                    field:'hourly_rate'
                },
                {
                    headerName:IMLocalized('created_date'),
                    field:'created',
                    cellRendererFramework:dateRenderer
                }
            ]

            setColumn(column)
        }
    },[props.id,dateRenderer,date2Renderer,get_payroll_increment_detail])

    const toHome=()=>{
        props.toHome();
    }

    

    const getDateFormat=(date)=>{
        const date1 = moment(date,'YYYY-MM-DD');
        const new_date = date1.format('DD/MM/YYYY');
        return new_date;
    }

    

    return(
        <div>
            {props.isLoading ? <OverlayLoading/>:
            <div>
                <Container fluid>
                    <Row className="border-bottom bg-white">
                        <Col className="p-2">
                            <Row>
                                <Col>
                                    <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                                        <BreadcrumbItem><a href="/#" onClick={toHome}>{IMLocalized('home')}</a></BreadcrumbItem>
                                        <BreadcrumbItem active>{IMLocalized('payroll_increment_detail')} ( {getDateFormat(props.id)}  )</BreadcrumbItem>
                                    </Breadcrumb>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="pl-2">
                                        <button onClick={props.goBack} className='btn btn-primary btn-sm'> <i className="fas fa-chevron-left"></i> {IMLocalized('go_back')}</button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col>
                            <div className='pt-2 ag-theme-balham' style={{height:action_toggle === false ? 'calc(100vh - 224px)' :  'calc(100vh - 260px)' , width:'100%' }} >
                                <AgGridReact 
                                rowData={props.rowData}
                                columnDefs={columnDefs}
                                suppressDragLeaveHidesColumns={true}
                                suppressSizeToFit={true}
                                overlayNoRowsTemplate={IMLocalized('no_data')}>
                                </AgGridReact>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            }
        </div>
    )

}
export default connect(mapStateToProps,mapDispatchToProps)(PayrollIncrementViewDetail);
import * as updateempbank from '../../action/employee/update_employee_bank';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const update_employee_bank = (state=initialState,action)=>{
    switch(action.type){
        case updateempbank.UPDATE_EMPLOYEEBANK_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case updateempbank.UPDATE_EMPLOYEEBANK_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case updateempbank.UPDATE_EMPLOYEEBANK_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case updateempbank.UPDATE_EMPLOYEEBANK_RESET:
            return{
                ...state,
                errors:null,
                data:null,
            }
        default:
            return state;
    }
}
export default update_employee_bank;
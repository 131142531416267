import * as leave_picture from '../../action/employee/emp_detail_get_leave_picture';

const initialState={
    data:null,
    errors:null,
    isLoading:false
}

const emp_detail_get_leave_picture = (state=initialState,action)=>{
    switch(action.type){
        case leave_picture.EMPDETAILGETLEAVEPICTURE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case leave_picture.EMPDETAILGETLEAVEPICTURE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case leave_picture.EMPDETAILGETLEAVEPICTURE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case leave_picture.EMPDETAILGETLEAVEPICTURE_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default emp_detail_get_leave_picture;
import React, { useContext, useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { Container,Row,Col,Input } from 'reactstrap';
import { IMLocalized } from '../../language/IMLocalized';
import {get_history_list} from '../../action/history/get_history_list';
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {DateRangePicker} from 'react-dates';
import moment from 'moment';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css'; 
import '../../css/aggrid.css';
import { dateRenderer } from '../ag-grid_component/dateRenderer';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';

const mapStateToProps=(state)=>({
    list:state.get_history_list.data,
    isLoading:state.get_history_list.isLoading,
    actionlist:state.dropdown_list.user_actions,
})

const mapDispatchToProps=(dispatch)=>({
    get_history_list:(page)=>{
        dispatch(get_history_list(page))
    }
})

const gridOptions={
    defaultColDef:{
        resizable:true,
        sortable:true
    }
}

const initialState={
    page:1,
    startDate:null,
    endDate:null,
    start_date:null,
    end_date:null,
    dateFormat:'DD/MM/YYYY',
    select:'LOGIN',
    datalist:[],
    next_url:null,
    prev_url:null,
}

function History(props){
    const {get_history_list} = props;
    const { action_toggle, general_history_reset, setGeneralHistoryReset, addItem } = useContext(NewMainContext);
    const [data,setData] = useState(initialState);

    useEffect(()=>{
        get_history_list(data.page);
    },[data.page,get_history_list])

    useEffect(()=>{
        if(props.list.length !== 0){
            const {results} = props.list;
            const {next} = props.list;
            const {previous} = props.list;
            let arr = results;
            if(data.start_date !== null || data.end_date !== null){
                if(data.start_date !== null){
                    arr = arr.filter(function(item){
                        const date = new Date(item.created_at);
                        const date1 = moment(date);
                        const new_date = date1.format('YYYY-MM-DD');
                        return new Date(new_date) >= new Date(data.start_date);
                    })
                }
                if(data.end_date !== null){
                    arr = arr.filter(function(item){
                        const date = new Date(item.created_at);
                        const date1 = moment(date);
                        const new_date = date1.format('YYYY-MM-DD');
                        return new Date(new_date) <= new Date(data.end_date)
                    })
                }
            }
            arr = arr.filter(function(item){
                return item.action === data.select
            })
            setData(prevState=>({...prevState,datalist:arr,next_url:next,prev_url:previous}))
        }
    },[props.list,data.start_date,data.end_date,data.select])

    useEffect(()=>{
        if(general_history_reset === true){
            setGeneralHistoryReset(false);
            setData(initialState);
        }
    },[general_history_reset,setGeneralHistoryReset])

    const onDatesChange=({startDate:startDate1,endDate:endDate1})=>{
        let start = null;
        if(startDate1 !== null){
            let startdate =startDate1._d;
            let startmonth = (startdate.getMonth()+1);
            if(startmonth <10){
                startmonth = '0'+startmonth;
            }
            let startday = startdate.getDate();
            if(startday<10){
                startday = '0'+startday
            }
            start = startdate.getFullYear()+'-' + startmonth+'-' +startday
        }

        let end = null;
        if(endDate1 !== null){
            let enddate = endDate1._d;
            let endmonth =(enddate.getMonth()+1);
            if(endmonth <10){
                endmonth = '0'+endmonth;
            }
            let endday = enddate.getDate();
            if(endday<10){
                endday = '0'+endday
            }
            end = enddate.getFullYear()+'-'+endmonth+'-'+endday
        }

        setData(prevState=>({
            ...prevState,
            endDate:endDate1,
            startDate:startDate1,
            start_date:start,
            end_date:end
        }))
    }


    let columnDefs = [];
    if(data.select === 'LOGIN' || data.select === 'LOGOUT'){
        columnDefs=[
            {
                headerName:IMLocalized('history_time'),
                field:'created_at',
                cellRendererFramework:dateRenderer,
                width:300
            },
            {
                headerName:IMLocalized('employee_name'),
                field:'employee_name',
                width:300
            }
        ]
    }
    else if(data.select === 'UPDATE'){
        columnDefs=[
            {
                headerName:IMLocalized('history_time'),
                field:'created_at',
                cellRendererFramework:dateRenderer,
                width:300
            },
            {
                headerName:IMLocalized('employee_name'),
                field:'employee_name',
                width:300
            },
            {
                headerName:IMLocalized('object_name'),
                field:'object_name',
                width:300
            },
            {
                headerName:IMLocalized('field_name'),
                field:'content_type_name',
                width:300
            }
        ]
    }
    else{
        columnDefs=[
            {
                headerName:IMLocalized('history_time'),
                field:'created_at',
                cellRendererFramework:dateRenderer,
                width:300
            },
            {
                headerName:IMLocalized('employee_name'),
                field:'employee_name',
                width:300
            },
            {
                headerName:IMLocalized('object_name'),
                field:'object_name',
                width:300
            },
        ]
    }

    return(
        <>
            <Container fluid>
                <Row className="border-bottom bg-white">
                    <Col className="p-2">
                        <BreadCrumbList list={[
                            { active: false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                            { active: true, title:IMLocalized('general_history')}
                        ]}/>
                    </Col>
                </Row>
                <Row className='mt-2 justify-content-center'>
                    <Col lg="11">
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-center'><h3 className='mb-0'>{IMLocalized('general_history')} </h3><small className='d-none d-sm-block ml-2'> ({IMLocalized('page')} - {data.page})</small></div>
                            <div className='d-flex'>
                                <DateRangePicker
                                    startDate={data.startDate}
                                    isOutsideRange={()=>false}
                                    small = {true}
                                    startDateId="unique_start_date_id"
                                    endDate={data.endDate}
                                    endDateId="unique_end_date_id"
                                    minimumNights={0}
                                    onDatesChange={onDatesChange}
                                    focusedInput={data.focusedInput}
                                    displayFormat={data.dateFormat}
                                    onFocusChange={(e)=>setData(prevState=>({...prevState,focusedInput:e}))} 
                                />
                                <Input type="select" size="sm" className='ml-2' style={{width:'100px'}} value={data.select} onChange={(e)=>{const value = e.target.value; setData(prevState=>({...prevState,select:value}))}} >
                                    {props.actionlist.map((item,index)=>{
                                        return <option value={item.value} key={index}>{IMLocalized(item.value)}</option>
                                    })}
                                </Input>
                            </div>
                        </div>
                        <div>
                            {props.isLoading && <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>}
                            {!props.isLoading && data.prev_url !== null && <button className='btn btn-primary btn-sm' onClick={()=>setData(prevState=>({...prevState,page:data.page -1}))} disabled={data.page === 1}>{IMLocalized('prev')}</button>}
                            {!props.isLoading && data.next_url !== null && <button className='btn btn-primary btn-sm' onClick={()=>setData(prevState=>({...prevState,page:data.page+1}))}>{IMLocalized('next')}</button>}
                        </div>
                        <div className='mt-2 ag-theme-balham' style={{height:action_toggle ? 'calc(100vh - 280px)' : 'calc(100vh - 244px)', width:'100%'}}>
                            <AgGridReact
                            gridOptions={gridOptions}
                            rowData={data.datalist}
                            columnDefs={columnDefs}
                            suppressDragLeaveHidesColumns={true}
                            suppressSizeToFit={true}
                            suppressColumnMoveAnimation={false}
                            overlayNoRowsTemplate={IMLocalized('no_data')}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(History);
import * as deleteholiday from '../action/delete_holiday';

const initialState={
    isLoading:false,
    errors:null,
    data:null
}

const delete_holiday = (state=initialState,action) =>{
    switch(action.type){
        case deleteholiday.DELHOLIDAY_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null
            }
        case deleteholiday.DELHOLIDAY_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case deleteholiday.DELHOLIDAY_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case deleteholiday.DELHOLIDAY_RESET:
            return{
                ...state,
                errors:null,
                data:null
            }
        default:
            return state;
    }
}
export default delete_holiday;
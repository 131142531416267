import myaxios from '../../axios';

export const WIZARDCOMPLETE_REQUEST = 'WIZARDCOMPLETE_REQUEST';
export const WIZARDCOMPLETE_SUCCESS = 'WIZARDCOMPLETE_SUCCESS';
export const WIZARDCOMPLETE_FAILURE = 'WIZARDCOMPLETE_FAILURE';

export const wizard_complete = (payroll_start_month,leave_start_month,claim_start_month)=>{
    return dispatch=>{
        dispatch({
            type:'WIZARDCOMPLETE_REQUEST'
        });

        myaxios.post('general/first/time/complete/',{
            payroll_start_month:payroll_start_month,
            leave_start_month:leave_start_month,
            claim_start_month:claim_start_month
        })
        .then((response)=>{
            dispatch({
                type:'WIZARDCOMPLETE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'WIZARDCOMPLETE_FAILURE',
                payload:error
            })
        })
    }
}
import * as dashboard from '../../action/dashboard/get_general_dashboard';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}



const get_general_dashboard = (state=initialState,action)=>{
    switch(action.type){
        case dashboard.GETGENERALDASHBOARD_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case dashboard.GETGENERALDASHBOARD_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,

            }
        case dashboard.GETGENERALDASHBOARD_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_general_dashboard;
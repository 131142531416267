import * as massassign from '../../action/approvals/mass_assign_claim_approval';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const mass_assign_claim_approval = (state=initialState,action)=>{
    switch(action.type){
        case massassign.MASSASSIGNCLAIMAPPROVAL_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case massassign.MASSASSIGNCLAIMAPPROVAL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case massassign.MASSASSIGNCLAIMAPPROVAL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state
    }
}
export default mass_assign_claim_approval;
import myaxios from '../axios';

export const GENERALFIRSTTIME_REQUEST= 'GENERALFIRSTTIME_REQUEST';
export const GENERALFIRSTTIME_SUCCESS= 'GENERALFIRSTTIME_SUCCESS';
export const GENERALFIRSTTIME_FAILURE= 'GENERALFIRSTTIME_FAILURE';

export const general_first_time =()=>{
    return dispatch=>{
        dispatch({
            type:'GENERALFIRSTTIME_REQUEST'
        });
        
        myaxios.get('general/first/time/')
        .then((response)=>{
            dispatch({
                type:'GENERALFIRSTTIME_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GENERALFIRSTTIME_FAILURE',
                payload:error
            })
        })
    }
}
import * as deleteclaimgrouptemp from '../../action/claim/delete_claim_mass_group_template';


const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const delete_claim_mass_group_template = (state=initialState,action)=>{
    switch(action.type){
        case deleteclaimgrouptemp.DELETECLAIMGROUPTEM_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null
            }
        case deleteclaimgrouptemp.DELETECLAIMGROUPTEM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case deleteclaimgrouptemp.DELETECLAIMGROUPTEM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case deleteclaimgrouptemp.DELETECLAIMGROUPTEM_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default delete_claim_mass_group_template;

import * as certpic from '../../action/employee/get_employee_certification_picture';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const get_employee_certification_picture = (state=initialState,action)=>{
    switch(action.type){
        case certpic.GETEMPCERTIFICATIONPIC_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case certpic.GETEMPCERTIFICATIONPIC_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case certpic.GETEMPCERTIFICATIONPIC_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case certpic.GETEMPCERTIFICATIONPIC_RESET:
            return{
                ...state,
                errors:null,
                data:null
            }
        default:
            return state;
    }
}
export default get_employee_certification_picture;
import * as contractcostcenter1 from '../../action/employee/employee_contract_costcenter1';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}

const employee_contract_costcenter1 = (state= initialState,action)=>{
    switch(action.type){
        case contractcostcenter1.EMPCONTRACTCOSTCENTER1_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case contractcostcenter1.EMPCONTRACTCOSTCENTER1_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case contractcostcenter1.EMPCONTRACTCOSTCENTER1_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default employee_contract_costcenter1;
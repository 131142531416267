import myaxios from '../../axios';

export const GETPAYSLIPEMPCONTRACT_REQUEST = 'GETPAYSLIPEMPCONTRACT_REQUEST';
export const GETPAYSLIPEMPCONTRACT_SUCCESS = 'GETPAYSLIPEMPCONTRACT_SUCCESS';
export const GETPAYSLIPEMPCONTRACT_FAILURE = 'GETPAYSLIPEMPCONTRACT_FAILURE';


export const get_payslip_employee_contract = (id)=>{
    // return dispatch=>{
    //     dispatch({
    //         type:'GETPAYSLIPEMPCONTRACT_REQUEST'
    //     })

    //     myaxios.get(`payroll/payroll/${id}/GetPaySlipEmployeeContracts/`)
    //     .then((response)=>{
    //         dispatch({
    //             type:'GETPAYSLIPEMPCONTRACT_SUCCESS',
    //             payload:response
    //         })
    //     })
    //     .catch((error)=>{
    //         dispatch({
    //             type:'GETPAYSLIPEMPCONTRACT_FAILURE',
    //             payload:error
    //         })
    //     })
    // }

    return async dispatch=>{
        try{
            dispatch({
                type: 'GETPAYSLIPEMPCONTRACT_REQUEST'
            });
            myaxios.get(`payroll/payroll/${id}/GetPaySlipEmployeeContracts/`)
            .then((response)=>{
                dispatch({
                    type:'GETPAYSLIPEMPCONTRACT_SUCCESS',
                    payload:response
                })
            })
        }
        catch(error){
            dispatch({
                type:'GETPAYSLIPEMPCONTRACT_FAILURE',
                payload:error.response
            })
        }
    }

}
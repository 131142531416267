import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import {get_all_employee_available_leave} from '../../../action/employee/get_all_employee_available_leave';
import {get_employee_list} from '../../../action/employee/get_employee_list';
import {get_leave_employee_noscheme} from '../../../action/leave/get_leave_employee_noscheme';
import { IMLocalized } from '../../../language/IMLocalized';
import EmployeeLeaveDetail from './component/EmployeeLeaveDetail';
import ModalAssignLeaveScheme from './modal/ModalAssignLeaveScheme';


const mapStateToProps=(state)=>({
    availablelist:state.get_all_employee_available_leave.data,
    leavelist:state.get_leave_list.data,
    emp_detail:state.get_employee_detail.data,
})

const mapDispatchToProps=(dispatch)=>({
    get_employee_list:()=>{
        dispatch(get_employee_list())
    },
    get_all_employee_available_leave:()=>{
        dispatch(get_all_employee_available_leave())
    },
    get_leave_employee_noscheme:()=>{
        dispatch(get_leave_employee_noscheme())
    }
})

function EmployeeLeave(props){

    const [availablelist,setAvailable] = useState([]);
    const [approval_group,setApprovalGroup] = useState([]);
    const [has_award,setHasAward] = useState(false);
    const [emp_scheme,setEmpScheme] = useState(null);
    const [approval_name,setApprovalName] = useState('');
    const [leave_approvers,setLeaveApprovers] = useState([]);
    const [leave_exceptions,setLeaveExceptions] = useState([]);
    const [scheme_toggle,setSchemeToggle] = useState(false);
    const approvalRef = useRef();

    useEffect(()=>{
        if(props.id !== '' && props.availablelist.length !== 0){
            const data = props.availablelist.filter(function(item){
                return item.employee === parseInt(props.id);
            })

            const approval_data = data.map((item)=>{
                return {...item,group:0}
            })

            const award = data.some(function(item){
                return item.leave_type === 'G'
            })

            setHasAward(award);
            setApprovalGroup(approval_data);
            setAvailable(data);
        }
        else{
            setApprovalGroup([]);
            setAvailable([]);
        }
    },[props.availablelist,props.id])

    useEffect(()=>{
        let arr = [];
        let arr1 = approval_group;
        if(props.emp_detail != null && props.id !=='' && approval_group.length !==0){
            const {metadata} = props.emp_detail;
            if('lag' in metadata){
                const {lag} = metadata;
                if(lag !== null){
                    setApprovalName(lag.name);
                    arr.push(lag.approvers);
                    const {exceptions} = lag;
                    for(let i=0;i<exceptions.length;i++){
                        const number = i+1;
                        const data = exceptions[i];
                        arr.push(data.approvers);
                        const exceptions1 = data.exceptions;
                        for(let j=0;j< exceptions1.length;j++){
                            arr1 = arr1.map(item=>
                                item.leave_setting === exceptions1[j].id
                                ?{...item,group:number}
                                :item
                            )
                        }
                    }
                }
                else{
                    setApprovalName('');
                }
            }
        }
        setLeaveApprovers(arr);
        setLeaveExceptions(arr1);

    },[props.emp_detail,approval_group,props.id])

    useEffect(()=>{
        if(props.emp_detail != null){
            const {metadata} = props.emp_detail;
            if('leave_scheme' in metadata){
                const leave_scheme = metadata.leave_scheme;
                if(leave_scheme !== null){
                    setEmpScheme(leave_scheme.name);
                }
                else{
                    setEmpScheme(null);
                }
            }
        }
        else{
            setEmpScheme(null);
        }
    },[props.emp_detail])

    const toAwardList =()=>{
        props.toAwardList(props.id);
    }

    const toViewAll =()=>{
        props.toViewAll(props.id);
    }

    const toViewDetail=(leave_setting)=>{
        props.toViewDetail(props.id,leave_setting);
    }

    return(
        <>
            <div className="d-flex justify-content-end align-items-center text-primary pt-1 pr-2" >
                <div className='cursor-pointer' onClick={()=>approvalRef.current.scrollIntoView({block:'start',behavior:'smooth'})}>
                    <i className="fas fa-angle-down mr-1 function_icon"/>
                    <small>{IMLocalized('scroll_down')}</small>
                </div>
            </div>
            <EmployeeLeaveDetail list={availablelist} has_award={has_award} toAwardList={toAwardList} toViewAll={toViewAll} id={props.id} toViewDetail={toViewDetail}  />
            <>
            <div className="d-flex justify-content-between mt-2 pl-2 emp-tabcontent-title">
                <div className="d-flex align-items-center">
                    <i className="far fa-calendar-alt text-primary"/>
                    <h4 className="text-primary pl-2 m-0">{IMLocalized('employee_leave_scheme')}</h4>
                </div>
                <div className="text-primary cursor-pointer p-2" onClick={()=>setSchemeToggle(true)} >
                    <i className="fas fa-plus-circle mr-1 function_icon "/>
                    <small>{IMLocalized('assign_leave_scheme')}</small>
                </div>
            </div>
            <div className="p-2">
                <h4>{emp_scheme === null ? IMLocalized('no_data') : emp_scheme}</h4>
            </div>
            </>
            <div ref={approvalRef}>
                <div className="d-flex align-items-center pl-2 emp-tabcontent-title" >
                    <i className="far fa-calendar-alt text-primary"/>
                    <h4 className="text-primary pl-2 m-0">{IMLocalized('leave_approval')}</h4>
                </div>
            </div>
            {leave_approvers.length === 0 && <div className='p-2'><h4>{IMLocalized('no_data')}</h4></div>}
            {leave_approvers.length !== 0 && leave_exceptions.length !==0 && 
            <>
                <div className='p-2'>
                    <h4>{approval_name}</h4>
                </div>
                {leave_approvers.map((item,index)=>{
                    const group = leave_exceptions.filter(function(x){
                        return x.group === index 
                    })
                    const arr_table = group.map((d,index1)=>{
                        const detail = props.leavelist.find((item1)=>item1.id === d.leave_setting );

                        return(
                            <div key={'exception'+index1} className="pt-1"><span className="mr-2" >●</span><span>{detail ? detail.name :'-'}</span></div>
                        )
                    })

                    const arr_approvers = item.map((data,index1)=>{
                        const data1 = data.map((item1,index2)=>{
                            return <div key={'approvers'+index2}><span>{item1.name} - ({IMLocalized('level')} : {index1 +1})</span></div>
                        })

                        return data1;
                    })
                    if(arr_table.length !== 0){
                        return (
                            <div className='px-2' key={index}>
                                <h4>{IMLocalized('group')} {index+1}</h4>
                                <Table size="sm" className='table-bordered' responsive>
                                    <thead className='thead-light'>
                                        <tr>
                                            <th className="w-50">{IMLocalized('leave_type')}</th>
                                            <th>{IMLocalized('approval_level')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{arr_table}</td>
                                            <td>{arr_approvers}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        )
                    }
                    else{
                        return null;
                    }
                })}
            </>
            }
            <ModalAssignLeaveScheme toggle={scheme_toggle} id={props.id} closeModal={()=>setSchemeToggle(false)} onSuccess={()=>{props.get_employee_list(); props.get_all_employee_available_leave(); get_leave_employee_noscheme(); setSchemeToggle(false)}}  />
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(EmployeeLeave);
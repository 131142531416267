import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { WizardHolidayContext } from "../../../context/WizardHolidayContext";
import { IMLocalized } from "../../../language/IMLocalized";


export default function ModalAddHolidayDetail({toggle,closeModal}){
    const {list, holiday_id, setList} = useContext(WizardHolidayContext);
    const dropdown = useSelector(state=>state.dropdown_list.schedule_holiday_options);
    const [ name, setName ] = useState('');
    const [ err_name, setErrName ] = useState('');
    const [ date, setDate] = useState('');
    const [ err_date, setErrDate ] = useState('');
    const [ day, setDay] = useState('');
    const [ err_day, setErrDay] = useState('');

    const addDetail=()=>{
        if(name !== '' && date !== '' && day !== ''){
            const data = [...list];
            data[holiday_id].holiday.push({ name, date, day});
            setList(data);
            closeModal();
            setName('');
            setDate('');
            setDay('');
        }
        else{
            if(name === ''){
                setErrName(IMLocalized('name_is_required'));
            }
            if(date === ''){
                setErrDate(IMLocalized('date_is_required'));
            }
            if(day === ''){
                setErrDay(IMLocalized('holiday_day_is_required'));
            }
        }
    }

    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('create_holiday')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="name">{IMLocalized('name')}</label>
                            <Input id="name" size="sm" type="text" value={name} invalid={err_name.length > 0} onChange={(e)=>{ setName(e.target.value); setErrName('')}} />
                            <FormFeedback>{err_name}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="date">{IMLocalized('date')}</label>
                            <Input id="date" size="sm" type="date" value={date} invalid={err_date.length >0} onChange={(e)=>{ setDate(e.target.value); setErrDate('') }} />
                            <FormFeedback>{err_date}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="day">{IMLocalized('holiday_day_type')}</label>
                            <Input type="select" size="sm" id="day" value={day} invalid={err_day.length >0} onChange={(e)=>{ setDay(e.target.value); setErrDay('') }}>
                                <option></option>
                                {dropdown.map((item,index)=>{
                                    return (
                                        <option value={item.value} key={index}>{item.display}</option>
                                    )
                                })}
                            </Input>
                            <FormFeedback>{err_day}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-primary btn-sm" onClick={addDetail}>{IMLocalized('create')}</button>
                <button className="btn btn-danger btn-sm" onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';

export const CONTRACTINPAYVALIDATE_REQUEST = 'CONTRACTINPAYVALIDATE_REQUEST';
export const CONTRACTINPAYVALIDATE_SUCCESS = 'CONTRACTINPAYVALIDATE_SUCCESS';
export const CONTRACTINPAYVALIDATE_FAILURE = 'CONTRACTINPAYVALIDATE_FAILURE';


export const create_contract_increment_pay_validate =(data)=>{
    return dispatch=>{
        dispatch({
            type:'CONTRACTINPAYVALIDATE_REQUEST'
        });

        myaxios.post('contract/incpay/validate/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'CONTRACTINPAYVALIDATE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'CONTRACTINPAYVALIDATE_FAILURE',
                payload:error
            });
            ErrToast.fire({
                type:'error',
                title:IMLocalized('validate_failure')
            });
        })
    }
}

import myaxios from "../axios";
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from "../swal/SwalToast";

export const FLUSHOTPBLACKLIST_REQUEST = 'FLUSHOTPBLACKLIST_REQUEST';
export const FLUSHOTPBLACKLIST_SUCCESS = 'FLUSHOTPBLACKLIST_SUCCESS';
export const FLUSHOTPBLACKLIST_FAILURE = 'FLUSHOTPBLACKLIST_FAILURE';
export const FLUSHOTPBLACKLIST_RESET = 'FLUSHOTPBLACKLIST_RESET'; 

export const flush_otp_blacklist = ()=>{
    return dispatch=>{
        dispatch({
            type:'FLUSHOTPBLACKLIST_REQUEST'
        });
        
        myaxios.get(`otp/blacklist/flush/`)
        .then((response)=>{
            dispatch({
                type:'FLUSHOTPBLACKLIST_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type:'success',
                title:IMLocalized('remove_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'FLUSHOTPBLACKLIST_FAILURE',
                payload:error,
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('remove_failure')
            });
        })
    }
}

export const flush_otp_blacklist_reset = () => dispatch=>{
    dispatch({
        type:'FLUSHOTPBLACKLIST_RESET'
    });
}
import React, { useContext } from "react";
import { NewMainContext } from "../../context/NewMainContext";
import ClaimOfficerFunctional from "./ClaimOfficerFunctionButton";
import HrFunctionButton from "./HrFunctionButton";
import LeaveOfficerFunctionButton from "./LeaveOfficerFunctionButton";
import UserFunctionButton from "./UserFunctionButton";



export default function FunctionBtn(){
    

    const {current_user,action_toggle} = useContext(NewMainContext);

    if(action_toggle){
        if(current_user === 'hr') return <HrFunctionButton/>
        else if (current_user === 'user' || current_user === 'manager') return <UserFunctionButton/>
        else if (current_user === 'leave_officer') return <LeaveOfficerFunctionButton/>
        else if (current_user === 'claim_officer') return <ClaimOfficerFunctional/>
    }
    return null;
}
import * as claim_scheme from '../../action/claim/get_claim_scheme_list';

const initialState={
    data:[],
    errors:[],
    isLoading:false
}

const get_claim_scheme_list = (state=initialState,action)=>{
    switch(action.type){
        case claim_scheme.GETCLAIMSCHEMELIST_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case claim_scheme.GETCLAIMSCHEMELIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case claim_scheme.GETCLAIMSCHEMELIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_claim_scheme_list;

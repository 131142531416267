import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { gender_detail, identification_type_detail, nationality_detail, race_detail, relationship_detail, salutation_detail } from "../../../../reducers/dropdown_list";
import { getDateFormat } from "../../../../func/getDateFormat";
import { IMLocalized } from "../../../../language/IMLocalized";


export default function ProfileTable(){

    const emp_data = useSelector(state=>state.get_employee_detail.data);
    const nationality = useSelector(state=>nationality_detail(state,emp_data?.nationality));
    const salutation = useSelector(state=>salutation_detail(state,emp_data?.salutation));
    const gender = useSelector(state=>gender_detail(state,emp_data?.gender));
    const race = useSelector(state=>race_detail(state,emp_data?.race));
    const religion = useSelector(state=>race_detail(state,emp_data?.religion));
    const relationship = useSelector(state=>relationship_detail(state,emp_data?.relationship));
    const id_name = useSelector(state=>identification_type_detail(state,emp_data?.id_type));


    const a = moment();
    const b = moment(emp_data.date_of_birth,'YYYY-MM-DD');
    const date2 = moment.duration(a.diff(b));
    const years = date2.years();
    const months = date2.months();
    const age = years + ` ${IMLocalized('years_old_and')} ` + months +` ${IMLocalized('dob_month')}`;

    return(
        <Row className="mt-2">
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('name')}</h5>
                    </Col>
                    <Col xs="7">
                        {salutation} {emp_data.name}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('employee_number')}</h5>
                    </Col>
                    <Col xs="7">
                        {emp_data.employee_number}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('badge_number')}</h5>
                    </Col>
                    <Col xs="7">
                        {emp_data.badge_number}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('email_address')}</h5>
                    </Col>
                    <Col xs="7">
                        {emp_data.email ? emp_data.email : '-'}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('nationality')}</h5>
                    </Col>
                    <Col xs="7">
                        {nationality}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('gender')}</h5>
                    </Col>
                    <Col xs="7">
                        {gender}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('race')}</h5>
                    </Col>
                    <Col xs="7">
                        {race}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('religion')}</h5>
                    </Col>
                    <Col xs="7">
                        {religion}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('relationship')}</h5>
                    </Col>
                    <Col xs="7">
                        {relationship}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('id_type')}</h5>
                    </Col>
                    <Col xs="7">
                        {id_name}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('identification_number')}</h5>
                    </Col>
                    <Col xs="7">
                        {emp_data.identification_number}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('contact_number')}</h5>
                    </Col>
                    <Col xs="7">
                        {emp_data.contact_number}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('date_of_birth')}</h5>
                    </Col>
                    <Col xs="7">
                        <div>
                            {getDateFormat(emp_data.date_of_birth)} 
                        </div>
                        <div>
                            ({age})
                        </div>
                    </Col>
                </Row>
            </Col>
            {emp_data.nationality !== 'SG' &&
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('is_pr')}</h5>
                    </Col>
                    <Col xs="7">
                        {emp_data.is_pr ? IMLocalized('yes') : IMLocalized('no')}
                    </Col>
                </Row>
            </Col>}
            {emp_data.nationality !== 'SG' && emp_data.is_pr===true &&
            <>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('pr_start_date')}</h5>
                    </Col>
                    <Col xs="7">
                        {getDateFormat(emp_data.pr_start_date)}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('pr_cpf_contribution_type')}</h5>
                    </Col>
                    <Col xs="7">
                        {IMLocalized(emp_data.pr_cpf_contribution_type) }
                    </Col>
                </Row>
            </Col>
            </>}
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('company')}</h5>
                    </Col>
                    <Col xs="7">
                        {emp_data.metadata.company}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('branch')}</h5>
                    </Col>
                    <Col xs="7">
                        {emp_data.metadata.branch ? emp_data.metadata.branch : '-'}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('payment')}</h5>
                    </Col>
                    <Col xs="7">
                        {IMLocalized(emp_data.payment)}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('join_date')}</h5>
                    </Col>
                    <Col xs="7">
                        {getDateFormat(emp_data.join_date)}
                    </Col>
                </Row>
            </Col>
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('probation_end_date')}</h5>
                    </Col>
                    <Col xs="7">
                        {emp_data.probation_end_date ? getDateFormat(emp_data.probation_end_date) : '-'}
                    </Col>
                </Row>
            </Col>
            {emp_data.resignation_date &&
            <Col lg="6">
                <Row className="pl-2">
                    <Col xs="5">
                        <h5>{IMLocalized('resign_date')}</h5>
                    </Col>
                    <Col xs="7">
                        {getDateFormat(emp_data.resignation_date)}
                    </Col>
                </Row>
            </Col>}
        </Row>
    )
}
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast } from '../../swal/SwalToast';


export const EMPRELATIONARRAYVALIDATE_REQUEST = 'EMPRELATIONARRAYVALIDATE_REQUEST';
export const EMPRELATIONARRAYVALIDATE_SUCCESS = 'EMPRELATIONARRAYVALIDATE_SUCCESS';
export const EMPRELATIONARRAYVALIDATE_FAILURE = 'EMPRELATIONARRAYVALIDATE_FAILURE';

export const employee_relationship_array_validate = (data)=>{
    return dispatch=>{
        dispatch({
            type:'EMPRELATIONARRAYVALIDATE_REQUEST'
        });

        myaxios.post('employee/relationship/array_validate/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'EMPRELATIONARRAYVALIDATE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'EMPRELATIONARRAYVALIDATE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}
import React from 'react';
import { Card, CardHeader, Table } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';

export default function BonusTable(props){

    return(
        <Card>
            <CardHeader className="p-2 border-0 bg-white">
                <h4 className="text-primary pl-2 pt-2">{IMLocalized('payslip_bonus')}</h4>
            </CardHeader>
            <Table size="sm" className="align-items-center" responsive>
                    <thead className="text-center thead-light p-2">
                        <tr>
                            <th className="p-2">{IMLocalized('name')}</th>
                            <th className="p-2">{IMLocalized('amount')}</th>
                        </tr>
                    </thead>
                    <tbody className="text-center p-2">
                        {props.list.map((item,index)=>{
                            return(
                                <tr key={index}>
                                    <td className="p-2">{item.name}</td>
                                    <td className="p-2">${item.amount.toFixed(2)}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
        </Card>
    )
}
import React,{useEffect, useState} from 'react';
import {connect} from 'react-redux';
import FileSaver from 'file-saver';
import ExcelJs from 'exceljs';
import moment from 'moment';
import { Row, Col,DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {get_apply_claim_list2} from '../../action/claim/get_apply_claim_list2';
import {DateRangePicker} from 'react-dates';
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {IMLocalized} from '../../language/IMLocalized';
import { getDateFormat } from '../../func/getDateFormat';
import { getColumnName } from '../../func/getColumnName';
import { getValue } from '../../func/getValue';
import { popError } from '../../func/popError';

const mapStateToProps=(state)=>({
    period:state.get_payroll_period.data,
    applylist:state.get_apply_claim_list2.data,
    claimlist:state.get_claim_list.data,
    isLoading:state.get_payroll_period.isLoading,
    isLoading1:state.get_apply_claim_list2.isLoading,
    isLoading2:state.get_claim_list.isLoading,
})

const mapDispatchToProps=(dispatch)=>({
    get_apply_claim_list2:()=>{
        dispatch(get_apply_claim_list2())
    }
})

const initialState={
    list:[],
    startDate:null,
    endDate:null,
    start_date:null,
    end_date:null,
    dateFormat:'DD/MM/YYYY',
    focusedInput:null,
    toggle:false,
    departmentlist:[]
}

function ClaimDetailReport(props){
    
    const [state,setState ] = useState(initialState);

    useEffect(()=>{
        if(props.period != null){
            const {end_day,pay_period} = props.period;
            const today = new Date();
            let start_date = null;
            let startDate = null;
            let end_date = null;
            let endDate = null;
            if(pay_period === 'CUSTOM'){
                const item_year = today.getFullYear();
                const item_month = today.getMonth()+1;
                let end_item ='';
                if(end_day >=10){
                    end_item = end_day;
                }
                else{
                    end_item = '0'+end_day;
                }
                const item = item_year+'-'+item_month+'-'+end_item;
                const item_date = new Date(item);
                if(today > item_date){
                    startDate = moment(item_date,'DD-MM-YYYY').add(1, 'days');
                    start_date = startDate.format('YYYY-MM-DD');
                    endDate = moment(item_date).add(1, 'M');
                    end_date = endDate.format('YYYY-MM-DD');
                }
                else{
                    endDate = moment(item_date);
                    end_date = endDate.format('YYYY-MM-DD');
                    const date2 = moment(endDate).subtract(1,'month');
                    startDate = moment(date2,"DD-MM-YYYY").add(1,'days');
                    start_date = startDate.format('YYYY-MM-DD');
                }
            }
            else{
                start_date = moment().startOf('month').format('YYYY-MM-DD');
                end_date   = moment().endOf('month').format('YYYY-MM-DD');
                startDate = moment(start_date);
                endDate = moment(end_date);
            }

            setState(prevState=>({...prevState,start_date,startDate,end_date,endDate}));
        }
    },[props.period,state.toggle])

    useEffect(()=>{
        let arr = [];
        let detail = props.applylist.filter(item=>item.status === 'PAID');
        if(state.start_date !== null ){
            detail = detail.filter(item=>new Date(item.claim_date) >= new Date(state.start_date));
        }
        if(state.end_date !== null){
            detail = detail.filter(item=>new Date(item.claim_date) <= new Date(state.end_date));
        }
        detail.forEach(item=>{
            const department = item.employee.contract_cache?.department;
            if(department != null){
                if(!arr.includes(department)){
                    arr.push(department);
                };
            }
        })
        setState(prevState=>({...prevState,departmentlist:arr,list:detail}));
    },[props.applylist,state.end_date,state.start_date])

    const getData=()=>{
        if(!state.toggle){
            props.get_apply_claim_list2();
        }

        setState(prevState=>({...prevState,toggle:!prevState.toggle}))
    }

    const generateExcel = ()=>{
        const wb = new ExcelJs.Workbook();
        const day = moment().format('DD');
        const month = moment().format('MM');
        if(state.departmentlist.length !== 0 ){
            for(let i=0;i<state.departmentlist.length ;i++){
                const department = state.departmentlist[i];
                const ws = wb.addWorksheet(department);
                const today = moment().format('DD/MM/YYYY');
                const time = moment().format('HH:mm:ss');
                ws.getCell('A1').value = 'Claim Detail Report - Department';
                ws.getCell('A2').value = `Start Date : ${getDateFormat(state.start_date)}          End Date : ${getDateFormat(state.end_date)}     Printed On : ${today} ${time}`;
                ws.columns=[
                    {width: 15},
                    {width: 20}, 
                    {width: 15}
                ]

                let count =3;
                const detail = state.list.filter(item=>item.employee.contract_cache?.department === department);
                let emplist= [];
                for(let j=0;j<detail.length;j++){
                    const name = detail[j].employee.name;
                    if(emplist.includes(name)=== false){
                        emplist.push(name);
                    }
                }

                if(emplist.length !== 0){
                    for(let x=0;x<emplist.length;x++){
                        const name = emplist[x];
                        const emp_detail = detail.filter(item=>item.employee.name === name);
                        if(emp_detail.length !== 0){
                            const column =[
                                {header:'Date',key:'claim_date'},
                                {header:'Claim Type',key:'claim_type.name'},
                                {header:'Amount',key:'claim_amount'},
                            ]

                            for(let o=0;o<emp_detail.length;o++){
                                if(o === 0){
                                    const count1= count +2;
                                    const employe_number = emp_detail[o].employee.employee_number;
                                    const name = emp_detail[o].employee.name;
                                    ws.getCell('A'+count1).value={richText:[
                                        {
                                            font:{
                                                bold:true
                                            },
                                            text:'Emp ID : ' +employe_number
                                        }
                                    ]};
                                    ws.getCell('B'+count1).value = {richText:[
                                        {
                                            font:{
                                                bold:true
                                            },
                                            text:'Name : '+name
                                        }
                                    ]};
                                    const count2 = count1 +1;
                                    for(let z=0;z<column.length;z++){
                                        const headerName = column[z].header;
                                        ws.getCell(getColumnName(z)+count2).value = headerName;
                                    }
                                    count = count2+1;
                                }
                                for(let z=0;z<column.length;z++){
                                    const field = (column[z].key);
                                    const item = getValue(emp_detail[o],field);
                                    if(field === 'claim_date'){
                                        ws.getCell(getColumnName(z)+count).value = getDateFormat(item);
                                    }
                                    else if(field === 'claim_amount'){
                                        ws.getCell(getColumnName(z)+count).value = '$'+item;
                                    }
                                    else{
                                        ws.getCell(getColumnName(z)+count).value = item;
                                    }
                                }
                                count++;
                            }
                            ['A'+count, 'B'+count, 'C'+count, 'D'+count, 'E'+count, 'F'+count, 'G'+count].forEach(key => {
                                ws.getCell(key).border = {bottom: {style:'thick'}}
                            });
                        }
                    }
                }
            }
            const title_name = 'ClaimDetailReportByDept_'+day+'-'+month;
            const file_name = title_name +'.xlsx';
            wb.xlsx.writeBuffer().then((buf)=>{
              
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                FileSaver.saveAs(file, file_name)
            });
            setState(prevState=>({...prevState,toggle:false}))
        }
        else{
            popError(IMLocalized('no_data'));
        }
    }

    return(
        <>
            <DropdownItem className="cursor-pointer" onClick={()=>getData()}>{IMLocalized('claim_detail_report')}</DropdownItem>
            <Modal size="lg" isOpen={state.toggle} >
                <ModalHeader toggle={()=>setState(prevState=>({...prevState,toggle:false}))}> {IMLocalized('claim_detail_report')}</ModalHeader>
                <ModalBody>
                    <Row className='mt-2'>
                        <Col>
                            <DateRangePicker
                            startDate={state.startDate}
                            isOutsideRange={()=>false}
                            small={true}
                            startDateId="unique_start_date_id"
                            endDate={state.endDate}
                            endDateId="unique_end_date_id"
                            focusedInput={state.focusedInput}
                            displayFormat={state.dateFormat}
                            onFocusChange={(focusedInput)=>setState(prevState=>({...prevState,focusedInput}))}
                            onDatesChange={({startDate,endDate})=>{

                                let start_date = null;
                                if(startDate !== null){
                                    let startdate =startDate._d;
                                    let startmonth = (startdate.getMonth()+1);
                                    if(startmonth <10){
                                        startmonth = '0'+startmonth;
                                    }
                                    let startday = startdate.getDate();
                                    if(startday<10){
                                        startday = '0'+startday
                                    }
                                    start_date = startdate.getFullYear()+'-' + startmonth+'-' +startday
                                }

                                let end_date = null;
                                if(endDate !== null){
                                    let enddate = endDate._d;
                                    let endmonth =(enddate.getMonth()+1);
                                    if(endmonth <10){
                                        endmonth = '0'+endmonth;
                                    }
                                    let endday = enddate.getDate();
                                    if(endday<10){
                                        endday = '0'+endday
                                    }
                                    end_date = enddate.getFullYear()+'-'+endmonth+'-'+endday
                                }

                                setState(prevState=>({
                                    ...prevState,
                                    startDate,
                                    endDate,
                                    start_date,
                                    end_date
                                }))
                            }}
                            >
                            </DateRangePicker>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {props.isLoading || props.isLoading1 || props.isLoading2 ?  <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button> : 
                    <button onClick={generateExcel} className="btn btn-primary btn-sm">{IMLocalized('get_claim_detail_report')}</button>}
                    <button className="btn btn-danger btn-sm" onClick={()=>setState(prevState=>({...prevState,toggle:false}))}>{IMLocalized('close')}</button>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ClaimDetailReport);
import React,{Component} from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {connect} from 'react-redux';
import { Container, Row, Col, Modal, ModalHeader, ModalBody, Table, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import XLSX from 'xlsx';
import ExcelJs from 'exceljs';
import FileSaver from 'file-saver';
import moment from 'moment';
import {get_claim_approval } from '../../action/claim/get_claim_approval';
import {mass_assign_claim_approval} from '../../action/approvals/mass_assign_claim_approval';
import {IMLocalized} from '../../language/IMLocalized';
import OverlayLoading from '../loading_component/overlay_loading';

const gridOptions={
    singleClickEdit:true,
    defaultColDef:{
        sortable:true,
        filter:true,
        suppressMenu:true,
        resizable:true
    },
    sideBar:{
        toolPanels:[
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                
            }
        ],
        defaultToolPanel:'filters'
    },
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
}

class wizard_employee_claim_approval extends Component{

    constructor(props){
        super(props);
        this.state={
            list:[],
            rowData:[],
            columnsDefs:[],
            select_all:false,
            err_msg:[],
            noRowTemplate:IMLocalized('no_data'),
            rowSelection:'single',
            lodingtemplate:IMLocalized('loading'),
            language:localStorage.getItem('language'),
            count:0
        }
    }

    componentDidMount(){
        const {list} = this.props;
        this.setState({list});
        this.props.get_claim_approval();
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.approvallist !== this.props.approvallist ||prevState.list !== this.state.list || prevState.count !== this.state.count){
            this.getDetail();
        }
        if(prevProps.errors !== this.props.errors){
            const {errors} = this.props;
            if(errors !== undefined){
                const data = errors.response.data;
                if('non_field_errors' in data){
                    const word = data['non_field_errors'].toString();
                    this.popError(word);
                }
                else if('approval_group' in data ){
                    const word = IMLocalized('approval_group')+ ' :' +data['approval_group'].toString();
                    this.popError(word);
                }
            }
        }

        if(prevProps.success !== this.props.success || prevState.step !== this.state.step){
            const {rowData} = this.state;
            const data = gridOptions.api.getSelectedRows();
            const idlist = data.map((item)=>{
                return item.employee.id
            }) 
            let arr = rowData.filter(function(item){
                return idlist.includes(item.employee.id) === false
            })
            this.setState({rowData:arr});
            if(arr.length !== 0){
                Swal.fire({
                    type:'warning',
                    title:IMLocalized('do_you_continue_assign_approval'),
                    text:IMLocalized('skip_move_to_next_step'),
                    showCancelButton:true,
                    cancelButtonText:IMLocalized('skip'),
                    confirmButtonText:IMLocalized('continue')
                })
                .then((result)=>{
                    if('value' in result){
                        
                        
                    }
                    else{
                        // 0 not show this msg
                        if(arr.length !== 0){
                            Swal.fire({
                                type:'warning',
                                title:`${arr.length} ${IMLocalized('ppl_not_assign_approval')}`,
                                showCancelButton:true,
                                cancelButtonText:IMLocalized('cancel'),
                                confirmButtonText:IMLocalized('ok!')
                            })
                            .then((result1)=>{
                                if('value' in result1){
                                    this.props.onNext();
                                }
                            })
                        }
                        else{
                            this.props.onNext();
                        }
                    }
                })
            }
            else{
                this.props.onNext();
            }
        }
    }

    onCellValueChanged=(params)=>{
        if(params.oldValue !== params.newValue){
            const column = params.column.colDef.field;
            if(column === 'employee.start_date'){
                params.data.employee.err_start_date = false;
                const focusedCell = gridOptions.api.getFocusedCell();
                if(focusedCell !== undefined){
                    gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                        if(index === focusedCell.rowIndex){
                            rowNode.setSelected(true);
                        }
                    })
                }
            }
            else if(column === 'employee.group'){
                params.data.employee.err_group = false;
                const focusedCell = gridOptions.api.getFocusedCell();
                if(focusedCell !== undefined){
                    gridOptions.api.forEachNodeAfterFilterAndSort((rowNode,index)=>{
                        if(index === focusedCell.rowIndex){
                            rowNode.setSelected(true);
                        }
                    })
                }
            }
            params.api.refreshCells();
        }
    }

    onCellFocused=(params)=>{
        if(params !== null){
            if(params.column.colDef !== null){
                if(params.column.colDef.field ==='employee.group' || params.column.colDef.field ==='employee.start_date'){
                    
                    params.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = true;
                }
                else{
                    params.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = false;
                }
            }   
        }
    }

    popError=(msg)=>{
        Swal.fire({
            title:msg,
            type:'warning',
            confirmButtonColor:'#d33',
            confirmButtonText:IMLocalized('ok!')
        })
    }

    getDetail=()=>{
        const {list} = this.state;
        const {approvallist,emplist} = this.props;
        let arr = [];
        for(let i =0;i<list.length;i++){
            const item = list[i];
            const id = item.employee.id;
            let contract = null;
            const detail = emplist.filter(function(item){
                return item.id === id
            })

            detail.forEach((item)=>{
                contract = item.contract_cache;
            })

            item.employee.start_date = 'd/M/yy';
            item.employee.group = null;
            item.employee.contract = contract;
            item.err_group = false;
            item.err_start_date = false;
            arr.push(item)

        }
        this.setState({rowData:arr});
    
        let approval_detail = [''];


        for(let i=0;i<approvallist.length ;i++){
            const name = approvallist[i].name;
            approval_detail.push(name)
        }

        const columnsDefs =[
            {
                headerName:IMLocalized('agrid_select'),
                field:'',
                editable:false,
                width:120,
                lockPosition:true,
                pinned:'left',
                filter:false,
                headerCheckboxSelection: this.isFirstColumn,
                checkboxSelection: this.isFirstColumn,
                headerCheckboxSelectionFilteredOnly:true,
            },
            {
                headerName:IMLocalized('agrid_emp_id'),
                field:'employee.employee_number',
                editable:false,
                width:150,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_emp_name'),
                field:'employee.name',
                editable:false,
                width:200,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_department'),
                field:'employee.contract.department',
                editable:false,
                width:180,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_section'),
                field:'employee.contract.section',
                editable:false,
                width:180,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_job_title'),
                field:'employee.contract.job_title',
                editable:false,
                width:180,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_job_level'),
                field:'employee.contract.job_level',
                editable:false,
                width:180,
                sortable:true
            },
            {
                headerName:IMLocalized('agrid_start_date'),
                field:'employee.start_date',
                editable:true,
                width:180,
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.employee.err_start_date}
                },
            },
            {
                headerName:IMLocalized('agrid_approval_group'),
                field:'employee.group',
                width:200,
                editable:true,
                cellEditor:'agSelectCellEditor',
                cellEditorParams:{
                    values:approval_detail
                },
                cellClassRules:{
                    'orange-bg':(params)=>{return params.data.employee.err_group}
                },
            }
        ]

        this.setState({columnsDefs});
        this.setState({err_msg:[]});
    }

    isFirstColumn(params) {
        if(params !== null){
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }   
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        
    };

    generateExcel=()=>{
        const {approvallist} = this.props;
        const {language} = this.state;
        const rowData = gridOptions.api.getSelectedRows();
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('claim_approval');
        const ws2 = wb.addWorksheet('setting');
        const ws3 = wb.addWorksheet('id');
        ws3.getCell('A1').value = 'WizardClaimApproval';
        ws3.getCell('A2').value = language;
        ws.columns=[
            {header:IMLocalized('agrid_emp_id'),key:'employee.employee_number'},
            {header:IMLocalized('agrid_emp_name'),key:'employee.name'},
            {header: IMLocalized('agrid_start_date') ,key:'employee.start_date'},
            {header:IMLocalized('agrid_approval_group'),key:'employee.group'},
        ];

        const columns=[
            {header:IMLocalized('agrid_emp_id'),key:'employee.employee_number'},
            {header:IMLocalized('agrid_emp_name'),key:'employee.name'},
            {header:IMLocalized('agrid_start_date'),key:'employee.start_date'},
            {header:IMLocalized('agrid_approval_group'),key:'employee.group'},
        ];

        for(let i =2;i<=2001;i++){
            ws.getCell('C'+i).numFmt = 'd/M/yy';
        }

        for(let i=0;i<approvallist.length ;i++){
            let x=i+1;
            ws2.getCell('A'+x).value = approvallist[i].name;
        }

        if(rowData.length !== 0){
            
            for(let i =0;i<columns.length;i++){
                const field = columns[i].key;
                for(let x =0;x<rowData.length;x++){
                    const step = 2+x;
                    const data = this.getValue(rowData[x],field);
                    ws.getCell(this.getColumnName(i)+step).value = data;
                    if(field === 'employee.start_date'){
                        ws.getCell(this.getColumnName(i)+step).numFmt = 'd/M/yy';
                        ws.getCell(this.getColumnName(i)+step).value = null;
                    }
                    else if(field ==='employee.group'){
                        if(approvallist.length !== 0){
                            ws.getCell(this.getColumnName(i)+step).dataValidation={
                                type:'list',
                                allowBlank:true,
                                formulae:['=setting!$A$1:$A$'+approvallist.length],
                                error:IMLocalized('excel_valid_in_list'),
                                errorStyle:'error',
                                errorTitle:'Error',
                                showErrorMessage:true,
                            };
                        }
                        ws.getCell(this.getColumnName(i)+step).value = null;
                    }
                    else{
                        ws.getCell(this.getColumnName(i)+step).value = data;
                    }
                }
            }

            ws.columns.forEach(column =>{
                column.width = column.header.length < 15 ? 15 : column.header.length
            });
    
    
            wb.xlsx.writeBuffer().then((buf)=>{
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                FileSaver.saveAs(file, 'Wizard_Claim_Approval_Template.xlsx')
            });
        }
        else{
            Swal.fire({
                title:IMLocalized('no_employee_selected'),
                type:'warning',
                confirmButtonText:IMLocalized('ok!')
            })
        }
    }

    getValue(object, path) {
        const parts = path.split(".");
        return parts.reduce((value, key) => value ? value[key] : value, object)
    }

    getColumnName=(n)=>{
        let ordA = 'A'.charCodeAt(0);
        let ordZ = 'Z'.charCodeAt(0);
        let len = ordZ - ordA +1;

        let s = '';
        while(n >=0){
            s = String.fromCharCode(n % len + ordA )+s;
            n = Math.floor(n/len) -1;
        }
        return s;
    }

    handleFile = event => {
        const { target = {} } = event || {};
        target.value = "";
    };

    uploadExcel=(e)=>{
        const file = e.target.files[0];
        const reader = new FileReader();
        const {language} = this.state;
        reader.onload=(e)=>{
            const bstr = e.target.result;
            const wb = XLSX.read(bstr,{type:'binary'});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const wsname3 = wb.SheetNames[2];
            const ws3 = wb.Sheets[wsname3];

            const columns = {
                'A':'employee_number',
                'B':'name',
                'C':'start_date',
                'D':'group',
            }

            let rowData1 = [];
            let rowIndex =2;
            let idd = '';
            let lang = '';
            if(ws3 !== undefined){
                if(ws3['A1'] !== undefined){
                    idd = ws3['A1'].w;
                    lang = ws3['A2'].w;
                }
            }

            if(idd === 'WizardClaimApproval'){
                if(lang === language){
                    while(ws['A'+rowIndex]){
                        let row ={};
                        for(let i =0;i<Object.keys(columns).length;i++){
                            const column = Object.keys(columns)[i];
                            if(ws[column+rowIndex] !== undefined){
                                row[columns[column]] = ws[column+rowIndex].w;
                            }
                            else{
                                row[columns[column]] = null;
                            }
                        }
                        rowData1.push(row);
                        rowIndex++;
                    }
                    Promise.resolve(this.getClearAggrid())
                    .then(()=> this.getImportData(rowData1))
                }
                else{
                    Swal.fire({
                        title:IMLocalized('wrong_file_selected'),
                        text:IMLocalized('please_choose_correct_language'),
                        type:'error',
                        confirmButtonColor:'#d33',
                        confirmButtonText:IMLocalized('ok!'),
                    })
                }
                
            }
            else{
                Swal.fire({
                    title:IMLocalized('wrong_file_selected'),
                    type:'error',
                    confirmButtonColor:'#d33',
                    confirmButtonText:IMLocalized('ok!'),
                })
            }
        }
        reader.readAsBinaryString(file)
    }

    getClearAggrid=()=>{
        gridOptions.api.forEachNode((rowNode,index)=>{
            rowNode.setSelected(false);
        })
    }

    onCreate=()=>{
        this.setState({err_msg:[]});
        const data = gridOptions.api.getSelectedRows();
        const {approvallist} = this.props;
        let arr =[];
        let arr2 = [];

        if(data.length !== 0){
            for(let i =0;i<data.length;i++){
                const group = data[i].employee.group;
                const name = data[i].employee.name;
                const number = data[i].employee.employee_number;
                let approval_group = null;
                let approval_master = null;

                approvallist.forEach((item)=>{
                    if(item.name === group){
                        approval_group = item.id;
                        approval_master = item.approval_master;
                    }
                })

                const employee = data[i].employee.id;
                let start_date = null;
                const date = data[i].employee.start_date;
                console.log(date)
                if(date !== null && date !== '' && date !== undefined && date !== 'd/M/yy'){
                    const date1 = moment(date,'dd/M/YY');
                    start_date = date1.format('YYYY-MM-DD'); 
                    if(start_date === 'Invalid date'){
                        arr2.push({employee,start_date,approval_group,name,number,approval_master});
                    }
                }
                else{
                    arr2.push({employee,start_date,approval_group,name,number,approval_master});
                }
                arr.push({employee,start_date,approval_group,name,number,approval_master});
            }

            if(arr2.length !== 0){
                this.handleError(arr2);
            }
            else{
                this.props.mass_assign_claim_approval(arr);
            }
        }
        else{
            Swal.fire({
                title:IMLocalized('no_employee_selected'),
                type:'warning',
                confirmButtonText:IMLocalized('ok!')
            })
        }
        
        
    }

    getImportData=(data)=>{
        for(let i=0;i<data.length;i++){
            const employee_number = data[i].employee_number;
            const group = data[i].group;
            const start_date = data[i].start_date;
            gridOptions.api.forEachNode((rowNode,index)=>{
                const employee_number1 = rowNode.data.employee.employee_number;
                let err_start_date = false;
                let err_group = false;
                if(employee_number1 === employee_number){
                    rowNode.data.employee.group = group;
                    rowNode.data.employee.start_date = start_date;
                    rowNode.data.employee.err_group = err_group;
                    rowNode.data.employee.err_start_date = err_start_date;
                    rowNode.setSelected(true);
                    gridOptions.api.refreshCells(rowNode)
                }
            })
        }
    }

    handleSelect=(e)=>{
        const target = e.target;
        const checked = target.checked;
        const name = target.name;
        this.setState({
            [name]:checked
        })
    }

    clearData=()=>{
        
        const {count} = this.state;
        const count1 = count +1;
        Promise.resolve(this.setState({rowData:[]}))
        .then(()=>this.setState({count:count1}))
    }

    handleError=(data)=>{
        let arr = [];
        for(let i=0;i<data.length ;i++){
            let arr1 = [];
            const date = data[i].start_date;
            const approval_group = data[i].approval_group;
            const name = data[i].name;
            const number = data[i].number;
            if(date === 'Invalid date'){
                arr1.push(
                    <div>
                        <span>● {IMLocalized('start_date')} : {IMLocalized('invalid_date')}</span>
                    </div>
                )

                gridOptions.api.forEachNode((rowNode,index)=>{
                    if(rowNode.data.employee.employee_number === number){
                        rowNode.data.employee.err_start_date = true;
                        gridOptions.api.refreshCells(rowNode)
                    }
                })
            }
            else if (date === null){
                arr1.push(
                    <div>
                        <span>● {IMLocalized('start_date_is_required')}</span>
                    </div>
                )

                gridOptions.api.forEachNode((rowNode,index)=>{
                    if(rowNode.data.employee.employee_number === number){
                        rowNode.data.employee.err_start_date = true;
                        gridOptions.api.refreshCells(rowNode)
                    }
                })
            }
            
            if(approval_group === null){
                arr1.push(
                    <div><span>● {IMLocalized('approval_group_is_required')}</span></div>
                )
                gridOptions.api.forEachNode((rowNode,index)=>{
                    if(rowNode.data.employee.employee_number === number){
                        rowNode.data.employee.err_group = true;
                        gridOptions.api.refreshCells(rowNode)
                    }
                })
            }
            if(arr1.length !== 0){
                arr.push(<tr><td>{number}</td><td>{name}</td><td>{arr1}</td></tr>)

            }
        }
        this.setState({err_msg:arr});
        this.setState({err_toggle:true});
    }

    closeError=()=>{
        const toggle = !this.state.err_toggle;
        this.setState({err_toggle:toggle});
    }

    skipAssign=()=>{
        Swal.fire({
            type:'warning',
            title:IMLocalized('skip_emp_claim_approval'),
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!')
        })
        .then((result)=>{
            if('value' in result){
                this.props.onNext()
                
            }
        })
    }

    goBack=()=>{
        Swal.fire({
            type:'warning',
            title:IMLocalized('skip_emp_wizard_confirmation'),
            showCancelButton:true,
            cancelButtonText:IMLocalized('no!'),
            confirmButtonText:IMLocalized('yes!')
        })
        .then((result)=>{
            if('value' in result){
                this.props.goBack()
            }
        })
        
    }

    render(){

        const {err_msg} = this.state;
        let err_btn ='';
        if(err_msg.length !== 0){
            err_btn = <button className="btn btn-danger btn-sm" onClick={this.closeError}>{IMLocalized('error')}</button>
        }


        return(
            <div>
                {this.props.isLoading ? <OverlayLoading/>:
                <Container fluid>
                    <Row className="pt-2">
                        <Col>
                            <div className="d-flex">
                                <div>
                                    <button className="btn btn-primary btn-sm" onClick={this.goBack}> <i className="fas fa-chevron-left"></i> {IMLocalized('back_to_main_page')}</button>
                                    <button onClick={this.skipAssign} className="btn btn-primary btn-sm">{IMLocalized('skip')}</button>
                                    <button onClick={this.generateExcel} className="btn btn-primary btn-sm">{IMLocalized('get_emp_claim_approval_template')}</button>
                                </div>
                                <div className="ml-2 mr-2">
                                    <label className="btn btn-primary btn-sm" for="massclaimapproval_file">{IMLocalized('import_from_file')}</label>
                                </div>
                                <div>
                                    
                                    <button onClick={this.clearData} className="btn btn-primary btn-sm">{IMLocalized('clear')}</button>
                                    <button onClick={this.onCreate} className="btn btn-primary btn-sm">{IMLocalized('assign')}</button>

                                    {err_btn}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col>
                            <div className="d-flex justify-content-center">
                                <div className="ag-theme-balham" style={{height:'calc(100vh - 200px)', width:'100%' }}>
                                    <AgGridReact
                                    columnDefs={this.state.columnsDefs}
                                    rowData={this.state.rowData}
                                    suppressDragLeaveHidesColumns={true}
                                    suppressSizeToFit={true}
                                    suppressColumnMoveAnimation={false}
                                    singleClickEdit={true}
                                    onCellFocused={this.onCellFocused}
                                    onCellValueChanged={this.onCellValueChanged}
                                    onGridReady={this.onGridReady}
                                    gridOptions={gridOptions}
                                    overlayNoRowsTemplate = {this.state.noRowTemplate}
                                    stopEditingWhenCellsLoseFocus={true}
                                    >
                                    </AgGridReact>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Modal isOpen={this.state.err_toggle} size="lg">
                        <ModalHeader toggle={this.closeError}>{IMLocalized('error_msg')}</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col>
                                    <Table size="sm" className="table-bordered align-items-center">
                                        <thead>
                                            <tr>
                                                <th>{IMLocalized('employee_number')}</th>
                                                <th>{IMLocalized('name')}</th>
                                                <th>{IMLocalized('error')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.err_msg}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn btn-danger btn-sm" onClick={this.closeError}>{IMLocalized('close')}</button>
                        </ModalFooter>
                    </Modal>
                    <input type="file" style={{display:'none'}} id="massclaimapproval_file" className="custom-file-input form-control-sm" accept=".xlsx"  onChange={this.uploadExcel} onClick={this.handleFile} />
                </Container>}
            </div>
        )
    }
}
function mapStateToProps(state){
    return{
        isLoading:state.get_claim_approval.isLoading,
        isLoading1:state.mass_assign_claim_approval.isLoading,
        approvallist:state.get_claim_approval.data,
        emplist:state.get_employee_list.data,
        success:state.mass_assign_claim_approval.data,
        errors:state.mass_assign_claim_approval.errors,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        get_claim_approval:()=>{
            dispatch(get_claim_approval())
        },
        mass_assign_claim_approval:(data)=>{
            dispatch(mass_assign_claim_approval(data))
        },
    }  
}


export default connect(mapStateToProps, mapDispatchToProps)(wizard_employee_claim_approval);
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const DELSGCHILD_REQUEST = 'DELSGCHILD_REQUEST';
export const DELSGCHILD_SUCCESS = 'DELSGCHILD_SUCCESS';
export const DELSGCHILD_FAILURE = 'DELSGCHILD_FAILURE';
export const DELSGCHILD_RESET = 'DELSGCHILD_RESET';

export const delete_sg_child = (id)=>{
    return dispatch=>{
        dispatch({
            type:'DELSGCHILD_REQUEST'
        });

        myaxios.delete(`leave/sg/child/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELSGCHILD_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELSGCHILD_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_sg_child = () => dispatch=>{
    dispatch({
        type:'DELSGCHILD_RESET'
    });
    
}
import * as empcert from '../../action/employee/get_employee_certification';

const initialState={
    isLoading:false,
    data:[],
    errors:null
}


const get_employee_certification = (state=initialState,action)=>{
    switch(action.type){
        case empcert.GETEMPCERTIFICATION_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:[],
            }
        case empcert.GETEMPCERTIFICATION_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case empcert.GETEMPCERTIFICATION_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_employee_certification;

export const emp_get_employee_certification = (state,id) =>{
    const { data } = state.get_employee_certification;
    return id != null ?  data.filter(item=>item.employee === parseInt(id)).sort((a,b)=>a.id - b.id) : [];
    
}

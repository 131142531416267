import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const EMPALLREJECTAPPLYCLAIM_REQUEST = 'EMPALLREJECTAPPLYCLAIM_REQUEST';
export const EMPALLREJECTAPPLYCLAIM_SUCCESS = 'EMPALLREJECTAPPLYCLAIM_SUCCESS';
export const EMPALLREJECTAPPLYCLAIM_FAILURE = 'EMPALLREJECTAPPLYCLAIM_FAILURE';

export const emp_all_reject_apply_claim = (id,comment)=>{
    return dispatch=>{
        dispatch({
            type:'EMPALLREJECTAPPLYCLAIM_REQUEST'
        });

        return myaxios.post(`claim/apply/${id}/reject/`,{
            comment:comment
        })
        .then((response)=>{
            dispatch({
                type:'EMPALLREJECTAPPLYCLAIM_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('reject_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'EMPALLREJECTAPPLYCLAIM_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('reject_failure')
            });
        })
    }
}
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATEPAYROLLPAYMENTDETAIL_REQUEST = 'UPDATEPAYROLLPAYMENTDETAIL_REQUEST';
export const UPDATEPAYROLLPAYMENTDETAIL_SUCCESS = 'UPDATEPAYROLLPAYMENTDETAIL_SUCCESS';
export const UPDATEPAYROLLPAYMENTDETAIL_FAILURE = 'UPDATEPAYROLLPAYMENTDETAIL_FAILURE';

export const update_payroll_paymentdetail = (id,reference)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEPAYROLLPAYMENTDETAIL_REQUEST'
        });

        myaxios.post(`payroll/payroll/${id}/PayslipUpdateReference/`,{
            data:reference
        })
        .then((response)=>{
            dispatch({
                type:'UPDATEPAYROLLPAYMENTDETAIL_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATEPAYROLLPAYMENTDETAIL_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}
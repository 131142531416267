import myaxios from '../axios';

export const CREATECONTRACTDEPARTMENT_SUCCESS = 'CREATECONTRACTDEPARTMENT_SUCCESS';
export const CREATECONTRACTDEPARTMENT_REQUEST = 'CREATECONTRACTDEPARTMENT_REQUEST';
export const CREATECONTRACTDEPARTMENT_FAILURE = 'CREATECONTRACTDEPARTMENT_FAILURE';
export const CREATECONTRACTDEPARTMENT_RESET = 'CREATECONTRACTDEPARTMENT_RESET';

export const create_contract_department = (name)=>{
    return dispatch =>{
        dispatch({
            type: 'CREATECONTRACTDEPARTMENT_REQUEST'
        });
        
        myaxios.post(`company/department/`,{
            name:name
        })
        .then((response)=>{
            dispatch({
                type:'CREATECONTRACTDEPARTMENT_SUCCESS',
                payload:response
            }
        )})
        .catch((error) =>{
            dispatch({
                type: 'CREATECONTRACTDEPARTMENT_FAILURE',
                payload:error
            })
        })
    }
}

export const reset_create_contract_department = () => dispatch =>{
    dispatch({
        type:'CREATECONTRACTDEPARTMENT_RESET'
    });
}
import * as leave_picture from '../../action/user/user_leave_get_leave_picture';

const initialState={
    data:null,
    errors:null,
    isLoading:false
}

const user_leave_get_leave_picture = (state=initialState,action)=>{
    switch(action.type){
        case leave_picture.USERLEAVEGETLEAVEPICTURE_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case leave_picture.USERLEAVEGETLEAVEPICTURE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case leave_picture.USERLEAVEGETLEAVEPICTURE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case leave_picture.USERLEAVEGETLEAVEPICTURE_RESET:
            return{
                ...state,
                data:null,
                errors:null,
            }
        default:
            return state;
    }
}
export default user_leave_get_leave_picture;
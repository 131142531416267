import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const CREATEHOLIDAY_SUCCESS = 'CREATEHOLIDAY_SUCCESS';
export const CREATEHOLIDAY_REQUEST = 'CREATEHOLIDAY_REQUEST';
export const CREATEHOLIDAY_FAILURE = 'CREATEHOLIDAY_FAILURE';
export const CREATEHOLIDAY_RESET = 'CREATEHOLIDAY_RESET';


export const create_holiday = (id,name,date,day)=>{
    return dispatch =>{
        dispatch({
            type: 'CREATEHOLIDAY_REQUEST'
        });
        
        myaxios.post(`contract/holiday/`,{
            name:name,
            date:date,
            day:day,
            holiday_list:id
        })
        .then((response)=>{
            dispatch({
                type:'CREATEHOLIDAY_SUCCESS',
                payload:response
                
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error) =>{
            dispatch({
                type: 'CREATEHOLIDAY_FAILURE',
                payload: error,
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_failure')
            });
        })
    }
}

export const reset_create_holiday = () => dispatch =>{
    dispatch({
        type:'CREATEHOLIDAY_RESET'
    });
}
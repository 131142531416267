import * as sg_address from '../../action/employee/get_employee_postal_code';

const initialState={
    data:[],
    errors:[],
    isLoading:false,
    address:[]
}

const get_employee_postal_code = (state=initialState,action)=>{
    switch(action.type){
        case sg_address.SGADDDRESS_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[],
                data:[]
            }
        case sg_address.SGADDDRESS_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
                address:action.payload.data.results,
                errors:[]
            }
        case sg_address.SGADDDRESS_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload,
                address:'',
                data:[]
            }
        default:
            return state;
    }
}
export default get_employee_postal_code;
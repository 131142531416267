import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const ARRAYEMPPASSPORT_REQUEST = 'ARRAYEMPPASSPORT_REQUEST';
export const ARRAYEMPPASSPORT_SUCCESS = 'ARRAYEMPPASSPORT_SUCCESS';
export const ARRAYEMPPASSPORT_FAILURE = 'ARRAYEMPPASSPORT_FAILURE';

export const array_create_employee_passport = (data)=>{
    return dispatch=>{
        dispatch({
            type:'ARRAYEMPPASSPORT_REQUEST'
        });

        myaxios.post('employee/passport/array_save/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'ARRAYEMPPASSPORT_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'ARRAYEMPPASSPORT_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}
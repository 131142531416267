import React, { useContext, useEffect, useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import XLSX from 'xlsx';
import ExcelJs from 'exceljs';
import FileSaver from 'file-saver';
import { get_additional_setting} from '../../action/additional_setting';
import { import_cas, remove_import_cas} from '../../action/company/import_cas';
import { IMLocalized} from '../../language/IMLocalized';
import ImportAdditionalTable from './content/ImportAdditionalTable';
import { popError } from '../../func/popError';
import ModalErrorImportAdditional from './modal/ModalErrorImportAdditional';
import { NewMainContext } from '../../context/NewMainContext';
import BreadCrumbList from '../breadcrumb/BreadCrumbList';


function ImportAdditional(props){

    const dispatch = useDispatch();
    const success = useSelector(state=>state.import_cas.data);
    const errors = useSelector(state=>state.import_cas.errors);
    const isLoading = useSelector(state=>state.import_cas.isLoading);
    const [ department, setDepartment ] = useState([]);
    const [ costcenter, setCostCenter ] = useState([]);
    const [ joblevel, setJobLevel ] = useState([]);
    const [ jobtitle, setJobTitle ] = useState([]);
    const [ section , setSection ] = useState([]);
    const [ err_msg , setErrMsg] = useState(null);
    const [ err_toggle, setErrToggle] = useState(false);
    const { addItem, import_additional_setting_reset, setImportAdditionalSettingReset } = useContext(NewMainContext);


    useEffect(()=>{
        if(success != null){
            dispatch(remove_import_cas());
            dispatch(get_additional_setting());
            setDepartment([]);
            setSection([]);
            setCostCenter([]);
            setJobLevel([]);
            setJobTitle([]);
        }
    },[success,dispatch])

    useEffect(()=>{
        if(import_additional_setting_reset === true){
            setDepartment([]);
            setSection([]);
            setCostCenter([]);
            setJobLevel([]);
            setJobTitle([]);
            setImportAdditionalSettingReset(false);
        }
    },[import_additional_setting_reset, setImportAdditionalSettingReset])

    useEffect(()=>{
        if( errors != null){
            let obj = {};
            if(errors.hasOwnProperty('department')){
                obj['department'] = errors.department;
            }
            if(errors.hasOwnProperty('section')){
                obj['section'] = errors['section'];
            }
            if(errors.hasOwnProperty('jobtitle')){
                obj['jobtitle'] = errors['jobtitle'];
            }
            if(errors.hasOwnProperty('joblevel')){
                obj['joblevel'] = errors['joblevel'];
            }
            if(errors.hasOwnProperty('costcenter')){
                obj['costcenter'] = errors['costcenter'];
            }
            setErrMsg(obj)
            setErrToggle(true);
            dispatch(remove_import_cas());
        }
    },[errors,dispatch])

    const getTemplate=()=>{
        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet(IMLocalized('additioanl_setting'));
        const ws2 = wb.addWorksheet('id');
        ws2.getCell('A1').value = "ImportCas";
        ws.columns =[
            {header:IMLocalized('department')},
            {header:IMLocalized('section')},
            {header:IMLocalized('job_title')},
            {header:IMLocalized('job_level')},
            {header:IMLocalized('cost_center')}
        ];
        ws.columns.forEach(column =>{
            column.width = column.header.length < 15 ? 15 : column.header.length
        })

        wb.xlsx.writeBuffer().then((buf)=>{
           
            const file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
            FileSaver.saveAs(file, 'Additional_Setting.xlsx')
        });
    }

    const uploadExcel=(e)=>{
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload=(e)=>{
            const bstr = e.target.result;
            const wb = XLSX.read(bstr,{type:'binary'});
            const wsname = wb.SheetNames[0];
            const wsname2 = wb.SheetNames[1];
            const ws = wb.Sheets[wsname];
            const ws2 = wb.Sheets[wsname2];
            const idd = ws2 !== undefined && ws2['A1'] !== undefined ? ws2['A1'].w : '';

            const columns = {
                'A':'name',
            }

            const section_columns = {
                'B':'name',
            }

            const title_columns = {
                'C':'name',
            }

            const level_columns = {
                'D':'name',
            }

            const cost_columns = {
                'E':'name',
            }

            let department=[];
            let section =[];
            let jobtitle=[];
            let joblevel =[];
            let costcenter=[];
            if(idd === 'ImportCas'){
                let rowIndex = 2;
                let section_rowIndex = 2;
                let title_rowIndex = 2;
                let level_rowIndex = 2;
                let cost_rowIndex = 2;
                while(ws['A'+rowIndex]){
                    let row ={};

                    for(let i =0;i<Object.keys(columns).length;i++){
                        const column = Object.keys(columns)[i];
                        const index = column + rowIndex;
                        if(ws[index] !== undefined){
                            row[columns[column]] = ws[index].w;
                            department.push(ws[index].w);
                        }
                    }
                    
                    rowIndex++;
                }
    
                while(ws['B'+section_rowIndex]){
                    let row ={};
                    for(let i =0;i<Object.keys(section_columns).length;i++){
                        const column = Object.keys(section_columns)[i];
                        const index = column + section_rowIndex;
                        if(ws[index] !== undefined){
                            row[columns[column]] = ws[index].w;
                            section.push(ws[index].w);
                        }
                    }
                    section_rowIndex++;
                }
    
                while(ws['C'+title_rowIndex]){
                    let row ={};
                    for(let i =0;i<Object.keys(title_columns).length;i++){
                        const column = Object.keys(title_columns)[i];
                        const index = column + title_rowIndex;
                        if(ws[index] !== undefined){
                            row[columns[column]] = ws[index].w;
                            jobtitle.push(ws[index].w);
                        }
                    }
                    title_rowIndex++;
                }
    
                while(ws['D'+level_rowIndex]){
                    let row ={};
                    for(let i =0;i<Object.keys(level_columns).length;i++){
                        const column = Object.keys(level_columns)[i];
                        const index = column + level_rowIndex;
                        if(ws[index] !== undefined){
                            row[columns[column]] = ws[index].w;
                            joblevel.push(ws[index].w);
                        }
                    }
                   
                    level_rowIndex++;
                }
    
                while(ws['E'+cost_rowIndex]){
                    let row ={};
                    for(let i =0;i<Object.keys(cost_columns).length;i++){
                        const column = Object.keys(cost_columns)[i];
                        const index = column + cost_rowIndex;
                        if(ws[index] !== undefined){
                            row[columns[column]] = ws[index].w;
                            costcenter.push(ws[index].w);
                        }
                    }
                    
                    cost_rowIndex++;
                }
                setDepartment(department);
                setSection(section);
                setJobTitle(jobtitle);
                setJobLevel(joblevel);
                setCostCenter(costcenter);
            }
            else{
                popError(IMLocalized('wrong_file_selected'));
            }
        }
        reader.readAsBinaryString(file);
    }
    

    const deleteItem=(type,index)=>{
        if(type === 'department'){
            const data = [...department];
            data.splice(index,1);
            setDepartment(data);
        }
        else if(type === 'section'){
            const data = [...section];
            data.splice(index,1);
            setSection(data);
        }
        else if(type === 'jobtitle'){
            const data = [...jobtitle];
            data.splice(index,1);
            setJobTitle(data);
        }
        else if(type === 'joblevel'){
            const data = [...joblevel];
            data.splice(index,1);
            setJobLevel(data);
        }
        else{
            const data = [...costcenter];
            data.splice(index,1);
            setCostCenter(data);
        }
    }

    const clearData=()=>{
        setDepartment([]);
        setSection([]);
        setCostCenter([]);
        setJobLevel([]);
        setJobTitle([]);
        setErrMsg(null);
    }

    const onValidate=()=>{
        dispatch(import_cas(department,section,jobtitle,joblevel,costcenter));
    }

    return(
        <>
            <Container fluid>
                <Row className='border-bottom bg-white'>
                    <Col className='p-2'>
                        <BreadCrumbList list={[
                            { active:false, title:IMLocalized('home'), onClick:()=>addItem('1','dashboard')},
                            { active:true, title:IMLocalized('import_additional_setting')}
                        ]}
                        />
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col >
                        <div className='d-flex'>
                            <div>
                                <button className='btn btn-primary btn-sm' onClick={getTemplate}>{IMLocalized('get_template')}</button>
                            </div>  
                            <div className="ml-2 mr-2">
                                <input type="file" id="ImportDepartmentFile" className="custom-file-input form-control-sm d-none" accept=".xlsx"
                                onClick={(e)=>{
                                    const { target = {} } = e || {};
                                    target.value ="";
                                }}
                                onChange={(e)=>uploadExcel(e)}  />
                                <label className="btn btn-primary btn-sm" htmlFor="ImportDepartmentFile">{IMLocalized('upload_file')}</label>
                            </div>
                            {department.length !== 0 || section.length !== 0 || joblevel.length !== 0 || jobtitle.length !== 0 || costcenter.length !== 0 ?
                            <div className='mr-2'>
                                <button className="btn btn-primary btn-sm" onClick={()=>clearData()}>{IMLocalized('clear')}</button>
                                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>:
                                <button className="btn btn-primary btn-sm" onClick={()=>onValidate()}>{IMLocalized('create')}</button>}
                                
                            </div>:null}
                        </div>
                    </Col>
                </Row>
                {department.length !== 0 || section.length !== 0 || joblevel.length !== 0 || jobtitle.length !== 0 || costcenter.length !== 0 ?
                <ImportAdditionalTable department={department} section={section} joblevel={joblevel} jobtitle={jobtitle} costcenter={costcenter} deleteItem={deleteItem} /> : null
                }
            </Container>
            <ModalErrorImportAdditional toggle={err_toggle} list={err_msg} closeModal={()=>setErrToggle(false)} />

        </>
    )
}
export default ImportAdditional;
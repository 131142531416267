import React from 'react';
import { Modal,ModalHeader,ModalBody,Table,Row,Col,ModalFooter } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { getDateFormat } from '../../../../func/getDateFormat';

function ModalViewChild(props){

    return(
        <Modal size="lg" isOpen={props.toggle}>
            <ModalHeader toggle={props.closeModal}>{IMLocalized('employee_children_detail')}</ModalHeader>
            <ModalBody>
                {props.detail &&
                <Row>
                    <Col>
                        <Table className="table-bordered" size="sm">
                            <tbody>
                                <tr>
                                    <td className="w-25">{IMLocalized('child_name')}</td>
                                    <td>
                                        {props.detail.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('date_of_birth')}</td>
                                    <td>{getDateFormat(props.detail.date_of_birth)}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('childcare_leave_start_date')}</td>
                                    <td>
                                        {getDateFormat(props.detail.childcare_leave_start_date)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('leave_start_next_year')}</td>
                                    <td>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" id={'hrchildleave_year_start_date'} className="custom-control-input" name="hrchild_leave_start_next_year" checked={props.detail.leave_start_next_year} readOnly/>
                                            <label className="custom-control-label" htmlFor={'hrchildleave_year_start_date'}></label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('is_sg')}</td>
                                    <td>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" id={'hrchildis_sg'} className="custom-control-input" name="is_sg" checked={props.detail.is_sg} readOnly/>
                                            <label className="custom-control-label" htmlFor={'hrchildis_sg'}></label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-25">{IMLocalized('is_adopted')}?</td>
                                    <td>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" id={'hrchildis_adopted'} className="custom-control-input" name="is_adopted" checked={props.detail.is_adopted} readOnly/>
                                            <label className="custom-control-label" htmlFor={'hrchildis_adopted'}></label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                }
                
            </ModalBody>
            <ModalFooter>
                <button className='btn btn-danger btn-sm' onClick={props.closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
export default ModalViewChild;
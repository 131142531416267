import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const DELETECLAIMAPPROVAL_REQUEST = 'DELETECLAIMAPPROVAL_REQUEST';
export const DELETECLAIMAPPROVAL_SUCCESS = 'DELETECLAIMAPPROVAL_SUCCESS';
export const DELETECLAIMAPPROVAL_FAILURE = 'DELETECLAIMAPPROVAL_FAILURE';
export const DELETECLAIMAPPROVAL_RESET = 'DELETECLAIMAPPROVAL_RESET';

export const delete_claim_approval = (id) =>{
    return dispatch=>{
        dispatch({
            type:'DELETECLAIMAPPROVAL_REQUEST'
        });

        myaxios.delete(`claim/approval/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELETECLAIMAPPROVAL_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELETECLAIMAPPROVAL_FAILURE',
                payload:error
            });
            
            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            });
        })
    }
}


export const reset_delete_claim_approval = () => dispatch =>{
    dispatch({
        type:'DELETECLAIMAPPROVAL_RESET'
    });
}
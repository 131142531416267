import myaxios from '../axios';
export const REQUEST_SCHEDULELIST = 'REQUEST_SCHEDULELIST';
export const GET_SCHEDULELIST = 'GET_SCHEDULELIST';
export const FAILURE_SCHEDULELIST = 'FAILURE_SCHEDULELIST';

export const getScheduleList = ()=>{
    return dispatch =>{
        dispatch({
            type: 'REQUEST_SCHEDULELIST'
        });
        myaxios.get('contract/schedules/')
        .then((response)=>{
            dispatch({
                type:'GET_SCHEDULELIST',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'FAILURE_SCHEDULELIST',
                payload:error,
                error:true
            })
        })
    }
}
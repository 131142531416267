import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UPDATEUSERLAYOUT_REQUEST = 'UPDATEUSERLAYOUT_REQUEST';
export const UPDATEUSERLAYOUT_SUCCESS = 'UPDATEUSERLAYOUT_SUCCESS';
export const UPDATEUSERLAYOUT_FAILURE = 'UPDATEUSERLAYOUT_FAILURE';

export const update_user_layout = (layout)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEUSERLAYOUT_REQUEST',
        })

        myaxios.post('general/update_user_view_setting/',{
            data:layout
        })
        .then((response)=>{
            dispatch({
                type:'UPDATEUSERLAYOUT_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATEUSERLAYOUT_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}
import myaxios from '../../axios';

export const GETSKIPOPTION_REQUEST = 'GETSKIPOPTION_REQUEST';
export const GETSKIPOPTION_SUCCESS = 'GETSKIPOPTION_SUCCESS';
export const GETSKIPOPTION_FAILURE = 'GETSKIPOPTION_FAILURE';

export const get_skip_option = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETSKIPOPTION_REQUEST'
        });

        myaxios.get('general/dashboard/')
        .then((response)=>{
            dispatch({
                type:'GETSKIPOPTION_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETSKIPOPTION_FAILURE',
                payload:error
            })
        })
    }
}
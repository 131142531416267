import myaxios from '../../axios';
export const REQUEST_EMPRANGE = 'REQUEST_EMPRANGE';
export const GET_EMPRANGE = 'GET_EMPRANGE';
export const FAILURE_EMPRANGE = 'FAILURE_EMPRANGE';


export const employee_range = (start_date1,end_date1)=>{
    return dispatch =>{
        dispatch({
            type: 'REQUEST_EMPRANGE'
        });
        myaxios.post(`/employee/range/`,{
            from_date:start_date1,
            to_date:end_date1
        })
        .then((response)=>{
            dispatch({
                type:'GET_EMPRANGE',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'FAILURE_EMPRANGE',
                payload:error
            })
        })
    }
}

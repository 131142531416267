import React from 'react';
import { Card, CardHeader, Table } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';


export default function OvertimeTable(props){

    return(
        <>
        <Card style={{ overflow:'auto',overflowX:'hidden',maxHeight:'400px'}}>
            <CardHeader className='p-2 border-0 bg-white'>
                <h4 className='pl-2 pt-2 text-primary'>{IMLocalized('overtime')}</h4>
            </CardHeader>
            <Table size="sm" className='align-items-center' responsive>
                <thead className='text-center thead-light p-1'>
                    <tr>
                        <th className="p-2">{IMLocalized('payslip_ot_name')}</th>
                        <th className="p-2">{IMLocalized('payslip_rate')}</th>
                        <th className="p-2">{IMLocalized('payslip_count')}</th>
                        <th className="p-2">{IMLocalized('amount')}</th>
                    </tr>
                </thead>
                <tbody className='text-center p-1'>
                    {props.list.map((item,index)=>{
                        return(
                            <tr key={index}>
                                <td className="p-2">{item.name}</td>
                                <td className="p-2">{item.rate}</td>
                                <td className="p-2">{item.count}</td>
                                <td className="p-2">${item.amount.toFixed(2)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </Card>
        </>
    )
}
import * as empbank from '../../action/employee/get_employee_bank';

const initialState ={
    data:[],
    errors:null,
    isLoading:false,
}


const get_employee_bank = (state=initialState,action) =>{
    switch(action.type) {
        case empbank.REQUEST_EMPBANK:
            return{
                ...state,
                isLoading: true,
                data:[],
                errors:null,
            }
        case empbank.GET_EMPBANK:
            return{
                ...state,
                data: action.payload?.data,
                isLoading:false,
            }
        case empbank.FAILURE_EMPBANK:
            return{
                ...state,
                errors:action.payload,
                isLoading:false
            }
        default:
            return state;
    }
}
export default get_employee_bank;

function sortBank(){

    return function(a,b){
        if(a.default === b.default){
            return 0;
        }
        else if (a.default === null){
            return 1;
        }
        else if (b.default === null){
            return -1;
        }
        else{
            return a < b ? -1 : 1;
        }
    }
}

export const emp_get_employee_bank = (state,id)=>{

    const { data } = state.get_employee_bank;

    return data.filter(item => item.employee === id).sort(sortBank());

}
import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const DETAILUPDATEPAYMENTCOUNT_REQUEST = 'DETAILUPDATEPAYMENTCOUNT_REQUEST';
export const DETAILUPDATEPAYMENTCOUNT_SUCCESS = 'DETAILUPDATEPAYMENTCOUNT_SUCCESS';
export const DETAILUPDATEPAYMENTCOUNT_FAILURE = 'DETAILUPDATEPAYMENTCOUNT_FAILURE';


export const payroll_detail_update_payment_count = (id,data)=>{
    return dispatch=>{
        dispatch({
            type:'DETAILUPDATEPAYMENTCOUNT_REQUEST'
        });
        
        myaxios.post(`payroll/payroll/${id}/UpdatePaymentCount/`,{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'DETAILUPDATEPAYMENTCOUNT_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DETAILUPDATEPAYMENTCOUNT_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Input, InputGroup, InputGroupAddon, InputGroupText, Tooltip } from 'reactstrap';
import Swal from 'sweetalert2';
import { popError } from '../../func/popError';
import { IMLocalized } from '../../language/IMLocalized';
import { usePrevious } from '../../hook/usePrevious';
import { get_payroll_instance } from '../../action/payroll/get_payroll_instance';
import { payroll_detail_get_aggridheader } from '../../action/payroll/payroll_detail_get_aggridheader';
import {get_payroll_list} from '../../action/payroll/get_payroll_list';

const mapStateToProps=(state)=>({
    // agheader:state.payroll_detail_get_aggridheader.data,
    list:state.get_payroll_instance.data,
    
    //To fetch the list
    // payroll_list:state.get_payroll_list.data,
})

const mapDispatchToProps=(dispatch)=>({
    payroll_detail_get_aggridheader:(id)=>{
        dispatch(payroll_detail_get_aggridheader(id))
    },
    get_payroll_instance:(id)=>{
        dispatch(get_payroll_instance(id))
    },
    //Dispatch the list
    get_payroll_list:()=>{
        dispatch(get_payroll_list())
    },

})

const gridOption={
    rowHeight: 40,
    defaultColDef:{
        suppressMenu:true,
        resizable:true,
        filter:true,
        editable:true
    },
    sideBar:{
        toolPanels: [
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                toolPanelParams: {
                },
            },
        ],
        defaultToolPanel: '',
    },
    singleClickEdit:true
}

function PayrollDetailAggrid(props){
    const {payroll_list} = props;
    // const {setEdit,toPayrollPayslip,is_edit} = props;
    const [quickFilterText,setQuickFilterText] = useState('');
    const [columnDefs,setColumnDefs] = useState([]);
    const [rowData,setRowData] = useState([]);

    const myCellRenderer=useCallback((par)=>{

        return <button className='btn btn-info btn-sm'
        onClick={()=>{
            let {pid,status,type} = par.data;
            if(status === 'PAID' || status === 'LOCK'){
                props.setDetail({step:'2',id:pid});
            }
            else{
                props.setDetail({step:'1',id:pid});
            }
            localStorage.setItem('payroll_status',status);
            localStorage.setItem('payroll_type',type);
        }}
        >{IMLocalized('view')}</button>

    },[props.setDetail])

    const monthNames=[IMLocalized('january'), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
    IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")]

    useEffect(()=>{
        let arr =[];

        if(payroll_list.length !== 0){
            for(let i=0;i<payroll_list.length ;i++){
                const item = payroll_list[i];
                let aggrid = {};

                const month1 = new Date(item.payroll_date).getMonth();
                const month = monthNames[month1];
                const year = new Date(item.payroll_date).getFullYear();
                let payroll_date = `${month} ${year}`;
                const type = item.type;
                if(type === 'BONUS'){
                    payroll_date = `Bonus ${month} ${year}`;
                }
                if(type === 'HMTH1'){
                    payroll_date = `Half of ${month} ${year}`;
                }

                const start_date = item.start_date;
                const end_date = item.end_date;
                const pid = item.id;
                const status = item.status;
                
                aggrid = {payroll_date,type,start_date,end_date,pid,status}

                arr.push({...aggrid});
            }
            
            setRowData(arr);
        }
        else{
            setRowData([]);
        }
       
    },[props.list,payroll_list])

    useEffect(()=>{
        let newlist = [
            {
                headerName:IMLocalized('payroll'),
                field:'payroll_date',
                lockPosition:true,
                hide:false,
                pinned:'left',
                width:300,
                filter:true,
                editable:false
            },
            {
                headerName:IMLocalized('type'),
                field:'type',
                lockPosition:true,
                pinned:'left',
                width:140,
                hide:false,
                filter:true,
                editable:false,
            },
            {
                headerName:IMLocalized('start_date'),
                field:'start_date',
                lockPosition:true,
                pinned:'left',
                width:140,
                hide:false,
                filter:true,
                editable:false,
            },
            {
                headerName:IMLocalized('end_date'),
                field:'end_date',
                editable:false,
                lockPosition:true,
                pinned:'left',
                hide:false,
                filter:true,
                width:140,
            },
            {
                headerName:IMLocalized('status'),
                field:'status',
                editable:false,
                lockPosition:true,
                pinned:'left',
                hide:false,
                filter:true,
                width:140,
            },
            {
                headerName:IMLocalized('action'),
                // field:'pid',
                lockPosition:true,
                pinned:'left',
                hide:false,
                width:120,
                editable:false,
                filter:false,
                cellRendererFramework:myCellRenderer
            }
        ];

        setColumnDefs(newlist);

    },[myCellRenderer])

    return(
        <>
        <div className='d-flex justify-content-between'>
            <div>
            <InputGroup size="sm" style={{width:'300px'}}>
                <Input type="text" value={quickFilterText} onChange={(e)=>{const value = e.target.value ; setQuickFilterText(value)}}/>
                    <InputGroupAddon addonType='append'>
                        <InputGroupText><i className='fas fa-search' /></InputGroupText>
                    </InputGroupAddon>
            </InputGroup>
            </div>
        </div>
        
        <div className='ag-theme-balham mt-2' style={{height:'calc(100vh - 350px)', width:'100%' }}>
            <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            overlayNoRowsTemplate={IMLocalized('no_data')}
            enableSorting={true}
            overlayLoadingTemplate={IMLocalized('loading')}
            quickFilterText={quickFilterText}
            suppressDragLeaveHidesColumns={true}
            enableColResize={true}
            stopEditingWhenCellsLoseFocus={true}
            gridOptions={gridOption}
            />
        </div>
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(PayrollDetailAggrid);
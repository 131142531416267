import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const VALIDATECLAIMPAY_REQUEST = 'VALIDATECLAIMPAY_REQUEST';
export const VALIDATECLAIMPAY_SUCCESS = 'VALIDATECLAIMPAY_SUCCESS';
export const VALIDATECLAIMPAY_FAILURE = 'VALIDATECLAIMPAY_FAILURE';
export const VALIDATECLAIMPAY_RESET = 'VALIDATECLAIMPAY_RESET';


export const validate_claim_pay = (input_claim_ids,pay_date,send_to_payroll) =>{
    return dispatch=>{
        dispatch({
            type:'VALIDATECLAIMPAY_REQUEST'
        });

        myaxios.post('claim/pay/validate/',{
            input_claim_ids:input_claim_ids,
            pay_date:pay_date,
            send_to_payroll:send_to_payroll
        })
        .then(()=>{
            myaxios.post('claim/pay/',{
                input_claim_ids:input_claim_ids,
                pay_date:pay_date,
                send_to_payroll:send_to_payroll
            })
            .then((response)=>{
                dispatch({
                    type:'VALIDATECLAIMPAY_SUCCESS',
                    payload:response
                });
                
                Toast.fire({
                    type:'success',
                    title:IMLocalized('paid_success')
                });
            })
        })
        .catch((error)=>{
            dispatch({
                type:'VALIDATECLAIMPAY_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('paid_failure')
            });
        })
    }
}

export const reset_validate_claim_pay = () => dispatch =>{
    dispatch({
        type:'VALIDATECLAIMPAY_RESET'
    });
}
import React, {useCallback, useRef} from 'react';
import {AgGridReact} from 'ag-grid-react';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import {Row,Col} from 'reactstrap';




function ModalUobAggrid(props){

    const gridRef = useRef();
    const gridOptions={
        defaultColDef:{
            suppressMenu:true,
            filter:false,
        },
        suppressExcelExport:true
    }

    const handleCopy = useCallback(()=>{
       gridRef.current.api.copySelectedRowsToClipboard();
    },[])

    const handleSelectAll = useCallback(()=>{
        gridRef.current.api.selectAll();
    },[])

    const handleClear = useCallback(()=>{
        gridRef.current.api.deselectAll()
    },[])

    const columnsDefs = [
        {
            headerName:'Payment Instruction (PI)',
            field:'A',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Receiving BIC Code',
            field:'B',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Receiving Bank A/C No (omit dashes)',
            field:'C',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Receiving A/C Name',
            field:'D',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Amount',
            field:'E',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Beneficiary Reference',
            field:'F',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'DDA Reference',
            field:'G',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Purpose Code',
            field:'H',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Remittance Information',
            field:'I',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Ultimate Payer/Beneficiary Name',
            field:'J',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Customer Reference',
            field:'K',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Beneficiary Advice Indicator (Y/N)',
            field:'L',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Beneficiary City',
            field:'M',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Beneficiary Country Code',
            field:'N',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Beneficiary Postal Code',
            field:'O',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Beneficiary Name Line 1',
            field:'P',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Beneficiary Name Line 2',
            field:'Q',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Beneficiary Name Line 3',
            field:'R',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Beneficiary Name Line 4',
            field:'S',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Beneficiary Address Line 1',
            field:'T',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Beneficiary Address Line 2',
            field:'U',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Beneficiary Address Line 3',
            field:'V',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Beneficiary Address Line 4',
            field:'W',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Email Address of Beneficiary',
            field:'X',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:'Facsimile Address of Beneficiary',
            field:'Y',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:"Payer's Name Line 1",
            field:'Z',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        },
        {
            headerName:"Payer's Name Line 2",
            field:'AA',
            filter:false,
            lockPosition:true,
            suppressMenu:true
        }
    ]

    return(
        <div className='mt-2'>
            <Row>
                <Col>
                    <h3>{props.company_name}</h3>
                </Col>
                <Col className="col-auto">
                    <button className='btn btn-danger btn-sm' onClick={handleClear}>Clear</button>
                    <button className='btn btn-primary btn-sm' onClick={handleSelectAll}>Select All</button>
                    <button className='btn btn-primary btn-sm' onClick={handleCopy}>Copy</button>
                </Col>
            </Row>
           
            <div className="d-flex justify-content-center">
                <div className="ag-theme-balham" style={{height:'300px', width:'100%' }}>
                    <AgGridReact
                    ref={gridRef}
                    gridOptions={gridOptions}
                    columnDefs={columnsDefs}
                    rowData={props.rowData}
                    rowSelection={'multiple'}
                    enableRangeSelection={true}
                    suppressExcelExport={true}
                    suppressCsvExport={true}
                    >
                    </AgGridReact>
                </div>
            </div>
        </div>
        
    )
}
export default ModalUobAggrid;
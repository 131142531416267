import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Col, FormFeedback,Input,UncontrolledTooltip, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import { create_leave, reset_create_leave } from '../../../action/create_leave';
import { get_leave_list } from '../../../action/get_leave_list';

function ModalCreateLeaveType({toggle, closeModal}){
    
    const dispatch = useDispatch();
    const leavetypelist = useSelector(state=>state.dropdown_list.leave_type);
    const initializationlist = useSelector(state=>state.dropdown_list.leave_setting_year_initialization);
    const create_success = useSelector(state=>state.create_leave.data);
    const errors = useSelector(state=>state.create_leave.errors);
    const isLoading = useSelector(state=>state.create_leave.isLoading);
    const [code,setCode] = useState('');
    const [err_code,setErrCode] = useState('');
    const [name,setName] = useState('');
    const [err_name,setErrName] = useState('');
    const [leave_type,setLeaveType] = useState('');
    const [can_user_apply,setCanUserApply] = useState(false);
    const [deductable,setDeductable] = useState(false);
    const [earned_can_negative,setEarnNegative] = useState(false);
    const [min,setMin] = useState(1);
    const [max,setMax] = useState(2);
    const [year_initialization,setYear] = useState('');
    const [err_leave_type,setErrLeaveType] = useState('');
    const [err_min,setErrMin] = useState('');
    const [err_max,setErrMax] = useState('');
    const [err_year,setErrYear] = useState('');
    const [photo,setPhoto] = useState(false);
    const [is_npl,setNpl] = useState(false);
    const [is_proration,setProration] = useState(false);
    

    useEffect(()=>{
        if( errors !== null && toggle){
            if(errors.hasOwnProperty('code')){
                const word = errors['code'].toString();
                setErrCode(word);
            }
            if(errors.hasOwnProperty('name')){
                const word = errors['name'].toString();
                setErrName(word);
            }
            if(errors.hasOwnProperty('minimum_images')){
                const word = errors['minimum_images'].toString();
                setErrMin(word);
            }
            dispatch(reset_create_leave());
        }
    },[toggle,errors,dispatch])

    useEffect(()=>{
        if( create_success !== null && toggle){
            closeModal();
            dispatch(get_leave_list());
            dispatch(reset_create_leave());
        }
    },[create_success,closeModal,dispatch,toggle])

    useEffect(()=>{
        if(toggle === true){
            setCode('');
            setErrCode('');
            setName('');
            setErrName('');
            setLeaveType('');
            setCanUserApply(false);
            setDeductable(false);
            setEarnNegative(false);
            setMin(1);
            setMax(2);
            setYear('');
            setErrLeaveType('');
            setErrMin('');
            setErrMax('');
            setErrYear('');
            setPhoto(false);
            setNpl(false);
            setProration(false);
        }
    },[toggle])

    const onSubmit = () => {
        let min1 =min,max1=max;
        let leave_type1 = leave_type;
        let year_initialization1 = year_initialization;
        if(code !== '' && name !== ''){
            let invalid = false;
            if(photo === true){
                if(min === '' || max === ''){
                    if(min === ''){
                        setErrMin(IMLocalized('minimum_image_is_required'));
                    }
                    if(max === ''){
                        setErrMax(IMLocalized('maximum_image_is_required'));
                    }
                    invalid = true;
                }
                else if(min > max){
                    setErrMax(IMLocalized('max_cannot_less_min'));
                    invalid = true;
                }
                else{
                    min1 = 0;
                    max1 = 0;
                }
            }

            if(deductable === true || deductable === 'true'){
                if(leave_type === '' || year_initialization === ''){
                    if(leave_type === ''){
                        setErrLeaveType(IMLocalized('leave_type_required'));
                        invalid = true;
                    }

                    if(year_initialization === ''){
                        setErrYear(IMLocalized('year_initialization_is_required'));
                        invalid = true;
                    }
                }
            }
            else{
                leave_type1 = 'F';
                year_initialization1 = 'NA';
            }

            if(!invalid){
                dispatch(create_leave(code,name,leave_type1,can_user_apply,deductable,earned_can_negative,min1,max1,year_initialization1,is_npl,is_proration));
            }
        }
        else{
            if(code === ''){
                setErrCode(IMLocalized('leave_code_is_required'));
            }
            if(name === ''){
                setErrName(IMLocalized('leave_name_is_required'));
            }
        }
    }


    return(
        <Modal isOpen={toggle} size="lg">
            <ModalHeader toggle={closeModal}>{IMLocalized('new_leave_type')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='code'>{IMLocalized('leave_code')}</label>
                            <Input size="sm" id="code" type="text" name="code" value={code} onChange={(e)=>{setCode(e.target.value); setErrCode('')}} invalid={err_code.length >0} />
                            <FormFeedback>{err_code}</FormFeedback>
                            <div>
                                <span className='text-red'>*</span><small>{IMLocalized('leave_code_tooltip1')}</small>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>{IMLocalized('leave_name')}</label>
                            <Input size="sm" id="name" type="text" name="name" value={name} onChange={(e)=>{setName(e.target.value); setErrName('') }} invalid={err_name.length >0} />
                            <FormFeedback>{err_name}</FormFeedback>
                        </FormGroup>    
                    </Col> 
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='deductible'>{IMLocalized('deductible')}</label>
                            <Input size="sm" type="select" id="deductible" name="deductible" value={deductable} onChange={(e)=>{setDeductable(e.target.value)}}>
                                <option value={false}>{IMLocalized('no')}</option>
                                <option value={true}>{IMLocalized('yes')}</option>
                            </Input>
                            <div>
                                <span className="text-red">*</span><small> {IMLocalized('deductible_tooltip2')}</small>
                            </div>
                            <div>
                                <span className="text-red">*</span><small> {IMLocalized('deductible_tooltip3')}</small>
                            </div>
                        </FormGroup>
                    </Col>
                    {deductable === 'true' && 
                    <>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='leave_type'>{IMLocalized('leave_type')}</label>
                            <Input size="sm" type="select" name="leave_type" id="leave_type" value={leave_type} onChange={(e)=>{setLeaveType(e.target.value); setErrLeaveType('')}} invalid={err_leave_type.length >0}>
                                <option></option>
                                {leavetypelist.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input> 
                            <FormFeedback>
                                {err_leave_type}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='year_initialization'>{IMLocalized('year_initialization')}</label>
                            <Input size="sm" type="select" name="year_initialization" id="year_initialization" value={year_initialization} onChange={(e)=>{setYear(e.target.value); setErrYear('')}} invalid={err_year.length >0}>
                                <option></option>
                                {initializationlist.map((item,index)=>{
                                    return <option value={item.value} key={index}>{item.display}</option>
                                })}
                            </Input>
                            <FormFeedback>{err_year}</FormFeedback>
                            <div>
                                <span className="text-red">*</span><small> {IMLocalized('year_initialization_tooltip1')}</small>
                            </div>
                            <div>
                                <span className="text-red">*</span><small> {IMLocalized('year_initialization_tooltip2')}</small>
                            </div>
                            <div>
                                <span className="text-red">*</span><small> {IMLocalized('year_initialization_tooltip3')}</small>
                            </div>
                        </FormGroup>
                    </Col>
                    </>}
                </Row>
                <Row>
                    <Col lg="12" className="d-flex align-items-center">
                        <div className="custom-control custom-checkbox checkboxes">
                            <input type="checkbox" id="requirephoto" name='photo' className="custom-control-input" checked={photo} onChange={()=>setPhoto(state=>!state)} />
                            <label className="custom-control-label" htmlFor="requirephoto">
                                {IMLocalized('require_to_upload_photo')}
                            </label>
                        </div>
                    </Col>
                    {photo &&
                    <>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='min'>{IMLocalized('minimum_image')}</label>
                            <Input size="sm" type="number" min="0" id="min" name="min" value={min} onChange={(e)=>setMin(e.target.value)} invalid={err_min.length >0} />
                            <FormFeedback>{err_min}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className='form-control-label' htmlFor='max'>{IMLocalized('maximum_image')}</label>
                            <Input size="sm" type="number" min="0" id="max" name="max" value={max} onChange={(e)=>setMax(e.target.value)} invalid={err_max.length >0} />
                            <FormFeedback>{err_max}</FormFeedback>
                        </FormGroup>
                    </Col>
                    </>}
                    <Col lg="12">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="new_leave_user_apply" name='can_user_apply' className="custom-control-input" checked={can_user_apply} onChange={()=>setCanUserApply(state=>!state)} />
                            <label className="custom-control-label" id="tooltip_apply" htmlFor="new_leave_user_apply">{IMLocalized('can_user_apply')}
                                <UncontrolledTooltip delay={0} placement="top" target="tooltip_apply">
                                    {IMLocalized('can_user_apply_tooltip1')}
                                </UncontrolledTooltip>
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="new_leave_earned" name='earned_can_negative' className="custom-control-input" checked={earned_can_negative} onChange={()=>setEarnNegative(state=>!state)} />
                            <label className="custom-control-label" id="tooltip_earned" htmlFor="new_leave_earned">{IMLocalized('earned_can_negative')}
                                <UncontrolledTooltip delay={0} placement="top" target="tooltip_earned" > 
                                    {IMLocalized('earned_can_negative_tooltip')}
                                </UncontrolledTooltip>
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="new_leave_is_npl" name='is_npl' className="custom-control-input" checked={is_npl} onChange={()=>setNpl(state=>!state)} />
                            <label className="custom-control-label" htmlFor="new_leave_is_npl">{IMLocalized('no_pay')}
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="new_leave_is_proration" name='is_proration' className="custom-control-input" checked={is_proration} onChange={()=>setProration(state=>!state)} />
                            <label className="custom-control-label" htmlFor="new_leave_is_proration">{IMLocalized('proration')}?
                            </label>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {isLoading ? <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button>
                :<button onClick={onSubmit} className="btn btn-primary btn-sm">{IMLocalized('create')}</button>}
                <button className='btn btn-danger btn-sm' onClick={closeModal}>{IMLocalized('close')}</button>
            </ModalFooter>
        </Modal>
    )
}
export default ModalCreateLeaveType;
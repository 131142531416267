import React from "react";
import { Table } from "reactstrap";
import { IMLocalized } from "../../../../language/IMLocalized";


export default function LeaveRemainingTable({ list, detail}){

    const monthNames =[IMLocalized("JAN"), IMLocalized("FEB"), IMLocalized("MAR"), IMLocalized("APR"), IMLocalized("MAY"), IMLocalized("JUN"),
    IMLocalized("JUL"), IMLocalized("AUG"), IMLocalized("SEP"), IMLocalized("OCT"), IMLocalized("NOV"), IMLocalized("DEC")];

    if(list.leave_type === 'E'){

        return(
            <>
            <h3>{detail.name}</h3>
            <Table size="sm" className="bg-white align-items-center table-bordered" responsive>
                <thead className="thead-light text-center">
                    <tr>
                        <th>{IMLocalized('MONTH')}</th>
                        {list.remaining_monthly.map((item,index)=>{
                            const month_name = monthNames[index];
                            return <th key={index} >{month_name} {list.year}</th>
                        })}
                    </tr>
                </thead>
                <tbody className="text-center">
                    <tr>
                        <td>{IMLocalized('total')}</td>
                        {list.employeeearntable.month_total.map((item,index)=>{
                            return <td key={index}>{parseFloat(item).toFixed('2')}</td>
                        })}
                    </tr>
                    <tr>
                        <td>{IMLocalized('balance')}</td>
                        {list.remaining_monthly.map((item,index)=>{
                            return <td key={index}>{parseFloat(item).toFixed('2')}</td>
                        })}
                    </tr>
                </tbody>
            </Table>
            </>
        )
    }
    else{
        return(
            <>
            <h3>{detail.name}</h3>
            <Table size="sm" className="table-bordered bg-white align-items-center w-25">
                <tbody className="text-center">
                    <tr>
                        <td>{IMLocalized('balance')}</td>
                        <td>{list.remaining}</td>
                    </tr>
                    <tr>
                        <td>{IMLocalized('total')}</td>
                        <td>{list.total}</td>
                    </tr>
                </tbody>
            </Table>
            </>
        )
    }
}
import myaxios from '../axios';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const UPDATELEAVEOPTION_REQUEST = 'UPDATELEAVEOPTION_REQUEST';
export const UPDATELEAVEOPTION_SUCCESS = 'UPDATELEAVEOPTION_SUCCESS';
export const UPDATELEAVEOPTION_FAILURE = 'UPDATELEAVEOPTION_FAILURE';
export const UPDATELEAVEOPTION_RESET = 'UPDATELEAVEOPTION_RESET';

export const update_leave_option = (proration_option,cutoff_day,after_cutoff_leave,probation_leave,calendar_view_list,allow_earned_leave_negative,maximum_months_application,annual_before_marked_as_urgent,annual_leave,leave_without_approval_pending,medical_leave,absent_leave,nsmup_leave,no_pay_leave)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATELEAVEOPTION_REQUEST'
        });
        
        myaxios.post('leave/basesetting/',{
            proration_option:proration_option,
            cutoff_day:cutoff_day,
            after_cutoff_leave:after_cutoff_leave,
            probation_leave:probation_leave,
            calendar_view_list:calendar_view_list,
            allow_earned_leave_negative:allow_earned_leave_negative,
            maximum_months_application:maximum_months_application,
            annual_before_marked_as_urgent:annual_before_marked_as_urgent,
            annual_leave:annual_leave,
            leave_without_approval_pending:leave_without_approval_pending,
            medical_leave:medical_leave,
            absent_leave:absent_leave,
            nsmup_leave:nsmup_leave,
            no_pay_leave:no_pay_leave,
        })
        .then((response)=>{
            dispatch({
                type:'UPDATELEAVEOPTION_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATELEAVEOPTION_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}

export const reset_update_leave_option = () => dispatch =>{
    dispatch({
        type:'UPDATELEAVEOPTION_RESET'
    });
}
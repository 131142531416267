import myaxios from '../../axios';

export const GETIRASDETAIL_REQUEST = 'GETIRASDETAIL_REQUEST';
export const GETIRASDETAIL_SUCCESS = 'GETIRASDETAIL_SUCCESS';
export const GETIRASDETAIL_FAILURE = 'GETIRASDETAIL_FAILURE';
export const GETIRASDETAIL_RESET = 'GETIRASDETAIL_RESET';

export const get_iras_detail = (id)=>{
    return dispatch=>{
        dispatch({
            type:'GETIRASDETAIL_REQUEST'
        });

        myaxios.get(`sg/iras/${id}/`)
        .then((response)=>{
            dispatch({
                type:'GETIRASDETAIL_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETIRASDETAIL_FAILURE',
                payload:error
            })
        })

        // dispatch({
        //     type:'GETIRASDETAIL_REQUEST'
        // });

        // myaxios.get(`payroll/iras/${id}/`)
        // .then((response)=>{
        //     dispatch({
        //         type:'GETIRASDETAIL_SUCCESS',
        //         payload:response
        //     })
        // })
        // .catch((error)=>{
        //     dispatch({
        //         type:'GETIRASDETAIL_FAILURE',
        //         payload:error
        //     })
        // })
    }
}

export const reset_get_iras_detail = () => dispatch =>{
    dispatch({
        type:'GETIRASDETAIL_RESET'
    });
}
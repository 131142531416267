import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const DELCLAIM_REQUEST = 'DELCLAIM_REQUEST';
export const DELCLAIM_SUCCESS = 'DELCLAIM_SUCCESS';
export const DELCLAIM_FAILURE = 'DELCLAIM_FAILURE';
export const DELCLAIM_RESET = 'DELCLAIM_RESET';

export const delete_claim = (id)=>{
    return dispatch=>{
        dispatch({
            type:'DELCLAIM_REQUEST'
        });

        myaxios.delete(`claim/type/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELCLAIM_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('delete_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'DELCLAIM_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('delete_failure')
            });
        })
    }
}

export const reset_delete_claim = () => dispatch =>{
    dispatch({
        type:'DELCLAIM_RESET'
    });
}
import * as detail from '../../action/user/get_user_leave_detail';

const initialState={
    isLoading:false,
    data:null,
    errors:null
}

const get_user_leave_detail = (state=initialState,action)=>{
    switch(action.type){
        case detail.GETUSEREMPLEAVEDETAIL_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case detail.GETUSEREMPLEAVEDETAIL_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload?.data
            }
        case detail.GETUSEREMPLEAVEDETAIL_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        case detail.GETUSEREMPLEAVEDETAIL_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default get_user_leave_detail;
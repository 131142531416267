import myaxios from '../../axios';

export const GETPAYSUMMARYDETAIL_REQUEST = 'GETPAYSUMMARYDETAIL_REQUEST';
export const GETPAYSUMMARYDETAIL_SUCCESS = 'GETPAYSUMMARYDETAIL_SUCCESS';
export const GETPAYSUMMARYDETAIL_FAILURE = 'GETPAYSUMMARYDETAIL_FAILURE';

export const get_pay_summary_detail = (id)=>{
    return dispatch=>{
        dispatch({
            type:'GETPAYSUMMARYDETAIL_REQUEST'
        });

        myaxios.get(`claim/pay/${id}/`)
        .then((response)=>{
            dispatch({
                type:'GETPAYSUMMARYDETAIL_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETPAYSUMMARYDETAIL_FAILURE',
                payload:error
            })
        })
    }
}
import myaxios from '../../axios';

export const GETIRASLIST_REQUEST = 'GETIRASLIST_REQUEST';
export const GETIRASLIST_SUCCESS = 'GETIRASLIST_SUCCESS';
export const GETIRASLIST_FAILURE = 'GETIRASLIST_FAILURE';


export const get_iras_list = ()=>{
    return dispatch=>{
        dispatch({
            type:'GETIRASLIST_REQUEST'
        });

        myaxios.get('sg/iras/')
        .then((response)=>{
            dispatch({
                type:'GETIRASLIST_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'GETIRASLIST_FAILURE',
                payload:error
            })
        })
    }
}
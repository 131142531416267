import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import { IMLocalized } from "../../../../language/IMLocalized";
import {get_payroll_instance} from '../../../../action/payroll/get_payroll_instance';
import { connect } from 'react-redux';

const mapStateToProps=(state)=>({
    list:state.get_payroll_instance.data,
    payrolllist:state.get_payroll_list.data,

    isLoading:state.get_payroll_instance.isLoading,

})

const mapDispatchToProps=(dispatch)=>({
    get_payroll_instance:(id)=>{
        dispatch(get_payroll_instance(id))
    }
})


function PayrollTable({ toDetail }){

    const payroll_list = useSelector(state=>state.get_employee_payroll.data);
    
    const monthNames = [IMLocalized('january'), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
    IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")]
    
    return(
        <Table size="sm" className="mt-2 align-items-center table-bordered" responsive>
            <thead className='thead-light'>
                <tr>
                    <th>{IMLocalized('payroll_list')}</th>
                </tr>
            </thead>
            <tbody>
                {payroll_list.map((item)=>{
                    const date = item.payroll_date;
                    const month = new Date(date).getMonth();
                    const month1 = monthNames[month];
                    const year = new Date(date).getFullYear();
                    return(
                        <tr key={item.id}>
                            <td><a href='/#' onClick={()=>toDetail(item.payroll.id)}>{(item.payroll.type==='BONUS')?'Bonus ':''}{month1} {year}</a></td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}

export default connect(mapStateToProps,mapDispatchToProps)(PayrollTable);
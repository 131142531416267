import React,{ useContext } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { NewMainContext } from '../../../context/NewMainContext';
import ManagerDashboard from '../../dashboard/manager_dashboard';
import UserDashboard from '../../dashboard/UserDashboard';
import UserProfile from '../../employee/user_employee/EmployeeProfile';
import UserClaimList from '../../employee/user_employee/UserClaimList';
import UserLeaveTable from '../../employee/user_employee/UserLeaveTable';
import UserCalendar from '../../employee/user_employee/UserLeaveCalendar';
import UserClaimApproval from '../../claim/ClaimApprovalList';
import ManagerCalendar from '../../employee/manager_employee/manager_calendar';
import ManagerAppraisal from '../../employee/manager_employee/manager_appraisal';
import LeaveCalendar from '../../leave/LeaveCalendar';
import LeaveTable from '../../leave/LeaveTable';

export default function UserTabContent(){

    const { activeTab, action_toggle, current_user } = useContext(NewMainContext);

    return(
        <TabContent activeTab={activeTab} className={action_toggle === true ? 'body-tabpane scrollbarstyle1' : 'body-tabpane1 scrollbarstyle1'}>
            {current_user === 'manager' &&
            <TabPane tabId="1">
                <ManagerDashboard/>
            </TabPane>}
            {current_user === 'user' &&
            <TabPane tabId="1">
                <UserDashboard/>
            </TabPane>}
            <TabPane tabId="2">
                <UserProfile/>
            </TabPane>
            <TabPane tabId="3">
                <UserClaimList/>
            </TabPane>
            {current_user === 'manager' &&
            <TabPane tabId="4">
                <LeaveTable/>
            </TabPane>}
            <TabPane tabId="4">
                <UserLeaveTable/>
            </TabPane>
            <TabPane tabId="5">
                <UserCalendar/>
            </TabPane>
            {current_user === 'manager' &&
            <>
            <TabPane tabId="7">
                <UserClaimApproval/>
            </TabPane>
            <TabPane tabId="9">
                <ManagerCalendar/>
            </TabPane>
            <TabPane tabId="10">
                <ManagerAppraisal/>
            </TabPane>
            <TabPane tabId="24">
                <LeaveCalendar/>
            </TabPane>
            </>}
            {/* {current_user === 'leave_officer' &&
            <>
            <TabPane tabId="24">
                <LeaveCalendar/>
            </TabPane>
            </>}
             */}
        </TabContent>
    )
}
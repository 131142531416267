import * as createclaimscheme from '../../action/claim/create_claim_scheme';

const initialState={
    isLoading:false,
    errors:null,
    data:null
}

const create_claim_scheme = (state=initialState,action)=>{
    switch(action.type){
        case createclaimscheme.CREATECLAIMSCHEME_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:null,
                data:null,
            }
        case createclaimscheme.CREATECLAIMSCHEME_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case createclaimscheme.CREATECLAIMSCHEME_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload?.response?.data
            }
        case createclaimscheme.CREATECLAIMSCHEME_RESET:
            return{
                ...state,
                data:null,
                errors:null
            }
        default:
            return state;
    }
}
export default create_claim_scheme;
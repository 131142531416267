import * as otplist from '../../action/user/get_otp_list';

const initialState={
    isLoading:false,
    data:[],
    errors:[]
}


const get_otp_list = (state=initialState,action)=>{
    switch(action.type){
        case otplist.GETOTPLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
            }
        case otplist.GETOTPLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case otplist.GETOTPLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_otp_list;
import myaxios from '../../axios';

export const EMPUNICHECK_REQUEST = 'EMPUNICHECK_REQUEST';
export const EMPUNICHECK_SUCCESS = 'EMPUNICHECK_SUCCESS';
export const EMPUNICHECK_FAILURE = 'EMPUNICHECK_FAILURE';

export const create_employee_unique_check=(data)=>{
    return dispatch=>{
        dispatch({
            type:'EMPUNICHECK_REQUEST'
        });

        myaxios.post('employee/employees/unique_validate/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'EMPUNICHECK_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'EMPUNICHECK_FAILURE',
                payload:error
            })
        })
    }
}
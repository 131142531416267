import { IMLocalized } from "../../../../language/IMLocalized";
import ExcelJs from 'exceljs';
import { getColumnName } from "../../../../func/getColumnName";
import { getDateFormat } from "../../../../func/getDateFormat";
import FileSaver from 'file-saver';
import moment from "moment";
import { getValue } from "../../../../func/getValue";
import { connect } from "react-redux";
import { popError } from "../../../../func/popError";

const mapStateToProps=(state)=>({
    claimlist:state.get_claim_list.data,
    claim_status:state.dropdown_list.claim_status,
})

const mapDispatchToProps=(dispatch)=>({

})

function ButtonClaimReport(props){

    const generateExcel=()=>{
        let arr = props.rowData;
        let string = '';
        if(props.selected === '4'){
            string = 'All';
        }
        else if(props.selected === '0'){
            string = 'Pending';
            arr = props.rowData.filter(item=>item.status === 'PENDING');
        }
        else if(props.selected === '1'){
            string = 'Approved';
            arr = props.rowData.filter(item=>item.status === 'APPROVED');
        }
        else if(props.selected === '2'){
            string = 'Rejected';
            arr = props.rowData.filter(item=>item.status === 'REJECTED');
        }
        else if(props.selected === '3'){
            string = 'Cancelled';
            arr = props.rowData.filter(item=>item.status === 'CANCELLED');
        }
        else if(props.selected === '5'){
            string = 'Paid';
            arr = props.rowData.filter(item=>item.status === 'PAID');
        }
        else if(props.selected === '6'){
            string = 'Payroll Paid';
            arr = props.rowData.filter(item=>item.status === 'PAYROLL');
        }

        const wb = new ExcelJs.Workbook();
        const ws = wb.addWorksheet('employee_claim_list');
        const today = moment(new Date()).format('DD/MM/YYYY');
        const time = moment().format('HH:mm:ss');
        const day =moment(new Date()).format("DD");
        const month =moment(new Date()).format("MM");

        ws.getCell('A1').value = 'Employee Leave List - ' + string;
        ws.getCell('A2').value = 'Printed On : '+today+' '+time;
        ws.getCell('A3').value = 'Emplyee ID : ' + props.emp_number;
        ws.getCell('A4').value = 'Employee Name : '+ props.emp_name;
        const title_name = 'EmployeeClaimReport_'+day+'-'+month;

        const column =[
            {header:'Date',key:'claim_date'},
            {header:'Emp ID',key:'employee_detail.employee_number'},
            {header:'Name',key:'employee_detail.name'},
            {header:'Claim Type',key:'claim_type'},
            {header:'Amount',key:'claim_amount'},
            {header:'Status',key:'status'}
        ]

        ws.columns =[
            {width:20},
            {width:20},
            {width:20},
            {width:20},
            {width:20},
            {width:20}
        ]

        if(arr.length !== 0){
            for(let i=0;i<column.length;i++){
                const headerName = column[i].header;
                const field = (column[i].key);
                const length = arr.length;
                ws.getCell(getColumnName(i)+'6').value = headerName;
                for(let x=0;x<length;x++){
                    const step = 7+x;
                    const data = getValue(arr[x],field);
                    if(field === 'claim_date'){
                        ws.getCell(getColumnName(i)+step).value = getDateFormat(data);
                    }
                    else if(field === 'claim_type'){
                        const detail =  props.claimlist.find((item)=>item.id === data);
                        ws.getCell(getColumnName(i)+step).value = detail ? detail.name : '-'
                    }
                    else if(field === 'status'){
                        const detail  = props.claim_status.find((item)=>item.value === data);
                        ws.getCell(getColumnName(i)+step).value = detail? detail.display : '-'
                    }
                    else if(field === 'claim_amount'){
                        ws.getCell(getColumnName(i)+step).value = `$ ${data}`
                    }
                    else{
                        ws.getCell(getColumnName(i)+step).value = data;
                    }
                }
            }
    
            const filename = title_name+'.xlsx';
            wb.xlsx.writeBuffer().then((buf)=>{
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                FileSaver.saveAs(file, filename)
            });
        }
        else{
            popError(IMLocalized('no_data'));
        }
    }

    return(
        <button className="btn btn-success btn-sm" onClick={()=>generateExcel()}>{IMLocalized('export')}</button>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(ButtonClaimReport);
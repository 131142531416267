import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const UNDOPAYROLL_REQUEST = 'UNDOPAYROLL_REQUEST';
export const UNDOPAYROLL_SUCCESS = 'UNDOPAYROLL_SUCCESS';
export const UNDOPAYROLL_FAILURE = 'UNDOPAYROLL_FAILURE';


export const undo_payroll =(id)=>{
    return dispatch=>{
        dispatch({
            type:'UNDOPAYROLL_REQUEST'
        });

        myaxios.post(`payroll/payroll/${id}/Undo/`)
        .then((response)=>{
            dispatch({
                type:'UNDOPAYROLL_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('undo_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UNDOPAYROLL_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('undo_failure')
            });
        })
    }
}
import * as monthlydatefilter2 from '../../action/report/monthly_report_date_filter2';


const initialState={
    isLoading:false,
    data:[],
    errors:[],
    hello:[]
}

const monthly_report_date_filter2 = (state=initialState,action)=>{
    switch(action.type){
        case monthlydatefilter2.MONTHLYREPORTDATEFILTER2_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:[]
            }
        case monthlydatefilter2.MONTHLYREPORTDATEFILTER2_SUCCESS:
            return{
                ...state,
                data:action.payload.data,
                isLoading:false,
                hello:action.payload
            }
        case monthlydatefilter2.MONTHLYREPORTDATEFILTER2_FAILURE:
            return{
                ...state,
                errors:action.payload,
                isLoading:false
            }
        case monthlydatefilter2.MONTHLYREPORTDATEFILTER2_RESET:
            return{
                ...state,
                errors:null,
                data:[],
            }
        default:
            return state;
    }
}
export default monthly_report_date_filter2;
import myaxios from '../../axios';

export const DELEMPCERTPIC_REQUEST = 'DELEMPCERTPIC_REQUEST';
export const DELEMPCERTPIC_SUCCESS = 'DELEMPCERTPIC_SUCCESS';
export const DELEMPCERTPIC_FAILURE = 'DELEMPCERTPIC_FAILURE';


export const delete_employee_certification_picture = (id) =>{
    return dispatch=>{
        dispatch({
            type:'DELEMPCERTPIC_REQUEST'
        });

        myaxios.delete(`ob/cert/${id}/`)
        .then((response)=>{
            dispatch({
                type:'DELEMPCERTPIC_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'DELEMPCERTPIC_FAILURE',
                payload:error
            })
        })
    }
}
import * as user_available_claim from '../../action/user/user_get_available_claim';

const initialState={
    isLoading:false,
    errors:[],
    data:[]
}

const user_get_available_claim= (state=initialState,action)=>{
    switch(action.type){
        case user_available_claim.USERAVAILABLECLAIM_REQUEST:
            return{
                ...state,
                isLoading:true,

            }
        case user_available_claim.USERAVAILABLECLAIM_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data
            }
        case user_available_claim.USERAVAILABLECLAIM_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default user_get_available_claim;
import React from 'react';
import { connect } from 'react-redux';
import {Table} from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';

const mapStateToProps=(state)=>({
    payroll_list:state.get_self_payroll.data,
    isLoading:state.get_self_payroll.isLoading,
})


function UserEmployeePayroll(props){

    const monthNames = [IMLocalized('january'), IMLocalized("february"), IMLocalized("march"), IMLocalized("april"), IMLocalized("may"), IMLocalized("june"),
    IMLocalized("july"), IMLocalized("august"), IMLocalized("september"), IMLocalized("october"), IMLocalized("november"), IMLocalized("december")]

    return(
        <>
            <div className='d-flex align-items-center mt-2 pl-2 emp-tabcontent-title' >
                <i className="far fa-money-bill-alt text-primary"></i>
                <h4 className="text-primary pl-2 m-0">{IMLocalized('employee_payroll')}</h4>
            </div>
            {props.isLoading && <div className="m-2"><h4>{IMLocalized('loading')}..</h4></div>}
            {!props.isLoading && props.payroll_list.length === 0 && <div className="m-2"><h4>{IMLocalized('no_data')}</h4></div>}
            {!props.isLoading && props.payroll_list.length !== 0 &&
            <Table size="sm" className="mt-2 align-items-center table-bordered" responsive>
                <thead className='thead-light'>
                    <tr>
                        <th>{IMLocalized('payroll_list')}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.payroll_list.map((item,index)=>{
                        const date = item.payroll_date;
                        const month = new Date(date).getMonth();
                        const month1 = monthNames[month];
                        const year = new Date(date).getFullYear();

                        return(
                            <tr key={index}>
                                <td><a href='/#' onClick={()=>props.toPayrollDetail(item.id)}>{month1} {year}</a></td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>}
        </>
    )
}
export default connect(mapStateToProps,null)(UserEmployeePayroll);
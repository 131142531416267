import * as increment_list from '../../action/payroll/get_payroll_increment_list';

const initialState={
    isLoading:false,
    data:[],
    errors:null
}

const get_payroll_increment_list = (state = initialState, action)=>{
    switch(action.type){
        case increment_list.GETPAYROLLINCREMENT_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:[],
                errors:null
            }
        case increment_list.GETPAYROLLINCREMENT_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
            }
        case increment_list.GETPAYROLLINCREMENT_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state
    }
}
export default get_payroll_increment_list;
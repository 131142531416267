import React,{Component} from 'react';
import {IMLocalized} from '../../language/IMLocalized';
import '../../css/loading2.css';

class overlay_loading extends Component{
    
    constructor(props){
        super(props);
        this.state={
            language:localStorage.getItem('language')
        }
    }

    componentDidMount(){

    }

    render(){
        return(
            <div className="overlay1">
                    <div className="timer-loader">
                    </div>
                    <br/>
                    <div >
                        {/* <span style={{color:'#0253cc'}}>{IMLocalized('loading')}...</span> */}
                        {/* <span style={{color:'#009387'}}>{IMLocalized('loading')}...</span> */}
                    </div>
            </div>
        )
    }
}
export default overlay_loading;
import React, { useContext } from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { IMLocalized } from '../../../../language/IMLocalized';
import { UserContext } from '../UserPayrollDetail';
import { connect } from 'react-redux';


const mapStateToProps=(state)=>({
    pay_component_type:state.dropdown_list.pay_component_type,
})

function SummaryTable(props){

    const user = useContext(UserContext);

    const summary_arr = [];
    const employer_arr = [];
  
    if(user?.emp_payslip != null){

        const gross_month_amount = user.payslipcontract.reduce((prev,current)=>prev+current.gross_month,0);
        summary_arr.push({name:IMLocalized('gross_month_total'),amount:`$${gross_month_amount.toFixed(2)}`})

        if(user.emp_payslip?.sdl != null){
            employer_arr.push({name:'SDL',amount:`$${(user.emp_payslip.sdl).toFixed(2)}`});
        }
    
        for(const overtime of user.overtimelist){
            const {amount,is_gross,name} = overtime;
            if(!is_gross && amount > 0){
                summary_arr.push({name,amount:`$${amount.toFixed(2)}`});
            }
        }
    
        for(const allowance of user.allowancelist){
            const {amount,name,is_gross} = allowance;
            if(!is_gross && amount > 0){
                summary_arr.push({name,amount:`$${amount.toFixed(2)}`});
            }
        }
    
        for(const recurring of user.recurringlist){
            const {amount,name,is_gross} = recurring;
            if(!is_gross && amount >0){
                summary_arr.push({name,amount:`$${amount.toFixed(2)}`});
            }
        }
    
        for(const flat of user.flatlist){
            const {is_gross,amount,code} = flat;
            if(!is_gross && amount > 0){
                summary_arr.push({name:code,amount:`$${amount.toFixed(2)}`});
            }
        }
    
        for(const backpay of user.backpaylist ){
            const {is_gross,deducatable,amount,pay_type} = backpay;
            if(!is_gross && !deducatable && amount > 0){
                const pay_detail = props.pay_component_type.find(el=>el.value === pay_type);
                const name = pay_detail?.display ?? '-';
                summary_arr.push({name,amount:`$${amount.toFixed(2)}`});
            }
        }
    
        for(const bonus of user.bonuslist){
            const {is_gross,pay_type,amount} = bonus;
            if(!is_gross && amount > 0){
                const pay_detail = props.pay_component_type.find(el=>el.value === pay_type);
                const name = pay_detail?.display ?? '-';
                summary_arr.push({name,amount:`$${amount.toFixed(2)}`});
            }
        }
    
        for(const nopay of user.nopaylist){
            summary_arr.push({name:nopay.code,amount:`($${nopay.amount.toFixed(2)})`});
        }
    
        for(const nation of user.nationlist){
            summary_arr.push({name:nation.code,amount:`($${nation.amount.toFixed(2)})`});
        }
    
        for(const absent of user.absentlist){
            const {amount, pay_type, is_gross} = absent;
            if(!is_gross && amount > 0 ){
                const pay_detail = props.pay_component_type.find(el=>el.value === pay_type);
                const name = pay_detail?.display ?? '-';
                summary_arr.push({name:`${name} (AWOL)`,amount:`($${absent.amount.toFixed(2)})`});
            }  
        }
    
        for(const deduct of user.deductablelist){
            const {is_gross,amount,deductable,pay_type} = deduct;
            if(!is_gross && deductable && amount > 0){
                const pay_detail = props.pay_component_type.find(el=>el.value === pay_type);
                const name = pay_detail?.display ?? '-';
    
                summary_arr.push({name,amount:`($${amount.toFixed(2)})`});
            }
        }
        
        summary_arr.push(
            {name:IMLocalized('payslip_ordinary_wage'),amount:`$${user.emp_payslip.ordinary_wage.toFixed(2)}`},
            {name:IMLocalized('payslip_additional_wage'),amount:`$${user.emp_payslip.additional_wage.toFixed(2)}`},
            {name:IMLocalized('payslip_deductables'),amount:`($${user.emp_payslip.deductables.toFixed(2)})`},
            {name:IMLocalized('payslip_total_wage'),amount:`$${user.emp_payslip.total_wage.toFixed(2)}`},
        );
    
        if(user.sgcpf !== null){
            summary_arr.push({name:'CPF' ,amount:`($${user.sgcpf.cpf_employee.toFixed(2)})`});
            employer_arr.push({name:IMLocalized('payslip_employer_total'), amount:`$${user.sgcpf.cpf_employer.toFixed(2)}`});
        }
    
        for(const shg_item of user.sgshg){
            summary_arr.push({name:shg_item.shg,amount:`($${shg_item.amount.toFixed(2)})`});
        }
    
        for(const claim of user.claimlist){
            summary_arr.push({name:IMLocalized('total_claim'), amount:`$${claim.amount.toFixed(2)}`});
        }
      
        summary_arr.push({name:IMLocalized('payslip_payable'),amount:`$${user.emp_payslip.payable_wage.toFixed(2)}`});
    }
    
    return(
        <div className='mb-2' style={{position:'sticky',top:'10px'}}>
            <h2 className='title-color1'>{IMLocalized('summary')}</h2>
            <Card>
                <CardHeader className="p-2 border-0 bg-white">
                    <h4 className='text-primary pl-2 pt-2'>{IMLocalized('summary')}</h4>
                </CardHeader>
                <Table className="align-items-center" size="sm">
                    <thead className="p-2 thead-light">
                        <tr>
                            <th>{IMLocalized('name')}</th>
                            <th width="40%">{IMLocalized('amount')}</th>
                        </tr>
                    </thead>
                    <tbody className="p-2">
                        {summary_arr.map((item,index)=>{
                            return(
                                <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>{item.amount}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Card>
            <Card>
                <CardHeader className="p-2 border-0 bg-white">
                    <h4 className='text-primary pl-2 pt-2'>{IMLocalized('payslip_employer_summary')}</h4>
                </CardHeader>
                {employer_arr.length === 0 &&
                <CardBody className="d-flex justify-content-center align-items-center"><span className="h4  mb-0">{IMLocalized('no_data')}</span></CardBody>
                }
                {employer_arr.length !== 0 &&
                <Table size="sm" className="align-items-center" responsive>
                    <tbody>
                        {employer_arr.map((item,index)=>{
                            return(
                                <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>{item.amount}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>}
            </Card>
        </div>
    )
}
export default connect(mapStateToProps,null)(SummaryTable);
import myaxios from '../axios';
import FormData from 'form-data';
import { IMLocalized } from '../language/IMLocalized';
import { ErrToast, Toast } from '../swal/SwalToast';

export const UPDATEGENLOGO_REQUEST = 'UPDATEGENLOGO_REQUEST';
export const UPDATEGENLOGO_SUCCESS = 'UPDATEGENLOGO_SUCCESS';
export const UPDATEGENLOGO_FAILURE = 'UPDATEGENLOGO_FAILURE';
export const UPDATEGENLOGO_RESET = 'UPDATEGENLOGO_RESET';

export const update_general_logo = (file)=>{
    return dispatch=>{
        dispatch({
            type:'UPDATEGENLOGO_REQUEST'
        });

        const formData = new FormData();
        formData.append('logo',file,file.filename);

        myaxios.post('settings/',formData,{headers: {'Content-Type': 'multipart/form-data'}})
        .then((response)=>{
            dispatch({
                type:'UPDATEGENLOGO_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('update_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'UPDATEGENLOGO_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('update_failure')
            });
        })
    }
}

export const reset_update_general_logo = () => dispatch =>{
    dispatch({
        type:'UPDATEGENLOGO_RESET'
    });
}
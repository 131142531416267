import myaxios from '../../axios';

export const COMPANYVALIDATE_REQUEST = 'COMPANYVALIDATE_REQUEST';
export const COMPANYVALIDATE_SUCCESS = 'COMPANYVALIDATE_SUCCESS';
export const COMPANYVALIDATE_FAILURE = 'COMPANYVALIDATE_FAILURE';

export const company_validate = (name,contact_number,UEN,CPF_payment_type,CPF_serial_number,unit_number,sg_postal_code,address)=>{
    return dispatch=>{
        dispatch({
            type:'COMPANYVALIDATE_REQUEST'
        });

        myaxios.post('company/company/validate/',{
            name:name,
            address:address,
            unit_number:unit_number,
            sg_postal_code:sg_postal_code,
            UEN:UEN,
            CPF_payment_type:CPF_payment_type,
            CPF_serial_number:CPF_serial_number,
            contact_number:contact_number,
        })
        .then((response)=>{
            dispatch({
                type:'COMPANYVALIDATE_SUCCESS',
                payload:response
            })
        })
        .catch((error)=>{
            dispatch({
                type:'COMPANYVALIDATE_FAILURE',
                payload:error
            })
        })
    }
}
import React,{useEffect, useState} from 'react';
import {connect} from 'react-redux';
import FileSaver from 'file-saver';
import ExcelJs from 'exceljs';
import moment from 'moment';
import { Row, Col,DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {get_apply_claim_list2} from '../../action/claim/get_apply_claim_list2';
import {DateRangePicker} from 'react-dates';
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {IMLocalized} from '../../language/IMLocalized';
import { getDateFormat } from '../../func/getDateFormat';
import { getColumnName } from '../../func/getColumnName';
import { getValue } from '../../func/getValue';
import { popError } from '../../func/popError';

import { get_employee_list} from '../../action/employee/get_employee_list';
import { employee_range } from '../../action/employee/employee_range';
import { get_employee_permit} from '../../action/employee/get_employee_permit';

const mapStateToProps=(state)=>({
    period:state.get_payroll_period.data,
    applylist:state.get_apply_claim_list2.data,
    claimlist:state.get_claim_list.data,
    employeelist:state.get_employee_list.data,
    rangeemployee:state.employee_range.data,
    permitlist:state.get_employee_permit.data,

    isLoading:state.get_payroll_period.isLoading,
    isLoading1:state.get_apply_claim_list2.isLoading,
    isLoading2:state.get_claim_list.isLoading,
    isLoading3:state.get_employee_list.isLoading,
    isLoading4:state.employee_range.isLoading,
    isLoading5:state.get_employee_permit.isLoading
})

const mapDispatchToProps=(dispatch)=>({
    get_apply_claim_list2:()=>{
        dispatch(get_apply_claim_list2())
    },
    get_employee_list:()=>{
        dispatch(get_employee_list())
    },
    employee_range:(from_date1, to_date1)=>{
        dispatch(employee_range(from_date1, to_date1))
    },
    get_employee_permit:()=>{
        dispatch(get_employee_permit())
    }

})

const initialState={
    list:[],
    startDate:null,
    endDate:null,
    start_date:null,
    end_date:null,
    dateFormat:'DD/MM/YYYY',
    focusedInput:null,
    toggle:false,
    // departmentlist:[]
    resignationlist:[],
    joinedlist:[]
}

function MonthlyHeadCountReport(props){
    
    const [state,setState ] = useState(initialState);

    useEffect(()=>{
        if(props.period != null){
            const {end_day,pay_period} = props.period;
            const today = new Date();
            let start_date = null;
            let startDate = null;
            let end_date = null;
            let endDate = null;
            if(pay_period === 'CUSTOM'){
                const item_year = today.getFullYear();
                const item_month = today.getMonth()+1;
                let end_item ='';
                if(end_day >=10){
                    end_item = end_day;
                }
                else{
                    end_item = '0'+end_day;
                }
                const item = item_year+'-'+item_month+'-'+end_item;
                const item_date = new Date(item);
                if(today > item_date){
                    startDate = moment(item_date,'DD-MM-YYYY').add(1, 'days');
                    start_date = startDate.format('YYYY-MM-DD');
                    endDate = moment(item_date).add(1, 'M');
                    end_date = endDate.format('YYYY-MM-DD');
                }
                else{
                    endDate = moment(item_date);
                    end_date = endDate.format('YYYY-MM-DD');
                    const date2 = moment(endDate).subtract(1,'month');
                    startDate = moment(date2,"DD-MM-YYYY").add(1,'days');
                    start_date = startDate.format('YYYY-MM-DD');
                }
            }
            else{
                start_date = moment().startOf('month').format('YYYY-MM-DD');
                end_date   = moment().endOf('month').format('YYYY-MM-DD');
                startDate = moment(start_date);
                endDate = moment(end_date);
            }

            setState(prevState=>({...prevState,start_date,startDate,end_date,endDate}));
        }
    },[props.period,state.toggle])

    useEffect(()=>{

        //RESIGNED
        let arr = [];
        let detail = props.employeelist.filter(item=>item.status !== null);
        if(state.start_date !== null ){
            detail = detail.filter(item=>new Date(item.resignation_date) >= new Date(state.start_date));
        }
        if(state.end_date !== null){
            detail = detail.filter(item=>new Date(item.resignation_date) <= new Date(state.end_date));
        }
        detail.forEach(item=>{
            // const resignation = item.resignation_date;
            const resignation = item
            if(resignation != null){
                if(!arr.includes(resignation)){
                    arr.push(resignation);
                };
            }
        })

        //JOINED
        let arr1 = [];
        let detail1 = props.employeelist.filter(item=>item.status !== null);
        if(state.start_date !== null ){
            detail1 = detail1.filter(item=>new Date(item.join_date) >= new Date(state.start_date));
        }
        if(state.end_date !== null){
            detail1 = detail1.filter(item=>new Date(item.join_date) <= new Date(state.end_date));
        }
        detail1.forEach(item=>{
            const join = item
            if(join != null){
                if(!arr1.includes(join)){
                    arr1.push(join);
                };
            }
        })

        //ACTIVE
        let arr2 = [];
        let detail2 = props.employeelist.filter(item=>item.status == null);
        if(state.start_date !== null ){
            detail2 = detail2.filter(item=>new Date(item.join_date) >= new Date(state.start_date));
        }
        if(state.end_date !== null){
            detail2 = detail2.filter(item=>new Date(item.join_date) <= new Date(state.end_date));
        }
        detail2.forEach(item=>{
            const active = item
            if(active != null){
                if(!arr2.includes(active)){
                    arr2.push(active);
                };
            }
        })

        // setState(prevState=>({...prevState,departmentlist:arr,list:detail}));
        setState(prevState=>({...prevState,resignationlist:arr,list:detail,joinedlist:arr1,list1:detail1}));
    },[props.employeelist,state.end_date,state.start_date])

    const getData=()=>{
        if(!state.toggle){
            props.get_employee_list();
        }

        setState(prevState=>({...prevState,toggle:!prevState.toggle}))
    }

    
    const generateRange = ()=>{
        // {state.start_date,state.end_date}

        let start_date1 = state.start_date
        let end_date1 = state.end_date

        props.employee_range(start_date1,end_date1);
        if(props.isLoading4===false && props.rangeemployee?.data?.employees?.length !== 0)
        {generateExcel();}
        
    }

    const generateExcel = ()=>{

        const { employees } = props?.rangeemployee?.data
        let activeemplist = employees

        const wb = new ExcelJs.Workbook();
        const day = moment().format('DD');
        const month = moment().format('MM');
        if(state.resignationlist.length !== 0 || state.joinedlist.length !== 0){
            const ws = wb.addWorksheet('Resigned');
            const ws1 = wb.addWorksheet('Joined');
            const ws2 = wb.addWorksheet('Active');


            //RESIGNED
            for(let i=0;i<state.resignationlist.length ;i++){
                const resignation = state.resignationlist[i];
                
                const today = moment().format('DD/MM/YYYY');
                const time = moment().format('HH:mm:ss');
                ws.getCell('A1').value = 'Monthly Headcount Report';
                ws.getCell('A2').value = `Start Date : ${getDateFormat(state.start_date)}          End Date : ${getDateFormat(state.end_date)}     Printed On : ${today} ${time}`;
                ws.columns=[
                    {width: 15},
                    {width: 20}, 
                    {width: 15}
                ]

                ws.getCell('A4').value = 'Resigned HC';
                ws.getCell('B4').value = 'Joined HC';
                ws.getCell('C4').value = 'Active HC';

                ws.getCell('A5').value = `${state.resignationlist.length}`;
                ws.getCell('B5').value = `${state.joinedlist.length}`;
                ws.getCell('C5').value = `${activeemplist.length}`;

                ws.getCell('A7').value = 'Emp ID';
                ws.getCell('B7').value = 'Emp Name';
                ws.getCell('C7').value = 'Resignation Date';
                let count =6;
                // const detail = state.list.filter(item=>item.resignation_date === resignation);
                // console.log(detail)
                const detail = state.list
                let emplist= [];
                for(let j=0;j<detail.length;j++){
                    const id = detail[j].id;
                    // if(emplist.includes(name)=== false){
                    //     emplist.push(name);
                    // }
                    emplist.push(id);
                }

                // console.log(props.permitlist)
                if(emplist.length !== 0){
                    for(let x=0;x<emplist.length;x++){
                        const id = emplist[x];
                        const emp_detail = detail.filter(item=>item.id === id);
                        // const passtype = permitlist.filter(item=>item.id === id).map(item1=>item1.permit_type)
                        if(emp_detail.length !== 0){
                            for(let o=0;o<emp_detail.length;o++){
                                if(o === 0){
                                    const count1= count +2;
                                    const employe_number = emp_detail[o].employee_number;
                                    const name = emp_detail[o].name;
                                    const resignation_date = emp_detail[o].resignation_date;
                                    const nationality = emp_detail[o].nationality;

                                    const passtype = props.permitlist?.filter(item=>item.employee === emp_detail[o].id).map(item1=>item1.permit_type)
                                    // console.log(passtype)
                                    // ws.getCell('A'+count1).value={richText:[
                                    //     {
                                    //         font:{
                                    //             bold:true
                                    //         },
                                    //         text:'Emp ID : ' +employe_number
                                    //     }
                                    // ]};
                                    ws.getCell('A'+count1).value = employe_number;
                                    ws.getCell('B'+count1).value = name;
                                    ws.getCell('C'+count1).value = resignation_date;
                                    ws.getCell('D'+count1).value = nationality;
                                    if(passtype.length === 0){
                                        ws.getCell('E'+count1).value = "-";
                                        }else{
                                        ws.getCell('E'+count1).value = passtype[0];
                                        }
                                    // const count2 = count1 +1;
                                    // for(let z=0;z<column.length;z++){
                                    //     const headerName = column[z].header;
                                    //     ws.getCell(getColumnName(z)+count2).value = headerName;
                                    // }
                                    // count = count2+1;
                                }
                                // for(let z=0;z<column.length;z++){
                                //     const field = (column[z].key);
                                //     const item = getValue(emp_detail[o],field);
                                //     if(field === 'claim_date'){
                                //         ws.getCell(getColumnName(z)+count).value = getDateFormat(item);
                                //     }
                                //     else if(field === 'claim_amount'){
                                //         ws.getCell(getColumnName(z)+count).value = '$'+item;
                                //     }
                                //     else{
                                //         ws.getCell(getColumnName(z)+count).value = item;
                                //     }
                                // }
                                count++;
                            }
                            // ['A'+count, 'B'+count, 'C'+count, 'D'+count, 'E'+count, 'F'+count, 'G'+count].forEach(key => {
                            //     ws.getCell(key).border = {bottom: {style:'thick'}}
                            // });
                        }
                    }
                }
            }


            //JOINED
            for(let i=0;i<state.joinedlist.length ;i++){
                const join = state.joinedlist[i];
                
                const today = moment().format('DD/MM/YYYY');
                const time = moment().format('HH:mm:ss');
                ws1.getCell('A1').value = 'Monthly Headcount Report';
                ws1.getCell('A2').value = `Start Date : ${getDateFormat(state.start_date)}          End Date : ${getDateFormat(state.end_date)}     Printed On : ${today} ${time}`;
                ws1.columns=[
                    {width: 15},
                    {width: 20}, 
                    {width: 15}
                ]

                ws1.getCell('A4').value = 'Resigned HC';
                ws1.getCell('B4').value = 'Joined HC';
                ws1.getCell('C4').value = 'Active HC';

                ws1.getCell('A5').value = `${state.resignationlist.length}`;
                ws1.getCell('B5').value = `${state.joinedlist.length}`;
                ws1.getCell('C5').value = `${activeemplist.length}`;

                ws1.getCell('A7').value = 'Emp ID';
                ws1.getCell('B7').value = 'Emp Name';
                ws1.getCell('C7').value = 'Joined Date';
                let count =6;
                const detail1 = state.list1
                let emplist= [];
                for(let j=0;j<detail1.length;j++){
                    const id = detail1[j].id;
                    emplist.push(id);
                    // console.log(emplist)
                }

                if(emplist.length !== 0){
                    for(let x=0;x<emplist.length;x++){
                        const id = emplist[x];
                        const emp_detail = detail1.filter(item=>item.id === id);
                        if(emp_detail.length !== 0){
                            for(let o=0;o<emp_detail.length;o++){
                                if(o === 0){
                                    const count1= count +2;
                                    const employe_number = emp_detail[o].employee_number;
                                    const name = emp_detail[o].name;
                                    const join_date = emp_detail[o].join_date;
                                    const nationality = emp_detail[o].nationality;
                                    const passtype = props.permitlist?.filter(item=>item.employee === emp_detail[o].id).map(item1=>item1.permit_type)

                                    ws1.getCell('A'+count1).value=employe_number;
                                    ws1.getCell('B'+count1).value = name;
                                    ws1.getCell('C'+count1).value = join_date;
                                    ws1.getCell('D'+count1).value = nationality;
                                    if(passtype.length === 0){
                                        ws1.getCell('E'+count1).value = "-";
                                        }else{
                                        ws1.getCell('E'+count1).value = passtype[0];
                                        }
                                }

                                count++;
                            }

                        }
                    }
                }
            }


            //ACTIVE
            for(let i=0;i<activeemplist.length ;i++){
                const active = activeemplist[i];
                
                const today = moment().format('DD/MM/YYYY');
                const time = moment().format('HH:mm:ss');
                ws2.getCell('A1').value = 'Monthly Headcount Report';
                ws2.getCell('A2').value = `Start Date : ${getDateFormat(state.start_date)}          End Date : ${getDateFormat(state.end_date)}     Printed On : ${today} ${time}`;
                ws2.columns=[
                    {width: 15},
                    {width: 20}, 
                    {width: 15}
                ]

                ws2.getCell('A4').value = 'Resigned HC';
                ws2.getCell('B4').value = 'Joined HC';
                ws2.getCell('C4').value = 'Active HC';

                ws2.getCell('A5').value = `${state.resignationlist.length}`;
                ws2.getCell('B5').value = `${state.joinedlist.length}`;
                ws2.getCell('C5').value = `${activeemplist.length}`;



                ws2.getCell('A7').value = 'Emp ID';
                ws2.getCell('B7').value = 'Emp Name';
                ws2.getCell('C7').value = 'Joined Date';
                let count =6;
                const detail2 = activeemplist
                let emplist = [];
                for(let j=0;j<detail2.length;j++){
                    const id = detail2[j].id;
                    emplist.push(id);
                    // console.log(emplist)
                }

                if(emplist.length !== 0){
                    for(let x=0;x<emplist.length;x++){
                        const id = emplist[x];
                        const emp_detail = detail2.filter(item=>item.id === id);
                        if(emp_detail.length !== 0){
                            for(let o=0;o<emp_detail.length;o++){
                                if(o === 0){
                                    const count1= count +2;
                                    const employe_number = emp_detail[o].employee_number;
                                    const name = emp_detail[o].name;
                                    const join_date = emp_detail[o].join_date;
                                    const nationality = emp_detail[o].nationality;

                                    let passtype = props.permitlist?.filter(item=>item.employee === emp_detail[o].id).map(item1=>item1.permit_type)

                                    ws2.getCell('A'+count1).value = employe_number;
                                    ws2.getCell('B'+count1).value = name;
                                    ws2.getCell('C'+count1).value = join_date;
                                    ws2.getCell('D'+count1).value = nationality;
                                    if(passtype.length === 0){
                                    ws2.getCell('E'+count1).value = "-";
                                    }else{
                                    ws2.getCell('E'+count1).value = passtype[0];
                                    }
                                }

                                count++;
                            }

                        }
                    }
                }
            }

            const title_name = 'MonthlyHeadCountReport'+day+'-'+month;
            const file_name = title_name +'.xlsx';
            wb.xlsx.writeBuffer().then((buf)=>{
              
                var file = new Blob([buf],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} )
                FileSaver.saveAs(file, file_name)
            });
            setState(prevState=>({...prevState,toggle:false}))
        }
        else{
            popError(IMLocalized('no_data'));
        }
    }

    return(
        <>
            <DropdownItem className="cursor-pointer" onClick={()=>getData()}>{IMLocalized('monthly_headcount_report')}</DropdownItem>
            <Modal size="lg" isOpen={state.toggle} >
                <ModalHeader toggle={()=>setState(prevState=>({...prevState,toggle:false}))}> {IMLocalized('monthly_headcount_report')}</ModalHeader>
                <ModalBody>
                    <Row className='mt-2'>
                        <Col>
                            <DateRangePicker
                            startDate={state.startDate}
                            isOutsideRange={()=>false}
                            small={true}
                            startDateId="unique_start_date_id"
                            endDate={state.endDate}
                            endDateId="unique_end_date_id"
                            focusedInput={state.focusedInput}
                            displayFormat={state.dateFormat}
                            onFocusChange={(focusedInput)=>setState(prevState=>({...prevState,focusedInput}))}
                            onDatesChange={({startDate,endDate})=>{

                                let start_date = null;
                                if(startDate !== null){
                                    let startdate =startDate._d;
                                    let startmonth = (startdate.getMonth()+1);
                                    if(startmonth <10){
                                        startmonth = '0'+startmonth;
                                    }
                                    let startday = startdate.getDate();
                                    if(startday<10){
                                        startday = '0'+startday
                                    }
                                    start_date = startdate.getFullYear()+'-' + startmonth+'-' +startday
                                }

                                let end_date = null;
                                if(endDate !== null){
                                    let enddate = endDate._d;
                                    let endmonth =(enddate.getMonth()+1);
                                    if(endmonth <10){
                                        endmonth = '0'+endmonth;
                                    }
                                    let endday = enddate.getDate();
                                    if(endday<10){
                                        endday = '0'+endday
                                    }
                                    end_date = enddate.getFullYear()+'-'+endmonth+'-'+endday
                                }

                                setState(prevState=>({
                                    ...prevState,
                                    startDate,
                                    endDate,
                                    start_date,
                                    end_date
                                }))
                            }}
                            >
                            </DateRangePicker>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {props.isLoading || props.isLoading1 || props.isLoading2 ?  <button className="btn btn-primary btn-sm"><i className="fas fa-spinner fa-spin"></i> {IMLocalized('loading')}</button> : 
                    <button onClick={generateRange} className="btn btn-primary btn-sm">{IMLocalized('get_monthly_headcount_report')}</button>}
                    <button className="btn btn-danger btn-sm" onClick={()=>setState(prevState=>({...prevState,toggle:false}))}>{IMLocalized('close')}</button>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(MonthlyHeadCountReport);
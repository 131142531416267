import myaxios from '../../axios';
import FormData from 'form-data';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const EMPUSERAPPLYLEAVE_REQUEST ='EMPUSERAPPLYLEAVE_REQUEST';
export const EMPUSERAPPLYLEAVE_SUCCESS ='EMPUSERAPPLYLEAVE_SUCCESS';
export const EMPUSERAPPLYLEAVE_FAILURE ='EMPUSERAPPLYLEAVE_FAILURE';


export const employee_user_apply_leave = (leave_options,require_confirmation,file,leave_entitlement,comment)=>{
    return dispatch=>{
        dispatch({
            type:'EMPUSERAPPLYLEAVE_REQUEST'
        });

        let formData = new FormData();
        if(file.length !== 0){
            for(let i=0;i<file.length;i++){
                formData.append('upload_images',file[i],file[i].name);
            }
            
        }
        
        if(leave_options.length !== 0){
            for(let i=0;i<leave_options.length;i++){
                formData.append(`leave_options[${i}]day`,leave_options[i].day);
                formData.append(`leave_options[${i}]date`,leave_options[i].date );
            }
        }
        
        formData.append('pending',require_confirmation);
        formData.append('leave_entitlement',leave_entitlement);
        formData.append('comment',comment);

        myaxios.post('leave/employee/',formData,{headers:{'Content-Type':'multipart/form-data'}})
        .then((response)=>{
            dispatch({
                type:'EMPUSERAPPLYLEAVE_SUCCESS',
                payload:response
            });
            
            Toast.fire({
                type: 'success',
                title: IMLocalized('apply_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'EMPUSERAPPLYLEAVE_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('apply_failure')
            });
        })
    }
}
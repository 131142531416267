import * as delete_delegation from '../../action/employee/delete_employee_delegation_list';

const initialState={
    isLoading:false,
    errors:null,
    data:null
}

const delete_employee_delegation_list = (state=initialState,action)=>{
    switch(action.type){
        case delete_delegation.DELEMPDELEGATIONLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                data:null,
                errors:null,
            }
        case delete_delegation.DELEMPDELEGATIONLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        case delete_delegation.DELEMPDELEGATIONLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state
    }
}
export default delete_employee_delegation_list;
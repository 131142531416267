import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';


export const LEAVEAPPROVAL_REQUEST = 'LEAVEAPPROVAL_REQUEST';
export const LEAVEAPPROVAL_SUCCESS = 'LEAVEAPPROVAL_SUCCESS';
export const LEAVEAPPROVAL_FAILURE = 'LEAVEAPPROVAL_FAILURE';


export const create_leave_approval=(data)=>{
    return dispatch=>{
        dispatch({
            type:'LEAVEAPPROVAL_REQUEST'
        });

        myaxios.post('employee/approval/create/',{
            data:data
        })
        .then((response)=>{
            dispatch({
                type:'LEAVEAPPROVAL_SUCCESS',
                payload:response
            });

            Toast.fire({
                type:'success',
                title:IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'LEAVEAPPROVAL_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type: 'error',
                title: IMLocalized('create_failure')
            });
        })
    }
}

export const sgpr_employee_cpf_payment= [
    {
        "value": "STD",
        "display": "Standard",
        "display_zh": "Standard"
    },
    {
        "value": "FULL",
        "display": "Full Contribution",
        "display_zh": "Full Contribution",
    },
    {
        "value": "EFESTD",
        "display": "Employer Full Contribution Employee Partial Rate",
        "display_zh": "Employer Full Contribution Employee Partial Rate",
    }
]
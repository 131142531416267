import * as claim_list from '../../action/claim/get_claim_list';

const initialState={
    data:[],
    errors:[],
    isLoading:false
}

const get_claim_list = (state=initialState,action)=>{
    switch(action.type){
        case claim_list.GETCLAIMLIST_REQUEST:
            return{
                ...state,
                isLoading:true,
                errors:[]
            }
        case claim_list.GETCLAIMLIST_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload.data,
                errors:[]
            }
        case claim_list.GETCLAIMLIST_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default get_claim_list;


export const get_claim_list_detail = (state,id)=>{
    const {data} = state.get_claim_list;
    return id !== null && data.length !== 0 ? data.find(item=>item.id === id) : null;
}
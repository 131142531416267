import { AgGridReact } from 'ag-grid-react';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Col, Container, Input, Row } from 'reactstrap';
import { reset_user_claim_detail_cancel_apply_claim, user_claim_detail_cancel_apply_claim } from '../../../action/user/user_claim_detail_cancel_apply_claim';
import { amountRenderer } from '../../ag-grid_component/amountRenderer';
import { date2Renderer } from '../../ag-grid_component/date2Renderer';
import { isFirstColumn } from '../../ag-grid_component/isFirstColumn';
import { IMLocalized } from '../../../language/IMLocalized';
import ModalUserApplyClaim from './modal/ModalUserApplyClaim';
import Swal from 'sweetalert2';
import {user_apply_claim_list} from '../../../action/user/user_apply_claim_list';
import { user_get_available_claim } from '../../../action/user/user_get_available_claim';
import { claim_detail_get_claim_detail } from '../../../action/user/claim_detail_get_claim_detail';
import ModalViewClaimDetail from './modal/ModalViewClaimDetail';
import { user_claim_detail_get_claim_picture } from '../../../action/user/user_claim_detail_get_claim_picture';
import OverlayLoading from '../../loading_component/overlay_loading';
import { NewMainContext } from '../../../context/NewMainContext';
import { ClaimStatusRenderer } from '../../../func/ClaimStatusRenderer';


const mapStateToProps=(state)=>({
    colorlist:state.get_general_color.data,
    applylist:state.user_apply_claim_list.data,
    isLoading:state.user_apply_claim_list.isLoading,
    isLoading1:state.get_general_color.isLoading,
    claimlist:state.get_claim_list.data,
    cancel_success:state.user_claim_detail_cancel_apply_claim.data,
    claimdetail:state.claim_detail_get_claim_detail.data,
    picture:state.user_claim_detail_get_claim_picture.data,
})

const mapDispatchToProps=(dispatch)=>({
    user_claim_detail_cancel_apply_claim:(id,comment)=>{
        dispatch(user_claim_detail_cancel_apply_claim(id,comment))
    },
    user_apply_claim_list:()=>{
        dispatch(user_apply_claim_list())
    },
    user_get_available_claim:()=>{
        dispatch(user_get_available_claim())
    },
    claim_detail_get_claim_detail:(id)=>{
        dispatch(claim_detail_get_claim_detail(id))
    },
    user_claim_detail_get_claim_picture:(id)=>{
        dispatch(user_claim_detail_get_claim_picture(id))
    },
    reset_user_claim_detail_cancel_apply_claim:()=>{
        dispatch(reset_user_claim_detail_cancel_apply_claim())
    }
})

const gridOptions={
    defaultColDef:{
        resizable:true,
        sortable:true,
    },
    rowSelection:'multiple',
    rowMultiSelectWithClick:true,
    suppressRowClickSelection:true,
}


const initialState={
    selected:'4',
    datalist:[],
    detail_toggle:false,
}

function UserClaimList(props){
    const {cancel_success,reset_user_claim_detail_cancel_apply_claim,user_apply_claim_list,user_get_available_claim} = props;
    const {action_toggle, claim_all_list_reset, setClaimAllListReset, addItem, activeTab } = useContext(NewMainContext);
    const [apply_toggle,setApplyToggle] = useState(false);
    const [state,setState] = useState(initialState);

    useEffect(()=>{
        if(claim_all_list_reset === true){
            setState(initialState);
            setClaimAllListReset(false);
        }
    },[claim_all_list_reset,setClaimAllListReset])

    useEffect(()=>{
        if(activeTab === '3' &&  cancel_success != null ){
            user_apply_claim_list();
            user_get_available_claim();
            reset_user_claim_detail_cancel_apply_claim();
        }
    },[cancel_success,user_apply_claim_list,user_get_available_claim,activeTab,reset_user_claim_detail_cancel_apply_claim])

    useEffect(()=>{
        const sort = props.applylist.sort((a,b)=> new Date(b.claim_date) - new Date(a.claim_date));
        setState(prevState=>({...prevState,datalist:sort}));
    },[props.applylist])


    const cancelPending =()=>{
        const data = gridOptions.api.getSelectedRows();
        if(data.length !== 0 ){
            Swal.fire({
                title:IMLocalized('cancelling_claim'),
                input:'textarea',
                text:`${IMLocalized('comment')} (${IMLocalized('optional')})`,
                showCancelButton:true,
                cancelButtonText:IMLocalized('no!'),
                confirmButtonText:IMLocalized('yes!')
            })
            .then((result)=>{
                if(result.value != null){
                    const arr2 = data.map(item=>{
                        return item.id
                    })
                    props.user_claim_detail_cancel_apply_claim(arr2,result.value);
                }
            })
        }
    }

    const onCellFocused=(params)=>{
        if(params?.column?.colDef?.field != null && params.column.colDef.field !== 'select'){
            const focusedCell = gridOptions.api.getFocusedCell();
            const cellValue = gridOptions.api.getDisplayedRowAtIndex(focusedCell.rowIndex)
            const id = cellValue.data.id;
            props.claim_detail_get_claim_detail(id);
            setState(prevState=>({
                ...prevState,
                detail_toggle:true,
            }))
        }
    }


    let columnDefs = [];
    if(state.selected === '0'){
        columnDefs = [
            {
                headerName:IMLocalized('select'),
                pinned:'left',
                field:'select',
                width:150,
                headerCheckboxSelection: isFirstColumn,
                checkboxSelection: isFirstColumn,
                headerCheckboxSelectionFilteredOnly:true,
                filter:false,
                lockPosition: true
            },
            {
                headerName:IMLocalized('claim_date'),
                field:'claim_date',
                cellRendererFramework:date2Renderer,
                filter:false,
            },
            {
                headerName:IMLocalized('claim_type'),
                field:'claim_type.name',
            },
            {
                headerName:IMLocalized('claim_amount'),
                field:'claim_amount',
                cellRendererFramework:amountRenderer
            },
            {
                headerName:IMLocalized('status'),
                field:'status',
                cellRendererFramework:ClaimStatusRenderer
            }
        ]
    }
    else{
        columnDefs = [
            {
                headerName:IMLocalized('claim_date'),
                field:'claim_date',
                cellRendererFramework:date2Renderer,
                filter:false,
            },
            {
                headerName:IMLocalized('claim_type'),
                field:'claim_type.name',
            },
            {
                headerName:IMLocalized('claim_amount'),
                field:'claim_amount',
                cellRendererFramework:amountRenderer
            },
            {
                headerName:IMLocalized('status'),
                field:'status',
                cellRendererFramework:ClaimStatusRenderer
            }
        ]
    }

    const row1 = state.datalist.filter(item=>{
        if(state.selected === '0'){
            return item.status === 'PENDING'
        }
        else if(state.selected === '1'){
            return item.status === 'APPROVED'
        }
        else if(state.selected === '2'){
            return item.status === 'REJECTED'
        }
        else if(state.selected === '3'){
            return item.status === 'CANCELLED'
        }
        else if(state.selected === '5'){
            return item.status === 'PAID'
        }
        else if(state.selected === '6'){
            return item.status === 'PAYROLL'
        }
        return item;
    })

    const pinnedData = [];
    if(row1.length !== 0 ){
        const claim_amount = row1.reduce((sum,item)=>{
            return sum+item.claim_amount;
        },0);
        pinnedData.push({claim_amount,claim_date:IMLocalized('total')});
    }

    return(
        <>
        {props.isLoading || props.isLoading1 ? <OverlayLoading /> :
        <>
        <Container fluid>
            <Row className='border-bottom bg-white'>
                <Col className='p-2'>
                    <Breadcrumb listClassName="breadcrumb-white bg-white px-2 py-1 mb-1">
                        <BreadcrumbItem><a href="/#" onClick={()=>addItem('1','dashboard')}>{IMLocalized('home')}</a></BreadcrumbItem>
                        <BreadcrumbItem active>{IMLocalized('claim_list')}</BreadcrumbItem>
                    </Breadcrumb>
                    <button className='ml-2 btn btn-primary btn-sm' onClick={()=>setApplyToggle(true)}>{IMLocalized('apply')}</button>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col className='d-flex align-items-center'>
                    <h3 className='mb-0'>
                        {state.selected === '4' && IMLocalized('all_claim_list')}
                        {state.selected === '0' && IMLocalized('pending_claim_list')}
                        {state.selected === '1' && IMLocalized('approved_claim_list')}
                        {state.selected === '2' && IMLocalized('rejected_claim_list')}
                        {state.selected === '3' && IMLocalized('cancelled_claim_list')}
                        {state.selected === '5' && IMLocalized('paid_claim_list')}
                        {state.selected === '6' && IMLocalized('payroll_paid_claim_list')}
                    </h3>
                </Col>
                <Col className='col-auto d-flex'>
                    {state.selected === '0' && <div className='mr-2'><button className='btn btn-danger btn-sm'  onClick={()=>cancelPending()}>{IMLocalized('cancel')}</button></div>}
                    <Input type="select" size="sm" name="selected" value={state.selected} 
                    onChange={(e)=>{
                        const value = e.target.value;
                        setState(prevState=>({...prevState,selected:value}))
                    }}>
                        <option value="4">{IMLocalized('ALL')}</option>
                        <option value='0'>{IMLocalized('PENDING')}</option>
                        <option value="1">{IMLocalized('APPROVED')}</option>
                        <option value="2">{IMLocalized('REJECTED')}</option>
                        <option value="3">{IMLocalized('CANCELLED')}</option>
                        <option value="5">{IMLocalized('PAID')}</option>
                        <option value="6">{IMLocalized('PAYROLL')}</option>
                    </Input>
                </Col>
            </Row>
            <div className='mt-2 ag-theme-balham' style={{height:action_toggle ? 'calc(100vh - 300px)' : 'calc(100vh - 264px)' }} >
                <AgGridReact
                gridOptions={gridOptions}
                columnDefs={columnDefs}
                rowData={row1}
                suppressDragLeaveHidesColumns={true}
                suppressSizeToFit={true}
                suppressColumnMoveAnimation={false}
                overlayNoRowsTemplate={IMLocalized('no_data')}
                pinnedBottomRowData={pinnedData}
                onCellFocused={onCellFocused}
                />
            </div>
        </Container>
        <ModalViewClaimDetail toggle={state.detail_toggle} 
        closeModal={()=>{
            setState(prevState=>({...prevState,detail_toggle:false}))
            gridOptions.api.clearFocusedCell();
        }} />
        <ModalUserApplyClaim toggle={apply_toggle} closeModal={()=>setApplyToggle(false)} />
        </>}
        </>
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(UserClaimList);
import * as contractarraysave from '../../action/contract/contract_contract_array_save';

const initialState={
    isLoading:false,
    data:[],
    errors:[],
    hello:[],
}

const contract_contract_array_save = (state=initialState,action )=>{
    switch(action.type){
        case contractarraysave.CONTRACTARRAYSAVE_REQUEST:
            return{
                ...state,
                isLoading:true
            }
        case contractarraysave.CONTRACTARRAYSAVE_SUCCESS:
            return{
                ...state,
                isLoading:false,
                data:action.payload,
                hello:action.payload.data
            }
        case contractarraysave.CONTRACTARRAYSAVE_FAILURE:
            return{
                ...state,
                isLoading:false,
                errors:action.payload
            }
        default:
            return state;
    }
}
export default contract_contract_array_save;
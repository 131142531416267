import myaxios from '../../axios';
import { IMLocalized } from '../../language/IMLocalized';
import { ErrToast, Toast } from '../../swal/SwalToast';

export const CREATECLAIMSCHEME_REQUEST = 'CREATECLAIMSCHEME_REQUEST';
export const CREATECLAIMSCHEME_SUCCESS = 'CREATECLAIMSCHEME_SUCCESS';
export const CREATECLAIMSCHEME_FAILURE = 'CREATECLAIMSCHEME_FAILURE';
export const CREATECLAIMSCHEME_RESET = 'CREATECLAIMSCHEME_RESET';
  
export const create_claim_scheme =(name,claim_entitlement)=>{
    return dispatch=>{
        dispatch({
            type:'CREATECLAIMSCHEME_REQUEST'
        });

        myaxios.post('claim/scheme/',{
            name:name,
            claim_entitlement:claim_entitlement
        })
        .then((response)=>{
            dispatch({
                type:'CREATECLAIMSCHEME_SUCCESS',
                payload:response
            });

            Toast.fire({
                type: 'success',
                title: IMLocalized('create_success')
            });
        })
        .catch((error)=>{
            dispatch({
                type:'CREATECLAIMSCHEME_FAILURE',
                payload:error
            });

            ErrToast.fire({
                type:'error',
                title:IMLocalized('create_failure')
            });
        })
    }
}

export const reset_create_claim_scheme = () => dispatch =>{
    dispatch({
        type:'CREATECLAIMSCHEME_RESET'
    });
}